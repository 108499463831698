import styled from "styled-components";
import { Form } from "antd";

import colors from "../../../../../../../themes/colors";

export const StyledForm = styled(Form)`
  .ant-form-item {
    margin-bottom: 0;
  }

  .ant-input-number-handler-wrap {
    display: none;
  }

  .ant-input-number,
  .ant-picker,
  .ant-input-number-group-wrapper {
    width: 100%;
  }

  .ant-input-number-input,
  .ant-picker-input input {
    text-align: right;
  }

  .ant-input-number-affix-wrapper input.ant-input-number-input {
    padding: 0 11px;
  }
`;

export const StyledFieldset = styled.fieldset`
  margin-bottom: 10px;
`;

export const InputWithAutoWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AutoWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: -4px;
  margin-bottom: 5px;
  gap: 10px;
  color: ${colors.lightGreyOpacity};
  cursor: pointer;
`;
