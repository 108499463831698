import styled, { css } from "styled-components";

import colors from "../../../themes/colors";

interface TableProps {
  readonly isRowClickable?: boolean;
}

export const Styles = styled.div<TableProps>`
  padding: 1rem 0;
  overflow-x: auto;
  max-width: 100%;

  table {
    border-spacing: 0;
    border: 1px solid black;

    th {
      border-top: 1px solid black;
    }

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }

    ${(props) =>
      props.isRowClickable &&
      css`
        tbody {
          cursor: pointer;
          tr[role="row"] {
            :hover {
              background-color: ${colors.lightGreyishBlue};
            }
          }
        }
      `}
  }
`;

export const PaginationText = styled.span`
  padding-left: 10px;
  padding-right: 10px;
`;
