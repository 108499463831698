import type * as Types from "../../schema.generated";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type ExportAnschreibenMutationVariables = Types.Exact<{
  email: Types.Scalars["String"];
  id: Types.Scalars["String"];
}>;

export type ExportAnschreibenMutation = {
  __typename?: "Mutation";
  exportAnschreiben: { __typename?: "MutationResult"; success: boolean };
};

export const ExportAnschreibenDocument = gql`
  mutation exportAnschreiben($email: String!, $id: String!) {
    exportAnschreiben(email: $email, id: $id) {
      success
    }
  }
`;
export type ExportAnschreibenMutationFn = Apollo.MutationFunction<
  ExportAnschreibenMutation,
  ExportAnschreibenMutationVariables
>;

/**
 * __useExportAnschreibenMutation__
 *
 * To run a mutation, you first call `useExportAnschreibenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportAnschreibenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportAnschreibenMutation, { data, loading, error }] = useExportAnschreibenMutation({
 *   variables: {
 *      email: // value for 'email'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExportAnschreibenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ExportAnschreibenMutation,
    ExportAnschreibenMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ExportAnschreibenMutation,
    ExportAnschreibenMutationVariables
  >(ExportAnschreibenDocument, options);
}
export type ExportAnschreibenMutationHookResult = ReturnType<
  typeof useExportAnschreibenMutation
>;
export type ExportAnschreibenMutationResult =
  Apollo.MutationResult<ExportAnschreibenMutation>;
export type ExportAnschreibenMutationOptions = Apollo.BaseMutationOptions<
  ExportAnschreibenMutation,
  ExportAnschreibenMutationVariables
>;
