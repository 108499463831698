import { ApiOutlined, BugOutlined, UserOutlined } from "@ant-design/icons";
import { Select } from "antd";
import { useEffect, useState } from "react";

import config from "../../../config";
import { getCurrentUserEmail } from "../../../gapi";
import {
  Bar,
  Left,
  Title,
  Right,
  SettingsList,
  ApiUrlSelect,
  UserSelect,
} from "./styledComponents";

const { Option } = Select;

const getApiUrlValue = () => {
  const apiUrl = localStorage.getItem("debug:apiUrl");

  if (!apiUrl) {
    return "default";
  }

  if (
    ![
      "default",
      "http://localhost:3000",
      "https://api.staging.kontist.com",
    ].includes(apiUrl)
  ) {
    return "custom";
  }

  return apiUrl;
};

const DebugBar = ({ isSignedIn }: { isSignedIn: boolean | null }) => {
  const [visible, setVisible] = useState<boolean>(
    localStorage.getItem("debug:showBar") === "true"
  );

  const apiUrlValue = getApiUrlValue();
  const userValue = localStorage.getItem("debug:mockAuth") ? "mock" : "default";
  const userEmail = getCurrentUserEmail();

  useEffect(() => {
    function handleKeyUp(event: KeyboardEvent) {
      if (event.target instanceof Element) {
        if (/^input|textarea|select|button$/i.test(event.target.tagName)) {
          return;
        }
      }

      // react-hotkeys-hook does not work properly if the key requires a
      // modifier, so we use our own event listener instead.
      // See https://github.com/JohannesKlauss/react-hotkeys-hook/issues/421.
      // See https://github.com/jaywcjlove/hotkeys/issues/198.
      if (event.key === "~") {
        setVisible(!visible);
      }
    }

    document.addEventListener("keyup", handleKeyUp);

    return () => {
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, [visible]);

  useEffect(() => {
    localStorage.setItem("debug:showBar", String(visible));
  }, [visible]);

  const onApiUrlChange = (value: unknown) => {
    if (value === "default") {
      localStorage.removeItem("debug:apiUrl");
    } else if (value === "custom") {
      return;
    } else if (typeof value === "string") {
      localStorage.setItem("debug:apiUrl", value);

      // Automatically set mockAuth if Google Sign-In is unlikely to work.
      if (
        value.endsWith(".kontist.com") &&
        !window.location.hostname.endsWith(".kontist.com")
      ) {
        localStorage.setItem("debug:mockAuth", "true");
      }
    } else {
      throw new Error(`Unknown apiUrl value: ${value}`);
    }

    setTimeout(() => {
      window.location.reload();
    });
  };

  const onApiUrlSelect = (value: unknown) => {
    if (value === "custom") {
      const url = window.prompt("Enter custom API URL");

      if (url) {
        localStorage.setItem("debug:apiUrl", url);
      }

      setTimeout(() => {
        window.location.reload();
      });
    }
  };

  const onUserChange = (value: unknown) => {
    if (value === "mock") {
      localStorage.setItem("debug:mockAuth", "true");
    } else if (value === "default") {
      localStorage.removeItem("debug:mockAuth");
    } else {
      throw new Error(`Unknown user value: ${value}`);
    }

    setTimeout(() => {
      window.location.reload();
    });
  };

  if (config.REACT_APP_ENV === "production") {
    return null;
  }

  return visible ? (
    <Bar>
      <Left>
        <BugOutlined style={{ verticalAlign: "middle" }} />
        <Title>Debug</Title>
      </Left>
      <Right>
        <SettingsList>
          <li>
            <ApiOutlined style={{ verticalAlign: "middle" }} />{" "}
            <ApiUrlSelect
              bordered={false}
              defaultValue={apiUrlValue}
              dropdownMatchSelectWidth={false}
              onChange={onApiUrlChange}
              onSelect={onApiUrlSelect}
              optionLabelProp="label"
            >
              <Option
                value="default"
                label={
                  apiUrlValue === "default" ? "Default" : <i>Reloading…</i>
                }
              >
                Default
                {apiUrlValue === "default" && (
                  <span style={{ float: "right" }}>✓</span>
                )}
                <br />
                <small className="text-monospace">
                  {process.env.REACT_APP_API_URL || "http://localhost:3000"}
                </small>
              </Option>
              <Option
                value="http://localhost:3000"
                label={
                  apiUrlValue === "http://localhost:3000" ? (
                    "Local"
                  ) : (
                    <i>Reloading…</i>
                  )
                }
              >
                Local
                {apiUrlValue === "http://localhost:3000" && (
                  <span style={{ float: "right" }}>✓</span>
                )}
                <br />
                <small className="text-monospace">http://localhost:3000</small>
              </Option>
              <Option
                value="https://api.staging.kontist.com"
                label={
                  apiUrlValue === "https://api.staging.kontist.com" ? (
                    "Staging"
                  ) : (
                    <i>Reloading…</i>
                  )
                }
              >
                Staging
                {apiUrlValue === "https://api.staging.kontist.com" && (
                  <span style={{ float: "right" }}>✓</span>
                )}
                <br />
                <small className="text-monospace">
                  https://api.staging.kontist.com
                </small>
              </Option>
              <Option
                value="custom"
                label={apiUrlValue === "custom" ? "Custom" : <i>Reloading…</i>}
              >
                Custom…
                {apiUrlValue === "custom" && (
                  <>
                    <span style={{ float: "right" }}>✓</span>
                    <br />
                    <small className="text-monospace">
                      {localStorage.getItem("debug:apiUrl")}
                    </small>
                  </>
                )}
              </Option>
            </ApiUrlSelect>
          </li>
          <li>
            <UserOutlined style={{ verticalAlign: "middle" }} />{" "}
            {isSignedIn ? (
              <UserSelect
                bordered={false}
                defaultValue={userValue}
                dropdownMatchSelectWidth={false}
                onChange={onUserChange}
                optionLabelProp="label"
              >
                <Option
                  value="default"
                  label={
                    userValue === "default" ? userEmail : <i>Reloading…</i>
                  }
                >
                  Use default
                  {userValue === "default" && (
                    <>
                      <span style={{ float: "right" }}>✓</span>
                      <br />
                      <small>{userEmail}</small>
                    </>
                  )}
                </Option>
                <Option
                  value="mock"
                  label={userValue === "mock" ? userEmail : <i>Reloading…</i>}
                >
                  Use mock user
                  {userValue === "mock" && (
                    <span style={{ float: "right" }}>✓</span>
                  )}
                  <br />
                  <small>debug@kontist.com</small>
                </Option>
              </UserSelect>
            ) : (
              "…"
            )}
          </li>
        </SettingsList>
      </Right>
    </Bar>
  ) : null;
};

export default DebugBar;
