import React, { FC } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { Editor as TinyMCEEditor } from "tinymce";

import config from "../../../config";

type EditorProps = {
  defaultValue?: string;
  editorRef: React.MutableRefObject<TinyMCEEditor | null>;
};

const RichTextEditor: FC<EditorProps> = ({ defaultValue, editorRef }) => (
  <Editor
    apiKey={config.TINYMCE_API_KEY}
    onInit={(_, editor) => (editorRef.current = editor)}
    initialValue={defaultValue || ""}
    init={{
      height: 700,
      menubar: true,
      statusbar: false,
      plugins: ["preview", "fullscreen", "link", "table", "advlist", "lists"],
      toolbar:
        "undo redo | bold italic underline strikethrough | numlist bullist | fullscreen preview print | forecolor backcolor",
      content_style:
        "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
    }}
  />
);

export default RichTextEditor;
