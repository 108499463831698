import { ApolloError } from "@apollo/client";

import { isRecord } from "./record";

export function toError(input: unknown): Error {
  if (input instanceof Error) {
    return input;
  }

  return new Error(getErrorMessage(input));
}

export function getErrorMessage(input: unknown): string {
  if (isWithMessage(input)) {
    return input.message;
  }

  try {
    return JSON.stringify(input);
  } catch {
    return String(input);
  }
}
export function getGraphQLErrorMessage(error: unknown) {
  if (
    error instanceof ApolloError &&
    error.networkError &&
    "result" in error.networkError
  ) {
    const graphQlError = error.networkError.result.errors[0];
    return {
      message: graphQlError?.message,
      description: graphQlError?.extensions?.payload,
    };
  }
}
function isWithMessage(input: unknown): input is { message: string } {
  return isRecord(input) && typeof input.message === "string";
}
