import React, { FC, useState, useMemo, DragEvent } from "react";
import moment, { Moment } from "moment";
import { InboxOutlined } from "@ant-design/icons";
import {
  Form,
  Select,
  Radio,
  Upload,
  DatePicker,
  RadioChangeEvent,
  Typography,
  notification,
  Button,
} from "antd";

import * as texts from "./constants";
import api from "../../../../../../api";
import {
  BookkeepingTool,
  SKRMode,
  BookingMode,
  useImportDatevDataMutation,
  DatevRawData,
} from "../../../../../../api/graphql";
import { parseCSVFiles, getCsvAndReceiptFiles } from "./utils/fileUploadUtils";
import { UploadFile } from "./utils/fileUploadUtils";
import { UploadContainer, StyledForm, ImportButtonWrapper } from "./styled";

const { Option } = Select;
const { Title } = Typography;

export interface UploadFormProps {
  userEmail: string;
  refetchDatevData: () => Promise<any>;
}

const UploadForm: FC<UploadFormProps> = ({ userEmail, refetchDatevData }) => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [date, setDate] = useState<Moment>(moment());
  const [isFileValid, setIsFileValid] = useState<boolean>(true);
  const [skrMode, setSkrMode] = useState<SKRMode>(SKRMode.SKR_03);
  const [bookingMode, setBookingMode] = useState<BookingMode>(
    BookingMode.ACCOUNTANT
  );
  const [bookkeepingTool, setBookkeepingTool] = useState<BookkeepingTool>(
    BookkeepingTool.DATEV
  );
  const [rawDataList, setRawDataList] = useState<DatevRawData[] | []>([]);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [receipts, setReceipts] = useState<UploadFile[]>([]);

  const skrModes = useMemo(() => Object.entries(texts.SKR_MODE_LABELS), []);
  const bookingModes = useMemo(
    () => Object.entries(texts.BOOKING_MODE_LABELS),
    []
  );
  const bookkeepingTools = useMemo(
    () => Object.entries(texts.BOOKKEEPING_TOOL_LABELS),
    []
  );
  const isFormMissing = useMemo(
    () =>
      !skrMode ||
      !bookingMode ||
      !bookkeepingTool ||
      !date ||
      rawDataList.length === 0,
    [skrMode, bookingMode, bookkeepingTool, date, rawDataList]
  );

  const [importDatevData, { loading: isImporting }] =
    useImportDatevDataMutation({
      variables: {
        payload: {
          skrMode,
          bookingMode,
          bookkeepingTool,
          year: date.year().toString(),
          email: userEmail,
          rawDataList,
        },
      },
    });

  const handleImportDatevData = async () => {
    await importDatevData();
    try {
      setIsUploading(true);
      await Promise.all(
        receipts.map((receipt: File) =>
          api.kontax.uploadReceipt(userEmail, receipt)
        )
      );
    } catch (e) {
      notification.error({
        message:
          "Einige Datein konnten nicht hochgeladen werden. Bitte versuche es erneut.",
      });
    } finally {
      setIsUploading(false);
    }
    await refetchDatevData();
  };

  const handleDropFile = async (e: DragEvent<HTMLDivElement>) => {
    const { csvFiles, receiptFiles } = getCsvAndReceiptFiles(e);

    // If there is no csv file, we throw error and stop processing files.
    if (!csvFiles.length) {
      notification.error({
        message: "Es muss immer eine CSV Datei hochgeladen werden",
      });
      return;
    }

    try {
      setRawDataList(await parseCSVFiles(bookingMode, csvFiles));
      setReceipts(receiptFiles);
      setFileList([...csvFiles, ...receiptFiles]);
      setIsFileValid(true);
    } catch ({ message }) {
      resetData();
      setIsFileValid(false);
      notification.error({
        message: `Format der hochgeladenen Datei wird nicht unterstützt. ${message}`,
      });
    }
  };

  const handleSelectSkrMode = (e: RadioChangeEvent) => {
    setSkrMode(e.target.value);
  };

  const handleSelectBookingMode = (e: RadioChangeEvent) => {
    setBookingMode(e.target.value);
  };

  const handleSelectBookkeepingTool = (newBookkeepingTool: BookkeepingTool) => {
    setBookkeepingTool(newBookkeepingTool);
  };

  const handleYearSelect = (date: Moment | null) => {
    if (date) {
      setDate(date);
    }
  };

  const resetData = () => {
    setRawDataList([]);
    setReceipts([]);
    setFileList([]);
  };

  const fileUploadProps = {
    name: "datev-csv",
    accept: ".csv,.jpg,.jpeg,.pdf,.png,.tiff",
    multiple: true,
    fileList,
    beforeUpload: () => false, // prevent files from being uploaded automatically
    onDrop: handleDropFile,
    onRemove: resetData,
    openFileDialogOnClick: false, // only support drag and drop
  };

  return (
    <div>
      <div>
        <Title level={3}>Datev-Import</Title>
        <p>
          Hier können Buchhaltungsdaten im Datev-EXTF-Format importiert werden.
        </p>
      </div>
      <div>
        <StyledForm
          layout="vertical"
          colon={false}
          initialValues={{
            skrMode,
            bookingMode,
            bookkeepingTool,
            date,
          }}
        >
          <Form.Item name="skrMode">
            <Radio.Group onChange={handleSelectSkrMode}>
              {skrModes.map(([value, label]) => (
                <Radio key={value} value={value}>
                  {label}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
          <Form.Item name="bookingMode">
            <Radio.Group onChange={handleSelectBookingMode}>
              {bookingModes.map(([value, label]) => (
                <Radio key={value} value={value}>
                  {label}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
          <Form.Item label="Buchhaltungsprogramm Export" name="bookkeepingTool">
            <Select onChange={handleSelectBookkeepingTool}>
              {bookkeepingTools.map(([value, label]) => (
                <Option key={value} value={value}>
                  {label}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Steuerjahr" name="date">
            <DatePicker picker="year" onChange={handleYearSelect} />
          </Form.Item>
        </StyledForm>
        <UploadContainer error={!isFileValid}>
          <Upload.Dragger {...fileUploadProps}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Dokumente hochladen</p>
            <p className="ant-upload-hint">
              Hier klicken oder Dateien per Drag & Drop ablegen.
            </p>
          </Upload.Dragger>
        </UploadContainer>
      </div>
      <ImportButtonWrapper>
        <Button
          type="primary"
          onClick={handleImportDatevData}
          disabled={isImporting || isUploading || isFormMissing}
        >
          Importieren
        </Button>
      </ImportButtonWrapper>
    </div>
  );
};

export default UploadForm;
