import React, { useCallback, useMemo } from "react";

import { Table } from "antd";

import VatAnnualDeclarationFieldsTable, {
  CODE_COLUMN_WIDTH,
} from "./VatAnnualDeclarationFieldsTable";
import VatAnnualDeclarationTableFooter from "./VatAnnualDeclarationTableFooter";
import EditableNumberCell, {
  EditableNumberCellProps,
} from "./EditableNumberCell";
import { TableWrapper, SourceCell } from "./styledComponents";
import {
  VatAnnualDeclarationField,
  VatAnnualDeclarationSection,
} from "../../../../../api/graphql";
import { COLUMN_ALIGN_RIGHT } from "./constants";
import FieldAmount from "./FieldAmount";
import { VatAnnualDeclarationPeriodType } from "../../types";

export interface VatAnnualDeclarationSectionRow
  extends VatAnnualDeclarationSection {
  title: string;
  source: string;
  editable?: boolean;
  yearSuffix?: number;
}

const VAT_ANNUAL_DECLARATION_TABLE_COMPONENTS = {
  body: {
    cell: EditableNumberCell,
  },
};

const VAT_ANNUAL_DECLARATION_TABLE_COLUMNS = [
  {
    title: "",
    dataIndex: "title",
    render: (title: string, row: VatAnnualDeclarationSectionRow) =>
      `${title}${row.yearSuffix ? ` ${row.yearSuffix}` : ""}`,
  },
  {
    title: "",
    dataIndex: "source",
    width: "30%",
    render: (source: string) => <SourceCell>{source}</SourceCell>,
  },
  {
    title: "",
    dataIndex: "total",
    width: "30%",
    align: COLUMN_ALIGN_RIGHT,
    render: (total: number | null) => <FieldAmount amount={total} />,
  },
];

export interface VatAnnualDeclarationBaseTableProps {
  email: string;
  taxYear: number;
  period: VatAnnualDeclarationPeriodType;
  inlinedFields?: VatAnnualDeclarationField[];
  sections: VatAnnualDeclarationSectionRow[];
  onChangeSection: (row: VatAnnualDeclarationSectionRow) => void;
  grandTotal: number;
  yearSuffix?: number;
}

const VatAnnualDeclarationBaseTable = ({
  email,
  taxYear,
  period,
  inlinedFields,
  sections,
  onChangeSection,
  grandTotal,
  yearSuffix,
}: VatAnnualDeclarationBaseTableProps) => {
  const rows = useMemo(
    () => sections.map((section) => ({ ...section, yearSuffix })),
    [sections, yearSuffix]
  );

  const handleTotalEdit = useCallback(
    (row: VatAnnualDeclarationSectionRow) => (total: number | null) => {
      if (total == null) {
        return;
      }

      onChangeSection({ ...row, total });
    },
    [onChangeSection]
  );

  const columns = useMemo(
    () =>
      VAT_ANNUAL_DECLARATION_TABLE_COLUMNS.map((col) => {
        if (col.dataIndex === "total") {
          return {
            ...col,
            onCell: (row: VatAnnualDeclarationSectionRow) =>
              row.editable
                ? ({
                    editable: true,
                    negativeEnabled: true,
                    value: row.total,
                    onEdit: handleTotalEdit(row),
                  } as EditableNumberCellProps)
                : {},
          };
        }

        return col;
      }),
    [handleTotalEdit]
  );

  return (
    <TableWrapper>
      {inlinedFields && (
        <VatAnnualDeclarationFieldsTable
          fields={inlinedFields}
          email={email}
          taxYear={taxYear}
          period={period}
          yearSuffix={yearSuffix}
          size="large"
        />
      )}
      <Table
        size="large"
        pagination={false}
        showHeader={false}
        components={VAT_ANNUAL_DECLARATION_TABLE_COMPONENTS}
        columns={columns}
        dataSource={rows}
        rowKey={(row) => row.title}
        rowClassName={(row) => (row.fields.length > 0 ? "expandable" : "")}
        expandable={{
          columnWidth: inlinedFields
            ? CODE_COLUMN_WIDTH // used to align title column
            : undefined,
          expandedRowRender: (row) => (
            <VatAnnualDeclarationFieldsTable
              fields={row.fields}
              email={email}
              taxYear={taxYear}
              period={period}
              yearSuffix={yearSuffix}
            />
          ),
          rowExpandable: (row) => row.fields.length > 0,
          expandRowByClick: true,
        }}
        footer={() => (
          <VatAnnualDeclarationTableFooter
            total={grandTotal}
            labelOffset={inlinedFields ? CODE_COLUMN_WIDTH : undefined}
          />
        )}
      />
    </TableWrapper>
  );
};

export default VatAnnualDeclarationBaseTable;
