import React, { useState } from "react";
import styled from "styled-components";

import colors from "../../../themes/colors";
import { ReactComponent as CloseIcon } from "../../../svgs/closeIcon.svg";

const PopupWrapper = styled.div`
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    99.99% {
      opacity: 0;
    }
  }
  padding: 32px 0;
  position: fixed;
  background: rgba(0, 0, 0, 0.9);
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  z-index: 2000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  animation: fadeIn 0.4s forwards;
  &.hidden {
    animation: fadeOut 0.4s forwards;
    display: none;
  }
`;

const PopupContent = styled.div`
  background: #fff;
  width: 375px;
  max-width: calc(100% - 40px);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-align: center;
  max-height: 100%;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  padding: 24px;
  position: relative;

  .closeIcon {
    top: 0;
    right: 0;
    position: absolute;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin: 5px 5px 0 0;
    transition: all 0.2s ease;
    &:hover {
      background: ${colors.lightPurple};
    }
    svg path {
      stroke: ${colors.purple};
    }
  }
`;

const PopupContentWrapper = styled.div`
  margin-bottom: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
`;

export interface Props {
  children?: any;
  onClosePopup?: () => void;
}

const Popup = ({ children, onClosePopup }: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(true);

  const closePopup = () => {
    if (onClosePopup) {
      onClosePopup();
    }
    setIsOpen(false);
  };

  const className = isOpen ? "" : "hidden";

  return (
    <PopupWrapper
      data-testid="popup"
      className={className}
      onClick={(event) => {
        closePopup();
        event.stopPropagation();
      }}
    >
      <PopupContent onClick={(event) => event.stopPropagation()}>
        <div className="closeIcon" onClick={closePopup}>
          <CloseIcon />
        </div>
        <PopupContentWrapper>{children}</PopupContentWrapper>
      </PopupContent>
    </PopupWrapper>
  );
};

export default Popup;
