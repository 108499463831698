import { TaxBoardUser } from "../../../../../api/graphql/schema.generated";
import { isEmail } from "../../../../../utils";
import { FilterType } from "./types";

export const generateFilterOptionFromStatus = (status: Record<string, any>) => {
  return Object.keys(status).map((key) => ({
    text: status[key].label || status[key],
    value: key,
  }));
};

export const getFullName = (user: TaxBoardUser) =>
  `${user.firstName} ${user.lastName}`;

const IS_DIGITS_REG_EXP = /^\d+$/;
export const getTaxUserSearchFilterType = (searchParam: string): FilterType => {
  if (searchParam.length === 0) {
    return FilterType.NONE;
  }

  if (isEmail(searchParam)) {
    return FilterType.EMAIL;
  } else if (IS_DIGITS_REG_EXP.test(searchParam)) {
    return FilterType.ACCOUNT_ID_OR_TAX_NUMBER;
  } else {
    return FilterType.USER_NAME;
  }
};

export const OrderDirectionMapping = {
  ascend: "ASC",
  descend: "DESC",
};

export const isDeclarationsExists = (taxBoardUser: TaxBoardUser) => {
  return (
    taxBoardUser.euerDeclarations.length &&
    taxBoardUser.incomeTaxDeclarations.length &&
    taxBoardUser.tradeTaxDeclarations.length &&
    taxBoardUser.vatAnnualDeclarations.length
  );
};
