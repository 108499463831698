import type * as Types from "../schema.generated";

import { gql } from "@apollo/client";
export type AnnotationOutputFragment = {
  __typename?: "AnnotationOutput";
  documentId: string;
  text: string;
  value: Array<{
    __typename?: "Annotation";
    id: string;
    start: number;
    end: number;
    tag: string;
    color: string;
    text: string;
  }>;
};

export const AnnotationOutputFragmentDoc = gql`
  fragment AnnotationOutput on AnnotationOutput {
    documentId
    text
    value {
      id
      start
      end
      tag
      color
      text
    }
  }
`;
