import { RefObject } from "react";
import { InputRef } from "antd";
import { useHotkeys } from "react-hotkeys-hook";

const useSearchHotkey = (inputRef: RefObject<InputRef>) =>
  useHotkeys("/, s", (event) => {
    event.preventDefault();

    if (inputRef.current) {
      inputRef.current.select();
      inputRef.current.input?.scrollIntoView(false);
    }
  });

export default useSearchHotkey;
