import { DEFAULT_LOCALE } from "../constants";

const formatDistanceInKm = (distance: number) =>
  Intl.NumberFormat(DEFAULT_LOCALE, {
    style: "unit",
    // TODO [typescript@>=4]: Remove @ts-ignore.
    // @ts-ignore: Ignore unknown property error because typing for `unit` is supported only in TypeScript v4
    unit: "kilometer",
  }).format(distance);

export default formatDistanceInKm;
