import React, { useCallback, useEffect, useMemo, useState } from "react";
import moment from "moment-timezone";
import {
  Button,
  DatePicker,
  Descriptions,
  Divider,
  Form,
  Input,
  message,
  PageHeader,
  Radio,
  Select,
  Table,
  Tag,
  Typography,
} from "antd";
import { CalculatorFilled } from "@ant-design/icons";
import normalizeSteuernummer, { State } from "normalize-steuernummer";
import { ServerError } from "@apollo/client";
import {
  DateParam,
  NumberParam,
  StringParam,
  useQueryParam,
  withDefault,
} from "use-query-params";

import { STATE_OPTION } from "../Mandanten/Common/UserDetails/TaxNumberList/utils";
import api from "../../../api";
import {
  showLoadingMessage,
  destroyMessage,
  BOTTOM_MESSAGE_STYLE,
} from "../../../utils";
import { ContentWrapper } from "../../common/styledComponents";
import { IUser } from "../../../types";
import UserInfoDrawer, {
  UserInfoDrawerSources,
} from "../../common/UserInfoExcerpt/UserInfoDrawer";
import { DEFAULT_CURRENCY, DEFAULT_LOCALE } from "../../../constants";
import { useGetTaxAccountLazyQuery } from "../../../api/graphql/queries/taxAccount/taxAccount.generated";
import { TaxAccountFragment } from "../../../api/graphql/fragments/taxAccount.generated";

const { Option } = Select;
const { Title } = Typography;

const TAX_ACCOUNT_LOADING_KEY = "tax-account-loading";
const TAX_ACCOUNT_SUM_KEY = "tax-account-sum";

const GERMAN_GROUP_SEPARATOR = ".";
const GERMAN_DECIMAL_SEPARATOR = ",";

interface TableRecord {
  key: string;
  teilbetragZeitraum: Array<string>;
  teilbetragWert?: Array<string> | null;
  children?: TableRecord[];
}

const TaxNumberHelp = ({
  isTaxNumberValid,
  user,
  onClick,
}: {
  isTaxNumberValid: boolean;
  user: IUser | null | undefined;
  onClick: React.MouseEventHandler<HTMLAnchorElement>;
}) => {
  if (!isTaxNumberValid) {
    return <span>Diese Steuernummer ist ungültig.</span>;
  }

  if (user) {
    return (
      <span>
        Hauptsteuernummer von{" "}
        <a href={`/?email=${encodeURIComponent(user.email)}`} onClick={onClick}>
          {user.firstName} {user.lastName} ({user.email})
        </a>
      </span>
    );
  } else if (user === null) {
    return (
      <span>
        Kein Mandant verwendet diese Steuernummer als Hauptsteuernummer
      </span>
    );
  } else {
    return null;
  }
};

const TABLE_COLUMNS = [
  {
    title: "Zeitraum",
    dataIndex: "teilbetragZeitraum",
    onCell: (record: TableRecord) => (record.children ? { colSpan: 2 } : {}),
    render: (text: string, record: TableRecord) =>
      record.children ? <th>{text}</th> : text,
  },
  {
    title: "Fälligkeit",
    dataIndex: "teilbetragFaelligkeit",
    onCell: (record: TableRecord) => (record.children ? { colSpan: 0 } : {}),
  },
  {
    title: "Wert",
    dataIndex: "teilbetragWert",
    render: (text: string, record: TableRecord) =>
      record.children ? <th>{text}</th> : text,
  },
  {
    title: "Erläuterung",
    dataIndex: "teilbetragErlaeuterung",
  },
];

const ROW_SELECTION = {
  getCheckboxProps: (record: TableRecord) => {
    if (record.children) {
      return { style: { display: "none" } };
    } else if (!record.teilbetragWert) {
      return {
        disabled: true,
      };
    } else {
      return {};
    }
  },
  hideSelectAll: true,
  onChange: (_: React.Key[], selectedRows: TableRecord[]) => {
    if (selectedRows.length === 0) {
      message.destroy(TAX_ACCOUNT_SUM_KEY);
      return;
    }

    const sum = selectedRows.reduce((sum, row) => {
      if (row.teilbetragWert && row.teilbetragWert.length > 1) {
        throw new Error("More than one teilbetrag-wert");
      }

      const valueString = row.teilbetragWert?.[0];
      let value;

      try {
        value = parseFloat(
          valueString!
            .replaceAll(GERMAN_GROUP_SEPARATOR, "")
            .replaceAll(GERMAN_DECIMAL_SEPARATOR, ".")
        );
      } catch (error) {
        throw new Error(`Unexpected teilbetrag-wert: ${valueString}`);
      }

      return sum + value;
    }, 0);

    const formattedSum = Intl.NumberFormat(DEFAULT_LOCALE, {
      style: "currency",
      currency: DEFAULT_CURRENCY,
    }).format(sum);
    const content = `Summe (aus ${selectedRows.length} ${
      selectedRows.length === 1 ? "Teilbetrag" : "Teilbeträgen"
    }): ${formattedSum}`;

    message.info({
      content,
      duration: 0,
      icon: <CalculatorFilled />,
      key: TAX_ACCOUNT_SUM_KEY,
      style: BOTTOM_MESSAGE_STYLE,
    });
  },
};

const TaxAccountTable = ({
  taxAccount,
}: {
  taxAccount: TaxAccountFragment;
}) => {
  const dataSource = useMemo(
    () =>
      taxAccount.kontoabfrageOutput[0].abfrageSteuerart?.map(
        (taxTypeData, taxTypeDataIndex: number): TableRecord => {
          return {
            key: taxTypeDataIndex.toString(),
            teilbetragZeitraum: taxTypeData.steuerartKlartext ?? [],
            teilbetragWert: taxTypeData.steuerartGesamtbetrag,
            ...(taxTypeData.steuerartErlaeuterung && {
              teilbetragErlaeuterung: taxTypeData.steuerartErlaeuterung,
            }),
            children: taxTypeData.steuerartTeilbetrag?.map(
              (record, recordIndex: number) => {
                return {
                  teilbetragZeitraum: record.teilbetragZeitraum ?? [],
                  teilbetragFaelligkeit: record.teilbetragFaelligkeit ?? [],
                  teilbetragWert: record.teilbetragWert,
                  teilbetragErlaeuterung: record.teilbetragErlaeuterung,
                  key: taxTypeDataIndex + "-" + recordIndex,
                };
              }
            ),
          };
        }
      ),
    [taxAccount]
  );

  return (
    <Table
      dataSource={dataSource}
      columns={TABLE_COLUMNS}
      expandable={{
        defaultExpandAllRows: true,
        showExpandColumn: false,
      }}
      rowSelection={ROW_SELECTION}
      pagination={false}
    />
  );
};

const TaxAccountView = () => {
  const lastMonth = moment().subtract(1, "months");
  const preselectedYear = Number(lastMonth.format("YYYY"));

  const [requestType, setRequestType] = useQueryParam(
    "requestType",
    withDefault(StringParam, "O")
  );
  const [taxNumber, setTaxNumber] = useQueryParam(
    "taxNumber",
    withDefault(StringParam, "")
  );
  const [isTaxNumberValid, setIsTaxNumberValid] = useState(true);
  const [state, setState] = useQueryParam(
    "state",
    withDefault(StringParam, "")
  );
  const [matchedUser, setMatchedUser] = useState<IUser | null | undefined>(
    undefined
  );
  const [isUserInfoDrawerVisible, setIsUserInfoDrawerVisible] = useState(false);
  const [taxType, setTaxType] = useQueryParam(
    "taxType",
    withDefault(StringParam, "alle")
  );
  const [year, setYear] = useQueryParam(
    "year",
    withDefault(NumberParam, preselectedYear)
  );
  const [valueDate, setValueDate] = useQueryParam(
    "valueDate",
    withDefault(DateParam, moment().startOf("year").toDate())
  );
  const [valueDateOption, setValueDateOption] = useQueryParam(
    "valueDateOption",
    withDefault(StringParam, "V")
  );

  // Clear irrelevant query parameters when switching request type.
  useEffect(() => {
    switch (requestType) {
      case "O":
        setTaxType(undefined);
        setYear(undefined);
        setValueDate(undefined);
        setValueDateOption(undefined);
        break;
      case "I":
        setYear(undefined);
        break;
      case "ZS":
        setValueDate(undefined);
        setValueDateOption(undefined);
        break;
    }
  }, [requestType, setTaxType, setYear, setValueDate, setValueDateOption]);

  const [
    getTaxAccount,
    { data, loading: isLoadingTaxAccount, error: taxAccountError },
  ] = useGetTaxAccountLazyQuery();

  const taxAccount = data?.getTaxAccount;

  useEffect(() => {
    return () => {
      message.destroy(TAX_ACCOUNT_SUM_KEY);
    };
  }, []);

  useEffect(() => {
    if (isLoadingTaxAccount) {
      showLoadingMessage(TAX_ACCOUNT_LOADING_KEY);
      destroyMessage(TAX_ACCOUNT_SUM_KEY);
    } else {
      destroyMessage(TAX_ACCOUNT_LOADING_KEY);
    }
  }, [isLoadingTaxAccount]);

  // We could debounce this function, but since it is only called in case a
  // valid tax number is entered, it is not as necessary.
  const getUser = useCallback(async (normalizedTaxNumber: string) => {
    try {
      const { users } = await api.kontax.getUsers({
        search: normalizedTaxNumber,
      });

      if (users.length > 1) {
        setMatchedUser(undefined);

        throw new Error("More than one user found");
      }

      // Sometimes, the search endpoint returns a user whose tax number does
      // not match the search input exactly.
      if (users[0].taxNumber === normalizedTaxNumber) {
        setMatchedUser(users[0]);
      } else {
        throw new Error("User not found");
      }
    } catch (error) {
      if (error instanceof Error && error.message === "User not found") {
        setMatchedUser(null);
      } else {
        setMatchedUser(undefined);

        throw error;
      }
    }
  }, []);

  useEffect(() => {
    if (!taxNumber || !state) {
      return;
    }

    let normalizedTaxNumber;

    try {
      normalizedTaxNumber = normalizeSteuernummer(taxNumber, state as State);
    } catch {
      setMatchedUser(undefined);
      return;
    }

    getUser(normalizedTaxNumber);
  }, [taxNumber, state, isTaxNumberValid, getUser]);

  const handleSubmit = () => {
    try {
      const normalizedTaxNumber = normalizeSteuernummer(
        taxNumber,
        state as State
      );
      setIsTaxNumberValid(true);
      switch (requestType) {
        case "O":
          return getTaxAccount({
            variables: {
              taxNumber: normalizedTaxNumber,
              state,
              taxType,
              requestType,
            },
          });
        case "I":
          return getTaxAccount({
            variables: {
              taxNumber: normalizedTaxNumber,
              state,
              taxType,
              requestType,
              valueDate: moment(valueDate).format("DDMMYYYY"),
              valueDateOption,
            },
          });
        case "ZS":
          return getTaxAccount({
            variables: {
              taxNumber: normalizedTaxNumber,
              state,
              taxType,
              requestType,
              year,
            },
          });
        default:
          throw new Error("Invalid request type");
      }
    } catch (err) {
      setIsTaxNumberValid(false);
    }
  };

  return (
    <ContentWrapper>
      <PageHeader
        className="site-page-header"
        title="Steuerkontoabfrage"
        tags={<Tag color="orange">Beta</Tag>}
        backIcon={false}
      />
      <Form layout="vertical" onFinish={handleSubmit}>
        <Form.Item label="Kontoabfrage-Art">
          <Select
            value={requestType}
            onChange={(value) => {
              setRequestType(value);

              // Only the request type `I` supports the tax type `alle`, so we
              // update the tax type if it is no longer valid.
              if (value !== "I" && taxType === "alle") {
                setTaxType("ESt");
              }
            }}
          >
            <Option value="O">Offene Beträge / O-Abfrage</Option>
            <Option value="I">Istbuchungen / I-Abfrage</Option>
            <Option value="ZS">Sollstellungen / ZS-Abfrage</Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="Steuernummer"
          name="taxNumber"
          validateStatus={isTaxNumberValid ? undefined : "error"}
          hasFeedback={!isTaxNumberValid}
          help={
            <TaxNumberHelp
              isTaxNumberValid={isTaxNumberValid}
              user={matchedUser}
              onClick={(event) => {
                event.preventDefault();
                setIsUserInfoDrawerVisible(true);
              }}
            />
          }
        >
          <Input
            defaultValue={taxNumber}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setTaxNumber(event.target.value);
            }}
          />
        </Form.Item>
        <Form.Item label="Bundesland">
          <Select
            options={STATE_OPTION}
            value={state}
            onChange={(value) => {
              setState(value);
            }}
          />
        </Form.Item>
        {["I", "ZS"].includes(requestType) && (
          <Form.Item label="Steuerart">
            <Select
              value={taxType}
              onChange={(value) => {
                setTaxType(value);
              }}
            >
              {requestType === "I" && <Option value="alle">Alle</Option>}
              <Option value="ESt">Einkommensteuer</Option>
              <Option value="KSt">Körperschaftsteuer</Option>
              <Option value="USt">Umsatzsteuer</Option>
              <Option value="LSt">Lohnsteuer</Option>
              <Option value="GewSt">Gewerbesteuer</Option>
              <Option value="ZaSt">Zinsabschlagsteuer</Option>
              <Option value="KapESt">Kapitalertragsteuer</Option>
            </Select>
          </Form.Item>
        )}
        {requestType === "I" && (
          <>
            <Form.Item label="Wertstellungsdatum">
              <DatePicker
                picker="date"
                format={["DD.MM.YYYY"]}
                style={{ width: "100%" }}
                defaultValue={moment(valueDate)}
                onChange={(value) => {
                  if (value) {
                    setValueDate(value.toDate());
                  }
                }}
              />
            </Form.Item>
            <Form.Item>
              <Radio.Group
                value={valueDateOption}
                onChange={(e) => setValueDateOption(e.target.value)}
              >
                <Radio value="V">bis heute</Radio>
                <Radio value="J">plus ein Jahr</Radio>
              </Radio.Group>
            </Form.Item>
          </>
        )}
        {requestType === "ZS" && (
          <Form.Item label="Jahr">
            <DatePicker
              picker="year"
              style={{ width: "100%" }}
              defaultValue={moment(year, "YYYY")}
              disabledDate={(current) => current && current > moment()}
              onChange={(value) => {
                if (value) {
                  setYear(value.year());
                }
              }}
            />
          </Form.Item>
        )}
        <Button type="primary" htmlType="submit">
          Abfrage durchführen
        </Button>
      </Form>
      {matchedUser && (
        <UserInfoDrawer
          visible={isUserInfoDrawerVisible}
          onClose={() => setIsUserInfoDrawerVisible(false)}
          user={matchedUser}
          notes={[]}
          source={UserInfoDrawerSources.CLIENTS}
        />
      )}
      {taxAccount && (
        <>
          <Divider />
          <Title level={4}>Abfrageeingaben</Title>
          <Descriptions>
            <Descriptions.Item label="Kontoabfrage-Art">
              {taxAccount.kontoabfrageInput[0].kontoabfrageArt?.join(" ")}
            </Descriptions.Item>
            {taxAccount.kontoabfrageInput[0].steuerart && (
              <Descriptions.Item label="Steuerart">
                {taxAccount.kontoabfrageInput[0].steuerart[0].value}
              </Descriptions.Item>
            )}
            <Descriptions.Item label="Steuernummer">
              {taxAccount.kontoabfrageInput[0].steuernummer?.join(" ")}
            </Descriptions.Item>
            {taxAccount.kontoabfrageInput[0].zeitraum && (
              <Descriptions.Item label="Zeitraum">
                {taxAccount.kontoabfrageInput[0].zeitraum.join(" ")}
              </Descriptions.Item>
            )}
          </Descriptions>
          <Title level={4}>Abfrageergebnis</Title>
          <Descriptions>
            {taxAccount.kontoabfrageOutput[0].abfrageErlaeuterung && (
              <Descriptions.Item label="Erläuterung">
                {taxAccount.kontoabfrageOutput[0].abfrageErlaeuterung}
              </Descriptions.Item>
            )}
            <Descriptions.Item label="Gesamtsumme">
              {taxAccount.kontoabfrageOutput[0].abfrageGesamtsumme}
            </Descriptions.Item>
            <Descriptions.Item label="Steuernummer">
              {taxAccount.kontoabfrageOutput[0].abfrageSteuernummer}
            </Descriptions.Item>
            <Descriptions.Item label="Tagesdatum">
              {taxAccount.kontoabfrageOutput[0].abfrageTagesdatum}
            </Descriptions.Item>
          </Descriptions>
          <TaxAccountTable taxAccount={taxAccount} />
        </>
      )}
      {taxAccountError && (
        <p>
          Sorry, that did not work (
          {(taxAccountError.networkError as ServerError)?.result?.errors
            .map(({ message }: { message: string }) => message)
            .join(", ") || taxAccountError.message}
          )
        </p>
      )}
    </ContentWrapper>
  );
};

export default TaxAccountView;
