import { CheckOutlined, WarningFilled } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Row,
  Select,
  Space,
  Spin,
} from "antd";
import { ReactNode, useState } from "react";

import { AutocategorizationRuleFragment } from "../../../api/graphql/fragments/autocategorizationRule.generated";
import {
  CreateAutocategorizationRuleInput,
  PaymentFrequency,
} from "../../../api/graphql/schema.generated";
import { Direction, IMessageType, PaymentFrequencyName } from "../../../types";
import { EXTERNAL_TRANSACTION_CATEGORIES, showMessage } from "../../../utils";
import { BooleanOptionRadioButton } from "../../common/BooleanOptionRadioButton";
import { ResettableBooleanOptionButton } from "../../common/ResettableBooleanOptionButton";
import { ResettableOptionButton } from "../../common/ResettableOptionButton";
import CategorySelect from "../TransactionView/CategorySelect";
import VatCategorySelect from "../TransactionView/VatCategorySelect";
import { AmountInput } from "./AmountInput";
import EmailSearch from "./EmailSearch";
import { RuleConditionStringInput } from "./RuleConditionStringInput";
import { FieldBox, FormHeader, StyleFix } from "./styledComponents";
import { useRuleForm } from "./useRuleForm";

export const getFrequencyOptions = () =>
  [
    PaymentFrequency.MONTHLY,
    PaymentFrequency.QUARTERLY,
    PaymentFrequency.YEARLY,
    PaymentFrequency.NONE,
    PaymentFrequency.NONE_QUARTERLY,
  ].map((value) => ({
    value,
    label: PaymentFrequencyName[value],
  }));

export type RuleFormProps = {
  rule?: AutocategorizationRuleFragment;
  title?: ReactNode;
  loading?: boolean;
  children?: ReactNode;
  onSubmit?: (rule: CreateAutocategorizationRuleInput) => void;
};

export const RuleFormErrors = {
  DESCRIPTION: "Please enter rule description",
  SELECTION: "At least one of these fields needs to be filled in",
  CATEGORY: "Please choose a category",
  VAT_CATEGORY: "Please choose a VAT category",
};

const RuleForm = (props: RuleFormProps) => {
  const { form, setForm, formControls, getRule } = useRuleForm({
    rule: props.rule,
  });

  const [isFormChanged, setIsFormChanged] = useState(false);
  const [triedToSubmit, setTriedToSubmit] = useState(false);

  const isSelectionFilled =
    form.conditions.name.value ||
    form.conditions.ibans.length ||
    form.conditions.description.value;

  return (
    <StyleFix>
      <Spin spinning={props.loading ?? false}>
        <Form
          layout="vertical"
          form={formControls}
          initialValues={form}
          onFinish={() => {
            const rule = getRule();
            if (typeof rule === "string") {
              showMessage({
                type: IMessageType.ERROR,
                content: rule,
                duration: 3,
              });
            } else {
              props.onSubmit?.(rule);
            }
          }}
          onFinishFailed={() => {
            setTriedToSubmit(true);
          }}
          onValuesChange={(_, form) => {
            setForm(form);
            setIsFormChanged(true);
          }}
          requiredMark="optional"
        >
          <FormHeader>
            {props.title}
            <Space>
              <Popconfirm
                title="You have unsaved changes. Are you sure you want to discard your changes?"
                onConfirm={() => window.close()}
                okText="Yes, discard"
                cancelText="No"
                icon={<WarningFilled style={{ color: "red" }} />}
                disabled={!isFormChanged}
                overlayStyle={{ width: 240 }}
              >
                <Button
                  onClick={() => {
                    !isFormChanged && window.close();
                  }}
                >
                  Cancel
                </Button>
              </Popconfirm>
              <Button
                onClick={formControls.submit}
                type="primary"
                data-test="submitButton"
                icon={<CheckOutlined />}
              >
                Save
              </Button>
            </Space>
          </FormHeader>

          <Form.Item name="enabled" hidden>
            <Input />
          </Form.Item>

          <Row>
            <Col span={8} offset={2}>
              <Form.Item
                name="description"
                label="Rule description"
                rules={[
                  { required: true, message: RuleFormErrors.DESCRIPTION },
                ]}
              >
                <Input.TextArea
                  placeholder="Enter rule description"
                  autoFocus
                />
              </Form.Item>
              <Form.Item
                name="priority"
                label="Rule priority"
                extra="Highest ( 5 ) to lowest ( -5 )"
                required
              >
                <InputNumber min={-5} max={5} precision={0} />
              </Form.Item>
              <Form.Item
                name={["conditions", "accountIds"]}
                label="Customer email/s"
              >
                <EmailSearch />
              </Form.Item>
              {props.children}
            </Col>
            <Col span={8} offset={1}>
              <FieldBox hasError={triedToSubmit && !isSelectionFilled}>
                <p
                  className={
                    triedToSubmit && !isSelectionFilled
                      ? "ant-form-item-explain-error"
                      : undefined
                  }
                >
                  {RuleFormErrors.SELECTION}
                </p>
                <RuleConditionStringInput
                  name={["conditions", "name"]}
                  label="Paid by"
                  placeholder="e.g. John Smith"
                  required={!isSelectionFilled || !!form.conditions.name.value}
                  validateStatus=""
                  help=""
                />
                <Form.Item
                  name={["conditions", "ibans"]}
                  label="IBAN"
                  rules={[
                    {
                      required:
                        !isSelectionFilled || !!form.conditions.ibans.length,
                    },
                  ]}
                  validateStatus=""
                  help=""
                >
                  <Select mode="tags" placeholder="e.g. DE12345678912334" />
                </Form.Item>
                <RuleConditionStringInput
                  name={["conditions", "description"]}
                  label="Description"
                  placeholder="e.g. Kontist Steuerservice, DE1254633"
                  required={
                    !isSelectionFilled || !!form.conditions.description.value
                  }
                  validateStatus=""
                  help=""
                />
              </FieldBox>
              <div style={{ paddingLeft: "24px", paddingRight: "24px" }}>
                <Form.Item
                  name="categoryCode"
                  label="Category"
                  trigger="onChangeHandler"
                  rules={[{ required: true, message: RuleFormErrors.CATEGORY }]}
                >
                  <CategorySelect
                    name="categoryCode"
                    transactionAmount={form.conditions.direction || -1}
                    disabledOptions={EXTERNAL_TRANSACTION_CATEGORIES}
                  />
                </Form.Item>

                <Form.Item
                  name="vatCategoryCode"
                  label="VAT category"
                  trigger="onChangeHandler"
                  rules={[
                    { required: true, message: RuleFormErrors.VAT_CATEGORY },
                  ]}
                >
                  <VatCategorySelect
                    name="vatCategoryCode"
                    kontaxCategory={form.categoryCode}
                    updateValueOnCategoryChange
                  />
                </Form.Item>

                <Form.Item
                  name={["conditions", "direction"]}
                  label="Type of transaction"
                >
                  <ResettableOptionButton
                    options={[
                      {
                        label: "Outgoing",
                        value: Direction.OUTGOING,
                      },
                      {
                        label: "Incoming",
                        value: Direction.INCOMING,
                      },
                    ]}
                  />
                </Form.Item>

                <AmountInput
                  name={["conditions", "amount"]}
                  direction={form.conditions.direction}
                  amount={form.conditions.amount}
                />

                <Form.Item
                  name={["conditions", "vatPaymentFrequencies"]}
                  label="VAT payment frequency"
                >
                  <Checkbox.Group
                    options={getFrequencyOptions()}
                    name="vatPaymentFrequencies"
                    style={{ maxWidth: "300px" }}
                  />
                </Form.Item>

                <Form.Item
                  name={["conditions", "hasEmployees"]}
                  label="Customer has employees"
                >
                  <ResettableBooleanOptionButton />
                </Form.Item>

                <Form.Item
                  name={["conditions", "hasVatNumber"]}
                  label="Customer has a VAT number"
                >
                  <ResettableBooleanOptionButton />
                </Form.Item>

                <Form.Item
                  name={["conditions", "hasInvoices"]}
                  label="Transaction has invoices"
                >
                  <ResettableBooleanOptionButton />
                </Form.Item>

                <Form.Item name="verify" label="Auto-verify" required>
                  <BooleanOptionRadioButton />
                </Form.Item>
              </div>
            </Col>
          </Row>
        </Form>
      </Spin>
    </StyleFix>
  );
};

export default RuleForm;
