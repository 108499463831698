import { Button, Space } from "antd";
import { CopyOutlined } from "@ant-design/icons";

import { predefinedKeywords } from "./data";
import { KeywordsContainer } from "./styledComponents";

const PredefinedKeywords = () => {
  return (
    <KeywordsContainer>
      <Space wrap>
        {predefinedKeywords.map((text) => (
          <Button
            key={text}
            icon={<CopyOutlined />}
            size="small"
            onClick={() => navigator.clipboard.writeText(text)}
          >
            {text}
          </Button>
        ))}
      </Space>
    </KeywordsContainer>
  );
};

export default PredefinedKeywords;
