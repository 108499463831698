import type * as Types from "../../schema.generated";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type UpsertHomeOfficeDocumentMutationVariables = Types.Exact<{
  email: Types.Scalars["String"];
  year: Types.Scalars["Int"];
  payload: Types.HomeOfficeDocumentInput;
}>;

export type UpsertHomeOfficeDocumentMutation = {
  __typename?: "Mutation";
  upsertHomeOfficeDocument: {
    __typename?: "HomeOfficeDocument";
    id: string;
    type: Types.HomeOfficeDocumentType;
    note: string;
  };
};

export const UpsertHomeOfficeDocumentDocument = gql`
  mutation upsertHomeOfficeDocument(
    $email: String!
    $year: Int!
    $payload: HomeOfficeDocumentInput!
  ) {
    upsertHomeOfficeDocument(email: $email, year: $year, payload: $payload) {
      id
      type
      note
    }
  }
`;
export type UpsertHomeOfficeDocumentMutationFn = Apollo.MutationFunction<
  UpsertHomeOfficeDocumentMutation,
  UpsertHomeOfficeDocumentMutationVariables
>;

/**
 * __useUpsertHomeOfficeDocumentMutation__
 *
 * To run a mutation, you first call `useUpsertHomeOfficeDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertHomeOfficeDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertHomeOfficeDocumentMutation, { data, loading, error }] = useUpsertHomeOfficeDocumentMutation({
 *   variables: {
 *      email: // value for 'email'
 *      year: // value for 'year'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useUpsertHomeOfficeDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertHomeOfficeDocumentMutation,
    UpsertHomeOfficeDocumentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpsertHomeOfficeDocumentMutation,
    UpsertHomeOfficeDocumentMutationVariables
  >(UpsertHomeOfficeDocumentDocument, options);
}
export type UpsertHomeOfficeDocumentMutationHookResult = ReturnType<
  typeof useUpsertHomeOfficeDocumentMutation
>;
export type UpsertHomeOfficeDocumentMutationResult =
  Apollo.MutationResult<UpsertHomeOfficeDocumentMutation>;
export type UpsertHomeOfficeDocumentMutationOptions =
  Apollo.BaseMutationOptions<
    UpsertHomeOfficeDocumentMutation,
    UpsertHomeOfficeDocumentMutationVariables
  >;
