import { YesNoEnum } from "../../types";
import {
  ResettableOptionButton,
  ResettableOptionButtonProps,
} from "./ResettableOptionButton";

export const ResettableBooleanOptionButton = (
  props: Omit<ResettableOptionButtonProps<boolean>, "options">
) => (
  <ResettableOptionButton
    {...props}
    options={[
      {
        label: YesNoEnum.NO,
        value: false,
      },
      {
        label: YesNoEnum.YES,
        value: true,
      },
    ]}
  />
);
