import type * as Types from "../../schema.generated";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type GetRetroactiveKontaxFeesQueryVariables = Types.Exact<{
  email: Types.Scalars["String"];
}>;

export type GetRetroactiveKontaxFeesQuery = {
  __typename?: "Query";
  retroactiveKontaxFees: Array<{
    __typename?: "RetroactiveKontaxFee";
    id: string;
    description: string;
    quantity: number;
    unitAmount: number;
    startDate: string;
    endDate: string;
    dueDate: string;
    invoiceDate?: string | null;
    status?: Types.RetroactiveKontaxFeeStatus | null;
  }>;
};

export const GetRetroactiveKontaxFeesDocument = gql`
  query GetRetroactiveKontaxFees($email: String!) {
    retroactiveKontaxFees(email: $email) {
      id
      description
      quantity
      unitAmount
      startDate
      endDate
      dueDate
      invoiceDate
      status
    }
  }
`;

/**
 * __useGetRetroactiveKontaxFeesQuery__
 *
 * To run a query within a React component, call `useGetRetroactiveKontaxFeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRetroactiveKontaxFeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRetroactiveKontaxFeesQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGetRetroactiveKontaxFeesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRetroactiveKontaxFeesQuery,
    GetRetroactiveKontaxFeesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetRetroactiveKontaxFeesQuery,
    GetRetroactiveKontaxFeesQueryVariables
  >(GetRetroactiveKontaxFeesDocument, options);
}
export function useGetRetroactiveKontaxFeesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRetroactiveKontaxFeesQuery,
    GetRetroactiveKontaxFeesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetRetroactiveKontaxFeesQuery,
    GetRetroactiveKontaxFeesQueryVariables
  >(GetRetroactiveKontaxFeesDocument, options);
}
export type GetRetroactiveKontaxFeesQueryHookResult = ReturnType<
  typeof useGetRetroactiveKontaxFeesQuery
>;
export type GetRetroactiveKontaxFeesLazyQueryHookResult = ReturnType<
  typeof useGetRetroactiveKontaxFeesLazyQuery
>;
export type GetRetroactiveKontaxFeesQueryResult = Apollo.QueryResult<
  GetRetroactiveKontaxFeesQuery,
  GetRetroactiveKontaxFeesQueryVariables
>;
