import React, { useEffect, useState } from "react";
import { Button, notification, Tag, Collapse } from "antd";
import moment, { Moment } from "moment";
import { EllipsisOutlined } from "@ant-design/icons";

import { SectionTitle } from "../../../../common/styledComponents";
import CustomerAttribute from "../../../../common/CustomerAttribute";
import EditableCustomerAttribute, {
  EditType,
} from "../../../../common/EditableCustomerAttribute";
import DependentsTable from "../../Common/UserDetails/DependentTable/DependentsTable";
import {
  IUser,
  IUserDetails,
  KontaxUserStatus,
  YesNoEnum,
  KontaxUserStatusAdditionalParams,
  PaymentFrequency,
  OnbStatus,
} from "../../../../../types";
import {
  ButtonsWrapper,
  HorizontalLine,
} from "../../Common/UserDetails/styledComponents";
import {
  CustomerAttributes,
  CustomerInformationSection,
  CustomerInformationSectionTitle,
  CustomerInformationCard,
  CustomerInformationSectionTitleWrapper,
} from "../../../../common/styledComponents";
import {
  formatDayMonthYear,
  formatTimestamp,
  formatMonthYear,
  formatOnbStatus,
} from "../../Common/UserDetails/utils";
import CustomerStatus from "../../Common/UserDetails/CustomerStatus";
import { DateInputType } from "../../../../common/TransactionFilters/DateInput";
import {
  formatAmountInCents,
  formatBooleanToString,
  getColorByOnboardingStatus,
} from "../../../../../utils";
import UserTaxNumbersList from "../../Common/UserDetails/TaxNumberList/TaxNumbersList";
import UserTaxInformationBlock from "../../../../common/UserTaxInformationBlock";
import UserBusinessInformationBlock from "../../Common/UserDetails/BusinessInformation";
import { ChangeKontaxStatusButton } from "../../Common/UserDetails/ChangeKontaxStatusButton";
import api from "../../../../../api";
import { UpsertAccountingSourcePayload } from "../../../../../types/AccountingSource.type";
import UserInfoDrawer, {
  UserInfoDrawerSources,
} from "../../../../common/UserInfoExcerpt/UserInfoDrawer";
import { useGetNotesQuery } from "../../../../../api/graphql/queries/notes";
import { PipedriveData, PipedriveDataContainer } from "../styledComponents";
import TextWithHyperlinks from "../../../../common/TextWithHyperlinks";
import { getUserFullName } from "../../../../../utils/getUserFullName";
import { SupportedLanguage } from "../../../../../api/graphql/schema.generated";
import ActionLogDrawerAsync from "../../../../common/ActionLogDrawerAsync";
import PaymentSection from "./PaymentSection";

const Panel = Collapse.Panel;

export function CustomerInformation({
  user,
  editUser,
  upsertAccountingSource,
  removeAccountingSource,
  updateUserStatus,
  updateDependents,
  deleteDependent,
}: {
  user: IUser;
  editUser: (email: string, userDetails: IUserDetails) => Promise<void>;
  removeAccountingSource: (email: string, year: number) => Promise<void>;
  upsertAccountingSource: (
    email: string,
    payload: UpsertAccountingSourcePayload
  ) => Promise<void>;
  updateUserStatus: (
    email: string,
    status: KontaxUserStatus | null,
    additionalParams?: KontaxUserStatusAdditionalParams
  ) => Promise<void>;
  updateDependents: Function;
  deleteDependent: (email: string, id: string) => Promise<void>;
}) {
  const [isUserInfoDrawerVisible, setIsUserInfoDrawerVisible] = useState(false);

  const getUserPOATemporaryUrl = async () => {
    const poaUrl = await api.kontax.getUserPOATemporaryUrl(user.email);

    if (!!poaUrl) {
      window.open(poaUrl, "_blank");
    }
  };

  const { data: notes, error: getNotesError } = useGetNotesQuery({
    variables: { email: user.email },
  });

  useEffect(() => {
    if (getNotesError) {
      notification.error({
        message: `An error occurred while fetching the notes for the user ${user.email}`,
      });
    }
  }, [getNotesError, user.email]);

  const onSaveFibuStartDateHandler = (fibuStartDate: string) => {
    editUser(user.email, {
      fibuStartDate,
    });
  };

  const onSaveFibuEndDateHandler = (lastDeclarationDate: Date | null) => {
    const lastDeclarationDueDate =
      user.vatPaymentFrequency === PaymentFrequency.QUARTERLY
        ? moment(lastDeclarationDate).endOf("quarter").toDate()
        : lastDeclarationDate;
    editUser(user.email, {
      kontaxUser: {
        lastDeclarationDueDate: lastDeclarationDueDate,
      },
    });
  };

  const onSaveTaxDeclarationStartDateHandler = async (
    firstTaxDeclarationDate: Moment | null
  ) => {
    const year =
      firstTaxDeclarationDate === null ? null : firstTaxDeclarationDate.year();
    await editUser(user.email, {
      firstTaxDeclarationYear: year,
    });
  };

  const onSaveKontaxBillingStartDateHandler = (
    kontaxBillingStartDate: string
  ) =>
    editUser(user.email, {
      kontaxBillingStartDate,
    });

  const onSaveKontaxOnbStatusHandler = (onbStatus: OnbStatus) =>
    editUser(user.email, {
      kontaxUser: {
        onbStatus: onbStatus === OnbStatus.NOT_SET ? null : onbStatus,
      },
    });

  return (
    <>
      <CustomerInformationSection>
        <UserInfoDrawer
          visible={isUserInfoDrawerVisible}
          onClose={() => setIsUserInfoDrawerVisible(false)}
          user={user}
          notes={notes?.getNotes || []}
          source={UserInfoDrawerSources.ACCOUNTING}
        />
        <CustomerInformationSectionTitleWrapper>
          <CustomerInformationSectionTitle>
            Customer Information
          </CustomerInformationSectionTitle>
          <Button
            type="default"
            onClick={() => setIsUserInfoDrawerVisible(true)}
            icon={<EllipsisOutlined />}
          >
            More info
          </Button>
        </CustomerInformationSectionTitleWrapper>
        <CustomerInformationCard>
          <CustomerAttributes>
            <CustomerAttribute label="Name" withFixedIcon isCopyable>
              {getUserFullName(user)}
            </CustomerAttribute>
            <CustomerAttribute label="Status">
              <CustomerStatus status={user.kontaxStatus} />
            </CustomerAttribute>
            <div>
              <SectionTitle>
                <EditableCustomerAttribute
                  label="ONB Status"
                  dataTest="onbStatus"
                  editType={EditType.Select}
                  formatFunction={formatOnbStatus}
                  options={Object.values(OnbStatus)}
                  initialValue={user.kontaxUser?.onbStatus || OnbStatus.NOT_SET}
                  onSaveHandler={onSaveKontaxOnbStatusHandler}
                  renderAttributeContent={(value: OnbStatus) => {
                    return (
                      <Tag color={getColorByOnboardingStatus(value)}>
                        {formatOnbStatus(value).toUpperCase()}
                      </Tag>
                    );
                  }}
                  inputRequired
                  allowClear
                />
                <span>
                  <ActionLogDrawerAsync
                    title="Action log"
                    modelName="kontax_user"
                    recordIds={[user.id.toString()]}
                    small
                  />
                </span>
              </SectionTitle>
            </div>
            <CustomerAttribute label="Account ID" isCopyable withFixedIcon>
              {user.accountId}
            </CustomerAttribute>
            <CustomerAttribute
              label="POA exported at"
              onInspect={user.poaExportedAt && getUserPOATemporaryUrl}
            >
              {formatTimestamp(user.poaExportedAt)}
            </CustomerAttribute>
            <CustomerAttribute label="POA signed at">
              {formatTimestamp(user.poaSignedAt)}
            </CustomerAttribute>
            <CustomerAttribute label="POA Stammdaten completed">
              {formatBooleanToString(user.poaCoreDataCompleted)}
            </CustomerAttribute>
            <EditableCustomerAttribute
              label="FiBu onboarding completed"
              initialValue={formatBooleanToString(
                !!user.taxServiceOnboardingCompletedAt
              )}
              editType={EditType.Select}
              options={[...Object.values(YesNoEnum)]}
              onSaveHandler={async (value: YesNoEnum) => {
                const taxServiceOnboardingCompleted = value === YesNoEnum.YES;

                await editUser(user.email, {
                  taxServiceOnboardingCompleted,
                });
              }}
            />
            <EditableCustomerAttribute
              label="FIBU start date"
              initialValue={user.fibuStartDate}
              formatFunction={formatMonthYear}
              editType={DateInputType.Month}
              inputRequired
              onSaveHandler={onSaveFibuStartDateHandler}
              allowClear
            />
            <EditableCustomerAttribute
              label="FIBU end date"
              initialValue={user.kontaxUser?.lastDeclarationDueDate}
              formatFunction={formatMonthYear}
              editType={EditType.PeriodPicker}
              allowClear
              onSaveHandler={onSaveFibuEndDateHandler}
              vatPaymentFrequency={user.vatPaymentFrequency as PaymentFrequency}
            />
            <EditableCustomerAttribute
              label="Erste Steuererklärung"
              initialValue={user.firstTaxDeclarationYear?.toString()}
              editType={DateInputType.Year}
              allowClear
              onSaveHandler={onSaveTaxDeclarationStartDateHandler}
            />

            <PipedriveDataContainer>
              <Collapse defaultActiveKey={1}>
                <Panel header="Pipedrive data" key={1}>
                  <PipedriveData>
                    <TextWithHyperlinks
                      text={user?.kontaxUser?.pipedriveData}
                    />
                  </PipedriveData>
                </Panel>
              </Collapse>
            </PipedriveDataContainer>
          </CustomerAttributes>
        </CustomerInformationCard>

        <HorizontalLine />

        <UserTaxNumbersList user={user} />
        <HorizontalLine />

        <UserTaxInformationBlock
          user={user}
          editUser={editUser}
          removeAccountingSource={removeAccountingSource}
          upsertAccountingSource={upsertAccountingSource}
          isOnboarding={true}
        />
        <HorizontalLine />

        <CustomerInformationSectionTitle>
          Personal Information
        </CustomerInformationSectionTitle>
        <CustomerInformationCard>
          <CustomerAttributes>
            <CustomerAttribute label="Email address" isCopyable withFixedIcon>
              {user.email}
            </CustomerAttribute>
            <CustomerAttribute label="Geburtstag" withFixedIcon isCopyable>
              {formatDayMonthYear(user.birthDate, SupportedLanguage.DE)}
            </CustomerAttribute>
            <CustomerAttribute label="Address">
              {user.street}, {user.postCode} {user.city}
            </CustomerAttribute>
            <CustomerAttribute
              label="Steuerliche Identifikationsnummer"
              isCopyable
              withFixedIcon
            >
              {user.deTaxId}
            </CustomerAttribute>
            <CustomerAttribute label="IBAN" isCopyable withFixedIcon>
              {user.accountIban}
            </CustomerAttribute>
            <CustomerAttribute label="Balance">
              {formatAmountInCents(user.balance, true)}
            </CustomerAttribute>
            {/* <AccountStats user={user} /> */}
          </CustomerAttributes>
        </CustomerInformationCard>
        <HorizontalLine />

        <UserBusinessInformationBlock user={user} editUser={editUser} />
        <HorizontalLine />

        <CustomerInformationSectionTitle>
          User's Dependents
        </CustomerInformationSectionTitle>
        <DependentsTable
          email={user.email}
          dependents={user.dependents || []}
          updateDependents={updateDependents}
          deleteDependent={deleteDependent}
        />
        <HorizontalLine />

        <CustomerInformationSectionTitle>
          Self-onboarding Information
        </CustomerInformationSectionTitle>
        <CustomerInformationCard>
          <CustomerAttributes>
            <CustomerAttribute label="Profession">
              {user.profession}
            </CustomerAttribute>
            <CustomerAttribute label="Uses a second business account">
              {formatBooleanToString(user.hasSecondBusinessAccount)}
            </CustomerAttribute>
            <CustomerAttribute label="Terms and conditions accepted at">
              {formatTimestamp(user.taxAdvisoryTermsVersionAcceptedAt)}
            </CustomerAttribute>
            <CustomerAttribute label="Onboarding started at">
              {formatTimestamp(user.taxServiceOnboardingStartedAt)}
            </CustomerAttribute>
          </CustomerAttributes>
        </CustomerInformationCard>

        <HorizontalLine />

        <PaymentSection
          user={user}
          onSaveKontaxBillingStartDateHandler={
            onSaveKontaxBillingStartDateHandler
          }
          editUserHandler={editUser}
        />

        <HorizontalLine />

        <ButtonsWrapper>
          <ChangeKontaxStatusButton
            kontaxStatus={user.kontaxStatus}
            email={user.email}
            updateUserStatus={updateUserStatus}
          />
        </ButtonsWrapper>
      </CustomerInformationSection>
    </>
  );
}
