import { notification } from "antd";
import { partition } from "lodash";
import { useCallback, useState } from "react";

import {
  useCreateEuerDeclarationSubformAssetMutation,
  useFinalizeAssetUploadMutation,
} from "../../../../../../api/graphql";
import { uploadFile } from "../../../../../../api/modules/Common";
import { Asset, ILocalAsset } from "../../../../../../types";
import { isLocalAsset } from "../../../../../common/LocalAssetsViewer";
import { SaveAssetResult } from "../../../types";
import { MAX_ASSET_UPLOAD_SIZE } from "../constants";

const FIND_FILE_EXT_REGEXP = /\.[^/.]+$/;

export interface FileNameAndType {
  name: string;
  type: string;
}

export const getFileNameAndType = (file: File): FileNameAndType => {
  const type = file.type.split("/").pop()!;
  // Remove extension from the name
  const name = file.name.replace(FIND_FILE_EXT_REGEXP, "");

  return { name, type };
};

/**
 * It will return a unique file name based on a file name and a list of existing assets.
 * It will first try to use the raw name and if it already exists it will try by appending ` (<depth>)`
 * where <depth> is the number of the recursion (e.g `myFileName (1)` when depth === 1).
 *
 * @param assets the current existing assets' list
 * @param fileName the name of the file being added (without file extension)
 * @param depth the depth of the recursion.
 * @returns {string} a unique file name given an assets' list.
 */
export const getUniqueFileName = (
  assets: Array<Asset | ILocalAsset>,
  file: FileNameAndType,
  depth = 0
): string => {
  let potentialName = !depth ? file.name : `${file.name} (${depth})`;
  // Add type as an extension to the name.
  potentialName += `.${file.type}`;

  const existingFile = assets.some(
    (asset) => (asset as ILocalAsset).name === potentialName
  );

  return !existingFile
    ? potentialName
    : getUniqueFileName(assets, file, depth + 1);
};

export const groupFilesBySize = (files: File[], maxSize: number) => {
  const [validFiles, invalidFiles] = partition(
    files,
    (file) => file.size <= maxSize
  );

  return { validFiles, invalidFiles };
};

const useEuerDeclarationSubformAssetsUploader = () => {
  const [assets, setAssets] = useState<Array<Asset | ILocalAsset>>([]);

  const handleDropFiles = useCallback(
    (files: File[]) => {
      const maxFileSize = 1024 * 1024 * MAX_ASSET_UPLOAD_SIZE;
      const { invalidFiles, validFiles } = groupFilesBySize(files, maxFileSize);

      if (invalidFiles.length > 0) {
        invalidFiles.forEach((file) => {
          notification.error({
            message: `File ${file.name} exceeding file size limit (${MAX_ASSET_UPLOAD_SIZE}MB).`,
          });
        });
      }

      const newAssets = validFiles.map((file) => {
        const { name, type } = getFileNameAndType(file);
        return {
          name: getUniqueFileName(assets, { name, type }),
          filetype: type,
          file,
        };
      });

      setAssets([...assets, ...newAssets]);
    },
    [assets]
  );

  const [createEuerDeclarationSubformAsset] =
    useCreateEuerDeclarationSubformAssetMutation();

  const [finalizeAssetUpload] = useFinalizeAssetUploadMutation();

  const uploadAsset = useCallback(
    async (
      euerDeclarationSubformId: string,
      { name, filetype, file }: ILocalAsset
    ): Promise<Asset> => {
      const createAssetResponse = await createEuerDeclarationSubformAsset({
        variables: {
          euerDeclarationSubformId,
          name,
          filetype,
        },
      });

      if (!createAssetResponse.data) {
        throw new Error("Failed to create EUER declaration subform asset");
      }

      const { assetId, formData, url } =
        createAssetResponse.data.createEuerDeclarationSubformAsset;

      // Prepare the form
      const form = new FormData();
      formData.forEach(({ key, value }) => form.append(key, value));
      form.append("file", file);

      await uploadFile({ url, form });

      const finalizeAssetUploadResponse = await finalizeAssetUpload({
        variables: { assetId },
      });

      if (!finalizeAssetUploadResponse.data) {
        throw new Error("Failed to finalize asset upload");
      }

      return finalizeAssetUploadResponse.data.finalizeAssetUpload;
    },
    [createEuerDeclarationSubformAsset, finalizeAssetUpload]
  );

  const saveAssets = useCallback(
    async (
      euerDeclarationSubformId: string
    ): Promise<Array<SaveAssetResult>> => {
      const localAssets = assets.filter((asset) =>
        isLocalAsset(asset)
      ) as ILocalAsset[];

      const uploadAssets = localAssets.map((asset) =>
        uploadAsset(euerDeclarationSubformId, asset)
      );

      const uploadStatuses = await Promise.allSettled(uploadAssets);

      return uploadStatuses.map((promiseState, index) => ({
        ...promiseState,
        localAsset: localAssets[index],
      }));
    },
    [assets, uploadAsset]
  );

  return {
    assets,
    setAssets,
    handleDropFiles,
    saveAssets,
  };
};

export default useEuerDeclarationSubformAssetsUploader;
