import { message } from "antd";

import { IMessage, IMessageType, EMPTY_IMESSAGE } from "../types";

export const showMessage = (data: IMessage): void => {
  switch (data.type) {
    case IMessageType.LOADING:
      message.loading({
        key: data.key,
        content: data.content || "Processing...",
        duration: data.duration ?? EMPTY_IMESSAGE.duration,
      });
      break;
    case IMessageType.SUCCESS:
      message.success(
        data.content || "Showing results",
        data.duration || EMPTY_IMESSAGE.duration
      );
      break;
    case IMessageType.INFO:
      message.info(data.content, data.duration || EMPTY_IMESSAGE.duration);
      break;
    case IMessageType.ERROR:
      message.error(data.content, data.duration || EMPTY_IMESSAGE.duration);
      break;
    default:
      break;
  }
};

/**
 * Displays a default loading message that is not automatically dismissed. When loading
 * has finished `destroyMessage` must be called with the same key to dismiss it, otherwise
 * the loading message will remain visible until page is reloaded/closed.
 */
export const showLoadingMessage = (key: string) =>
  showMessage({ type: IMessageType.LOADING, duration: 0, key });

/**
 * Displays a success message with a 1 second duration.
 */
export const showSuccessMessage = (content?: string) =>
  showMessage({ type: IMessageType.SUCCESS, duration: 1, content });

/**
 * Displays an error message with a 1 second duration.
 */
export const showErrorMessage = (content: string) =>
  showMessage({ type: IMessageType.ERROR, duration: 1, content });

export const destroyMessage = (key: string): void => {
  message.destroy(key);
};

export const BOTTOM_MESSAGE_STYLE = {
  bottom: "8px",
  display: "flex",
  flexDirection: "column-reverse",
  height: "100vh",
  left: "50%",
  position: "fixed",
  transform: "translateX(-50%)",
};
