import type * as Types from "../../schema.generated";

import { gql } from "@apollo/client";
import { AnschreibenFragmentDoc } from "../../fragments/anschreiben.generated";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type AnschreibenQueryVariables = Types.Exact<{
  email: Types.Scalars["String"];
  year: Types.Scalars["Int"];
}>;

export type AnschreibenQuery = {
  __typename?: "Query";
  anschreiben?: {
    __typename?: "Anschreiben";
    anschreibenPdfUrl?: string | null;
    id: string;
    content: string;
    year: number;
    note?: string | null;
    approvedAt?: string | null;
    rejectedAt?: string | null;
    exportedAt?: string | null;
  } | null;
};

export const AnschreibenDocument = gql`
  query anschreiben($email: String!, $year: Int!) {
    anschreiben(email: $email, year: $year) {
      ...Anschreiben
      anschreibenPdfUrl
    }
  }
  ${AnschreibenFragmentDoc}
`;

/**
 * __useAnschreibenQuery__
 *
 * To run a query within a React component, call `useAnschreibenQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnschreibenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnschreibenQuery({
 *   variables: {
 *      email: // value for 'email'
 *      year: // value for 'year'
 *   },
 * });
 */
export function useAnschreibenQuery(
  baseOptions: Apollo.QueryHookOptions<
    AnschreibenQuery,
    AnschreibenQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AnschreibenQuery, AnschreibenQueryVariables>(
    AnschreibenDocument,
    options
  );
}
export function useAnschreibenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AnschreibenQuery,
    AnschreibenQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AnschreibenQuery, AnschreibenQueryVariables>(
    AnschreibenDocument,
    options
  );
}
export type AnschreibenQueryHookResult = ReturnType<typeof useAnschreibenQuery>;
export type AnschreibenLazyQueryHookResult = ReturnType<
  typeof useAnschreibenLazyQuery
>;
export type AnschreibenQueryResult = Apollo.QueryResult<
  AnschreibenQuery,
  AnschreibenQueryVariables
>;
