import React from "react";
import { ArrowRightOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Row, Typography } from "antd";

const { Text } = Typography;

export interface TradeTaxDeclarationPreparationFieldProps {
  label: string;
  value?: string | null;
  onEdit?: () => void;
}

export const TradeTaxDeclarationPreparationField = ({
  label,
  value,
  onEdit,
}: TradeTaxDeclarationPreparationFieldProps) => {
  return (
    <>
      <Row align="middle">
        <Col flex="1">
          <Text>{label}</Text>
        </Col>
        <Col className={onEdit ? undefined : "pr-3"}>
          <Text>{value || "–"}</Text>
          {onEdit && (
            <Button
              data-testid="tradeTaxDeclarationPreparationFieldEditButton"
              type="link"
              size="middle"
              onClick={onEdit}
            >
              Edit
              <ArrowRightOutlined />
            </Button>
          )}
        </Col>
      </Row>
      <Divider className="my-2" />
    </>
  );
};
