import type * as Types from "../schema.generated";

import { gql } from "@apollo/client";
export type TaxDeclarationSubmissionDataFragment = {
  __typename?: "TaxDeclarationSubmissionInfo";
  submissionAgent?: string | null;
  submissionDate?: string | null;
  pdf?: string | null;
  calculationSheet?: string | null;
  externalAssets?: Array<{
    __typename?: "TaxDeclarationExternalAsset";
    url: string;
    filetype: string;
    createdAt: string;
  }> | null;
};

export const TaxDeclarationSubmissionDataFragmentDoc = gql`
  fragment TaxDeclarationSubmissionData on TaxDeclarationSubmissionInfo {
    submissionAgent
    submissionDate
    externalAssets {
      url
      filetype
      createdAt
    }
    pdf
    calculationSheet
  }
`;
