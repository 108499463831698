import styled from "styled-components";

import { Input } from "../styledComponents";
import colors from "../../../themes/colors";

export const DateInputWrapper = styled(Input)`
  min-width: 0;
  height: 50px;
  margin: ${({ withoutMargin }) => (withoutMargin ? "0px;" : "0 6px 10px 0")};
`;

export const DateInputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const VatRateWrapper = styled.div`
  margin-bottom: 10px;
`;

export const ShowCountWrapper = styled.div`
  margin-top: 24px;
  height: 32px;
  display: flex;
  align-items: center;
  & > button {
    padding: 0;
    color: ${colors.purple};
  }
`;
