import { gql, QueryHookOptions, useQuery } from "@apollo/client";

import { DocumentCategory } from "../types";

export interface DocumentCategoriesQueryResult {
  documentCategories: DocumentCategory[];
}

export const DOCUMENT_CATEGORIES_QUERY = gql`
  query DocumentCategories($categoryNames: [String!]) {
    documentCategories(categoryNames: $categoryNames) {
      id
      categoryName
      folderName
    }
  }
`;

export const useDocumentCategoriesQuery = (
  options?: QueryHookOptions<DocumentCategoriesQueryResult>
) =>
  useQuery<DocumentCategoriesQueryResult>(DOCUMENT_CATEGORIES_QUERY, options);
