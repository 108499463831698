import { gql, QueryHookOptions, useLazyQuery } from "@apollo/client";

import { EmailDocument } from "../types";
import { DOCUMENT_MATCH_RESULT_FIELDS } from "./fragments";

export interface EmailDocumentVariables {
  email: string;
  id?: string;
}

export interface EmailDocumentResult {
  emailDocument: EmailDocument;
}

export const EMAIL_DOCUMENT_QUERY = gql`
  ${DOCUMENT_MATCH_RESULT_FIELDS}
  query emailDocument(
    $email: String!
    $id: String
    $uploadSources: [DocumentUploadSource!]
  ) {
    emailDocument(email: $email, id: $id, uploadSources: $uploadSources) {
      id
      filename
      matchStatus
      name
      transactionId
      url
      amount
      currency
      date
      documentNumber
      iban
      createdAt
      transactionMatches {
        ...DocumentMatchResultFields
      }
    }
  }
`;

export const useEmailDocumentQuery = (
  options?: QueryHookOptions<EmailDocumentResult, EmailDocumentVariables>
) =>
  useLazyQuery<EmailDocumentResult, EmailDocumentVariables>(
    EMAIL_DOCUMENT_QUERY,
    options
  );
