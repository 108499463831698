import { isNull } from "lodash";

import { formatIntegerAmountCurrency } from "../../../../../utils";
import {
  EMPTY_INDICATOR,
  FINAL_RESULT_STATUS_TITLES,
  ValueType,
} from "./constants";

export const isPercentageValue = (valueType: ValueType) =>
  valueType === ValueType.PERCENTAGE;

export const isAmountValue = (valueType: ValueType) =>
  valueType === ValueType.AMOUNT;

export const getFormattedAmount = (value?: number) => {
  return isNull(value) ? EMPTY_INDICATOR : formatIntegerAmountCurrency(value);
};

export const getFormattedPercentage = (value?: number) => {
  return isNull(value) ? EMPTY_INDICATOR : `${value} %`;
};

export const isPositive = (number: number) => Math.sign(number) === 1;

export const getFormattedFinalResult = (value: number) => {
  if (value) {
    return formatIntegerAmountCurrency(-value);
  }
  return EMPTY_INDICATOR;
};

interface Option {
  label: string;
  value: string;
}

export const filterCities = (inputValue: string, option?: Option) =>
  option!.label.toLowerCase().startsWith(inputValue.toLowerCase());

export const getFinalResultLabel = (value: number) => {
  return value < 0
    ? FINAL_RESULT_STATUS_TITLES.REFUND
    : FINAL_RESULT_STATUS_TITLES.ADDITIONAL_PAYMENT;
};
