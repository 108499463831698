import React, { useState, useCallback, useMemo } from "react";
import { Comment, Drawer, List } from "antd";
import moment from "moment-timezone";

import { DeclarationDecline } from "../../../api/graphql/schema.generated";
import { useUserContext } from "../../pages/TaxDeclaration/contexts/UserContext";
import {
  NameContainer,
  NameLabel,
  StyledUserIcon,
  Title,
} from "./styledComponents";
import { declarationDeclinesSorter } from "../../../utils/declarationDeclines";

interface DeclarationDeclinesDrawerProps {
  declarationDeclines: DeclarationDecline[];
  showNotification?: boolean;
  title: string;
}
const DeclarationDeclinesDrawer = ({
  declarationDeclines,
  showNotification,
  title,
}: DeclarationDeclinesDrawerProps) => {
  const user = useUserContext();

  const [shouldShowDrawer, setShouldShowDrawer] = useState(false);
  const dataSource = useMemo(
    () => declarationDeclines.sort(declarationDeclinesSorter),
    [declarationDeclines]
  );

  const onClick = useCallback(() => setShouldShowDrawer(true), []);

  if (!user || !declarationDeclines.length) {
    return null;
  }

  return (
    <>
      <StyledUserIcon
        onClick={onClick}
        data-testid="view-declining-reasons-button"
        title="View declining reasons"
        showNotification={showNotification}
      />
      <Drawer
        title={`Kundennachrichten ${title}`}
        placement="right"
        onClose={() => setShouldShowDrawer(false)}
        visible={shouldShowDrawer}
      >
        <NameContainer>
          <NameLabel>Name</NameLabel>
          <div>{`${user.firstName} ${user.lastName}`}</div>
        </NameContainer>
        <Title>Nachrichten</Title>
        <List
          style={{ maxWidth: 700 }}
          dataSource={dataSource}
          itemLayout="horizontal"
          renderItem={({ reason, createdAt }) => (
            <Comment
              content={reason}
              author="Mandant*in"
              datetime={moment(createdAt).format("L")}
            />
          )}
        />
      </Drawer>
    </>
  );
};

export default DeclarationDeclinesDrawer;
