import { Col, Row, Typography } from "antd";
import React, { useMemo } from "react";

import { TradeTaxDeclarationCalculations } from "../../../../../../../api/graphql";
import colors from "../../../../../../../themes/colors";
import { roundDownCents } from "../../../../../../../utils";
import { SectionFields, SECTION_ROW_CONFIGS } from "../../constants";
import {
  CalculationList,
  CalculationListHeading,
} from "../../styledComponents";
import {
  getFinalResultLabel,
  getFormattedFinalResult,
  isPositive,
} from "../../utils";
import TradeTaxDeclarationCalculationListItem from "./TradeTaxDeclarationCalculationListItem";

const { Text, Paragraph } = Typography;

const TradeTaxDeclarationCalculationList = ({
  calculations,
  onChange,
}: {
  calculations: TradeTaxDeclarationCalculations;
  onChange: (value: number | null) => Promise<void>;
}) => {
  const sections: SectionFields[] = useMemo(() => {
    if (!calculations) {
      return [];
    }

    return [
      {
        ...SECTION_ROW_CONFIGS.PROFIT,
        value: calculations.profit,
      },
      {
        ...SECTION_ROW_CONFIGS.DEDUCTED_ADDITIONAL_AMOUNTS_MINUS200K_ALLOWANCE,
        value: calculations.deductedAdditionalAmountsMinus200kAllowance,
      },
      {
        ...SECTION_ROW_CONFIGS.ALLOWANCE,
        value: calculations.allowance,
      },
      {
        ...SECTION_ROW_CONFIGS.TRADE_TAX_ASSESSMENT_BASIS,
        value: calculations.tradeTaxAssessmentBasis,
      },
      {
        ...SECTION_ROW_CONFIGS.TRADE_TAX_RATE_FEDERAL,
        value: calculations.tradeTaxRateFederal,
      },
      {
        ...SECTION_ROW_CONFIGS.TAX_BASE_AMOUNT_FEDERAL,
        value: roundDownCents(calculations.taxBaseAmountFederal),
      },
      {
        ...SECTION_ROW_CONFIGS.TRADE_TAX_RATE_LOCAL,
        value: calculations.tradeTaxRateLocal,
      },
      {
        ...SECTION_ROW_CONFIGS.TRADE_TAX_AMOUNT,
        value: roundDownCents(calculations.tradeTaxAmount),
      },
      {
        ...SECTION_ROW_CONFIGS.TAX_OFFICE_BALANCE,
        value: calculations.taxOfficeBalance,
      },
    ];
  }, [calculations]);

  return (
    <>
      <CalculationListHeading>
        <Paragraph className="mr-2">
          <Text strong>Berechnung</Text>
        </Paragraph>
        <Paragraph>
          <Text>Taschenrechner hassen diesen Trick ;) </Text>
        </Paragraph>
      </CalculationListHeading>
      <CalculationList
        size="large"
        bordered
        footer={
          <Row
            justify="space-between"
            style={{
              color: isPositive(calculations.finalResult)
                ? colors.danger
                : colors.lightGreen,
            }}
          >
            <Col span={4}>{getFinalResultLabel(calculations.finalResult)}</Col>
            <Col span={4} style={{ textAlign: "right" }}>
              {getFormattedFinalResult(calculations.finalResult)}
            </Col>
          </Row>
        }
        dataSource={sections}
        renderItem={(item: SectionFields) => {
          return (
            <TradeTaxDeclarationCalculationListItem
              item={item}
              onChange={onChange}
            />
          );
        }}
      />
    </>
  );
};

export default TradeTaxDeclarationCalculationList;
