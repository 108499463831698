import React, { useState, useCallback, useEffect } from "react";
import { Drawer } from "antd";

import { HoverableCommentIcon } from "../styledComponents";
import {
  KontaxNote,
  KontaxNoteType,
} from "../../../api/graphql/schema.generated";
import AntNotes from "../AntNotes";
import { useUserContext } from "../../pages/TaxDeclaration/contexts/UserContext";

interface NotesDrawerProps {
  notes: KontaxNote[];
  title: string;
  recordId?: string;
  type: KontaxNoteType;
  email?: string;
}

const NotesDrawer = ({
  notes: initialNotes,
  title,
  recordId,
  type,
  email,
}: NotesDrawerProps) => {
  const user = useUserContext();

  const [shouldShowLog, setShouldShowLog] = useState(false);
  const [notes, setNotes] = useState<KontaxNote[]>(initialNotes || []);

  useEffect(() => setNotes(initialNotes || []), [initialNotes]);

  const onClick = useCallback(() => setShouldShowLog(true), []);

  if (!email && !user) {
    return null;
  }

  return (
    <>
      <HoverableCommentIcon
        onClick={onClick}
        data-testid="view-notes-button"
        title="View Notes"
      />
      <Drawer
        title={`Kommentare für ${title}`}
        placement="right"
        onClose={() => setShouldShowLog(false)}
        visible={shouldShowLog}
      >
        <AntNotes
          setNotes={setNotes}
          notes={notes}
          email={email || user!.email}
          type={type}
          recordId={recordId}
        />
      </Drawer>
    </>
  );
};

export default NotesDrawer;
