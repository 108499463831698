import React, { useState } from "react";
import { DatePicker } from "antd";
import { RangeValue } from "rc-picker/lib/interface";
import moment from "moment-timezone";
import { range } from "lodash";

import {
  DatePickerNoPopupHeader,
  StyledDateRangePicker,
} from "./styledComponents";

import { DateInputType } from "./TransactionFilters/DateInput";
import { ResettableOptionButton } from "./ResettableOptionButton";
import { endOfBerlinDayMoment, startOfBerlinDayMoment } from "./helpers";

export interface DateValues {
  dateFrom?: string;
  dateTo?: string;
}

interface Props {
  dateFrom?: string;
  dateTo?: string;
  maxDate: string;
  onChange: Function;
}

const format = {
  [DateInputType.Year as string]: "YYYY",
  [DateInputType.Quarter as string]: "Q.YYYY",
  [DateInputType.Month as string]: "MM.YYYY",
};

const getCurrentYear = () => moment().get(DateInputType.Year).toString();

const getYearString = (year: string): string => year || getCurrentYear();
const getQuarterString = (quarter: string, year: string): string =>
  `${quarter}.${getYearString(year)}`;
const getMonthString = (dateString: string, year: string): string =>
  dateString.slice(0, 3) + getYearString(year);

export const getDateRange = (
  start: moment.MomentInput,
  picker: DateInputType,
  dateLatest: string
): DateValues => {
  const momentStart = moment(start, format[picker]).startOf(picker);
  const momentEnd = moment(start, format[picker]).endOf(picker);
  const momentLatest = moment(dateLatest);

  return {
    dateFrom: startOfBerlinDayMoment(momentStart),
    dateTo: momentEnd.isAfter(momentLatest)
      ? endOfBerlinDayMoment(momentLatest)
      : endOfBerlinDayMoment(momentEnd),
  };
};

const doesQuarterBeginInFuture = (
  quarterString: string,
  year: string,
  dateLatestString: moment.MomentInput
): boolean => {
  const dateMoment = moment(quarterString, format[DateInputType.Quarter]);
  dateMoment.startOf(DateInputType.Quarter);

  // if year isn't set assume current year and check if the quarter starts in the future
  return (
    (!year || year === getCurrentYear()) && dateMoment.isAfter(dateLatestString)
  );
};

const setDatePickerAndSend = (
  range: DateValues,
  setDateRange: (arg: DateValues) => void,
  onChange: Function
): void => {
  setDateRange(range);
  onChange(range);
};

const setMonthPicker = (
  year: string,
  setDateMonthValue: (arg: moment.Moment | null) => void
): void => {
  // setting value to the DatePicker for a moment
  setDateMonthValue(moment(getYearString(year), format[DateInputType.Year]));
  // to make sure previous value was set
  setTimeout(() => setDateMonthValue(null), 0);
};

const getYearOptions = () =>
  range(Number(getCurrentYear()) - 2, Number(getCurrentYear()) + 1).map(
    (year) => ({
      value: year.toString(),
      label: year.toString(),
    })
  );

const DateFiltersPicker = ({ dateFrom, dateTo, maxDate, onChange }: Props) => {
  const [dateRange, setDateRange] = useState<DateValues>({ dateFrom, dateTo });
  const [dateMonthValue, setDateMonthValue] = useState<moment.Moment | null>();
  const [year, setYear] = useState<string>("");
  const [quarter, setQuarter] = useState<string>("");

  const maxWidth = 160; // maxWidth of filers group is 160px

  const quaterOptions = range(1, 4 + 1).map((quarterIdx) => ({
    value: "Q" + quarterIdx,
    label: "Q" + quarterIdx,
    disabled: doesQuarterBeginInFuture("Q" + quarterIdx, year, maxDate),
  }));

  const handleYearSelect = (yearSelected: string | undefined): void => {
    setQuarter("");
    setMonthPicker(getYearString(yearSelected || ""), setDateMonthValue);
    if (yearSelected === undefined) {
      setYear("");
      setMonthPicker(getCurrentYear(), setDateMonthValue);
      return;
    }
    setYear(yearSelected || "");
    setDatePickerAndSend(
      getDateRange(yearSelected, DateInputType.Year, maxDate),
      setDateRange,
      onChange
    );
  };

  const handleQuarterSelect = (quarterString: string | undefined): void => {
    if (!year) setYear(getCurrentYear());
    if (dateMonthValue) setDateMonthValue(null);
    if (quarterString === undefined) {
      setQuarter("");
      return setDatePickerAndSend(
        getDateRange(year, DateInputType.Year, maxDate),
        setDateRange,
        onChange
      );
    }
    setQuarter(quarterString);
    setDatePickerAndSend(
      getDateRange(
        getQuarterString(quarterString, year),
        DateInputType.Quarter,
        maxDate
      ),
      setDateRange,
      onChange
    );
  };

  const handleMonthSelect = (
    date: moment.Moment | null,
    dateString: string
  ): void => {
    if (!year) setYear(getCurrentYear());
    setDateMonthValue(date?.set(DateInputType.Year, +getYearString(year)));
    setQuarter("");
    if (!date) {
      return setDatePickerAndSend(
        getDateRange(year, DateInputType.Year, maxDate),
        setDateRange,
        onChange
      );
    }
    setDatePickerAndSend(
      getDateRange(
        getMonthString(dateString, year),
        DateInputType.Month,
        maxDate
      ),
      setDateRange,
      onChange
    );
  };

  const handleRangePickerSelect = (dates: RangeValue<moment.Moment>): void => {
    // happens if user clears the dateRangePicker
    if (!dates) {
      setDateRange({
        dateFrom,
        dateTo,
      });
    } else {
      setDateMonthValue(null);
      setQuarter("");
      setYear("");
      setDateRange({
        dateFrom: dates[0]?.format(),
        dateTo: dates[1]?.format(),
      });
      onChange({
        dateFrom: dates[0] && startOfBerlinDayMoment(dates[0]),
        dateTo: dates[1] && endOfBerlinDayMoment(dates[1]),
      });
    }
  };

  const yearOptions = getYearOptions();

  return (
    <>
      <div>
        <ResettableOptionButton
          options={yearOptions}
          onChange={handleYearSelect}
          width={(maxWidth + yearOptions.length - 1) / yearOptions.length}
          padding={0}
          value={year}
        />
      </div>
      <div>
        <ResettableOptionButton
          options={quaterOptions}
          onChange={handleQuarterSelect}
          width={(maxWidth + quaterOptions.length - 1) / quaterOptions.length}
          padding={0}
          value={quarter}
        />
      </div>
      <DatePicker
        data-test="monthPicker"
        picker={DateInputType.Month}
        format="MM.YYYY"
        placeholder="Month"
        value={dateMonthValue}
        disabledDate={
          maxDate && (!year || year === getCurrentYear())
            ? (date) => !date || date.isAfter(maxDate, "day")
            : undefined
        }
        onChange={handleMonthSelect}
        onOpenChange={() => setQuarter("")}
        // remove year selection div from popup calendar
        panelRender={(node) => (
          <DatePickerNoPopupHeader>{node}</DatePickerNoPopupHeader>
        )}
      />

      <StyledDateRangePicker
        format="DD.MM.YY"
        data-test="datePicker"
        disabledDate={
          maxDate ? (date) => !date || date.isAfter(maxDate, "day") : undefined
        }
        value={[
          dateFrom ? moment(dateRange.dateFrom) : null,
          dateTo ? moment(dateRange.dateTo) : null,
        ]}
        onChange={handleRangePickerSelect}
      />
    </>
  );
};

export default DateFiltersPicker;
