import React, { useCallback, useMemo, useState, useEffect } from "react";
import {
  Table,
  Popconfirm,
  Form,
  Typography,
  Button,
  notification,
  Space,
} from "antd";
import denormalizeTaxNumber from "denormalize-steuernummer";
import normalizeSteuernummer from "normalize-steuernummer";
import moment from "moment";
import { pick } from "lodash";
import { BankOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

import { IUser } from "../../../../../../types";
import {
  CustomerInformationCard,
  CustomerInformationSectionTitle,
} from "../../../../../common/styledComponents";
import { IsMainBusinessTaxNumber, TaxNumberType } from "./types";
import { StateAbbreviation } from "./types";
import {
  STATE_LABEL,
  TAX_NUMBER_TYPE_LABELS,
  TABLE_COLUMN_TITLES,
  getTaxAccountViewPath,
} from "./utils";
import { VerticalAligned } from "../styledComponents";
import { TaxNumber } from "../../../../../../api/graphql/types";
import {
  useTaxNumbersQuery,
  TAX_NUMBERS_QUERY,
} from "../../../../../../api/graphql/queries/taxNumbers";
import {
  useUpdateTaxNumberMutation,
  useCreateTaxNumberMutation,
  useDeleteTaxNumberMutation,
} from "../../../../../../api/graphql";
import EditableRow from "./EditableRow";
import {
  destroyMessage,
  showGraphQlErrorNotification,
  showLoadingMessage,
} from "../../../../../../utils";
import { notifySavedChange } from "../../utils";
import ActionLogDrawer from "../../../../../common/ActionLogDrawer";
import { mergeChangelogs } from "../../../../../common/ActionLogDrawer/helpers";
import Copyable from "../../../../../common/Copyable";
import colors from "../../../../../../themes/colors";
import MissingTaxNumberTable from "./MissingTaxNumberTable";
import { TAX_ADVISORY_PERMISSION_SCOPES } from "../../../../../../constants";
import { getPermissionScope } from "../../../../../../gapi";

const TAX_NUMBER_LOADING_KEY = "tax-number-loading";
const newRecordId: string = "new";

const UserTaxNumbersList = ({ user }: { user: IUser }) => {
  const hasTaxAdvisoryPermissionScope = TAX_ADVISORY_PERMISSION_SCOPES.includes(
    getPermissionScope()
  );

  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState<string | null>(null);
  const [newTaxNumber, setNewTaxNumber] = useState<TaxNumber | null>(null);
  const [hasBusinessTaxNumber, setHasBusinessTaxNumber] =
    useState<boolean>(false);
  const [hasPersonalTaxNumber, setHasPersonalTaxNumber] =
    useState<boolean>(false);

  useEffect(() => {
    setHasBusinessTaxNumber(user.hasBusinessTaxNumber);
    setHasPersonalTaxNumber(user.hasPersonalTaxNumber);
  }, [user.hasBusinessTaxNumber, user.hasPersonalTaxNumber]);

  const { data: userTaxNumbersData } = useTaxNumbersQuery({
    variables: { email: user.email },
  });

  const [updateUserTaxNumber, { loading: updateLoading }] =
    useUpdateTaxNumberMutation({
      refetchQueries: [TAX_NUMBERS_QUERY],
    });

  const [createUserTaxNumber, { loading: createLoading }] =
    useCreateTaxNumberMutation({
      refetchQueries: [TAX_NUMBERS_QUERY],
    });

  const [deleteUserTaxNumber, { loading: deleteLoading }] =
    useDeleteTaxNumberMutation({
      refetchQueries: [TAX_NUMBERS_QUERY],
    });

  const isLoading = useMemo(
    () => updateLoading || createLoading || deleteLoading,
    [updateLoading, createLoading, deleteLoading]
  );

  const taxNumberDataSource = useMemo(() => {
    const taxNumbers = userTaxNumbersData?.taxNumbers || [];
    if (newTaxNumber) {
      return [...taxNumbers, newTaxNumber];
    }
    return taxNumbers;
  }, [userTaxNumbersData, newTaxNumber]);

  const isNewRecord = (id: string) => id === newRecordId;

  const getHeaderErrorMessage = useCallback((id: string) => {
    return isNewRecord(id)
      ? `Error while saving the new tax number`
      : `Error while updating the tax number`;
  }, []);

  const save = useCallback(
    async (id: string) => {
      const row = await form.validateFields();
      if (isNewRecord(id)) {
        try {
          row.taxNumber = normalizeSteuernummer(row.rawTaxNumber, row.state);
        } catch (error) {
          if (error instanceof Error) {
            const errorMessageHeader = getHeaderErrorMessage(id);
            notification.error({
              message: errorMessageHeader,
              description: `${error.message}`,
            });
            return;
          }
        }
      }

      const payload = {
        ...pick(row, [
          "type",
          "taxNumber",
          "validFrom",
          "description",
          "isMainBusinessTaxNumber",
        ]),
      };

      try {
        showLoadingMessage(TAX_NUMBER_LOADING_KEY);
        if (isNewRecord(id)) {
          await createUserTaxNumber({
            variables: { email: user.email, payload },
          });
          setNewTaxNumber(null);
        } else {
          await updateUserTaxNumber({
            variables: { id, payload },
          });
        }
        row.type === TaxNumberType.PERSONAL
          ? setHasPersonalTaxNumber(true)
          : setHasBusinessTaxNumber(true);
        notifySavedChange();
        setEditingKey(null);
      } catch (error) {
        const errorMessageHeader = getHeaderErrorMessage(id);
        showGraphQlErrorNotification(errorMessageHeader, error);
      } finally {
        destroyMessage(TAX_NUMBER_LOADING_KEY);
      }
    },
    [
      form,
      user.email,
      updateUserTaxNumber,
      createUserTaxNumber,
      getHeaderErrorMessage,
    ]
  );

  const deleteTaxNumberRecord = useCallback(
    async (id: string) => {
      if (id !== newRecordId) {
        try {
          showLoadingMessage(TAX_NUMBER_LOADING_KEY);
          await deleteUserTaxNumber({
            variables: { id },
          });
          notifySavedChange();
        } catch (error) {
          showGraphQlErrorNotification(
            "Error while deleting the tax number",
            error
          );
        } finally {
          destroyMessage(TAX_NUMBER_LOADING_KEY);
        }
      } else {
        setNewTaxNumber(null);
      }
      setEditingKey(null);
    },
    [deleteUserTaxNumber]
  );

  const add = useCallback(() => {
    form.resetFields();
    setNewTaxNumber({
      id: newRecordId,
      type: TaxNumberType.PERSONAL,
      description: "",
      isMainBusinessTaxNumber: false,
      taxNumber: "",
    });
    setEditingKey(newRecordId);
  }, [form]);

  const edit = useCallback(
    (record: TaxNumber) => {
      form.resetFields();
      setEditingKey(record.id);
    },
    [form]
  );

  const cancel = useCallback(() => {
    setEditingKey(null);
    if (newTaxNumber) {
      setNewTaxNumber(null);
    }
  }, [newTaxNumber]);

  const isEditing = useCallback(
    (record: TaxNumber) => record.id === editingKey,
    [editingKey]
  );

  const changeLogs = useMemo(
    () =>
      mergeChangelogs(
        userTaxNumbersData?.taxNumbers?.map((tax) => {
          const { taxNumber } = denormalizeTaxNumber(tax.taxNumber) || {};

          return {
            ...tax,
            ...(taxNumber ? { subText: `Tax Number: ${taxNumber}` } : {}),
          };
        }) || []
      ),
    [userTaxNumbersData]
  );

  const columns = useMemo(
    () => [
      {
        key: "type",
        title: TABLE_COLUMN_TITLES.TYPE,
        dataIndex: "type",
        editable: true,
        required: true,
        width: "180px",
        render: (type: TaxNumberType) => TAX_NUMBER_TYPE_LABELS[type],
      },
      {
        key: "state",
        title: TABLE_COLUMN_TITLES.STATE,
        width: "150px",
        editable: true,
        required: true,
        render: (record: TaxNumber) =>
          STATE_LABEL[
            denormalizeTaxNumber(record.taxNumber)
              ?.taxState as StateAbbreviation
          ],
      },
      {
        key: "rawTaxNumber",
        title: TABLE_COLUMN_TITLES.TAX_NUMBER,
        width: "170px",
        editable: editingKey === newRecordId,
        required: true,
        render: (record: TaxNumber) => (
          <Copyable withFixedIcon>
            {denormalizeTaxNumber(record.taxNumber)?.taxNumber}
          </Copyable>
        ),
      },
      {
        key: "taxAccountView",
        title: "",
        render: (record: TaxNumber) => (
          <>
            <Link
              to={getTaxAccountViewPath({
                email: user.email,
                taxNumber: record.taxNumber,
              })}
            >
              <BankOutlined style={{ color: colors.purple }} />
            </Link>
          </>
        ),
      },
      {
        key: "validFrom",
        title: TABLE_COLUMN_TITLES.VALID_FROM,
        editable: true,
        width: "100px",
        required: false,
        render: (record: TaxNumber) =>
          record.validFrom && moment(record.validFrom).format("DD.MM.YYYY"),
      },
      {
        key: "description",
        title: TABLE_COLUMN_TITLES.DESCRIPTION,
        dataIndex: "description",
        width: "150px",
        editable: true,
        required: false,
      },
      {
        key: "isMainBusinessTaxNumber",
        title: TABLE_COLUMN_TITLES.MAIN_TAX_NUMBER,
        dataIndex: "isMainBusinessTaxNumber",
        editable: true,
        required: true,
        width: "180px",
        render: (isMainBusinessTaxNumber: boolean) => {
          if (isMainBusinessTaxNumber) {
            return IsMainBusinessTaxNumber.YES;
          }
          return IsMainBusinessTaxNumber.NO;
        },
      },
      {
        key: "action",
        title: TABLE_COLUMN_TITLES.ACTION,
        render: (_: any, record: TaxNumber) => {
          if (record.deletedAt) {
            return "(Deleted)";
          }
          const editable = isEditing(record);
          return editable ? (
            <span>
              <Typography.Link
                onClick={() => save(record.id)}
                style={{ marginRight: 8 }}
                disabled={isLoading}
              >
                Save
              </Typography.Link>
              <Typography.Link
                onClick={cancel}
                style={{ marginRight: 8 }}
                disabled={isLoading}
              >
                Cancel
              </Typography.Link>
              <Popconfirm
                title="Sure to delete?"
                onConfirm={() => deleteTaxNumberRecord(record.id)}
              >
                <Typography.Link
                  style={{ marginRight: 8 }}
                  disabled={isLoading}
                >
                  Delete
                </Typography.Link>
              </Popconfirm>
            </span>
          ) : (
            <Typography.Link
              disabled={editingKey !== null}
              onClick={() => edit(record)}
            >
              Edit
            </Typography.Link>
          );
        },
      },
    ],
    [
      cancel,
      deleteTaxNumberRecord,
      edit,
      editingKey,
      isEditing,
      isLoading,
      save,
      user.email,
    ]
  );

  const mergedColumns = useMemo(
    () =>
      columns.map((col) => {
        if (!col.editable) {
          return col;
        }

        return {
          ...col,
          onCell: (record: TaxNumber) => ({
            record,
            dataIndex: col.key,
            title: col.title,
            editing: isEditing(record),
            required: col.required,
          }),
        };
      }),
    [columns, isEditing]
  );

  return (
    <Form form={form} component={false}>
      <CustomerInformationSectionTitle>
        <Space>
          Steuernummern
          <ActionLogDrawer title="Action log" changeLogs={changeLogs} />
        </Space>
      </CustomerInformationSectionTitle>
      <CustomerInformationCard>
        <VerticalAligned>
          <Table
            rowClassName={(record) =>
              record.deletedAt ? "ant-typography ant-typography-disabled" : ""
            }
            components={{
              body: {
                cell: EditableRow,
              },
            }}
            dataSource={taxNumberDataSource}
            columns={mergedColumns}
            pagination={false}
          />
          {hasTaxAdvisoryPermissionScope && (
            <div style={{ display: "flex", justifyContent: "end" }}>
              <Button disabled={editingKey !== null} onClick={add}>
                + Hinzufügen
              </Button>
            </div>
          )}
        </VerticalAligned>
      </CustomerInformationCard>
      <CustomerInformationCard>
        <MissingTaxNumberTable
          missingBusinessTaxNumberNote={user.missingBusinessTaxNumberNote}
          missingPersonalTaxNumberNote={user.missingPersonalTaxNumberNote}
          hasBusinessTaxNumber={hasBusinessTaxNumber}
          hasPersonalTaxNumber={hasPersonalTaxNumber}
        ></MissingTaxNumberTable>
      </CustomerInformationCard>
    </Form>
  );
};

export default UserTaxNumbersList;
