import { useCallback, useEffect, useRef } from "react";

/**
 * Hook to check if component is (still) mounted.
 *
 * example usage:
 *
 * const isMounted = useMountedState();
 * // stuff happens
 * if (isMounted()) {
 * // do component related stuff, e.g update component state
 * }
 *
 * @return - true if component is mounted else false
 */
export default function useMountedState(): () => boolean {
  const mountedRef = useRef<boolean>(false);
  const get = useCallback(() => mountedRef.current, []);

  useEffect(() => {
    mountedRef.current = true;

    return () => {
      mountedRef.current = false;
    };
  });

  return get;
}
