import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, Row, Typography } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";

import UserFilters, {
  UserFilterOptions,
} from "../Mandanten/Common/UserFilters";
import useEmailParam from "../../hooks/useEmailParam";
import { ContentWrapper } from "../../common/styledComponents";
import { destroyMessage, isEmail, showLoadingMessage } from "../../../utils";
import CreateTaxServiceModal from "./createTaxServiceModal";
import { TaxServiceContainer } from "./styledComponent";
import api from "../../../api";
import { IUser } from "../../../types";
import { useTaxServicesQuery } from "../../../api/graphql/queries/taxService/taxServices.generated";
import { getCurrentUserEmail } from "../../../gapi";
import TaxServicesTable from "./TaxServicesTable";

const { Title, Text } = Typography;

const LOADING_MESSAGE_KEY = "finding-user";

const getTexts = (t: TFunction<"ServiceTracker", undefined>) => ({
  headline: t("serviceTracker.headline"),
  noClientSelectedWarning: t("serviceTracker.noClientSelectedWarning"),
  ctaAddNewService: t("serviceTracker.ctaAddNewService"),
});

const TaxServiceView = () => {
  const { t } = useTranslation("ServiceTracker");
  const texts = getTexts(t);

  const [emailParam, setEmailParam] = useEmailParam();
  const [searchParam, setSearchParam] = useState<string | null | undefined>(
    emailParam
  );
  const [filterOptions, setFilterOptions] = useState<UserFilterOptions>({
    search: searchParam || "",
    all: false,
    myCases: false,
  });
  const [user, setUser] = useState<IUser | null>(null);
  const [showCreateTaxServiceModal, setShowCreateTaxServiceModal] =
    useState(false);

  const { data: queryResult, refetch: refetchTaxServices } =
    useTaxServicesQuery({
      skip: !filterOptions.search.length && !filterOptions.myCases,
      variables: {
        filter: {
          email: filterOptions.search,
          createdBy: filterOptions.myCases ? getCurrentUserEmail() : "",
        },
      },
      fetchPolicy: "network-only",
      nextFetchPolicy: "cache-first",
    });
  const taxServices = queryResult?.taxServices;

  const fetchUser = useCallback(async (email: string) => {
    if (!email) {
      return;
    }
    showLoadingMessage(LOADING_MESSAGE_KEY);
    try {
      const { user } = await api.kontax.getVatMeta(email);
      setUser(user);
    } catch (error) {
      setUser(null);
    } finally {
      destroyMessage(LOADING_MESSAGE_KEY);
    }
  }, []);

  useEffect(() => {
    if (emailParam) {
      fetchUser(emailParam);
    } else {
      setUser(null);
    }
  }, [emailParam, fetchUser]);

  return (
    <ContentWrapper>
      <UserFilters
        withoutLoadAll
        withMyCases
        filterOptions={filterOptions}
        setFilterOptions={(
          updatedFilterOptions: Partial<UserFilterOptions>
        ) => {
          if (!updatedFilterOptions.all) {
            setSearchParam(updatedFilterOptions.search);
            isEmail(updatedFilterOptions.search || "")
              ? setEmailParam(updatedFilterOptions.search)
              : setEmailParam(undefined);
          }
          setFilterOptions({
            ...filterOptions,
            ...updatedFilterOptions,
          });
        }}
      />
      <TaxServiceContainer>
        <Title level={3}>{texts.headline}</Title>
        {!user && <Text type="secondary">{texts.noClientSelectedWarning}</Text>}
        <Row justify="end">
          <Col>
            <Button
              icon={<PlusOutlined />}
              type="primary"
              disabled={!user}
              onClick={() => {
                setShowCreateTaxServiceModal(true);
              }}
            >
              {texts.ctaAddNewService}
            </Button>
          </Col>
        </Row>
        <br />
        <Row>
          <TaxServicesTable
            taxServices={taxServices}
            onRefresh={refetchTaxServices}
          />
        </Row>
        <CreateTaxServiceModal
          user={user}
          visible={showCreateTaxServiceModal}
          onClose={() => {
            setShowCreateTaxServiceModal(false);
          }}
          onSuccess={refetchTaxServices}
        />
      </TaxServiceContainer>
    </ContentWrapper>
  );
};

export default TaxServiceView;
