import { gql, QueryHookOptions, useQuery } from "@apollo/client";

import { DeclarationDeclineFragmentDoc } from "../../fragments/declarationDecline.generated";
import { NoteDataFragmentDoc } from "../../fragments/note.generated";
import { EuerDeclaration, EuerDeclarationSubformOverview } from "../../types";

export interface EuerDeclarationOverviewQueryVariables {
  email: string;
  year: number;
}

export interface EuerDeclarationOverviewQueryResult {
  euerDeclaration: EuerDeclaration;
  businessAssetsSubform: EuerDeclarationSubformOverview;
  carUsageSubform: EuerDeclarationSubformOverview;
  officeUsageSubform: EuerDeclarationSubformOverview;
  travelExpensesSubform: EuerDeclarationSubformOverview;
}

export const EUER_DECLARATION_OVERVIEW_QUERY = gql`
  query euerDeclarationOverview($email: String!, $year: Int!) {
    euerDeclaration(email: $email, year: $year) {
      id
      status
      statusUpdatedAt
      submittedAt
      fields
      lastStatusChange {
        status
        changedAt
        changedBy
      }
      submissions {
        submissionAgent
        submissionDate
        pdf
        calculationSheet
      }
      submissionInfo {
        submissionAgent
        submissionDate
        externalAssets {
          url
        }
      }
      savedDraftInfo {
        createdBy
        createdAt
        updatedAt
        externalAssets {
          url
          createdAt
        }
      }
      changeLogs {
        changes
        changedAt
        changedBy
      }
      notes {
        ...noteData
      }
      declarationDeclines {
        ...DeclarationDecline
      }
    }

    businessAssetsSubform: euerDeclarationSubform(
      email: $email
      year: $year
      type: BUSINESS_ASSETS
    ) {
      type
      status
      statusUpdatedAt
      lastStatusChange {
        status
        changedAt
        changedBy
      }
    }

    carUsageSubform: euerDeclarationSubform(
      email: $email
      year: $year
      type: CAR_USAGE
    ) {
      type
      status
      statusUpdatedAt
      lastStatusChange {
        status
        changedAt
        changedBy
      }
    }

    officeUsageSubform: euerDeclarationSubform(
      email: $email
      year: $year
      type: OFFICE_USAGE
    ) {
      type
      status
      statusUpdatedAt
      lastStatusChange {
        status
        changedAt
        changedBy
      }
    }

    travelExpensesSubform: euerDeclarationSubform(
      email: $email
      year: $year
      type: TRAVEL_EXPENSES
    ) {
      type
      status
      statusUpdatedAt
      lastStatusChange {
        status
        changedAt
        changedBy
      }
    }
  }
  ${NoteDataFragmentDoc}
  ${DeclarationDeclineFragmentDoc}
`;

export const useEuerDeclarationOverviewQuery = (
  options?: QueryHookOptions<
    EuerDeclarationOverviewQueryResult,
    EuerDeclarationOverviewQueryVariables
  >
) =>
  useQuery<
    EuerDeclarationOverviewQueryResult,
    EuerDeclarationOverviewQueryVariables
  >(EUER_DECLARATION_OVERVIEW_QUERY, options);
