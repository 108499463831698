export const profitAndLossData = {
  en: [
    "Transfer of data from your Kontist bank account",
    "Transfer of data from Debitoor",
    "Transfer of data from lexoffice",
    "Transfer of data from sevDesk",
    "Transfer of data from provided accounting records",
    "Transfer of data from provided bookkeeping data",
    "Transfer of data from the Drive folder",
    "Transfer of data from provided Excel sheet",
    "Checking the received data for plausibility",
    "Extensive reconciliation of operating income and expenses",
    "Reconciliation of the private use of your car",
    "Reconciliation of business trips by car",
    "Reconciliation of the study room",
    "Reconciliation of the Home Office flat rate",
    "Reconciliation of additional expenses for meals",
    "Verification of account movements as well as the cash office",
    "Reconciliation of payments made and received on the bank account",
    "Additional adjustment postings:",
    "Recording of depreciations",
    "Reconciliation and adjustment of tax payments with the tax office data",
  ],
  de: [
    "Übernahme der Daten von deinem Kontist-Konto",
    "Übernahme der Daten von Debitoor",
    "Übernahme der Daten von lexoffice",
    "Übernahme der Daten von sevDesk",
    "Übernahme der Daten von bereitgestellten Buchhaltungsunterlagen",
    "Übernahme der Daten aus dem Drive-Ordner",
    "Übernahme der Daten aus bereitgestellter Excel-Tabelle",
    "Prüfung der erhaltenen Daten auf Plausibilität",
    "Umfangreiche Abstimmung der Betriebseinnahmen und -ausgaben",
    "Berücksichtigung der privaten Nutzung deines Pkws",
    "Berücksichtigung der betrieblichen Fahrten mit dem Pkw",
    "Berücksichtigung des Arbeitszimmers",
    "Berücksichtigung der Home Office-Pauschale",
    "Berücksichtigung der Verpflegungsmehraufwendungen",
    "Abstimmung der Bewegung bei den Banken sowie der Kasse",
    "Abstimmung der gezahlten und erhaltenen Zahlungen auf dem Bankkonto",
    "Zusätzliche Korrekturbuchungen:",
    "Erfassung von Abschreibungen",
    "Abstimmung und Anpassung von Steuerzahlungen mit den Daten vom Finanzamt",
  ],
};

export const otherThingsData = {
  en: [
    "Parental allowance",
    "Income from employment",
    "Income from employment (spouse)",
    "Income from renting and leasing (Annex V)",
    "Investment income (Annex KAP)",
    "Contributions to health and nursing care insurance",
    "Contributions to liability insurance",
    "Expenses of a provident nature",
    "Household-related services",
    "Craftsman services",
    "Donations",
  ],
  de: [
    "Elterngeld",
    "Einkünfte aus nichtselbstständiger Arbeit",
    "Einkünfte aus nichtselbstständiger Arbeit (Ehepartner)",
    "Einkünfte aus Vermietung und Verpachtung (Anlage V)",
    "Kapitaleinkünfte (Anlage KAP)",
    "Beiträge zur Kranken- sowie Pflegeversicherung",
    "Beiträge zur Haftpflichtversicherung",
    "Vorsorgeaufwendungen",
    "Haushaltsnahe Dienstleistungen",
    "Handwerkerleistungen",
    "Spenden",
  ],
};
