import React, { useCallback, useMemo } from "react";
import { Spin } from "antd";

import {
  VatAnnualDeclaration,
  VatAnnualDeclarationAutoCalculatedValues,
} from "../../../../../../api/graphql";
import { SECTION_ROW_CONFIGS, SECTION_TITLES } from "../constants";
import { SpinContainer } from "./styledComponents";
import VatAnnualDeclarationBaseTable, {
  VatAnnualDeclarationSectionRow,
} from "../VatAnnualDeclarationBaseTable";
import { calculateVatAnnualDeclarationGrandTotal } from "../utils";
import { VatAnnualDeclarationPeriodType } from "../../../types";

export interface VatAnnualDeclarationTableProps {
  email: string;
  taxYear: number;
  period: VatAnnualDeclarationPeriodType;
  autoCalculatedValues: VatAnnualDeclarationAutoCalculatedValues | null;
  declaration: VatAnnualDeclaration;
  onChangeDeclaration: (
    declarationChange: Partial<VatAnnualDeclaration>
  ) => void;
}

const VatAnnualDeclarationTable = ({
  email,
  taxYear,
  period,
  autoCalculatedValues,
  declaration: { taxOfficeBalance },
  onChangeDeclaration,
}: VatAnnualDeclarationTableProps) => {
  const sections: VatAnnualDeclarationSectionRow[] = useMemo(() => {
    if (!autoCalculatedValues) {
      return [];
    }

    return [
      {
        ...SECTION_ROW_CONFIGS.SALES_TAX,
        ...autoCalculatedValues.salesTax,
      },
      {
        ...SECTION_ROW_CONFIGS.DEDUCTIBLE_INPUT_TAX,
        ...autoCalculatedValues.deductibleInputTax,
      },
      {
        ...SECTION_ROW_CONFIGS.TAX_OFFICE_BALANCE,
        fields: [],
        total: taxOfficeBalance,
      },
      {
        ...SECTION_ROW_CONFIGS.ADDITIONAL_INFORMATION,
        ...autoCalculatedValues.additionalInformation,
      },
    ];
  }, [taxOfficeBalance, autoCalculatedValues]);

  const grandTotal = calculateVatAnnualDeclarationGrandTotal(
    autoCalculatedValues,
    taxOfficeBalance
  );

  const handleSectionChange = useCallback(
    (section: VatAnnualDeclarationSectionRow) => {
      if (section.title === SECTION_TITLES.TAX_OFFICE_BALANCE) {
        onChangeDeclaration({ taxOfficeBalance: section.total });
      }
    },
    [onChangeDeclaration]
  );

  if (!autoCalculatedValues) {
    return (
      <SpinContainer>
        <Spin />
      </SpinContainer>
    );
  }

  return (
    <VatAnnualDeclarationBaseTable
      email={email}
      taxYear={taxYear}
      period={period}
      sections={sections}
      onChangeSection={handleSectionChange}
      grandTotal={grandTotal}
    />
  );
};

export default VatAnnualDeclarationTable;
