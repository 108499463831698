import React, {
  ChangeEvent,
  ReactNode,
  useCallback,
  useRef,
  useState,
} from "react";
import { useEffect } from "react";
import { Col, Input, Button, InputRef } from "antd";

import api from "../../../../../api";
import UserInfoExcerpt from "../../../../common/UserInfoExcerpt";
import { IUser, VatMetaNote, ExcerptData } from "../../../../../types";
import useEmailParam from "../../../../hooks/useEmailParam";
import { UserContext } from "../../contexts/UserContext";
import {
  RowWithDivider,
  UserDetails,
  UserName,
  UserEmail,
} from "./styledComponents";
import { destroyMessage, showLoadingMessage } from "../../../../../utils";
import useSearchHotkey from "../../../../hooks/useSearchHotkey";
import { UserInfoDrawerSources } from "../../../../common/UserInfoExcerpt/UserInfoDrawer";

const { Search } = Input;

const PLACEHOLDER = "––";

export interface UserSearchProps {
  children: ReactNode;
}

const LOADING_MESSAGE_KEY = "finding-user";

const UserSearch = ({ children }: UserSearchProps) => {
  const [emailFromQueryParams, setEmailToQueryParams] = useEmailParam();
  const [emailFromInput, setEmailToInput] = useState("");
  const searchRef = useRef<InputRef>(null);
  useSearchHotkey(searchRef);
  const [user, setUser] = useState<IUser | null>(null);
  const [notes, setNotes] = useState<VatMetaNote[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const resetUser = useCallback(() => {
    setUser(null);
    setEmailToQueryParams(undefined); // pass undefined to remove query param from url
  }, [setEmailToQueryParams]);

  const fetchUser = useCallback(
    async (email: string) => {
      if (!email) {
        resetUser();
        return;
      }

      setIsLoading(true);
      showLoadingMessage(LOADING_MESSAGE_KEY);

      try {
        const { user, notes } = await api.kontax.getVatMeta(email);

        setUser(user);
        setNotes(notes);
        setEmailToQueryParams(email);
      } catch (error) {
        resetUser();
      }

      setIsLoading(false);
      destroyMessage(LOADING_MESSAGE_KEY);
    },
    [resetUser, setEmailToQueryParams]
  );

  useEffect(() => {
    if (emailFromQueryParams) {
      fetchUser(emailFromQueryParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // sync value from query params only once during initiation

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmailToInput(event.target.value);
  };

  const handleSearchButtonClick = () => {
    if (isLoading) {
      return;
    }

    fetchUser(emailFromInput);
    setEmailToInput("");
  };

  const renderExcerptData = (excerptData: ExcerptData) => {
    if (excerptData?.user)
      return (
        <UserName>
          {user ? `${user.firstName} ${user.lastName}` : PLACEHOLDER}
          <Button type="link">View user info</Button>
        </UserName>
      );
    return <></>;
  };

  return (
    <>
      <RowWithDivider>
        <Col flex="auto">
          <UserDetails>
            <UserInfoExcerpt
              user={user}
              notes={notes}
              source={UserInfoDrawerSources.TAX}
              renderExcerptData={(excerptData) =>
                renderExcerptData(excerptData)
              }
            />

            <UserEmail>{user?.email || PLACEHOLDER}</UserEmail>
          </UserDetails>
        </Col>
        <Col flex="400px">
          <Search
            ref={searchRef}
            id="email"
            placeholder="Mandanten-E-Mail eingeben"
            value={emailFromInput}
            enterButton
            size="middle"
            onChange={handleInputChange}
            disabled={isLoading}
            onSearch={handleSearchButtonClick}
          />
        </Col>
      </RowWithDivider>
      <UserContext.Provider value={user}>{children}</UserContext.Provider>
    </>
  );
};

export default UserSearch;
