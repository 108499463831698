import React, { useCallback, useState, useEffect } from "react";

import { notification } from "antd";

import { IUser, IUserDetails } from "../../../../../types";
import api from "../../../../../api";
import { UserDetailsBlock } from "../../styledComponents";
import UserTaxInformationBlock from "../../../../common/UserTaxInformationBlock";
import { UpsertAccountingSourcePayload } from "../../../../../types/AccountingSource.type";
import {
  useRemoveAccountingSourceMutation,
  useUpsertAccountingSourceMutation,
} from "../../../../../api/graphql/mutations/accountingSource";

const UserDetails = ({ user: initialUser }: { user: IUser | null }) => {
  const [user, setUser] = useState<IUser | null>(initialUser);
  const [upsertAccountingSourceMutation] = useUpsertAccountingSourceMutation();
  const [removeAccountingSourceMutation] = useRemoveAccountingSourceMutation();

  useEffect(() => {
    setUser(initialUser);
  }, [initialUser]);

  const editUser = useCallback(
    async (email: string, userDetails: IUserDetails) => {
      try {
        const updatedUser = await api.kontax.updateUser({
          email,
          ...userDetails,
        });
        setUser({ ...user, ...updatedUser });
      } catch (error) {
        notification.error({
          message: "Failed updating user.",
        });
      }
    },
    [user]
  );

  const upsertAccountingSource = useCallback(
    async (email: string, payload: UpsertAccountingSourcePayload) => {
      try {
        const mutationResult = await upsertAccountingSourceMutation({
          variables: { email, payload },
        });

        const result = mutationResult.data?.upsertAccountingSource;

        if (result) {
          const accountingSources = user?.kontaxUser?.accountingSources && [
            ...user.kontaxUser.accountingSources.filter(
              (accountingSource) => accountingSource.year !== payload.year
            ),
            result,
          ];

          const kontaxUser = user?.kontaxUser && {
            ...user.kontaxUser,
            accountingSources,
          };

          const currentUser = user && {
            ...user,
            kontaxUser,
          };

          setUser(currentUser);
        }
      } catch (error) {
        notification.error({
          message: `Error occurred while saving accounting source for the year ${payload.year}`,
        });
      }
    },
    [user, upsertAccountingSourceMutation]
  );

  const removeAccountingSource = useCallback(
    async (email: string, year: number) => {
      try {
        await removeAccountingSourceMutation({
          variables: { email, year },
        });

        const accountingSources = user?.kontaxUser?.accountingSources?.filter(
          (accountingSource) => accountingSource.year !== year
        );

        const kontaxUser = user?.kontaxUser && {
          ...user.kontaxUser,
          accountingSources,
        };

        const currentUser = user && {
          ...user,
          kontaxUser,
        };
        setUser(currentUser);
      } catch (error) {
        notification.error({
          message: `Error occurred while removing accounting source for the year ${year}`,
        });
      }
    },
    [user, removeAccountingSourceMutation]
  );

  if (!user) return null;

  return (
    <UserDetailsBlock>
      <UserTaxInformationBlock
        user={user}
        editUser={editUser}
        upsertAccountingSource={upsertAccountingSource}
        removeAccountingSource={removeAccountingSource}
      />
    </UserDetailsBlock>
  );
};

export default UserDetails;
