import React from "react";
import { useTable, useExpanded, useSortBy, usePagination } from "react-table";
import Table from "react-bootstrap/Table";

import { Styles, PaginationText } from "./styledComponents";
import { RowProps } from "../../../types";

const getSortingProps = (disableSorting: boolean, column: any) => {
  return disableSorting
    ? null
    : { ...column.getHeaderProps(column.getSortByToggleProps()) };
};

const ReactTable = ({
  columns,
  data,
  renderRowSubComponent,
  isRowClickable = false,
  getRowProps = () => {},
  getCellProps = () => {},
  hasPagination = false,
  disableSorting = false,
  defaultSorted = [],
  tableName = "table",
}: any) => {
  const options: any = {
    columns,
    data,
    autoResetSortBy: false,
    autoResetExpanded: false,
    initialState: {
      pageSize: 100,
      sortBy: defaultSorted,
      expanded: {
        // expand row by default if there is only one row in the table
        "0": data.length === 1,
      },
    },
  };

  const {
    rows,
    page,
    prepareRow,
    headerGroups,
    getTableProps,
    getTableBodyProps,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  }: any = useTable(options, useSortBy, useExpanded, usePagination);

  const totalUsersAmount = data.length;
  const firstRowIndexOnPage = pageIndex * pageSize + 1;
  const lastRowIndexOnPage = Math.min(
    (pageIndex + 1) * pageSize,
    totalUsersAmount
  );

  return (
    <Styles isRowClickable={isRowClickable}>
      <Table data-test={tableName} {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup: any, headerGroupsIndex: number) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={headerGroupsIndex}>
              {headerGroup.headers.map((column: any, index: number) => (
                <th
                  data-test={column.Header}
                  key={index}
                  {...getSortingProps(disableSorting, column)}
                >
                  {column.render("Header")}
                  <span data-test={`${column.Header}-sort`}>
                    {column.isSorted ? (column.isSortedDesc ? " ▼" : " ▲") : ""}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {(hasPagination ? page : rows).map((row: RowProps, index: number) => {
            prepareRow(row);
            return (
              <React.Fragment key={index}>
                <tr
                  data-test={`${tableName}-row-${index}`}
                  {...row.getRowProps(getRowProps(row))}
                >
                  {row.cells.map((cell: any, cellIndex: number) => {
                    return (
                      <td
                        {...cell.getCellProps(getCellProps(cell))}
                        key={cellIndex}
                        data-test={`${tableName}-row-${index}-col${cellIndex}`}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
                {row.isExpanded ? (
                  <tr>
                    <td colSpan={row.cells.length}>
                      {renderRowSubComponent({ row })}
                    </td>
                  </tr>
                ) : null}
              </React.Fragment>
            );
          })}
        </tbody>
      </Table>
      {hasPagination && (
        <div className="pagination">
          <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            {"<<"}
          </button>
          <button onClick={() => previousPage()} disabled={!canPreviousPage}>
            {"<"}
          </button>
          <button onClick={() => nextPage()} disabled={!canNextPage}>
            {">"}
          </button>
          <button
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            {">>"}
          </button>
          <PaginationText>
            Showing
            <strong>
              {` ${firstRowIndexOnPage} to ${lastRowIndexOnPage} of ${totalUsersAmount} `}
            </strong>
            {`users | Go to page: `}
            <input
              type="number"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
              style={{ width: "100px" }}
            />
          </PaginationText>
          <select
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[50, 75, 100].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </div>
      )}
    </Styles>
  );
};

export default ReactTable;
