import { Space, Typography } from "antd";
import React from "react";

import { TradeTaxDeclaration } from "../../../../../../../api/graphql";

import { ContentWrapper } from "../../styledComponents";
import TradeTaxDeclarationCalculationList from "./TradeTaxDeclarationCalculationList";
import TradeTaxDeclarationCalculationField from "./TradeTaxDeclarationCalculationField";

const { Text, Paragraph } = Typography;

export interface TradeTaxDeclarationCalculationProps {
  tradeTaxDeclaration: TradeTaxDeclaration;
  onChange: (value: number | null) => Promise<void>;
}

const TradeTaxDeclarationCalculation = ({
  tradeTaxDeclaration,
  onChange,
}: TradeTaxDeclarationCalculationProps) => {
  const { autoCalculatedValues } = tradeTaxDeclaration;
  return (
    <Space direction="vertical" size={60} style={{ width: "100%" }}>
      <ContentWrapper>
        <Paragraph>
          <Text strong>Gewinn</Text>
        </Paragraph>

        <TradeTaxDeclarationCalculationField
          tag="10"
          label="Gewinn aus Gewerbebetrieb vor Anwendung des § 7 Satz 4 GewStG"
          amount={autoCalculatedValues.profit}
        />

        <Paragraph>
          <Text strong>Hinzurechnungen</Text>
        </Paragraph>

        <TradeTaxDeclarationCalculationField
          tag="31"
          label="Entgelte für Schulden (§ 8 Nummer 1 Buchstabe a GewStG)(Euro)"
          amount={autoCalculatedValues.additions.feesForDebts}
        />

        <TradeTaxDeclarationCalculationField
          tag="34"
          label="Miet- und Pachtzinsen (einschließlich Leasingraten) für die Benutzung fremder beweglicher Betriebsanlagegüter (§ 8 Nummer 1 Buchstabe d Satz 1 GewStG) (ohne Beträge laut 44a)"
          amount={
            autoCalculatedValues.additions
              .movableBusinessAssetsRentOrLeaseInterest
          }
        />

        <TradeTaxDeclarationCalculationField
          tag="35"
          label="Miet- und Pachtzinsen (einschließlich Leasingraten) für die Benutzung fremder unbeweglicher Betriebsanlagegüter (§ 8 Nummer 1 Buchstabe e GewStG)"
          amount={
            autoCalculatedValues.additions
              .immovableBusinessAssetsRentOrLeaseInterest
          }
        />

        <TradeTaxDeclarationCalculationField
          tag="36"
          label="Aufwendungen für die zeitlich befristete Überlassung von Rechten - insbesondere Konzessionen und Lizenzen - (§ 8 Nummer 1 Buchstabe f GewStG)"
          amount={autoCalculatedValues.additions.softwareAndLicenses}
        />
      </ContentWrapper>
      <TradeTaxDeclarationCalculationList
        calculations={autoCalculatedValues.calculations}
        onChange={onChange}
      />
    </Space>
  );
};

export default TradeTaxDeclarationCalculation;
