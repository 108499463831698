import { ChangeEvent } from "react";
import isEmpty from "lodash/isEmpty";
import isNumber from "lodash/isNumber";
import isEqual from "lodash/isEqual";
import maxBy from "lodash/maxBy";
import max from "lodash/max";
import min from "lodash/min";
import omit from "lodash/omit";
import range from "lodash/range";
import fill from "lodash/fill";
import calculateDepreciation from "asset-depreciation-calculator";

import { formatDayMonthYear } from "../../../Common/UserDetails/utils";
import { formatDEAmount } from "../../../../../..//utils";

import {
  IBusinessAsset,
  IFormattedBusinessAsset,
  DepreciableCategory,
  BusinessAssetType,
  IDepreciation,
  BEExitReason,
} from "../../../../../../types";
import { EditFormState } from "./types";
import { EditMode } from "./constants";
import immovableAssets from "../../../../../BusinessAsset/immovableAssets";
import movableAssets from "../../../../../BusinessAsset/movableAssets.json";
import { CUSTOM_ASSET_CLASS } from "../../../../../BusinessAsset/constants";
import { ExitReason } from "../../../../../../api/graphql/schema.generated";
import { isNoteRequired } from "../../../../../BusinessAsset/utils";

/**
 * "Manual" date parsing and conversion from string to avoid timezone issues.
 * It relies on the format YYYY-MM-DD.
 */
export const getDateFromString = (date: string) => {
  const [year, month, day] = date.split("-");
  return new Date(Number(year), Number(month) - 1, Number(day));
};

export const getUTCYear = (date?: string) =>
  date ? new Date(date).getUTCFullYear() : new Date().getUTCFullYear();

export const CURRENT_YEAR = getUTCYear();

export const translateExitReason = (reason: ExitReason) => {
  switch (reason) {
    case ExitReason.SOLD:
      return "Verkauft";
    case ExitReason.LOST:
      return "Ausscheiden";
    case ExitReason.PRIVATE_USE:
      return "Private Nutzung";
    case ExitReason.DEPRECIATED:
      return "Abgeschrieben";
    default:
      return "";
  }
};

/**
 * Formatter depends on the year user has selected (by default current yer is used).
 */
export const getFormattedBusinessAssets = (
  businessAssets: IBusinessAsset[],
  yearFilter: number
): IFormattedBusinessAsset[] => {
  const businessAssetsSnapshot = getBusinessAssetsSnapshot(
    businessAssets,
    yearFilter
  );

  return businessAssetsSnapshot.map(
    ({
      amount,
      purchaseDate,
      depreciations,
      naturallyDepreciated,
      endAmount,
      exitReason,
      exitDate,
      exitAmount,
      isExitedWithVat,
      bookValueOnExit,
      assetType,
      ...rest
    }) => {
      const generalInfo = {
        ...rest,
        amount: formatDEAmount(amount)!,
        purchaseDate: formatDayMonthYear(purchaseDate),
        depreciations,
        naturallyDepreciated,
        assetType: BusinessAssetType[assetType],
        exitReason,
        isExitedWithVat,
      };
      // The item is marked as "sold", "lost", or for "private use".
      // For these items, endAmount is always 0.
      if (exitDate && exitReason) {
        return {
          ...generalInfo,
          exitDate: formatDayMonthYear(exitDate),
          exitAmount: formatDEAmount(exitAmount),
          bookValueOnExit: formatDEAmount(bookValueOnExit),
          endAmount: formatDEAmount(0),
        };
      }

      const { depreciationAmount, startAmount } =
        depreciations.find(
          ({ year }: { year: number }) => year === yearFilter
        ) || {};

      // The item is completely depreciated.
      if (naturallyDepreciated && !depreciationAmount) {
        return {
          ...generalInfo,
          exitReason: ExitReason.DEPRECIATED,
          depreciationAmount: formatDEAmount(0),
          endAmount: formatDEAmount(0),
        };
      }

      return {
        ...generalInfo,
        depreciationAmount: formatDEAmount(depreciationAmount),
        endAmount: formatDEAmount(startAmount! - depreciationAmount!),
      };
    }
  );
};

// apollo client converts enums to upper snake case but we use lower case in the backend
export const mapExitReasonEnum = (exitReason: ExitReason): BEExitReason => {
  switch (exitReason) {
    case ExitReason.SOLD:
      return BEExitReason.SOLD;
    case ExitReason.LOST:
      return BEExitReason.LOST;
    case ExitReason.PRIVATE_USE:
      return BEExitReason.PRIVATE_USE;
    case ExitReason.DEPRECIATED:
      return BEExitReason.DEPRECIATED;
  }
};

export const shouldSaveButtonDisabled = ({
  originalBusinessAsset,
  editMode,
  exitReason,
  formFields,
}: {
  originalBusinessAsset: IFormattedBusinessAsset;
  editMode: EditMode;
  exitReason?: ExitReason;
  formFields: EditFormState;
}): boolean => {
  if (
    editMode === EditMode.NONE &&
    (originalBusinessAsset.note === formFields.note ||
      (isNoteRequired(formFields.assetType, formFields.assetClass) &&
        !formFields.note))
  ) {
    return true;
  }

  if (editMode === EditMode.EXIT) {
    if (
      exitReason === ExitReason.SOLD &&
      // When asset is sold there should be an exit date and exit amount should be greater than 0.
      (isEmpty(formFields.exitDate) || !formFields.exitAmount)
    ) {
      return true;
    }

    if (exitReason === ExitReason.LOST && isEmpty(formFields.exitDate)) {
      return true;
    }

    if (exitReason === ExitReason.PRIVATE_USE && isEmpty(formFields.exitDate)) {
      return true;
    }

    if (
      isNoteRequired(formFields.assetType, formFields.assetClass) &&
      !formFields.note
    ) {
      return true;
    }
  }

  if (editMode === EditMode.UPDATE) {
    if (
      isEmpty(formFields.assetClass) ||
      isEmpty(formFields.depreciationYears)
    ) {
      return true;
    }

    if (
      formFields.assetClass === CUSTOM_ASSET_CLASS &&
      isEmpty(formFields.assetClassCustom)
    ) {
      return true;
    }

    if (
      isNoteRequired(formFields.assetType, formFields.assetClass) &&
      !formFields.note
    ) {
      return true;
    }
  }

  return false;
};

export const getFormOptionClassName = (editMode: EditMode): string => {
  switch (editMode) {
    case EditMode.NONE:
      return "disabled";
    case EditMode.DELETE:
      return "danger";
    default:
      return "";
  }
};

export const getInputValue = (e: ChangeEvent<any>): string =>
  e?.target?.value || "";

export const lookupAssetClass = (
  assetClass: string
): { assetClass?: string; depreciationYears?: number | null } | undefined =>
  [...immovableAssets, ...movableAssets].find(
    (asset) => asset.assetClass === assetClass
  );

export const getDepreciationYears = (
  assetClass: string,
  category: string | null
): string => {
  // When category > 250 is selected, depreciationYears should always be 0 and non editable.
  // Asset is immediately depreciated.
  if (category === DepreciableCategory.OVER_250) {
    return "0";
  }

  const { depreciationYears } = lookupAssetClass(assetClass) || {};

  if (
    category === DepreciableCategory.OVER_800 &&
    isNumber(depreciationYears)
  ) {
    return depreciationYears.toString();
  }

  // This can happen for immovable assets or custom assets.
  // In both cases, depreciationYears is editable.
  return "";
};

export const isAssetType = (option: string): option is BusinessAssetType =>
  (Object.values(BusinessAssetType) as string[]).includes(option);

export const isEditMode = (option: string): option is EditMode =>
  (Object.values(EditMode) as string[]).includes(option);

export const isExitReason = (option: string): option is ExitReason =>
  (Object.values(ExitReason) as string[]).includes(option);
/**
 * It returns a snapshot of how the business assets the user had (or will have)
 * in the provided year and their depreciation status at that point.
 */
export const getBusinessAssetsSnapshot = (
  assets: IBusinessAsset[],
  year: number
): IBusinessAsset[] => {
  if (year === getUTCYear()) {
    return assets;
  }

  return (
    assets
      // Filter out assets bought after the provided year
      .filter(({ purchaseDate }) => {
        const purchaseYear = getUTCYear(purchaseDate);
        return purchaseYear <= year;
      })
      // Build the depreciation snapshot of the assets
      .map((asset) => {
        const { depreciations, exitReason, naturallyDepreciated } = asset;
        // Find the asset's last depreciation year without assuming depreciations array is ordered.
        const lastDepreciation = maxBy(depreciations, "year");
        // If the asset's last depreciation year equals or is greater than the provided year, no update is required.
        if (lastDepreciation && year >= lastDepreciation.year) {
          return {
            ...asset,
            // If the requested snapshot year is in the future & the asset wasn't exited, it has theoretically reached
            // the end of the depreciation process and should be set as naturally depreciated.
            ...(year > getUTCYear() && !exitReason && !naturallyDepreciated
              ? { naturallyDepreciated: true }
              : {}),
          };
        }

        const currentYearDepreciation = depreciations.find(
          ({ year: depreciationYear }) => depreciationYear === year
        );

        // Safety check.
        if (!currentYearDepreciation) {
          return asset;
        }

        // When last year of depreciation "hasn't happened" yet (but exists)
        return {
          ...omit(asset, ["bookValueOnExit", "exitDate", "exitReason"]),
          naturallyDepreciated: false,
        };
      })
  );
};

/**
 * Builds a list of years that starts from the first purchased good until the year the last business asset
 * finalizes (or finalized) its depreciation process (or the current year if the last depreciation happens before that).
 */
export const getYearsList = (businessAssets: IBusinessAsset[]): number[] => {
  const currentYear = getUTCYear();
  // If no business assets are provided build a list with the current year only.
  if (!businessAssets || !businessAssets.length) {
    return [currentYear];
  }

  const purchaseYears = businessAssets.map(({ purchaseDate }) =>
    getUTCYear(purchaseDate)
  );
  const start = min(purchaseYears) || currentYear;

  const lastDepreciationsYears = businessAssets.map(
    ({ depreciations }) => (maxBy(depreciations, "year") || {}).year
  );
  let end = max(lastDepreciationsYears);

  // if last business asset event (purchase/depreciation) happened before current year,
  // set the end to the current year.
  if (!end || end < currentYear) {
    end = currentYear;
  }

  // The +1 is due to range not including the end number.
  return range(start, end + 1);
};

type Validation = { valid: boolean; error?: string };
/**
 * Verifies that the contained values in the endAmounts array are valid and coherent.
 */
export const validateEndAmountValues = ({
  endAmounts,
  purchaseAmount,
  depreciationYears,
}: {
  endAmounts: number[];
  purchaseAmount: number;
  depreciationYears: string;
}): Validation => {
  // If depreciation years equals 0, end amounts is ignored.
  if (Number(depreciationYears) === 0) {
    return { valid: true };
  }

  if (endAmounts[endAmounts.length - 1] !== 0) {
    return {
      valid: false,
      error: "Der Buchwert im letzten Jahr der Abschreibung muss Null sein",
    };
  }

  if (endAmounts[0] >= purchaseAmount) {
    return {
      valid: false,
      error:
        "Buchwert am Ende des Jahres mal geringer sein als der Anschaffungswert",
    };
  }

  const invalidValues = endAmounts.some(
    (endAmount, index) =>
      // End amount must be an integer, it represents cents and should never be negative.
      isNaN(endAmount) ||
      !Number.isInteger(endAmount) ||
      endAmount < 0 ||
      // No endAmount should be greater or equal than its previous.
      (index > 0 && endAmount >= endAmounts[index - 1])
  );

  return invalidValues
    ? { valid: false, error: "Buchwert am Ende des Jahres nicht korrekt" }
    : { valid: true };
};

/**
 * Returns the new end amounts depending on current form status (calculating automatically when needed, and
 * returning `null` when no change should be done).
 */
export const getNewEndAmounts = ({
  amount,
  endAmounts,
  purchaseDate,
  depreciationYears,
  automaticEndAmounts,
  isPartialAutomaticEndAmountsEnabled,
}: {
  amount: number;
  endAmounts: number[];
  purchaseDate: string;
  depreciationYears: string;
  automaticEndAmounts: boolean;
  isPartialAutomaticEndAmountsEnabled: boolean;
}): number[] | null => {
  // Calculate depreciations and amount of years based on the required information
  const depreciations: IDepreciation[] = calculateDepreciation({
    purchaseAmount: amount,
    purchaseDate: getDateFromString(purchaseDate),
    totalDepreciationYears: Number(depreciationYears),
  });

  // The size of the end amounts array is decided based on the calculated depreciations.
  // If calculation is set to automatic, values are taken from those calculated depreciations.
  if (automaticEndAmounts) {
    return depreciations.map(
      ({ startAmount, depreciationAmount }) => startAmount - depreciationAmount
    );
  }

  // If purchase date year was before current year and user has chosen to calculate the current and
  // future years automatically (partialAutomaticEndAmounts), calculation happens based on the last
  // end amount input.
  if (
    !isEqual(endAmounts, []) &&
    getUTCYear(purchaseDate) < CURRENT_YEAR &&
    isPartialAutomaticEndAmountsEnabled
  ) {
    // Calculate the amount of depreciation years that happened before the current year
    const yearsBeforeCurrent = depreciations
      .filter(({ year }) => year < CURRENT_YEAR)
      .reduce((sum) => sum + 1, 0);

    if (yearsBeforeCurrent >= depreciations.length) {
      return null;
    }

    // Years before current are input manually, current and following years are calculated
    // automatically. So the initial amount of the calculation is taken from the last manual
    // end amount input, the purchase date set to the beginning of the current year (to prevent
    // an extra depreciation year) and the total depreciation years is equal to the calculated years
    // minus the years that are input manually (those before the current year).
    const partialDepreciations: IDepreciation[] = calculateDepreciation({
      purchaseAmount: endAmounts[yearsBeforeCurrent - 1],
      purchaseDate: new Date(`01/01/${CURRENT_YEAR}`),
      totalDepreciationYears: depreciations.length - yearsBeforeCurrent,
    });

    // Once automatic depreciations are calculated, we calculate the end amounts from them.
    const partialEndAmounts = partialDepreciations.map(
      ({ startAmount, depreciationAmount }) => startAmount - depreciationAmount
    );

    // Resulting end amounts is the union of the manually provided end amounts and those
    // calculated automatically.
    return [...endAmounts.slice(0, yearsBeforeCurrent), ...partialEndAmounts];
  }

  // When all required information was provided, no automatic calculation needs to be done
  // and end amounts were not yet set, fill the end amounts with 0
  if (isEqual(endAmounts, []) || depreciations.length !== endAmounts.length) {
    return fill(Array(depreciations.length), 0);
  }

  // No changes are required when user turns off the automatic calculation.
  return null;
};
