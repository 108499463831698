import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { SentryLink } from "apollo-link-sentry";

import config from "../../config";
import { getToken } from "../../gapi";
import { showErrorNotification } from "../../utils";

const authLink = setContext((_, { headers }) => {
  // Get the authentication token if it exists
  const token = getToken() || "";
  // Return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      /*
        TODO: remove "X-Auth-Token" header. Our GQL api currently sits behind our Express REST API /api/kontax.
        Our kontax REST APIs are using the "X-Auth-Token" header for authentication, but as we migrate to GQL we
        are going to start using the more standard `Bearer token` authorization header for OAuth2.
        To prevent the requests from failing due to the REST API authentication and also prevent us from using
        a separate GQL path (currently /api/kontax/graphql) we are leaving "X-Auth-Token" temporarily until we fully
        migrate.
      */
      "X-Auth-Token": token,
      authorization: `Bearer ${token}`,
    },
  };
});

const httpLink = createHttpLink({
  uri: `${config.REACT_APP_API_URL}/api/kontax/graphql`,
});

/**
 * Display a generic error message when any GraphQL errors or network error occurred
 * while running a query.
 */
const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  const operationDefinition = operation.query.definitions.find(
    ({ kind }) => kind === "OperationDefinition"
  );
  if (
    operationDefinition &&
    "operation" in operationDefinition &&
    operationDefinition.operation === "query" &&
    (graphQLErrors || networkError)
  ) {
    showErrorNotification({
      // There was an error while retrieving some information
      message: "Beim Abrufen einiger Informationen ist ein Fehler aufgetreten",
    });
  }
});

const client = new ApolloClient({
  link: ApolloLink.from([
    new SentryLink({
      attachBreadcrumbs: {
        includeError: true,
        includeQuery: true,
        includeVariables: true,
      },
    }),
    authLink,
    errorLink,
    httpLink,
  ]),
  cache: new InMemoryCache(),
  defaultOptions: {
    // Cache should be explicitly enabled per query
    query: { fetchPolicy: "no-cache" },
    watchQuery: { fetchPolicy: "no-cache" },
  },
});

export default client;
