import React, { useRef, useState } from "react";
import { Input, Button, Col, InputRef } from "antd";

import useSearchHotkey from "../../../../hooks/useSearchHotkey";
import { StyledRow } from "./styledComponents";

const { Search } = Input;

export interface UserFilterOptions {
  search: string;
  all: boolean;
  myCases: boolean;
}

const UserFilters = ({
  filterOptions,
  setFilterOptions,
}: {
  filterOptions: UserFilterOptions;
  setFilterOptions: (filterOptions: Partial<UserFilterOptions>) => void;
}) => {
  const { search } = filterOptions;
  const [searchValue, setSearchValue] = useState(search);
  const searchRef = useRef<InputRef>(null);

  useSearchHotkey(searchRef);

  return (
    <StyledRow gutter={8}>
      <Col flex="auto" xl={8}>
        <Search
          ref={searchRef}
          value={searchValue}
          onChange={(e) => {
            setSearchValue(e.target.value);
          }}
          onSearch={(value: string) => {
            setFilterOptions({
              search: value,
              all: false,
              myCases: false,
            });
          }}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
            }
          }}
          id="search"
          data-test="searchBar"
          placeholder="Account ID, last block fed. tax number, name or Email"
          allowClear
          enterButton="Filter"
        />
      </Col>
      <Col>
        <Button
          type="default"
          data-test="loadAll"
          onClick={(event) => {
            event.preventDefault();
            setFilterOptions({ all: true, search: "", myCases: false });
            setSearchValue("");
          }}
        >
          Load all
        </Button>
      </Col>
      <Col>
        <Button
          type="default"
          data-test="myCases"
          onClick={(event) => {
            event.preventDefault();
            setFilterOptions({ myCases: true, all: false, search: "" });
          }}
        >
          Meine Aufgaben
        </Button>
      </Col>
    </StyledRow>
  );
};

export default UserFilters;
