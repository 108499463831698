import LocalAssetsViewer from "../../common/LocalAssetsViewer";
import { KontaxTransactionForAdminFragment } from "../../../api/graphql/fragments/kontaxTransactionForAdmin.generated";
import { NotFoundContainer, AssetsWrapper } from "./styledComponents";

const Assets = ({
  transaction,
}: {
  transaction: KontaxTransactionForAdminFragment;
}) => {
  if (!transaction.assets?.length) {
    return <NotFoundContainer>No Asset found</NotFoundContainer>;
  }

  return (
    <AssetsWrapper>
      <LocalAssetsViewer
        assets={transaction.assets || []}
        id={`asset-${transaction.id}`}
        minHeight={600}
      />
    </AssetsWrapper>
  );
};

export default Assets;
