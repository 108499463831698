import { gql, MutationHookOptions, useMutation } from "@apollo/client";

export interface DeleteBusinessAddressMutationVariables {
  id: string;
}

export interface DeleteBusinessAddressMutationResult {
  createBusinessAddress: {
    success: boolean;
  };
}

export const DELETE_BUSINESS_ADDRESS_MUTATION = gql`
  mutation deleteBusinessAddress($id: ID!) {
    deleteBusinessAddress(id: $id) {
      success
    }
  }
`;

export const useDeleteBusinessAddressMutation = (
  options?: MutationHookOptions<
    DeleteBusinessAddressMutationResult,
    DeleteBusinessAddressMutationVariables
  >
) =>
  useMutation<
    DeleteBusinessAddressMutationResult,
    DeleteBusinessAddressMutationVariables
  >(DELETE_BUSINESS_ADDRESS_MUTATION, options);
