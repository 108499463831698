import React from "react";
import { Typography } from "antd";

import colors from "../../../../../themes/colors";
import { VatAnnualDeclarationFieldType } from "../../../../../api/graphql";
import { VAT_ANNUAL_DECLARATION_FIELD_TYPE_CODES } from "./constants";
import { formatAmountInCents } from "../../../../../utils";

const { Text } = Typography;

export interface FieldAmountProps {
  type?: VatAnnualDeclarationFieldType;
  amount: number | null;
}
const FieldAmount = ({ type, amount }: FieldAmountProps) => (
  <Text
    style={{
      color:
        // if type is passed fields without code should be in grey color
        !type || VAT_ANNUAL_DECLARATION_FIELD_TYPE_CODES[type]
          ? colors.black
          : colors.lightGreyOpacity,
    }}
  >
    {amount != null ? formatAmountInCents(amount, true) : "-"}
  </Text>
);
export default FieldAmount;
