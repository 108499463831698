import React, { ReactNode, FC, useMemo, useCallback, MouseEvent } from "react";
import moment from "moment";
import { Tag, Select, Table, Button, Space } from "antd";
import isEqual from "lodash/isEqual";
import {
  DeleteOutlined,
  CheckCircleOutlined,
  RetweetOutlined,
} from "@ant-design/icons";
import uniq from "lodash/uniq";

import {
  BUSINESS_ASSET_CATEGORIES,
  EXTERNAL_TRANSACTION_CATEGORIES,
  formatDEAmount,
} from "../../../../../../utils";
import { Direction, DatevData } from "../../../../../../api/graphql";
import CategorySelect from "../../../../TransactionView/CategorySelect";
import VatCategorySelect from "../../../../TransactionView/VatCategorySelect";
import { ReactComponent as InvoiceIcon } from "../../../../../../svgs/invoice.svg";
import ImageCarousel from "../../../../../common/ImageCarousel";
import { Asset } from "../../../../../../types";
import {
  TableWrapper,
  AntIconTag,
  IconContainer,
  NoReceipt,
  CheckboxCell,
} from "./styled";
import getBuKeyCopy from "./utils/getBuKeyCopy";
import getSkrCopy from "./utils/getSkrCopy";
import { onClickStopPropagation } from "../../../../../../utils/table";

const { Option } = Select;

type DatevTableProps = {
  datevData: DatevData[];
  selectedIds: string[];
  onRowSelection: (ids: string[]) => void;
  onFieldSelect: (
    fieldName: string,
    row: DatevData
  ) => (value: string | null) => void;
  onFieldDelete: (ids: string[]) => void;
  onVerifyData: (id: string) => void;
  onSwitchAccount: (ids: string[]) => void;
  isLoading: boolean;
};

const DatevTable: FC<DatevTableProps> = ({
  datevData,
  selectedIds,
  onRowSelection,
  onFieldSelect,
  onFieldDelete,
  onVerifyData,
  onSwitchAccount,
  isLoading,
}) => {
  const renderInvoiceComponent = useCallback((datevData: DatevData) => {
    if (datevData.assets?.length) {
      return <ImageCarousel assets={datevData.assets} id={datevData.id} />;
    }

    return <NoReceipt> No receipt found</NoReceipt>;
  }, []);

  const yearsFilter = useMemo(
    () =>
      uniq(
        datevData.map(({ paymentDate }) => `${moment(paymentDate).year()}`)
      ).map((year) => ({ text: year, value: year })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [datevData.length]
  ); // only update if the number of items change.

  // We use this logic to be able to select the checkbox by clicking anywhere on the cell
  const onCheckboxClick = (event: MouseEvent<HTMLElement, any>) => {
    event.stopPropagation();

    const checkbox = event.currentTarget.querySelector<HTMLInputElement>(
      'input[type="checkbox"]'
    );

    checkbox?.dispatchEvent(
      new globalThis.MouseEvent(event.nativeEvent.type, event.nativeEvent)
    );
  };

  const datevColumns = useMemo(
    () => [
      {
        title: "Amount",
        dataIndex: "amount",
        key: "amount",
        width: 120,
        render: (amount: number) => formatDEAmount(amount),
        sorter: (a: DatevData, b: DatevData) => a.amount - b.amount,
        shouldCellUpdate: () => false,
      },
      {
        title: "Invoice",
        dataIndex: "assets",
        key: "assets",
        width: 80,
        render: (assets: Asset[]) =>
          assets?.length ? (
            <IconContainer>
              <InvoiceIcon />
            </IconContainer>
          ) : null,
        shouldCellUpdate: () => false,
      },
      {
        title: "H/S",
        dataIndex: "direction",
        key: "direction",
        width: 90,
        render: (direction: Direction, row: DatevData) => (
          <Select
            value={direction}
            onChange={onFieldSelect("direction", row)}
            disabled={Boolean(row.verifiedAt)}
          >
            <Option value={Direction.OUTGOING}>S</Option>
            <Option value={Direction.INCOMING}>H</Option>
          </Select>
        ),
        sorter: (a: DatevData) => (a.direction === Direction.INCOMING ? 1 : -1),
        shouldCellUpdate: (a: DatevData, b: DatevData) =>
          !isEqual(a.direction, b.direction) ||
          !isEqual(a.verifiedAt, b.verifiedAt),
        onCell: onClickStopPropagation,
      },
      {
        title: "Belegfeld 1",
        dataIndex: "meta1",
        key: "meta1",
        width: 120,
        sorter: (a: DatevData, b: DatevData) => a.meta1.localeCompare(b.meta1),
        shouldCellUpdate: () => false,
      },
      {
        title: "Konto",
        dataIndex: "account",
        key: "account",
        width: 180,
        sorter: (a: DatevData, b: DatevData) =>
          a.account.localeCompare(b.account),
        render: (account: string, { skrMode }: DatevData) => {
          const { label, color } = getSkrCopy(account, skrMode);
          return <Tag color={color}>{label}</Tag>;
        },
        shouldCellUpdate: (a: DatevData, b: DatevData) =>
          !isEqual(a.account, b.account) || !isEqual(a.skrMode, b.skrMode),
      },
      {
        title: "Buchungstext",
        dataIndex: "description",
        key: "description",
        width: 180,
        shouldCellUpdate: () => false,
      },
      {
        title: "Payment date",
        dataIndex: "paymentDate",
        key: "paymentDate",
        width: 160,
        render: (paymentDate: string) => (
          <div>{moment(paymentDate).format("L")}</div>
        ),
        sorter: (a: DatevData, b: DatevData) =>
          a.paymentDate > b.paymentDate ? 1 : -1,
        filters: yearsFilter,
        onFilter: (value: any, record: DatevData) =>
          `${moment(record.paymentDate).year()}` === value,
        shouldCellUpdate: () => false,
      },
      {
        title: "Verified",
        dataIndex: "verifiedAt",
        key: "verifiedAt",
        width: 114, // for the tag to be centered
        render: (
          verifiedAt: string,
          { id, kkr, vatCategoryCode }: DatevData
        ) => (
          <AntIconTag
            onClick={() => kkr && vatCategoryCode && onVerifyData(id)}
            inactive={!kkr || !vatCategoryCode}
          >
            {verifiedAt ? (
              <Tag color="success" icon={<CheckCircleOutlined />}>
                Verified
              </Tag>
            ) : (
              <Tag color="grey">Not verified</Tag>
            )}
          </AntIconTag>
        ),
        sorter: (a: DatevData) => (Boolean(a.verifiedAt) ? 1 : -1),
        shouldCellUpdate: (a: DatevData, b: DatevData) =>
          !isEqual(a.kkr, b.kkr) ||
          !isEqual(a.vatCategoryCode, b.vatCategoryCode) ||
          !isEqual(a.offsetAccount, b.offsetAccount) ||
          !isEqual(a.skrMode, b.skrMode) ||
          !isEqual(a.direction, b.direction) ||
          !isEqual(a.verifiedAt, b.verifiedAt),
        onCell: onClickStopPropagation,
      },
      {
        title: "Category",
        dataIndex: "kkr",
        key: "kkr",
        width: 200,
        render: (kkr: string, row: DatevData) => {
          const suggestedAmount = row.direction === Direction.INCOMING ? 1 : -1;
          const { label, color } = getSkrCopy(row.offsetAccount, row.skrMode);

          return (
            <div>
              <Tag color={color}>{label}</Tag>
              <CategorySelect
                id={`category-${row.id}`}
                value={kkr}
                disabledOptions={[
                  ...EXTERNAL_TRANSACTION_CATEGORIES,
                  ...BUSINESS_ASSET_CATEGORIES,
                ]}
                onChangeHandler={onFieldSelect("kkr", row)}
                transactionAmount={suggestedAmount}
                style={{ marginTop: -1 }}
                disabled={Boolean(row.verifiedAt)}
              />
            </div>
          );
        },
        shouldCellUpdate: (a: DatevData, b: DatevData) =>
          !isEqual(a.kkr, b.kkr) ||
          !isEqual(a.offsetAccount, b.offsetAccount) ||
          !isEqual(a.skrMode, b.skrMode) ||
          !isEqual(a.direction, b.direction) ||
          !isEqual(a.verifiedAt, b.verifiedAt),
        onCell: onClickStopPropagation,
      },
      {
        title: "VAT rate",
        dataIndex: "vatCategoryCode",
        key: "vatCategoryCode",
        width: 200,
        render: (vatCategoryCode: string, row: DatevData) => {
          return (
            <div>
              <Tag color="blue">{getBuKeyCopy(row.buKey, row.paymentDate)}</Tag>
              <VatCategorySelect
                value={vatCategoryCode}
                onChangeHandler={onFieldSelect("vatCategoryCode", row)}
                style={{ marginTop: -2 }}
                disabled={Boolean(row.verifiedAt)}
                kontaxCategory={row.kkr}
              />
            </div>
          );
        },
        shouldCellUpdate: (a: DatevData, b: DatevData) =>
          !isEqual(a.vatCategoryCode, b.vatCategoryCode) ||
          !isEqual(a.verifiedAt, b.verifiedAt),
        onCell: onClickStopPropagation,
      },
      {
        title: "Actions",
        dataIndex: "id",
        key: "actions",
        width: 120,
        render: (id: string, { verifiedAt }: DatevData) => (
          <Space className="text-center">
            <Button
              onClick={() => onSwitchAccount([id])}
              icon={<RetweetOutlined />}
              disabled={Boolean(verifiedAt)}
            />
            <Button
              onClick={() => onFieldDelete([id])}
              icon={<DeleteOutlined />}
              danger
              disabled={Boolean(verifiedAt)}
            />
          </Space>
        ),
        shouldCellUpdate: (a: DatevData, b: DatevData) =>
          !isEqual(a.verifiedAt, b.verifiedAt),
        onCell: onClickStopPropagation,
      },
    ],
    // To optimize the performance, do not rerender the list when the functions change.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [yearsFilter]
  );

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[]) => {
      onRowSelection(selectedRowKeys as string[]);
    },
    renderCell: (
      _checked: boolean,
      _record: DatevData,
      _i: number,
      node: ReactNode
    ) => <CheckboxCell onClick={onCheckboxClick}>{node}</CheckboxCell>,
    selectedRowKeys: selectedIds,
  };

  const tablePaginationOptions = {
    pageSizeOptions: ["50", "150", "300"],
    defaultPageSize: 50,
  };

  return (
    <TableWrapper>
      <Table
        dataSource={datevData}
        columns={datevColumns}
        rowKey={(row: DatevData) => row.id}
        loading={isLoading}
        scroll={{ x: "100%", y: 800 }}
        expandable={{
          expandedRowRender: renderInvoiceComponent,
          expandRowByClick: true,
          showExpandColumn: false,
        }}
        rowSelection={rowSelection}
        pagination={tablePaginationOptions}
      />
    </TableWrapper>
  );
};

export default DatevTable;
