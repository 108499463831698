import React, { useState, useEffect, useCallback } from "react";
import { Modal, Form, Button } from "antd";

import {
  BusinessAssetForm,
  ITransaction,
  PaymentFrequency,
} from "../../../../types";
import { useAddBusinessAssetToTransactionMutation } from "../../../../api/graphql/mutations/transaction";
import { showGraphQlErrorNotification } from "../../../../utils/notifications";
import { getBusinessAssetFormForUpdate } from "./utils";
import { BusinessAsset } from "../../../../api/graphql/schema.generated";
import AddBusinessAssetForm from "./AddBusinessAssetForm";
import { isDepreciableCategoryCode } from "../../../BusinessAsset/utils";

type Values = {
  categoryCode: string;
  vatCategoryCode: string;
  businessAssetForm?: BusinessAssetForm;
};

export interface InitialValues {
  id: string;
  categoryCode?: string | null;
  vatCategoryCode?: string | null;
  businessAssets?: BusinessAsset[];
  amount: number;
  paymentDate: string;
}

export const AddBusinessAssetPopup = ({
  closePopup,
  initialValues,
  vatYearPaymentFrequency,
  onTransactionUpdated,
}: {
  closePopup: Function;
  initialValues: InitialValues;
  vatYearPaymentFrequency?: PaymentFrequency;
  onTransactionUpdated: (upsertedTransaction: ITransaction) => void;
}) => {
  const [form] = Form.useForm();
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [addBusinessAsset] = useAddBusinessAssetToTransactionMutation();

  const upsertTransaction = useCallback(
    async (values: Values, transactionId: string) => {
      const result = await addBusinessAsset({
        variables: {
          businessAssetForm: getBusinessAssetFormForUpdate(
            values.businessAssetForm
          )!,
          transactionId,
          categoryCode: values.categoryCode,
          vatCategoryCode: values.vatCategoryCode,
        },
      });

      onTransactionUpdated(
        result.data?.addBusinessAssetToTransaction as ITransaction
      );
    },
    [addBusinessAsset, onTransactionUpdated]
  );

  useEffect(() => {
    if (!initialValues) {
      form.resetFields();
    } else {
      form.setFields([
        { name: "categoryCode", value: initialValues.categoryCode },
        { name: "vatCategoryCode", value: initialValues.vatCategoryCode },
      ]);
    }
    // it should not depend on the form
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  const handleCancel = () => {
    closePopup();
    form.resetFields();
  };

  const handleSubmit = async (values: Values) => {
    setIsButtonLoading(true);
    try {
      await upsertTransaction(values, initialValues.id);
      handleCancel();
    } catch (error) {
      showGraphQlErrorNotification("Add business asset:", error);
    } finally {
      setIsButtonLoading(false);
    }
  };

  const onChangeCategory = (categoryCode: string | null) => {
    form.setFieldsValue({ categoryCode });

    form.setFieldsValue({
      businessAssetForm: isDepreciableCategoryCode(categoryCode)
        ? {}
        : undefined,
    }); // set businessAssetForm to {} to reveal the form

    if (form.getFieldValue("vatCategoryCode")) {
      form.resetFields(["vatCategoryCode"]);
    }
  };

  const onChangeBusinessAsset = (businessAssetForm: BusinessAssetForm) => {
    form.setFieldsValue({ businessAssetForm });
  };

  const onVatCategorySelected = (category: string | null) => {
    form.setFieldsValue({
      vatCategoryCode: category,
    });
  };

  const footer = [
    <Button
      form="addBusinessAssetFormId"
      key="submit"
      htmlType="submit"
      type="primary"
      loading={isButtonLoading}
    >
      Add
    </Button>,
  ];

  return (
    <Modal
      data-test="addBusinessAssetPopup"
      title="Add business asset"
      visible
      onCancel={handleCancel}
      footer={footer}
      centered
      width={600}
    >
      <Form
        form={form}
        name="addBusinessAssetForm"
        id="addBusinessAssetFormId"
        onFinish={handleSubmit}
        autoComplete="off"
        layout="vertical"
        requiredMark="optional"
      >
        <AddBusinessAssetForm
          onChangeCategory={onChangeCategory}
          onVatCategorySelected={onVatCategorySelected}
          onChangeBusinessAsset={onChangeBusinessAsset}
          vatYearPaymentFrequency={vatYearPaymentFrequency}
        />
      </Form>
    </Modal>
  );
};
