import { PaymentFrequency } from ".";

export enum NextVatDeclarationStatus {
  PENDING = "PENDING",
  READY_TO_CHECK = "READY_TO_CHECK",
  READY_TO_SEND = "READY_TO_SEND",
  BLOCKED = "BLOCKED",
  READY_TO_BOOK = "READY_TO_BOOK",
}

export enum NextVatDeclarationStatusLabel {
  PENDING = "Pending",
  READY_TO_CHECK = "Ready to check",
  READY_TO_SEND = "Ready to send",
  BLOCKED = "Blocked",
  READY_TO_BOOK = "Ready to book",
}

export interface IVatDeclarationUser {
  accountId: number;
  frequency: PaymentFrequency;
  status: NextVatDeclarationStatus;
  email: string;
  firstName: string;
  lastName: string;
  nextDeclarationDuePeriod?: string;
  nextDeclarationDueYear?: number;
  nextDeclarationDueDate?: string;
  unbookedTransactions?: number;
  unbookedTransactionsWithoutReceipts?: number;
  vatLastCheckedAt?: string;
  lastVATDeclarationSent?: string;
  hasNotes?: boolean;
  subscriptionType: string;
}

export enum QuarterlyVatDeclarationPeriod {
  Q1 = "Q1",
  Q2 = "Q2",
  Q3 = "Q3",
  Q4 = "Q4",
}

export enum MonthlyVatDeclarationPeriod {
  JANUARY = "01",
  FEBRUARY = "02",
  MARCH = "03",
  APRIL = "04",
  MAY = "05",
  JUNE = "06",
  JULY = "07",
  AUGUST = "08",
  SEPTEMBER = "09",
  OCTOBER = "10",
  NOVEMBER = "11",
  DECEMBER = "12",
}

export type VatAdvanceDeclarationPeriods = Array<
  QuarterlyVatDeclarationPeriod | MonthlyVatDeclarationPeriod
>;
