import type * as Types from "../schema.generated";

import { gql } from "@apollo/client";
export type DeclarationApprovalFragment = {
  __typename?: "DeclarationApproval";
  id: string;
  jointDeclaration?: boolean | null;
  delaySubmission?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export const DeclarationApprovalFragmentDoc = gql`
  fragment DeclarationApproval on DeclarationApproval {
    id
    jointDeclaration
    delaySubmission
    createdAt
    updatedAt
  }
`;
