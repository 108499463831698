import type * as Types from "../../schema.generated";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type ExportDocumentInputsMutationVariables = Types.Exact<{
  email: Types.Scalars["String"];
  type: Types.QuestionnaireType;
  year: Types.Scalars["Int"];
}>;

export type ExportDocumentInputsMutation = {
  __typename?: "Mutation";
  exportDocumentInputs: { __typename?: "MutationResult"; success: boolean };
};

export const ExportDocumentInputsDocument = gql`
  mutation exportDocumentInputs(
    $email: String!
    $type: QuestionnaireType!
    $year: Int!
  ) {
    exportDocumentInputs(email: $email, type: $type, year: $year) {
      success
    }
  }
`;
export type ExportDocumentInputsMutationFn = Apollo.MutationFunction<
  ExportDocumentInputsMutation,
  ExportDocumentInputsMutationVariables
>;

/**
 * __useExportDocumentInputsMutation__
 *
 * To run a mutation, you first call `useExportDocumentInputsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportDocumentInputsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportDocumentInputsMutation, { data, loading, error }] = useExportDocumentInputsMutation({
 *   variables: {
 *      email: // value for 'email'
 *      type: // value for 'type'
 *      year: // value for 'year'
 *   },
 * });
 */
export function useExportDocumentInputsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ExportDocumentInputsMutation,
    ExportDocumentInputsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ExportDocumentInputsMutation,
    ExportDocumentInputsMutationVariables
  >(ExportDocumentInputsDocument, options);
}
export type ExportDocumentInputsMutationHookResult = ReturnType<
  typeof useExportDocumentInputsMutation
>;
export type ExportDocumentInputsMutationResult =
  Apollo.MutationResult<ExportDocumentInputsMutation>;
export type ExportDocumentInputsMutationOptions = Apollo.BaseMutationOptions<
  ExportDocumentInputsMutation,
  ExportDocumentInputsMutationVariables
>;
