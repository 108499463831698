import React from "react";
import { Tag } from "antd";

import { KontaxUserStatus } from "../../../../../types";
import { getColorByUserStatus } from "../../../../../utils";

const CustomerStatus = ({ status }: { status?: KontaxUserStatus }) => {
  if (!status) return null;
  return (
    <Tag data-test="customerStatus" color={getColorByUserStatus(status)}>
      {status}
    </Tag>
  );
};

export default CustomerStatus;
