import styled from "styled-components";

import colors from "../../../themes/colors";

const dropdownWidth = 230;

/**
 * Custom Menu
 */

export const ButtonGroup = styled.div`
  display: flex;
  padding: 0 10px;
  button {
    margin: 0px;
  }
  &.space-between {
    justify-content: space-between;
  }
`;

export const OptionsWrapper = styled.div`
  width: ${dropdownWidth}px;
  padding: 0 10px;
  max-height: 200px;
  overflow-y: scroll;
`;

export const CustomMenuWrapper = styled.div`
  padding: 10px 0;
  background-color: ${colors.white};
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 2px;
`;
