import { useMemo, useEffect, useState } from "react";
import { Badge, Tabs } from "antd";

import { TaxBoardUser } from "../../../../../api/graphql/schema.generated";
import TaxCaseActions from "./TaxCaseActions";
import TaxCaseOverview from "./TaxCaseOverview";
import TaxCaseComments from "./TaxCaseComments";
import { KontaxUserContext } from "../../../../contexts/KontaxUserContext";
import TaxCaseExternal from "./TaxCaseExternal";
import colors from "../../../../../themes/colors";
import ExternalBookkeepingTab from "../../../Mandanten/ActivationView/UserDetails/ExternalBookkeepingTab";
import UserExternalBookkeepingBlock from "./UserExternalBookkeepingBlock";
import { UserFibuFinalCheck } from "../../../../common/FibuFinalCheck";
import { useFetchDocumentCountLazyQuery } from "../../../../../api/graphql/queries/documents/documentCount.generated";
import { showGraphQlErrorNotification } from "../../../../../utils";

const { TabPane } = Tabs;

enum TaxCaseDetailsTab {
  DASHBOARD = "DASHBOARD",
  EXTERNAL_DOCUMENTS = "EXTERNAL_DOCUMENTS",
  COMMENTS = "COMMENTS",
}

const TaxCaseDetails = ({
  email,
  taxBoardUser,
  taxYear,
  onRefresh,
}: {
  email: string;
  taxBoardUser: TaxBoardUser;
  taxYear: number;
  onRefresh: () => void;
}) => {
  const declarationDocumentsCount = useMemo(() => {
    const {
      euerDeclarations,
      vatAnnualDeclarations,
      tradeTaxDeclarations,
      incomeTaxDeclarations,
    } = taxBoardUser;

    return [
      euerDeclarations[0],
      vatAnnualDeclarations[0],
      tradeTaxDeclarations[0],
      incomeTaxDeclarations[0],
    ]
      .flatMap((declaration) => declaration.declarationDocuments)
      .flatMap((document) => document.assets).length;
  }, [taxBoardUser]);

  const [
    externalBookkeepingDocumentsCount,
    setExternalBookkeepingDocumentsCount,
  ] = useState<number | null>(null);

  const [fetchDocumentCount] = useFetchDocumentCountLazyQuery({
    variables: {
      userId: taxBoardUser.id,
      year: taxYear,
    },
    onCompleted: (data) => {
      setExternalBookkeepingDocumentsCount(data.documentCount.count);
    },
    onError: (error) => {
      showGraphQlErrorNotification(``, error);
    },
  });

  useEffect(() => {
    if (externalBookkeepingDocumentsCount === null) {
      fetchDocumentCount();
    }
  }, [taxBoardUser, fetchDocumentCount, externalBookkeepingDocumentsCount]);

  return (
    <KontaxUserContext email={email}>
      <Tabs centered style={{ width: 1000 }}>
        <TabPane tab="Dashboard" key={TaxCaseDetailsTab.DASHBOARD}>
          <TaxCaseActions taxBoardUser={taxBoardUser} />
          <UserFibuFinalCheck year={taxYear} />
          <UserExternalBookkeepingBlock />
          <TaxCaseOverview taxBoardUser={taxBoardUser} taxYear={taxYear} />
        </TabPane>
        <TabPane
          tab={
            <>
              Externe Anlagen
              <Badge
                count={declarationDocumentsCount}
                style={{ backgroundColor: colors.purple, marginLeft: 4 }}
              />
            </>
          }
          key={TaxCaseDetailsTab.EXTERNAL_DOCUMENTS}
        >
          <TaxCaseExternal taxBoardUser={taxBoardUser} onRefresh={onRefresh} />
        </TabPane>
        <ExternalBookkeepingTab
          tab={
            <span id="ExterneBuchhaltungTabButton">
              Externe Buchhaltung
              {!!externalBookkeepingDocumentsCount && (
                <Badge
                  count={externalBookkeepingDocumentsCount}
                  style={{ backgroundColor: colors.purple, marginLeft: 4 }}
                />
              )}
            </span>
          }
          setDocumentsCount={setExternalBookkeepingDocumentsCount}
        />
        <TabPane tab="Kommentare" key={TaxCaseDetailsTab.COMMENTS}>
          <TaxCaseComments taxYear={taxYear} />
        </TabPane>
      </Tabs>
    </KontaxUserContext>
  );
};

export default TaxCaseDetails;
