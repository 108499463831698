import type * as Types from "../schema.generated";

import { gql } from "@apollo/client";
export type NoteDataFragment = {
  __typename?: "KontaxNote";
  id: number;
  creator: string;
  updatedAt: string;
  createdAt: string;
  message: string;
};

export const NoteDataFragmentDoc = gql`
  fragment noteData on KontaxNote {
    id
    creator
    updatedAt
    createdAt
    message
  }
`;
