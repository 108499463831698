import { useQueryParam, NumberParam, withDefault } from "use-query-params";
import range from "lodash/range";

export const TAX_YEAR_PARAM_NAME = "year";

const FIRST_TAX_YEAR = 2019;
const LAST_TAX_YEAR = 2024;

export const getTaxYearOptions = () => range(FIRST_TAX_YEAR, LAST_TAX_YEAR + 1);

export const getDefaultTaxYear = () => new Date().getFullYear() - 2;

const useTaxYearParam = (defaultTaxYear = getDefaultTaxYear()) =>
  useQueryParam(TAX_YEAR_PARAM_NAME, withDefault(NumberParam, defaultTaxYear));

export default useTaxYearParam;
