export enum TaxNumberType {
  PERSONAL = "PERSONAL",
  BUSINESS = "BUSINESS",
}

export enum StateAbbreviation {
  DE_BW = "DE-BW",
  DE_BY = "DE-BY",
  DE_BE = "DE-BE",
  DE_BB = "DE-BB",
  DE_HB = "DE-HB",
  DE_HH = "DE-HH",
  DE_HE = "DE-HE",
  DE_MV = "DE-MV",
  DE_NI = "DE-NI",
  DE_NW = "DE-NW",
  DE_RP = "DE-RP",
  DE_SL = "DE-SL",
  DE_SN = "DE-SN",
  DE_ST = "DE-ST",
  DE_SH = "DE-SH",
  DE_TH = "DE-TH",
}

export enum IsMainBusinessTaxNumber {
  YES = "Ja",
  NO = "Nein",
}

export enum ReasonType {
  CANNOT_FIND = "I don’t know where to find it",
  NOT_APPLIED = "I am a new founder. Have not applied yet.",
  OTHER = "Other reasons",
  WAITING = "I have applied for it. Still waiting.",
}
