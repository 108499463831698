import React from "react";
import { Form, Input, DatePicker } from "antd";

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: "number" | "text" | "date";
  index: number;
  children: React.ReactNode;
}

function EditableCell({
  editing,
  dataIndex,
  title,
  inputType,
  index,
  children,
  ...restProps
}: React.PropsWithChildren<EditableCellProps>) {
  const inputNode =
    inputType === "date" ? (
      <DatePicker style={{ width: "120px" }} />
    ) : (
      <Input disabled />
    );
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
}

export default EditableCell;
