import styled from "styled-components";
import Table from "react-bootstrap/Table";

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 8px;
`;

export const Fieldset = styled.fieldset`
  border: none;
  padding: 15px 0;
`;

export const VatMeta = styled.fieldset`
  padding: 70px 110px;
  font-weight: 400;
  font-size: 18px;
`;

export const ClickableTd = styled.td`
  cursor: pointer;
  &:hover {
    background: #eeeeee;
  }
`;

export const Legend = styled.div`
  border: 0px;
  margin: 10px 0px;
  padding: 10px 0px;
  font-weight: bold;
  font-size: 18px;
`;

export const StyledTable = styled(Table)`
  width: 600px;
  th,
  td {
    border: 1px solid #eeeeee !important;
  }
  thead td {
    font-weight: bold;
  }
`;

export const H3 = styled.h3`
  padding-bottom: 10px;
`;

export const UserDetailsBlock = styled.fieldset`
  padding: 20px 0;
`;

export const SelectWrapper = styled.div`
  .ant-select {
    > div.ant-select-selector {
      min-width: 160px;
      background: rgba(0, 0, 0, 0.05);
      align-items: center;
    }
    > div {
      > span {
        > input.ant-select-selection-search-input {
          height: 100% !important;
        }
      }
    }
  }
`;
