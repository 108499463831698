import type * as Types from "../../schema.generated";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type FetchDocumentsQueryVariables = Types.Exact<{
  email: Types.Scalars["String"];
  categoryIds?: Types.InputMaybe<
    Array<Types.Scalars["String"]> | Types.Scalars["String"]
  >;
  year?: Types.InputMaybe<Types.Scalars["Int"]>;
}>;

export type FetchDocumentsQuery = {
  __typename?: "Query";
  documents: Array<{
    __typename?: "Document";
    id: string;
    name: string;
    url: string;
    note?: string | null;
    type: string;
    createdAt: string;
    downloadUrl: string;
    metadata?: {
      __typename?: "DocumentMetadata";
      category: {
        __typename?: "DocumentCategory";
        id: string;
        categoryName: string;
        folderName: string;
      };
    } | null;
  }>;
};

export const FetchDocumentsDocument = gql`
  query FetchDocuments($email: String!, $categoryIds: [String!], $year: Int) {
    documents(email: $email, categoryIds: $categoryIds, year: $year) {
      id
      name
      url
      note
      type
      createdAt
      downloadUrl
      metadata {
        category {
          id
          categoryName
          folderName
        }
      }
    }
  }
`;

/**
 * __useFetchDocumentsQuery__
 *
 * To run a query within a React component, call `useFetchDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchDocumentsQuery({
 *   variables: {
 *      email: // value for 'email'
 *      categoryIds: // value for 'categoryIds'
 *      year: // value for 'year'
 *   },
 * });
 */
export function useFetchDocumentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchDocumentsQuery,
    FetchDocumentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchDocumentsQuery, FetchDocumentsQueryVariables>(
    FetchDocumentsDocument,
    options
  );
}
export function useFetchDocumentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchDocumentsQuery,
    FetchDocumentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchDocumentsQuery, FetchDocumentsQueryVariables>(
    FetchDocumentsDocument,
    options
  );
}
export type FetchDocumentsQueryHookResult = ReturnType<
  typeof useFetchDocumentsQuery
>;
export type FetchDocumentsLazyQueryHookResult = ReturnType<
  typeof useFetchDocumentsLazyQuery
>;
export type FetchDocumentsQueryResult = Apollo.QueryResult<
  FetchDocumentsQuery,
  FetchDocumentsQueryVariables
>;
