import moment from "moment";

import { buKey as buKeyCopies } from "../data";
import { INVALID_VALUE } from "../constants";

const taxCutStart = new Date("2020-07-01T00:00:00.000+02:00");
const taxCutEnd = new Date("2020-12-31T23:59:59.000+02:00");

type BuKeyCopy = {
  label: string;
  beforeTaxCut?: string;
  duringTaxCut?: string;
  afterTaxCut?: string;
};

const getSuffix = (label?: string) => (label ? ` ${label}` : "");

const getBuKeyCopy = (buKey: string, paymentDate: string): string => {
  const buKeyCopy = (buKeyCopies as Record<string, BuKeyCopy>)[buKey];

  if (!buKeyCopy) {
    return INVALID_VALUE;
  }

  const parsedDate = moment.utc(paymentDate);
  const isBefore = parsedDate.isBefore(taxCutStart);
  const isAfter = parsedDate.isAfter(taxCutEnd);
  const { label, beforeTaxCut, afterTaxCut, duringTaxCut } = buKeyCopy;

  if (isBefore) {
    return `${label}${getSuffix(beforeTaxCut)}`;
  }

  if (isAfter) {
    return `${label}${getSuffix(afterTaxCut)}`;
  }

  return `${label}${getSuffix(duringTaxCut)}`;
};

export default getBuKeyCopy;
