import { InputRef } from "antd";
import React, {
  useState,
  useEffect,
  useRef,
  ReactNode,
  KeyboardEvent,
  useCallback,
} from "react";

import { StyledCurrencyInput } from "./styledComponents";

const EDITABLE_NUMBER_CELL_PLACEHOLDER = "[Klicken für Eingabe]";

export interface EditableNumberProps {
  editable: boolean;
  negativeEnabled?: boolean;
  value: number | null;
  onEdit: (value: number | null) => void;
  children?: ReactNode;
}

const EditableNumber = ({
  editable,
  negativeEnabled,
  children,
  value: initialValue,
  onEdit,
  ...restProps
}: EditableNumberProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const inputRef = useRef<InputRef>(null);

  useEffect(() => {
    if (isEditing) {
      inputRef.current!.focus();
    }
  }, [isEditing]);

  const toggleEdit = () => {
    setIsEditing(!isEditing);
  };

  const submitValue = (value: number) => {
    toggleEdit();
    const valueInCents = value == null ? null : Math.round(value * 100);
    onEdit(valueInCents);
  };

  const onKeyPress = useCallback((event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      inputRef.current?.blur();
    }
  }, []);

  let childNode = children;

  if (editable) {
    childNode = isEditing ? (
      <StyledCurrencyInput
        negativeEnabled={negativeEnabled}
        value={initialValue == null ? undefined : initialValue / 100}
        onChange={submitValue}
        onKeyPress={onKeyPress}
        onBlur={toggleEdit}
        ref={inputRef}
        size="small"
      />
    ) : (
      <div onClick={toggleEdit}>
        {initialValue == null ? EDITABLE_NUMBER_CELL_PLACEHOLDER : children}
      </div>
    );
  }

  return <div {...restProps}>{childNode}</div>;
};

export default EditableNumber;
