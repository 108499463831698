import styled, { css } from "styled-components";
import { Row } from "antd";

import { Select } from "../../../../../common/styledComponents";
import colors from "../../../../../../themes/colors";
import DateInput from "../../../../../common/TransactionFilters/DateInput";
import BusinessAssetClassSelect from "../../../../../BusinessAsset/BusinessAssetClassSelect";
import BusinessAssetTypeSelect from "../../../../../BusinessAsset/BusinessAssetTypeSelect";
import CurrencyInput from "../../../../../common/CurrencyInput";

export const Container = styled.div`
  width: 900px;
  padding: 20px;
  cursor: default;
  margin: auto;
`;

export const SubHeader = styled.div`
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 12px;
`;

export const SubComponent = styled.div`
  padding: 12px;
`;

export const RowGroup = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Col = styled.div`
  margin-right: 40px;
`;

export const SpinnerContainer = styled.div`
  display: flex;
  padding: 20px;
  width: 900px;
  align-items: center;
  justify-content: center;
  margin: auto;
`;

export const EditFormContainer = styled.div`
  margin-top: 50px;

  input,
  select {
    width: 350px;
    min-width: 350px;
    flex: 0;
  }

  label {
    display: inline-block;
    margin-right: 30px;
    width: 200px;
    font-size: 20px;
  }
`;

export const Label = styled.label`
  line-height: 50px;
`;

export const Fieldset = styled.fieldset`
  margin-bottom: 10px;
  display: flex;
`;

export const ModalIconContainer = styled.div`
  text-align: center;
`;

export const ModalHeading = styled.div`
  margin: 20px 0;
  text-align: center;
  font-size: 26px;
  font-weight: bold;
  line-height: 32px;
`;

export const ModalText = styled.div`
  margin: 10px 30px 0;
  text-align: center;
  font-size: 18px;
  line-height: 25px;
`;

export const YearFilterSelect = styled(Select)`
  margin-left: 10px;
  min-width: 120px;
  font-size: 18px;
`;

const inputsWidth = "280px";
const inputsStyles = css`
  width: ${inputsWidth};
  max-width: ${inputsWidth};
  min-width: ${inputsWidth};
`;

export const CategoryContainer = styled.div`
  ${inputsStyles}
  flex: 0;
`;

export const CustomDateInput = styled(DateInput)`
  ${inputsStyles}
`;

export const CustomBusinessAssetClassSelect = styled(BusinessAssetClassSelect)`
  ${inputsStyles}
`;

export const CustomBusinessAssetTypeSelect = styled(BusinessAssetTypeSelect)`
  ${inputsStyles}
`;

export const CustomCurrencyInput = styled(CurrencyInput)`
  ${inputsStyles}
`;

export const FormError = styled.div`
  margin: 15px 0;
  font-size: 18px;
  color: ${colors.darkRed};
`;

export const ListRow = styled(Row)`
  padding: 10px 24px;
`;
