import { ParamsType } from "@ant-design/pro-provider";
import ProTable, { ActionType, ProColumns } from "@ant-design/pro-table";
import "@ant-design/pro-table/dist/table.less";
import { ProSchema } from "@ant-design/pro-utils/lib/typing";
import { RecordKey } from "@ant-design/pro-utils/lib/useEditableArray";
import { Button, Input, InputRef, Typography } from "antd";
import _ from "lodash";
import React, { RefObject, useEffect, useRef, useState } from "react";

import { PermissionsScope, TaxOpsPermissionsScope } from "../../../../types";
import AntDeletePopupConfirm from "../../../common/AntDeletePopupConfirm";
import useSearchHotkey from "../../../hooks/useSearchHotkey";

const valueEnumPermissionsScope = () =>
  Object.keys(PermissionsScope).reduce((acc: any, k: string) => {
    acc[k] = {
      text: k,
      status: k,
    };

    return acc;
  }, {});

const getColumns = (
  onDeleteHandler: (key: number, record: TaxOpsPermissionsScope) => void,
  emailRef: RefObject<InputRef>
): ProColumns<TaxOpsPermissionsScope>[] => [
  {
    dataIndex: "email",
    title: "Email Address",
    valueType: "text",
    formItemProps: () => ({
      rules: [
        { required: true, message: "Please enter taxOps email", type: "email" },
      ],
    }),
    renderFormItem: (_: ProSchema<TaxOpsPermissionsScope>, config: any) => {
      return (
        <Input
          {...config.fieldProps}
          ref={emailRef}
          placeholder="email@kontist.com"
        />
      );
    },
    editable: (_, record) => record.id === -1,
    sorter: (a: TaxOpsPermissionsScope, b: TaxOpsPermissionsScope) =>
      a.email.localeCompare(b.email),
  },
  {
    dataIndex: "permissionsScope",
    title: "Permission Scope",
    valueType: "select",
    valueEnum: valueEnumPermissionsScope(),
    sorter: (a: TaxOpsPermissionsScope, b: TaxOpsPermissionsScope) =>
      a.permissionsScope.localeCompare(b.permissionsScope),
    formItemProps: () => ({
      rules: [{ required: true, message: "Please select permissions scope" }],
    }),
  },
  {
    title: "Actions",
    valueType: "option",
    render: (text, record, _, action) => [
      <Typography.Link
        key="editable"
        onClick={() => {
          action?.startEditable?.(record.id);
        }}
      >
        Edit
      </Typography.Link>,
      <AntDeletePopupConfirm
        key="deletePopup"
        title="Are you sure to delete this permission?"
        onDelete={() => onDeleteHandler(record.id, record)}
      >
        <Typography.Link key="deleteLink">Delete</Typography.Link>
      </AntDeletePopupConfirm>,
    ],
  },
];

const PermissionsTable = ({
  permissionsScopes,
  loading,
  onDelete,
  onSave,
}: {
  permissionsScopes: Array<TaxOpsPermissionsScope>;
  loading: boolean;
  onDelete: (permissionsScope: TaxOpsPermissionsScope) => void;
  onSave: (permissionsScope: TaxOpsPermissionsScope) => void;
}) => {
  const [editableKeys, setEditableRowKeys] = useState<React.Key[]>();
  const [dataSource, setDataSource] = useState<TaxOpsPermissionsScope[]>([]);
  const emailRef = useRef<InputRef>(null);
  useSearchHotkey(emailRef);
  const actionRef = useRef<ActionType>();

  useEffect(() => {
    setDataSource(permissionsScopes);
  }, [permissionsScopes]);

  const onCancelHandler = async () => {
    const newData = _.filter(dataSource, (s) => s.id !== -1);
    if (editableKeys?.length) {
      actionRef.current?.cancelEditable(editableKeys[0]);
    }
    setDataSource(newData);
    setEditableRowKeys(undefined);
  };

  const onCreateHandler = () => {
    const permissionsScope: TaxOpsPermissionsScope = {
      id: -1,
      email: "",
      permissionsScope: PermissionsScope.ADMIN,
      createdAt: new Date(),
    };
    actionRef.current?.addEditRecord(permissionsScope, { position: "top" });
  };

  const onDeleteHandler = async (
    key: RecordKey,
    record: TaxOpsPermissionsScope
  ) => {
    setEditableRowKeys(undefined);
    onDelete(record);
  };

  const onFilterHandler = (params: ParamsType) => {
    const filterPredicts: Array<
      ((taxOpsPermissionsScope: TaxOpsPermissionsScope) => boolean) | undefined
    > = [
      params.email
        ? (d: TaxOpsPermissionsScope) =>
            d.email.toLowerCase().includes(params.email.toLowerCase())
        : undefined,
      params.permissionsScope
        ? (d: TaxOpsPermissionsScope) =>
            d.permissionsScope === params.permissionsScope
        : undefined,
    ];

    const filteredDataSource = permissionsScopes.filter(
      (taxOpsPermissionsScope) =>
        filterPredicts.every(
          (filterPredict) =>
            !filterPredict || filterPredict(taxOpsPermissionsScope)
        )
    );

    setDataSource(filteredDataSource);
  };

  const onResetFilterHandler = () => {
    setDataSource(permissionsScopes);
  };

  const onSaveHandler = async (
    key: RecordKey,
    record: TaxOpsPermissionsScope
  ) => {
    try {
      const updatedDataSource = [...dataSource];
      const index = updatedDataSource.findIndex((item) => key === item.id);
      const item = updatedDataSource[index];
      const newItem = { ...item, ...record };
      updatedDataSource.splice(index, 1, newItem);
      setDataSource(updatedDataSource);
      setEditableRowKeys(undefined);
      onSave(newItem);
    } catch (errInfo) {
      // eslint-disable-next-line no-console
      console.log("Validate Failed:", errInfo);
    }
  };

  const onUpdateHandler = (editableKeys: React.Key[]) => {
    setEditableRowKeys(editableKeys);
  };

  return (
    <ProTable<TaxOpsPermissionsScope>
      loading={loading}
      rowKey="id"
      actionRef={actionRef}
      columns={getColumns(onDeleteHandler, emailRef)}
      dataSource={dataSource}
      headerTitle="TaxOps Permission Scopes"
      toolBarRender={() => [
        <Button
          key="button"
          type="primary"
          style={{ float: "right" }}
          disabled={!!editableKeys?.length}
          onClick={onCreateHandler}
        >
          Add new
        </Button>,
      ]}
      search={{
        filterType: "query",
        labelWidth: "auto",
        searchText: "Search",
      }}
      onSubmit={onFilterHandler}
      onReset={onResetFilterHandler}
      onChange={onCancelHandler}
      cardBordered={true}
      editable={{
        type: "single",
        deletePopconfirmMessage: "Are you sure to delete this permission?",
        editableKeys,
        onlyAddOneLineAlertMessage:
          "Please Save or Cancel current updatable row",
        onlyOneLineEditorAlertMessage:
          "Please Save or Cancel current updatable row",
        onCancel: onCancelHandler,
        onChange: onUpdateHandler,
        onSave: onSaveHandler,
        actionRender: (row, config, defaultDoms) => {
          return [defaultDoms.save, defaultDoms.cancel];
        },
      }}
      toolbar={{ settings: [] }}
      pagination={{ pageSize: 10, showSizeChanger: false }}
    ></ProTable>
  );
};

export default PermissionsTable;
