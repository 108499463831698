import styled from "styled-components";
import { Modal, Typography } from "antd";

import colors from "../../../../../../../../themes/colors";

const { Paragraph } = Typography;

export const ModalContent = styled.div`
  display: grid;
  grid-template-columns: 395px 1fr 1fr;
  gap: 0 24px;
  justify-items: stretch;
  align-items: center;

  .assets {
    grid-column: 1 / span 1;
    grid-row: 1 / span 4;
    align-self: stretch;

    .assetContainer {
      min-height: 430px;
    }
  }

  .ant-input-number-group-wrapper,
  .ant-picker {
    width: 100%;
  }

  .anticon {
    display: flex;
  }
`;

export const ExpenseModalContent = styled(ModalContent)`
  .ant-upload-drag {
    min-height: 430px !important;
  }
`;

export const DocumentModalContent = styled(ModalContent)`
  .ant-upload-drag {
    min-height: 280px !important;
  }
`;

export const AssetViewerContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: end;
  width: 100%;
`;

export const StyledParagraph = styled(Paragraph)`
  color: ${colors.lightGreyOpacity};
`;

export const HomeOfficeExpenseModal = styled(Modal)<{
  withDeleteButton?: boolean;
}>`
  * .ant-modal-footer {
    text-align: none;
    display: flex;
    flex-direction: row;
    justify-content: ${({ withDeleteButton }) =>
      withDeleteButton ? "space-between" : "flex-end"};
    align-items: center;

    width: 100%;
  }
`;
