import { Button, List, Table } from "antd";
import { TableProps } from "antd/lib/table";
import { ListProps } from "antd/lib/list";

import React from "react";
import styled from "styled-components";

import colors from "../../../../../../themes/colors";

export const GREYED_OUT_CLASS = "greyed-out";
export const StyledTable = styled(Table)`
  width: 100%;
  border: 1px solid ${colors.veryLightGrey};
  margin-top: 30px;

  tr.${GREYED_OUT_CLASS} {
    color: ${colors.lightGrey};
  }
`;

export const DocumentsTable: React.FC<TableProps<any>> = styled(Table)`
  width: 100%;
  .ant-table-expanded-row td {
    background: ${colors.white};
  }
  .ant-table-expanded-row :hover {
    background: ${colors.white};
  }
`;

export const AssetContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: end;
  box-sizing: border-size;
  border: 1px solid ${colors.veryLightGrey};
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
`;

export const InputsList: React.FC<ListProps<any>> = styled(List)`
  .ant-list-header {
    border: none;
  }
`;

export const CopyButton = styled(Button)`
  color: ${colors.midGrey};
  :hover {
    color: ${colors.primaryPurple};
  }
`;

export const InformationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
