import React, { useState, useCallback } from "react";
import { Button } from "antd";
import { Modal } from "react-bootstrap";
import { SendOutlined } from "@ant-design/icons";
import { notification } from "antd";
import "./modal.css";

import RadioButtonGroup from "../../../common/RadioButtonGroup";
import { NextButton } from "./styledComponents";
import { useSetActionReasonMutation } from "../../../../api/graphql";
import { ActionReason } from "../../../../api/graphql/schema.generated";

const INCOMING_TRANSACTION_REASONS = [
  { label: "Hinweis auf KU fehlt", value: ActionReason.SMALL_BUSINESS_MISSING },
  {
    label: "Steuersatz falsch Nebenleistungen",
    value: ActionReason.WRONG_TAXRATE_ANCILLARY_SERVICE,
  },
  {
    label: "Hinweis auf steuerfreie Umsätze fehlt",
    value: ActionReason.MISSING_TAX_EXEMPT_SALES,
  },
  { label: "Kein ermäßigter Steuersatz", value: ActionReason.NO_REDUCED_TAX },
  {
    label: "Reverse Charge Hinweis fehlt",
    value: ActionReason.REVERSE_CHARGE_MISSING,
  },
  { label: "Leistung nicht steuerbefreit", value: ActionReason.OBLIGED_TAXES },
  { label: "Betrag falsch", value: ActionReason.INCOMING_AMOUNT_WRONG },
  { label: "USt-ID fehlt", value: ActionReason.VAT_ID_MISSING },
  { label: "KU weist 19% aus", value: ActionReason.SMALL_BUSINESS_VAT },
  {
    label: "Keine gültige Ausgangsrechnung",
    value: ActionReason.WRONG_OUTGOING_INVOICE,
  },
  {
    label: "Belege hochladen",
    value: ActionReason.INVOICE_REQUIRED,
  },
  {
    label: "Elektronische Dienstleistung",
    value: ActionReason.ELECTRONIC_SERVICES,
  },
];

const OUTGOING_TRANSACTION_REASONS = [
  { label: "Ungültiger Beleg", value: ActionReason.INVALID_RECEIPT },
  {
    label: "Bewirtungsbeleg fehlt",
    value: ActionReason.NO_HOSPITALITY_RECEIPT,
  },
  {
    label: "Betrag auf Beleg falsch",
    value: ActionReason.OUTGOING_AMOUNT_WRONG,
  },
  {
    label: "Reverse Charge Information",
    value: ActionReason.REVERSE_CHARGE_INFORMATION,
  },
  {
    label: "Rechnung über 250€",
    value: ActionReason.INVOICE_ABOVE_250,
  },
  {
    label: "Rechnung unter 250€",
    value: ActionReason.INVOICE_BELOW_250,
  },
  {
    label: "Unklare Ausgabe",
    value: ActionReason.UNCLEAR_EXPENSE,
  },
  {
    label: "Externes Bankkonto",
    value: ActionReason.EXTERNAL_BANK_ACCOUNT,
  },
  {
    label: "Neuer Pkw",
    value: ActionReason.CAR_USAGE,
  },
];

export const ContactUserModal = ({
  show,
  onHide,
  name,
  amount,
  transactionId,
  fetchNextTransaction,
}: {
  show: boolean;
  onHide: () => void;
  name: string;
  amount: number;
  transactionId: string;
  fetchNextTransaction: () => void;
}) => {
  const [reason, setReason] = useState<ActionReason | null>(null);
  const [showFinalModal, setShowFinalModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);

  const [setActionReason] = useSetActionReasonMutation();

  const onNextClickHandler = () => {
    setShowFinalModal(true);
  };

  const onSendClickHandler = useCallback(async () => {
    if (reason) {
      setIsLoading(true);
      try {
        await setActionReason({
          variables: { reason, transactionId },
        });
        await fetchNextTransaction();
      } catch (err) {
        notification.error({
          message: `An errorr occurred while setting action reason: ${
            (err as Error).message
          }`,
        });
      } finally {
        setIsLoading(false);
      }
    }
    onHide();
  }, [setActionReason, reason, transactionId, onHide, fetchNextTransaction]);

  const onGoBackClickHandler = () => {
    setShowFinalModal(false);
  };

  const renderFinalModal = () => {
    const label = [
      ...INCOMING_TRANSACTION_REASONS,
      ...OUTGOING_TRANSACTION_REASONS,
    ].find(({ value }) => value === reason)?.label;

    return (
      <>
        <Modal.Header closeButton>
          <Modal.Title className="contact-user-modal-title">{`Email senden an ${name}`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Grund:</p>
          <b>{label}</b>
        </Modal.Body>
        <Modal.Footer>
          <Button
            data-test="contacUserGoBackBtn"
            onClick={onGoBackClickHandler}
          >
            Zurück
          </Button>
          <NextButton
            disabled={isLoading}
            data-test="contacUserSendBtn"
            onClick={onSendClickHandler}
          >
            <SendOutlined />
            Senden
          </NextButton>
        </Modal.Footer>
      </>
    );
  };

  const renderReasonsModal = () => (
    <>
      <Modal.Header closeButton>
        <Modal.Title className="contact-user-modal-title">{`Kontaktiere ${name}`}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <b>Wähle einen Grund</b>
        <br />
        <RadioButtonGroup
          value={reason}
          options={
            amount > 0
              ? INCOMING_TRANSACTION_REASONS
              : OUTGOING_TRANSACTION_REASONS
          }
          onChange={(value) => setReason(value)}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button data-test="contacUserCancelBtn" onClick={onHide}>
          Abbrechen
        </Button>
        <NextButton
          disabled={!reason}
          data-test="contacUserNextBtn"
          onClick={onNextClickHandler}
        >
          Weiter
        </NextButton>
      </Modal.Footer>
    </>
  );

  return (
    <Modal
      show={show}
      onHide={onHide}
      dialogClassName="contact-user-modal"
      data-test="сontactUserModal"
    >
      {showFinalModal ? renderFinalModal() : renderReasonsModal()}
    </Modal>
  );
};
