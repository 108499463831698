import React, { useState } from "react";
import { Table, Button } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

import CategorySelect from "../pages/TransactionView/CategorySelect";
import VatCategorySelect from "../pages/TransactionView/VatCategorySelect";
import {
  TransactionSplit,
  DepreciableCategory,
  ITransaction,
} from "../../types";
import BusinessAsset from "./BusinessAssetContainer";

import SplitAmountInput from "./SplitAmountInput";
import {
  DEPRECIABLE_CATEGORY_CODES,
  LATEST_POSSIBLE_PURCHASE_DATE,
} from "../BusinessAsset/constants";
import { BusinessAssetType } from "../../types";
import {
  BUSINESS_ASSET_CATEGORIES,
  EXTERNAL_TRANSACTION_CATEGORIES,
} from "../../utils";
import { isDepreciableCategoryCode } from "../BusinessAsset/utils";
import TransactionSubmissionStatus from "../pages/VerificationView/TransactionList/TransactionSubmissionStatus";

export const getResetAssetFields = (
  categoryCode: string
): {
  assetType: BusinessAssetType;
  assetClass: string;
  assetClassCustom: string;
  purchaseDate: string;
  depreciationYears: string;
  depreciationYearsEditable: boolean;
} => ({
  assetType: BusinessAssetType.MOVABLE_OTHERS,
  assetClass: "",
  assetClassCustom: "",
  purchaseDate: LATEST_POSSIBLE_PURCHASE_DATE,
  // If category is OVER_250, asset depreciates immediately, hence 0
  depreciationYears: categoryCode === DepreciableCategory.OVER_250 ? "0" : "",
  depreciationYearsEditable: categoryCode === DepreciableCategory.OVER_800,
});

const TransactionSplitView = ({
  splits,
  transaction,
  isEscalateClicked,
  isNextClicked,
  disableBusinessAssetFeatures,
  onChangeHandler,
  onDeleteHandler,
  isTransactionVerified,
}: {
  splits: Array<Partial<TransactionSplit>>;
  transaction: Pick<
    ITransaction,
    "amount" | "businessAssets" | "vatYearPaymentFrequency"
  >;
  isEscalateClicked?: boolean;
  isNextClicked?: boolean;
  disableBusinessAssetFeatures?: boolean;
  onChangeHandler: (split: Partial<TransactionSplit>, index: number) => void;
  onDeleteHandler: (index: number) => void;
  isTransactionVerified?: boolean;
}) => {
  const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([]);

  const columns = [
    {
      title: "Amount",
      key: "amount",
      width: "120px",
      render: (record: Partial<TransactionSplit>, text: any, index: number) => (
        <SplitAmountInput
          split={record}
          index={index}
          onChangeHandler={onChangeHandler}
          disabled={isTransactionVerified}
        />
      ),
    },
    {
      title: "Category",
      key: "categoryCode",
      width: "340px",
      render: (record: Partial<TransactionSplit>, text: any, index: number) => {
        return (
          <CategorySelect
            id={`category-${index}`}
            value={record?.categoryCode}
            disabled={isTransactionVerified}
            transactionAmount={transaction.amount}
            invalid={isNextClicked && !record?.categoryCode}
            onChangeHandler={(categoryCode: any) =>
              onChangeHandler(
                {
                  ...record,
                  categoryCode,
                  businessAssetForm: undefined,
                  // If depreciable category code is selected,
                  // reset business asset form fields to their default values
                  ...(DEPRECIABLE_CATEGORY_CODES.includes(categoryCode) && {
                    businessAssetForm: getResetAssetFields(categoryCode),
                  }),
                },
                index
              )
            }
            style={{ width: "100%", maxWidth: "320px" }}
            disabledOptions={[
              ...EXTERNAL_TRANSACTION_CATEGORIES,
              ...(disableBusinessAssetFeatures
                ? BUSINESS_ASSET_CATEGORIES
                : []),
            ]}
          />
        );
      },
    },
    {
      title: "VAT Category",
      key: "vatCategoryCode",
      width: "325px",
      render: (record: Partial<TransactionSplit>, text: any, index: number) => {
        return (
          <VatCategorySelect
            id={`vat-category-${index}`}
            value={record?.vatCategoryCode}
            disabled={isTransactionVerified}
            invalid={isNextClicked && !record?.vatCategoryCode}
            onChangeHandler={(vatCategoryCode) =>
              onChangeHandler({ ...record, vatCategoryCode }, index)
            }
            updateValueOnCategoryChange
            kontaxCategory={record.categoryCode}
            vatYearPaymentFrequency={transaction.vatYearPaymentFrequency}
            style={{ width: "100%", maxWidth: "305px" }}
          />
        );
      },
    },
    {
      title: "Submitted",
      key: "submitted",
      width: 150,
      render: (record: Partial<TransactionSplit>) => (
        <TransactionSubmissionStatus record={record} />
      ),
      showSorterTooltip: false,
      onCell: () => ({ style: { verticalAlign: "middle" } }),
    },
    {
      ...(splits.length > 2 && {
        render: (
          record: Partial<TransactionSplit>,
          text: any,
          index: number
        ) => (
          <Button
            shape="circle"
            icon={<DeleteOutlined />}
            onClick={() => onDeleteHandler(index)}
          />
        ),
      }),
    },
  ];

  const renderExpandedRow = (
    split: Partial<TransactionSplit>,
    index: number
  ) => {
    const onExpandedRowChange = (data: Partial<TransactionSplit>) => {
      onChangeHandler(data, index);
    };

    return (
      <div>
        {!disableBusinessAssetFeatures &&
          split &&
          split.categoryCode &&
          DEPRECIABLE_CATEGORY_CODES.includes(split.categoryCode) && (
            <BusinessAsset
              record={split}
              onChange={onExpandedRowChange}
              isSubmitClicked={Boolean(isNextClicked || isEscalateClicked)}
            />
          )}
      </div>
    );
  };

  const onExpand = async (expanded: boolean, record: any) => {
    const keys = expanded
      ? [...expandedRowKeys, record.key]
      : expandedRowKeys.filter((key) => key !== record.key);
    setExpandedRowKeys(keys);
  };

  const data = splits.map((split, index) => ({
    key: index,
    ...split,
  }));

  const expandable = disableBusinessAssetFeatures
    ? undefined
    : {
        expandedRowRender: renderExpandedRow,
        expandedRowClassName: () => "expandable-row",
        expandedRowKeys,
        onExpand,
        rowExpandable: (record: Partial<TransactionSplit>) =>
          isDepreciableCategoryCode(record.categoryCode) &&
          !transaction.businessAssets?.some(
            ({ businessAssetableId }) => businessAssetableId === record.uuid
          ),
      };

  return (
    <Table
      data-test="splitTable"
      columns={columns}
      dataSource={data}
      pagination={false}
      scroll={{ x: "100%" }}
      size="middle"
      expandable={expandable}
    />
  );
};

export default TransactionSplitView;
