import { useMemo } from "react";
import { Typography } from "antd";

import colors from "../../../../../../../themes/colors";
import { QuestionnaireStatus as QuestionnaireStatusType } from "../../../../../../../api/graphql/schema.generated";

const { Text } = Typography;

export interface QuestionnaireStatusProps {
  status: QuestionnaireStatusType | undefined;
}

const QuestionnaireStatus = ({ status }: QuestionnaireStatusProps) => {
  const { label, color } = useMemo(() => {
    if (!status || status === QuestionnaireStatusType.NOT_STARTED) {
      return { label: "Offen", color: colors.lightGreyOpacity };
    } else if (status === QuestionnaireStatusType.DOCUMENTS_UPLOADED) {
      return { label: "Abgeschlossen ", color: colors.darkLimeGreen };
    } else {
      return { label: "Gestartet", color: colors.lightGreyOpacity };
    }
  }, [status]);

  return <Text style={{ color }}>{label}</Text>;
};

export default QuestionnaireStatus;
