import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Form, Button, notification, Typography, Popconfirm } from "antd";

import {
  EuerDeclarationSubformType,
  calculateGrossAmount,
  calculateNetAmount,
  calculateVatAmount,
} from "@kontist/euer-declaration";

import HomeOfficeExpenseForm from "./HomeOfficeExpenseForm";
import { useUpsertHomeOfficeExpenseMutation } from "../../../../../../../../api/graphql/mutations/homeOfficeExpense/upsertHomeOfficeExpense.generated";
import {
  centsToEuros,
  eurosToCents,
  destroyMessage,
  showGraphQlErrorNotification,
  showLoadingMessage,
  showMessage,
} from "../../../../../../../../utils";
import {
  Asset,
  ILocalAsset,
  IMessageType,
} from "../../../../../../../../types";
import { useCreateHomeOfficeExpenseAssetMutation } from "../../../../../../../../api/graphql/mutations/homeOfficeExpense/createHomeOfficeExpenseAsset.generated";
import { useFinalizeAssetUploadMutation } from "../../../../../../../../api/graphql";
import { uploadFile } from "../../../../../../../../api/modules/Common";
import { isLocalAsset } from "../../../../../../../common/LocalAssetsViewer";
import {
  HomeOfficeExpensePeriod,
  HomeOfficeExpenseType,
} from "../../../../../../../../api/graphql/schema.generated";

import { MAX_ASSET_UPLOAD_SIZE } from "../../../constants";
import {
  getFileNameAndType,
  getUniqueFileName,
  groupFilesBySize,
} from "../../../hooks/useEuerDeclarationSubformAssetsUploader";
import { SaveAssetResult } from "../../../../../types";
import { notifyUserAfterSubmittingSubform } from "../../../../../utils";
import {
  EuerDeclarationSubformDocument,
  useEuerDeclarationSubformQuery,
} from "../../../../../../../../api/graphql/queries/euerDeclarationSubform.generated";
import { HomeOfficeExpenseModal } from "./styledComponents";
import { useDeleteHomeOfficeExpenseMutation } from "../../../../../../../../api/graphql/mutations/homeOfficeExpense/deleteHomeOfficeExpense.generated";

const LOADING_MESSAGE_KEY = "saving-home-office-expense";

export type HomeOfficeExpenseFormInputs = {
  adjustByOfficeAreaShare: boolean;
  amount: number;
  assets?: Array<Asset | ILocalAsset>;
  id: string;
  monthsUsed?: number | null;
  note: string;
  period: HomeOfficeExpensePeriod;
  type: HomeOfficeExpenseType;
  vatRate: string;
};

export const UpsertHomeOfficeExpenseModal = ({
  email,
  taxYear,
  visible,
  onSuccess,
  onClose,
  officeAreaShare,
  initialValues,
}: {
  email: string;
  taxYear: number;
  visible: boolean;
  onSuccess: Function;
  onClose: Function;
  officeAreaShare: number;
  initialValues?: HomeOfficeExpenseFormInputs;
}) => {
  const [form] = Form.useForm<HomeOfficeExpenseFormInputs>();
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [assets, setAssets] = useState<Array<Asset | ILocalAsset>>([]);
  const [upsertHomeOfficeExpense] = useUpsertHomeOfficeExpenseMutation();
  const [deleteHomeOfficeExpense] = useDeleteHomeOfficeExpenseMutation();
  const [createHomeOfficeExpenseAsset] =
    useCreateHomeOfficeExpenseAssetMutation();
  const [finalizeAssetUpload] = useFinalizeAssetUploadMutation();

  const [totalGrossAmount, setTotalGrossAmount] = useState(0);
  const [totalNetAmount, setTotalNetAmount] = useState(0);
  const [totalVatAmount, setTotalVatAmount] = useState(0);

  const [monthsUsed, setMonthsUsed] = useState<number>(12);
  useEuerDeclarationSubformQuery({
    skip: !email,
    variables: {
      email: email!,
      year: taxYear,
      type: EuerDeclarationSubformType.OFFICE_USAGE,
      shouldIncludeHomeOfficeExpenses: true,
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ euerDeclarationSubform }) => {
      if (!euerDeclarationSubform) {
        return;
      }
      const { inputs } = euerDeclarationSubform;
      if (inputs.monthsUsed) setMonthsUsed(inputs.monthsUsed as number);
    },
  });

  const handleReset = useCallback(() => {
    setAssets([]);
    form.resetFields();
  }, [form]);

  const handleCancel = () => {
    onClose();
    handleReset();
  };

  const clearForm = useCallback(() => {
    form.setFieldsValue({
      adjustByOfficeAreaShare: undefined,
      amount: undefined,
      assets: undefined,
      id: undefined,
      monthsUsed: form.getFieldValue("monthsUsed") || undefined,
      note: undefined,
      period: undefined,
      type: undefined,
      vatRate: undefined,
    });
    setAssets([]);
    setTotalGrossAmount(0);
    setTotalNetAmount(0);
    setTotalVatAmount(0);
  }, [form]);

  const calculateFormAutoValues = useCallback(() => {
    const amount = Math.floor(eurosToCents(form.getFieldValue("amount")));
    const period = form.getFieldValue("period");
    const monthsUsed = form.getFieldValue("monthsUsed");
    const vatRate = form.getFieldValue("vatRate");
    const adjustByOfficeAreaShare = form.getFieldValue(
      "adjustByOfficeAreaShare"
    );

    const totalGrossAmount = calculateGrossAmount({
      amount,
      period,
      monthsUsed,
      officeAreaShare,
      adjustByOfficeAreaShare,
    });
    setTotalGrossAmount(totalGrossAmount);

    const totalNetAmount = calculateNetAmount({
      amount,
      period,
      vatRate,
      monthsUsed,
      officeAreaShare,
      adjustByOfficeAreaShare,
    });
    setTotalNetAmount(totalNetAmount);

    const totalVatAmount = calculateVatAmount({
      amount,
      period,
      vatRate,
      monthsUsed,
      officeAreaShare,
      adjustByOfficeAreaShare,
    });
    setTotalVatAmount(totalVatAmount);
  }, [form, officeAreaShare]);

  const calculatedInitialValues = useMemo(() => {
    return { ...initialValues, monthsUsed };
  }, [initialValues, monthsUsed]);

  useEffect(() => {
    if (calculatedInitialValues) {
      form.setFieldsValue(calculatedInitialValues);

      form.setFields([
        { name: "amount", value: centsToEuros(calculatedInitialValues.amount) },
      ]);

      calculatedInitialValues.assets &&
        setAssets(calculatedInitialValues.assets);
    } else {
      clearForm();
    }

    calculateFormAutoValues();
  }, [form, calculateFormAutoValues, calculatedInitialValues, clearForm]);

  const onAssetDelete = (deletedAsset: Asset | ILocalAsset) => {
    const updatedAssets = assets.filter(
      (asset) =>
        (asset as ILocalAsset).name !== (deletedAsset as ILocalAsset).name
    );
    setAssets(updatedAssets);
    form.setFieldsValue({
      assets: updatedAssets,
    });
  };

  const onDropFiles = useCallback(
    (files: File[]) => {
      const maxFileSize = 1024 * 1024 * MAX_ASSET_UPLOAD_SIZE;
      const { invalidFiles, validFiles } = groupFilesBySize(files, maxFileSize);

      if (invalidFiles.length > 0) {
        invalidFiles.forEach((file) => {
          notification.error({
            message: `File ${file.name} exceeding file size limit (${MAX_ASSET_UPLOAD_SIZE}MB).`,
          });
        });
      }

      const newAssets = validFiles.map((file) => {
        const { name, type } = getFileNameAndType(file);
        return {
          name: getUniqueFileName(assets, { name, type }),
          filetype: type,
          fullsize: URL.createObjectURL(file),
          file,
        };
      });
      const updatedAssets = [...assets, ...newAssets];

      form.setFieldsValue({
        assets: updatedAssets,
      });

      setAssets(updatedAssets);
    },
    [form, assets]
  );

  const uploadAsset = useCallback(
    async (
      homeOfficeExpenseId: string,
      { name, filetype, file }: ILocalAsset
    ): Promise<Asset> => {
      const createAssetResponse = await createHomeOfficeExpenseAsset({
        variables: {
          homeOfficeExpenseId,
          name,
          filetype,
        },
      });

      if (!createAssetResponse.data) {
        throw new Error("Failed to create home office expense asset");
      }

      const { assetId, formData, url } =
        createAssetResponse.data.createHomeOfficeExpenseAsset;

      const form = new FormData();
      formData.forEach(({ key, value }) => form.append(key, value));
      form.append("file", file);

      await uploadFile({ url, form });

      const finalizeAssetUploadResponse = await finalizeAssetUpload({
        variables: { assetId },
      });

      if (!finalizeAssetUploadResponse.data) {
        throw new Error("Failed to finalize asset upload");
      }

      return finalizeAssetUploadResponse.data.finalizeAssetUpload;
    },
    [createHomeOfficeExpenseAsset, finalizeAssetUpload]
  );

  const saveAssets = useCallback(
    async (
      euerDeclarationSubformId: string
    ): Promise<Array<SaveAssetResult>> => {
      const localAssets = assets.filter((asset) =>
        isLocalAsset(asset)
      ) as ILocalAsset[];

      const uploadAssets = localAssets.map((asset) =>
        uploadAsset(euerDeclarationSubformId, asset)
      );

      const uploadStatuses = await Promise.allSettled(uploadAssets);

      return uploadStatuses.map((promiseState, index) => ({
        ...promiseState,
        localAsset: localAssets[index],
      }));
    },
    [assets, uploadAsset]
  );

  const handleSubmit = useCallback(
    async (values: HomeOfficeExpenseFormInputs) => {
      setIsButtonLoading(true);
      delete values.assets;

      showLoadingMessage(LOADING_MESSAGE_KEY);
      let savingFailed = false;
      let assetResults: SaveAssetResult[] = [];

      try {
        const result = await upsertHomeOfficeExpense({
          variables: {
            email,
            year: taxYear,
            payload: {
              ...values,
              id: calculatedInitialValues?.id,
              amount: eurosToCents(values.amount),
              note: values.note ?? "",
            },
          },
          refetchQueries: [EuerDeclarationSubformDocument],
        });

        assetResults = await saveAssets(
          result.data?.upsertHomeOfficeExpense.id!
        );

        await onSuccess();

        onClose();
        handleReset();
      } catch (error) {
        savingFailed = true;
        showGraphQlErrorNotification(`Create home office expense`, error);
      } finally {
        setIsButtonLoading(false);
        destroyMessage(LOADING_MESSAGE_KEY);
        notifyUserAfterSubmittingSubform(savingFailed, assetResults);
      }
    },
    [
      onClose,
      email,
      handleReset,
      calculatedInitialValues,
      onSuccess,
      saveAssets,
      taxYear,
      upsertHomeOfficeExpense,
    ]
  );

  const handleFormValuesChange = useCallback(() => {
    calculateFormAutoValues();
  }, [calculateFormAutoValues]);

  const handleHomeOfficeExpenseDelete = useCallback(async () => {
    setIsButtonLoading(true);
    showLoadingMessage(LOADING_MESSAGE_KEY);

    let hasErrors = false;
    try {
      const result = await deleteHomeOfficeExpense({
        variables: {
          homeOfficeExpenseId: calculatedInitialValues!.id!,
        },
      });

      hasErrors = (result?.errors ?? []).length > 0;

      await onSuccess();
    } catch (err) {
      showGraphQlErrorNotification("Delete home office expense", err);
    } finally {
      setIsButtonLoading(false);
      destroyMessage(LOADING_MESSAGE_KEY);

      onClose();
      handleReset();

      showMessage({
        content: hasErrors
          ? "Home office expense has already been deleted."
          : "Home office expense successfully deleted.",
        type: hasErrors ? IMessageType.ERROR : IMessageType.SUCCESS,
        duration: 3,
      });
    }
  }, [
    deleteHomeOfficeExpense,
    handleReset,
    calculatedInitialValues,
    onClose,
    onSuccess,
  ]);

  const footer = [
    calculatedInitialValues ? (
      <Popconfirm
        title="Sure to delete?"
        onConfirm={handleHomeOfficeExpenseDelete}
      >
        <Typography.Link style={{ marginRight: 8 }}>
          Beleg löschen
        </Typography.Link>
      </Popconfirm>
    ) : (
      <React.Fragment />
    ),
    <Button
      form="homeOfficeExpenseFormId"
      key="submit"
      htmlType="submit"
      type="default"
      loading={isButtonLoading}
    >
      Erstellen
    </Button>,
  ];

  return (
    <HomeOfficeExpenseModal
      title="Beleg für häusliches Arbeitzimmer hinzufügen"
      visible={visible}
      onCancel={handleCancel}
      withDeleteButton={!!calculatedInitialValues}
      footer={footer}
      centered
      width={1058}
    >
      <Form
        form={form}
        name="homeOfficeExpenseForm"
        id="homeOfficeExpenseFormId"
        onFinish={handleSubmit}
        autoComplete="off"
        layout="vertical"
        requiredMark={false}
        onValuesChange={handleFormValuesChange}
        initialValues={calculatedInitialValues}
      >
        <HomeOfficeExpenseForm
          onDropFiles={onDropFiles}
          assets={assets}
          onAssetDelete={onAssetDelete}
          totalGrossAmount={totalGrossAmount}
          totalNetAmount={totalNetAmount}
          totalVatAmount={totalVatAmount}
        />
      </Form>
    </HomeOfficeExpenseModal>
  );
};
