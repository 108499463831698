import { IUser } from "./IUser.type";

export enum UserDependentType {
  CHILD = "child",
  PARTNER = "partner",
  EMPTY = "",
}

export interface UserDependent {
  id?: string;
  firstName: string;
  lastName: string;
  deTaxId?: string;
  birthDate: string;
  marriageStartDate?: string;
  marriageEndDate?: string;
  type: UserDependentType;
}

export interface VatMetaNote {
  createdAt: string;
  updatedAt: string;
  message: string;
  creator: string;
  id: number;
  editable?: boolean;
}

export interface VatDeclaration {
  id: string;
  amount: number;
  year: number;
  period: string;
  uploadedAt: string;
}

export interface VatMeta {
  notes: VatMetaNote[];
  pastDeclarations: VatDeclaration[];
  user: IUser | null;
}
