import React from "react";
import { Typography } from "antd";

import { IUser } from "../../../../../../../../types";

const { Text } = Typography;

export interface UserAddressProps {
  user: IUser;
}

const UserAddress = ({ user }: UserAddressProps) => {
  return (
    <>
      <Text strong>
        {user.firstName} {user.lastName}
      </Text>
      <div>{user.street}</div>
      <div>
        {user.postCode} {user.city}
      </div>
    </>
  );
};

export default UserAddress;
