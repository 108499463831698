import { gql, MutationHookOptions, useMutation } from "@apollo/client";

import { BusinessAddress } from "../..";

export interface UpdateBusinessAddressInput {
  id: string;
  street: string;
  postCode: string;
  city: string;
  movingDate: Date;
}

export interface UpdateBusinessAddressMutationVariables {
  payload: UpdateBusinessAddressInput;
}

export interface UpdateBusinessAddressMutationResult {
  updateBusinessAddress: BusinessAddress;
}

export const UPDATE_BUSINESS_ADDRESS_MUTATION = gql`
  mutation updateBusinessAddress($payload: UpdateBusinessAddressInput!) {
    updateBusinessAddress(payload: $payload) {
      id
      street
      postCode
      city
      movingDate
    }
  }
`;

export const useUpdateBusinessAddressMutation = (
  options?: MutationHookOptions<
    UpdateBusinessAddressMutationResult,
    UpdateBusinessAddressMutationVariables
  >
) =>
  useMutation<
    UpdateBusinessAddressMutationResult,
    UpdateBusinessAddressMutationVariables
  >(UPDATE_BUSINESS_ADDRESS_MUTATION, options);
