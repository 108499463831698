import React from "react";
import { Spin, Typography } from "antd";
import styled from "styled-components";

import {
  EuerDeclaration,
  EuerDeclarationStatus,
  EuerDeclarationSubformType,
  IncomeTaxDeclaration,
  Questionnaire,
  TaxBoardUser,
  TradeTaxDeclaration,
  VatAnnualDeclaration,
} from "../../../../../api/graphql/schema.generated";
import { getSupportedQuestionnairesInfoForYear } from "../TaxPreparation/pages/utils";
import QuestionnaireCard from "../TaxPreparation/pages/TaxPreparationOverview/QuestionnaireCard";
import DeclarationCard from "./DeclarationCard";
import {
  EUER_DECLARATION_OVERVIEW_META_INFO,
  EUER_DECLARATION_ROOT_PATH,
  EUER_DECLARATION_STATUS_MAPPINGS,
  SUBFORM_STATUS_MAPPINGS,
} from "../EuerDeclaration/constants";
import { INCOME_TAX_DECLARATION_STATUS_MAPPINGS } from "../IncomeTaxDeclaration/constants";
import { TRADE_TAX_DECLARATION_STATUS_MAPPINGS } from "../TradeTaxDeclaration/constants";
import { VAT_ANNUAL_DECLARATION_STATUS_MAPPINGS } from "../VatAnnualDeclaration/constants";
import { useTaxBoardOverviewEuerDeclarationSubformsQuery } from "../../../../../api/graphql/queries/taxBoard/taxBoardOverviewEuerDeclarationSubforms.generated";
import { showGraphQlErrorNotification } from "../../../../../utils";
import { useQuestionnairesQuery } from "../../../../../api/graphql/queries/questionnaire/questionnaires.generated";

const { Title } = Typography;

const GRID_AREAS = ["a", "b", "c", "d"];
const CustomCardWrapper = styled.div<{ withTemplate?: boolean }>`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 16px;
  ${({ withTemplate }) =>
    withTemplate &&
    `
      grid-template-areas:
        "${GRID_AREAS[0]} ${GRID_AREAS[1]} ."
        "${GRID_AREAS[2]} ${GRID_AREAS[3]} .";
    `}
  margin-bottom: 30px;
`;

const DECLINE_REASON_HINT_TEXT = "Kundennachricht vorhanden";
const DELAYED_SUBMISSION_HINT_TEXT = "Zum letztmöglichen Zeitpunkt einreichen";
const IMMEDIATE_SUBMISSION_HINT_TEXT = "So früh wie möglich einreichen";

const getDeclarationCardHint = (
  declaration:
    | IncomeTaxDeclaration
    | TradeTaxDeclaration
    | VatAnnualDeclaration
    | EuerDeclaration
) => {
  const { status, declarationDeclines, declarationApproval } = declaration;
  let declarationHint = "";
  if (declarationApproval?.delaySubmission != null) {
    declarationHint = declarationApproval.delaySubmission
      ? DELAYED_SUBMISSION_HINT_TEXT
      : IMMEDIATE_SUBMISSION_HINT_TEXT;
  }

  if (
    declaration.__typename === "IncomeTaxDeclaration" &&
    declarationApproval !== null
  ) {
    declarationHint += `, Freigabe Partner: ${
      declarationApproval?.jointDeclaration ? "Ja" : "Nein"
    }`;
  }

  if (
    status === EuerDeclarationStatus.OBJECTED_BY_USER &&
    declarationDeclines?.length > 0
  ) {
    declarationHint = DECLINE_REASON_HINT_TEXT;
  }
  return declarationHint;
};

interface TaxCaseOverviewProps {
  taxBoardUser: TaxBoardUser;
  taxYear: number;
}

const EUER_SUBFORMS_META: Record<
  EuerDeclarationSubformType,
  { name: string; path: string; queryParams?: Record<string, string> }
> = {
  [EuerDeclarationSubformType.BUSINESS_ASSETS]:
    EUER_DECLARATION_OVERVIEW_META_INFO.businessAssetsSubform,
  [EuerDeclarationSubformType.CAR_USAGE]:
    EUER_DECLARATION_OVERVIEW_META_INFO.carUsageSubform,
  [EuerDeclarationSubformType.OFFICE_USAGE]:
    EUER_DECLARATION_OVERVIEW_META_INFO.officeUsageSubform,
  [EuerDeclarationSubformType.TRAVEL_EXPENSES]:
    EUER_DECLARATION_OVERVIEW_META_INFO.travelExpensesSubform,
};

const TaxCaseOverview = ({ taxBoardUser, taxYear }: TaxCaseOverviewProps) => {
  const euerDeclaration = taxBoardUser.euerDeclarations[0];
  const incomeTaxDeclaration = taxBoardUser.incomeTaxDeclarations[0];
  const tradeTaxDeclaration = taxBoardUser.tradeTaxDeclarations[0];
  const vatAnnualDeclaration = taxBoardUser.vatAnnualDeclarations[0];

  const { data: euerDeclarationSubformsData } =
    useTaxBoardOverviewEuerDeclarationSubformsQuery({
      skip: !taxBoardUser.email,
      variables: {
        email: taxBoardUser.email!,
        year: taxYear,
      },
      onError: (error) =>
        showGraphQlErrorNotification(
          "Something went wrong while retrieving the EUER declaration subforms",
          error
        ),
    });

  const { data: questionnairesData, loading: isLoadingQuestionnaires } =
    useQuestionnairesQuery({
      skip: !taxBoardUser.email,
      variables: {
        email: taxBoardUser.email!,
        year: taxYear,
      },
    });
  const questionnaires = questionnairesData?.questionnaires;

  const euerSubforms = euerDeclarationSubformsData?.euerDeclarationSubforms;

  if (!euerSubforms || !questionnaires) {
    return <Spin />;
  }

  return (
    <>
      {/* TAX DECLARATIONS */}
      <Title level={3} className="mb-3">{`Anlagen ${taxYear}`}</Title>
      <CustomCardWrapper withTemplate>
        <DeclarationCard
          title="Anlage EÜR inkl. AV"
          statusInfo={EUER_DECLARATION_STATUS_MAPPINGS[euerDeclaration.status]}
          path={EUER_DECLARATION_ROOT_PATH}
          gridArea={GRID_AREAS[0]}
          taxYear={taxYear}
          hint={getDeclarationCardHint(euerDeclaration)}
        />
        <DeclarationCard
          title="Umsatzsteuererklärung"
          statusInfo={
            VAT_ANNUAL_DECLARATION_STATUS_MAPPINGS[vatAnnualDeclaration.status]
          }
          path="vat-annual-declaration"
          gridArea={GRID_AREAS[1]}
          taxYear={taxYear}
          hint={getDeclarationCardHint(vatAnnualDeclaration)}
        />
        <DeclarationCard
          title="Gewerbesteuererklärung"
          statusInfo={
            TRADE_TAX_DECLARATION_STATUS_MAPPINGS[tradeTaxDeclaration.status]
          }
          path="trade-tax-declaration"
          gridArea={GRID_AREAS[2]}
          taxYear={taxYear}
          hint={getDeclarationCardHint(tradeTaxDeclaration)}
        />
        <DeclarationCard
          title="Einkommensteuererklärung"
          statusInfo={
            INCOME_TAX_DECLARATION_STATUS_MAPPINGS[incomeTaxDeclaration.status]
          }
          path="income-tax-declaration"
          gridArea={GRID_AREAS[3]}
          taxYear={taxYear}
          hint={getDeclarationCardHint(incomeTaxDeclaration)}
        />
      </CustomCardWrapper>

      {/* EUER SUBFORMS */}
      <Title level={3} className="mb-3">{`Unteranlagen EÜR ${taxYear}`}</Title>
      <CustomCardWrapper withTemplate>
        {[...euerSubforms]
          .sort((a, b) => a.type.localeCompare(b.type))
          .map((subform, index) => (
            <DeclarationCard
              key={`euer-subform.${subform.type}`}
              statusInfo={SUBFORM_STATUS_MAPPINGS[subform.status]}
              title={EUER_SUBFORMS_META[subform.type].name}
              path={EUER_SUBFORMS_META[subform.type].path!}
              queryParams={EUER_SUBFORMS_META[subform.type].queryParams}
              gridArea={GRID_AREAS[index]}
              taxYear={taxYear}
            />
          ))}
      </CustomCardWrapper>

      {/* QUESTIONNAIRES */}
      <Title level={3} className="mb-3">{`Fragebögen ${taxYear}`}</Title>
      <CustomCardWrapper>
        {getSupportedQuestionnairesInfoForYear(taxYear).map(
          (info, infoIndex) => {
            const matchedQuestionnaires = questionnaires.filter(
              ({ type }) => type === info.type
            );

            if (!matchedQuestionnaires.length && !info.showOnlyIfMatch) {
              return (
                <QuestionnaireCard
                  key={`${info.type}_${infoIndex}`}
                  taxYear={taxYear}
                  info={info}
                  questionnaire={undefined}
                  loading={isLoadingQuestionnaires}
                  detailedStatus
                />
              );
            }

            return matchedQuestionnaires.map((questionnaire, subIndex) => (
              <QuestionnaireCard
                key={`${info.type}_${infoIndex}_${subIndex}`}
                taxYear={taxYear}
                info={info}
                questionnaire={questionnaire as Questionnaire}
                loading={isLoadingQuestionnaires}
                detailedStatus
              />
            ));
          }
        )}
      </CustomCardWrapper>
    </>
  );
};

export default TaxCaseOverview;
