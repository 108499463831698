import * as GQL from "../api/graphql/schema.generated";
import { AccountingSource } from "./AccountingSource.type";
import { ITaxNumber } from "./ITaxNumber.type";
import { KontaxUserStatus } from "./KontaxUserStatus";
import { UserDependent } from "./UserDependent.type";
import { BookkeepingSource } from "./BookkeepingSource.type";

export enum CompanyType {
  EINZELUNTERNEHMER = "Einzelunternehmer",
  GEWERBETREIBENDER = "Gewerbetreibender",
  FREIBERUFLER = "Freiberufler",
}

export enum TradeTax {
  SONSTIGE_EINZELGEWERBETREIBENDE = "Sonstige Einzelgewerbetreibende",
  ANGEHORIGE_FREIEN_BERUFE = "Angehörige der freien Berufe",
}

export enum YesNoEnum {
  NO = "No",
  YES = "Yes",
}

export type Subscription = {
  type: string;
  recurlyPlanId: string;
  status: string;
};

export type VatYearSetting = {
  vatPaymentFrequency: PaymentFrequency;
  year: number;
  id?: number;
};

export enum InternationalCustomers {
  NONE = "none",
  EU = "EU",
  WORLDWIDE = "worldwide",
}

export enum CashTransactionsPercentage {
  NONE = "0",
  LESS_THAN_10 = "10",
  MORE_THAN_10 = "100",
}

export enum PermanentExtensionStatus {
  DOES_HAVE = "DOES_HAVE",
  DOES_NOT_HAVE = "DOES_NOT_HAVE",
  DOES_NOT_KNOW = "DOES_NOT_KNOW",
}

export enum PaymentFrequency {
  MONTHLY = "monthly",
  QUARTERLY = "quarterly",
  YEARLY = "yearly",
  NONE = "none",
  NONE_QUARTERLY = "none_quarterly",
}

export const PaymentFrequencyName: Record<GQL.PaymentFrequency, string> = {
  [GQL.PaymentFrequency.MONTHLY]: "Monthly",
  [GQL.PaymentFrequency.QUARTERLY]: "Quarterly",
  [GQL.PaymentFrequency.YEARLY]: "Yearly",
  [GQL.PaymentFrequency.NONE]: "None",
  [GQL.PaymentFrequency.NONE_QUARTERLY]: "Quarterly (None)",
};

export const SMALL_BUSINESS_PAYMENT_FREQUENCIES = [
  PaymentFrequency.NONE,
  PaymentFrequency.NONE_QUARTERLY,
];

export const QUARTERLY_PAYMENT_FREQUENCIES = [
  PaymentFrequency.QUARTERLY,
  PaymentFrequency.NONE_QUARTERLY,
];

export enum ThreeStateAnswer {
  YES = "YES",
  NO = "NO",
  NOT_SURE = "NOT_SURE",
}

export enum BusinessType {
  CONSULTING_COACHING = "Beratung/Coaching",
  IT_CONSULTING = "IT-Beratung",
  GRAPHIC_DESIGN_IT = "Grafikdesign (IT)",
  GRAPHIC_DESIGN_ART = "Grafikdesign (künstl.)",
  PHOTOGRAPHY = "Fotografie",
  FINANCIAL_SERVICES = "Finanzdienstleistungen",
  HEALTH_CARE = "Gesundheit/Pflege",
  CRAFT = "Handwerk",
  IT_PROGRAMMING = "IT/Programmierung",
  ARTIST = "Künstler",
  MUSIC_PRODUCTION = "Musikproduktion",
  MARKETING_PR_EVENT = "Marketing/PR/Event",
  SOCIAL_MEDIA_MANAGER = "Social-Media-Manager",
  TRANSLATOR = "Übersetzer",
  FORWARDING_AGENT_COURIER = "Spedition/Kurier",
  SALES = "Vertrieb/Verkauf",
  LAWYER = "Rechtsanwalt",
  WRITER = "Schriftsteller",
  MOVING_COMPANY = "Umzüge",
  OTHER_NON_TRADE = "Sonst. freib. Tätigkeit",
  OTHER_TRADE = "Sonst. gewerb. Tätigkeit",
  JOURNALIST = "Journalismus",
  MOVIE_INDUSTRY = "Filmindustrie",
  REAL_ESTATE_AGENT = "Immobilienmakler",
}

export enum KontaxPlan {
  KONTAX = "kontax",
  KONTAX_SB = "kontax_sb",
  ACCOUNTING = "accounting",
}

export enum VatExemptionWithItd {
  SECTION_4_NR_7 = "7",
}

export enum VatExemptionWithoutItd {
  SECTION_4_NR_8 = "4 Nr. 8",
  SECTION_4_NR_11 = "4 Nr. 11",
  SECTION_4_NR_14 = "4 Nr. 14",
  SECTION_4_NR_16 = "4 Nr. 16",
  SECTION_4_NR_20 = "4 Nr. 20",
  SECTION_4_NR_21 = "4 Nr. 21",
  SECTION_4_NR_22 = "4 Nr. 22",
}

export enum OnbStatus {
  NOT_SET = "NOT_SET",
  NOT_COMPLETED = "NOT_COMPLETED",
  COMPLETED = "COMPLETED",
  DATA_CHECK = "DATA_CHECK",
  READY_FOR_CALL = "READY_FOR_CALL",
  WAITING_FOR_USER = "WAITING_FOR_USER",
  WAITING_FOR_TAX_NUMBER = "WAITING_FOR_TAX_NUMBER",
  DECLINED_IN_CALL = "DECLINED_IN_CALL",
  DUNNING_BLOCK = "DUNNING_BLOCK",
  EXPIRED = "EXPIRED",
  USER_CANCELLATION = "USER_CANCELLATION",
  IN_REVIEW = "IN_REVIEW",
}

export type FibuFinalCheck = {
  id?: number;
  year: number;
  status: GQL.FibuFinalCheckStatus | null;
};

export type ProfitDetermination = {
  id?: number;
  year: number;
  typeOfProfit: TypeOfProfit;
};

export enum TypeOfProfit {
  NA = "N/A",
  EUR = "EÜR",
  BALANCE = "Bilanz",
}

interface BusinessAddress {
  city: string;
  postCode: string;
  street: string;
}

export interface IUser {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  language?: string;
  birthDate?: string;
  mobileNumber: number;
  untrustedPhoneNumber: number;
  accountId: number;
  taxNumber?: string;
  vatNumber?: string;
  vatYearSettings?: VatYearSetting[];
  kontaxStatus?: KontaxUserStatus;
  isDebitoorConnected: boolean;
  isFastbillConnected: boolean;
  isLexofficeConnected: boolean;
  profession?: string;
  accountingTool?: string;
  hasSecondBusinessAccount?: boolean;
  maximumCashTransactionsPercentage?: CashTransactionsPercentage;
  companyType?: CompanyType;
  hasEmployees?: boolean | null;
  internationalCustomers?: InternationalCustomers;
  permanentExtensionStatus?: PermanentExtensionStatus;
  taxAdvisoryTermsVersionAccepted?: boolean;
  taxAdvisoryTermsVersionAcceptedAt?: string;
  vatRate?: string;
  subjectToAccounting?: ThreeStateAnswer | null;
  workingInEcommerce?: boolean;
  hasMoreThanOneBusiness?: boolean | null;
  workAsHandyman?: boolean | null;
  deTaxId?: string;
  taxServiceOnboardingStartedAt?: string;
  accountState?: string;
  pastDue: boolean;
  pastDueSince?: string;
  recurlyBalance?: number;
  kontaxSubscriptionCanceledAt?: string;
  kontaxBillingStartDate?: string;
  businessTypeComment?: string;
  subscriptions: Subscription[];
  pendingKontaxPlan?: KontaxPlan;
  taxServiceOnboardingCompleted?: boolean;
  taxServiceOnboardingCompletedAt?: string;
  dependents: UserDependent[];
  fibuStartDate?: string;
  firstTaxDeclarationYear?: number | null;
  lastTaxDeclarationYear?: number | null;
  poaCoreDataCompleted: boolean;
  poaExportedAt?: string;
  poaSignedAt?: string;
  bookkeepingSources: BookkeepingSource[];
  kontaxUser?: {
    id?: number;
    finanzamtNotes?: string | null;
    tradeTax?: TradeTax;
    businessStartDate?: string | null;
    businessEndDate?: string | null;
    accountingSources?: AccountingSource[];
    createdInDatev?: boolean | null;
    businessType?: BusinessType | null;
    lastDeclarationDueDate?: Date | null;
    taxDeclarationPreviousYear?: number | null;
    newBusinessTaxNumberReceived?: boolean | null;
    taxRegistrationWithFaDone?: boolean | null;
    pipedriveData?: string | null;
    onbStatus?: OnbStatus | null;
    businessAddresses?: BusinessAddress[];
  };
  city: string;
  street: string;
  postCode: string;
  accountIban: string;
  vatPaymentFrequency: string;
  balance: number;
  lockingStatus: string;
  lockingReasons: string[];
  fibuFinalChecks: FibuFinalCheck[];
  profitDeterminations: ProfitDetermination[];
  missingBusinessTaxNumberNote: string | null;
  missingPersonalTaxNumberNote: string | null;
  hasBusinessTaxNumber: boolean;
  hasPersonalTaxNumber: boolean;
  vatExemptionWithItd?: VatExemptionWithItd | null;
  vatExemptionWithoutItd?: VatExemptionWithoutItd | null;
}

export interface UserRow {
  key: number;
  name: string;
  fibuStartDate?: string | null;
  user: IUser;
}

export type IUserDetails = Partial<
  Omit<IUser, "taxNumber"> & { taxNumber: ITaxNumber } & {
    bookkeepingSource: BookkeepingSource;
  }
>;
