import { gql, MutationHookOptions, useMutation } from "@apollo/client";

import { AccountingSourceType } from "../../../../types/AccountingSource.type";

export interface UpsertAccountingSourceInput {
  type: AccountingSourceType;
  year: number;
}

export interface UpsertAccountingSourceVariables {
  email: string;
  payload: UpsertAccountingSourceInput;
}

export const UPSERT_ACCOUNTING_SOURCE_MUTATION = gql`
  mutation UpsertAccountingSource(
    $email: String!
    $payload: UpsertAccountingSourceInput!
  ) {
    upsertAccountingSource(email: $email, payload: $payload) {
      year
      type
    }
  }
`;

export interface UpsertAccountingSourceResult {
  upsertAccountingSource: {
    year: number;
    type: AccountingSourceType;
  };
}

export const useUpsertAccountingSourceMutation = (
  options?: MutationHookOptions<
    UpsertAccountingSourceResult,
    UpsertAccountingSourceVariables
  >
) =>
  useMutation<UpsertAccountingSourceResult, UpsertAccountingSourceVariables>(
    UPSERT_ACCOUNTING_SOURCE_MUTATION,
    options
  );
