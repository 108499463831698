import styled from "styled-components";
import { Card } from "antd";

export const CardWrapper = styled(Card)`
  margin-bottom: 20px;
`;

export const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Middle = styled.div`
  flex-grow: 1;
`;

export const Container = styled.div`
  padding: 20px 40px;
  overflow: scroll;
  height: 100%;
  width: 100%;
`;
