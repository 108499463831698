import type * as Types from "../../schema.generated";

import { gql } from "@apollo/client";
import { KontaxTransactionFragmentDoc } from "../../fragments/kontaxTransaction.generated";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type AddBusinessAssetToTransactionMutationVariables = Types.Exact<{
  businessAssetForm: Types.BusinessAssetForm;
  transactionId: Types.Scalars["String"];
  categoryCode: Types.Scalars["String"];
  vatCategoryCode: Types.Scalars["String"];
}>;

export type AddBusinessAssetToTransactionMutation = {
  __typename?: "Mutation";
  addBusinessAssetToTransaction: {
    __typename?: "KontaxTransaction";
    id: string;
    amount: number;
    valutaDate: string;
    verified: boolean;
    verifiedBy?: string | null;
    name?: string | null;
    iban?: string | null;
    description?: string | null;
    merchantCategoryCode?: string | null;
    foreignCurrency?: string | null;
    escalated?: boolean | null;
    escalationNote?: string | null;
    internalNote?: string | null;
    businessTypeComment?: string | null;
    personalNote?: string | null;
    invoiceRequestedAt?: string | null;
    email: string;
    firstName: string;
    lastName: string;
    categoryCode?: string | null;
    vatCategoryCode?: string | null;
    hasIntegrationDocument?: boolean | null;
    isSplitCategorized?: boolean | null;
    vatYearPaymentFrequency?: Types.PaymentFrequency | null;
    source: string;
    categoryCodeMeta?: {
      __typename?: "ValueMeta";
      label?: string | null;
      description?: string | null;
      categorizationType?: string | null;
      suggestionSource?: string | null;
    } | null;
    vatCategoryCodeMeta?: {
      __typename?: "ValueMeta";
      label?: string | null;
      description?: string | null;
      categorizationType?: string | null;
      suggestionSource?: string | null;
    } | null;
    assets: Array<{
      __typename?: "AssetData";
      id: string;
      filetype: string;
      fullsize: string;
    }>;
    businessAssets: Array<{
      __typename?: "BusinessAsset";
      businessAssetableId: string;
      businessAssetableType: string;
      assetType: Types.AssetType;
      assetClass: string;
      purchaseDate: string;
      amount: number;
      depreciationPeriodYears: number;
      note?: string | null;
    }>;
  };
};

export const AddBusinessAssetToTransactionDocument = gql`
  mutation addBusinessAssetToTransaction(
    $businessAssetForm: BusinessAssetForm!
    $transactionId: String!
    $categoryCode: String!
    $vatCategoryCode: String!
  ) {
    addBusinessAssetToTransaction(
      businessAssetForm: $businessAssetForm
      transactionId: $transactionId
      categoryCode: $categoryCode
      vatCategoryCode: $vatCategoryCode
    ) {
      ...KontaxTransaction
    }
  }
  ${KontaxTransactionFragmentDoc}
`;
export type AddBusinessAssetToTransactionMutationFn = Apollo.MutationFunction<
  AddBusinessAssetToTransactionMutation,
  AddBusinessAssetToTransactionMutationVariables
>;

/**
 * __useAddBusinessAssetToTransactionMutation__
 *
 * To run a mutation, you first call `useAddBusinessAssetToTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddBusinessAssetToTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addBusinessAssetToTransactionMutation, { data, loading, error }] = useAddBusinessAssetToTransactionMutation({
 *   variables: {
 *      businessAssetForm: // value for 'businessAssetForm'
 *      transactionId: // value for 'transactionId'
 *      categoryCode: // value for 'categoryCode'
 *      vatCategoryCode: // value for 'vatCategoryCode'
 *   },
 * });
 */
export function useAddBusinessAssetToTransactionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddBusinessAssetToTransactionMutation,
    AddBusinessAssetToTransactionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddBusinessAssetToTransactionMutation,
    AddBusinessAssetToTransactionMutationVariables
  >(AddBusinessAssetToTransactionDocument, options);
}
export type AddBusinessAssetToTransactionMutationHookResult = ReturnType<
  typeof useAddBusinessAssetToTransactionMutation
>;
export type AddBusinessAssetToTransactionMutationResult =
  Apollo.MutationResult<AddBusinessAssetToTransactionMutation>;
export type AddBusinessAssetToTransactionMutationOptions =
  Apollo.BaseMutationOptions<
    AddBusinessAssetToTransactionMutation,
    AddBusinessAssetToTransactionMutationVariables
  >;
