export interface Codec<A, B> {
  readonly decode: {
    (a: A): B;
    (a: unknown): B | undefined;
  };
  readonly encode: {
    (b: B): A;
    (b: unknown): A | undefined;
  };
}

/**
 * Utility to assist in mapping two sets of values between each other.
 * Returns undefined if mapping is missing.
 * Returns latest defined mapping in case of duplicates.
 * Should be used with `as const` mapping assertion for strict types.
 *
 * @example
 *
 * enum A {
 *   X,
 *   Y,
 * }
 *
 * const codec = createCodec([
 *   [A.X, "abc"],
 *   [A.Y, "xyz"],
 *   [A.Y, "xyz-duplicate"],
 * ] as const);
 *
 * codec.decode(A.X); // "abc"
 * codec.decode(A.Y); // "xyz-duplicate" - not "xyz"
 *
 * codec.encode("xyz"); // A.Y
 * codec.encode("does not exist"); // undefined
 */
export function createCodec<T extends readonly [unknown, unknown]>(
  mapping: ReadonlyArray<T>
): Codec<T[0], T[1]> {
  const decodeMapping = new Map(mapping);
  const encodeMapping = new Map(mapping.map(([a, b]) => [b, a]));

  return {
    decode: (a) => decodeMapping.get(a),
    encode: (b) => encodeMapping.get(b),
  };
}
