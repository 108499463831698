import React, { ReactNode } from "react";
import Badge from "react-bootstrap/Badge";

import { Tooltip } from "antd";

import { ValueCategorizationType, ValueMeta } from "../../../types";

import { SelectBadgeContainer } from "./styledComponents";

export const getLabel = (meta?: ValueMeta) => {
  if (!meta?.categorizationType && !meta?.label) {
    return undefined;
  }

  // prioritize Booked by ML
  if (meta?.categorizationType === ValueCategorizationType.BOOKED_BY_ML) {
    return "Booked by ML";
  }

  // we display rule id when type is RECURRING_PAYMENT or AUTOMATIC_KONTAX_RULE
  if (meta?.label) {
    return meta.label;
  }

  switch (meta?.categorizationType) {
    case ValueCategorizationType.TAX_OPS:
      return "Tax Ops";
    case ValueCategorizationType.USER_OVERWRITE:
      return "User";
    case ValueCategorizationType.STANDING_ORDER:
      return "Standing order";
    case ValueCategorizationType.INVOICING:
      return "Invoicing";
    case ValueCategorizationType.DATEV:
      return "Datev";
    case ValueCategorizationType.SCRIPT:
    case ValueCategorizationType.SCRIPT_UNKNOWN:
      return "Script";
    case ValueCategorizationType.PROPAGATED:
      return "Propagated";
  }
};

export const getVariant = (meta?: ValueMeta) => {
  if (!meta?.categorizationType && !meta?.suggestionSource) {
    return undefined;
  }

  switch (meta.categorizationType) {
    case ValueCategorizationType.INVOICING:
    case ValueCategorizationType.STANDING_ORDER:
    case ValueCategorizationType.AUTOMATIC_KONTAX_RULE:
      return "primary";

    case ValueCategorizationType.BOOKED_BY_ML:
      return "danger";
  }

  return "secondary";
};

const getLink = (meta?: ValueMeta): string | undefined => {
  if (
    !meta ||
    ![
      ValueCategorizationType.AUTOMATIC_KONTAX_RULE,
      ValueCategorizationType.RECURRING_PAYMENT,
    ].includes(meta.categorizationType as ValueCategorizationType)
  ) {
    return;
  }

  const ruleId = meta.label?.match(/\d+/)?.[0];
  if (ruleId) {
    return `/rules-view/${ruleId}`;
  }
};

const SelectBadge = ({
  meta,
  info,
  hasValue,
}: {
  meta?: ValueMeta;
  info?: string;
  hasValue?: boolean;
}) => {
  const variant = getVariant(meta);
  const label = getLabel(meta);
  const description = meta?.description;
  const link = getLink(meta);

  return (
    <SelectBadgeContainer>
      {info && (
        <Badge variant="info" className="mb-1">
          {info}
        </Badge>
      )}
      {meta && hasValue && (
        <WithOptionalLink href={link}>
          <Tooltip placement="topLeft" title={description}>
            <Badge
              variant={variant}
              className="mb-1"
              data-test="classificationBadge"
            >
              {label}
            </Badge>
          </Tooltip>
        </WithOptionalLink>
      )}
    </SelectBadgeContainer>
  );
};

function WithOptionalLink(props: { href?: string; children: ReactNode }) {
  return props.href ? (
    <a href={props.href} target="_blank" rel="noreferrer">
      {props.children}
    </a>
  ) : (
    <>{props.children}</>
  );
}

export default SelectBadge;
