import React, { ChangeEvent } from "react";

import { StyledTextArea } from "./styledComponents";
import { NOTE_PLACEHOLDER } from "../texts";

type NoteProps = {
  note?: string | null;
  setNote: (updatedNote: string) => void;
};

const Note = ({ note, setNote }: NoteProps) => {
  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) =>
    setNote(event.target.value);

  return (
    <StyledTextArea
      rows={4}
      showCount
      maxLength={2000}
      onChange={handleChange}
      value={note || ""}
      placeholder={NOTE_PLACEHOLDER}
    />
  );
};

export default Note;
