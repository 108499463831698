import { Form } from "antd";

import CategorySelect from "../../TransactionView/CategorySelect";
import { BusinessAssetForm, PaymentFrequency } from "../../../../types";
import VatCategorySelect from "../../TransactionView/VatCategorySelect";
import ExternalBusinessAsset from "./ExternalBusinessAsset";
import { businessAssetValidator } from "./utils";
import { BUSINESS_ASSET_CATEGORIES } from "../../../../utils";

type AddBusinessAssetFormProps = {
  onChangeCategory: (categoryCode: string | null) => void;
  onVatCategorySelected: (category: string | null) => void;
  onChangeBusinessAsset: (businessAssetForm: BusinessAssetForm) => void;
  vatYearPaymentFrequency?: PaymentFrequency;
};

const AddBusinessAssetForm = ({
  onChangeCategory,
  onVatCategorySelected,
  onChangeBusinessAsset,
  vatYearPaymentFrequency,
}: AddBusinessAssetFormProps) => {
  return (
    <div>
      <Form.Item noStyle shouldUpdate>
        {({ getFieldValue }) => (
          <div>
            <Form.Item
              label="Category"
              name="categoryCode"
              rules={[{ required: true, message: "Please select a category" }]}
            >
              <CategorySelect
                transactionAmount={-1}
                onChangeHandler={onChangeCategory}
                style={{ width: "100%" }}
                options={BUSINESS_ASSET_CATEGORIES}
              />
            </Form.Item>
            {getFieldValue("businessAssetForm") != null && (
              <Form.Item
                name="businessAssetForm"
                rules={[{ validator: businessAssetValidator }]}
              >
                <ExternalBusinessAsset
                  businessAssetForm={getFieldValue("businessAssetForm")}
                  categoryCode={getFieldValue("categoryCode")}
                  onChange={onChangeBusinessAsset}
                  isSubmitClicked={false}
                />
              </Form.Item>
            )}
          </div>
        )}
      </Form.Item>
      <Form.Item noStyle shouldUpdate>
        {({ getFieldValue }) => (
          <Form.Item
            label="Vat category"
            name="vatCategoryCode"
            rules={[{ required: true, message: "Please select vat category" }]}
          >
            <VatCategorySelect
              value={getFieldValue("vatCategoryCode")}
              onChangeHandler={onVatCategorySelected}
              kontaxCategory={getFieldValue("categoryCode")}
              style={{ width: "100%" }}
              vatYearPaymentFrequency={vatYearPaymentFrequency}
            />
          </Form.Item>
        )}
      </Form.Item>
    </div>
  );
};

export default AddBusinessAssetForm;
