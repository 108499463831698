import type * as Types from "../../schema.generated";

import { gql } from "@apollo/client";
import { AutocategorizationRuleFragmentDoc } from "../../fragments/autocategorizationRule.generated";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type CreateAutocategorizationRuleMutationVariables = Types.Exact<{
  input: Types.CreateAutocategorizationRuleInput;
}>;

export type CreateAutocategorizationRuleMutation = {
  __typename?: "Mutation";
  createAutocategorizationRule: {
    __typename?: "CreateAutocategorizationRulePayload";
    autocategorizationRule: {
      __typename?: "AutocategorizationRule";
      id: number;
      description: string;
      enabled: boolean;
      priority: number;
      categoryCode?: Types.CategoryCode | null;
      vatCategoryCode?: Types.VatCategoryCode | null;
      verify: boolean;
      accuracy?: number | null;
      appliedTimesCount?: number | null;
      conditions: {
        __typename?: "AutocategorizationRuleConditions";
        accountIds?: Array<number> | null;
        hasEmployees?: boolean | null;
        hasInvoices?: boolean | null;
        hasVatNumber?: boolean | null;
        ibans?: Array<string> | null;
        vatPaymentFrequencies?: Array<Types.PaymentFrequency> | null;
        amount?: {
          __typename?: "RuleConditionNumber";
          op: Types.RuleConditionNumberOp;
          value: number;
        } | null;
        description?: {
          __typename?: "RuleConditionString";
          op: Types.RuleConditionStringOp;
          value: string;
        } | null;
        name?: {
          __typename?: "RuleConditionString";
          op: Types.RuleConditionStringOp;
          value: string;
        } | null;
      };
    };
  };
};

export const CreateAutocategorizationRuleDocument = gql`
  mutation createAutocategorizationRule(
    $input: CreateAutocategorizationRuleInput!
  ) {
    createAutocategorizationRule(input: $input) {
      autocategorizationRule {
        ...AutocategorizationRule
      }
    }
  }
  ${AutocategorizationRuleFragmentDoc}
`;
export type CreateAutocategorizationRuleMutationFn = Apollo.MutationFunction<
  CreateAutocategorizationRuleMutation,
  CreateAutocategorizationRuleMutationVariables
>;

/**
 * __useCreateAutocategorizationRuleMutation__
 *
 * To run a mutation, you first call `useCreateAutocategorizationRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAutocategorizationRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAutocategorizationRuleMutation, { data, loading, error }] = useCreateAutocategorizationRuleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAutocategorizationRuleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAutocategorizationRuleMutation,
    CreateAutocategorizationRuleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateAutocategorizationRuleMutation,
    CreateAutocategorizationRuleMutationVariables
  >(CreateAutocategorizationRuleDocument, options);
}
export type CreateAutocategorizationRuleMutationHookResult = ReturnType<
  typeof useCreateAutocategorizationRuleMutation
>;
export type CreateAutocategorizationRuleMutationResult =
  Apollo.MutationResult<CreateAutocategorizationRuleMutation>;
export type CreateAutocategorizationRuleMutationOptions =
  Apollo.BaseMutationOptions<
    CreateAutocategorizationRuleMutation,
    CreateAutocategorizationRuleMutationVariables
  >;
