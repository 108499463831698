import React, { useState, useEffect } from "react";
import { Button, Carousel } from "antd";
import { RotateRightOutlined } from "@ant-design/icons";

import { CarouselWrapper } from "./styledComponents";
import { RotateReceiptButtonWrapper } from "./styledComponents";
import { Asset } from "../../../types";
import AssetPreview from "./Invoice";

interface SliderProps {
  assets: Array<Asset>;
  id: string;
}

const ImageCarousel = ({ assets, id }: SliderProps) => {
  const [assetList, setAssetList] = useState(
    assets.map((asset) => ({ ...asset, rotationAngle: 0 }))
  );

  useEffect(() => {
    setAssetList((assetList) =>
      assets.map((asset, index) => ({
        ...asset,
        rotationAngle: assetList[index]?.rotationAngle || 0,
      }))
    );
  }, [assets]);

  const rotateAssetImage = () => {
    setAssetList(
      assetList.map((asset, index) =>
        index === currentAssetIndex
          ? { ...asset, rotationAngle: (asset.rotationAngle + 90) % 360 }
          : asset
      )
    );
  };

  const [currentAssetIndex, setCurrentAssetIndex] = useState(0);

  const settings = {
    customPaging: (index: number) => {
      return <div>{index + 1}</div>;
    },
    afterChange: setCurrentAssetIndex,
  };

  return (
    <div style={{ position: "relative", width: "100%" }}>
      <CarouselWrapper>
        <Carousel {...settings}>
          {assetList.map((asset, index) => {
            const imageId = `slide-${index + 1}-${id}`;
            return (
              <div key={index} id={imageId} className="imageWrapper">
                <AssetPreview asset={asset} />
              </div>
            );
          })}
        </Carousel>
      </CarouselWrapper>
      <RotateReceiptButtonWrapper>
        {assetList[currentAssetIndex] &&
          assetList[currentAssetIndex].filetype !== "pdf" && (
            <Button
              shape="circle"
              size="large"
              icon={<RotateRightOutlined />}
              onClick={rotateAssetImage}
            />
          )}
      </RotateReceiptButtonWrapper>
    </div>
  );
};

export default ImageCarousel;
