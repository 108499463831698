import React, { useState } from "react";
import styled from "styled-components";
import { Button, Card, notification } from "antd";
import { PlusOutlined } from "@ant-design/icons";

import { EditableNote } from "./EditableNote";

const Wrapper = styled.div`
  display: flex;
  gap: 24px;
`;

export const NoteSection = ({
  title,
  onSave,
  initialValue = "",
  personalNote,
  addButtonLabel,
  maxLength,
  uneditable,
}: {
  title?: string;
  onSave: (note: string) => Promise<boolean>;
  initialValue: string;
  personalNote?: string | undefined | null;
  addButtonLabel?: string;
  maxLength?: number;
  uneditable?: boolean;
}) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [note, setNote] = useState<string>(initialValue);

  const testId = "internalNote";

  const onSaveNote = async (value: string) => {
    // Errors are handled on the onSave function.
    const success = await onSave(value);
    setIsEditMode(false);
    if (success) {
      notification.success({ message: value ? "Note saved" : "Note deleted" });
    }
  };

  return (
    <Wrapper data-test={testId}>
      {!note && !isEditMode ? (
        <Button
          icon={<PlusOutlined />}
          size="large"
          type="default"
          onClick={() => setIsEditMode(true)}
          data-test="addNoteButton"
          style={{
            display: "flex",
            alignItems: "center",
            border: "1px dashed #D9D9D9",
          }}
          disabled={uneditable}
        >
          {addButtonLabel ?? "Add internal note"}
        </Button>
      ) : (
        <EditableNote
          value={note}
          onSave={async () => {
            onSaveNote(note);
          }}
          onChange={(value) => setNote(value)}
          onDelete={() => {
            setNote("");
            onSaveNote("");
          }}
          onCancel={() => {
            setIsEditMode(false);
            setNote(initialValue);
          }}
          setEditMode={() => {
            setIsEditMode(true);
          }}
          isEditMode={isEditMode}
          title={title}
          maxLength={maxLength}
          uneditable={uneditable}
        />
      )}
      {!!personalNote && (
        <Card
          data-test="personalNote"
          size="small"
          title="User’s note"
          style={{ width: 400 }}
        >
          {personalNote}
        </Card>
      )}
    </Wrapper>
  );
};
