import React from "react";
import { Typography } from "antd";

const { Text } = Typography;

export interface TransactionsSummaryProps {
  transactionsCount: number;
  uncategorizedCount: number;
  verifiedTransactionsCount: number;
}

const TransactionsSummary = ({
  transactionsCount,
  uncategorizedCount,
  verifiedTransactionsCount,
}: TransactionsSummaryProps) => {
  return (
    <>
      <Text strong>Transaktionen</Text>
      <div>Kategorisiert: {transactionsCount}</div>
      <div>Unkategorisiert: {uncategorizedCount}</div>
      <div>Verifiziert: {verifiedTransactionsCount}</div>
    </>
  );
};

export default TransactionsSummary;
