import React from "react";
import isNumber from "lodash/isNumber";

import BusinessAsset from "../pages/TransactionView/BusinessAsset";
import { isImmovableAsset } from "../BusinessAsset/utils";
import {
  BusinessAssetFormField,
  BusinessAssetType,
  DepreciableCategory,
  TransactionSplit,
} from "../../types";
import { LATEST_POSSIBLE_PURCHASE_DATE } from "../BusinessAsset/constants";
import immovableAssets from "../BusinessAsset/immovableAssets";
import movableAssets from "../BusinessAsset/movableAssets.json";

const BusinessAssetContainer = ({
  isSubmitClicked,
  onChange,
  record,
}: {
  isSubmitClicked: boolean;
  onChange: (split: Partial<TransactionSplit>) => void;
  record: Partial<TransactionSplit>;
}) => {
  const onAssetTypeChangeHandler = (value: string) => {
    onChange({
      ...record,
      businessAssetForm: {
        ...record.businessAssetForm,
        assetType: value as BusinessAssetType,
        // On every asset type change,
        // reset assetClassCustom and depreciationYears values
        assetClassCustom: "",
        // Only set depreciationYears field when depreciationYearsEditable is true,
        // otherwise depreciationYears value is 0 and not affected by asset type change
        ...(record.businessAssetForm?.depreciationYearsEditable && {
          depreciationYears: "",
        }),
      },
    });
  };

  const onFormFieldChangeHandler = (
    field: BusinessAssetFormField,
    value: string
  ) =>
    onChange({
      ...record,
      businessAssetForm: {
        ...record.businessAssetForm,
        [field]: value,
      },
    });

  const onAssetClassChangeHandler = (value: string) => {
    const assets = isImmovableAsset(record.businessAssetForm?.assetType)
      ? immovableAssets
      : movableAssets;

    const selectedAssetClass = assets.find(
      (asset) => asset.assetClass === value
    );

    const depreciationYears = isNumber(selectedAssetClass?.depreciationYears)
      ? selectedAssetClass!.depreciationYears.toString()
      : "";

    onChange({
      ...record,
      businessAssetForm: {
        ...record.businessAssetForm,
        assetClass: value,
        assetClassCustom: "",
        // Only set depreciationYears field when depreciationYearsEditable is true,
        // otherwise depreciationYears value is 0 and not affected by asset class change
        ...(selectedAssetClass &&
          record.businessAssetForm?.depreciationYearsEditable && {
            depreciationYears,
          }),
      },
    });
  };

  /**
   * Updates the form data based on the provided field name and value (on the event).
   */
  const onBusinessAssetFormDataChange = (
    field: BusinessAssetFormField,
    value: string
  ) => {
    switch (field) {
      case BusinessAssetFormField.ASSET_CLASS:
        onAssetClassChangeHandler(value);
        break;
      case BusinessAssetFormField.ASSET_TYPE:
        onAssetTypeChangeHandler(value);
        break;
      // For assetClass, purchaseDate, depreciationYears & note handling is more generic.
      case BusinessAssetFormField.ASSET_CLASS_CUSTOM:
      case BusinessAssetFormField.PURCHASE_DATE:
      case BusinessAssetFormField.DEPRECIATION_YEARS:
      case BusinessAssetFormField.NOTE:
        onFormFieldChangeHandler(field, value);
        break;
    }
  };

  return (
    <BusinessAsset
      businessAssetFormData={{
        assetType:
          record.businessAssetForm?.assetType ||
          BusinessAssetType.MOVABLE_OTHERS,
        assetClass: record.businessAssetForm?.assetClass || "",
        assetClassCustom: record.businessAssetForm?.assetClassCustom || "",
        purchaseDate: record.businessAssetForm?.purchaseDate,
        purchaseDateMax: LATEST_POSSIBLE_PURCHASE_DATE,
        depreciationYears: record.businessAssetForm?.depreciationYears || "",
        depreciationYearsEditable:
          record.businessAssetForm?.depreciationYearsEditable ||
          record.categoryCode === DepreciableCategory.OVER_800,
        note: record.businessAssetForm?.note || "",
      }}
      onFieldChange={onBusinessAssetFormDataChange}
      isSubmitClicked={isSubmitClicked}
    />
  );
};

export default BusinessAssetContainer;
