import type * as Types from "../../schema.generated";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type FibuFinalCheckTasksQueryVariables = Types.Exact<{
  email: Types.Scalars["String"];
  year: Types.Scalars["Int"];
}>;

export type FibuFinalCheckTasksQuery = {
  __typename?: "Query";
  fibuFinalCheckTasks?: Array<{
    __typename?: "FibuFinalCheckTask";
    type: Types.FibuFinalCheckTaskType;
    status: Types.FibuFinalCheckTaskStatus;
  }> | null;
};

export const FibuFinalCheckTasksDocument = gql`
  query FibuFinalCheckTasks($email: String!, $year: Int!) {
    fibuFinalCheckTasks(email: $email, year: $year) {
      type
      status
    }
  }
`;

/**
 * __useFibuFinalCheckTasksQuery__
 *
 * To run a query within a React component, call `useFibuFinalCheckTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useFibuFinalCheckTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFibuFinalCheckTasksQuery({
 *   variables: {
 *      email: // value for 'email'
 *      year: // value for 'year'
 *   },
 * });
 */
export function useFibuFinalCheckTasksQuery(
  baseOptions: Apollo.QueryHookOptions<
    FibuFinalCheckTasksQuery,
    FibuFinalCheckTasksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FibuFinalCheckTasksQuery,
    FibuFinalCheckTasksQueryVariables
  >(FibuFinalCheckTasksDocument, options);
}
export function useFibuFinalCheckTasksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FibuFinalCheckTasksQuery,
    FibuFinalCheckTasksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FibuFinalCheckTasksQuery,
    FibuFinalCheckTasksQueryVariables
  >(FibuFinalCheckTasksDocument, options);
}
export type FibuFinalCheckTasksQueryHookResult = ReturnType<
  typeof useFibuFinalCheckTasksQuery
>;
export type FibuFinalCheckTasksLazyQueryHookResult = ReturnType<
  typeof useFibuFinalCheckTasksLazyQuery
>;
export type FibuFinalCheckTasksQueryResult = Apollo.QueryResult<
  FibuFinalCheckTasksQuery,
  FibuFinalCheckTasksQueryVariables
>;
