import {
  RuleConditionNumber,
  RuleConditionNumberOp,
} from "../../../api/graphql/schema.generated";
import { Direction, IRule } from "../../../types";

export const getLocaleAmountString = (value: string): string => {
  return value
    .replace(/\./g, ",") // replace all "." with ","
    .replace(/\B(?=(\d{3})+(?!\d))/g, "."); // separate 3 consecutive digits with "."
};

export const getAmountFromString = (value: string): string => {
  return value
    .replace(/\.$/, ",") // replace "." at the end with ","
    .replace(/\./g, "") // remove all "."
    .replace(/,/g, ".") // replace commas with dots
    .replace(/[^0-9\\.]/g, "") // allow only digits and "."
    .replace(/(.*\..*)(\.)/g, "$1") // allow only one "." per value
    .replace(/(\.\d{2})(\d+)/g, "$1") // allow only 2 digits after "."
    .replace(/^\./g, "0."); // bonus: if "." is the first symbol add "0" before it
};

export const getFilteredRules = (rules: IRule[], accountId: string) =>
  rules.filter(
    ({ conditions }) =>
      !!conditions &&
      (conditions.indexOf(`"accountId","value":{"eq":${accountId}}`) > -1 ||
        conditions
          .match(/"accountId","value":{"in":\[((\d+),?)*\]/)
          ?.shift()
          ?.match(/\d+/g)
          ?.includes(accountId))
  );

export const INVERSE_CONDITION_NUMBER_OP = {
  [RuleConditionNumberOp.LT]: RuleConditionNumberOp.GT,
  [RuleConditionNumberOp.EQ]: RuleConditionNumberOp.EQ,
  [RuleConditionNumberOp.GT]: RuleConditionNumberOp.LT,
};

export function encodeFormAmountCondition(
  direction: Direction,
  amount?: {
    op: RuleConditionNumberOp;
    value?: string;
  }
): RuleConditionNumber | Error {
  if (!amount?.value) {
    return {
      op:
        direction === Direction.OUTGOING
          ? RuleConditionNumberOp.LT
          : RuleConditionNumberOp.GT,
      value: 0,
    };
  }

  const valueInCents = parseValueInCents({
    op: amount.op,
    value: amount.value,
  });
  if (valueInCents instanceof Error) {
    return valueInCents;
  }

  switch (direction) {
    case Direction.INCOMING:
      return {
        op: amount.op,
        value: valueInCents,
      };

    case Direction.OUTGOING:
      return {
        op: INVERSE_CONDITION_NUMBER_OP[amount.op],
        value: -valueInCents,
      };
  }
}

function parseValueInCents(amount: {
  op: RuleConditionNumberOp;
  value: string;
}): number | Error {
  const valueInCents = Math.round(parseFloat(amount.value) * 100);

  if (isNaN(valueInCents)) {
    return new Error(`Amount value is invalid: '${amount.value}'`);
  }

  const minValueInCents = amount.op === RuleConditionNumberOp.LT ? 2 : 1;

  if (valueInCents < minValueInCents) {
    return new Error(
      `Amount value should be greater than ${(minValueInCents - 1) / 100}`
    );
  }

  return valueInCents;
}
