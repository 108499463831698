import React, { useState, useRef, useLayoutEffect } from "react";

import { Asset } from "../../../types";
import magnify from "./magnify";
import { AssetImage } from "./styledComponents";

const AssetPreview = ({ asset }: { asset: Asset }) => {
  const { filetype, fullsize } = asset;

  const [imageLoaded, setImageLoaded] = useState(false);
  const imageRef = useRef<HTMLImageElement>(null);

  useLayoutEffect(() => {
    const image = imageRef.current;
    if (!image || !image.width || !image.height) return;

    const parent = image.parentElement;
    if (!parent) return;

    let scale = 1;

    let imageWidth = image.offsetWidth;
    let imageHeight = image.offsetHeight;

    if ((asset.rotationAngle || 0) % 180 !== 0) {
      imageWidth = image.offsetHeight;
      imageHeight = image.offsetWidth;
    }

    scale = Math.min(
      parent.offsetWidth / imageWidth,
      parent.offsetHeight / imageHeight
    );

    image.style.transform = `rotate(${
      asset.rotationAngle || 0
    }deg) scale(${scale})`;

    const magnifier = magnify(image, 3, asset.rotationAngle, scale);

    return () => {
      magnifier.dispose();
    };
  }, [asset.rotationAngle, imageLoaded]);

  return (
    <div className="assetContainer">
      {filetype === "pdf" ? (
        <embed src={fullsize} width="100%" height="100%" />
      ) : (
        <AssetImage
          ref={imageRef}
          src={fullsize}
          alt="invoice"
          onLoad={(e: React.SyntheticEvent<HTMLImageElement>) => {
            setImageLoaded(true);
          }}
          className="invoice"
        />
      )}
    </div>
  );
};

export default AssetPreview;
