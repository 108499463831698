import { Divider } from "antd";

import { useTransactionVerifiedByMlQuery } from "../../../api/graphql/queries/transaction/transactionVerifiedByML.generated";
import {
  HalfWidthContainer,
  Container,
  NotFoundContainer,
  SpinnerContainer,
} from "./styledComponents";
import AnnotationInput from "./AnnotationInput";
import Assets from "./Assets";
import TransactionDetails from "./TransactionDetails";
import { SpinnerBasic } from "../../common/Spinner";
import FeedbackForm from "./FeedbackForm";

const FeedbackView = () => {
  const {
    data,
    loading: isFetching,
    refetch,
  } = useTransactionVerifiedByMlQuery();
  const transaction = data?.transactionVerifiedByML;

  if (!transaction) {
    return <NotFoundContainer>Transaction not found</NotFoundContainer>;
  }

  if (isFetching) {
    return (
      <Container>
        <SpinnerContainer>
          <SpinnerBasic />
        </SpinnerContainer>
      </Container>
    );
  }

  return (
    <Container>
      <HalfWidthContainer>
        <Assets transaction={transaction} />
        <AnnotationInput transaction={transaction} />
      </HalfWidthContainer>
      <HalfWidthContainer>
        <TransactionDetails transaction={transaction} />
        <Divider />
        <FeedbackForm transactionId={transaction.id} refetch={refetch} />
      </HalfWidthContainer>
    </Container>
  );
};

export default FeedbackView;
