import { gql, MutationHookOptions, useMutation } from "@apollo/client";

import { DatevData } from "../../types";

export interface SwitchDatevDataAccountFieldMutationVariables {
  ids: string[];
}

export interface SwitchDatevDataAccountFieldMutationResult {
  switchDatevDataAccountField: DatevData[];
}

export const SWITCH_ACCOUNT_OFFSET_ACCOUNT_MUTATION = gql`
  mutation switchDatevDataAccountField($ids: [ID!]!) {
    switchDatevDataAccountField(ids: $ids) {
      id
      skrMode
      bookingMode
      bookkeepingTool
      amount
      direction
      account
      offsetAccount
      paymentDate
      buKey
      meta1
      description
      kkr
      vatCategoryCode
      verifiedAt
      assets {
        filetype
        fullsize
      }
    }
  }
`;

export const useSwitchDatevDataAccountFieldMutation = (
  options?: MutationHookOptions<
    SwitchDatevDataAccountFieldMutationResult,
    SwitchDatevDataAccountFieldMutationVariables
  >
) =>
  useMutation<
    SwitchDatevDataAccountFieldMutationResult,
    SwitchDatevDataAccountFieldMutationVariables
  >(SWITCH_ACCOUNT_OFFSET_ACCOUNT_MUTATION, options);
