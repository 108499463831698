import React, { ReactNode, useState, useEffect } from "react";
import styled from "styled-components";
import { Button, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";

const { Dragger } = Upload;

const Wrapper = styled.div`
  flex: 1;
  flex-direction: column;
  .ant-upload-drag-container > div > div {
    padding: 0;
  }

  .ant-upload.ant-upload-drag .ant-upload-drag-container {
    vertical-align: top;
  }

  .button-below {
    position: absolute;
    right: 0;
    margin-top: 10px;
  }
`;

interface Props {
  children?: ReactNode;
  onDropFiles: (files: File[]) => void;
  isInPreviewMode?: boolean;
}

const props = {
  multiple: true,
  withCredentials: false,
  accept: ".jpg, .jpeg, .png, .pdf",
};

const DragAndDropWithUpload = ({
  onDropFiles,
  children,
  isInPreviewMode,
}: Props) => {
  const [droppedFiles, setDroppedFiles] = useState<File[]>([]);

  useEffect(() => {
    if (droppedFiles.length) {
      onDropFiles(droppedFiles);
      setDroppedFiles([]);
    }
  }, [droppedFiles, onDropFiles]);

  // ant calls beforeUpload for every single file that is uploaded.
  // This function together with useEffect hook is a workaround to make it possible
  // to call onDropFiles only once with all files together.
  const beforeUpload = (_: File, files: File[]) => {
    setDroppedFiles(files);
    return Upload.LIST_IGNORE;
  };

  return (
    <Wrapper>
      <Dragger
        data-test="documentUploadArea"
        {...props}
        beforeUpload={beforeUpload}
        openFileDialogOnClick={false}
        style={{
          minHeight: "300px",
          display: "flex",
          alignItems: "center",
        }}
      >
        {children}
        <Upload
          {...props}
          beforeUpload={beforeUpload}
          className={isInPreviewMode ? "button-below" : ""}
        >
          <Button style={{ display: "inline-flex" }} icon={<UploadOutlined />}>
            Upload
          </Button>
        </Upload>
      </Dragger>
    </Wrapper>
  );
};

export default DragAndDropWithUpload;
