import type * as Types from "../../schema.generated";

import { gql } from "@apollo/client";
import { AnschreibenFragmentDoc } from "../../fragments/anschreiben.generated";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type ApproveOrRejectAnschreibenMutationVariables = Types.Exact<{
  email: Types.Scalars["String"];
  id: Types.Scalars["String"];
  isApproved: Types.Scalars["Boolean"];
}>;

export type ApproveOrRejectAnschreibenMutation = {
  __typename?: "Mutation";
  approveOrRejectAnschreiben: {
    __typename?: "Anschreiben";
    id: string;
    content: string;
    year: number;
    note?: string | null;
    approvedAt?: string | null;
    rejectedAt?: string | null;
    exportedAt?: string | null;
  };
};

export const ApproveOrRejectAnschreibenDocument = gql`
  mutation approveOrRejectAnschreiben(
    $email: String!
    $id: String!
    $isApproved: Boolean!
  ) {
    approveOrRejectAnschreiben(
      email: $email
      id: $id
      isApproved: $isApproved
    ) {
      ...Anschreiben
    }
  }
  ${AnschreibenFragmentDoc}
`;
export type ApproveOrRejectAnschreibenMutationFn = Apollo.MutationFunction<
  ApproveOrRejectAnschreibenMutation,
  ApproveOrRejectAnschreibenMutationVariables
>;

/**
 * __useApproveOrRejectAnschreibenMutation__
 *
 * To run a mutation, you first call `useApproveOrRejectAnschreibenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveOrRejectAnschreibenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveOrRejectAnschreibenMutation, { data, loading, error }] = useApproveOrRejectAnschreibenMutation({
 *   variables: {
 *      email: // value for 'email'
 *      id: // value for 'id'
 *      isApproved: // value for 'isApproved'
 *   },
 * });
 */
export function useApproveOrRejectAnschreibenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ApproveOrRejectAnschreibenMutation,
    ApproveOrRejectAnschreibenMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ApproveOrRejectAnschreibenMutation,
    ApproveOrRejectAnschreibenMutationVariables
  >(ApproveOrRejectAnschreibenDocument, options);
}
export type ApproveOrRejectAnschreibenMutationHookResult = ReturnType<
  typeof useApproveOrRejectAnschreibenMutation
>;
export type ApproveOrRejectAnschreibenMutationResult =
  Apollo.MutationResult<ApproveOrRejectAnschreibenMutation>;
export type ApproveOrRejectAnschreibenMutationOptions =
  Apollo.BaseMutationOptions<
    ApproveOrRejectAnschreibenMutation,
    ApproveOrRejectAnschreibenMutationVariables
  >;
