import { PermissionsScope } from "./types";

export const DEFAULT_LOCALE = "de-DE";

export const DEFAULT_CURRENCY = "EUR";

export enum StatusColor {
  GOLD = "gold",
  GREEN = "green",
  ORANGE = "orange",
  PURPLE = "purple",
  GRAY = "gray",
  RED = "red",
}

export const TAX_ADVISORY_PERMISSION_SCOPES = [
  PermissionsScope.TAX_SUPERVISOR,
  PermissionsScope.ADMIN,
  PermissionsScope.CLERK,
  PermissionsScope.ACCOUNTING,
  PermissionsScope.READONLY,
];
