import styled from "styled-components";
import { Button } from "antd";

import colors from "../../../../themes/colors";

export const NextButton = styled(Button)`
  background: ${colors.purple};
  color: ${colors.white};
  border: 1px solid ${colors.purple};
`;
