import { useEffect, useMemo, useRef } from "react";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";

import { useLocation } from "react-router-dom";

import { getCurrentUserEmail } from "../../gapi";
import api from "../../api";
import { UsageStatPingPayload } from "../../types";

const USAGE_STATS_PING_INTERVAL = 5000;

const getLogEntry = (): UsageStatPingPayload => {
  const searchParams = new URLSearchParams(document.location.search);
  return {
    activity: `BO: ${document.location.pathname.replace("/", "")}`,
    employee: getCurrentUserEmail(),
    customer: {
      mail: searchParams.get("email"),
      taxNumber: searchParams.get("taxNumber"),
      transactionId: searchParams.get("id"),
    },
  };
};

const useUsageStatsTracker = () => {
  const sessionId = useMemo(() => uuidv4(), []);
  const lastLogEntry = useRef<UsageStatPingPayload | null>(null);
  const sendTracking = useMemo(
    () =>
      _.throttle(() => {
        lastLogEntry.current = getLogEntry();

        if (process.env.REACT_APP_ENV !== "development")
          // not awaiting promise on purpose
          api.kontax.sendUsageStatsPing({
            ...lastLogEntry.current!,
            sessionId,
          });
      }, USAGE_STATS_PING_INTERVAL),
    [sessionId]
  );

  useEffect(() => {
    document.body.addEventListener("mousemove", sendTracking);
    return () => document.body.removeEventListener("mousemove", sendTracking);
  }, [sendTracking]);

  useEffect(() => {
    document.body.addEventListener("keypress", sendTracking);
    return () => document.body.removeEventListener("keypress", sendTracking);
  }, [sendTracking]);

  const location = useLocation();
  useEffect(() => {
    if (!_.isEqual(lastLogEntry.current, getLogEntry())) {
      // Force tracking when current activity is changed
      sendTracking.cancel();
      sendTracking();
    }
  }, [location, sendTracking]);
};

export default useUsageStatsTracker;
