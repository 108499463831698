import { gql, MutationHookOptions, useMutation } from "@apollo/client";

export interface SubmitVatAnnualDeclarationMutationVariables {
  email: string;
  year: number;
}

export interface SubmitVatAnnualDeclarationMutationResult {
  submitVatAnnualDeclaration: {
    processResult: string;
    pdf: string;
  };
}

export const SUBMIT_VAT_ANNUAL_DECLARATION_MUTATION = gql`
  mutation submitVatAnnualDeclaration($email: String!, $year: Int!) {
    submitVatAnnualDeclaration(email: $email, year: $year) {
      pdf
      processResult
    }
  }
`;

export const useSubmitVatAnnualDeclarationMutation = (
  options?: MutationHookOptions<
    SubmitVatAnnualDeclarationMutationResult,
    SubmitVatAnnualDeclarationMutationVariables
  >
) =>
  useMutation<
    SubmitVatAnnualDeclarationMutationResult,
    SubmitVatAnnualDeclarationMutationVariables
  >(SUBMIT_VAT_ANNUAL_DECLARATION_MUTATION, options);
