import type * as Types from "../../schema.generated";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type TaxServicesQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.TaxServicesFilterArgs>;
}>;

export type TaxServicesQuery = {
  __typename?: "Query";
  taxServices: Array<{
    __typename?: "TaxServiceDetails";
    id: string;
    department: Types.TaxServiceDepartment;
    name: Types.TaxServiceName;
    year?: number | null;
    hours?: number | null;
    internalNote: string;
    externalNote: string;
    status: Types.TaxServiceStatus;
    createdBy: string;
    createdAt: string;
    updatedAt: string;
    kontaxUser: {
      __typename?: "TaxServiceKontaxUser";
      kontistUser: {
        __typename?: "TaxServiceUser";
        email?: string | null;
        firstName?: string | null;
        lastName?: string | null;
      };
    };
  }>;
};

export const TaxServicesDocument = gql`
  query taxServices($filter: TaxServicesFilterArgs) {
    taxServices(filter: $filter) {
      id
      department
      name
      year
      hours
      internalNote
      externalNote
      status
      createdBy
      createdAt
      updatedAt
      kontaxUser {
        kontistUser {
          email
          firstName
          lastName
        }
      }
    }
  }
`;

/**
 * __useTaxServicesQuery__
 *
 * To run a query within a React component, call `useTaxServicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaxServicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaxServicesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useTaxServicesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TaxServicesQuery,
    TaxServicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TaxServicesQuery, TaxServicesQueryVariables>(
    TaxServicesDocument,
    options
  );
}
export function useTaxServicesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TaxServicesQuery,
    TaxServicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TaxServicesQuery, TaxServicesQueryVariables>(
    TaxServicesDocument,
    options
  );
}
export type TaxServicesQueryHookResult = ReturnType<typeof useTaxServicesQuery>;
export type TaxServicesLazyQueryHookResult = ReturnType<
  typeof useTaxServicesLazyQuery
>;
export type TaxServicesQueryResult = Apollo.QueryResult<
  TaxServicesQuery,
  TaxServicesQueryVariables
>;
