import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Tabs } from "antd";

const { TabPane } = Tabs;

export const VAT_TABS = {
  VAT: {
    key: "VAT",
    path: "/vat-view",
  },
  ELSTER: {
    key: "ELSTER",
    path: "/elster-view",
  },
};

const SubNavigation = ({ activeTab }: { activeTab: string }) => {
  const history = useHistory();

  const onChangeTab = useCallback(
    (key: string) => {
      if (key === VAT_TABS.VAT.key) {
        history.push(VAT_TABS.VAT.path + history.location.search);
      } else {
        history.push(VAT_TABS.ELSTER.path + history.location.search);
      }
    },
    [history]
  );

  return (
    <Tabs type="card" activeKey={activeTab} onChange={onChangeTab}>
      <TabPane tab={VAT_TABS.VAT.key} key={VAT_TABS.VAT.key} />
      <TabPane tab={VAT_TABS.ELSTER.key} key={VAT_TABS.ELSTER.key} />
    </Tabs>
  );
};

export default SubNavigation;
