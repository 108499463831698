import { gql, MutationHookOptions, useMutation } from "@apollo/client";

export interface SubmitTradeTaxDeclarationMutationVariables {
  email: string;
  year: number;
}

export interface SubmitTradeTaxDeclarationMutationResult {
  submitTradeTaxDeclaration: {
    processResult: string;
    pdf: string;
  };
}

export const SUBMIT_TRADE_TAX_DECLARATION_MUTATION = gql`
  mutation submitTradeTaxDeclaration($email: String!, $year: Int!) {
    submitTradeTaxDeclaration(email: $email, year: $year) {
      pdf
      processResult
    }
  }
`;

export const useSubmitTradeTaxDeclarationMutation = (
  options?: MutationHookOptions<
    SubmitTradeTaxDeclarationMutationResult,
    SubmitTradeTaxDeclarationMutationVariables
  >
) =>
  useMutation<
    SubmitTradeTaxDeclarationMutationResult,
    SubmitTradeTaxDeclarationMutationVariables
  >(SUBMIT_TRADE_TAX_DECLARATION_MUTATION, options);
