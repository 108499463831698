import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import UserSearch from "./components/UserSearch";
import EuerDeclaration from "./pages/EuerDeclaration";
import VatAnnualDeclaration from "./pages/VatAnnualDeclaration";
import TradeTaxDeclaration from "./pages/TradeTaxDeclaration";
import IncomeTaxDeclaration from "./pages/IncomeTaxDeclaration";
import TaxPreparation from "./pages/TaxPreparation";
import { ContentWrapper } from "../../common/styledComponents";
import TaxHome from "./pages/TaxHome";

const TaxDeclaration = () => {
  const { path } = useRouteMatch();

  const wrapWithUserSearch = (Component: React.ComponentType<{}>) => {
    return (
      <UserSearch>
        <Component />
      </UserSearch>
    );
  };

  return (
    <ContentWrapper>
      <Switch>
        <Route path={`${path}/tax-home`} component={TaxHome} />
        <Route
          path={`${path}/tax-preparation`}
          render={() => wrapWithUserSearch(TaxPreparation)}
        />
        <Route
          path={`${path}/euer-declaration`}
          render={() => wrapWithUserSearch(EuerDeclaration)}
        />
        <Route
          path={`${path}/vat-annual-declaration`}
          render={() => wrapWithUserSearch(VatAnnualDeclaration)}
        />
        <Route
          path={`${path}/trade-tax-declaration`}
          render={() => wrapWithUserSearch(TradeTaxDeclaration)}
        />
        <Route
          path={`${path}/income-tax-declaration`}
          render={() => wrapWithUserSearch(IncomeTaxDeclaration)}
        />
      </Switch>
    </ContentWrapper>
  );
};

export default TaxDeclaration;
