import React, { useMemo, useState } from "react";
import { Button, Modal, Table, Tag } from "antd";
import { PlusOutlined } from "@ant-design/icons";

import { useTranslation } from "react-i18next";

import { TFunction } from "i18next";

import { ColumnType } from "antd/lib/table";

import moment from "moment-timezone";

import CustomerAttribute from "../../../../common/CustomerAttribute";
import EditableCustomerAttribute, {
  EditType,
} from "../../../../common/EditableCustomerAttribute";
import { IUser, KontaxPlan, KontaxUserStatus } from "../../../../../types";
import {
  formatAmountInCents,
  formatBooleanToString,
} from "../../../../../utils";
import {
  formatDayMonthYear,
  formatRecurlyPlans,
} from "../../Common/UserDetails/utils";
import { DateInputType } from "../../../../common/TransactionFilters/DateInput";
import {
  CustomerAttributes,
  CustomerInformationCard,
  CustomerInformationSectionTitle,
} from "../../../../common/styledComponents";
import { VerticalAligned } from "../../Common/UserDetails/styledComponents";
import i18n from "../../../../../i18n";
import { useGetRetroactiveKontaxFeesQuery } from "../../../../../api/graphql/queries/retroactiveKontaxFees/retroactiveKontaxFees.generated";
import {
  RetroactiveKontaxFee,
  RetroactiveKontaxFeeStatus,
} from "../../../../../api/graphql/schema.generated";
import { CustomerAttributeLabel } from "../../../../common/CustomerAttribute/styledComponents";
import ManualInvoiceForm from "../../Common/UserDetails/ManualInvoiceForm";
import { ButtonWrapper } from "./styledComponents";
import { TAX_ADVISORY_PERMISSION_SCOPES } from "../../../../../constants";
import { getPermissionScope } from "../../../../../gapi";

export const getColorByStatus = (status?: RetroactiveKontaxFeeStatus) => {
  switch (status) {
    case RetroactiveKontaxFeeStatus.OPEN:
      return "yellow";
    case RetroactiveKontaxFeeStatus.PAST_DUE:
      return "red";
    case RetroactiveKontaxFeeStatus.CANCELED:
      return "";
    case RetroactiveKontaxFeeStatus.PAYED:
      return "green";
  }
};

const getTexts = (t: TFunction<"Onboarding", undefined>) => ({
  columnLabel: (columnName: string) =>
    t(`payment.retroactivePayment.tableHeaders.${columnName}`),
  subHeadline: t("payment.retroactivePayment.subHeadline"),
  status: (status: RetroactiveKontaxFeeStatus) =>
    t(`payment.retroactivePayment.state.${status}`),
});

type PaymentSectionProps = {
  user: IUser;
  onSaveKontaxBillingStartDateHandler: (date: DateInputType) => void;
  editUserHandler: (email: string, data: any) => void;
};

const PaymentSection = (props: PaymentSectionProps) => {
  const { user, onSaveKontaxBillingStartDateHandler, editUserHandler } = props;

  const { t } = useTranslation("Onboarding");
  const texts = getTexts(t);

  const [showManualInvoiceModal, setShowManualInvoiceModal] = useState(false);
  const [isInvoiceSaveLoading, setIsInvoiceSaveLoading] = useState(false);

  const hasTaxAdvisoryPermissionScope = TAX_ADVISORY_PERMISSION_SCOPES.includes(
    getPermissionScope()
  );

  const { data: queryResult, refetch: refetchRetroactiveKontaxFee } =
    useGetRetroactiveKontaxFeesQuery({
      variables: {
        email: user.email,
      },
      skip: !hasTaxAdvisoryPermissionScope,
    });

  const retroactiveKontaxFees = queryResult?.retroactiveKontaxFees || [];

  const columns: ColumnType<RetroactiveKontaxFee>[] = useMemo(
    () => [
      {
        key: "invoiceDate",
        title: texts.columnLabel("invoiceDate"),
        required: true,
        render: (record: RetroactiveKontaxFee) =>
          record.invoiceDate
            ? moment(record.invoiceDate).format("DD-MM-YYYY")
            : "-",
      },
      {
        key: "dueDate",
        title: texts.columnLabel("dueDate"),
        required: true,
        render: (record: RetroactiveKontaxFee) =>
          moment(record.dueDate).format("DD-MM-YYYY"),
      },
      {
        key: "description",
        title: texts.columnLabel("description"),
        required: true,
        render: (record: RetroactiveKontaxFee) => record.description,
      },
      {
        key: "billingRange",
        title: texts.columnLabel("timeFrame"),
        width: 180,
        required: true,
        render: (_: any, record: RetroactiveKontaxFee) =>
          `${moment(record.startDate).format("MMM YYYY")} - ${moment(
            record.endDate
          ).format("MMM YYYY")}`,
      },
      {
        key: "quantity",
        title: texts.columnLabel("quantity"),
        dataIndex: "quantity",
        required: true,
      },
      {
        key: "netAmount",
        title: texts.columnLabel("netAmount"),

        required: true,
        render: (_: any, record: RetroactiveKontaxFee) =>
          `${formatAmountInCents(record.quantity * record.unitAmount, true)}`,
      },
      {
        key: "status",
        title: "Status",
        dataIndex: "status",
        render: (_: any, record: RetroactiveKontaxFee) =>
          record.status ? (
            <Tag color={getColorByStatus(record.status)}>
              {texts.status(record.status)}
            </Tag>
          ) : (
            "-"
          ),
      },
    ],
    [texts]
  );

  const onCancel = () => {
    setShowManualInvoiceModal(false);
  };

  return (
    <>
      <CustomerInformationSectionTitle>
        Zahlungsinformationen
      </CustomerInformationSectionTitle>
      <CustomerInformationCard>
        <CustomerAttributes>
          <CustomerAttribute label="Aktueller Plan">
            {formatRecurlyPlans(user.subscriptions)}
          </CustomerAttribute>
          <EditableCustomerAttribute
            label="Pending Kontax Plan"
            initialValue={user.pendingKontaxPlan}
            editType={EditType.Select}
            options={[...Object.values(KontaxPlan)]}
            onSaveHandler={(pendingKontaxPlan: KontaxPlan) =>
              editUserHandler(user.email, { pendingKontaxPlan })
            }
            isReadOnly={!hasTaxAdvisoryPermissionScope}
          />
          <CustomerAttribute label="Zahlungsverzug">
            {formatBooleanToString(user.pastDue)}
          </CustomerAttribute>
          <CustomerAttribute label="Fällige Beträge in €">
            {formatAmountInCents(user.recurlyBalance || 0)}
          </CustomerAttribute>
          <CustomerAttribute label="Fällig seit">
            {formatDayMonthYear(user.pastDueSince)}
          </CustomerAttribute>
          <EditableCustomerAttribute
            label="Kontax billing start date"
            initialValue={user.kontaxBillingStartDate}
            formatFunction={formatDayMonthYear}
            editType={DateInputType.Date}
            onSaveHandler={onSaveKontaxBillingStartDateHandler}
            inputRequired
            allowClear
            isReadOnly={!hasTaxAdvisoryPermissionScope}
          />
        </CustomerAttributes>
      </CustomerInformationCard>
      <VerticalAligned>
        <CustomerAttributeLabel>{texts.subHeadline}</CustomerAttributeLabel>
        <Table
          bordered
          key={i18n.language}
          dataSource={retroactiveKontaxFees}
          columns={columns}
          pagination={false}
        />
      </VerticalAligned>
      {user.kontaxStatus === KontaxUserStatus.ACTIVATED &&
      hasTaxAdvisoryPermissionScope ? (
        <ButtonWrapper>
          <Button
            icon={<PlusOutlined />}
            size="large"
            style={{ display: "flex", alignItems: "center" }}
            onClick={() => setShowManualInvoiceModal(true)}
          >
            Hinzufügen
          </Button>
        </ButtonWrapper>
      ) : null}
      <Modal
        title="Manual Invoice"
        width={700}
        visible={showManualInvoiceModal}
        onCancel={onCancel}
        destroyOnClose
        footer={[
          <Button
            form="manualInvoiceFormId"
            key="submit"
            htmlType="submit"
            type="primary"
            loading={isInvoiceSaveLoading}
          >
            Save
          </Button>,
        ]}
      >
        <ManualInvoiceForm
          email={user.email}
          onModalCancel={onCancel}
          setIsInvoiceSaveLoading={setIsInvoiceSaveLoading}
          refreshRetroactiveKontaxFee={refetchRetroactiveKontaxFee}
        />
      </Modal>
    </>
  );
};

export default PaymentSection;
