import type * as Types from "../../schema.generated";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type TaxCaseQueryVariables = Types.Exact<{
  email: Types.Scalars["String"];
  year: Types.Scalars["Int"];
}>;

export type TaxCaseQuery = {
  __typename?: "Query";
  taxCase: {
    __typename?: "TaxBoardTaxCase";
    finalizedAt?: string | null;
    incomeTaxSkippedAt?: string | null;
  };
};

export const TaxCaseDocument = gql`
  query taxCase($email: String!, $year: Int!) {
    taxCase(email: $email, year: $year) {
      finalizedAt
      incomeTaxSkippedAt
    }
  }
`;

/**
 * __useTaxCaseQuery__
 *
 * To run a query within a React component, call `useTaxCaseQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaxCaseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaxCaseQuery({
 *   variables: {
 *      email: // value for 'email'
 *      year: // value for 'year'
 *   },
 * });
 */
export function useTaxCaseQuery(
  baseOptions: Apollo.QueryHookOptions<TaxCaseQuery, TaxCaseQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TaxCaseQuery, TaxCaseQueryVariables>(
    TaxCaseDocument,
    options
  );
}
export function useTaxCaseLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TaxCaseQuery, TaxCaseQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TaxCaseQuery, TaxCaseQueryVariables>(
    TaxCaseDocument,
    options
  );
}
export type TaxCaseQueryHookResult = ReturnType<typeof useTaxCaseQuery>;
export type TaxCaseLazyQueryHookResult = ReturnType<typeof useTaxCaseLazyQuery>;
export type TaxCaseQueryResult = Apollo.QueryResult<
  TaxCaseQuery,
  TaxCaseQueryVariables
>;
