import { gql, MutationHookOptions, useMutation } from "@apollo/client";

export interface SubmitEuerMutationVariables {
  payload: {
    email: string;
    year: number;
  };
}

export interface SubmitEuerMutationResult {
  submitEuer: {
    processResult: string;
    pdf: string;
  };
}

export const SUBMIT_EUER_MUTATION = gql`
  mutation submitEuer($payload: EuerProcessingRequest!) {
    submitEuer(payload: $payload) {
      processResult
      pdf
    }
  }
`;

export const useSubmitEuerMutation = (
  options?: MutationHookOptions<
    SubmitEuerMutationResult,
    SubmitEuerMutationVariables
  >
) =>
  useMutation<SubmitEuerMutationResult, SubmitEuerMutationVariables>(
    SUBMIT_EUER_MUTATION,
    options
  );
