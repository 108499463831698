import React, { useState } from "react";
import moment from "moment";
import { Button, Table } from "antd";

import { NextDeclarationDue } from "./NextDeclarationDue/NextDeclarationDue";
import { NextDeclarationStatus } from "./NextDeclarationStatus";
import { VatLastCheckedAt } from "./VatLastCheckedAt";
import UserFilters, { UserFilterOptions } from "../UserFilters";
import { IVatDeclarationUser } from "../../../../types";
import { InvoiceIconWrapper } from "../../../common/styledComponents";
import VatDeclarationDetails from "./VatDeclarationDetails/index";
import { ReactComponent as InvoiceIcon } from "../../../../svgs/invoice.svg";
import EmptyWrapper from "../../../common/EmptyWrapper";
import { formatPaymentFrequency } from "../../../../utils/paymentFrequency";
import { NextVatDeclarationStatus } from "../../../../types";
import { nextDeclarationDueSort } from "./NextDeclarationDue/helpers";
import { vatTableColumnSorter } from "./helpers";
import { KontaxUserContext } from "../../../contexts/KontaxUserContext";
import { onClickStopPropagation } from "../../../../utils/table";

interface Props {
  users: IVatDeclarationUser[] | null;
  filterOptions: UserFilterOptions;
  handleFilterChange: Function;
  handlePageChange: Function;
  pageNumber: number;
  loadAll: () => void;
}

const VatTable = ({
  users,
  filterOptions,
  pageNumber,
  handleFilterChange,
  handlePageChange,
  loadAll,
}: Props) => {
  interface UpdatedDetail {
    email: string;
    hasNotes?: boolean;
    status?: NextVatDeclarationStatus;
  }
  const [updatedDetails, setUpdatedDetails] = useState<Array<UpdatedDetail>>(
    []
  );
  const data = React.useMemo(
    () =>
      users?.map((user) => {
        const {
          accountId,
          firstName,
          lastName,
          email,
          frequency,
          nextDeclarationDueDate,
          nextDeclarationDuePeriod,
          nextDeclarationDueYear,
          vatLastCheckedAt,
          status,
          lastVATDeclarationSent,
          unbookedTransactions,
          unbookedTransactionsWithoutReceipts,
          hasNotes,
          subscriptionType,
        } = user;

        const hasNewNotes =
          hasNotes ||
          updatedDetails?.find(
            (updatedDetails) => updatedDetails.email === user.email
          )?.hasNotes;

        const updatedStatus =
          updatedDetails?.find(
            (updatedDetails) => updatedDetails.email === user.email
          )?.status || status;

        return {
          accountId,
          firstName,
          lastName,
          email,
          frequency,
          nextDeclarationDuePeriod,
          nextDeclarationDueYear,
          unbookedTransactions,
          hasNotes: hasNewNotes,
          unbookedTransactionsWithoutReceipts,
          nextDeclarationDueDate: nextDeclarationDueDate
            ? moment(nextDeclarationDueDate).format("YYYY-MM-DD")
            : "",
          vatLastCheckedAt: vatLastCheckedAt
            ? moment(vatLastCheckedAt).format("YYYY-MM-DD")
            : "",
          status: updatedStatus,
          lastVATDeclarationSent: lastVATDeclarationSent
            ? moment(lastVATDeclarationSent).format("YYYY-MM-DD")
            : "",
          subscriptionType,
        };
      }),
    [updatedDetails, users]
  );

  const columns = React.useMemo(
    () => [
      {
        title: "Notes",
        key: "hasNotes",
        render: (row: IVatDeclarationUser) => {
          const { hasNotes } = row;
          return hasNotes ? (
            <InvoiceIconWrapper>
              <InvoiceIcon />
            </InvoiceIconWrapper>
          ) : null;
        },
      },
      {
        title: "Recurly plan",
        dataIndex: "subscriptionType",
        key: "subscriptionType",
        sorter: (a: IVatDeclarationUser, b: IVatDeclarationUser) =>
          vatTableColumnSorter(a.subscriptionType, b.subscriptionType),
      },
      {
        title: "Name",
        key: "lastName",
        render: (row: IVatDeclarationUser) => {
          const { firstName, lastName, email } = row;
          return (
            <React.Fragment>
              <div>
                {firstName} {lastName}
              </div>
              <div>{email}</div>
            </React.Fragment>
          );
        },
        sorter: (a: IVatDeclarationUser, b: IVatDeclarationUser) =>
          vatTableColumnSorter(
            a.firstName + a.lastName,
            b.firstName + b.lastName
          ),
      },
      {
        title: "Next declaration due",
        key: "nextDeclarationDue",
        render: (row: IVatDeclarationUser) => {
          const {
            email,
            nextDeclarationDuePeriod,
            nextDeclarationDueYear,
            frequency,
          } = row;

          return (
            <NextDeclarationDue
              email={email}
              vatPaymentFrequency={frequency}
              nextDeclarationDueYear={nextDeclarationDueYear}
              nextDeclarationDuePeriod={nextDeclarationDuePeriod}
              setUpdatedDetails={(status: NextVatDeclarationStatus) =>
                setUpdatedDetails(updatedDetails.concat({ email, status }))
              }
            />
          );
        },
        sorter: nextDeclarationDueSort,
        onCell: onClickStopPropagation,
      },
      {
        title: "Next declaration due date",
        dataIndex: "nextDeclarationDueDate",
        key: "nextDeclarationDueDate",
        sorter: (a: IVatDeclarationUser, b: IVatDeclarationUser) =>
          vatTableColumnSorter(
            a.nextDeclarationDueDate,
            b.nextDeclarationDueDate
          ),
      },
      {
        title: "Frequency",
        key: "frequency",
        render: (row: IVatDeclarationUser) =>
          formatPaymentFrequency(row.frequency),
        sorter: (a: IVatDeclarationUser, b: IVatDeclarationUser) =>
          vatTableColumnSorter(a.frequency, b.frequency),
      },
      {
        title: "Last checked",
        key: "vatLastCheckedAt",
        render: (row: IVatDeclarationUser) => {
          const { email, vatLastCheckedAt } = row;
          return (
            <VatLastCheckedAt
              email={email}
              vatLastCheckedAt={vatLastCheckedAt}
            />
          );
        },
        sorter: (a: IVatDeclarationUser, b: IVatDeclarationUser) =>
          vatTableColumnSorter(a.vatLastCheckedAt, b.vatLastCheckedAt),
        onCell: onClickStopPropagation,
      },
      {
        title: "Status",
        key: "status",
        render: (row: IVatDeclarationUser) => {
          const { email, status } = row;
          return (
            <NextDeclarationStatus
              email={email}
              status={status}
              setUpdatedDetails={(status: NextVatDeclarationStatus) =>
                setUpdatedDetails(updatedDetails.concat({ email, status }))
              }
            />
          );
        },
        sorter: (a: IVatDeclarationUser, b: IVatDeclarationUser) =>
          vatTableColumnSorter(a.status, b.status),
        onCell: onClickStopPropagation,
      },
      {
        title: "Last VAT- declaration sent",
        dataIndex: "lastVATDeclarationSent",
        key: "lastVATDeclarationSent",
        sorter: (a: IVatDeclarationUser, b: IVatDeclarationUser) =>
          vatTableColumnSorter(
            a.lastVATDeclarationSent,
            b.lastVATDeclarationSent
          ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const renderRowSubComponent = React.useCallback(
    (row: IVatDeclarationUser) => {
      return (
        <KontaxUserContext email={row.email}>
          <VatDeclarationDetails
            setUpdatedDetails={(newDetails: UpdatedDetail) =>
              setUpdatedDetails(updatedDetails.concat(newDetails))
            }
          />
        </KontaxUserContext>
      );
    },
    [updatedDetails]
  );

  return (
    <>
      <UserFilters
        filterOptions={filterOptions}
        setFilterOptions={(
          updatedFilterOptions: Partial<UserFilterOptions>
        ) => {
          handleFilterChange({
            ...filterOptions,
            ...updatedFilterOptions,
          });
        }}
        loadAll={loadAll}
      />
      {!!users?.length ? (
        <>
          <Table
            columns={columns}
            dataSource={data}
            bordered
            expandable={{
              expandedRowRender: renderRowSubComponent,
              expandRowByClick: true,
            }}
            pagination={false}
            scroll={{ x: "100%" }}
            rowKey="accountId"
          />
          {Number(pageNumber) !== 1 && (
            <Button
              onClick={() => {
                handlePageChange(Number(pageNumber) - 1);
              }}
              type="primary"
            >
              Previous
            </Button>
          )}
          <span style={{ paddingRight: "10px" }}>Page: {pageNumber}</span>
          <Button
            onClick={() => {
              handlePageChange(Number(pageNumber) + 1);
            }}
            type="primary"
          >
            Next
          </Button>
        </>
      ) : (
        users && <EmptyWrapper description="No users found" />
      )}
    </>
  );
};

export default VatTable;
