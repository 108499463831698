import { gql, LazyQueryHookOptions, useLazyQuery } from "@apollo/client";

import { EuerDeclarationAutoCalculatedValues } from "../../types";

export interface EuerDeclarationAutoCalculatedValuesQueryVariables {
  email: string;
  year: number;
}

export interface EuerDeclarationAutoCalculatedValuesQueryResult {
  euerDeclaration: {
    autoCalculatedValues: EuerDeclarationAutoCalculatedValues;
  };
}

export const EUER_DECLARATION_AUTO_CALCULATED_VALUES_QUERY = gql`
  query euerDeclarationAutoCalculatedValues($email: String!, $year: Int!) {
    euerDeclaration(email: $email, year: $year) {
      autoCalculatedValues {
        transactionsCount
        uncategorizedCount
        verifiedTransactionsCount
        fields
      }
    }
  }
`;

export const useEuerDeclarationAutoCalculatedValuesLazyQuery = (
  options?: LazyQueryHookOptions<
    EuerDeclarationAutoCalculatedValuesQueryResult,
    EuerDeclarationAutoCalculatedValuesQueryVariables
  >
) =>
  useLazyQuery<
    EuerDeclarationAutoCalculatedValuesQueryResult,
    EuerDeclarationAutoCalculatedValuesQueryVariables
  >(EUER_DECLARATION_AUTO_CALCULATED_VALUES_QUERY, options);
