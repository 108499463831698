import { Route, Switch, useRouteMatch } from "react-router-dom";

import { AddRule } from "./AddRule";
import RulesView from "./RulesView";
import { EditRule } from "./EditRule";

const RulesRouter = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={path} exact component={RulesView} />
      <Route path={`${path}/add`} exact component={AddRule} />
      <Route
        path={`${path}/:ruleId`}
        exact
        render={({ match }) => <EditRule id={parseInt(match.params.ruleId)} />}
      />
    </Switch>
  );
};

export default RulesRouter;
