import styled from "styled-components";
import { Modal, Row } from "antd";

import colors from "../../../../../themes/colors";

export const StyledModal = styled(Modal)`
  top: 20px;

  .ant-modal-title {
    font-weight: normal;
  }

  .ant-modal-body {
    padding: 0;
  }
`;

export const SectionRow = styled(Row)`
  padding: 24px;
`;

export const ListRow = styled(Row)`
  padding: 10px 24px;
`;

export const Total = styled.span`
  font-weight: bold;
  font-size: 16px;
`;

export const Error = styled.div`
  color: ${colors.darkRed};
  padding: 10px 0px;
  font-weight: bold;
`;
