import { EuerDeclarationSubformCalculationMethod } from "../../../../types";

export const CAR_USAGE_CALCULATION_METHOD_OPTIONS: Array<{
  label: string;
  value: EuerDeclarationSubformCalculationMethod;
}> = [
  {
    label: "1-Prozent-Methode",
    value: EuerDeclarationSubformCalculationMethod.CAR_USAGE_1_PCT_METHOD,
  },
  {
    label: "Fahrtenbuch",
    value: EuerDeclarationSubformCalculationMethod.CAR_USAGE_DRIVERS_LOG,
  },
];
