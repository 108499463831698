import React, { useState, useCallback, useEffect } from "react";
import { DatePicker, notification } from "antd";
import moment from "moment";

import api from "../../../../api";

interface Props {
  email: string;
  vatLastCheckedAt?: string;
}

export const VatLastCheckedAt = ({ email, vatLastCheckedAt }: Props) => {
  const [vatLastChecked, setVatLastChecked] = useState(vatLastCheckedAt);
  const [updatedVatLastChecked, setUpdatedVatLastChecked] =
    useState(vatLastCheckedAt);

  useEffect(() => setVatLastChecked(vatLastCheckedAt), [vatLastCheckedAt]);

  const setVatLastCheckedAt = useCallback(
    async (date) => {
      setVatLastChecked(date);

      const response = await api.kontax.updateNextDeclarationData({
        email,
        vatLastCheckedAt: date,
      });

      if (!(response instanceof Error)) {
        setUpdatedVatLastChecked(date);
      } else {
        // Set to previous server-side updated vatLastCheckedAt
        // in case the request failed
        setVatLastChecked(updatedVatLastChecked);
        notification.error({ message: "Error updating vatLastCheckedAt" });
      }
    },
    [email, updatedVatLastChecked]
  );
  return (
    <DatePicker
      name="vat_last_checked_at"
      value={vatLastChecked ? moment(vatLastChecked) : null}
      onChange={(value: moment.Moment | null) => {
        setVatLastCheckedAt(value ? value.format("YYYY-MM-DD") : "");
      }}
    />
  );
};
