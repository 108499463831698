import { Form } from "antd";
import { useCallback, useEffect, useState } from "react";

import { AutocategorizationRuleFragment } from "../../../api/graphql/fragments/autocategorizationRule.generated";
import {
  CreateAutocategorizationRuleInput,
  RuleConditionNumberOp,
  RuleConditionStringOp,
} from "../../../api/graphql/schema.generated";
import { Direction } from "../../../types";
import { categoryCodeCodec, vatCategoryCodeCodec } from "../../../utils";
import {
  encodeFormAmountCondition,
  INVERSE_CONDITION_NUMBER_OP,
} from "./helpers";

export function useRuleForm(config: { rule?: AutocategorizationRuleFragment }) {
  const [form, setForm] = useState(() => getRuleFormState(config.rule));

  const [formControls] = Form.useForm<typeof form>();
  const setFieldsValue = useCallback<typeof formControls["setFieldsValue"]>(
    (values) => {
      formControls.setFieldsValue(values);
      setForm(formControls.getFieldsValue(true));
    },
    [formControls]
  );

  useEffect(() => {
    setFieldsValue(getRuleFormState(config.rule));
  }, [config.rule, setFieldsValue]);

  const getRule = useCallback(():
    | CreateAutocategorizationRuleInput
    | string => {
    const categoryCode = categoryCodeCodec.encode(form.categoryCode);
    if (!categoryCode) {
      return `Invalid category code ${form.categoryCode}`;
    }
    const vatCategoryCode = vatCategoryCodeCodec.encode(form.vatCategoryCode);
    if (!vatCategoryCode) {
      return `Invalid VAT category code ${form.vatCategoryCode}`;
    }
    const amount = form.conditions.direction
      ? encodeFormAmountCondition(
          form.conditions.direction,
          form.conditions.amount
        )
      : undefined;
    if (amount instanceof Error) {
      return amount.message;
    }

    return {
      description: form.description,
      enabled: form.enabled,
      priority: form.priority,
      categoryCode,
      vatCategoryCode,
      verify: form.verify,
      conditions: {
        accountIds: form.conditions.accountIds?.length
          ? form.conditions.accountIds
          : undefined,
        amount,
        description: form.conditions.description.value
          ? form.conditions.description
          : undefined,
        hasEmployees: form.conditions.hasEmployees ?? undefined,
        hasInvoices: form.conditions.hasInvoices ?? undefined,
        hasVatNumber: form.conditions.hasVatNumber ?? undefined,
        ibans: form.conditions.ibans.length ? form.conditions.ibans : undefined,
        name: form.conditions.name.value ? form.conditions.name : undefined,
        vatPaymentFrequencies: form.conditions.vatPaymentFrequencies.length
          ? form.conditions.vatPaymentFrequencies
          : undefined,
      },
    };
  }, [form]);

  return {
    form,
    setForm,
    formControls,
    getRule,
  };
}

function getRuleFormState(rule?: AutocategorizationRuleFragment) {
  return {
    description: rule?.description ?? "",
    enabled: rule?.enabled ?? true,
    priority: rule?.priority ?? 0,
    categoryCode: categoryCodeCodec.decode(rule?.categoryCode) ?? "",
    vatCategoryCode: vatCategoryCodeCodec.decode(rule?.vatCategoryCode) ?? "",
    verify: rule?.verify ?? false,
    conditions: {
      accountIds: rule?.conditions?.accountIds ?? [],
      hasEmployees: rule?.conditions?.hasEmployees ?? undefined,
      hasInvoices: rule?.conditions?.hasInvoices ?? undefined,
      hasVatNumber: rule?.conditions?.hasVatNumber ?? undefined,
      ibans: rule?.conditions?.ibans ?? [],
      vatPaymentFrequencies: rule?.conditions?.vatPaymentFrequencies ?? [],
      direction: decodeDirectionCondition(rule?.conditions.amount),
      amount: decodeAmountCondition(rule?.conditions.amount),
      description: {
        op: rule?.conditions.description?.op || RuleConditionStringOp.EQ,
        value: rule?.conditions.description?.value || "",
      },
      name: {
        op: rule?.conditions.name?.op || RuleConditionStringOp.EQ,
        value: rule?.conditions.name?.value || "",
      },
    },
  };
}

function decodeDirectionCondition(
  amount?: AutocategorizationRuleFragment["conditions"]["amount"]
): Direction | undefined {
  if (!amount) {
    return;
  }

  if (amount.value === 0) {
    return amount.op === RuleConditionNumberOp.LT
      ? Direction.OUTGOING
      : Direction.INCOMING;
  }

  return amount.value > 0 ? Direction.INCOMING : Direction.OUTGOING;
}

function decodeAmountCondition(
  amount?: AutocategorizationRuleFragment["conditions"]["amount"]
) {
  if (!amount) {
    return {
      op: RuleConditionNumberOp.EQ,
      value: undefined,
    };
  }

  const op =
    amount.value < 0 ? INVERSE_CONDITION_NUMBER_OP[amount.op] : amount.op;
  const valueInEur = Math.abs(amount.value) / 100;

  return {
    op,
    value: valueInEur ? valueInEur.toString() : undefined,
  };
}
