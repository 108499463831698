export const vatTableColumnSorter = (
  firstValue: string | undefined,
  secondValue: string | undefined
): number => {
  if (firstValue === undefined || firstValue === null || firstValue === "")
    return 0;
  if (secondValue === undefined || secondValue === null || secondValue === "")
    return -1;
  return firstValue.localeCompare(secondValue);
};
