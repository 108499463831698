import React, { useState, useRef, useEffect } from "react";
import { notification } from "antd";
import { Editor as TinyMCEEditor } from "tinymce";

import { useAnschreibenTemplateLazyQuery } from "../../../../api/graphql/queries/anschreiben/anschreibenTemplate.generated";
import { useUpsertAnschreibenTemplateMutation } from "../../../../api/graphql/mutations/anschreiben/upsertAnschreibenTemplate.generated";
import RichTextEditor from "../../../common/RichTextEditor";
import { reportError } from "../../../../sentry";
import Note from "../Note";
import TopBar from "../TopBar/AnschreibenTemplateTopBar";
import { TEMPLATE_SAVED, CANT_SAVE_TEMPLATE } from "../texts";
import { SearchTerms } from "../types";
import { Container } from "./styledComponents";
import PredefinedKeywords from "./PredefinedKeywords";

const AnschreibenTemplate = () => {
  const editorRef = useRef<TinyMCEEditor | null>(null);

  const [searchTerms, setSerchTerms] = useState<SearchTerms | null>(null);
  const [note, setNote] = useState<string>("");

  const [getTemplate, { data: templateData, loading: loadingTemplate }] =
    useAnschreibenTemplateLazyQuery();
  const [saveTemplate] = useUpsertAnschreibenTemplateMutation();

  const onSearch = (updatedSearchTerms: SearchTerms) => {
    setSerchTerms(updatedSearchTerms);
    getTemplate({
      variables: {
        ...updatedSearchTerms,
        language: updatedSearchTerms.language.toLowerCase(),
      },
    });
  };

  const onSave = async () => {
    const content = editorRef?.current?.getContent();
    if (!searchTerms || content == null) {
      return;
    }

    try {
      await saveTemplate({
        variables: {
          payload: {
            ...searchTerms,
            content,
            note,
          },
        },
      });

      notification.success({ message: TEMPLATE_SAVED });
    } catch (e) {
      reportError(e);
      notification.error({ message: CANT_SAVE_TEMPLATE });
    }
  };

  useEffect(() => {
    setNote(templateData?.anschreibenTemplate?.note || "");
  }, [templateData]);

  return (
    <Container>
      <TopBar
        onSearch={onSearch}
        onSave={onSave}
        disabled={loadingTemplate}
        templateId={templateData?.anschreibenTemplate?.id}
      />
      {searchTerms && !loadingTemplate && (
        <>
          <PredefinedKeywords />
          <RichTextEditor
            defaultValue={templateData?.anschreibenTemplate?.content}
            editorRef={editorRef}
          />
          <Note note={note} setNote={setNote} />
        </>
      )}
    </Container>
  );
};

export default AnschreibenTemplate;
