import {
  ElsterFieldDefinition,
  ELSTER_FIELDS_ENTRIES,
} from "../../../../../BWAView/elsterFields";

const insertFields = (
  fields: Array<[string, ElsterFieldDefinition]>,
  {
    after,
    newFields,
  }: { after: string; newFields: Array<[string, ElsterFieldDefinition]> }
): void => {
  const index = fields.findIndex(([elsterField]) => elsterField === after);
  fields.splice(index + 1, 0, ...newFields);
};

const removeFields = (
  fields: Array<[string, ElsterFieldDefinition]>,
  fieldsToRemove: string[]
): void => {
  for (const fieldToRemove of fieldsToRemove) {
    const index = fields.findIndex(
      ([elsterField]) => elsterField === fieldToRemove
    );
    fields.splice(index, 1);
  }
};

export const EUER_DECLARATION_FIELDS_ENTRIES: Array<
  [string, ElsterFieldDefinition]
> = [
  [
    "111",
    {
      label: "111",
      description:
        "Betriebseinnahmen als umsatzsteuerlicher Kleinunternehmer (nach § 19Abs. 1 UStG)",
    },
  ],
  ...ELSTER_FIELDS_ENTRIES,
];

insertFields(EUER_DECLARATION_FIELDS_ENTRIES, {
  after: "141",
  newFields: [
    [
      "102",
      {
        label: "102",
        description: "Veräußerung oder Entnahme von Anlagevermögen",
      },
    ],
  ],
});

insertFields(EUER_DECLARATION_FIELDS_ENTRIES, {
  after: "172",
  newFields: [
    [
      "168",
      {
        label: "168",
        description: "Sonstige NICHT abziehbare Betriebsausgaben",
      },
    ],
    [
      "177",
      {
        label: "177",
        description: "Sonstige beschränkt abziehbaren Betriebsausgaben",
      },
    ],
  ],
});

insertFields(EUER_DECLARATION_FIELDS_ENTRIES, {
  after: "147",
  newFields: [
    [
      "142",
      {
        label: "142",
        description:
          "Fahrtkosten für Wege zwischen Wohnung und erster Betriebsstätte; Familien- heimfahrten (pauschaliert oder tatsächlich)",
      },
    ],
    [
      "176",
      {
        label: "176",
        description:
          "Mindestens abziehbare Fahrtkosten für Wege zwischen Wohnung und erster Betriebsstätte (Entfernungspauschale); Familienheimfahrten",
      },
    ],
  ],
});

removeFields(EUER_DECLARATION_FIELDS_ENTRIES, ["108b", "137"]);

const euerDeclarationFields: Map<string, ElsterFieldDefinition> = new Map(
  EUER_DECLARATION_FIELDS_ENTRIES
);

export default euerDeclarationFields;
