import React from "react";
import { Button, Input, Popconfirm, Card } from "antd";
import { WarningOutlined } from "@ant-design/icons";
import styled from "styled-components";

import colors from "../../../../themes/colors";

const TextareaWrapper = styled.div`
  padding: 16px 0px 30px;
`;

const LinkButton = styled.span`
  color: ${colors.lightGreyOpacity};
  margin-right: 10px;
  cursor: pointer;

  :hover {
    color: ${colors.primaryPurple};
  }
`;

const DEFAULT_NOTE_TITLE = "Internal note";

export const EditableNote = ({
  title = DEFAULT_NOTE_TITLE,
  onSave,
  onChange,
  onDelete,
  onCancel,
  isEditMode,
  setEditMode,
  value,
  uneditable,
  maxLength = 255,
}: {
  title?: string;
  onSave: () => Promise<void>;
  onChange: (value: string) => void;
  onDelete: () => void;
  onCancel: () => void;
  isEditMode: boolean;
  setEditMode: () => void;
  value: string;
  uneditable?: boolean;
  maxLength?: number;
}) => {
  return (
    <Card size="small" title={title} style={{ width: 400 }}>
      {isEditMode ? (
        <>
          <TextareaWrapper>
            <Input.TextArea
              showCount
              maxLength={maxLength}
              disabled={false}
              id="note"
              value={value}
              autoFocus
              data-test="noteInput"
              onChange={(event) => {
                onChange(event.target.value);
              }}
              style={{ height: "76px" }}
            />
          </TextareaWrapper>
          <Button
            size="large"
            type="primary"
            data-test="saveNote"
            onClick={onSave}
            style={{ marginRight: 10 }}
          >
            Save note
          </Button>
          <Button
            size="large"
            data-test="cancelSaveNote"
            onClick={(e) => {
              e.stopPropagation();
              onCancel();
            }}
          >
            Cancel
          </Button>
        </>
      ) : (
        <>
          <p data-test="noteValue">{value}</p>
          {!uneditable && (
            <>
              <LinkButton onClick={setEditMode}>Edit</LinkButton>
              <Popconfirm
                title="Willst du die Notiz löschen?"
                icon={<WarningOutlined style={{ color: "red" }} />}
                okText="Notiz löschen"
                cancelText="Abbrechen"
                onConfirm={(event) => {
                  event?.stopPropagation();
                  onDelete();
                }}
                onCancel={(e) => {
                  e?.stopPropagation();
                }}
                placement="bottomRight"
                overlayStyle={{ maxWidth: 370 }}
              >
                <LinkButton
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  data-test="deleteNote"
                >
                  Delete
                </LinkButton>
              </Popconfirm>
            </>
          )}
        </>
      )}
    </Card>
  );
};
