import { useMemo } from "react";
import { Col, Row, Typography } from "antd";

import colors from "../../../../../themes/colors";
import { formatDate } from "../../utils";
import { EMPTY_INDICATOR } from "../TradeTaxDeclaration/constants";
import {
  Questionnaire,
  QuestionnaireStatus as QuestionnaireStatusType,
} from "../../../../../api/graphql/schema.generated";

const { Text } = Typography;

export interface QuestionnaireDetailedStatusProps {
  questionnaire?: Questionnaire;
}

const QuestionnaireDetailedStatus = ({
  questionnaire,
}: QuestionnaireDetailedStatusProps) => {
  const { firstLabel, secondLabel, color } = useMemo(() => {
    if (!questionnaire) {
      return {
        firstLabel: "Offen",
        secondLabel: null,
        color: colors.lightGreyOpacity,
      };
    } else if (
      questionnaire.status === QuestionnaireStatusType.DOCUMENTS_UPLOADED
    ) {
      return {
        firstLabel: `Gestartet: ${formatDate(questionnaire.startedAt)}`,
        secondLabel: `Abgeschlossen: ${formatDate(questionnaire.completedAt)}`,
        color: colors.darkLimeGreen,
      };
    } else {
      return {
        firstLabel: `Gestartet: ${formatDate(questionnaire.startedAt)}`,
        secondLabel: `Abgeschlossen: ${EMPTY_INDICATOR}`,
        color: colors.lightGreyOpacity,
      };
    }
  }, [questionnaire]);

  return (
    <Col>
      <Row>
        <Text style={{ color }}>{firstLabel}</Text>
      </Row>
      {secondLabel && (
        <Row>
          <Text style={{ color }}>{secondLabel}</Text>
        </Row>
      )}
    </Col>
  );
};

export default QuestionnaireDetailedStatus;
