import camelCase from "lodash/camelCase";
import mapKeys from "lodash/mapKeys";
import * as Papa from "papaparse";

import { DatevRawData, Direction } from "../../../../../../../api/graphql";

// In the csv file, receipt name has the following format
// - BEDI "receipt_name"
// - DDMS "receipt_name"
export const trimReceiptName = (rawName?: string): string | undefined =>
  rawName?.split('"')[1];

export const normalizeKeys = (
  obj: Record<string, string>
): Record<string, string> =>
  mapKeys(obj, (_, key) => {
    // remove unsupported characters
    const alphabetKey = key.replace(/[^0-9a-z]/gi, "");
    // convert key to camelCase
    return camelCase(alphabetKey);
  });

const parseCSV = (input: File | string): Promise<DatevRawData[]> => {
  return new Promise((resolve, reject) => {
    const data: DatevRawData[] = [];

    Papa.parse<Record<string, string>>(input, {
      delimiter: ";",
      header: true,
      skipEmptyLines: true,

      beforeFirstChunk: (chunk) => {
        // remove first line
        return chunk.substring(chunk.indexOf("\n") + 1);
      },

      step: (result, parser) => {
        if (result.errors.length) {
          parser.abort();

          const errorMessage = result.errors
            .map((error) => error.message)
            .join("\n");
          reject(new Error(errorMessage));
          return;
        }

        const normalizedRow = normalizeKeys(result.data);

        const formatted: DatevRawData = {
          amount: normalizedRow.umsatzohneSollHabenKz || normalizedRow.umsatz,
          direction:
            normalizedRow.sollHabenKennzeichen === "S"
              ? Direction.OUTGOING
              : Direction.INCOMING,
          account: normalizedRow.konto,
          offsetAccount: normalizedRow.gegenkontoohneBuSchlssel,
          buKey: normalizedRow.buSchlssel,
          date: normalizedRow.belegdatum,
          meta1: normalizedRow.belegfeld1,
          description: normalizedRow.buchungstext,
          receiptName: trimReceiptName(normalizedRow.beleglink),
        };

        data.push(formatted);
      },

      complete: () => {
        resolve(data);
      },
    });
  });
};

export default parseCSV;
