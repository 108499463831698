import { gql, QueryHookOptions, useQuery } from "@apollo/client";

export interface BusinessAddressOverviewQueryVariables {
  email: string;
  year: number;
}

export interface Address {
  street: string;
  postCode: string;
  city: string;
  isSameAsPrivateAddress: boolean;
}

export interface BusinessAddressMovement {
  movingDate: Date;
  previousBusinessAddress: Address;
}

export interface BusinessAddressOverviewQueryResult {
  businessAddress: Address;
  businessAddressMovement: BusinessAddressMovement | null;
}

export const BUSINESS_ADDRESS_OVERVIEW_QUERY = gql`
  query businessAddressOverview($email: String!, $year: Int!) {
    businessAddress(email: $email, year: $year) {
      street
      postCode
      city
      isSameAsPrivateAddress
    }

    businessAddressMovement(email: $email, year: $year) {
      movingDate
      previousBusinessAddress {
        street
        postCode
        city
      }
    }
  }
`;

export const useBusinessAddressOverviewQuery = (
  options?: QueryHookOptions<
    BusinessAddressOverviewQueryResult,
    BusinessAddressOverviewQueryVariables
  >
) =>
  useQuery<
    BusinessAddressOverviewQueryResult,
    BusinessAddressOverviewQueryVariables
  >(BUSINESS_ADDRESS_OVERVIEW_QUERY, options);
