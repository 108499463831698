import type * as Types from "../../schema.generated";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type UsageStatsQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.UsageStatsFilterArgs>;
  offset: Types.Scalars["Int"];
  limit: Types.Scalars["Int"];
  orderBy?: Types.InputMaybe<Types.Scalars["String"]>;
  orderDirection?: Types.InputMaybe<Types.Scalars["String"]>;
}>;

export type UsageStatsQuery = {
  __typename?: "Query";
  usageStats: {
    __typename?: "UsageStatDetailsAndCount";
    count: number;
    data: Array<{
      __typename?: "UsageStatDetails";
      id: string;
      activity: string;
      comment?: string | null;
      startedAt: string;
      finishedAt: string;
      kontistUser?: {
        __typename?: "UsageStatUser";
        email?: string | null;
        firstName?: string | null;
        lastName?: string | null;
      } | null;
    }>;
  };
};

export const UsageStatsDocument = gql`
  query usageStats(
    $filter: UsageStatsFilterArgs
    $offset: Int!
    $limit: Int!
    $orderBy: String
    $orderDirection: String
  ) {
    usageStats(
      filter: $filter
      offset: $offset
      limit: $limit
      orderBy: $orderBy
      orderDirection: $orderDirection
    ) {
      count
      data {
        id
        activity
        comment
        startedAt
        finishedAt
        kontistUser {
          email
          firstName
          lastName
        }
      }
    }
  }
`;

/**
 * __useUsageStatsQuery__
 *
 * To run a query within a React component, call `useUsageStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsageStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsageStatsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      orderBy: // value for 'orderBy'
 *      orderDirection: // value for 'orderDirection'
 *   },
 * });
 */
export function useUsageStatsQuery(
  baseOptions: Apollo.QueryHookOptions<
    UsageStatsQuery,
    UsageStatsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UsageStatsQuery, UsageStatsQueryVariables>(
    UsageStatsDocument,
    options
  );
}
export function useUsageStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UsageStatsQuery,
    UsageStatsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UsageStatsQuery, UsageStatsQueryVariables>(
    UsageStatsDocument,
    options
  );
}
export type UsageStatsQueryHookResult = ReturnType<typeof useUsageStatsQuery>;
export type UsageStatsLazyQueryHookResult = ReturnType<
  typeof useUsageStatsLazyQuery
>;
export type UsageStatsQueryResult = Apollo.QueryResult<
  UsageStatsQuery,
  UsageStatsQueryVariables
>;
