import React, { useMemo } from "react";
import { Button } from "antd";
import { DownloadOutlined } from "@ant-design/icons";

import moment from "moment-timezone";

import { base64toBlob } from "../../../../common/helpers";
import {
  DeclineDateContainer,
  DeclineInfoHeader,
  DeclineReasonContainer,
  DeclineReasonMetaContainer,
  InternalPdfContainer,
  StyledModal,
  UserNameContainer,
} from "./styledComponents";
import { useUserContext } from "../../contexts/UserContext";
import useTaxYearParam from "../../hooks/useTaxYearParam";
import { getDeclarationDownloadName } from "./utils";
import {
  DeclarationDecline,
  TaxDeclarationSavedDraftInfo,
  TaxDeclarationSubmission,
  TaxDeclarationSubmissionInfo,
} from "../../../../../api/graphql/schema.generated";
import LocalAssetsViewer from "../../../../common/LocalAssetsViewer";

export interface PdfPreviewModalProps {
  title: string;
  info:
    | TaxDeclarationSavedDraftInfo
    | TaxDeclarationSubmissionInfo
    | TaxDeclarationSubmission;
  declarationName: string;
  creationDate?: string;
  declarationDecline?: DeclarationDecline;
  statusUpdatedAt?: string;
  onClose: () => void;
}

const PdfPreviewModal = ({
  title,
  info,
  onClose,
  creationDate,
  declarationName,
  declarationDecline,
  statusUpdatedAt,
}: PdfPreviewModalProps) => {
  const hasExternalAssets = useMemo(
    () =>
      !!(info as TaxDeclarationSubmissionInfo | TaxDeclarationSavedDraftInfo)
        ?.externalAssets,
    [info]
  );

  const pdfUrl = useMemo(() => {
    if (!info.pdf) {
      return null;
    }

    const blob = base64toBlob(info.pdf!);
    return URL.createObjectURL(blob);
  }, [info]);

  const calculationSheetUrl = useMemo(() => {
    if (!info.calculationSheet) {
      return null;
    }

    const blob = base64toBlob(info.calculationSheet!);
    return URL.createObjectURL(blob);
  }, [info]);

  const assets = useMemo(() => {
    const result = [];

    if (pdfUrl) {
      result.push({
        filetype: "pdf",
        fullsize: pdfUrl,
      });
    }
    if (calculationSheetUrl) {
      result.push({
        filetype: "pdf",
        fullsize: calculationSheetUrl,
      });
    }

    if ("externalAssets" in info && info.externalAssets) {
      result.push(
        ...info.externalAssets.map((asset) => ({
          filetype: asset.filetype!,
          fullsize: asset.url,
        }))
      );
    }

    return result;
  }, [pdfUrl, calculationSheetUrl, info]);

  const user = useUserContext();
  const [taxYear] = useTaxYearParam();

  const DeclineInfo = useMemo(
    () =>
      declarationDecline && (
        <>
          <DeclineInfoHeader>
            <UserNameContainer>
              {`${user?.firstName} ${user?.lastName}`}
            </UserNameContainer>
            <DeclineDateContainer>
              {statusUpdatedAt &&
                `Abgelehnt am ${moment(statusUpdatedAt).format("L")}`}
            </DeclineDateContainer>
          </DeclineInfoHeader>
          <DeclineReasonContainer>
            <DeclineReasonMetaContainer>{`Mandant ${moment(
              declarationDecline.createdAt
            ).format("L")}`}</DeclineReasonMetaContainer>
            <div>{declarationDecline.reason}</div>
          </DeclineReasonContainer>
        </>
      ),
    [declarationDecline, statusUpdatedAt, user]
  );

  return (
    <StyledModal
      title={`${title}${hasExternalAssets ? " (extern)" : ""}`}
      centered
      visible
      onOk={onClose}
      onCancel={onClose}
      width={800}
    >
      {DeclineInfo}
      <div style={{ marginTop: 2, position: "relative" }}>
        {pdfUrl && (
          <Button
            className="mx-4 my-3"
            type="default"
            size="small"
            style={{ display: "inline-flex", alignItems: "center" }}
            icon={<DownloadOutlined />}
            download={getDeclarationDownloadName({
              firstName: user?.firstName,
              lastName: user?.lastName,
              creationDate: creationDate ? new Date(creationDate) : new Date(),
              declarationName,
              taxYear,
            })}
            href={pdfUrl}
            target="_blank"
          >
            Download
          </Button>
        )}
        {assets.length === 1 ? (
          <InternalPdfContainer>
            <iframe
              title={title}
              src={assets[0].fullsize}
              style={{ width: "100%", height: "100%" }}
            />
          </InternalPdfContainer>
        ) : (
          <LocalAssetsViewer
            topPagination={!!declarationDecline}
            minHeight={600}
            assets={assets}
          />
        )}
      </div>
    </StyledModal>
  );
};
export default PdfPreviewModal;
