import { Drawer, Space } from "antd";
import styled from "styled-components";

import colors from "../../../themes/colors";

export const UserInfoExcerptWrapper = styled.div`
  width: 100%;
  margin-top: 20px;
  padding: 10px 16px;
  border-radius: 4px;
  background-color: ${colors.lightestPurple};
  cursor: pointer;

  &:hover {
    background-color: ${colors.lightPurple};
  }

  span.loading-text {
    margin-right: 16px;
  }
`;

export const UserNameWrapper = styled.span`
  color: ${colors.black};
  margin-right: 16px;
`;

export const UserInfoWrapper = styled.span`
  color: ${colors.veryDarkGreyishViolet};

  &::after {
    content: " • ";
  }

  &:last-child::after {
    content: "";
  }
`;

export const ViewMoreWrapper = styled.span`
  float: right;
  color: ${colors.purple};

  svg {
    vertical-align: text-bottom;
    margin-left: 8px;
  }
`;

export const AntDrawerStyled = styled(Drawer)`
  .ant-drawer-header {
    padding: 8px 16px;
  }

  .ant-drawer-footer {
    padding: 16px;
  }

  .anticon svg {
    vertical-align: unset;
  }
`;

export const AttributeTitle = styled.div`
  font-weight: 500;
`;

export const AttributeData = styled.div`
  font-weight: 400;
`;

export const FullSpace = styled(Space)`
  width: 100%;
`;
