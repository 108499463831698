import styled from "styled-components";
import { Col, Select, Modal, Input } from "antd";

import colors from "../../../../../../../../themes/colors";

export const StyledModal = styled(Modal)`
  top: 20px;
`;

export const ListScrollContainer = styled.div`
  height: 445px;
  border-top: 1px solid ${colors.veryLightGrey};
  border-bottom: 1px solid ${colors.veryLightGrey};
  margin: 24px -24px; /* use negative margin to make the list full-width in modal */
  overflow-y: scroll;
`;

export const SpinContainer = styled.div`
  padding: 283px 0 282px;
  text-align: center;
`;

export const StyledSelect: typeof Select = styled(Select)`
  width: 300px;
`;

export const ElsterCodeCol = styled(Col)`
  padding-left: 18px;
`;

export const AmountCol = styled(Col)`
  text-align: right;
  padding-right: 18px;
  white-space: nowrap;
`;

export const Error = styled.div`
  color: ${colors.darkRed};
  padding: 10px 0px;
  font-weight: bold;
`;

export const AmountInputWrapper = styled.div`
  text-align: right;
  padding: 5px 11px 5px 0;
  height: 32px;
  border: 1px solid transparent;
  border-radius: 2px;
  transition: border-color 0.3s;

  &.editable:hover {
    border-color: ${colors.midLightGrey};
  }
`;

export const StyledInput = styled(Input)`
  text-align: right;
`;
