import React from "react";
import { message } from "antd";
import styled from "styled-components";

import colors from "../../../themes/colors";
import UserInfoExcerpt from "../UserInfoExcerpt";
import { ViewMoreWrapper } from "../UserInfoExcerpt/styledComponents";
import Copyable from "../Copyable";
import { useKontaxUserContext } from "../../contexts/KontaxUserContext";
import { UserInfoDrawerSources } from "../UserInfoExcerpt/UserInfoDrawer";

const Wrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  margin-top: 30px;
`;

export const NameAndInfoWrapper = styled.div`
  display: flex;
  gap: 5px;
  justify-content: space-between;
`;

export const UserName = styled.div`
  color: ${colors.almostBlack};
`;

export const UserEmail = styled.div`
  color: rgba(0, 0, 0, 0.45);
  cursor: pointer;
  gap: 5px;
  align-items: center;
  display: flex;
`;

export const ViewMore = styled(ViewMoreWrapper)`
  cursor: pointer;
`;

interface Props {
  source: UserInfoDrawerSources;
}

const UserInfo = ({ source }: Props) => {
  const { user, notes, isLoading } = useKontaxUserContext();
  const copyToClipboard = () => {
    if (!user) {
      return;
    }
    navigator.clipboard.writeText(user.email);
    message.success("Email was copied to clipboard.");
  };

  return (
    <Wrapper>
      <NameAndInfoWrapper>
        {!isLoading && (
          <UserName>
            {user?.firstName} {user?.lastName}
          </UserName>
        )}
        <UserInfoExcerpt
          user={user}
          notes={notes}
          source={source}
          isLoading={isLoading}
          renderExcerptData={() => <ViewMore>View more info</ViewMore>}
          wrapperStyle={{ marginTop: 0 }}
        />
      </NameAndInfoWrapper>

      {!isLoading && user?.email && (
        <UserEmail data-test="userEmailInMoreInfo" onClick={copyToClipboard}>
          <Copyable withFixedIcon>{user.email}</Copyable>
        </UserEmail>
      )}
    </Wrapper>
  );
};

export default UserInfo;
