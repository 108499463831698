import React from "react";
import { Space } from "antd";
import denormalizeTaxNumber from "denormalize-steuernummer";

import { ExcerptData, BookkeepingSourceType } from "../../../../types";
import {
  formatDayMonthYear,
  formatMonthYear,
} from "../../../pages/Mandanten/Common/UserDetails/utils";
import DrawerUserAttribute from "../DrawerUserAttribute";
import Copyable from "../../Copyable";

const AccountingTemplate = ({
  user,
  subscriptionType,
  vatDetails,
}: ExcerptData) => {
  const taxNumber = denormalizeTaxNumber(user.taxNumber!)?.taxNumber;
  const bookkeepingTool = user.bookkeepingSources?.find(
    (source) => source.type === BookkeepingSourceType.TOOL
  );

  return (
    user && (
      <Space direction="vertical" size="large">
        <DrawerUserAttribute label="Name">
          <Copyable withFixedIcon text={`${user.firstName} ${user.lastName}`}>
            {user.firstName} {user.lastName}
          </Copyable>
        </DrawerUserAttribute>

        <DrawerUserAttribute label="Account-Id">
          <Copyable withFixedIcon>{user.accountId}</Copyable>
        </DrawerUserAttribute>

        <DrawerUserAttribute label="Email">
          <Copyable withFixedIcon>{user.email}</Copyable>
        </DrawerUserAttribute>

        <DrawerUserAttribute label="Telefonnummer">
          <Copyable withFixedIcon>{user.mobileNumber}</Copyable>
        </DrawerUserAttribute>

        <DrawerUserAttribute label="Geschäftsadresse">
          {user.kontaxUser?.businessAddresses?.length
            ? user.kontaxUser?.businessAddresses.map((address) => (
                <p key={address.street}>
                  {address.street}, {address.postCode} {address.city}
                </p>
              ))
            : "-"}
        </DrawerUserAttribute>

        <DrawerUserAttribute label="USt-ID">
          {user.vatNumber ? (
            <Copyable withFixedIcon>{user.vatNumber}</Copyable>
          ) : (
            "-"
          )}
        </DrawerUserAttribute>

        <DrawerUserAttribute label="Steuernummer">
          {taxNumber ? <Copyable withFixedIcon>{taxNumber}</Copyable> : "-"}
        </DrawerUserAttribute>

        <DrawerUserAttribute
          label={`USt-Voranmeldungszeitraum ${vatDetails?.year}`}
        >
          {vatDetails?.isKleinunternehmer && "Kleinunternehmer "}
          {vatDetails?.frequency || "-"}
        </DrawerUserAttribute>

        <DrawerUserAttribute label="Beginn Tätigkeit">
          {formatDayMonthYear(user.kontaxUser?.businessStartDate as string)}
        </DrawerUserAttribute>

        <DrawerUserAttribute label="Art des Betriebes">
          {user.kontaxUser?.businessType || "-"}
        </DrawerUserAttribute>

        <DrawerUserAttribute label="Kommentar zur Art des Betriebs">
          {user.businessTypeComment?.trim() || "-"}
        </DrawerUserAttribute>

        <DrawerUserAttribute label="Mehrfach Selbständig">
          {user.hasMoreThanOneBusiness ? "Ja" : "Nein"}
        </DrawerUserAttribute>

        <DrawerUserAttribute label="Steuerfreie Umsätze">
          {user.vatExemptionWithItd}
          {user.vatExemptionWithoutItd}
          {!user.vatExemptionWithItd && "-"}
        </DrawerUserAttribute>

        <DrawerUserAttribute label="Mitarbeiter">
          {user.hasEmployees ? "Ja" : "Nein"}
        </DrawerUserAttribute>

        <DrawerUserAttribute label="Buchhaltungstool">
          {bookkeepingTool?.name || "-"}
        </DrawerUserAttribute>

        <DrawerUserAttribute label="Buchhaltungstool Link">
          {bookkeepingTool?.link ? (
            <Copyable>{bookkeepingTool.link}</Copyable>
          ) : (
            "-"
          )}
        </DrawerUserAttribute>

        <DrawerUserAttribute label="Gewerbesteuer">
          {user.kontaxUser?.tradeTax || "-"}
        </DrawerUserAttribute>

        <DrawerUserAttribute label="FiBu start date">
          {formatMonthYear(user.fibuStartDate)}
        </DrawerUserAttribute>

        <DrawerUserAttribute label="Kontax billing date">
          {formatDayMonthYear(user.kontaxBillingStartDate)}
        </DrawerUserAttribute>

        <DrawerUserAttribute label="Recurly plan">
          {subscriptionType}
        </DrawerUserAttribute>
      </Space>
    )
  );
};

export default AccountingTemplate;
