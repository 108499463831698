import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Row,
  Select,
  Space,
  Typography,
  Form,
  FormInstance,
  Input,
} from "antd";
import { UserOutlined } from "@ant-design/icons";
import sum from "lodash/sum";
import isEqual from "lodash/isEqual";
import moment, { Moment } from "moment";
import { useLocation } from "react-router-dom";
import {
  EuerDeclarationCarUsageSubform1PctMethodInputs,
  EuerDeclarationCarUsageSubformCalculationResults,
  calculateSubformResults,
  EuerDeclarationCarUsageSubformDriversLogInputs,
  CarUsageInputs,
  CarExpenses,
} from "@kontist/euer-declaration";

import useTaxYearParam from "../../../../hooks/useTaxYearParam";
import RadioButtonGroup from "../../../../../../common/RadioButtonGroup";
import BackButton from "../../components/BackButton";
import {
  EuerNumericField,
  EuerCurrencyField,
} from "../../components/EuerFormFields";
import colors from "../../../../../../../themes/colors";
import {
  CarType,
  EuerDeclarationSubformCalculationMethod,
  EuerDeclarationSubformStatus,
  SaveAssetResult,
} from "../../../../types";
import {
  centsToEuros,
  currencyFormatter,
  destroyMessage,
  eurosToCents,
  formatAmount,
  formatAmountInCents,
  formatAmountToCurrency,
  showLoadingMessage,
} from "../../../../../../../utils";
import useEmailParam from "../../../../../../hooks/useEmailParam";
import { Asset, ILocalAsset, VatRate } from "../../../../../../../types";
import { CAR_USAGE_CALCULATION_METHOD_OPTIONS } from "./constants";
import CarUsageStatusChangeModal from "./CarUsageStatusChangeModal";
import DeclarationStatus from "../../../../components/DeclarationStatus";
import LabelWithTag from "../../components/LabelWithTag";
import LocalAssetsViewer from "../../../../../../common/LocalAssetsViewer";
import Upload from "../../../../../../common/Upload";
import {
  DriversLogAutoCalculatedFormValues,
  CarUsageAutoCalculatedFormValues,
  EuerDeclarationSubformWithAutoCalculatedValues,
  OnePercentAutoCalculatedValues,
  useUpdateEuerDeclarationSubformMutation,
  ChangeLog,
} from "../../../../../../../api/graphql";
import {
  EuerDeclarationSubformType,
  KontaxNoteType,
} from "../../../../../../../api/graphql/schema.generated";
import useEuerDeclarationSubformAssetsUploader from "../../hooks/useEuerDeclarationSubformAssetsUploader";
import { SUBFORM_STATUS_MAPPINGS } from "../../constants";
import {
  StyledForm,
  StyledFieldset,
  InputWithAutoWrapper,
  AutoWrapper,
  LabelWithIcon,
} from "./styledComponents";
import ActionLogDrawer from "../../../../../../common/ActionLogDrawer";
import NotesDrawer from "../../../../../../common/NotesDrawer";
import { notifyUserAfterSubmittingSubform } from "../../../../utils";
import { useEuerDeclarationSubformQuery } from "../../../../../../../api/graphql/queries/euerDeclarationSubform.generated";
import WarnLeaveWithoutSaving from "../../../../../../common/WarnLeaveWithoutSaving";
import { ActionsContainer } from "../../../../styles";

const { Option } = Select;
const { Text, Title } = Typography;
const dateFormat = "DD.MM.YYYY";

const LOADING_MESSAGE_KEY = "finding-user";

export const QUESTIONNAIRE_SYNC_SUPPORTED_AFTER = 2020;

type EuerDeclarationCarUsageSubformInputs =
  | EuerDeclarationCarUsageSubform1PctMethodInputs
  | EuerDeclarationCarUsageSubformDriversLogInputs;

type Form1PctInputs = CarUsageInputs &
  Pick<
    EuerDeclarationCarUsageSubform1PctMethodInputs,
    | "catalogPrice"
    | "usageMonths"
    | "actualCosts"
    | "interestCosts"
    | "depreciationCosts"
    | "collectedVat"
  > & {
    purchaseDate: Moment;
  };

type DriversLogFieldsWithAutoCalculation = {
  // Vat 19%
  interestWithTaxDeduction: number;
  leasingWithTaxDeduction: number;
  feesWithTaxDeduction: number;
  costsWithTaxDeduction: number;
  depreciationWithTaxDeduction: number;
  // Vat 0%
  interestWithoutTaxDeduction: number;
  leasingWithoutTaxDeduction: number;
  feesWithoutTaxDeduction: number;
  costsWithoutTaxDeduction: number;
  depreciationWithoutTaxDeduction: number;
};

type FormDriversLogInputs = CarUsageInputs &
  Pick<
    EuerDeclarationCarUsageSubformDriversLogInputs,
    "totalKm" | "privateKm" | "homeCompanyKm"
  > &
  DriversLogFieldsWithAutoCalculation;

type CarUsageQuestionnaireValues = {
  catalogPrice?: number;
  purchaseDate?: Moment;
  totalKm?: number;
  privateKm?: number;
  homeCompanyKm?: number;
  commuteDistance?: number;
  commuteDays?: number;
  carType?: CarType;
};

const INITIAL_CALCULATION_RESULTS = {
  privateCarUsage: 0, // [106] Private Kfz-Nutzung
  collectedVat: 0, // [140] Vereinnahmte USt 19 %
  homeCompanyTrips: 0, // [142] Fahrten zwischen Wohnung/Betrieb
  minDeductibleTravelExpenses: 0, // [176] Min. abziehbare Fahrtkosten
  // `costCap` is only used for the 1% calculation method.
  costCap: 0, // Kostendeckelung
  profitIncrease: 0, // Gewinnerhöhung
};

/**
 * Checks if the calculation method is `1%`.
 */
export const is1PctMethod = (
  calculationMethod: EuerDeclarationSubformCalculationMethod
) =>
  calculationMethod ===
  EuerDeclarationSubformCalculationMethod.CAR_USAGE_1_PCT_METHOD;

const allAreNumbers = (values: any[], inputs: any) =>
  values.every((key) => !isNaN(inputs[key]));

/**
 * Validates (based on the calculation method) that the required inputs to calculate
 * the results are valid/defined.
 */
const inputsAreValid = (
  calculationMethod: EuerDeclarationSubformCalculationMethod,
  inputs: EuerDeclarationCarUsageSubformInputs
) => {
  if (
    !inputs.carType ||
    !allAreNumbers(["commuteDays", "commuteDistance"], inputs)
  ) {
    return false;
  }

  return is1PctMethod(calculationMethod)
    ? onePercentInputsAreValid(
        inputs as EuerDeclarationCarUsageSubform1PctMethodInputs
      )
    : driversLogInputsAreValid(
        inputs as EuerDeclarationCarUsageSubformDriversLogInputs
      );
};

const onePercentInputsAreValid = (
  inputs: EuerDeclarationCarUsageSubform1PctMethodInputs
) => allAreNumbers(["usageMonths", "catalogPrice"], inputs);

const driversLogInputsAreValid = (
  inputs: EuerDeclarationCarUsageSubformDriversLogInputs
) =>
  allAreNumbers(
    [
      "totalKm",
      "privateKm",
      "homeCompanyKm",
      "interestWithTaxDeduction",
      "leasingWithTaxDeduction",
      "feesWithTaxDeduction",
      "costsWithTaxDeduction",
      "depreciationWithTaxDeduction",
      "interestWithoutTaxDeduction",
      "leasingWithoutTaxDeduction",
      "feesWithoutTaxDeduction",
      "costsWithoutTaxDeduction",
      "depreciationWithoutTaxDeduction",
    ],
    inputs
  );

/**
 * Based on the selected calculation method it returns some of the initial form fields
 * values that come from previously saved values or 0 by default if the first is undefined.
 */
const getInitialFormFields = (
  subform: EuerDeclarationSubformWithAutoCalculatedValues,
  questionnaireValues: CarUsageQuestionnaireValues | null,
  taxYear: number,
  newCalculationMethod?: EuerDeclarationSubformCalculationMethod
) => {
  if (is1PctMethod(newCalculationMethod || subform.calculationMethod)) {
    const inputs =
      subform.inputs as EuerDeclarationCarUsageSubform1PctMethodInputs;

    const purchaseDate =
      inputs.purchaseDate != null
        ? moment(inputs.purchaseDate)
        : questionnaireValues?.purchaseDate;

    const catalogPrice =
      (inputs.catalogPrice && centsToEuros(inputs.catalogPrice)) ??
      questionnaireValues?.catalogPrice ??
      0;

    return {
      purchaseDate,
      catalogPrice,
      collectedVat: centsToEuros(inputs.collectedVat),
      usageMonths:
        inputs.usageMonths ??
        (purchaseDate ? calculateUsageMonths(purchaseDate, taxYear) : null),
    };
  } else {
    const inputs =
      subform.inputs as EuerDeclarationCarUsageSubformDriversLogInputs;
    return {
      totalKm: inputs.totalKm ?? questionnaireValues?.totalKm ?? 0,
      privateKm: inputs.privateKm ?? questionnaireValues?.privateKm ?? 0,
      homeCompanyKm:
        inputs.homeCompanyKm ?? questionnaireValues?.homeCompanyKm ?? 0,
    };
  }
};

/**
 * Based on the selected calculation method it returns the initial car expenses values
 * that come from either previously saved values, auto calculated or 0 by default
 * if none of the first two are defined.
 */
const getInitialCarExpenses = (
  subform: EuerDeclarationSubformWithAutoCalculatedValues,
  newCalculationMethod?: EuerDeclarationSubformCalculationMethod
): OnePercentAutoCalculatedValues | DriversLogFieldsWithAutoCalculation => {
  const calculationMethod = newCalculationMethod || subform.calculationMethod;
  return is1PctMethod(calculationMethod)
    ? getInitial1PctCarExpenses(subform)
    : getInitialDriversLogCarExpenses(subform);
};

/**
 * Returns the initial car expenses values of the 1Pct's (PKW 1%) calculation method.
 * If there was already saved inputs they are used, else auto-calculated values from the BE are used.
 * When values none of the above values are defined, 0 is assigned.
 */
const getInitial1PctCarExpenses = (
  subform: EuerDeclarationSubformWithAutoCalculatedValues
): OnePercentAutoCalculatedValues => {
  const { actualCosts, interestCosts, depreciationCosts } =
    subform.inputs as EuerDeclarationCarUsageSubform1PctMethodInputs;
  const {
    actualCosts: autoCalculatedActualCosts,
    interestCosts: autoCalculatedInterestCosts,
    depreciationCosts: autoCalculatedDepreciationCosts,
  } = (subform.autoCalculatedValues as CarUsageAutoCalculatedFormValues)
    ?.onePercent || {};
  return {
    actualCosts: centsToEuros(actualCosts || autoCalculatedActualCosts),
    interestCosts: centsToEuros(interestCosts || autoCalculatedInterestCosts),
    depreciationCosts: centsToEuros(
      depreciationCosts || autoCalculatedDepreciationCosts
    ),
  };
};

/**
 * Returns the initial car expenses values of the Driver's log (Fahrtenbuch) calculation method.
 * If there was already saved inputs they are used, else auto-calculated values from the BE are used.
 * When values none of the above values are defined, 0 is assigned.
 */
const getInitialDriversLogCarExpenses = (
  subform: EuerDeclarationSubformWithAutoCalculatedValues
): DriversLogFieldsWithAutoCalculation => {
  const { carExpenses = {}, carExpensesWithTaxDeduction = {} } =
    subform.inputs as EuerDeclarationCarUsageSubformDriversLogInputs;
  // Vat 19%
  const {
    interestCosts: interestWithTaxDeduction,
    leasingCosts: leasingWithTaxDeduction,
    carFees: feesWithTaxDeduction,
    carCosts: costsWithTaxDeduction,
    depreciationCosts: depreciationWithTaxDeduction,
  } = carExpensesWithTaxDeduction as CarExpenses;
  // Vat 0%
  const {
    interestCosts: interestWithoutTaxDeduction,
    leasingCosts: leasingWithoutTaxDeduction,
    carFees: feesWithoutTaxDeduction,
    carCosts: costsWithoutTaxDeduction,
    depreciationCosts: depreciationWithoutTaxDeduction,
  } = carExpenses as CarExpenses;
  const autoCalculatedValues = (subform.autoCalculatedValues?.driversLog ||
    {}) as DriversLogAutoCalculatedFormValues;

  return {
    // Vat 19%
    interestWithTaxDeduction: centsToEuros(
      interestWithTaxDeduction ??
        autoCalculatedValues[VatRate.VAT_19]?.interestCosts
    ),
    leasingWithTaxDeduction: centsToEuros(
      leasingWithTaxDeduction ??
        autoCalculatedValues[VatRate.VAT_19]?.leasingCosts
    ),
    feesWithTaxDeduction: centsToEuros(
      feesWithTaxDeduction ?? autoCalculatedValues[VatRate.VAT_19]?.carFees
    ),
    costsWithTaxDeduction: centsToEuros(
      costsWithTaxDeduction ?? autoCalculatedValues[VatRate.VAT_19]?.carCosts
    ),
    depreciationWithTaxDeduction: centsToEuros(
      depreciationWithTaxDeduction ??
        autoCalculatedValues[VatRate.VAT_19]?.depreciationCosts
    ),
    // Vat 0%
    interestWithoutTaxDeduction: centsToEuros(
      interestWithoutTaxDeduction ??
        autoCalculatedValues[VatRate.VAT_0]?.interestCosts
    ),
    leasingWithoutTaxDeduction: centsToEuros(
      leasingWithoutTaxDeduction ??
        autoCalculatedValues[VatRate.VAT_0]?.leasingCosts
    ),
    feesWithoutTaxDeduction: centsToEuros(
      feesWithoutTaxDeduction ?? autoCalculatedValues[VatRate.VAT_0]?.carFees
    ),
    costsWithoutTaxDeduction: centsToEuros(
      costsWithoutTaxDeduction ?? autoCalculatedValues[VatRate.VAT_0]?.carCosts
    ),
    depreciationWithoutTaxDeduction: centsToEuros(
      depreciationWithoutTaxDeduction ??
        autoCalculatedValues[VatRate.VAT_0]?.depreciationCosts
    ),
  };
};

/**
 * Gets and transforms the 1Pct specific form inputs into the expected
 * payload format to submit to the back-end.
 */
const get1PctInputs = (inputs: Omit<Form1PctInputs, "carType">) => ({
  purchaseDate: inputs.purchaseDate && inputs.purchaseDate.format("YYYY-MM-DD"),
  usageMonths: inputs.usageMonths,
  catalogPrice: eurosToCents(inputs.catalogPrice),
  actualCosts: eurosToCents(inputs.actualCosts),
  interestCosts: eurosToCents(inputs.interestCosts),
  depreciationCosts: eurosToCents(inputs.depreciationCosts),
  collectedVat: eurosToCents(inputs.collectedVat!),
});

/**
 * Gets and transforms the Drivers' log specific form inputs into the expected
 * payload format to submit to the back-end.
 */
const getDriversLogInputs = (
  inputs: Omit<FormDriversLogInputs, "carType">
) => ({
  totalKm: inputs.totalKm,
  privateKm: inputs.privateKm,
  homeCompanyKm: inputs.homeCompanyKm,
  carExpenses: {
    interestCosts: eurosToCents(inputs.interestWithoutTaxDeduction),
    leasingCosts: eurosToCents(inputs.leasingWithoutTaxDeduction),
    carFees: eurosToCents(inputs.feesWithoutTaxDeduction),
    carCosts: eurosToCents(inputs.costsWithoutTaxDeduction),
    depreciationCosts: eurosToCents(inputs.depreciationWithoutTaxDeduction),
  },
  carExpensesWithTaxDeduction: {
    interestCosts: eurosToCents(inputs.interestWithTaxDeduction),
    leasingCosts: eurosToCents(inputs.leasingWithTaxDeduction),
    carFees: eurosToCents(inputs.feesWithTaxDeduction),
    carCosts: eurosToCents(inputs.costsWithTaxDeduction),
    depreciationCosts: eurosToCents(inputs.depreciationWithTaxDeduction),
  },
});

/**
 * Returns a formatted payload based on the form fields to submit to the BE.
 */
const getFormValues = (
  headerForm: FormInstance,
  form: FormInstance,
  status: EuerDeclarationSubformStatus,
  calculationResults: EuerDeclarationCarUsageSubformCalculationResults
) => {
  const { calculationMethod, carType } = headerForm.getFieldsValue(true);
  const inputs = form.getFieldsValue(true);
  return {
    calculationMethod,
    inputs: {
      carType,
      commuteDistance: inputs.commuteDistance,
      commuteDays: inputs.commuteDays,
      ...(is1PctMethod(calculationMethod)
        ? get1PctInputs(inputs)
        : getDriversLogInputs(inputs)),
    },
    calculationResults: calculationResults,
    status,
  };
};

const calculateUsageMonths = (purchaseDate: Moment, taxYear: number) => {
  if (!purchaseDate) {
    return undefined;
  }

  if (purchaseDate.year() < taxYear) {
    return 12;
  } else if (purchaseDate.year() === taxYear) {
    return 12 - purchaseDate.month();
  } else {
    return undefined;
  }
};

const sumValues = (values: Array<number | null>) =>
  sum(values.map((value) => value || 0));

const CarUsage = () => {
  const [taxYear] = useTaxYearParam();
  const [email] = useEmailParam();
  const location = useLocation();
  const [headerForm] = Form.useForm();
  const [form] = Form.useForm<Form1PctInputs | FormDriversLogInputs>();
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const [EuerFormState, setEuerFormState] = useState({} as any);
  const [calculationResults, setCalculationResults] =
    useState<EuerDeclarationCarUsageSubformCalculationResults>(
      INITIAL_CALCULATION_RESULTS
    );
  const [initialFormValues, setInitialFormValues] = useState({});
  const [initialCalculationResults, setInitialCalculationResults] = useState(
    {}
  );
  const [questionnaireCalculationMethod, setQuestionnaireCalculationMethod] =
    useState<EuerDeclarationSubformCalculationMethod | null>(null);
  const [questionnaireValues, setQuestionnaireValues] =
    useState<CarUsageQuestionnaireValues | null>(null);

  const { assets, setAssets, handleDropFiles, saveAssets } =
    useEuerDeclarationSubformAssetsUploader();

  const [isStatusChangeModalVisible, setIsStatusChangeModalVisible] =
    useState(false);

  const [isCommuteFieldsDisable, setIsCommuteFieldsDisable] = useState(true);

  const isFieldEqualToQuestionnaireValue = useCallback(
    (fieldName: keyof CarUsageQuestionnaireValues) => {
      const questionnaireValue =
        questionnaireValues?.[fieldName as keyof typeof initialFormValues];

      return (
        questionnaireValue != null &&
        form.getFieldValue(fieldName) === questionnaireValue
      );
    },
    [form, questionnaireValues]
  );

  const showStatusChangeModal = useCallback(() => {
    setIsStatusChangeModalVisible(true);
  }, []);

  const handleStatusChangeModalClose = useCallback(() => {
    setIsStatusChangeModalVisible(false);
  }, []);

  const currentFormValues = form.getFieldsValue(true);
  const currentHeaderFormValues = headerForm.getFieldsValue(true);
  const isDirty = useMemo(() => {
    const formValuesChanged = !isEqual(
      {
        calculationMethod: currentHeaderFormValues.calculationMethod,
        carType: currentHeaderFormValues.carType,
        commuteDistance: currentFormValues.commuteDistance,
        commuteDays: currentFormValues.commuteDays,
        ...(is1PctMethod(currentHeaderFormValues.calculationMethod)
          ? get1PctInputs(currentFormValues)
          : getDriversLogInputs(currentFormValues)),
        assets,
      },
      initialFormValues
    );

    const calculationResultsChanged = !isEqual(
      calculationResults,
      initialCalculationResults
    );

    return formValuesChanged || calculationResultsChanged;
  }, [
    assets,
    currentFormValues,
    currentHeaderFormValues,
    calculationResults,
    initialFormValues,
    initialCalculationResults,
  ]);

  const onEuerFormFieldChange = useCallback(
    (name: string, value: any) => {
      // Keep the form fields updated as they are reflected in the inputs.
      form.setFieldsValue({ [name]: value });
      // As form field values do not trigger re-renders, we need to keep the fields updated in parallel.
      setEuerFormState(
        (currentInputs: EuerDeclarationCarUsageSubformInputs) => ({
          ...currentInputs,
          [name]: value,
        })
      );
    },
    [form]
  );

  const handlePurchaseDateChange = useCallback(
    (purchaseDate: Moment) => {
      const usageMonths = calculateUsageMonths(purchaseDate, taxYear);

      form.setFieldsValue({
        purchaseDate,
        usageMonths,
      });
      setEuerFormState(
        (currentInputs: EuerDeclarationCarUsageSubformInputs) => ({
          ...currentInputs,
          purchaseDate,
          usageMonths,
        })
      );
    },
    [form, taxYear]
  );

  const { data: queryResult, refetch: refetchSubform } =
    useEuerDeclarationSubformQuery({
      skip: !email,
      notifyOnNetworkStatusChange: true,
      variables: {
        email: email!,
        year: taxYear,
        type: EuerDeclarationSubformType.CAR_USAGE,
      },
      onCompleted: (data) => {
        const subformData = data.euerDeclarationSubform;

        if (!subformData) {
          return;
        }

        const {
          calculationMethod: savedCalculationMethod,
          correspondingSubformMinDeductibleTravelExpenses,
          questionnaireCalculationMethod,
          questionnaireValues: savedQuestionnaireValues,
        } = subformData;

        setQuestionnaireCalculationMethod(
          questionnaireCalculationMethod || null
        );
        const questionnaireValues: CarUsageQuestionnaireValues = {
          ...savedQuestionnaireValues,
          ...(savedQuestionnaireValues?.catalogPrice != null
            ? {
                catalogPrice: centsToEuros(
                  savedQuestionnaireValues.catalogPrice as number
                ),
              }
            : {}),
          ...(savedQuestionnaireValues?.purchaseDate != null
            ? {
                purchaseDate: moment(savedQuestionnaireValues.purchaseDate),
              }
            : {}),
        };
        setQuestionnaireValues(questionnaireValues);

        const isEmptySubform = isEqual(subformData.calculationResults, {});

        const calculationMethod =
          isEmptySubform && questionnaireCalculationMethod
            ? questionnaireCalculationMethod
            : savedCalculationMethod;

        const { carType: savedCarType, ...formFields } =
          (subformData.inputs as unknown as EuerDeclarationCarUsageSubformInputs) ||
          {};

        const carType =
          savedCarType || questionnaireValues?.carType || CarType.ICE;

        const newValues = {
          ...formFields,
          commuteDays:
            formFields.commuteDays ?? questionnaireValues?.commuteDays ?? 0,
          commuteDistance:
            formFields.commuteDistance ??
            questionnaireValues?.commuteDistance ??
            0,
          ...getInitialFormFields(
            {
              ...subformData,
              calculationMethod,
            } as unknown as EuerDeclarationSubformWithAutoCalculatedValues,
            questionnaireValues,
            taxYear
          ),
          ...getInitialCarExpenses(
            subformData as unknown as EuerDeclarationSubformWithAutoCalculatedValues
          ),
        };

        form.setFieldsValue({
          ...form.getFieldsValue(true),
          ...newValues,
        });

        setIsCommuteFieldsDisable(
          !!correspondingSubformMinDeductibleTravelExpenses
        );

        const initialHeaderFormValues = {
          ...headerForm.getFieldsValue(true),
          carType,
          calculationMethod,
        };
        headerForm.setFieldsValue(initialHeaderFormValues);
        setEuerFormState(
          (currentInputs: EuerDeclarationCarUsageSubformInputs) => ({
            ...currentInputs,
            ...newValues,
            carType,
            calculationMethod,
          })
        );

        const initCalculationResults = isEmptySubform
          ? INITIAL_CALCULATION_RESULTS
          : (subformData.calculationResults as unknown as EuerDeclarationCarUsageSubformCalculationResults);
        setCalculationResults(initCalculationResults);

        setInitialFormValues({
          ...initialHeaderFormValues,
          commuteDistance: newValues.commuteDistance,
          commuteDays: newValues.commuteDays,
          /**
           * After processing data and getting the right initial form values depending on the calculation method,
           * we make use of `get1PctInputs` / `getDriversLogInputs` util method to convert them into "submittable"
           * values, in order to simplify future change calculation.
           */
          ...(is1PctMethod(calculationMethod)
            ? get1PctInputs(newValues as Form1PctInputs)
            : getDriversLogInputs(
                newValues as Omit<FormDriversLogInputs, "carType">
              )),
          assets: subformData.assets,
        });
        setInitialCalculationResults(initCalculationResults);

        setAssets(subformData.assets);
      },
    });
  const subform = queryResult?.euerDeclarationSubform;

  const autoCalculatedValues =
    subform?.autoCalculatedValues as CarUsageAutoCalculatedFormValues;

  const [updateEuerDeclarationSubform] =
    useUpdateEuerDeclarationSubformMutation();

  const saveForm = useCallback(
    async (status?: EuerDeclarationSubformStatus) => {
      if (email && subform) {
        setIsSaving(true);
        showLoadingMessage(LOADING_MESSAGE_KEY);
        let savingFailed = false;
        let assetResults: Array<SaveAssetResult> = [];

        try {
          const result = await updateEuerDeclarationSubform({
            variables: {
              email,
              year: taxYear,
              type: EuerDeclarationSubformType.CAR_USAGE,
              payload: getFormValues(
                headerForm,
                form,
                status || subform.status,
                calculationResults
              ),
            },
          });

          assetResults = await saveAssets(
            result.data?.updateEuerDeclarationSubform.id!
          );

          await refetchSubform();
        } catch (error) {
          savingFailed = true;
        } finally {
          destroyMessage(LOADING_MESSAGE_KEY);
          setIsSaving(false);
          notifyUserAfterSubmittingSubform(savingFailed, assetResults);
        }
      }
    },
    [
      email,
      subform,
      updateEuerDeclarationSubform,
      taxYear,
      headerForm,
      form,
      calculationResults,
      saveAssets,
      refetchSubform,
    ]
  );

  const onSaveClick = useCallback(() => {
    saveForm();
  }, [saveForm]);

  useEffect(() => {
    const carType = headerForm.getFieldValue("carType");
    const fieldsValue = form.getFieldsValue(true);
    if (
      inputsAreValid(EuerFormState.calculationMethod, {
        ...fieldsValue,
        carType,
      })
    ) {
      const calculationInputs = {
        carType,
        commuteDistance: fieldsValue.commuteDistance,
        commuteDays: fieldsValue.commuteDays,
        ...(is1PctMethod(EuerFormState.calculationMethod)
          ? get1PctInputs(fieldsValue)
          : getDriversLogInputs(fieldsValue)),
      };

      const result = calculateSubformResults(
        EuerDeclarationSubformType.CAR_USAGE,
        EuerFormState.calculationMethod,
        calculationInputs,
        taxYear
      );

      setCalculationResults(
        result as EuerDeclarationCarUsageSubformCalculationResults
      );
    } else {
      setCalculationResults(INITIAL_CALCULATION_RESULTS);
    }
  }, [headerForm, taxYear, EuerFormState, form]);

  const isActiveCostCap = useMemo(
    () => calculationResults.costCap == null || calculationResults.costCap < 0,
    [calculationResults.costCap]
  );
  const profitIncreased = useMemo(
    () => calculationResults.profitIncrease > 0,
    [calculationResults.profitIncrease]
  );

  const handleAssetDelete = (
    deletedAsset: Asset | ILocalAsset,
    deletedRemoteAsset?: boolean
  ) => {
    const newAssetsList = assets.filter(
      (asset) =>
        (asset as ILocalAsset).name !== (deletedAsset as ILocalAsset).name
    );
    setAssets(newAssetsList);
    // If the asset was deleted remotely, the original list needs to be updated to properly
    // calculate when the form has changed.
    if (deletedRemoteAsset) {
      setInitialFormValues((initialValues) => ({
        ...initialValues,
        assets: newAssetsList,
      }));
    }
  };

  const handleAutoButton = useCallback(
    (field: keyof CarUsageQuestionnaireValues) =>
      onEuerFormFieldChange(field, questionnaireValues?.[field]!),
    [onEuerFormFieldChange, questionnaireValues]
  );

  const shouldShowAutoField = useCallback(
    (field: keyof CarUsageQuestionnaireValues) => {
      return (
        taxYear > QUESTIONNAIRE_SYNC_SUPPORTED_AFTER &&
        questionnaireValues &&
        questionnaireValues[field] != null &&
        !isFieldEqualToQuestionnaireValue(field)
      );
    },
    [questionnaireValues, taxYear, isFieldEqualToQuestionnaireValue]
  );

  const handleAutoField = useCallback(
    (
      field: keyof CarUsageQuestionnaireValues,
      {
        formatValue,
        style,
      }: {
        formatValue?: (value: any) => string;
        style?: React.CSSProperties;
      } = {}
    ) => {
      const value = questionnaireValues?.[field];
      return shouldShowAutoField(field) ? (
        <AutoWrapper
          onClick={() => {
            handleAutoButton(field);
          }}
          style={style}
        >
          <UserOutlined />
          <span>{formatValue ? formatValue(value) : value}</span>
        </AutoWrapper>
      ) : null;
    },
    [shouldShowAutoField, questionnaireValues, handleAutoButton]
  );

  if (!subform) {
    return null;
  }

  const { calculationMethod } = EuerFormState;
  return (
    <>
      <WarnLeaveWithoutSaving warn={isDirty} />
      <BackButton
        text={`Anlage EÜR ${taxYear}`}
        to={{
          pathname: "/tax-declaration/euer-declaration",
          search: location.search,
        }}
      />
      <ActionsContainer>
        <ActionLogDrawer
          withIcon
          title="Action log"
          changeLogs={subform.changeLogs as ChangeLog[]}
        />
        <NotesDrawer
          notes={subform.notes}
          title="Private PKW-Nutzung"
          type={KontaxNoteType.EUER_DECLARATION_SUBFORM}
          recordId={subform.id}
        />
      </ActionsContainer>
      <Title level={3}>{`Private PKW-Nutzung ${taxYear}`}</Title>
      <StyledForm
        layout="vertical"
        initialValues={{
          carType: CarType.ICE,
          calculationMethod:
            EuerDeclarationSubformCalculationMethod.CAR_USAGE_1_PCT_METHOD,
        }}
        form={headerForm}
      >
        <Row gutter={16} style={{ marginBottom: 24 }}>
          <Col span={12}>
            <Form.Item label="Fahrzeugtyp" name="carType">
              <Select
                style={{ width: 300 }}
                onChange={(value: CarType) =>
                  onEuerFormFieldChange("carType", value)
                }
              >
                <Option value={CarType.ICE}>
                  <LabelWithIcon>
                    {questionnaireValues?.carType === CarType.ICE && (
                      <UserOutlined />
                    )}
                    Verbrennerfahrzeug (100 %)
                  </LabelWithIcon>
                </Option>
                <Option value={CarType.HYBRID}>
                  <LabelWithIcon>
                    {questionnaireValues?.carType === CarType.HYBRID && (
                      <UserOutlined />
                    )}
                    Hybrid / Elektrofahrzeuge (50 %)
                  </LabelWithIcon>
                </Option>
                <Option value={CarType.EV}>
                  <LabelWithIcon>
                    {questionnaireValues?.carType === CarType.EV && (
                      <UserOutlined />
                    )}
                    Elektrofahrzeug (25 %)
                  </LabelWithIcon>
                </Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="calculationMethod" label="Berechnungsmethode">
              <RadioButtonGroup
                direction="horizontal"
                options={CAR_USAGE_CALCULATION_METHOD_OPTIONS}
                renderLabel={(label, value) => (
                  <LabelWithIcon>
                    {value === questionnaireCalculationMethod && (
                      <UserOutlined />
                    )}
                    {label}
                  </LabelWithIcon>
                )}
                value={EuerFormState.calculationMethod}
                onChange={(value: EuerDeclarationSubformCalculationMethod) => {
                  headerForm.setFieldsValue({ calculationMethod: value });
                  // When calculation method changes, form fields need to be re-initialized.
                  const reinitializedFields = queryResult
                    ? {
                        ...getInitialCarExpenses(
                          queryResult.euerDeclarationSubform as unknown as EuerDeclarationSubformWithAutoCalculatedValues,
                          value
                        ),
                        ...getInitialFormFields(
                          queryResult.euerDeclarationSubform as unknown as EuerDeclarationSubformWithAutoCalculatedValues,
                          questionnaireValues,
                          taxYear,
                          value
                        ),
                      }
                    : {};
                  setEuerFormState(
                    (currentInputs: EuerDeclarationCarUsageSubformInputs) => ({
                      ...currentInputs,
                      calculationMethod: value,
                      ...reinitializedFields,
                    })
                  );
                  form.setFieldsValue({
                    ...form.getFieldsValue(true),
                    ...reinitializedFields,
                  });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <DeclarationStatus
              mapping={SUBFORM_STATUS_MAPPINGS}
              status={subform.status}
              statusUpdatedAt={subform.statusUpdatedAt}
              changedBy={subform.lastStatusChange?.changedBy}
              onClick={showStatusChangeModal}
            />
          </Col>
        </Row>
        <Divider />
        <Row justify="end" style={{ marginBottom: 24 }}>
          <Col>
            <Space>
              <Button onClick={showStatusChangeModal}>Status ändern</Button>
              <Button onClick={onSaveClick} type="primary" disabled={isSaving}>
                Speichern
              </Button>
            </Space>
          </Col>
        </Row>
      </StyledForm>
      <Row gutter={16} align="top">
        <Col
          span={12}
          style={{
            paddingRight: 30,
            minHeight: "600px",
            display: "flex",
          }}
        >
          <Upload
            onDropFiles={handleDropFiles}
            isInPreviewMode={!!assets.length}
          >
            <LocalAssetsViewer
              assets={assets}
              minHeight={600}
              onDelete={handleAssetDelete}
            />
          </Upload>
        </Col>
        <Col span={12}>
          <StyledForm
            layout="horizontal"
            labelAlign="left"
            colon={false}
            labelCol={{ span: 14 }}
            wrapperCol={{ span: 10 }}
            form={form}
            initialValues={{
              carType: CarType.ICE,
              calculationMethod:
                EuerDeclarationSubformCalculationMethod.CAR_USAGE_1_PCT_METHOD,
            }}
          >
            {is1PctMethod(EuerFormState.calculationMethod) ? (
              <>
                <StyledFieldset>
                  <legend>Private Kfz-Nutzung</legend>
                  <InputWithAutoWrapper>
                    <EuerCurrencyField
                      name="catalogPrice"
                      prefix={
                        isFieldEqualToQuestionnaireValue("catalogPrice") ? (
                          <UserOutlined />
                        ) : (
                          <span />
                        )
                      }
                      label="Bruttolistenpreis"
                      onChange={onEuerFormFieldChange}
                    />
                    {handleAutoField("catalogPrice", {
                      formatValue: (value) =>
                        formatAmountToCurrency(currencyFormatter.format(value)),
                      style: { paddingRight: 12 },
                    })}
                  </InputWithAutoWrapper>
                  <InputWithAutoWrapper>
                    <Form.Item
                      name="purchaseDate"
                      label="Beginn der PKW-Nutzung"
                    >
                      <DatePicker
                        format={dateFormat}
                        onChange={(value) =>
                          handlePurchaseDateChange(value as Moment)
                        }
                        {...(isFieldEqualToQuestionnaireValue("purchaseDate")
                          ? { suffixIcon: <UserOutlined /> }
                          : {})}
                      />
                    </Form.Item>
                    {handleAutoField("purchaseDate", {
                      formatValue: (value) => value.format(dateFormat),
                      style: { paddingRight: 30 },
                    })}
                  </InputWithAutoWrapper>
                  <Form.Item name="usageMonths" label="Kalendermonate gesamt">
                    <Input disabled value={form.getFieldValue("usageMonths")} />
                  </Form.Item>
                </StyledFieldset>
                <StyledFieldset>
                  <legend>Kostendeckelung</legend>
                  <EuerCurrencyField
                    name="actualCosts"
                    label="Tatsächliche Kfz-Kosten"
                    value={EuerFormState.actualCosts}
                    autoValue={
                      autoCalculatedValues &&
                      centsToEuros(autoCalculatedValues.onePercent.actualCosts)
                    }
                    addonBefore={autoCalculatedValues && "Auto"}
                    onChange={onEuerFormFieldChange}
                  />
                  <EuerCurrencyField
                    name="interestCosts"
                    label="Zinsen für Kfz"
                    value={EuerFormState.interestCosts}
                    autoValue={
                      autoCalculatedValues &&
                      centsToEuros(
                        autoCalculatedValues.onePercent.interestCosts
                      )
                    }
                    addonBefore={autoCalculatedValues && "Auto"}
                    onChange={onEuerFormFieldChange}
                  />
                  <EuerCurrencyField
                    name="depreciationCosts"
                    label="AfA für Kfz"
                    value={EuerFormState.depreciationCosts}
                    autoValue={
                      autoCalculatedValues &&
                      centsToEuros(
                        autoCalculatedValues.onePercent.depreciationCosts
                      )
                    }
                    addonBefore={autoCalculatedValues && "Auto"}
                    onChange={onEuerFormFieldChange}
                  />
                  <Form.Item
                    label={
                      <label
                        style={{
                          color: isActiveCostCap
                            ? colors.darkLimeGreen
                            : colors.darkGrey,
                        }}
                      >
                        {`Kostendeckelung ${
                          isActiveCostCap ? "aktiv" : "inaktiv"
                        }`}
                      </label>
                    }
                    style={{ textAlign: "right" }}
                  >
                    <Text className="ant-form-text">
                      {formatAmountInCents(
                        calculationResults.costCap
                          ? calculationResults.costCap
                          : 0,
                        true
                      )}
                    </Text>
                  </Form.Item>
                </StyledFieldset>
                <StyledFieldset>
                  <legend>Arbeitsweg</legend>
                  <InputWithAutoWrapper>
                    <EuerNumericField
                      name="commuteDistance"
                      prefix={
                        isFieldEqualToQuestionnaireValue("commuteDistance") ? (
                          <UserOutlined />
                        ) : (
                          <span />
                        )
                      }
                      label="Einfache Entfernung Wohnung/Betrieb"
                      value={EuerFormState.commuteDistance}
                      onChange={onEuerFormFieldChange}
                      integerOnly
                      addonAfter="km"
                      disabled={isCommuteFieldsDisable}
                    />
                    {handleAutoField("commuteDistance", {
                      style: { paddingRight: 54 },
                    })}
                  </InputWithAutoWrapper>
                  <InputWithAutoWrapper>
                    <EuerNumericField
                      name="commuteDays"
                      prefix={
                        isFieldEqualToQuestionnaireValue("commuteDays") ? (
                          <UserOutlined />
                        ) : (
                          <span />
                        )
                      }
                      label="Tage Fahrten Wohnung/Betrieb"
                      value={EuerFormState.commuteDays}
                      maxValue={280}
                      integerOnly
                      addonAfter="Tage"
                      onChange={onEuerFormFieldChange}
                      disabled={isCommuteFieldsDisable}
                    />
                    {handleAutoField("commuteDays", {
                      style: { paddingRight: 65 },
                    })}
                  </InputWithAutoWrapper>
                </StyledFieldset>
                <StyledFieldset>
                  <legend>Umsatzsteuer</legend>
                  <EuerCurrencyField
                    name="collectedVat"
                    label={
                      <LabelWithTag
                        tag="140"
                        label="Vereinnahmte USt 19&nbsp;%"
                      />
                    }
                    value={
                      EuerFormState.collectedVat != null
                        ? EuerFormState.collectedVat
                        : centsToEuros(
                            calculationResults.autoCalculatedCollectedVat
                          )
                    }
                    addonBefore="Auto"
                    autoValue={centsToEuros(
                      calculationResults.autoCalculatedCollectedVat
                    )}
                    onChange={onEuerFormFieldChange}
                  />
                </StyledFieldset>
              </>
            ) : (
              <>
                <StyledFieldset>
                  <legend>Private Kfz-Nutzung</legend>
                  <InputWithAutoWrapper>
                    <EuerNumericField
                      name="totalKm"
                      prefix={
                        isFieldEqualToQuestionnaireValue("totalKm") ? (
                          <UserOutlined />
                        ) : (
                          <span />
                        )
                      }
                      label="Insgesamt gefahrene km"
                      addonAfter="km"
                      integerOnly
                      onChange={onEuerFormFieldChange}
                    />
                    {handleAutoField("totalKm", {
                      style: { paddingRight: 54 },
                    })}
                  </InputWithAutoWrapper>
                  <InputWithAutoWrapper>
                    <EuerNumericField
                      name="privateKm"
                      prefix={
                        isFieldEqualToQuestionnaireValue("privateKm") ? (
                          <UserOutlined />
                        ) : (
                          <span />
                        )
                      }
                      label="davon Privatfahrten"
                      addonAfter="km"
                      integerOnly
                      onChange={onEuerFormFieldChange}
                    />
                    {handleAutoField("privateKm", {
                      style: { paddingRight: 54 },
                    })}
                  </InputWithAutoWrapper>
                  <InputWithAutoWrapper>
                    <EuerNumericField
                      name="homeCompanyKm"
                      prefix={
                        isFieldEqualToQuestionnaireValue("homeCompanyKm") ? (
                          <UserOutlined />
                        ) : (
                          <span />
                        )
                      }
                      label="davon Fahrten Whg/Betrieb"
                      addonAfter="km"
                      integerOnly
                      onChange={onEuerFormFieldChange}
                    />
                    {handleAutoField("homeCompanyKm", {
                      style: { paddingRight: 54 },
                    })}
                  </InputWithAutoWrapper>
                </StyledFieldset>
                <StyledFieldset>
                  <legend>Arbeitsweg</legend>
                  <InputWithAutoWrapper>
                    <EuerNumericField
                      name="commuteDistance"
                      prefix={
                        isFieldEqualToQuestionnaireValue("commuteDistance") ? (
                          <UserOutlined />
                        ) : (
                          <span />
                        )
                      }
                      label="Einfache Entfernung km Whg/Betrieb"
                      addonAfter="km"
                      integerOnly
                      onChange={onEuerFormFieldChange}
                    />
                    {handleAutoField("commuteDistance", {
                      style: { paddingRight: 54 },
                    })}
                  </InputWithAutoWrapper>
                  <InputWithAutoWrapper>
                    <EuerNumericField
                      name="commuteDays"
                      prefix={
                        isFieldEqualToQuestionnaireValue("commuteDays") ? (
                          <UserOutlined />
                        ) : (
                          <span />
                        )
                      }
                      label="Tage Fahrten Whg/Betrieb"
                      addonAfter="Tage"
                      integerOnly
                      onChange={onEuerFormFieldChange}
                    />
                    {handleAutoField("commuteDays", {
                      style: { paddingRight: 65 },
                    })}
                  </InputWithAutoWrapper>
                </StyledFieldset>
                <StyledFieldset>
                  <legend>
                    Gesamtkosten netto mit 19&nbsp;% Vorsteuerabzug
                  </legend>
                  <EuerCurrencyField
                    name="depreciationWithTaxDeduction"
                    label={
                      <LabelWithTag tag="130" label="AfA anteilig monatl." />
                    }
                    value={EuerFormState.depreciationWithTaxDeduction}
                    autoValue={
                      autoCalculatedValues &&
                      centsToEuros(
                        autoCalculatedValues.driversLog[VatRate.VAT_19]
                          .depreciationCosts
                      )
                    }
                    addonBefore={autoCalculatedValues && "Auto"}
                    onChange={onEuerFormFieldChange}
                  />
                  <EuerCurrencyField
                    name="interestWithTaxDeduction"
                    label={<LabelWithTag tag="232" label="Schuldzinsen" />}
                    value={EuerFormState.interestWithTaxDeduction}
                    autoValue={
                      autoCalculatedValues &&
                      centsToEuros(
                        autoCalculatedValues.driversLog[VatRate.VAT_19]
                          .interestCosts
                      )
                    }
                    addonBefore={autoCalculatedValues && "Auto"}
                    onChange={onEuerFormFieldChange}
                  />
                  <EuerCurrencyField
                    name="leasingWithTaxDeduction"
                    label={<LabelWithTag tag="144" label="Leasingkosten" />}
                    value={EuerFormState.leasingWithTaxDeduction}
                    autoValue={
                      autoCalculatedValues &&
                      centsToEuros(
                        autoCalculatedValues.driversLog[VatRate.VAT_19]
                          .leasingCosts
                      )
                    }
                    addonBefore={autoCalculatedValues && "Auto"}
                    onChange={onEuerFormFieldChange}
                  />
                  <EuerCurrencyField
                    name="feesWithTaxDeduction"
                    label={
                      <LabelWithTag tag="145" label="Steuern/Vers./Maut" />
                    }
                    value={EuerFormState.feesWithTaxDeduction}
                    autoValue={
                      autoCalculatedValues &&
                      centsToEuros(
                        autoCalculatedValues.driversLog[VatRate.VAT_19].carFees
                      )
                    }
                    addonBefore={autoCalculatedValues && "Auto"}
                    onChange={onEuerFormFieldChange}
                  />
                  <EuerCurrencyField
                    name="costsWithTaxDeduction"
                    label={
                      <LabelWithTag
                        tag="146"
                        label="Sonstige tatsächliche Fahrtkosten"
                      />
                    }
                    value={EuerFormState.costsWithTaxDeduction}
                    autoValue={
                      autoCalculatedValues &&
                      centsToEuros(
                        autoCalculatedValues.driversLog[VatRate.VAT_19].carCosts
                      )
                    }
                    addonBefore={autoCalculatedValues && "Auto"}
                    onChange={onEuerFormFieldChange}
                  />
                  <Form.Item label="Summe netto" style={{ textAlign: "right" }}>
                    <Text type="secondary" className="ant-form-text">
                      {formatAmount(
                        sumValues([
                          EuerFormState.depreciationWithTaxDeduction,
                          EuerFormState.interestWithTaxDeduction,
                          EuerFormState.leasingWithTaxDeduction,
                          EuerFormState.feesWithTaxDeduction,
                          EuerFormState.costsWithTaxDeduction,
                        ])
                      )}
                    </Text>
                  </Form.Item>
                </StyledFieldset>
                <StyledFieldset>
                  <legend>Gesamtkosten netto ohne Vorsteuerabzug</legend>
                  <EuerCurrencyField
                    name="depreciationWithoutTaxDeduction"
                    label={
                      <LabelWithTag tag="130" label="AfA anteilig monatl." />
                    }
                    value={EuerFormState.depreciationWithoutTaxDeduction}
                    autoValue={
                      autoCalculatedValues &&
                      centsToEuros(
                        autoCalculatedValues.driversLog[VatRate.VAT_0]
                          .depreciationCosts
                      )
                    }
                    addonBefore={autoCalculatedValues && "Auto"}
                    onChange={onEuerFormFieldChange}
                  />
                  <EuerCurrencyField
                    name="interestWithoutTaxDeduction"
                    label={<LabelWithTag tag="232" label="Schuldzinsen" />}
                    value={EuerFormState.interestWithoutTaxDeduction}
                    autoValue={
                      autoCalculatedValues &&
                      centsToEuros(
                        autoCalculatedValues.driversLog[VatRate.VAT_0]
                          .interestCosts
                      )
                    }
                    addonBefore={autoCalculatedValues && "Auto"}
                    onChange={onEuerFormFieldChange}
                  />
                  <EuerCurrencyField
                    name="leasingWithoutTaxDeduction"
                    label={<LabelWithTag tag="144" label="Leasingkosten" />}
                    value={EuerFormState.leasingWithoutTaxDeduction}
                    autoValue={
                      autoCalculatedValues &&
                      centsToEuros(
                        autoCalculatedValues.driversLog[VatRate.VAT_0]
                          .leasingCosts
                      )
                    }
                    addonBefore={autoCalculatedValues && "Auto"}
                    onChange={onEuerFormFieldChange}
                  />
                  <EuerCurrencyField
                    name="feesWithoutTaxDeduction"
                    label={
                      <LabelWithTag tag="145" label="Steuern/Vers./Maut" />
                    }
                    value={EuerFormState.feesWithoutTaxDeduction}
                    autoValue={
                      autoCalculatedValues &&
                      centsToEuros(
                        autoCalculatedValues.driversLog[VatRate.VAT_0].carFees
                      )
                    }
                    addonBefore={autoCalculatedValues && "Auto"}
                    onChange={onEuerFormFieldChange}
                  />
                  <EuerCurrencyField
                    name="costsWithoutTaxDeduction"
                    label={
                      <LabelWithTag
                        tag="146"
                        label="Sonstige tatsächliche Fahrtkosten"
                      />
                    }
                    value={EuerFormState.costsWithoutTaxDeduction}
                    autoValue={
                      autoCalculatedValues &&
                      centsToEuros(
                        autoCalculatedValues.driversLog[VatRate.VAT_0].carCosts
                      )
                    }
                    addonBefore={autoCalculatedValues && "Auto"}
                    onChange={onEuerFormFieldChange}
                  />
                  <Form.Item label="Summe netto" style={{ textAlign: "right" }}>
                    <Text type="secondary" className="ant-form-text">
                      {formatAmount(
                        sumValues([
                          EuerFormState.depreciationWithoutTaxDeduction,
                          EuerFormState.interestWithoutTaxDeduction,
                          EuerFormState.leasingWithoutTaxDeduction,
                          EuerFormState.feesWithoutTaxDeduction,
                          EuerFormState.costsWithoutTaxDeduction,
                        ])
                      )}
                    </Text>
                  </Form.Item>
                </StyledFieldset>
              </>
            )}

            <fieldset>
              <legend>Ergebnisse für EÜR</legend>
              <Form.Item
                label={<LabelWithTag tag="106" label="Private Kfz-Nutzung" />}
                style={{ textAlign: "right" }}
              >
                <Text className="ant-form-text">
                  {formatAmountInCents(
                    calculationResults.privateCarUsage || 0,
                    true
                  )}
                </Text>
              </Form.Item>
              <Form.Item
                label={<LabelWithTag tag="140" label="Vereinnahmte USt" />}
                style={{ textAlign: "right" }}
              >
                <Text className="ant-form-text">
                  {formatAmountInCents(
                    calculationResults.collectedVat || 0,
                    true
                  )}
                </Text>
              </Form.Item>
              <Form.Item
                label={
                  <LabelWithTag
                    tag="142"
                    label="Fahrten zwischen Wohnung/Betrieb"
                  />
                }
                style={{ textAlign: "right" }}
              >
                <Text className="ant-form-text">
                  {formatAmountInCents(
                    calculationResults.homeCompanyTrips || 0,
                    true
                  )}
                </Text>
              </Form.Item>
              <Form.Item
                label={
                  <LabelWithTag tag="176" label="Min. abziehbare Fahrtkosten" />
                }
                style={{ textAlign: "right" }}
              >
                <Text
                  className="ant-form-text"
                  style={{ color: colors.darkRed }}
                >
                  −
                  {formatAmountInCents(
                    calculationResults.minDeductibleTravelExpenses || 0,
                    true
                  )}
                </Text>
              </Form.Item>
              <Divider />
              <Form.Item
                label={profitIncreased ? "Gewinnerhöhung" : "Gewinnminderung"}
                style={{ textAlign: "right" }}
              >
                <Text
                  className="ant-form-text"
                  style={{
                    color: profitIncreased
                      ? colors.darkLimeGreen
                      : calculationResults.profitIncrease < 0
                      ? colors.darkRed
                      : colors.darkGrey,
                  }}
                >
                  {formatAmountInCents(
                    calculationResults.profitIncrease || 0,
                    true
                  )}
                </Text>
              </Form.Item>
            </fieldset>
          </StyledForm>
        </Col>
      </Row>

      {subform && isStatusChangeModalVisible && (
        <CarUsageStatusChangeModal
          status={subform.status}
          inputs={EuerFormState}
          calculationResults={calculationResults}
          calculationMethod={calculationMethod}
          onClose={handleStatusChangeModalClose}
          onSave={saveForm}
        />
      )}
    </>
  );
};

export default CarUsage;
