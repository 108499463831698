import React, { useEffect, useState } from "react";
import { StringParam, useQueryParam } from "use-query-params";
import { notification } from "antd";
import styled from "styled-components";

import useEmailParam from "../../hooks/useEmailParam";
import { EmailDocument } from "../../../api/graphql";
import EmailDocumentPreview from "./EmailDocumentPreview";
import Header from "./Header";
import { useEmailDocumentQuery } from "../../../api/graphql/queries/emailDocuments";
import TransactionListContainer from "./TransactionList/TransactionListContainer";
import EmptyWrapper from "../../common/EmptyWrapper";
import NoMatchSection from "./NoMatchSection";
import { ContentWrapper } from "../../common/styledComponents";
import { KontaxUserContext } from "../../contexts/KontaxUserContext";

const PreviewAndListWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
`;

const RightSection = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 24px;
`;

const BelegeView = () => {
  const [email, setEmailParam] = useEmailParam() as [string, Function];
  const [id, setId] = useQueryParam("id", StringParam) as [string, Function];
  const [emailDocument, setEmailDocument] = useState<EmailDocument | null>(
    null
  );
  const [getEmailDocument] = useEmailDocumentQuery({
    onCompleted: (data: { emailDocument: EmailDocument }) => {
      setEmailDocument(data.emailDocument);
      setId(data.emailDocument?.id || null);
    },
    onError: (err: Error) => {
      notification.error({ message: `An error occurred: ${err.message}` });
    },
  });

  const fetchNext = () => {
    getEmailDocument({
      variables: { email },
    });
  };

  useEffect(() => {
    if (!email) {
      return;
    }

    getEmailDocument({
      variables: {
        id,
        email,
      },
    });
  }, [id, email, getEmailDocument]);

  return (
    <ContentWrapper>
      <KontaxUserContext email={email}>
        <Header setEmail={setEmailParam} email={email} />
        {!email && <EmptyWrapper description="User email is required" />}
        {email && !emailDocument && (
          <EmptyWrapper
            description="No more documents! 👏"
            buttonProperties={{
              text: "Refresh",
              onClick: fetchNext,
            }}
          />
        )}
        {email && emailDocument && (
          <PreviewAndListWrapper>
            <EmailDocumentPreview
              emailDocument={emailDocument}
              fetchNext={fetchNext}
            />
            <RightSection>
              <TransactionListContainer
                transactions={emailDocument.transactionMatches}
                email={email}
                currentEmailDocument={emailDocument}
                onMatchSuccess={fetchNext}
              />
              <NoMatchSection
                email={email}
                emailDocument={emailDocument}
                onCreateExternalTransaction={fetchNext}
              />
            </RightSection>
          </PreviewAndListWrapper>
        )}
      </KontaxUserContext>
    </ContentWrapper>
  );
};
export default BelegeView;
