import * as Sentry from "@sentry/react";

import config from "./config";
import packageInfo from "../package.json";

if (config.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: config.REACT_APP_SENTRY_DSN,
    environment: config.REACT_APP_ENV,
    release: packageInfo.version,
  });
}

export const reportError = config.REACT_APP_SENTRY_DSN
  ? Sentry.captureException
  : () => null;
