import React, { useEffect, useState } from "react";
import { message, Table, Tag, Tooltip } from "antd";
import { capitalize } from "lodash";
import { ColumnType, TablePaginationConfig } from "antd/lib/table";
import denormalizeTaxNumber from "denormalize-steuernummer";

import { SorterResult, TableCurrentDataSource } from "antd/lib/table/interface";

import { FilterValue } from "antd/es/table/interface";

import {
  formatBookkeepingServices,
  formatOnbStatus,
  formatTimestamp,
  getActiveSubscription,
} from "../../Common/UserDetails/utils";
import {
  IUser,
  IUserDetails,
  KontaxUserStatus,
  KontaxUserStatusAdditionalParams,
  TableParams,
  UserRow,
  OnbStatus,
} from "../../../../../types";
import UserDetails from "../UserDetails";
import { UpsertAccountingSourcePayload } from "../../../../../types/AccountingSource.type";
import Copyable from "../../../../common/Copyable";
import { getColorByOnboardingStatus } from "../../../../../utils";
import { getUserFullName } from "../../../../../utils/getUserFullName";

const copyToClipboard = (email: string, successMessage: string) => {
  navigator.clipboard.writeText(email);
  message.success(successMessage);
};

const onbStatusFilters: { text: string; value: string }[] = Object.keys(
  OnbStatus
)
  .map((key) => ({ text: capitalize(key), value: key }))
  .sort((a, b) => a.text.localeCompare(b.text));

const setDefaultExpandedRowKeys = (dataSource: UserRow[]) =>
  dataSource.length === 1 ? [0] : [];

const UsersTable = ({
  users,
  updateUserStatus,
  updateDependents,
  deleteDependent,
  upsertAccountingSource,
  removeAccountingSource,
  editUser,
  setEmailParam,
  tableParams,
  onChange,
}: {
  users: Array<IUser>;
  updateUserStatus: (
    email: string,
    status: KontaxUserStatus | null,
    additionalParams?: KontaxUserStatusAdditionalParams
  ) => Promise<void>;
  updateDependents: Function;
  upsertAccountingSource: (
    email: string,
    payload: UpsertAccountingSourcePayload
  ) => Promise<void>;
  removeAccountingSource: (email: string, year: number) => Promise<void>;
  deleteDependent: (email: string, id: string) => Promise<void>;
  editUser: (email: string, userDetails: IUserDetails) => Promise<void>;
  setEmailParam: (email: string) => void;
  tableParams: TableParams;
  onChange: (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<UserRow> | SorterResult<UserRow>[],
    extra: TableCurrentDataSource<UserRow>
  ) => void;
}) => {
  const bookkeepingServiceFilters = new Set<string>();
  const activePlanFilters = new Set<string>();

  let dataSource: UserRow[] = users.map((user: IUser, index) => {
    const bookkeepingService = formatBookkeepingServices(user);
    const activePlanType = getActiveSubscription(user.subscriptions);

    bookkeepingServiceFilters.add(bookkeepingService ?? "");
    activePlanFilters.add(activePlanType);

    return {
      key: index,
      name: getUserFullName(user),
      fibuStartDate: formatTimestamp(user.fibuStartDate),
      user,
    };
  });

  const columns: ColumnType<UserRow>[] = [
    {
      title: "Account ID",
      dataIndex: ["user", "accountId"],
      width: 100,
      showSorterTooltip: false,
    },
    {
      title: "Name",
      dataIndex: "name",
      width: 120,
      showSorterTooltip: false,
    },
    {
      title: "Email",
      dataIndex: ["user", "email"],
      render: (value) => (
        <Tooltip title={value} placement="topLeft">
          <Copyable ellipsis>{value}</Copyable>
        </Tooltip>
      ),
      onCell: (record: UserRow) => {
        return {
          onClick: (event: any) => {
            event.stopPropagation();
            if (record.user.email)
              copyToClipboard(record.user.email, "Email copied");
          },
        };
      },
      width: 90,
      ellipsis: {
        showTitle: false,
      },
      showSorterTooltip: false,
      sorter: true,
    },
    {
      title: "Hauptsteuernr.",
      dataIndex: ["user", "taxNumber"],
      width: 110,
      showSorterTooltip: false,
      render: (value) => value && denormalizeTaxNumber(value)?.taxNumber,
    },
    {
      title: "Steuerliche Identifikationsnr.",
      dataIndex: ["user", "deTaxId"],
      width: 120,
      showSorterTooltip: false,
      sorter: true,
    },
    {
      title: "Fibu start date",
      dataIndex: "fibuStartDate",
      width: 100,
      showSorterTooltip: false,
      sorter: true,
    },
    {
      title: "First tax declaration year",
      dataIndex: ["user", "firstTaxDeclarationYear"],
      width: 100,
      showSorterTooltip: false,
      sorter: true,
    },
    {
      title: "ONB Status",
      width: 140,
      dataIndex: ["user", "kontaxUser", "onbStatus"],
      render: (value: OnbStatus) => (
        <Tag
          color={getColorByOnboardingStatus(value)}
          style={{ marginRight: 0 }}
        >
          {formatOnbStatus(value).toUpperCase()}
        </Tag>
      ),
      showSorterTooltip: false,
      filters: onbStatusFilters,
    },
  ];

  const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>(
    setDefaultExpandedRowKeys(dataSource)
  );

  useEffect(() => {
    if (expandedRowKeys.length) {
      document
        .querySelector(`tr[data-row-key="${expandedRowKeys[0]}"]`)
        ?.scrollIntoView({
          behavior: "smooth",
        });
    }
  }, [expandedRowKeys]);

  useEffect(() => {
    if (users.length === 1) setEmailParam(users[0].email);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users]);

  const onTableRowExpand = (expanded: boolean, record: UserRow) => {
    setExpandedRowKeys(expanded ? [record.key] : []);
    setEmailParam(record.user.email);
  };

  if (!users.length) {
    return null;
  }

  return (
    <Table
      dataSource={dataSource}
      pagination={tableParams.pagination}
      onChange={onChange}
      columns={columns}
      bordered
      expandRowByClick
      scroll={{ x: "100%" }}
      rowClassName="expendableRow"
      expandable={{
        expandedRowRender: (record: UserRow) => (
          <UserDetails
            user={record.user}
            updateUserStatus={updateUserStatus}
            upsertAccountingSource={upsertAccountingSource}
            removeAccountingSource={removeAccountingSource}
            editUser={editUser}
            updateDependents={updateDependents}
            deleteDependent={deleteDependent}
          />
        ),
        defaultExpandedRowKeys: setDefaultExpandedRowKeys(dataSource),
        expandedRowKeys: expandedRowKeys,
        onExpand: onTableRowExpand,
      }}
    />
  );
};

export default UsersTable;
