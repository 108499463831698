import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import classNames from "classnames";

import { DatalistInput } from "../common/styledComponents";

const BusinessAssetClassSelect = ({
  id,
  value,
  options,
  required = false,
  invalid = false,
  onChange,
  className = "",
}: {
  id: string;
  value: string;
  options: Array<{ assetClass: string; depreciationYears?: number | null }>;
  required?: boolean;
  invalid?: boolean;
  onChange: (value: string) => void;
  className?: string;
}) => {
  const [inputValue, setInputValue] = useState(value);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;

      setInputValue(value);

      const normalizedValue = value.trim();
      const selectedOption = options.find(
        ({ assetClass }) => assetClass === normalizedValue
      );
      onChange(selectedOption?.assetClass || "");
    },
    [onChange, options]
  );

  return (
    <div>
      <DatalistInput
        className={classNames(className, { invalid })}
        autoComplete="off"
        list={`list_${id}`}
        id={id}
        name={id}
        value={inputValue}
        required={required}
        onChange={handleChange}
      />
      <datalist id={`list_${id}`}>
        {options.map(({ assetClass }) => (
          <option key={assetClass} value={assetClass} />
        ))}
      </datalist>
    </div>
  );
};

export default BusinessAssetClassSelect;
