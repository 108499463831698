import { ApolloError } from "@apollo/client";
import { notification } from "antd";

import { reportError } from "../sentry";
import colors from "../themes/colors";

const notificationStyle: React.CSSProperties = {
  color: colors.purple,
  overflowY: "auto",
  whiteSpace: "pre-wrap",
  wordWrap: "break-word",
  maxHeight: "450px",
};

export const showErrorNotification = ({
  message,
  description,
}: {
  message: string;
  description?: string;
}) => {
  notification.error({
    message: message,
    description: description,
    duration: 0,
    style: notificationStyle,
  });
};

export const showInfoNotification = ({
  message,
  description,
}: {
  message: string;
  description?: string;
}) => {
  notification.info({
    message: message,
    description: description,
    duration: 0,
    style: notificationStyle,
  });
};

export const showGraphQlErrorNotification = (
  messagePrefix: string,
  error: unknown
) => {
  let message;
  let description;

  if (
    error instanceof ApolloError &&
    error.networkError &&
    "result" in error.networkError
  ) {
    const graphQlError = error.networkError.result.errors[0];
    message = graphQlError?.message;
    description = graphQlError?.extensions?.payload;
  }

  if (error) {
    reportError(error);
  }

  showErrorNotification({
    message: `${messagePrefix} ${message}`,
    description: description,
  });
};
