import moment from "moment";

import { IVatDeclarationUser } from "../../../../../types";

export const calculateDate = (
  nextDeclarationDuePeriod?: string,
  nextDeclarationDueYear?: number
): Date | null => {
  if (!nextDeclarationDuePeriod || !nextDeclarationDueYear) {
    return null;
  }

  const periodStr = nextDeclarationDuePeriod.startsWith("Q") ? "Q" : "MM";
  const periodValue = nextDeclarationDuePeriod.replace(/Q/g, "");
  const nextDeclarationDue = `${periodValue}-${nextDeclarationDueYear}`;

  return moment(nextDeclarationDue, `${periodStr}-YYYY`, true).toDate();
};

export const nextDeclarationDueSort = (
  first: IVatDeclarationUser,
  second: IVatDeclarationUser
): number => {
  const dateA = calculateDate(
    first.nextDeclarationDuePeriod,
    first.nextDeclarationDueYear
  )?.getTime();

  const dateB = calculateDate(
    second.nextDeclarationDuePeriod,
    second.nextDeclarationDueYear
  )?.getTime();

  if (dateA === null || dateA === undefined) return 0;
  if (dateB === null || dateB === undefined) return -1;
  if (dateA === dateB) return 0;
  return dateA > dateB ? 1 : -1;
};
