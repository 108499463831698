import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import TaxPreparationOverview from "./pages/TaxPreparationOverview";
import StartOfTheYearQuestionnaire from "./pages/StartOfTheYearQuestionnaire";
import EndOfTheYearQuestionnaire from "./pages/EndOfTheYearQuestionnaire";
import { QuestionnaireType } from "../../../../../api/graphql/schema.generated";

const TaxPreparation = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}`} component={TaxPreparationOverview} exact />
      <Route
        path={`${path}/${QuestionnaireType.START_OF_THE_YEAR}`}
        component={StartOfTheYearQuestionnaire}
      />
      <Route
        path={`${path}/:type/:questionnaireId?`}
        component={EndOfTheYearQuestionnaire}
      />
    </Switch>
  );
};

export default TaxPreparation;
