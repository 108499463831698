import React, { useState, useMemo, useEffect } from "react";
import { Button, Drawer, Tooltip } from "antd";

import {
  ActionBlock,
  DateBlock,
  TitleWithLogs,
  OneLine,
} from "../ActionLogDrawer/styledComponents";
import { LoaderWrapper } from "./styledComponents";
import {
  constructChangeLogTexts,
  filterSelectedFields,
  formatDateTime,
} from "../ActionLogDrawer/helpers";
import { useChangeLogsQuery } from "../../../api/graphql/queries/changeLogs";
import { SpinnerBasic } from "../Spinner";
import { HoverableHistoryIcon } from "../styledComponents";

const TEST_ID = "view-logs-button";
const ACTION_TEXT = "View logs";

const ActionLogDrawerAsync = ({
  title,
  modelName,
  recordIds,
  subTexts,
  small,
  withIcon,
  renderButton,
  selectedFields,
}: {
  title: string;
  modelName: string;
  recordIds: string[];
  subTexts?: Record<string, string>;
  small?: boolean;
  withIcon?: boolean;
  renderButton?: (options: { onClick: () => void }) => React.ReactElement;
  selectedFields?: string[];
}) => {
  const [shouldShowLog, setShouldShowLog] = useState(false);
  const [getChangeLogs, { data: queryData, loading }] = useChangeLogsQuery({
    variables: {
      modelName,
      recordIds,
    },
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (shouldShowLog) {
      getChangeLogs();
    }
  }, [shouldShowLog, getChangeLogs]);

  const changeLogList = useMemo(() => {
    const filteredChangeLogs = filterSelectedFields({
      changeLogs: queryData?.changeLogs,
      selectedFields,
    });

    return filteredChangeLogs.map(
      ({ changes, changedAt, changedBy, recordId, modelName }) => (
        <ActionBlock key={`${changedAt}`}>
          <DateBlock>{formatDateTime(changedAt)}</DateBlock>
          {changedBy && <div>{changedBy}</div>}
          {recordId && subTexts?.[recordId] && (
            <div>{subTexts?.[recordId]}</div>
          )}
          {constructChangeLogTexts(changes, modelName).map((text, idx) => (
            <OneLine key={`${changedAt}-${idx}`}>
              <Tooltip placement="topLeft" title={text}>
                {text}
              </Tooltip>
            </OneLine>
          ))}
        </ActionBlock>
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryData?.changeLogs?.length]);

  return (
    <>
      {renderButton ? (
        renderButton({ onClick: () => setShouldShowLog(true) })
      ) : withIcon ? (
        <HoverableHistoryIcon
          onClick={() => setShouldShowLog(true)}
          data-testid={TEST_ID}
          title={ACTION_TEXT}
        />
      ) : (
        <Button
          type="link"
          onClick={() => setShouldShowLog(true)}
          data-testid={TEST_ID}
          size={small ? "small" : "middle"}
        >
          {ACTION_TEXT}
        </Button>
      )}
      <Drawer
        title={title}
        data-test="logsDrawer"
        placement="right"
        onClose={() => setShouldShowLog(false)}
        visible={shouldShowLog}
      >
        {loading ? (
          <LoaderWrapper>
            <SpinnerBasic />
          </LoaderWrapper>
        ) : (
          changeLogList
        )}
      </Drawer>
    </>
  );
};

export default ActionLogDrawerAsync;
export { TitleWithLogs };
