import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Form, notification, Popconfirm, Table, Tag, Typography } from "antd";
import { SorterResult, ColumnType } from "antd/lib/table/interface";
import moment, { Moment } from "moment-timezone";
import { cloneDeep } from "lodash";

import { useGetTaxBoardUsersQuery } from "../../../../../api/graphql/queries/taxBoard/taxBoardUsers.generated";
import {
  TaxBoardUser,
  TaxBoardUsersFilterArgs,
} from "../../../../../api/graphql/schema.generated";

import { FilterType } from "./types";
import EmptyWrapper from "../../../../common/EmptyWrapper";
import UserFilters, { UserFilterOptions } from "../../components/UserFilters";
import useTaxYearParam from "../../hooks/useTaxYearParam";
import useEmailParam from "../../../../hooks/useEmailParam";

import { formatDate } from "../../utils";
import { EUER_DECLARATION_STATUS_MAPPINGS } from "../EuerDeclaration/constants";
import { INCOME_TAX_DECLARATION_STATUS_MAPPINGS } from "../IncomeTaxDeclaration/constants";
import { TRADE_TAX_DECLARATION_STATUS_MAPPINGS } from "../TradeTaxDeclaration/constants";
import { VAT_ANNUAL_DECLARATION_STATUS_MAPPINGS } from "../VatAnnualDeclaration/constants";

import {
  getFullName,
  generateFilterOptionFromStatus,
  getTaxUserSearchFilterType,
  OrderDirectionMapping,
  isDeclarationsExists,
} from "./utils";
import {
  FIBU_FINAL_CHECK_STATUS_MAPPINGS,
  TAX_CASE_STATUS_MAPPINGS,
} from "./constants";
import AssigneeInput from "./AssigneeInput";
import TaxCaseDetails from "./TaxCaseDetails";
import EditableCell from "./EditableCell";
import { useUpdateTaxCaseMutation } from "../../../../../api/graphql/mutations/taxCase/updateTaxCase.generated";
import { getCurrentUserEmail } from "../../../../../gapi";
import { TableContainer } from "./style";

const FIRST_SUPPORTED_TAX_YEAR = 2021;
const EMPTY_INDICATOR = "-";

interface EditableColumnsType<T> extends ColumnType<T> {
  editable?: boolean;
}

const getColumns = ({
  assignee,
  isAssigneeFiltered,
  setAssignee,
}: {
  assignee: string;
  isAssigneeFiltered: boolean;
  setAssignee: (assignee: string) => void;
}): EditableColumnsType<TaxBoardUser>[] => [
  {
    title: "Account ID",
    key: "id",
    render: (record: TaxBoardUser) => record.accounts[0].id || "-",
    sorter: true,
    defaultSortOrder: "ascend",
  },
  {
    title: "Email",
    key: "email",
    render: (record: TaxBoardUser) => record.email || "-",
    sorter: true,
  },
  {
    title: "Name",
    key: "fullName",
    render: (record: TaxBoardUser) => getFullName(record),
    sorter: true,
  },
  {
    title: "Steuernummer",
    key: "taxNumber",
    render: (record: TaxBoardUser) => record.taxNumbers[0]?.taxNumber || "-",
    sorter: true,
  },
  {
    title: "Abgeschlossen Kunde",
    key: "userFinalizedAt",
    dataIndex: ["kontaxUser", "taxCases", "0", "userFinalizedAt"],
    render: (userFinalizedAt: Moment) => {
      return formatDate(userFinalizedAt?.toDate()) || EMPTY_INDICATOR;
    },
    sorter: true,
  },
  {
    title: "Fibu",
    key: "fibuFinalCheckStatus",
    render: (record: TaxBoardUser) =>
      record.kontaxUser?.fibuFinalChecks[0]
        ? FIBU_FINAL_CHECK_STATUS_MAPPINGS[
            record.kontaxUser.fibuFinalChecks[0].status
          ]
        : EMPTY_INDICATOR,
    sorter: true,
    filterSearch: true,
    filters: generateFilterOptionFromStatus(FIBU_FINAL_CHECK_STATUS_MAPPINGS),
  },
  {
    title: "Vorbereitung Kunde",
    key: "taxCaseStatus",
    render: (record: TaxBoardUser) => {
      if (record.kontaxUser?.taxCases[0]) {
        return TAX_CASE_STATUS_MAPPINGS[record.kontaxUser?.taxCases[0]?.status];
      }
      return EMPTY_INDICATOR;
    },
    sorter: true,
    filterSearch: true,
    filters: generateFilterOptionFromStatus(TAX_CASE_STATUS_MAPPINGS),
  },
  {
    title: "Abgabefrist Kunde",
    key: "deadline",
    dataIndex: ["kontaxUser", "taxCases", "0", "deadline"],
    editable: true,
    render: (deadline: Moment) => {
      return formatDate(deadline?.toDate()) || EMPTY_INDICATOR;
    },
    sorter: true,
  },
  {
    title: "Abgabefrist FA",
    key: "taxOfficeDeadline",
    dataIndex: ["kontaxUser", "taxCases", "0", "taxOfficeDeadline"],
    editable: true,
    render: (taxOfficeDeadline: Moment) => {
      return formatDate(taxOfficeDeadline?.toDate()) || EMPTY_INDICATOR;
    },
    sorter: true,
  },
  {
    title: "EÜR",
    key: "euerDeclarationStatus",
    render: (record: TaxBoardUser) => {
      if (record.euerDeclarations[0]) {
        return EUER_DECLARATION_STATUS_MAPPINGS[
          record.euerDeclarations[0].status
        ].label;
      }
      return EMPTY_INDICATOR;
    },
    sorter: true,
    filterSearch: true,
    filters: generateFilterOptionFromStatus(EUER_DECLARATION_STATUS_MAPPINGS),
  },
  {
    title: "UStE",
    key: "vatAnnualDeclarationStatus",
    render: (record: TaxBoardUser) => {
      if (record.vatAnnualDeclarations[0]) {
        return VAT_ANNUAL_DECLARATION_STATUS_MAPPINGS[
          record.vatAnnualDeclarations[0].status
        ].label;
      }
      return EMPTY_INDICATOR;
    },
    sorter: true,
    filterSearch: true,
    filters: generateFilterOptionFromStatus(
      VAT_ANNUAL_DECLARATION_STATUS_MAPPINGS
    ),
  },
  {
    title: "GewStE",
    key: "tradeTaxDeclarationStatus",
    render: (record: TaxBoardUser) => {
      if (record.tradeTaxDeclarations[0]) {
        return TRADE_TAX_DECLARATION_STATUS_MAPPINGS[
          record.tradeTaxDeclarations[0].status
        ].label;
      }
      return EMPTY_INDICATOR;
    },
    sorter: true,
    filterSearch: true,
    filters: generateFilterOptionFromStatus(
      TRADE_TAX_DECLARATION_STATUS_MAPPINGS
    ),
  },
  {
    title: "EStE",
    key: "incomeTaxDeclarationStatus",
    render: (record: TaxBoardUser) => {
      if (record.incomeTaxDeclarations[0]) {
        return INCOME_TAX_DECLARATION_STATUS_MAPPINGS[
          record.incomeTaxDeclarations[0].status
        ].label;
      }
      return EMPTY_INDICATOR;
    },
    sorter: true,
    filterSearch: true,
    filters: generateFilterOptionFromStatus(
      INCOME_TAX_DECLARATION_STATUS_MAPPINGS
    ),
  },
  {
    title: "Bearbeiter",
    key: "assignee",
    filtered: isAssigneeFiltered,
    render: (record: TaxBoardUser) =>
      record.kontaxUser?.taxCases[0]?.assignee?.split("@")[0],
    sorter: true,
    filterSearch: true,
    filterDropdown: () => {
      return <AssigneeInput assignee={assignee} setAssignee={setAssignee} />;
    },
  },
];

const TaxHome = () => {
  const [year] = useTaxYearParam();
  const [emailParam, setEmailParam] = useEmailParam();

  const [email, setEmailToQueryParams] = useEmailParam();
  const [updateTaxCase] = useUpdateTaxCaseMutation();
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>(
    email ? [email] : []
  );

  const isSupportedTaxYear = year >= FIRST_SUPPORTED_TAX_YEAR;

  // states for query params
  const [queryOrderParams, setQueryOrderParams] = useState<{
    order: string;
    orderDirection: string;
  }>({ order: "", orderDirection: "" });
  const [queryFilterParams, setQueryFilterParams] =
    useState<TaxBoardUsersFilterArgs>({
      ...(emailParam && { email: emailParam }),
    });
  const [assignee, setAssignee] = useState<string>("");

  // states for pagination
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState("");

  const handleTableOnChange = (
    filters: TaxBoardUsersFilterArgs,
    sorter: SorterResult<TaxBoardUser>
  ) => {
    if (sorter.order) {
      const order = sorter.columnKey as string;
      const orderDirection = OrderDirectionMapping[sorter.order];
      if (
        queryOrderParams.order !== order ||
        queryOrderParams.orderDirection !== orderDirection
      ) {
        setQueryOrderParams({
          order: sorter.columnKey as string,
          orderDirection: OrderDirectionMapping[sorter.order],
        });
      }
    }
    if (filters) {
      setQueryFilterParams({
        ...filters,
        email: queryFilterParams.email,
        name: queryFilterParams.name,
        idOrTaxNumber: queryFilterParams.idOrTaxNumber,
      });
    }
  };

  useEffect(() => {
    if (assignee) {
      queryFilterParams.assignee = assignee;
      setQueryFilterParams(queryFilterParams);
    }
  }, [assignee, queryFilterParams]);

  const {
    data: taxBoardUserDataAndCount,
    loading: isTaxBoardUserQueryLoading,
    refetch: refetchTaxBoardUsers,
  } = useGetTaxBoardUsersQuery({
    skip: !isSupportedTaxYear,
    variables: {
      year,
      limit: pageSize,
      offset: (page - 1) * pageSize,
      orderBy: queryOrderParams?.order,
      orderDirection: queryOrderParams?.orderDirection,
      filter: queryFilterParams,
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
  });

  const handleSearchBar = useCallback(
    (filterOptions: Partial<UserFilterOptions>) => {
      const { search, all, myCases } = filterOptions;
      if (all) {
        setAssignee("");
        setQueryFilterParams({
          ...queryFilterParams,
          email: "",
          name: "",
          idOrTaxNumber: "",
          assignee: "",
        });
        return;
      }
      if (search) {
        const filterType = getTaxUserSearchFilterType(search);
        switch (filterType) {
          case FilterType.EMAIL:
            setEmailParam(search);
            setAssignee("");
            setQueryFilterParams({
              ...queryFilterParams,
              email: search,
              assignee: "",
              name: "",
              idOrTaxNumber: "",
            });
            break;
          case FilterType.USER_NAME:
            setQueryFilterParams({
              ...queryFilterParams,
              name: search,
              email: "",
              idOrTaxNumber: "",
            });
            break;
          case FilterType.ACCOUNT_ID_OR_TAX_NUMBER:
            setQueryFilterParams({
              ...queryFilterParams,
              idOrTaxNumber: search,
              email: "",
              name: "",
            });
            break;
        }
        return;
      }
      if (myCases) {
        const [assigneeName] = getCurrentUserEmail().split("@");

        setAssignee(assigneeName);
        setQueryFilterParams({
          ...queryFilterParams,
          assignee: assigneeName,
        });
        return;
      }

      setEmailParam(undefined);
      setQueryFilterParams({
        ...queryFilterParams,
        email: "",
        name: "",
        idOrTaxNumber: "",
      });
    },
    [queryFilterParams, setEmailParam]
  );

  const onTableRowExpand = (expanded: boolean, taxBoardUser: TaxBoardUser) => {
    setEmailToQueryParams(taxBoardUser.email);
    setExpandedRowKeys(
      expanded ? [taxBoardUser.email ? taxBoardUser.email : ""] : []
    );
  };

  const isEditing = useCallback(
    (record: TaxBoardUser) => {
      return record.email === editingKey;
    },
    [editingKey]
  );

  const edit = useCallback(
    async (record: Partial<TaxBoardUser>) => {
      form.setFieldsValue({ ...record });
      setEditingKey(record.email!);
    },
    [form]
  );

  const cancel = () => {
    setEditingKey("");
  };

  /**
   * add this memo to transform the deadline and taxOfficeDeadline for each kontax user taxCase
   * from @param {string} to @param {Moment} because the date picker didn't accept string as a parameter
   */

  const taxBoardUsers = useMemo(() => {
    return taxBoardUserDataAndCount?.taxBoardUsers.data.map((taxBoardUser) => {
      let taxBoardUserCopy = cloneDeep(taxBoardUser) as any;
      if (
        taxBoardUserCopy.kontaxUser &&
        taxBoardUserCopy.kontaxUser.taxCases.length > 0
      ) {
        taxBoardUserCopy.kontaxUser.taxCases[0].deadline = moment(
          taxBoardUserCopy.kontaxUser.taxCases[0].deadline
        );
        taxBoardUserCopy.kontaxUser.taxCases[0].userFinalizedAt = moment(
          taxBoardUserCopy.kontaxUser.taxCases[0].userFinalizedAt
        );

        taxBoardUserCopy.kontaxUser.taxCases[0].taxOfficeDeadline = moment(
          taxBoardUserCopy.kontaxUser.taxCases[0].taxOfficeDeadline
        );
      }
      return taxBoardUserCopy;
    });
  }, [taxBoardUserDataAndCount]);

  const save = useCallback(
    async (key) => {
      try {
        const formData = (await form.validateFields()) as TaxBoardUser;
        const selectedUser = taxBoardUsers?.find(
          (taxBoardUser) => taxBoardUser.email === key
        );
        await updateTaxCase({
          variables: {
            payload: {
              id: selectedUser.kontaxUser?.taxCases[0].id,
              deadline: formData.kontaxUser?.taxCases[0].deadline!,
              taxOfficeDeadline:
                formData.kontaxUser?.taxCases[0].taxOfficeDeadline!,
            },
          },
        });
        setEditingKey("");
        refetchTaxBoardUsers();
      } catch (error) {
        notification.error({
          message: `Error occurred while assign or unassign client`,
        });
      }
    },
    [form, refetchTaxBoardUsers, updateTaxCase, taxBoardUsers]
  );

  const preventRowExpansion = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      e.preventDefault();
      e.stopPropagation();
    },
    []
  );

  const columns = useMemo(() => {
    const dataColumns = getColumns({
      assignee,
      isAssigneeFiltered: assignee != null && assignee !== "",
      setAssignee,
    });

    return [
      ...dataColumns,
      {
        title: "Operation",
        dataIndex: "operation",
        render: (_: any, record: TaxBoardUser) => {
          const editable = isEditing(record);
          return editable ? (
            <span
              onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
                preventRowExpansion(e);
              }}
            >
              <Typography.Link
                onClick={() => save(record.email!)}
                style={{ marginRight: 8 }}
              >
                Save
              </Typography.Link>
              <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                <a href="/#">Cancel</a>
              </Popconfirm>
            </span>
          ) : (
            <Typography.Link
              disabled={editingKey !== ""}
              onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
                preventRowExpansion(e);
                edit(record);
              }}
            >
              Edit
            </Typography.Link>
          );
        },
      },
    ];
  }, [assignee, isEditing, editingKey, edit, save, preventRowExpansion]);

  const mergedColumns = columns.map((col) => {
    return {
      ...col,
      onCell: (record: TaxBoardUser) => {
        return {
          record: record,
          inputType:
            col.key === "deadline" || col.key === "taxOfficeDeadline"
              ? "date"
              : "text",
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing(record) && col.editable === true,
        };
      },
    };
  });

  if (!isSupportedTaxYear) {
    return (
      <EmptyWrapper
        description={`Steuer Home wird erst ab ${FIRST_SUPPORTED_TAX_YEAR} unterstützt`}
      />
    );
  }

  return (
    <>
      <UserFilters
        filterOptions={{
          search: emailParam || "",
          all: false,
          myCases: false,
        }}
        setFilterOptions={handleSearchBar}
      />
      <Form form={form} component={false}>
        <TableContainer>
          <Table
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            loading={isTaxBoardUserQueryLoading}
            columns={mergedColumns as EditableColumnsType<TaxBoardUser>[]}
            dataSource={taxBoardUsers}
            onChange={(pagination, filters, sorter) =>
              handleTableOnChange(
                filters as TaxBoardUsersFilterArgs,
                sorter as SorterResult<TaxBoardUser>
              )
            }
            pagination={{
              total: taxBoardUserDataAndCount?.taxBoardUsers.count,
              defaultPageSize: pageSize,
              pageSizeOptions: ["10", "20", "50"],
              showSizeChanger: true,
              current: page,
              onChange: (newPage, pageSize) => {
                setPage(newPage);
                setPageSize(pageSize);
              },
            }}
            rowKey="email"
            rowClassName="expandable"
            expandable={{
              expandedRowRender: (taxBoardUser) => {
                if (isDeclarationsExists(taxBoardUser)) {
                  return (
                    <TaxCaseDetails
                      email={email!}
                      taxYear={year}
                      taxBoardUser={taxBoardUser}
                      onRefresh={refetchTaxBoardUsers}
                    />
                  );
                }
                return (
                  <Tag color="warning">
                    Für diesen Benutzer wurde keine Steuererklärung erstellt
                  </Tag>
                );
              },
              defaultExpandAllRows: false,
              expandRowByClick: true,
              // Hide expand icon
              expandIconColumnIndex: -1,
              expandedRowKeys: expandedRowKeys,
              onExpand: onTableRowExpand,
            }}
          />
        </TableContainer>
      </Form>
    </>
  );
};

export default TaxHome;
