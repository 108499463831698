import React, { useCallback, useEffect, useRef, useState } from "react";
import moment from "moment";
import { Button, DatePicker, Row, Input, InputRef } from "antd";

import { DownloadOutlined, EyeOutlined } from "@ant-design/icons";

import { range } from "lodash";

import { Euer } from "../../../types";
import api from "../../../api";
import {
  AccountWrapper,
  ButtonsWrapper,
  BWAHeaderContainer,
  DateFiltersBWAView,
} from "./styledComponents";

import { useLazyRequest } from "../../hooks/useRequest.hook";

import PreviewModal from "./PreviewModal";

import { downloadCSV } from "./csvHandling";
import FilterBoxCard from "../../common/Card/FilterBoxCard";
import { DateInputType } from "../../common/TransactionFilters/DateInput";
import {
  endOfBerlinDayMoment,
  getBerlinMomentTimezone,
  startOfBerlinDayMoment,
} from "../../common/helpers";
import useSearchHotkey from "../../hooks/useSearchHotkey";
import { downloadPDF } from "./helpers";
import { showErrorNotification } from "../../../utils";

const isNotEmpty = (value: string) => !!value;
const { Search } = Input;
const dateFormat = "MM-YYYY";
const EXPORT_DATE_FORMAT = "YYYY-MM-DD";
const maxWidth = 200; // maxWidth of filers group is 200px
const maxDate = getBerlinMomentTimezone().format();
const { RangePicker } = DatePicker;

const BWAHeader = ({
  email,
  changeHandler,
}: {
  email?: string;
  changeHandler: Function;
}) => {
  const [emailInput, setEmailInput] = useState(email);
  const searchRef = useRef<InputRef>(null);
  useSearchHotkey(searchRef);

  const [startDate, setStartDate] = useState<string | null>(
    moment().startOf("year").format()
  );
  const [endDate, setEndDate] = useState<string | null>(moment().format());
  const [accountId, setAccountId] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selection, setSelection] = useState<string>("");
  const [isExportingPdf, setIsExportingPdf] = useState(false);

  const getCurrentYear = () => moment().get(DateInputType.Year).toString();

  const [getEuerElster, getEuerElsterResponse] = useLazyRequest<Euer>(
    (accountId: string, startDate: string, endDate: string) =>
      api.kontax.getEuerElster(accountId, startDate, endDate)
  );

  const canExportBwaPDF = emailInput && startDate && endDate;

  const getAccountId = useCallback(async (email) => {
    try {
      if (isNotEmpty(email)) {
        const accountId = await api.kontax.getAccountIdByEmail(email);
        setAccountId(accountId);
      } else {
        setAccountId("");
      }
    } catch (e) {
      setAccountId("");
    }
  }, []);

  useEffect(() => {
    getAccountId(email);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleYearSelect = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    yearSelected: string
  ): void => {
    setSelection(yearSelected);
    changeHandler({
      dateFrom: startOfBerlinDayMoment(moment(yearSelected).endOf("day")),
      dateTo: yearSelected.includes(moment().year().toString())
        ? endOfBerlinDayMoment(moment())
        : endOfBerlinDayMoment(moment(yearSelected).endOf("year")),
    });
    setStartDate(moment(yearSelected).endOf("day").toISOString());
    if (yearSelected.includes(moment().year().toString())) {
      setEndDate(moment().toISOString());
    } else {
      setEndDate(moment(yearSelected).endOf("year").toISOString());
    }
  };

  const handlePreviewRequest = async (event: React.FormEvent) => {
    event.preventDefault();
    await getEuerElster(accountId, startDate, endDate);
    setShowModal(true);
  };

  const handleDownloadCSVRequest = async (event: React.FormEvent) => {
    event.preventDefault();
    const data = await getEuerElster(accountId, startDate, endDate);
    downloadCSV(accountId, data.elsterFields);
  };

  const handleDownloadPDFRequest = async (event: React.FormEvent) => {
    event.preventDefault();
    if (!canExportBwaPDF) {
      return;
    }

    setIsExportingPdf(true);

    try {
      const response = await api.kontax.getBWAPdf(
        emailInput,
        moment(startDate).format(EXPORT_DATE_FORMAT),
        moment(endDate).format(EXPORT_DATE_FORMAT)
      );
      const filename = response.headers["content-disposition"].split("=")[1];
      await downloadPDF(response.data, filename);
    } catch (err) {
      showErrorNotification({
        message: `An error occurred while generating BWA PDF: ${
          (err as Error).message
        }`,
      });
    } finally {
      setIsExportingPdf(false);
    }
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  return (
    <>
      <Row>
        <BWAHeaderContainer>
          <FilterBoxCard title="Email">
            <AccountWrapper>
              <Search
                ref={searchRef}
                value={emailInput}
                onChange={(event) => {
                  setEmailInput(event.target.value);
                }}
                onSearch={(email) => {
                  changeHandler({ email: email });
                  getAccountId(email);
                }}
                id="email"
                placeholder="Enter E-mail"
                allowClear
                enterButton="Filter"
              />
            </AccountWrapper>
          </FilterBoxCard>
          <FilterBoxCard>
            <DateFiltersBWAView>
              {range(+getCurrentYear(), 2019).map((yearIdx, _, arr) => {
                const yearString = "" + yearIdx;
                return (
                  <Button
                    style={{ width: `${maxWidth / arr.length}px` }}
                    key={yearString}
                    type={yearString === selection ? "primary" : "default"}
                    onClick={(e) => handleYearSelect(e, yearString)}
                  >
                    {yearString}
                  </Button>
                );
              })}
            </DateFiltersBWAView>
          </FilterBoxCard>

          <FilterBoxCard>
            <RangePicker
              style={{ width: maxWidth }}
              disabledDate={
                maxDate
                  ? (date) => !date || date.isAfter(maxDate, "day")
                  : undefined
              }
              format={dateFormat}
              defaultValue={
                startDate && endDate
                  ? [moment(startDate), moment(endDate)]
                  : undefined
              }
              onChange={(dates) => {
                setSelection("");
                if (!dates) {
                  // happens if user clears the dateRangePicker
                  setStartDate("");
                  setEndDate("");
                } else {
                  const momentStart = moment(dates[0])
                    .startOf("month")
                    .add(1, "days");
                  const momentEnd = moment(dates[1]).endOf("month");
                  const momentLatest = moment();

                  changeHandler({
                    dateFrom: startOfBerlinDayMoment(momentStart),
                    dateTo: momentEnd.isAfter(momentLatest)
                      ? endOfBerlinDayMoment(momentLatest)
                      : endOfBerlinDayMoment(momentEnd),
                  });
                  setStartDate(moment(dates[0]).startOf("month").toISOString());
                  if (momentEnd.isAfter(momentLatest)) {
                    setEndDate(momentLatest.toISOString());
                  } else {
                    setEndDate(moment(dates[1]).endOf("month").toISOString());
                  }
                }
              }}
              picker="month"
            />
          </FilterBoxCard>
          {getEuerElsterResponse.error && (
            <p>
              Sorry, that did not work ({getEuerElsterResponse.error.message})
            </p>
          )}
          <ButtonsWrapper>
            <Button
              icon={<DownloadOutlined />}
              disabled={!canExportBwaPDF}
              loading={isExportingPdf}
              onClick={handleDownloadPDFRequest}
              style={{ display: "inline-flex" }}
            >
              Download PDF
            </Button>
            <Button
              icon={<EyeOutlined />}
              disabled={!accountId}
              type="primary"
              onClick={handlePreviewRequest}
              style={{ display: "inline-flex" }}
            >
              Preview
            </Button>
            <Button
              icon={<DownloadOutlined />}
              disabled={!accountId}
              onClick={handleDownloadCSVRequest}
              style={{ display: "inline-flex" }}
            >
              Download CSV
            </Button>
          </ButtonsWrapper>
        </BWAHeaderContainer>
      </Row>

      <PreviewModal
        show={showModal}
        onCloseHandler={handleModalClose}
        accountId={accountId}
        startDate={startDate}
        endDate={endDate}
        euer={getEuerElsterResponse.data}
      />
    </>
  );
};

export default BWAHeader;
