export const getUserFullName = ({
  firstName,
  lastName,
}: {
  firstName?: string;
  lastName?: string;
}): string =>
  firstName && lastName
    ? `${firstName} ${lastName}`
        .toLocaleLowerCase()
        .split(" ")
        .map((name) => name.charAt(0).toLocaleUpperCase() + name.substring(1))
        .join(" ")
    : "-";
