export enum QuestionnaireAnswerDocumentsStatus {
  NOT_REQUIRED = "NOT_REQUIRED",
  PENDING = "PENDING",
  DELETED = "DELETED",
  UPLOADED = "UPLOADED",
}

export enum QuestionnaireInputType {
  BOOLEAN = "BOOLEAN",
  LIST = "LIST",
  DROPDOWN = "DROPDOWN",
  INTEGER = "INTEGER",
  DATE = "DATE",
  ADDRESS = "ADDRESS",
  CURRENCY = "CURRENCY",
  TIMEFRAME = "TIMEFRAME",
  TEXT = "TEXT",
  EMAIL = "EMAIL",
  PHONE = "PHONE",
  TEXT_AREA = "TEXT_AREA",
  FORM = "FORM",
}

export interface QuestionnaireInputConfigBoolean {
  type: QuestionnaireInputType.BOOLEAN;
  withNotSureOption?: boolean;
}

export interface QuestionnaireInputConfigList {
  type: QuestionnaireInputType.LIST;
  options: Array<string | number>;
  multiple?: boolean;
}

export interface QuestionnaireInputConfigDropdown {
  type: QuestionnaireInputType.DROPDOWN;
  options: Array<string | number>;
}

export interface IntegerValidationParams {
  min?: number;
  max?: number;
}

export interface QuestionnaireInputConfigInteger {
  type: QuestionnaireInputType.INTEGER;
  validation: IntegerValidationParams;
}

export interface QuestionnaireInputConfigDate {
  type: QuestionnaireInputType.DATE;
}

export interface QuestionnaireInputConfigAddress {
  type: QuestionnaireInputType.ADDRESS;
}

export interface QuestionnaireInputConfigCurrency {
  type: QuestionnaireInputType.CURRENCY;
}

export interface QuestionnaireInputConfigTimeframe {
  type: QuestionnaireInputType.TIMEFRAME;
  minDate?: Date;
  maxDate?: Date;
}

export interface QuestionnaireInputConfigText {
  type: QuestionnaireInputType.TEXT;
}

export interface QuestionnaireInputConfigEmail {
  type: QuestionnaireInputType.EMAIL;
}

export interface QuestionnaireInputConfigPhone {
  type: QuestionnaireInputType.PHONE;
}

export interface QuestionnaireInputConfigTextArea {
  type: QuestionnaireInputType.TEXT_AREA;
  maxLength?: number;
  showCount?: boolean;
}

interface QuestionnaireInputConfigFormField<T> {
  name: string;
  value: T;
  oneOfGroup?: number;
}

export type QuestionnaireInputConfigFormInput =
  QuestionnaireInputConfigFormField<
    | QuestionnaireInputConfigText
    | QuestionnaireInputConfigEmail
    | QuestionnaireInputConfigPhone
    | QuestionnaireInputConfigDate
    | QuestionnaireInputConfigDropdown
    | QuestionnaireInputConfigTextArea
  > & { optional?: boolean };

export interface QuestionnaireInputConfigForm {
  type: QuestionnaireInputType.FORM;
  inputs: QuestionnaireInputConfigFormInput[];
  multiple?: boolean;
}

export type QuestionnaireInputConfig =
  | QuestionnaireInputConfigBoolean
  | QuestionnaireInputConfigList
  | QuestionnaireInputConfigDropdown
  | QuestionnaireInputConfigInteger
  | QuestionnaireInputConfigDate
  | QuestionnaireInputConfigTimeframe
  | QuestionnaireInputConfigAddress
  | QuestionnaireInputConfigCurrency
  | QuestionnaireInputConfigText
  | QuestionnaireInputConfigEmail
  | QuestionnaireInputConfigPhone
  | QuestionnaireInputConfigForm
  | QuestionnaireInputConfigTextArea;

export interface QuestionnaireAnswer {
  value?: any;
  submittedAt?: Date;
  postponedAt?: Date;
  documentsStatus: QuestionnaireAnswerDocumentsStatus;
}

export interface QuestionnaireQuestion {
  name: string;
  topic: string;
  inputConfig?: QuestionnaireInputConfig;
  answer?: QuestionnaireAnswer;
}
