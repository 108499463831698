import React, { useState, useMemo, memo } from "react";
import ReactPanZoom from "react-image-pan-zoom-rotate";

import { InboxOutlined } from "@ant-design/icons";

import { getLocalUrlForFile } from "../helpers";
import { Asset, ILocalAsset } from "../../../types";
import {
  PlaceholderContainer,
  ViewerContainer,
  Pagination,
  ImagePreviewWrapper,
  ImagePreviewContainer,
} from "./styledComponents";
import DeleteAssetButton from "../DeleteAssetButton";
import DeleteDocumentButton from "../DeleteDocumentButton";

export const isLocalAsset = (
  asset: Asset | ILocalAsset
): asset is ILocalAsset => "file" in asset;

/**
 * This component is for viewing local assets only (assets are not uploaded yet)
 */
const LocalAssetsViewer = ({
  assets,
  className,
  minHeight = 300,
  maxHeight,
  id = "local-assets-viewer",
  topPagination,
  onDelete,
  onDeleteDocument,
  email,
  isReadOnly,
}: {
  assets: Array<Asset | ILocalAsset>;
  className?: string;
  minHeight?: number;
  maxHeight?: number;
  id?: string;
  topPagination?: boolean;
  onDelete?: (asset: Asset | ILocalAsset, deletedRemoteAsset?: boolean) => void;
  onDeleteDocument?: (documentId: string) => void;
  email?: string;
  isReadOnly?: boolean;
}) => {
  const assetsWithUrl: Asset[] = assets.map((asset) =>
    isLocalAsset(asset) ? getLocalUrlForFile(asset) : asset
  );
  const [currentAssetIndex, setCurrentAssetIndex] = useState(0);

  const assetIndex = useMemo(
    () =>
      currentAssetIndex && currentAssetIndex > assets.length - 1
        ? assets.length - 1
        : currentAssetIndex,
    [assets, currentAssetIndex]
  );

  const PaginationComponent = useMemo(
    () =>
      assetsWithUrl.length > 1 && (
        <Pagination topPagination={topPagination} isReadOnly={isReadOnly}>
          {assetsWithUrl.map((_, index) => (
            <li
              key={index}
              className={index === assetIndex ? "active" : ""}
              onClick={() => setCurrentAssetIndex(index)}
            >
              {index + 1}
            </li>
          ))}
        </Pagination>
      ),
    [assetsWithUrl, assetIndex, topPagination, isReadOnly]
  );

  const deleteButtonStyle = useMemo(
    () =>
      getDeleteButtonStyle(assetsWithUrl[assetIndex]?.filetype, topPagination),
    [assetIndex, assetsWithUrl, topPagination]
  );

  return (
    <>
      {topPagination && PaginationComponent}
      <ViewerContainer
        className={`${className} ${assets.length && "preview"}`}
        minHeight={minHeight}
        maxHeight={maxHeight}
        id={id}
      >
        {assets.length ? (
          <ImagePreviewWrapper>
            {assetsWithUrl[assetIndex].filetype === "pdf" ? (
              <embed
                src={assetsWithUrl[assetIndex].fullsize}
                width="100%"
                height="auto"
              />
            ) : (
              <ImagePreviewContainer maxHeight={maxHeight}>
                <ReactPanZoom image={assetsWithUrl[assetIndex].fullsize} />
              </ImagePreviewContainer>
            )}
            {!isReadOnly && onDelete && (
              <DeleteAssetButton
                asset={assetsWithUrl[assetIndex]}
                onConfirm={onDelete}
                style={deleteButtonStyle}
              />
            )}
            {!isReadOnly &&
              onDeleteDocument &&
              assetsWithUrl[assetIndex]?.id &&
              email && (
                <DeleteDocumentButton
                  documentId={assetsWithUrl[assetIndex].id!}
                  style={deleteButtonStyle}
                  email={email}
                  onDeleteDocument={onDeleteDocument}
                />
              )}
          </ImagePreviewWrapper>
        ) : (
          <PlaceholderContainer>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-hint">
              Drag and drop invoices here to upload
            </p>
          </PlaceholderContainer>
        )}
      </ViewerContainer>
      {!topPagination && PaginationComponent}
    </>
  );
};

export default memo(LocalAssetsViewer);

function getDeleteButtonStyle(filetype: string, topNavigation?: boolean) {
  if (!topNavigation) {
    return undefined;
  }

  if (filetype === "pdf") {
    return { top: 140 };
  }

  return { top: 270 };
}
