import { Col, List } from "antd";
import React from "react";

import colors from "../../../../../../../themes/colors";

import { SectionFields } from "../../constants";
import EditableNumber from "../../../../components/EditableNumber";
import {
  getFormattedAmount,
  getFormattedPercentage,
  isAmountValue,
  isPercentageValue,
} from "../../utils";

const TradeTaxDeclarationCalculationListItem = ({
  item,
  onChange,
}: {
  item: SectionFields;
  onChange: (value: number | null) => Promise<void>;
}) => {
  return (
    <List.Item>
      <Col span={4}>{item.title}</Col>
      <Col span={4} style={{ color: colors.darkGrey }}>
        {item.source}
      </Col>
      <Col
        span={4}
        style={{
          color: isPercentageValue(item.valueType)
            ? colors.darkGrey
            : colors.black,
          textAlign: "right",
        }}
      >
        {item.editable ? (
          <EditableNumber value={item.value!} editable={true} onEdit={onChange}>
            {getFormattedAmount(item.value)}
          </EditableNumber>
        ) : isAmountValue(item.valueType) ? (
          getFormattedAmount(item.value)
        ) : (
          getFormattedPercentage(item.value)
        )}
      </Col>
    </List.Item>
  );
};

export default TradeTaxDeclarationCalculationListItem;
