import React from "react";
import LinkifyIt from "linkify-it";

const TextWithHyperlinks = (props: {
  text: string | number | null | undefined;
  withFallbackDash?: boolean;
}) => {
  const Fallback = () => (props.withFallbackDash ? <>-</> : null);

  const result = React.useMemo(() => {
    if (!props.text || typeof props.text !== "string") return props.text;
    const linkify = LinkifyIt();
    return props.text
      ?.replace(/(?:\n)/g, " \n ")
      .replace(/(?:\r\n)/g, " \r\n ")
      .replace(/(?:\n)/g, " \n ")
      .split(" ")
      .map((part: string, index: any) => {
        const foundHyperlink = linkify.match(part)?.[0];
        return foundHyperlink ? (
          <a href={foundHyperlink.url} rel="noreferrer" target="_blank">
            {`${foundHyperlink.text} `}
          </a>
        ) : (
          `${part} `
        );
      });
  }, [props.text]);
  return result ? <>{result}</> : <Fallback />;
};

export default TextWithHyperlinks;
