import React, { useEffect } from "react";

import { Wrapper } from "./styledComponents";

const Login = () => {
  useEffect(() => {
    window.gapi.signin2.render("my-signin2", {
      scope: "profile email",
      width: 240,
      height: 50,
      longtitle: true,
      theme: "dark",
    });
  }, []);

  return (
    <Wrapper>
      <div id="my-signin2"></div>
    </Wrapper>
  );
};

export default Login;
