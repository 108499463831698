import { gql, QueryHookOptions, useQuery } from "@apollo/client";

export interface TradeTaxDeclarationDraftQueryVariables {
  email: string;
  year: number;
}

export interface TradeTaxDeclarationDraftQueryResult {
  tradeTaxDeclaration: {
    draft: {
      message: string;
      requestXml: string;
      processResult: string;
      pdf: string;
      calculationSheet: string;
    };
  };
}

export const TRADE_TAX_DECLARATION_DRAFT_QUERY = gql`
  query tradeTaxDeclarationDraft($email: String!, $year: Int!) {
    tradeTaxDeclaration(email: $email, year: $year) {
      draft {
        message
        requestXml
        processResult
        pdf
        calculationSheet
      }
    }
  }
`;

export const useTradeTaxDeclarationDraftQuery = (
  options?: QueryHookOptions<
    TradeTaxDeclarationDraftQueryResult,
    TradeTaxDeclarationDraftQueryVariables
  >
) =>
  useQuery<
    TradeTaxDeclarationDraftQueryResult,
    TradeTaxDeclarationDraftQueryVariables
  >(TRADE_TAX_DECLARATION_DRAFT_QUERY, options);
