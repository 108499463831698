import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Col, Tag, List, Space } from "antd";
import { DownloadOutlined, EyeOutlined } from "@ant-design/icons";
import pick from "lodash/pick";
import * as queryString from "query-string";

import { merge } from "lodash";

import useTaxYearParam from "../../../../hooks/useTaxYearParam";
import {
  ActionText,
  AntListItem,
  AntListItemMeta,
  AntRow,
  StatusUpdateText,
  ListBox,
  TagContainer,
  FormTitle,
} from "./styledComponents";
import useEmailParam from "../../../../../../hooks/useEmailParam";
import EmptyWrapper from "../../../../../../common/EmptyWrapper";
import EuerDeclarationPreviewModal from "./EuerDeclarationPreviewModal";
import {
  EUER_DECLARATION_OVERVIEW_QUERY,
  EuerDeclaration,
  EuerDeclarationSubformOverview,
  useEuerDeclarationAutoCalculatedValuesLazyQuery,
  useEuerDeclarationOverviewQuery,
  useEuerDraftPdfQuery,
  useSubmitEuerMutation,
  useUpdateEuerDeclarationMutation,
  EuerDeclarationStatusChange,
} from "../../../../../../../api/graphql";
import {
  EuerDeclarationStatus,
  TaxDeclarationSavedDraftInfo,
  TaxDeclarationSubmission,
  TaxDeclarationType,
} from "../../../../../../../api/graphql/schema.generated";
import { IMessageType } from "../../../../../../../types";
import {
  showMessage,
  destroyMessage,
  showGraphQlErrorNotification,
  showInfoNotification,
  showLoadingMessage,
} from "../../../../../../../utils";
import {
  EUER_DECLARATION_OVERVIEW_META_INFO,
  EUER_DECLARATION_STATUS_MAPPINGS,
  SUBFORM_STATUS_MAPPINGS,
} from "../../constants";
import { EuerDeclarationSubformStatus } from "../../../../types";
import { AntGrayTag } from "../../../../components/styledComponents";
import { StatusColor } from "../../../../../../../constants";
import PdfPreviewModal from "../../../../components/PdfPreviewModal";
import DeclarationSubmitConfirmation from "../../../../components/DeclarationSubmitConfirmation";
import EuerDeclarationHeader from "./EuerDeclarationHeader";
import { getStatusText, hasEricNotice } from "../../../../utils";
import { useSaveEuerDraftPdfMutation } from "../../../../../../../api/graphql/mutations/euerDeclaration/saveEuerDrafPdf.generated";
import { useEuerDeclarationSavedDraftInfoQuery } from "../../../../../../../api/graphql/queries/euerDeclaration/euerDeclarationSavedDraftInfo.generated";
import DeclarationInfoMessage from "../../../../components/DeclarationInfoMessage";
import { declarationDeclinesSorter } from "../../../../../../../utils/declarationDeclines";
import DeclarationDeclinedBanner from "../../../../components/DeclarationDeclinedBanner";
import { declarationSubmissionsSorter } from "../../../../../../../utils/declarationSubmissons";

export const PREVIEW_LOCATION_HASH = "#preview";

const STATUSES_WITH_BLOCKED_PDF_RECREATION = [
  EuerDeclarationStatus.APPROVED_BY_USER,
  EuerDeclarationStatus.OBJECTED_BY_USER,
  EuerDeclarationStatus.SUBMITTED,
  EuerDeclarationStatus.OBJECTED_BY_FINANZAMT,
  EuerDeclarationStatus.RECEIVED_TAX_BILL,
  EuerDeclarationStatus.CLOSED,
  EuerDeclarationStatus.APPEAL_PROCESS_STARTED,
  EuerDeclarationStatus.APPEAL_PROCESS_COMPLETED,
];

type ListItem = (EuerDeclaration | EuerDeclarationSubformOverview) & {
  name: string;
  isSubForm?: boolean;
  path?: string;
  queryParams?: Record<string, any>;
  lastStatusChange: EuerDeclarationStatusChange | null;
};

const EuerDeclarationOverview = () => {
  const [taxYear] = useTaxYearParam();
  const [email] = useEmailParam();
  const [isEuerDeclarationPreviewVisible, setIsEuerDeclarationPreviewVisible] =
    useState(false);

  const [confirmationVisible, setConfirmationVisible] = useState(false);
  const [confirmationLoading, setConfirmationLoading] = useState(false);
  const [selectedSubmission, setSelectedSubmission] =
    useState<TaxDeclarationSubmission>();
  const [draftInfo, setDraftInfo] =
    useState<TaxDeclarationSavedDraftInfo | null>();

  const {
    data: euerDeclarationOverviewData,
    loading: isLoadingEuerDeclarationOverview,
  } = useEuerDeclarationOverviewQuery({
    skip: !email,
    variables: {
      email: email!,
      year: taxYear,
    },
  });
  const savedSubmissionInfo =
    euerDeclarationOverviewData?.euerDeclaration.submissionInfo || null;
  const savedDraftInfo =
    euerDeclarationOverviewData?.euerDeclaration.savedDraftInfo || null;

  const [
    getEuerDeclarationAutoCalculatedValues,
    { data: euerDeclarationAutoCalculatedValuesData },
  ] = useEuerDeclarationAutoCalculatedValuesLazyQuery({
    variables: {
      email: email!,
      year: taxYear,
    },
  });

  const { refetch: getEuerDraft } = useEuerDraftPdfQuery({
    variables: {
      payload: {
        email: email!,
        year: taxYear,
      },
    },
    fetchPolicy: "standby",
  });

  const { refetch: getEuerDeclarationSavedDraftPdf } =
    useEuerDeclarationSavedDraftInfoQuery({
      variables: {
        email: email!,
        year: taxYear,
      },
      fetchPolicy: "standby",
    });

  const [saveEuerDraftPdf] = useSaveEuerDraftPdfMutation({
    variables: {
      payload: {
        email: email!,
        year: taxYear,
      },
    },
    refetchQueries: [EUER_DECLARATION_OVERVIEW_QUERY],
  });

  const [updateEuerDeclaration] = useUpdateEuerDeclarationMutation({
    refetchQueries: [EUER_DECLARATION_OVERVIEW_QUERY],
  });

  const [submitEuer] = useSubmitEuerMutation({
    variables: {
      payload: {
        email: email!,
        year: taxYear,
      },
    },
    refetchQueries: [EUER_DECLARATION_OVERVIEW_QUERY],
  });

  const showConfirmation = useCallback(() => {
    setConfirmationVisible(true);
  }, []);

  const closeConfirmation = useCallback(() => {
    setConfirmationVisible(false);
    setConfirmationLoading(false);
  }, []);

  const handleSubmitConfirmation = useCallback(async () => {
    setConfirmationLoading(true);
    try {
      const result = await submitEuer();
      if (result.data?.submitEuer?.pdf) {
        showMessage({
          type: IMessageType.SUCCESS,
          content: "Erfolgreich eingereicht",
        });
      } else {
        showMessage({
          type: IMessageType.ERROR,
          content:
            result.data?.submitEuer?.processResult ||
            "Bei der Einreichung ist ein Fehler aufgetreten",
          duration: 5,
        });
      }
    } catch (error) {
      showGraphQlErrorNotification(
        "Bei der Einreichung ist ein Fehler aufgetreten:",
        error
      );
    }
    setConfirmationLoading(false);
    setConfirmationVisible(false);
  }, [submitEuer]);

  const showDraftPdf = useCallback(async () => {
    const loadingKey = "loading-draft-pdf";
    showLoadingMessage(loadingKey);
    try {
      const result = await getEuerDraft();
      const pdf = result.data?.getEuerDraftPdf?.pdf;
      if (pdf) {
        setDraftInfo({ pdf });
        if (hasEricNotice(result.data?.getEuerDraftPdf?.processResult)) {
          showInfoNotification({
            message: result.data?.getEuerDraftPdf?.message,
            description: result.data?.getEuerDraftPdf?.processResult,
          });
        }
        showMessage({
          type: IMessageType.SUCCESS,
          content: "Abrufen des Entwurf-PDF erfolgreich",
        });
      } else {
        showMessage({
          type: IMessageType.ERROR,
          content:
            result.data?.getEuerDraftPdf?.processResult ||
            "Beim Abrufen des Entwurf-PDFs ist ein Fehler aufgetreten",
          duration: 5,
        });
      }
    } catch (error) {
      showGraphQlErrorNotification(
        "Beim Abrufen des Entwurf-PDFs ist ein Fehler aufgetreten:",
        error
      );
    } finally {
      destroyMessage(loadingKey);
    }
  }, [getEuerDraft]);

  const saveAndShowDraftPdf = useCallback(async () => {
    const loadingKey = "saving-and-loading-draft-pdf";
    showLoadingMessage(loadingKey);
    try {
      const result = await saveEuerDraftPdf();
      const pdf = result.data?.saveEuerDraftPdf.pdf;
      if (pdf) {
        setDraftInfo({ pdf });
        if (hasEricNotice(result.data?.saveEuerDraftPdf?.processResult!)) {
          showInfoNotification({
            message: result.data?.saveEuerDraftPdf?.message!,
            description: result.data?.saveEuerDraftPdf?.processResult,
          });
        }
        showMessage({
          type: IMessageType.SUCCESS,
          content: "Abrufen des Entwurf-PDF erfolgreich",
        });
      } else {
        showMessage({
          type: IMessageType.ERROR,
          content:
            result.data?.saveEuerDraftPdf?.processResult ||
            "Beim Abrufen des Entwurf-PDFs ist ein Fehler aufgetreten",
          duration: 5,
        });
      }
    } catch (error) {
      showGraphQlErrorNotification(
        "Beim Abrufen des Entwurf-PDFs ist ein Fehler aufgetreten:",
        error
      );
    } finally {
      destroyMessage(loadingKey);
    }
  }, [saveEuerDraftPdf]);

  const showSavedDraftPdf = useCallback(async () => {
    const loadingKey = "loading-saved-draft-pdf";
    showLoadingMessage(loadingKey);
    try {
      const result = await getEuerDeclarationSavedDraftPdf();
      setDraftInfo(result.data.euerDeclaration.savedDraftInfo);
    } catch (error) {
      showGraphQlErrorNotification(
        "Beim Abrufen des Anl. EÜR PDF ist ein Fehler aufgetreten:",
        error
      );
    } finally {
      destroyMessage(loadingKey);
    }
  }, [getEuerDeclarationSavedDraftPdf]);

  const showEuerDeclarationPreview = useCallback(async () => {
    if (!email) {
      return;
    }
    setIsEuerDeclarationPreviewVisible(true);
    try {
      await getEuerDeclarationAutoCalculatedValues();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      setIsEuerDeclarationPreviewVisible(false);
    }
  }, [email, getEuerDeclarationAutoCalculatedValues]);

  const handleEuerDeclarationPreviewClose = useCallback(() => {
    setIsEuerDeclarationPreviewVisible(false);
  }, []);

  const handleEuerDeclarationSave = useCallback(
    async (declaration: EuerDeclaration) => {
      if (!email) {
        return;
      }
      const payload = pick(declaration, ["fields", "status"]);
      await updateEuerDeclaration({
        variables: {
          email,
          year: taxYear,
          payload,
        },
      });
    },
    [email, taxYear, updateEuerDeclaration]
  );

  const history = useHistory();

  /**
   * Format BE data to source the UI list.
   */
  const listDataSource: ListItem[] = useMemo(() => {
    if (!euerDeclarationOverviewData) {
      return [];
    }

    // Define the data array by extending BE data with each form/sub-form metadata (such as
    // `name`, url `path` and `isSubform` flag).
    return Object.values(
      merge(
        {},
        euerDeclarationOverviewData,
        EUER_DECLARATION_OVERVIEW_META_INFO
      )
    );
  }, [euerDeclarationOverviewData]);

  useEffect(() => {
    // Check if preview modal should be open to allow deep-linking
    if (history.location.hash === PREVIEW_LOCATION_HASH) {
      history.replace({
        pathname: history.location.pathname,
        search: history.location.search,
        hash: undefined,
      });
      showEuerDeclarationPreview();
    }
    // It should be executed only on initial render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const shouldBlockPdfRecreationByStatus = useMemo(() => {
    return euerDeclarationOverviewData
      ? STATUSES_WITH_BLOCKED_PDF_RECREATION.includes(
          euerDeclarationOverviewData.euerDeclaration.status
        )
      : true;
  }, [euerDeclarationOverviewData]);

  const lastDeclarationDecline = useMemo(() => {
    if (
      euerDeclarationOverviewData?.euerDeclaration.declarationDeclines?.length
    ) {
      const declarationDeclines =
        euerDeclarationOverviewData.euerDeclaration.declarationDeclines;
      declarationDeclines.sort(declarationDeclinesSorter);
      return declarationDeclines[0];
    }
  }, [euerDeclarationOverviewData]);

  const submissions = useMemo(() => {
    if (euerDeclarationOverviewData?.euerDeclaration.submissions?.length) {
      const declarationSubmissions =
        euerDeclarationOverviewData?.euerDeclaration.submissions;
      return declarationSubmissions.sort(declarationSubmissionsSorter);
    }
    return [];
  }, [euerDeclarationOverviewData]);
  const isStatusObjectedByUser = useMemo(
    () =>
      euerDeclarationOverviewData?.euerDeclaration.status ===
      EuerDeclarationStatus.OBJECTED_BY_USER,
    [euerDeclarationOverviewData]
  );

  if (!email) {
    return <EmptyWrapper description="Suche nach einem Mandanten" />;
  }

  return (
    <>
      <EuerDeclarationHeader
        taxYear={taxYear}
        isLoading={isLoadingEuerDeclarationOverview}
        draftInfo={savedDraftInfo}
        hasExternalAssets={!!savedSubmissionInfo?.externalAssets}
        lastSubmission={submissions[0]}
        changeLogs={euerDeclarationOverviewData?.euerDeclaration.changeLogs}
        notes={euerDeclarationOverviewData?.euerDeclaration.notes || []}
        declarationDeclines={
          euerDeclarationOverviewData?.euerDeclaration.declarationDeclines || []
        }
        declarationId={euerDeclarationOverviewData?.euerDeclaration.id!}
        status={euerDeclarationOverviewData?.euerDeclaration.status}
      />
      <Space direction="horizontal">
        {!isLoadingEuerDeclarationOverview && savedDraftInfo && (
          <Button
            type="primary"
            size="small"
            icon={<EyeOutlined />}
            onClick={showSavedDraftPdf}
          >
            Aktuelles Freigabe-PDF
          </Button>
        )}
        {!isLoadingEuerDeclarationOverview &&
          submissions &&
          submissions.map((submission, index) => (
            <Button
              key={submission.submissionDate}
              type="primary"
              size="small"
              icon={<DownloadOutlined />}
              onClick={() => setSelectedSubmission(submission)}
            >
              {`Übermittelte Anl. EÜR PDF ${submissions.length - index} `}
            </Button>
          ))}
      </Space>
      <DeclarationDeclinedBanner
        declaration={euerDeclarationOverviewData?.euerDeclaration!}
        declarationType={TaxDeclarationType.EUER}
        onLinkClick={showSavedDraftPdf}
      />
      <ListBox>
        <List
          loading={isLoadingEuerDeclarationOverview}
          itemLayout="horizontal"
          dataSource={listDataSource}
          renderItem={(item: ListItem) => {
            const statusMappings = item.isSubForm
              ? SUBFORM_STATUS_MAPPINGS[
                  item.status as EuerDeclarationSubformStatus
                ]
              : EUER_DECLARATION_STATUS_MAPPINGS[
                  item.status as EuerDeclarationStatus
                ];

            const euerStatusText =
              getStatusText({
                statusUpdatedAt: item.statusUpdatedAt,
                changedBy: item.lastStatusChange?.changedBy,
              }) || "Keine Daten hinterlegt";

            return (
              <AntListItem
                actions={[<ActionText>Bearbeiten</ActionText>]}
                isSubForm={item.isSubForm}
                onClick={() => {
                  if (item.isSubForm) {
                    history.push({
                      pathname: item.path,
                      search: queryString.stringify({
                        email,
                        year: taxYear,
                        ...item.queryParams,
                      }),
                    });
                  } else {
                    showEuerDeclarationPreview();
                  }
                }}
              >
                <AntListItemMeta
                  title={<FormTitle>{item.name}</FormTitle>}
                  description={
                    <StatusUpdateText>
                      {euerStatusText || "Keine Daten hinterlegt"}
                    </StatusUpdateText>
                  }
                  isSubForm={item.isSubForm}
                />
                <TagContainer>
                  {statusMappings.color === StatusColor.GRAY ? (
                    <AntGrayTag>{statusMappings.label}</AntGrayTag>
                  ) : (
                    <Tag color={statusMappings.color}>
                      {statusMappings.label}
                    </Tag>
                  )}
                </TagContainer>
              </AntListItem>
            );
          }}
        />
      </ListBox>
      <AntRow>
        <Col flex="auto">
          <Space>
            <Button type="default" size="middle" onClick={showDraftPdf}>
              Vorschau-Formular
            </Button>
            <Button
              type="default"
              size="middle"
              onClick={saveAndShowDraftPdf}
              disabled={
                shouldBlockPdfRecreationByStatus ||
                !!savedDraftInfo?.externalAssets
              }
            >
              Freigabe-PDF aktualisieren
            </Button>
          </Space>
        </Col>
        <Col>
          <Button
            type="primary"
            onClick={showConfirmation}
            disabled={
              isLoadingEuerDeclarationOverview ||
              !!savedDraftInfo?.externalAssets
            }
          >
            Einreichen
          </Button>
        </Col>
      </AntRow>
      <DeclarationInfoMessage
        hasExternalAssets={
          !!savedDraftInfo?.externalAssets ||
          !!savedSubmissionInfo?.externalAssets
        }
        shouldBlockPdfRecreationByStatus={shouldBlockPdfRecreationByStatus}
      />
      {euerDeclarationOverviewData && (
        <EuerDeclarationPreviewModal
          visible={isEuerDeclarationPreviewVisible}
          declaration={euerDeclarationOverviewData.euerDeclaration}
          autoCalculatedValues={
            euerDeclarationAutoCalculatedValuesData?.euerDeclaration
              .autoCalculatedValues
          }
          onClose={handleEuerDeclarationPreviewClose}
          onSave={handleEuerDeclarationSave}
        />
      )}
      {confirmationVisible && (
        <DeclarationSubmitConfirmation
          declarationName="Anlage EÜR"
          taxYear={taxYear}
          email={email}
          loading={confirmationLoading}
          onConfirm={handleSubmitConfirmation}
          onCancel={closeConfirmation}
        />
      )}
      {draftInfo && (
        <PdfPreviewModal
          declarationName="EÜR"
          title="Testformular"
          info={draftInfo}
          declarationDecline={
            isStatusObjectedByUser ? lastDeclarationDecline : undefined
          }
          statusUpdatedAt={
            isStatusObjectedByUser &&
            euerDeclarationOverviewData?.euerDeclaration.lastStatusChange
              ? euerDeclarationOverviewData.euerDeclaration.lastStatusChange
                  .changedAt
              : undefined
          }
          onClose={() => setDraftInfo(undefined)}
        />
      )}
      {selectedSubmission && (
        <PdfPreviewModal
          title="Übermittelte Anl. EÜR PDF"
          declarationName="EÜR"
          info={selectedSubmission}
          onClose={() => setSelectedSubmission(undefined)}
        />
      )}
    </>
  );
};

export default EuerDeclarationOverview;
