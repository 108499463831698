import { gql, MutationHookOptions, useMutation } from "@apollo/client";

export interface CreateEuerDeclarationSubformAssetMutationVariables {
  euerDeclarationSubformId: string;
  name: string;
  filetype: string;
}

export interface CreateEuerDeclarationSubformAssetMutationResult {
  createEuerDeclarationSubformAsset: {
    assetId: string;
    url: string;
    formData: Array<{
      key: string;
      value: string;
    }>;
  };
}

export const CREATE_EUER_DECLARATION_SUBFORM_ASSET_MUTATION = gql`
  mutation createEuerDeclarationSubformAsset(
    $euerDeclarationSubformId: ID!
    $name: String!
    $filetype: String!
  ) {
    createEuerDeclarationSubformAsset(
      euerDeclarationSubformId: $euerDeclarationSubformId
      name: $name
      filetype: $filetype
    ) {
      assetId
      url
      formData {
        key
        value
      }
    }
  }
`;

export const useCreateEuerDeclarationSubformAssetMutation = (
  options?: MutationHookOptions<
    CreateEuerDeclarationSubformAssetMutationResult,
    CreateEuerDeclarationSubformAssetMutationVariables
  >
) =>
  useMutation<
    CreateEuerDeclarationSubformAssetMutationResult,
    CreateEuerDeclarationSubformAssetMutationVariables
  >(CREATE_EUER_DECLARATION_SUBFORM_ASSET_MUTATION, options);
