import React from "react";
import { Button, Popconfirm } from "antd";
import { WarningOutlined, DeleteOutlined } from "@ant-design/icons";

const DeleteButton = ({
  handleConfirm,
  isLoading,
  title,
  okText,
  cancelText,
}: {
  handleConfirm: () => Promise<void>;
  isLoading: boolean;
  title: string;
  okText: string;
  cancelText: string;
}) => (
  <Popconfirm
    title={title}
    icon={<WarningOutlined style={{ color: "red" }} />}
    okText={okText}
    cancelText={cancelText}
    onConfirm={handleConfirm}
    placement="bottomRight"
    overlayStyle={{ maxWidth: 370, zIndex: 9999999 }}
  >
    <Button
      icon={<DeleteOutlined />}
      size="large"
      danger
      loading={isLoading}
      className="delete-button"
      style={{ justifyContent: "center" }}
    />
  </Popconfirm>
);

export default DeleteButton;
