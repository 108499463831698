import React, { useCallback, useEffect, useState } from "react";

import { notification, TablePaginationConfig } from "antd";

import { FilterValue, SorterResult } from "antd/es/table/interface";

import _ from "lodash";

import api from "../../../../../api";
import UsersTable from "./UsersTable";
import {
  IUser,
  IUserDetails,
  KontaxUserStatus,
  KontaxUserStatusAdditionalParams,
  TableParams,
  UserRow,
} from "../../../../../types";
import {
  destroyMessage,
  showErrorMessage,
  showSuccessMessage,
  showLoadingMessage,
} from "../../../../../utils";
import EmptyWrapper from "../../../../common/EmptyWrapper";
import { UserFilterOptions } from "../../Common/UserFilters";
import { UpsertAccountingSourcePayload } from "../../../../../types/AccountingSource.type";
import {
  useUpsertAccountingSourceMutation,
  useRemoveAccountingSourceMutation,
} from "../../../../../api/graphql/mutations/accountingSource";
import { notifySavedChange } from "../../Common/utils";
import { useDeleteUserDependentMutation } from "../../../../../api/graphql/mutations/userDependent/deleteUserDependent.generated";

const USER_UPDATING_KEY = "user-updating";
const DELETING_DEPENDENT_KEY = "deleting-dependent";

const UserList = ({
  filterOptions,
  setEmailParam,
  tableParams,
  setTableParams,
}: {
  filterOptions: UserFilterOptions;
  setEmailParam: (email: string) => void;
  tableParams: TableParams;
  setTableParams: (tableParams: TableParams) => void;
}) => {
  const [users, setUsers] = useState<Array<IUser> | null>(null);
  const [upsertAccountingSourceMutation] = useUpsertAccountingSourceMutation();
  const [removeAccountingSourceMutation] = useRemoveAccountingSourceMutation();
  const [deleteUserDependentHandler] = useDeleteUserDependentMutation();

  const tableParamsChanged = JSON.stringify(
    _.omit(tableParams, "pagination.total")
  );

  const fetchUsers = useCallback(
    async (skipSuccessMessage: boolean = false) => {
      const loadingKey = "loading-users";

      try {
        showLoadingMessage(loadingKey);

        const { users, count } = await api.kontax.getOnboardingUsers({
          search: filterOptions.search,
          page: tableParams.pagination?.current,
          pageSize: tableParams.pagination?.pageSize,
          filters: _.omitBy(tableParams.filters, _.isNull),
          sortField: Array.isArray(tableParams.field)
            ? tableParams.field.join(".")
            : (tableParams.field as string),
          sortOrder: tableParams.order as string,
        });

        setUsers(users);
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: count,
          },
        });
        !skipSuccessMessage && showSuccessMessage();
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error("Error fetching user", error);
        showErrorMessage("Error fetching user");
        setUsers([]);
      } finally {
        destroyMessage(loadingKey);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filterOptions.search, setTableParams, tableParamsChanged]
  );

  useEffect(() => {
    if (filterOptions.search || filterOptions.all) {
      fetchUsers();
    } else {
      setUsers(null);
    }
  }, [fetchUsers, filterOptions]);

  const updateUserListWithChangedUser = useCallback(
    (updatedUser: IUser) => {
      const updatedUserList = users!.map((user) => {
        if (user.email === updatedUser.email) {
          return updatedUser;
        }

        return user;
      });
      setUsers(updatedUserList);
    },
    [users]
  );

  const updateUserStatus = useCallback(
    async (
      email: string,
      status: KontaxUserStatus | null,
      additionalParams?: KontaxUserStatusAdditionalParams
    ) => {
      try {
        showLoadingMessage(USER_UPDATING_KEY);
        const updatedUser = await api.kontax.updateUserStatus(
          email,
          status,
          additionalParams
        );
        updateUserListWithChangedUser(updatedUser);
        notifySavedChange();
      } catch (error) {
        throw error;
      } finally {
        destroyMessage(USER_UPDATING_KEY);
      }
    },
    [updateUserListWithChangedUser]
  );

  const editUser = useCallback(
    async (email: string, userDetails: IUserDetails) => {
      showLoadingMessage(USER_UPDATING_KEY);
      try {
        const updatedUser = await api.kontax.updateUser({
          email,
          ...userDetails,
        });
        updateUserListWithChangedUser(updatedUser);
        notifySavedChange();
      } catch (error) {
        throw error;
      } finally {
        destroyMessage(USER_UPDATING_KEY);
      }
    },
    [updateUserListWithChangedUser]
  );

  const updateDependents = useCallback(
    async (email, userDependents) => {
      try {
        showLoadingMessage(USER_UPDATING_KEY);
        const updatedDependents = await api.kontax.updateDependents(
          email,
          userDependents
        );
        const updatedUserList = users!.map((user) => {
          if (user.email === email) {
            return {
              ...user,
              dependents: updatedDependents,
            };
          }

          return user;
        });

        notifySavedChange();
        setUsers(updatedUserList);
      } catch (error) {
        throw error;
      } finally {
        destroyMessage(USER_UPDATING_KEY);
      }
    },
    [users]
  );

  const deleteDependent = useCallback(
    async (email: string, id: string) => {
      showLoadingMessage(DELETING_DEPENDENT_KEY);
      try {
        const { data } = await deleteUserDependentHandler({
          variables: { id },
        });

        if (data?.deleteUserDependent.success) {
          const updatedUserList = users!.map((user) => {
            if (user.email === email) {
              return {
                ...user,
                dependents: user.dependents.filter(
                  (dependent) => dependent.id !== id
                ),
              };
            }

            return user;
          });
          setUsers(updatedUserList);
          showSuccessMessage("Dependent deleted successfully");
        }
      } catch (error) {
        notification.error({ message: `Error while deleting dependent` });
      } finally {
        destroyMessage(DELETING_DEPENDENT_KEY);
      }
    },
    [deleteUserDependentHandler, users]
  );

  const upsertAccountingSource = useCallback(
    async (email: string, payload: UpsertAccountingSourcePayload) => {
      try {
        showLoadingMessage(USER_UPDATING_KEY);
        const { data: upsertResult } = await upsertAccountingSourceMutation({
          variables: { email, payload },
        });
        const upsertedAccountingSource = upsertResult?.upsertAccountingSource!;

        // Update the user's accounting sources to refresh the UI.
        const updatedUserList = users!.map((user) => {
          if (user.email === email) {
            const currentAccountingSources =
              user.kontaxUser?.accountingSources || [];
            const newAccountingSources = [
              ...currentAccountingSources.filter(
                ({ year }) => upsertedAccountingSource.year !== year
              ),
              upsertedAccountingSource,
            ];

            return {
              ...user,
              kontaxUser: {
                ...user.kontaxUser,
                accountingSources: newAccountingSources,
              },
            };
          }

          return user;
        });

        notifySavedChange();
        setUsers(updatedUserList);
      } catch (error) {
        showErrorMessage(
          `Error while saving accounting source for the year ${payload.year}`
        );
      } finally {
        destroyMessage(USER_UPDATING_KEY);
      }
    },
    [upsertAccountingSourceMutation, users]
  );

  const removeAccountingSource = useCallback(
    async (email: string, year: number) => {
      try {
        showLoadingMessage(USER_UPDATING_KEY);
        await removeAccountingSourceMutation({
          variables: { email, year },
        });

        // Update the user's accounting sources to refresh the UI.
        const updatedUserList = users!.map((user) => {
          if (user.email === email) {
            return {
              ...user,
              kontaxUser: {
                ...user.kontaxUser,
                accountingSources: user.kontaxUser?.accountingSources?.filter(
                  (accSource) => accSource.year !== year
                ),
              },
            };
          }

          return user;
        });

        notifySavedChange();
        setUsers(updatedUserList);
      } catch (error) {
        showErrorMessage(
          `Error occurred while removing accounting source for the year ${year}`
        );
      } finally {
        destroyMessage(USER_UPDATING_KEY);
      }
    },
    [removeAccountingSourceMutation, users]
  );

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<UserRow> | SorterResult<UserRow>[]
  ) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setUsers([]);
    }
  };

  return (
    <>
      {!!users?.length ? (
        <div style={{ marginTop: "12px" }}>
          <UsersTable
            users={users}
            tableParams={tableParams}
            onChange={handleTableChange}
            updateUserStatus={updateUserStatus}
            updateDependents={updateDependents}
            deleteDependent={deleteDependent}
            upsertAccountingSource={upsertAccountingSource}
            removeAccountingSource={removeAccountingSource}
            editUser={editUser}
            setEmailParam={setEmailParam}
          />
        </div>
      ) : (
        users && <EmptyWrapper description="No users found" />
      )}
    </>
  );
};

export default UserList;
