import React from "react";
import { Modal } from "react-bootstrap";

import {
  Asset,
  IFormattedBusinessAsset,
  ILocalAsset,
} from "../../../../../../types";
import LocalAssetsViewer from "../../../../../common/LocalAssetsViewer";

const ReceiptsModal = ({
  show,
  asset,
  onHide,
  onAssetDelete,
}: {
  show: boolean;
  asset: IFormattedBusinessAsset;
  onHide: () => void;
  onAssetDelete: (asset: Asset | ILocalAsset) => void;
}) => {
  const { receipts } = asset;

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton />
      <Modal.Body style={{ paddingBottom: 80 }}>
        <LocalAssetsViewer
          assets={receipts}
          minHeight={1000}
          onDelete={onAssetDelete}
        />
      </Modal.Body>
    </Modal>
  );
};

export default ReceiptsModal;
