import React from "react";
import { Table, Switch, Tooltip, Badge, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";

import { IRule } from "../../../types";
import {
  allCategoriesRaw,
  vatCategories,
  getCategoryName,
} from "../../../utils";

const RulesTable = ({
  rules,
  updateAutocategorizationRule,
}: {
  rules: Array<IRule>;
  updateAutocategorizationRule: Function;
}) => {
  const columns = React.useMemo(
    () => [
      {
        title: "ID",
        dataIndex: "id",
        key: "id",
        width: 70,
        sorter: (a: IRule, b: IRule) => a.id - b.id,
      },
      {
        title: "Description",
        dataIndex: "description",
        key: "description",
        sorter: (a: IRule, b: IRule) =>
          a.description.localeCompare(b.description),
        onFilter: (value: any, record: IRule) =>
          record.description.toLowerCase().includes(value.toLowerCase()),
        filterIcon: () => <SearchOutlined />,
        filterDropdown: ({
          setSelectedKeys,
          selectedKeys,
          confirm,
        }: {
          setSelectedKeys: (selectedKeys: React.Key[]) => void;
          selectedKeys: React.Key[];
          confirm: (param?: { closeDropdown: boolean }) => void;
        }) => (
          <div style={{ padding: 8, width: "240px" }}>
            <Input
              placeholder="Search description"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => confirm()}
              allowClear
            />
          </div>
        ),
      },
      {
        title: "Category Code",
        key: "categoryCode",
        width: 180,
        ellipsis: true,
        render: (record: IRule) => {
          const categoryName = getCategoryName(
            record.categoryCode,
            allCategoriesRaw
          );

          return (
            <Tooltip placement="topLeft" title={categoryName}>
              {categoryName}
            </Tooltip>
          );
        },
        filters: allCategoriesRaw.map(([value, text]) => ({ value, text })),
        onFilter: (value: any, record: IRule) => record.categoryCode === value,
        filterSearch: true,
      },
      {
        title: "Vat Category",
        key: "vatCategoryCode",
        width: 180,
        ellipsis: true,
        render: (record: IRule) => {
          const vatCategoryName = getCategoryName(
            record.vatCategoryCode,
            vatCategories
          );

          return (
            <Tooltip placement="topLeft" title={vatCategoryName}>
              {vatCategoryName}
            </Tooltip>
          );
        },
        filters: vatCategories.map(([value, text]) => ({ value, text })),
        onFilter: (value: any, record: IRule) =>
          record.vatCategoryCode === value,
        filterSearch: true,
      },
      {
        title: "Auto-verified",
        key: "verify",
        width: 90,
        filterMultiple: false,
        render: (record: IRule) => {
          return (
            <Badge
              status={record.verify ? "success" : "default"}
              text={record.verify ? "Yes" : "No"}
            />
          );
        },
        filters: [
          { text: "Yes", value: true },
          { text: "No", value: false },
        ],
        onFilter: (value: any, record: IRule) => record.verify === value,
      },
      {
        title: "Times applied",
        dataIndex: "appliedTimesCount",
        key: "appliedTimesCount",
        width: 90,
        sorter: (a: IRule, b: IRule) =>
          a.appliedTimesCount - b.appliedTimesCount,
      },
      {
        title: "Accuracy",
        dataIndex: "accuracy",
        key: "accuracy",
        width: 90,
        sorter: (a: IRule, b: IRule) => a.accuracy - b.accuracy,
        render: (accuracy: number) => {
          return accuracy === null ? "-" : `${(accuracy * 100).toFixed()}%`;
        },
      },
      {
        title: "Active",
        key: "enabledStr",
        width: 90,
        render: (record: IRule) => {
          const { enabled, id } = record;
          return (
            <Switch
              onChange={(checked, event) => {
                event.stopPropagation();
                updateAutocategorizationRule(id, checked);
              }}
              checked={enabled}
              checkedChildren="ON"
              unCheckedChildren="OFF"
            />
          );
        },
        filters: [
          { text: "On", value: true },
          { text: "Off", value: false },
        ],
        onFilter: (value: any, record: IRule) => record.enabled === value,
        filterMultiple: false,
      },
    ],
    [updateAutocategorizationRule]
  );

  const data = React.useMemo(
    () =>
      (rules || []).map((rule) => {
        const {
          id,
          description,
          appliedTimesCount,
          categoryCode,
          vatCategoryCode,
          enabled,
          verify,
          conditions,
          accuracy,
        } = rule;

        return {
          id,
          description,
          appliedTimesCount,
          categoryCode,
          vatCategoryCode,
          enabled,
          verify,
          conditions,
          accuracy,
          enabledStr: enabled.toString(),
        };
      }),
    [rules]
  );

  if (!rules?.length) {
    return null;
  }

  return (
    <Table
      columns={columns}
      dataSource={data}
      pagination={{ defaultPageSize: 100 }}
      rowKey="id"
      onRow={({ id }) => ({
        style: {
          cursor: "pointer",
        },
        onClick: () => {
          window.open(`/rules-view/${id}`)?.focus();
        },
      })}
    />
  );
};

export default RulesTable;
