import type * as Types from "../../schema.generated";

import { gql } from "@apollo/client";
import { TaxAccountFragmentDoc } from "../../fragments/taxAccount.generated";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type GetTaxAccountQueryVariables = Types.Exact<{
  taxNumber: Types.Scalars["String"];
  state: Types.Scalars["String"];
  taxType: Types.Scalars["String"];
  year?: Types.InputMaybe<Types.Scalars["Int"]>;
  requestType: Types.Scalars["String"];
  valueDate?: Types.InputMaybe<Types.Scalars["String"]>;
  valueDateOption?: Types.InputMaybe<Types.Scalars["String"]>;
}>;

export type GetTaxAccountQuery = {
  __typename?: "Query";
  getTaxAccount: {
    __typename?: "TaxAccount";
    kontoabfrageInput: Array<{
      __typename?: "KontoabfrageInput";
      kontoabfrageArt?: Array<string> | null;
      steuernummer?: Array<string> | null;
      zeitraum?: Array<string> | null;
      steuerart?: Array<{
        __typename?: "Steuerart";
        value?: string | null;
      }> | null;
    }>;
    kontoabfrageOutput: Array<{
      __typename?: "KontoabfrageOutput";
      abfrageTagesdatum?: Array<string> | null;
      abfrageSteuernummer?: Array<string> | null;
      abfrageErlaeuterung?: Array<string> | null;
      abfrageGesamtsumme?: Array<string> | null;
      abfrageSteuerart?: Array<{
        __typename?: "AbfrageSteuerart";
        steuerartKlartext?: Array<string> | null;
        steuerartGesamtbetrag?: Array<string> | null;
        steuerartErlaeuterung?: Array<string> | null;
        steuerartTeilbetrag?: Array<{
          __typename?: "SteuerartTeilbetrag";
          teilbetragZeitraum?: Array<string> | null;
          teilbetragFaelligkeit?: Array<string> | null;
          teilbetragWert?: Array<string> | null;
          teilbetragErlaeuterung?: Array<string> | null;
        }> | null;
      }> | null;
    }>;
  };
};

export const GetTaxAccountDocument = gql`
  query getTaxAccount(
    $taxNumber: String!
    $state: String!
    $taxType: String!
    $year: Int
    $requestType: String!
    $valueDate: String
    $valueDateOption: String
  ) {
    getTaxAccount(
      taxNumber: $taxNumber
      state: $state
      taxType: $taxType
      year: $year
      requestType: $requestType
      valueDate: $valueDate
      valueDateOption: $valueDateOption
    ) {
      ...TaxAccount
    }
  }
  ${TaxAccountFragmentDoc}
`;

/**
 * __useGetTaxAccountQuery__
 *
 * To run a query within a React component, call `useGetTaxAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaxAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaxAccountQuery({
 *   variables: {
 *      taxNumber: // value for 'taxNumber'
 *      state: // value for 'state'
 *      taxType: // value for 'taxType'
 *      year: // value for 'year'
 *      requestType: // value for 'requestType'
 *      valueDate: // value for 'valueDate'
 *      valueDateOption: // value for 'valueDateOption'
 *   },
 * });
 */
export function useGetTaxAccountQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTaxAccountQuery,
    GetTaxAccountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTaxAccountQuery, GetTaxAccountQueryVariables>(
    GetTaxAccountDocument,
    options
  );
}
export function useGetTaxAccountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTaxAccountQuery,
    GetTaxAccountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTaxAccountQuery, GetTaxAccountQueryVariables>(
    GetTaxAccountDocument,
    options
  );
}
export type GetTaxAccountQueryHookResult = ReturnType<
  typeof useGetTaxAccountQuery
>;
export type GetTaxAccountLazyQueryHookResult = ReturnType<
  typeof useGetTaxAccountLazyQuery
>;
export type GetTaxAccountQueryResult = Apollo.QueryResult<
  GetTaxAccountQuery,
  GetTaxAccountQueryVariables
>;
