import styled from "styled-components";
import { Button } from "antd";

export const DownloadAllButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const DownloadAllButton = styled(Button)`
  display: flex;
  margin-top: 10px;
  align-items: center;
`;
