import { gql, QueryHookOptions, useLazyQuery } from "@apollo/client";

import { Transaction } from "../../../types/transactions";
import { DOCUMENT_MATCH_RESULT_FIELDS } from "./fragments";

interface TransactionsSearchVariables {
  email: string;
  filter: any;
}

interface TransactionsConnectionEdge {
  node: Transaction;
}

export interface TransactionsSearchResult {
  transactions: {
    edges: TransactionsConnectionEdge[];
  };
}

type SearchFilter = {
  assets_exist?: boolean;
  valutaDate_gte?: string;
  valutaDate_lte?: string;
};

enum BaseOperator {
  And = "AND",
  Or = "OR",
}

type AmountSearchFilter = {
  amount_in: number[];
};

export type TransactionFilter = {
  assets_exist?: boolean;
  operator: string;
  valutaDate_gte?: string;
  valutaDate_lte?: string;
  conditions: {
    operator: string;
    name_likeAny: string[];
    purpose_likeAny: string[];
    amount_in?: number[];
  };
};

const MAX_SEARCH_QUERY_LENGTH = 200;
const MAX_SEARCH_AMOUNT_IN_CENTS = 2000000000;

const parseAmountSearchTerm = (amountTerm: string): AmountSearchFilter => {
  const amountInCents = Math.round(
    parseFloat(amountTerm.replace(",", ".")) * 100
  );

  if (amountInCents > MAX_SEARCH_AMOUNT_IN_CENTS) {
    return { amount_in: [] };
  }

  const invertedAmountInCents = amountInCents * -1;
  return {
    amount_in: [amountInCents, invertedAmountInCents],
  };
};

export const parseSearchQuery = (
  searchQuery: string,
  searchFilter: SearchFilter
): TransactionFilter => {
  const searchTerms = searchQuery
    .slice(0, MAX_SEARCH_QUERY_LENGTH)
    .split(" ")
    .filter((term) => term.length > 0);

  const filter: TransactionFilter = {
    operator: BaseOperator.And,
    ...searchFilter,
    conditions: {
      operator: BaseOperator.Or,
      name_likeAny: searchTerms,
      purpose_likeAny: searchTerms,
    },
  };

  const amountRegex = /^-?\d+([,.]\d{1,2})?$/;
  const amountFilter = searchTerms
    .filter((term) => amountRegex.test(term))
    .reduce(
      (
        partialAmountFilter: AmountSearchFilter,
        term: string
      ): AmountSearchFilter => {
        const parsedAmountSearchTerm = parseAmountSearchTerm(term);

        return {
          amount_in: [
            ...partialAmountFilter.amount_in,
            ...parsedAmountSearchTerm.amount_in,
          ],
        };
      },
      { amount_in: [] }
    );

  if (amountFilter.amount_in.length > 0) {
    filter.conditions.amount_in = amountFilter.amount_in;
  }

  return filter;
};

export const TRANSACTIONS_SEARCH_QUERY = gql`
  ${DOCUMENT_MATCH_RESULT_FIELDS}
  query transactions($email: String!, $filter: TransactionFilter!) {
    transactions(email: $email, filter: $filter) {
      edges {
        node {
          ...DocumentMatchResultFields
        }
      }
    }
  }
`;

export const useTransactionsSearchQuery = (
  options?: QueryHookOptions<
    TransactionsSearchResult,
    TransactionsSearchVariables
  >
) =>
  useLazyQuery<TransactionsSearchResult, TransactionsSearchVariables>(
    TRANSACTIONS_SEARCH_QUERY,
    options
  );
