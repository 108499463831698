import React, { useEffect, useState } from "react";
import { Button, notification, Tag } from "antd";
import moment, { Moment } from "moment";
import { EllipsisOutlined } from "@ant-design/icons";

import { SectionTitle } from "../../../../common/styledComponents";
import CustomerAttribute from "../../../../common/CustomerAttribute";
import EditableCustomerAttribute, {
  EditType,
} from "../../../../common/EditableCustomerAttribute";
import DependentsTable from "../../Common/UserDetails/DependentTable/DependentsTable";
import {
  IUser,
  IUserDetails,
  KontaxUserStatus,
  YesNoEnum,
  KontaxUserStatusAdditionalParams,
  PaymentFrequency,
  OnbStatus,
} from "../../../../../types";
import {
  ButtonsWrapper,
  HorizontalLine,
} from "../../Common/UserDetails/styledComponents";
import {
  CustomerAttributes,
  CustomerInformationSection,
  CustomerInformationSectionTitle,
  CustomerInformationCard,
  CustomerInformationSectionTitleWrapper,
} from "../../../../common/styledComponents";
import {
  formatDayMonthYear,
  formatCashTransactionsPercentage,
  formatInternationalCustomers,
  formatThreeStateAnswerToString,
  formatTimestamp,
  formatMonthYear,
  formatOnbStatus,
} from "../../Common/UserDetails/utils";
import CustomerStatus from "../../Common/UserDetails/CustomerStatus";
import { DateInputType } from "../../../../common/TransactionFilters/DateInput";
import {
  formatAmountInCents,
  formatBooleanToString,
  getColorByOnboardingStatus,
} from "../../../../../utils";
import UserTaxNumbersList from "../../Common/UserDetails/TaxNumberList/TaxNumbersList";
import UserTaxInformationBlock from "../../../../common/UserTaxInformationBlock";
import UserBusinessInformationBlock from "../../Common/UserDetails/BusinessInformation";
import { ChangeKontaxStatusButton } from "../../Common/UserDetails/ChangeKontaxStatusButton";
import api from "../../../../../api";
import { UpsertAccountingSourcePayload } from "../../../../../types/AccountingSource.type";
import UserInfoDrawer, {
  UserInfoDrawerSources,
} from "../../../../common/UserInfoExcerpt/UserInfoDrawer";
import { useGetNotesQuery } from "../../../../../api/graphql/queries/notes";
import { getUserFullName } from "../../../../../utils/getUserFullName";
import { SupportedLanguage } from "../../../../../api/graphql/schema.generated";
import ActionLogDrawerAsync from "../../../../common/ActionLogDrawerAsync";
import PaymentSection from "../../OnboardingView/UserDetails/PaymentSection";
import { TAX_ADVISORY_PERMISSION_SCOPES } from "../../../../../constants";
import { getPermissionScope } from "../../../../../gapi";

export function CustomerInformation({
  user,
  editUser,
  upsertAccountingSource,
  removeAccountingSource,
  updateUserStatus,
  updateDependents,
  deleteDependent,
}: {
  user: IUser;
  editUser: (email: string, userDetails: IUserDetails) => Promise<void>;
  removeAccountingSource: (email: string, year: number) => Promise<void>;
  upsertAccountingSource: (
    email: string,
    payload: UpsertAccountingSourcePayload
  ) => Promise<void>;
  updateUserStatus: (
    email: string,
    status: KontaxUserStatus | null,
    additionalParams?: KontaxUserStatusAdditionalParams
  ) => Promise<void>;
  updateDependents: Function;
  deleteDependent: (email: string, id: string) => Promise<void>;
}) {
  const [isUserInfoDrawerVisible, setIsUserInfoDrawerVisible] = useState(false);

  const hasTaxAdvisoryPermissionScope = TAX_ADVISORY_PERMISSION_SCOPES.includes(
    getPermissionScope()
  );

  const getUserPOATemporaryUrl = async () => {
    const poaUrl = await api.kontax.getUserPOATemporaryUrl(user.email);

    if (!!poaUrl) {
      window.open(poaUrl, "_blank");
    }
  };

  const { data: notes, error: getNotesError } = useGetNotesQuery({
    variables: { email: user.email },
    skip: !hasTaxAdvisoryPermissionScope,
  });

  useEffect(() => {
    if (getNotesError) {
      notification.error({
        message: `An error occurred while fetching the notes for the user ${user.email}`,
      });
    }
  }, [getNotesError, user.email]);

  const onSaveFibuStartDateHandler = (fibuStartDate: string) => {
    editUser(user.email, {
      fibuStartDate,
    });
  };

  const onSaveFibuEndDateHandler = (lastDeclarationDate: Date | null) => {
    const lastDeclarationDueDate =
      user.vatPaymentFrequency === PaymentFrequency.QUARTERLY
        ? moment(lastDeclarationDate).endOf("quarter").toDate()
        : lastDeclarationDate;
    editUser(user.email, {
      kontaxUser: {
        lastDeclarationDueDate: lastDeclarationDueDate,
      },
    });
  };

  const onSaveKontaxOnbStatusHandler = (onbStatus: OnbStatus) =>
    editUser(user.email, {
      kontaxUser: {
        onbStatus: onbStatus === OnbStatus.NOT_SET ? null : onbStatus,
      },
    });

  const onSaveTaxDeclarationStartDateHandler = async (
    firstTaxDeclarationDate: Moment | null
  ) => {
    const year =
      firstTaxDeclarationDate === null ? null : firstTaxDeclarationDate.year();
    await editUser(user.email, {
      firstTaxDeclarationYear: year,
    });
  };

  const onSaveTaxDeclarationEndDateHandler = async (
    lastTaxDeclarationDate: Moment | null
  ) => {
    const year =
      lastTaxDeclarationDate === null ? null : lastTaxDeclarationDate.year();
    await editUser(user.email, {
      lastTaxDeclarationYear: year,
    });
  };

  const onSaveKontaxBillingStartDateHandler = (
    kontaxBillingStartDate: string
  ) =>
    editUser(user.email, {
      kontaxBillingStartDate,
    });

  return (
    <>
      <CustomerInformationSection data-test="customerDetails">
        <UserInfoDrawer
          visible={isUserInfoDrawerVisible}
          onClose={() => setIsUserInfoDrawerVisible(false)}
          user={user}
          notes={notes?.getNotes || []}
          source={UserInfoDrawerSources.ACCOUNTING}
        />
        <CustomerInformationSectionTitleWrapper>
          <CustomerInformationSectionTitle>
            Customer Information
          </CustomerInformationSectionTitle>
          <Button
            type="default"
            onClick={() => setIsUserInfoDrawerVisible(true)}
            icon={<EllipsisOutlined />}
          >
            More info
          </Button>
        </CustomerInformationSectionTitleWrapper>
        <CustomerInformationCard>
          <CustomerAttributes>
            <CustomerAttribute label="Name" withFixedIcon isCopyable>
              {getUserFullName(user)}
            </CustomerAttribute>
            <CustomerAttribute
              label="Status"
              changeLog={
                <ActionLogDrawerAsync
                  title="Action log"
                  modelName="user"
                  recordIds={[`${user.id}`]}
                  small
                  selectedFields={["kontaxStatus"]}
                />
              }
            >
              <CustomerStatus status={user.kontaxStatus} />
            </CustomerAttribute>
            <SectionTitle>
              <EditableCustomerAttribute
                label="ONB Status"
                dataTest="onbStatus"
                editType={EditType.Select}
                formatFunction={formatOnbStatus}
                options={Object.values(OnbStatus)}
                initialValue={user.kontaxUser?.onbStatus || OnbStatus.NOT_SET}
                onSaveHandler={onSaveKontaxOnbStatusHandler}
                renderAttributeContent={(value: OnbStatus) => {
                  return (
                    <Tag color={getColorByOnboardingStatus(value)}>
                      {formatOnbStatus(value).toUpperCase()}
                    </Tag>
                  );
                }}
                inputRequired
                allowClear
                changeLog={
                  <ActionLogDrawerAsync
                    title="Action log"
                    modelName="kontax_user"
                    recordIds={[user.id.toString()]}
                    small
                  />
                }
                isReadOnly={!hasTaxAdvisoryPermissionScope}
              />
            </SectionTitle>
            <CustomerAttribute
              label="Account ID"
              dataTest="accountId"
              isCopyable
              withFixedIcon
            >
              {user.accountId}
            </CustomerAttribute>
            <CustomerAttribute label="POA Stammdaten completed">
              {formatBooleanToString(user.poaCoreDataCompleted)}
            </CustomerAttribute>
            <CustomerAttribute
              label="POA exported at"
              onInspect={user.poaExportedAt && getUserPOATemporaryUrl}
            >
              {formatTimestamp(user.poaExportedAt)}
            </CustomerAttribute>
            <EditableCustomerAttribute
              label="FiBu onboarding completed"
              dataTest="FiBuOnboardingCompleted"
              initialValue={formatBooleanToString(
                !!user.taxServiceOnboardingCompletedAt
              )}
              editType={EditType.Select}
              options={[...Object.values(YesNoEnum)]}
              onSaveHandler={async (value: YesNoEnum) => {
                const taxServiceOnboardingCompleted = value === YesNoEnum.YES;

                await editUser(user.email, {
                  taxServiceOnboardingCompleted,
                });
              }}
              isReadOnly={!hasTaxAdvisoryPermissionScope}
            />
            <EditableCustomerAttribute
              label="FIBU start date"
              initialValue={user.fibuStartDate}
              formatFunction={formatMonthYear}
              dataTest="fibuStartDate"
              editType={DateInputType.Month}
              inputRequired
              onSaveHandler={onSaveFibuStartDateHandler}
              allowClear
              isReadOnly={!hasTaxAdvisoryPermissionScope}
            />
            <EditableCustomerAttribute
              label="FIBU end date"
              initialValue={user.kontaxUser?.lastDeclarationDueDate}
              formatFunction={formatMonthYear}
              editType={EditType.PeriodPicker}
              allowClear
              onSaveHandler={onSaveFibuEndDateHandler}
              vatPaymentFrequency={user.vatPaymentFrequency as PaymentFrequency}
              isReadOnly={!hasTaxAdvisoryPermissionScope}
            />
            <EditableCustomerAttribute
              label="Erste Steuererklärung"
              dataTest="ersteSteuer"
              initialValue={user.firstTaxDeclarationYear?.toString()}
              editType={DateInputType.Year}
              allowClear
              onSaveHandler={onSaveTaxDeclarationStartDateHandler}
              changeLog={
                <ActionLogDrawerAsync
                  title="Action log"
                  modelName="user"
                  recordIds={[`${user.id}`]}
                  selectedFields={["firstTaxDeclarationYear"]}
                  small
                />
              }
              isReadOnly={!hasTaxAdvisoryPermissionScope}
            />
            <EditableCustomerAttribute
              label="Letzte Steuererklärung"
              initialValue={user.lastTaxDeclarationYear?.toString()}
              editType={DateInputType.Year}
              allowClear
              onSaveHandler={onSaveTaxDeclarationEndDateHandler}
              changeLog={
                <ActionLogDrawerAsync
                  title="Action log"
                  modelName="user"
                  recordIds={[`${user.id}`]}
                  selectedFields={["lastTaxDeclarationYear"]}
                  small
                />
              }
              isReadOnly={!hasTaxAdvisoryPermissionScope}
            />
          </CustomerAttributes>
        </CustomerInformationCard>

        <HorizontalLine />

        <UserTaxNumbersList user={user} />
        <HorizontalLine />

        <UserTaxInformationBlock
          user={user}
          editUser={editUser}
          removeAccountingSource={removeAccountingSource}
          upsertAccountingSource={upsertAccountingSource}
        />
        <HorizontalLine />

        <CustomerInformationSectionTitle>
          Personal Information
        </CustomerInformationSectionTitle>
        <CustomerInformationCard>
          <CustomerAttributes>
            <CustomerAttribute label="Phone number" isCopyable withFixedIcon>
              {user.mobileNumber || user.untrustedPhoneNumber}
            </CustomerAttribute>
            <CustomerAttribute label="Email address" isCopyable withFixedIcon>
              {user.email}
            </CustomerAttribute>
            <CustomerAttribute label="Language">
              {user.language}
            </CustomerAttribute>
            <CustomerAttribute label="Geburtstag" withFixedIcon isCopyable>
              {formatDayMonthYear(user.birthDate, SupportedLanguage.DE)}
            </CustomerAttribute>
            <div>{/* Shifting */}</div>
            <CustomerAttribute
              label="Address"
              changeLog={
                <ActionLogDrawerAsync
                  title="Action log"
                  modelName="user"
                  recordIds={[`${user.id}`]}
                />
              }
            >
              {user.street}, {user.postCode} {user.city}
            </CustomerAttribute>
            <CustomerAttribute
              label="Steuerliche Identifikationsnummer"
              isCopyable
              withFixedIcon
            >
              {user.deTaxId}
            </CustomerAttribute>
            <CustomerAttribute label="IBAN" isCopyable withFixedIcon>
              {user.accountIban}
            </CustomerAttribute>
            <CustomerAttribute label="Balance">
              {formatAmountInCents(user.balance, true)}
            </CustomerAttribute>
            {/* <AccountStats user={user} /> */}
            <CustomerAttribute label="Locking status">
              {user.lockingStatus}
            </CustomerAttribute>
            <CustomerAttribute label="Locking reasons">
              {user.lockingReasons?.join(", ") || null}
            </CustomerAttribute>
          </CustomerAttributes>
        </CustomerInformationCard>
        <HorizontalLine />

        <UserBusinessInformationBlock user={user} editUser={editUser} />
        <HorizontalLine />

        <CustomerInformationSectionTitle>
          User's Dependents
        </CustomerInformationSectionTitle>
        <DependentsTable
          email={user.email}
          dependents={user.dependents || []}
          updateDependents={updateDependents}
          deleteDependent={deleteDependent}
        />
        <HorizontalLine />

        <CustomerInformationSectionTitle>
          Self-onboarding Information
        </CustomerInformationSectionTitle>
        <CustomerInformationCard>
          <CustomerAttributes>
            <CustomerAttribute label="Is subject to accounting">
              {formatThreeStateAnswerToString(user.subjectToAccounting)}
            </CustomerAttribute>
            <CustomerAttribute label="Works in e-commerce">
              {formatBooleanToString(user.workingInEcommerce)}
            </CustomerAttribute>
            <CustomerAttribute label="Profession">
              {user.profession}
            </CustomerAttribute>
            <CustomerAttribute label="Accounting tool">
              {user.accountingTool}
            </CustomerAttribute>
            <CustomerAttribute label="Uses a second business account">
              {formatBooleanToString(user.hasSecondBusinessAccount)}
            </CustomerAttribute>
            <CustomerAttribute label="Vat rate">
              {user.vatRate}
            </CustomerAttribute>
            <CustomerAttribute label="Cash transactions">
              {formatCashTransactionsPercentage(
                user.maximumCashTransactionsPercentage
              )}
            </CustomerAttribute>
            <CustomerAttribute label="Company type">
              {user.companyType}
            </CustomerAttribute>
            <CustomerAttribute label="International customers">
              {formatInternationalCustomers(user.internationalCustomers)}
            </CustomerAttribute>
            <CustomerAttribute label="Handyman">
              {formatBooleanToString(user.workAsHandyman)}
            </CustomerAttribute>
            <CustomerAttribute label="Terms and conditions accepted at">
              {formatTimestamp(user.taxAdvisoryTermsVersionAcceptedAt)}
            </CustomerAttribute>
            <CustomerAttribute label="Onboarding started at">
              {formatTimestamp(user.taxServiceOnboardingStartedAt)}
            </CustomerAttribute>
          </CustomerAttributes>
        </CustomerInformationCard>

        <HorizontalLine />

        <PaymentSection
          user={user}
          onSaveKontaxBillingStartDateHandler={
            onSaveKontaxBillingStartDateHandler
          }
          editUserHandler={editUser}
        />

        {hasTaxAdvisoryPermissionScope && (
          <ButtonsWrapper>
            <ChangeKontaxStatusButton
              kontaxStatus={user.kontaxStatus}
              email={user.email}
              updateUserStatus={updateUserStatus}
            />
          </ButtonsWrapper>
        )}
      </CustomerInformationSection>
    </>
  );
}
