import styled from "styled-components";
import { FileTextOutlined } from "@ant-design/icons";

import colors from "../../../../themes/colors";

export const MessageWrapper = styled.div`
  padding-top: 30px;
`;

export const TransactionsListViewWrapper = styled.div`
  width: 100%;
`;

export const IbanWrapper = styled.div`
  word-break: break-all;
  min-width: 60px;
`;

export const AntIconTag = styled.div<{ inactive?: boolean }>`
  cursor: pointer;
  padding-left: 10px;
  ${({ inactive }) => inactive && "opacity: 0.5; cursor: not-allowed"}
`;

export const InvoiceViewWrapper = styled.div`
  display: flex;
  min-height: 400px;
  width: 100%;
  flex: 1.5;

  > span {
    width: 100%;
  }
`;

export const SplitAndInvoiceWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 24px;
`;

export const ExternalTransactionModalContent = styled.div`
  display: grid;
  grid-template-columns: 450px 1fr;
  grid-template-rows: repeat(1, 1fr);
  gap: 0 24px;
  justify-items: stretch;
  align-items: center;
  grid-auto-flow: column;

  .assets {
    grid-column: 1 / span 1;
    grid-row: 1 / span 4;
    align-self: stretch;

    .assetContainer {
      min-height: 300px;
    }
  }

  .ant-input-number-group-wrapper,
  .ant-picker {
    width: 100%;
  }

  .anticon {
    display: flex;
  }
`;

export const CheckboxCell = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
`;

export const VerifiedSuccessWrapper = styled.div`
  display: flex;
`;

export const RobotWrapper = styled.div`
  align-items: center;
`;

export const CheckmarkWrapper = styled.div`
  svg {
    display: inline;
    vertical-align: baseline;
  }
`;

export const TableWrapper = styled.div`
  .ant-table-selection-col {
    width: 32px;
  }
  & .ant-table-expanded-row .ant-table-cell {
    padding: 0 !important;
  }
  .centerIcon {
    svg {
      display: inline;
      vertical-align: baseline;
    }
  }
  .ant-table-sticky-holder {
    position: sticky;
    top: 78px !important;
    z-index: 5;
  }
`;

export const InvoiceIcon = styled(FileTextOutlined)`
  cursor: pointer;
  opacity: 0.45;
  :hover {
    opacity: 1;
    color: ${colors.primaryPurple};
  }
`;
