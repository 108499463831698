import React, { FormEvent, useCallback, useEffect, useState } from "react";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import isNumber from "lodash/isNumber";
import moment from "moment";

import TextArea from "../../../../../common/TextArea";
import {
  Button,
  Form,
  InputWrapper,
  Label,
} from "../../../../../common/styledComponents";
import {
  CustomDateInput,
  CustomBusinessAssetTypeSelect,
  CustomBusinessAssetClassSelect,
  CustomCurrencyInput,
} from "./styledComponents";
import {
  DEPRECIABLE_CATEGORY_CODES,
  LATEST_POSSIBLE_PURCHASE_DATE,
} from "../../../../../BusinessAsset/constants";
import movableAssets from "../../../../../BusinessAsset/movableAssets.json";
import immovableAssets, {
  LAND_ASSET_CLASS,
} from "../../../../../BusinessAsset/immovableAssets";
import {
  isImmovableAsset,
  isIntangibleAsset,
  isCustomAssetClass,
  isNoteRequired,
} from "../../../../../BusinessAsset/utils";
import {
  Asset,
  BusinessAssetType,
  DepreciableCategory,
  ICreateBusinessAssetPayload,
  ILocalAsset,
} from "../../../../../../types";
import CategorySelect from "../../../../TransactionView/CategorySelect";
import LocalAssetsViewer from "../../../../../common/LocalAssetsViewer";
import Upload from "../../../../../common/Upload";
import {
  AssetClassCustomField,
  DepreciationYearsField,
  DepreciationYearsEditableField,
} from "../../../../../BusinessAsset/BusinessAssetFields";
import Toggle from "../../../../../common/Toggle";
import {
  CURRENT_YEAR,
  getUTCYear,
  validateEndAmountValues,
  getNewEndAmounts,
} from "./utils";
import { FormError } from "./styledComponents";
import {
  BUSINESS_ASSET_CATEGORIES,
  centsToEuros,
  eurosToCents,
} from "../../../../../../utils";

const BusinessAssetCreateForm = ({
  onCreate,
  onCancel,
}: {
  onCreate: Function;
  onCancel: () => void;
}) => {
  const [isSubmitClicked, setIsSubmitClicked] = useState<boolean>(false);

  const [category, setCategory] = useState<string | null>(null);
  const [amount, setAmount] = useState<number>();
  const [purchaseDate, setPurchaseDate] = useState<string>(
    LATEST_POSSIBLE_PURCHASE_DATE
  );
  const [assetType, setAssetType] = useState<BusinessAssetType>(
    BusinessAssetType.MOVABLE_OTHERS
  );
  const [assetClass, setAssetClass] = useState<string>("");
  const [assetClassCustom, setAssetClassCustom] = useState<string>("");
  const [depreciationYears, setDepreciationYears] = useState<string>("");
  const [automaticEndAmounts, setAutomaticEndAmounts] =
    useState<boolean>(false);
  const [formError, setFormError] = useState<string>("");
  const [
    isPartialAutomaticEndAmountsEnabled,
    setIsPartialAutomaticEndAmountsEnabled,
  ] = useState<boolean>(false);
  const [endAmounts, setEndAmounts] = useState<Array<number>>([]);
  const [depreciationYearsEditable, setDepreciationYearsEditable] =
    useState<boolean>(false);
  const [note, setNote] = useState<string>("");
  const [assets, setAssets] = useState<ILocalAsset[]>([]);

  const isAssetTypeImmovable = isImmovableAsset(assetType);
  const isAssetTypeIntangible = isIntangibleAsset(assetType);
  const isAssetClassCustom = isCustomAssetClass(assetClass);

  const isAssetValid = useCallback((): boolean => {
    if (!category) {
      return false;
    }

    if (!amount || amount <= 0) {
      return false;
    }

    if (
      !purchaseDate ||
      moment(purchaseDate).isAfter(moment(LATEST_POSSIBLE_PURCHASE_DATE))
    ) {
      return false;
    }

    if (!assetClassCustom && !assetClass) {
      return false;
    }

    if (isNoteRequired(assetType, assetClass) && !note) {
      return false;
    }

    return !(!depreciationYears && assetClass !== LAND_ASSET_CLASS);
  }, [
    category,
    amount,
    purchaseDate,
    assetType,
    assetClass,
    assetClassCustom,
    note,
    depreciationYears,
  ]);

  // Accept "endAmounts" argument. If the argument is undefined, we get "endAmounts" from state.
  const updateEndAmounts = (endAmountsArg = endAmounts) => {
    // If any of the required fields is empty or 0 we clear the list.
    let newEndAmounts: Array<number> | null = [];

    if (depreciationYears && purchaseDate && amount) {
      newEndAmounts = getNewEndAmounts({
        // Convert amounts to cents as the depreciation calculation expects it.
        amount: eurosToCents(amount),
        endAmounts: endAmountsArg.map(eurosToCents),
        depreciationYears,
        purchaseDate,
        automaticEndAmounts,
        isPartialAutomaticEndAmountsEnabled,
      });
    }

    // To prevent unnecessary re-renders, we only update the endAmounts when they have changed.
    if (newEndAmounts && !isEqual(endAmounts, newEndAmounts)) {
      setEndAmounts(newEndAmounts.map(centsToEuros));
    }
  };

  // Updates the endAmounts when related fields change.
  // Note: we intentionally ignore the listener on endAmounts' updates. It will be handled differently via "getEndAmountsChangeHandler".
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateEndAmountsWhenFieldsChanged = useCallback(updateEndAmounts, [
    depreciationYears,
    purchaseDate,
    automaticEndAmounts,
    isPartialAutomaticEndAmountsEnabled,
    amount,
  ]);

  useEffect(() => {
    setFormError("");
    updateEndAmountsWhenFieldsChanged();
  }, [updateEndAmountsWhenFieldsChanged]);

  const shouldRenderEditableDepreciationYearsField =
    isAssetTypeImmovable || isAssetTypeIntangible || isAssetClassCustom;

  const handleCategoryChange = (category: string | null) => {
    setCategory(category);

    if (category && DEPRECIABLE_CATEGORY_CODES.includes(category)) {
      setDepreciationYearsEditable(category === DepreciableCategory.OVER_800);

      // Set default business asset values on every category change
      setAssetType(BusinessAssetType.MOVABLE_OTHERS);
      setAssetClass("");
      setAssetClassCustom("");
      setPurchaseDate(LATEST_POSSIBLE_PURCHASE_DATE);

      // if category is OVER_250, asset depreciates immediately, hence 0
      const depreciationYearsValue =
        category === DepreciableCategory.OVER_250 ? "0" : "";
      setDepreciationYears(depreciationYearsValue);
    }
  };

  const handlePurchaseDateChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPurchaseDate(event.target.value);
  };

  const handleAssetTypeChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setAssetType(event.target.value as BusinessAssetType);
    setAssetClass("");
    setAssetClassCustom("");
    if (depreciationYearsEditable) {
      setDepreciationYears("");
    }
  };

  const handleAssetClassChange = (value: string) => {
    setAssetClassCustom("");
    setAssetClass(value);

    const assets = isAssetTypeImmovable ? immovableAssets : movableAssets;
    const selectedAssetClass = assets.find(
      (asset) => asset.assetClass === value
    );

    if (selectedAssetClass && depreciationYearsEditable) {
      const selectedAssetDepreciationYears =
        selectedAssetClass.depreciationYears;
      const depreciationYearsValue = isNumber(selectedAssetDepreciationYears)
        ? selectedAssetDepreciationYears.toString()
        : "";
      setDepreciationYears(depreciationYearsValue);
    }
  };

  const handleAssetClassCustomChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAssetClassCustom(event.target.value);
  };

  const handleDepreciationYearsChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDepreciationYears(event.target.value);
  };

  const handleNoteChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNote(event.target.value);
  };

  /**
   * Parses the value to check for changes, if any it updates endAmounts field by cloning it
   * and updating the value on the provided index.
   */
  const getEndAmountsChangeHandler =
    (index: number) => (newEndAmount: number) => {
      const newEndAmounts = Array.from(endAmounts);
      newEndAmounts[index] = newEndAmount;
      setEndAmounts(newEndAmounts);
      updateEndAmounts(newEndAmounts);
    };

  const handleSubmit = async (event: FormEvent<HTMLElement>) => {
    event.preventDefault();

    setIsSubmitClicked(true);

    if (!isAssetValid()) {
      return;
    }

    // Map amounts into cents as they are expected in the BE.
    const amountInCents = eurosToCents(amount!);
    const endAmountsInCents = endAmounts.map(eurosToCents);

    if (assetClass !== LAND_ASSET_CLASS) {
      const endAmountsValidation = validateEndAmountValues({
        endAmounts: endAmountsInCents,
        purchaseAmount: amountInCents,
        depreciationYears,
      });

      if (!endAmountsValidation.valid) {
        endAmountsValidation.error && setFormError(endAmountsValidation.error);
        return;
      }
    }

    // End amounts are set when depreciationPeriodYears is > 0
    const endAmountsPayload = isEmpty(endAmountsInCents)
      ? {}
      : { endAmounts: endAmountsInCents };

    const businessAsset: ICreateBusinessAssetPayload = {
      amount: amountInCents,
      ...endAmountsPayload,
      categoryCode: category || "",
      assetType,
      assetClass: assetClassCustom || assetClass,
      purchaseDate,
      depreciationPeriodYears: depreciationYears
        ? Number(depreciationYears)
        : null,
      note,
      assets,
    };

    await onCreate(businessAsset);
  };

  const handleCancel = () => {
    onCancel();
  };

  const dropFiles = (files: File[]) => {
    const startIndex = assets.length;

    const newAssets = files.map((file: File, index: number) => ({
      // Make file name unique, for looking up later.
      name: `${file.name}-${startIndex + index}`,
      filetype: file.type.split("/").pop() as string,
      file,
    }));

    setAssets([...assets, ...newAssets]);
  };

  const handleAssetDelete = (deletedAsset: Asset | ILocalAsset) => {
    setAssets(
      assets.filter((asset) => asset.name !== (deletedAsset as any).name)
    );
  };

  const shouldDisplayPartialAutomaticEndAmountsToggle = ({
    isLastEndAmountField,
    year,
  }: {
    isLastEndAmountField: boolean;
    year: number;
  }) => {
    const purchaseYear = getUTCYear(purchaseDate);

    return (
      // If full automatic calculation is ON then partial calculation is ignored.
      !automaticEndAmounts &&
      // If it's the last end amount, no automatic calculation is required, since it should always be 0.
      !isLastEndAmountField &&
      // Partial automatic calculations can only happen when the item was bought on past years.
      purchaseYear < CURRENT_YEAR &&
      // We only do the auto-calculation for present and future years.
      year === CURRENT_YEAR
    );
  };

  /**
   * Gets a toggle to allow the user to automatically calculate endAmounts and the endAmount fields (amount of fields
   * depends on the size of endAmounts, which depends on depreciationYears). Info will be requested (shown) when;
   * - asset is valid
   * - depreciationYears > 0
   */
  const getEndAmountsFields = () => {
    if (!isAssetValid() || Number(depreciationYears) === 0) {
      return null;
    }

    const firstYear = getUTCYear(purchaseDate);
    return (
      <>
        <Toggle
          value={automaticEndAmounts}
          onChange={setAutomaticEndAmounts}
          label="Automatisch berechnen"
          text="Vergewissere dich, dass die berechneten Werte tatsächlich mit den bisherigen Buchwerten übereinstimmen."
        />
        {endAmounts.map((val, index) => {
          const year = firstYear + index;
          const isLastEndAmountField = index === endAmounts.length - 1;
          return (
            <React.Fragment key={year}>
              {shouldDisplayPartialAutomaticEndAmountsToggle({
                isLastEndAmountField,
                year,
              }) && (
                <Toggle
                  value={isPartialAutomaticEndAmountsEnabled}
                  onChange={setIsPartialAutomaticEndAmountsEnabled}
                  label="Abschreibung für offene Jahre automatisch berechnen"
                />
              )}

              <InputWrapper>
                <Label
                  htmlFor={`endAmount-${year}`}
                >{`Buchwert Ende des Jahres ${year}`}</Label>
                <CustomCurrencyInput
                  id={`endAmount-${year}`}
                  value={val}
                  onChange={getEndAmountsChangeHandler(index)}
                  invalid={isSubmitClicked && isEmpty(val)}
                  // - When automatic end amounts is enabled, all fields are populated and disabled.
                  // - When partial Automatic End Amounts is enabled all fields for years greater or equal
                  //   than the current year are disabled and populated automatically.
                  // - Last end amount is always disabled because its value will always be 0.
                  disabled={
                    automaticEndAmounts ||
                    (isPartialAutomaticEndAmountsEnabled &&
                      year >= CURRENT_YEAR) ||
                    isLastEndAmountField
                  }
                />
              </InputWrapper>
            </React.Fragment>
          );
        })}
      </>
    );
  };

  return (
    <Form onSubmit={handleSubmit}>
      <InputWrapper data-test="assetCategory">
        <Label htmlFor="category">Kategorie</Label>
        <CategorySelect
          value={category}
          onChangeHandler={handleCategoryChange}
          // <CategorySelect> only cares about the sign (+/-).
          transactionAmount={-1}
          focused
          invalid={isSubmitClicked && !category}
          options={BUSINESS_ASSET_CATEGORIES}
        />
      </InputWrapper>

      <InputWrapper data-test="netPurchaseValue">
        <Label htmlFor="amount">Anschaffungswert (netto)</Label>
        <CustomCurrencyInput
          id="amount"
          value={amount}
          onChange={setAmount}
          invalid={isSubmitClicked && isEmpty(amount)}
        />
      </InputWrapper>

      <InputWrapper data-test="purchaseDate">
        <Label htmlFor="purchaseDate">Anschaffungsdatum</Label>
        <div>
          <CustomDateInput
            required
            name="purchaseDate"
            value={purchaseDate}
            max={LATEST_POSSIBLE_PURCHASE_DATE}
            onChange={handlePurchaseDateChange}
            withoutMargin
          />
        </div>
      </InputWrapper>

      <InputWrapper>
        <Label htmlFor="assetType">AfA-Klasse</Label>
        <CustomBusinessAssetTypeSelect
          id="assetType"
          value={assetType}
          onChangeHandler={handleAssetTypeChange}
        />
      </InputWrapper>

      {!isAssetTypeIntangible && (
        <InputWrapper>
          <Label htmlFor="assetClass">Anlage-Typ</Label>
          <CustomBusinessAssetClassSelect
            id="assetClass"
            value={assetClass}
            options={isAssetTypeImmovable ? immovableAssets : movableAssets}
            invalid={isSubmitClicked && !assetClass}
            onChange={handleAssetClassChange}
          />
        </InputWrapper>
      )}

      {(isAssetTypeIntangible || isAssetClassCustom) && (
        <AssetClassCustomField
          id="assetClassCustom"
          value={assetClassCustom}
          label={isAssetTypeIntangible ? "Anlage-Typ" : "Beschreibung"}
          invalid={isSubmitClicked && !assetClassCustom}
          onChange={handleAssetClassCustomChange}
        />
      )}

      <TextArea
        id="note"
        label="Bezeichnung"
        value={note}
        placeholder="Genaue Beschreibung des Wirtschaftsguts (Marke und Modell)"
        disabled={false}
        onChange={handleNoteChange}
        rows={3}
        invalid={
          isSubmitClicked && isNoteRequired(assetType, assetClass) && !note
        }
      />

      {
        // land doesn't depreciate
        assetClass !== LAND_ASSET_CLASS && (
          <>
            {depreciationYearsEditable &&
            shouldRenderEditableDepreciationYearsField ? (
              <DepreciationYearsEditableField
                id="depreciationYearsEditable"
                value={depreciationYears}
                type="number"
                min={0}
                label="Abschreibungsdauer"
                textAfter="Jahre"
                invalid={isSubmitClicked && !depreciationYears}
                onChange={handleDepreciationYearsChange}
              />
            ) : (
              <DepreciationYearsField
                disabled
                className="no-background"
                id="depreciationYears"
                value={depreciationYears}
                label="Abschreibungsdauer"
                textAfter={depreciationYears && "Jahre"}
              />
            )}

            {getEndAmountsFields()}
          </>
        )
      }

      <InputWrapper className="mb-3 multiline">
        <Label htmlFor="upload">Beleg</Label>
        <Upload onDropFiles={dropFiles}>
          <LocalAssetsViewer assets={assets} onDelete={handleAssetDelete} />
        </Upload>
      </InputWrapper>

      {formError && <FormError>{formError}</FormError>}

      <Button data-test="addBusinessAsset" type="submit">
        Erstellen
      </Button>
      <Button className="secondary" onClick={handleCancel}>
        Abbrechen
      </Button>

      <div className="divider" />
    </Form>
  );
};

export default BusinessAssetCreateForm;
