import type * as Types from "../../schema.generated";

import { gql } from "@apollo/client";
import { NoteDataFragmentDoc } from "../../fragments/note.generated";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type TaxBoardCommentsQueryVariables = Types.Exact<{
  email: Types.Scalars["String"];
  year: Types.Scalars["Int"];
}>;

export type TaxBoardCommentsQuery = {
  __typename?: "Query";
  taxCase: {
    __typename?: "TaxBoardTaxCase";
    id: string;
    notes: Array<{
      __typename?: "KontaxNote";
      id: number;
      creator: string;
      updatedAt: string;
      createdAt: string;
      message: string;
    }>;
  };
  euerDeclaration: {
    __typename?: "EuerDeclaration";
    id: string;
    notes: Array<{
      __typename?: "KontaxNote";
      id: number;
      creator: string;
      updatedAt: string;
      createdAt: string;
      message: string;
    }>;
  };
  vatAnnualDeclaration: {
    __typename?: "VatAnnualDeclaration";
    id: string;
    notes: Array<{
      __typename?: "KontaxNote";
      id: number;
      creator: string;
      updatedAt: string;
      createdAt: string;
      message: string;
    }>;
  };
  tradeTaxDeclaration: {
    __typename?: "TradeTaxDeclaration";
    id: string;
    notes: Array<{
      __typename?: "KontaxNote";
      id: number;
      creator: string;
      updatedAt: string;
      createdAt: string;
      message: string;
    }>;
  };
  incomeTaxDeclaration: {
    __typename?: "IncomeTaxDeclaration";
    id: string;
    notes: Array<{
      __typename?: "KontaxNote";
      id: number;
      creator: string;
      updatedAt: string;
      createdAt: string;
      message: string;
    }>;
  };
  euerDeclarationSubforms: Array<{
    __typename?: "EuerDeclarationSubform";
    id: string;
    type: Types.EuerDeclarationSubformType;
    notes: Array<{
      __typename?: "KontaxNote";
      id: number;
      creator: string;
      updatedAt: string;
      createdAt: string;
      message: string;
    }>;
  }>;
};

export const TaxBoardCommentsDocument = gql`
  query taxBoardComments($email: String!, $year: Int!) {
    taxCase(email: $email, year: $year) {
      id
      notes {
        ...noteData
      }
    }
    euerDeclaration(email: $email, year: $year) {
      id
      notes {
        ...noteData
      }
    }
    vatAnnualDeclaration(email: $email, year: $year) {
      id
      notes {
        ...noteData
      }
    }
    tradeTaxDeclaration(email: $email, year: $year) {
      id
      notes {
        ...noteData
      }
    }
    incomeTaxDeclaration(email: $email, year: $year) {
      id
      notes {
        ...noteData
      }
    }
    euerDeclarationSubforms(email: $email, year: $year) {
      id
      type
      notes {
        ...noteData
      }
    }
  }
  ${NoteDataFragmentDoc}
`;

/**
 * __useTaxBoardCommentsQuery__
 *
 * To run a query within a React component, call `useTaxBoardCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaxBoardCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaxBoardCommentsQuery({
 *   variables: {
 *      email: // value for 'email'
 *      year: // value for 'year'
 *   },
 * });
 */
export function useTaxBoardCommentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    TaxBoardCommentsQuery,
    TaxBoardCommentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TaxBoardCommentsQuery, TaxBoardCommentsQueryVariables>(
    TaxBoardCommentsDocument,
    options
  );
}
export function useTaxBoardCommentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TaxBoardCommentsQuery,
    TaxBoardCommentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TaxBoardCommentsQuery,
    TaxBoardCommentsQueryVariables
  >(TaxBoardCommentsDocument, options);
}
export type TaxBoardCommentsQueryHookResult = ReturnType<
  typeof useTaxBoardCommentsQuery
>;
export type TaxBoardCommentsLazyQueryHookResult = ReturnType<
  typeof useTaxBoardCommentsLazyQuery
>;
export type TaxBoardCommentsQueryResult = Apollo.QueryResult<
  TaxBoardCommentsQuery,
  TaxBoardCommentsQueryVariables
>;
