import type * as Types from "../../schema.generated";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type CreateHomeOfficeDocumentAssetMutationVariables = Types.Exact<{
  homeOfficeDocumentId: Types.Scalars["ID"];
  name: Types.Scalars["String"];
  filetype: Types.Scalars["String"];
}>;

export type CreateHomeOfficeDocumentAssetMutation = {
  __typename?: "Mutation";
  createHomeOfficeDocumentAsset: {
    __typename?: "CreateAssetResponse";
    assetId: string;
    url: string;
    formData: Array<{
      __typename?: "FormDataPair";
      key: string;
      value: string;
    }>;
  };
};

export const CreateHomeOfficeDocumentAssetDocument = gql`
  mutation createHomeOfficeDocumentAsset(
    $homeOfficeDocumentId: ID!
    $name: String!
    $filetype: String!
  ) {
    createHomeOfficeDocumentAsset(
      homeOfficeDocumentId: $homeOfficeDocumentId
      name: $name
      filetype: $filetype
    ) {
      assetId
      url
      formData {
        key
        value
      }
    }
  }
`;
export type CreateHomeOfficeDocumentAssetMutationFn = Apollo.MutationFunction<
  CreateHomeOfficeDocumentAssetMutation,
  CreateHomeOfficeDocumentAssetMutationVariables
>;

/**
 * __useCreateHomeOfficeDocumentAssetMutation__
 *
 * To run a mutation, you first call `useCreateHomeOfficeDocumentAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateHomeOfficeDocumentAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createHomeOfficeDocumentAssetMutation, { data, loading, error }] = useCreateHomeOfficeDocumentAssetMutation({
 *   variables: {
 *      homeOfficeDocumentId: // value for 'homeOfficeDocumentId'
 *      name: // value for 'name'
 *      filetype: // value for 'filetype'
 *   },
 * });
 */
export function useCreateHomeOfficeDocumentAssetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateHomeOfficeDocumentAssetMutation,
    CreateHomeOfficeDocumentAssetMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateHomeOfficeDocumentAssetMutation,
    CreateHomeOfficeDocumentAssetMutationVariables
  >(CreateHomeOfficeDocumentAssetDocument, options);
}
export type CreateHomeOfficeDocumentAssetMutationHookResult = ReturnType<
  typeof useCreateHomeOfficeDocumentAssetMutation
>;
export type CreateHomeOfficeDocumentAssetMutationResult =
  Apollo.MutationResult<CreateHomeOfficeDocumentAssetMutation>;
export type CreateHomeOfficeDocumentAssetMutationOptions =
  Apollo.BaseMutationOptions<
    CreateHomeOfficeDocumentAssetMutation,
    CreateHomeOfficeDocumentAssetMutationVariables
  >;
