import {
  Annotation,
  AnnotationTag,
} from "../../../api/graphql/schema.generated";

export const getTagSelectOptions = (annotationTags?: AnnotationTag[]) =>
  (annotationTags || []).map(({ tag }) => ({
    value: tag,
    label: tag,
  }));

export const findTagColor = (
  selectedTag: string | null,
  annotationTags?: AnnotationTag[]
) => {
  if (!annotationTags || !selectedTag) {
    return "";
  }

  return annotationTags.find(({ tag }) => tag === selectedTag)?.color || "";
};

export const findOddAnnotation = (
  list1: Annotation[],
  list2: Annotation[]
): Annotation | undefined => {
  const isList1Bigger = list1.length > list2.length;
  const fromList = isList1Bigger ? list1 : list2;
  const toList = isList1Bigger ? list2 : list1;

  return fromList.find(
    (annotation) =>
      !toList.find(
        ({ tag, start, end }) =>
          annotation.tag === tag &&
          annotation.start === start &&
          annotation.end === end
      )
  );
};

export const invertColor = (rgb: string) => {
  const invertedParts = rgb.match(/\d+/g)?.map((c) => 255 - parseInt(c, 10));
  return invertedParts?.length ? `rgb(${invertedParts.join(", ")})` : null;
};
