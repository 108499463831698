import { SKRMode } from "../../../../../../../api/graphql";
import { skr03, skr04 } from "../data";
import { INVALID_VALUE } from "../constants";

const DEBITOR_RANGES = {
  min: 10000,
  max: 69999,
};

const KREDITOR_RANGES = {
  min: 70000,
  max: 99999,
};

const SKR03_ASSET_RANGES = [
  {
    min: 50,
    max: 75,
  },
  {
    min: 79,
    max: 115,
  },
  {
    min: 120,
    max: 129,
  },
  {
    min: 140,
    max: 280,
  },
  {
    min: 290,
    max: 490,
  },
  {
    min: 498,
    max: 499,
  },
];

const SKR04_ASSET_RANGES = [
  {
    min: 200,
    max: 398,
  },
  {
    min: 400,
    max: 470,
  },
  {
    min: 500,
    max: 690,
  },
  {
    min: 700,
    max: 795,
  },
];

const GOLD_9 = "#874d00";
const YELLOW_7 = "#d4b106";
const BLUE = "blue";
const RED_7 = "#cf1322";

const DEBITOR = "Debitor";
const KREDITOR = "Kreditor";

const getSkrCopy = (
  account: string,
  skrMode: SKRMode
): {
  color: string;
  label: string;
} => {
  const parsedAccount = parseInt(account, 10);

  if (
    parsedAccount >= DEBITOR_RANGES.min &&
    parsedAccount <= DEBITOR_RANGES.max
  ) {
    return {
      color: GOLD_9,
      label: DEBITOR,
    };
  }

  if (
    parsedAccount >= KREDITOR_RANGES.min &&
    parsedAccount <= KREDITOR_RANGES.max
  ) {
    return {
      color: YELLOW_7,
      label: KREDITOR,
    };
  }

  const skrCopies: Record<string, string> =
    skrMode === SKRMode.SKR_03 ? skr03 : skr04;

  const isSkr03Asset =
    skrMode === SKRMode.SKR_03 &&
    SKR03_ASSET_RANGES.some(
      ({ min, max }) => parsedAccount >= min && parsedAccount <= max
    );

  const isSkr04Asset =
    skrMode === SKRMode.SKR_04 &&
    SKR04_ASSET_RANGES.some(
      ({ min, max }) => parsedAccount >= min && parsedAccount <= max
    );

  return {
    color: isSkr03Asset || isSkr04Asset ? RED_7 : BLUE,
    label: `${account} ${skrCopies[account] || INVALID_VALUE}`,
  };
};

export default getSkrCopy;
