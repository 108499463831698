import { gql, QueryHookOptions, useQuery } from "@apollo/client";

import { VatMetaNote } from "../../../../types";

export interface GetNotesVariables {
  email: string;
}

export interface GetNotesResult {
  getNotes: VatMetaNote[];
}

export const GET_NOTES_QUERY = gql`
  query GetNotes($email: String!) {
    getNotes(email: $email) {
      id
      creator
      message
      createdAt
      updatedAt
    }
  }
`;

export const useGetNotesQuery = (
  options?: QueryHookOptions<GetNotesResult, GetNotesVariables>
) => useQuery<GetNotesResult, GetNotesVariables>(GET_NOTES_QUERY, options);
