import type * as Types from "../schema.generated";

import { gql } from "@apollo/client";
export type KontaxTransactionForAdminFragment = {
  __typename?: "KontaxTransactionForAdmin";
  id: string;
  amount: number;
  valutaDate: string;
  verified: boolean;
  verifiedBy?: string | null;
  name?: string | null;
  iban?: string | null;
  description?: string | null;
  merchantCategoryCode?: string | null;
  foreignCurrency?: string | null;
  escalated?: boolean | null;
  escalationNote?: string | null;
  internalNote?: string | null;
  businessTypeComment?: string | null;
  personalNote?: string | null;
  invoiceRequestedAt?: string | null;
  email: string;
  firstName: string;
  lastName: string;
  categoryCode?: string | null;
  vatCategoryCode?: string | null;
  hasIntegrationDocument?: boolean | null;
  isSplitCategorized?: boolean | null;
  vatYearPaymentFrequency?: Types.PaymentFrequency | null;
  source: string;
  taxAdvisorProof?: Types.TaxAdvisorProof | null;
  fibuCategoryComment?: string | null;
  vatCategoryComment?: string | null;
  categoryCodeMeta?: {
    __typename?: "ValueMeta";
    label?: string | null;
    description?: string | null;
    categorizationType?: string | null;
    suggestionSource?: string | null;
  } | null;
  vatCategoryCodeMeta?: {
    __typename?: "ValueMeta";
    label?: string | null;
    description?: string | null;
    categorizationType?: string | null;
    suggestionSource?: string | null;
  } | null;
  assets: Array<{
    __typename?: "AssetData";
    id: string;
    filetype: string;
    fullsize: string;
  }>;
  businessAssets: Array<{
    __typename?: "BusinessAsset";
    businessAssetableId: string;
    businessAssetableType: string;
    assetType: Types.AssetType;
    assetClass: string;
    purchaseDate: string;
    amount: number;
    depreciationPeriodYears: number;
    note?: string | null;
  }>;
};

export const KontaxTransactionForAdminFragmentDoc = gql`
  fragment KontaxTransactionForAdmin on KontaxTransactionForAdmin {
    id
    amount
    valutaDate
    verified
    verifiedBy
    name
    iban
    description
    merchantCategoryCode
    foreignCurrency
    escalated
    escalationNote
    internalNote
    businessTypeComment
    personalNote
    invoiceRequestedAt
    email
    firstName
    lastName
    categoryCode
    categoryCodeMeta {
      label
      description
      categorizationType
      suggestionSource
    }
    vatCategoryCode
    vatCategoryCodeMeta {
      label
      description
      categorizationType
      suggestionSource
    }
    assets {
      id
      filetype
      fullsize
    }
    hasIntegrationDocument
    isSplitCategorized
    vatYearPaymentFrequency
    businessAssets {
      businessAssetableId
      businessAssetableType
      assetType
      assetClass
      purchaseDate
      amount
      depreciationPeriodYears
      note
    }
    source
    taxAdvisorProof
    fibuCategoryComment
    vatCategoryComment
  }
`;
