import styled from "styled-components";

export const LargeText = styled.span`
  font-size: 16px;
`;

export const TagText = styled.span`
  font-size: 12px;
  font-weight: 500;
`;

export const ColorContainer = styled.div<{ color: string }>`
  padding-left: 10px;
  padding-right: 10px;
  background-color: ${({ color }) => color};
`;
