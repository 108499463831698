import { Button, Layout } from "antd";
import styled from "styled-components";

import colors from "../../../themes/colors";

export const AntLayout = styled(Layout)`
  position: fixed;
  width: 100vw;
  height: 100vh;

  // LeftNavigation
  .ant-menu {
    border-right: none;
    color: rgba(0, 0, 0, 0.45);
    background-color: rgb(250, 250, 250);
  }
  .ant-menu-submenu {
    padding: 8px 0px;
  }
  .ant-menu-submenu-title {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 12px;
  }
  .ant-menu-submenu-title,
  .ant-menu-item {
    :hover {
      background-color: ${colors.lightestGray};
    }
  }

  // LeftNavigation - handles icons ui changes when the navigation is in 'collapsed' state
  .ant-layout-sider-collapsed {
    .arrow {
      transform: rotate(180deg);
    }
    .kontist-logo {
      opacity: 0;
    }
  }
`;

export const IconMenuButton = styled(Button)`
  background-color: ${colors.antiFlashWhite};
  border: none;
  :hover {
    background-color: ${colors.veryLightPurple};
  }
`;

export const MenuHeader = styled.div<{ isStaging: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ isStaging }) =>
    isStaging ? colors.veryLightCyan : colors.lightestEverGray};
  border-bottom: 1px solid
    ${({ isStaging }) => (isStaging ? colors.lightCyan : colors.antiFlashWhite)};
  height: 64px;
  position: sticky;
  top: 0;
  z-index: 999;

  .kontist-logo {
    position: absolute;
    left: 16px;
    top: 20px;
    z-index: 0;
    opacity: 1;
    transition: opacity 300ms;
    ${({ isStaging }) => isStaging && `path { fill: ${colors.cyan}}`}
  }

  .arrow {
    position: absolute;
    right: 16px;
    top: 20px;
    z-index: 1;
    transition: transform 0s 300ms ease-in-out;

    :focus {
      outline-style: solid;
      outline-width: 1px;
    }

    ${({ isStaging }) =>
      isStaging &&
      `
    background: ${colors.lightCyan};
    :hover {
      color: ${colors.cyan};
    }
    `}
  }
`;

export const HeadSiderTitle = styled.div`
  margin-left: 48px;
  font-size: 19px;
  line-height: 24px;
  color: ${colors.cyan};
  letter-spacing: 0.02em;
`;
