import React, { useState } from "react";
import { Button, Checkbox, Modal } from "antd";
import styled from "styled-components";

import {
  IMessageType,
  ITransaction,
  KontaxTransactionMeta,
} from "../../../../types";
import VatCategorySelect from "../../TransactionView/VatCategorySelect";
import CategorySelect from "../../TransactionView/CategorySelect";
import {
  BUSINESS_ASSET_CATEGORIES,
  destroyMessage,
  EXTERNAL_TRANSACTION_CATEGORIES,
  showGraphQlErrorNotification,
  showLoadingMessage,
  showMessage,
} from "../../../../utils";
import { RESET_OPTION } from "../../../../utils/categories";
import { CategoryCodeType, VatCategoryCode } from "../../../../types";

type CategoryCodeTypeWithReset = CategoryCodeType | RESET_OPTION | null;
type VatCategoryCodeTypeWithReset = VatCategoryCode | RESET_OPTION | null;

const categorizationErrorMessage = "Error during updating transactions";

const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const FooterWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const CategorizeBatchPopup = ({
  isPopupVisible,
  closePopup,
  selectedTransactions,
  updateTransactionsMeta,
}: {
  isPopupVisible: boolean;
  closePopup: () => void;
  selectedTransactions: Array<ITransaction>;
  updateTransactionsMeta: (
    payload: Array<KontaxTransactionMeta>
  ) => Promise<boolean>;
}) => {
  const [categoryCode, setCategoryCode] =
    useState<CategoryCodeTypeWithReset>(null);
  const [vatCategoryCode, setVatCategoryCode] =
    useState<VatCategoryCodeTypeWithReset>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isVerifySelected, setIsVerifySelected] = useState<boolean>(false);

  const loadingKey = "categorize-batch";

  const resetAndClose = () => {
    setCategoryCode(null);
    setVatCategoryCode(null);
    setIsVerifySelected(false);
    closePopup();
  };

  const categorizeBatch = async () => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);
    showLoadingMessage(loadingKey);

    const mappedTransactions = selectedTransactions.map((t) => ({
      kontistTransactionId: t.id,
      categoryCode: categoryCode === RESET_OPTION.RESET ? null : categoryCode,
      vatCategoryCode:
        vatCategoryCode === RESET_OPTION.RESET ? null : vatCategoryCode,
      ...(isVerifySelected
        ? {
            verified: true,
          }
        : {}),
    }));

    try {
      const success = await updateTransactionsMeta(mappedTransactions);
      if (success) {
        showMessage({
          type: IMessageType.SUCCESS,
          content: "Successfully updated transactions",
        });
        resetAndClose();
      }
    } catch (error) {
      showGraphQlErrorNotification(categorizationErrorMessage, error);
    } finally {
      destroyMessage(loadingKey);
      setIsLoading(false);
    }
  };

  return (
    <Modal
      data-test="externalTransactionPopup"
      title={`Categorize ${selectedTransactions.length} transactions`}
      onCancel={closePopup}
      visible={isPopupVisible}
      centered
      width="700px"
      footer={
        <FooterWrapper>
          <Button onClick={closePopup}>Cancel</Button>
          <div>
            <Checkbox
              checked={isVerifySelected}
              onChange={() => {
                setIsVerifySelected(!isVerifySelected);
              }}
              style={{ marginRight: "10px" }}
              data-test="verifyCheckbox"
              disabled={
                !vatCategoryCode ||
                !categoryCode ||
                categoryCode === RESET_OPTION.RESET ||
                vatCategoryCode === RESET_OPTION.RESET
              }
            >
              Verify
            </Checkbox>
            <Button
              disabled={!categoryCode || !vatCategoryCode}
              onClick={categorizeBatch}
              data-test="confirmCategorizeButton"
            >
              Confirm
            </Button>
          </div>
        </FooterWrapper>
      }
    >
      <ContentWrapper data-test="categorizeBatchPopup">
        <CategorySelect
          value={categoryCode}
          withResetOption
          onChangeHandler={(category: CategoryCodeType) => {
            setVatCategoryCode(null);
            setCategoryCode(category);
          }}
          // We want to show outgoing categories first, thats why we have an negative amount here
          transactionAmount={-1}
          disabledOptions={[
            ...EXTERNAL_TRANSACTION_CATEGORIES,
            ...BUSINESS_ASSET_CATEGORIES,
          ]}
        />
        <VatCategorySelect
          value={vatCategoryCode}
          withResetOption
          onChangeHandler={(category: string | null) => {
            setVatCategoryCode(category as VatCategoryCodeTypeWithReset);
          }}
          kontaxCategory={categoryCode}
        />
      </ContentWrapper>
    </Modal>
  );
};
