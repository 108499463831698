import * as GQL from "../api/graphql/schema.generated";
import {
  CategoryCodeType,
  DepreciableCategory,
  IncomingCategoryCode,
  OutgoingCategoryCode,
} from "../types";
import { createCodec } from "./codec";

export const incomingCategoriesRaw = [
  ["1220", "1220 Privateinlage"],
  ["1120", "1120 Erlöse"],
  ["1030", "1030 Erlöse EU"],
  ["1031", "1031 Erlöse Drittland"],
  ["1410", "1410 Umsatzsteuererstattungen"],
  ["5000", "5000 Einkommensteuererstattungen"],
  ["9000", "9000 Gewerbesteuererstattungen"],
  ["1032", "1032 nicht steuerbare Umsätze"],
  ["1033", "1033 Erlöse § 13 b Abs. 5 UStG"],
  ["1110", "1110 Erlöse Kleinunternehmer"],
  ["1081", "1081 Sonstige Nutzungs-und Leistungsentnahmen"],
  ["1082", "1082 Unentg. Lieferung (netto)"],
  ["1083", "1083 Unentg. Lieferung PV 19 % (netto)"],
  ["1084", "1084 Unentg. Sonst. Leistung (netto)"],
  ["1111", "1111 Elektr. Leist. EU (B2C) KU"],
  ["1121", "1121 Erlöse (nur USt)"],
  ["1122", "1122 Elektr. Leist. EU (B2C)"],
];

export const outgoingCategoriesRaw = [
  ["1830", "1830 Sonstige betriebliche Aufwendungen"],
  ["1460", "1460 Reisekosten"],
  ["2240", "2240 Werbung"],
  ["1230", "1230 Privatentnahme"],
  ["2230", "2230 Gebühren"],
  ["2800", "2800 Telekommunikation"],
  ["2280", "2280 Wartung und Beratung für EDV"],
  ["2220", "2220 Miete für bewegliche Wirtschaftsgüter"],
  ["2290", "2290 Bürobedarf"],
  ["1940", "1940 Beratungskosten"],
  ["1500", "1500 Miete für Immobilien"],
  ["2810", "2810 Fortbildungskosten"],
  ["1860", "1860 Umsatzsteuerzahlungen"],
  ["1100", "1100 Fremdleistungen"],
  ["1650", "1650 Bewirtungsaufwendungen"],
  ["2210", "2210 Übernachtungskosten"],
  ["1000", "1000 Waren"],
  ["1200", "1200 Lohn und Gehalt"],
  ["1300", "1300 GWG unter 250 €"],
  ["1320", "1320 GWG über 250 €"],
  ["1370", "1370 Anlagevermögen über 800 €"],
  ["2250", "2250 Erhaltungsaufwendungen für bewegliche Wirtschaftsgüter"],
  ["2270", "2270 Kosten für Logistik"],
  ["2320", "2320 Schuldzinsen zur Finanzierung"],
  ["2321", "2321 Schuldzinsen zur Finanzierung (Kfz)"],
  ["2340", "2340 Übrige Schuldzinsen"],
  ["1740", "1740 Geschenke bis 35 EUR netto pro Person"],
  ["1710", "1710 Verpflegungsmehraufwand"],
  ["1440", "1440 Leasingkosten (Kfz)"],
  ["1450", "1450 Steuern, Versicherungen und Maut (Kfz)"],
  ["2260", "2260 Kosten für Abfallbeseitigung und Entsorgung"],
  ["2000", "2000 Einkommensteuer(voraus)zahlungen"],
  ["8000", "8000 Gewerbesteuer(voraus)zahlungen"],
  ["1850", "1850 Gezahlte Vorsteuerbeträge"],
  ["1221", "1221 Privatentnahmen (EStE)"],
  ["1461", "1461 Kfz-Betriebskosten"],
  ["1462", "1462 ÖPNV"],
  [
    "1680",
    "1680 Sonstige beschränkt abziehbare Betriebsausgaben (nicht abziehbar)",
  ],
  ["1770", "1770 Sonstige beschränkt abziehbare Betriebsausgaben (abziehbar)"],
  ["1831", "1831 Nebenkosten des Geldverkehrs"],
  ["2231", "2231 Versicherungen"],
  ["2291", "2291 Software und Lizenzen"],
  ["2292", "2292 Fachliteratur"],
  ["9991", "9991 Anzahlung"],
  ["1851", "1851 Einfuhrumsatzsteuer"],
];

export const BUSINESS_ASSET_CATEGORIES = Object.values(DepreciableCategory);

export const EXTERNAL_TRANSACTION_CATEGORIES = ["1081", "1082", "1083", "1084"];

export const allCategoriesRaw = [
  ["uncategorized", "Uncategorized"],
  ...incomingCategoriesRaw,
  ...outgoingCategoriesRaw,
];

export const allCategoryCodes = [
  ...Object.values(IncomingCategoryCode),
  ...Object.values(OutgoingCategoryCode),
];

export enum RESET_OPTION {
  RESET = "RESET",
}

/**
 * When a category was changed from a business asset category to another type of category,
 * user has to be warned to delete the business asset that was linked to the transaction.
 */
export const hasChangedFromBusinessAssetCategory = ({
  previousCategory,
  newCategory,
}: {
  previousCategory?: string;
  newCategory?: string;
}) =>
  BUSINESS_ASSET_CATEGORIES.includes(previousCategory as DepreciableCategory) &&
  !BUSINESS_ASSET_CATEGORIES.includes(newCategory as DepreciableCategory);

// TODO: remove this after historical data is migrated from UNKNOWN_* to null
export const NOT_VERIFIED_CODES = ["9990", "6660"];

export const EXCLUDED_CATEGORIES_FOR_NO_VAT_NUMBER = [
  IncomingCategoryCode.INCOME_EU,
] as Array<CategoryCodeType>;

export const DEPRECATED_CATEGORIES = ["1081"];

export const categoryCodeCodec = createCodec([
  [
    GQL.CategoryCode.CONSTRUCTION_REVENUE,
    IncomingCategoryCode.CONSTRUCTION_REVENUE,
  ],
  [GQL.CategoryCode.CORONA_HELP, IncomingCategoryCode.CORONA_HELP],
  [GQL.CategoryCode.INCOME_EU, IncomingCategoryCode.INCOME_EU],
  [GQL.CategoryCode.INCOME_GERMANY, IncomingCategoryCode.INCOME_GERMANY],
  [GQL.CategoryCode.INCOME_INTL, IncomingCategoryCode.INCOME_INTL],
  [
    GQL.CategoryCode.OTHER_USAGE_AND_SERVICE_WITHDRAWALS,
    IncomingCategoryCode.OTHER_USAGE_AND_SERVICE_WITHDRAWALS,
  ],
  [GQL.CategoryCode.PRIVATE_IN, IncomingCategoryCode.PRIVATE_IN],
  [GQL.CategoryCode.REVENUE_SB, IncomingCategoryCode.REVENUE_SB],
  [GQL.CategoryCode.TAX_REFUND, IncomingCategoryCode.TAX_REFUND],
  [GQL.CategoryCode.TRADE_TAX_REFUND, IncomingCategoryCode.TRADE_TAX_REFUND],
  [
    GQL.CategoryCode.VAT_ON_UNPAID_ITEMS,
    IncomingCategoryCode.VAT_ON_UNPAID_ITEMS,
  ],
  [GQL.CategoryCode.VAT_REFUND, IncomingCategoryCode.VAT_REFUND],

  [GQL.CategoryCode.ACCOMMODATION, OutgoingCategoryCode.ACCOMMODATION],
  [GQL.CategoryCode.ADVERTISING, OutgoingCategoryCode.ADVERTISING],
  [
    GQL.CategoryCode.ASSETS_GREATER_THAN_EUR_250,
    OutgoingCategoryCode.ASSETS_GREATER_THAN_EUR_250,
  ],
  [
    GQL.CategoryCode.ASSETS_GREATER_THAN_EUR_800,
    OutgoingCategoryCode.ASSETS_GREATER_THAN_EUR_800,
  ],
  [
    GQL.CategoryCode.ASSETS_LESS_THAN_EUR_250,
    OutgoingCategoryCode.ASSETS_LESS_THAN_EUR_250,
  ],
  [GQL.CategoryCode.BANK_FEES, OutgoingCategoryCode.BANK_FEES],
  [GQL.CategoryCode.BOOKS, OutgoingCategoryCode.BOOKS],
  [GQL.CategoryCode.CAR_COSTS, OutgoingCategoryCode.CAR_COSTS],
  [GQL.CategoryCode.CAR_FEES, OutgoingCategoryCode.CAR_FEES],
  [GQL.CategoryCode.DAILY_ALLOWANCE, OutgoingCategoryCode.DAILY_ALLOWANCE],
  [GQL.CategoryCode.DEPOSIT, OutgoingCategoryCode.DEPOSIT],
  [GQL.CategoryCode.DOWN_PAYMENT, OutgoingCategoryCode.DOWN_PAYMENT],
  [GQL.CategoryCode.EDUCATION, OutgoingCategoryCode.EDUCATION],
  [GQL.CategoryCode.ENTERTAINMENT, OutgoingCategoryCode.ENTERTAINMENT],
  [
    GQL.CategoryCode.EXTERNAL_FREELANCER,
    OutgoingCategoryCode.EXTERNAL_FREELANCER,
  ],
  [GQL.CategoryCode.FEES, OutgoingCategoryCode.FEES],
  [GQL.CategoryCode.GIFTS, OutgoingCategoryCode.GIFTS],
  [GQL.CategoryCode.GOODS, OutgoingCategoryCode.GOODS],
  [GQL.CategoryCode.IMPORT_VAT, OutgoingCategoryCode.IMPORT_VAT],
  [GQL.CategoryCode.INSURANCES, OutgoingCategoryCode.INSURANCES],
  [GQL.CategoryCode.INTERESTS_ASSETS, OutgoingCategoryCode.INTERESTS_ASSETS],
  [
    GQL.CategoryCode.INTERESTS_CAR_ASSETS,
    OutgoingCategoryCode.INTERESTS_CAR_ASSETS,
  ],
  [GQL.CategoryCode.INTERESTS_OTHER, OutgoingCategoryCode.INTERESTS_OTHER],
  [GQL.CategoryCode.IT_COSTS, OutgoingCategoryCode.IT_COSTS],
  [GQL.CategoryCode.LEASING_CAR, OutgoingCategoryCode.LEASING_CAR],
  [GQL.CategoryCode.LEASING_MOVABLES, OutgoingCategoryCode.LEASING_MOVABLES],
  [
    GQL.CategoryCode.LEGAL_TAX_CONSULTING,
    OutgoingCategoryCode.LEGAL_TAX_CONSULTING,
  ],
  [
    GQL.CategoryCode.LIMITED_DEDUCTIBLE_EXPENSES,
    OutgoingCategoryCode.LIMITED_DEDUCTIBLE_EXPENSES,
  ],
  [
    GQL.CategoryCode.LIMITED_NOT_DEDUCTIBLE_EXPENSES,
    OutgoingCategoryCode.LIMITED_NOT_DEDUCTIBLE_EXPENSES,
  ],
  [GQL.CategoryCode.MAINTENANCE_COSTS, OutgoingCategoryCode.MAINTENANCE_COSTS],
  [GQL.CategoryCode.OFFICE_COSTS, OutgoingCategoryCode.OFFICE_COSTS],
  [GQL.CategoryCode.OTHER_EXPENSES, OutgoingCategoryCode.OTHER_EXPENSES],
  [GQL.CategoryCode.PAYROLL, OutgoingCategoryCode.PAYROLL],
  [GQL.CategoryCode.PRIVATE_OUT, OutgoingCategoryCode.PRIVATE_OUT],
  [
    GQL.CategoryCode.PRIVATE_WITHDRAWAL,
    OutgoingCategoryCode.PRIVATE_WITHDRAWAL,
  ],
  [GQL.CategoryCode.PUBLIC_TRANSPORT, OutgoingCategoryCode.PUBLIC_TRANSPORT],
  [GQL.CategoryCode.RENT, OutgoingCategoryCode.RENT],
  [GQL.CategoryCode.SHIPPING_COSTS, OutgoingCategoryCode.SHIPPING_COSTS],
  [
    GQL.CategoryCode.SOFTWARE_AND_LICENSES,
    OutgoingCategoryCode.SOFTWARE_AND_LICENSES,
  ],
  [GQL.CategoryCode.TAX_PAYMENT, OutgoingCategoryCode.TAX_PAYMENT],
  [GQL.CategoryCode.TELECOMMUNICATION, OutgoingCategoryCode.TELECOMMUNICATION],
  [GQL.CategoryCode.TRADE_TAX_PAYMENT, OutgoingCategoryCode.TRADE_TAX_PAYMENT],
  [GQL.CategoryCode.TRAVEL_COSTS, OutgoingCategoryCode.TRAVEL_COSTS],
  [GQL.CategoryCode.VAT, OutgoingCategoryCode.VAT],
  [GQL.CategoryCode.VAT_PAYMENT, OutgoingCategoryCode.VAT_PAYMENT],
  [GQL.CategoryCode.WASTE_DISPOSALS, OutgoingCategoryCode.WASTE_DISPOSALS],
] as const);
