import React from "react";
import { Row, Col, Typography } from "antd";
import moment from "moment";

import {
  extractNameFromEmail,
  getDraftInfoLatestCreationDate,
} from "../../utils";
import colors from "../../../../../themes/colors";
import ActionLogDrawer from "../../../../common/ActionLogDrawer";
import { ChangeLog } from "../../../../../api/graphql";
import { ActionsContainer } from "../../styles";
import NotesDrawer from "../../../../common/NotesDrawer";
import {
  DeclarationDecline,
  KontaxNote,
  KontaxNoteType,
  TaxDeclarationSavedDraftInfo,
  TaxDeclarationSubmission,
  VatAnnualDeclarationStatus,
} from "../../../../../api/graphql/schema.generated";
import DeclarationDeclinesDrawer from "../../../../common/DeclarationDeclinesDrawer";

const { Title } = Typography;

export interface VatAnnualDeclarationHeaderProps {
  declarationId: string;
  taxYear: number;
  changeLogs: ChangeLog[];
  notes: KontaxNote[];
  isSmallBusiness?: boolean;
  lastSubmission?: TaxDeclarationSubmission | null;
  draftInfo?: TaxDeclarationSavedDraftInfo | null;
  status: VatAnnualDeclarationStatus;
  declarationDeclines: DeclarationDecline[];
  hasExternalAssets?: boolean;
}

const VatAnnualDeclarationHeader = ({
  declarationId,
  taxYear,
  changeLogs,
  notes,
  status,
  declarationDeclines,
  isSmallBusiness,
  lastSubmission,
  draftInfo,
  hasExternalAssets,
}: VatAnnualDeclarationHeaderProps) => {
  const title = `Umsatzsteuererklärung ${taxYear}`;
  return (
    <>
      <Row align="middle">
        <Col flex="1">
          <ActionsContainer noTopMargin>
            <ActionLogDrawer
              withIcon
              title="Action log"
              changeLogs={changeLogs}
            />
            <NotesDrawer
              notes={notes}
              title={title}
              type={KontaxNoteType.VAT_ANNUAL_DECLARATION}
              recordId={declarationId}
            />
            <DeclarationDeclinesDrawer
              declarationDeclines={declarationDeclines}
              showNotification={
                status === VatAnnualDeclarationStatus.OBJECTED_BY_USER
              }
              title={title}
            />
          </ActionsContainer>

          <Title level={3}>{title}</Title>
        </Col>
        {!!lastSubmission || hasExternalAssets ? (
          <Col style={{ color: colors.darkLimeGreen }}>
            Eingereicht
            {lastSubmission && (
              <div style={{ color: colors.darkGrey }}>
                am {moment(lastSubmission.submissionDate).format("L")} von{" "}
                {extractNameFromEmail(lastSubmission.submissionAgent!)}
              </div>
            )}
          </Col>
        ) : !!draftInfo ? (
          <div style={{ marginTop: 25, color: colors.darkGrey }}>
            <div>Freigabe PDF erstellt</div>
            <div>
              am{" "}
              {moment(getDraftInfoLatestCreationDate(draftInfo!)).format("L")}
            </div>
          </div>
        ) : null}
      </Row>
      {isSmallBusiness != null && (
        <div>{isSmallBusiness ? "Kleinunternehmer" : "Regelbesteuerung"}</div>
      )}
    </>
  );
};

export default VatAnnualDeclarationHeader;
