import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Row, Typography, Tabs, Space } from "antd";
import { ArrowRightOutlined, PlusOutlined } from "@ant-design/icons";
import { Calendar, momentLocalizer } from "react-big-calendar";

import moment from "moment";

import { SorterResult } from "antd/lib/table/interface";

import UserFilters, {
  UserFilterOptions,
} from "../Mandanten/Common/UserFilters";
import useEmailParam from "../../hooks/useEmailParam";
import { ContentWrapper } from "../../common/styledComponents";
import { destroyMessage, isEmail, showLoadingMessage } from "../../../utils";
import { ActivityTrackingContainer } from "./styledComponent";
import api from "../../../api";
import { IUser } from "../../../types";
import ActivityTrackingTable from "./ActivityTrackingTable";
import { useUsageStatsQuery } from "../../../api/graphql";
import AddNewActivityModal from "./AddNewActivityModal";
import { UsageStatDetails } from "../../../api/graphql/schema.generated";
import { OrderDirectionMapping } from "./utils";
import { useSystemUsageStatsQuery } from "../../../api/graphql/queries/usageStat/systemUsageStats.generated";
import colors from "../../../themes/colors";

const { Title, Text } = Typography;
const localizer = momentLocalizer(moment);

const LOADING_MESSAGE_KEY = "finding-user";
const AUTOMATIC_ACTIVITY_TITLE = "Backoffice";
const AUTOMATIC_ACTIVITY_STYLES = {
  style: {
    backgroundColor: colors.lightPurple,
    borderColor: colors.purple,
    color: colors.primaryPurple,
  },
};

interface OrderParams {
  order: string;
  orderDirection: string;
}

const ActivityTrackingView = () => {
  const [emailParam, setEmailParam] = useEmailParam();
  const [searchParam, setSearchParam] = useState<string | null | undefined>(
    emailParam
  );
  const [filterOptions, setFilterOptions] = useState<UserFilterOptions>({
    search: searchParam || "",
    all: false,
  });
  const [user, setUser] = useState<IUser | null>(null);
  const [showAddNewActivityModal, setShowAddNewActivityModal] = useState(false);
  const [activityDateRange, setActivityDateRange] = useState<{
    startDate: Date;
    endDate: Date;
  } | null>(null);

  // state for order
  const [queryOrderParams, setQueryOrderParams] = useState<OrderParams>({
    order: "",
    orderDirection: "",
  });

  // state for pagination
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const handleOrderChange = (sorter: SorterResult<UsageStatDetails>) => {
    if (sorter.order) {
      const order = sorter.columnKey as string;
      const orderDirection = OrderDirectionMapping[sorter.order];
      if (
        queryOrderParams.order !== order ||
        queryOrderParams.orderDirection !== orderDirection
      ) {
        setQueryOrderParams({
          order: sorter.columnKey as string,
          orderDirection: OrderDirectionMapping[sorter.order],
        });
      }
    }
  };

  const handlePaginationChange = (newPage: number, pageSize: number) => {
    setPage(newPage);
    setPageSize(pageSize);
  };

  const { data: usageStatsAndCount, refetch: refetchUsageStats } =
    useUsageStatsQuery({
      variables: {
        filter: {
          ...(!filterOptions.all && user ? { email: user.email } : {}),
        },
        limit: pageSize,
        offset: (page - 1) * pageSize,
        orderBy: queryOrderParams?.order,
        orderDirection: queryOrderParams?.orderDirection,
      },
      fetchPolicy: "network-only",
      nextFetchPolicy: "cache-first",
    });

  const { data: systemUsageStatsData } = useSystemUsageStatsQuery();
  const systemUsageStats = systemUsageStatsData?.systemUsageStats;

  const fetchUser = useCallback(async (email: string) => {
    if (!email) {
      return;
    }
    showLoadingMessage(LOADING_MESSAGE_KEY);
    try {
      const { user } = await api.kontax.getVatMeta(email);
      setUser(user);
    } catch (error) {
      setUser(null);
    } finally {
      destroyMessage(LOADING_MESSAGE_KEY);
    }
  }, []);

  useEffect(() => {
    if (emailParam) {
      fetchUser(emailParam);
    } else {
      setUser(null);
    }
  }, [emailParam, fetchUser]);

  const calendarEvents = useMemo(
    () =>
      [
        ...(usageStatsAndCount?.usageStats?.data || []),
        ...(systemUsageStats || []),
      ].map((usageStat) => {
        return {
          title:
            "activity" in usageStat
              ? usageStat.activity
              : AUTOMATIC_ACTIVITY_TITLE,
          start: new Date(usageStat.startedAt),
          end: new Date(usageStat.finishedAt),
        };
      }),
    [usageStatsAndCount?.usageStats?.data, systemUsageStats]
  );

  return (
    <ContentWrapper>
      <UserFilters
        filterOptions={filterOptions}
        setFilterOptions={(
          updatedFilterOptions: Partial<UserFilterOptions>
        ) => {
          if (!updatedFilterOptions.all) {
            setSearchParam(updatedFilterOptions.search);
            isEmail(updatedFilterOptions.search || "")
              ? setEmailParam(updatedFilterOptions.search)
              : setEmailParam(undefined);
          }
          if (!updatedFilterOptions.search) {
            setEmailParam(undefined);
          }
          setFilterOptions({
            ...filterOptions,
            ...updatedFilterOptions,
          });
        }}
      />
      <ActivityTrackingContainer>
        <Title level={3}>Activity Tracking</Title>
        <Text type="secondary">
          Track the activities you are doing outside of the backoffice for a
          specific user or for a general purpose
        </Text>
        <Row justify="end">
          <Space
            direction="vertical"
            align="center"
            style={{ minWidth: "180px" }}
          >
            <Button
              icon={<PlusOutlined />}
              type="primary"
              onClick={() => {
                setShowAddNewActivityModal(true);
              }}
            >
              Add new activity
            </Button>
            <Button
              icon={<ArrowRightOutlined />}
              type="link"
              href="https://kontist.de.looker.com/dashboards/447"
              target="blank"
            >
              Go to Looker Board
            </Button>
          </Space>
        </Row>
        <br />
        <Tabs>
          <Tabs.TabPane tab="Listing" key="listing">
            <Row>
              <Row>
                <ActivityTrackingTable
                  usageStatsAndCount={usageStatsAndCount?.usageStats}
                  page={page}
                  pageSize={pageSize}
                  handleOrderChange={handleOrderChange}
                  handlePaginationChange={handlePaginationChange}
                />
              </Row>
            </Row>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Calendar" key="calendar">
            <Row>
              <Calendar
                localizer={localizer}
                defaultDate={new Date()}
                selectable={true}
                views={["day", "week"]}
                onSelectSlot={(slotInfo) => {
                  setActivityDateRange({
                    startDate: slotInfo.start,
                    endDate: slotInfo.end,
                  });
                  setShowAddNewActivityModal(true);
                }}
                defaultView="day"
                events={calendarEvents}
                step={15}
                style={{ width: "100%" }}
                eventPropGetter={(event) =>
                  event.title === AUTOMATIC_ACTIVITY_TITLE
                    ? AUTOMATIC_ACTIVITY_STYLES
                    : {}
                }
              />
            </Row>
          </Tabs.TabPane>
        </Tabs>

        <AddNewActivityModal
          user={user}
          visible={showAddNewActivityModal}
          onClose={() => {
            setShowAddNewActivityModal(false);
          }}
          activityDateRange={activityDateRange}
          onSuccess={refetchUsageStats}
        />
      </ActivityTrackingContainer>
    </ContentWrapper>
  );
};

export default ActivityTrackingView;
