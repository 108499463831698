import styled from "styled-components";

import colors from "../../../themes/colors";

export const ButtonsWrapper = styled.div`
  display: block;
  margin-left: auto;
`;

export const Fieldset = styled.fieldset`
  border: none;
  padding: 10px 0 30px;
`;

export const Content = styled.div`
  display: block;
  margin: 50px auto;
  max-width: 900px;
  text-align: left;
`;

export const AccountWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: start;
  box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.02);
`;

export const ButtonGroup = styled.div`
  flex-grow: 0;
  padding-left: 30px;
  margin-left: 30px;
  border-left: 1px solid ${colors.lightGreyishBlue};
`;

export const DateFiltersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 200px;
`;

export const DateFiltersBWAView = styled.div`
  display: end;
  flex-direction: row;
  max-width: 200px;
`;

export const BWAHeaderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: end;
`;

export const BWATableWrapper = styled.div`
  margin-top: 20px;

  .ant-table-thead > tr > th,
  .ant-table-summary > tr > td {
    background: ${colors.lightestGray};
  }

  .ant-table-summary
    > tr
    > td:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    position: absolute;
    top: 50%;
    right: 0;
    width: 1px;
    height: 1.6em;
    background-color: rgba(0, 0, 0, 0.06);
    transform: translateY(-50%);
    transition: background-color 0.3s;
    content: "";
  }

  .ant-table-row > td:nth-child(even) {
    background: ${colors.lightestEverGray};
  }

  .ant-table-cell-row-hover {
    cursor: pointer;
  }

  .ant-table-cell.active {
    background: ${colors.lighterPurple} !important;
  }
  .ant-table-cell.data-cell:hover {
    text-decoration: underline;
    background: ${colors.lighterPurple} !important;
  }
`;

export const BWATableCellWrapper = styled.span<{
  content: number | string;
}>`
  color: ${({ content }) =>
    content ? "rgba(0, 0, 0, 0.85)" : "rgba(0, 0, 0, 0.25)"};
  }
`;
