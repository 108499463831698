import React, { ReactNode } from "react";
import { Input, DatePicker, Form } from "antd";
import moment from "moment-timezone";

import { BusinessAddress } from "../../../../../../api/graphql";

export enum InputType {
  TEXT = "text",
  DATE = "date",
}

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: InputType;
  record: BusinessAddress;
  index: number;
  children: ReactNode;
}

const isStreetCell = (dataIndex: string): boolean => dataIndex === "street";

const BusinessAddressEditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  let inputNode: ReactNode =
    inputType === InputType.TEXT ? (
      <Input
        showCount={isStreetCell(dataIndex)}
        maxLength={isStreetCell(dataIndex) ? 30 : undefined}
      />
    ) : (
      <DatePicker />
    );

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              // Field is required!
              message: `${title} ist erforderlich!`,
            },
            // Add movingDate validation when input is date type
            ...(inputType === InputType.DATE
              ? [
                  {
                    validator: (_rule: any, value: Date) => {
                      if (moment(value).isAfter(moment())) {
                        return Promise.reject(
                          // Date can't be in the future
                          new Error(`${title} darf nicht in der Zukunft liegen`)
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]
              : []),
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

export default BusinessAddressEditableCell;
