import type * as Types from "../../schema.generated";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type UpsertHomeOfficeExpenseMutationVariables = Types.Exact<{
  email: Types.Scalars["String"];
  year: Types.Scalars["Int"];
  payload: Types.HomeOfficeExpenseInput;
}>;

export type UpsertHomeOfficeExpenseMutation = {
  __typename?: "Mutation";
  upsertHomeOfficeExpense: {
    __typename?: "HomeOfficeExpense";
    id: string;
    type: Types.HomeOfficeExpenseType;
    period: Types.HomeOfficeExpensePeriod;
    monthsUsed?: number | null;
    adjustByOfficeAreaShare: boolean;
    amount: number;
    vatRate: string;
    note: string;
  };
};

export const UpsertHomeOfficeExpenseDocument = gql`
  mutation upsertHomeOfficeExpense(
    $email: String!
    $year: Int!
    $payload: HomeOfficeExpenseInput!
  ) {
    upsertHomeOfficeExpense(email: $email, year: $year, payload: $payload) {
      id
      type
      period
      monthsUsed
      adjustByOfficeAreaShare
      amount
      vatRate
      note
    }
  }
`;
export type UpsertHomeOfficeExpenseMutationFn = Apollo.MutationFunction<
  UpsertHomeOfficeExpenseMutation,
  UpsertHomeOfficeExpenseMutationVariables
>;

/**
 * __useUpsertHomeOfficeExpenseMutation__
 *
 * To run a mutation, you first call `useUpsertHomeOfficeExpenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertHomeOfficeExpenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertHomeOfficeExpenseMutation, { data, loading, error }] = useUpsertHomeOfficeExpenseMutation({
 *   variables: {
 *      email: // value for 'email'
 *      year: // value for 'year'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useUpsertHomeOfficeExpenseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertHomeOfficeExpenseMutation,
    UpsertHomeOfficeExpenseMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpsertHomeOfficeExpenseMutation,
    UpsertHomeOfficeExpenseMutationVariables
  >(UpsertHomeOfficeExpenseDocument, options);
}
export type UpsertHomeOfficeExpenseMutationHookResult = ReturnType<
  typeof useUpsertHomeOfficeExpenseMutation
>;
export type UpsertHomeOfficeExpenseMutationResult =
  Apollo.MutationResult<UpsertHomeOfficeExpenseMutation>;
export type UpsertHomeOfficeExpenseMutationOptions = Apollo.BaseMutationOptions<
  UpsertHomeOfficeExpenseMutation,
  UpsertHomeOfficeExpenseMutationVariables
>;
