import { gql, QueryHookOptions, useQuery } from "@apollo/client";

import { BWAStatsCategory } from "../types";

export interface BWAStatsQueryVariables {
  email: string;
  dateFrom: string;
  dateTo: string;
}

export interface BWAStatsQueryResult {
  BWAStats?: BWAStatsCategory[];
}

export const BWA_STATS_QUERY = gql`
  query BWAStats($email: String!, $dateFrom: String!, $dateTo: String!) {
    BWAStats(email: $email, dateFrom: $dateFrom, dateTo: $dateTo) {
      category
      data {
        year
        month
        sum
      }
    }
  }
`;

export const useBWAStatsQuery = (
  options?: QueryHookOptions<BWAStatsQueryResult, BWAStatsQueryVariables>
) =>
  useQuery<BWAStatsQueryResult, BWAStatsQueryVariables>(
    BWA_STATS_QUERY,
    options
  );
