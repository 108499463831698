import React, { useMemo } from "react";
import { Table } from "antd";

import { TaxNumberType, ReasonType } from "./types";
import { TAX_NUMBER_TYPE_LABELS } from "./utils";
import {
  MissingTaxNumberTableTitle,
  MissingTaxNumberTableWrapper,
} from "../styledComponents";

interface MissingTaxNumberNotes {
  type: TaxNumberType;
  reasonGiven: string;
}

interface MissingTaxNumberTableProps {
  missingBusinessTaxNumberNote: string | null;
  missingPersonalTaxNumberNote: string | null;
  hasBusinessTaxNumber: boolean;
  hasPersonalTaxNumber: boolean;
}

const MissingTaxNumberTable = ({
  missingBusinessTaxNumberNote,
  missingPersonalTaxNumberNote,
  hasBusinessTaxNumber,
  hasPersonalTaxNumber,
}: MissingTaxNumberTableProps) => {
  const missingTaxNumberDataSource = useMemo(() => {
    const taxNumbers: MissingTaxNumberNotes[] = [];

    if (!hasBusinessTaxNumber && missingBusinessTaxNumberNote) {
      taxNumbers.push({
        type: TaxNumberType.BUSINESS,
        reasonGiven: missingBusinessTaxNumberNote,
      });
    }
    if (!hasPersonalTaxNumber && missingPersonalTaxNumberNote) {
      taxNumbers.push({
        type: TaxNumberType.PERSONAL,
        reasonGiven: missingPersonalTaxNumberNote,
      });
    }

    return taxNumbers;
  }, [
    hasBusinessTaxNumber,
    hasPersonalTaxNumber,
    missingBusinessTaxNumberNote,
    missingPersonalTaxNumberNote,
  ]);

  const columns = useMemo(
    () => [
      {
        key: "type",
        title: "Art",
        dataIndex: "type",
        render: (type: TaxNumberType) => TAX_NUMBER_TYPE_LABELS[type],
      },
      {
        key: "reasonGiven",
        title: "Ursache",
        dataIndex: "reasonGiven",
        render: (reasonGiven: string) =>
          ReasonType[reasonGiven as keyof typeof ReasonType] || reasonGiven,
      },
    ],
    []
  );

  return missingTaxNumberDataSource.length ? (
    <MissingTaxNumberTableWrapper data-test="missingTaxNumberTable">
      <Table
        dataSource={missingTaxNumberDataSource}
        columns={columns}
        pagination={false}
        title={() => (
          <MissingTaxNumberTableTitle>
            Fehlende Steuernummern
          </MissingTaxNumberTableTitle>
        )}
      />
    </MissingTaxNumberTableWrapper>
  ) : null;
};

export default MissingTaxNumberTable;
