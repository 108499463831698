import type * as Types from "../../schema.generated";

import { gql } from "@apollo/client";
import { NoteDataFragmentDoc } from "../../fragments/note.generated";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type UpsertKontaxNoteMutationVariables = Types.Exact<{
  email: Types.Scalars["String"];
  payload: Types.KontaxNoteInput;
}>;

export type UpsertKontaxNoteMutation = {
  __typename?: "Mutation";
  upsertKontaxNote: {
    __typename?: "KontaxNote";
    id: number;
    creator: string;
    updatedAt: string;
    createdAt: string;
    message: string;
  };
};

export const UpsertKontaxNoteDocument = gql`
  mutation upsertKontaxNote($email: String!, $payload: KontaxNoteInput!) {
    upsertKontaxNote(email: $email, payload: $payload) {
      ...noteData
    }
  }
  ${NoteDataFragmentDoc}
`;
export type UpsertKontaxNoteMutationFn = Apollo.MutationFunction<
  UpsertKontaxNoteMutation,
  UpsertKontaxNoteMutationVariables
>;

/**
 * __useUpsertKontaxNoteMutation__
 *
 * To run a mutation, you first call `useUpsertKontaxNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertKontaxNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertKontaxNoteMutation, { data, loading, error }] = useUpsertKontaxNoteMutation({
 *   variables: {
 *      email: // value for 'email'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useUpsertKontaxNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertKontaxNoteMutation,
    UpsertKontaxNoteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpsertKontaxNoteMutation,
    UpsertKontaxNoteMutationVariables
  >(UpsertKontaxNoteDocument, options);
}
export type UpsertKontaxNoteMutationHookResult = ReturnType<
  typeof useUpsertKontaxNoteMutation
>;
export type UpsertKontaxNoteMutationResult =
  Apollo.MutationResult<UpsertKontaxNoteMutation>;
export type UpsertKontaxNoteMutationOptions = Apollo.BaseMutationOptions<
  UpsertKontaxNoteMutation,
  UpsertKontaxNoteMutationVariables
>;
