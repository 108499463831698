const config = {
  REACT_APP_API_URL:
    localStorage.getItem("debug:apiUrl") ||
    process.env.REACT_APP_API_URL ||
    "http://localhost:3000",
  GOOGLE_CLIENT_ID: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  REACT_APP_URL: process.env.REACT_APP_URL,
  REACT_APP_ENV: process.env.REACT_APP_ENV,
  REACT_APP_SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN,
  TINYMCE_API_KEY: "wtwyljbixca8vxsfp9r8nqqdppkykmgjdarol6xgla5nw1nd",
};

export default config;
