import { Form, Input, Select, Typography } from "antd";
import { ChangeEvent } from "react";

import { RuleConditionNumberOp } from "../../../api/graphql/schema.generated";
import { Direction } from "../../../types";
import {
  encodeFormAmountCondition,
  getAmountFromString,
  getLocaleAmountString,
} from "./helpers";
import { currencyFormatter } from "../../../utils";

export type AmountInputProps = {
  name: string[];
  direction?: Direction;
  amount: AmountInputValue;
};

export type AmountInputValue = {
  op: RuleConditionNumberOp;
  value?: string;
};

export const AmountInput = (props: AmountInputProps) => {
  const opInputName = [...props.name, "op"];

  return (
    <Form.Item
      name={[...props.name, "value"]}
      label="Amount"
      extra={displayExtraMessage(props)}
      getValueProps={(value = "") => ({
        value: getLocaleAmountString(value),
      })}
      getValueFromEvent={(event: ChangeEvent<HTMLInputElement>) =>
        getAmountFromString(event.target.value)
      }
      rules={[
        {
          validator: async (_, value: string) => {
            if (props.direction) {
              const amountCondition = encodeFormAmountCondition(
                props.direction,
                {
                  op: props.amount.op,
                  value,
                }
              );
              if (amountCondition instanceof Error) {
                throw amountCondition;
              }
            }
          },
        },
      ]}
      dependencies={[opInputName]}
    >
      <Input
        addonBefore={
          <Form.Item name={opInputName} noStyle>
            <Select>
              <Select.Option value={RuleConditionNumberOp.EQ}>
                is exactly
              </Select.Option>
              <Select.Option value={RuleConditionNumberOp.LT}>
                less than
              </Select.Option>
              <Select.Option value={RuleConditionNumberOp.GT}>
                greater than
              </Select.Option>
            </Select>
          </Form.Item>
        }
        style={{ maxWidth: "280px", display: "block" }}
        placeholder="e.g. 1.000"
        suffix={
          <Typography.Text type={props.amount?.value ? undefined : "secondary"}>
            €
          </Typography.Text>
        }
        disabled={!props.direction}
      />
    </Form.Item>
  );
};

function displayExtraMessage({
  direction,
  amount,
}: AmountInputProps): string | undefined {
  if (!direction || !amount?.op || !amount?.value) {
    return;
  }

  const amountCondition = encodeFormAmountCondition(direction, amount);
  if (amountCondition instanceof Error) {
    return;
  }

  const absAmountInCents = Math.abs(amountCondition.value);

  if (amount.op === RuleConditionNumberOp.EQ) {
    return `Result is ${displayAmountInCents(
      absAmountInCents
    )} (${displayDirection(direction)})`;
  }

  const adjustedAmount = displayAmountInCents(
    absAmountInCents + (amount.op === RuleConditionNumberOp.LT ? -1 : 1)
  );

  if (amount.op === RuleConditionNumberOp.LT) {
    return `Result is 0 to ${adjustedAmount} (${displayDirection(direction)})`;
  }

  return `Result is greater than or equal to ${adjustedAmount} (${displayDirection(
    direction
  )})`;
}

function displayAmountInCents(amountInCents: number): string {
  return currencyFormatter.format(amountInCents / 100);
}

function displayDirection(direction: Direction): string {
  return direction === Direction.INCOMING ? "incoming" : "outgoing";
}
