import type * as Types from "../schema.generated";

import { gql } from "@apollo/client";
import { HomeOfficeExpenseFragmentDoc } from "../fragments/homeOfficeExpense.generated";
import { NoteDataFragmentDoc } from "../fragments/note.generated";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type EuerDeclarationSubformQueryVariables = Types.Exact<{
  email: Types.Scalars["String"];
  type: Types.EuerDeclarationSubformType;
  year: Types.Scalars["Int"];
  shouldIncludeHomeOfficeExpenses?: Types.InputMaybe<Types.Scalars["Boolean"]>;
}>;

export type EuerDeclarationSubformQuery = {
  __typename?: "Query";
  euerDeclarationSubform?: {
    __typename?: "EuerDeclarationSubform";
    id: string;
    year: number;
    type: Types.EuerDeclarationSubformType;
    calculationMethod: Types.EuerDeclarationSubformCalculationMethod;
    inputs: Record<string, unknown>;
    calculationResults: Record<string, unknown>;
    status: Types.EuerDeclarationSubformStatus;
    statusUpdatedAt?: string | null;
    totalNetAmount: number;
    totalVatAmount: number;
    questionnaireCalculationMethod?: Types.EuerDeclarationSubformCalculationMethod | null;
    questionnaireValues: Record<string, unknown>;
    syncedFromQuestionnaireAt?: string | null;
    correspondingSubformMinDeductibleTravelExpenses?: number | null;
    autoCalculatedValues?: Record<string, unknown> | null;
    assets: Array<{
      __typename?: "Asset";
      id: string;
      name: string;
      filetype: string;
      thumbnail: string;
      fullsize: string;
    }>;
    lastStatusChange?: {
      __typename?: "EuerDeclarationSubformStatusChange";
      status: Types.EuerDeclarationSubformStatus;
      changedAt: string;
      changedBy?: string | null;
    } | null;
    changeLogs: Array<{
      __typename?: "ChangeLog";
      changes: Record<string, unknown>;
      changedAt: string;
      changedBy?: string | null;
    }>;
    homeOfficeExpenses?: Array<{
      __typename?: "HomeOfficeExpense";
      id: string;
      type: Types.HomeOfficeExpenseType;
      period: Types.HomeOfficeExpensePeriod;
      monthsUsed?: number | null;
      adjustByOfficeAreaShare: boolean;
      syncedFromQuestionnaireAt?: string | null;
      amount: number;
      grossAmount: number;
      netAmount: number;
      vatAmount: number;
      vatRate: string;
      note: string;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        name: string;
        filetype: string;
        thumbnail: string;
        fullsize: string;
      }>;
    }>;
    notes: Array<{
      __typename?: "KontaxNote";
      id: number;
      creator: string;
      updatedAt: string;
      createdAt: string;
      message: string;
    }>;
  } | null;
};

export const EuerDeclarationSubformDocument = gql`
  query euerDeclarationSubform(
    $email: String!
    $type: EuerDeclarationSubformType!
    $year: Int!
    $shouldIncludeHomeOfficeExpenses: Boolean = false
  ) {
    euerDeclarationSubform(email: $email, type: $type, year: $year) {
      id
      year
      type
      calculationMethod
      inputs
      calculationResults
      status
      statusUpdatedAt
      totalNetAmount
      totalVatAmount
      questionnaireCalculationMethod
      questionnaireValues
      syncedFromQuestionnaireAt
      correspondingSubformMinDeductibleTravelExpenses
      assets {
        id
        name
        filetype
        thumbnail
        fullsize
      }
      autoCalculatedValues
      lastStatusChange {
        status
        changedAt
        changedBy
      }
      changeLogs {
        changes
        changedAt
        changedBy
      }
      homeOfficeExpenses @include(if: $shouldIncludeHomeOfficeExpenses) {
        ...homeOfficeExpense
      }
      notes {
        ...noteData
      }
    }
  }
  ${HomeOfficeExpenseFragmentDoc}
  ${NoteDataFragmentDoc}
`;

/**
 * __useEuerDeclarationSubformQuery__
 *
 * To run a query within a React component, call `useEuerDeclarationSubformQuery` and pass it any options that fit your needs.
 * When your component renders, `useEuerDeclarationSubformQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEuerDeclarationSubformQuery({
 *   variables: {
 *      email: // value for 'email'
 *      type: // value for 'type'
 *      year: // value for 'year'
 *      shouldIncludeHomeOfficeExpenses: // value for 'shouldIncludeHomeOfficeExpenses'
 *   },
 * });
 */
export function useEuerDeclarationSubformQuery(
  baseOptions: Apollo.QueryHookOptions<
    EuerDeclarationSubformQuery,
    EuerDeclarationSubformQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    EuerDeclarationSubformQuery,
    EuerDeclarationSubformQueryVariables
  >(EuerDeclarationSubformDocument, options);
}
export function useEuerDeclarationSubformLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EuerDeclarationSubformQuery,
    EuerDeclarationSubformQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    EuerDeclarationSubformQuery,
    EuerDeclarationSubformQueryVariables
  >(EuerDeclarationSubformDocument, options);
}
export type EuerDeclarationSubformQueryHookResult = ReturnType<
  typeof useEuerDeclarationSubformQuery
>;
export type EuerDeclarationSubformLazyQueryHookResult = ReturnType<
  typeof useEuerDeclarationSubformLazyQuery
>;
export type EuerDeclarationSubformQueryResult = Apollo.QueryResult<
  EuerDeclarationSubformQuery,
  EuerDeclarationSubformQueryVariables
>;
