import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import moment from "moment-timezone";
import { saveAs } from "file-saver";
import {
  Button,
  Select,
  Input,
  Form,
  Modal,
  Dropdown,
  Menu,
  InputRef,
} from "antd";

import range from "lodash/range";

import api from "../../../api";
import { UstvaPeriod, PaymentFrequency } from "../../../types";
import { ContentWrapper, InputWrapper } from "../../common/styledComponents";
import { Fieldset, Legend } from "./styledComponents";
import { useLazyRequest } from "../../hooks/useRequest.hook";
import { formatAmountCurrency } from "../../../utils";
import SubNavigation, { VAT_TABS } from "./SubNavigation";
import useEmailParam from "../../hooks/useEmailParam";
import useSearchHotkey from "../../hooks/useSearchHotkey";

const finanzamt = require("finanzamt");

type integer = number;
const { Option } = Select;

interface UStVAElsterFields {
  "41": integer;
  "43": integer;
  "48": integer;
  "81": integer;
  "86": integer;
  "35": integer;
  "36": number;
  "89": integer;
  "60": integer;
  "21": integer;
  "45": integer;
  "46": integer;
  "47": number;
  "66": number;
  "61": number;
  "62": number;
  "67": number;
  "83": number;
}

interface UStVACustomFields {
  "81-gross19": number;
  "81-vat19": number;
  "86-gross7": number;
  "86-vat7": number;
  "66-vat19": number;
  "66-vat7": number;
  "66-gross19": number;
  "66-gross7": number;
  "89-vat19": number;
}

interface UStVAFields extends UStVAElsterFields, UStVACustomFields {}

enum ElsterFormFormat {
  XML = "application/vnd.elster+xml",
  JSON = "application/json",
}

export interface UStVA {
  accountId: number;
  firstName: string;
  lastName: string;
  street: string;
  postCode: string;
  city: string;
  taxNumber: string;
  vatPaymentFrequency: any;
  businessTradingName: string;
  year: string;
  period: string;
  vendorId: string;
  transactionsCount: number;
  uncategorizedCount?: number;
  creationDate: string;
  elsterFields: UStVAFields;
}

const ElsterRow = ({
  elsterField,
  description,
  amount,
  className,
  showZeroAmount,
}: {
  elsterField?: string;
  description: string;
  amount: number | undefined;
  className?: string;
  showZeroAmount?: boolean;
}) => {
  if (!amount && !showZeroAmount) {
    return null;
  }

  return (
    <tr className={className || "regular"}>
      <td>{elsterField}</td>
      <td>{formatAmountCurrency(amount)}</td>
      <td>{description}</td>
    </tr>
  );
};

export const formatValidationMessage = (message: string) => {
  if (message.startsWith("Not categorized transactions: ")) {
    return (
      <ul>
        {message
          .replace("Not categorized transactions: ", "")
          .split(",")
          .map((id) => (
            <li key={id}>
              <a
                href={`/transaction-view?id=${id}`}
                target="_blank"
                rel="noreferrer"
              >
                {id}
              </a>
            </li>
          ))}
      </ul>
    );
  }
  return <li key={message}>{message}</li>;
};

export const isCorrection = (warnings: string[] | undefined) =>
  !!warnings &&
  warnings.some((warning: string) => warning.startsWith("A statement for"));

const ElsterView = () => {
  const lastMonth = moment().subtract(1, "months");
  const preselectedYear = lastMonth.format("YYYY");
  const preselectedMonth = lastMonth.format("MM");
  const yearList = range(2020, moment().year() + 1);

  const [email, setEmail] = useEmailParam();
  const emailRef = useRef<InputRef>(null);
  useSearchHotkey(emailRef);
  const [period, setPeriod] = useState(preselectedMonth);
  const [year, setYear] = useState(preselectedYear);
  const [contentType, setContentType] = useState(ElsterFormFormat.XML);
  const [ustva, setUstva] = useState<UStVA | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [isAlreadySubmitted, setIsAlreadySubmitted] = useState(false);
  const [ustvaStatus, setUstvaStatus] = useState<any | null>(null);

  const [getUstvaElster, getUstvaElsterResponse] = useLazyRequest(
    async (
      email: string,
      year: number,
      period: UstvaPeriod,
      contentType: ElsterFormFormat
    ) => {
      setContentType(contentType);
      const accountId = await api.kontax.getAccountIdByEmail(email);
      return api.kontax.getUstvaElster(accountId, year, period, contentType);
    }
  );

  const getUstvaStatus = useCallback(
    async (email: string, period: string, year: number) => {
      try {
        const accountId = await api.kontax.getAccountIdByEmail(email);
        const response = await api.kontax.getUstvaStatus(
          accountId,
          period,
          year
        );
        setUstvaStatus(response);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log("error", error);
        setUstvaStatus(null);
      }
    },
    []
  );

  const [sendViaEric, sendViaEricResponse] = useLazyRequest(
    async (email: string, period: string, year: number) => {
      const accountId = await api.kontax.getAccountIdByEmail(email);
      return api.kontax.sendViaEric(accountId, period, year);
    }
  );

  useEffect(() => {
    if (getUstvaElsterResponse.data) {
      switch (contentType) {
        case ElsterFormFormat.XML:
          const blob = new Blob([getUstvaElsterResponse.data], {
            type: "application/xml",
          });
          saveAs(blob, "ustva.xml");
          getUstvaElsterResponse.data = undefined;
          break;
        case ElsterFormFormat.JSON:
          setUstva(JSON.parse(JSON.stringify(getUstvaElsterResponse.data)));
          setShowModal(true);
          getUstvaElsterResponse.data = undefined;
          break;
      }
    }
    // Found this when updating react-scripts package
    // Dependency should be getUstvaElsterResponse instead of getUstvaElsterResponse.data
    // (it should be tested before updating)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentType, getUstvaElsterResponse.data]);

  useEffect(() => {
    if (sendViaEricResponse.data) {
      const blob = new Blob([sendViaEricResponse.data], {
        type: "application/pdf",
      });
      saveAs(blob, "eric.pdf");
    }
  }, [sendViaEricResponse]);

  const handleSubmit = () => {
    getUstvaElster(email, Number.parseInt(year), period, contentType);
  };

  const generateFinanzamtLink = (taxNumber: string | undefined) => {
    if (!taxNumber) return;
    return `https://www.bzst.de/DE/Service/Behoerdenwegweiser/Finanzamtsuche/GemFa/finanzamtsuche_Formular.html?suche=${taxNumber.substr(
      0,
      4
    )}`;
  };

  const displayPeriodMatchingStatus = () => {
    const isValidPeriodMonthly =
      ustva?.vatPaymentFrequency === PaymentFrequency.MONTHLY &&
      [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
      ].includes(ustva?.period);
    const isValidPeriodQuarterly =
      ustva?.vatPaymentFrequency === PaymentFrequency.QUARTERLY &&
      ["Q1", "Q2", "Q3", "Q4"].includes(ustva?.period);
    const isValidPeriod = isValidPeriodMonthly || isValidPeriodQuarterly;
    if (!isValidPeriod) {
      return (
        <span
          style={{ color: "red" }}
          title="Der ausgewählte Zeitraum stimmt nicht mit dem im Benutzerprofil hinterlegten Umsatzsteuervoranmeldungs-Zyklus überein"
        >
          ⚠
        </span>
      );
    }
    return "";
  };

  const resetForm = () => {
    setIsAlreadySubmitted(false);
    setUstvaStatus(null);
  };

  const handlePreview = useCallback(() => {
    getUstvaElster(email, Number.parseInt(year), period, ElsterFormFormat.JSON);
  }, [email, year, period, getUstvaElster]);
  const handleDownloadXML = useCallback(() => {
    getUstvaElster(email, Number.parseInt(year), period, ElsterFormFormat.XML);
  }, [email, year, period, getUstvaElster]);

  const handleSendViaERIC = useCallback(() => {
    setIsAlreadySubmitted(true);
    sendViaEric(email, period, year);
  }, [email, period, year, sendViaEric]);
  const isEricButtonDisabled =
    ustvaStatus?.errors?.length || isAlreadySubmitted;

  const ActionMenu = useMemo(
    () => (
      <Menu>
        <Menu.Item key="DOWNLOAD_XML" onClick={handleDownloadXML}>
          Download XML
        </Menu.Item>
        <Menu.Item
          key="SEND_VIA_ERIC"
          disabled={isEricButtonDisabled}
          onClick={handleSendViaERIC}
        >
          Send via ERiC
          {isCorrection(ustvaStatus?.warnings) ? " (Korrekturmeldung)" : ""}
        </Menu.Item>
      </Menu>
    ),
    [handleDownloadXML, handleSendViaERIC, isEricButtonDisabled, ustvaStatus]
  );

  return (
    <ContentWrapper>
      <SubNavigation activeTab={VAT_TABS.ELSTER.key} />
      <Form onFinish={handleSubmit} initialValues={{ email }}>
        <Fieldset>
          <Legend>Choose client</Legend>
          <Form.Item
            label="E-mail"
            name="email"
            rules={[
              {
                required: true,
                type: "email",
                message: "Please enter a valid e-mail address",
              },
            ]}
          >
            <Input
              ref={emailRef}
              value={email || ""}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setEmail(event.target.value);
                resetForm();
              }}
            />
          </Form.Item>
          <InputWrapper>
            <Select
              id="period"
              style={{ width: "100%" }}
              value={period}
              onChange={(value) => {
                setPeriod(value);
                resetForm();
              }}
            >
              <Option value="01">January</Option>
              <Option value="02">February</Option>
              <Option value="03">March</Option>
              <Option value="04">April</Option>
              <Option value="05">May</Option>
              <Option value="06">June</Option>
              <Option value="07">July</Option>
              <Option value="08">August</Option>
              <Option value="09">September</Option>
              <Option value="10">October</Option>
              <Option value="11">November</Option>
              <Option value="12">December</Option>
              <Option value="Q1">Quarter 1</Option>
              <Option value="Q2">Quarter 2</Option>
              <Option value="Q3">Quarter 3</Option>
              <Option value="Q4">Quarter 4</Option>
            </Select>
          </InputWrapper>
          <InputWrapper>
            <Select
              style={{ width: "100%" }}
              id="year"
              value={year}
              onChange={(value) => {
                setYear(value);
                resetForm();
              }}
            >
              {yearList.map((e: number, key) => {
                return (
                  <Option key={key} value={e}>
                    {e}
                  </Option>
                );
              })}
            </Select>
          </InputWrapper>
          <Button
            disabled={!email}
            size="large"
            type="primary"
            htmlType="submit"
            onClick={(e) => {
              e.preventDefault();
              getUstvaStatus(email || "", period, parseInt(year));
            }}
          >
            Show
          </Button>
        </Fieldset>
        {ustvaStatus && (
          <>
            <Fieldset style={{ maxWidth: "500px" }}>
              {ustvaStatus?.errors.length ? (
                <section>
                  <legend>Errors</legend>
                  <ul>{ustvaStatus?.errors.map(formatValidationMessage)}</ul>
                </section>
              ) : null}
              {ustvaStatus?.warnings.length ? (
                <section>
                  <legend>Warnings</legend>
                  <ul>{ustvaStatus?.warnings.map(formatValidationMessage)}</ul>
                </section>
              ) : null}
              <section>
                <Dropdown.Button
                  size="large"
                  overlay={ActionMenu}
                  onClick={handlePreview}
                >
                  Preview
                </Dropdown.Button>
              </section>
            </Fieldset>
          </>
        )}

        {getUstvaElsterResponse.error && (
          <p>
            Sorry, that did not work ({getUstvaElsterResponse.error.message})
          </p>
        )}
        {sendViaEricResponse.error && (
          <p>Sorry, that did not work ({sendViaEricResponse.error.message})</p>
        )}
      </Form>
      <Modal
        visible={showModal}
        title={`Umsatzsteuer-Voranmeldung [${ustva?.accountId}] • Vorschau`}
        footer={null}
        width={800}
        onCancel={() => setShowModal(false)}
      >
        <div style={{ float: "left" }}>
          <strong>Datum:</strong>{" "}
          {moment(ustva?.creationDate).format("DD.MM.YYYY")}
          <br />
          <strong>Zeitraum:</strong> {ustva?.period}/{ustva?.year}{" "}
          {displayPeriodMatchingStatus()}
          <br />
          <br />
          <strong>Name:</strong> {ustva?.firstName} {ustva?.lastName}
          <br />
          <strong>Straße:</strong> {ustva?.street}
          <br />
          <strong>Ort:</strong> {ustva?.postCode} {ustva?.city}
        </div>
        <div style={{ float: "right" }}>
          <strong>Anzahl Transaktionen:</strong>
          <br />
          Kategorisiert: {ustva?.transactionsCount}
          <br />
          Unkategorisiert: {ustva?.uncategorizedCount}
          <br />
          <br />
          <strong>Steuer-Nr.:</strong> {ustva?.taxNumber}
          <br />
          <strong>Finanzamt:</strong>{" "}
          <a
            href={generateFinanzamtLink(ustva?.taxNumber)}
            target="_blank"
            rel="noopener noreferrer"
            style={{ fontSize: "16px" }}
          >
            {ustva?.taxNumber && finanzamt(ustva.taxNumber.substr(0, 4))?.name}
          </a>
        </div>
        <div style={{ clear: "both" }}></div>
        <table className="ustva">
          <thead>
            <tr>
              <th>ELSTER-Kennzahl</th>
              <th>Betrag</th>
              <th>Bezeichnung</th>
            </tr>
          </thead>
          <tbody>
            <ElsterRow
              elsterField="41"
              amount={ustva?.elsterFields[41]}
              description="Innergemeinschaftliche Lieferungen (§ 4 Nr. 1 Buchst. b UStG)"
            />
            <ElsterRow
              elsterField="43"
              amount={ustva?.elsterFields[43]}
              description="Steuerfreie Umsätze mit Vorsteuerabzug: Ausfuhrlieferungen, § 4 Nr. 2 bis 7 UStG"
            />
            <ElsterRow
              elsterField="48"
              amount={ustva?.elsterFields[48]}
              description="Steuerfreie Umsätze ohne Vorsteuerabzug: § 4 Nr. 8 bis 29 UStG"
            />
            <ElsterRow
              elsterField="81"
              amount={ustva?.elsterFields[81]}
              description="Steuerpflichtige Umsätze 19 % (Summe NETTO)"
            />
            <ElsterRow
              className="light"
              amount={ustva?.elsterFields["81-gross19"]}
              description="Steuerpflichtige Umsätze 19 % (Summe BRUTTO)"
            />
            <ElsterRow
              className="light"
              amount={ustva?.elsterFields["81-vat19"]}
              description="Steuerlast 19 %"
            />
            <ElsterRow
              elsterField="86"
              amount={ustva?.elsterFields[86]}
              description="Steuerpflichtige Umsätze 7 % (Summe NETTO)"
            />
            <ElsterRow
              className="light"
              amount={ustva?.elsterFields["86-gross7"]}
              description="Steuerpflichtige Umsätze 7 % (Summe BRUTTO)"
            />
            <ElsterRow
              className="light"
              amount={ustva?.elsterFields["86-vat7"]}
              description="Steuerlast 7 %"
            />
            <ElsterRow
              elsterField="35"
              amount={ustva?.elsterFields[35]}
              description="Steuerpflichtige Umsätze andere (Summe NETTO)"
            />
            <ElsterRow
              elsterField="36"
              amount={ustva?.elsterFields[36]}
              description="Steuerlast andere"
            />
            <ElsterRow
              elsterField="89"
              amount={ustva?.elsterFields[89]}
              description="Steuerpflichtige innergemeinschaftliche Erwerbe 19 % (Summe NETTO)"
            />
            <ElsterRow
              className="light"
              amount={ustva?.elsterFields["89-vat19"]}
              description="Steuerlast innergemeinschaftliche Erwerbe 19 %"
            />
            <ElsterRow
              elsterField="60"
              amount={ustva?.elsterFields[60]}
              description="Steuerpflichtige Umsätze nach § 13b Abs. 5 UStG"
            />
            <ElsterRow
              elsterField="21"
              amount={ustva?.elsterFields[21]}
              description="Nicht steuerbare sonstige Leistungen gem. § 18b Satz 1 Nr. 2 UStG"
            />
            <ElsterRow
              elsterField="45"
              amount={ustva?.elsterFields[45]}
              description="Übrige nicht steuerbare Umsätze (Leistungsort nicht im Inland)"
            />
            <ElsterRow
              elsterField="46"
              amount={ustva?.elsterFields[46]}
              description="Reverse-Charge (Summe NETTO)"
            />
            <ElsterRow
              elsterField="47"
              amount={ustva?.elsterFields[47]}
              description="Reverse Charge USt."
            />
            <ElsterRow
              elsterField="66"
              amount={ustva?.elsterFields[66]}
              description="Summe abziehbarer Vorsteuerbeträge"
            />
            <ElsterRow
              className="light"
              amount={ustva?.elsterFields["66-vat19"]}
              description="Steuerlast 19 %"
            />
            <ElsterRow
              className="light"
              amount={ustva?.elsterFields["66-vat7"]}
              description="Steuerlast 7 %"
            />
            <ElsterRow
              className="light"
              amount={ustva?.elsterFields["66-gross19"]}
              description="Besteuerte Ausgaben 19 % BRUTTO"
            />
            <ElsterRow
              className="light"
              amount={ustva?.elsterFields["66-gross7"]}
              description="Besteuerte Ausgaben 7 % BRUTTO"
            />
            <ElsterRow
              elsterField="61"
              amount={ustva?.elsterFields[61]}
              description="Vorsteuerbeträge aus dem Innerg. Erwerb von Gegenständen"
            />
            <ElsterRow
              elsterField="62"
              amount={ustva?.elsterFields[62]}
              description="Entstandene Einfuhrumsatzsteuer"
            />
            <ElsterRow
              elsterField="67"
              amount={ustva?.elsterFields[67]}
              description="Reverse Charge MwSt."
            />
            <ElsterRow
              className="emphasized"
              elsterField="83"
              amount={ustva?.elsterFields[83]}
              description="Umsatzsteuer-Vorauszahlung"
              showZeroAmount
            />
          </tbody>
          <tfoot>
            <tr>
              <th></th>
              <th></th>
              <th></th>
            </tr>
          </tfoot>
        </table>
      </Modal>
    </ContentWrapper>
  );
};

export default ElsterView;
