import React, { FC, useState } from "react";
import { Space, Button, Modal } from "antd";

import UploadForm from "./UploadForm";

export interface ButtonGroupsProps {
  loading: boolean;
  userEmail: string;
  onDeleteImportedData?: () => Promise<any>;
  refetchDatevData: () => Promise<any>;
}

const ButtonGroups: FC<ButtonGroupsProps> = ({
  loading,
  userEmail,
  onDeleteImportedData,
  refetchDatevData,
}) => {
  const [isImportModalVisible, setIsImportModalVisible] =
    useState<boolean>(false);

  const showDeleteConfirmationModal = () => {
    Modal.error({
      title:
        "Importierte Buchhaltungsdaten werden hiermit unwiederbringlich gelöscht",
      content:
        "Der Import wird dadurch aus dem System entfernt und kann zu Fehlern in bereits abgewickelter Buchhaltung führen.",
      onOk: onDeleteImportedData,
      okType: "danger",
      okText: "Verbindlich löschen",
      cancelText: "Abbrechen",
      closable: true,
      centered: true,
    });
  };

  const refreshDataAndCloseModal = async () => {
    await refetchDatevData();
    setIsImportModalVisible(false);
  };

  return (
    <div>
      <Space>
        <Button
          danger
          onClick={showDeleteConfirmationModal}
          disabled={loading || !onDeleteImportedData}
        >
          Buchhaltungsdaten löschen
        </Button>
        <Button
          type="primary"
          disabled={loading}
          onClick={() => setIsImportModalVisible(true)}
        >
          Weiterer Import
        </Button>
      </Space>
      <Modal
        title="Datev-Import"
        visible={isImportModalVisible}
        onCancel={() => setIsImportModalVisible(false)}
        footer={null}
      >
        <UploadForm
          userEmail={userEmail}
          refetchDatevData={refreshDataAndCloseModal}
        />
      </Modal>
    </div>
  );
};

export default ButtonGroups;
