import React from "react";
import { Modal } from "react-bootstrap";

import { Button } from "../common/styledComponents";
import {
  ModalHeading,
  ModalIconContainer,
  ModalText,
} from "../pages/Mandanten/ActivationView/UserDetails/BusinessAssetList/styledComponents";
import { ReactComponent as AssetsIcon } from "../../svgs/assets.svg";
import "./modal.css";

export const BusinessAssetConfirmationModal = ({
  show,
  onHide,
}: {
  show: boolean;
  onHide: () => void;
}) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      dialogClassName="business-asset-confirmation-modal"
      data-test="businessAssetConfirmationModel"
    >
      <Modal.Header closeButton />
      <Modal.Body>
        <ModalIconContainer>
          <AssetsIcon />
        </ModalIconContainer>
        <ModalHeading>Gegenstand erfolgreich hinzugefügt</ModalHeading>
        <ModalText>
          Du kannst diese Güter im Bereich Anlagevermögen des Kunden bearbeiten.
        </ModalText>
      </Modal.Body>
      <Modal.Footer>
        <Button data-test="okAssetSuccess" onClick={onHide}>
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default BusinessAssetConfirmationModal;
