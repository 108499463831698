import type * as Types from "../../schema.generated";

import { gql } from "@apollo/client";
import { TaxDeclarationSavedDraftDataFragmentDoc } from "../../fragments/taxDeclarationSavedDraftData.generated";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type IncomeTaxDeclarationSavedDraftInfoQueryVariables = Types.Exact<{
  email: Types.Scalars["String"];
  year: Types.Scalars["Int"];
}>;

export type IncomeTaxDeclarationSavedDraftInfoQuery = {
  __typename?: "Query";
  incomeTaxDeclaration: {
    __typename?: "IncomeTaxDeclaration";
    savedDraftInfo?: {
      __typename?: "TaxDeclarationSavedDraftInfo";
      createdAt?: string | null;
      createdBy?: string | null;
      updatedAt?: string | null;
      pdf?: string | null;
      calculationSheet?: string | null;
      externalAssets?: Array<{
        __typename?: "TaxDeclarationExternalAsset";
        url: string;
        filetype: string;
        createdAt: string;
      }> | null;
    } | null;
  };
};

export const IncomeTaxDeclarationSavedDraftInfoDocument = gql`
  query incomeTaxDeclarationSavedDraftInfo($email: String!, $year: Int!) {
    incomeTaxDeclaration(email: $email, year: $year) {
      savedDraftInfo {
        ...TaxDeclarationSavedDraftData
      }
    }
  }
  ${TaxDeclarationSavedDraftDataFragmentDoc}
`;

/**
 * __useIncomeTaxDeclarationSavedDraftInfoQuery__
 *
 * To run a query within a React component, call `useIncomeTaxDeclarationSavedDraftInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useIncomeTaxDeclarationSavedDraftInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIncomeTaxDeclarationSavedDraftInfoQuery({
 *   variables: {
 *      email: // value for 'email'
 *      year: // value for 'year'
 *   },
 * });
 */
export function useIncomeTaxDeclarationSavedDraftInfoQuery(
  baseOptions: Apollo.QueryHookOptions<
    IncomeTaxDeclarationSavedDraftInfoQuery,
    IncomeTaxDeclarationSavedDraftInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    IncomeTaxDeclarationSavedDraftInfoQuery,
    IncomeTaxDeclarationSavedDraftInfoQueryVariables
  >(IncomeTaxDeclarationSavedDraftInfoDocument, options);
}
export function useIncomeTaxDeclarationSavedDraftInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IncomeTaxDeclarationSavedDraftInfoQuery,
    IncomeTaxDeclarationSavedDraftInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    IncomeTaxDeclarationSavedDraftInfoQuery,
    IncomeTaxDeclarationSavedDraftInfoQueryVariables
  >(IncomeTaxDeclarationSavedDraftInfoDocument, options);
}
export type IncomeTaxDeclarationSavedDraftInfoQueryHookResult = ReturnType<
  typeof useIncomeTaxDeclarationSavedDraftInfoQuery
>;
export type IncomeTaxDeclarationSavedDraftInfoLazyQueryHookResult = ReturnType<
  typeof useIncomeTaxDeclarationSavedDraftInfoLazyQuery
>;
export type IncomeTaxDeclarationSavedDraftInfoQueryResult = Apollo.QueryResult<
  IncomeTaxDeclarationSavedDraftInfoQuery,
  IncomeTaxDeclarationSavedDraftInfoQueryVariables
>;
