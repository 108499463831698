import { IUser, PaymentFrequency, VatYearSetting } from "../types";

export const formatPaymentFrequency = (
  frequency?: PaymentFrequency | string
) => {
  switch (frequency) {
    case PaymentFrequency.MONTHLY:
      return "Monthly";
    case PaymentFrequency.QUARTERLY:
      return "Quarterly";
    case PaymentFrequency.YEARLY:
      return "Annually";
    case PaymentFrequency.NONE:
      return "Annually KU";
    case PaymentFrequency.NONE_QUARTERLY:
      return "Quarterly KU";
    default:
      return "";
  }
};

export const fetchVatPaymentFrequency = (
  user?: IUser,
  year?: number
): {
  /**
   * is small business owner
   */
  isKleinunternehmer: boolean;
  frequency: string;
  year: number;
} | null => {
  if (user?.vatYearSettings && user.vatYearSettings.length > 0) {
    let vatYearSetting: VatYearSetting | undefined;
    if (year) {
      vatYearSetting = user.vatYearSettings.find(
        (setting) => setting.year === year
      );
    }
    if (!vatYearSetting) {
      vatYearSetting = user.vatYearSettings.slice().pop() as VatYearSetting;
    }

    if (vatYearSetting.vatPaymentFrequency === PaymentFrequency.NONE) {
      return {
        isKleinunternehmer: true,
        frequency: "Annually",
        year: vatYearSetting.year,
      };
    }

    if (
      vatYearSetting.vatPaymentFrequency === PaymentFrequency.NONE_QUARTERLY
    ) {
      return {
        isKleinunternehmer: true,
        frequency: "Quarterly",
        year: vatYearSetting.year,
      };
    }

    return {
      isKleinunternehmer: false,
      frequency:
        formatPaymentFrequency(vatYearSetting.vatPaymentFrequency) || "",
      year: vatYearSetting.year,
    };
  }

  return null;
};
