import { AlignType } from "rc-table/lib/interface";

import { StatusColor } from "../../../../../constants";
import { VatAnnualDeclarationFieldType } from "../../../../../api/graphql";
import { VatAnnualDeclarationStatus } from "../../../../../api/graphql/schema.generated";

export const COLUMN_ALIGN_RIGHT = "right" as AlignType;

export const VAT_ANNUAL_DECLARATION_STATUS_MAPPINGS = {
  [VatAnnualDeclarationStatus.OPEN]: {
    color: StatusColor.GRAY,
    label: "Offen",
  },
  [VatAnnualDeclarationStatus.NOT_RELEVANT]: {
    color: StatusColor.GREEN,
    label: "Nicht relevant",
  },
  [VatAnnualDeclarationStatus.IN_PROGRESS_DATA]: {
    color: StatusColor.GOLD,
    label: "In Bearbeitung DATA",
  },
  [VatAnnualDeclarationStatus.CONSULTATION_DATA]: {
    color: StatusColor.GOLD,
    label: "Rückfrage DATA",
  },
  [VatAnnualDeclarationStatus.COMPLETED_BY_DATA]: {
    color: StatusColor.PURPLE,
    label: "Abgeschlossen DATA",
  },
  [VatAnnualDeclarationStatus.IN_PROGRESS_OPS]: {
    color: StatusColor.GOLD,
    label: "In Bearbeitung Ops",
  },
  [VatAnnualDeclarationStatus.COMPLETED_BY_OPS]: {
    color: StatusColor.PURPLE,
    label: "Abgeschlossen Ops",
  },
  [VatAnnualDeclarationStatus.IN_PROGRESS_TAX_CONSULTANT]: {
    color: StatusColor.GOLD,
    label: "In Bearbeitung SB",
  },
  [VatAnnualDeclarationStatus.APPROVED_BY_TAX_CONSULTANT]: {
    color: StatusColor.GREEN,
    label: "Abgeschlossen SB",
  },
  [VatAnnualDeclarationStatus.OBJECTED_BY_TAX_CONSULTANT]: {
    color: StatusColor.RED,
    label: "Vorbehalt SB",
  },
  [VatAnnualDeclarationStatus.IN_SUBMISSION_FOR_USER_APPROVAL]: {
    color: StatusColor.PURPLE,
    label: "Vorbereitung Kundenfreigabe",
    hidden: true,
  },
  [VatAnnualDeclarationStatus.WAITING_FOR_USER_APPROVAL]: {
    color: StatusColor.GOLD,
    label: "Warten auf Kundenfreigabe",
  },
  [VatAnnualDeclarationStatus.APPROVED_BY_USER]: {
    color: StatusColor.GREEN,
    label: "Von Kunde freigegeben",
  },
  [VatAnnualDeclarationStatus.OBJECTED_BY_USER]: {
    color: StatusColor.RED,
    label: "Vorbehalt Kunde",
  },
  [VatAnnualDeclarationStatus.SUBMITTED]: {
    color: StatusColor.PURPLE,
    label: "Eingereicht FA",
  },
  [VatAnnualDeclarationStatus.OBJECTED_BY_FINANZAMT]: {
    color: StatusColor.RED,
    label: "Vorbehalt FA",
  },
  [VatAnnualDeclarationStatus.RECEIVED_TAX_BILL]: {
    color: StatusColor.PURPLE,
    label: "Bescheid erhalten",
  },
  [VatAnnualDeclarationStatus.CLOSED]: {
    color: StatusColor.GREEN,
    label: "Abgeschlossen",
  },
  [VatAnnualDeclarationStatus.APPEAL_PROCESS_STARTED]: {
    color: StatusColor.RED,
    label: "Rechtsbehelfsverfahren begonnen",
  },
  [VatAnnualDeclarationStatus.APPEAL_PROCESS_COMPLETED]: {
    color: StatusColor.GREEN,
    label: "Rechtsbehelfsverfahren abgeschlossem",
  },
};

export const VAT_ANNUAL_DECLARATION_STATUSES = Object.keys(
  VAT_ANNUAL_DECLARATION_STATUS_MAPPINGS
) as VatAnnualDeclarationStatus[];

export const DATA_SOURCE_LABELS = {
  AUTO_CALCULATED: "Interne Daten",
  MANUAL: "Manuelle Eingabe",
};

export const SECTION_TITLES = {
  SALES_TAX: "Summe Umsatzsteuer",
  DEDUCTIBLE_INPUT_TAX: "Summe Abziehbare Vorsteuerbeträge",
  TAX_OFFICE_BALANCE: "Vorauszahlungssoll",
  ADDITIONAL_INFORMATION: "Ergänzende Angaben zu Umsätzen",
};

export const SECTION_ROW_CONFIGS = {
  SALES_TAX: {
    title: SECTION_TITLES.SALES_TAX,
    source: DATA_SOURCE_LABELS.AUTO_CALCULATED,
  },
  DEDUCTIBLE_INPUT_TAX: {
    title: SECTION_TITLES.DEDUCTIBLE_INPUT_TAX,
    source: DATA_SOURCE_LABELS.AUTO_CALCULATED,
  },
  TAX_OFFICE_BALANCE: {
    title: SECTION_TITLES.TAX_OFFICE_BALANCE,
    source: DATA_SOURCE_LABELS.MANUAL,
    editable: true,
  },
  ADDITIONAL_INFORMATION: {
    title: SECTION_TITLES.ADDITIONAL_INFORMATION,
    source: DATA_SOURCE_LABELS.AUTO_CALCULATED,
  },
};

export const VAT_ANNUAL_DECLARATION_FIELD_TYPE_LABELS: Record<
  VatAnnualDeclarationFieldType,
  string | string[]
> = {
  [VatAnnualDeclarationFieldType.TOTAL_INCOME_19]: "Summe Umsätze 19 %",
  [VatAnnualDeclarationFieldType.TOTAL_INCOME_7]: "Summe Umsätze 7 %",
  [VatAnnualDeclarationFieldType.INCOME_19]: "Steuerpflichtige Umsätze 19 %",
  [VatAnnualDeclarationFieldType.INCOME_7]: "Steuerpflichtige Umsätze 7 %",
  [VatAnnualDeclarationFieldType.INCOME_EU_B2C]:
    "Erlöse EU Sonstige Leistung (B2C)",
  [VatAnnualDeclarationFieldType.INCOME_EU_INTRA_B2C]:
    "Erlöse EU Innerg. Lieferung (B2C)",
  [VatAnnualDeclarationFieldType.TOTAL_INCOME_REDUCED_RATES]:
    "Summe andere Steuersätze",
  [VatAnnualDeclarationFieldType.INCOME_16]: "Steuerpflichtige Umsätze 16 %",
  [VatAnnualDeclarationFieldType.INCOME_5]: "Steuerpflichtige Umsätze 5 %",
  [VatAnnualDeclarationFieldType.INTRA_ACQUISITION_ST]:
    "Innerg. Erwerb (Umsatzsteuer)",
  [VatAnnualDeclarationFieldType.REVERSE_CHARGE_ST]:
    "Reverse Charge (Umsatzsteuer)",
  [VatAnnualDeclarationFieldType.REVERSE_CHARGE]: "Reverse Charge (nur USt)",
  [VatAnnualDeclarationFieldType.FREE_VALUE_DELIVERY_19]:
    "Unentgeltliche Wertabgaben (Lieferungen nach § 3 Absatz 1b UStG zu 19 %)",
  [VatAnnualDeclarationFieldType.FREE_BENEFITS]:
    "Unentgeltliche Wertabgaben (Sonstige Leistungen nach § 3 Abs. 9a UStG zu 19 %)",
  [VatAnnualDeclarationFieldType.CAR_USAGE]:
    "Umsatzsteuer aus privater KFZ Nutzung",
  [VatAnnualDeclarationFieldType.OTHER_USAGE_AND_SERVICE_WITHDRAWALS]:
    "Umsatzsteuer aus anderen sonstigen Leistungen",
  [VatAnnualDeclarationFieldType.FREE_VALUE_DELIVERY_7]:
    "Unentgeltliche Wertabgaben (Lieferungen nach § 3 Absatz 1b UStG zu 7 %)",
  [VatAnnualDeclarationFieldType.FREE_VALUE_SERVICE_7]:
    "Unentgeltliche Wertabgaben (Sonstige Leistungen nach § 3 Absatz 9a UStG zu 7 %)",
  [VatAnnualDeclarationFieldType.TOTAL_DIT]:
    "Summe abziehbare Vorsteuerbeträge",
  [VatAnnualDeclarationFieldType.DIT_5]: "Abziehbare Vorsteuerbeträge 5 %",
  [VatAnnualDeclarationFieldType.DIT_7]: "Abziehbare Vorsteuerbeträge 7 %",
  [VatAnnualDeclarationFieldType.DIT_16]: "Abziehbare Vorsteuerbeträge 16 %",
  [VatAnnualDeclarationFieldType.DIT_19]: "Abziehbare Vorsteuerbeträge 19 %",
  [VatAnnualDeclarationFieldType.HOME_OFFICE_DIT]:
    "Abziehbare Vorsteuerbeträge für häusliches Arbeitszimmer",
  [VatAnnualDeclarationFieldType.KKR_1850]:
    "KKR 1850 (Gezahlte Vorsteuerbeträge)",
  [VatAnnualDeclarationFieldType.INTRA_ACQUISITION_IT]:
    "Innerg. Erwerb (Vorsteuer)",
  [VatAnnualDeclarationFieldType.IMPORT_VAT]: "Einfuhrumsatzsteuer",
  [VatAnnualDeclarationFieldType.REVERSE_CHARGE_IT]:
    "Reverse Charge (Vorsteuer)",
  [VatAnnualDeclarationFieldType.INCOME_EU_INTRA_B2B]:
    "Erlöse EU Innerg. Lieferung (B2B)",
  [VatAnnualDeclarationFieldType.VAT_EXEMPTION_WITH_ITD]:
    "Weitere steuerfreie Umsätze mit Vorsteuerabzug",
  [VatAnnualDeclarationFieldType.EXPORT_DELIVERY]: "Ausfuhrlieferungen",
  [VatAnnualDeclarationFieldType.INCOME_0_ITD]: "Umsätze nach § 4 Nr. ... UStG",
  [VatAnnualDeclarationFieldType.VAT_EXEMPTION_WITHOUT_ITD]:
    "Steuerfreie Umsätze ohne Vorsteuerabzug nach § ... UStG",
  [VatAnnualDeclarationFieldType.INCOME_EU_B2B]:
    "Erlöse EU Sonstige Leistung (B2B)",
  [VatAnnualDeclarationFieldType.NON_TAXABLE]:
    "Übrige nicht steuerbare Umsätze (Leistungsort nicht im Inland)",
  [VatAnnualDeclarationFieldType.INCOME_13B5_USTG]:
    "Steuerpflichtige Umsätze des leistenden Unternehmers, für die der Leistungsempfänger die Steuer nach § 13b Abs. 5 UStG schuldet",
  [VatAnnualDeclarationFieldType.ELECTRONIC_SERVICE_EU_B2C]:
    "Elektronische Leistungen EU (B2C)",
};

export const VAT_ANNUAL_DECLARATION_FIELD_TYPE_CODES: Record<string, string> = {
  [VatAnnualDeclarationFieldType.TOTAL_INCOME_19]: "177",
  [VatAnnualDeclarationFieldType.TOTAL_INCOME_7]: "275",
  [VatAnnualDeclarationFieldType.TOTAL_INCOME_REDUCED_RATES]: "155/156",
  [VatAnnualDeclarationFieldType.INTRA_ACQUISITION_ST]: "781",
  [VatAnnualDeclarationFieldType.REVERSE_CHARGE_ST]: "846",
  [VatAnnualDeclarationFieldType.REVERSE_CHARGE]: "846",
  [VatAnnualDeclarationFieldType.FREE_VALUE_DELIVERY_19]: "178",
  [VatAnnualDeclarationFieldType.FREE_BENEFITS]: "179",
  [VatAnnualDeclarationFieldType.FREE_VALUE_DELIVERY_7]: "195",
  [VatAnnualDeclarationFieldType.FREE_VALUE_SERVICE_7]: "196",
  [VatAnnualDeclarationFieldType.TOTAL_DIT]: "320",
  [VatAnnualDeclarationFieldType.INTRA_ACQUISITION_IT]: "761",
  [VatAnnualDeclarationFieldType.IMPORT_VAT]: "762",
  [VatAnnualDeclarationFieldType.REVERSE_CHARGE_IT]: "467",
  [VatAnnualDeclarationFieldType.INCOME_EU_INTRA_B2B]: "741",
  [VatAnnualDeclarationFieldType.VAT_EXEMPTION_WITH_ITD]: "237",
  [VatAnnualDeclarationFieldType.VAT_EXEMPTION_WITHOUT_ITD]: "287",
  [VatAnnualDeclarationFieldType.INCOME_EU_B2B]: "721",
  [VatAnnualDeclarationFieldType.NON_TAXABLE]: "205",
  [VatAnnualDeclarationFieldType.INCOME_13B5_USTG]: "209",
  [VatAnnualDeclarationFieldType.ELECTRONIC_SERVICE_EU_B2C]: "213",
};
