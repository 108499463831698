import React from "react";

import { Toggle, ToggleText, ToggleWrapper } from "./styledComponents";

type Props = {
  onChange?: (value: boolean) => void;
  value: boolean;
  disabled?: boolean;
  label?: string;
  text?: string;
};

const ToggleButton = ({
  value,
  onChange,
  disabled = false,
  label,
  text,
}: Props) => {
  const onClick = () => onChange && onChange(!value);
  return (
    <ToggleWrapper onClick={onClick} disabled={disabled}>
      <Toggle checked={value} disabled={disabled} />
      {(label || text) && (
        <ToggleText>
          <strong>{label}</strong>
          <span>{text}</span>
        </ToggleText>
      )}
    </ToggleWrapper>
  );
};

export default ToggleButton;
