import React, { useCallback, useState, ChangeEvent, useRef } from "react";
import { Card, Input, InputRef, Row } from "antd";

import { CheckboxValueType } from "antd/lib/checkbox/Group";

import { VatRateWrapper } from "./styledComponents";
import RadioButtonGroup from "../RadioButtonGroup";
import { DateFiltersWrapper } from "../styledComponents";
import {
  Direction,
  KontaxTransactionFilterOptions,
  FilterSource,
} from "../../../types";
import VatPaymentFrequencyFilter from "./VatPaymentFrequencyFilter";
import PlanFilter from "./PlanFilter";
import DateFiltersPicker from "../DateFiltersPicker";
import DropdownMultiSelect from "../DropdownMultiSelect";
import {
  incomingCategoriesRaw,
  outgoingCategoriesRaw,
  vatCategories as allVatCategories,
} from "../../../utils";
import FilterBoxCard from "../Card/FilterBoxCard";
import { getBerlinMomentTimezone } from "../helpers";
import useSearchHotkey from "../../hooks/useSearchHotkey";
import ShowCount from "./ShowCount";

const { Search } = Input;

const VAT_CATEGORY_OPTIONS = allVatCategories.map(([value, label]) => ({
  value,
  label,
}));

const CATEGORY_OPTIONS = [
  ...incomingCategoriesRaw,
  ...outgoingCategoriesRaw,
].map(([value, label]) => ({ value, label }));

export enum Filter {
  STATUS = "status",
  ASSETS = "assets",
  VAT_PAYMENT_FREQUENCY = "vat-payment-frequency",
  PLAN = "plan",
  VAT_CATEGORIES = "vat-categories",
  CATEGORIES = "categories",
  SOURCE = "source",
  CATEGORIZED = "categorized",
}

const ACTION_REASON = "ACTION_REASON";

type Props = {
  onChangeHandler: (
    filterOptions: Partial<KontaxTransactionFilterOptions>
  ) => void;
  hiddenFilters?: Filter[];
  showCount?: boolean;
  count?: number;
  fetchTransactionCount?: () => void;
} & KontaxTransactionFilterOptions;

const TransactionFilters = ({
  email,
  direction,
  source,
  dateFrom,
  dateTo,
  vatCategoryCodes,
  categories,
  onChangeHandler,
  hiddenFilters = [],
  plans,
  vatPaymentFrequency,
  categorized,
  showCount,
  count,
  fetchTransactionCount = () => {},
}: Props) => {
  const [emailInput, setEmailInput] = useState(email);
  const searchRef = useRef<InputRef>(null);
  useSearchHotkey(searchRef);

  const onEscalatedChange = (value: boolean | string) => {
    if (value === ACTION_REASON) {
      onChangeHandler({ actionReason: true });
    }

    if (typeof value == "boolean") {
      onChangeHandler({ escalated: value, actionReason: false });
    }
  };

  const includesFilter = useCallback(
    (filter: Filter) => !hiddenFilters.includes(filter),
    [hiddenFilters]
  );

  return (
    <Card bordered={false} bodyStyle={{ padding: "0" }}>
      <Row>
        <FilterBoxCard title="Email">
          <Search
            ref={searchRef}
            value={emailInput}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              setEmailInput(event.target.value);
            }}
            onSearch={(email) => {
              onChangeHandler({ email });
            }}
            id="email"
            placeholder="Enter Email"
            allowClear
            enterButton="Filter"
          />
          {showCount ? (
            <ShowCount
              count={count}
              fetchTransactionCount={fetchTransactionCount}
            />
          ) : null}
        </FilterBoxCard>
        {includesFilter(Filter.CATEGORIZED) && (
          <FilterBoxCard data-test="bookingStatusRadios" title="Booking status">
            <RadioButtonGroup
              value={categorized}
              options={[
                { label: "All", value: undefined },
                {
                  label: "Not booked",
                  value: false,
                },
                {
                  label: "Booked, not verified",
                  value: true,
                },
              ]}
              onChange={(categorized?: boolean) => {
                onChangeHandler({ categorized });
              }}
            />
          </FilterBoxCard>
        )}
        <FilterBoxCard data-test="transactionDirectionTypeRadios" title="Type">
          <RadioButtonGroup
            value={direction}
            options={[
              { label: "All", value: undefined },
              {
                label: "Incoming",
                value: Direction.INCOMING,
              },
              {
                label: "Outgoing",
                value: Direction.OUTGOING,
              },
            ]}
            onChange={(direction: Direction) => {
              onChangeHandler({ direction });
            }}
          />
        </FilterBoxCard>
        {includesFilter(Filter.SOURCE) && (
          <FilterBoxCard data-test="transactionSourceRadios" title="Source">
            <RadioButtonGroup
              value={source}
              options={[
                { label: "All", value: undefined },
                {
                  label: "Bank",
                  value: FilterSource.BANK,
                },
                {
                  label: "External",
                  value: FilterSource.EXTERNAL,
                },
              ]}
              onChange={(source: FilterSource) => {
                onChangeHandler({ source });
              }}
            />
          </FilterBoxCard>
        )}

        {includesFilter(Filter.ASSETS) && (
          <FilterBoxCard data-test="invoicesRadios" title="Invoices">
            <RadioButtonGroup
              value={true}
              options={[
                { label: "Yes", value: true },
                { label: "No", value: false },
              ]}
              onChange={(assets: boolean) => {
                onChangeHandler({ assets });
              }}
            />
          </FilterBoxCard>
        )}
        {includesFilter(Filter.STATUS) && (
          <FilterBoxCard data-test="transactionStatusRadios" title="Status">
            <RadioButtonGroup
              value={false}
              options={[
                { label: "Normal", value: false },
                { label: "Escalated", value: true },
                { label: "User action required", value: ACTION_REASON },
              ]}
              onChange={(value: boolean | string) => {
                onEscalatedChange(value);
              }}
            />
          </FilterBoxCard>
        )}
        {includesFilter(Filter.VAT_PAYMENT_FREQUENCY) && (
          <VatPaymentFrequencyFilter
            onChangeHandler={onChangeHandler}
            value={vatPaymentFrequency}
          />
        )}
        {includesFilter(Filter.PLAN) && (
          <PlanFilter onChangeHandler={onChangeHandler} value={plans} />
        )}
        <FilterBoxCard title="Date filters">
          <DateFiltersWrapper data-test="transactionDateFilters">
            <DateFiltersPicker
              dateFrom={dateFrom ?? ""}
              dateTo={dateTo ?? ""}
              maxDate={getBerlinMomentTimezone().format()}
              onChange={onChangeHandler}
            />
          </DateFiltersWrapper>
        </FilterBoxCard>
        {(includesFilter(Filter.VAT_CATEGORIES) ||
          includesFilter(Filter.CATEGORIES)) && (
          <FilterBoxCard
            data-test="transactionCategoryFilters"
            title="Category filters"
          >
            {includesFilter(Filter.VAT_CATEGORIES) && (
              <VatRateWrapper>
                <DropdownMultiSelect
                  name="vat-category"
                  placeholder="All VAT categories"
                  values={vatCategoryCodes || []}
                  options={VAT_CATEGORY_OPTIONS}
                  onChange={(values: CheckboxValueType[]) =>
                    onChangeHandler({ vatCategoryCodes: values })
                  }
                  withFilter={false}
                />
              </VatRateWrapper>
            )}
            {includesFilter(Filter.CATEGORIES) && (
              <DropdownMultiSelect
                name="category"
                placeholder="All categories"
                values={categories || []}
                options={CATEGORY_OPTIONS}
                onChange={(values: CheckboxValueType[]) =>
                  onChangeHandler({ categories: values })
                }
                moveSelectedToTheTop
                withCheckAll
              />
            )}
          </FilterBoxCard>
        )}
      </Row>
    </Card>
  );
};

export default TransactionFilters;
