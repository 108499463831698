import translation from "../locales/en/translation.json";
import ServiceTracker from "../locales/en/ServiceTracker.json";
import Navigation from "../locales/en/Navigation.json";
import Onboarding from "../locales/en/Onboarding.json";

const translations = {
  translation,
  ServiceTracker,
  Navigation,
  Onboarding,
};

export default translations;
