import type * as Types from "../schema.generated";

import { gql } from "@apollo/client";
export type HomeOfficeDocumentFragment = {
  __typename?: "HomeOfficeDocument";
  id: string;
  type: Types.HomeOfficeDocumentType;
  note: string;
  syncedFromQuestionnaireAt?: string | null;
  assets: Array<{
    __typename?: "Asset";
    id: string;
    name: string;
    filetype: string;
    thumbnail: string;
    fullsize: string;
  }>;
};

export const HomeOfficeDocumentFragmentDoc = gql`
  fragment HomeOfficeDocument on HomeOfficeDocument {
    id
    type
    note
    syncedFromQuestionnaireAt
    assets {
      id
      name
      filetype
      thumbnail
      fullsize
    }
  }
`;
