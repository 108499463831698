import React from "react";
import { Col, Divider, Row, Typography } from "antd";

import { formatAmountInCents } from "../../../../../utils";
import { getVatAnnualDeclarationGrandTotalDisplayData } from "./utils";

const { Text } = Typography;

const DEFAULT_LABEL_OFFSET = 49;
const TABLE_CELL_PADDING = 16;

export interface VatAnnualDeclarationTableFooterProps {
  total: number;
  labelOffset?: number;
}

const VatAnnualDeclarationTableFooter = ({
  total,
  labelOffset = DEFAULT_LABEL_OFFSET,
}: VatAnnualDeclarationTableFooterProps) => {
  const { label, color, displayAmount } =
    getVatAnnualDeclarationGrandTotalDisplayData(total);
  return (
    <Row justify="space-between">
      <Col>
        <Divider
          type="vertical"
          style={{
            marginLeft: labelOffset - TABLE_CELL_PADDING,
            marginRight: TABLE_CELL_PADDING,
          }}
        />
        <Text strong style={{ color }}>
          {label}
        </Text>
      </Col>
      <Col>
        <Text strong style={{ color }}>
          {formatAmountInCents(displayAmount, true)}
        </Text>
      </Col>
    </Row>
  );
};

export default VatAnnualDeclarationTableFooter;
