import React, { useCallback } from "react";

import {
  TaxServiceDetails,
  TaxServiceStatus,
} from "../../../api/graphql/schema.generated";
import { NoteSection } from "../VerificationView/TransactionList/NoteSection";
import {
  destroyMessage,
  showGraphQlErrorNotification,
  showLoadingMessage,
} from "../../../utils";
import { useUpsertTaxServiceMutation } from "../../../api/graphql/mutations/taxService/upsertTaxService.generated";
import { NotesContainer } from "./styledComponent";

const UPDATING_NOTE_MESSAGE_KEY = "updating-tax-service-note";

enum NoteType {
  EXTERNAL = "EXTERNAL",
  INTERNAL = "INTERNAL",
}

const TaxServicesTableExpandRow = ({
  taxService,
  onRefresh,
}: {
  taxService: TaxServiceDetails;
  onRefresh: () => void;
}) => {
  const [upsertTaxService] = useUpsertTaxServiceMutation();

  const handleUpsertNote = useCallback(
    async (note: string, noteType: NoteType) => {
      showLoadingMessage(UPDATING_NOTE_MESSAGE_KEY);
      try {
        await upsertTaxService({
          variables: {
            email: taxService.kontaxUser.kontistUser.email!,
            payload: {
              id: taxService.id,
              department: taxService.department,
              name: taxService.name,
              status: taxService.status,
              externalNote:
                noteType === NoteType.EXTERNAL ? note : taxService.externalNote,
              internalNote:
                noteType === NoteType.INTERNAL ? note : taxService.internalNote,
            },
          },
        });
        onRefresh();
        return true;
      } catch (error) {
        showGraphQlErrorNotification(
          `Updating tax service internal note`,
          error
        );
        return false;
      } finally {
        destroyMessage(UPDATING_NOTE_MESSAGE_KEY);
      }
    },
    [onRefresh, upsertTaxService, taxService]
  );

  return (
    <NotesContainer>
      <NoteSection
        title="Interner Kommentar"
        addButtonLabel="Interner Kommentar"
        initialValue={taxService.internalNote}
        onSave={(note: string) => handleUpsertNote(note, NoteType.INTERNAL)}
        maxLength={250}
      />
      {(taxService.status === TaxServiceStatus.BLOCKED ||
        !!taxService.externalNote?.length) && (
        <NoteSection
          title="Kommentar für Kunde (wird auf Rechnung angezeigt)"
          addButtonLabel="Kommentar für Kunde"
          initialValue={taxService.externalNote}
          onSave={(note: string) => handleUpsertNote(note, NoteType.EXTERNAL)}
          maxLength={250}
          uneditable
        />
      )}
    </NotesContainer>
  );
};

export default TaxServicesTableExpandRow;
