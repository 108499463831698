import React, { useState } from "react";
import { notification } from "antd";
import styled from "styled-components";

import { Asset, ILocalAsset } from "../../types";
import { useDeleteAssetMutation } from "../../api/graphql/mutations";
import DeleteButton from "./DeleteButton";

export const texts = {
  title: "Willst du den Beleg löschen?",
  okText: "Beleg löschen",
  cancelText: "Abbrechen",
};

const Wrapper = styled.span`
  z-index: 999;
  top: 10px;
  left: 10px;
  position: absolute;

  button {
    display: flex;
    align-items: center;
  }
`;

const DeleteAssetButton = (props: {
  asset: Asset | ILocalAsset;
  onConfirm?: (
    asset: Asset | ILocalAsset,
    deletedRemoteAsset?: boolean
  ) => void;
  style?: React.CSSProperties;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [deleteAssetHandler] = useDeleteAssetMutation();

  const handleConfirm = async () => {
    setIsLoading(true);

    try {
      let deletedRemoteAsset = false;
      if ("id" in props.asset) {
        const result = await deleteAssetHandler({
          variables: { id: props.asset.id as string },
        });
        if (result.data?.deleteAsset?.success) {
          deletedRemoteAsset = true;
          notification.success({ message: "Asset was removed" });
        }
      }
      props.onConfirm && props.onConfirm(props.asset, deletedRemoteAsset);
    } catch (err) {
      notification.error({
        message: `An errorr occurred while deleting asset: ${
          (err as Error).message
        }`,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Wrapper style={props.style}>
      <DeleteButton
        isLoading={isLoading}
        handleConfirm={handleConfirm}
        title={texts.title}
        okText={texts.okText}
        cancelText={texts.cancelText}
      />
    </Wrapper>
  );
};

export default DeleteAssetButton;
