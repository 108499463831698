import React, { useState, useCallback, ChangeEvent, useRef } from "react";
import { Input, Button, Space, Card, Row, InputRef } from "antd";

import RadioButtonGroup from "../../../common/RadioButtonGroup";
import {
  NextVatDeclarationStatus,
  NextVatDeclarationStatusLabel,
} from "../../../../types";
import VatPaymentFrequencyFilter from "../../../common/TransactionFilters/VatPaymentFrequencyFilter";
import PlanFilter from "../../../common/TransactionFilters/PlanFilter";
import { PaymentFrequency, KontaxPlan } from "../../../../types";
import FilterBoxCard from "../../../common/Card/FilterBoxCard";
import useSearchHotkey from "../../../hooks/useSearchHotkey";

const { Search } = Input;

export interface UserFilterOptions {
  email: string;
  status?: NextVatDeclarationStatus;
  vatPaymentFrequency?: PaymentFrequency;
  plans?: Array<KontaxPlan>;
}

const UserFilters = ({
  filterOptions,
  setFilterOptions,
  loadAll,
}: {
  filterOptions: UserFilterOptions;
  setFilterOptions: (filterOptions: Partial<UserFilterOptions>) => void;
  loadAll: () => void;
}) => {
  const { email, status, vatPaymentFrequency, plans } = filterOptions;
  const [emailValue, setEmailValue] = useState(email);
  const searchRef = useRef<InputRef>(null);
  useSearchHotkey(searchRef);
  const onChangeHandler = useCallback(
    (updatedFilterOptions: Partial<UserFilterOptions>) => {
      !updatedFilterOptions.email && setEmailValue("");
      setFilterOptions({
        ...filterOptions,
        ...updatedFilterOptions,
        ...(!updatedFilterOptions.email && { email: "" }),
      });
    },
    [filterOptions, setFilterOptions]
  );

  return (
    <Card bordered={false} bodyStyle={{ padding: "0" }}>
      <Row>
        <FilterBoxCard title="Email">
          <Space>
            <Search
              ref={searchRef}
              value={emailValue}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setEmailValue(e.target.value);
              }}
              onSearch={(email) => {
                onChangeHandler({ email });
              }}
              id="email"
              placeholder="Enter Email"
              allowClear
              enterButton="Filter"
              style={{ width: "auto" }}
            />

            <Button
              type="default"
              onClick={(event) => {
                event.preventDefault();
                setEmailValue("");
                loadAll();
              }}
            >
              Load All
            </Button>
          </Space>
        </FilterBoxCard>
        <FilterBoxCard title="Declaration status">
          <RadioButtonGroup
            isReset={status === undefined}
            options={[
              { label: "All", value: undefined },
              {
                label: NextVatDeclarationStatusLabel.PENDING,
                value: NextVatDeclarationStatus.PENDING,
              },
              {
                label: NextVatDeclarationStatusLabel.READY_TO_CHECK,
                value: NextVatDeclarationStatus.READY_TO_CHECK,
              },
              {
                label: NextVatDeclarationStatusLabel.READY_TO_SEND,
                value: NextVatDeclarationStatus.READY_TO_SEND,
              },
              {
                label: NextVatDeclarationStatusLabel.BLOCKED,
                value: NextVatDeclarationStatus.BLOCKED,
              },
              {
                label: NextVatDeclarationStatusLabel.READY_TO_BOOK,
                value: NextVatDeclarationStatus.READY_TO_BOOK,
              },
            ]}
            onChange={(value: NextVatDeclarationStatus) => {
              onChangeHandler({ status: value });
            }}
          />
        </FilterBoxCard>
        <VatPaymentFrequencyFilter
          fetchAll
          onChangeHandler={onChangeHandler}
          value={vatPaymentFrequency}
        />
        <PlanFilter onChangeHandler={onChangeHandler} value={plans} />
      </Row>
    </Card>
  );
};

export default UserFilters;
