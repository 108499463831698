import { Button, Col, Row } from "antd";
import { useMemo, useState } from "react";
import { UploadOutlined } from "@ant-design/icons";

import { TaxCaseExternalContainer } from "./style";
import { TaxBoardUser } from "../../../../../api/graphql/schema.generated";
import CreateDeclarationDocumentModal from "./CreateDeclarationDocumentModal";
import DeclarationDocuments from "./DeclarationDocuments";

const TaxCaseExternal = ({
  taxBoardUser,
  onRefresh,
}: {
  taxBoardUser: TaxBoardUser;
  onRefresh: () => void;
}) => {
  const [showDeclarationDocumentModal, setShowDeclarationDocumentModal] =
    useState<boolean>(false);

  const declarationDocuments = useMemo(
    () => [
      {
        title: "Anlage EÜR inkl. AV",
        documents: taxBoardUser.euerDeclarations[0].declarationDocuments,
      },
      {
        title: "Umsatzsteuererklärung",
        documents: taxBoardUser.vatAnnualDeclarations[0].declarationDocuments,
      },
      {
        title: "Gewerbesteuererklärung",
        documents: taxBoardUser.tradeTaxDeclarations[0].declarationDocuments,
      },
      {
        title: "Einkommensteuererklärung",
        documents: taxBoardUser.incomeTaxDeclarations[0].declarationDocuments,
      },
    ],
    [taxBoardUser]
  );

  return (
    <TaxCaseExternalContainer>
      <Col>
        <Row gutter={8} style={{ marginBottom: "35px" }}>
          <Button
            size="large"
            icon={<UploadOutlined />}
            onClick={() => setShowDeclarationDocumentModal(true)}
          >
            Importieren
          </Button>
          <CreateDeclarationDocumentModal
            taxBoardUser={taxBoardUser}
            visible={showDeclarationDocumentModal}
            onSuccess={() => onRefresh()}
            onClose={() => setShowDeclarationDocumentModal(false)}
          />
        </Row>
        {declarationDocuments.map((d, index) => {
          return (
            <Row key={index}>
              <DeclarationDocuments
                title={d.title}
                declarationDocuments={d.documents}
                onRefresh={onRefresh}
              />
            </Row>
          );
        })}
      </Col>
    </TaxCaseExternalContainer>
  );
};

export default TaxCaseExternal;
