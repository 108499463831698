import { DEFAULT_LOCALE } from "../../../constants";
import { EuerElsterFields } from "../../../types";
import elsterFields from "./elsterFields";

const headerKeyName = "ekz";
const headerValueName = "value";

// generateCSV returns a standard format for EUER data,
// including all required fields with defaults set to 0
export function generateCSV(euerElsterFields: EuerElsterFields) {
  const rows = [`"${headerKeyName}";"${headerValueName}"`];

  elsterFields.forEach((definition, code) => {
    const value = euerElsterFields[code]
      ? Number(euerElsterFields[code]).toLocaleString(DEFAULT_LOCALE, {
          minimumFractionDigits: 2,
        })
      : "0,00";
    rows.push(`"${code}";"${value}"`);
  });

  return rows.join("\n");
}

export function downloadCSV(
  accountId: string,
  euerElsterFields: EuerElsterFields
) {
  const text = generateCSV(euerElsterFields);
  const filename = `bwa-${accountId}.csv`;

  const element = document.createElement("a");
  element.setAttribute(
    "href",
    `data:text/csv;charset=utf-8,${encodeURIComponent(text)}`
  );
  element.setAttribute("download", filename);
  element.style.display = "none";
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}
