import moment from "moment-timezone";

import { KontaxTransactionFilterOptions } from "../../types";
import { endOfBerlinDay, startOfBerlinDay } from "./helpers";

export const getDefaultFilterState = (): KontaxTransactionFilterOptions => ({
  escalated: false,
  dateFrom: startOfBerlinDay(moment().subtract(3, "months").toISOString()),
  dateTo: endOfBerlinDay(moment().toISOString()),
  vatPaymentFrequency: undefined,
  plans: undefined,
  vatCategoryCodes: undefined,
  categories: undefined,
});
