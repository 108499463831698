import styled from "styled-components";

export const TaxServiceContainer = styled.div`
  margin-top: 50px;
`;

export const TaxServiceModalContent = styled.div`
  .ant-form-item {
    margin-bottom: 10px;
  }
  div.ant-typography {
    margin-bottom: 0px;
  }
`;

export const NotesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;
