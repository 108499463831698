import {
  BookingMode,
  BookkeepingTool,
  Direction,
  SKRMode,
} from "../../../../../../api/graphql";

export const BOOKKEEPING_TOOL_LABELS = {
  [BookkeepingTool.DATEV]: "DATEV",
  [BookkeepingTool.LEXOFFICE]: "lexoffice",
  [BookkeepingTool.DEBITOOR]: "Debitoor",
  [BookkeepingTool.SEVDESK]: "SevDesk",
  [BookkeepingTool.FREE_TEXT]: "FREE_TEXT",
};

export const SKR_MODE_LABELS = {
  [SKRMode.SKR_03]: "SKR 03",
  [SKRMode.SKR_04]: "SKR 04",
};

export const BOOKING_MODE_LABELS = {
  [BookingMode.ACCOUNTANT]: "Buchhaltung",
  [BookingMode.ACCOUNT_PAYABLE]: "Debitoren- und Kreditorenbuchhaltung",
};

export const DIRECTION_LABELS = {
  [Direction.INCOMING]: "INCOMING",
  [Direction.OUTGOING]: "OUTGOING",
};

export const INVALID_VALUE = "unbekannt";
export const DEPRECIATION = " abschreibung";
