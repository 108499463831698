import React, { ReactNode } from "react";

import EditableNumber from "../../components/EditableNumber";

export interface EditableNumberCellProps {
  editable: boolean;
  negativeEnabled?: boolean;
  value: number | null;
  onEdit: (value: number | null) => void;
  children?: ReactNode;
}

const EditableNumberCell = ({
  editable,
  negativeEnabled,
  children,
  value,
  onEdit,
  ...restProps
}: EditableNumberCellProps) => {
  return (
    <td {...restProps}>
      <EditableNumber
        value={value}
        editable={editable}
        negativeEnabled={negativeEnabled}
        onEdit={onEdit}
      >
        {children}
      </EditableNumber>
    </td>
  );
};

export default EditableNumberCell;
