import React, { useEffect, useState } from "react";

import UserFilters, { UserFilterOptions } from "../Common/UserFilters";
import UserList from "./UserList";
import useEmailParam from "../../../hooks/useEmailParam";
import { isEmail } from "../../../../utils";
import { ContentWrapper } from "../../../common/styledComponents";
import { TableParams } from "../../../../types";

const OnboardingView = () => {
  const [emailParam, setEmailParam] = useEmailParam();
  const [searchParam, setSearchParam] = useState<string | null | undefined>(
    emailParam
  );
  const [filterOptions, setFilterOptions] = useState<UserFilterOptions>({
    search: searchParam || "",
    all: false,
  });

  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  useEffect(() => {
    // If no account selected, auto click `Load all` button.
    if (!emailParam) {
      setFilterOptions({ all: true, search: "", myCases: false });
    }
  }, [emailParam]);

  return (
    <ContentWrapper>
      <UserFilters
        filterOptions={filterOptions}
        setFilterOptions={(
          updatedFilterOptions: Partial<UserFilterOptions>
        ) => {
          if (!updatedFilterOptions.all) {
            setSearchParam(updatedFilterOptions.search);
            isEmail(updatedFilterOptions.search || "")
              ? setEmailParam(updatedFilterOptions.search)
              : setEmailParam(undefined);
          }
          setFilterOptions({
            ...filterOptions,
            ...updatedFilterOptions,
          });
          setTableParams({
            ...tableParams,
            pagination: {
              ...tableParams.pagination,
              current: 1,
            },
          });
        }}
      />
      <UserList
        filterOptions={filterOptions}
        setEmailParam={setEmailParam}
        tableParams={tableParams}
        setTableParams={setTableParams}
      />
    </ContentWrapper>
  );
};

export default OnboardingView;
