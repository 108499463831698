import { ChangeEvent, useState, useEffect, useCallback } from "react";
import moment from "moment-timezone";
import omit from "lodash/omit";
import { notification, Modal } from "antd";
import isNumber from "lodash/isNumber";

import { useLazyRequest } from "../../hooks/useRequest.hook";
import api from "../../../api";
import {
  KontaxTransactionMeta,
  KontaxTransactionFilterOptions,
  ITransaction,
  Asset,
  TransactionSplit,
  BusinessAssetFormField,
  PaymentFrequency,
} from "../../../types";
import {
  CategorisationWrapper,
  InvoiceWrapper,
  Banner,
  IconBackgroundSmall,
  SpinnerBasicCentered,
} from "./styledComponents";
import { EMPTY_TRANSACTION } from "./constants";
import TransactionFilters, { Filter } from "../../common/TransactionFilters";
import { getDefaultFilterState } from "../../common/filter";
import { ReactComponent as EnvelopeIcon } from "../../../svgs/envelope.svg";
import TransactionDetails from "./TransactionDetails";
import { TransactionSplitState } from "../../common/types";
import immovableAssets from "../../BusinessAsset/immovableAssets";
import movableAssets from "../../BusinessAsset/movableAssets.json";
import {
  BusinessAssetType,
  DepreciableCategory,
  ICreateBusinessAssetFromTransactionPayload,
} from "../../../types";
import {
  DEPRECIABLE_CATEGORY_CODES,
  LATEST_POSSIBLE_PURCHASE_DATE,
} from "../../BusinessAsset/constants";
import {
  getToBeDeletedBusinessAssets,
  isImmovableAsset,
  isNoteRequired,
} from "../../BusinessAsset/utils";
import EmptyWrapper from "../../common/EmptyWrapper";
import useEmailParam from "../../hooks/useEmailParam";
import { createTransactionAsset } from "../../common/DragAndDrop/actionHandler";
import Upload from "../../common/Upload";
import LocalAssetsViewer from "../../common/LocalAssetsViewer";
import { getErrorMessage } from "../../../utils/error";
import { ActionButtons } from "./ActionButtons";
import {
  isTransactionCategorized,
  shouldRefetchTransactionOnAssetUpload,
} from "./helpers";
import { useDeleteBusinessAssetConfirmation } from "../../BusinessAsset/useDeleteBusinessAssetConfirmation";
import { KontaxUserContext } from "../../contexts/KontaxUserContext";
import {
  KontaxTransactionUpsertInput,
  UPSERT_KONTAX_TRANSACTIONS_MUTATION,
  useUpsertTransactionsMutation,
} from "../../../api/graphql/mutations/transaction/upsertKontaxTransactions";
import { ContactUserModal } from "./ContactUserModal";
import { useCreateRecurringPaymentMutation } from "../../../api/graphql/mutations/recurringPayment";
type CategoryType = string | undefined | null;

const revokeFileURL = (assets: Asset[] | undefined) => {
  if (URL.revokeObjectURL) {
    assets?.map((asset: Asset) => URL.revokeObjectURL(asset.fullsize));
  }
};

type Props = {
  refetchTransaction: () => Promise<void>;
  fetchNextTransaction: (filterOptions: KontaxTransactionFilterOptions) => void;
  fetchTransactionCount: (
    filterOptions: KontaxTransactionFilterOptions
  ) => void;
  serverTransaction: ITransaction | null;
  transactionsCount?: number;
  initialData?: ITransaction;
  shouldShowReminderModal?: boolean;
};

export const TransactionCategorisationView = ({
  refetchTransaction,
  fetchNextTransaction,
  fetchTransactionCount,
  serverTransaction,
  transactionsCount,
  initialData = EMPTY_TRANSACTION,
  shouldShowReminderModal = true, // enable by default
}: Props) => {
  const [emailParam, setEmailParam] = useEmailParam();

  const [transaction, setTransaction] = useState<ITransaction>({
    ...initialData,
  });

  const [filterOptions, setFilterOptions] =
    useState<KontaxTransactionFilterOptions>({
      ...getDefaultFilterState(),
      assets: true,
      vatPaymentFrequency: PaymentFrequency.MONTHLY,
      email: emailParam || undefined,
      categorized: false,
      actionReason: false,
    });

  const [category, setCategory] = useState<CategoryType>(undefined);
  const [vatCategory, setVatCategory] = useState<CategoryType>(undefined);
  const [error, setError] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [jobInput, setJobInput] = useState<string | undefined>("");
  const [internalNoteInput, setInternalNoteInput] = useState<
    string | undefined
  >("");
  const [isEscalateClicked, setIsEscalateClicked] = useState(false);
  const [isNextClicked, setIsNextClicked] = useState(false);
  const [splitState, setSplitState] = useState<
    TransactionSplitState | undefined
  >(undefined);

  // Business Asset state
  const [isBusinessAssetMode, triggerBusinessAssetMode] = useState<boolean>(
    DEPRECIABLE_CATEGORY_CODES.includes(category as string)
  );
  const [assetType, setAssetType] = useState<BusinessAssetType>(
    BusinessAssetType.MOVABLE_OTHERS
  );
  const [assetClass, setAssetClass] = useState<string>("");
  const [assetClassCustom, setAssetClassCustom] = useState<string>("");
  const [purchaseDate, setPurchaseDate] = useState<string>(
    LATEST_POSSIBLE_PURCHASE_DATE
  );
  const [depreciationYears, setDepreciationYears] = useState<string>("");
  const [depreciationYearsEditable, setDepreciationYearsEditable] =
    useState<boolean>(category === DepreciableCategory.OVER_800);
  const [note, setNote] = useState<string>("");
  const [
    showBusinessAssetConfirmationModal,
    setShowBusinessAssetConfirmationModal,
  ] = useState(false);
  const [showContactModal, setShowContactModal] = useState<boolean>(false);

  const [createRecurringPaymentRule] = useCreateRecurringPaymentMutation();

  const isAssetTypeImmovable = isImmovableAsset(assetType);

  const isBusinessAssetValid = useCallback((): boolean => {
    return !(
      (!assetClassCustom && !assetClass) ||
      !depreciationYears ||
      (isNoteRequired(assetType, assetClass) && !note)
    );
  }, [assetType, assetClass, assetClassCustom, depreciationYears, note]);

  const resetBusinessAssetValues = (category?: CategoryType) => {
    setAssetType(BusinessAssetType.MOVABLE_OTHERS);
    setAssetClass("");
    setAssetClassCustom("");
    setPurchaseDate(LATEST_POSSIBLE_PURCHASE_DATE);

    // if category is OVER_250, asset depreciates immediately, hence 0
    const depreciationYearsValue =
      category === DepreciableCategory.OVER_250 ? "0" : "";
    setDepreciationYears(depreciationYearsValue);
    setNote("");
  };

  const reset = useCallback(() => {
    setCategory(undefined);
    setVatCategory(undefined);
    setInternalNoteInput("");
    setJobInput("");
    setIsEscalateClicked(false);
    setError("");
    triggerBusinessAssetMode(false);
    resetBusinessAssetValues();
  }, []);

  const onSuccessfulSubmit = useCallback(() => {
    setTransaction({ ...EMPTY_TRANSACTION });
    reset();
    fetchNextTransaction(filterOptions);
  }, [fetchNextTransaction, filterOptions, reset]);

  const composeMetaToUpdate = useCallback(
    (extras?: Record<string, any>, isSplit?: boolean) => ({
      kontistTransactionId: transaction.id,
      categoryCodeSuggestionSource:
        transaction?.categoryCodeMeta?.suggestionSource,
      ...(isSplit
        ? {}
        : {
            categoryCode: category || null,
            vatCategoryCode: vatCategory || null,
          }),
      // this note is dedicated for escalated transactions however the taxOps also has the possibility to use it to add a note to a transaction.
      ...extras,
    }),
    [category, vatCategory, transaction]
  );

  useEffect(() => {
    if (serverTransaction) {
      reset();
      setTransaction({
        ...serverTransaction,
      });
      setCategory(serverTransaction.categoryCode || undefined);
      setVatCategory(serverTransaction.vatCategoryCode || undefined);
      setJobInput(serverTransaction.businessTypeComment || "");
      setInternalNoteInput(serverTransaction.internalNote || "");
      setSplitState(undefined);
    }
  }, [reset, serverTransaction, transaction.splits]);

  useEffect(() => {
    fetchNextTransaction(filterOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterOptions]);

  const [upsertKontaxTransactions] = useUpsertTransactionsMutation({
    refetchQueries: [UPSERT_KONTAX_TRANSACTIONS_MUTATION],
  });

  const [updateTransactionMeta] = useLazyRequest(
    async (meta: KontaxTransactionMeta) => {
      setError("");
      try {
        await upsertKontaxTransactions({
          variables: {
            transactions: [meta] as KontaxTransactionUpsertInput[],
          },
        });
      } catch (error) {
        notification.error({
          message: "Error while categorizing the transaction",
        });
        setError("Error while categorizing the transaction");
      }
    }
  );

  const [createBusinessAsset] = useLazyRequest(
    async (
      transactionId: string,
      transactionMeta: KontaxTransactionMeta
    ): Promise<boolean> => {
      setError("");

      const businessAsset: ICreateBusinessAssetFromTransactionPayload = {
        categoryCode: category || "",
        assetType,
        assetClass: assetClassCustom || assetClass,
        purchaseDate,
        depreciationPeriodYears: Number(depreciationYears),
        note,
      };

      const response = await api.kontax.createBusinessAssetFromTransaction({
        transactionId,
        businessAsset,
        transactionMeta,
      });

      if (response?.data?.success) {
        setShowBusinessAssetConfirmationModal(true);
        return true;
      }

      return false;
    }
  );

  const prepareSplitsPayload = useCallback(
    (splits: TransactionSplit[]): TransactionSplit[] => {
      return splits.map((split: TransactionSplit) => {
        const { businessAssetForm } = split;

        if (businessAssetForm) {
          const {
            assetType,
            assetClass,
            assetClassCustom,
            purchaseDate,
            depreciationYears,
            note,
          } = businessAssetForm;

          return {
            ...omit(split, ["businessAssetForm"]),
            businessAsset: {
              assetType,
              assetClass: assetClassCustom || assetClass,
              purchaseDate,
              depreciationPeriodYears: Number(depreciationYears),
              note,
            },
          };
        }

        return split;
      });
    },
    []
  );

  const isSplitBusinessAssetMode = (
    splitState?: TransactionSplitState
  ): boolean =>
    Boolean(
      splitState?.splits?.some(
        (split: Partial<TransactionSplit>) => split.businessAssetForm
      )
    );

  const [createOrUpdateTransactionSplit] = useLazyRequest(
    async (splitState): Promise<void> => {
      const methodName = splitState?.isNew
        ? "createTransactionSplit"
        : "updateTransactionSplit";

      const splitsPayload = prepareSplitsPayload(
        splitState.splits as TransactionSplit[]
      );

      const response = await api.kontax[methodName](
        transaction.id,
        splitsPayload
      );

      if (
        [200, 201].includes(response?.status) &&
        isSplitBusinessAssetMode(splitState)
      ) {
        setShowBusinessAssetConfirmationModal(true);
      }
    }
  );

  const [deleteBusinessAssetConfirmationPopup, confirmDeleteBusinessAsset] =
    useDeleteBusinessAssetConfirmation();

  const onNextClickHandler = useCallback(
    async (verified: boolean, isRecurringVerify?: boolean) => {
      setIsEscalateClicked(false);
      setIsNextClicked(true);

      if (
        splitState?.splits.length
          ? !splitState.isValid
          : !isTransactionCategorized(transaction)
      ) {
        return;
      }

      if (isBusinessAssetMode && !isBusinessAssetValid()) {
        return;
      }

      const confirmedDeleteBusinessAsset = await confirmDeleteBusinessAsset(
        getToBeDeletedBusinessAssetsFromState(transaction, splitState)
      );
      if (!confirmedDeleteBusinessAsset) {
        return false;
      }

      const onNext = async () => {
        const transactionMeta = composeMetaToUpdate(
          { verified },
          splitState?.isValid
        );

        if (splitState?.isValid) {
          // categorize transaction split and create split business asset
          await createOrUpdateTransactionSplit(splitState);
        }

        if (isBusinessAssetMode && isBusinessAssetValid()) {
          // categorize transaction and create transaction business asset
          await createBusinessAsset(transaction.id, transactionMeta);
        } else {
          // categorize transaction only
          await updateTransactionMeta(transactionMeta);
        }

        revokeFileURL(transaction.assets);

        // If it is not business asset mode, proceed to the next page.
        // Otherwise, confirmation modal will be shown.
        // isBusinessAssetMode - default transaction view
        // isSplitBusinessAssetMode - transaction split view
        if (!isBusinessAssetMode && !isSplitBusinessAssetMode(splitState)) {
          onSuccessfulSubmit();
        }
        setIsNextClicked(false);

        if (splitState) {
          setSplitState(undefined);
        }
        if (isRecurringVerify) {
          if (!transaction.recurringPaymentRuleId) {
            await createRecurringPaymentRule({
              variables: { transactionId: transaction.id },
            });
          }
        }
      };

      if (shouldShowReminderModal && !transaction.assets?.length) {
        Modal.info({
          content: "Erstelle eine wiederkehrenden Zahlung",
          onOk: onNext,
        });
      } else {
        await onNext();
      }
    },
    [
      splitState,
      transaction,
      isBusinessAssetMode,
      isBusinessAssetValid,
      confirmDeleteBusinessAsset,
      composeMetaToUpdate,
      createOrUpdateTransactionSplit,
      createBusinessAsset,
      updateTransactionMeta,
      onSuccessfulSubmit,
      createRecurringPaymentRule,
      shouldShowReminderModal,
    ]
  );

  const onEscalateHandler = useCallback(
    async (event) => {
      event.preventDefault();
      setIsNextClicked(false);
      setIsEscalateClicked(true);

      await updateTransactionMeta({
        kontistTransactionId: transaction.id,
        escalated: true,
      });

      onSuccessfulSubmit();
      setIsEscalateClicked(false);
    },
    [transaction.id, updateTransactionMeta, onSuccessfulSubmit]
  );

  const dropFiles = useCallback(
    async (files: File[]) => {
      try {
        setIsUploading(true);

        const assets = (
          await Promise.all(
            files.map(async (file: File) => ({
              id: await createTransactionAsset(transaction.id, file),
              fullsize: URL.createObjectURL(file),
              filetype: file.type.split("/").pop() as string,
            }))
          )
        ).concat(transaction.assets || ([] as any));

        if (shouldRefetchTransactionOnAssetUpload(transaction)) {
          await new Promise((resolve) => setTimeout(resolve, 200));
          await refetchTransaction();
          return;
        }

        const updatedTransaction: ITransaction = {
          ...transaction,
          assets,
        };
        setTransaction(updatedTransaction);
      } catch (error) {
        const errorMessage = getErrorMessage(error);
        setError(errorMessage);
        notification.error({ message: errorMessage });
      } finally {
        setIsUploading(false);
      }
    },
    [transaction, refetchTransaction]
  );

  /**
   * Updates the form data based on the provided field name and value (on the event).
   */
  const onBusinessAssetFormDataChange = (
    field: BusinessAssetFormField,
    value: string
  ) => {
    switch (field) {
      case BusinessAssetFormField.ASSET_CLASS:
        onAssetClassChangeHandler(value);
        break;
      case BusinessAssetFormField.ASSET_TYPE:
        onAssetTypeChangeHandler(value);
        break;
      case BusinessAssetFormField.ASSET_CLASS_CUSTOM:
        setAssetClassCustom(value);
        break;
      case BusinessAssetFormField.PURCHASE_DATE:
        setPurchaseDate(value);
        break;
      case BusinessAssetFormField.DEPRECIATION_YEARS:
        setDepreciationYears(value);
        break;
      case BusinessAssetFormField.NOTE:
        setNote(value);
        break;
    }
  };

  // Business Asset handlers
  const onAssetTypeChangeHandler = (value: string) => {
    setAssetType(value as BusinessAssetType);
    setAssetClass("");
    setAssetClassCustom("");
    if (depreciationYearsEditable) {
      setDepreciationYears("");
    }
  };

  const onAssetClassChangeHandler = (value: string) => {
    setAssetClassCustom("");
    setAssetClass(value);

    const assets = isAssetTypeImmovable ? immovableAssets : movableAssets;
    const selectedAssetClass = assets.find(
      (asset) => asset.assetClass === value
    );

    if (selectedAssetClass && depreciationYearsEditable) {
      const depreciationYearsValue = isNumber(
        selectedAssetClass.depreciationYears
      )
        ? selectedAssetClass.depreciationYears.toString()
        : "";
      setDepreciationYears(depreciationYearsValue);
    }
  };

  const onHideBusinessAssetConfirmationModal = () => {
    onSuccessfulSubmit();
    setShowBusinessAssetConfirmationModal(false);
  };

  const dateFormat = "YYYY/MM/DD HH:mm";
  const invoiceRequestedDate = moment(transaction.invoiceRequestedAt).format(
    dateFormat
  );

  const isDateToday =
    transaction.invoiceRequestedAt &&
    moment(invoiceRequestedDate).isSame(moment(), "day");

  const invoiceRequestedDateToShow = isDateToday
    ? "today"
    : `on ${invoiceRequestedDate}`;

  const renderInvoiceContent = (transaction: ITransaction) => {
    if (transaction.assets?.length) {
      return (
        <LocalAssetsViewer
          topPagination
          assets={transaction.assets}
          onDelete={(deletedAsset) => {
            setTransaction({
              ...transaction,
              assets: transaction.assets?.filter(
                (asset) => asset !== deletedAsset
              ),
            });
          }}
        />
      );
    } else {
      return (
        <>
          {transaction.invoiceRequestedAt && (
            <Banner>
              <IconBackgroundSmall>
                <EnvelopeIcon />
              </IconBackgroundSmall>
              The invoice has been requested {invoiceRequestedDateToShow}
            </Banner>
          )}
          <p style={{ textAlign: "center" }}> No Invoice found</p>
          <p className="ant-upload-hint">
            Drag and drop invoices here to upload
          </p>
        </>
      );
    }
  };

  const renderInvoices = (
    <>
      {renderInvoiceContent(transaction)}
      {isUploading && <SpinnerBasicCentered />}
    </>
  );

  const renderContactUserPopup = useCallback(() => {
    return (
      <ContactUserModal
        amount={transaction.amount}
        transactionId={transaction.id}
        show={showContactModal}
        onHide={() => {
          setShowContactModal(false);
        }}
        name={`${transaction.firstName} ${transaction.lastName}`}
        fetchNextTransaction={() => fetchNextTransaction(filterOptions)}
      />
    );
  }, [showContactModal, transaction, fetchNextTransaction, filterOptions]);

  return (
    <>
      <TransactionFilters
        email={filterOptions.email}
        categorized={filterOptions.categorized}
        vatPaymentFrequency={filterOptions.vatPaymentFrequency}
        dateFrom={filterOptions.dateFrom}
        dateTo={filterOptions.dateTo}
        onChangeHandler={(
          updatedFilterOptions: Partial<KontaxTransactionFilterOptions>
        ) => {
          const newFilterOptions = {
            ...filterOptions,
            ...updatedFilterOptions,
          };
          setFilterOptions(newFilterOptions);
          setEmailParam(newFilterOptions.email || undefined);
          revokeFileURL(transaction.assets);
          setTransaction({
            ...transaction,
            assets: [],
          });
        }}
        hiddenFilters={[Filter.VAT_CATEGORIES, Filter.CATEGORIES, Filter.PLAN]}
        showCount
        count={transactionsCount}
        fetchTransactionCount={() => fetchTransactionCount(filterOptions)}
      />
      {showContactModal && renderContactUserPopup()}
      {serverTransaction ? (
        <CategorisationWrapper>
          <InvoiceWrapper data-test="transactionInvoices">
            <Upload
              onDropFiles={dropFiles}
              isInPreviewMode={!!transaction.assets?.length}
            >
              {renderInvoices}
            </Upload>
          </InvoiceWrapper>

          <KontaxUserContext email={serverTransaction.email}>
            <TransactionDetails
              transaction={transaction}
              jobInput={jobInput}
              isEscalateClicked={isEscalateClicked}
              isNextClicked={isNextClicked}
              category={category}
              vatCategory={vatCategory}
              error={error}
              internalNoteInput={internalNoteInput}
              onInternalNoteChange={(
                event: ChangeEvent<HTMLTextAreaElement>
              ) => {
                setInternalNoteInput(event.target.value);
                setIsNextClicked(false);
              }}
              onSaveInternalNoteClick={async () => {
                try {
                  await updateTransactionMeta({
                    kontistTransactionId: transaction.id,
                    internalNote: internalNoteInput,
                  });
                  notification.success({ message: "Note saved" });
                } catch (err) {
                  notification.error({
                    message: "An error occurred when saving note:",
                    description: err as string,
                  });
                }
              }}
              onEscalateHandler={onEscalateHandler}
              onCategorySelected={(category) => {
                setCategory(category);
                let transactionUpdate = {
                  ...transaction,
                  categoryCode: category,
                  categoryCodeMeta: {
                    ...transaction.categoryCodeMeta,
                    label: undefined,
                    suggestionSource: undefined,
                  },
                };

                setTransaction({
                  ...transactionUpdate,
                });
                setIsEscalateClicked(false);

                const isBusinessAssetMode =
                  !!category && DEPRECIABLE_CATEGORY_CODES.includes(category);
                triggerBusinessAssetMode(isBusinessAssetMode);

                if (isBusinessAssetMode) {
                  setDepreciationYearsEditable(
                    category === DepreciableCategory.OVER_800
                  );

                  resetBusinessAssetValues(category);
                }
              }}
              onVatCategorySelected={(vatCategory) => {
                setVatCategory(vatCategory);

                let transactionUpdate = {
                  ...transaction,
                  vatCategoryCode: vatCategory,
                  vatCategoryCodeMeta: {
                    ...transaction.vatCategoryCodeMeta,
                    label: undefined,
                    suggestionSource: undefined,
                  },
                };

                setTransaction({
                  ...transactionUpdate,
                });
                setIsEscalateClicked(false);
              }}
              businessAssetFormData={{
                assetType,
                assetClass,
                assetClassCustom,
                purchaseDate,
                purchaseDateMax: LATEST_POSSIBLE_PURCHASE_DATE,
                depreciationYears,
                depreciationYearsEditable,
                note,
              }}
              onFieldChange={onBusinessAssetFormDataChange}
              showBusinessAssetConfirmationModal={
                showBusinessAssetConfirmationModal
              }
              triggerContactUserMode={setShowContactModal}
              onHideBusinessAssetConfirmationModal={
                onHideBusinessAssetConfirmationModal
              }
              hideBusinessAssetMode={() => triggerBusinessAssetMode(false)}
              buttonsSection={
                <ActionButtons
                  originalTransaction={serverTransaction}
                  updatedTransaction={{
                    ...transaction,
                    splits: splitState?.splits || transaction.splits,
                  }}
                  onClick={onNextClickHandler}
                />
              }
              onSplitChange={setSplitState}
              onSplitDelete={async (state: TransactionSplitState) => {
                if (serverTransaction.splits?.length) {
                  const confirmedDeleteBusinessAsset =
                    await confirmDeleteBusinessAsset(
                      getToBeDeletedBusinessAssetsFromState(transaction, state)
                    );
                  if (!confirmedDeleteBusinessAsset) {
                    return false;
                  }

                  await api.kontax.deleteTransactionSplit(transaction.id);
                  await refetchTransaction();
                }

                setSplitState(state);
                return true;
              }}
            />
          </KontaxUserContext>
          {deleteBusinessAssetConfirmationPopup}
        </CategorisationWrapper>
      ) : (
        <EmptyWrapper
          description="No more transactions!👏 Go get some coffee!"
          buttonProperties={{
            text: "Refresh",
            onClick: () => fetchNextTransaction(filterOptions),
          }}
        />
      )}
    </>
  );
};

function getToBeDeletedBusinessAssetsFromState(
  transaction: ITransaction,
  splitState?: TransactionSplitState
) {
  return getToBeDeletedBusinessAssets({
    id: transaction.id,
    businessAssets: transaction.businessAssets,
    ...(splitState?.splits.length
      ? { splits: splitState.splits }
      : { categoryCode: transaction.categoryCode }),
  });
}

export default TransactionCategorisationView;
