import { Button } from "antd";
import styled from "styled-components";

import colors from "../../../themes/colors";

export const AntNotesBlock = styled.div`
  .ant-comment-content-author-name {
    font-size: 14px;
  }

  .ant-comment-actions {
    > li {
      > span {
        font-size: 14px;
      }
    }
  }

  .ant-comment-content-detail {
    word-break: break-all;
  }

  .ant-comment-content-detail > div.ant-form-item {
    margin-bottom: 10px;
  }
`;

export const AntNotesHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
`;

export const AntNotesTitle = styled.div`
  font-weight: 500;
  display: inline-block;
`;

export const EditField = styled.span`
  &:hover {
    color: ${colors.purple} !important;
  }

  &:active {
    color: #27134e !important;
  }
`;

export const DeleteField = styled.span`
  &:hover {
    color: #e03d00 !important;
  }

  &:active {
    color: #6b1d00 !important;
  }
`;

export const DateTimeField = styled.span`
  font-size: 14px;
  color: rgba(0, 0, 0, 0.45);
`;

export const EditTextAreaField = styled.div`
  textarea {
    resize: vertical !important;
  }
`;

export const AntNotesFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 5px 0;
`;

export const LoadMoreButton = styled(Button)`
  color: ${colors.midGrey};
  left: 24px;
`;
