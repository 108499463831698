import React, { useCallback, useEffect, useMemo, useState } from "react";
import { TextAnnotator } from "react-text-annotate";
import { notification } from "antd";

import { Annotation } from "../../../api/graphql/schema.generated";
import { SpinnerBasicCentered } from "../TransactionView/styledComponents";
import { useAnnotationQuery } from "../../../api/graphql/queries/annotation/annotation.generated";
import { useAnnotationTagsQuery } from "../../../api/graphql/queries/annotation/annotationTags.generated";
import { useAddAnnotationMutation } from "../../../api/graphql/mutations/annotation/addAnnotation.generated";
import { useDeleteAnnotationMutation } from "../../../api/graphql/mutations/annotation/deleteAnnotation.generated";
import {
  NotFoundContainer,
  TagSelect,
  AnnotatorContainer,
  AnnotationWrapper,
} from "./styledComponents";
import {
  findTagColor,
  getTagSelectOptions,
  findOddAnnotation,
  invertColor,
} from "./utils";
import { KontaxTransactionForAdminFragment } from "../../../api/graphql/fragments/kontaxTransactionForAdmin.generated";

const AnnotationInput = ({
  transaction,
}: {
  transaction: KontaxTransactionForAdminFragment;
}) => {
  const [tag, setTag] = useState<string | null>(null);
  const [isMissingTag, setIsMissingTag] = useState<boolean>(false);

  // hacky way to highlight text with dark color
  const revertMarksColors = useCallback(() => {
    Array.from(document.getElementsByTagName("mark"))
      .filter((element) => element.style?.backgroundColor)
      .forEach((element) => {
        element.style.color = invertColor(
          element.style.backgroundColor
        ) as string;
      });
  }, []);

  /**
   * GraphQL
   */

  const {
    data: annotationQuery,
    loading: loadingAnnotation,
    refetch: refetchAnnotation,
  } = useAnnotationQuery({
    variables: {
      transactionId: transaction.id,
    },
  });

  const { data: tagsQuery, loading: loadingAnnotationTags } =
    useAnnotationTagsQuery();

  const [addAnnotation, { loading: addingAnnotation }] =
    useAddAnnotationMutation();

  const [deleteAnnotation, { loading: deletingAnnotation }] =
    useDeleteAnnotationMutation();

  /**
   * Memo
   */

  const color = useMemo(
    () => findTagColor(tag, tagsQuery?.annotationTags),
    [tag, tagsQuery?.annotationTags]
  );

  const tagSelectOptions = useMemo(
    () => getTagSelectOptions(tagsQuery?.annotationTags),
    [tagsQuery?.annotationTags]
  );

  /**
   * Main logic to update annotation
   */

  const _addAnnotation = async (
    documentId: string,
    newList: Annotation[],
    currentList: Annotation[]
  ) => {
    if (!tag) {
      notification.warn({ message: "Please select a tag" });
      setIsMissingTag(true);
      return;
    }

    const addingAnnotation = findOddAnnotation(newList, currentList);
    const annotationTagId = tagsQuery?.annotationTags.find(
      ({ tag }) => tag === addingAnnotation?.tag
    )?.id;

    // there's a possibility (through trial and error) that either start or end can be null.
    if (
      !addingAnnotation?.start ||
      !addingAnnotation?.end ||
      !annotationTagId
    ) {
      return;
    }

    await addAnnotation({
      variables: {
        payload: {
          documentId,
          annotationTagId,
          start: addingAnnotation.start,
          end: addingAnnotation.end,
          text: addingAnnotation.text,
        },
      },
    });

    await refetchAnnotation();

    revertMarksColors();
  };

  const _removeAnnotation = async (
    newList: Annotation[],
    currentList: Annotation[]
  ) => {
    const removingAnnotation = findOddAnnotation(currentList, newList);

    if (!removingAnnotation?.id) {
      return;
    }

    await deleteAnnotation({
      variables: {
        id: removingAnnotation.id,
      },
    });

    await refetchAnnotation();
  };

  const updateAnnotation = async (newList: Annotation[]) => {
    const currentList = annotationQuery?.annotation?.value;
    const documentId = annotationQuery?.annotation?.documentId;

    if (addingAnnotation || deletingAnnotation || !currentList || !documentId) {
      return;
    }

    // if the newList is bigger than the currentList, we're adding new annotation.
    // on the other hand, we're removing one.
    const comparedLength = newList.length - currentList.length;

    if (comparedLength > 0) {
      await _addAnnotation(documentId, newList, currentList);
    }

    if (comparedLength < 0) {
      await _removeAnnotation(newList, currentList);
    }
  };

  // type of onSelect value is RawValueType | LabelInValueType.
  const updateTag = (tagValue: any) => {
    setTag(tagValue);
    setIsMissingTag(false);
  };

  useEffect(() => {
    if (annotationQuery?.annotation?.text) {
      revertMarksColors();
    }
  }, [annotationQuery?.annotation?.text, revertMarksColors]);

  if (loadingAnnotation || loadingAnnotationTags) {
    return <SpinnerBasicCentered />;
  }

  if (!annotationQuery?.annotation?.text) {
    return <NotFoundContainer>No document found</NotFoundContainer>;
  }

  return (
    <AnnotationWrapper>
      <TagSelect
        options={tagSelectOptions}
        color={color}
        onSelect={updateTag}
        defaultValue={tag}
        disabled={!tagsQuery?.annotationTags?.length}
        placeholder="Select a tag"
        status={isMissingTag ? "error" : ""}
      />
      <AnnotatorContainer>
        <TextAnnotator
          content={annotationQuery.annotation.text}
          value={annotationQuery.annotation.value}
          onChange={(value: any) => updateAnnotation(value)}
          getSpan={(span) => ({ ...span, tag, color })}
        />
      </AnnotatorContainer>
    </AnnotationWrapper>
  );
};

export default AnnotationInput;
