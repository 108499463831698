import { gql, QueryHookOptions, useQuery } from "@apollo/client";

import { VatAnnualDeclarationPeriodType } from "../../../../components/pages/TaxDeclaration/types";

import { VatAnnualDeclarationAutoCalculatedValues } from "../../types";

export interface VatAnnualDeclarationAutoCalculatedValuesQueryVariables {
  email: string;
  year: number;
  period?: VatAnnualDeclarationPeriodType;
}

export interface VatAnnualDeclarationAutoCalculatedValuesQueryResult {
  vatAnnualDeclaration: {
    autoCalculatedValues: VatAnnualDeclarationAutoCalculatedValues;
  };
}

export const VAT_ANNUAL_DECLARATION_AUTO_CALCULATED_VALUES_QUERY = gql`
  query vatAnnualDeclarationAutoCalculatedValues(
    $email: String!
    $year: Int!
    $period: String
  ) {
    vatAnnualDeclaration(email: $email, year: $year) {
      autoCalculatedValues(period: $period) {
        salesTax {
          fields {
            type
            netAmount
            vatAmount
            advanceNetAmount
            advanceVatAmount
            transactionFilters {
              categoryCodes
              vatCategoryCodes
            }
          }
          total
        }
        deductibleInputTax {
          fields {
            type
            netAmount
            vatAmount
            advanceNetAmount
            advanceVatAmount
            transactionFilters {
              categoryCodes
              vatCategoryCodes
            }
          }
          total
        }
        additionalInformation {
          fields {
            type
            netAmount
            vatAmount
            advanceNetAmount
            advanceVatAmount
            transactionFilters {
              categoryCodes
              vatCategoryCodes
            }
          }
          total
        }
        total
      }
    }
  }
`;

export const useVatAnnualDeclarationAutoCalculatedValuesQuery = (
  options?: QueryHookOptions<
    VatAnnualDeclarationAutoCalculatedValuesQueryResult,
    VatAnnualDeclarationAutoCalculatedValuesQueryVariables
  >
) =>
  useQuery<
    VatAnnualDeclarationAutoCalculatedValuesQueryResult,
    VatAnnualDeclarationAutoCalculatedValuesQueryVariables
  >(VAT_ANNUAL_DECLARATION_AUTO_CALCULATED_VALUES_QUERY, options);
