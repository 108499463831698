import { StatusColor } from "../../../../../constants";
import {
  EuerDeclarationStatus,
  EuerDeclarationSubformStatus,
} from "../../../../../api/graphql/schema.generated";
import { UserPages } from "../../../Mandanten/Common/UserDetails/enums";

export const EUER_DECLARATION_ROOT_PATH = "/tax-declaration/euer-declaration";

export const MAX_ASSET_UPLOAD_SIZE = 25; // MB

export const EUER_DECLARATION_OVERVIEW_META_INFO = {
  euerDeclaration: {
    name: "Anlage EÜR",
  },
  businessAssetsSubform: {
    name: "Anlagevermögen",
    path: "/",
    queryParams: {
      userPage: UserPages.ASSETS,
    },
    isSubForm: true,
  },
  carUsageSubform: {
    name: "PKW-Nutzung",
    path: `${EUER_DECLARATION_ROOT_PATH}/car-usage`,
    isSubForm: true,
  },
  officeUsageSubform: {
    name: "Häusliches Arbeitszimmer",
    path: `${EUER_DECLARATION_ROOT_PATH}/office-usage`,
    isSubForm: true,
  },
  travelExpensesSubform: {
    name: "Verpflegungsmehraufwand & KFZ-Pauschalen",
    path: `${EUER_DECLARATION_ROOT_PATH}/travel-expenses`,
    isSubForm: true,
  },
};

export const SUBFORM_STATUS_MAPPINGS = {
  [EuerDeclarationSubformStatus.OPEN]: {
    color: StatusColor.GRAY,
    label: "Offen",
  },
  [EuerDeclarationSubformStatus.NOT_RELEVANT]: {
    color: StatusColor.GREEN,
    label: "Nicht relevant",
  },
  [EuerDeclarationSubformStatus.IN_PROGRESS]: {
    color: StatusColor.GOLD,
    label: "In Bearbeitung",
  },
  [EuerDeclarationSubformStatus.COMPLETED]: {
    color: StatusColor.PURPLE,
    label: "Vollständig",
  },
  [EuerDeclarationSubformStatus.VERIFIED]: {
    color: StatusColor.GREEN,
    label: "In Ordnung",
  },
};

export const SUBFORM_STATUSES = Object.keys(
  SUBFORM_STATUS_MAPPINGS
) as EuerDeclarationSubformStatus[];

export const EUER_DECLARATION_STATUS_MAPPINGS = {
  [EuerDeclarationStatus.OPEN]: {
    color: StatusColor.GRAY,
    label: "Offen",
  },
  [EuerDeclarationStatus.IN_PROGRESS_DATA]: {
    color: StatusColor.GOLD,
    label: "In Bearbeitung DATA",
  },
  [EuerDeclarationStatus.CONSULTATION_DATA]: {
    color: StatusColor.GOLD,
    label: "Rückfrage DATA",
  },
  [EuerDeclarationStatus.COMPLETED_BY_DATA]: {
    color: StatusColor.PURPLE,
    label: "Abgeschlossen DATA",
  },
  [EuerDeclarationStatus.IN_PROGRESS_OPS]: {
    color: StatusColor.GOLD,
    label: "In Bearbeitung Ops",
  },
  [EuerDeclarationStatus.COMPLETED_BY_OPS]: {
    color: StatusColor.PURPLE,
    label: "Abgeschlossen Ops",
  },
  [EuerDeclarationStatus.IN_PROGRESS_TAX_CONSULTANT]: {
    color: StatusColor.GOLD,
    label: "In Bearbeitung SB",
  },
  [EuerDeclarationStatus.APPROVED_BY_TAX_CONSULTANT]: {
    color: StatusColor.GREEN,
    label: "Abgeschlossen SB",
  },
  [EuerDeclarationStatus.OBJECTED_BY_TAX_CONSULTANT]: {
    color: StatusColor.RED,
    label: "Vorbehalt SB",
  },
  [EuerDeclarationStatus.WAITING_FOR_USER_APPROVAL]: {
    color: StatusColor.GOLD,
    label: "Warten auf Kundenfreigabe",
  },
  [EuerDeclarationStatus.APPROVED_BY_USER]: {
    color: StatusColor.GREEN,
    label: "Von Kunde freigegeben",
  },
  [EuerDeclarationStatus.OBJECTED_BY_USER]: {
    color: StatusColor.RED,
    label: "Vorbehalt Kunde",
  },
  [EuerDeclarationStatus.SUBMITTED]: {
    color: StatusColor.PURPLE,
    label: "Eingereicht FA",
  },
  [EuerDeclarationStatus.OBJECTED_BY_FINANZAMT]: {
    color: StatusColor.RED,
    label: "Vorbehalt FA",
  },
  [EuerDeclarationStatus.RECEIVED_TAX_BILL]: {
    color: StatusColor.PURPLE,
    label: "Bescheid erhalten",
  },
  [EuerDeclarationStatus.CLOSED]: {
    color: StatusColor.GREEN,
    label: "Abgeschlossen",
  },
  [EuerDeclarationStatus.APPEAL_PROCESS_STARTED]: {
    color: StatusColor.RED,
    label: "Rechtsbehelfsverfahren begonnen",
  },
  [EuerDeclarationStatus.APPEAL_PROCESS_COMPLETED]: {
    color: StatusColor.GREEN,
    label: "Rechtsbehelfsverfahren abgeschlossem",
  },
};

export const EUER_DECLARATION_STATUSES = Object.keys(
  EUER_DECLARATION_STATUS_MAPPINGS
) as EuerDeclarationStatus[];
