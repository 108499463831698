// Titles
export const TELENUMBERS = "Telenummern";
export const PROFIT_AND_LOSS =
  "Folgende Tätigkeiten waren Bestand unserer Arbeit:";
export const OTHER_STUFF =
  "Wir haben unter anderem folgende Angaben in der Einkommensteuererklärung erfasst:";

// Placeholders
export const NOTE_PLACEHOLDER = "Erfasse eine neue Notiz";
export const TELENUMBER_1 = "EÜR Telenummer";
export const TELENUMBER_2 = "UStE Telenummer";
export const TELENUMBER_3 = "EStE Telenummer *";
export const TELENUMBER_4 = "GewStE Telenummer";

// Notificaiton messages
export const TEMPLATE_SAVED = "Vorlage gespeichert.";
export const CANT_SAVE_TEMPLATE =
  "Die Vorlage konnte nicht gespeichert werden.";
export const SUCCESS = "Erfolgreich";
export const FAILED_TO_UPDATE_ANSCHREIBEN =
  "Das Anschreiben für diesen Mandanten kann nicht gespeichert werden.";
export const FAILED_TO_CREATE_ANSCHREIBEN =
  "Das Anschreiben für diesen Mandanten kann nicht generiert werden.";
export const FAILED_TO_APPROVE_OR_REJECT =
  "Das Anschreiben für diesen Mandanten kann weder bestätigt noch zurückgewiesen werden.";
export const FAILED_TO_REMOVE = "Das Anschreiben konnte nicht gelöscht werden.";
export const NO_TEMPLATE =
  "Für dieses Jahr kann die Anschreiben-Vorlage nicht gefunden werden.";
export const FAILED_TO_SEND = "Das Anschreiben konnte nicht versendet werden";

// Buttons
export const GENERATE = "Erstellen";
export const SAVE = "Speichern";
export const APPROVE = "Bestätigen";
export const REJECT = "Zurückweisen";
export const SEND = "Senden";

// Status
export const APPROVED = "Bestätigt";
export const REJECTED = "Zurückgewiesen";

// Job type
export const FREELANCER = "Freiberufler";
export const TRADER = "Gewerbetreibender";

export const CANCEL = "Abbrechen";
export const PROCEED = "Bearbeiten";
export const DELETE = "Löschen";
export const NOTE_WARNING = "Notizen werden nicht gespeichert";
export const SAVE_WARNING = "Genehmigung/Zurückweisung wird aufgehoben";
export const REMOVE_WARNING =
  "Durch das Zurücksetzen des Anschreibens gehen alle Fortschritte verloren und es muss ein neues Anschreiben erstellt werden.";
export const SEND_WARNING =
  "Durch Bestätigung wird eine Benachrichtigung-E-Mail an den Kunden geschickt. Dies kann nicht rückgängig gemacht werden.";

export const EXPORT = "Export";
export const OPEN_PDF = "PDF öffnen";

export const TRADE_TAX_MISSING = "Gewerbesteuerpflicht nicht ausgefüllt";
