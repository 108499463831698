import React, { useCallback, useEffect, useState, useMemo } from "react";
import { Col, Row, Spin, Button, Modal } from "antd";
import isEqual from "lodash/isEqual";

import {
  EuerDeclarationAutoCalculatedValues,
  EuerDeclarationFields,
  EuerDeclaration,
} from "../../../../../../../../api/graphql";
import { EuerDeclarationStatus } from "../../../../../../../../api/graphql/schema.generated";
import {
  combineEuerFields,
  extractFieldAdjustments,
} from "../../../../../../../../utils/euerFields";
import useTaxYearParam from "../../../../../hooks/useTaxYearParam";
import { useUserContext } from "../../../../../contexts/UserContext";
import { StyledModal, SpinContainer } from "./styledComponents";
import EuerDeclarationStatusSelect from "./EuerDeclarationStatusSelect";
import EuerDeclarationFieldsList from "./EuerDeclarationFieldsList";
import UserAddress from "./UserAddress";
import TransactionsSummary from "./TransactionsSummary";
import useEmailParam from "../../../../../../../hooks/useEmailParam";
import { WARNING_MESSAGE } from "../../../../../../../common/WarnLeaveWithoutSaving/constants";
import WarnLeaveWithoutSaving from "../../../../../../../common/WarnLeaveWithoutSaving";
import { getGraphQLErrorMessage } from "../../../../../../../../utils/error";
import { Error } from "./styledComponents";
import EuerProfit from "./EuerProfit";

export interface EuerDeclarationPreviewModalProps {
  visible: boolean;
  declaration: EuerDeclaration;
  autoCalculatedValues?: EuerDeclarationAutoCalculatedValues;
  onSave: (declaration: EuerDeclaration) => Promise<void>;
  onClose: () => void;
}

const EuerDeclarationPreviewModal = ({
  visible,
  declaration,
  autoCalculatedValues,
  onSave,
  onClose,
}: EuerDeclarationPreviewModalProps) => {
  const [taxYear] = useTaxYearParam();
  const [email] = useEmailParam();
  const user = useUserContext();
  const [declarationState, setDeclarationState] =
    useState<EuerDeclaration>(declaration);
  const [isSaving, setIsSaving] = useState(false);
  const [
    euerDeclarationUpdateErrorMessage,
    setEuerDeclarationUpdateErrorMessage,
  ] = useState<string>("");

  const declarationWithCombinedFields = useMemo(
    () => ({
      ...declaration,
      fields: combineEuerFields([
        declaration.fields,
        autoCalculatedValues?.fields || {},
      ]),
    }),
    [declaration, autoCalculatedValues]
  );

  useEffect(() => {
    setDeclarationState(declarationWithCombinedFields);
  }, [declarationWithCombinedFields]);

  const isDeclarationChanged = useMemo(
    () => !isEqual(declarationState, declarationWithCombinedFields),
    [declarationState, declarationWithCombinedFields]
  );

  const handleClose = useCallback(() => {
    setEuerDeclarationUpdateErrorMessage("");
    if (isDeclarationChanged) {
      Modal.confirm({
        title: WARNING_MESSAGE,
        onOk: onClose,
        cancelText: "Abbrechen",
      });
    } else {
      onClose();
    }
  }, [isDeclarationChanged, onClose]);

  const handleStatusChange = useCallback(
    (status: EuerDeclarationStatus) => {
      setDeclarationState({ ...declarationState, status });
    },
    [declarationState]
  );

  const handleFieldsChange = useCallback(
    (fields: EuerDeclarationFields) => {
      setDeclarationState({ ...declarationState, fields });
    },
    [declarationState]
  );

  const handleReset = useCallback(() => {
    setDeclarationState({
      ...declarationState,
      fields: autoCalculatedValues!.fields,
    });
  }, [declarationState, autoCalculatedValues]);

  const handleSaveAndClose = useCallback(async () => {
    try {
      setEuerDeclarationUpdateErrorMessage("");
      setIsSaving(true);
      const manualAdjustments = extractFieldAdjustments(
        declarationState.fields,
        autoCalculatedValues!.fields
      );
      await onSave({
        ...declarationState,
        fields: manualAdjustments,
      });
      onClose();
    } catch (error) {
      const errorMessage = getGraphQLErrorMessage(error);
      if (
        errorMessage &&
        errorMessage.message === "DOCUMENTS_REQUIRED_FOR_STATUS_UPDATE"
      ) {
        setEuerDeclarationUpdateErrorMessage(
          "Ein Dokument muss hochgeladen sein, um diesen Status setzen zu können"
        );
      }
    } finally {
      setIsSaving(false);
    }
  }, [autoCalculatedValues, declarationState, onClose, onSave]);

  if (!user) {
    return null;
  }

  return (
    <>
      <WarnLeaveWithoutSaving warn={isDeclarationChanged && visible} />
      <StyledModal
        title={`Anlage EÜR Übersicht ${taxYear}`}
        visible={visible}
        maskClosable={true}
        onCancel={handleClose}
        width={800}
        footer={
          <>
            <Button
              type="default"
              onClick={handleReset}
              disabled={!autoCalculatedValues || isSaving}
            >
              Werte zurücksetzen
            </Button>
            <Button
              type="primary"
              onClick={handleSaveAndClose}
              disabled={!isDeclarationChanged}
              loading={isSaving}
            >
              Speichern und schließen
            </Button>
          </>
        }
      >
        {!autoCalculatedValues ? (
          <SpinContainer>
            <Spin />
          </SpinContainer>
        ) : (
          <>
            <Row>
              <Col span={6}>
                <UserAddress user={user} />
              </Col>
              <Col span={6}>
                <TransactionsSummary
                  transactionsCount={autoCalculatedValues.transactionsCount}
                  uncategorizedCount={autoCalculatedValues.uncategorizedCount}
                  verifiedTransactionsCount={
                    autoCalculatedValues.verifiedTransactionsCount
                  }
                />
              </Col>
              <Col span={6}>
                <EuerProfit profit={declarationState.fields[219]} />
              </Col>
            </Row>
            <EuerDeclarationFieldsList
              email={email!}
              taxYear={taxYear}
              fields={declarationState.fields}
              autoCalculatedFields={autoCalculatedValues.fields}
              onChange={handleFieldsChange}
            />
            <Row>
              <Col>
                <EuerDeclarationStatusSelect
                  error={!!euerDeclarationUpdateErrorMessage}
                  value={declarationState.status}
                  onChange={handleStatusChange}
                  disabled={isSaving}
                />
              </Col>
            </Row>
            <Row>
              {!!euerDeclarationUpdateErrorMessage && (
                <Error>{euerDeclarationUpdateErrorMessage}</Error>
              )}
            </Row>
          </>
        )}
      </StyledModal>
    </>
  );
};

export default EuerDeclarationPreviewModal;
