import { AnschreibenType } from "../../../../api/graphql/schema.generated";
import { IUser, TradeTax } from "../../../../types";

export const getInitialType = (user: IUser): AnschreibenType | null => {
  if (user.kontaxUser?.tradeTax === TradeTax.ANGEHORIGE_FREIEN_BERUFE) {
    return AnschreibenType.FREELANCER;
  }

  if (user.kontaxUser?.tradeTax === TradeTax.SONSTIGE_EINZELGEWERBETREIBENDE) {
    return AnschreibenType.TRADER;
  }

  return null;
};
