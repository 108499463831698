import React, { useState, useEffect } from "react";

export function TextFileViewer({ file }: { file: File }) {
  const [fileContent, setFileContent] = useState("");

  useEffect(() => {
    file.text().then(setFileContent);
  }, [file]);

  return (
    <div>
      <pre>{fileContent}</pre>
    </div>
  );
}
