import { gql, MutationHookOptions, useMutation } from "@apollo/client";

export interface RemoveDatevDataMutationVariables {
  email: string;
  ids?: string[];
}

export interface RemoveDatevDataMutationResult {
  removeDatevData: {
    success: boolean;
    deletedIds: string[];
  };
}

export const REMOVE_DATEV_DATA_MUTATION = gql`
  mutation removeDatevData($email: String!, $ids: [ID!]) {
    removeDatevData(email: $email, ids: $ids) {
      success
      deletedIds
    }
  }
`;

export const useRemoveDatevDataMutation = (
  options?: MutationHookOptions<
    RemoveDatevDataMutationResult,
    RemoveDatevDataMutationVariables
  >
) =>
  useMutation<RemoveDatevDataMutationResult, RemoveDatevDataMutationVariables>(
    REMOVE_DATEV_DATA_MUTATION,
    options
  );
