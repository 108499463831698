import React, { useCallback, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { Form, Modal, Tabs, Typography } from "antd";
import defaults from "lodash/defaults";
import isEqual from "lodash/isEqual";

import {
  EuerDeclarationOfficeUsageSubformCalculationResults,
  EuerDeclarationOfficeUsageSubformInputs,
  EuerDeclarationSubformInputs,
  HomeOwnershipType,
  calculateSubformResults,
} from "@kontist/euer-declaration";

import { isEmpty } from "lodash";

import BackButton from "../../../components/BackButton";
import ActionLogDrawer from "../../../../../../../common/ActionLogDrawer";
import { WARNING_MESSAGE } from "../../../../../../../common/WarnLeaveWithoutSaving/constants";
import CalculationTab from "./CalculationTab";
import useEmailParam from "../../../../../../../hooks/useEmailParam";
import {
  ChangeLog,
  UpdateEuerDeclarationSubformInput,
  useUpdateEuerDeclarationSubformMutation,
} from "../../../../../../../../api/graphql";
import ExpensesTab from "./ExpensesTab";
import DeclarationStatus from "../../../../../components/DeclarationStatus";
import { SUBFORM_STATUS_MAPPINGS } from "../../../constants";
import EmptyWrapper from "../../../../../../../common/EmptyWrapper";
import OfficeUsageStatusChangeModal from "../OfficeUsageStatusChangeModal";
import { EuerDeclarationSubformStatus } from "../../../../../types";
import {
  destroyMessage,
  showErrorMessage,
  showSuccessMessage,
  showLoadingMessage,
} from "../../../../../../../../utils";
import { SMALL_BUSINESS_PAYMENT_FREQUENCIES } from "../../../../../../../../types";
import {
  getInputsFromForm,
  INITIAL_CALCULATION_RESULTS,
  INITIAL_FLAT_RATE_INPUTS,
  INITIAL_FULL_COST_INPUTS,
  transformInputsToFormFieldsValue,
} from "../helpers";
import { useUserContext } from "../../../../../contexts/UserContext";
import {
  EuerDeclarationSubformType,
  EuerDeclarationSubformCalculationMethod,
  HomeOfficeExpense,
  KontaxNoteType,
} from "../../../../../../../../api/graphql/schema.generated";
import {
  EuerDeclarationSubformDocument,
  useEuerDeclarationSubformQuery,
} from "../../../../../../../../api/graphql/queries/euerDeclarationSubform.generated";
import WarnLeaveWithoutSaving from "../../../../../../../common/WarnLeaveWithoutSaving";
import { ActionsContainer } from "../../../../../styles";
import NotesDrawer from "../../../../../../../common/NotesDrawer";
import { useUpsertHomeOfficeExpenseMutation } from "../../../../../../../../api/graphql/mutations/homeOfficeExpense/upsertHomeOfficeExpense.generated";
import client from "../../../../../../../../api/graphql/client";
import { OfficeUsageQuestionnaireValues } from "../types";

const { Title } = Typography;

const { TabPane } = Tabs;
const StyledTabs = styled(Tabs)`
  margin-top: 45px;
`;
const DeclarationStatusWrapper = styled.div`
  margin-top: 30px;
`;

interface Props {
  taxYear: number;
}

export enum Tab {
  EXPENSES = "1",
  CALCULATION = "2",
}

type OfficeUsageFormInputs = EuerDeclarationOfficeUsageSubformInputs & {
  homeOwnershipType?: HomeOwnershipType;
  calculationMethod: EuerDeclarationSubformCalculationMethod;
};

const LOADING_MESSAGE_KEY = "saving-office-usage-subform";

const OfficeUsageView = ({ taxYear }: Props) => {
  const user = useUserContext();
  const [email] = useEmailParam();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState<string>(Tab.CALCULATION);
  const [formInstance] = Form.useForm<OfficeUsageFormInputs>();
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const [upsertHomeOfficeExpense] = useUpsertHomeOfficeExpenseMutation();

  const [questionnaireCalculationMethod, setQuestionnaireCalculationMethod] =
    useState<EuerDeclarationSubformCalculationMethod | null>(null);
  const [questionnaireValues, setQuestionnaireValues] =
    useState<OfficeUsageQuestionnaireValues | null>(null);
  const [calculationResults, setCalculationResults] =
    useState<EuerDeclarationOfficeUsageSubformCalculationResults>(
      INITIAL_CALCULATION_RESULTS
    );

  const [currentFormValues, setCurrentFormValues] =
    useState<OfficeUsageFormInputs>({
      calculationMethod:
        EuerDeclarationSubformCalculationMethod.OFFICE_USAGE_FULL_COST,
      ...INITIAL_FLAT_RATE_INPUTS,
      ...INITIAL_FULL_COST_INPUTS,
    });
  const [initialFormValues, setInitialFormValues] = useState({});
  const [initialCalculationResults, setInitialCalculationResults] = useState(
    {}
  );

  const [isStatusChangeModalVisible, setIsStatusChangeModalVisible] =
    useState<boolean>(false);

  const isSmallBusiness = useMemo(() => {
    const taxYearVatSettings = user?.vatYearSettings?.find(
      ({ year }) => year === taxYear
    );
    return SMALL_BUSINESS_PAYMENT_FREQUENCIES.includes(
      taxYearVatSettings?.vatPaymentFrequency!
    );
  }, [user, taxYear]);

  const isDirty = useMemo(() => {
    const { calculationMethod } = currentFormValues;
    const formValuesChanged = !isEqual(
      {
        calculationMethod,
        ...getInputsFromForm(calculationMethod, currentFormValues),
      },
      initialFormValues
    );

    const calculationResultsChanged = !isEqual(
      calculationResults,
      initialCalculationResults
    );

    return formValuesChanged || calculationResultsChanged;
  }, [
    currentFormValues,
    calculationResults,
    initialFormValues,
    initialCalculationResults,
  ]);

  const { data: queryResult, refetch: refetchUseEuerDeclarationSubformQuery } =
    useEuerDeclarationSubformQuery({
      skip: !email,
      variables: {
        email: email!,
        year: taxYear,
        type: EuerDeclarationSubformType.OFFICE_USAGE,
        shouldIncludeHomeOfficeExpenses: true,
      },
      notifyOnNetworkStatusChange: true,
      onCompleted: ({ euerDeclarationSubform }) => {
        if (!euerDeclarationSubform) {
          return;
        }
        const {
          calculationMethod: savedCalculationMethod,
          questionnaireCalculationMethod,
          questionnaireValues,
          inputs,
          calculationResults,
        } = euerDeclarationSubform;
        setQuestionnaireCalculationMethod(
          questionnaireCalculationMethod || null
        );
        setQuestionnaireValues(questionnaireValues || null);
        const isEmptySubform = isEmpty(calculationResults);

        const calculationMethod =
          isEmptySubform && questionnaireCalculationMethod
            ? questionnaireCalculationMethod
            : savedCalculationMethod;

        const fieldValues = {
          ...transformInputsToFormFieldsValue(
            calculationMethod,
            inputs as unknown as EuerDeclarationSubformInputs,
            questionnaireValues
          ),
          calculationMethod,
        };

        setCurrentFormValues(fieldValues);
        setInitialFormValues({
          calculationMethod,
          ...getInputsFromForm(calculationMethod, fieldValues),
        });
        formInstance.setFieldsValue(fieldValues);

        const initCalculationResults = defaults(
          { ...calculationResults },
          calculateInitialResults(calculationMethod, fieldValues)
        );
        setCalculationResults(initCalculationResults);
        setInitialCalculationResults(initCalculationResults);
      },
    });
  const subform = queryResult?.euerDeclarationSubform;
  const homeOfficeExpenses = subform?.homeOfficeExpenses;

  const [updateEuerDeclarationSubform] =
    useUpdateEuerDeclarationSubformMutation();

  const showStatusChangeModal = useCallback(() => {
    setIsStatusChangeModalVisible(true);
  }, []);

  const closeStatusChangeModal = useCallback(() => {
    setIsStatusChangeModalVisible(false);
  }, []);

  const onFormValueChange = useCallback((values: { [key: string]: any }) => {
    setCurrentFormValues((currentValues) => ({
      ...currentValues,
      ...values,
    }));
  }, []);

  const updateHomeOfficeExpenses = useCallback(
    () =>
      Promise.all(
        homeOfficeExpenses?.map((expense) => {
          return upsertHomeOfficeExpense({
            variables: {
              email: email!,
              year: taxYear,
              payload: {
                id: expense.id,
                type: expense.type,
                period: expense.period,
                monthsUsed: formInstance.getFieldValue("monthsUsed"),
                adjustByOfficeAreaShare: expense.adjustByOfficeAreaShare,
                amount: expense.amount,
                vatRate: expense.vatRate,
                note: expense.note,
              },
            },
          });
        }) || []
      ),
    [email, formInstance, homeOfficeExpenses, taxYear, upsertHomeOfficeExpense]
  );

  const calculateInitialResults = (
    method: EuerDeclarationSubformCalculationMethod,
    fieldValues: any
  ) => {
    const inputs = getInputsFromForm(method, fieldValues);

    let results: EuerDeclarationOfficeUsageSubformCalculationResults;
    try {
      results = calculateSubformResults(
        EuerDeclarationSubformType.OFFICE_USAGE,
        method,
        inputs,
        taxYear
      ) as EuerDeclarationOfficeUsageSubformCalculationResults;
    } catch (error) {
      // Use empty results when validation failed
      results = INITIAL_CALCULATION_RESULTS;
    }

    return results;
  };

  const saveOfficeUsageSubform = useCallback(
    async (status?: EuerDeclarationSubformStatus) => {
      if (email && subform) {
        const { calculationMethod } = currentFormValues;
        setIsSaving(true);
        showLoadingMessage(LOADING_MESSAGE_KEY);
        let savingFailed = false;
        try {
          const inputs = getInputsFromForm(
            calculationMethod,
            currentFormValues
          );
          const payload: UpdateEuerDeclarationSubformInput = {
            calculationMethod,
            inputs,
            calculationResults,
            status: status || subform.status,
          };
          await updateHomeOfficeExpenses();
          await updateEuerDeclarationSubform({
            variables: {
              email,
              year: taxYear,
              type: EuerDeclarationSubformType.OFFICE_USAGE,
              payload,
            },
          });
          await client.refetchQueries({
            include: [EuerDeclarationSubformDocument],
          });
          // to update expense table
        } catch (error) {
          savingFailed = true;
        } finally {
          destroyMessage(LOADING_MESSAGE_KEY);
          setIsSaving(false);
          if (!savingFailed) {
            showSuccessMessage("Successfully saved");
          } else {
            showErrorMessage("There was a problem submitting the form");
          }
        }
      }
    },
    [
      email,
      subform,
      currentFormValues,
      calculationResults,
      updateEuerDeclarationSubform,
      taxYear,
      updateHomeOfficeExpenses,
    ]
  );

  const onTabChange = useCallback(
    (key) => {
      // If user is transitioning from the form tab to the expenses tab with unsaved changes, warn them.
      if (activeTab === Tab.CALCULATION && key === Tab.EXPENSES && isDirty) {
        Modal.confirm({
          title: WARNING_MESSAGE,
          onOk: () => setActiveTab(key),
          cancelText: "Abbrechen",
        });
      } else {
        setActiveTab(key);
      }
    },
    [activeTab, isDirty]
  );

  const officeAreaShare = useMemo(() => {
    const { calculationMethod } = currentFormValues;
    if (
      calculationMethod ===
      EuerDeclarationSubformCalculationMethod.OFFICE_USAGE_FLAT_RATE_5_EUR
    ) {
      return 1;
    }

    const totalHomeArea =
      "totalHomeArea" in currentFormValues
        ? currentFormValues.totalHomeArea
        : 0;

    const officeArea =
      "officeArea" in currentFormValues ? currentFormValues.officeArea : 0;

    if (!totalHomeArea || !officeArea) {
      return 0;
    }

    return officeArea / totalHomeArea;
  }, [currentFormValues]);

  if (!email) {
    return <EmptyWrapper description="Suche nach einem Mandanten" />;
  }

  if (!subform) {
    return null;
  }

  const { calculationMethod } = currentFormValues;

  return (
    <>
      <WarnLeaveWithoutSaving warn={isDirty} />
      <BackButton
        text={`Anlage EÜR ${taxYear}`}
        to={{
          pathname: "/tax-declaration/euer-declaration",
          search: location.search,
        }}
      />
      <ActionsContainer>
        <ActionLogDrawer
          withIcon
          title="Action log"
          changeLogs={subform.changeLogs as ChangeLog[]}
        />
        <NotesDrawer
          notes={subform.notes}
          title="Häusliches Arbeitszimmer"
          type={KontaxNoteType.EUER_DECLARATION_SUBFORM}
          recordId={subform.id}
        />
      </ActionsContainer>
      <Title level={3}>{`Häusliches Arbeitszimmer ${taxYear}`}</Title>
      <DeclarationStatusWrapper>
        <DeclarationStatus
          mapping={SUBFORM_STATUS_MAPPINGS}
          status={subform.status}
          statusUpdatedAt={subform.statusUpdatedAt}
          changedBy={subform.lastStatusChange?.changedBy}
          onClick={showStatusChangeModal}
        />
      </DeclarationStatusWrapper>
      <StyledTabs activeKey={activeTab} onChange={onTabChange}>
        <TabPane tab="Belege" key={Tab.EXPENSES}>
          <ExpensesTab
            taxYear={taxYear}
            email={email}
            officeAreaShare={officeAreaShare}
            refetchHomeOfficeExpense={refetchUseEuerDeclarationSubformQuery}
            homeOfficeExpenses={homeOfficeExpenses as HomeOfficeExpense[]}
          />
        </TabPane>
        <TabPane tab="Berechnung" key={Tab.CALCULATION}>
          <CalculationTab
            taxYear={taxYear}
            calculationMethod={calculationMethod}
            questionnaireCalculationMethod={questionnaireCalculationMethod}
            questionnaireValues={questionnaireValues}
            onFormValueChange={onFormValueChange}
            formInstance={formInstance}
            saveSubform={saveOfficeUsageSubform}
            calculationResults={calculationResults}
            setCalculationResults={setCalculationResults}
            isSmallBusiness={isSmallBusiness}
            isSaving={isSaving}
            officeAreaShare={officeAreaShare}
            setActiveTab={setActiveTab}
            homeOfficeExpenses={homeOfficeExpenses as HomeOfficeExpense[]}
          />
        </TabPane>
      </StyledTabs>

      {subform && isStatusChangeModalVisible && (
        <OfficeUsageStatusChangeModal
          calculationMethod={calculationMethod}
          inputs={getInputsFromForm(calculationMethod, currentFormValues)}
          calculationResults={calculationResults}
          status={subform.status}
          isSmallBusiness={isSmallBusiness}
          onClose={closeStatusChangeModal}
          onSave={saveOfficeUsageSubform}
          officeAreaShare={officeAreaShare}
          totalNetAmount={subform.totalNetAmount}
          totalVatAmount={subform.totalVatAmount}
        />
      )}
    </>
  );
};

export default OfficeUsageView;
