import { useCallback, useEffect, useState } from "react";
import { notification } from "antd";

import { Document, IUser, IUserDetails } from "../../../../../../types";
import { DocumentCategory } from "../../../../../../api/graphql/types";
import { useDocumentCategoriesQuery } from "../../../../../../api/graphql/queries";
import DocumentCategoryFiles from "./DocumentCategoryFiles";
import { useFetchDocumentsLazyQuery } from "../../../../../../api/graphql/queries/documents/documents.generated";
import { Document as GQLDocument } from "../../../../../../api/graphql/schema.generated";

const OTHER_CATEGORY = {
  id: null,
  categoryName: "OTHER",
  folderName: "RECEIPTS",
};

const DocumentsTree = ({
  user,
  editUser,
  categoryNames,
  year,
  allowUpload,
  setSelectedDocumentIds,
  setAllDocumentIds,
  setDocumentsCount,
}: {
  user: IUser;
  editUser: (email: string, userDetails: IUserDetails) => Promise<void>;
  categoryNames: string[];
  year?: number;
  allowUpload?: boolean;
  setSelectedDocumentIds?: (ids: string[]) => void;
  setAllDocumentIds?: (ids: string[]) => void;
  setDocumentsCount?: (count: number) => void;
}) => {
  const [documents, setDocuments] = useState<Document[]>([]);
  const [categories, setDocumentCategories] = useState<DocumentCategory[]>([]);
  const [isLoadingCategories, setIsLoadingCategories] = useState(true);
  const { refetch: getDocumentCategories } = useDocumentCategoriesQuery({
    variables: {
      categoryNames,
    },
  });

  const [fetchDocumentsQuery, { loading: isLoadingDocuments, data: docs }] =
    useFetchDocumentsLazyQuery();

  const fetchDocuments = useCallback(() => {
    fetchDocumentsQuery({
      variables: {
        email: user.email,
        year,
        categoryIds: hasOtherCategory(categoryNames)
          ? null
          : (categories.map((c) => c.id) as string[]),
      },
    });
  }, [categoryNames, categories, fetchDocumentsQuery, user.email, year]);

  const fetchDocumentCategories = useCallback(async () => {
    try {
      setIsLoadingCategories(true);
      const { data } = await getDocumentCategories({ categoryNames });

      const categoryData = [...data.documentCategories];

      if (categoryNames.includes("OTHER")) {
        categoryData.push({
          id: null,
          categoryName: "OTHER",
          folderName: "RECEIPTS",
        });
      }

      setDocumentCategories(categoryData);
    } catch (error) {
      const errMessage = (error as Error)?.message;
      notification.error({
        message: `An error occurred while fetching documents: (${errMessage})`,
      });
    } finally {
      setIsLoadingCategories(false);
    }
  }, [categoryNames, getDocumentCategories]);

  const onDeleteDocument = (documentId: string) => {
    const updatedDocs = documents.filter(({ id }) => id !== documentId);
    setDocuments(updatedDocs);
    setDocumentsCount?.(updatedDocs.length);
  };

  useEffect(() => {
    fetchDocumentCategories();
  }, [fetchDocumentCategories]);

  useEffect(() => {
    fetchDocuments();
  }, [categories, user.email, fetchDocuments]);

  useEffect(() => {
    if (docs?.documents) {
      setDocuments(
        (docs.documents as GQLDocument[]).map(mapGQLDocumentToDocument)
      );
      setAllDocumentIds &&
        setAllDocumentIds(docs.documents.map((doc) => doc.id));
      setDocumentsCount?.(docs.documents.length);
    }
  }, [docs?.documents, setAllDocumentIds, setDocumentsCount]);
  const hasOtherDocumentsSection = hasOtherCategory(categoryNames);

  const addUploadedDocuments = (newDocuments: Document[]) => {
    const updatedDocuments = [...documents, ...newDocuments];
    setDocuments(updatedDocuments);
    setDocumentsCount?.(updatedDocuments.length);
  };

  return (
    <div style={{ width: 900, margin: "auto" }}>
      {!isLoadingCategories && !isLoadingDocuments && (
        <>
          <DocumentCategoryFiles
            categories={categories?.filter(
              (category) => category.categoryName !== "OTHER"
            )}
            user={user}
            editUser={editUser}
            documents={documents}
            allowUpload={allowUpload}
            year={year}
            addUploadedDocuments={addUploadedDocuments}
            setSelectedDocumentIds={setSelectedDocumentIds}
            onDeleteDocument={onDeleteDocument}
          />
          {hasOtherDocumentsSection && (
            <DocumentCategoryFiles
              categories={[OTHER_CATEGORY]}
              user={user}
              editUser={editUser}
              documents={documents}
              allowUpload={allowUpload}
              year={year}
              addUploadedDocuments={addUploadedDocuments}
              onDeleteDocument={onDeleteDocument}
            />
          )}
        </>
      )}
    </div>
  );
};

function hasOtherCategory(categoryNames: string[]) {
  return categoryNames?.includes("OTHER");
}

function mapGQLDocumentToDocument(doc: GQLDocument): Document {
  return {
    metadata: doc.metadata as any,
    id: doc.id,
    type: doc.type,
    url: doc.url,
    downloadUrl: doc.downloadUrl,
    filename: `${doc.name}.${doc.type}`,
    createdAt: doc.createdAt,
  };
}

export default DocumentsTree;
