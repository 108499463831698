import React, { ChangeEvent } from "react";
import classNames from "classnames";

import { Select } from "../common/styledComponents";
import { BusinessAssetType } from "../../types";

const ASSET_TYPE_OPTIONS = [
  {
    label: "Bewegliche Wirtschaftsgüter",
    options: [
      [BusinessAssetType.MOVABLE_MOTOR_VEHICLES, "Kraftfahrzeuge"],
      [BusinessAssetType.MOVABLE_OFFICE_EQUIPMENT, "Büroausstattung"],
      [BusinessAssetType.MOVABLE_OTHERS, "Andere"],
    ],
  },
  {
    options: [[BusinessAssetType.IMMOVABLE, "Unbewegliche Wirtschaftsgüter"]],
  },
  {
    options: [[BusinessAssetType.INTANGIBLE, "Immaterielle Wirtschaftsgüter"]],
  },
];

const BusinessAssetTypeSelect = ({
  id,
  value,
  onChangeHandler,
  className = "",
  invalid = false,
}: {
  id: string;
  value: string;
  onChangeHandler: (event: ChangeEvent<HTMLSelectElement>) => void;
  className?: string;
  invalid?: boolean;
}) => {
  return (
    <div>
      <Select
        id={id}
        value={value}
        onChange={onChangeHandler}
        className={classNames(className, { invalid })}
      >
        {ASSET_TYPE_OPTIONS.map(({ label, options }) => {
          if (label) {
            return (
              <optgroup key={label} label={label}>
                {options.map(([val, text]) => (
                  <option key={val} value={val}>
                    {text}
                  </option>
                ))}
              </optgroup>
            );
          }

          return options.map(([val, text]) => (
            <option key={val} value={val}>
              {text}
            </option>
          ));
        })}
      </Select>
    </div>
  );
};

export default BusinessAssetTypeSelect;
