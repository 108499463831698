import type * as Types from "../../schema.generated";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type DeleteRecurringPaymentMutationVariables = Types.Exact<{
  transactionId: Types.Scalars["ID"];
}>;

export type DeleteRecurringPaymentMutation = {
  __typename?: "Mutation";
  deleteRecurringPayment: { __typename?: "MutationResult"; success: boolean };
};

export const DeleteRecurringPaymentDocument = gql`
  mutation deleteRecurringPayment($transactionId: ID!) {
    deleteRecurringPayment(transactionId: $transactionId) {
      success
    }
  }
`;
export type DeleteRecurringPaymentMutationFn = Apollo.MutationFunction<
  DeleteRecurringPaymentMutation,
  DeleteRecurringPaymentMutationVariables
>;

/**
 * __useDeleteRecurringPaymentMutation__
 *
 * To run a mutation, you first call `useDeleteRecurringPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRecurringPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRecurringPaymentMutation, { data, loading, error }] = useDeleteRecurringPaymentMutation({
 *   variables: {
 *      transactionId: // value for 'transactionId'
 *   },
 * });
 */
export function useDeleteRecurringPaymentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteRecurringPaymentMutation,
    DeleteRecurringPaymentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteRecurringPaymentMutation,
    DeleteRecurringPaymentMutationVariables
  >(DeleteRecurringPaymentDocument, options);
}
export type DeleteRecurringPaymentMutationHookResult = ReturnType<
  typeof useDeleteRecurringPaymentMutation
>;
export type DeleteRecurringPaymentMutationResult =
  Apollo.MutationResult<DeleteRecurringPaymentMutation>;
export type DeleteRecurringPaymentMutationOptions = Apollo.BaseMutationOptions<
  DeleteRecurringPaymentMutation,
  DeleteRecurringPaymentMutationVariables
>;
