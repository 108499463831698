import axios from "axios";

interface Props {
  url: string;
  form: FormData;
}

export const uploadFile = ({ url, form }: Props) => {
  return axios.post(url, form, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};
