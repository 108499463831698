export type IMessage = {
  type: IMessageType;
  duration?: number;
  content?: string;
  key?: string;
};

export enum IMessageType {
  NONE = "none",
  LOADING = "loading",
  SUCCESS = "success",
  ERROR = "error",
  INFO = "info",
}

export const EMPTY_IMESSAGE: IMessage = {
  type: IMessageType.NONE,
  duration: 1.5,
  content: undefined,
  key: undefined,
};
