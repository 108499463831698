import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { Typography } from "antd";
import { InputRef } from "rc-input/lib/interface";

import { formatAmountCurrency } from "../../../../../../../../utils";
import { AmountInputWrapper, StyledInput } from "./styledComponents";
import { DEFAULT_LOCALE } from "../../../../../../../../constants";
import { calculateSimpleMathExpression } from "../../../../../../../../utils";

const { Text } = Typography;

interface AmountInputProps {
  editable: boolean;
  strong?: boolean;
  value: number;
  onChange: (value: number) => void;
}

const formatAmountInputValue = (value: number | undefined): string => {
  if (value == null) {
    return "";
  }

  return Intl.NumberFormat(DEFAULT_LOCALE).format(value);
};

const AmountInput = ({
  editable,
  strong,
  value,
  onChange,
}: AmountInputProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    if (isEditing) {
      inputRef.current!.focus();
    }
  }, [isEditing]);

  const startEditing = () => {
    setInputValue(formatAmountInputValue(value));
    setIsEditing(true);
  };

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const calculateExpression = () => {
    const calculatedValue = calculateSimpleMathExpression(inputValue);
    if (!isNaN(calculatedValue)) {
      setInputValue(formatAmountInputValue(calculatedValue));
    }
  };

  const commitChange = () => {
    setIsEditing(false);
    const calculatedValue = calculateSimpleMathExpression(inputValue);
    if (!isNaN(calculatedValue) && calculatedValue !== value) {
      onChange(calculatedValue);
    }
  };

  if (isEditing) {
    return (
      <StyledInput
        ref={inputRef}
        value={inputValue}
        onChange={handleOnChange}
        onPressEnter={calculateExpression}
        onBlur={commitChange}
      />
    );
  }

  return (
    <AmountInputWrapper
      {...(editable ? { className: "editable", onClick: startEditing } : {})}
    >
      <Text strong={strong}>{formatAmountCurrency(value)}</Text>
    </AmountInputWrapper>
  );
};

export default AmountInput;
