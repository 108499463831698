import React from "react";
import { Col, Row, List, Typography } from "antd";

import { EuerDeclarationFields } from "../../../../../../../../api/graphql";
import {
  recalculateTotalFields,
  TOTAL_FIELDS,
} from "../../../../../../../../utils/euerFields";
import {
  ListScrollContainer,
  ElsterCodeCol,
  AmountCol,
  AmountInputWrapper,
} from "./styledComponents";
import euerDeclarationFields from "../euerDeclarationFields";
import AmountInput from "./AmountInput";
import { formatAmountCurrency } from "../../../../../../../../utils";
import EuerDeclarationFieldLink from "./EuerDeclarationFieldLink";

const { Text } = Typography;

const NOT_EDITABLE_EUER_FIELDS = [...TOTAL_FIELDS];

export interface EuerDeclarationFieldsListProps {
  email: string;
  taxYear: number;
  fields: EuerDeclarationFields;
  autoCalculatedFields: EuerDeclarationFields;
  onChange: (fields: EuerDeclarationFields) => void;
}

const EuerDeclarationFieldsList = ({
  fields,
  email,
  taxYear,
  autoCalculatedFields,
  onChange,
}: EuerDeclarationFieldsListProps) => {
  const handleAmountChange = (elsterField: string, value: number) => {
    onChange(recalculateTotalFields({ ...fields, [elsterField]: value }));
  };

  return (
    <ListScrollContainer>
      <List
        itemLayout="horizontal"
        dataSource={Array.from(euerDeclarationFields)}
        header={
          <Row>
            <ElsterCodeCol span={3}>
              <Text strong>Kennzahl</Text>
            </ElsterCodeCol>
            <Col span={16}>
              <Text strong>Bezeichnung</Text>
            </Col>
            <AmountCol span={5}>
              <Text strong>Summe</Text>
            </AmountCol>
          </Row>
        }
        renderItem={([elsterField, definition]) => (
          <List.Item>
            <ElsterCodeCol span={3}>{definition.label}</ElsterCodeCol>
            <Col span={16}>
              <EuerDeclarationFieldLink
                email={email}
                taxYear={taxYear}
                code={elsterField}
                description={definition.description}
              />
            </Col>
            <AmountCol span={5}>
              <AmountInput
                editable={!NOT_EDITABLE_EUER_FIELDS.includes(elsterField)}
                strong={TOTAL_FIELDS.includes(elsterField)}
                value={fields[elsterField] || 0}
                onChange={(value: number) =>
                  handleAmountChange(elsterField, value)
                }
              />
              {!TOTAL_FIELDS.includes(elsterField) &&
                fields[elsterField] !== autoCalculatedFields[elsterField] && (
                  <AmountInputWrapper className="ant-typography ant-typography-secondary m-0">
                    {`Auto: ${formatAmountCurrency(
                      autoCalculatedFields[elsterField] || 0
                    )}`}
                  </AmountInputWrapper>
                )}
            </AmountCol>
          </List.Item>
        )}
      />
    </ListScrollContainer>
  );
};

export default EuerDeclarationFieldsList;
