const parseDayAndMonth = (
  date: string
): {
  day: number;
  month: number;
} => {
  const day = date.length === 3 ? date.slice(0, 1) : date.slice(0, 2);
  const month = date.slice(-2);

  return {
    day: parseInt(day, 10),
    month: parseInt(month, 10),
  };
};

const isDateAfter = (date1: string, date2: string) => {
  const parsedDate1 = parseDayAndMonth(date1);
  const parsedDate2 = parseDayAndMonth(date2);

  // compare month first
  if (parsedDate1.month > parsedDate2.month) {
    return true;
  }

  // if same month, compare day
  return !!(
    parsedDate1.month === parsedDate2.month && parsedDate1.day > parsedDate2.day
  );
};

export default isDateAfter;
