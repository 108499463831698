import React, { useState } from "react";
import { Checkbox, notification } from "antd";

import {
  useCreateRecurringPaymentMutation,
  useDeleteRecurringPaymentMutation,
} from "../../api/graphql/mutations/recurringPayment";
import { showGraphQlErrorNotification } from "../../utils";
import { ITransaction, TransactionSource } from "../../types";

export interface RecurringPaymentToggleProps {
  transactionId: string;
  recurringPaymentRuleId?: number | null;
  isDisabled?: boolean;
}

export const RECURRING_PAYMENT_TOGGLE_TEXT = "Wiederkehrende Zahlung";

export const shouldDisplayToggle = (transaction: ITransaction): boolean => {
  return (
    transaction.source === TransactionSource.SOLARIS && !!transaction.verified
  );
};

const RecurringPaymentToggle = ({
  transactionId,
  recurringPaymentRuleId,
  isDisabled,
}: RecurringPaymentToggleProps) => {
  const [ruleId, setRecurringPaymentRuleId] = useState<
    number | undefined | null
  >(recurringPaymentRuleId);

  const [createRecurringPaymentRule, { loading: creating }] =
    useCreateRecurringPaymentMutation();
  const [deleteRecurringPaymentRule, { loading: deleting }] =
    useDeleteRecurringPaymentMutation();

  const toggle = async () => {
    if (creating || deleting) {
      return;
    }

    try {
      if (ruleId) {
        await deleteRecurringPaymentRule({ variables: { transactionId } });
        setRecurringPaymentRuleId(null);
      } else {
        const result = await createRecurringPaymentRule({
          variables: { transactionId },
        });

        setRecurringPaymentRuleId(result.data?.createRecurringPayment?.id);
      }

      notification.success({
        message: `Recurring payment was ${ruleId ? "deleted" : "created"}`,
      });
    } catch (err) {
      showGraphQlErrorNotification(
        `An error occurred while ${
          ruleId ? "deleting" : "creating"
        } recurring payment:`,
        err
      );
    }
  };

  return (
    <Checkbox
      checked={!!ruleId}
      onChange={toggle}
      disabled={isDisabled || creating || deleting}
    >
      {RECURRING_PAYMENT_TOGGLE_TEXT}
    </Checkbox>
  );
};

export default RecurringPaymentToggle;
