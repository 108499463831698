import { BugOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import styled from "styled-components";

import colors from "../../../themes/colors";

const Indicator = styled.div`
  bottom: 10px;
  color: ${colors.black};
  position: absolute;
  right: 10px;
  opacity: 0.5;
  z-index: 1;
`;

const DebugIndicator = () => {
  const isAnyDebugSettingActive =
    localStorage.getItem("debug:apiUrl") ||
    localStorage.getItem("debug:mockAuth") === "true";

  return isAnyDebugSettingActive ? (
    <Indicator>
      <Tooltip title="Some debug settings are active" placement="left">
        <BugOutlined style={{ verticalAlign: "middle" }} />
      </Tooltip>
    </Indicator>
  ) : null;
};

export default DebugIndicator;
