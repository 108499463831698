import styled from "styled-components";

import colors from "../../../themes/colors";

export const StyleFix = styled.div`
  .ant-select-selection-item-remove {
    line-height: 17px;
  }
`;

export const FormHeader = styled.div`
  padding: 16px 24px 15px;
  margin-bottom: 32px;
  border-bottom: 1px solid ${colors.veryLightGrey};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const FormHeading = styled.h1`
  font-size: 20px;
  margin: 0;
`;

interface FieldBoxProps {
  readonly hasError?: boolean;
}

export const FieldBox = styled.div<FieldBoxProps>`
  padding: 24px 24px 0;
  margin-bottom: 24px;
  border: 1px solid
    ${(props) => (props.hasError ? colors.danger : colors.veryLightGrey)};
  background-color: ${colors.lightestEverGray};
  border-radius: 4px;
`;

export const RulesViewHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;
