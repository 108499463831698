import { Button, Form, Modal, Select } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";

import { Asset, ILocalAsset } from "../../../../../types";
import Upload from "../../../../common/Upload";
import LocalAssetsViewer from "../../../../common/LocalAssetsViewer";
import { DeclarationDocumentModalContent } from "./style";
import { showGraphQlErrorNotification } from "../../../../../utils";
import {
  DeclarationDocumentType,
  TaxDeclarationType,
  TaxBoardUser,
} from "../../../../../api/graphql/schema.generated";
import {
  DECLARATION_DOCUMENT_TYPE_OPTIONS,
  DECLARATION_TYPE_OPTIONS,
} from "./constants";
import { useCreateDeclarationDocumentMutation } from "../../../../../api/graphql/mutations/declarationDocument/createDeclarationDocument.generated";
import { useCreateDeclarationDocumentAssetMutation } from "../../../../../api/graphql/mutations/declarationDocument/createDeclarationDocumentAsset.generated";
import { useFinalizeAssetUploadMutation } from "../../../../../api/graphql";
import { uploadFile } from "../../../../../api/modules/Common";
import { SaveAssetResult } from "../../types";
import { notifyUserAfterSubmittingSubform } from "../../utils";

export type DeclarationDocumentFormInputs = {
  assets?: Array<ILocalAsset>;
  declarationType: TaxDeclarationType;
  documentType: DeclarationDocumentType;
  taxBoardUser: TaxBoardUser;
};

const CreateDeclarationDocumentModal = ({
  visible,
  onSuccess,
  onClose,
  taxBoardUser,
}: {
  visible: boolean;
  onSuccess: Function;
  onClose: Function;
  taxBoardUser: TaxBoardUser;
}) => {
  const [form] = Form.useForm<DeclarationDocumentFormInputs>();
  const [assets, setAssets] = useState<Array<ILocalAsset>>([]);
  const [declarationId, setDeclarationId] = useState<string>();
  const [createDeclarationDocument] = useCreateDeclarationDocumentMutation();
  const [createDeclarationDocumentAsset] =
    useCreateDeclarationDocumentAssetMutation();
  const [finalizeAssetUpload] = useFinalizeAssetUploadMutation();
  const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(true);

  useEffect(() => {
    const { declarationType, documentType } = form.getFieldsValue();

    setIsSubmitDisabled(
      !declarationType || !documentType || assets.length === 0
    );
  }, [assets.length, form]);

  const declarationIdByDeclarationType = useMemo(() => {
    const {
      euerDeclarations,
      incomeTaxDeclarations,
      vatAnnualDeclarations,
      tradeTaxDeclarations,
    } = taxBoardUser;

    return {
      [TaxDeclarationType.EUER]: euerDeclarations[0].id,
      [TaxDeclarationType.INCOME_TAX]: incomeTaxDeclarations[0].id,
      [TaxDeclarationType.TRADE_TAX]: tradeTaxDeclarations[0].id,
      [TaxDeclarationType.VAT_ANNUAL]: vatAnnualDeclarations[0].id,
    };
  }, [taxBoardUser]);

  const handleValueChanges = () => {
    const { declarationType, documentType } = form.getFieldsValue();

    setDeclarationId(declarationIdByDeclarationType[declarationType]);
    setIsSubmitDisabled(
      !declarationType || !documentType || assets.length === 0
    );
  };

  const handleReset = useCallback(() => {
    setAssets([]);
    form.resetFields();
  }, [form]);

  const handleCancel = () => {
    onClose();
    handleReset();
  };

  const uploadAsset = useCallback(
    async (
      declarationDocumentId: string,
      { name, filetype, file }: ILocalAsset
    ): Promise<Asset> => {
      const createAssetResponse = await createDeclarationDocumentAsset({
        variables: {
          declarationDocumentId,
          name,
          filetype,
        },
      });

      if (!createAssetResponse.data) {
        throw new Error("Failed to create home office expense asset");
      }

      const { assetId, formData, url } =
        createAssetResponse.data.createDeclarationDocumentAsset;

      const form = new FormData();
      formData.forEach(({ key, value }) => form.append(key, value));
      form.append("file", file);

      await uploadFile({ url, form });

      const finalizeAssetUploadResponse = await finalizeAssetUpload({
        variables: { assetId },
      });

      if (!finalizeAssetUploadResponse.data) {
        throw new Error("Failed to finalize asset upload");
      }

      return finalizeAssetUploadResponse.data.finalizeAssetUpload;
    },
    [createDeclarationDocumentAsset, finalizeAssetUpload]
  );

  const saveAssets = useCallback(
    async (declarationDocumentId: string): Promise<Array<SaveAssetResult>> => {
      const uploadAssets = assets.map((asset) =>
        uploadAsset(declarationDocumentId, asset)
      );

      const uploadStatuses = await Promise.allSettled(uploadAssets);

      return uploadStatuses.map((promiseState, index) => ({
        ...promiseState,
        localAsset: assets[index],
      }));
    },
    [assets, uploadAsset]
  );

  const handleSubmit = useCallback(
    async (values: DeclarationDocumentFormInputs) => {
      setIsButtonLoading(true);
      delete values.assets;

      let savingFailed = false;
      let assetResults: SaveAssetResult[] = [];

      try {
        const result = await createDeclarationDocument({
          variables: { ...values, declarationId: declarationId! },
        });

        await saveAssets(result.data?.createDeclarationDocument?.id!);

        await onSuccess();
        onClose();
        handleReset();
      } catch (err) {
        savingFailed = true;
        showGraphQlErrorNotification("Create tax case document error", err);
      } finally {
        setIsButtonLoading(false);
        notifyUserAfterSubmittingSubform(savingFailed, assetResults);
      }
    },
    [
      declarationId,
      createDeclarationDocument,
      handleReset,
      onClose,
      onSuccess,
      saveAssets,
    ]
  );

  const onAssetDelete = (deletedAsset: Asset | ILocalAsset) => {
    const updatedAssets = assets.filter(
      (asset) =>
        (asset as ILocalAsset).name !== (deletedAsset as ILocalAsset).name
    );
    setAssets(updatedAssets);
    form.setFieldsValue({
      assets: updatedAssets,
    });
  };

  const onDropFiles = useCallback(
    (files: File[]) => {
      const newAssets = files.map((file) => ({
        name: file.name,
        fullsize: URL.createObjectURL(file),
        filetype: file.type.split("/").pop()!,
        file,
      }));
      const updatedAssets = [...assets, ...newAssets];

      form.setFieldsValue({
        assets: updatedAssets,
      });

      setAssets(updatedAssets);
    },
    [form, assets]
  );

  const footer = [
    <Button
      form="declarationDocumentFormId"
      key="submit"
      htmlType="submit"
      type="primary"
      disabled={isSubmitDisabled}
      loading={isButtonLoading}
    >
      Hochladen
    </Button>,
  ];

  return (
    <Modal
      visible={visible}
      centered
      footer={footer}
      onCancel={handleCancel}
      width={862}
      title="Externe Anlagen importieren"
    >
      <Form
        form={form}
        name="declarationDocumentForm"
        id="declarationDocumentFormId"
        onValuesChange={handleValueChanges}
        autoComplete="off"
        layout="vertical"
        requiredMark={false}
        onFinish={handleSubmit}
      >
        <DeclarationDocumentModalContent>
          <Form.Item
            name="assets"
            rules={[{ required: true }]}
            className="assets"
          >
            <Upload onDropFiles={onDropFiles} isInPreviewMode={!!assets.length}>
              <LocalAssetsViewer
                assets={assets}
                minHeight={430}
                onDelete={onAssetDelete}
              />
            </Upload>
          </Form.Item>

          <Form.Item
            label="Anlage"
            name="declarationType"
            rules={[{ required: true }]}
          >
            <Select
              placeholder="Anlage auswahlen"
              options={DECLARATION_TYPE_OPTIONS}
            ></Select>
          </Form.Item>

          <Form.Item
            label="Dokumentart"
            name="documentType"
            rules={[{ required: true }]}
          >
            <Select
              placeholder="Anlage auswahlen"
              options={DECLARATION_DOCUMENT_TYPE_OPTIONS}
            />
          </Form.Item>
        </DeclarationDocumentModalContent>
      </Form>
    </Modal>
  );
};

export default CreateDeclarationDocumentModal;
