import React from "react";
import { Select } from "antd";

import { StyledSelect } from "./styledComponents";
import {
  EUER_DECLARATION_STATUSES,
  EUER_DECLARATION_STATUS_MAPPINGS,
} from "../../../constants";
import { EuerDeclarationStatus } from "../../../../../../../../api/graphql/schema.generated";

export interface EuerDeclarationStatusSelectProps {
  value: EuerDeclarationStatus;
  onChange: (value: EuerDeclarationStatus) => void;
  disabled?: boolean;
  error?: boolean;
}

const EuerDeclarationStatusSelect = ({
  value,
  onChange,
  disabled,
  error,
}: EuerDeclarationStatusSelectProps) => {
  return (
    <StyledSelect
      value={value}
      status={error ? "error" : ""}
      defaultValue={EuerDeclarationStatus.OPEN}
      onChange={onChange}
      disabled={disabled}
    >
      {EUER_DECLARATION_STATUSES.map((value) => (
        <Select.Option key={value} value={value}>
          {EUER_DECLARATION_STATUS_MAPPINGS[value].label}
        </Select.Option>
      ))}
    </StyledSelect>
  );
};

export default EuerDeclarationStatusSelect;
