import styled from "styled-components";
import { AutoComplete, AutoCompleteProps, Input, Space } from "antd";

import colors from "../../../themes/colors";

export const Container = styled.div`
  background-color: ${colors.white};
  display: flex;
  flex: 1 1 0px;
  gap: 24px;
  padding: 40px;
  overflow: scroll;
  height: 100%;
  width: 100%;
`;

export const HalfWidthContainer = styled.div`
  flex: 0 50%;
`;

export const NotFoundContainer = styled.p`
  text-align: center;
  width: 100%;
  height: 300px;
  margin-top: 14px;
`;

export const UserEmail = styled.div`
  color: rgba(0, 0, 0, 0.45);
  cursor: pointer;
  gap: 5px;
  align-items: center;
  display: flex;
`;

export const AssetViewerContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: end;
  width: 100%;
`;

export const FormLabel = styled.label`
  width: 100%;
  margin-top: 20px;
`;

export const AnnotatorContainer = styled.div`
  mark {
    cursor: pointer;
  }
`;

export const TagSelect = styled(AutoComplete)<
  AutoCompleteProps & { color: string }
>`
  .ant-select-selector {
    background-color: ${({ color }) => color} !important;
  }
  width: 100%;
  margin-bottom: 20px;
  margin-right: 20px;
`;

const { TextArea } = Input;

export const CommentInput = styled(TextArea)`
  width: 520px;
`;

export const AssetsWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: end;
  box-sizing: border-size;
  border: 1px solid ${colors.veryLightGrey};
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
  margin-bottom: 60px;
`;

export const SaveButtonContainer = styled.div`
  padding: 20px 0;
`;

export const AnnotationWrapper = styled.div`
  padding-bottom: 40px;
`;

export const SpinnerContainer = styled(Space)`
  width: 100%;
  justify-content: center;
`;
