import React, { useEffect, useMemo } from "react";
import { Select, Form } from "antd";
import { NamePath } from "antd/lib/form/interface";

import { AntSelect } from "../../common/styledComponents";
import { vatCategories, getCompatibleVatCategories } from "../../../utils";
import {
  PaymentFrequency,
  ValueMeta,
  SMALL_BUSINESS_PAYMENT_FREQUENCIES,
  VatCategoryCode,
} from "../../../types";
import SelectBadges from "./SelectBadges";
import { RESET_OPTION } from "../../../utils/categories";
import { EXCLUDED_VCC_FOR_NO_VAT_NUMBER } from "../../../utils/vatCategories";
import { useKontaxUserContext } from "../../contexts/KontaxUserContext";

const { Option } = Select;

const VAT_CATEGORY_DROPDOWN_OPTIONS_WIDTH = 305;

interface VatCategoryOption {
  value: string;
  label: string;
  disabled?: boolean;
}

const isSmallBusinessOwner = (vatYearPaymentFrequency?: PaymentFrequency) => {
  return (
    vatYearPaymentFrequency &&
    SMALL_BUSINESS_PAYMENT_FREQUENCIES.includes(
      // this is the workaround needed because vatYearPaymentFrequency may come from the BE all in caps
      vatYearPaymentFrequency.toLowerCase() as PaymentFrequency
    )
  );
};

const mapToCategoryOptions = (categoryOption: string[]): VatCategoryOption => {
  const [value, label] = categoryOption;
  return {
    value,
    label,
  };
};
const getCategories = ({
  value,
  kontaxCategory,
  isSmallBusinessOwner,
  withResetOption,
  withoutExcludedNoVatNumberCodes,
}: {
  value?: string | null;
  kontaxCategory?: string | null;
  isSmallBusinessOwner?: boolean;
  withResetOption: boolean;
  withoutExcludedNoVatNumberCodes?: boolean;
}) => {
  let categories: VatCategoryOption[] = [
    {
      value: "",
      label: value ? "-- RESET --" : "Select VAT category",
      disabled: !value,
    },
  ];

  if (withResetOption) {
    const resetOption = {
      value: RESET_OPTION.RESET,
      label: "-- RESET --",
      disabled: false,
    };

    categories = value ? [resetOption] : [...categories, resetOption];
  }

  const results = categories.concat(
    (kontaxCategory
      ? getCompatibleVatCategories(kontaxCategory, isSmallBusinessOwner)
      : vatCategories
    ).map(mapToCategoryOptions)
  );

  if (withoutExcludedNoVatNumberCodes) {
    return results.map((vatCategoryOption) => {
      if (
        EXCLUDED_VCC_FOR_NO_VAT_NUMBER.includes(
          vatCategoryOption.value as VatCategoryCode
        )
      ) {
        return {
          ...vatCategoryOption,
          disabled: true,
        };
      }
      return vatCategoryOption;
    });
  }

  return results;
};

const VatCategorySelect = ({
  id,
  name,
  value,
  onChangeHandler,
  kontaxCategory,
  disabled,
  invalid,
  vatYearPaymentFrequency,
  meta,
  showInfo,
  updateValueOnCategoryChange,
  style,
  label,
  help,
  withResetOption = false,
}: {
  id?: string;
  name?: NamePath;
  value?: string | null;
  kontaxCategory?: string | null;
  onChangeHandler?: (category: string | null) => void;
  disabled?: boolean;
  invalid?: boolean;
  vatYearPaymentFrequency?: PaymentFrequency;
  meta?: ValueMeta;
  showInfo?: boolean;
  updateValueOnCategoryChange?: boolean;
  style?: React.CSSProperties;
  label?: string;
  help?: string;
  withResetOption?: boolean;
}) => {
  const user = useKontaxUserContext().user;
  const withoutExcludedNoVatNumberCodes = !!user && !user.vatNumber;

  const categoryOptions = useMemo(
    () =>
      getCategories({
        withResetOption,
        value,
        kontaxCategory,
        isSmallBusinessOwner: isSmallBusinessOwner(vatYearPaymentFrequency),
        withoutExcludedNoVatNumberCodes,
      }),
    [
      kontaxCategory,
      value,
      vatYearPaymentFrequency,
      withResetOption,
      withoutExcludedNoVatNumberCodes,
    ]
  );

  useEffect(() => {
    if (!updateValueOnCategoryChange) return;

    const hasOnlyOneOption = categoryOptions.length === 2;

    if (hasOnlyOneOption) {
      // The first option is always "RESET", so the only available option is at index 1
      const category = categoryOptions[1].value;

      if (value !== category && kontaxCategory !== null) {
        onChangeHandler?.(category);
      }
    }

    const isValueAllowed = categoryOptions
      .map(({ value }) => value)
      .includes(value as string);

    // If the value is not allowed, reset it.
    if (value && !isValueAllowed) {
      onChangeHandler?.(null);
    }
  }, [
    updateValueOnCategoryChange,
    categoryOptions,
    onChangeHandler,
    value,
    kontaxCategory,
  ]);

  return (
    <AntSelect data-test="vatCategorySelect" style={style}>
      <Form.Item label={label}>
        <SelectBadges
          meta={meta}
          hasValue
          info={
            showInfo && isSmallBusinessOwner(vatYearPaymentFrequency)
              ? "Kleinunternehmer"
              : undefined
          }
        />
        <Form.Item
          name={name}
          validateStatus={invalid ? "error" : undefined}
          help={help}
        >
          <Select
            id={id || "vat-category"}
            style={{ width: style?.width }}
            showSearch
            optionFilterProp="children"
            value={value || ""}
            onChange={(categoryValue) => {
              onChangeHandler?.(categoryValue || null);
            }}
            disabled={disabled}
            dropdownMatchSelectWidth={VAT_CATEGORY_DROPDOWN_OPTIONS_WIDTH}
          >
            {categoryOptions.map(({ value, label, disabled }) => {
              return (
                <Option key={value || label} value={value} disabled={disabled}>
                  {label}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
      </Form.Item>
    </AntSelect>
  );
};

export default VatCategorySelect;
