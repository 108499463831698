import React from "react";
import { Col, Divider, Typography } from "antd";
import moment from "moment";
import {
  EuerDeclarationCarUsageSubform1PctMethodInputs,
  EuerDeclarationCarUsageSubformCalculationResults,
  EuerDeclarationCarUsageSubformDriversLogInputs,
} from "@kontist/euer-declaration";

import {
  EuerDeclarationSubformCalculationMethod,
  EuerDeclarationSubformStatus,
} from "../../../../types";
import {
  formatAmountCurrency,
  formatAmountInCents,
} from "../../../../../../../utils";
import colors from "../../../../../../../themes/colors";
import { CAR_USAGE_CALCULATION_METHOD_OPTIONS } from "./constants";
import DeclarationStatusChangeModal from "../../../../components/DeclarationStatusChangeModal";
import useTaxYearParam from "../../../../hooks/useTaxYearParam";
import { is1PctMethod } from ".";
import formatDistanceInKm from "../../../../../../../utils/formatDistanceInKm";
import { SUBFORM_STATUSES, SUBFORM_STATUS_MAPPINGS } from "../../constants";

const { Text } = Typography;
const { ListRow, Total } = DeclarationStatusChangeModal;

export interface CarUsageStatusChangeModalProps {
  calculationMethod: EuerDeclarationSubformCalculationMethod;
  inputs:
    | EuerDeclarationCarUsageSubform1PctMethodInputs
    | EuerDeclarationCarUsageSubformDriversLogInputs;
  calculationResults: EuerDeclarationCarUsageSubformCalculationResults;
  status: EuerDeclarationSubformStatus;
  onSave: (status: EuerDeclarationSubformStatus) => Promise<void>;
  onClose: () => void;
}

const CarUsageStatusChangeModal = ({
  calculationMethod,
  inputs,
  calculationResults,
  status,
  onSave,
  onClose,
}: CarUsageStatusChangeModalProps) => {
  const [taxYear] = useTaxYearParam();

  const calculationMethodLabel = CAR_USAGE_CALCULATION_METHOD_OPTIONS.find(
    ({ value }) => value === calculationMethod
  )?.label;
  const isCostCapActive =
    calculationResults.costCap && calculationResults.costCap < 0;
  const is1PctCalculationMethod = is1PctMethod(calculationMethod);

  return (
    <DeclarationStatusChangeModal
      title="PKW-Nutzung"
      year={taxYear}
      subtitle={calculationMethodLabel}
      status={status}
      statusOptions={SUBFORM_STATUSES}
      statusMapping={SUBFORM_STATUS_MAPPINGS}
      onSave={onSave}
      onClose={onClose}
    >
      <ListRow>
        <Col>
          <Text strong>Private KFZ-Nutzung</Text>
        </Col>
      </ListRow>

      {is1PctCalculationMethod && (
        <>
          <ListRow>
            <Col flex="1">Bruttolistenpreis</Col>
            <Col>
              {formatAmountCurrency(
                (inputs as EuerDeclarationCarUsageSubform1PctMethodInputs)
                  .catalogPrice
              )}
            </Col>
          </ListRow>
          <ListRow>
            <Col flex="1">Kaufdatum</Col>
            <Col>
              {moment(
                (inputs as EuerDeclarationCarUsageSubform1PctMethodInputs)
                  .purchaseDate
              ).format("L")}
            </Col>
          </ListRow>
          <ListRow>
            <Col flex="1">Kalendermonate gesamt</Col>
            <Col>
              {
                (inputs as EuerDeclarationCarUsageSubform1PctMethodInputs)
                  .usageMonths
              }
            </Col>
          </ListRow>
        </>
      )}

      {!is1PctCalculationMethod && (
        <>
          <ListRow>
            <Col flex="1">Insgesamt gefahrene km</Col>
            <Col>
              {formatDistanceInKm(
                (inputs as EuerDeclarationCarUsageSubformDriversLogInputs)
                  .totalKm
              )}
            </Col>
          </ListRow>
          <ListRow>
            <Col flex="1">davon Privatfahrten</Col>
            <Col>
              {formatDistanceInKm(
                (inputs as EuerDeclarationCarUsageSubformDriversLogInputs)
                  .privateKm
              )}
            </Col>
          </ListRow>
          <ListRow>
            <Col flex="1">davon Fahrten Whg/Betrieb</Col>
            <Col>
              {formatDistanceInKm(
                (inputs as EuerDeclarationCarUsageSubformDriversLogInputs)
                  .homeCompanyKm
              )}
            </Col>
          </ListRow>
        </>
      )}

      <ListRow>
        <Col flex="1">[106] Private KFZ-Nutzung</Col>
        <Col>{formatAmountInCents(calculationResults.privateCarUsage)}</Col>
      </ListRow>

      <Divider className="m-0" />

      <ListRow>
        <Col>
          <Text strong>Umsatzsteuer</Text>
        </Col>
      </ListRow>
      <ListRow>
        <Col flex="1">[140] Vereinnahmte USt 19 %</Col>
        <Col>
          {is1PctCalculationMethod &&
          (inputs as EuerDeclarationCarUsageSubform1PctMethodInputs)
            .collectedVat != null
            ? formatAmountCurrency(
                (inputs as EuerDeclarationCarUsageSubform1PctMethodInputs)
                  .collectedVat
              )
            : formatAmountInCents(calculationResults.collectedVat, true)}
        </Col>
      </ListRow>
      <Divider className="m-0" />

      <ListRow>
        <Col>
          <Text strong>Arbeitsweg</Text>
        </Col>
      </ListRow>
      <ListRow>
        <Col flex="1">Einfache Entfernung km Whg/Betrieb</Col>
        <Col>
          {formatDistanceInKm(
            (inputs as EuerDeclarationCarUsageSubformDriversLogInputs)
              .commuteDistance
          )}
        </Col>
      </ListRow>
      <ListRow>
        <Col flex="1">Tage Fahrten Whg/Betrieb</Col>
        <Col>
          {
            (inputs as EuerDeclarationCarUsageSubformDriversLogInputs)
              .commuteDays
          }
        </Col>
      </ListRow>
      <ListRow>
        <Col flex="1">[142] Fahrten zw. Whg/Betrieb</Col>
        <Col>
          {formatAmountInCents(calculationResults.homeCompanyTrips, true)}
        </Col>
      </ListRow>
      <ListRow>
        <Col flex="1">[176] Min. abziehbare Fahrtkosten zw. Whg/Betrieb</Col>
        <Col>
          <Text strong style={{ color: colors.darkRed }}>
            {formatAmountInCents(
              -calculationResults.minDeductibleTravelExpenses,
              true
            )}
          </Text>
        </Col>
      </ListRow>

      {is1PctCalculationMethod && (
        <>
          <Divider className="m-0" />

          <ListRow>
            <Col flex="1">
              <Text
                strong
                style={{
                  color: isCostCapActive
                    ? colors.darkLimeGreen
                    : colors.lightGrey,
                }}
              >
                {`Kostendeckelung ${isCostCapActive ? "aktiv" : "inaktiv"}`}
              </Text>
            </Col>
            <Col>
              {calculationResults.costCap && isCostCapActive
                ? formatAmountInCents(
                    Math.abs(calculationResults.costCap),
                    true
                  )
                : "-"}
            </Col>
          </ListRow>
        </>
      )}

      <Divider className="m-0" />

      <ListRow>
        <Col flex="1">
          <Total>
            {calculationResults.profitIncrease > 0
              ? "Gewinnerhöhung"
              : "Gewinnminderung"}
          </Total>
        </Col>
        <Col>
          <Text
            strong
            style={{
              color:
                calculationResults.profitIncrease > 0
                  ? colors.darkRed
                  : colors.darkLimeGreen,
            }}
          >
            {formatAmountInCents(
              Math.abs(calculationResults.profitIncrease),
              true
            )}
          </Text>
        </Col>
      </ListRow>
    </DeclarationStatusChangeModal>
  );
};

export default CarUsageStatusChangeModal;
