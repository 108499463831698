import { BookkeepingSource } from "../../../../../types";
import { useKontaxUserContext } from "../../../../contexts/KontaxUserContext";
import { HorizontalLine } from "../../../Mandanten/Common/UserDetails/styledComponents";
import UserExternalBookkeepingBlock from "../../../../common/UserExternalBookkeepingBlock";

export default function UserExternalBookkeeping() {
  const { user, editUser } = useKontaxUserContext();

  if (!user) return null;

  return (
    <>
      <UserExternalBookkeepingBlock
        bookkeepingSources={user.bookkeepingSources}
        onUpdate={async (bookkeepingSource: BookkeepingSource) =>
          editUser({ bookkeepingSource })
        }
        fibuFinalChecks={user.fibuFinalChecks}
      />
      <HorizontalLine />
    </>
  );
}
