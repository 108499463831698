import sum from "lodash/sum";

import { numberParser } from "../components/common/helpers";
import { roundCents } from "./amount";

const SUMMANDS_REG_EXP = /(\+|-)[0-9.,]+/g;

const startsWithSign = (normalized: string) => !!normalized.match(/^(\+|-)/);

const parseSummand = (normalizedSummand: string): number => {
  // normalizedSummand always have sign at the first position (e.g "+12.034,99", "-123,05")
  const sign = normalizedSummand[0] === "-" ? -1 : 1;
  const value = parseFloat(numberParser(normalizedSummand.substr(1)));
  return sign * value;
};

/**
 * Calculate string with simple math expression that supports only addition and subtraction.
 *
 * Examples:
 * - "2 + 2" => 4
 * - "10 - 2" => 8
 * - "1.000,95 - 212,05 + 10" => 798,9
 * - "-50 + 10" => -40
 */
export const calculateSimpleMathExpression = (
  expression: string = ""
): number => {
  let normalized = expression.replace(/[^0-9.,+-]/g, "");

  if (!startsWithSign(normalized)) {
    normalized = `+${normalized}`; // always start expression with sign to simplify parsing of summands
  }

  const summands = normalized.match(SUMMANDS_REG_EXP) || [];

  const parsedSummands = summands.map(parseSummand);

  const total = sum(parsedSummands);

  return roundCents(total);
};
