import { gql, QueryHookOptions, useQuery } from "@apollo/client";

export interface Municipality {
  name: string;
  taxRate: number;
}
export interface MunicipalitiesQueryResult {
  municipalities: Municipality[];
}

export const GET_MUNICIPALITIES_QUERY = gql`
  query MunicipalitiesResolver {
    municipalities {
      name
      taxRate
    }
  }
`;

export const useMunicipalitiesQuery = (
  options?: QueryHookOptions<MunicipalitiesQueryResult>
) => useQuery<MunicipalitiesQueryResult>(GET_MUNICIPALITIES_QUERY, options);
