import styled from "styled-components";

export const Container = styled.div`
  padding: 20px 40px;
  overflow: scroll;
  height: 100%;
  width: 100%;
`;

export const KeywordsContainer = styled.div`
  margin-bottom: 20px;
`;
