import moment from "moment";
import defaults from "lodash/defaults";
import toPairs from "lodash/toPairs";

import {
  EuerDeclarationOfficeUsageSubformCalculationResults,
  EuerDeclarationOfficeUsageSubformFlatRate5EurInputs,
  EuerDeclarationOfficeUsageSubformFullCostInputs,
  EuerDeclarationOfficeUsageSubformInputs,
  EuerDeclarationSubformCalculationMethod,
  EuerDeclarationSubformInputs,
  HomeOwnershipType,
} from "@kontist/euer-declaration";

import { centsToEuros, eurosToCents } from "../../../../../../../utils";
import { OfficeUsageQuestionnaireValues } from "./types";

export const OFFICE_USAGE_CALCULATION_METHOD_LABELS: {
  [calculationMethod: string]: string;
} = {
  [EuerDeclarationSubformCalculationMethod.OFFICE_USAGE_FULL_COST]:
    "Voller Kostenansatz",
  [EuerDeclarationSubformCalculationMethod.OFFICE_USAGE_UP_TO_1250_EUR]:
    "Ansatz bis zu 1.250 €",
  [EuerDeclarationSubformCalculationMethod.OFFICE_USAGE_FLAT_RATE_5_EUR]:
    "Ansatz Pauschbetrag 5 €",
};

export const OFFICE_USAGE_CALCULATION_METHOD_OPTIONS = toPairs(
  OFFICE_USAGE_CALCULATION_METHOD_LABELS
).map(([calculationMethod, label]) => ({
  value: calculationMethod as EuerDeclarationSubformCalculationMethod,
  label,
}));

export const HOME_OWNERSHIP_TYPE_LABELS: {
  [homeOwnershipType: string]: string;
} = {
  [HomeOwnershipType.RENTAL]: "Miete",
  [HomeOwnershipType.OWN]: "Eigentum",
};

export const HOME_OWNERSHIP_TYPE_OPTIONS = toPairs(
  HOME_OWNERSHIP_TYPE_LABELS
).map(([homeOwnershipType, label]) => ({
  value: homeOwnershipType as HomeOwnershipType,
  label,
}));

export const INITIAL_FULL_COST_INPUTS: EuerDeclarationOfficeUsageSubformFullCostInputs =
  {
    homeOwnershipType: HomeOwnershipType.RENTAL,
    totalHomeArea: 0,
    officeArea: 0,
    monthsUsed: 12,
    monthlyRent: 0,
    usagePeriodOperatingHomeExpenses: 0,
    annualOtherHomeExpenses: 0,
    annualOtherOfficeExpenses: 0,
    paidHomeExpensesInputTax16: 0,
    paidHomeExpensesInputTax19: 0,
    paidOfficeExpensesInputTax16: 0,
    paidOfficeExpensesInputTax19: 0,
  };

export const INITIAL_FLAT_RATE_INPUTS: EuerDeclarationOfficeUsageSubformFlatRate5EurInputs =
  {
    daysUsed: 0,
  };

export const INITIAL_CALCULATION_RESULTS: EuerDeclarationOfficeUsageSubformCalculationResults =
  {
    homeOfficeExpenses: 0, // [172] Abziehbare Aufwendung für häusliches Arbeitszimmer (inkl. AfA und Schuldzinsen)
    paidInputTax: 0, // [185] Gezahlte Vorsteuerbeträge
    profitReduction: 0, // Gewinnminderung
  };

const isFlatRateValues = (
  calculationMethod: EuerDeclarationSubformCalculationMethod,
  values: EuerDeclarationOfficeUsageSubformInputs
): values is EuerDeclarationOfficeUsageSubformFlatRate5EurInputs =>
  calculationMethod ===
  EuerDeclarationSubformCalculationMethod.OFFICE_USAGE_FLAT_RATE_5_EUR;

export const getInputsFromForm = (
  calculationMethod: EuerDeclarationSubformCalculationMethod,
  formFieldValues: EuerDeclarationOfficeUsageSubformInputs
): EuerDeclarationOfficeUsageSubformInputs => {
  if (isFlatRateValues(calculationMethod, formFieldValues)) {
    return {
      daysUsed: formFieldValues.daysUsed,
    };
  } else {
    return {
      homeOwnershipType: formFieldValues.homeOwnershipType as any,
      totalHomeArea: formFieldValues.totalHomeArea,
      officeArea: formFieldValues.officeArea,
      usageStartedAt: formFieldValues.usageStartedAt,
      monthsUsed: formFieldValues.monthsUsed,
      ...(formFieldValues.homeOwnershipType === HomeOwnershipType.RENTAL
        ? {
            monthlyRent: eurosToCents(formFieldValues.monthlyRent),
          }
        : {
            annualHomeDepreciation: eurosToCents(
              formFieldValues.annualHomeDepreciation
            ),
          }),
      usagePeriodOperatingHomeExpenses: eurosToCents(
        formFieldValues.usagePeriodOperatingHomeExpenses
      ),
      annualOtherHomeExpenses: eurosToCents(
        formFieldValues.annualOtherHomeExpenses
      ),
      annualOtherOfficeExpenses: eurosToCents(
        formFieldValues.annualOtherOfficeExpenses
      ),
      paidHomeExpensesInputTax16: eurosToCents(
        formFieldValues.paidHomeExpensesInputTax16
      ),
      paidHomeExpensesInputTax19: eurosToCents(
        formFieldValues.paidHomeExpensesInputTax19
      ),
      paidOfficeExpensesInputTax16: eurosToCents(
        formFieldValues.paidOfficeExpensesInputTax16
      ),
      paidOfficeExpensesInputTax19: eurosToCents(
        formFieldValues.paidOfficeExpensesInputTax19
      ),
    };
  }
};
export function transformInputsToFormFieldsValue(
  calculationMethod: EuerDeclarationSubformCalculationMethod,
  inputs: EuerDeclarationSubformInputs,
  questionnaireValues?: OfficeUsageQuestionnaireValues
) {
  if (
    calculationMethod ===
    EuerDeclarationSubformCalculationMethod.OFFICE_USAGE_FLAT_RATE_5_EUR
  ) {
    const flatRateInputs =
      inputs as EuerDeclarationOfficeUsageSubformFlatRate5EurInputs;
    return defaults(
      {
        daysUsed: flatRateInputs.daysUsed,
      },
      {
        daysUsed: questionnaireValues?.daysUsed,
      },
      INITIAL_FLAT_RATE_INPUTS
    );
  } else {
    const fullCostInputs =
      inputs as EuerDeclarationOfficeUsageSubformFullCostInputs;
    return defaults(
      {
        homeOwnershipType: fullCostInputs.homeOwnershipType,
        totalHomeArea: fullCostInputs.totalHomeArea,
        officeArea: fullCostInputs.officeArea,
        usageStartedAt: fullCostInputs.usageStartedAt
          ? moment(fullCostInputs.usageStartedAt)
          : null,
        monthsUsed: fullCostInputs.monthsUsed,
        ...(fullCostInputs.homeOwnershipType === HomeOwnershipType.RENTAL
          ? {
              monthlyRent: centsToEuros(fullCostInputs.monthlyRent),
            }
          : {
              annualHomeDepreciation: centsToEuros(
                fullCostInputs.annualHomeDepreciation
              ),
            }),
        usagePeriodOperatingHomeExpenses: centsToEuros(
          fullCostInputs.usagePeriodOperatingHomeExpenses
        ),
        annualOtherHomeExpenses: centsToEuros(
          fullCostInputs.annualOtherHomeExpenses
        ),
        annualOtherOfficeExpenses: centsToEuros(
          fullCostInputs.annualOtherOfficeExpenses
        ),
        paidHomeExpensesInputTax16: centsToEuros(
          fullCostInputs.paidHomeExpensesInputTax16
        ),
        paidHomeExpensesInputTax19: centsToEuros(
          fullCostInputs.paidHomeExpensesInputTax19
        ),
        paidOfficeExpensesInputTax16: centsToEuros(
          fullCostInputs.paidOfficeExpensesInputTax16
        ),
        paidOfficeExpensesInputTax19: centsToEuros(
          fullCostInputs.paidOfficeExpensesInputTax19
        ),
      },
      {
        homeOwnershipType: questionnaireValues?.homeOwnershipType,
        totalHomeArea: questionnaireValues?.totalHomeArea,
        officeArea: questionnaireValues?.officeArea,
        monthsUsed: questionnaireValues?.monthsUsed,
      },
      INITIAL_FULL_COST_INPUTS
    );
  }
}
