import { Location } from "history";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Layout, Menu } from "antd";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";

import colors from "../../../themes/colors";

import { HeadSider } from "./HeadSider";
import LeftNavLink from "./LeftNavItem";
import {
  NavigationItem,
  navigationItems,
  NavigationSection,
} from "./navigationItems";
import { getPermissionScope } from "../../../gapi";

const { Sider } = Layout;
const { SubMenu, Item } = Menu;

const styleMenuItem: React.CSSProperties = {
  paddingLeft: "40px",
  margin: "0px",
};

const styleSider = (collapsed: boolean): React.CSSProperties => {
  return {
    overflowX: "hidden",
    overflowY: collapsed ? "hidden" : "scroll",
    height: "100%",
    borderRight: `1px solid ${colors.lightestGray}`,
    backgroundColor: colors.lightestEverGray,
  };
};

const hasAccessToNavigationItem = (child: NavigationItem) => {
  const hasRequiredScope =
    !child.requiredScope || child.requiredScope.includes(getPermissionScope());

  const hasFeatureFlag =
    !child.featureFlag || localStorage.getItem(child.featureFlag);

  return hasRequiredScope && hasFeatureFlag;
};

const renderNavigationSection =
  (texts: { label: (name: string) => string }) =>
  (navigationSection: NavigationSection) =>
    (
      <SubMenu
        key={navigationSection.label}
        title={texts.label(navigationSection.label) || navigationSection.label}
        icon={navigationSection.icon && <navigationSection.icon />}
      >
        {navigationSection.children
          .filter(hasAccessToNavigationItem)
          .map((child: NavigationItem, index: React.Key) => {
            return (
              <Item
                data-test={`${child.label.replace(/\s/g, "")}-link`}
                key={`${navigationSection.label}-${index}`}
                style={styleMenuItem}
              >
                {"component" in child ? (
                  <child.component />
                ) : (
                  <LeftNavLink item={child} />
                )}
              </Item>
            );
          })}
      </SubMenu>
    );

/**
 * Based on the current location it checks if the current path corresponds
 * to a child of a section, if found it returns its key and key of the section.
 */
const findActiveKeys = (location: Location) => {
  let activeKey = "";
  let openKey = navigationItems[1].label;
  navigationItems.forEach((section) => {
    if ("children" in section) {
      section.children.forEach((child: NavigationItem, index: number) => {
        if (
          "path" in child &&
          location.pathname.startsWith(child.path as string)
        ) {
          activeKey = `${section.label}-${index}`;
          openKey = section.label;
        }
      });
    }
  });

  return { activeKey, openKey };
};

const getTexts = (t: TFunction<"Navigation", undefined>) => ({
  label: (labelName: string) => t(`navigation.${labelName}`, ""),
});

const LeftNavigation = () => {
  const location = useLocation();
  const keys = findActiveKeys(location);

  const { t } = useTranslation("Navigation");
  const texts = getTexts(t);

  const [collapsed, setCollapsed] = useState(false);

  const onCollapse = () => {
    setCollapsed(!collapsed);
  };

  return (
    <Sider
      trigger={null}
      collapsible
      collapsed={collapsed}
      collapsedWidth={56}
      style={styleSider(collapsed)}
    >
      <HeadSider collapsed={collapsed} onCollapse={onCollapse} />
      <Menu
        mode="inline"
        selectedKeys={[keys.activeKey]}
        defaultOpenKeys={navigationItems.map(({ label }) => label)}
        inlineIndent={16}
      >
        {navigationItems
          .filter((navigationSection) =>
            navigationSection.children.some(hasAccessToNavigationItem)
          )
          .map(renderNavigationSection(texts))}
      </Menu>
    </Sider>
  );
};

export default LeftNavigation;
