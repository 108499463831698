import React, { useState, useEffect } from "react";
import { Button, Space, Select } from "antd";

import {
  AnschreibenType,
  SupportedLanguage,
} from "../../../../api/graphql/schema.generated";
import ActionLogDrawerAsync from "../../../common/ActionLogDrawerAsync";
import { SearchTerms } from "../types";
import { FREELANCER, TRADER, SAVE } from "../texts";
import { CardWrapper, CardContainer, Middle } from "./styledComponents";
import { years } from "./data";

const { Option } = Select;

const AnschreibenTemplateTopBar = ({
  onSearch,
  onSave,
  disabled = false,
  templateId,
}: {
  onSearch: (params: SearchTerms) => void;
  onSave: () => void;
  disabled?: boolean;
  templateId?: string;
}) => {
  const [year, setYear] = useState<number>(2020);
  const [type, setType] = useState<AnschreibenType>(AnschreibenType.FREELANCER);
  const [language, setLanguage] = useState<SupportedLanguage>(
    SupportedLanguage.EN
  );

  useEffect(() => {
    if (year && type && language) {
      onSearch({ year, type, language });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [year, type, language]);

  return (
    <CardWrapper>
      <CardContainer>
        <Space>
          <Select value={year} onChange={setYear} disabled={disabled}>
            {years.map((y) => (
              <Option key={y} value={y}>
                {y}
              </Option>
            ))}
          </Select>

          <Select value={type} onChange={setType} disabled={disabled}>
            <Option value={AnschreibenType.FREELANCER}>{FREELANCER}</Option>
            <Option value={AnschreibenType.TRADER}>{TRADER}</Option>
          </Select>

          <Select value={language} onChange={setLanguage} disabled={disabled}>
            <Option value={SupportedLanguage.EN}>Englisch</Option>
            <Option value={SupportedLanguage.DE}>Deutsch</Option>
          </Select>

          {templateId && (
            <ActionLogDrawerAsync
              title="Action log"
              modelName="anschreiben_template"
              recordIds={[templateId]}
            />
          )}
        </Space>
        <Middle />
        <Button type="primary" onClick={onSave} disabled={disabled}>
          {SAVE}
        </Button>
      </CardContainer>
    </CardWrapper>
  );
};

export default AnschreibenTemplateTopBar;
