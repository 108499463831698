export enum UstvaPeriod {
  JANUARY = "01",
  FEBRUARY = "02",
  MARCH = "03",
  APRIL = "04",
  MAY = "05",
  JUNE = "06",
  JULY = "07",
  AUGUST = "08",
  SEPTEMBER = "09",
  OCTOBER = "10",
  NOVEMBER = "11",
  DECEMBER = "12",

  FIRST_QUARTER = "Q1",
  SECOND_QUARTER = "Q2",
  THIRD_QUARTER = "Q3",
  FOURTH_QUARTER = "Q4",
}
