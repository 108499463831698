import { Typography, Checkbox } from "antd";

import { Wrapper } from "./styledComponents";

const { Title } = Typography;

const Checkboxes = ({
  title,
  allOptions,
  selectedOptions,
  selectOption,
}: {
  title: string;
  allOptions: string[];
  selectedOptions: string[];
  selectOption: (options: string[]) => void;
}) => {
  const onSelect = (option: string) => () => {
    if (selectedOptions.includes(option)) {
      selectOption(selectedOptions.filter((o) => o !== option));
      return;
    }

    selectOption([...selectedOptions, option]);
  };

  return (
    <Wrapper>
      <Title level={5}>{title}</Title>
      {allOptions.map((option) => (
        <div key={option}>
          <Checkbox
            checked={selectedOptions.includes(option)}
            onChange={onSelect(option)}
          >
            {option}
          </Checkbox>
          <br />
        </div>
      ))}
    </Wrapper>
  );
};

export default Checkboxes;
