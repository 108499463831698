import type * as Types from "../../schema.generated";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type SaveEuerDraftPdfMutationVariables = Types.Exact<{
  payload: Types.EuerProcessingRequest;
}>;

export type SaveEuerDraftPdfMutation = {
  __typename?: "Mutation";
  saveEuerDraftPdf: {
    __typename?: "EuerProcessingResponse";
    processResult: string;
    pdf?: string | null;
    message: string;
  };
};

export const SaveEuerDraftPdfDocument = gql`
  mutation saveEuerDraftPdf($payload: EuerProcessingRequest!) {
    saveEuerDraftPdf(payload: $payload) {
      processResult
      pdf
      message
    }
  }
`;
export type SaveEuerDraftPdfMutationFn = Apollo.MutationFunction<
  SaveEuerDraftPdfMutation,
  SaveEuerDraftPdfMutationVariables
>;

/**
 * __useSaveEuerDraftPdfMutation__
 *
 * To run a mutation, you first call `useSaveEuerDraftPdfMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveEuerDraftPdfMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveEuerDraftPdfMutation, { data, loading, error }] = useSaveEuerDraftPdfMutation({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useSaveEuerDraftPdfMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveEuerDraftPdfMutation,
    SaveEuerDraftPdfMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SaveEuerDraftPdfMutation,
    SaveEuerDraftPdfMutationVariables
  >(SaveEuerDraftPdfDocument, options);
}
export type SaveEuerDraftPdfMutationHookResult = ReturnType<
  typeof useSaveEuerDraftPdfMutation
>;
export type SaveEuerDraftPdfMutationResult =
  Apollo.MutationResult<SaveEuerDraftPdfMutation>;
export type SaveEuerDraftPdfMutationOptions = Apollo.BaseMutationOptions<
  SaveEuerDraftPdfMutation,
  SaveEuerDraftPdfMutationVariables
>;
