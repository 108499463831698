import React, { useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { isEmpty } from "lodash";
import {
  FileTextOutlined,
  CopyOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import {
  Col,
  List,
  Row,
  message,
  Button,
  Typography,
  notification,
} from "antd";

import { QuestionnaireDocumentFragment } from "../../../../../../../api/graphql/fragments/questionnaireDocument.generated";
import { formatDate } from "../../../../utils";
import {
  QUESTIONNAIRE_DOCUMENT_INPUTS_CONFIG,
  QuestionnaireDocumentInputType,
  QUESTIONNAIRE_DOCUMENT_TYPE,
} from "./constants";
import LocalAssetsViewer from "../../../../../../common/LocalAssetsViewer";
import {
  AssetContainer,
  CopyButton,
  DocumentsTable,
  InputsList,
} from "../styledComponents";
import {
  currencyFormatter,
  formatAmountToCurrency,
} from "../../../../../../../utils";
import {
  HOME_OFFICE_EXPENSE_PERIOD_LABELS,
  HOME_OFFICE_VAT_RATE_LABELS,
} from "../../../EuerDeclaration/pages/OfficeUsage/OfficeUsageView/constants";
import { base64toBlob } from "../../../../../../common/helpers";
import { useGetQuestionnaireDocumentAssetsArchiveLazyQuery } from "../../../../../../../api/graphql/queries/questionnaireDocument/getQuestionnaireDocumentAssetsArchive.generated";
import useTaxYearParam from "../../../../hooks/useTaxYearParam";
import useEmailParam from "../../../../../../hooks/useEmailParam";
import { UrlParams } from ".";
import { useUserContext } from "../../../../contexts/UserContext";

const { Text } = Typography;

type QuestionnaireDocumentType = QuestionnaireDocumentFragment & {
  count: number;
  index: number;
};

type DocumentInputDisplayType = { label: string; value: string };

const formatDocumentInputValue = (
  value: string | number | boolean,
  type: QuestionnaireDocumentInputType,
  isAdjustByOfficeAreaShareInput: boolean = false
): string => {
  switch (type) {
    case QuestionnaireDocumentInputType.STRING:
      return value as string;
    case QuestionnaireDocumentInputType.NUMBER:
      return value.toString();
    case QuestionnaireDocumentInputType.CURRENCY:
      return formatAmountToCurrency(currencyFormatter.format(value as number));
    case QuestionnaireDocumentInputType.DATE:
      return formatDate(value as string) || "";
    case QuestionnaireDocumentInputType.BOOLEAN:
      if (isAdjustByOfficeAreaShareInput) {
        return value ? "Arbeitszimmer" : "Wohnung";
      }

      return value ? "Ja" : "Nien";
    case QuestionnaireDocumentInputType.HOME_OFFICE_EXPENSE_PERIOD:
      return HOME_OFFICE_EXPENSE_PERIOD_LABELS[value as string];
    case QuestionnaireDocumentInputType.HOME_OFFICE_VAT_RATE:
      return HOME_OFFICE_VAT_RATE_LABELS[value as string];
    default:
      return "";
  }
};

const copyToClipboard = (value: string) => {
  navigator.clipboard.writeText(value);
  message.success("the value was copied to clipboard.");
};

const COMMENT_LABEL = QUESTIONNAIRE_DOCUMENT_INPUTS_CONFIG["comment"].label;
const OFFICE_AREA_SHARE_KEY = "adjustByOfficeAreaShare";

export const QuestionnaireDocumentsTable = ({
  questionnaireTitle,
  isLoadingQuestionnaire,
  documents,
  syncedAt,
}: {
  questionnaireTitle: string;
  isLoadingQuestionnaire: boolean;
  documents: QuestionnaireDocumentType[];
  syncedAt?: string;
}) => {
  const [taxYear] = useTaxYearParam();
  const [email] = useEmailParam();
  const user = useUserContext();
  const { type } = useParams<UrlParams>();

  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const columns = useMemo(
    () => [
      {
        title: "Art",
        key: "type",
        width: "70%",
        render: (record: QuestionnaireDocumentType) =>
          `${record.count > 1 ? record.index + "." : ""} ${
            QUESTIONNAIRE_DOCUMENT_TYPE[record.type]
          }`,
      },
      {
        title: "Upload",
        key: "Upload",
        width: "15%",
        render: (record: QuestionnaireDocumentType) =>
          record.assets.length ? <FileTextOutlined /> : "",
      },
      {
        title: "Bearbeitet",
        key: "updatedAt",
        dataIndex: "updatedAt",
        width: "15%",
        render: (updatedAt: string) => formatDate(updatedAt),
      },
      {
        title: "Daten übernommen",
        key: "syncedAt",
        render: () => (syncedAt ? formatDate(syncedAt) : "-"),
      },
    ],
    [syncedAt]
  );

  const getDocumentInputs = useCallback(
    (
      inputs: Record<string, string | number | boolean>
    ): DocumentInputDisplayType[] => {
      if (isEmpty(inputs)) {
        return [];
      }
      const inputsArray = Object.keys(inputs).map((key) => ({
        label: QUESTIONNAIRE_DOCUMENT_INPUTS_CONFIG[key].label,
        value: formatDocumentInputValue(
          inputs[key],
          QUESTIONNAIRE_DOCUMENT_INPUTS_CONFIG[key].type,
          key === OFFICE_AREA_SHARE_KEY
        ),
      }));

      // find the comment in the inputs and then moved it to the last position
      inputsArray.push(
        inputsArray.splice(
          inputsArray.findIndex(({ label }) => label === COMMENT_LABEL),
          1
        )[0]
      );
      return inputsArray;
    },
    []
  );

  const [getAssetsArchive] = useGetQuestionnaireDocumentAssetsArchiveLazyQuery({
    variables: {
      email: email!,
      type: type!,
      year: taxYear,
    },
    onCompleted: (data) => {
      const assetsArchive = data?.questionnaire?.assetsArchive;
      if (assetsArchive) {
        const blob = base64toBlob(assetsArchive);
        saveAs(
          blob,
          `Belege ${questionnaireTitle}, ${user?.lastName}, ${user?.firstName}, ${taxYear}.zip`
        );
      }
      setIsButtonLoading(false);
    },
    onError: (err: Error) => {
      notification.error({ message: `An error occurred: ${err.message}` });
      setIsButtonLoading(false);
    },
  });

  const isButtonEnable = useMemo(
    () => !!documents.flatMap((document) => document.assets).length,
    [documents]
  );

  const handleDownloadAll = () => {
    setIsButtonLoading(true);
    getAssetsArchive();
  };

  return (
    <>
      <Row
        align="middle"
        justify="space-between"
        className="mb-3"
        style={{ flexDirection: "row-reverse" }}
      >
        <Col>
          <Button
            icon={<DownloadOutlined />}
            size="large"
            style={{ display: "flex", alignItems: "center" }}
            onClick={handleDownloadAll}
            loading={isButtonLoading}
            disabled={!isButtonEnable}
          >
            Download aller Belege
          </Button>
        </Col>
      </Row>
      <Row>
        <DocumentsTable
          size="large"
          columns={columns}
          dataSource={documents}
          pagination={false}
          loading={isLoadingQuestionnaire}
          rowKey={(row: QuestionnaireDocumentType) => row.id}
          scroll={{ x: "100%" }}
          expandable={{
            expandedRowRender: (record: QuestionnaireDocumentType) => {
              return (
                <Row>
                  <Col span={14}>
                    <AssetContainer>
                      <LocalAssetsViewer
                        assets={record.assets}
                        minHeight={600}
                      />
                    </AssetContainer>
                  </Col>
                  <Col span={10}>
                    <InputsList
                      header={<Text strong>Angaben Mandant</Text>}
                      itemLayout="horizontal"
                      style={{ marginLeft: "24px" }}
                      dataSource={getDocumentInputs(record.inputs)}
                      renderItem={(item) => (
                        <List.Item>
                          {item.label !== COMMENT_LABEL ? (
                            <>
                              <Col span={15}>
                                <Text>{item.label}</Text>
                              </Col>
                              <Col span={5}>
                                <Text>{item.value}</Text>
                              </Col>
                              <Col span={2}>
                                <CopyButton
                                  onClick={() => copyToClipboard(item.value)}
                                  type="link"
                                  icon={<CopyOutlined />}
                                />
                              </Col>
                            </>
                          ) : (
                            <Col>
                              <Row>
                                <Text strong>{item.label}</Text>
                              </Row>
                              <Row>
                                <Text>{item.value}</Text>
                              </Row>
                            </Col>
                          )}
                        </List.Item>
                      )}
                    />
                  </Col>
                </Row>
              );
            },
            rowExpandable: (record: QuestionnaireDocumentType) =>
              !!record.assets.length,
          }}
        />
      </Row>
    </>
  );
};
