import { gql, QueryHookOptions, useQuery } from "@apollo/client";

import { VatAdvanceDeclarationPeriods } from "../../../../types";

export interface AvailableVatAdvanceDeclarationPeriodsQueryVariables {
  email: string;
  year: number;
}

export interface AvailableVatAdvanceDeclarationPeriodsQueryResult {
  availableVatAdvanceDeclarationPeriods: VatAdvanceDeclarationPeriods;
}

export const AVAILABLE_VAT_ADVANCED_DECLARATION_PERIODS_QUERY = gql`
  query availableVatAdvanceDeclarationPeriods($email: String!, $year: Int!) {
    availableVatAdvanceDeclarationPeriods(email: $email, year: $year)
  }
`;

export const useAvailableVatAdvanceDeclarationPeriodsQuery = (
  options?: QueryHookOptions<
    AvailableVatAdvanceDeclarationPeriodsQueryResult,
    AvailableVatAdvanceDeclarationPeriodsQueryVariables
  >
) =>
  useQuery<
    AvailableVatAdvanceDeclarationPeriodsQueryResult,
    AvailableVatAdvanceDeclarationPeriodsQueryVariables
  >(AVAILABLE_VAT_ADVANCED_DECLARATION_PERIODS_QUERY, options);
