import React, { useMemo, useState } from "react";
import { Button, Row, Col, Table } from "antd";
import { PlusOutlined, EditOutlined } from "@ant-design/icons";

import {
  calculateGrossAmount,
  calculateNetAmount,
  calculateVatAmount,
} from "@kontist/euer-declaration";

import moment from "moment";

import { formatAmountInCents } from "../../../../../../../../utils";

import {
  HomeOfficeExpenseType,
  HomeOfficeExpensePeriod,
  HomeOfficeExpense,
} from "../../../../../../../../api/graphql/schema.generated";
import LocalAssetsViewer from "../../../../../../../common/LocalAssetsViewer";
import ActionLogDrawerAsync from "../../../../../../../common/ActionLogDrawerAsync";
import {
  HomeOfficeExpenseFormInputs,
  UpsertHomeOfficeExpenseModal,
} from "./UpsertHomeOfficeExpenseModal";
import { AssetViewerContainer } from "./styledComponents";
import {
  HOME_OFFICE_EXPENSE_PERIOD_LABELS,
  HOME_OFFICE_EXPENSE_TYPE_LABELS,
} from "./constants";
import { HomeOfficeExpenseFragment } from "../../../../../../../../api/graphql/fragments/homeOfficeExpense.generated";

interface Props {
  taxYear: number;
  email?: string | null;
  officeAreaShare: number;
  homeOfficeExpenses: HomeOfficeExpense[];
  refetchHomeOfficeExpense: () => void;
}

const ExpensesTable = ({
  taxYear,
  email,
  officeAreaShare,
  homeOfficeExpenses,
  refetchHomeOfficeExpense,
}: Props) => {
  const [
    showUpsertHomeOfficeExpenseModal,
    setShowUpsertHomeOfficeExpenseModal,
  ] = useState<boolean>(false);
  const [
    homeOfficeExpenseFormInitialValues,
    setHomeOfficeExpenseFormInitialValues,
  ] = useState<HomeOfficeExpenseFormInputs | undefined>(undefined);

  const columns = useMemo(
    () => [
      {
        title: "Art",
        key: "type",
        dataIndex: "type",
        sorter: (a: HomeOfficeExpenseFragment, b: HomeOfficeExpenseFragment) =>
          a.type.localeCompare(b.type),
        render: (type: HomeOfficeExpenseType) =>
          HOME_OFFICE_EXPENSE_TYPE_LABELS[type],
      },
      {
        title: "Abrechnungszeitraum des Beleg",
        key: "period",
        dataIndex: "period",
        sorter: (a: HomeOfficeExpenseFragment, b: HomeOfficeExpenseFragment) =>
          a.period.localeCompare(b.period),
        render: (period: HomeOfficeExpensePeriod) =>
          HOME_OFFICE_EXPENSE_PERIOD_LABELS[period],
      },
      {
        title: "Betrag brutto laut Beleg",
        key: "amount",
        dataIndex: "amount",
        render: (receiptGrossAmount: number) => {
          return formatAmountInCents(receiptGrossAmount, true);
        },
        sorter: (a: HomeOfficeExpenseFragment, b: HomeOfficeExpenseFragment) =>
          a.amount - b.amount,
      },
      {
        title: "Nutzungszeit Arbeitszimmer",
        key: "monthsUsed",
        dataIndex: "monthsUsed",
        sorter: (a: HomeOfficeExpenseFragment, b: HomeOfficeExpenseFragment) =>
          (a.monthsUsed || 0) - (b.monthsUsed || 0),
      },
      {
        title: "Anteilig auf m² des Arbeitszimmers",
        key: "adjustByOfficeAreaShare",
        dataIndex: "adjustByOfficeAreaShare",
        render: (adjustByOfficeAreaShare: boolean) => {
          return adjustByOfficeAreaShare ? "Ja" : "Nein";
        },
        sorter: (a: HomeOfficeExpenseFragment, b: HomeOfficeExpenseFragment) =>
          (a.adjustByOfficeAreaShare ? 1 : 0) -
          (b.adjustByOfficeAreaShare ? 1 : 0),
      },
      {
        title: "Umsatzsteuerrate",
        key: "vatRate",
        dataIndex: "vatRate",
        render: (vatRate: number) => {
          return `${vatRate} %`;
        },
        sorter: (a: HomeOfficeExpenseFragment, b: HomeOfficeExpenseFragment) =>
          parseInt(a.vatRate) - parseInt(b.vatRate),
      },
      {
        title: "Beschreibung",
        key: "note",
        dataIndex: "note",
        sorter: (a: HomeOfficeExpenseFragment, b: HomeOfficeExpenseFragment) =>
          a.note.localeCompare(b.note),
      },
      {
        title: "Betrag brutto",
        key: "grossAmount",
        render: (record: HomeOfficeExpenseFragment) => {
          return formatAmountInCents(
            calculateGrossAmount({
              amount: record.amount,
              period: record.period,
              monthsUsed: record.monthsUsed!,
              officeAreaShare,
              adjustByOfficeAreaShare: record.adjustByOfficeAreaShare,
            }),
            true
          );
        },
      },
      {
        title: "Betrag netto",
        key: "netAmount",
        render: (record: HomeOfficeExpenseFragment) => {
          return formatAmountInCents(
            calculateNetAmount({
              amount: record.amount,
              period: record.period,
              monthsUsed: record.monthsUsed!,
              vatRate: parseInt(record.vatRate),
              officeAreaShare,
              adjustByOfficeAreaShare: record.adjustByOfficeAreaShare,
            }),
            true
          );
        },
      },
      {
        title: "Betrag Umsatzsteuer",
        key: "vatAmount",
        render: (record: HomeOfficeExpenseFragment) => {
          return formatAmountInCents(
            calculateVatAmount({
              amount: record.amount,
              period: record.period,
              monthsUsed: record.monthsUsed!,
              vatRate: parseInt(record.vatRate),
              officeAreaShare,
              adjustByOfficeAreaShare: record.adjustByOfficeAreaShare,
            }),
            true
          );
        },
      },
      {
        title: "Daten übernommen",
        key: "syncedFromQuestionnaireAt",
        render: (record: HomeOfficeExpenseFragment) => {
          return record.syncedFromQuestionnaireAt
            ? moment(record.syncedFromQuestionnaireAt).format("DD.MM.YYYY")
            : "-";
        },
      },
    ],
    [officeAreaShare]
  );

  const actionLogRecordIds = useMemo(
    () => homeOfficeExpenses?.map(({ id }) => id) || [],
    [homeOfficeExpenses]
  );

  const actionLogSubTexts = useMemo(
    () =>
      Object.fromEntries(
        (homeOfficeExpenses || []).map(({ id }) => [id, `ID: ${id}`])
      ),
    [homeOfficeExpenses]
  );

  const handleCreate = () => {
    setHomeOfficeExpenseFormInitialValues(undefined);
    setShowUpsertHomeOfficeExpenseModal(true);
  };

  const handleEdit = (homeOfficeExpense: HomeOfficeExpenseFragment) => {
    setHomeOfficeExpenseFormInitialValues({ ...homeOfficeExpense });
    setShowUpsertHomeOfficeExpenseModal(true);
  };

  return (
    <>
      <Row align="middle" justify="space-between" className="mb-3">
        <Col>
          <ActionLogDrawerAsync
            title="Action log"
            modelName="home_office_expense"
            recordIds={actionLogRecordIds}
            subTexts={actionLogSubTexts}
            small
          />
        </Col>
        <Col>
          <Button
            icon={<PlusOutlined />}
            size="large"
            style={{ display: "flex", alignItems: "center" }}
            onClick={handleCreate}
          >
            Beleg hinzufügen
          </Button>
        </Col>
      </Row>
      <Row>
        <Table
          size="large"
          columns={columns}
          dataSource={homeOfficeExpenses}
          pagination={false}
          rowKey={(row: HomeOfficeExpenseFragment) => row.id}
          scroll={{ x: "100%" }}
          expandable={{
            expandedRowRender: (record: HomeOfficeExpenseFragment) => {
              return (
                <Col>
                  <Row style={{ paddingBottom: "16px" }}>
                    <Button
                      icon={<EditOutlined />}
                      size="large"
                      style={{ display: "flex", alignItems: "center" }}
                      onClick={() => handleEdit(record)}
                    >
                      Beleg ändern
                    </Button>
                  </Row>
                  <Row>
                    <AssetViewerContainer>
                      <LocalAssetsViewer
                        assets={record.assets}
                        minHeight={600}
                      />
                    </AssetViewerContainer>
                  </Row>
                </Col>
              );
            },
          }}
        />
      </Row>
      <UpsertHomeOfficeExpenseModal
        email={email!}
        taxYear={taxYear}
        visible={showUpsertHomeOfficeExpenseModal}
        onSuccess={refetchHomeOfficeExpense}
        onClose={() => {
          setShowUpsertHomeOfficeExpenseModal(false);
        }}
        officeAreaShare={officeAreaShare}
        initialValues={homeOfficeExpenseFormInitialValues}
      />
    </>
  );
};

export default ExpensesTable;
