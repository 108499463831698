import { PlusOutlined } from "@ant-design/icons";
import { Button, notification } from "antd";
import moment from "moment-timezone";
import React, { useState } from "react";
import styled from "styled-components";

import { EmailDocument } from "../../../api/graphql";
import {
  ExternalTransactionInitialValues,
  ExternalTransactionPopup,
} from "../VerificationView/TransactionList/ExternalTransactionPopup";
import { getAsset } from "./EmailDocumentPreview";

const Wrapper = styled.div`
  padding: 24px;
  display: flex;
  border: 1px solid #f0f0f0;
  justify-content: space-between;
  align-items: center;

  > div {
    flex-direction: column;
  }
`;

const Title = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  rgba(0, 0, 0, 0.85);
  margin-bottom: 4px;
`;

const Subtitle = styled.p`
  color: rgba(0, 0, 0, 0.45);
  padding-right: 20px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  margin: 0;
`;

const getPrefilledData = (
  emailDocument: EmailDocument
): ExternalTransactionInitialValues => ({
  amount: emailDocument.amount,
  name: emailDocument.name,
  description: emailDocument.documentNumber,
  iban: emailDocument.iban,
  valutaDate: moment(emailDocument.date).isValid()
    ? emailDocument.date
    : new Date(),
  assets: [getAsset(emailDocument)],
});

const NoMatchSection = ({
  email,
  emailDocument,
  onCreateExternalTransaction,
}: {
  email: string;
  emailDocument: EmailDocument;
  onCreateExternalTransaction: () => void;
}) => {
  const [showExternalTransactionPopup, setShowExternalTransactionPopup] =
    useState<boolean>(false);

  return (
    <>
      <Wrapper>
        <div>
          <Title>Can’t find a match?</Title>
          <Subtitle>
            There is no Kontist transaction for this Beleg?
            <br />
            If this is an old Beleg before Kontist times, you can create a
            virtual transaction for it
          </Subtitle>
        </div>
        <Button
          icon={<PlusOutlined />}
          onClick={() => setShowExternalTransactionPopup(true)}
        >
          Create
        </Button>
      </Wrapper>
      <ExternalTransactionPopup
        hideAssetActions
        email={email}
        isPopupShown={showExternalTransactionPopup}
        onSuccess={(_: unknown, isDuplicate: boolean) => {
          if (!isDuplicate) {
            setTimeout(onCreateExternalTransaction, 2000);
            notification.success({
              message:
                "Created external transaction with linked email document",
            });
          } else {
            notification.success({
              message: "Created external transaction",
            });
          }
        }}
        closePopup={() => {
          setShowExternalTransactionPopup(false);
        }}
        initialValues={getPrefilledData(emailDocument)}
        emailDocumentId={emailDocument.id}
      />
    </>
  );
};

export default NoMatchSection;
