import { gql, MutationHookOptions, useMutation } from "@apollo/client";

import { TaxNumber } from "../../types";
import { TaxNumberType } from "../../../../components/pages/Mandanten/Common/UserDetails/TaxNumberList/types";

export interface CreateTaxNumberInput {
  type: TaxNumberType;
  taxNumber: string;
  validFrom?: Date;
  description: string;
  isMainBusinessTaxNumber: boolean;
}

export interface CreateTaxNumberMutationVariables {
  email: string;
  payload: CreateTaxNumberInput;
}

export interface CreateTaxNumberMutationResult {
  createTaxNumber: TaxNumber;
}

export const CREATE_TAX_NUMBERS_MUTATION = gql`
  mutation createUserTaxNumber(
    $email: String!
    $payload: CreateTaxNumberInput!
  ) {
    createTaxNumber(email: $email, payload: $payload) {
      id
      type
      taxNumber
      validFrom
      description
      isMainBusinessTaxNumber
    }
  }
`;

export const useCreateTaxNumberMutation = (
  options?: MutationHookOptions<
    CreateTaxNumberMutationResult,
    CreateTaxNumberMutationVariables
  >
) =>
  useMutation<CreateTaxNumberMutationResult, CreateTaxNumberMutationVariables>(
    CREATE_TAX_NUMBERS_MUTATION,
    options
  );
