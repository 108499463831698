import { Tag } from "antd";
import styled from "styled-components";

import colors from "../../../../themes/colors";

export const AntGrayTag = styled(Tag)`
  color: ${colors.darkGrey};
  border-color: ${colors.darkGrey};
`;

export const SavedDraftInfoHint = styled.div`
  margin-top: 25px;
  color: ${colors.darkGrey};
`;
