import {
  GreyInfoIcon,
  InfoMessageContainer,
  YellowInfoIcon,
} from "./styledComponents";

const DeclarationInfoMessage = ({
  hasExternalAssets,
  shouldBlockPdfRecreationByStatus,
}: {
  hasExternalAssets: boolean;
  shouldBlockPdfRecreationByStatus?: boolean | null;
}) => {
  if (!hasExternalAssets && !shouldBlockPdfRecreationByStatus) {
    return null;
  }

  if (shouldBlockPdfRecreationByStatus) {
    return (
      <InfoMessageContainer>
        <GreyInfoIcon />
        <p>
          Das Erstellen von neuen Freigabe-Formularen ist bei dem ausgewählten
          Status aus Sicherheitsgründen deaktiviert.
        </p>
      </InfoMessageContainer>
    );
  }

  return (
    <InfoMessageContainer>
      <YellowInfoIcon />
      Das erstellen eines Freigabe-Formular oder das finale Einreichen ist hier
      gegebenenfalls nicht möglich, da ein externes Dokument hinterlegt ist
    </InfoMessageContainer>
  );
};

export default DeclarationInfoMessage;
