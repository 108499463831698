import React, { useMemo } from "react";
import { Table } from "antd";
import { SizeType } from "antd/lib/config-provider/SizeContext";

import colors from "../../../../../themes/colors";
import FieldAmount from "./FieldAmount";
import {
  VatAnnualDeclarationField,
  VatAnnualDeclarationFieldType,
} from "../../../../../api/graphql";
import {
  COLUMN_ALIGN_RIGHT,
  VAT_ANNUAL_DECLARATION_FIELD_TYPE_CODES,
} from "./constants";
import { useUserContext } from "../../contexts/UserContext";
import {
  VatExemptionWithItd,
  VatExemptionWithoutItd,
} from "../../../../../types";
import { VatAnnualDeclarationPeriodType } from "../../types";
import VatAnnualDeclarationFieldTypeLabel from "./VatAnnualDeclarationFieldTypeLabel";

export interface VatAnnualDeclarationFieldRow
  extends VatAnnualDeclarationField {
  email: string;
  taxYear: number;
  period: VatAnnualDeclarationPeriodType;
  yearSuffix?: number;
  vatExemptionWithItd: VatExemptionWithItd | null;
  vatExemptionWithoutItd: VatExemptionWithoutItd | null;
}

export const CODE_COLUMN_WIDTH = 90;

const VAT_ANNUAL_DECLARATION_FIELDS_TABLE_COLUMNS = [
  {
    key: "code",
    title: "Kennzahl",
    dataIndex: "type",
    width: CODE_COLUMN_WIDTH,
    render: (type: VatAnnualDeclarationFieldType) =>
      VAT_ANNUAL_DECLARATION_FIELD_TYPE_CODES[type] || "",
  },
  {
    key: "type",
    title: "Steuerpflichtige Umsätze",
    render: (row: VatAnnualDeclarationFieldRow) => (
      <VatAnnualDeclarationFieldTypeLabel row={row} />
    ),
  },
  {
    title: "Bemessungsgrundlage",
    dataIndex: "netAmount",
    width: "15%",
    align: COLUMN_ALIGN_RIGHT,
    onCell: () => ({ style: { backgroundColor: colors.lightPurpleOpacity } }),
    render: (netAmount: number | null, row: VatAnnualDeclarationFieldRow) => (
      <FieldAmount type={row.type} amount={netAmount} />
    ),
  },
  {
    title: "Steuer",
    dataIndex: "vatAmount",
    width: "15%",
    align: COLUMN_ALIGN_RIGHT,
    onCell: () => ({ style: { backgroundColor: colors.lightPurpleOpacity } }),
    render: (vatAmount: number | null, row: VatAnnualDeclarationFieldRow) => (
      <FieldAmount type={row.type} amount={vatAmount} />
    ),
  },
  {
    title: "Voranmeldung BMG",
    dataIndex: "advanceNetAmount",
    width: "15%",
    align: COLUMN_ALIGN_RIGHT,
    render: (
      advanceNetAmount: number | null,
      row: VatAnnualDeclarationFieldRow
    ) => <FieldAmount type={row.type} amount={advanceNetAmount} />,
  },
  {
    title: "Voranmeldung Steuer",
    dataIndex: "advanceVatAmount",
    width: "15%",
    align: COLUMN_ALIGN_RIGHT,
    render: (
      advanceVatAmount: number | null,
      row: VatAnnualDeclarationFieldRow
    ) => <FieldAmount type={row.type} amount={advanceVatAmount} />,
  },
];

export interface VatAnnualDeclarationFieldsTableProps {
  fields: VatAnnualDeclarationField[];
  email: string;
  taxYear: number;
  period: VatAnnualDeclarationPeriodType;
  size?: SizeType;
  yearSuffix?: number;
}

const VatAnnualDeclarationFieldsTable = ({
  fields,
  email,
  taxYear,
  period,
  size = "middle",
  yearSuffix,
}: VatAnnualDeclarationFieldsTableProps) => {
  const user = useUserContext();
  const rows: VatAnnualDeclarationFieldRow[] = useMemo(
    () =>
      fields.map((field) => ({
        ...field,
        email,
        taxYear,
        period,
        yearSuffix,
        vatExemptionWithItd: user?.vatExemptionWithItd || null,
        vatExemptionWithoutItd: user?.vatExemptionWithoutItd || null,
      })),
    [fields, email, taxYear, period, yearSuffix, user]
  );
  return (
    <Table
      columns={VAT_ANNUAL_DECLARATION_FIELDS_TABLE_COLUMNS}
      dataSource={rows}
      rowKey={(row) => row.type}
      pagination={false}
      size={size}
    />
  );
};

export default VatAnnualDeclarationFieldsTable;
