import React from "react";
import { Typography } from "antd";

import useEmailParam from "../../../../../../hooks/useEmailParam";
import useTaxYearParam from "../../../../hooks/useTaxYearParam";
import EmptyWrapper from "../../../../../../common/EmptyWrapper";
import { getSupportedQuestionnairesInfoForYear } from "../utils";
import { CardsWrapper } from "./styledComponents";
import QuestionnaireCard from "./QuestionnaireCard";
import { useQuestionnairesQuery } from "../../../../../../../api/graphql/queries/questionnaire/questionnaires.generated";
import { useTaxCaseQuery } from "../../../../../../../api/graphql/queries/taxCase/taxCase.generated";
import { Questionnaire } from "../../../../../../../api/graphql/schema.generated";
import { EOY_INCOME_TAX_QUESTIONNAIRE_TYPES } from "./constants";
import { TAX_ADVISORY_PERMISSION_SCOPES } from "../../../../../../../constants";
import { getPermissionScope } from "../../../../../../../gapi";

const { Title } = Typography;

const FIRST_SUPPORTED_TAX_YEAR = 2021;

const TaxPreparationOverview = () => {
  const [taxYear] = useTaxYearParam();
  const [email] = useEmailParam();

  const hasTaxAdvisoryPermissionScope = TAX_ADVISORY_PERMISSION_SCOPES.includes(
    getPermissionScope()
  );

  const isSupportedTaxYear = taxYear >= FIRST_SUPPORTED_TAX_YEAR;
  const { data: questionnairesData, loading: isLoadingQuestionnaires } =
    useQuestionnairesQuery({
      skip: !email || !isSupportedTaxYear,
      variables: {
        email: email!,
        year: taxYear,
      },
    });
  const questionnaires = questionnairesData?.questionnaires || [];

  const { data: taxCaseData, loading: isLoadingTaxCase } = useTaxCaseQuery({
    skip: !email || !isSupportedTaxYear || !hasTaxAdvisoryPermissionScope,
    variables: {
      email: email!,
      year: taxYear,
    },
  });
  const isIncomeTaxSkipped = !!taxCaseData?.taxCase?.incomeTaxSkippedAt;

  const isLoading = isLoadingQuestionnaires || isLoadingTaxCase;

  if (!email) {
    return <EmptyWrapper description="Suche nach einem Mandanten" />;
  }

  if (!isSupportedTaxYear) {
    return (
      <EmptyWrapper
        description={`Vorbereitung der Steuererklärung wird erst ab ${FIRST_SUPPORTED_TAX_YEAR} unterstützt`}
      />
    );
  }

  return (
    <>
      <Title
        level={3}
        className="mb-3"
      >{`Vorbereitung der Steuererklärung ${taxYear}`}</Title>

      <CardsWrapper>
        {getSupportedQuestionnairesInfoForYear(taxYear).map(
          (info, infoIndex) => {
            const matchedQuestionnaires = questionnaires.filter(
              ({ type }) => type === info.type
            );
            const isNotRelevant =
              isIncomeTaxSkipped &&
              EOY_INCOME_TAX_QUESTIONNAIRE_TYPES.includes(info.type);

            if (!matchedQuestionnaires.length && !info.showOnlyIfMatch) {
              return (
                <QuestionnaireCard
                  key={`${info.type}_${infoIndex}`}
                  taxYear={taxYear}
                  info={info}
                  questionnaire={undefined}
                  loading={isLoading}
                  isNotRelevant={isNotRelevant}
                />
              );
            }

            return matchedQuestionnaires.map((questionnaire, subIndex) => (
              <QuestionnaireCard
                key={`${info.type}_${infoIndex}_${subIndex}`}
                taxYear={taxYear}
                info={info}
                questionnaire={questionnaire as Questionnaire}
                loading={isLoading}
                isNotRelevant={isNotRelevant}
              />
            ));
          }
        )}
      </CardsWrapper>
    </>
  );
};

export default TaxPreparationOverview;
