import { gql, MutationHookOptions, useMutation } from "@apollo/client";

import { TaxNumber } from "../../types";
import { TaxNumberType } from "../../../../components/pages/Mandanten/Common/UserDetails/TaxNumberList/types";

export interface UpdateTaxNumberInput {
  type: TaxNumberType;
  validFrom?: Date;
  description: string;
  isMainBusinessTaxNumber: boolean;
}

export interface UpdateTaxNumberMutationVariables {
  id: string;
  payload: UpdateTaxNumberInput;
}

export interface UpdateTaxNumberMutationResult {
  updateTaxNumber: TaxNumber;
}

export const UPDATE_TAX_NUMBER_MUTATION = gql`
  mutation updateTaxNumber(
    $id: ID!
    $payload: UpdateTaxNumberInputWithNoTaxNumber!
  ) {
    updateTaxNumber(id: $id, payload: $payload) {
      id
      type
      taxNumber
      validFrom
      description
      isMainBusinessTaxNumber
    }
  }
`;

export const useUpdateTaxNumberMutation = (
  options?: MutationHookOptions<
    UpdateTaxNumberMutationResult,
    UpdateTaxNumberMutationVariables
  >
) =>
  useMutation<UpdateTaxNumberMutationResult, UpdateTaxNumberMutationVariables>(
    UPDATE_TAX_NUMBER_MUTATION,
    options
  );
