import React, { useCallback, useMemo, useState } from "react";
import { Alert, Button, Col, Row, Space } from "antd";
import { DownloadOutlined, EyeOutlined } from "@ant-design/icons";
import pick from "lodash/pick";

import VatAnnualDeclarationSmallBusinessTable from "./VatAnnualDeclarationSmallBusinessTable";
import VatAnnualDeclarationStatusChangeModal from "./VatAnnualDeclarationStatusChangeModal";
import VatAnnualDeclarationPeriodSelect from "./VatAnnualDeclarationPeriodSelect";
import useTaxYearParam from "../../hooks/useTaxYearParam";
import { useUserContext } from "../../contexts/UserContext";
import {
  AntSpin,
  AvailablePeriodErrorContainer,
  AvailablePeriodErrorMessage,
  ColParagraph,
  FileDoneOutlinedIcon,
  ReloadOutlinedIcon,
  SelectHintContainer,
  SelectWrapper,
  VatTableSpace,
} from "./styledComponents";
import {
  VatAnnualDeclarationPeriod,
  VatAnnualDeclarationPeriodType,
} from "../../types";
import {
  IMessageType,
  SMALL_BUSINESS_PAYMENT_FREQUENCIES,
} from "../../../../../types";
import DeclarationStatus from "../../components/DeclarationStatus";
import { VAT_ANNUAL_DECLARATION_STATUS_MAPPINGS } from "./constants";
import useEmailParam from "../../../../hooks/useEmailParam";
import {
  useAvailableVatAdvanceDeclarationPeriodsQuery,
  VatAnnualDeclaration as VatAnnualDeclarationModel,
  useVatAnnualDeclarationQuery,
  VAT_ANNUAL_DECLARATION_QUERY,
  useUpdateVatAnnualDeclarationMutation,
  UpdateVatAnnualDeclarationInput,
  useVatAnnualDeclarationAutoCalculatedValuesQuery,
  useVatAnnualDeclarationDraftQuery,
  useSubmitVatAnnualDeclarationMutation,
  VatAnnualDeclarationFieldType,
} from "../../../../../api/graphql";
import {
  TaxDeclarationSavedDraftInfo,
  TaxDeclarationSubmission,
  TaxDeclarationType,
  VatAnnualDeclarationStatus,
} from "../../../../../api/graphql/schema.generated";
import VatAnnualDeclarationTable from "./VatAnnualDeclarationTable";
import {
  destroyMessage,
  showMessage,
  showGraphQlErrorNotification,
  showInfoNotification,
  showLoadingMessage,
} from "../../../../../utils";
import PdfPreviewModal from "../../components/PdfPreviewModal";
import DeclarationSubmitConfirmation from "../../components/DeclarationSubmitConfirmation";
import EmptyWrapper from "../../../../common/EmptyWrapper";
import VatAnnualDeclarationHeader from "./VatAnnualDeclarationHeader";
import { hasEricNotice } from "../../utils";
import { useSaveVatAnnualDeclarationDraftPdfMutation } from "../../../../../api/graphql/mutations/vatAnnualDeclaration/saveVatAnnualDeclarationDraftPdf.generated";
import { useVatAnnualDeclarationSavedDraftInfoQuery } from "../../../../../api/graphql/queries/vatAnnualDeclaration/vatAnnualDeclarationSavedDraftInfo.generated";
import DeclarationInfoMessage from "../../components/DeclarationInfoMessage";
import { declarationDeclinesSorter } from "../../../../../utils/declarationDeclines";
import DeclarationDeclinedBanner from "../../components/DeclarationDeclinedBanner";
import { useVatAnnualDeclarationSmallBusinessValuesQuery } from "../../../../../api/graphql/queries/vatAnnualDeclaration/vatAnnualDeclarationSmallBusinessValues.generated";
import { declarationSubmissionsSorter } from "../../../../../utils/declarationSubmissons";

const STATUSES_WITH_BLOCKED_PDF_RECREATION = [
  VatAnnualDeclarationStatus.APPROVED_BY_USER,
  VatAnnualDeclarationStatus.OBJECTED_BY_USER,
  VatAnnualDeclarationStatus.SUBMITTED,
  VatAnnualDeclarationStatus.OBJECTED_BY_FINANZAMT,
  VatAnnualDeclarationStatus.RECEIVED_TAX_BILL,
  VatAnnualDeclarationStatus.CLOSED,
  VatAnnualDeclarationStatus.APPEAL_PROCESS_STARTED,
  VatAnnualDeclarationStatus.APPEAL_PROCESS_COMPLETED,
];

const VatAnnualDeclaration = () => {
  const [taxYear] = useTaxYearParam();
  const [email] = useEmailParam();
  const user = useUserContext();

  const userVatPaymentFrequency = useMemo(
    () =>
      user?.vatYearSettings?.find(({ year }) => year === taxYear)
        ?.vatPaymentFrequency,
    [taxYear, user]
  );

  const isSmallBusiness = useMemo(
    () => SMALL_BUSINESS_PAYMENT_FREQUENCIES.includes(userVatPaymentFrequency!),
    [userVatPaymentFrequency]
  );

  const [isStatusChangeModalVisible, setIsStatusChangeModalVisible] =
    useState(false);

  const showStatusChangeModal = useCallback(() => {
    setIsStatusChangeModalVisible(true);
  }, []);

  const handleStatusChangeModalClose = useCallback(() => {
    setIsStatusChangeModalVisible(false);
  }, []);

  const [periodValue, setPeriodValue] =
    useState<VatAnnualDeclarationPeriodType>(VatAnnualDeclarationPeriod.YEAR);

  const [confirmationVisible, setConfirmationVisible] = useState(false);
  const [confirmationLoading, setConfirmationLoading] = useState(false);
  const [selectedSubmission, setSelectedSubmission] =
    useState<TaxDeclarationSubmission>();
  const [draftInfo, setDraftInfo] = useState<TaxDeclarationSavedDraftInfo>();

  const {
    data: availableVatAdvanceDeclarationPeriodsData,
    loading: isLoadingAvailablePeriods,
    error: errorFetchingAvailablePeriods,
    refetch: refetchAvailablePeriods,
  } = useAvailableVatAdvanceDeclarationPeriodsQuery({
    skip: !email,
    variables: {
      email: email!,
      year: taxYear,
    },
    // Allow loading status to get updated on refetch
    notifyOnNetworkStatusChange: true,
  });

  const { data: vatAnnualDeclarationData } = useVatAnnualDeclarationQuery({
    skip: !email,
    variables: {
      email: email!,
      year: taxYear,
    },
  });
  const vatAnnualDeclaration =
    vatAnnualDeclarationData?.vatAnnualDeclaration || null;
  const savedSubmissionInfo = vatAnnualDeclaration?.submissionInfo || null;
  const savedDraftInfo = vatAnnualDeclaration?.savedDraftInfo || null;

  const { data: vatAnnualDeclarationAutoCalculatedValuesData } =
    useVatAnnualDeclarationAutoCalculatedValuesQuery({
      skip: !email || isSmallBusiness,
      variables: {
        email: email!,
        year: taxYear,
        period: periodValue,
      },
    });
  const autoCalculatedValues =
    vatAnnualDeclarationAutoCalculatedValuesData?.vatAnnualDeclaration
      .autoCalculatedValues || null;

  const { data: vatAnnualDeclarationSmallBusinessValuesData } =
    useVatAnnualDeclarationSmallBusinessValuesQuery({
      skip: !email || !isSmallBusiness,
      variables: {
        email: email!,
        year: taxYear,
      },
    });
  const smallBusinessValues =
    vatAnnualDeclarationSmallBusinessValuesData?.vatAnnualDeclaration
      .smallBusinessValues || null;

  const { refetch: getVatAnnualDeclarationDraft } =
    useVatAnnualDeclarationDraftQuery({
      variables: {
        email: email!,
        year: taxYear,
      },
      fetchPolicy: "standby",
    });

  const { refetch: getVatAnnualDeclarationSavedDraftPdf } =
    useVatAnnualDeclarationSavedDraftInfoQuery({
      variables: {
        email: email!,
        year: taxYear,
      },
      fetchPolicy: "standby",
    });

  const [saveVatAnnualDeclarationDraftPdf] =
    useSaveVatAnnualDeclarationDraftPdfMutation({
      variables: {
        email: email!,
        year: taxYear,
      },
      refetchQueries: [VAT_ANNUAL_DECLARATION_QUERY],
    });

  const [updateVatAnnualDeclaration] = useUpdateVatAnnualDeclarationMutation({
    refetchQueries: [VAT_ANNUAL_DECLARATION_QUERY],
  });

  const [submitVatAnnualDeclaration] = useSubmitVatAnnualDeclarationMutation({
    variables: {
      email: email!,
      year: taxYear,
    },
    refetchQueries: [VAT_ANNUAL_DECLARATION_QUERY],
  });

  const saveDeclaration = useCallback(
    async (declarationChange: Partial<VatAnnualDeclarationModel>) => {
      if (!email || !vatAnnualDeclaration) {
        return;
      }

      const payload: UpdateVatAnnualDeclarationInput = {
        ...pick(vatAnnualDeclaration, [
          "status",
          "lastYearIncomeAdjustment",
          "currentYearIncomeAdjustment",
          "taxOfficeBalance",
        ]),
        ...declarationChange,
      };
      await updateVatAnnualDeclaration({
        variables: {
          email,
          year: taxYear,
          payload,
        },
      });
    },
    [email, taxYear, updateVatAnnualDeclaration, vatAnnualDeclaration]
  );

  const handleStatusChange = useCallback(
    (status: VatAnnualDeclarationStatus) => saveDeclaration({ status }),
    [saveDeclaration]
  );

  const showDraftPdf = useCallback(async () => {
    const loadingKey = "loading-draft-pdf";
    showLoadingMessage(loadingKey);
    try {
      const result = await getVatAnnualDeclarationDraft();
      const pdf = result.data?.vatAnnualDeclaration?.draft?.pdf;
      if (pdf) {
        setDraftInfo({ pdf });
        if (
          hasEricNotice(result.data?.vatAnnualDeclaration?.draft?.processResult)
        ) {
          showInfoNotification({
            message: result.data?.vatAnnualDeclaration?.draft?.message,
            description:
              result.data?.vatAnnualDeclaration?.draft?.processResult,
          });
        }
        showMessage({
          type: IMessageType.SUCCESS,
          content: "Abrufen des Entwurf-PDF erfolgreich",
        });
      } else {
        showMessage({
          type: IMessageType.ERROR,
          content:
            result.data?.vatAnnualDeclaration?.draft?.processResult ||
            "Beim Abrufen des Entwurf-PDFs ist ein Fehler aufgetreten",
          duration: 5,
        });
      }
    } catch (error) {
      showGraphQlErrorNotification(
        "Beim Abrufen des Entwurf-PDFs ist ein Fehler aufgetreten:",
        error
      );
    } finally {
      destroyMessage(loadingKey);
    }
  }, [getVatAnnualDeclarationDraft]);

  const saveAndShowDraftPdf = useCallback(async () => {
    const loadingKey = "saving-and-loading-draft-pdf";
    showLoadingMessage(loadingKey);
    try {
      const result = await saveVatAnnualDeclarationDraftPdf();
      const pdf = result.data?.saveVatAnnualDeclarationDraftPdf?.pdf;
      if (pdf) {
        setDraftInfo({ pdf });
        if (
          hasEricNotice(
            result.data?.saveVatAnnualDeclarationDraftPdf?.processResult!
          )
        ) {
          showInfoNotification({
            message: result.data?.saveVatAnnualDeclarationDraftPdf?.message!,
            description:
              result.data?.saveVatAnnualDeclarationDraftPdf?.processResult,
          });
        }
        showMessage({
          type: IMessageType.SUCCESS,
          content: "Abrufen des Entwurf-PDF erfolgreich",
        });
      } else {
        showMessage({
          type: IMessageType.ERROR,
          content:
            result.data?.saveVatAnnualDeclarationDraftPdf?.processResult! ||
            "Beim Abrufen des Entwurf-PDFs ist ein Fehler aufgetreten",
          duration: 5,
        });
      }
    } catch (error) {
      showGraphQlErrorNotification(
        "Beim Abrufen des Entwurf-PDFs ist ein Fehler aufgetreten:",
        error
      );
    } finally {
      destroyMessage(loadingKey);
    }
  }, [saveVatAnnualDeclarationDraftPdf]);

  const showSavedDraftPdf = useCallback(async () => {
    const loadingKey = "loading-saved-draft-pdf";
    showLoadingMessage(loadingKey);
    try {
      const result = await getVatAnnualDeclarationSavedDraftPdf();
      setDraftInfo(
        result.data.vatAnnualDeclaration.savedDraftInfo || undefined
      );
    } catch (error) {
      showGraphQlErrorNotification(
        "Beim Abrufen des USt-Erklärung ist ein Fehler aufgetreten:",
        error
      );
    } finally {
      destroyMessage(loadingKey);
    }
  }, [getVatAnnualDeclarationSavedDraftPdf]);

  const showConfirmation = useCallback(() => {
    setConfirmationVisible(true);
  }, []);

  const hideConfirmation = useCallback(() => {
    setConfirmationVisible(false);
    setConfirmationLoading(false);
  }, []);

  const handleSubmitConfirmation = useCallback(async () => {
    setConfirmationLoading(true);
    try {
      const result = await submitVatAnnualDeclaration();
      if (result.data?.submitVatAnnualDeclaration?.pdf) {
        showMessage({
          type: IMessageType.SUCCESS,
          content: "Erfolgreich eingereicht",
        });
      } else {
        showMessage({
          type: IMessageType.ERROR,
          content:
            result.data?.submitVatAnnualDeclaration?.processResult ||
            "Bei der Einreichung ist ein Fehler aufgetreten",
          duration: 5,
        });
      }
    } catch (error) {
      showGraphQlErrorNotification(
        "Bei der Einreichung ist ein Fehler aufgetreten:",
        error
      );
    }
    hideConfirmation();
  }, [hideConfirmation, submitVatAnnualDeclaration]);

  const refetchAvailablePeriodsHandler = useCallback(
    () => refetchAvailablePeriods(),
    [refetchAvailablePeriods]
  );

  const hasMissingVatExemptionSetting = useMemo(() => {
    if (isSmallBusiness || !autoCalculatedValues || !user) {
      return false;
    }

    const { fields } = autoCalculatedValues.additionalInformation;

    const hasVatExemptionTransactionsWithItd =
      fields.find(
        ({ type }) => type === VatAnnualDeclarationFieldType.INCOME_0_ITD
      )?.netAmount! > 0;

    const hasVatExemptionTransactionsWithoutItd =
      fields.find(
        ({ type }) =>
          type === VatAnnualDeclarationFieldType.VAT_EXEMPTION_WITHOUT_ITD
      )?.netAmount! > 0;

    return (
      (hasVatExemptionTransactionsWithItd && !user.vatExemptionWithItd) ||
      (hasVatExemptionTransactionsWithoutItd && !user.vatExemptionWithoutItd)
    );
  }, [isSmallBusiness, autoCalculatedValues, user]);

  const shouldBlockPdfRecreationByStatus = useMemo(() => {
    return vatAnnualDeclarationData
      ? STATUSES_WITH_BLOCKED_PDF_RECREATION.includes(
          vatAnnualDeclarationData.vatAnnualDeclaration.status
        )
      : true;
  }, [vatAnnualDeclarationData]);

  const lastDeclarationDecline = useMemo(() => {
    if (
      vatAnnualDeclarationData?.vatAnnualDeclaration.declarationDeclines?.length
    ) {
      const declarationDeclines =
        vatAnnualDeclarationData.vatAnnualDeclaration.declarationDeclines;
      declarationDeclines.sort(declarationDeclinesSorter);

      return declarationDeclines[0];
    }
  }, [vatAnnualDeclarationData]);

  const submissions = useMemo(() => {
    if (vatAnnualDeclarationData?.vatAnnualDeclaration.submissions?.length) {
      const declarationSubmissions =
        vatAnnualDeclarationData?.vatAnnualDeclaration.submissions;
      return declarationSubmissions.sort(declarationSubmissionsSorter);
    }
    return [];
  }, [vatAnnualDeclarationData]);

  const isStatusObjectedByUser = useMemo(
    () =>
      vatAnnualDeclarationData?.vatAnnualDeclaration.status ===
      VatAnnualDeclarationStatus.OBJECTED_BY_USER,
    [vatAnnualDeclarationData]
  );

  if (!email) {
    return <EmptyWrapper description="Suche nach einem Mandanten" />;
  }

  if (!vatAnnualDeclaration) {
    return null;
  }

  if (user && !userVatPaymentFrequency) {
    return (
      <VatTableSpace direction="vertical" size={45}>
        <VatAnnualDeclarationHeader
          declarationId={vatAnnualDeclaration.id}
          taxYear={taxYear}
          status={vatAnnualDeclaration.status}
          changeLogs={vatAnnualDeclaration.changeLogs}
          notes={vatAnnualDeclaration.notes}
          declarationDeclines={vatAnnualDeclaration.declarationDeclines}
        />
        <Alert
          message="USt-Voranmeldungszeitraum muss zuerst für dieses Jahr gesetzt werden"
          type="error"
          showIcon
        />
      </VatTableSpace>
    );
  }

  return (
    <VatTableSpace direction="vertical" size={45}>
      <VatAnnualDeclarationHeader
        declarationId={vatAnnualDeclaration.id}
        taxYear={taxYear}
        status={vatAnnualDeclaration.status}
        changeLogs={vatAnnualDeclaration.changeLogs}
        notes={vatAnnualDeclaration.notes}
        isSmallBusiness={isSmallBusiness}
        lastSubmission={submissions[0]}
        draftInfo={savedDraftInfo}
        declarationDeclines={vatAnnualDeclaration.declarationDeclines}
      />
      <Row align="bottom">
        {!isSmallBusiness && (
          <Col span={12}>
            <ColParagraph>
              Ansichtszeitraum
              <SelectHintContainer>
                <FileDoneOutlinedIcon />
                USt-VA vorhanden
              </SelectHintContainer>
            </ColParagraph>
            <SelectWrapper>
              <VatAnnualDeclarationPeriodSelect
                value={periodValue}
                onChange={setPeriodValue}
                availablePeriods={
                  availableVatAdvanceDeclarationPeriodsData?.availableVatAdvanceDeclarationPeriods
                }
                taxYear={taxYear}
              />
              {/* Display a loading indicator while retrieving available periods (Select can still be used). */}
              {isLoadingAvailablePeriods ? (
                <AntSpin />
              ) : (
                // If an error occurs, user can retry fetching the data.
                errorFetchingAvailablePeriods && (
                  <AvailablePeriodErrorContainer>
                    <AvailablePeriodErrorMessage>
                      Fehler beim Laden der verfügbare Zeiträume.
                    </AvailablePeriodErrorMessage>
                    <ReloadOutlinedIcon
                      title="Versuch es nochmal"
                      onClick={refetchAvailablePeriodsHandler}
                    />
                  </AvailablePeriodErrorContainer>
                )
              )}
            </SelectWrapper>
          </Col>
        )}
        <Col span={12}>
          <DeclarationStatus
            mapping={VAT_ANNUAL_DECLARATION_STATUS_MAPPINGS}
            status={vatAnnualDeclaration.status}
            statusUpdatedAt={vatAnnualDeclaration.statusUpdatedAt}
            changedBy={vatAnnualDeclaration.lastStatusChange?.changedBy}
            onClick={showStatusChangeModal}
          />
        </Col>
      </Row>
      <Row>
        <Space direction="horizontal">
          {savedDraftInfo && (
            <Button
              type="primary"
              size="small"
              icon={<EyeOutlined />}
              onClick={showSavedDraftPdf}
            >
              Aktuelles Freigabe-PDF
            </Button>
          )}
          {submissions &&
            submissions.map((submission, index) => (
              <Button
                key={submission.submissionDate}
                type="primary"
                size="small"
                icon={<DownloadOutlined />}
                onClick={() => setSelectedSubmission(submission)}
              >
                {`Übermittelte USt-Erklärung ${submissions.length - index} `}
              </Button>
            ))}
        </Space>
      </Row>
      <DeclarationDeclinedBanner
        declaration={vatAnnualDeclaration}
        declarationType={TaxDeclarationType.VAT_ANNUAL}
        onLinkClick={showSavedDraftPdf}
      />
      {hasMissingVatExemptionSetting && (
        <Alert
          message="Fehlende Angaben zu Steuerfreie Umsätze"
          type="error"
          showIcon
        />
      )}
      {isSmallBusiness ? (
        <VatAnnualDeclarationSmallBusinessTable
          email={email}
          taxYear={taxYear}
          period={periodValue}
          smallBusinessValues={smallBusinessValues}
          declaration={vatAnnualDeclaration}
          onChangeDeclaration={saveDeclaration}
        />
      ) : (
        <VatAnnualDeclarationTable
          email={email}
          taxYear={taxYear}
          period={periodValue}
          autoCalculatedValues={autoCalculatedValues}
          declaration={vatAnnualDeclaration}
          onChangeDeclaration={saveDeclaration}
        />
      )}
      <div>
        <Row justify="space-between">
          <Col>
            <Space>
              <Button
                type="default"
                disabled={hasMissingVatExemptionSetting}
                onClick={showDraftPdf}
              >
                Vorschau-Formular
              </Button>
              <Button
                type="default"
                disabled={
                  hasMissingVatExemptionSetting ||
                  shouldBlockPdfRecreationByStatus ||
                  !!savedDraftInfo?.externalAssets
                }
                onClick={saveAndShowDraftPdf}
              >
                Freigabe-PDF aktualisieren
              </Button>
            </Space>
          </Col>
          <Col>
            <Button
              type="primary"
              disabled={
                hasMissingVatExemptionSetting ||
                !!savedDraftInfo?.externalAssets
              }
              onClick={showConfirmation}
            >
              Einreichen
            </Button>
          </Col>
        </Row>
        <DeclarationInfoMessage
          hasExternalAssets={
            !!savedDraftInfo?.externalAssets ||
            !!savedSubmissionInfo?.externalAssets
          }
          shouldBlockPdfRecreationByStatus={shouldBlockPdfRecreationByStatus}
        />
      </div>
      {isStatusChangeModalVisible && (
        <VatAnnualDeclarationStatusChangeModal
          status={vatAnnualDeclaration.status}
          isSmallBusiness={isSmallBusiness}
          smallBusinessValues={smallBusinessValues}
          autoCalculatedValues={autoCalculatedValues}
          declaration={vatAnnualDeclaration}
          onSave={handleStatusChange}
          onClose={handleStatusChangeModalClose}
        />
      )}
      {draftInfo && (
        <PdfPreviewModal
          declarationName="UStE"
          title="Testformular"
          info={draftInfo}
          onClose={() => setDraftInfo(undefined)}
          declarationDecline={
            isStatusObjectedByUser ? lastDeclarationDecline : undefined
          }
          statusUpdatedAt={
            isStatusObjectedByUser &&
            vatAnnualDeclarationData?.vatAnnualDeclaration.lastStatusChange
              ? vatAnnualDeclarationData.vatAnnualDeclaration.lastStatusChange
                  .changedAt
              : undefined
          }
        />
      )}
      {selectedSubmission && (
        <PdfPreviewModal
          title="Übermittelte USt-Erklärung"
          declarationName="UStE"
          info={selectedSubmission}
          onClose={() => setSelectedSubmission(undefined)}
        />
      )}
      {confirmationVisible && (
        <DeclarationSubmitConfirmation
          declarationName="Umsatzsteuererklärung"
          taxYear={taxYear}
          email={email!}
          loading={confirmationLoading}
          onConfirm={handleSubmitConfirmation}
          onCancel={hideConfirmation}
        />
      )}
    </VatTableSpace>
  );
};

export default VatAnnualDeclaration;
