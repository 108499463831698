import React, { useState, ReactNode } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import styled from "styled-components";

import { IUser, VatMetaNote, ExcerptData } from "../../../types";
import { UserInfoExcerptWrapper } from "./styledComponents";
import UserInfoDrawer, { UserInfoDrawerSources } from "./UserInfoDrawer";
import { fetchSubscriptionType } from "../../../utils/subscription";
import { fetchVatPaymentFrequency } from "../../../utils/paymentFrequency";

type ContainerProps = {
  isSticky?: boolean;
};
const Container = styled.div`
  ${(props: ContainerProps) =>
    props.isSticky
      ? `
  position: sticky;
  top: -24px;
  z-index: 5;`
      : ""}
`;

/** UserInfoExcerpt component uses AntNotes, so it contains kontax API calls */
const UserInfoExcerpt = ({
  isLoading,
  source,
  user,
  notes,
  renderExcerptData,
  wrapperStyle,
  isSticky,
}: {
  isLoading?: boolean;
  source: UserInfoDrawerSources;
  user: IUser | null;
  notes: VatMetaNote[];
  renderExcerptData(excerptData: ExcerptData): ReactNode;
  wrapperStyle?: React.CSSProperties;
  isSticky?: boolean;
}) => {
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);

  return isLoading ? (
    <UserInfoExcerptWrapper style={wrapperStyle}>
      <span className="loading-text">Loading user info</span>
      <LoadingOutlined style={{ fontSize: 24 }} spin />
    </UserInfoExcerptWrapper>
  ) : (
    user && (
      <Container isSticky={isSticky}>
        <div className="user-excerpt" onClick={() => setIsDrawerVisible(true)}>
          {renderExcerptData({
            user,
            subscriptionType: fetchSubscriptionType(user),
            vatDetails: fetchVatPaymentFrequency(
              user,
              new Date().getFullYear()
            ),
            notesCount: `${notes.length} note${notes.length === 1 ? "" : "s"}`,
          })}
        </div>
        <UserInfoDrawer
          visible={isDrawerVisible}
          onClose={() => setIsDrawerVisible(false)}
          user={user}
          notes={notes}
          source={source}
        />
      </Container>
    )
  );
};

export default UserInfoExcerpt;
