export enum CustomerVettingStatus {
  NOT_VETTED = "NOT_VETTED",
  NO_MATCH = "NO_MATCH",
  POTENTIAL_MATCH = "POTENTIAL_MATCH",
  INFORMATION_REQUESTED = "INFORMATION_REQUESTED",
  INFORMATION_RECEIVED = "INFORMATION_RECEIVED",
  RISK_ACCEPTED = "RISK_ACCEPTED",
  RISK_REJECTED = "RISK_REJECTED",
  CUSTOMER_UNRESPONSIVE = "CUSTOMER_UNRESPONSIVE",
  VETTING_NOT_REQUIRED = "VETTING_NOT_REQUIRED",
}
