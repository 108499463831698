import React, { useEffect, useCallback } from "react";
import moment, { Moment } from "moment-timezone";
import { Row, Col, Input, Form, DatePicker, notification } from "antd";

import {
  BERLIN_TIMEZONE,
  getBerlinMomentTimezone,
} from "../../../../../common/helpers";

import { StyledInputNumber } from "./styledComponents";
import { useCreateRetroactiveKontaxFeeMutation } from "../../../../../../api/graphql/mutations/retroactiveKontaxFee/createRetroactiveKontaxFee.generated";
import { showGraphQlErrorNotification } from "../../../../../../utils";

enum FormFieldNames {
  DESCRIPTION = "description",
  AMOUNT = "unitAmount",
  QUANTITY = "quantity",
  DUE_DATE = "dueDate",
  BILLING_RANGE_START = "startDate",
  BILLING_RANGE_END = "endDate",
}

const dateFormats = ["DD.MM.YYYY", "DDMMYYYY", "DDMM"];

const disabledDueDate = (current: Moment) => {
  return (
    current.diff(moment.tz(BERLIN_TIMEZONE).format("YYYY-MM-DD"), "days") <= 0
  );
};

const ManualInvoiceForm = ({
  email,
  onModalCancel,
  setIsInvoiceSaveLoading,
  refreshRetroactiveKontaxFee,
}: {
  email: string;
  onModalCancel: Function;
  setIsInvoiceSaveLoading: Function;
  refreshRetroactiveKontaxFee: Function;
}) => {
  const [form] = Form.useForm();
  const [createRetroactiveKontaxFee] = useCreateRetroactiveKontaxFeeMutation();

  useEffect(() => {
    form.setFields([
      {
        name: FormFieldNames.DESCRIPTION,
        value: "Rückwirk. Zahlung",
      },
      {
        name: FormFieldNames.AMOUNT,
        value: 179,
      },
      {
        name: FormFieldNames.QUANTITY,
        value: new Date().getMonth(),
      },
      {
        name: FormFieldNames.DUE_DATE,
        value: getBerlinMomentTimezone(
          moment().add(1, "day").format("YYYY-MM-DD")
        ),
      },
      {
        name: FormFieldNames.BILLING_RANGE_START,
      },
      {
        name: FormFieldNames.BILLING_RANGE_END,
      },
    ]);
  }, [form]);

  const handleSubmit = async (payload: any) => {
    try {
      setIsInvoiceSaveLoading(true);

      await createRetroactiveKontaxFee({
        variables: {
          email,
          payload,
        },
      });

      notification.success({
        message: (
          <div>
            Invoice for <strong>{email}</strong> has been successfully created!
          </div>
        ),
      });
      await refreshRetroactiveKontaxFee();
      onModalCancel();
    } catch (error) {
      showGraphQlErrorNotification(
        "An error occurred while creating the manual invoice:",
        error
      );
    } finally {
      setIsInvoiceSaveLoading(false);
    }
  };

  const disabledStartRangeDate = useCallback(
    (current: Moment) => {
      return (
        getBerlinMomentTimezone(current.format("YYYY-MM-DD")).diff(
          form.getFieldValue(FormFieldNames.DUE_DATE),
          "days"
        ) >= 0 ||
        (form.getFieldValue(FormFieldNames.BILLING_RANGE_END) &&
          getBerlinMomentTimezone(current.format("YYYY-MM-DD")).diff(
            form.getFieldValue(FormFieldNames.BILLING_RANGE_END),
            "days"
          ) >= 0)
      );
    },
    [form]
  );

  const disabledEndRangeDate = useCallback(
    (current: Moment) => {
      return (
        getBerlinMomentTimezone(current.format("YYYY-MM-DD")).diff(
          form.getFieldValue(FormFieldNames.DUE_DATE),
          "days"
        ) >= 0 ||
        (form.getFieldValue(FormFieldNames.BILLING_RANGE_START) &&
          getBerlinMomentTimezone(current.format("YYYY-MM-DD")).diff(
            form.getFieldValue(FormFieldNames.BILLING_RANGE_START),
            "days"
          ) <= 0)
      );
    },
    [form]
  );

  const billingRangeStartValidator = useCallback(
    (_: any, value: Moment) => {
      if (!value) {
        return Promise.resolve();
      }

      return moment(form.getFieldValue(FormFieldNames.BILLING_RANGE_END)).diff(
        value,
        "days"
      ) <= 0
        ? Promise.reject(
            new Error(
              "Billing range start should be less than billing range end"
            )
          )
        : Promise.resolve();
    },
    [form]
  );

  const billingRangeEndValidator = useCallback(
    (_: any, value: Moment) => {
      if (!value) {
        return Promise.resolve();
      }

      return moment(
        form.getFieldValue(FormFieldNames.BILLING_RANGE_START)
      ).diff(value, "days") >= 0
        ? Promise.reject(
            new Error(
              "Billing range end should be greater than billing range start"
            )
          )
        : Promise.resolve();
    },
    [form]
  );

  const onDateChange = (name: FormFieldNames, date: Moment | null) => {
    if (date) {
      form.setFields([
        {
          name,
          value: getBerlinMomentTimezone(date?.format("YYYY-MM-DD")),
        },
      ]);
    }
  };

  return (
    <Form
      form={form}
      layout="vertical"
      name="manualInvoiceForm"
      id="manualInvoiceFormId"
      onFinish={handleSubmit}
      autoComplete="off"
      requiredMark="optional"
    >
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            label="Description"
            name={FormFieldNames.DESCRIPTION}
            rules={[{ required: true, message: "Please enter a description" }]}
          >
            <Input placeholder="Enter a description" autoFocus />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Zahlungsziel"
            name={FormFieldNames.DUE_DATE}
            rules={[{ required: true, message: "Please enter a due date" }]}
          >
            <DatePicker
              picker="date"
              placeholder="DD.MM.YYYY"
              format={dateFormats}
              style={{ width: "100%" }}
              disabledDate={disabledDueDate}
              onChange={(value: Moment | null) =>
                onDateChange(FormFieldNames.DUE_DATE, value)
              }
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Billing range starts"
            name={FormFieldNames.BILLING_RANGE_START}
            rules={[
              { required: true, message: "Please enter billing range starts" },
              { validator: billingRangeStartValidator },
            ]}
          >
            <DatePicker
              picker="date"
              placeholder="DD.MM.YYYY"
              format={dateFormats}
              style={{ width: "100%" }}
              disabledDate={disabledStartRangeDate}
              onChange={(value: Moment | null) =>
                onDateChange(FormFieldNames.BILLING_RANGE_START, value)
              }
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Billing range ends"
            name={FormFieldNames.BILLING_RANGE_END}
            rules={[
              { required: true, message: "Please enter billing range ends" },
              { validator: billingRangeEndValidator },
            ]}
          >
            <DatePicker
              picker="date"
              placeholder="DD.MM.YYYY"
              format={dateFormats}
              style={{ width: "100%" }}
              disabledDate={disabledEndRangeDate}
              onChange={(value: Moment | null) =>
                onDateChange(FormFieldNames.BILLING_RANGE_END, value)
              }
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Quantity"
            name={FormFieldNames.QUANTITY}
            rules={[{ required: true, message: "Please enter a quantity" }]}
          >
            <StyledInputNumber min={1} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Amount"
            name={FormFieldNames.AMOUNT}
            rules={[{ required: true, message: "Please enter an amount" }]}
          >
            <StyledInputNumber min={1} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default ManualInvoiceForm;
