import React, { useEffect, useState } from "react";
import { Table, Button, Input, Space } from "antd";
import omit from "lodash/omit";

import {
  AnnotationTag,
  AnnotationTagInput,
} from "../../../../api/graphql/schema.generated";
import { onClickStopPropagation } from "../../../../utils/table";
import { EMPTY_ANNOTATION_TAG, SAMPLE_TEXT } from "./constants";
import { LargeText, TagText, ColorContainer } from "./styledComponents";

interface AnnotationTagTableProps {
  annotationTags?: AnnotationTag[];
  upsertAnnotationTag: (annotationTag: AnnotationTagInput) => Promise<any>;
  loading: boolean;
}

const cleanTypename = (v: AnnotationTag): AnnotationTagInput =>
  omit(v, "__typename");

const cleanTypenames = (value: AnnotationTag[]): AnnotationTagInput[] =>
  value.map(cleanTypename);

const AnnotationTagTable = ({
  annotationTags,
  upsertAnnotationTag,
  loading,
}: AnnotationTagTableProps) => {
  const [localAnnotationTags, setLocalAnnotationTags] = useState<
    AnnotationTagInput[]
  >(annotationTags || []);

  const [errors, setErrors] = useState<Record<string, boolean>>({});

  const [selectedAnnotationTag, setSelectedAnnotationTag] =
    useState<AnnotationTagInput | null>(null);

  useEffect(() => {
    if (annotationTags?.length) {
      setLocalAnnotationTags(cleanTypenames(annotationTags));
    }
  }, [annotationTags]);

  const validateForm = () => {
    // Tag is unique, therefore when adding new tag, check against the current list.
    const isExistingTag =
      !selectedAnnotationTag?.id &&
      localAnnotationTags.some(
        ({ tag: currentTag }) =>
          currentTag.toLocaleLowerCase() ===
          selectedAnnotationTag?.tag?.toLocaleLowerCase()
      );

    return {
      tag: !selectedAnnotationTag?.tag || isExistingTag,
      color: !selectedAnnotationTag?.color,
      description: !selectedAnnotationTag?.description,
    };
  };

  const onSave = async () => {
    if (selectedAnnotationTag) {
      const formErrors = validateForm();
      const hasError = Object.values(formErrors).some((error) => error);
      setErrors(formErrors);

      if (hasError) {
        return;
      }

      const {
        data: { upsertAnnotationTag: updated },
      } = await upsertAnnotationTag(selectedAnnotationTag);

      const newLocalAnnotationTags = localAnnotationTags.map((tag) =>
        tag.id === selectedAnnotationTag.id ? cleanTypename(updated) : tag
      );

      setLocalAnnotationTags(newLocalAnnotationTags);
      setSelectedAnnotationTag(null);
    }
  };

  const onCancel = () => {
    // remove any new tag that is not saved
    setLocalAnnotationTags(localAnnotationTags.filter((tag) => tag.id));

    // remove errors
    setErrors({});

    // remove selected tag
    setSelectedAnnotationTag(null);
  };

  const handleOnInputChange = (input: string, value: string) => {
    if (selectedAnnotationTag) {
      setSelectedAnnotationTag({
        ...selectedAnnotationTag,
        [input]: value,
      });
    }
  };

  const onAddNewTag = () => {
    setLocalAnnotationTags([...localAnnotationTags, EMPTY_ANNOTATION_TAG]);
    setSelectedAnnotationTag(EMPTY_ANNOTATION_TAG);
  };

  const columns = [
    {
      title: "Tag",
      key: "tag",
      width: 200,
      render: (record: AnnotationTagInput) => {
        if (selectedAnnotationTag?.id !== record.id) {
          return <div>{record.tag}</div>;
        }

        return (
          <Input
            defaultValue={record.tag}
            onChange={(e) => handleOnInputChange("tag", e.target.value)}
            data-testid={`tag-${record.id}`}
            placeholder="input a tag"
            disabled={Boolean(selectedAnnotationTag?.id)} // does not allow to edit existing annotationTag.tag
            status={errors.tag ? "error" : undefined}
          />
        );
      },
      onCell: onClickStopPropagation,
    },
    {
      title: "Color",
      key: "color",
      width: 300,
      render: (record: AnnotationTagInput) => {
        if (selectedAnnotationTag?.id !== record.id) {
          return (
            <ColorContainer color={record.color}>
              <LargeText>{SAMPLE_TEXT}</LargeText>
              <TagText>{` ${record.tag}`}</TagText>
            </ColorContainer>
          );
        }

        return (
          <Input
            defaultValue={record.color}
            onChange={(e) => handleOnInputChange("color", e.target.value)}
            type="color"
            data-testid={`color-${record.id}`}
            style={{ width: 60 }}
            status={errors.color ? "error" : undefined}
          />
        );
      },
      onCell: onClickStopPropagation,
    },
    {
      title: "Description",
      key: "description",
      width: 400,
      render: (record: AnnotationTagInput) => {
        if (selectedAnnotationTag?.id !== record.id) {
          return <div>{record.description}</div>;
        }

        return (
          <Input
            defaultValue={record.description}
            onChange={(e) => handleOnInputChange("description", e.target.value)}
            data-testid={`description-${record.id}`}
            placeholder="input a description"
            status={errors.description ? "error" : undefined}
          />
        );
      },
      onCell: onClickStopPropagation,
    },
    {
      title: "Action",
      key: "action",
      render: (_: any, record: AnnotationTagInput) => (
        <>
          {selectedAnnotationTag?.id !== record.id && (
            <Button
              type="link"
              onClick={() => setSelectedAnnotationTag(record)}
            >
              Edit
            </Button>
          )}
          {selectedAnnotationTag?.id === record.id && (
            <Space direction="horizontal" size="middle">
              <Button type="link" onClick={onSave}>
                Save
              </Button>
              <Button type="link" onClick={onCancel}>
                Cancel
              </Button>
            </Space>
          )}
        </>
      ),
    },
  ];

  return (
    <>
      <Table
        data-testid="AnnotationTagTable"
        columns={columns}
        dataSource={localAnnotationTags}
        bordered
        rowKey={(record) => record.tag}
        scroll={{ x: "100%", y: "80vh" }}
        size="middle"
        sticky
        loading={loading}
        locale={{ emptyText: "No annotation tags found 🔎" }}
        pagination={{
          position: ["bottomRight"],
          pageSize: 50,
        }}
      />
      <Button
        disabled={Boolean(selectedAnnotationTag) || loading}
        onClick={onAddNewTag}
        type="primary"
      >
        Add new tag
      </Button>
    </>
  );
};

export default AnnotationTagTable;
