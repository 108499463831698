import React, { useState } from "react";

import TransactionList from "./TransactionList";
import { KontaxTransactionFilterOptions } from "../../../types";
import TransactionFilters, { Filter } from "../../common/TransactionFilters";
import { getDefaultFilterState } from "../../common/filter";
import UserInfoExcerpt from "../../common/UserInfoExcerpt";
import {
  UserInfoExcerptWrapper,
  UserInfoWrapper,
  UserNameWrapper,
  ViewMoreWrapper,
} from "../../common/UserInfoExcerpt/styledComponents";
import { ReactComponent as ArrowRight } from "../../../svgs/arrow-right.svg";
import useTransactionFilterParams from "../../hooks/useTransactionFilterParams";
import { ContentWrapper } from "../../common/styledComponents";
import {
  KontaxUserContext,
  useKontaxUserContext,
} from "../../contexts/KontaxUserContext";
import { UserInfoDrawerSources } from "../../common/UserInfoExcerpt/UserInfoDrawer";

const UserInfo = () => {
  const { user, notes, isLoading } = useKontaxUserContext();

  return (
    <UserInfoExcerpt
      isSticky
      user={user}
      notes={notes}
      source={UserInfoDrawerSources.ACCOUNTING}
      isLoading={isLoading}
      renderExcerptData={(excerptData) => {
        return (
          <UserInfoExcerptWrapper>
            <UserNameWrapper>
              {excerptData.user.firstName} {excerptData.user.lastName}
            </UserNameWrapper>

            <span>
              {excerptData.vatDetails?.isKleinunternehmer && (
                <UserInfoWrapper>Kleinunternehmer</UserInfoWrapper>
              )}
              {excerptData.user.businessTypeComment && (
                <UserInfoWrapper>
                  {excerptData.user.businessTypeComment}
                </UserInfoWrapper>
              )}
              <UserInfoWrapper>
                {excerptData.vatDetails?.frequency} (
                {excerptData.vatDetails?.year})
              </UserInfoWrapper>
              <UserInfoWrapper>{excerptData.subscriptionType}</UserInfoWrapper>
              <UserInfoWrapper>{excerptData.notesCount}</UserInfoWrapper>
            </span>

            <ViewMoreWrapper>
              <span>View more info</span>
              <ArrowRight />
            </ViewMoreWrapper>
          </UserInfoExcerptWrapper>
        );
      }}
    />
  );
};

const VerificationView = () => {
  const [filterParams, setFilterParams] = useTransactionFilterParams();
  const [filterOptions, setFilterOptions] =
    useState<KontaxTransactionFilterOptions>({
      ...getDefaultFilterState(),
      escalated: undefined, // We want to see both escalated and normal transactions on this view
      ...filterParams,
      includeAll: true,
    });

  return (
    <ContentWrapper>
      <TransactionFilters
        onChangeHandler={(
          updatedFilterOptions: Partial<KontaxTransactionFilterOptions>
        ) => {
          const newFilterOptions = {
            ...filterOptions,
            ...updatedFilterOptions,
          };
          setFilterOptions(newFilterOptions);
          setFilterParams(updatedFilterOptions);
        }}
        email={filterOptions.email}
        direction={filterOptions.direction}
        source={filterOptions.source}
        dateFrom={filterOptions.dateFrom}
        dateTo={filterOptions.dateTo}
        categories={filterOptions.categories}
        vatCategoryCodes={filterOptions.vatCategoryCodes}
        hiddenFilters={[
          Filter.STATUS,
          Filter.ASSETS,
          Filter.VAT_PAYMENT_FREQUENCY,
          Filter.PLAN,
          Filter.CATEGORIZED,
        ]}
      />
      <KontaxUserContext email={filterOptions.email}>
        <UserInfo />
        <TransactionList filterOptions={filterOptions} />
      </KontaxUserContext>
    </ContentWrapper>
  );
};

export default VerificationView;
