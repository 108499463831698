import type * as Types from "../../schema.generated";

import { gql } from "@apollo/client";
import { AnnotationOutputFragmentDoc } from "../../fragments/annotationOutput.generated";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type AnnotationQueryVariables = Types.Exact<{
  transactionId: Types.Scalars["ID"];
}>;

export type AnnotationQuery = {
  __typename?: "Query";
  annotation?: {
    __typename?: "AnnotationOutput";
    documentId: string;
    text: string;
    value: Array<{
      __typename?: "Annotation";
      id: string;
      start: number;
      end: number;
      tag: string;
      color: string;
      text: string;
    }>;
  } | null;
};

export const AnnotationDocument = gql`
  query annotation($transactionId: ID!) {
    annotation(transactionId: $transactionId) {
      ...AnnotationOutput
    }
  }
  ${AnnotationOutputFragmentDoc}
`;

/**
 * __useAnnotationQuery__
 *
 * To run a query within a React component, call `useAnnotationQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnnotationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnnotationQuery({
 *   variables: {
 *      transactionId: // value for 'transactionId'
 *   },
 * });
 */
export function useAnnotationQuery(
  baseOptions: Apollo.QueryHookOptions<
    AnnotationQuery,
    AnnotationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AnnotationQuery, AnnotationQueryVariables>(
    AnnotationDocument,
    options
  );
}
export function useAnnotationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AnnotationQuery,
    AnnotationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AnnotationQuery, AnnotationQueryVariables>(
    AnnotationDocument,
    options
  );
}
export type AnnotationQueryHookResult = ReturnType<typeof useAnnotationQuery>;
export type AnnotationLazyQueryHookResult = ReturnType<
  typeof useAnnotationLazyQuery
>;
export type AnnotationQueryResult = Apollo.QueryResult<
  AnnotationQuery,
  AnnotationQueryVariables
>;
