import React, { Fragment, useEffect, useState } from "react";
import { useQueryParam } from "use-query-params";
import { Tabs, Badge } from "antd";

import BusinessAssetList from "./BusinessAssetList";
import {
  IUser,
  IUserDetails,
  KontaxUserStatus,
  KontaxUserStatusAdditionalParams,
} from "../../../../../types";
import DocumentsList from "./DocumentsList";
import DatevImport from "./DatevImport";
import Anschreiben from "../../../Anschreiben/Anschreiben";
import { UserPages } from "../../Common/UserDetails/enums";
import { CustomerInformation } from "./CustomerInformation";
import { UpsertAccountingSourcePayload } from "../../../../../types/AccountingSource.type";
import { KontaxUserContext } from "../../../../contexts/KontaxUserContext";
import DocumentsTree from "../../Common/UserDetails/DocumentsTree";
import colors from "../../../../../themes/colors";
import { DocuwareDocuments } from "../../Common/UserDetails/DocumentsTree/DocuwareDocuments";
import { getPermissionScope } from "../../../../../gapi";
import { TAX_ADVISORY_PERMISSION_SCOPES } from "../../../../../constants";

const { TabPane } = Tabs;

const badgeStyle = {
  backgroundColor: colors.purple,
  color: colors.white,
};

const UserDetails = ({
  user,
  updateUserStatus,
  upsertAccountingSource,
  removeAccountingSource,
  editUser,
  updateDependents,
  deleteDependent,
}: {
  user: IUser;
  editUser: (email: string, userDetails: IUserDetails) => Promise<void>;
  removeAccountingSource: (email: string, year: number) => Promise<void>;
  upsertAccountingSource: (
    email: string,
    payload: UpsertAccountingSourcePayload
  ) => Promise<void>;
  updateUserStatus: (
    email: string,
    status: KontaxUserStatus | null,
    additionalParams?: KontaxUserStatusAdditionalParams
  ) => Promise<void>;
  updateDependents: Function;
  deleteDependent: (email: string, id: string) => Promise<void>;
}) => {
  const [userPageQueryParam, setUserPageQueryParam] = useQueryParam<
    UserPages | undefined
  >("userPage");
  const [businessAssetsCount, setBusinessAssetsCount] = useState<number>(0);
  const [documentsCount, setDocumentsCount] = useState<number>(0);
  const defaultActiveTab = userPageQueryParam || UserPages.INFORMATION;
  const [activeTab, setActiveTab] = useState<string>(defaultActiveTab);
  const hasTaxAdvisoryPermissionScope = TAX_ADVISORY_PERMISSION_SCOPES.includes(
    getPermissionScope()
  );

  useEffect(() => {
    if (userPageQueryParam) {
      // Clear `userPage` query param because it's intended to be used only once during initial render
      setUserPageQueryParam(undefined, "replaceIn");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <KontaxUserContext user={user}>
      <Tabs
        defaultActiveKey={defaultActiveTab}
        centered
        onChange={setActiveTab}
      >
        <TabPane
          tab={<span id="InformationTabButton">Information</span>}
          key={UserPages.INFORMATION}
        >
          <CustomerInformation
            user={user}
            editUser={editUser}
            updateUserStatus={updateUserStatus}
            upsertAccountingSource={upsertAccountingSource}
            removeAccountingSource={removeAccountingSource}
            updateDependents={updateDependents}
            deleteDependent={deleteDependent}
          />
        </TabPane>
        {hasTaxAdvisoryPermissionScope && (
          <TabPane
            forceRender={true}
            tab={
              <span id="DocumentsTabButton">
                Dokumente <Badge count={documentsCount} style={badgeStyle} />
              </span>
            }
            key={UserPages.DOCUMENTS}
          >
            <DocumentsTree
              user={user}
              editUser={editUser}
              categoryNames={["VERIFICATION", "OTHER"]}
              allowUpload
              setDocumentsCount={setDocumentsCount}
            />
            {activeTab === UserPages.DOCUMENTS && (
              <DocuwareDocuments accountId={user.accountId} />
            )}
          </TabPane>
        )}
        <TabPane
          forceRender={true}
          tab={
            <span id="AssetsTabButton">
              Anlagevermögen{" "}
              <Badge count={businessAssetsCount} style={badgeStyle} />
            </span>
          }
          key={UserPages.ASSETS}
        >
          <BusinessAssetList
            user={user}
            setBusinessAssetsCount={setBusinessAssetsCount}
          />
        </TabPane>
        {hasTaxAdvisoryPermissionScope && (
          <Fragment>
            <TabPane
              tab={<span id="ReceiptsTabButton">Belege</span>}
              key={UserPages.RECEIPTS}
            >
              <DocumentsList user={user} pageType={UserPages.RECEIPTS} />
            </TabPane>
            <TabPane
              tab={
                <span id="DateVImportTabButton">DTVF &amp; EXTF Import</span>
              }
              key={UserPages.DATEV_IMPORT}
            >
              <DatevImport user={user} />
            </TabPane>
            <TabPane
              tab={<span id="AnschreibenTabButton">Anschreiben</span>}
              key={UserPages.ANSCHREIBEN}
            >
              <Anschreiben user={user} />
            </TabPane>
          </Fragment>
        )}
      </Tabs>
    </KontaxUserContext>
  );
};

export default UserDetails;
