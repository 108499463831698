import styled from "styled-components";

import CurrencyInput from "../../../../common/CurrencyInput";

export const StyledCurrencyInput: typeof CurrencyInput = styled(CurrencyInput)`
  /* Use -1px to compensate bigger size of input in comparison for regular cell */
  margin: -1px 0;
  max-width: 100px;

  .ant-input-number-input {
    text-align: right;
  }
`;
