import type * as Types from "../../schema.generated";

import { gql } from "@apollo/client";
import { KontaxTransactionForAdminFragmentDoc } from "../../fragments/kontaxTransactionForAdmin.generated";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type TransactionVerifiedByMlQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type TransactionVerifiedByMlQuery = {
  __typename?: "Query";
  transactionVerifiedByML?: {
    __typename?: "KontaxTransactionForAdmin";
    id: string;
    amount: number;
    valutaDate: string;
    verified: boolean;
    verifiedBy?: string | null;
    name?: string | null;
    iban?: string | null;
    description?: string | null;
    merchantCategoryCode?: string | null;
    foreignCurrency?: string | null;
    escalated?: boolean | null;
    escalationNote?: string | null;
    internalNote?: string | null;
    businessTypeComment?: string | null;
    personalNote?: string | null;
    invoiceRequestedAt?: string | null;
    email: string;
    firstName: string;
    lastName: string;
    categoryCode?: string | null;
    vatCategoryCode?: string | null;
    hasIntegrationDocument?: boolean | null;
    isSplitCategorized?: boolean | null;
    vatYearPaymentFrequency?: Types.PaymentFrequency | null;
    source: string;
    taxAdvisorProof?: Types.TaxAdvisorProof | null;
    fibuCategoryComment?: string | null;
    vatCategoryComment?: string | null;
    categoryCodeMeta?: {
      __typename?: "ValueMeta";
      label?: string | null;
      description?: string | null;
      categorizationType?: string | null;
      suggestionSource?: string | null;
    } | null;
    vatCategoryCodeMeta?: {
      __typename?: "ValueMeta";
      label?: string | null;
      description?: string | null;
      categorizationType?: string | null;
      suggestionSource?: string | null;
    } | null;
    assets: Array<{
      __typename?: "AssetData";
      id: string;
      filetype: string;
      fullsize: string;
    }>;
    businessAssets: Array<{
      __typename?: "BusinessAsset";
      businessAssetableId: string;
      businessAssetableType: string;
      assetType: Types.AssetType;
      assetClass: string;
      purchaseDate: string;
      amount: number;
      depreciationPeriodYears: number;
      note?: string | null;
    }>;
  } | null;
};

export const TransactionVerifiedByMlDocument = gql`
  query transactionVerifiedByML {
    transactionVerifiedByML {
      ...KontaxTransactionForAdmin
    }
  }
  ${KontaxTransactionForAdminFragmentDoc}
`;

/**
 * __useTransactionVerifiedByMlQuery__
 *
 * To run a query within a React component, call `useTransactionVerifiedByMlQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransactionVerifiedByMlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransactionVerifiedByMlQuery({
 *   variables: {
 *   },
 * });
 */
export function useTransactionVerifiedByMlQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TransactionVerifiedByMlQuery,
    TransactionVerifiedByMlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    TransactionVerifiedByMlQuery,
    TransactionVerifiedByMlQueryVariables
  >(TransactionVerifiedByMlDocument, options);
}
export function useTransactionVerifiedByMlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TransactionVerifiedByMlQuery,
    TransactionVerifiedByMlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TransactionVerifiedByMlQuery,
    TransactionVerifiedByMlQueryVariables
  >(TransactionVerifiedByMlDocument, options);
}
export type TransactionVerifiedByMlQueryHookResult = ReturnType<
  typeof useTransactionVerifiedByMlQuery
>;
export type TransactionVerifiedByMlLazyQueryHookResult = ReturnType<
  typeof useTransactionVerifiedByMlLazyQuery
>;
export type TransactionVerifiedByMlQueryResult = Apollo.QueryResult<
  TransactionVerifiedByMlQuery,
  TransactionVerifiedByMlQueryVariables
>;
