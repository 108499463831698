import type * as Types from "../../schema.generated";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type CreateUsageStatMutationVariables = Types.Exact<{
  payload: Types.CreateUsageStatInput;
}>;

export type CreateUsageStatMutation = {
  __typename?: "Mutation";
  createUsageStat: {
    __typename?: "UsageStat";
    id: string;
    activity: string;
    comment?: string | null;
    startedAt: string;
    finishedAt: string;
  };
};

export const CreateUsageStatDocument = gql`
  mutation createUsageStat($payload: CreateUsageStatInput!) {
    createUsageStat(payload: $payload) {
      id
      activity
      comment
      startedAt
      finishedAt
    }
  }
`;
export type CreateUsageStatMutationFn = Apollo.MutationFunction<
  CreateUsageStatMutation,
  CreateUsageStatMutationVariables
>;

/**
 * __useCreateUsageStatMutation__
 *
 * To run a mutation, you first call `useCreateUsageStatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUsageStatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUsageStatMutation, { data, loading, error }] = useCreateUsageStatMutation({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useCreateUsageStatMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUsageStatMutation,
    CreateUsageStatMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateUsageStatMutation,
    CreateUsageStatMutationVariables
  >(CreateUsageStatDocument, options);
}
export type CreateUsageStatMutationHookResult = ReturnType<
  typeof useCreateUsageStatMutation
>;
export type CreateUsageStatMutationResult =
  Apollo.MutationResult<CreateUsageStatMutation>;
export type CreateUsageStatMutationOptions = Apollo.BaseMutationOptions<
  CreateUsageStatMutation,
  CreateUsageStatMutationVariables
>;
