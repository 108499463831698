import { gql, MutationHookOptions, useMutation } from "@apollo/client";

export interface DeleteAssetMutationVariables {
  id: string;
}

export interface DeleteAssetMutationResult {
  deleteAsset: {
    success: boolean;
  };
}

export const DELETE_ASSET_MUTATION = gql`
  mutation deleteAsset($id: ID!) {
    deleteAsset(assetId: $id) {
      success
    }
  }
`;

export const useDeleteAssetMutation = (
  options?: MutationHookOptions<
    DeleteAssetMutationResult,
    DeleteAssetMutationVariables
  >
) =>
  useMutation<DeleteAssetMutationResult, DeleteAssetMutationVariables>(
    DELETE_ASSET_MUTATION,
    options
  );
