import React from "react";
import { Tag } from "antd";

export interface LabelWithTagProps {
  tag: string;
  label: string;
}

const LabelWithTag = ({ tag, label }: LabelWithTagProps) => (
  <>
    <Tag>{tag}</Tag>
    <span>{label}</span>
  </>
);

export default LabelWithTag;
