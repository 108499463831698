import React, {
  ChangeEvent,
  KeyboardEvent,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Input as AntInput, InputRef } from "antd";
import styled from "styled-components";
import classNames from "classnames";
import { SizeType } from "antd/lib/config-provider/SizeContext";

import {
  formatAmountToCurrency,
  parseAmount,
  currencyFormatter,
} from "../../../utils";
import colors from "../../../themes/colors";

const Input = styled(AntInput)`
  text-align: right;

  &.invalid {
    border: 1px solid ${colors.darkRed};
  }

  .ant-input {
    text-align: right;
  }
`;

interface CurrencyInputProps {
  id?: string;
  placeholder?: string;
  name?: string;
  value?: number;
  onChange: (value: number) => void;
  negativeEnabled?: boolean;
  required?: boolean;
  disabled?: boolean;
  className?: string;
  addonBefore?: string;
  prefix?: ReactNode;
  invalid?: boolean;
  onKeyPress?: (event: KeyboardEvent<HTMLInputElement>) => void;
  onBlur?: () => void;
  size?: SizeType;
}

/**
 * Currency Input internally keeps a string representation of the formatted amount.
 * Changes are only triggered on blur to prevent formatting when the user is typing, and the provided
 * changed value will be numeric.
 */
const CurrencyInput = React.forwardRef(
  (props: CurrencyInputProps, ref: React.ForwardedRef<InputRef>) => {
    const {
      id,
      placeholder,
      name,
      value = 0,
      onChange,
      negativeEnabled = false, // negative input is not supported by default
      required = false,
      disabled = false,
      className,
      addonBefore,
      prefix,
      invalid = false,
      onKeyPress,
      onBlur,
      size,
    } = props;
    const [visibleValue, setVisibleValue] = useState<string>(value.toString());

    const setFormattedVisibleValue = useCallback((value, negativeEnabled) => {
      const normalizedValueString = currencyFormatter.format(value);
      setVisibleValue(
        formatAmountToCurrency(normalizedValueString, negativeEnabled)
      );
    }, []);

    /**
     * On blur is in charge of triggering the onChange after normalizing/formatting
     * the input amount and verifying it actually changed. If the value hasn't changed
     * it simply formats it again (to get rid of unexpected chars).
     */
    const onInputBlur = useCallback(() => {
      const currencyFormattedAmount = formatAmountToCurrency(
        visibleValue,
        negativeEnabled
      );
      const newValue = parseAmount(currencyFormattedAmount) / 100;

      // When numeric value changes elevate the change
      if (value !== newValue) {
        onChange(newValue);
      } else {
        // Since value has not changed, we need to manually format visible value.
        setFormattedVisibleValue(value, negativeEnabled);
      }
      onBlur && onBlur();
    }, [
      negativeEnabled,
      onChange,
      setFormattedVisibleValue,
      value,
      visibleValue,
      onBlur,
    ]);

    /**
     * On change will only update the internal formatted value and will bubble up the change.
     * Actual onChange is called onBlur.
     */
    const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
      setVisibleValue(event.target.value);
    };

    useEffect(() => {
      setFormattedVisibleValue(value, negativeEnabled);
    }, [value, negativeEnabled, setFormattedVisibleValue]);

    return (
      <Input
        id={id}
        ref={ref}
        name={name}
        placeholder={placeholder}
        value={visibleValue}
        required={required}
        onChange={onInputChange}
        onBlur={onInputBlur}
        addonBefore={addonBefore}
        prefix={prefix}
        disabled={disabled}
        className={classNames(className, { invalid })}
        onKeyPress={onKeyPress}
        size={size}
      />
    );
  }
);

export default CurrencyInput;
