import React, { useState, useEffect, useCallback } from "react";
import { Modal, Form, Button, notification, Select, Input } from "antd";

import { useUpsertHomeOfficeDocumentMutation } from "../../../../../../../../api/graphql/mutations/homeOfficeDocument/upsertHomeOfficeDocument.generated";
import {
  destroyMessage,
  showGraphQlErrorNotification,
  showLoadingMessage,
} from "../../../../../../../../utils";
import { Asset, ILocalAsset } from "../../../../../../../../types";
import Upload from "../../../../../../../common/Upload";
import { useCreateHomeOfficeDocumentAssetMutation } from "../../../../../../../../api/graphql/mutations/homeOfficeDocument/createHomeOfficeDocumentAsset.generated";
import { useFinalizeAssetUploadMutation } from "../../../../../../../../api/graphql";
import { uploadFile } from "../../../../../../../../api/modules/Common";
import LocalAssetsViewer, {
  isLocalAsset,
} from "../../../../../../../common/LocalAssetsViewer";
import { HomeOfficeDocumentType } from "../../../../../../../../api/graphql/schema.generated";
import { MAX_ASSET_UPLOAD_SIZE } from "../../../constants";
import { groupFilesBySize } from "../../../hooks/useEuerDeclarationSubformAssetsUploader";
import { SaveAssetResult } from "../../../../../types";
import { notifyUserAfterSubmittingSubform } from "../../../../../utils";
import { DocumentModalContent } from "./styledComponents";
import { HOME_OFFICE_DOCUMENT_TYPE_OPTIONS } from "./constants";

const LOADING_MESSAGE_KEY = "saving-home-office-Document";

export type HomeOfficeDocumentFormInputs = {
  assets?: Array<Asset | ILocalAsset>;
  id: string;
  note: string;
  type: HomeOfficeDocumentType;
};

export const UpsertHomeOfficeDocumentModal = ({
  email,
  taxYear,
  visible,
  onSuccess,
  onClose,
  initialValues,
}: {
  email: string;
  taxYear: number;
  visible: boolean;
  onSuccess: Function;
  onClose: Function;
  initialValues?: HomeOfficeDocumentFormInputs;
}) => {
  const [form] = Form.useForm<HomeOfficeDocumentFormInputs>();
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [assets, setAssets] = useState<Array<Asset | ILocalAsset>>([]);
  const [upsertHomeOfficeDocument] = useUpsertHomeOfficeDocumentMutation();
  const [createHomeOfficeDocumentAsset] =
    useCreateHomeOfficeDocumentAssetMutation();
  const [finalizeAssetUpload] = useFinalizeAssetUploadMutation();

  const handleReset = useCallback(() => {
    setAssets([]);
    form.resetFields();
  }, [form]);

  const handleCancel = () => {
    onClose();
    handleReset();
  };

  const clearForm = useCallback(() => {
    form.setFieldsValue({
      id: undefined,
      type: undefined,
      note: undefined,
      assets: undefined,
    });
    setAssets([]);
  }, [form]);

  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue(initialValues);
      initialValues.assets && setAssets(initialValues.assets);
    } else {
      clearForm();
    }
  }, [form, initialValues, clearForm]);

  const onAssetDelete = (deletedAsset: Asset | ILocalAsset) => {
    const updatedAssets = assets.filter(
      (asset) =>
        (asset as ILocalAsset).name !== (deletedAsset as ILocalAsset).name
    );
    setAssets(updatedAssets);
    form.setFieldsValue({
      assets: updatedAssets,
    });
  };

  const onDropFiles = useCallback(
    (files: File[]) => {
      const maxFileSize = 1024 * 1024 * MAX_ASSET_UPLOAD_SIZE;
      const { invalidFiles, validFiles } = groupFilesBySize(files, maxFileSize);

      if (invalidFiles.length > 0) {
        invalidFiles.forEach((file) => {
          notification.error({
            message: `File ${file.name} exceeding file size limit (${MAX_ASSET_UPLOAD_SIZE}MB).`,
          });
        });
      }

      const newAssets = validFiles.map((file) => ({
        name: file.name,
        fullsize: URL.createObjectURL(file),
        filetype: file.type.split("/").pop()!,
        file,
      }));
      const updatedAssets = [...assets, ...newAssets];

      form.setFieldsValue({
        assets: updatedAssets,
      });

      setAssets(updatedAssets);
    },
    [form, assets]
  );

  const uploadAsset = useCallback(
    async (
      homeOfficeDocumentId: string,
      { name, filetype, file }: ILocalAsset
    ): Promise<Asset> => {
      const createAssetResponse = await createHomeOfficeDocumentAsset({
        variables: {
          homeOfficeDocumentId,
          name,
          filetype,
        },
      });

      if (!createAssetResponse.data) {
        throw new Error("Failed to create home office Document asset");
      }

      const { assetId, formData, url } =
        createAssetResponse.data.createHomeOfficeDocumentAsset;

      const form = new FormData();
      formData.forEach(({ key, value }) => form.append(key, value));
      form.append("file", file);

      await uploadFile({ url, form });

      const finalizeAssetUploadResponse = await finalizeAssetUpload({
        variables: { assetId },
      });

      if (!finalizeAssetUploadResponse.data) {
        throw new Error("Failed to finalize asset upload");
      }

      return finalizeAssetUploadResponse.data.finalizeAssetUpload;
    },
    [createHomeOfficeDocumentAsset, finalizeAssetUpload]
  );

  const saveAssets = useCallback(
    async (
      euerDeclarationSubformId: string
    ): Promise<Array<SaveAssetResult>> => {
      const localAssets = assets.filter((asset) =>
        isLocalAsset(asset)
      ) as ILocalAsset[];

      const uploadAssets = localAssets.map((asset) =>
        uploadAsset(euerDeclarationSubformId, asset)
      );

      const uploadStatuses = await Promise.allSettled(uploadAssets);

      return uploadStatuses.map((promiseState, index) => ({
        ...promiseState,
        localAsset: localAssets[index],
      }));
    },
    [assets, uploadAsset]
  );

  const handleSubmit = useCallback(
    async (values: HomeOfficeDocumentFormInputs) => {
      setIsButtonLoading(true);
      delete values.assets;

      showLoadingMessage(LOADING_MESSAGE_KEY);
      let savingFailed = false;
      let assetResults: SaveAssetResult[] = [];

      try {
        const result = await upsertHomeOfficeDocument({
          variables: {
            email,
            year: taxYear,
            payload: {
              ...values,
              id: initialValues?.id,
              note: values.note ?? "",
            },
          },
        });

        assetResults = await saveAssets(
          result.data?.upsertHomeOfficeDocument.id!
        );

        await onSuccess();

        onClose();
        handleReset();
      } catch (error) {
        savingFailed = true;
        showGraphQlErrorNotification(`Create home office Document`, error);
      } finally {
        setIsButtonLoading(false);
        destroyMessage(LOADING_MESSAGE_KEY);
        notifyUserAfterSubmittingSubform(savingFailed, assetResults);
      }
    },
    [
      onClose,
      email,
      handleReset,
      initialValues,
      onSuccess,
      saveAssets,
      taxYear,
      upsertHomeOfficeDocument,
    ]
  );

  const footer = [
    <Button
      form="homeOfficeDocumentFormId"
      key="submit"
      htmlType="submit"
      type="default"
      loading={isButtonLoading}
    >
      Erstellen
    </Button>,
  ];

  return (
    <Modal
      title="Sonstigen Beleg für häusliches Arbeitzimmer hinzufügen"
      visible={visible}
      onCancel={handleCancel}
      footer={footer}
      centered
      width={1058}
    >
      <Form
        form={form}
        name="homeOfficeDocumentForm"
        id="homeOfficeDocumentFormId"
        onFinish={handleSubmit}
        autoComplete="off"
        layout="vertical"
        requiredMark={false}
        initialValues={initialValues}
      >
        <DocumentModalContent>
          <Form.Item
            name="assets"
            rules={[{ required: true, message: "Please upload a document" }]}
            className="assets"
          >
            <Upload onDropFiles={onDropFiles} isInPreviewMode={!!assets.length}>
              <LocalAssetsViewer
                assets={assets}
                minHeight={280}
                onDelete={onAssetDelete}
              />
            </Upload>
          </Form.Item>

          <div>
            <Form.Item
              label="Art"
              name="type"
              rules={[{ required: true, message: "Please select type" }]}
            >
              <Select options={HOME_OFFICE_DOCUMENT_TYPE_OPTIONS}></Select>
            </Form.Item>

            <Form.Item
              label="Beschreibung"
              name="note"
              rules={[{ required: true, message: "Please enter note" }]}
            >
              <Input placeholder="Verpflichtend" />
            </Form.Item>
          </div>
        </DocumentModalContent>
      </Form>
    </Modal>
  );
};
