import React, { useCallback, useMemo, useState } from "react";
import { Popconfirm, Table, Typography } from "antd";
import { CommentOutlined } from "@ant-design/icons";
import moment from "moment-timezone";
import { ColumnType } from "antd/lib/table";
import uniq from "lodash/uniq";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";

import {
  TaxServiceDepartment,
  TaxServiceDetails,
  TaxServiceName,
  TaxServiceStatus,
} from "../../../api/graphql/schema.generated";
import { formatDate } from "../TaxDeclaration/utils";
import TaxServicesTableExpandRow from "./TaxServicesTableExpandRow";
import EditableCell from "./EditableCell";
import { useDeleteTaxServiceMutation } from "../../../api/graphql/mutations/taxService/deleteTaxService.generated";
import { useUpsertTaxServiceMutation } from "../../../api/graphql/mutations/taxService/upsertTaxService.generated";

type EditableColumnType = ColumnType<TaxServiceDetails> & {
  editable?: boolean;
};

const getFullName = (data: TaxServiceDetails) => {
  const { firstName, lastName } = data.kontaxUser.kontistUser;
  return `${firstName} ${lastName}`;
};

const getEmail = (data: TaxServiceDetails) =>
  data.kontaxUser.kontistUser.email!;

const isServiceEditable = (record: TaxServiceDetails) => {
  return (
    record.status === TaxServiceStatus.BLOCKED ||
    record.status === TaxServiceStatus.WILL_BE_BILLED
  );
};
const getTexts = (t: TFunction<"ServiceTracker", undefined>) => ({
  columnLabel: (columnName: string) =>
    t(`serviceTracker.tableHeaders.${columnName}`),
  departmentName: (name: string) => t(`serviceTracker.departmentsName.${name}`),
  serviceName: (name: string) => t(`serviceTracker.servicesName.${name}`),
  status: (status: string) => t(`serviceTracker.statuses.${status}`),
});

const TaxServicesTable = ({
  taxServices,
  onRefresh,
}: {
  taxServices: TaxServiceDetails[] | undefined;
  onRefresh: () => void;
}) => {
  const [editKey, setEditKey] = useState<string | null>(null);
  const [deleteTaxServiceMutation] = useDeleteTaxServiceMutation();
  const [upsertTaxService] = useUpsertTaxServiceMutation();
  const [statusToChange, setStatusToChange] = useState<TaxServiceStatus | null>(
    null
  );

  const isEditing = useCallback((id: string) => editKey === id, [editKey]);

  const { t } = useTranslation("ServiceTracker");
  const texts = getTexts(t);
  const handleDeleteTaxService = useCallback(async () => {
    await deleteTaxServiceMutation({
      variables: { taxServiceId: editKey! },
    });
    onRefresh();
  }, [deleteTaxServiceMutation, editKey, onRefresh]);

  const handleChangingTaxServiceStatus = useCallback(
    async (record: TaxServiceDetails) => {
      await upsertTaxService({
        variables: {
          email: record.kontaxUser.kontistUser.email!,
          payload: {
            status: statusToChange!,
            id: record.id,
            department: record.department,
            name: record.name,
            externalNote: record.externalNote,
            internalNote: record.internalNote,
          },
        },
      });
      setStatusToChange(null);
      setEditKey(null);
      onRefresh();
    },
    [onRefresh, statusToChange, upsertTaxService]
  );

  const handleDelete = useCallback(() => {
    setStatusToChange(null);
    handleDeleteTaxService();
  }, [handleDeleteTaxService]);

  const handleCancel = () => {
    setStatusToChange(null);
    setEditKey(null);
  };

  const yearsFilter = useMemo(
    () =>
      uniq(taxServices?.map(({ year }) => (year ? `${year}` : "-"))).map(
        (year) => ({
          text: year,
          value: year,
        })
      ),
    [taxServices]
  );

  const serviceNamesFilter = useMemo(
    () =>
      uniq(taxServices?.map(({ name }) => name)).map((name) => ({
        text: texts.serviceName(name),
        value: name,
      })),
    [taxServices, texts]
  );

  const serviceDepartmentsFilter = useMemo(
    () =>
      uniq(taxServices?.map(({ department }) => department)).map(
        (department) => ({
          text: texts.departmentName(department),
          value: department,
        })
      ),
    [taxServices, texts]
  );

  const serviceStatusesFilter = useMemo(
    () =>
      uniq(taxServices?.map(({ status }) => status)).map((status) => ({
        text: texts.status(status),
        value: status,
      })),
    [taxServices, texts]
  );

  const createdByFilter = useMemo(
    () =>
      uniq(taxServices?.map(({ createdBy }) => createdBy)).map((createdBy) => ({
        text: createdBy.split("@")[0],
        value: createdBy,
      })),
    [taxServices]
  );

  const columns: EditableColumnType[] = useMemo(
    () => [
      {
        title: texts.columnLabel("date"),
        key: "createdAt",
        dataIndex: "createdAt",
        defaultSortOrder: "descend",
        render: (createdAt: Date) => {
          return formatDate(createdAt);
        },
        sorter: (a, b) => (moment(a.createdAt).isAfter(b.createdAt) ? 1 : -1),
      },
      {
        title: <CommentOutlined />,
        key: "internalNote",
        dataIndex: "internalNote",
        render: (internalNote: string) => {
          return internalNote.length ? <CommentOutlined /> : "";
        },
      },
      {
        title: texts.columnLabel("clientName"),
        render: (row: TaxServiceDetails) => getFullName(row),
        sorter: (a, b) => {
          const nameA = getFullName(a);
          const nameB = getFullName(b);
          return nameA.localeCompare(nameB);
        },
      },
      {
        title: texts.columnLabel("clientEmail"),
        render: (row: TaxServiceDetails) => getEmail(row),
        sorter: (a, b) => getEmail(a).localeCompare(getEmail(b)),
      },
      {
        title: texts.columnLabel("service"),
        key: "name",
        dataIndex: "name",
        render: (name: TaxServiceName) => texts.serviceName(name),
        sorter: (a, b) => a.name.localeCompare(b.name),
        filters: serviceNamesFilter,
        filterSearch: true,
        onFilter: (
          value: string | number | boolean,
          record: TaxServiceDetails
        ) => record.name === value,
      },
      {
        title: texts.columnLabel("hours"),
        key: "hours",
        dataIndex: "hours",
        render: (hours) => hours ?? "-",
        sorter: (a, b) => (a.hours ?? 0) - (b.hours ?? 0),
      },
      {
        title: texts.columnLabel("taxYear"),
        key: "year",
        dataIndex: "year",
        render: (year) => year ?? "-",
        sorter: (a, b) => (a.year ?? 0) - (b.year ?? 0),
        filters: yearsFilter,
        filterSearch: true,
        onFilter: (
          value: string | number | boolean,
          record: TaxServiceDetails
        ) => (record.year ?? "-").toString() === value.toString(),
      },
      {
        title: texts.columnLabel("operator"),
        key: "createdBy",
        dataIndex: "createdBy",
        render: (createdBy: string) => createdBy.split("@")[0],
        sorter: (a, b) => a.createdBy.localeCompare(b.createdBy),
        filters: createdByFilter,
        filterSearch: true,
        onFilter: (
          value: string | number | boolean,
          record: TaxServiceDetails
        ) => record.createdBy === value,
      },
      {
        title: texts.columnLabel("field"),
        key: "department",
        dataIndex: "department",
        render: (department: TaxServiceDepartment) =>
          texts.departmentName(department),
        sorter: (a, b) => a.department.localeCompare(b.department),
        filters: serviceDepartmentsFilter,
        filterSearch: true,
        onFilter: (
          value: string | number | boolean,
          record: TaxServiceDetails
        ) => record.department === value,
      },
      {
        title: texts.columnLabel("status"),
        key: "status",
        dataIndex: "status",
        render: (status: TaxServiceStatus) => texts.status(status),
        sorter: (a, b) => a.status.localeCompare(b.status),
        filters: serviceStatusesFilter,
        filterSearch: true,
        editable: true,
        onFilter: (
          value: string | number | boolean,
          record: TaxServiceDetails
        ) => record.status === value,
      },
      {
        title: texts.columnLabel("edit"),
        dataIndex: "edit",
        render: (_: any, record: TaxServiceDetails) => {
          return isEditing(record.id) ? (
            <span>
              <Typography.Link
                style={{ marginRight: 8 }}
                onClick={() => handleChangingTaxServiceStatus(record)}
              >
                Save
              </Typography.Link>
              <Popconfirm title="Sure to cancel?" onConfirm={handleCancel}>
                <a href="/#">Cancel</a>
              </Popconfirm>
              <Popconfirm title="Sure to delete?" onConfirm={handleDelete}>
                <a href="/#" style={{ marginLeft: 8 }}>
                  Delete
                </a>
              </Popconfirm>
            </span>
          ) : (
            <Typography.Link
              disabled={!isServiceEditable(record)}
              onClick={() => setEditKey(record.id)}
            >
              Edit
            </Typography.Link>
          );
        },
      },
    ],
    [
      texts,
      serviceNamesFilter,
      yearsFilter,
      createdByFilter,
      serviceDepartmentsFilter,
      serviceStatusesFilter,
      isEditing,
      handleDelete,
      handleChangingTaxServiceStatus,
    ]
  );

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: TaxServiceDetails) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: editKey === record.id,
        handleStatusChange: setStatusToChange,
      }),
    };
  });

  return (
    <Table
      columns={mergedColumns as EditableColumnType[]}
      dataSource={taxServices}
      pagination={{
        total: taxServices?.length,
        defaultPageSize: 10,
        pageSizeOptions: ["10", "20", "50"],
        showSizeChanger: true,
      }}
      rowKey="id"
      style={{ width: "100%" }}
      rowClassName="expandable"
      components={{
        body: {
          cell: EditableCell,
        },
      }}
      expandable={{
        expandedRowRender: (taxService: TaxServiceDetails) => {
          return (
            <TaxServicesTableExpandRow
              taxService={taxService}
              onRefresh={onRefresh}
            />
          );
        },
        defaultExpandAllRows: false,
      }}
    />
  );
};

export default TaxServicesTable;
