import styled from "styled-components";
import { Form, Button } from "antd";

import colors from "../../../../../../themes/colors";

type UploadContainerProps = {
  error: boolean;
};

const UPLOAD_FIELD_WIDTH = "472px";

export const UploadContainer = styled(Form.Item)<UploadContainerProps>`
  .ant-upload.ant-upload-drag {
    width: ${UPLOAD_FIELD_WIDTH};
    height: 180px;
    ${(props) => (props.error ? `border-color: ${colors.darkRed}` : "")}
  }

  .ant-upload-list-item {
    width: ${UPLOAD_FIELD_WIDTH};
    ${(props) => (props.error ? `color: ${colors.darkRed}` : "")}
  }

  .ant-upload-text-icon {
    display: flex;
  }
`;

export const StyledForm = styled(Form)`
  .ant-form-item {
    margin-bottom: 20px;
  }

  .ant-picker,
  .ant-select {
    width: 150px;
  }
`;

export const Container = styled.div`
  width: 50%;
  padding: 20px;
  margin: auto;
`;

export const TableContainer = styled.div`
  width: 100%;
`;

export const LoaderContainer = styled.div`
  width: 100%;
  display: flex;
  text-align: center;
  height: 620px;
  align-items: center;
  justify-content: center;
`;

export const TableWrapper = styled.div`
  .ant-table-cell {
    vertical-align: middle;
    max-width: 200px;

    .ant-select {
      width: 100%;
      max-width: auto;
    }

    .ant-tag {
      max-width: 100%;
      white-space: initial;
    }
  }

  .skr-match {
    background-color: ${colors.lightPurple};
  }

  .ant-table-column-title {
    white-space: nowrap;
  }

  .ant-table-column-sorter {
    margin-left: 10px;
  }
`;

export const AntIconTag = styled.div<{ inactive?: boolean }>`
  min-width: 90px;
  text-align: center;
  cursor: pointer;

  svg {
    display: inline;
    vertical-align: baseline;
  }

  ${({ inactive }) => inactive && "opacity: 0.5; cursor: not-allowed"}
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const NoReceipt = styled.p`
  text-align: center;
`;

export const TableOptionsContainer = styled.div`
  padding-top: 8px;
  padding-bottom: 24px;
  display: flex;
  justify-content: space-between;
`;

export const SearchContainer = styled.div`
  width: 400px;
`;

export const ButtonWithIcon = styled(Button)`
  display: flex;
  align-items: center;
`;

export const ImportButtonWrapper = styled.div`
  width: ${UPLOAD_FIELD_WIDTH};
  display: flex;
  justify-content: flex-end;
`;

export const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const FooterWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const CheckboxCell = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
`;
