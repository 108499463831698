import { Col } from "antd";
import styled from "styled-components";

export const InputWithIconWrapper = styled.div`
  display: flex;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
`;

export const StyledCol = styled(Col)`
  margin-top: -4px;
  margin-bottom: 5px;
`;

export const AutoValueWrapper = styled.span`
  cursor: pointer;
  display: flex;
  padding: 0 3px;
  input {
    cursor: pointer;
  }
`;

export const AutoValue = styled.span`
  flex-grow: 1;
  text-align: right;
`;
