import type * as Types from "../../schema.generated";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type SendAnschreibenMutationVariables = Types.Exact<{
  email: Types.Scalars["String"];
  id: Types.Scalars["String"];
}>;

export type SendAnschreibenMutation = {
  __typename?: "Mutation";
  sendAnschreiben: { __typename?: "MutationResult"; success: boolean };
};

export const SendAnschreibenDocument = gql`
  mutation sendAnschreiben($email: String!, $id: String!) {
    sendAnschreiben(email: $email, id: $id) {
      success
    }
  }
`;
export type SendAnschreibenMutationFn = Apollo.MutationFunction<
  SendAnschreibenMutation,
  SendAnschreibenMutationVariables
>;

/**
 * __useSendAnschreibenMutation__
 *
 * To run a mutation, you first call `useSendAnschreibenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendAnschreibenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendAnschreibenMutation, { data, loading, error }] = useSendAnschreibenMutation({
 *   variables: {
 *      email: // value for 'email'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSendAnschreibenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendAnschreibenMutation,
    SendAnschreibenMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SendAnschreibenMutation,
    SendAnschreibenMutationVariables
  >(SendAnschreibenDocument, options);
}
export type SendAnschreibenMutationHookResult = ReturnType<
  typeof useSendAnschreibenMutation
>;
export type SendAnschreibenMutationResult =
  Apollo.MutationResult<SendAnschreibenMutation>;
export type SendAnschreibenMutationOptions = Apollo.BaseMutationOptions<
  SendAnschreibenMutation,
  SendAnschreibenMutationVariables
>;
