import React, { Key, useState } from "react";
import { BasicDataNode, DataNode, EventDataNode } from "rc-tree/lib/interface";

import { Categories, Folders } from "../../../../../../types";
import { StyledTree } from "./styledComponent";

interface CustomDataNode extends BasicDataNode {
  url?: string;
}

interface DocumentTreeProps {
  categoryName: string;
  getTreeData: () => DataNode[];
  setSelectedDocumentIds?: (ids: string[], checked?: boolean) => void;
}

const DocumentTree = ({
  categoryName,
  getTreeData,
  setSelectedDocumentIds,
}: DocumentTreeProps) => {
  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([
    ...Object.keys(Categories),
    ...Object.keys(Folders),
  ]);

  const onSelectTreeNode = (
    _: Key[],
    info: {
      event: "select";
      selected: boolean;
      node: EventDataNode<CustomDataNode>;
      selectedNodes: CustomDataNode[];
      nativeEvent: MouseEvent;
    }
  ) => {
    const { key } = info.node;

    const newExpandedKeys = new Set(expandedKeys);
    if (newExpandedKeys.has(key)) newExpandedKeys.delete(key);
    else newExpandedKeys.add(key);

    setExpandedKeys(Array.from(newExpandedKeys));
  };

  const onExpand = (expandedKeys: Key[]) => setExpandedKeys(expandedKeys);

  const getIdKey = (key: string) => /\d/.test(key);

  return (
    <StyledTree
      checkable={!!setSelectedDocumentIds}
      onCheck={(values, e: any) => {
        if (!setSelectedDocumentIds) {
          return;
        }

        if (!e.checked) {
          // get unchecked ids from the subtree or from one field
          const uncheckedNodes = e.node.children
            ? e.node.children.map((node: any) => node.key).filter(getIdKey)
            : [e.node.key];
          setSelectedDocumentIds(uncheckedNodes, false);
        } else {
          setSelectedDocumentIds(
            e.checkedNodes.map((node: any) => node.key).filter(getIdKey)
          );
        }
      }}
      key={categoryName}
      data-test="documentUploadTree"
      showLine
      defaultExpandAll
      expandedKeys={expandedKeys}
      treeData={getTreeData()}
      onSelect={onSelectTreeNode}
      onExpand={onExpand}
    />
  );
};

export default DocumentTree;
