import { DownloadOutlined } from "@ant-design/icons";
import { Button } from "antd";
import styled from "styled-components";

import { ILocalAsset } from "../../../../../../types";
import LocalAssetsViewer from "../../../../../common/LocalAssetsViewer";
import { SpinnerBasic } from "../../../../../common/Spinner";
import { SpinnerContainer } from "../../../../FeedbackView/styledComponents";
import { EMLFileViewer } from "./EMLFilePreview";
import { TextFileViewer } from "./TextFilePreview";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const DocuwareDocumentPreview = ({
  asset,
  isLoading,
}: {
  asset: ILocalAsset;
  isLoading: boolean;
}) => {
  const renderContent = () => {
    if (!asset) {
      return null;
    }

    if (["txt"].includes(asset.filetype)) {
      return <TextFileViewer file={asset.file} />;
    }

    if (["eml"].includes(asset.filetype)) {
      return <EMLFileViewer file={asset.file} />;
    }

    if (["png", "gif", "bmp", "jpg", "pdf"].includes(asset.filetype)) {
      return (
        <LocalAssetsViewer assets={[asset]} minHeight={400} maxHeight={400} />
      );
    }

    return <p>No preview available</p>;
  };

  const onClick = () => {
    if (!asset) {
      return;
    }

    const url = URL.createObjectURL(asset.file);
    const element = document.createElement("a");
    element.href = url;
    element.setAttribute("download", asset.name);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  if (isLoading) {
    return (
      <Wrapper>
        <SpinnerContainer>
          <SpinnerBasic />
        </SpinnerContainer>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      {renderContent()}
      <Button onClick={onClick} icon={<DownloadOutlined />} size="large">
        Download
      </Button>
    </Wrapper>
  );
};

export default DocuwareDocumentPreview;
