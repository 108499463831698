import styled, { css } from "styled-components";
import { Row } from "antd";

import colors from "../../../../../themes/colors";

const baseFontStyle = css`
  font-weight: 500;
  line-height: 120%;
`;

export const UserDetails = styled.div`
  flex: 1 1 auto;
  ${baseFontStyle}
`;

export const UserName = styled.div`
  ${baseFontStyle}
  color: ${colors.veryDarkViolet};
`;

export const UserEmail = styled.div`
  ${baseFontStyle}
  color: ${colors.darkGrey};
  word-break: break-all;
`;

export const RowWithDivider = styled(Row)`
  border-bottom: 1px solid ${colors.lightGrey};
  padding: 20px 0 40px;
  margin-bottom: 30px;
`;
