import React, { useState, useEffect } from "react";
import { Space } from "antd";
import finanzamt from "finanzamt";

import denormalizeTaxNumber from "denormalize-steuernummer";

import { IUser } from "../../../../types";
import DrawerUserAttribute from "../DrawerUserAttribute";

import { Finanzamt } from "../../../../types/Finanzamt";
import { formatBooleanToString } from "../../../../utils";
import Copyable from "../../Copyable";

const TaxTemplate = ({ user }: { user: IUser | null }) => {
  const [taxOffice, setTaxOffice] = useState<Finanzamt>();

  useEffect(() => {
    if (user?.taxNumber) {
      const firstFourDigits = user.taxNumber.substring(0, 4);
      const taxOfficeDetails = finanzamt(firstFourDigits);
      setTaxOffice(taxOfficeDetails);
    } else {
      setTaxOffice(undefined);
    }
  }, [user?.taxNumber]);

  const displayFinanzamt = () => {
    return taxOffice ? (
      <>
        {taxOffice.name}
        {taxOffice.hausanschrift && (
          <p>
            {taxOffice.hausanschrift.strasse} {taxOffice.hausanschrift.hausNr}
            {", "}
            {taxOffice.hausanschrift.plz} {taxOffice.hausanschrift.ort}
          </p>
        )}
      </>
    ) : (
      <span>-</span>
    );
  };

  const fullName =
    user?.firstName && user?.lastName
      ? user.firstName + " " + user.lastName
      : null;

  const taxNumber = user && denormalizeTaxNumber(user.taxNumber!)?.taxNumber;

  return (
    user && (
      <Space direction="vertical" size="large">
        <DrawerUserAttribute label="Email">{user.email}</DrawerUserAttribute>
        <DrawerUserAttribute label="Name">
          {fullName ? (
            <Copyable withFixedIcon text={fullName}>
              {fullName}
            </Copyable>
          ) : (
            "-"
          )}
        </DrawerUserAttribute>
        <DrawerUserAttribute label="Art des Betriebs">
          {user.kontaxUser?.businessType || "-"}
        </DrawerUserAttribute>
        <DrawerUserAttribute label="Contact">
          {user.mobileNumber ? <Copyable>{user.mobileNumber}</Copyable> : "-"}
        </DrawerUserAttribute>
        <DrawerUserAttribute label="Language">
          {user.language || "-"}
        </DrawerUserAttribute>
        <DrawerUserAttribute label="DATEV ID">
          {user.accountId || "-"}
        </DrawerUserAttribute>
        <DrawerUserAttribute label="Steuernummer">
          {taxNumber ? <Copyable withFixedIcon>{taxNumber}</Copyable> : "-"}
        </DrawerUserAttribute>
        <DrawerUserAttribute label="Finanzamt">
          {displayFinanzamt()}
        </DrawerUserAttribute>
        <DrawerUserAttribute label="Trade Tax">
          {user?.kontaxUser?.tradeTax || "-"}
        </DrawerUserAttribute>
        <DrawerUserAttribute label="Steuerfreie Umsätze">
          {user.vatExemptionWithItd || user.vatExemptionWithoutItd || "-"}
        </DrawerUserAttribute>
        <DrawerUserAttribute label="Mehrfach selbständig ">
          {formatBooleanToString(user.hasMoreThanOneBusiness) || "-"}
        </DrawerUserAttribute>
        <DrawerUserAttribute label="Dauer der Unternehmereigenschaft">
          {user.kontaxUser?.businessStartDate} -{" "}
          {user.kontaxUser?.businessEndDate}
        </DrawerUserAttribute>
        <DrawerUserAttribute label="Erste Steuererklärung">
          {user.firstTaxDeclarationYear || "-"}
        </DrawerUserAttribute>
        <DrawerUserAttribute label="Letzte Steuererklärung">
          {user.lastTaxDeclarationYear || "-"}
        </DrawerUserAttribute>
        <DrawerUserAttribute label="In DATEV angelegt">
          {formatBooleanToString(user.kontaxUser?.createdInDatev) || "-"}
        </DrawerUserAttribute>
        <DrawerUserAttribute label="Mehrfach selbständig">
          {formatBooleanToString(user.hasMoreThanOneBusiness) || "-"}
        </DrawerUserAttribute>
      </Space>
    )
  );
};

export default TaxTemplate;
