import type * as Types from "../../schema.generated";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type DeleteAnnotationMutationVariables = Types.Exact<{
  id: Types.Scalars["ID"];
}>;

export type DeleteAnnotationMutation = {
  __typename?: "Mutation";
  deleteAnnotation: { __typename?: "MutationResult"; success: boolean };
};

export const DeleteAnnotationDocument = gql`
  mutation deleteAnnotation($id: ID!) {
    deleteAnnotation(id: $id) {
      success
    }
  }
`;
export type DeleteAnnotationMutationFn = Apollo.MutationFunction<
  DeleteAnnotationMutation,
  DeleteAnnotationMutationVariables
>;

/**
 * __useDeleteAnnotationMutation__
 *
 * To run a mutation, you first call `useDeleteAnnotationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAnnotationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAnnotationMutation, { data, loading, error }] = useDeleteAnnotationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAnnotationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteAnnotationMutation,
    DeleteAnnotationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteAnnotationMutation,
    DeleteAnnotationMutationVariables
  >(DeleteAnnotationDocument, options);
}
export type DeleteAnnotationMutationHookResult = ReturnType<
  typeof useDeleteAnnotationMutation
>;
export type DeleteAnnotationMutationResult =
  Apollo.MutationResult<DeleteAnnotationMutation>;
export type DeleteAnnotationMutationOptions = Apollo.BaseMutationOptions<
  DeleteAnnotationMutation,
  DeleteAnnotationMutationVariables
>;
