import { TypeOfProfit } from "../types";

export const formatTypeOfProfit = (typeOfProfit?: TypeOfProfit | string) => {
  switch (typeOfProfit) {
    case TypeOfProfit.NA:
      return "N/A";
    case TypeOfProfit.EUR:
      return "EÜR";
    case TypeOfProfit.BALANCE:
      return "Bilanz";
    default:
      return "";
  }
};
