import { gql, QueryHookOptions, useQuery } from "@apollo/client";

import { DeclarationDeclineFragmentDoc } from "../../fragments/declarationDecline.generated";
import { NoteDataFragmentDoc } from "../../fragments/note.generated";
import { TradeTaxDeclaration } from "../../types";

export interface TradeTaxDeclarationQueryVariables {
  email: string;
  year: number;
}

export interface TradeTaxDeclarationQueryResult {
  tradeTaxDeclaration: TradeTaxDeclaration;
}

export const TRADE_TAX_DECLARATION_QUERY = gql`
  query tradeTaxDeclaration($email: String!, $year: Int!) {
    tradeTaxDeclaration(email: $email, year: $year) {
      id
      status
      statusUpdatedAt
      autoCalculatedValues {
        profit
        additions {
          feesForDebts
          movableBusinessAssetsRentOrLeaseInterest
          immovableBusinessAssetsRentOrLeaseInterest
          softwareAndLicenses
          totalNetAmount
          totalDeductibleAmount
        }
        calculations {
          profit
          deductedAdditionalAmountsMinus200kAllowance
          allowance
          tradeTaxAssessmentBasis
          tradeTaxRateFederal
          taxBaseAmountFederal
          tradeTaxRateLocal
          tradeTaxAmount
          taxOfficeBalance
          finalResult
        }
      }
      hasMultipleMunicipalities
      municipality
      municipalityTaxRate
      lastStatusChange {
        status
        changedAt
        changedBy
      }
      submissionInfo {
        submissionAgent
        submissionDate
        externalAssets {
          url
        }
      }
      submissions {
        submissionAgent
        submissionDate
        pdf
        calculationSheet
      }
      savedDraftInfo {
        createdBy
        createdAt
        updatedAt
        externalAssets {
          url
          createdAt
        }
      }
      changeLogs {
        changes
        changedBy
        changedAt
      }
      notes {
        ...noteData
      }
      declarationDeclines {
        ...DeclarationDecline
      }
    }
  }
  ${NoteDataFragmentDoc}
  ${DeclarationDeclineFragmentDoc}
`;

export const useTradeTaxDeclarationQuery = (
  options?: QueryHookOptions<
    TradeTaxDeclarationQueryResult,
    TradeTaxDeclarationQueryVariables
  >
) =>
  useQuery<TradeTaxDeclarationQueryResult, TradeTaxDeclarationQueryVariables>(
    TRADE_TAX_DECLARATION_QUERY,
    options
  );
