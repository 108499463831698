import React from "react";
import { Col, Typography, Divider } from "antd";

import {
  VAT_ANNUAL_DECLARATION_FIELD_TYPE_CODES,
  VAT_ANNUAL_DECLARATION_FIELD_TYPE_LABELS,
  VAT_ANNUAL_DECLARATION_STATUS_MAPPINGS,
  VAT_ANNUAL_DECLARATION_STATUSES,
} from "./constants";
import {
  VatAnnualDeclaration,
  VatAnnualDeclarationAutoCalculatedValues,
  VatAnnualDeclarationFieldType,
} from "../../../../../api/graphql";
import {
  VatAnnualDeclarationSmallBusinessValues,
  VatAnnualDeclarationStatus,
} from "../../../../../api/graphql/schema.generated";
import DeclarationStatusChangeModal from "../../components/DeclarationStatusChangeModal";
import useTaxYearParam from "../../hooks/useTaxYearParam";
import { formatAmountInCents } from "../../../../../utils";
import {
  calculateVatAnnualDeclarationGrandTotal,
  calculateVatAnnualDeclarationSmallBusinessGrandTotal,
  getVatAnnualDeclarationGrandTotalDisplayData,
} from "./utils";

const { Text } = Typography;
const { ListRow, Total } = DeclarationStatusChangeModal;

export interface VatAnnualDeclarationStatusChangeModalProps {
  status: VatAnnualDeclarationStatus;
  isSmallBusiness: boolean;
  smallBusinessValues: VatAnnualDeclarationSmallBusinessValues | null;
  autoCalculatedValues: VatAnnualDeclarationAutoCalculatedValues | null;
  declaration: VatAnnualDeclaration;
  onSave: (status: VatAnnualDeclarationStatus) => Promise<void>;
  onClose: () => void;
}

const VatAnnualDeclarationStatusChangeModal = ({
  status,
  isSmallBusiness,
  smallBusinessValues,
  autoCalculatedValues,
  declaration: {
    lastYearIncomeAdjustment,
    currentYearIncomeAdjustment,
    taxOfficeBalance,
  },
  onSave,
  onClose,
}: VatAnnualDeclarationStatusChangeModalProps) => {
  const [taxYear] = useTaxYearParam();
  const grandTotal = isSmallBusiness
    ? calculateVatAnnualDeclarationSmallBusinessGrandTotal(
        smallBusinessValues,
        taxOfficeBalance
      )
    : calculateVatAnnualDeclarationGrandTotal(
        autoCalculatedValues,
        taxOfficeBalance
      );
  const {
    label: grandTotalLabel,
    color: grandTotalColor,
    displayAmount: grandTotalDisplayAmount,
  } = getVatAnnualDeclarationGrandTotalDisplayData(grandTotal);

  const reverseCharge = smallBusinessValues?.salesTax.fields.find(
    (f) => f.type === VatAnnualDeclarationFieldType.REVERSE_CHARGE
  )!;

  return (
    <DeclarationStatusChangeModal
      title="USt-Erklärung"
      subtitle={isSmallBusiness ? "Kleinunternehmer" : "Regelbesteuerung"}
      year={taxYear}
      status={status}
      statusOptions={VAT_ANNUAL_DECLARATION_STATUSES}
      statusMapping={VAT_ANNUAL_DECLARATION_STATUS_MAPPINGS}
      onSave={onSave}
      onClose={onClose}
    >
      {isSmallBusiness
        ? smallBusinessValues && (
            <>
              <ListRow>
                <Col flex="1">{`Summe Umsätze Kalenderjahr ${
                  taxYear - 1
                }`}</Col>
                <Col>
                  {formatAmountInCents(
                    smallBusinessValues.lastYearIncome +
                      (lastYearIncomeAdjustment || 0),
                    true
                  )}
                </Col>
              </ListRow>

              <ListRow>
                <Col flex="1">{`Summe Umsätze Kalenderjahr ${taxYear}`}</Col>
                <Col>
                  {formatAmountInCents(
                    smallBusinessValues.currentYearIncome +
                      (currentYearIncomeAdjustment || 0),
                    true
                  )}
                </Col>
              </ListRow>

              <Divider className="m-0" />

              <ListRow>
                <Col>
                  <Text strong>Abschlussrechnung</Text>
                </Col>
              </ListRow>

              <ListRow>
                <Col flex="1">{`[${
                  VAT_ANNUAL_DECLARATION_FIELD_TYPE_CODES[reverseCharge.type]
                }] ${
                  VAT_ANNUAL_DECLARATION_FIELD_TYPE_LABELS[reverseCharge.type]
                } ${taxYear}`}</Col>
                <Col>
                  {formatAmountInCents(reverseCharge.vatAmount || 0, true)}
                </Col>
              </ListRow>
            </>
          )
        : autoCalculatedValues && (
            <>
              <ListRow>
                <Col>
                  <Text strong>Abschlussrechnung</Text>
                </Col>
              </ListRow>

              <ListRow>
                <Col flex="1">Summe Steuerpflichtige Umsätze</Col>
                <Col>
                  {formatAmountInCents(
                    autoCalculatedValues.salesTax.total || 0,
                    true
                  )}
                </Col>
              </ListRow>

              <ListRow>
                <Col flex="1">Summe Abziehbare Vorsteuerbeträge</Col>
                <Col>
                  {formatAmountInCents(
                    autoCalculatedValues.deductibleInputTax.total || 0,
                    true
                  )}
                </Col>
              </ListRow>
            </>
          )}

      <ListRow>
        <Col flex="1">Vorauszahlungssoll</Col>
        <Col>
          {taxOfficeBalance == null
            ? "-"
            : formatAmountInCents(taxOfficeBalance, true)}
        </Col>
      </ListRow>

      <Divider className="m-0" />

      <ListRow>
        <Col flex="1">
          <Total>{grandTotalLabel}</Total>
        </Col>
        <Col>
          <Text strong style={{ color: grandTotalColor }}>
            {formatAmountInCents(grandTotalDisplayAmount, true)}
          </Text>
        </Col>
      </ListRow>
    </DeclarationStatusChangeModal>
  );
};

export default VatAnnualDeclarationStatusChangeModal;
