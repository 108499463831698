import React from "react";
import { Typography } from "antd";

import { formatAmountCurrency } from "../../../../../../../../utils";

const { Text } = Typography;

const EuerProfit = ({ profit }: { profit: number }) => {
  return (
    <>
      <Text strong>Steuerpflichtiger Gewinn/Verlust</Text>
      <div>{formatAmountCurrency(profit)}</div>
    </>
  );
};

export default EuerProfit;
