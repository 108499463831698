import { Input, InputRef, Row } from "antd";
import React, { useRef, useState } from "react";
import styled from "styled-components";

import FilterBoxCard from "../../common/Card/FilterBoxCard";
import useSearchHotkey from "../../hooks/useSearchHotkey";
import UserInfo from "../../common/UserInfo";
import { UserInfoDrawerSources } from "../../common/UserInfoExcerpt/UserInfoDrawer";

const HeaderWrapper = styled.div`
  margin-bottom: 35px;
`;

const { Search } = Input;

const Header = ({ setEmail, email }: { setEmail: Function; email: string }) => {
  const [emailInput, setEmailInput] = useState<string>(email);
  const searchRef = useRef<InputRef>(null);
  useSearchHotkey(searchRef);
  return (
    <Row>
      <HeaderWrapper>
        <FilterBoxCard title="Email">
          <Search
            ref={searchRef}
            type="email"
            style={{ width: "280px" }}
            value={emailInput}
            onChange={(event) => {
              setEmailInput(event.target.value);
            }}
            onSearch={(email) => {
              setEmail(email);
            }}
            id="email"
            placeholder="Enter Email"
            allowClear
            enterButton="Filter"
          />
        </FilterBoxCard>
      </HeaderWrapper>
      <UserInfo source={UserInfoDrawerSources.ACCOUNTING} />
    </Row>
  );
};

export default Header;
