import {
  Button,
  Col,
  Divider,
  Form,
  FormInstance,
  Row,
  Space,
  Typography,
} from "antd";
import React, { useCallback, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  calculateTravelExpensesSubformResults,
  EuerDeclarationSubformType,
  EuerDeclarationTravelExpensesSubformCalculationResults,
  EuerDeclarationTravelExpensesSubformInputs,
} from "@kontist/euer-declaration";
import defaults from "lodash/defaults";
import isEqual from "lodash/isEqual";

import { UserOutlined } from "@ant-design/icons";

import useTaxYearParam from "../../../../hooks/useTaxYearParam";
import {
  EuerCurrencyField,
  EuerNumericField,
} from "../../components/EuerFormFields";
import BackButton from "../../components/BackButton";
import DeclarationStatus from "../../../../components/DeclarationStatus";
import {
  ChangeLog,
  UpdateEuerDeclarationSubformInput,
  useUpdateEuerDeclarationSubformMutation,
} from "../../../../../../../api/graphql";
import useEmailParam from "../../../../../../hooks/useEmailParam";
import {
  centsToEuros,
  destroyMessage,
  eurosToCents,
  formatAmountInCents,
  showErrorMessage,
  showSuccessMessage,
  showLoadingMessage,
  currencyFormatter,
  formatAmountToCurrency,
} from "../../../../../../../utils";
import colors from "../../../../../../../themes/colors";
import {
  StyledForm,
  StyledFieldset,
  InputWithAutoWrapper,
  AutoWrapper,
} from "./styledComponents";
import LabelWithTag from "../../components/LabelWithTag";
import { EuerDeclarationSubformStatus } from "../../../../types";
import TravelExpensesStatusChangeModal from "./TravelExpensesStatusChangeModal";
import { SUBFORM_STATUS_MAPPINGS } from "../../constants";
import WarnLeaveWithoutSaving from "../../../../../../common/WarnLeaveWithoutSaving";
import ActionLogDrawer from "../../../../../../common/ActionLogDrawer";
import { useEuerDeclarationSubformQuery } from "../../../../../../../api/graphql/queries/euerDeclarationSubform.generated";
import NotesDrawer from "../../../../../../common/NotesDrawer";
import { ActionsContainer } from "../../../../styles";
import { KontaxNoteType } from "../../../../../../../api/graphql/schema.generated";

const { Text, Title } = Typography;

const LOADING_MESSAGE_KEY = "saving-travel-expenses-subform";

export const QUESTIONNAIRE_SYNC_SUPPORTED_AFTER = 2020;

type QuestionnaireValuesType = {
  privateCarTravelDistance?: number;
  additionalMealExpenses?: number;
  commuteDistance?: number;
  commuteDays?: number;
};

const INITIAL_CALCULATION_RESULTS: EuerDeclarationTravelExpensesSubformCalculationResults =
  {
    privateCarUsageContribution: 0, // [147] Fahrten für nicht zum Betriebsvermögen gehörende Fahrzeuge (Nutzungseinlage)
    additionalMealExpenses: 0, // [171] Verpflegungsmehraufwand
    minDeductibleTravelExpenses: 0, // [176] Min. abziehbare Fahrtkosten zw Whg/Betrieb
    profitReduction: 0, // Gewinnminderung
  };

const inputsAreValid = (inputs: EuerDeclarationTravelExpensesSubformInputs) =>
  Object.values(inputs).every((value) => value >= 0);

const getInputsFromForm = (
  form: FormInstance<EuerDeclarationTravelExpensesSubformInputs>
): EuerDeclarationTravelExpensesSubformInputs => {
  const formFieldValues = form.getFieldsValue(true);
  return {
    commuteDistance: formFieldValues.commuteDistance,
    commuteDays: formFieldValues.commuteDays,
    privateCarTravelDistance: formFieldValues.privateCarTravelDistance,
    additionalMealExpenses: eurosToCents(
      formFieldValues.additionalMealExpenses
    ),
  };
};

const TravelExpenses = () => {
  const [taxYear] = useTaxYearParam();
  const [email] = useEmailParam();
  const location = useLocation();
  const [form] = Form.useForm<EuerDeclarationTravelExpensesSubformInputs>();
  const [calculationResults, setCalculationResults] =
    useState<EuerDeclarationTravelExpensesSubformCalculationResults>(
      INITIAL_CALCULATION_RESULTS
    );
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [initialFormValues, setInitialFormValues] = useState({});
  const [questionnaireValues, setQuestionnaireValues] =
    useState<QuestionnaireValuesType>({});

  const [isStatusChangeModalVisible, setIsStatusChangeModalVisible] =
    useState(false);

  const [isCommuteFieldsDisable, setIsCommuteFieldsDisable] = useState(true);

  const isFieldEqualToQuestionnaireValue = useCallback(
    (fieldName: keyof EuerDeclarationTravelExpensesSubformInputs) =>
      form.getFieldValue(fieldName) ===
      questionnaireValues[fieldName as keyof typeof initialFormValues],
    [form, questionnaireValues]
  );

  const currentFormValues = form.getFieldsValue(true);
  const isDirty = useMemo(() => {
    return !isEqual(
      { ...currentFormValues, ...calculationResults },
      initialFormValues
    );
  }, [currentFormValues, calculationResults, initialFormValues]);

  const showStatusChangeModal = useCallback(() => {
    setIsStatusChangeModalVisible(true);
  }, []);

  const handleStatusChangeModalClose = useCallback(() => {
    setIsStatusChangeModalVisible(false);
  }, []);

  const { data: queryResult, refetch: refetchSubform } =
    useEuerDeclarationSubformQuery({
      skip: !email,
      variables: {
        email: email!,
        year: taxYear,
        type: EuerDeclarationSubformType.TRAVEL_EXPENSES,
      },
      // Allow onCompleted to be triggered on refetch.
      notifyOnNetworkStatusChange: true,
      onCompleted: ({ euerDeclarationSubform }) => {
        if (!euerDeclarationSubform) return;

        const {
          inputs,
          calculationResults,
          questionnaireValues: savedQuestionnaireValues,
          correspondingSubformMinDeductibleTravelExpenses,
        } = euerDeclarationSubform;
        const travelExpensesInputs =
          inputs as unknown as EuerDeclarationTravelExpensesSubformInputs;

        setIsCommuteFieldsDisable(
          !!correspondingSubformMinDeductibleTravelExpenses
        );

        const questionnaireValues: QuestionnaireValuesType = {
          ...savedQuestionnaireValues,
          ...(savedQuestionnaireValues?.additionalMealExpenses != null
            ? {
                additionalMealExpenses: centsToEuros(
                  savedQuestionnaireValues.additionalMealExpenses as number
                ),
              }
            : {}),
        };
        setQuestionnaireValues(questionnaireValues);

        const commuteDistance =
          travelExpensesInputs?.commuteDistance ??
          questionnaireValues?.commuteDistance ??
          0;
        const commuteDays =
          travelExpensesInputs?.commuteDays ??
          questionnaireValues?.commuteDays ??
          0;

        const privateCarTravelDistance =
          travelExpensesInputs?.privateCarTravelDistance ??
          questionnaireValues?.privateCarTravelDistance ??
          0;
        const additionalMealExpenses =
          (travelExpensesInputs?.additionalMealExpenses &&
            centsToEuros(travelExpensesInputs.additionalMealExpenses)) ??
          questionnaireValues?.additionalMealExpenses ??
          0;

        const formFieldValues = {
          commuteDistance,
          commuteDays,
          privateCarTravelDistance,
          additionalMealExpenses,
        };
        form.setFieldsValue(formFieldValues);

        if (Object.entries(calculationResults).length !== 0) {
          const initialCalculationResults = defaults(
            { ...calculationResults },
            INITIAL_CALCULATION_RESULTS
          );
          setCalculationResults(initialCalculationResults);
          setInitialFormValues({
            ...formFieldValues,
            ...initialCalculationResults,
          });
        } else {
          const calculationResults = calculateTravelExpensesSubformResults(
            {
              commuteDistance: formFieldValues.commuteDistance,
              commuteDays: formFieldValues.commuteDays,
              additionalMealExpenses: eurosToCents(
                formFieldValues.additionalMealExpenses
              ),
              privateCarTravelDistance:
                formFieldValues.privateCarTravelDistance,
            },
            taxYear
          );
          setCalculationResults(calculationResults);
          setInitialFormValues({
            ...formFieldValues,
            ...calculationResults,
          });
        }
      },
    });
  const subform = queryResult?.euerDeclarationSubform;

  const [updateEuerDeclarationSubform] =
    useUpdateEuerDeclarationSubformMutation();

  const calculateResults = useCallback(() => {
    const inputs = getInputsFromForm(form);
    const result = inputsAreValid(inputs)
      ? calculateTravelExpensesSubformResults(inputs, taxYear)
      : INITIAL_CALCULATION_RESULTS;

    setCalculationResults(result);
  }, [form, taxYear]);

  const saveSubform = useCallback(
    async (status?: EuerDeclarationSubformStatus) => {
      if (email && subform) {
        setIsSaving(true);
        showLoadingMessage(LOADING_MESSAGE_KEY);
        let savingFailed = false;
        try {
          const inputs = getInputsFromForm(form);
          const payload: UpdateEuerDeclarationSubformInput = {
            calculationMethod: subform.calculationMethod,
            inputs,
            calculationResults,
            status: status || subform.status,
          };
          await updateEuerDeclarationSubform({
            variables: {
              email,
              year: taxYear,
              type: EuerDeclarationSubformType.TRAVEL_EXPENSES,
              payload,
            },
          });
          await refetchSubform();
        } catch (error) {
          savingFailed = true;
        } finally {
          destroyMessage(LOADING_MESSAGE_KEY);
          setIsSaving(false);
          if (!savingFailed) {
            showSuccessMessage("Successfully saved");
          } else {
            showErrorMessage("There was a problem submitting the form");
          }
        }
      }
    },
    [
      email,
      subform,
      form,
      calculationResults,
      updateEuerDeclarationSubform,
      taxYear,
      refetchSubform,
    ]
  );

  const onSaveClick = useCallback(() => {
    saveSubform();
  }, [saveSubform]);

  const handleAutoButton = useCallback(
    (field: keyof QuestionnaireValuesType) => {
      form.setFieldsValue({
        [field]: questionnaireValues[field]!,
      });
      calculateResults();
    },
    [form, questionnaireValues, calculateResults]
  );

  const shouldShowAutoField = useCallback(
    (field: keyof QuestionnaireValuesType) => {
      return (
        taxYear > QUESTIONNAIRE_SYNC_SUPPORTED_AFTER &&
        questionnaireValues &&
        questionnaireValues[field] != null &&
        !isFieldEqualToQuestionnaireValue(field)
      );
    },
    [questionnaireValues, taxYear, isFieldEqualToQuestionnaireValue]
  );

  const handleAutoField = useCallback(
    (
      field: keyof QuestionnaireValuesType,
      {
        formatValue,
        style,
      }: {
        formatValue?: (value: any) => string;
        style?: React.CSSProperties;
      } = {}
    ) => {
      const value = questionnaireValues?.[field];
      return shouldShowAutoField(field) ? (
        <AutoWrapper
          onClick={() => {
            handleAutoButton(field);
          }}
          style={style}
        >
          <UserOutlined />
          <span>{formatValue ? formatValue(value) : value}</span>
        </AutoWrapper>
      ) : null;
    },
    [shouldShowAutoField, questionnaireValues, handleAutoButton]
  );

  if (!subform) {
    return null;
  }

  return (
    <>
      <WarnLeaveWithoutSaving warn={isDirty} />
      <BackButton
        text={`Anlage EÜR ${taxYear}`}
        to={{
          pathname: "/tax-declaration/euer-declaration",
          search: location.search,
        }}
      />
      <ActionsContainer>
        <ActionLogDrawer
          withIcon
          title="Action log"
          changeLogs={subform.changeLogs as ChangeLog[]}
        />
        <NotesDrawer
          notes={subform.notes}
          title="Verpflegungsmehraufwand & KFZ-Pauschalen"
          type={KontaxNoteType.EUER_DECLARATION_SUBFORM}
          recordId={subform.id}
        />
      </ActionsContainer>
      <Title
        level={3}
      >{`Verpflegungsmehraufwand & KFZ-Pauschalen ${taxYear}`}</Title>
      <Row gutter={16} style={{ marginBottom: 24 }}>
        <Col span={12}>
          <DeclarationStatus
            mapping={SUBFORM_STATUS_MAPPINGS}
            status={subform.status}
            statusUpdatedAt={subform.statusUpdatedAt}
            changedBy={subform.lastStatusChange?.changedBy}
            onClick={showStatusChangeModal}
          />
        </Col>
      </Row>
      <Divider />
      <Row justify="end" style={{ marginBottom: 24 }}>
        <Col>
          <Space>
            <Button onClick={showStatusChangeModal}>Status ändern</Button>
            <Button onClick={onSaveClick} type="primary" disabled={isSaving}>
              Speichern
            </Button>
          </Space>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <StyledForm
            labelAlign="left"
            colon={false}
            labelCol={{ span: 14 }}
            wrapperCol={{ span: 10 }}
            form={form}
            onValuesChange={calculateResults}
          >
            <StyledFieldset>
              <legend>
                Mindestens abziehbare Fahrtkosten für Wege zwischen Wohnung und
                erster Betriebsstätte (Entfernungspauschale)
              </legend>
              <InputWithAutoWrapper>
                <EuerNumericField
                  name="commuteDistance"
                  prefix={
                    isFieldEqualToQuestionnaireValue("commuteDistance") ? (
                      <UserOutlined />
                    ) : (
                      <span />
                    )
                  }
                  label="Einfache Entfernung km Whg/Betrieb"
                  addonAfter="km"
                  disabled={isCommuteFieldsDisable}
                />
                {handleAutoField("commuteDistance", {
                  style: { paddingRight: 54 },
                })}
              </InputWithAutoWrapper>
              <InputWithAutoWrapper>
                <EuerNumericField
                  name="commuteDays"
                  prefix={
                    isFieldEqualToQuestionnaireValue("commuteDays") ? (
                      <UserOutlined />
                    ) : (
                      <span />
                    )
                  }
                  label="Tage Fahrten Whg/Betrieb"
                  addonAfter="Tage"
                  disabled={isCommuteFieldsDisable}
                />
                {handleAutoField("commuteDays", {
                  style: { paddingRight: 65 },
                })}
              </InputWithAutoWrapper>
            </StyledFieldset>
            <StyledFieldset>
              <legend>
                Betriebliche Nutzung des privaten KFZ (Nutzungseinlage)
              </legend>
              <InputWithAutoWrapper>
                <EuerNumericField
                  name="privateCarTravelDistance"
                  prefix={
                    isFieldEqualToQuestionnaireValue(
                      "privateCarTravelDistance"
                    ) ? (
                      <UserOutlined />
                    ) : (
                      <span />
                    )
                  }
                  label="Gefahrene Kilometer"
                  addonAfter="km"
                />
                {handleAutoField("privateCarTravelDistance", {
                  style: { paddingRight: 54 },
                })}
              </InputWithAutoWrapper>
            </StyledFieldset>
            <StyledFieldset>
              <legend>Verpflegungsmehraufwand</legend>
              <InputWithAutoWrapper>
                <EuerCurrencyField
                  name="additionalMealExpenses"
                  prefix={
                    isFieldEqualToQuestionnaireValue(
                      "additionalMealExpenses"
                    ) ? (
                      <UserOutlined />
                    ) : (
                      <span />
                    )
                  }
                  label="Summe Pauschbeträge"
                />
                {handleAutoField("additionalMealExpenses", {
                  formatValue: (value) =>
                    formatAmountToCurrency(currencyFormatter.format(value)),
                  style: { paddingRight: 12 },
                })}
              </InputWithAutoWrapper>
            </StyledFieldset>
            <fieldset>
              <legend>Ergebnisse für EÜR</legend>
              <Form.Item
                label={
                  <LabelWithTag
                    tag="147"
                    label="Fahrten für nicht zum Betriebsvermögen gehörende Fahrzeuge (Nutzungseinlage)"
                  />
                }
                style={{ textAlign: "right" }}
              >
                <Text className="ant-form-text">
                  {formatAmountInCents(
                    calculationResults.privateCarUsageContribution,
                    true
                  )}
                </Text>
              </Form.Item>
              <Form.Item
                label={
                  <LabelWithTag tag="171" label="Verpflegungsmehraufwand" />
                }
                style={{ textAlign: "right" }}
              >
                <Text className="ant-form-text">
                  {formatAmountInCents(
                    calculationResults.additionalMealExpenses,
                    true
                  )}
                </Text>
              </Form.Item>
              <Form.Item
                label={
                  <LabelWithTag
                    tag="176"
                    label="Min. abziehbare Fahrtkosten zw Whg/Betrieb"
                  />
                }
                style={{ textAlign: "right" }}
              >
                <Text className="ant-form-text">
                  {formatAmountInCents(
                    calculationResults.minDeductibleTravelExpenses,
                    true
                  )}
                </Text>
              </Form.Item>
              <Divider />
              <Form.Item label="Gewinnminderung" style={{ textAlign: "right" }}>
                <Text
                  className="ant-form-text"
                  style={{
                    color:
                      calculationResults.profitReduction > 0
                        ? colors.darkRed
                        : colors.darkGrey,
                  }}
                >
                  {formatAmountInCents(
                    calculationResults.profitReduction || 0,
                    true
                  )}
                </Text>
              </Form.Item>
            </fieldset>
          </StyledForm>
        </Col>
      </Row>

      {subform && isStatusChangeModalVisible && (
        <TravelExpensesStatusChangeModal
          status={subform.status}
          inputs={getInputsFromForm(form)}
          calculationResults={calculationResults}
          onClose={handleStatusChangeModalClose}
          onSave={saveSubform}
        />
      )}
    </>
  );
};

export default TravelExpenses;
