import React from "react";

import { IncomeTaxDeclarationStatus } from "../../../../../api/graphql/schema.generated";
import useTaxYearParam from "../../hooks/useTaxYearParam";
import DeclarationStatusChangeModal from "../../components/DeclarationStatusChangeModal";
import {
  INCOME_TAX_DECLARATION_STATUS_MAPPINGS,
  INCOME_TAX_DECLARATION_STATUSES,
} from "./constants";

export interface IncomeTaxDeclarationStatusChangeModalProps {
  status: IncomeTaxDeclarationStatus;
  onSave: (status: IncomeTaxDeclarationStatus) => Promise<void>;
  onClose: () => void;
}

const IncomeTaxDeclarationStatusChangeModal = ({
  status,
  onSave,
  onClose,
}: IncomeTaxDeclarationStatusChangeModalProps) => {
  const [taxYear] = useTaxYearParam();

  return (
    <DeclarationStatusChangeModal
      title="Einkommensteuererklärung"
      year={taxYear}
      status={status}
      statusOptions={INCOME_TAX_DECLARATION_STATUSES}
      statusMapping={INCOME_TAX_DECLARATION_STATUS_MAPPINGS}
      onSave={onSave}
      onClose={onClose}
    />
  );
};

export default IncomeTaxDeclarationStatusChangeModal;
