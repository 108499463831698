import React, { useCallback, useMemo, useState } from "react";
import { Tabs, Button, Modal, Row, Col, Space, Typography } from "antd";
import pick from "lodash/pick";
import isEqual from "lodash/isEqual";

import moment from "moment";

import { DownloadOutlined, EyeOutlined } from "@ant-design/icons";

import {
  TradeTaxDeclaration as TradeTaxDeclarationModel,
  TRADE_TAX_DECLARATION_QUERY,
  UpdateTradeTaxDeclarationInput,
  useSubmitTradeTaxDeclarationMutation,
  useUpdateTradeTaxDeclarationMutation,
  useTradeTaxDeclarationDraftQuery,
  useTradeTaxDeclarationQuery,
} from "../../../../../api/graphql";
import {
  KontaxNoteType,
  TaxDeclarationSavedDraftInfo,
  TaxDeclarationSubmission,
  TaxDeclarationType,
  TradeTaxDeclarationStatus,
} from "../../../../../api/graphql/schema.generated";
import useEmailParam from "../../../../hooks/useEmailParam";
import useTaxYearParam from "../../hooks/useTaxYearParam";
import DeclarationStatus from "../../components/DeclarationStatus";
import { TRADE_TAX_DECLARATION_STATUS_MAPPINGS } from "./constants";
import EmptyWrapper from "../../../../common/EmptyWrapper";
import { TradeTaxDeclarationPreparation } from "./components/TradeTaxDeclarationPreparation";
import TradeTaxDeclarationCalculation from "./components/TradeTaxDeclarationCalculation";
import {
  destroyMessage,
  showGraphQlErrorNotification,
  showInfoNotification,
  showLoadingMessage,
  showMessage,
} from "../../../../../utils";
import { IMessageType } from "../../../../../types";
import PdfPreviewModal from "../../components/PdfPreviewModal";
import DeclarationSubmitConfirmation from "../../components/DeclarationSubmitConfirmation";
import colors from "../../../../../themes/colors";
import {
  extractNameFromEmail,
  getDraftInfoLatestCreationDate,
  hasEricNotice,
} from "../../utils";
import TradeTaxDeclarationStatusChangeModal from "./components/TradeTaxDeclarationStatusChangeModal";
import WarnLeaveWithoutSaving from "../../../../common/WarnLeaveWithoutSaving";
import { notifySavedChange } from "../../../Mandanten/Common/utils";
import { WARNING_MESSAGE } from "../../../../common/WarnLeaveWithoutSaving/constants";
import { ActionsContainer } from "../../styles";
import ActionLogDrawer from "../../../../common/ActionLogDrawer";
import NotesDrawer from "../../../../common/NotesDrawer";
import { useSaveTradeTaxDeclarationDraftPdfMutation } from "../../../../../api/graphql/mutations/tradeTaxDeclaration/saveTradeTaxDeclarationDraftPdf.generated";
import { useTradeTaxDeclarationSavedDraftInfoQuery } from "../../../../../api/graphql/queries/tradeTaxDeclaration/tradeTaxDeclarationSavedDraftInfo.generated";
import DeclarationInfoMessage from "../../components/DeclarationInfoMessage";
import DeclarationDeclinesDrawer from "../../../../common/DeclarationDeclinesDrawer";
import { declarationDeclinesSorter } from "../../../../../utils/declarationDeclines";
import DeclarationDeclinedBanner from "../../components/DeclarationDeclinedBanner";
import { SavedDraftInfoHint } from "../../components/styledComponents";
import { declarationSubmissionsSorter } from "../../../../../utils/declarationSubmissons";

const { TabPane } = Tabs;
const { Title } = Typography;

const TRADE_TAX_SAVING_KEY = "trade-tax-declaration-saving";
const FIRST_SUPPORTED_TAX_YEAR = 2020;

const STATUSES_WITH_BLOCKED_PDF_RECREATION = [
  TradeTaxDeclarationStatus.APPROVED_BY_USER,
  TradeTaxDeclarationStatus.OBJECTED_BY_USER,
  TradeTaxDeclarationStatus.SUBMITTED,
  TradeTaxDeclarationStatus.OBJECTED_BY_FINANZAMT,
  TradeTaxDeclarationStatus.RECEIVED_TAX_BILL,
  TradeTaxDeclarationStatus.CLOSED,
  TradeTaxDeclarationStatus.APPEAL_PROCESS_STARTED,
  TradeTaxDeclarationStatus.APPEAL_PROCESS_COMPLETED,
];

enum Tab {
  PREPARATION = "1",
  CALCULATION = "2",
}

const TradeTaxDeclaration = () => {
  const [activeTab, setActiveTab] = useState<string>(Tab.PREPARATION);
  const [municipality, setMunicipality] = useState<string | null>(null);
  const [municipalityTaxRate, setMunicipalityTaxRate] = useState<number | null>(
    null
  );
  const [taxYear] = useTaxYearParam();
  const [email] = useEmailParam();

  const [isStatusChangeModalVisible, setIsStatusChangeModalVisible] =
    useState(false);
  const [hasMultipleMunicipalities, setHasMultipleMunicipalities] =
    useState<boolean>(true);

  const [confirmationVisible, setConfirmationVisible] = useState(false);
  const [confirmationLoading, setConfirmationLoading] = useState(false);
  const [selectedSubmission, setSelectedSubmission] =
    useState<TaxDeclarationSubmission | null>();
  const [draftInfo, setDraftInfo] =
    useState<TaxDeclarationSavedDraftInfo | null>();
  const [initialFormValues, setInitialFormValues] = useState({});

  const showStatusChangeModal = useCallback(() => {
    setIsStatusChangeModalVisible(true);
  }, []);

  const handleStatusChangeModalClose = useCallback(() => {
    setIsStatusChangeModalVisible(false);
  }, []);

  const isSupportedTaxYear = taxYear >= FIRST_SUPPORTED_TAX_YEAR;

  const { data: tradeTaxDeclarationData } = useTradeTaxDeclarationQuery({
    skip: !email || !isSupportedTaxYear,
    variables: {
      email: email!,
      year: taxYear,
    },
    onCompleted: (tradeTaxDeclarationData) => {
      const { tradeTaxDeclaration } = tradeTaxDeclarationData;
      setHasMultipleMunicipalities(
        tradeTaxDeclaration.hasMultipleMunicipalities
      );
      setMunicipality(tradeTaxDeclaration.municipality);
      setMunicipalityTaxRate(tradeTaxDeclaration.municipalityTaxRate);

      setInitialFormValues({
        hasMultipleMunicipalities:
          tradeTaxDeclaration.hasMultipleMunicipalities,
        municipality: tradeTaxDeclaration.municipality,
        municipalityTaxRate: tradeTaxDeclaration.municipalityTaxRate,
      });
    },
  });
  const tradeTaxDeclaration = tradeTaxDeclarationData?.tradeTaxDeclaration;
  const savedSubmissionInfo = tradeTaxDeclaration?.submissionInfo || null;
  const savedDraftInfo = tradeTaxDeclaration?.savedDraftInfo || null;

  const { refetch: getTradeTaxDeclarationDraft } =
    useTradeTaxDeclarationDraftQuery({
      variables: {
        email: email!,
        year: taxYear,
      },
      fetchPolicy: "standby",
    });

  const { refetch: getTradeTaxDeclarationSavedDraftInfo } =
    useTradeTaxDeclarationSavedDraftInfoQuery({
      variables: {
        email: email!,
        year: taxYear,
      },
      fetchPolicy: "standby",
    });

  const [saveTradeTaxDeclarationDraftPdf] =
    useSaveTradeTaxDeclarationDraftPdfMutation({
      variables: {
        email: email!,
        year: taxYear,
      },
      refetchQueries: [TRADE_TAX_DECLARATION_QUERY],
    });

  const [submitTradeTaxDeclaration] = useSubmitTradeTaxDeclarationMutation({
    variables: {
      email: email!,
      year: taxYear,
    },
    refetchQueries: [TRADE_TAX_DECLARATION_QUERY],
  });

  const isDirty = useMemo(() => {
    const currentValues = {
      hasMultipleMunicipalities,
      municipality,
      municipalityTaxRate,
    };

    return !isEqual(initialFormValues, currentValues);
  }, [
    hasMultipleMunicipalities,
    initialFormValues,
    municipality,
    municipalityTaxRate,
  ]);

  const onTabChange = useCallback(
    (key) => {
      // If user is transitioning from preparation to calculation with unsaved changes, warn them.
      if (activeTab === Tab.PREPARATION && key === Tab.CALCULATION && isDirty) {
        Modal.confirm({
          title: WARNING_MESSAGE,
          onOk: () => setActiveTab(key),
          cancelText: "Abbrechen",
        });
      } else {
        setActiveTab(key);
      }
    },
    [activeTab, isDirty]
  );

  const shouldShowDetailedView = useMemo(() => {
    return (
      tradeTaxDeclarationData &&
      tradeTaxDeclarationData.tradeTaxDeclaration.status !==
        TradeTaxDeclarationStatus.NOT_RELEVANT
    );
  }, [tradeTaxDeclarationData]);

  const [updateTradeTaxDeclaration] = useUpdateTradeTaxDeclarationMutation({
    refetchQueries: [TRADE_TAX_DECLARATION_QUERY],
  });

  const saveDeclaration = useCallback(
    async (declarationChange: Partial<TradeTaxDeclarationModel>) => {
      if (!email || !tradeTaxDeclaration) {
        return;
      }

      showLoadingMessage(TRADE_TAX_SAVING_KEY);
      const payload: UpdateTradeTaxDeclarationInput = {
        ...pick(tradeTaxDeclaration, ["status", "taxOfficeBalance"]),
        ...declarationChange,
        hasMultipleMunicipalities,
        municipality,
        municipalityTaxRate,
      };

      try {
        await updateTradeTaxDeclaration({
          variables: {
            email: email!,
            year: taxYear,
            payload,
          },
        });

        notifySavedChange();
        setInitialFormValues({
          hasMultipleMunicipalities,
          municipality,
          municipalityTaxRate,
        });
      } catch (error) {
        throw error;
      } finally {
        destroyMessage(TRADE_TAX_SAVING_KEY);
      }
    },
    [
      email,
      taxYear,
      municipality,
      municipalityTaxRate,
      tradeTaxDeclaration,
      updateTradeTaxDeclaration,
      hasMultipleMunicipalities,
    ]
  );

  const showDraftPdf = useCallback(async () => {
    const loadingKey = "loading-draft-pdf";
    showLoadingMessage(loadingKey);
    try {
      const result = await getTradeTaxDeclarationDraft();
      if (result.data?.tradeTaxDeclaration?.draft?.pdf) {
        const { pdf, calculationSheet } = result.data.tradeTaxDeclaration.draft;
        setDraftInfo({ pdf, calculationSheet });
        if (
          hasEricNotice(result.data?.tradeTaxDeclaration?.draft?.processResult)
        ) {
          showInfoNotification({
            message: result.data?.tradeTaxDeclaration?.draft?.message,
            description: result.data?.tradeTaxDeclaration?.draft?.processResult,
          });
        }
        showMessage({
          type: IMessageType.SUCCESS,
          content: "Abrufen des Entwurf-PDF erfolgreich",
        });
      } else {
        showMessage({
          type: IMessageType.ERROR,
          content:
            result.data?.tradeTaxDeclaration?.draft?.processResult ||
            "Beim Abrufen des Entwurf-PDFs ist ein Fehler aufgetreten",
          duration: 5,
        });
      }
    } catch (error) {
      showGraphQlErrorNotification(
        "Beim Abrufen des Entwurf-PDFs ist ein Fehler aufgetreten:",
        error
      );
    } finally {
      destroyMessage(loadingKey);
    }
  }, [getTradeTaxDeclarationDraft]);

  const saveAndShowDraftPdf = useCallback(async () => {
    const loadingKey = "saving-and-loading-draft-pdf";
    showLoadingMessage(loadingKey);
    try {
      const result = await saveTradeTaxDeclarationDraftPdf();
      if (result.data?.saveTradeTaxDeclarationDraftPdf?.pdf) {
        const { pdf, calculationSheet } =
          result.data.saveTradeTaxDeclarationDraftPdf;
        setDraftInfo({ pdf, calculationSheet });
        if (
          hasEricNotice(
            result.data?.saveTradeTaxDeclarationDraftPdf?.processResult!
          )
        ) {
          showInfoNotification({
            message: result.data?.saveTradeTaxDeclarationDraftPdf?.message!,
            description:
              result.data?.saveTradeTaxDeclarationDraftPdf?.processResult,
          });
        }
        showMessage({
          type: IMessageType.SUCCESS,
          content: "Abrufen des Entwurf-PDF erfolgreich",
        });
      } else {
        showMessage({
          type: IMessageType.ERROR,
          content:
            result.data?.saveTradeTaxDeclarationDraftPdf?.processResult ||
            "Beim Abrufen des Entwurf-PDFs ist ein Fehler aufgetreten",
          duration: 5,
        });
      }
    } catch (error) {
      showGraphQlErrorNotification(
        "Beim Abrufen des Entwurf-PDFs ist ein Fehler aufgetreten:",
        error
      );
    } finally {
      destroyMessage(loadingKey);
    }
  }, [saveTradeTaxDeclarationDraftPdf]);

  const showSavedDraftPdf = useCallback(async () => {
    const loadingKey = "loading-saved-draft-pdf";
    showLoadingMessage(loadingKey);
    try {
      const result = await getTradeTaxDeclarationSavedDraftInfo();
      setDraftInfo(result.data.tradeTaxDeclaration.savedDraftInfo);
    } catch (error) {
      showGraphQlErrorNotification(
        "Beim Abrufen des GewSt-Erklärung ist ein Fehler aufgetreten:",
        error
      );
    } finally {
      destroyMessage(loadingKey);
    }
  }, [getTradeTaxDeclarationSavedDraftInfo]);

  const showConfirmation = useCallback(() => {
    setConfirmationVisible(true);
  }, []);

  const hideConfirmation = useCallback(() => {
    setConfirmationVisible(false);
    setConfirmationLoading(false);
  }, []);

  const handleSubmitConfirmation = useCallback(async () => {
    setConfirmationLoading(true);
    try {
      const result = await submitTradeTaxDeclaration();
      if (result.data?.submitTradeTaxDeclaration?.pdf) {
        showMessage({
          type: IMessageType.SUCCESS,
          content: "Erfolgreich eingereicht",
        });
      } else {
        showMessage({
          type: IMessageType.ERROR,
          content:
            result.data?.submitTradeTaxDeclaration?.processResult ||
            "Bei der Einreichung ist ein Fehler aufgetreten",
          duration: 5,
        });
      }
    } catch (error) {
      showGraphQlErrorNotification(
        "Bei der Einreichung ist ein Fehler aufgetreten:",
        error
      );
    }
    hideConfirmation();
  }, [hideConfirmation, submitTradeTaxDeclaration]);

  const shouldBlockPdfRecreationByStatus = useMemo(() => {
    return tradeTaxDeclarationData
      ? STATUSES_WITH_BLOCKED_PDF_RECREATION.includes(
          tradeTaxDeclarationData.tradeTaxDeclaration.status
        )
      : true;
  }, [tradeTaxDeclarationData]);

  const lastDeclarationDecline = useMemo(() => {
    if (
      tradeTaxDeclarationData?.tradeTaxDeclaration.declarationDeclines?.length
    ) {
      const declarationDeclines =
        tradeTaxDeclarationData.tradeTaxDeclaration.declarationDeclines;
      declarationDeclines.sort(declarationDeclinesSorter);

      return declarationDeclines[0];
    }
  }, [tradeTaxDeclarationData]);

  const submissions = useMemo(() => {
    if (tradeTaxDeclarationData?.tradeTaxDeclaration.submissions?.length) {
      const declarationSubmissions =
        tradeTaxDeclarationData?.tradeTaxDeclaration.submissions;
      return declarationSubmissions.sort(declarationSubmissionsSorter);
    }
    return [];
  }, [tradeTaxDeclarationData]);

  const isStatusObjectedByUser = useMemo(
    () =>
      tradeTaxDeclarationData?.tradeTaxDeclaration.status ===
      TradeTaxDeclarationStatus.OBJECTED_BY_USER,
    [tradeTaxDeclarationData]
  );

  if (!email) {
    return <EmptyWrapper description="Suche nach einem Mandanten" />;
  }

  if (!isSupportedTaxYear) {
    return (
      <EmptyWrapper
        description={`Gewerbesteuererklärung wird erst ab ${FIRST_SUPPORTED_TAX_YEAR} unterstützt`}
      />
    );
  }

  if (!tradeTaxDeclaration) {
    return null;
  }

  const title = `Gewerbesteuererklärung ${taxYear}`;
  return (
    <>
      <WarnLeaveWithoutSaving warn={isDirty} />
      <Row align="middle" className="mb-3">
        <Col flex="1">
          <ActionsContainer noTopMargin>
            <ActionLogDrawer
              withIcon
              title="Action log"
              changeLogs={tradeTaxDeclaration.changeLogs}
            />
            <NotesDrawer
              notes={tradeTaxDeclaration.notes}
              title={title}
              type={KontaxNoteType.TRADE_TAX_DECLARATION}
              recordId={tradeTaxDeclaration.id}
            />
            <DeclarationDeclinesDrawer
              declarationDeclines={tradeTaxDeclaration.declarationDeclines}
              showNotification={
                tradeTaxDeclaration.status ===
                TradeTaxDeclarationStatus.OBJECTED_BY_USER
              }
              title={title}
            />
          </ActionsContainer>
          <Title level={3}>{title}</Title>
        </Col>
        {submissions.length > 0 ? (
          <Col style={{ color: colors.darkLimeGreen }}>
            Eingereicht
            <div style={{ color: colors.darkGrey }}>
              am {moment(submissions[0].submissionDate).format("L")} von{" "}
              {extractNameFromEmail(submissions[0].submissionAgent!)}
            </div>
          </Col>
        ) : !!savedDraftInfo ? (
          <SavedDraftInfoHint style={{ marginTop: 25, color: colors.darkGrey }}>
            <div>Freigabe PDF erstellt</div>
            <div>
              am{" "}
              {moment(getDraftInfoLatestCreationDate(savedDraftInfo!)).format(
                "L"
              )}
            </div>
          </SavedDraftInfoHint>
        ) : null}
      </Row>

      <Row justify="space-between" align="middle" className="mb-3">
        <Col>
          <DeclarationStatus
            mapping={TRADE_TAX_DECLARATION_STATUS_MAPPINGS}
            status={tradeTaxDeclaration.status}
            statusUpdatedAt={tradeTaxDeclaration.statusUpdatedAt}
            changedBy={tradeTaxDeclaration.lastStatusChange?.changedBy}
            onClick={showStatusChangeModal}
          />
        </Col>
        {shouldShowDetailedView && (
          <Col>
            <Button
              type="primary"
              onClick={() =>
                saveDeclaration({ status: tradeTaxDeclaration.status })
              }
            >
              Speichern
            </Button>
          </Col>
        )}
      </Row>
      <Row className="mb-4">
        <Space direction="horizontal">
          {savedDraftInfo && (
            <Button
              type="primary"
              size="small"
              icon={<EyeOutlined />}
              onClick={showSavedDraftPdf}
            >
              Aktuelles Freigabe-PDF
            </Button>
          )}
          {submissions &&
            submissions.map((submission, index) => (
              <Button
                key={submission.submissionDate}
                type="primary"
                size="small"
                icon={<DownloadOutlined />}
                onClick={() => setSelectedSubmission(submission)}
              >
                {`Übermittelte GewSt-Erklärung ${submissions.length - index} `}
              </Button>
            ))}
        </Space>
      </Row>
      <DeclarationDeclinedBanner
        declaration={tradeTaxDeclaration}
        declarationType={TaxDeclarationType.TRADE_TAX}
        onLinkClick={showSavedDraftPdf}
      />
      {shouldShowDetailedView && (
        <Tabs activeKey={activeTab} onChange={onTabChange}>
          <TabPane tab="Vorbereitung" key={Tab.PREPARATION}>
            <TradeTaxDeclarationPreparation
              email={email}
              taxYear={taxYear}
              hasMultipleMunicipalities={hasMultipleMunicipalities}
              onHasMultipleMunicipalitiesChange={setHasMultipleMunicipalities}
              municipality={tradeTaxDeclaration.municipality}
              municipalityTaxRate={tradeTaxDeclaration.municipalityTaxRate}
              onMunicipalityTaxRateChange={setMunicipalityTaxRate}
              onMunicipalityChange={setMunicipality}
            />
          </TabPane>
          <TabPane tab="Berechnung" key={Tab.CALCULATION}>
            <Space direction="vertical" size={30} style={{ width: "100%" }}>
              <TradeTaxDeclarationCalculation
                tradeTaxDeclaration={tradeTaxDeclaration}
                onChange={(taxOfficeBalance) =>
                  saveDeclaration({ taxOfficeBalance })
                }
              />
              <div>
                <Row justify="space-between">
                  <Col>
                    <Space>
                      <Button type="default" onClick={showDraftPdf}>
                        Vorschau-Formular
                      </Button>
                      <Button
                        type="default"
                        onClick={saveAndShowDraftPdf}
                        disabled={
                          shouldBlockPdfRecreationByStatus ||
                          !!savedDraftInfo?.externalAssets
                        }
                      >
                        Freigabe-PDF aktualisieren
                      </Button>
                    </Space>
                  </Col>
                  <Col>
                    <Button
                      type="primary"
                      disabled={!!savedDraftInfo?.externalAssets}
                      onClick={showConfirmation}
                    >
                      Einreichen
                    </Button>
                  </Col>
                </Row>
                <DeclarationInfoMessage
                  hasExternalAssets={
                    !!savedDraftInfo?.externalAssets ||
                    !!savedSubmissionInfo?.externalAssets
                  }
                  shouldBlockPdfRecreationByStatus={
                    shouldBlockPdfRecreationByStatus
                  }
                />
              </div>
            </Space>
          </TabPane>
        </Tabs>
      )}

      {isStatusChangeModalVisible && (
        <TradeTaxDeclarationStatusChangeModal
          calculations={tradeTaxDeclaration.autoCalculatedValues.calculations}
          status={tradeTaxDeclaration.status}
          onSave={(status) => saveDeclaration({ status })}
          onClose={handleStatusChangeModalClose}
        />
      )}
      {draftInfo && (
        <PdfPreviewModal
          title="Testformular"
          declarationName="GewSt"
          info={draftInfo}
          declarationDecline={
            isStatusObjectedByUser ? lastDeclarationDecline : undefined
          }
          statusUpdatedAt={
            isStatusObjectedByUser &&
            tradeTaxDeclarationData?.tradeTaxDeclaration.lastStatusChange
              ? tradeTaxDeclarationData.tradeTaxDeclaration.lastStatusChange
                  .changedAt
              : undefined
          }
          onClose={() => setDraftInfo(undefined)}
        />
      )}
      {selectedSubmission && (
        <PdfPreviewModal
          title="Übermittelte GewSt-Erklärung"
          declarationName="GewSt"
          info={selectedSubmission}
          onClose={() => setSelectedSubmission(undefined)}
        />
      )}
      {confirmationVisible && (
        <DeclarationSubmitConfirmation
          declarationName="Gewerbesteuererklärung"
          taxYear={taxYear}
          email={email!}
          loading={confirmationLoading}
          onConfirm={handleSubmitConfirmation}
          onCancel={hideConfirmation}
        />
      )}
    </>
  );
};

export default TradeTaxDeclaration;
