import { gql, MutationHookOptions, useMutation } from "@apollo/client";

import { TradeTaxDeclarationStatus } from "../../schema.generated";
import { TradeTaxDeclaration } from "../../types";

export interface UpdateTradeTaxDeclarationInput {
  status: TradeTaxDeclarationStatus;
  hasMultipleMunicipalities: boolean;
  municipality: string | null;
  municipalityTaxRate: number | null;
  taxOfficeBalance: number | null;
}

export interface UpdateTradeTaxDeclarationMutationVariables {
  email: string;
  year: number;
  payload: UpdateTradeTaxDeclarationInput;
}

export interface UpdateTradeTaxDeclarationMutationResult {
  updateTradeTaxDeclaration: TradeTaxDeclaration;
}

export const UPDATE_TRADE_TAX_DECLARATION_MUTATION = gql`
  mutation updateTradeTaxDeclaration(
    $email: String!
    $year: Int!
    $payload: UpdateTradeTaxDeclarationInput!
  ) {
    updateTradeTaxDeclaration(email: $email, year: $year, payload: $payload) {
      status
      statusUpdatedAt
      hasMultipleMunicipalities
      municipality
      municipalityTaxRate
      taxOfficeBalance
    }
  }
`;

export const useUpdateTradeTaxDeclarationMutation = (
  options?: MutationHookOptions<
    UpdateTradeTaxDeclarationMutationResult,
    UpdateTradeTaxDeclarationMutationVariables
  >
) =>
  useMutation<
    UpdateTradeTaxDeclarationMutationResult,
    UpdateTradeTaxDeclarationMutationVariables
  >(UPDATE_TRADE_TAX_DECLARATION_MUTATION, options);
