import { useCallback, useState } from "react";

import { BusinessAssetMetaData } from "../../types";
import { formatAmountInCents } from "../../utils";
import DeleteConfirmationPopup from "../common/DeleteConfirmationPopup";
import { getBerlinMomentTimezone } from "../common/helpers";

const DELETE_CONFIRMATION_POPUP_TITLE = "Achtung";
const DELETE_CONFIRMATION_POPUP_TEXT =
  "Durch die Umbuchung wird automatisch auch die dazugehörende Anlage aus dem Anlagevermögen gelöscht:\n\n";

export const getBusinessAssetsDataForPopup = (
  businessAssets: BusinessAssetMetaData[]
): string => {
  return businessAssets
    .map((businessAsset) =>
      [
        businessAsset.assetClass,
        formatAmountInCents(businessAsset.amount),
        getBerlinMomentTimezone(businessAsset.purchaseDate).format(
          "YYYY-MM-DD"
        ),
      ].join(" | ")
    )
    .join("\n");
};

export const DeleteBusinessAssetConfirmationPopup = ({
  businessAssets,
  ...props
}: {
  businessAssets: BusinessAssetMetaData[];
  isOpen?: boolean;
  onDelete: () => void;
  onClose: () => void;
}) => {
  return (
    <DeleteConfirmationPopup
      title={DELETE_CONFIRMATION_POPUP_TITLE}
      text={
        DELETE_CONFIRMATION_POPUP_TEXT +
        getBusinessAssetsDataForPopup(businessAssets)
      }
      {...props}
    />
  );
};

export function useDeleteBusinessAssetConfirmation() {
  const [component, setComponent] = useState<JSX.Element | null>(null);

  const confirm = useCallback((businessAssets: BusinessAssetMetaData[]) => {
    return new Promise<boolean>((resolve) => {
      if (!businessAssets.length) {
        resolve(true);
        return;
      }

      setComponent(
        <DeleteBusinessAssetConfirmationPopup
          businessAssets={businessAssets}
          isOpen
          onDelete={() => {
            setComponent(null);
            resolve(true);
          }}
          onClose={() => {
            setComponent(null);
            resolve(false);
          }}
        />
      );
    });
  }, []);

  return [component, confirm] as const;
}
