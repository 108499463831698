import type * as Types from "../../schema.generated";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type DeleteAutocategorizationRuleMutationVariables = Types.Exact<{
  id: Types.Scalars["Int"];
}>;

export type DeleteAutocategorizationRuleMutation = {
  __typename?: "Mutation";
  deleteAutocategorizationRule: {
    __typename?: "MutationResult";
    success: boolean;
  };
};

export const DeleteAutocategorizationRuleDocument = gql`
  mutation deleteAutocategorizationRule($id: Int!) {
    deleteAutocategorizationRule(id: $id) {
      success
    }
  }
`;
export type DeleteAutocategorizationRuleMutationFn = Apollo.MutationFunction<
  DeleteAutocategorizationRuleMutation,
  DeleteAutocategorizationRuleMutationVariables
>;

/**
 * __useDeleteAutocategorizationRuleMutation__
 *
 * To run a mutation, you first call `useDeleteAutocategorizationRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAutocategorizationRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAutocategorizationRuleMutation, { data, loading, error }] = useDeleteAutocategorizationRuleMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAutocategorizationRuleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteAutocategorizationRuleMutation,
    DeleteAutocategorizationRuleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteAutocategorizationRuleMutation,
    DeleteAutocategorizationRuleMutationVariables
  >(DeleteAutocategorizationRuleDocument, options);
}
export type DeleteAutocategorizationRuleMutationHookResult = ReturnType<
  typeof useDeleteAutocategorizationRuleMutation
>;
export type DeleteAutocategorizationRuleMutationResult =
  Apollo.MutationResult<DeleteAutocategorizationRuleMutation>;
export type DeleteAutocategorizationRuleMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteAutocategorizationRuleMutation,
    DeleteAutocategorizationRuleMutationVariables
  >;
