import React, { ReactElement } from "react";
import { Space, Select } from "antd";

import { AnschreibenType } from "../../../../api/graphql/schema.generated";
import ActionLogDrawerAsync from "../../../common/ActionLogDrawerAsync";
import { FREELANCER, TRADER, TRADE_TAX_MISSING } from "../texts";
import { CardWrapper, CardContainer, Middle } from "./styledComponents";
import { years } from "./data";

const { Option } = Select;

const AnschreibenTopBar = ({
  year,
  setYear,
  type,
  setType,
  language,
  setLanguage,
  RightComponents,
  anschreibenId,
}: {
  year: number;
  setYear: (year: number) => void;
  type?: AnschreibenType | null;
  setType?: (type: AnschreibenType) => void;
  language?: string;
  setLanguage?: (type: string) => void;
  RightComponents?: ReactElement;
  anschreibenId?: string;
}) => {
  return (
    <CardWrapper>
      <CardContainer>
        <Space>
          <Select value={year} onChange={setYear}>
            {years.map((y) => (
              <Option key={y} value={y}>
                {y}
              </Option>
            ))}
          </Select>
          {!anschreibenId && (
            <Select value={type} onChange={setType} disabled>
              <Option value={null}>{TRADE_TAX_MISSING}</Option>
              <Option value={AnschreibenType.FREELANCER}>{FREELANCER}</Option>
              <Option value={AnschreibenType.TRADER}>{TRADER}</Option>
            </Select>
          )}
          {!anschreibenId && (
            <Select value={language} onChange={setLanguage}>
              <Option value="en">Englisch</Option>
              <Option value="de">Deutsch</Option>
            </Select>
          )}
          {anschreibenId && (
            <ActionLogDrawerAsync
              title="Action log"
              modelName="anschreiben"
              recordIds={[anschreibenId]}
            />
          )}
        </Space>
        <Middle />
        {RightComponents}
      </CardContainer>
    </CardWrapper>
  );
};

export default AnschreibenTopBar;
