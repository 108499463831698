import { gql, QueryHookOptions, useQuery } from "@apollo/client";

import { NoteDataFragmentDoc } from "../../fragments/note.generated";
import { DeclarationDeclineFragmentDoc } from "../../fragments/declarationDecline.generated";
import { VatAnnualDeclaration } from "../../types";

export interface VatAnnualDeclarationQueryVariables {
  email: string;
  year: number;
}

export interface VatAnnualDeclarationQueryResult {
  vatAnnualDeclaration: VatAnnualDeclaration;
}

export const VAT_ANNUAL_DECLARATION_QUERY = gql`
  query vatAnnualDeclaration($email: String!, $year: Int!) {
    vatAnnualDeclaration(email: $email, year: $year) {
      id
      status
      statusUpdatedAt
      lastYearIncomeAdjustment
      currentYearIncomeAdjustment
      taxOfficeBalance
      lastStatusChange {
        status
        changedAt
        changedBy
      }
      submissionInfo {
        submissionAgent
        submissionDate
        externalAssets {
          url
        }
      }
      submissions {
        submissionAgent
        submissionDate
        pdf
        calculationSheet
      }
      savedDraftInfo {
        createdBy
        createdAt
        updatedAt
        externalAssets {
          url
          createdAt
        }
      }
      changeLogs {
        changes
        changedAt
        changedBy
      }
      notes {
        ...noteData
      }
      declarationDeclines {
        ...DeclarationDecline
      }
    }
  }
  ${NoteDataFragmentDoc}
  ${DeclarationDeclineFragmentDoc}
`;

export const useVatAnnualDeclarationQuery = (
  options?: QueryHookOptions<
    VatAnnualDeclarationQueryResult,
    VatAnnualDeclarationQueryVariables
  >
) =>
  useQuery<VatAnnualDeclarationQueryResult, VatAnnualDeclarationQueryVariables>(
    VAT_ANNUAL_DECLARATION_QUERY,
    options
  );
