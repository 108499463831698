import {
  EuerDeclarationFieldLink,
  EuerDeclarationFieldType,
} from "../../../../types";
import { EUER_DECLARATION_OVERVIEW_META_INFO } from "../../constants";
import euerDeclarationFields from "./euerDeclarationFields";

export const PATH_BY_EUER_TYPE: Record<EuerDeclarationFieldType, string> = {
  [EuerDeclarationFieldType.BusinessAssets]:
    EUER_DECLARATION_OVERVIEW_META_INFO.businessAssetsSubform.path,
  [EuerDeclarationFieldType.OfficeUsageSubform]:
    EUER_DECLARATION_OVERVIEW_META_INFO.officeUsageSubform.path,
  [EuerDeclarationFieldType.TravelExpensesSubform]:
    EUER_DECLARATION_OVERVIEW_META_INFO.travelExpensesSubform.path,
  [EuerDeclarationFieldType.CarUsageSubform]:
    EUER_DECLARATION_OVERVIEW_META_INFO.carUsageSubform.path,
  [EuerDeclarationFieldType.None]: "",
  [EuerDeclarationFieldType.Verification]: "/verification-view",
};

export const CATEGORIES_BY_ELSTER_FIELD: Record<string, string[]> = {
  "111": ["1110", "1111"],
  "112": ["1120", "1030", "1121", "1122"],
  "103": ["1030", "1031", "1032", "1033", "1120"],
  "141": ["1410"],
  "108": ["1081", "1082", "1083", "1084"],
  "100": ["1000"],
  "110": ["1100"],
  "120": ["1200"],
  "136": ["1370"],
  "131": ["1370"],
  "130": ["1370"],
  "132": ["1300", "1320"],
  "150": ["1500"],
  "168": ["1680", "8000", "9000"],
  "177": ["1770"],
  "280": ["2800"],
  "221": ["2210"],
  "281": ["2810"],
  "194": ["1940"],
  "222": ["2220"],
  "225": ["2250"],
  "223": ["2230", "2231"],
  "228": ["2280"],
  "229": ["2290", "2291", "2292"],
  "226": ["2260"],
  "227": ["2270"],
  "224": ["2240"],
  "232": ["2320", "2321"],
  "234": ["2340"],
  "186": ["1860"],
  "183": ["1830", "1831"],
  "164": ["1740"],
  "174": ["1740"],
  "165": ["1650"],
  "175": ["1650"],
  "171": ["1710"],
  "144": ["1440"],
  "145": ["1450"],
  "146": ["1460", "1461", "1462"],
  "122": ["1230", "1221", "2000"],
  "123": ["1220", "5000"],
};

export const VAT_CATEGORIES_BY_ELSTER_FIELD: Record<string, string[]> = {
  "112": [
    "81",
    "82",
    "83",
    "84",
    "61",
    "62",
    "63",
    "64",
    "71",
    "72",
    "73",
    "74",
  ],
  "103": ["70", "60", "51", "52", "10", "53", "85", "86"],
};

// @TODO field 171 have actually two view types but it will be handled on follow up ticket
export const TYPE_BY_ELSTER_FIELD: Record<string, EuerDeclarationFieldType> = {
  "102": EuerDeclarationFieldType.BusinessAssets,
  "106": EuerDeclarationFieldType.CarUsageSubform,
  "135": EuerDeclarationFieldType.BusinessAssets,
  "140": EuerDeclarationFieldType.None,
  "159": EuerDeclarationFieldType.None,
  "171": EuerDeclarationFieldType.None,
  "172": EuerDeclarationFieldType.OfficeUsageSubform,
  "185": EuerDeclarationFieldType.None,
  "147": EuerDeclarationFieldType.TravelExpensesSubform,
  "142": EuerDeclarationFieldType.CarUsageSubform,
  "176": EuerDeclarationFieldType.CarUsageSubform,
  "199": EuerDeclarationFieldType.None,
  "219": EuerDeclarationFieldType.None,
};

export const ELSTER_FIELD_CODES = Array.from(euerDeclarationFields.keys());
export const EUER_DECLARATION_FIELD_LINKS =
  ELSTER_FIELD_CODES.reduce<EuerDeclarationFieldLink>((links, code) => {
    const categoryCodes = CATEGORIES_BY_ELSTER_FIELD[code];
    const vatCategoryCodes = VAT_CATEGORIES_BY_ELSTER_FIELD[code];
    const type = TYPE_BY_ELSTER_FIELD[code]
      ? TYPE_BY_ELSTER_FIELD[code]
      : EuerDeclarationFieldType.Verification;

    return {
      ...links,
      [code]: {
        transactionFilters: {
          categoryCodes,
          vatCategoryCodes,
        },
        type,
      },
    };
  }, {});
