import moment from "moment";
import React, { useState } from "react";

import { BWAFilterOptions } from "../../../types";
import {
  UserInfoExcerptWrapper,
  UserInfoWrapper,
  UserNameWrapper,
  ViewMoreWrapper,
} from "../../common/UserInfoExcerpt/styledComponents";
import useEmailParam from "../../hooks/useEmailParam";

import BWAHeader from "./BWAHeader";
import BWAStatsTable from "./BWAStatsTable";
import { ReactComponent as ArrowRight } from "../../../svgs/arrow-right.svg";
import UserInfoExcerpt from "../../common/UserInfoExcerpt";
import { ContentWrapper } from "../../common/styledComponents";
import {
  KontaxUserContext,
  useKontaxUserContext,
} from "../../contexts/KontaxUserContext";
import { UserInfoDrawerSources } from "../../common/UserInfoExcerpt/UserInfoDrawer";

const UserInfo = () => {
  const { user, notes, isLoading } = useKontaxUserContext();

  return (
    <UserInfoExcerpt
      user={user}
      notes={notes}
      source={UserInfoDrawerSources.ACCOUNTING}
      isLoading={isLoading}
      renderExcerptData={(excerptData) => {
        return (
          <UserInfoExcerptWrapper>
            <UserNameWrapper>
              {excerptData.user.firstName} {excerptData.user.lastName}
            </UserNameWrapper>

            <span>
              {excerptData.vatDetails?.isKleinunternehmer && (
                <UserInfoWrapper>Kleinunternehmer</UserInfoWrapper>
              )}
              {excerptData.user.businessTypeComment && (
                <UserInfoWrapper>
                  {excerptData.user.businessTypeComment}
                </UserInfoWrapper>
              )}
              <UserInfoWrapper>
                {excerptData.vatDetails?.frequency} (
                {excerptData.vatDetails?.year})
              </UserInfoWrapper>
              <UserInfoWrapper>{excerptData.subscriptionType}</UserInfoWrapper>
              <UserInfoWrapper>{excerptData.notesCount}</UserInfoWrapper>
            </span>

            <ViewMoreWrapper>
              <span>View more info</span>
              <ArrowRight />
            </ViewMoreWrapper>
          </UserInfoExcerptWrapper>
        );
      }}
    />
  );
};

const BWAView = () => {
  const [emailParam, setEmailParam] = useEmailParam();
  const [filterOptions, setFilterOptions] = useState<BWAFilterOptions>({
    email: emailParam || "",
    dateTo: moment().endOf("day").format(),
    dateFrom: moment().startOf("year").format(),
  });

  return (
    <ContentWrapper>
      <BWAHeader
        email={filterOptions.email}
        changeHandler={(updatedFilterOptions: Partial<BWAFilterOptions>) => {
          const newFilterOptions = {
            ...filterOptions,
            ...updatedFilterOptions,
          };
          setFilterOptions(newFilterOptions);
          setEmailParam(newFilterOptions.email || undefined);
        }}
      />
      <KontaxUserContext email={filterOptions.email}>
        <UserInfo />
        <BWAStatsTable filterOptions={filterOptions} />
      </KontaxUserContext>
    </ContentWrapper>
  );
};

export default BWAView;
