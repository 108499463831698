import {
  KontaxUserStatus,
  NextVatDeclarationStatus,
  OnbStatus,
} from "../types";

export const getColorByUserStatus = (status?: KontaxUserStatus) => {
  switch (status) {
    case KontaxUserStatus.ACTIVATED:
      return "success";
    case KontaxUserStatus.PENDING:
      return "processing";
    case KontaxUserStatus.LEAD:
      return "warning";
    case KontaxUserStatus.DECLINED:
      return "error";
    case KontaxUserStatus.DEACTIVATED:
      return "default";
    default:
      return "default";
  }
};

export const getVariantByDeclarationStatus = (
  status?: NextVatDeclarationStatus
) => {
  switch (status) {
    case NextVatDeclarationStatus.READY_TO_SEND:
      return "#c3e6cb";
    case NextVatDeclarationStatus.PENDING:
      return "#d6d8db";
    case NextVatDeclarationStatus.READY_TO_CHECK:
      return "#ffeeba";
    case NextVatDeclarationStatus.BLOCKED:
      return "#fcd2cf";
    case NextVatDeclarationStatus.READY_TO_BOOK:
      return "#e3d9ff";
    default:
      return "rgba(0,0,0,0.05)";
  }
};

export const getColorByOnboardingStatus = (onbStatus?: OnbStatus | null) => {
  switch (onbStatus) {
    case OnbStatus.COMPLETED:
      return "success";
    case OnbStatus.NOT_SET:
      return "default";
    case OnbStatus.USER_CANCELLATION:
      return "error";
    default:
      return "warning";
  }
};
