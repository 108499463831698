import React, { useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import { Typography } from "antd";
import * as queryString from "query-string";

import { BorderedTable, SourceCell } from "../styledComponents";
import { COLUMN_ALIGN_RIGHT, DATA_SOURCE_LABELS } from "../constants";
import EditableNumberCell, {
  EditableNumberCellProps,
} from "../EditableNumberCell";
import { formatAmountInCents } from "../../../../../../utils";
import { EUER_DECLARATION_ROOT_PATH } from "../../EuerDeclaration/constants";
import { PREVIEW_LOCATION_HASH } from "../../EuerDeclaration/pages/EuerDeclarationOverview";

const { Text } = Typography;

const SMALL_BUSINESS_INCOME_TABLE_COMPONENTS = {
  body: {
    cell: EditableNumberCell,
  },
};

const SMALL_BUSINESS_INCOME_TABLE_COLUMNS = [
  {
    key: "year",
    title: "",
    width: "40%",
    render: ({ year, email }: SmallBusinessIncome) => {
      const path = {
        pathname: EUER_DECLARATION_ROOT_PATH,
        search: queryString.stringify({ email, year }),
        hash: PREVIEW_LOCATION_HASH,
      };
      return <Link to={path}>{`Summe Umsätze Kalenderjahr ${year}`}</Link>;
    },
  },
  {
    key: "source",
    title: "",
    render: () => <SourceCell>{DATA_SOURCE_LABELS.AUTO_CALCULATED}</SourceCell>,
  },
  {
    key: "total",
    title: "",
    dataIndex: "total",
    align: COLUMN_ALIGN_RIGHT,
    render: (total: number, { autoCalculatedTotal }: SmallBusinessIncome) => (
      <>
        <Text>{formatAmountInCents(total, true)}</Text>
        {total !== autoCalculatedTotal ? (
          <div className="ant-typography ant-typography-secondary m-0">{`Auto: ${formatAmountInCents(
            autoCalculatedTotal,
            true
          )}`}</div>
        ) : null}
      </>
    ),
  },
];

export interface SmallBusinessIncome {
  email: string;
  year: number;
  autoCalculatedTotal: number;
  total: number;
}

export interface SmallBusinessIncomeTableProps {
  incomes: SmallBusinessIncome[];
  onIncomeChange: (income: SmallBusinessIncome) => void;
}

const SmallBusinessIncomeTable = ({
  incomes,
  onIncomeChange,
}: SmallBusinessIncomeTableProps) => {
  const handleTotalEdit = useCallback(
    (income: SmallBusinessIncome) => (total: number | null) => {
      const normalizedTotal = total || 0; // handle empty input as 0
      if (income.total === normalizedTotal) {
        return;
      }

      onIncomeChange({ ...income, total: normalizedTotal });
    },
    [onIncomeChange]
  );

  const columns = useMemo(
    () =>
      SMALL_BUSINESS_INCOME_TABLE_COLUMNS.map((col) => {
        if (col.dataIndex === "total") {
          return {
            ...col,
            onCell: (income: SmallBusinessIncome) =>
              ({
                editable: true,
                value: income.total,
                onEdit: handleTotalEdit(income),
              } as EditableNumberCellProps),
          };
        }

        return col;
      }),
    [handleTotalEdit]
  );

  return (
    <BorderedTable
      pagination={false}
      showHeader={false}
      columns={columns}
      dataSource={incomes}
      rowKey={(income) => income.year}
      components={SMALL_BUSINESS_INCOME_TABLE_COMPONENTS}
    />
  );
};

export default SmallBusinessIncomeTable;
