const magnify = (
  img: any,
  zoom: number,
  angle: number = 0,
  imgScale: number
) => {
  /* Create magnifier glass: */
  const glass = document.createElement("DIV");
  glass.setAttribute("class", "img-magnifier-glass");

  const srcImage = document.createElement("IMG") as HTMLImageElement;
  srcImage.src = img.src;

  let imgWidth = img.width * imgScale;
  let imgHeight = img.height * imgScale;

  /* Set the image zoom  */
  srcImage.width = imgWidth * zoom;
  srcImage.height = imgHeight * zoom;

  /* Set the appropriate origin to fit the picture to the upper left corner after rotation */
  let origin = "";
  if (angle % 360 === 0) {
    origin = "";
  } else if (angle % 270 === 0) {
    origin = `${srcImage.width / 2}px ${srcImage.width / 2}px`;
  } else if (angle % 180 === 0) {
    origin = "center";
  } else if (angle % 90 === 0) {
    origin = `${srcImage.height / 2}px ${srcImage.height / 2}px`;
  }
  srcImage.style.transformOrigin = origin;
  srcImage.style.transform = `rotate(${angle}deg)`;

  glass.appendChild(srcImage);

  /* Insert magnifier glass: */
  img.parentElement?.insertBefore(glass, img);

  let offsetX = 0;
  let offsetY = 0;

  /* Set the offests considering rotation */
  if (angle % 180 === 0) {
    offsetX = img.offsetLeft;
    offsetY = img.offsetTop;
  } else {
    offsetX = img.offsetTop;
    offsetY = img.offsetLeft;

    /* Switch the width and height if the image rotates 90 or 270 degrees */
    imgWidth = img.height * imgScale;
    imgHeight = img.width * imgScale;
  }

  /* Set background properties for the magnifier glass: */
  glass.style.overflow = "hidden";
  glass.style.visibility = "hidden";
  const width = glass.offsetWidth / 2;
  const height = glass.offsetHeight / 2;

  /* Execute a function when someone moves the magnifier glass over the image: */
  glass.addEventListener("mousemove", moveMagnifier);
  img.addEventListener("mousemove", moveMagnifier);
  img.addEventListener("mouseout", hideMagnifier);
  glass.addEventListener("mouseout", hideMagnifier);

  function moveMagnifier(e: MouseEvent) {
    e.preventDefault();
    /* Get the cursor's x and y positions: */
    const pos = getCursorPos(e);
    let x = pos.x;
    let y = pos.y;

    /* Prevent the magnifier glass from being positioned outside the image: */
    if (x > imgWidth - width / zoom) {
      x = imgWidth - width / zoom;
    }
    if (x < width / zoom) {
      x = width / zoom;
    }
    if (y > imgHeight - height / zoom) {
      y = imgHeight - height / zoom;
    }
    if (y < height / zoom) {
      y = height / zoom;
    }

    offsetX =
      img.getBoundingClientRect().left -
      img.parentElement.getBoundingClientRect().left;

    offsetY =
      img.getBoundingClientRect().top -
      img.parentElement.getBoundingClientRect().top;

    /* Set the position of the magnifier glass: */
    glass.style.visibility = "visible";
    glass.style.left = x - width + offsetX + "px";
    glass.style.top = y - height + offsetY + "px";

    /* Display what the magnifier glass "sees": */
    srcImage.style.marginLeft = "-" + (x * zoom - width) + "px";
    srcImage.style.marginTop = "-" + (y * zoom - height) + "px";
  }

  function hideMagnifier() {
    glass.style.visibility = "hidden";
  }

  const getCursorPos = (e: MouseEvent) => {
    e = e || window.event;
    /* Get the x and y positions of the image: */
    const a = img.getBoundingClientRect();
    /* Calculate the cursor's x and y coordinates, relative to the image: */
    let x = e.pageX - a.left;
    let y = e.pageY - a.top;
    /* Consider any page scrolling: */
    x = x - window.pageXOffset;
    y = y - window.pageYOffset;
    return { x: x, y: y };
  };

  return {
    dispose: () => {
      glass.removeEventListener("mousemove", moveMagnifier);
      img.removeEventListener("mousemove", moveMagnifier);
      img.removeEventListener("mouseout", hideMagnifier);
      glass.removeEventListener("mouseout", hideMagnifier);

      img.parentElement?.removeChild(glass);
    },
  };
};

export default magnify;
