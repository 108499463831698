import React, { useCallback, useEffect, useMemo } from "react";
import { Prompt } from "react-router-dom";

import { WARNING_MESSAGE } from "./constants";

interface ComponentProps {
  warn: boolean;
  message?: string;
}

/**
 * Prompts the user when navigating out of the current route with unsaved changes.
 * The pop up will be displayed when the `warn` prop is true (defining when to warn is the
 * responsibility of the implementing component).
 * There are two different boxes displayed depending on how the user is navigating out of the route:
 *  - Navigating to another route inside the app: uses the default `react-router-dom`'s Prompt component
 *  - Closing the tab/window or reloading the page: uses the browser's default pop up (through the `beforeunload` event)
 *
 * Depending on the browser, when displaying the browser's default box, the message might be defaulted by it, ignoring
 * the one passed by the component.
 *
 * A default message for the component is defined (different from the browser's) but it can be replaced by passing the `message` prop.
 */
const WarnLeaveWithoutSaving = ({ warn, message }: ComponentProps) => {
  const warningMessage = useMemo(() => message || WARNING_MESSAGE, [message]);
  const beforeUnloadHandler = useCallback(
    (event: BeforeUnloadEvent) => {
      if (warn) {
        event.preventDefault();
        event.returnValue = warningMessage;
        return warningMessage;
      }
    },
    [warn, warningMessage]
  );

  useEffect(() => {
    window.addEventListener("beforeunload", beforeUnloadHandler);
    return () => {
      window.removeEventListener("beforeunload", beforeUnloadHandler);
    };
  }, [beforeUnloadHandler]);

  return <Prompt when={warn} message={warningMessage} />;
};

export default WarnLeaveWithoutSaving;
