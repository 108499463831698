import styled from "styled-components";

import colors from "../../../../themes/colors";

export const MatchButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  color: ${colors.veryLightGreyOpacity};
  cursor: pointer;
  ${(props: { disabled?: boolean }) =>
    !props.disabled
      ? `&:hover {
    color: ${colors.primaryPurple};
  }`
      : `
      cursor: not-allowed;`}
  width: 90px;
`;
