import React from "react";
import { Radio, RadioChangeEvent } from "antd";

import { YesNoEnum } from "../../types";

export const BooleanOptionRadioButton = (props: {
  value?: boolean | undefined;
  onChange?: (value: RadioChangeEvent) => void;
}) => {
  return (
    <Radio.Group
      name="verify"
      options={[
        { label: YesNoEnum.NO, value: false },
        { label: YesNoEnum.YES, value: true },
      ]}
      optionType="button"
      buttonStyle="solid"
      {...props}
    />
  );
};
