import React, { useCallback, useState, useRef } from "react";
import { useQueryParam, StringParam } from "use-query-params";

import { notification } from "antd";

import TransactionCategorisationView from "./TransactionCategorisationView";
import api from "../../../api";
import {
  KontaxTransactionFilterOptions,
  FetchTransactionsResponse,
  ITransaction,
} from "../../../types";
import {
  destroyMessage,
  showSuccessMessage,
  showLoadingMessage,
} from "../../../utils";
import { getAsset } from "../../common/helpers";
import { ContentWrapper } from "../../common/styledComponents";

const TransactionView = () => {
  const [response, setResponse] = useState<FetchTransactionsResponse>({
    transactions: [],
    count: undefined,
  });

  const [id, setId] = useQueryParam("id", StringParam);

  const isFirstUpdate = useRef<boolean>(true);

  const fetchNextTransaction = useCallback(
    async (filterOptions: KontaxTransactionFilterOptions) => {
      const loadingKey = "loading-transactions";

      showLoadingMessage(loadingKey);

      try {
        let response: FetchTransactionsResponse;

        if (isFirstUpdate.current && id) {
          response = await fetchTransactionResponseById(id);
        } else {
          response = await api.kontax.fetchTransactions(
            { ...filterOptions, verified: false },
            1
          );
        }

        isFirstUpdate.current = false;

        if (response.transactions[0]) {
          response.transactions[0] = await updateTransactionAssets(
            response.transactions[0]
          );
        }

        setResponse(response);

        setId(response?.transactions[0]?.id);

        showSuccessMessage();
      } catch (error) {
        notification.error({
          message: (error as Error).name,
          description: (error as Error).message,
        });
      } finally {
        destroyMessage(loadingKey);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const fetchTransactionCount = useCallback(
    async (filterOptions: KontaxTransactionFilterOptions) => {
      const count = await api.kontax.fetchTransactionCount({
        ...filterOptions,
        verified: false,
      });

      setResponse({ ...response, count });
    },
    [response]
  );

  const refetchTransaction = useCallback(async () => {
    if (!id) return;

    try {
      const response = await fetchTransactionResponseById(id);
      if (response.transactions[0]) {
        response.transactions[0] = await updateTransactionAssets(
          response.transactions[0]
        );
      }
      setResponse(response);
    } catch (error) {
      notification.error({
        message: (error as Error).name,
        description: (error as Error).message,
      });
    }
  }, [id]);

  return (
    <ContentWrapper>
      <TransactionCategorisationView
        refetchTransaction={refetchTransaction}
        serverTransaction={response.transactions[0]}
        transactionsCount={response.count}
        fetchNextTransaction={fetchNextTransaction}
        fetchTransactionCount={fetchTransactionCount}
      />
    </ContentWrapper>
  );
};

async function fetchTransactionResponseById(
  id: string
): Promise<FetchTransactionsResponse> {
  const transaction = await api.kontax.fetchTransactionById(id);
  return {
    transactions: transaction ? [transaction] : [],
    count: transaction ? 1 : 0,
  };
}

async function updateTransactionAssets(
  transaction: ITransaction
): Promise<ITransaction> {
  if (transaction.assets?.length || !transaction.hasIntegrationDocument) {
    return transaction;
  }
  const assets = await downloadIntegrationDocument(transaction.id);
  if (!assets?.length) {
    return transaction;
  }
  return {
    ...transaction,
    assets,
  };
}

async function downloadIntegrationDocument(transactionId: string) {
  try {
    const downloadResponse = await api.kontax.downloadIntegrationDocument(
      transactionId
    );
    return [getAsset(downloadResponse?.data)];
  } catch (error) {
    notification.error({
      message: "Error while downloading lexoffice document",
    });
  }
}

export default TransactionView;
