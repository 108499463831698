import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Form } from "antd";

import { EuerFormFieldProps } from "./types";
import CurrencyInput from "../../../../../../common/CurrencyInput";
import { AutoValue, AutoValueWrapper, StyledCol } from "./styledComponenets";
import { currencyFormatter } from "../../../../../../../utils";

export const EuerCurrencyField = ({
  name,
  prefix,
  label,
  value,
  autoValue,
  addonBefore,
  disabled,
  onChange = () => {},
}: EuerFormFieldProps) => {
  const [edit, setEdit] = useState(false);
  const isAutoValueProvided = useMemo(
    () => autoValue !== undefined,
    [autoValue]
  );

  useEffect(() => {
    if (value !== undefined) {
      setEdit(isAutoValueProvided && autoValue !== value);
    }
  }, [value, autoValue, name, isAutoValueProvided]);

  const onValueChange = useCallback(
    (amount: number) => {
      setEdit(Boolean(autoValue && autoValue !== amount));
      onChange(name, amount);
    },
    [autoValue, name, onChange]
  );

  return (
    <>
      <Form.Item name={name} label={label} initialValue={value || autoValue}>
        <CurrencyInput
          value={value}
          addonBefore={!edit && addonBefore ? addonBefore : undefined}
          prefix={prefix}
          onChange={onValueChange}
          disabled={disabled}
        />
      </Form.Item>
      {edit && isAutoValueProvided && (
        <StyledCol span={10} offset={14}>
          <AutoValueWrapper
            onClick={() => onValueChange(autoValue!)}
            className="ant-typography ant-typography-secondary"
          >
            Auto <AutoValue>{currencyFormatter.format(autoValue!)}</AutoValue>
          </AutoValueWrapper>
        </StyledCol>
      )}
    </>
  );
};
