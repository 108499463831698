import React, { useRef, useState, useEffect } from "react";
import classNames from "classnames";

import { InputWrapper, Label, TextAreaWrapper } from "./styledComponents";

const TextArea = ({
  id,
  label,
  value,
  disabled = false,
  invalid = false,
  placeholder,
  searchable,
  onChange,
  maxLength,
  required,
  className,
  withSeparator,
  rows,
  autoFocus = false,
}: {
  id?: string;
  label?: string;
  value?: string | null;
  placeholder?: string;
  disabled?: boolean;
  searchable?: boolean;
  onChange?: (e: any) => void;
  maxLength?: number;
  required?: boolean;
  className?: string;
  withSeparator?: boolean;
  rows?: number;
  autoFocus?: boolean;
  invalid?: boolean;
}) => {
  // Resize automatically the height of the textArea based on its content
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [text, setText] = useState("");
  const [textAreaHeight, setTextAreaHeight] = useState("auto");

  useEffect(() => {
    setText(value || "");
    setTextAreaHeight(`${textAreaRef.current?.scrollHeight}px`);
  }, [text, value]);

  const onChangeHandler = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTextAreaHeight("auto");
    setText(event.target.value);
    if (onChange) onChange(event);
  };

  const inputWrapperClasses = classNames({
    multiline: rows && rows > 1,
    separator: withSeparator,
  });

  return (
    <InputWrapper className={inputWrapperClasses}>
      {label && (
        <Label
          className={searchable ? "link" : ""}
          htmlFor={id}
          onClick={() =>
            searchable &&
            window.open(`https://www.google.com/search?q=${value}`, "_blank")
          }
        >
          {label}
        </Label>
      )}
      <TextAreaWrapper
        id={id}
        value={text}
        disabled={disabled}
        placeholder={placeholder}
        onChange={onChangeHandler}
        maxLength={maxLength}
        required={required}
        className={classNames(className, { invalid })}
        style={{ height: textAreaHeight }}
        ref={textAreaRef}
        rows={rows || 1}
        autoFocus={autoFocus}
      />
    </InputWrapper>
  );
};

export default TextArea;
