import { RightSquareOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { ReactNode } from "react";
import styled from "styled-components";

import { UserPages } from "../Mandanten/Common/UserDetails/enums";

const StyledRightSquareOutlined = styled(RightSquareOutlined)`
  svg {
    vertical-align: initial;
  }
`;

export const UserBusinessAssetsLink = (props: {
  email: string;
  children?: ReactNode;
}) => {
  return (
    <Button
      type="link"
      href={`/?email=${encodeURIComponent(props.email)}&userPage=${
        UserPages.ASSETS
      }`}
      target="_blank"
      icon={<StyledRightSquareOutlined />}
    >
      Go to Business Assets
    </Button>
  );
};
