import { gql, MutationHookOptions, useMutation } from "@apollo/client";

import { EuerDeclarationStatus } from "../schema.generated";
import { EuerDeclarationFields } from "../types";

export interface UpdateEuerDeclarationInput {
  status: EuerDeclarationStatus;
  fields: EuerDeclarationFields;
}

export interface UpdateEuerDeclarationMutationVariables {
  email: string;
  year: number;
  payload: UpdateEuerDeclarationInput;
}

export interface UpdateEuerDeclarationMutationResult {
  updateEuerDeclaration: {
    status: EuerDeclarationStatus;
    statusUpdatedAt: Date;
    fields: EuerDeclarationFields;
  };
}

export const UPDATE_EUER_DECLARATION_MUTATION = gql`
  mutation updateEuerDeclaration(
    $email: String!
    $year: Int!
    $payload: UpdateEuerDeclarationInput!
  ) {
    updateEuerDeclaration(email: $email, year: $year, payload: $payload) {
      status
      statusUpdatedAt
      fields
    }
  }
`;

export const useUpdateEuerDeclarationMutation = (
  options?: MutationHookOptions<
    UpdateEuerDeclarationMutationResult,
    UpdateEuerDeclarationMutationVariables
  >
) =>
  useMutation<
    UpdateEuerDeclarationMutationResult,
    UpdateEuerDeclarationMutationVariables
  >(UPDATE_EUER_DECLARATION_MUTATION, options);
