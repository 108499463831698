import React, { useState } from "react";
import { Button, Modal, Checkbox, Space } from "antd";

import VatCategorySelect from "../../../../TransactionView/VatCategorySelect";
import CategorySelect from "../../../../TransactionView/CategorySelect";
import {
  BUSINESS_ASSET_CATEGORIES,
  EXTERNAL_TRANSACTION_CATEGORIES,
} from "../../../../../../utils";
import { ContentWrapper, FooterWrapper } from "./styled";
import { getCompatibleVatCategories } from "../../../../../../utils/vatCategories";

const RESET = "RESET";

const CategorizeBatchPopup = ({
  isPopupVisible,
  selectedIds,
  closePopup,
  updateMultipleDatevData,
}: {
  isPopupVisible: boolean;
  selectedIds: string[];
  closePopup: () => void;
  updateMultipleDatevData: ({
    categoryCode,
    vatCategoryCode,
    shouldVerify,
  }: {
    categoryCode: string;
    vatCategoryCode: string;
    shouldVerify: boolean;
  }) => Promise<void>;
}) => {
  const [categoryCode, setCategoryCode] = useState<string | null>(null);
  const [vatCategoryCode, setVatCategoryCode] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [shouldVerify, setShouldVerify] = useState<boolean>(false);

  const resetAndClose = () => {
    setCategoryCode(null);
    setVatCategoryCode(null);
    setShouldVerify(false);
    closePopup();
    setIsLoading(false);
  };

  const cannotSubmit = isLoading || !categoryCode || !vatCategoryCode;

  const categorizeBatch = async () => {
    if (cannotSubmit) {
      return;
    }

    setIsLoading(true);

    try {
      await updateMultipleDatevData({
        categoryCode,
        vatCategoryCode,
        shouldVerify,
      });
    } finally {
      resetAndClose();
    }
  };

  const handleCategoryCodeSelect = (categoryCode: string) => {
    // If select "-- RESET --" option in category code, reset both fields.
    if (!categoryCode || categoryCode === RESET) {
      setCategoryCode(null);
      setVatCategoryCode(null);
      return;
    }

    setCategoryCode(categoryCode);

    // At this moment, we don't know if client is business owner or not, because "vatYearPaymentFrequency" is not defined anywhere.
    // So we intentionally set "isSmallBusinessOwner" to false.
    const vatCategories = getCompatibleVatCategories(categoryCode, false);
    const vatCategoryIds = vatCategories.map(([id]) => id);

    // If there is only one matching vatCategory, select it
    if (vatCategoryIds.length === 1) {
      setVatCategoryCode(vatCategoryIds[0]);
      return;
    }

    // If the vatCategory is not in the list, unset it.
    if (!vatCategoryIds.includes(vatCategoryCode || "")) {
      setVatCategoryCode(null);
    }
  };

  return (
    <Modal
      data-test="externalTransactionPopup"
      title={`Categorize ${selectedIds.length} transactions`}
      onCancel={resetAndClose}
      visible={isPopupVisible}
      centered
      width="700px"
      footer={
        <FooterWrapper>
          <Button onClick={resetAndClose} disabled={isLoading}>
            Cancel
          </Button>
          <Space>
            <Checkbox
              checked={shouldVerify}
              onChange={() => setShouldVerify(!shouldVerify)}
              disabled={cannotSubmit}
            >
              Verify
            </Checkbox>
            <Button
              disabled={cannotSubmit}
              onClick={categorizeBatch}
              data-test="confirmCategorizeButton"
            >
              Confirm
            </Button>
          </Space>
        </FooterWrapper>
      }
    >
      <ContentWrapper data-test="categorizeBatchPopup">
        <CategorySelect
          value={categoryCode}
          withResetOption
          onChangeHandler={handleCategoryCodeSelect}
          // We want to show outgoing categories first, thats why we have an negative amount here
          transactionAmount={-1}
          disabledOptions={[
            ...EXTERNAL_TRANSACTION_CATEGORIES,
            ...BUSINESS_ASSET_CATEGORIES,
          ]}
        />
        <VatCategorySelect
          value={vatCategoryCode}
          withResetOption
          onChangeHandler={setVatCategoryCode}
          kontaxCategory={categoryCode}
        />
      </ContentWrapper>
    </Modal>
  );
};

export default CategorizeBatchPopup;
