import type * as Types from "../../schema.generated";

import { gql } from "@apollo/client";
import { HomeOfficeDocumentFragmentDoc } from "../../fragments/homeOfficeDocument.generated";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type HomeOfficeDocumentsQueryVariables = Types.Exact<{
  email: Types.Scalars["String"];
  type: Types.EuerDeclarationSubformType;
  year: Types.Scalars["Int"];
}>;

export type HomeOfficeDocumentsQuery = {
  __typename?: "Query";
  euerDeclarationSubform?: {
    __typename?: "EuerDeclarationSubform";
    homeOfficeDocuments: Array<{
      __typename?: "HomeOfficeDocument";
      id: string;
      type: Types.HomeOfficeDocumentType;
      note: string;
      syncedFromQuestionnaireAt?: string | null;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        name: string;
        filetype: string;
        thumbnail: string;
        fullsize: string;
      }>;
    }>;
  } | null;
};

export const HomeOfficeDocumentsDocument = gql`
  query homeOfficeDocuments(
    $email: String!
    $type: EuerDeclarationSubformType!
    $year: Int!
  ) {
    euerDeclarationSubform(email: $email, type: $type, year: $year) {
      homeOfficeDocuments {
        ...HomeOfficeDocument
      }
    }
  }
  ${HomeOfficeDocumentFragmentDoc}
`;

/**
 * __useHomeOfficeDocumentsQuery__
 *
 * To run a query within a React component, call `useHomeOfficeDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeOfficeDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeOfficeDocumentsQuery({
 *   variables: {
 *      email: // value for 'email'
 *      type: // value for 'type'
 *      year: // value for 'year'
 *   },
 * });
 */
export function useHomeOfficeDocumentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    HomeOfficeDocumentsQuery,
    HomeOfficeDocumentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    HomeOfficeDocumentsQuery,
    HomeOfficeDocumentsQueryVariables
  >(HomeOfficeDocumentsDocument, options);
}
export function useHomeOfficeDocumentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HomeOfficeDocumentsQuery,
    HomeOfficeDocumentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    HomeOfficeDocumentsQuery,
    HomeOfficeDocumentsQueryVariables
  >(HomeOfficeDocumentsDocument, options);
}
export type HomeOfficeDocumentsQueryHookResult = ReturnType<
  typeof useHomeOfficeDocumentsQuery
>;
export type HomeOfficeDocumentsLazyQueryHookResult = ReturnType<
  typeof useHomeOfficeDocumentsLazyQuery
>;
export type HomeOfficeDocumentsQueryResult = Apollo.QueryResult<
  HomeOfficeDocumentsQuery,
  HomeOfficeDocumentsQueryVariables
>;
