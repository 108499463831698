import { gql, QueryHookOptions, useLazyQuery } from "@apollo/client";

import { ChangeLog } from "../types";

export interface ChangeLogsQueryVariables {
  modelName: string;
  recordIds: string[];
}

export interface ChangeLogsQueryResult {
  changeLogs: ChangeLog[];
}

export const CHANGE_LOGS_QUERY = gql`
  query ChangeLogs($modelName: String!, $recordIds: [String!]!) {
    changeLogs(modelName: $modelName, recordIds: $recordIds) {
      recordId
      changes
      changedBy
      changedAt
      modelName
    }
  }
`;

export const useChangeLogsQuery = (
  options?: QueryHookOptions<ChangeLogsQueryResult, ChangeLogsQueryVariables>
) =>
  useLazyQuery<ChangeLogsQueryResult, ChangeLogsQueryVariables>(
    CHANGE_LOGS_QUERY,
    options
  );
