import { gql, MutationHookOptions, useMutation } from "@apollo/client";

import { DatevData } from "../../types";

export interface ToggleDatevDataVerifiedFlagMutationVariables {
  id: string;
}

export interface ToggleDatevDataVerifiedFlagMutationResult {
  toggleDatevDataVerifiedFlag: DatevData;
}

export const TOGGLE_DATEV_DATA_VERIFICATION_FLAG_MUTATION = gql`
  mutation toggleDatevDataVerifiedFlag($id: ID!) {
    toggleDatevDataVerifiedFlag(id: $id) {
      id
      skrMode
      bookingMode
      bookkeepingTool
      amount
      direction
      account
      offsetAccount
      paymentDate
      buKey
      meta1
      description
      kkr
      vatCategoryCode
      verifiedAt
      assets {
        filetype
        fullsize
      }
    }
  }
`;

export const useToggleDatevDataVerifiedFlagMutation = (
  options?: MutationHookOptions<
    ToggleDatevDataVerifiedFlagMutationResult,
    ToggleDatevDataVerifiedFlagMutationVariables
  >
) =>
  useMutation<
    ToggleDatevDataVerifiedFlagMutationResult,
    ToggleDatevDataVerifiedFlagMutationVariables
  >(TOGGLE_DATEV_DATA_VERIFICATION_FLAG_MUTATION, options);
