import React from "react";
import { Select } from "antd";

import { StyledSelect } from "./styledComponents";

export interface DeclarationStatusSelectProps<T extends string> {
  value: T;
  options: T[];
  mapping: Record<T, { label: string; hidden?: boolean }>;
  onChange: (value: T) => void;
  disabled?: boolean;
  error?: boolean;
}

const DeclarationStatusSelect = <T extends string>({
  value,
  options,
  mapping,
  onChange,
  disabled,
  error,
}: DeclarationStatusSelectProps<T>) => {
  return (
    <StyledSelect
      data-test="anlagevermogenStatusSelect"
      value={value}
      status={error ? "error" : ""}
      onChange={onChange}
      disabled={disabled}
    >
      {options
        .filter((value) => !mapping[value].hidden)
        .map((value) => (
          <Select.Option key={value} value={value} data-test={value}>
            {mapping[value].label}
          </Select.Option>
        ))}
    </StyledSelect>
  );
};

export default DeclarationStatusSelect;
