import styled from "styled-components";

export const PipedriveDataContainer = styled.div`
  grid-column: 1/-1;
  flex-direction: column;
  > .ant-collapse > .ant-collapse-item {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0em;
    text-align: left;
  }

  // The arrow is a bit above the text when font weight >= 400
  > .ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow
    > svg {
    margin-top: -0.3em;
  }
`;

export const PipedriveData = styled.p`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.85);
  word-break: break-all;
  white-space: pre-line;
`;
