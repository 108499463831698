import { gql, QueryHookOptions, useQuery } from "@apollo/client";

export interface VatAnnualDeclarationDraftQueryVariables {
  email: string;
  year: number;
}

export interface VatAnnualDeclarationDraftQueryResult {
  vatAnnualDeclaration: {
    draft: {
      message: string;
      processResult: string;
      pdf: string;
    };
  };
}

export const VAT_ANNUAL_DECLARATION_DRAFT_QUERY = gql`
  query vatAnnualDeclarationDraft($email: String!, $year: Int!) {
    vatAnnualDeclaration(email: $email, year: $year) {
      draft {
        message
        processResult
        pdf
      }
    }
  }
`;

export const useVatAnnualDeclarationDraftQuery = (
  options?: QueryHookOptions<
    VatAnnualDeclarationDraftQueryResult,
    VatAnnualDeclarationDraftQueryVariables
  >
) =>
  useQuery<
    VatAnnualDeclarationDraftQueryResult,
    VatAnnualDeclarationDraftQueryVariables
  >(VAT_ANNUAL_DECLARATION_DRAFT_QUERY, options);
