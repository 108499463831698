import React from "react";
import { Col, Row, Typography } from "antd";

import { HomeOfficeExpense } from "../../../../../../../../api/graphql/schema.generated";

import DocumentsTable from "./DocumentsTable";
import ExpensesTable from "./ExpensesTable";
import { StyledParagraph } from "./styledComponents";

const { Title } = Typography;

interface Props {
  taxYear: number;
  email?: string | null;
  officeAreaShare: number;
  refetchHomeOfficeExpense: () => void;
  homeOfficeExpenses: HomeOfficeExpense[];
}

const ExpensesTab = ({
  taxYear,
  email,
  officeAreaShare,
  refetchHomeOfficeExpense,
  homeOfficeExpenses,
}: Props) => {
  return (
    <>
      <ExpensesTable
        taxYear={taxYear}
        email={email}
        officeAreaShare={officeAreaShare}
        homeOfficeExpenses={homeOfficeExpenses}
        refetchHomeOfficeExpense={refetchHomeOfficeExpense}
      />
      <Row style={{ marginTop: 30 }}>
        <Col>
          <Row>
            <Title level={5}>Sonstiges</Title>
          </Row>
          <Row>
            <StyledParagraph>
              dh. Belege bei denen es sich um keine Ausgaben/Rechnungen handelt
              (zB. Grundriss, Fotos...)
            </StyledParagraph>
          </Row>
        </Col>
      </Row>
      <DocumentsTable taxYear={taxYear} email={email} />
    </>
  );
};

export default ExpensesTab;
