import type * as Types from "../../schema.generated";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type UnassignTaxCaseMutationVariables = Types.Exact<{
  taxCaseId: Types.Scalars["ID"];
}>;

export type UnassignTaxCaseMutation = {
  __typename?: "Mutation";
  unassignTaxCase: {
    __typename?: "TaxBoardTaxCase";
    id: string;
    year: number;
    assignee?: string | null;
    deadline: string;
  };
};

export const UnassignTaxCaseDocument = gql`
  mutation unassignTaxCase($taxCaseId: ID!) {
    unassignTaxCase(taxCaseId: $taxCaseId) {
      id
      year
      assignee
      deadline
    }
  }
`;
export type UnassignTaxCaseMutationFn = Apollo.MutationFunction<
  UnassignTaxCaseMutation,
  UnassignTaxCaseMutationVariables
>;

/**
 * __useUnassignTaxCaseMutation__
 *
 * To run a mutation, you first call `useUnassignTaxCaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnassignTaxCaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unassignTaxCaseMutation, { data, loading, error }] = useUnassignTaxCaseMutation({
 *   variables: {
 *      taxCaseId: // value for 'taxCaseId'
 *   },
 * });
 */
export function useUnassignTaxCaseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnassignTaxCaseMutation,
    UnassignTaxCaseMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UnassignTaxCaseMutation,
    UnassignTaxCaseMutationVariables
  >(UnassignTaxCaseDocument, options);
}
export type UnassignTaxCaseMutationHookResult = ReturnType<
  typeof useUnassignTaxCaseMutation
>;
export type UnassignTaxCaseMutationResult =
  Apollo.MutationResult<UnassignTaxCaseMutation>;
export type UnassignTaxCaseMutationOptions = Apollo.BaseMutationOptions<
  UnassignTaxCaseMutation,
  UnassignTaxCaseMutationVariables
>;
