import React, { useCallback, ChangeEvent } from "react";

import { InputWrapper, Label, FormTitle } from "../../common/styledComponents";
import DateInput from "../../common/TransactionFilters/DateInput";
import BusinessAssetTypeSelect from "../../BusinessAsset/BusinessAssetTypeSelect";
import BusinessAssetClassSelect from "../../BusinessAsset/BusinessAssetClassSelect";
import {
  AssetClassCustomField,
  DepreciationYearsField,
  DepreciationYearsEditableField,
} from "../../BusinessAsset/BusinessAssetFields";
import { BusinessAssetFormField, IBusinessAssetForm } from "../../../types";
import movableAssets from "../../BusinessAsset/movableAssets.json";
import immovableAssets, {
  LAND_ASSET_CLASS,
} from "../../BusinessAsset/immovableAssets";
import {
  isImmovableAsset,
  isIntangibleAsset,
  isCustomAssetClass,
  isNoteRequired,
} from "../../BusinessAsset/utils";
import TextArea from "../../common/TextArea";

type Props = {
  isSubmitClicked: boolean;
} & IBusinessAssetForm;

const BusinessAsset = ({
  businessAssetFormData,
  onFieldChange,
  isSubmitClicked,
}: Props) => {
  const {
    assetType,
    assetClass,
    assetClassCustom,
    purchaseDate,
    purchaseDateMax,
    depreciationYears,
    depreciationYearsEditable,
    note,
  } = businessAssetFormData;

  const isAssetTypeImmovable = isImmovableAsset(assetType);
  const isAssetTypeIntangible = isIntangibleAsset(assetType);
  const isAssetClassCustom = isCustomAssetClass(assetClass);
  const isInvalid = useCallback(
    (value?: string) => isSubmitClicked && !value,
    [isSubmitClicked]
  );

  const shouldRenderEditableDepreciationYearsField =
    isAssetTypeImmovable || isAssetTypeIntangible || isAssetClassCustom;

  const getOnChangeHandler =
    (field: BusinessAssetFormField) =>
    (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) =>
      onFieldChange(field, event.target.value);

  return (
    <>
      <FormTitle>Vermögensgegenstand</FormTitle>

      <InputWrapper>
        <Label htmlFor="assetType">AfA-Klasse</Label>
        <BusinessAssetTypeSelect
          id="assetType"
          value={assetType}
          onChangeHandler={getOnChangeHandler(
            BusinessAssetFormField.ASSET_TYPE
          )}
          invalid={isInvalid(assetType)}
        />
      </InputWrapper>

      {!isAssetTypeIntangible && (
        <InputWrapper>
          <Label htmlFor="assetClass">Anlage-Typ</Label>
          <BusinessAssetClassSelect
            id="assetClass"
            value={assetClass}
            options={isAssetTypeImmovable ? immovableAssets : movableAssets}
            onChange={(value) =>
              onFieldChange(BusinessAssetFormField.ASSET_CLASS, value)
            }
            invalid={isInvalid(assetClass)}
          />
        </InputWrapper>
      )}

      {(isAssetTypeIntangible || isAssetClassCustom) && (
        <AssetClassCustomField
          id="assetClassCustom"
          value={assetClassCustom}
          label={isAssetTypeIntangible ? "Anlage-Typ" : "Beschreibung"}
          onChange={getOnChangeHandler(
            BusinessAssetFormField.ASSET_CLASS_CUSTOM
          )}
          invalid={isInvalid(assetClassCustom)}
        />
      )}

      <TextArea
        id="note"
        label="Bezeichnung"
        value={note}
        placeholder="Genaue Beschreibung des Wirtschaftsguts (Marke und Modell)"
        disabled={false}
        onChange={getOnChangeHandler(BusinessAssetFormField.NOTE)}
        rows={3}
        invalid={isNoteRequired(assetType, assetClass) && isInvalid(note)}
      />

      <InputWrapper>
        <Label htmlFor="purchaseDate">Anschaffungsdatum</Label>
        <div>
          <DateInput
            name="purchaseDate"
            value={purchaseDate}
            max={purchaseDateMax}
            onChange={getOnChangeHandler(BusinessAssetFormField.PURCHASE_DATE)}
            invalid={isInvalid(purchaseDate)}
          />
        </div>
      </InputWrapper>

      {
        // land doesn't depreciate
        assetClass !== LAND_ASSET_CLASS &&
          (depreciationYearsEditable &&
          shouldRenderEditableDepreciationYearsField ? (
            <DepreciationYearsEditableField
              id="depreciationYearsEditable"
              value={depreciationYears}
              type="number"
              min={0}
              label="Abschreibungsdauer"
              textAfter="Jahre"
              onChange={getOnChangeHandler(
                BusinessAssetFormField.DEPRECIATION_YEARS
              )}
              invalid={isInvalid(depreciationYears)}
            />
          ) : (
            <DepreciationYearsField
              disabled
              className="no-background"
              id="depreciationYears"
              value={depreciationYears}
              label="Abschreibungsdauer"
              textAfter={depreciationYears && "Jahre"}
            />
          ))
      }
    </>
  );
};

export default BusinessAsset;
