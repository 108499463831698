import React from "react";
import { LeftCircleOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import styled from "styled-components";

import colors from "../../../../../../themes/colors";

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  color: ${colors.black};

  &:hover {
    color: ${colors.black};
  }
`;

const BackButton = ({
  text,
  to,
}: {
  text: string;
  to: { pathname: string; search?: string };
}) => (
  <StyledLink to={to}>
    <LeftCircleOutlined style={{ fontSize: "24px", marginRight: "10px" }} />
    <div>{text}</div>
  </StyledLink>
);

export default BackButton;
