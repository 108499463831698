export const downloadUsingAnchorElement = ({
  url,
  fileName,
  event,
}: {
  url: string;
  fileName: string;
  event: React.MouseEvent;
}) => {
  event.stopPropagation();

  const anchor = document.createElement("a");
  anchor.href = url;
  anchor.target = "_blank";
  anchor.rel = "noreferrer";
  anchor.download = fileName;

  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
};
