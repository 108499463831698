import { gql, MutationHookOptions, useMutation } from "@apollo/client";

export interface DeleteBusinessAssetMutationVariables {
  id: string;
}

export interface DeleteBusinessAssetMutationResult {
  deleteBusinessAsset: {
    success: boolean;
  };
}

export const DELETE_BUSINESS_ASSET_MUTATION = gql`
  mutation deleteBusinessAsset($id: ID!) {
    deleteBusinessAsset(id: $id) {
      success
    }
  }
`;

export const useDeleteBusinessAssetMutation = (
  options?: MutationHookOptions<
    DeleteBusinessAssetMutationResult,
    DeleteBusinessAssetMutationVariables
  >
) =>
  useMutation<
    DeleteBusinessAssetMutationResult,
    DeleteBusinessAssetMutationVariables
  >(DELETE_BUSINESS_ASSET_MUTATION, options);
