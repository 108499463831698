import flatten from "lodash/flatten";
import { DragEvent } from "react";

import parseCSV from "./parseCsv";
import combineTransactions from "./combineTransactions";
import { DatevRawData, BookingMode } from "../../../../../../../api/graphql";

export interface UploadFile extends File {
  uid: string;
}

export const parseCSVFiles = async (
  bookingMode: BookingMode,
  csvFiles: UploadFile[]
): Promise<DatevRawData[]> => {
  const results = await Promise.all(
    csvFiles.map(async (csv) => {
      const parsed = await parseCSV(csv);
      // combine transactions if agent select Debitoren- und Kreditorenbuchhaltung
      return bookingMode === BookingMode.ACCOUNT_PAYABLE
        ? combineTransactions(parsed)
        : parsed;
    })
  );

  return flatten(results);
};

const getValidReceiptFiles = (fileArray: UploadFile[]): UploadFile[] =>
  fileArray.filter(({ name }) => name.match(/(jpg|jpeg|pdf|png|tiff)$/i));

const getCsvFiles = (fileArray: UploadFile[]): UploadFile[] =>
  fileArray.filter(({ name }) => name.match(/(csv)$/i));

export const getCsvAndReceiptFiles = (
  e: DragEvent<HTMLDivElement>
): { csvFiles: UploadFile[]; receiptFiles: UploadFile[] } => {
  const { files } = e.dataTransfer || {};
  const fileArray = Array.from(files) as UploadFile[];
  const csvFiles = getCsvFiles(fileArray);
  const receiptFiles = getValidReceiptFiles(fileArray);

  return {
    csvFiles,
    receiptFiles,
  };
};
