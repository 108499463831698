import { DownOutlined, LoadingOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { EventDataNode } from "antd/lib/tree";
import { notification, Typography } from "antd";

import { useFetchDocuWareDocumentsQuery } from "../../../../../../api/graphql/queries/documents/docuwareDocuments.generated";
import kontaxApi from "../../../../../../api";
import DocuwareDocumentPreview from "./DocuwareDocumentPreview";
import { ILocalAsset } from "../../../../../../types";
import { DocuwareDocumentsContainer, StyledTree } from "./styledComponent";
import { DocuWareDocument } from "../../../../../../api/graphql/schema.generated";

export const DocuwareDocuments = ({ accountId }: { accountId: number }) => {
  const {
    loading: isLoadingDocuwareDocuments,
    data: docuwareDocumentsResponse,
  } = useFetchDocuWareDocumentsQuery({
    variables: {
      accountId,
    },
  });

  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([]);
  const [assets, setAssets] = useState<Record<string, ILocalAsset>>({});
  const [isFetchingDocument, setIsFetchingDocument] = useState<boolean>(false);

  if (isLoadingDocuwareDocuments) {
    return (
      <DocuwareDocumentsContainer
        style={{ alignItems: "center", display: "flex" }}
      >
        <LoadingOutlined style={{ fontSize: 24, margin: "0 auto" }} spin />
      </DocuwareDocumentsContainer>
    );
  }

  if (!docuwareDocumentsResponse?.docuWareDocuments?.documents?.length) {
    return (
      <DocuwareDocumentsContainer>
        <p>No Docuware documents</p>
      </DocuwareDocumentsContainer>
    );
  }

  const docuwareCategories = Array.from(
    new Set(
      docuwareDocumentsResponse.docuWareDocuments.documents.map(
        ({ category }: DocuWareDocument) => category
      )
    )
  );

  const getDocuwareDocument = async (documentId: string) => {
    const document = docuwareDocumentsResponse.docuWareDocuments.documents.find(
      (document: DocuWareDocument) => document.id.toString() === documentId
    );
    if (!document) {
      return;
    }

    setIsFetchingDocument(true);

    try {
      const response = await kontaxApi.kontax.getDocuwareDocument(documentId);
      const extension =
        document.name?.split(".").pop() ||
        getFileExtension(response.headers["content-type"]);
      const documentName = document.name || `${document.id}.${extension}`;

      setAssets({
        ...assets,
        [documentId]: {
          name: documentName,
          filetype: extension,
          file: new File([response.data], documentName, {
            type: extension,
          }),
        },
      });
    } catch (err) {
      notification.error({
        message: `An error occurred: Could not fetch file: ${err}`,
      });
    } finally {
      setIsFetchingDocument(false);
    }
  };

  const isDocument = (key: string) => /^\d+$/.test(key);

  const onSelectTreeNode = (
    _: React.Key[],
    info: {
      event: "select";
      selected: boolean;
      node: EventDataNode<any>;
      selectedNodes: any[];
      nativeEvent: MouseEvent;
    }
  ) => {
    const { key } = info.node;

    let newExpandedKeys = [...expandedKeys];
    if (newExpandedKeys.includes(key)) {
      newExpandedKeys = newExpandedKeys.filter((k) => k !== key);
    } else {
      if (isDocument(key)) {
        getDocuwareDocument(key);
        newExpandedKeys = newExpandedKeys.filter(
          (k) => !isDocument(k as string)
        );
      }
      newExpandedKeys.push(key);
    }

    setExpandedKeys(newExpandedKeys);
  };

  return (
    <DocuwareDocumentsContainer>
      <h5>Docuware documents</h5>
      {docuwareCategories.map((category) => {
        const documents =
          docuwareDocumentsResponse.docuWareDocuments.documents.filter(
            (document) => document.category === category
          );

        return (
          <StyledTree
            key={category}
            motion={false}
            virtual={false}
            expandedKeys={expandedKeys}
            onSelect={onSelectTreeNode}
            showLine
            className="category-node"
            switcherIcon={<DownOutlined />}
            treeData={[
              {
                title: <Typography.Text strong>{category}</Typography.Text>,
                key: category,
                className: "document-node",
                selectable: true,
                children: documents.map((document) => {
                  return {
                    title: () => <p>{document.name}</p>,
                    key: document.id,
                    showIcon: false,
                    children: [
                      {
                        key: `asset-${document.id}`,
                        title: (
                          <DocuwareDocumentPreview
                            asset={assets[document.id]}
                            isLoading={isFetchingDocument}
                          />
                        ),
                        checkable: false,
                        selectable: false,
                      },
                    ],
                  };
                }),
              },
            ]}
          />
        );
      })}
    </DocuwareDocumentsContainer>
  );
};

const contentTypeToExtensionMap: Record<string, string> = {
  "image/jpeg": "jpg",
  "image/jpg": "jpg",
  "image/png": "png",
  "image/gif": "gif",
  "image/bmp": "bmp",
  "application/pdf": "pdf",
  "text/plain": "txt",
};

function getFileExtension(contentType: string) {
  const type = contentType.split(";")[0];
  return contentTypeToExtensionMap[type];
}
