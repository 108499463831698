import React, { useState, ChangeEvent, useMemo, Fragment } from "react";

import { Radio, RadioChangeEvent } from "antd";

import {
  IFormattedBusinessAsset,
  DepreciableCategory,
  BusinessAssetType,
  BusinessAssetFormField,
  IUser,
  PaymentFrequency,
  SMALL_BUSINESS_PAYMENT_FREQUENCIES,
} from "../../../../../../types";
import { Button, Select } from "../../../../../common/styledComponents";
import {
  EditFormContainer,
  Label,
  RowGroup,
  Fieldset,
  CategoryContainer,
} from "./styledComponents";
import DateInput from "../../../../../common/TransactionFilters/DateInput";
import FormField from "../../../../../common/FormField";
import CurrencyInput from "../../../../../common/CurrencyInput";
import CategorySelect from "../../../../TransactionView/CategorySelect";
import BusinessAssetUpdateForm from "./BusinessAssetUpdateForm";
import { EditMode } from "./constants";
import { EditFormState } from "./types";
import { CUSTOM_ASSET_CLASS } from "../../../../../BusinessAsset/constants";
import {
  eurosToCents,
  formatDEAmount,
  getCategoryName,
  allCategoriesRaw,
  BUSINESS_ASSET_CATEGORIES,
} from "../../../../../../utils";
import {
  shouldSaveButtonDisabled,
  getFormOptionClassName,
  getInputValue,
  lookupAssetClass,
  getDepreciationYears,
  isAssetType,
  isEditMode,
  isExitReason,
  mapExitReasonEnum,
} from "./utils";
import { getBerlinMomentTimezone } from "../../../../../common/helpers";
import DeleteConfirmationPopup from "../../../../../common/DeleteConfirmationPopup";
import TextArea from "../../../../../common/TextArea";
import { ExitReason } from "../../../../../../api/graphql/schema.generated";
import { isNoteRequired } from "../../../../../BusinessAsset/utils";

export const getResetAssetFields = ({
  categoryCode,
  assetType,
}: {
  categoryCode: string | null;
  assetType?: BusinessAssetType;
}): {
  assetClass: string;
  assetClassCustom: string;
  depreciationYears: string;
} => ({
  assetClass:
    assetType === BusinessAssetType.INTANGIBLE ? CUSTOM_ASSET_CLASS : "",
  assetClassCustom: "",
  // If category is OVER_250, asset depreciates immediately, hence 0
  depreciationYears: categoryCode === DepreciableCategory.OVER_250 ? "0" : "",
});

/**
 * The list of edit options for business asset.
 * The options include update, depreciate (by marking as sold, lost, etc.), and delete.
 */
const EditBusinessAsset = ({
  businessAsset,
  onUpdate,
  onDelete,
  onCancel,
  isSaving,
  isEditable,
  user,
  isDepreciated,
}: {
  businessAsset: IFormattedBusinessAsset;
  onUpdate: Function;
  onDelete: Function;
  onCancel: Function;
  isSaving: boolean;
  isEditable: boolean;
  user: IUser;
  isDepreciated: boolean;
}) => {
  const isSmallBusinessOwner = useMemo(
    () =>
      SMALL_BUSINESS_PAYMENT_FREQUENCIES.includes(
        user.vatPaymentFrequency as PaymentFrequency
      ),
    [user.vatPaymentFrequency]
  );

  const initState: EditFormState = useMemo(() => {
    // If asset class is listed in either immovable or movable Assets list,
    // Then we consider it's not a custom asset class.
    const isAssetClassListed = !!lookupAssetClass(businessAsset.assetClass);

    return {
      exitDate: "",
      exitAmount: 0,
      isExitedWithVat: !isSmallBusinessOwner,
      note: businessAsset.note || "",
      assetType: businessAsset.assetType,
      assetClass: isAssetClassListed
        ? businessAsset.assetClass
        : CUSTOM_ASSET_CLASS,
      assetClassCustom: isAssetClassListed ? "" : businessAsset.assetClass,
      depreciationYears: businessAsset.depreciationPeriodYears.toString(),
    };
  }, [
    businessAsset.assetClass,
    businessAsset.note,
    businessAsset.assetType,
    businessAsset.depreciationPeriodYears,
    isSmallBusinessOwner,
  ]);

  const hasAssociatedTransaction = useMemo(
    () => !!businessAsset?.metaData,
    [businessAsset]
  );

  const [editMode, setEditMode] = useState<EditMode>(EditMode.NONE);
  const [exitReason, setExitReason] = useState<ExitReason>();
  const [formFields, setFormFields] = useState<EditFormState>(initState);
  const [categoryCode, setCategoryCode] = useState<string | null>(
    businessAsset.categoryCode
  );
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState<boolean>(false);
  const [depreciationYearsEditable, setDepreciationYearsEditable] =
    useState<boolean>(
      businessAsset.categoryCode === DepreciableCategory.OVER_800
    );

  const updateOption = (e: ChangeEvent<any>) => {
    const selectedOption: string = getInputValue(e);

    if (isEditMode(selectedOption)) {
      setEditMode(selectedOption);
      setExitReason(undefined);
    }

    if (isExitReason(selectedOption)) {
      setEditMode(EditMode.EXIT);
      setExitReason(selectedOption);
    }
  };

  const updateFormInput = (field: string) => (e: ChangeEvent<any>) => {
    setFormFields({
      ...formFields,
      [field]: getInputValue(e),
    });
  };

  const updateExitAmount = (value: number) => {
    setFormFields({
      ...formFields,
      exitAmount: value,
    });
  };

  const updateAssetType = (value: string) => {
    if (isAssetType(value)) {
      setFormFields({
        ...formFields,
        ...getResetAssetFields({ categoryCode, assetType: value }),
        assetType: value,
      });
    }
  };

  const updateCategory = (newCategoryCode: string | null) => {
    // Don't allow updating business asset's category if it is associated with a transaction or a split
    if (hasAssociatedTransaction) {
      return;
    }

    setCategoryCode(newCategoryCode);
    setDepreciationYearsEditable(
      newCategoryCode === DepreciableCategory.OVER_800
    );
    setFormFields({
      ...formFields,
      ...getResetAssetFields({
        categoryCode: newCategoryCode,
        assetType: formFields.assetType,
      }),
    });
  };

  const updateAssetClass = (value: string) => {
    setFormFields({
      ...formFields,
      assetClass: value,
      assetClassCustom: "",
      depreciationYears: getDepreciationYears(value, categoryCode),
    });
  };
  const handleNoteChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setFormFields({
      ...formFields,
      note: event.target.value,
    });
  };

  /**
   * Updates the form data based on the provided field name and value (on the event).
   */
  const onBusinessAssetFormDataChange = (
    field: BusinessAssetFormField,
    value: string
  ) => {
    switch (field) {
      case BusinessAssetFormField.ASSET_CLASS:
        updateAssetClass(value);
        break;
      case BusinessAssetFormField.ASSET_TYPE:
        updateAssetType(value);
        break;
      case BusinessAssetFormField.ASSET_CLASS_CUSTOM:
      case BusinessAssetFormField.DEPRECIATION_YEARS:
      case BusinessAssetFormField.NOTE:
        setFormFields({
          ...formFields,
          [field]: value,
        });
        break;
    }
  };

  const handleDeleteAsset = async () => {
    await onDelete(businessAsset.id);
    setIsDeletePopupOpen(false);
    // Unselect business asset.
    onCancel();
  };

  const handleSave = async () => {
    if (editMode === EditMode.DELETE) {
      setIsDeletePopupOpen(true);
      // Return early, so that we don't edit and save note if it's changed.
      return;
    }

    const noteChanged = businessAsset.note !== formFields.note;
    const notePayload = noteChanged ? { note: formFields.note } : {};

    if (editMode === EditMode.UPDATE) {
      await onUpdate({
        id: businessAsset.id,
        ...(hasAssociatedTransaction ? {} : { categoryCode }),
        assetType: formFields.assetType,
        assetClass:
          formFields.assetClass === CUSTOM_ASSET_CLASS
            ? formFields.assetClassCustom
            : formFields.assetClass,
        depreciationPeriodYears: Number(formFields.depreciationYears),
        ...notePayload,
      });
    }

    if (editMode === EditMode.EXIT) {
      await onUpdate({
        id: businessAsset.id,
        exitReason: mapExitReasonEnum(exitReason!),
        exitDate: formFields.exitDate,

        ...(exitReason === ExitReason.SOLD ||
        exitReason === ExitReason.PRIVATE_USE
          ? {
              exitAmount: eurosToCents(formFields.exitAmount!),
              isExitedWithVat: formFields.isExitedWithVat,
            }
          : {}),
        ...notePayload,
      });
    }

    if (editMode === EditMode.NONE && noteChanged) {
      await onUpdate({ id: businessAsset.id, ...notePayload });
    }
  };

  const handleCancel = () => {
    setFormFields(initState);
    onCancel();
  };

  const deleteConfirmationPopUpText =
    "Achtung, diese Aktion kann nicht rückgängig gemacht werden" +
    (businessAsset.metaData != null
      ? `

    Zudem wird die Buchungskategorie für folgende Transaktion zurückgesetzt:

      ${[
        formatDEAmount(businessAsset.metaData.amount),
        getBerlinMomentTimezone(
          businessAsset.metaData.transactionValutaDate
        ).format("YYYY-MM-DD"),
        businessAsset.metaData.transactionName,
        businessAsset.metaData.transactionDescription,
      ].join(" | ")}
    `
      : "");

  return (
    <EditFormContainer>
      <Fieldset>
        <Label htmlFor="form-option">Bearbeitungsgrund</Label>
        <Select
          value={exitReason || editMode}
          onChange={updateOption}
          className={getFormOptionClassName(editMode)}
          data-test="assetChangeReason"
          id="form-option"
        >
          <option value={EditMode.NONE}>Auswählen</option>
          {(isEditable || isDepreciated) && (
            <Fragment>
              <option value={ExitReason.SOLD}>Verkauf</option>
              <option value={ExitReason.LOST}>
                Ausscheiden (zB. verloren, beschädigt)
              </option>
              <option value={ExitReason.PRIVATE_USE}>Private Nutzung</option>
            </Fragment>
          )}
          {isEditable && (
            <option value={EditMode.UPDATE}>Kategorie bearbeiten</option>
          )}
          <option value={EditMode.DELETE}>Löschen</option>
        </Select>
      </Fieldset>
      {exitReason === ExitReason.SOLD && (
        <>
          <Fieldset>
            <Label htmlFor="exitDate">Verkaufsdatum</Label>
            <DateInput
              id="exitDate"
              name="exitDate"
              value={formFields.exitDate}
              onChange={updateFormInput("exitDate")}
            />
          </Fieldset>
          <Fieldset>
            <Label htmlFor="exitAmount">Verkaufspreis (netto)</Label>
            <CurrencyInput
              id="exitAmount"
              name="exitAmount"
              placeholder="z.B. 500,00 €"
              value={formFields.exitAmount}
              onChange={updateExitAmount}
            />
          </Fieldset>
          <Fieldset>
            <Label htmlFor="vatRate">Ausscheidungsgrund</Label>
            <Radio.Group
              onChange={(e: RadioChangeEvent) =>
                setFormFields({
                  ...formFields,
                  isExitedWithVat: !!e.target.value,
                })
              }
              disabled
              value={!isSmallBusinessOwner}
            >
              <Radio value={true}>19% USt</Radio>
              <Radio value={false}>0% USt</Radio>
            </Radio.Group>
          </Fieldset>
        </>
      )}
      {(exitReason === ExitReason.LOST ||
        exitReason === ExitReason.PRIVATE_USE) && (
        <div>
          <Fieldset>
            <Label htmlFor="exitDate">Ausscheidungsdatum</Label>
            <DateInput
              id="exitDate"
              name="exitDate"
              value={formFields.exitDate}
              onChange={updateFormInput("exitDate")}
            />
          </Fieldset>

          {exitReason === ExitReason.PRIVATE_USE && (
            <>
              <Fieldset>
                <Label htmlFor="exitAmount">Zeitwert netto</Label>
                <CurrencyInput
                  id="exitAmount"
                  name="exitAmount"
                  placeholder="z.B. 500,00 €"
                  value={formFields.exitAmount}
                  onChange={updateExitAmount}
                />
              </Fieldset>
              <Fieldset>
                <Label htmlFor="vatRate">Ausscheidungsgrund</Label>
                <Radio.Group
                  onChange={(e: RadioChangeEvent) =>
                    setFormFields({
                      ...formFields,
                      isExitedWithVat: !!e.target.value,
                    })
                  }
                  disabled={isSmallBusinessOwner}
                  value={
                    isSmallBusinessOwner ? false : formFields.isExitedWithVat
                  }
                >
                  <Radio value={true}>19% USt</Radio>
                  <Radio value={false}>0% USt</Radio>
                </Radio.Group>
              </Fieldset>
            </>
          )}
        </div>
      )}
      {editMode === EditMode.UPDATE && (
        <>
          <FormField
            disabled
            className="no-background"
            id="old-category"
            value={getCategoryName(
              businessAsset.categoryCode,
              allCategoriesRaw
            )}
            label="Alte Kategorie"
          />
          <Fieldset>
            <Label htmlFor="new-category">Neue Kategorie</Label>
            <CategoryContainer>
              <CategorySelect
                id="new-category"
                value={categoryCode}
                onChangeHandler={updateCategory}
                // <CategorySelect> only cares about the sign (+/-).
                transactionAmount={-1}
                focused
                options={BUSINESS_ASSET_CATEGORIES}
                disabled={hasAssociatedTransaction}
                invalid={!categoryCode}
              />
            </CategoryContainer>
          </Fieldset>
          <BusinessAssetUpdateForm
            businessAssetFormData={{
              assetType: formFields.assetType,
              assetClass: formFields.assetClass,
              assetClassCustom: formFields.assetClassCustom,
              note: formFields.note,
              depreciationYears: formFields.depreciationYears,
              depreciationYearsEditable: depreciationYearsEditable,
            }}
            onFieldChange={onBusinessAssetFormDataChange}
          />
        </>
      )}
      {![EditMode.UPDATE, EditMode.DELETE].includes(editMode) && (
        <TextArea
          id="note"
          label="Bezeichnung"
          value={formFields.note}
          placeholder="Genaue Beschreibung des Wirtschaftsguts (Marke und Modell)"
          onChange={handleNoteChange}
          rows={3}
          required={isNoteRequired(formFields.assetType, formFields.assetClass)}
        />
      )}
      <RowGroup className="mt-3">
        <Button
          className="primary"
          data-test="saveAsset"
          onClick={handleSave}
          disabled={
            isSaving ||
            shouldSaveButtonDisabled({
              originalBusinessAsset: businessAsset,
              editMode,
              exitReason,
              formFields,
            })
          }
        >
          Save
        </Button>
        <Button
          data-test="cancelAssetChange"
          className="secondary"
          onClick={handleCancel}
          disabled={isSaving}
        >
          Cancel
        </Button>
      </RowGroup>
      <DeleteConfirmationPopup
        title="Objekt wird unwiederbringlich gelöscht"
        text={deleteConfirmationPopUpText}
        isOpen={isDeletePopupOpen}
        onDelete={handleDeleteAsset}
        onClose={() => setIsDeletePopupOpen(false)}
        disabled={isSaving}
      />
    </EditFormContainer>
  );
};

export default EditBusinessAsset;
