import moment from "moment-timezone";
import { encodeDelimitedArray } from "use-query-params";
import * as queryString from "query-string";
import mapValues from "lodash/mapValues";

import { VatAnnualDeclarationAutoCalculatedValues } from "../../../../../api/graphql";
import colors from "../../../../../themes/colors";
import {
  MonthlyVatDeclarationPeriod,
  QuarterlyVatDeclarationPeriod,
} from "../../../../../types";
import {
  BERLIN_TIMEZONE,
  endOfBerlinDayMoment,
  startOfBerlinDayMoment,
} from "../../../../common/helpers";
import {
  VatAnnualDeclarationPeriodType,
  YearlyVatDeclarationPeriod,
} from "../../types";
import { PATH_BY_EUER_TYPE } from "../EuerDeclaration/pages/EuerDeclarationOverview/helpers";
import { VatAnnualDeclarationSmallBusinessValues } from "../../../../../api/graphql/schema.generated";

const TOTAL_LABELS = {
  REFUND: {
    label: "Erstattung",
    color: colors.darkLimeGreen,
  },
  ADDITIONAL_PAYMENT: {
    label: "Nachzahlung",
    color: colors.darkRed,
  },
};

export const getVatAnnualDeclarationGrandTotalDisplayData = (
  grandTotal: number
) => {
  const isRefund = grandTotal <= 0;

  const { label, color } = isRefund
    ? TOTAL_LABELS.REFUND
    : TOTAL_LABELS.ADDITIONAL_PAYMENT;

  const displayAmount = isRefund
    ? Math.abs(grandTotal) // avoid displaying -0,00 €
    : -grandTotal;

  return { label, color, displayAmount };
};

export const calculateVatAnnualDeclarationGrandTotal = (
  autoCalculatedValues: VatAnnualDeclarationAutoCalculatedValues | null,
  taxOfficeBalance: number | null
) => (autoCalculatedValues?.total || 0) - (taxOfficeBalance || 0);

export const calculateVatAnnualDeclarationSmallBusinessGrandTotal = (
  smallBusinessValues: VatAnnualDeclarationSmallBusinessValues | null,
  taxOfficeBalance: number | null
) => (smallBusinessValues?.salesTax.total || 0) - (taxOfficeBalance || 0);

const isQuarterlyVatDeclarationPeriod = (
  period: VatAnnualDeclarationPeriodType
): period is QuarterlyVatDeclarationPeriod =>
  Object.values(QuarterlyVatDeclarationPeriod).includes(
    period as QuarterlyVatDeclarationPeriod
  );

const isMonthlyVatDeclarationPeriod = (
  period: VatAnnualDeclarationPeriodType
): period is MonthlyVatDeclarationPeriod =>
  Object.values(MonthlyVatDeclarationPeriod).includes(
    period as MonthlyVatDeclarationPeriod
  );

const parseVatDeclarationPeriod = (
  period: VatAnnualDeclarationPeriodType
): ["quarter" | "month" | "year", number | null] => {
  if (isQuarterlyVatDeclarationPeriod(period)) {
    const quarter = Number(period.substring(1));
    return ["quarter", quarter];
  } else if (isMonthlyVatDeclarationPeriod(period)) {
    const month = Number(period);
    return ["month", month - 1]; // subtract 1 because months start from 0
  } else {
    return ["year", null];
  }
};

const getTransactionFilterDateRange = (
  taxYear: number,
  period: VatAnnualDeclarationPeriodType
): { dateFrom: string; dateTo: string } => {
  const date = moment.tz(BERLIN_TIMEZONE).year(taxYear);
  const [periodUnit, periodValue] = parseVatDeclarationPeriod(period);
  if (periodValue !== null) {
    date.set(periodUnit, periodValue);
  }
  const dateFrom = startOfBerlinDayMoment(date.startOf(periodUnit));
  const dateTo = endOfBerlinDayMoment(date.endOf(periodUnit));
  return { dateFrom, dateTo };
};

export const getVerificationViewPath = ({
  email,
  taxYear,
  period = YearlyVatDeclarationPeriod.YEAR,
  transactionFilters: { categoryCodes, vatCategoryCodes },
}: {
  email: string;
  taxYear: number;
  period?: VatAnnualDeclarationPeriodType;
  transactionFilters: {
    categoryCodes?: string[];
    vatCategoryCodes?: string[];
  };
}) => {
  return {
    pathname: PATH_BY_EUER_TYPE.VERIFICATION_VIEW,
    search: queryString.stringify({
      email,
      ...mapValues({ categories: categoryCodes, vatCategoryCodes }, (value) =>
        value ? encodeDelimitedArray(value) : undefined
      ),
      ...getTransactionFilterDateRange(taxYear, period),
      year: taxYear,
    }),
  };
};
