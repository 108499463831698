import React from "react";
import classNames from "classnames";

import { DateInputWrapper } from "./styledComponents";
import { getBerlinMomentTimezone } from "../helpers";

export enum DateInputType {
  Date = "date",
  Month = "month",
  Quarter = "quarter",
  Year = "year",
  Day = "day",
}

interface Props {
  id?: string;
  value?: Date | string | undefined;
  onChange: any;
  name: string;
  max?: Date | string | undefined;
  min?: Date | string | undefined;
  required?: boolean;
  type?: DateInputType;
  className?: string;
  withoutMargin?: boolean;
  invalid?: boolean;
}

const getDateFormatterForInput = (type: DateInputType) => {
  const format = type === DateInputType.Month ? "yyyy-MM" : "YYYY-MM-DD";
  return (date?: Date | string) => getBerlinMomentTimezone(date).format(format);
};

const DateInput = ({
  id,
  name,
  value,
  onChange,
  min,
  max,
  required,
  type = DateInputType.Date,
  className,
  withoutMargin,
  invalid = false,
}: Props) => {
  const format = getDateFormatterForInput(type);
  return (
    <DateInputWrapper
      id={id}
      type={type}
      name={name}
      value={value && format(value)}
      onChange={onChange}
      min={min && format(min)}
      max={max && format(max)}
      required={required}
      className={classNames(className, { invalid })}
      withoutMargin={withoutMargin}
    />
  );
};

export default DateInput;
