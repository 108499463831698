import styled, { css } from "styled-components";
import { Button as AntdButton, DatePicker } from "antd";
import { Content } from "antd/lib/layout/layout";

import { UserOutlined } from "@ant-design/icons";

import colors from "../../themes/colors";
import dropdownArrow from "../../svgs/dropdown-arrow.svg";
import { ReactComponent as CommentSvg } from "../../svgs/comment.svg";
import { ReactComponent as HistorySvg } from "../../svgs/history.svg";

export const Header = styled.div`
  font-size: 24px;
  font-weight: 900;
`;

export const Label = styled.label`
  float: left;
  width: 200px;
  min-width: 200px;
  font-size: 18px;
  &.link {
    cursor: pointer;
    text-decoration: underline;
    &:hover {
      color: ${colors.purple};
    }
  }
`;

export const Button = styled.button`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 10px 15px;
  background: ${colors.purple};
  border: 2px solid ${colors.purple};
  font-size: 18px;
  font-weight: 500;
  line-height: 1;
  color: ${colors.white};
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3);
  transition: all 0.2s ease;
  min-width: 100px;
  margin-right: 10px;
  &:hover:not([disabled]) {
    background: ${colors.darkPurple};
    border: 2px solid ${colors.darkPurple};
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
    color: ${colors.white};
    path {
      stroke: ${colors.white};
    }
  }
  &:disabled {
    opacity: 0.4;
    color: rgba(255, 255, 255, 0.5);
    cursor: not-allowed;
  }
  &.secondary {
    background: ${colors.white};
    color: ${colors.purple};

    &.borderless {
      border: none;
      box-shadow: none;

      &:hover:not([disabled]) {
        color: ${colors.purple};
        background: white;
        border: 2px solid ${colors.darkPurple};
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
      }
    }
  }

  &.btn-danger {
    background: ${colors.darkRed};
    border-color: ${colors.darkRed};
    color: white;

    &:hover:not([disabled]) {
      background: ${colors.darkRed};
      border: 2px solid ${colors.darkRed};
      box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
    }

    &.secondary {
      background: white;
      border-color: ${colors.darkRed};
      color: ${colors.darkRed};

      &:hover:not([disabled]) {
        color: white;
        background: ${colors.darkRed};
        border-color: ${colors.darkRed};
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
      }
    }
  }

  &.btn-dark {
    background: ${colors.veryDarkGreyishBlue};
    border-color: ${colors.veryDarkGreyishBlue};
    color: ${colors.white};

    &:hover:not([disabled]) {
      background: ${colors.black};
      border: 2px solid black;
      box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
    }
  }

  &.btn-lightPurple {
    background: ${colors.lightPurple};
    border-color: ${colors.lightPurple};
    color: ${colors.purple};
    path {
      stroke: ${colors.purple};
    }

    &:disabled {
      opacity: 1;
      color: ${colors.veryDarkGreyishViolet};
      background: ${colors.veryLightGrey};
      border-color: ${colors.veryLightGrey};
      box-shadow: none;
      path {
        stroke: ${colors.veryDarkGreyishViolet};
      }
    }
  }

  &.link {
    background: ${colors.white};
    color: ${colors.purple};
    border: none;
    box-shadow: none;

    &:hover:not([disabled]) {
      border: none;
      box-shadow: none;
      color: ${colors.darkPurple};
      background: ${colors.white};
    }
  }
`;

export const Form = styled.form`
  div #internal-note {
    min-height: 80px;
    margin-bottom: 10px;
  }

  .divider {
    height: 23px;
  }
`;

export const Input = styled.input<{ withoutMargin?: boolean }>`
  flex: 1;
  line-height: 140%;
  height: 50px;
  background: rgba(0, 0, 0, 0.05);
  border-width: 0;
  padding: 0 12px;
  box-sizing: border-box;
  border-radius: 4px;
  transition: all 0.4s ease;
  min-width: 300px;
  &:disabled {
    opacity: 0.7;
    padding: 8px 12px;
  }

  &.no-background {
    background: none;
    border: none;
    color: ${colors.black};
    &:disabled {
      opacity: 1;
    }
  }

  &.small-field {
    min-width: 200px;
  }

  &:required:invalid {
    border: 1px solid ${colors.darkRed};
  }

  &.invalid {
    border: 1px solid ${colors.darkRed};
  }

  &.borderless:focus {
    border: none;
    outline: none;
  }
`;

export const TextAreaWrapper = styled.textarea`
  flex: 1;
  line-height: 140%;
  min-height: 50px;
  background: rgba(0, 0, 0, 0.05);
  padding: 12px;
  box-sizing: border-box;
  border-radius: 4px;
  transition: all 0.4s ease;
  min-width: 300px;
  word-break: break-word;
  resize: none;
  border-width: 0;
  &:disabled {
    min-height: 20px;
    height: fit-content;
    padding: 8px 12px;
    opacity: 0.7;
  }

  &:required:invalid,
  &.invalid {
    border: 1px solid ${colors.darkRed};
  }

  &.no-background {
    background: none;
    border: none;
    color: ${colors.black};
    &:disabled {
      opacity: 1;
    }
  }

  &.separator {
    label {
      color: ${colors.darkGrey};
    }
  }

  &.resizable {
    max-width: 500px;
    max-height: 500px;
    resize: auto;
  }
`;

export const InputWrapper = styled.div`
  margin: 20px 0;
  display: flex;
  align-items: center;

  label {
    margin-bottom: 0;
  }

  &.multiline {
    align-items: start;
  }

  &.separator {
    padding: 8px 0;
    margin: 0;
    border-bottom: 1px solid ${colors.veryLightGrey};
    label {
      color: ${colors.darkGrey};
    }
  }

  &.no-margin {
    margin: 0;
  }
`;

export const Select = styled.select`
  height: 50px;
  padding: 0 32px 0 12px;
  border-radius: 4px;
  min-width: 280px;
  flex: 1;
  cursor: pointer;
  appearance: none;
  background: rgba(0, 0, 0, 0.05) url(${dropdownArrow}) no-repeat 98% center;
  background-size: 24px;
  border-width: 0;

  &:required:invalid,
  &.invalid {
    border: 1px solid ${colors.darkRed};
  }

  &.disabled {
    color: ${colors.darkGrey};
  }

  &.danger {
    color: ${colors.darkRed};
  }
`;

export const Fieldset = styled.fieldset`
  border: none;
  padding: 0;
  &.flex {
    display: flex;
  }
`;

export const LeftAlignedButton = styled(AntdButton)`
  margin: 0 auto 0 0;
`;

export const ButtonsWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 8px;
  justify-content: end;
`;

export const SpinningEmoji = styled.i`
  display: inline-block;
  animation: spin 1s infinite linear;

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }

  @keyframes twist-up {
    to {
      transform: rotateX(360deg);
    }
  }
`;

export const SpinnerWrapper = styled.div`
  padding-bottom: 35px;
`;

export const TransactionSplitLineWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 30px;

  select,
  input {
    width: 320px;
    max-width: 320px;
    margin: 0;
    padding: 0 10px;
  }
`;

export const SplitButton = styled.div`
  display: inline-flex;
  float: right;
`;

export const SplitsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  .ant-input-number-input {
    text-align: right;
  }

  .ant-table-cell {
    vertical-align: bottom;
    &:first-of-type {
      vertical-align: bottom;
    }
  }
`;

export const SplitButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  padding: 12px 8px;
`;

export const InvoiceIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 3px;
`;

export const DatalistInput = styled.input`
  height: 50px;
  padding: 0 32px 0 12px;
  border-radius: 4px;
  min-width: 280px;
  flex: 1;
  cursor: pointer;
  appearance: none;
  background: rgba(0, 0, 0, 0.05) url(${dropdownArrow}) no-repeat 98% center;
  background-size: 24px;
  border-width: 0;

  &:required:invalid {
    border: 1px solid ${colors.darkRed};
  }

  &.invalid {
    border: 1px solid ${colors.darkRed};
  }
`;

export const TextAfterInput = styled.div`
  display: inline-block;
  height: 50px;
  line-height: 50px;
  font-size: 18px;
  margin-left: 10px;
`;

export const FormTitle = styled.div`
  display: flex;
  line-height: 50px;
  margin-bottom: 10px;
  color: ${colors.darkGrey};
  font-size: 18px;
`;

export const ToggleWrapper = styled.div<{
  disabled: boolean;
  noMarginBottom?: boolean;
}>`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-bottom: ${({ noMarginBottom }) => (noMarginBottom ? `0;` : `15px`)};
  ${({ disabled }) => (disabled ? `opacity: 0.7;` : `cursor: pointer;`)}
`;

export const ToggleText = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 10px;

  * {
    display: block;
    margin-bottom: 5px;
  }

  .warning {
    color: ${colors.darkOrange};
  }
`;

export const Toggle = styled.div<{ checked?: boolean; disabled?: boolean }>`
  width: 40px;
  height: 25px;
  padding: 2px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 30px;

  transition: all 0.1s ease;

  &:after {
    display: block;
    content: "";
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background: ${colors.white};
    transition: left 0.1s ease;
  }

  ${({ checked, disabled }) =>
    checked &&
    !disabled &&
    `
      background: ${colors.purple};
      padding-left: ${40 - 20 - 2}px; // track width - (button width + padding)
    `}
`;

interface AntSelectProps {
  readonly width?: number;
}

export const AntSelect = styled.div<AntSelectProps>`
  .ant-form-item {
    margin-bottom: 0;
  }

  .ant-select {
    width: ${(props) => props.width || 320}px;
  }
`;

export const RemainingAmountBox = styled.div`
  min-width: 154px;
  width: fit-content;
  padding: 16px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 2px;
  background: ${colors.lightPurpleOpacity};
  &.green {
    background: #f6ffed50;
  }
`;

export const RemainingAmount = styled.p`
  font-size: 24px;
  margin-bottom: 0;
  color: ${colors.purple};
  &.green {
    color: ${colors.darkLimeGreen};
  }
`;

export const RemainingTitle = styled.p`
  color: ${colors.darkGrey};
`;

export const DatePickerNoPopupHeader = styled.div`
  .ant-picker-header {
    display: none;
  }
`;

export const DateFiltersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 160px;
`;

type ContentWrapperProps = {
  padding?: string;
};

export const ContentWrapper = styled(Content)<ContentWrapperProps>`
  padding: ${(props) => props.padding || "24px"};
  overflow: scroll;
  background: rgb(255, 255, 255);
  height: 100%;
`;

export const StyledDateRangePicker = styled(DatePicker.RangePicker)`
  height: 32px;

  .ant-picker-input > input {
    font-size: 12px;
  }

  .ant-picker-range-separator {
    line-height: 0;
    padding: 0 2px;
  }
`;

export const BookkeepingSourceWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CustomerAttributes = styled.dl`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1.5rem;
  width: 100%;
`;

export const CustomerInformationCard = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
`;

export const CustomerInformationSection = styled.div`
  width: 900px;
  padding: 20px;
  margin: auto;
`;

export const CustomerInformationSectionTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CustomerInformationSectionTitle = styled.h3`
  display: flex;
  justify-content: space-between;
`;

export const SectionTitle = styled.div`
  font-size: 80%;
  font-weight: 500;
  padding-bottom: 0.25rem;
  display: flex;
  align-items: center;
`;

const hoverableIconStyle = css`
  cursor: pointer;
  path {
    fill-opacity: 0.45;
  }
  :hover {
    path {
      fill-opacity: 1;
      fill: ${colors.primaryPurple};
    }
  }
`;

export const HoverableCommentIcon = styled(CommentSvg)`
  ${hoverableIconStyle}
  height: 16px;
  width: 16px;
`;

export const HoverableHistoryIcon = styled(HistorySvg)`
  ${hoverableIconStyle}
`;

export const HoverableUserIcon = styled(UserOutlined)`
  ${hoverableIconStyle}
  path {
    stroke: ${colors.lightGreyOpacity};
  }
  height: 16px;
  width: 16px;
`;
