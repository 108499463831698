import { Asset } from "../../../types";

export enum BookkeepingTool {
  DATEV = "DATEV",
  LEXOFFICE = "LEXOFFICE",
  DEBITOOR = "DEBITOOR",
  SEVDESK = "SEVDESK",
  FREE_TEXT = "FREE_TEXT",
}

export enum SKRMode {
  SKR_03 = "SKR_03",
  SKR_04 = "SKR_04",
}

export enum BookingMode {
  ACCOUNTANT = "ACCOUNTANT",
  ACCOUNT_PAYABLE = "ACCOUNT_PAYABLE",
}

export enum Direction {
  INCOMING = "INCOMING",
  OUTGOING = "OUTGOING",
}

export interface DatevData {
  id: string;
  skrMode: SKRMode;
  bookingMode: BookingMode;
  bookkeepingTool: BookkeepingTool;
  amount: number;
  direction: Direction;
  account: string;
  offsetAccount: string;
  buKey: string;
  paymentDate: string;
  meta1: string;
  description: string;
  kkr: string;
  vatCategoryCode: string;
  verifiedAt?: string;
  assets?: Asset[];
}
