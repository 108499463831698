import React, { useCallback, useMemo } from "react";
import { Spin } from "antd";

import { VatTableSpace } from "../styledComponents";
import SmallBusinessIncomeTable, {
  SmallBusinessIncome,
} from "./SmallBusinessIncomeTable";
import SmallBusinessFieldsTable from "./SmallBusinessFieldsTable";
import { VatAnnualDeclaration } from "../../../../../../api/graphql";
import { VatAnnualDeclarationPeriodType } from "../../../types";
import { SpinContainer } from "./styledComponents";
import { calculateVatAnnualDeclarationSmallBusinessGrandTotal } from "../utils";
import { VatAnnualDeclarationSmallBusinessValues } from "../../../../../../api/graphql/schema.generated";

export interface VatAnnualDeclarationSmallBusinessTableProps {
  email: string;
  taxYear: number;
  period: VatAnnualDeclarationPeriodType;
  smallBusinessValues: VatAnnualDeclarationSmallBusinessValues | null;
  declaration: VatAnnualDeclaration;
  onChangeDeclaration: (
    declarationChange: Partial<VatAnnualDeclaration>
  ) => void;
}

const VatAnnualDeclarationSmallBusinessTable = ({
  email,
  taxYear,
  period,
  smallBusinessValues,
  declaration,
  onChangeDeclaration,
}: VatAnnualDeclarationSmallBusinessTableProps) => {
  const incomes: SmallBusinessIncome[] = useMemo(() => {
    if (!smallBusinessValues) {
      return [];
    }

    return [
      {
        email,
        year: taxYear - 1,
        autoCalculatedTotal: smallBusinessValues.lastYearIncome,
        total:
          smallBusinessValues.lastYearIncome +
          (declaration.lastYearIncomeAdjustment || 0),
      },
      {
        email,
        year: taxYear,
        autoCalculatedTotal: smallBusinessValues.currentYearIncome,
        total:
          smallBusinessValues.currentYearIncome +
          (declaration.currentYearIncomeAdjustment || 0),
      },
    ];
  }, [email, declaration, smallBusinessValues, taxYear]);

  const grandTotal = calculateVatAnnualDeclarationSmallBusinessGrandTotal(
    smallBusinessValues,
    declaration.taxOfficeBalance
  );

  const handleIncomeChange = useCallback(
    (income: SmallBusinessIncome) => {
      const adjustment = income.total - income.autoCalculatedTotal;
      const declarationChange =
        income.year === taxYear
          ? { currentYearIncomeAdjustment: adjustment }
          : { lastYearIncomeAdjustment: adjustment };
      onChangeDeclaration(declarationChange);
    },
    [onChangeDeclaration, taxYear]
  );

  const handleTaxOfficeBalanceChange = useCallback(
    (taxOfficeBalance: number | null) => {
      onChangeDeclaration({ taxOfficeBalance });
    },
    [onChangeDeclaration]
  );

  if (!smallBusinessValues) {
    return (
      <SpinContainer>
        <Spin />
      </SpinContainer>
    );
  }

  return (
    <VatTableSpace direction="vertical" size={45}>
      <SmallBusinessIncomeTable
        incomes={incomes}
        onIncomeChange={handleIncomeChange}
      />
      <SmallBusinessFieldsTable
        email={email}
        taxYear={taxYear}
        period={period}
        taxOfficeBalance={declaration.taxOfficeBalance}
        onTaxOfficeBalanceChange={handleTaxOfficeBalanceChange}
        smallBusinessValues={smallBusinessValues}
        grandTotal={grandTotal}
      />
    </VatTableSpace>
  );
};

export default VatAnnualDeclarationSmallBusinessTable;
