import React, { useMemo, useEffect } from "react";
import { Select } from "antd";
import { useLocation } from "react-router-dom";

import useTaxYearParam, {
  getDefaultTaxYear,
  getTaxYearOptions,
} from "../../hooks/useTaxYearParam";

const { Option } = Select;

const TaxYearSelect = () => {
  const location = useLocation();
  const disabled = !location.pathname.startsWith("/tax-declaration");

  const taxYearOptions = useMemo(getTaxYearOptions, []);
  const [taxYear, setTaxYear] = useTaxYearParam();

  useEffect(() => {
    // use default year if query param is invalid
    if (!taxYearOptions.includes(taxYear) && !disabled) {
      setTaxYear(getDefaultTaxYear());
    }
  }, [setTaxYear, taxYear, taxYearOptions, disabled]);

  return (
    <Select
      value={taxYear}
      onChange={(value: number) => setTaxYear(value)}
      style={{ width: "100%" }}
      disabled={disabled}
    >
      {taxYearOptions.map((year) => (
        <Option key={year} value={year}>
          {year}
        </Option>
      ))}
    </Select>
  );
};

export default TaxYearSelect;
