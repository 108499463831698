import { StatusColor } from "../../../../../constants";
import { TradeTaxDeclarationStatus } from "../../../../../api/graphql/schema.generated";

export const EMPTY_INDICATOR = "–";

export const TRADE_TAX_DECLARATION_STATUS_MAPPINGS = {
  [TradeTaxDeclarationStatus.OPEN]: {
    color: StatusColor.GRAY,
    label: "Offen",
  },
  [TradeTaxDeclarationStatus.NOT_RELEVANT]: {
    color: StatusColor.GREEN,
    label: "Nicht relevant",
  },
  [TradeTaxDeclarationStatus.IN_PROGRESS_DATA]: {
    color: StatusColor.GOLD,
    label: "In Bearbeitung DATA",
  },
  [TradeTaxDeclarationStatus.CONSULTATION_DATA]: {
    color: StatusColor.GOLD,
    label: "Rückfrage DATA",
  },
  [TradeTaxDeclarationStatus.COMPLETED_BY_DATA]: {
    color: StatusColor.PURPLE,
    label: "Abgeschlossen DATA",
  },
  [TradeTaxDeclarationStatus.IN_PROGRESS_OPS]: {
    color: StatusColor.GOLD,
    label: "In Bearbeitung Ops",
  },
  [TradeTaxDeclarationStatus.COMPLETED_BY_OPS]: {
    color: StatusColor.PURPLE,
    label: "Abgeschlossen Ops",
  },
  [TradeTaxDeclarationStatus.IN_PROGRESS_TAX_CONSULTANT]: {
    color: StatusColor.GOLD,
    label: "In Bearbeitung SB",
  },
  [TradeTaxDeclarationStatus.APPROVED_BY_TAX_CONSULTANT]: {
    color: StatusColor.GREEN,
    label: "Abgeschlossen SB",
  },
  [TradeTaxDeclarationStatus.OBJECTED_BY_TAX_CONSULTANT]: {
    color: StatusColor.RED,
    label: "Vorbehalt SB",
  },
  [TradeTaxDeclarationStatus.IN_SUBMISSION_FOR_USER_APPROVAL]: {
    color: StatusColor.PURPLE,
    label: "Vorbereitung Kundenfreigabe",
    hidden: true,
  },
  [TradeTaxDeclarationStatus.WAITING_FOR_USER_APPROVAL]: {
    color: StatusColor.GOLD,
    label: "Warten auf Kundenfreigabe",
  },
  [TradeTaxDeclarationStatus.APPROVED_BY_USER]: {
    color: StatusColor.GREEN,
    label: "Von Kunde freigegeben",
  },
  [TradeTaxDeclarationStatus.OBJECTED_BY_USER]: {
    color: StatusColor.RED,
    label: "Vorbehalt Kunde",
  },
  [TradeTaxDeclarationStatus.SUBMITTED]: {
    color: StatusColor.PURPLE,
    label: "Eingereicht FA",
  },
  [TradeTaxDeclarationStatus.OBJECTED_BY_FINANZAMT]: {
    color: StatusColor.RED,
    label: "Vorbehalt FA",
  },
  [TradeTaxDeclarationStatus.RECEIVED_TAX_BILL]: {
    color: StatusColor.PURPLE,
    label: "Bescheid erhalten",
  },
  [TradeTaxDeclarationStatus.CLOSED]: {
    color: StatusColor.GREEN,
    label: "Abgeschlossen",
  },
  [TradeTaxDeclarationStatus.APPEAL_PROCESS_STARTED]: {
    color: StatusColor.RED,
    label: "Rechtsbehelfsverfahren begonnen",
  },
  [TradeTaxDeclarationStatus.APPEAL_PROCESS_COMPLETED]: {
    color: StatusColor.GREEN,
    label: "Rechtsbehelfsverfahren abgeschlossem",
  },
};

export const TRADE_TAX_DECLARATION_STATUSES = Object.keys(
  TRADE_TAX_DECLARATION_STATUS_MAPPINGS
) as TradeTaxDeclarationStatus[];

export const DATA_SOURCE_LABELS = {
  INTERNAL_DATA: "Interne Daten",
  MANUAL: "Manuelle Eingabe",
  FIXED_AMOUNT: "Fixbetrag",
  CALCULATION: "Berechnung",
};

export enum ValueType {
  AMOUNT = "AMOUNT",
  PERCENTAGE = "PERCENTAGE",
}

export type SectionFields = {
  value?: number;
  title: string;
  source: string;
  valueType: ValueType;
  editable?: boolean;
};

export const SECTION_TITLES = {
  PROFIT: "Gewinn aus Gewerbebetrieb vor Anwendung des § 7 Satz 4 GewStG",
  DEDUCTED_ADDITIONAL_AMOUNTS_MINUS200K_ALLOWANCE:
    "Hinzurechnungsbetrag (gekürzt abzgl. 200 k Freibetrag)",
  ALLOWANCE: "Freibetrag",
  TRADE_TAX_ASSESSMENT_BASIS:
    "Berechnungsgrundlage Gewerbeertrag  (abgerundet)",
  TRADE_TAX_RATE_FEDERAL: "Gewerbesteuermesszahl",
  TAX_BASE_AMOUNT_FEDERAL: "ergibt Steuermessbetrag",
  TRADE_TAX_RATE_LOCAL: "Hebesatz der Gemeinde",
  TRADE_TAX_AMOUNT: "ergibt Gewerbesteuer",
  TAX_OFFICE_BALANCE: "Bereits geleistete Vorauszahlungen",
};

export const FINAL_RESULT_STATUS_TITLES = {
  ADDITIONAL_PAYMENT: "Nachzahlung",
  REFUND: "Erstattung",
};

export const SECTION_ROW_CONFIGS = {
  PROFIT: {
    title: SECTION_TITLES.PROFIT,
    source: DATA_SOURCE_LABELS.INTERNAL_DATA,
    valueType: ValueType.AMOUNT,
  },
  DEDUCTED_ADDITIONAL_AMOUNTS_MINUS200K_ALLOWANCE: {
    title: SECTION_TITLES.DEDUCTED_ADDITIONAL_AMOUNTS_MINUS200K_ALLOWANCE,
    source: DATA_SOURCE_LABELS.INTERNAL_DATA,
    valueType: ValueType.AMOUNT,
  },
  ALLOWANCE: {
    title: SECTION_TITLES.ALLOWANCE,
    source: DATA_SOURCE_LABELS.FIXED_AMOUNT,
    valueType: ValueType.AMOUNT,
  },
  TRADE_TAX_ASSESSMENT_BASIS: {
    title: SECTION_TITLES.TRADE_TAX_ASSESSMENT_BASIS,
    source: DATA_SOURCE_LABELS.CALCULATION,
    valueType: ValueType.AMOUNT,
  },
  TRADE_TAX_RATE_FEDERAL: {
    title: SECTION_TITLES.TRADE_TAX_RATE_FEDERAL,
    source: DATA_SOURCE_LABELS.FIXED_AMOUNT,
    valueType: ValueType.PERCENTAGE,
  },
  TAX_BASE_AMOUNT_FEDERAL: {
    title: SECTION_TITLES.TAX_BASE_AMOUNT_FEDERAL,
    source: DATA_SOURCE_LABELS.CALCULATION,
    valueType: ValueType.AMOUNT,
  },
  TRADE_TAX_RATE_LOCAL: {
    title: SECTION_TITLES.TRADE_TAX_RATE_LOCAL,
    source: DATA_SOURCE_LABELS.INTERNAL_DATA,
    valueType: ValueType.PERCENTAGE,
  },
  TRADE_TAX_AMOUNT: {
    title: SECTION_TITLES.TRADE_TAX_AMOUNT,
    source: DATA_SOURCE_LABELS.CALCULATION,
    valueType: ValueType.AMOUNT,
  },
  TAX_OFFICE_BALANCE: {
    title: SECTION_TITLES.TAX_OFFICE_BALANCE,
    source: DATA_SOURCE_LABELS.MANUAL,
    valueType: ValueType.AMOUNT,
    editable: true,
  },
};
