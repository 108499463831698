import type * as Types from "../schema.generated";

import { gql } from "@apollo/client";
export type AutocategorizationRuleFragment = {
  __typename?: "AutocategorizationRule";
  id: number;
  description: string;
  enabled: boolean;
  priority: number;
  categoryCode?: Types.CategoryCode | null;
  vatCategoryCode?: Types.VatCategoryCode | null;
  verify: boolean;
  accuracy?: number | null;
  appliedTimesCount?: number | null;
  conditions: {
    __typename?: "AutocategorizationRuleConditions";
    accountIds?: Array<number> | null;
    hasEmployees?: boolean | null;
    hasInvoices?: boolean | null;
    hasVatNumber?: boolean | null;
    ibans?: Array<string> | null;
    vatPaymentFrequencies?: Array<Types.PaymentFrequency> | null;
    amount?: {
      __typename?: "RuleConditionNumber";
      op: Types.RuleConditionNumberOp;
      value: number;
    } | null;
    description?: {
      __typename?: "RuleConditionString";
      op: Types.RuleConditionStringOp;
      value: string;
    } | null;
    name?: {
      __typename?: "RuleConditionString";
      op: Types.RuleConditionStringOp;
      value: string;
    } | null;
  };
};

export const AutocategorizationRuleFragmentDoc = gql`
  fragment AutocategorizationRule on AutocategorizationRule {
    id
    description
    enabled
    priority
    categoryCode
    vatCategoryCode
    verify
    accuracy
    appliedTimesCount
    conditions {
      accountIds
      hasEmployees
      hasInvoices
      hasVatNumber
      ibans
      vatPaymentFrequencies
      amount {
        op
        value
      }
      description {
        op
        value
      }
      name {
        op
        value
      }
    }
  }
`;
