import React, { useCallback, useState, ReactNode } from "react";

import moment from "moment-timezone";

import {
  Comment,
  Form,
  Button,
  List,
  Input,
  Space,
  Typography,
  notification,
} from "antd";

import { getCurrentUserEmail } from "../../../gapi";
import {
  AntNotesBlock,
  AntNotesHeader,
  AntNotesTitle,
  EditField,
  DeleteField,
  DateTimeField,
  EditTextAreaField,
  AntNotesFooter,
  LoadMoreButton,
} from "./styledComponents";
import AntDeletePopupConfirm from "../AntDeletePopupConfirm";
import TextWithHyperlinks from "../TextWithHyperlinks";
import {
  KontaxNote,
  KontaxNoteInput,
  KontaxNoteType,
} from "../../../api/graphql/schema.generated";
import { useUpsertKontaxNoteMutation } from "../../../api/graphql/mutations/kontaxNotes/upsertKontaxNote.generated";
import { useDeleteKontaxNoteMutation } from "../../../api/graphql/mutations/kontaxNotes/deleteKontaxNote.generated";
import {
  destroyMessage,
  showSuccessMessage,
  showLoadingMessage,
} from "../../../utils";
import colors from "../../../themes/colors";

const { TextArea } = Input;
const { Text } = Typography;

export type LocalKontaxNote = KontaxNote & {
  editable?: boolean;
};

const MAX_COMMENTS_BEFORE_LOAD_MORE = 3;

const EMPTY_NOTES: LocalKontaxNote = {
  createdAt: "",
  updatedAt: "",
  creator: "",
  message: "",
  id: 0,
  editable: false,
};

const ANT_NOTES_KEY = "ant-notes-loading-key";

const AntNotes = ({
  title = "Notes",
  notes,
  setNotes,
  email,
  type,
  recordId,
  withLoadMore,
  className,
}: {
  title?: string;
  notes: LocalKontaxNote[];
  setNotes: (notes: LocalKontaxNote[]) => void;
  email: string;
  type: KontaxNoteType;
  recordId?: string;
  withLoadMore?: boolean;
  className?: string;
}) => {
  const [currentNote, setCurrentNote] = useState<LocalKontaxNote>(EMPTY_NOTES);
  const [newNote, setNewNote] = useState<LocalKontaxNote>(EMPTY_NOTES);
  const [showAll, setShowAll] = useState(!withLoadMore);

  const [upsertKontaxNote] = useUpsertKontaxNoteMutation();
  const [deleteKontaxNote] = useDeleteKontaxNoteMutation();

  const cancelEditNote = useCallback(
    (noteItem: LocalKontaxNote) => {
      setCurrentNote({ ...currentNote, editable: false });
      setNotes(
        notes.map((raw) =>
          raw.id === noteItem.id ? { ...raw, editable: false } : raw
        )
      );
    },
    [notes, currentNote, setNotes]
  );

  const openAddNote = useCallback(() => {
    if (currentNote.editable) {
      cancelEditNote(currentNote);
    }
    setNewNote({ ...EMPTY_NOTES, editable: !newNote.editable });
  }, [newNote, cancelEditNote, currentNote]);

  const cancelAddNote = useCallback(() => {
    setNewNote({ ...EMPTY_NOTES });
  }, []);

  /**
   * Updates the edited note on the existing notes list and reorders the notes.
   */
  const getUpdatedNotesList = useCallback(
    (editedNote: KontaxNote) =>
      notes
        .map((existingNote) =>
          existingNote.id === editedNote.id
            ? { ...editedNote, editable: false }
            : existingNote
        )
        .sort(
          (a, b) =>
            new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
        ),
    [notes]
  );

  const onSave = useCallback(
    async ({ id, message }: { id?: number; message: string }) => {
      showLoadingMessage(ANT_NOTES_KEY);
      let payload: KontaxNoteInput = { id, message };
      // When id is provided it's an update and only message is expected.
      if (!id) {
        payload = { ...payload, type, recordId };
      }

      try {
        const { data } = await upsertKontaxNote({
          variables: { email, payload },
        });

        if (data) {
          const { upsertKontaxNote: note } = data;
          // If id was provided update the existing note, else add a new one on the top.
          setNotes(id ? getUpdatedNotesList(note) : [note, ...notes]);
        }
        cancelAddNote();
        showSuccessMessage("Note saved successfully");
      } catch (error) {
        notification.error({
          message: `Error while ${id ? "updating" : "creating"} the note`,
        });
      } finally {
        destroyMessage(ANT_NOTES_KEY);
      }
    },
    [
      setNotes,
      cancelAddNote,
      upsertKontaxNote,
      getUpdatedNotesList,
      notes,
      email,
      recordId,
      type,
    ]
  );

  const onAddSaveClick = useCallback(async () => {
    onSave({ message: newNote.message });
  }, [newNote, onSave]);

  const onEditClick = useCallback(
    (noteItem: LocalKontaxNote) => {
      if (newNote.editable) {
        cancelAddNote();
      }

      setCurrentNote({ ...noteItem, editable: true });

      setNotes(
        notes.map((raw) =>
          raw.id === noteItem.id
            ? { ...raw, editable: true }
            : { ...raw, editable: false }
        )
      );
    },
    [notes, newNote, cancelAddNote, setNotes]
  );

  const onEditSaveClick = useCallback(
    async (noteItem: LocalKontaxNote) => {
      if (noteItem.id === currentNote.id) {
        onSave({ id: noteItem.id, message: currentNote.message });
      }
    },
    [onSave, currentNote]
  );

  const onDeleteHandler = useCallback(
    async (item: KontaxNote) => {
      showLoadingMessage(ANT_NOTES_KEY);
      try {
        await deleteKontaxNote({ variables: { id: item.id } });
        setNotes(notes.filter((raw) => raw.id !== item.id));
        showSuccessMessage("Note deleted successfully");
      } catch (error) {
        notification.error({
          message: `Error while deleting the note`,
        });
      } finally {
        destroyMessage(ANT_NOTES_KEY);
      }
    },
    [deleteKontaxNote, notes, setNotes]
  );

  const renderAddNoteCommentView = useCallback(() => {
    return (
      newNote.editable && (
        <Comment
          author={getCurrentUserEmail()}
          content={
            <>
              <Form.Item>
                <TextArea
                  rows={4}
                  showCount
                  maxLength={2000}
                  onChange={(event) =>
                    setNewNote({
                      ...newNote,
                      message: event.target.value,
                    })
                  }
                  value={newNote.message}
                  id="addNoteTextArea"
                  placeholder="Enter a new note"
                />
              </Form.Item>
              <Form.Item>
                <Space>
                  <Button
                    htmlType="submit"
                    onClick={onAddSaveClick}
                    type="primary"
                    data-test="saveAddNoteBtn"
                  >
                    Save
                  </Button>
                  <Button onClick={cancelAddNote} data-test="cancelAddNoteBtn">
                    Cancel
                  </Button>
                </Space>
              </Form.Item>
            </>
          }
        />
      )
    );
  }, [newNote, onAddSaveClick, cancelAddNote]);

  const renderAntDeletePopupConfirm = useCallback(
    (item: KontaxNote, child: ReactNode) => {
      return (
        <AntDeletePopupConfirm
          title="Are you sure want to delete this note? A deleted note can not be
          recovered easily."
          onDelete={() => onDeleteHandler(item)}
        >
          {child}
        </AntDeletePopupConfirm>
      );
    },
    [onDeleteHandler]
  );

  const getDataSource = useCallback(() => {
    const notesToShow = showAll
      ? notes
      : notes.slice(0, MAX_COMMENTS_BEFORE_LOAD_MORE);

    return notesToShow.map((item, index) => {
      return {
        author: item.editable
          ? getCurrentUserEmail() && "Editing as " + getCurrentUserEmail()
          : item.creator,
        actions: !item.editable
          ? [
              <>
                <EditField
                  id={`editNote-${index}`}
                  onClick={() => onEditClick(item)}
                >
                  Edit
                </EditField>
                {renderAntDeletePopupConfirm(
                  item,
                  <DeleteField id={`delete-${index}`} onClick={() => {}}>
                    Delete
                  </DeleteField>
                )}
              </>,
            ]
          : undefined,
        datetime: (
          <DateTimeField>
            {moment(item.updatedAt).format("YYYY-MM-DD")}
          </DateTimeField>
        ),
        content: item.editable ? (
          <>
            <Form.Item key={item.id}>
              <EditTextAreaField>
                <TextArea
                  id={`editNoteTextArea-${index}`}
                  showCount
                  maxLength={2000}
                  autoSize={{ minRows: 4 }}
                  onChange={(event) =>
                    setCurrentNote({
                      ...currentNote,
                      message: event.target.value,
                    })
                  }
                  value={currentNote.message}
                />
              </EditTextAreaField>
            </Form.Item>
            <Form.Item>
              <Space>
                {currentNote.message === "" ? (
                  renderAntDeletePopupConfirm(
                    item,
                    <Button
                      id={`delete-${index}`}
                      htmlType="submit"
                      onClick={() => {}}
                      type="primary"
                    >
                      Save
                    </Button>
                  )
                ) : (
                  <Button
                    id={`saveEditNoteBtn-${index}`}
                    htmlType="submit"
                    onClick={() => onEditSaveClick(item)}
                    type="primary"
                  >
                    Save
                  </Button>
                )}
                <Button
                  id={`cancelEditNoteBtn-${index}`}
                  onClick={() => cancelEditNote(item)}
                >
                  Cancel
                </Button>
              </Space>
            </Form.Item>
          </>
        ) : (
          <div>
            <TextWithHyperlinks text={item.message} />
          </div>
        ),
      };
    });
  }, [
    showAll,
    cancelEditNote,
    notes,
    currentNote,
    onEditSaveClick,
    onEditClick,
    renderAntDeletePopupConfirm,
  ]);

  const loadMore = useCallback(() => {
    setShowAll(true);
  }, []);

  return (
    <AntNotesBlock className={className}>
      <AntNotesHeader>
        <AntNotesTitle>{title}</AntNotesTitle>
        <Button
          style={{ left: 24 }}
          type="link"
          onClick={openAddNote}
          data-test="addNoteBtn"
        >
          Add a note
        </Button>
      </AntNotesHeader>
      {renderAddNoteCommentView()}
      {!notes.length && !newNote.editable && (
        <Text style={{ color: colors.midGrey }}>
          Keine Kommentare vorhanden
        </Text>
      )}
      {notes.length > 0 && (
        <List
          style={{ maxWidth: 700 }}
          dataSource={getDataSource()}
          itemLayout="horizontal"
          renderItem={(props) => <Comment {...props} />}
        />
      )}
      {!showAll && notes.length > MAX_COMMENTS_BEFORE_LOAD_MORE && (
        <AntNotesFooter>
          <LoadMoreButton type="link" onClick={loadMore}>
            Load more
          </LoadMoreButton>
        </AntNotesFooter>
      )}
    </AntNotesBlock>
  );
};

export default AntNotes;
