import {
  IncomingCategoryCode,
  ITransaction,
  OutgoingCategoryCode,
} from "../../../types";

type CategorizableTransaction = Categorizable & {
  splits?: Categorizable[];
};

export function isTransactionCategorized(
  transaction: CategorizableTransaction
): boolean {
  return transaction.splits?.length
    ? transaction.splits.every(isCategorized)
    : isCategorized(transaction);
}

export type Categorizable = Pick<
  Partial<ITransaction>,
  "categoryCode" | "vatCategoryCode"
>;

function isCategorized(categorizable: Categorizable): boolean {
  return !!categorizable.categoryCode && !!categorizable.vatCategoryCode;
}

export const shouldRefetchTransactionOnAssetUpload = (
  transaction?: ITransaction
) => {
  return [
    IncomingCategoryCode.PRIVATE_IN,
    OutgoingCategoryCode.PRIVATE_OUT,
    OutgoingCategoryCode.PRIVATE_WITHDRAWAL,
  ].includes(
    transaction?.categoryCode as OutgoingCategoryCode | IncomingCategoryCode
  );
};
