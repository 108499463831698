import React, { ReactNode } from "react";
import { Popconfirm, PopconfirmProps } from "antd";
import { WarningOutlined } from "@ant-design/icons";

import useMountedState from "../../hooks/useMountedState";

enum ButtonNames {
  OK_BUTTON = "ok-button",
  CANCEL_BUTTON = "cancel-button",
}

const AntDeletePopupConfirm = (props: {
  children: ReactNode;
  title: string;
  onDelete: Function;
  placement?: PopconfirmProps["placement"];
}) => {
  const [isVisible, setIsVisible] = React.useState(false);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const isMounted = useMountedState();

  const showPopconfirm = () => {
    if (!isMounted()) return;
    setIsVisible(true);
  };

  const handleConfirm = async () => {
    setConfirmLoading(true);
    await props.onDelete();
    if (!isMounted()) return;
    setIsVisible(false);
    setConfirmLoading(false);
  };

  const onVisibleChange = (visible: boolean, event?: any) => {
    if (event?.currentTarget?.name === ButtonNames.OK_BUTTON || !isMounted()) {
      return;
    }
    setIsVisible(visible);
  };

  const childrenWithProps = React.Children.map(props.children, (child) => {
    if (React.isValidElement<any>(child)) {
      return React.cloneElement(child, { onClick: showPopconfirm });
    }
    return child;
  });

  return (
    <Popconfirm
      getPopupContainer={(triggerNode: any) => triggerNode.parentNode}
      title={props.title}
      icon={<WarningOutlined style={{ color: "red" }} />}
      visible={isVisible}
      onConfirm={handleConfirm}
      okButtonProps={{
        loading: confirmLoading,
        name: ButtonNames.OK_BUTTON,
        size: "middle",
      }}
      cancelButtonProps={{
        name: ButtonNames.CANCEL_BUTTON,
        size: "middle",
      }}
      okText="Yes, Delete"
      cancelText="No"
      onVisibleChange={(visible, event) => onVisibleChange(visible, event)}
      placement={props.placement}
    >
      {childrenWithProps}
    </Popconfirm>
  );
};

export default AntDeletePopupConfirm;
