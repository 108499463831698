import { Typography } from "antd";
import styled from "styled-components";

import colors from "../../../../../themes/colors";

const { Paragraph } = Typography;

export const TagContainer = styled.div`
  cursor: pointer;
`;

export const SubParagraph = styled(Paragraph)`
  color: ${colors.lightGreyOpacity};
  margin-top: 15px;
  &.ant-typography {
    margin-bottom: 0;
  }
`;
