import { Divider } from "antd";

import { useCallback, useEffect, useState } from "react";

import { formatAmountInCents } from "../../../utils";
import CategorySelect from "../TransactionView/CategorySelect";
import VatCategorySelect from "../TransactionView/VatCategorySelect";
import { KontaxTransactionForAdminFragment } from "../../../api/graphql/fragments/kontaxTransactionForAdmin.generated";
import Copyable from "../../common/Copyable";
import { UserEmail } from "./styledComponents";
import { NameAndInfoWrapper, UserName, ViewMore } from "../../common/UserInfo";
import UserInfoExcerpt from "../../common/UserInfoExcerpt";
import { UserInfoDrawerSources } from "../../common/UserInfoExcerpt/UserInfoDrawer";
import { IUser, VatMetaNote } from "../../../types";
import api from "../../../api";

const FeedbackView = ({
  transaction,
}: {
  transaction: KontaxTransactionForAdminFragment;
}) => {
  const [user, setUser] = useState<IUser | null>(null);
  const [notes, setNotes] = useState<VatMetaNote[]>([]);
  const [isFetchingUser, setIsFetchingUser] = useState<boolean>(false);

  const fetchUser = useCallback(
    async (email: string) => {
      if (!transaction.email) {
        return;
      }

      try {
        setIsFetchingUser(true);

        const { user, notes } = await api.kontax.getVatMeta(email);

        setUser(user);
        setNotes(notes);
      } finally {
        setIsFetchingUser(false);
      }
    },
    [transaction.email]
  );

  useEffect(() => {
    if (transaction.email) {
      fetchUser(transaction.email);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div>
        <NameAndInfoWrapper>
          <UserName>
            {transaction.firstName} {transaction.lastName}
          </UserName>
          <UserInfoExcerpt
            user={user}
            notes={notes}
            source={UserInfoDrawerSources.TAX}
            isLoading={isFetchingUser}
            renderExcerptData={() => <ViewMore>View more info</ViewMore>}
            wrapperStyle={{ marginTop: 0, width: "180px" }}
          />
        </NameAndInfoWrapper>
        <UserEmail>
          <Copyable withFixedIcon>{transaction.email}</Copyable>
        </UserEmail>
      </div>
      <Divider />

      <div>
        <span id="amount">
          Amount: {formatAmountInCents(transaction.amount, true)}
        </span>
      </div>
      <Divider />

      <CategorySelect
        label="Category"
        value={transaction.categoryCode}
        transactionAmount={transaction.amount}
        disabled
      />
      <Divider />

      <VatCategorySelect
        label="VAT"
        value={transaction.vatCategoryCode}
        disabled
      />
      <Divider />

      <div>
        <span id="job">Job: {transaction.businessTypeComment}</span>
      </div>
      <Divider />

      <div>
        <span id="paidBy">Paid by: {transaction.name}</span>
      </div>
      <Divider />

      <div>
        <span id="description">Description: {transaction.description}</span>
      </div>
      <Divider />

      <div>
        <span id="date">Date: {transaction.valutaDate}</span>
      </div>
      <Divider />

      <div>
        <span id="iban">IBAN: {transaction.iban}</span>
      </div>
      <Divider />

      <div>
        <span id="internalNote">
          Internal note:{" "}
          {transaction.internalNote || transaction.escalationNote}
        </span>
      </div>
    </>
  );
};

export default FeedbackView;
