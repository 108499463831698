import { MouseEvent } from "react";
import isEqual from "lodash/isEqual";

const stopPropagation = (event: MouseEvent<HTMLElement, any>) =>
  event.stopPropagation();

export const onClickStopPropagation = () => ({
  onClick: stopPropagation,
});

export const shouldCellUpdate = (record: any, prevRecord: any): boolean =>
  !isEqual(record, prevRecord);
