import translation from "../locales/de/translation.json";
import ServiceTracker from "../locales/de/ServiceTracker.json";
import Navigation from "../locales/de/Navigation.json";
import Onboarding from "../locales/de/Onboarding.json";

const translations = {
  translation,
  ServiceTracker,
  Navigation,
  Onboarding,
};
export default translations;
