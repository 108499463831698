import type * as Types from "../../schema.generated";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type DeleteUserDependentMutationVariables = Types.Exact<{
  id: Types.Scalars["ID"];
}>;

export type DeleteUserDependentMutation = {
  __typename?: "Mutation";
  deleteUserDependent: { __typename?: "MutationResult"; success: boolean };
};

export const DeleteUserDependentDocument = gql`
  mutation DeleteUserDependent($id: ID!) {
    deleteUserDependent(id: $id) {
      success
    }
  }
`;
export type DeleteUserDependentMutationFn = Apollo.MutationFunction<
  DeleteUserDependentMutation,
  DeleteUserDependentMutationVariables
>;

/**
 * __useDeleteUserDependentMutation__
 *
 * To run a mutation, you first call `useDeleteUserDependentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserDependentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserDependentMutation, { data, loading, error }] = useDeleteUserDependentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserDependentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteUserDependentMutation,
    DeleteUserDependentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteUserDependentMutation,
    DeleteUserDependentMutationVariables
  >(DeleteUserDependentDocument, options);
}
export type DeleteUserDependentMutationHookResult = ReturnType<
  typeof useDeleteUserDependentMutation
>;
export type DeleteUserDependentMutationResult =
  Apollo.MutationResult<DeleteUserDependentMutation>;
export type DeleteUserDependentMutationOptions = Apollo.BaseMutationOptions<
  DeleteUserDependentMutation,
  DeleteUserDependentMutationVariables
>;
