import { notification } from "antd";
import { max } from "lodash";
import moment from "moment-timezone";

import { Address } from "../../../api/graphql";
import { TaxDeclarationSavedDraftInfo } from "../../../api/graphql/schema.generated";
import { IMessageType } from "../../../types";
import { showMessage } from "../../../utils";
import { SaveAssetResult } from "./types";

export const navigateToMandatenView = (email: string) => {
  window.open(email ? `/?email=${encodeURIComponent(email)}` : "/", "_blank");
};

export const formatDate = (date?: Date | string | null) => {
  if (date) {
    const momentDate = moment(date);
    if (momentDate.isValid()) {
      return momentDate.format("L");
    }
  }
  return null;
};

export const formatAddress = (address: Address | null | undefined) =>
  address ? [address.street, address.postCode, address.city].join(", ") : "";

export const formatBoolean = (value: boolean | null | undefined) =>
  value ? "Ja" : "Nein";

export const getStatusText = ({
  statusUpdatedAt,
  changedBy,
}: {
  statusUpdatedAt?: Date | string | null;
  changedBy?: string | null | undefined;
}) => {
  if (!statusUpdatedAt) {
    return null;
  }

  const statusUpdateText = moment(statusUpdatedAt).format("DD.MM.YYYY");
  const performer = changedBy ? ` von ${changedBy.split("@")[0]}` : "";
  return `am ${statusUpdateText}${performer}`;
};

export const extractNameFromEmail = (email: string | undefined) => {
  if (!email) {
    return "unbekannt";
  }
  if (email.includes("@")) {
    return email.substring(0, email.lastIndexOf("@"));
  }
  return email;
};

export const notifyFormSavingResult = (isFailed: boolean) => {
  if (!isFailed) {
    showMessage({
      content: "Form saved successfully",
      type: IMessageType.SUCCESS,
      duration: 3,
    });
  } else {
    showMessage({
      content: "There was a problem submitting the form",
      type: IMessageType.ERROR,
      duration: 3,
    });
  }
};

export const notifyAssetUploadResult = (
  assetResults: Array<SaveAssetResult>
) => {
  if (assetResults.length) {
    const rejectedAssets = assetResults
      .filter((asserResult) => asserResult.status === "rejected")
      .map((assetResult) => assetResult.localAsset);

    if (!rejectedAssets.length) {
      showMessage({
        content: "All files were successfully uploaded",
        type: IMessageType.SUCCESS,
        duration: 3,
      });
    } else {
      showMessage({
        content: "There was a problem when uploading some assets",
        type: IMessageType.ERROR,
        duration: 3,
      });

      rejectedAssets.forEach((asset) => {
        notification.error({
          message: `${asset.name} could not be uploaded.`,
        });
      });
    }
  }
};

export const notifyUserAfterSubmittingSubform = (
  isFailed: boolean,
  assetResults: SaveAssetResult[]
) => {
  notifyFormSavingResult(isFailed);
  notifyAssetUploadResult(assetResults);
};

// ERIC process result can have notice (warning) that is not fatal,
// but it's useful to show it to a user
const ERIC_NOTICE_TAG = "<Hinweis>";
export const hasEricNotice = (processResult: string) =>
  !!processResult?.includes(ERIC_NOTICE_TAG);

export const getDraftInfoLatestCreationDate = (
  draftInfo: TaxDeclarationSavedDraftInfo
) => {
  if (draftInfo.externalAssets?.length) {
    return max(draftInfo.externalAssets.map((asset) => asset.createdAt));
  }
  return draftInfo.updatedAt;
};
