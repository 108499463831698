import {
  ITransaction,
  KontaxTransactionMeta,
  ValueCategorizationType,
  ValueMeta,
  ValueSuggestionSource,
} from "../../../../types";

const hasNoCategorizationConfirmed = (meta?: ValueMeta) =>
  !meta?.categorizationType;

const isSuggestionAvailable = (
  suggestionSource?: ValueSuggestionSource
): boolean => {
  return (
    !!suggestionSource &&
    [
      ValueSuggestionSource.BOOKKEEPING_PARTNER,
      ValueSuggestionSource.KONTIST_ML,
      ValueSuggestionSource.USER,
    ].includes(suggestionSource)
  );
};

export const findTransactionsToConfirm = (
  transactions: Array<ITransaction>
): Array<KontaxTransactionMeta> => {
  const transactionsToUpdate: Array<KontaxTransactionMeta> = [];
  const suggestionSourcePrefix = `First suggested by`;

  for (const transaction of transactions) {
    const {
      id: kontistTransactionId,
      categoryCode,
      categoryCodeMeta,
      vatCategoryCode,
      vatCategoryCodeMeta,
    } = transaction;

    const noVatCategoryCodeConfirmed =
      hasNoCategorizationConfirmed(vatCategoryCodeMeta);
    const isVatCategoryCodeSuggestionAvailable = isSuggestionAvailable(
      vatCategoryCodeMeta?.suggestionSource
    );
    const isVatCategoryCodeFromML =
      vatCategoryCodeMeta?.suggestionSource === ValueSuggestionSource.KONTAX_ML;

    const noCategoryCodeConfirmed =
      hasNoCategorizationConfirmed(categoryCodeMeta);
    const isCategoryCodeSuggestionAvailable = isSuggestionAvailable(
      categoryCodeMeta?.suggestionSource
    );
    const isCategoryCodeFromML =
      categoryCodeMeta?.suggestionSource === ValueSuggestionSource.KONTAX_ML;

    // we can confirm/apply suggested values for categoryCode and vatCategoryCode either when:
    // - both are undefined and both have suggested values to apply
    // OR when
    // - either of these is defined and the other has a suggested value to apply
    //
    // preserve the original suggestion into suggestionSource and send to BE
    const meta: KontaxTransactionMeta = {
      ...(noCategoryCodeConfirmed &&
        isCategoryCodeSuggestionAvailable &&
        ((vatCategoryCode && !isVatCategoryCodeFromML) ||
          isVatCategoryCodeSuggestionAvailable) && {
          categoryCode,
          categoryCodeMeta: {
            categorizationType: ValueCategorizationType.TAX_OPS,
            label: `${suggestionSourcePrefix} ${categoryCodeMeta?.suggestionSource}`,
            suggestionSource: categoryCodeMeta?.suggestionSource,
          },
        }),
      ...(noVatCategoryCodeConfirmed &&
        isVatCategoryCodeSuggestionAvailable &&
        ((categoryCode && !isCategoryCodeFromML) ||
          isCategoryCodeSuggestionAvailable) && {
          vatCategoryCode,
          vatCategoryCodeMeta: {
            categorizationType: ValueCategorizationType.TAX_OPS,
            label: `${suggestionSourcePrefix} ${vatCategoryCodeMeta?.suggestionSource}`,
            suggestionSource: vatCategoryCodeMeta?.suggestionSource,
          },
        }),
    };

    // there's nothing to confirm for this transaction
    if (!Object.keys(meta).length) {
      continue;
    }

    transactionsToUpdate.push({
      kontistTransactionId,
      ...meta,
    });
  }

  return transactionsToUpdate;
};
