import type * as Types from "../../schema.generated";

import { gql } from "@apollo/client";
export type DatevImportFragment = {
  __typename?: "DatevImport";
  id: string;
  skrMode: Types.SkrMode;
  bookingMode: Types.BookingMode;
  bookkeepingTool: Types.BookkeepingTool;
  amount: number;
  direction: Types.Direction;
  account: string;
  offsetAccount: string;
  paymentDate: string;
  buKey: string;
  meta1: string;
  description: string;
  kkr?: string | null;
  vatCategoryCode?: string | null;
  verifiedAt?: string | null;
  assets?: Array<{
    __typename?: "Asset";
    filetype: string;
    fullsize: string;
  }> | null;
};

export const DatevImportFragmentDoc = gql`
  fragment DatevImport on DatevImport {
    id
    skrMode
    bookingMode
    bookkeepingTool
    amount
    direction
    account
    offsetAccount
    paymentDate
    buKey
    meta1
    description
    kkr
    vatCategoryCode
    verifiedAt
    assets {
      filetype
      fullsize
    }
  }
`;
