import React, { ReactNode, useEffect, ChangeEvent } from "react";
import { Button, Divider, Input } from "antd";

import {
  OptionsWrapper,
  ButtonGroup,
  CustomMenuWrapper,
} from "./styledComponents";

type Props = {
  id: string;
  children: ReactNode;
  show?: boolean;
  filter?: string;
  setFilter?: Function;
  disableApply: boolean;
  dropdownDisabled: boolean;
  onReset: () => void;
  onApply: () => void;
};

/**
 * Custom menu for the dropdown.
 */
const CustomMenu = ({
  children,
  id,
  filter,
  setFilter,
  disableApply,
  onReset,
  onApply,
  dropdownDisabled,
  show,
  ...rest
}: Props) => {
  const withFilter = !!setFilter;
  const showFilter = !dropdownDisabled && withFilter;

  useEffect(() => {
    if (show && setFilter) {
      setFilter("");
    }
  }, [show, setFilter]);

  const renderSearchedOptions = () => {
    if (!!filter) {
      return React.Children.toArray(children)
        .slice(1) // don't display 'check all' option
        .map((child: any) => {
          let searchedOptions: string[] = [];
          child.props?.options?.forEach((option: any) => {
            if (option.label?.toLowerCase().includes(filter.toLowerCase())) {
              searchedOptions.push(option);
            }
          });
          return React.cloneElement(child, { options: searchedOptions });
        });
    } else {
      return children;
    }
  };

  return (
    <CustomMenuWrapper>
      {showFilter && (
        <>
          <Input
            id={`${id}-dropdown-filter`}
            value={filter}
            type="search"
            placeholder="Type to filter..."
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              setFilter && setFilter(event.target.value);
            }}
            bordered={false}
            allowClear
          />
          <Divider style={{ margin: "12px 0" }} />
        </>
      )}
      <OptionsWrapper>{renderSearchedOptions()}</OptionsWrapper>
      {!dropdownDisabled && (
        <>
          <Divider style={{ margin: "12px 0" }} />
          <ButtonGroup className="space-between">
            <Button onClick={onReset}>Clear</Button>
            <Button onClick={onApply} disabled={disableApply} type="primary">
              Apply
            </Button>
          </ButtonGroup>
        </>
      )}
    </CustomMenuWrapper>
  );
};

export default CustomMenu;
