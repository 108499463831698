import type * as Types from "../../schema.generated";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type UpsertTaxServiceMutationVariables = Types.Exact<{
  email: Types.Scalars["String"];
  payload: Types.UpsertTaxServiceInput;
}>;

export type UpsertTaxServiceMutation = {
  __typename?: "Mutation";
  upsertTaxService: {
    __typename?: "TaxService";
    id: string;
    department: Types.TaxServiceDepartment;
    name: Types.TaxServiceName;
    year?: number | null;
    hours?: number | null;
    internalNote: string;
    externalNote: string;
    status: Types.TaxServiceStatus;
    createdBy: string;
    createdAt: string;
  };
};

export const UpsertTaxServiceDocument = gql`
  mutation upsertTaxService($email: String!, $payload: UpsertTaxServiceInput!) {
    upsertTaxService(email: $email, payload: $payload) {
      id
      department
      name
      year
      hours
      internalNote
      externalNote
      status
      createdBy
      createdAt
    }
  }
`;
export type UpsertTaxServiceMutationFn = Apollo.MutationFunction<
  UpsertTaxServiceMutation,
  UpsertTaxServiceMutationVariables
>;

/**
 * __useUpsertTaxServiceMutation__
 *
 * To run a mutation, you first call `useUpsertTaxServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertTaxServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertTaxServiceMutation, { data, loading, error }] = useUpsertTaxServiceMutation({
 *   variables: {
 *      email: // value for 'email'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useUpsertTaxServiceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertTaxServiceMutation,
    UpsertTaxServiceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpsertTaxServiceMutation,
    UpsertTaxServiceMutationVariables
  >(UpsertTaxServiceDocument, options);
}
export type UpsertTaxServiceMutationHookResult = ReturnType<
  typeof useUpsertTaxServiceMutation
>;
export type UpsertTaxServiceMutationResult =
  Apollo.MutationResult<UpsertTaxServiceMutation>;
export type UpsertTaxServiceMutationOptions = Apollo.BaseMutationOptions<
  UpsertTaxServiceMutation,
  UpsertTaxServiceMutationVariables
>;
