import type * as Types from "../../schema.generated";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type UpdateTaxCaseMutationVariables = Types.Exact<{
  payload: Types.UpdateTaxCasePayload;
}>;

export type UpdateTaxCaseMutation = {
  __typename?: "Mutation";
  updateTaxCase: { __typename?: "MutationResult"; success: boolean };
};

export const UpdateTaxCaseDocument = gql`
  mutation updateTaxCase($payload: UpdateTaxCasePayload!) {
    updateTaxCase(payload: $payload) {
      success
    }
  }
`;
export type UpdateTaxCaseMutationFn = Apollo.MutationFunction<
  UpdateTaxCaseMutation,
  UpdateTaxCaseMutationVariables
>;

/**
 * __useUpdateTaxCaseMutation__
 *
 * To run a mutation, you first call `useUpdateTaxCaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaxCaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaxCaseMutation, { data, loading, error }] = useUpdateTaxCaseMutation({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useUpdateTaxCaseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTaxCaseMutation,
    UpdateTaxCaseMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateTaxCaseMutation,
    UpdateTaxCaseMutationVariables
  >(UpdateTaxCaseDocument, options);
}
export type UpdateTaxCaseMutationHookResult = ReturnType<
  typeof useUpdateTaxCaseMutation
>;
export type UpdateTaxCaseMutationResult =
  Apollo.MutationResult<UpdateTaxCaseMutation>;
export type UpdateTaxCaseMutationOptions = Apollo.BaseMutationOptions<
  UpdateTaxCaseMutation,
  UpdateTaxCaseMutationVariables
>;
