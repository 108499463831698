import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Radio,
  RadioChangeEvent,
  Row,
  Select,
  Space,
  Typography,
} from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import defaults from "lodash/defaults";
import range from "lodash/range";
import isEqual from "lodash/isEqual";

import {
  calculateSubformResults,
  EuerDeclarationOfficeUsageSubformCalculationResults,
  EuerDeclarationOfficeUsageSubformInputs,
  EuerDeclarationSubformCalculationMethod,
  EuerDeclarationSubformInputs,
  EuerDeclarationSubformType,
  HomeOwnershipType,
} from "@kontist/euer-declaration";

import {
  EuerNumericField,
  EuerCurrencyField,
} from "../../components/EuerFormFields";
import BackButton from "../../components/BackButton";
import colors from "../../../../../../../themes/colors";
import {
  EuerDeclarationSubformStatus,
  SaveAssetResult,
} from "../../../../types";
import DeclarationStatus from "../../../../components/DeclarationStatus";
import {
  ChangeLog,
  UpdateEuerDeclarationSubformInput,
  useUpdateEuerDeclarationSubformMutation,
} from "../../../../../../../api/graphql";
import {
  destroyMessage,
  formatAmountInCents,
  showLoadingMessage,
} from "../../../../../../../utils";
import useEmailParam from "../../../../../../hooks/useEmailParam";
import {
  Asset,
  ILocalAsset,
  SMALL_BUSINESS_PAYMENT_FREQUENCIES,
} from "../../../../../../../types";
import OldOfficeUsageStatusChangeModal from "./OldOfficeUsageStatusChangeModal";
import LabelWithTag from "../../components/LabelWithTag";
import { StyledFieldset, StyledForm } from "./styledComponents";
import LocalAssetsViewer from "../../../../../../common/LocalAssetsViewer";
import {
  INITIAL_FLAT_RATE_INPUTS,
  INITIAL_FULL_COST_INPUTS,
  INITIAL_CALCULATION_RESULTS,
  getInputsFromForm,
  transformInputsToFormFieldsValue,
  HOME_OWNERSHIP_TYPE_OPTIONS,
  OFFICE_USAGE_CALCULATION_METHOD_OPTIONS,
} from "./helpers";
import useEuerDeclarationSubformAssetsUploader from "../../hooks/useEuerDeclarationSubformAssetsUploader";
import { useUserContext } from "../../../../contexts/UserContext";
import { SUBFORM_STATUS_MAPPINGS } from "../../constants";
import Upload from "../../../../../../common/Upload";
import ActionLogDrawer from "../../../../../../common/ActionLogDrawer";
import { notifyUserAfterSubmittingSubform } from "../../../../utils";
import { useEuerDeclarationSubformQuery } from "../../../../../../../api/graphql/queries/euerDeclarationSubform.generated";
import WarnLeaveWithoutSaving from "../../../../../../common/WarnLeaveWithoutSaving";
import { ActionsContainer } from "../../../../styles";
import NotesDrawer from "../../../../../../common/NotesDrawer";
import { KontaxNoteType } from "../../../../../../../api/graphql/schema.generated";

const { Text, Title } = Typography;
const dateFormat = "DD.MM.YYYY";

const LOADING_MESSAGE_KEY = "saving-office-usage-subform";

const MONTHS_USED_OPTIONS = range(1, 13).map((value) => ({
  label: String(value),
  value,
}));

interface Props {
  taxYear: number;
}

type OfficeUsageFormInputs = EuerDeclarationOfficeUsageSubformInputs & {
  homeOwnershipType?: HomeOwnershipType;
  calculationMethod: EuerDeclarationSubformCalculationMethod;
};

const OfficeUsageForm = ({ taxYear }: Props) => {
  const [email] = useEmailParam();
  const user = useUserContext();
  const location = useLocation();
  const [form] = Form.useForm<OfficeUsageFormInputs>();
  const [calculationResults, setCalculationResults] =
    useState<EuerDeclarationOfficeUsageSubformCalculationResults>(
      INITIAL_CALCULATION_RESULTS
    );
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [currentFormValues, setCurrentFormValues] =
    useState<OfficeUsageFormInputs>({
      calculationMethod:
        EuerDeclarationSubformCalculationMethod.OFFICE_USAGE_FULL_COST,
      ...INITIAL_FLAT_RATE_INPUTS,
      ...INITIAL_FULL_COST_INPUTS,
    });
  const [initialFormValues, setInitialFormValues] = useState({});
  const [initialCalculationResults, setInitialCalculationResults] = useState(
    {}
  );
  const { assets, setAssets, handleDropFiles, saveAssets } =
    useEuerDeclarationSubformAssetsUploader();

  const [isStatusChangeModalVisible, setIsStatusChangeModalVisible] =
    useState(false);

  const isDirty = useMemo(() => {
    const { calculationMethod } = currentFormValues;
    const formValuesChanged = !isEqual(
      {
        calculationMethod,
        ...getInputsFromForm(calculationMethod, currentFormValues),
        assets,
      },
      initialFormValues
    );

    const calculationResultsChanged = !isEqual(
      calculationResults,
      initialCalculationResults
    );

    return formValuesChanged || calculationResultsChanged;
  }, [
    assets,
    currentFormValues,
    calculationResults,
    initialFormValues,
    initialCalculationResults,
  ]);

  const isSmallBusiness = useMemo(() => {
    const taxYearVatSettings = user?.vatYearSettings?.find(
      ({ year }) => year === taxYear
    );
    return SMALL_BUSINESS_PAYMENT_FREQUENCIES.includes(
      taxYearVatSettings?.vatPaymentFrequency!
    );
  }, [user, taxYear]);

  const handleSelectCalculationMethod = (
    newCalculationMethod: EuerDeclarationSubformCalculationMethod
  ) => {
    const oldCalculationMethod = currentFormValues.calculationMethod;
    if (newCalculationMethod === oldCalculationMethod) {
      return;
    }

    if (
      newCalculationMethod ===
      EuerDeclarationSubformCalculationMethod.OFFICE_USAGE_FLAT_RATE_5_EUR
    ) {
      form.setFieldsValue(INITIAL_FLAT_RATE_INPUTS);
    } else if (
      oldCalculationMethod ===
      EuerDeclarationSubformCalculationMethod.OFFICE_USAGE_FLAT_RATE_5_EUR
    ) {
      form.setFieldsValue(INITIAL_FULL_COST_INPUTS);
    }

    setCurrentFormValues((currentValues) => ({
      ...currentValues,
      calculationMethod: newCalculationMethod,
    }));
    calculateResults(newCalculationMethod);
  };

  const handleHomeOwnershipTypeChange = useCallback(
    (event: RadioChangeEvent) => {
      const { value } = event.target;

      setCurrentFormValues((currentValues) => ({
        ...currentValues,
        homeOwnershipType: value,
      }));
    },
    []
  );

  const showStatusChangeModal = useCallback(() => {
    setIsStatusChangeModalVisible(true);
  }, []);

  const handleStatusChangeModalClose = useCallback(() => {
    setIsStatusChangeModalVisible(false);
  }, []);

  const { data: queryResult, refetch: refetchSubform } =
    useEuerDeclarationSubformQuery({
      skip: !email,
      notifyOnNetworkStatusChange: true,
      variables: {
        email: email!,
        year: taxYear,
        type: EuerDeclarationSubformType.OFFICE_USAGE,
      },
      onCompleted: ({ euerDeclarationSubform }) => {
        if (!euerDeclarationSubform) {
          return;
        }

        const { calculationMethod, inputs, calculationResults, assets } =
          euerDeclarationSubform;
        const fieldValues = {
          ...transformInputsToFormFieldsValue(
            calculationMethod,
            inputs as unknown as EuerDeclarationSubformInputs
          ),
          calculationMethod,
        };

        form.setFieldsValue(fieldValues);
        setCurrentFormValues(fieldValues);
        setInitialFormValues({
          calculationMethod,
          ...getInputsFromForm(calculationMethod, fieldValues),
          assets,
        });

        const initCalculationResults = defaults(
          calculationResults,
          INITIAL_CALCULATION_RESULTS
        );
        setCalculationResults(initCalculationResults);
        setInitialCalculationResults(initCalculationResults);

        setAssets(assets);
      },
    });
  const subform = queryResult?.euerDeclarationSubform;

  const calculateResults = useCallback(
    (
      // Allow to explicitly pass calculation method to be able to call that function with new calculation method just after it is set
      calculationMethodOverwrite: EuerDeclarationSubformCalculationMethod = currentFormValues.calculationMethod
    ) => {
      const inputs = getInputsFromForm(
        calculationMethodOverwrite,
        currentFormValues
      );

      let results: EuerDeclarationOfficeUsageSubformCalculationResults;
      try {
        results = calculateSubformResults(
          EuerDeclarationSubformType.OFFICE_USAGE,
          calculationMethodOverwrite,
          inputs,
          taxYear
        ) as EuerDeclarationOfficeUsageSubformCalculationResults;
      } catch (error) {
        // Use empty results when validation failed
        results = INITIAL_CALCULATION_RESULTS;
      }

      setCalculationResults(results);
    },
    [currentFormValues, taxYear]
  );

  // calculate results on form change
  useEffect(() => {
    calculateResults();
  }, [currentFormValues, calculateResults]);

  const [updateEuerDeclarationSubform] =
    useUpdateEuerDeclarationSubformMutation();

  const saveSubform = useCallback(
    async (status?: EuerDeclarationSubformStatus) => {
      if (email && subform) {
        setIsSaving(true);
        showLoadingMessage(LOADING_MESSAGE_KEY);
        let savingFailed = false;
        let assetResults: SaveAssetResult[] = [];

        try {
          const { calculationMethod } = currentFormValues;
          const formFieldValues = form.getFieldsValue(true);
          const inputs = getInputsFromForm(calculationMethod, formFieldValues);
          const payload: UpdateEuerDeclarationSubformInput = {
            calculationMethod,
            inputs,
            calculationResults,
            status: status || subform.status,
          };
          const result = await updateEuerDeclarationSubform({
            variables: {
              email,
              year: taxYear,
              type: EuerDeclarationSubformType.OFFICE_USAGE,
              payload,
            },
          });
          assetResults = await saveAssets(
            result.data?.updateEuerDeclarationSubform.id!
          );
          await refetchSubform();
        } catch (error) {
          savingFailed = true;
        } finally {
          destroyMessage(LOADING_MESSAGE_KEY);
          setIsSaving(false);
          notifyUserAfterSubmittingSubform(savingFailed, assetResults);
        }
      }
    },
    [
      email,
      subform,
      form,
      currentFormValues,
      calculationResults,
      updateEuerDeclarationSubform,
      taxYear,
      saveAssets,
      refetchSubform,
    ]
  );

  const onSaveClick = useCallback(() => {
    saveSubform();
  }, [saveSubform]);

  const handleAssetDelete = (
    deletedAsset: Asset | ILocalAsset,
    deletedRemoteAsset?: boolean
  ) => {
    const newAssetsList = assets.filter(
      (asset) =>
        (asset as ILocalAsset).name !== (deletedAsset as ILocalAsset).name
    );
    setAssets(newAssetsList);
    // If the asset was deleted remotely, the original list needs to be updated to properly
    // calculate when the form has changed.
    if (deletedRemoteAsset) {
      setInitialFormValues((initialValues) => ({
        ...initialValues,
        assets: newAssetsList,
      }));
    }
  };

  if (!subform) {
    return null;
  }

  const { calculationMethod, homeOwnershipType } = currentFormValues;

  return (
    <>
      <WarnLeaveWithoutSaving warn={isDirty} />
      <BackButton
        text={`Anlage EÜR ${taxYear}`}
        to={{
          pathname: "/tax-declaration/euer-declaration",
          search: location.search,
        }}
      />
      <ActionsContainer>
        <ActionLogDrawer
          withIcon
          title="Action log"
          changeLogs={subform.changeLogs as ChangeLog[]}
        />
        <NotesDrawer
          notes={subform.notes}
          title="Häusliches Arbeitszimmer"
          type={KontaxNoteType.EUER_DECLARATION_SUBFORM}
          recordId={subform.id}
        />
      </ActionsContainer>
      <Title level={3}>{`Häusliches Arbeitszimmer ${taxYear}`}</Title>
      <Row gutter={16}>
        <Col span={12}>
          <StyledForm layout="vertical" colon={false} form={form}>
            <Form.Item label="Kostenansatz" name="calculationMethod">
              <Select
                style={{ width: 300 }}
                options={OFFICE_USAGE_CALCULATION_METHOD_OPTIONS}
                value={calculationMethod}
                onChange={handleSelectCalculationMethod}
              />
            </Form.Item>
          </StyledForm>
          {calculationMethod !==
            EuerDeclarationSubformCalculationMethod.OFFICE_USAGE_FLAT_RATE_5_EUR && (
            <StyledForm layout="vertical" colon={false} form={form}>
              <Form.Item label="Art der Wohnimmobilie" name="homeOwnershipType">
                <Radio.Group
                  options={HOME_OWNERSHIP_TYPE_OPTIONS}
                  onChange={handleHomeOwnershipTypeChange}
                />
              </Form.Item>
            </StyledForm>
          )}
        </Col>
        <Col span={12}>
          <DeclarationStatus
            mapping={SUBFORM_STATUS_MAPPINGS}
            status={subform.status}
            statusUpdatedAt={subform.statusUpdatedAt}
            changedBy={subform.lastStatusChange?.changedBy}
            onClick={showStatusChangeModal}
          />
        </Col>
      </Row>
      <Divider />
      <Row justify="end" style={{ marginBottom: 24 }}>
        <Col>
          <Space>
            <Button onClick={showStatusChangeModal}>Status ändern</Button>
            <Button onClick={onSaveClick} type="primary" disabled={isSaving}>
              Speichern
            </Button>
          </Space>
        </Col>
      </Row>

      <Row gutter={16} align="top">
        {calculationMethod !==
          EuerDeclarationSubformCalculationMethod.OFFICE_USAGE_FLAT_RATE_5_EUR && (
          <Col
            span={12}
            style={{ paddingRight: 30, minHeight: "600px", display: "flex" }}
          >
            <Upload
              onDropFiles={handleDropFiles}
              isInPreviewMode={!!assets.length}
            >
              <LocalAssetsViewer
                assets={assets}
                minHeight={600}
                onDelete={handleAssetDelete}
              />
            </Upload>
          </Col>
        )}
        <Col span={12}>
          <StyledForm
            labelAlign="left"
            colon={false}
            labelCol={{ span: 14 }}
            wrapperCol={{ span: 10 }}
            form={form}
            initialValues={
              calculationMethod ===
              EuerDeclarationSubformCalculationMethod.OFFICE_USAGE_FLAT_RATE_5_EUR
                ? INITIAL_FLAT_RATE_INPUTS
                : INITIAL_FULL_COST_INPUTS
            }
            onValuesChange={(changedValue) => {
              setCurrentFormValues((currentValues) => ({
                ...currentValues,
                ...changedValue,
              }));
            }}
          >
            {calculationMethod !==
              EuerDeclarationSubformCalculationMethod.OFFICE_USAGE_FLAT_RATE_5_EUR && (
              <>
                <StyledFieldset>
                  <legend>Angaben Wohnung/Haus</legend>
                  <EuerNumericField
                    label="Gesamtfläche"
                    name="totalHomeArea"
                    value={0}
                    addonAfter="m2"
                  />
                  <EuerNumericField
                    label="Arbeitszimmer Fläche"
                    name="officeArea"
                    value={0}
                    addonAfter="m2"
                  />
                </StyledFieldset>
                <StyledFieldset>
                  <legend>Nutzungszeitraum</legend>
                  <Form.Item label="Nutzungsbeginn" name="usageStartedAt">
                    <DatePicker format={dateFormat} />
                  </Form.Item>
                  <Form.Item label="Nutzungsmonate" name="monthsUsed">
                    <Select options={MONTHS_USED_OPTIONS} />
                  </Form.Item>
                </StyledFieldset>
                <StyledFieldset>
                  <legend>Kosten</legend>
                  {homeOwnershipType === HomeOwnershipType.RENTAL ? (
                    <EuerCurrencyField
                      value={0}
                      label="Kaltmiete monatlich (lt. Mietvertrag)"
                      name="monthlyRent"
                    />
                  ) : (
                    <EuerCurrencyField
                      value={0}
                      label="AfA ganze Wohnung volles Jahr (netto)"
                      name="annualHomeDepreciation"
                    />
                  )}
                  <EuerCurrencyField
                    value={0}
                    label="Betriebskosten für tatsächlichen Nutzungszeitraum gesamte Wohnung (netto)"
                    name="usagePeriodOperatingHomeExpenses"
                  />
                  <EuerCurrencyField
                    value={0}
                    label="Weitere Ausgaben gesamte Wohnung (netto)"
                    name="annualOtherHomeExpenses"
                  />
                  <EuerCurrencyField
                    value={0}
                    label="Weitere Ausgaben Arbeitszimmer (netto)"
                    name="annualOtherOfficeExpenses"
                  />
                  <Form.Item
                    style={{ textAlign: "right" }}
                    label={
                      <label
                        style={{
                          color:
                            calculationMethod ===
                            EuerDeclarationSubformCalculationMethod.OFFICE_USAGE_UP_TO_1250_EUR
                              ? colors.darkLimeGreen
                              : colors.darkGrey,
                        }}
                      >
                        {calculationMethod ===
                        EuerDeclarationSubformCalculationMethod.OFFICE_USAGE_UP_TO_1250_EUR
                          ? "Kostendeckelung aktiv"
                          : "Summe Kosten Arbeitszimmer"}
                      </label>
                    }
                  >
                    <Text className="ant-form-text">
                      {formatAmountInCents(
                        calculationResults.homeOfficeExpenses,
                        true
                      )}
                    </Text>
                  </Form.Item>
                </StyledFieldset>
                {!isSmallBusiness && (
                  <StyledFieldset>
                    <legend>Vorsteuer</legend>
                    <EuerCurrencyField
                      value={0}
                      label="16&nbsp;% gezahlte Vorsteuer gesamte Wohnung"
                      name="paidHomeExpensesInputTax16"
                    />
                    <EuerCurrencyField
                      value={0}
                      label="19&nbsp;% gezahlte Vorsteuer gesamte Wohnung"
                      name="paidHomeExpensesInputTax19"
                    />
                    <EuerCurrencyField
                      value={0}
                      label="16&nbsp;% gezahlte Vorsteuer Arbeitszimmer"
                      name="paidOfficeExpensesInputTax16"
                    />
                    <EuerCurrencyField
                      value={0}
                      label="19&nbsp;% gezahlte Vorsteuer Arbeitszimmer"
                      name="paidOfficeExpensesInputTax19"
                    />
                    <Form.Item
                      style={{ textAlign: "right" }}
                      label={
                        <label style={{ color: colors.darkGrey }}>
                          Summe gezahlte Vorsteuer Arbeitszimmer
                        </label>
                      }
                    >
                      <Text className="ant-form-text">
                        {formatAmountInCents(
                          calculationResults.paidInputTax || 0,
                          true
                        )}
                      </Text>
                    </Form.Item>
                  </StyledFieldset>
                )}
              </>
            )}
            {calculationMethod ===
              EuerDeclarationSubformCalculationMethod.OFFICE_USAGE_FLAT_RATE_5_EUR && (
              <StyledFieldset>
                <legend>Berechnung</legend>
                <EuerNumericField
                  label="An wie vielen Tagen genutzt"
                  name="daysUsed"
                  value={0}
                  addonAfter="Tage"
                />
              </StyledFieldset>
            )}
            <fieldset>
              <legend>Ergebnisse für EÜR</legend>
              <Form.Item
                style={{ textAlign: "right" }}
                label={
                  <LabelWithTag
                    tag="172"
                    label="Abziehbare Aufwendung für häusliches Arbeitszimmer (inkl. AfA und Schuldzinsen)"
                  />
                }
              >
                <Text className="ant-form-text">
                  {formatAmountInCents(
                    calculationResults.homeOfficeExpenses || 0,
                    true
                  )}
                </Text>
              </Form.Item>
              {calculationMethod !==
                EuerDeclarationSubformCalculationMethod.OFFICE_USAGE_FLAT_RATE_5_EUR &&
                !isSmallBusiness && (
                  <Form.Item
                    style={{ textAlign: "right" }}
                    label={
                      <LabelWithTag
                        tag="185"
                        label="Gezahlte Vorsteuerbeträge"
                      />
                    }
                  >
                    <Text className="ant-form-text">
                      {formatAmountInCents(
                        calculationResults.paidInputTax || 0,
                        true
                      )}
                    </Text>
                  </Form.Item>
                )}
              <Divider />
              <Form.Item label="Gewinnminderung" style={{ textAlign: "right" }}>
                <Text
                  className="ant-form-text"
                  style={{
                    color:
                      calculationResults.profitReduction > 0
                        ? colors.darkRed
                        : colors.darkGrey,
                  }}
                >
                  {formatAmountInCents(
                    calculationResults.profitReduction || 0,
                    true
                  )}
                </Text>
              </Form.Item>
            </fieldset>
          </StyledForm>
        </Col>
      </Row>

      {subform && isStatusChangeModalVisible && (
        <OldOfficeUsageStatusChangeModal
          calculationMethod={calculationMethod}
          inputs={getInputsFromForm(calculationMethod, currentFormValues)}
          calculationResults={calculationResults}
          status={subform.status}
          isSmallBusiness={isSmallBusiness}
          onClose={handleStatusChangeModalClose}
          onSave={saveSubform}
        />
      )}
    </>
  );
};

export default OfficeUsageForm;
