import { FC, useState, useRef, useEffect } from "react";
import { Button, Space, notification, Tag, Modal } from "antd";
import { Editor as TinyMCEEditor } from "tinymce";
import { ApolloQueryResult } from "@apollo/client";
import { SendOutlined } from "@ant-design/icons";

import { Anschreiben } from "../../../../api/graphql/schema.generated";
import { AnschreibenQuery } from "../../../../api/graphql/queries/anschreiben/anschreiben.generated";
import { useUpdateAnschreibenMutation } from "../../../../api/graphql/mutations/anschreiben/updateAnschreiben.generated";
import { useApproveOrRejectAnschreibenMutation } from "../../../../api/graphql/mutations/anschreiben/approveOrRejectAnschreiben.generated";
import { useExportAnschreibenMutation } from "../../../../api/graphql/mutations/anschreiben/exportAnschreiben.generated";
import { useRemoveAnschreibenMutation } from "../../../../api/graphql/mutations/anschreiben/removeAnschreiben.generated";
import { useSendAnschreibenMutation } from "../../../../api/graphql/mutations/anschreiben/sendAnschreiben.generated";
import { IUser } from "../../../../types";
import RichTextEditor from "../../../common/RichTextEditor";
import { reportError } from "../../../../sentry";
import Note from "../Note";
import TopBar from "../TopBar/AnschreibenTopBar";
import * as texts from "../texts";
import { OpenPdfIcon } from "./styledComponents";

type AnschreibenFormProps = {
  user: IUser;
  anschreiben: Anschreiben;
  refetchAnschreiben: () => Promise<ApolloQueryResult<AnschreibenQuery>>;
  year: number;
  setYear: (year: number) => void;
};

const AnschreibenForm: FC<AnschreibenFormProps> = ({
  user,
  anschreiben,
  refetchAnschreiben,
  year,
  setYear,
}) => {
  const editorRef = useRef<TinyMCEEditor | null>(null);
  const [note, setNote] = useState<string>("");

  const [updateAnschreiben] = useUpdateAnschreibenMutation();
  const [approveOrReject] = useApproveOrRejectAnschreibenMutation();
  const [exportAnschreiben] = useExportAnschreibenMutation();
  const [removeAnschreiben] = useRemoveAnschreibenMutation();
  const [sendAnschreiben] = useSendAnschreibenMutation();

  useEffect(() => {
    setNote(anschreiben.note || "");
  }, [anschreiben]);

  useEffect(() => {
    // set page title, it will become PDF name when printing PDF.
    const title =
      user.language === "en"
        ? "Cover letter tax returns"
        : "Anschreiben Steuererklärungen";
    document.title = `${title} ${year} - ${user.firstName} ${user.lastName}`;

    // clear up when unmount the component.
    return () => {
      document.title = "Backoffice"; // default title
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isApproved = Boolean(anschreiben.approvedAt);
  const isRejected = Boolean(anschreiben.rejectedAt);
  const isApprovedOrRejected = isApproved || isRejected;
  const isExported = Boolean(anschreiben.exportedAt);

  const confirmUpdate = () => {
    const content = editorRef?.current?.getContent();

    if (content == null) {
      return;
    }

    if (anschreiben.approvedAt || anschreiben.rejectedAt) {
      Modal.confirm({
        content: texts.SAVE_WARNING,
        onOk: () => _update(content),
        cancelText: texts.CANCEL,
        okText: texts.PROCEED,
      });
      return;
    }

    _update(content);
  };

  const confirmApproveOrReject = (isApproved: boolean) => () => {
    if (isApprovedOrRejected) {
      return;
    }

    if (note !== (anschreiben.note || "")) {
      Modal.confirm({
        content: texts.NOTE_WARNING,
        onOk: () => _approve(isApproved),
        cancelText: texts.CANCEL,
        okText: texts.PROCEED,
      });
      return;
    }

    _approve(isApproved);
  };

  const confirmRemove = () => {
    Modal.confirm({
      content: texts.REMOVE_WARNING,
      onOk: _remove,
      cancelText: texts.CANCEL,
      okText: texts.DELETE,
    });
  };

  const exportPDF = async () => {
    if (!isApproved) {
      return;
    }

    try {
      await exportAnschreiben({
        variables: {
          email: user.email,
          id: anschreiben.id,
        },
      });

      notification.success({ message: texts.SUCCESS });
    } catch (e) {
      reportError(e);
      notification.error({ message: texts.FAILED_TO_UPDATE_ANSCHREIBEN });
    }

    await refetchAnschreiben();
  };

  const _update = async (content: string) => {
    try {
      await updateAnschreiben({
        variables: {
          email: user.email,
          payload: {
            id: anschreiben.id,
            content,
            note,
          },
        },
      });

      notification.success({ message: texts.SUCCESS });
    } catch (e) {
      reportError(e);
      notification.error({ message: texts.FAILED_TO_UPDATE_ANSCHREIBEN });
    }

    await refetchAnschreiben();
  };

  const _approve = async (isApproved: boolean) => {
    try {
      await approveOrReject({
        variables: {
          email: user.email,
          id: anschreiben.id,
          isApproved,
        },
      });

      notification.success({ message: texts.SUCCESS });
    } catch (e) {
      reportError(e);
      notification.error({ message: texts.FAILED_TO_APPROVE_OR_REJECT });
    }

    await refetchAnschreiben();
  };

  const _remove = async () => {
    try {
      await removeAnschreiben({
        variables: {
          email: user.email,
          id: anschreiben.id,
        },
      });

      notification.success({ message: texts.SUCCESS });
    } catch (e) {
      reportError(e);
      notification.error({ message: texts.FAILED_TO_REMOVE });
    }

    await refetchAnschreiben();
  };

  const openPdf = () => {
    const { anschreibenPdfUrl } = anschreiben;
    if (anschreibenPdfUrl) {
      const openedWindow = window.open();
      if (openedWindow) {
        openedWindow.location.href = anschreibenPdfUrl;
      }
      setTimeout(function () {
        URL.revokeObjectURL(anschreibenPdfUrl);
      }, 3000);
    }
  };

  const confirmSend = () => {
    Modal.confirm({
      content: texts.SEND_WARNING,
      onOk: _send,
      cancelText: texts.CANCEL,
      okText: texts.APPROVE,
    });
  };

  const _send = async () => {
    let success: boolean = false;
    try {
      const result = await sendAnschreiben({
        variables: {
          email: user.email,
          id: anschreiben.id,
        },
      });

      success = result.data?.sendAnschreiben.success!;
    } catch (e) {
      reportError(e);
    } finally {
      if (success) {
        notification.success({ message: texts.SUCCESS });
      } else {
        notification.error({ message: texts.FAILED_TO_SEND });
      }
    }

    await refetchAnschreiben();
  };

  return (
    <>
      <TopBar
        year={year}
        setYear={setYear}
        anschreibenId={anschreiben.id}
        RightComponents={
          <>
            <Space>
              {!isApprovedOrRejected && (
                <Button onClick={confirmApproveOrReject(false)} danger ghost>
                  {texts.REJECT}
                </Button>
              )}

              {!isApprovedOrRejected && (
                <Button onClick={confirmApproveOrReject(true)}>
                  {texts.APPROVE}
                </Button>
              )}

              {isApproved && <Tag color="success">{texts.APPROVED}</Tag>}
              {isRejected && <Tag color="error">{texts.REJECTED}</Tag>}

              {isApproved && !isExported && (
                <Button onClick={exportPDF}>{texts.EXPORT}</Button>
              )}

              {isExported && (
                <Button type="link" onClick={openPdf}>
                  <span>{texts.OPEN_PDF}</span>
                  <OpenPdfIcon />
                </Button>
              )}

              {isApproved && (
                <Button onClick={confirmSend}>
                  <span>{texts.SEND}</span>
                  <SendOutlined />
                </Button>
              )}

              <Button type="primary" onClick={confirmRemove} danger>
                {texts.DELETE}
              </Button>

              <Button type="primary" onClick={confirmUpdate}>
                {texts.SAVE}
              </Button>
            </Space>
          </>
        }
      />
      <RichTextEditor
        defaultValue={anschreiben.content}
        editorRef={editorRef}
      />
      <Note note={note} setNote={setNote} />
    </>
  );
};

export default AnschreibenForm;
