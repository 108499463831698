import React, { useEffect, useState } from "react";
import { useQueryParam } from "use-query-params";
import { Badge, Tabs } from "antd";

import {
  IUser,
  IUserDetails,
  KontaxUserStatus,
  KontaxUserStatusAdditionalParams,
} from "../../../../../types";
import { UserPages } from "../../Common/UserDetails/enums";
import { CustomerInformation } from "./CustomerInformation";
import { UpsertAccountingSourcePayload } from "../../../../../types/AccountingSource.type";
import DocumentsTree from "../../Common/UserDetails/DocumentsTree";
import { KontaxUserContext } from "../../../../contexts/KontaxUserContext";
import colors from "../../../../../themes/colors";
import { DocuwareDocuments } from "../../Common/UserDetails/DocumentsTree/DocuwareDocuments";

const { TabPane } = Tabs;

const UserDetails = ({
  user,
  updateUserStatus,
  upsertAccountingSource,
  removeAccountingSource,
  editUser,
  updateDependents,
  deleteDependent,
}: {
  user: IUser;
  editUser: (email: string, userDetails: IUserDetails) => Promise<void>;
  removeAccountingSource: (email: string, year: number) => Promise<void>;
  upsertAccountingSource: (
    email: string,
    payload: UpsertAccountingSourcePayload
  ) => Promise<void>;
  updateUserStatus: (
    email: string,
    status: KontaxUserStatus | null,
    additionalParams?: KontaxUserStatusAdditionalParams
  ) => Promise<void>;
  updateDependents: Function;
  deleteDependent: (email: string, id: string) => Promise<void>;
}) => {
  const [documentsCount, setDocumentsCount] = useState<number>(0);
  const [userPageQueryParam, setUserPageQueryParam] = useQueryParam<
    UserPages | undefined
  >("userPage");
  const defaultActiveTab = userPageQueryParam || UserPages.INFORMATION;
  const [activeTab, setActiveTab] = useState<string>(defaultActiveTab);

  useEffect(() => {
    if (userPageQueryParam) {
      // Clear `userPage` query param because it's intended to be used only once during initial render
      setUserPageQueryParam(undefined, "replaceIn");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <KontaxUserContext user={user}>
      <Tabs
        defaultActiveKey={defaultActiveTab}
        onChange={setActiveTab}
        centered
      >
        <TabPane
          tab={<span id="InformationTabButton">Information</span>}
          key={UserPages.INFORMATION}
        >
          <CustomerInformation
            user={user}
            editUser={editUser}
            updateUserStatus={updateUserStatus}
            upsertAccountingSource={upsertAccountingSource}
            removeAccountingSource={removeAccountingSource}
            updateDependents={updateDependents}
            deleteDependent={deleteDependent}
          />
        </TabPane>
        <TabPane
          forceRender={true}
          tab={
            <span id="DocumentsTabButton">
              Dokumente{" "}
              <Badge
                count={documentsCount}
                style={{
                  backgroundColor: colors.purple,
                  color: colors.white,
                }}
              />
            </span>
          }
          key={UserPages.DOCUMENTS}
        >
          <DocumentsTree
            user={user}
            editUser={editUser}
            categoryNames={["VERIFICATION", "OTHER"]}
            allowUpload
            setDocumentsCount={setDocumentsCount}
          />
          {activeTab === UserPages.DOCUMENTS && (
            <DocuwareDocuments accountId={user.accountId} />
          )}
        </TabPane>
      </Tabs>
    </KontaxUserContext>
  );
};

export default UserDetails;
