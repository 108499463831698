import { FileOutlined } from "@ant-design/icons";
import { Button, Collapse, Typography } from "antd";
import styled from "styled-components";

import colors from "../../../../../../themes/colors";

export const DocumentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 36px;
`;

export const DeclarationTypeTitle = styled(Typography.Text)`
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
`;

export const DeclarationTypeCollapse = styled(Collapse)`
  .ant-collapse-arrow {
    vertical-align: 2px !important;
  }
  .ant-collapse-header {
    padding: 12px 0px !important;
  }
`;

export const DocumentCollapse = styled(Collapse)`
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-width: 1px 1px 0 1px;

  .ant-collapse-item > .ant-collapse-header {
    padding: 0px;
    background-color: ${colors.white};
  }
  .ant-collapse-item > .ant-collapse-content {
    background-color: ${colors.lightestGray};
    position: relative;
  }
  .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
    padding: 16px;
  }
  .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header {
    padding: 0px;
  }
`;

export const DocumentTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 876px;
`;

export const DocumentTitleIcon = styled(FileOutlined)`
  margin: 0px 14px;
  color: #bfbfbf;
  font-size: 20px;
`;

export const DocumentTitleContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const DocumentTitle = styled(Typography.Text)`
  line-height: 22px;
  font-size: 14px;
`;

export const DocumentDescription = styled(Typography.Text)`
  line-height: 20px;
  font-size: 12px;
`;

export const DeleteButtonWrapper = styled.div`
  text-align: right;
`;

export const DeleteButton = styled(Button)`
  margin-left: "auto";
  margin-top: 16px;
`;
