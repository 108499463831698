import styled from "styled-components";
import { EditOutlined } from "@ant-design/icons";

import colors from "../../../themes/colors";

export const CustomerAttributeLabel = styled.dt`
  font-size: 80%;
  padding-bottom: 0.25rem;
  align-self: flex-end;
`;

export const CustomerAttributeIconWrapper = styled(CustomerAttributeLabel)`
  padding-top: 0.2rem;
  padding-left: 0.2rem;
  display: inline-flex;
`;

export const CustomerAttributeLabelContainer = styled.div`
  display: flex;
`;

export const CopyEmoji = styled.button`
  border: 0;
  padding: 0;
  background-color: white;

  margin-left: 5px;
`;

export const CustomerAttributeValue = styled.dd`
  margin-left: 0;
`;

export const InspectLink = styled.a`
  border: 0;
  padding: 0;
  background-color: white;
  color: ${colors.purple};

  svg {
    vertical-align: unset;
    stroke: ${colors.purple};
  }

  span {
    vertical-align: unset;
    font-size: 14px;
    margin-left: 11px;
  }
`;

export const EditOutlinedIcon = styled(EditOutlined)`
  svg {
    position: relative;
    top: -3px;
  }
  color: ${colors.midGrey};
  &:hover {
    color: ${colors.purple};
  }
`;
