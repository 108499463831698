import React, { FC, ChangeEvent, useState, useMemo, useCallback } from "react";
import { DeleteOutlined, RetweetOutlined } from "@ant-design/icons";
import { Space, Input, Button } from "antd";

import {
  TableOptionsContainer,
  SearchContainer,
  ButtonWithIcon,
} from "./styled";
import { DatevData } from "../../../../../../api/graphql";
import CategorizeBatchPopup from "./CategorizeBatchPopup";
import { useUpdateMultipleDatevDataMutation } from "../../../../../../api/graphql/mutations/datevData/updateMultipleDatevData.generated";
import { useToggleMultipleDatevDataVerifiedFlagMutation } from "../../../../../../api/graphql/mutations/datevData/verifyMultipleDatevData.generated";
import {
  showLoadingMessage,
  showGraphQlErrorNotification,
  destroyMessage,
  showMessage,
  showSuccessMessage,
} from "../../../../../../utils";
import { IMessageType } from "../../../../../../types";

const { Search } = Input;

const CATEGORIZE = "categorize-multiple-datev-data";
const VERIFY = "verify-multiple-datev-data";

const CATEGORIZE_ERROR = "Categorize error: ";
const VERIFY_ERROR = "Categorize error: ";

const CATEGORIZE_SUCCESS = "Categorize successfully";
const VERIFY_SUCCESS = "Verify successfully";

const RESET = "RESET";

// If "RESET" option is selected, set it to empty string.
const checkReset = (option: string) => (option === RESET ? "" : option);

type TableOptionsProps = {
  skrSearchInput: string;
  selectedIds: string[];
  onDeleteImportedData: (ids: string[]) => void;
  onSwitchAccountField: (ids: string[]) => void;
  onSkrSearchChange: (searchTerm: ChangeEvent<HTMLInputElement>) => void;
  onSearch: () => void;
  resetData: () => void;
  isLoading: boolean;
  datevData: DatevData[];
};

const TableOptions: FC<TableOptionsProps> = ({
  skrSearchInput,
  selectedIds,
  onDeleteImportedData,
  onSwitchAccountField,
  onSkrSearchChange,
  onSearch,
  resetData,
  isLoading,
  datevData,
}) => {
  const [isPopupVisible, setIsPopupVisible] = useState<boolean>(false);

  const verifiedSelectedIds = useMemo(
    () =>
      selectedIds.filter((selectedId) =>
        datevData.some(({ id, verifiedAt }) => selectedId === id && verifiedAt)
      ),
    [selectedIds, datevData]
  );

  const hasSelectedVerifiedTransactionsOnly = useMemo(
    () =>
      selectedIds.length && verifiedSelectedIds.length === selectedIds.length,
    [verifiedSelectedIds, selectedIds]
  );

  // check if any item is missing either kkr or vatCategory.
  const assertVerifyingIds = useCallback(
    (verifyingIds: string[]): void => {
      const hasMissingData = datevData
        .filter(({ id }) => verifyingIds.includes(id))
        .some(({ kkr, vatCategoryCode }) => !kkr || !vatCategoryCode);

      if (hasMissingData) {
        throw new Error(
          "Some transactions are missing either KKR or VAT Category code"
        );
      }
    },
    [datevData]
  );

  // return the Datev IDs to verify / unverify in bulk.
  const getIdsToVerify = (): string[] => {
    // if all selected items are verified, then return all the IDs
    if (hasSelectedVerifiedTransactionsOnly) {
      return verifiedSelectedIds;
    }

    // if none of the selected items is verified, then return all the IDs.
    if (!verifiedSelectedIds.length) {
      return selectedIds;
    }

    // if both verified and unverified transactions are selected, return only the not verified ones.
    return selectedIds.filter((id) => !verifiedSelectedIds.includes(id));
  };

  const [updateMultipleDatevDataMutation, { loading: multipleUploadLoading }] =
    useUpdateMultipleDatevDataMutation();

  const [
    toggleMultipleDatevDataVerifiedFlagMutation,
    { loading: multipleVerifyLoading },
  ] = useToggleMultipleDatevDataVerifiedFlagMutation();

  const handleUpdateMultipleDatevData = async ({
    categoryCode,
    vatCategoryCode,
    shouldVerify,
  }: {
    categoryCode: string;
    vatCategoryCode: string;
    shouldVerify: boolean;
  }) => {
    const data = selectedIds.map((id) => {
      const selected = datevData.find(({ id: dId }) => dId === id);

      return {
        id: selected!.id,
        direction: selected!.direction,
        kkr: checkReset(categoryCode),
        vatCategoryCode: checkReset(vatCategoryCode),
      };
    });

    showLoadingMessage(CATEGORIZE);

    try {
      await updateMultipleDatevDataMutation({
        variables: {
          payload: data,
        },
      });

      if (shouldVerify) {
        await toggleMultipleDatevDataVerifiedFlagMutation({
          variables: {
            payload: selectedIds,
          },
        });
      }

      resetData();
      showSuccessMessage(CATEGORIZE_SUCCESS);
    } catch (error) {
      showGraphQlErrorNotification(CATEGORIZE_ERROR, error);
    } finally {
      destroyMessage(CATEGORIZE);
    }
  };

  const handleVerifyMultipleDatevData = async () => {
    showLoadingMessage(VERIFY);

    try {
      const payload = getIdsToVerify();
      assertVerifyingIds(payload);

      await toggleMultipleDatevDataVerifiedFlagMutation({
        variables: {
          payload,
        },
      });
      resetData();
      showSuccessMessage(VERIFY_SUCCESS);
    } catch (error) {
      if (error instanceof Error) {
        showMessage({
          type: IMessageType.ERROR,
          duration: 4,
          content: error.message,
        });
      } else {
        showGraphQlErrorNotification(VERIFY_ERROR, error);
      }
    } finally {
      destroyMessage(VERIFY);
    }
  };

  const isAllLoading =
    isLoading || multipleUploadLoading || multipleVerifyLoading;

  return (
    <TableOptionsContainer>
      <Space>
        <ButtonWithIcon
          icon={<DeleteOutlined />}
          disabled={!selectedIds.length || Boolean(verifiedSelectedIds.length)}
          loading={isAllLoading}
          onClick={() => onDeleteImportedData(selectedIds)}
        >
          Löschen
        </ButtonWithIcon>
        <ButtonWithIcon
          icon={<RetweetOutlined />}
          disabled={!selectedIds.length || Boolean(verifiedSelectedIds.length)}
          loading={isAllLoading}
          onClick={() => onSwitchAccountField(selectedIds)}
        >
          Konten wechseln
        </ButtonWithIcon>
        <Button
          disabled={!selectedIds.length}
          loading={isAllLoading}
          onClick={handleVerifyMultipleDatevData}
        >
          {hasSelectedVerifiedTransactionsOnly
            ? "Unverify Selected"
            : "Verify Selected"}
        </Button>
        <Button
          disabled={!selectedIds.length || Boolean(verifiedSelectedIds.length)}
          loading={isAllLoading}
          onClick={() => setIsPopupVisible(true)}
        >
          Categorize Selected
        </Button>
      </Space>
      <SearchContainer>
        <Search
          value={skrSearchInput}
          onChange={onSkrSearchChange}
          onSearch={onSearch}
          id="skr_search"
          placeholder="SKR eingeben"
          allowClear
          enterButton="Markieren"
        />
      </SearchContainer>
      <CategorizeBatchPopup
        isPopupVisible={isPopupVisible}
        closePopup={() => setIsPopupVisible(false)}
        selectedIds={selectedIds}
        updateMultipleDatevData={handleUpdateMultipleDatevData}
      />
    </TableOptionsContainer>
  );
};

export default TableOptions;
