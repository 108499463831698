import React from "react";

import { PaymentFrequency } from "../../../types/IUser.type";
import { formatPaymentFrequency } from "../../../utils/paymentFrequency";
import RadioButtonGroup from "../RadioButtonGroup";
import FilterBoxCard from "../Card/FilterBoxCard";

type Props = {
  onChangeHandler?: any;
  fetchAll?: boolean;
  value?: PaymentFrequency;
};

const VatPaymentFrequencyFilter = ({
  onChangeHandler,
  fetchAll,
  value,
}: Props) => {
  const options: Array<{ label: string; value: string | undefined }> = [
    {
      label: formatPaymentFrequency(PaymentFrequency.MONTHLY),
      value: PaymentFrequency.MONTHLY,
    },
    {
      label: formatPaymentFrequency(PaymentFrequency.QUARTERLY),
      value: PaymentFrequency.QUARTERLY,
    },
    {
      label: formatPaymentFrequency(PaymentFrequency.YEARLY),
      value: PaymentFrequency.YEARLY,
    },
    {
      label: formatPaymentFrequency(PaymentFrequency.NONE),
      value: PaymentFrequency.NONE,
    },
    {
      label: formatPaymentFrequency(PaymentFrequency.NONE_QUARTERLY),
      value: PaymentFrequency.NONE_QUARTERLY,
    },
  ];

  if (fetchAll) {
    options.unshift({ label: "All", value: undefined });
  }
  return (
    <FilterBoxCard title="VAT frequency">
      <RadioButtonGroup
        value={value}
        isReset={value === undefined}
        options={options}
        onChange={(vatPaymentFrequency: PaymentFrequency) => {
          onChangeHandler({ vatPaymentFrequency });
        }}
      />
    </FilterBoxCard>
  );
};

export default VatPaymentFrequencyFilter;
