import type * as Types from "../../schema.generated";

import { gql } from "@apollo/client";
import { TaxDeclarationSubmissionDataFragmentDoc } from "../../fragments/taxDeclarationSubmissionData.generated";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type IncomeTaxDeclarationSubmissionInfoQueryVariables = Types.Exact<{
  email: Types.Scalars["String"];
  year: Types.Scalars["Int"];
}>;

export type IncomeTaxDeclarationSubmissionInfoQuery = {
  __typename?: "Query";
  incomeTaxDeclaration: {
    __typename?: "IncomeTaxDeclaration";
    submissionInfo?: {
      __typename?: "TaxDeclarationSubmissionInfo";
      submissionAgent?: string | null;
      submissionDate?: string | null;
      pdf?: string | null;
      calculationSheet?: string | null;
      externalAssets?: Array<{
        __typename?: "TaxDeclarationExternalAsset";
        url: string;
        filetype: string;
        createdAt: string;
      }> | null;
    } | null;
  };
};

export const IncomeTaxDeclarationSubmissionInfoDocument = gql`
  query incomeTaxDeclarationSubmissionInfo($email: String!, $year: Int!) {
    incomeTaxDeclaration(email: $email, year: $year) {
      submissionInfo {
        ...TaxDeclarationSubmissionData
      }
    }
  }
  ${TaxDeclarationSubmissionDataFragmentDoc}
`;

/**
 * __useIncomeTaxDeclarationSubmissionInfoQuery__
 *
 * To run a query within a React component, call `useIncomeTaxDeclarationSubmissionInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useIncomeTaxDeclarationSubmissionInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIncomeTaxDeclarationSubmissionInfoQuery({
 *   variables: {
 *      email: // value for 'email'
 *      year: // value for 'year'
 *   },
 * });
 */
export function useIncomeTaxDeclarationSubmissionInfoQuery(
  baseOptions: Apollo.QueryHookOptions<
    IncomeTaxDeclarationSubmissionInfoQuery,
    IncomeTaxDeclarationSubmissionInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    IncomeTaxDeclarationSubmissionInfoQuery,
    IncomeTaxDeclarationSubmissionInfoQueryVariables
  >(IncomeTaxDeclarationSubmissionInfoDocument, options);
}
export function useIncomeTaxDeclarationSubmissionInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IncomeTaxDeclarationSubmissionInfoQuery,
    IncomeTaxDeclarationSubmissionInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    IncomeTaxDeclarationSubmissionInfoQuery,
    IncomeTaxDeclarationSubmissionInfoQueryVariables
  >(IncomeTaxDeclarationSubmissionInfoDocument, options);
}
export type IncomeTaxDeclarationSubmissionInfoQueryHookResult = ReturnType<
  typeof useIncomeTaxDeclarationSubmissionInfoQuery
>;
export type IncomeTaxDeclarationSubmissionInfoLazyQueryHookResult = ReturnType<
  typeof useIncomeTaxDeclarationSubmissionInfoLazyQuery
>;
export type IncomeTaxDeclarationSubmissionInfoQueryResult = Apollo.QueryResult<
  IncomeTaxDeclarationSubmissionInfoQuery,
  IncomeTaxDeclarationSubmissionInfoQueryVariables
>;
