import type * as Types from "../../schema.generated";

import { gql } from "@apollo/client";
import { AnnotationTagFragmentDoc } from "../../fragments/annotationTag.generated";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type AnnotationTagsQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type AnnotationTagsQuery = {
  __typename?: "Query";
  annotationTags: Array<{
    __typename?: "AnnotationTag";
    id: string;
    tag: string;
    color: string;
    description: string;
  }>;
};

export const AnnotationTagsDocument = gql`
  query annotationTags {
    annotationTags {
      ...AnnotationTag
    }
  }
  ${AnnotationTagFragmentDoc}
`;

/**
 * __useAnnotationTagsQuery__
 *
 * To run a query within a React component, call `useAnnotationTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnnotationTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnnotationTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAnnotationTagsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AnnotationTagsQuery,
    AnnotationTagsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AnnotationTagsQuery, AnnotationTagsQueryVariables>(
    AnnotationTagsDocument,
    options
  );
}
export function useAnnotationTagsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AnnotationTagsQuery,
    AnnotationTagsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AnnotationTagsQuery, AnnotationTagsQueryVariables>(
    AnnotationTagsDocument,
    options
  );
}
export type AnnotationTagsQueryHookResult = ReturnType<
  typeof useAnnotationTagsQuery
>;
export type AnnotationTagsLazyQueryHookResult = ReturnType<
  typeof useAnnotationTagsLazyQuery
>;
export type AnnotationTagsQueryResult = Apollo.QueryResult<
  AnnotationTagsQuery,
  AnnotationTagsQueryVariables
>;
