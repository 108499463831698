import React, { useEffect, useState } from "react";
import { TabPaneProps, Tabs } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";

import DocumentsTree from "../../Common/UserDetails/DocumentsTree";
import { useFibuFinalCheckTasksLazyQuery } from "../../../../../api/graphql/queries/fibuFinalCheck";
import { FibuFinalCheckTaskType } from "../../../../../api/graphql/schema.generated";
import { UserPages } from "../../Common/UserDetails/enums";
import { useKontaxUserContext } from "../../../../contexts/KontaxUserContext";
import { IUser } from "../../../../../types";
import {
  DownloadAllButtonWrapper,
  DownloadAllButton,
} from "./styledComponents";
import api from "../../../../../api";
import { getDefaultTaxYear } from "../../../TaxDeclaration/hooks/useTaxYearParam";

const { TabPane } = Tabs;

const UPLOAD_TASK_TO_DOCUMENT_CATEGORY_NAME: {
  [key: string]: string;
} = {
  [FibuFinalCheckTaskType.UPLOAD_ADVISOR]: "EXTERNAL_BOOKKEEPING_ADVISOR",
  [FibuFinalCheckTaskType.UPLOAD_MANUAL]: "EXTERNAL_BOOKEEPING_MANUAL",
  [FibuFinalCheckTaskType.UPLOAD_TOOL]: "EXTERNAL_BOOKKEEPING_TOOL",
};

type ExternalBookkeepingTabProps = {
  tab: React.ReactNode;
  setDocumentsCount?: (count: number) => void;
} & TabPaneProps;

const ExternalBookkeepingTab = (props: ExternalBookkeepingTabProps) => {
  const { user, editUser } = useKontaxUserContext();
  const [uploadDocumentsCategories, setUploadDocumentsCategories] = useState<
    string[] | null
  >(null);
  const [selectedDocumentIds, setSelectedDocumentIds] = useState<string[]>([]);
  const [alldDocumentIds, setAllDocumentIds] = useState<string[]>([]);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const year = +(searchParams.get("year") || getDefaultTaxYear());

  const onSelectDocumentHandler = (ids: string[], checked = true) => {
    if (!checked) {
      setSelectedDocumentIds(
        selectedDocumentIds.filter((id) => !ids.includes(id))
      );
    } else {
      setSelectedDocumentIds(
        Array.from(new Set([...selectedDocumentIds, ...ids]))
      );
    }
  };

  const [getFibuFinalCheckTasks] = useFibuFinalCheckTasksLazyQuery({
    variables: { email: (user as IUser)?.email, year },
    onCompleted(data) {
      const { fibuFinalCheckTasks } = data;

      if (fibuFinalCheckTasks?.length) {
        setUploadDocumentsCategories(
          fibuFinalCheckTasks
            .filter((task) => task.type !== FibuFinalCheckTaskType.TAX_RECEIPTS)
            .map((task) => UPLOAD_TASK_TO_DOCUMENT_CATEGORY_NAME[task.type])
            .filter((category) => category)
        );
      }
    },
  });

  useEffect(() => {
    if (user?.email && props.active) {
      getFibuFinalCheckTasks();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.email, props.active]);

  if (!user || !uploadDocumentsCategories?.length) {
    return null;
  }

  const handleDownloadAll = async () => {
    const documentIds = selectedDocumentIds.length
      ? selectedDocumentIds
      : alldDocumentIds;

    await api.kontax.downloadDocumentsArchive(user.email, documentIds);
  };

  return (
    <TabPane key={UserPages.EXTERNAL_BOOKKEEPING} {...props}>
      {alldDocumentIds.length && (
        <DownloadAllButtonWrapper>
          <DownloadAllButton
            icon={<DownloadOutlined />}
            onClick={handleDownloadAll}
          >
            {selectedDocumentIds.length ? "Download selected" : "Download all"}
          </DownloadAllButton>
        </DownloadAllButtonWrapper>
      )}
      <DocumentsTree
        user={user}
        editUser={(_, userData) => editUser(userData)}
        year={year}
        categoryNames={uploadDocumentsCategories}
        allowUpload
        setSelectedDocumentIds={onSelectDocumentHandler}
        setAllDocumentIds={setAllDocumentIds}
        setDocumentsCount={props.setDocumentsCount}
      />
    </TabPane>
  );
};

export default ExternalBookkeepingTab;
