import type * as Types from "../schema.generated";

import { gql } from "@apollo/client";
export type QuestionnaireDocumentFragment = {
  __typename?: "QuestionnaireDocument";
  id: string;
  type: Types.QuestionnaireDocumentType;
  inputs?: any | null;
  updatedAt: string;
  assets: Array<{
    __typename?: "Asset";
    id: string;
    name: string;
    filetype: string;
    thumbnail: string;
    fullsize: string;
  }>;
};

export const QuestionnaireDocumentFragmentDoc = gql`
  fragment questionnaireDocument on QuestionnaireDocument {
    id
    type
    inputs
    updatedAt
    assets {
      id
      name
      filetype
      thumbnail
      fullsize
    }
  }
`;
