import styled from "styled-components";
import { InputNumber, List, Row } from "antd";
import { ListProps } from "antd/lib/list";

import colors from "../../,./../../../../themes/colors";

export const ContentWrapper = styled.div`
  max-width: 680px;
`;

export const RightAlignedInputNumber = styled(InputNumber)`
  .ant-form-item {
    margin-bottom: 10px;
  }
  .ant-input-number-handler-wrap {
    display: none;
  }
  .ant-input-number,
  .ant-picker {
    width: 100%;
  }
  .ant-input-number-input,
  .ant-picker-input input {
    text-align: right;
  }
`;

export const AutoRow = styled(Row)`
  cursor: pointer;
  justify-content: space-between;
  padding: 2px 13px 0px;
  margin-bottom: 0px !important;
  input {
    cursor: pointer;
  }
`;
export const CalculationList: React.FC<ListProps<any>> = styled(List)`
  &.ant-list-bordered.ant-list-lg .ant-list-footer {
    background: ${colors.lightestEverGray};
    font-weight: 500;
  }
`;

export const CalculationListHeading = styled(Row)`
  div:last-child .ant-typography {
    color: ${colors.lightGreyOpacity};
  }
`;
