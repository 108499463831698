import { notification } from "antd";

import { useCreateAutocategorizationRuleMutation } from "../../../api/graphql/mutations/autocategorizationRule";
import { IMessageType } from "../../../types";
import { showMessage } from "../../../utils";
import { ContentWrapper } from "../../common/styledComponents";
import RuleForm from "./RuleForm";
import { FormHeading } from "./styledComponents";

export const AddRule = () => {
  const [createRule, createRuleResult] =
    useCreateAutocategorizationRuleMutation({
      onCompleted: () => {
        showMessage({
          type: IMessageType.SUCCESS,
          duration: 3,
          content: "Rule saved",
        });
        window.opener?.location.reload();
        window.close();
      },
      onError: (error) => {
        notification.error({
          message: "Failed creating Rule",
          description: error.message,
        });
      },
    });

  return (
    <ContentWrapper padding="0">
      <RuleForm
        title={<FormHeading>Add Rule</FormHeading>}
        loading={createRuleResult.loading}
        onSubmit={(rule) => {
          createRule({
            variables: {
              input: rule,
            },
          });
        }}
      />
    </ContentWrapper>
  );
};
