import styled from "styled-components";

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 60px;
`;

export const EditIcon = styled.button`
  /* Override default browser styles */
  border: none;
  padding: 0;
  background-color: transparent;

  height: 24px;
  width: 24px;
  align-self: flex-end;
  cursor: pointer;
  opacity: 0.5;
  float: right;

  :hover {
    opacity: 1;
  }

  &:disabled {
    opacity: 1;
  }
`;

export const UserDetailsPage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const HorizontalLine = styled.hr`
  margin-bottom: 20px;
`;

export const DependentForm = styled.div`
  padding: 12px;
`;

export const DocumentsContainer = styled.div`
  width: 900px;
  background-color: #f5f5f5;
  margin: auto;

  .ant-upload-list {
    padding-left: 20px;
    padding-right: 20px;
  }

  .ant-upload-list-item-name {
    display: inherit;
  }

  .list-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    span.list-item-meta {
      margin-left: 25px;
      font-size: 12px;
      color: #999;
    }
  }
`;

export const VerticalAligned = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 1.5rem;
`;

export const MissingTaxNumberTableWrapper = styled.div`
  width: 100%;
`;

export const MissingTaxNumberTableTitle = styled.div`
  font-weight: 500;
  font-size: 16px;
`;
