import { gql, MutationHookOptions, useMutation } from "@apollo/client";

export interface DeleteTaxNumberMutationVariables {
  id: string;
}

export interface DeleteTaxNumberMutationResult {
  deleteTaxNumber: {
    success: boolean;
  };
}

export const DELETE_TAX_NUMBER_MUTATION = gql`
  mutation deleteUserTaxNumber($id: ID!) {
    deleteTaxNumber(id: $id) {
      success
    }
  }
`;

export const useDeleteTaxNumberMutation = (
  options?: MutationHookOptions<
    DeleteTaxNumberMutationResult,
    DeleteTaxNumberMutationVariables
  >
) =>
  useMutation<DeleteTaxNumberMutationResult, DeleteTaxNumberMutationVariables>(
    DELETE_TAX_NUMBER_MUTATION,
    options
  );
