import type * as Types from "../../schema.generated";

import { gql } from "@apollo/client";
import { AnschreibenFragmentDoc } from "../../fragments/anschreiben.generated";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type UpdateAnschreibenMutationVariables = Types.Exact<{
  email: Types.Scalars["String"];
  payload: Types.UpdateAnschreibenInput;
}>;

export type UpdateAnschreibenMutation = {
  __typename?: "Mutation";
  updateAnschreiben: {
    __typename?: "Anschreiben";
    id: string;
    content: string;
    year: number;
    note?: string | null;
    approvedAt?: string | null;
    rejectedAt?: string | null;
    exportedAt?: string | null;
  };
};

export const UpdateAnschreibenDocument = gql`
  mutation updateAnschreiben(
    $email: String!
    $payload: UpdateAnschreibenInput!
  ) {
    updateAnschreiben(email: $email, payload: $payload) {
      ...Anschreiben
    }
  }
  ${AnschreibenFragmentDoc}
`;
export type UpdateAnschreibenMutationFn = Apollo.MutationFunction<
  UpdateAnschreibenMutation,
  UpdateAnschreibenMutationVariables
>;

/**
 * __useUpdateAnschreibenMutation__
 *
 * To run a mutation, you first call `useUpdateAnschreibenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAnschreibenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAnschreibenMutation, { data, loading, error }] = useUpdateAnschreibenMutation({
 *   variables: {
 *      email: // value for 'email'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useUpdateAnschreibenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAnschreibenMutation,
    UpdateAnschreibenMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAnschreibenMutation,
    UpdateAnschreibenMutationVariables
  >(UpdateAnschreibenDocument, options);
}
export type UpdateAnschreibenMutationHookResult = ReturnType<
  typeof useUpdateAnschreibenMutation
>;
export type UpdateAnschreibenMutationResult =
  Apollo.MutationResult<UpdateAnschreibenMutation>;
export type UpdateAnschreibenMutationOptions = Apollo.BaseMutationOptions<
  UpdateAnschreibenMutation,
  UpdateAnschreibenMutationVariables
>;
