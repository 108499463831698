import { useEffect, useState } from "react";
import { Radio } from "antd";
import i18n from "i18next";

import { LanguageCode } from "../../../i18n";

export const LanguageToggle = () => {
  const defaultLanguage =
    i18n.language === LanguageCode.ENGLISH
      ? LanguageCode.ENGLISH
      : LanguageCode.GERMAN;

  const [language, setLanguage] = useState<LanguageCode>(defaultLanguage);

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  return (
    <Radio.Group value={language} onChange={(e) => setLanguage(e.target.value)}>
      <Radio.Button value={LanguageCode.GERMAN}>DE</Radio.Button>
      <Radio.Button value={LanguageCode.ENGLISH}>EN</Radio.Button>
    </Radio.Group>
  );
};
