import styled, { css } from "styled-components";

import colors from "../../../themes/colors";

export const ViewerContainer = styled.div<any>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: auto;
  flex-direction: column;

  &.preview {
    min-height: ${(props) => `${props.minHeight}px`};
    max-height: ${(props) => `${props.maxHeight}px`};
    height: 100%;
  }

  .ant-image-preview-operations {
    background: rgba(0, 0, 0, 0.5);
  }

  .anticon-close {
    display: none;
  }

  .ant-image-preview-mask,
  .ant-image-preview-wrap {
    position: absolute;
    z-index: 100;
  }

  .ant-image-preview-body {
    overflow-y: scroll;
    pointer-events: none;
  }
`;

export const ImagePreviewContainer = styled.div<any>`
  width: 100%;
  max-height: ${(props) => `${props.maxHeight}px`};
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ImagePreviewWrapper = styled.div`
  width: 100%;
  align-self: stretch;
  display: flex;
  flex: 1;

  > div div {
    left: 10px;
    right: auto !important;
    top: 60px !important;
  }
`;

export const PlaceholderContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 250px;
  padding: 30px;
  text-align: center;
  color: ${colors.darkGrey};
`;

export const Pagination = styled.ul<{
  topPagination?: boolean;
  isReadOnly?: boolean;
}>`
  display: flex;
  list-style: none;
  flex-wrap: wrap;
  width: calc(100% - 150px);
  margin: 0;
  padding: 0;
  ${({ topPagination }) =>
    topPagination
      ? "margin-bottom: 10px;"
      : css`
          position: absolute;
          margin-top: 20px;
        `}
  ${({ isReadOnly }) => isReadOnly && "margin-left: 64px"};

  li {
    background-color: ${colors.white};
    border-radius: 50%;
    width: 40px;
    height: 40px;
    cursor: pointer;
    text-indent: 0;
    :hover {
      background-color: ${colors.lightestPurple};
    }
  }

  li {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 3px;
  }

  li.active {
    color: ${colors.purple};
    background-color: ${colors.lightPurple};
    width: 40px;
  }
`;
