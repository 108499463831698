import type * as Types from "../schema.generated";

import { gql } from "@apollo/client";
export type TaxAccountFragment = {
  __typename?: "TaxAccount";
  kontoabfrageInput: Array<{
    __typename?: "KontoabfrageInput";
    kontoabfrageArt?: Array<string> | null;
    steuernummer?: Array<string> | null;
    zeitraum?: Array<string> | null;
    steuerart?: Array<{
      __typename?: "Steuerart";
      value?: string | null;
    }> | null;
  }>;
  kontoabfrageOutput: Array<{
    __typename?: "KontoabfrageOutput";
    abfrageTagesdatum?: Array<string> | null;
    abfrageSteuernummer?: Array<string> | null;
    abfrageErlaeuterung?: Array<string> | null;
    abfrageGesamtsumme?: Array<string> | null;
    abfrageSteuerart?: Array<{
      __typename?: "AbfrageSteuerart";
      steuerartKlartext?: Array<string> | null;
      steuerartGesamtbetrag?: Array<string> | null;
      steuerartErlaeuterung?: Array<string> | null;
      steuerartTeilbetrag?: Array<{
        __typename?: "SteuerartTeilbetrag";
        teilbetragZeitraum?: Array<string> | null;
        teilbetragFaelligkeit?: Array<string> | null;
        teilbetragWert?: Array<string> | null;
        teilbetragErlaeuterung?: Array<string> | null;
      }> | null;
    }> | null;
  }>;
};

export const TaxAccountFragmentDoc = gql`
  fragment TaxAccount on TaxAccount {
    kontoabfrageInput {
      kontoabfrageArt
      steuernummer
      steuerart {
        value
      }
      zeitraum
    }
    kontoabfrageOutput {
      abfrageTagesdatum
      abfrageSteuernummer
      abfrageErlaeuterung
      abfrageSteuerart {
        steuerartKlartext
        steuerartTeilbetrag {
          teilbetragZeitraum
          teilbetragFaelligkeit
          teilbetragWert
          teilbetragErlaeuterung
        }
        steuerartGesamtbetrag
        steuerartErlaeuterung
      }
      abfrageGesamtsumme
    }
  }
`;
