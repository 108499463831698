import { QuestionnaireType } from "../../../../../../../api/graphql/schema.generated";

export interface QuestionnaireInfo {
  type: QuestionnaireType;
  title: string;
  description: string;
  notAvailable?: boolean;
  firstSupportedYear?: number;
  lastSupportedYear?: number;
  showOnlyIfMatch?: boolean;
}

export const QUESTIONNAIRES_INFO: Array<QuestionnaireInfo> = [
  {
    type: QuestionnaireType.EOY_BASIC_DATA,
    title: "Stammdaten",
    description: "Prüfung und Aktualisierung Kundendaten",
  },
  {
    type: QuestionnaireType.EOY_CAR_USAGE,
    title: "Pkw-Nutzung",
    description: "Privaten Nutzung des Betrieblichen PKW",
  },
  {
    type: QuestionnaireType.EOY_OFFICE_USAGE,
    title: "Häusliches Arbeitszimmer",
    description: "Angaben zu häuslichem Arbeitszimmer",
  },
  {
    type: QuestionnaireType.EOY_TRAVEL_EXPENSES,
    title: "Geschäftsreisen",
    description:
      "Verpflegungsmehraufwand & betrieblichen Nutzung des privaten PKW",
  },
  {
    type: QuestionnaireType.EOY_INCOME_TAX,
    title: "Private Steuererklärung",
    description: "Einkommenssteuererklärung",
    lastSupportedYear: 2021,
  },
  {
    type: QuestionnaireType.START_OF_THE_YEAR,
    title: "Anfang des Jahres Fragebogen",
    description: "Auto, Homeoffice, Verpflegungsmehraufwand",
    firstSupportedYear: 2022,
  },
  {
    type: QuestionnaireType.EOY_INCOME_TAX_BASIC_DATA,
    title: "Einkommensteuer",
    description: "Stammdaten",
    firstSupportedYear: 2022,
  },
  {
    type: QuestionnaireType.EOY_INCOME_TAX_BASIC_DATA_PARTNER,
    title: "Partner Einkommensteuer",
    description: "Partner Stammdaten",
    firstSupportedYear: 2022,
    showOnlyIfMatch: true,
  },
  {
    type: QuestionnaireType.EOY_INCOME_TAX_CHILD,
    title: "Einkommensteuer",
    description: "Kinder", // childName will be appended from context.
    firstSupportedYear: 2022,
    showOnlyIfMatch: true,
  },
  {
    type: QuestionnaireType.EOY_INCOME_TAX_PRIVATE_EXPENSES,
    title: "Einkommensteuer",
    description: "Private Ausgaben",
    firstSupportedYear: 2022,
  },
  {
    type: QuestionnaireType.EOY_INCOME_TAX_PRIVATE_EXPENSES_PARTNER,
    title: "Einkommensteuer",
    description: "Partner Private Ausgaben",
    firstSupportedYear: 2022,
    showOnlyIfMatch: true,
  },
  {
    type: QuestionnaireType.EOY_INCOME_TAX_ADDITIONAL_INCOME,
    title: "Einkommensteuer",
    description: "Zusätzliche Einkünfte",
    firstSupportedYear: 2022,
  },
  {
    type: QuestionnaireType.EOY_INCOME_TAX_ADDITIONAL_INCOME_PARTNER,
    title: "Einkommensteuer",
    description: "Zusätzliche Einkünfte Partner",
    firstSupportedYear: 2022,
    showOnlyIfMatch: true,
  },
];

export const BIZ_TAX_QUESTIONNAIRES_INFO: Array<QuestionnaireInfo> = [
  {
    type: QuestionnaireType.BIZ_TAX_BASIC_DATA,
    title: "Stammdaten",
    description: "Prüfung und Aktualisierung Kundendaten",
  },
  {
    type: QuestionnaireType.BIZ_TAX_CAR_USAGE,
    title: "Pkw-Nutzung",
    description: "Privaten Nutzung des Betrieblichen PKW",
  },
  {
    type: QuestionnaireType.BIZ_TAX_OFFICE_USAGE,
    title: "Häusliches Arbeitszimmer",
    description: "Angaben zu häuslichem Arbeitszimmer",
  },
  {
    type: QuestionnaireType.BIZ_TAX_TRAVEL_EXPENSES,
    title: "Geschäftsreisen",
    description:
      "Verpflegungsmehraufwand & betrieblichen Nutzung des privaten PKW",
  },
];

export const EOY_INCOME_TAX_QUESTIONNAIRE_TYPES = [
  QuestionnaireType.EOY_INCOME_TAX_BASIC_DATA,
  QuestionnaireType.EOY_INCOME_TAX_BASIC_DATA_PARTNER,
  QuestionnaireType.EOY_INCOME_TAX_CHILD,
  QuestionnaireType.EOY_INCOME_TAX_PRIVATE_EXPENSES,
  QuestionnaireType.EOY_INCOME_TAX_PRIVATE_EXPENSES_PARTNER,
  QuestionnaireType.EOY_INCOME_TAX_ADDITIONAL_INCOME,
  QuestionnaireType.EOY_INCOME_TAX_ADDITIONAL_INCOME_PARTNER,
];
