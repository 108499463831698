import React, { useCallback, useEffect, useState } from "react";

import { notification } from "antd";

import { TaxOpsPermissionsScope } from "../../../types";
import PermissionsTable from "./PermissionsTable";
import api from "../../../api";
import { getErrorMessage } from "../../../utils/error";
import { ContentWrapper } from "../../common/styledComponents";
import { getCurrentUserEmail } from "../../../gapi";

const TaxOpsView = () => {
  const [loading, setLoading] = useState(true);
  const [permissionsScopes, setPermissionsScopes] = useState<
    Array<TaxOpsPermissionsScope>
  >([]);

  const deletePermissionsScope = useCallback(
    async (taxOpsPermissionsScope: TaxOpsPermissionsScope) => {
      try {
        setLoading(true);
        const newDataSource = [...permissionsScopes];
        const index = newDataSource.findIndex(
          (item) => taxOpsPermissionsScope.id === item.id
        );
        newDataSource.splice(index, 1);
        setPermissionsScopes(newDataSource);
        await api.kontax.deleteTaxOpsPermissionsScope(taxOpsPermissionsScope);
      } catch (err) {
        notification.error({
          message: getErrorMessage(err),
        });
      } finally {
        setLoading(false);
      }
    },
    [permissionsScopes]
  );

  const fetchPermissionsScopes = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await api.kontax.getTaxOpsPermissionsScopes();
      setPermissionsScopes(data);
    } catch (err) {
      notification.error({
        message: getErrorMessage(err),
      });
    } finally {
      setLoading(false);
    }
  }, []);

  const savePermissionsScope = useCallback(
    async (taxOpsPermissionsScope: TaxOpsPermissionsScope) => {
      try {
        setLoading(true);
        if (taxOpsPermissionsScope.id === -1) {
          const { data: createdPermissionsScope } =
            await api.kontax.createTaxOpsPermissionsScope(
              taxOpsPermissionsScope
            );
          setPermissionsScopes([createdPermissionsScope, ...permissionsScopes]);
        } else {
          await api.kontax.updateTaxOpsPermissionsScope(taxOpsPermissionsScope);
        }
      } catch (err) {
        notification.error({
          message: getErrorMessage(err),
        });
      } finally {
        setLoading(false);
        if (taxOpsPermissionsScope.email === getCurrentUserEmail()) {
          window.location.reload(); // to apply the new UI for logged in user based on their new permission
        }
      }
    },
    [permissionsScopes]
  );

  useEffect(() => {
    fetchPermissionsScopes();
  }, [fetchPermissionsScopes]);

  return (
    <ContentWrapper>
      <PermissionsTable
        permissionsScopes={permissionsScopes}
        loading={loading}
        onDelete={deletePermissionsScope}
        onSave={savePermissionsScope}
      />
    </ContentWrapper>
  );
};

export default TaxOpsView;
