import React, { useState } from "react";
import { notification } from "antd";
import styled from "styled-components";

import DeleteButton from "./DeleteButton";
import { useDeleteDocumentMutation } from "../../api/graphql/mutations/documents";

export const texts = {
  title: "Willst du das Dokument löschen?",
  okText: "Beleg löschen",
  cancelText: "Abbrechen",
};

const Wrapper = styled.span`
  z-index: 999;
  top: 10px;
  left: 10px;
  position: absolute;

  button {
    display: flex;
    align-items: center;
  }
`;

const DeleteDocumentButton = (props: {
  documentId: string;
  email: string;
  onDeleteDocument?: (documentId: string) => void;
  style?: React.CSSProperties;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [deleteDocument] = useDeleteDocumentMutation();

  const handleConfirm = async () => {
    setIsLoading(true);

    try {
      const result = await deleteDocument({
        variables: { id: props.documentId, email: props.email! },
      });
      if (result.data?.deleteDocument?.success) {
        notification.success({ message: "Document was removed" });
      }
      props.onDeleteDocument?.(props.documentId);
    } catch (err) {
      notification.error({
        message: `An errorr occurred while deleting asset: ${
          (err as Error).message
        }`,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Wrapper style={props.style}>
      <DeleteButton
        isLoading={isLoading}
        handleConfirm={handleConfirm}
        title={texts.title}
        okText={texts.okText}
        cancelText={texts.cancelText}
      />
    </Wrapper>
  );
};

export default DeleteDocumentButton;
