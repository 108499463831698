import type * as Types from "../../schema.generated";

import { gql } from "@apollo/client";
import { AnschreibenTemplateFragmentDoc } from "../../fragments/anschreibenTemplate.generated";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type UpsertAnschreibenTemplateMutationVariables = Types.Exact<{
  payload: Types.UpsertAnschreibenTemplateInput;
}>;

export type UpsertAnschreibenTemplateMutation = {
  __typename?: "Mutation";
  upsertAnschreibenTemplate: {
    __typename?: "AnschreibenTemplate";
    id: string;
    content: string;
    type: string;
    language: string;
    year: number;
    note: string;
  };
};

export const UpsertAnschreibenTemplateDocument = gql`
  mutation upsertAnschreibenTemplate(
    $payload: UpsertAnschreibenTemplateInput!
  ) {
    upsertAnschreibenTemplate(payload: $payload) {
      ...AnschreibenTemplate
    }
  }
  ${AnschreibenTemplateFragmentDoc}
`;
export type UpsertAnschreibenTemplateMutationFn = Apollo.MutationFunction<
  UpsertAnschreibenTemplateMutation,
  UpsertAnschreibenTemplateMutationVariables
>;

/**
 * __useUpsertAnschreibenTemplateMutation__
 *
 * To run a mutation, you first call `useUpsertAnschreibenTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertAnschreibenTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertAnschreibenTemplateMutation, { data, loading, error }] = useUpsertAnschreibenTemplateMutation({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useUpsertAnschreibenTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertAnschreibenTemplateMutation,
    UpsertAnschreibenTemplateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpsertAnschreibenTemplateMutation,
    UpsertAnschreibenTemplateMutationVariables
  >(UpsertAnschreibenTemplateDocument, options);
}
export type UpsertAnschreibenTemplateMutationHookResult = ReturnType<
  typeof useUpsertAnschreibenTemplateMutation
>;
export type UpsertAnschreibenTemplateMutationResult =
  Apollo.MutationResult<UpsertAnschreibenTemplateMutation>;
export type UpsertAnschreibenTemplateMutationOptions =
  Apollo.BaseMutationOptions<
    UpsertAnschreibenTemplateMutation,
    UpsertAnschreibenTemplateMutationVariables
  >;
