export interface QuestionnaireAnswerByQuestionName {
  [questionName: string]: string;
}

export interface QuestionnaireAnswersText {
  [answer: string]: string | QuestionnaireAnswerByQuestionName;
}

export const ANSWERS: QuestionnaireAnswersText = {
  BUSINESS: "Nahezu ausschließlich betrieblich",
  MIXED: "Privat und betrieblich gemischt",
  NO_ALMOST_EVERYTHING_IN_A_DIFFERENT_PLACE:
    "Nein, ich erledige fast alles an einem anderen Ort.",
  NO_ONLY_OFFICE_WORK_AT_HOME:
    "Nein, ich erledige nur meine Bürotätigkeiten Zuhause.",
  YES_ALMOST_EVERYTHING_FROM_HOME:
    "Ja, ich erledige fast alles von Zuhause aus.",
  OWN: "Eigentum",
  FLATMATE_50: "Von meinem/er Mitbewohner*in und mir, zu je 50 %",
  ME_100: "Von mir allein zu 100 %",
  OTHER_RENT_PAYMENT: "Sonstige Konstellation oder Zahlungsverhältnisse",
  PARTNER_50: "Von meinem/er Partner*in und mir, zu je 50 %",
  YES_FIRST_TIME: "Ja, das ist das erste steuerrelevante Arbeitszimmer",
  NO_DONE_BEFORE: "Nein, ich habe schon mal eines abgesetzt",
  YES_SHARING: "Ja (z.B. Familie, Mitbewohner*innen etc.)",
  NO_ONLY_ME: "Nein, dieses Arbeitszimmer wird nur von mir genutzt",
  RENTAL: "Miete",
  ELECTRICITY: "Stromkosten",
  HEATING: "Heizkosten",
  OTHER: {
    EOY_OFFICE_USAGE_QUESTION_8:
      "Sonstiges (zB. Handwerkerrechnung, Renovierung...)",
    EOY_INCOME_TAX_CHILD_QUESTION_9:
      "Keines der genannten / Es ist kompliziert",
    EOY_INCOME_TAX_CHILD_QUESTION_17:
      "Keines der genannten / Es ist kompliziert",
    EOY_INCOME_TAX_CHILD_QUESTION_25:
      "Keines der genannten / Es ist kompliziert",
    EOY_INCOME_TAX_CHILD_QUESTION_39:
      "Keines der genannten / Es ist kompliziert",
  },
  PHONE_OR_INTERNET: "Internet- und Telefonkosten",
  UTILITY: "Nebenkostenabrechnung 2021",
  UTILITY_AFTER_PAYMENT: "Nebenkostennachzahlung 2020",
  CAR_USAGE_1_PCT_METHOD: "1 %-Regel",
  CAR_USAGE_DRIVERS_LOG: "Fahrtenbuch",
  CAR_USAGE_TRAVEL_OVER_8_HOURS: "Geschäftsreisen über 8 Std.",
  CAR_USAGE_TRAVEL_LESS_8_HOURS: "Betriebliche Fahrten unter 8 Std.",
  CAR_USAGE_TRAVEL_BOTH: "Beides vorhanden",
  LESS_THAN_50_PERCENT: "Weniger als 50 %",
  MORE_THAN_50_PERCENT: "Mehr als 50 % aber weniger als 99 %",
  TO_100_PERCENT: "Zu 100 %",
  EV: "Elektrofahrzeug",
  HYBRID: "Hybridelektrofahrzeug",
  ICE: "Kraftfahrzeug",
  ARTIST: "Artist",
  CONSULTING_COACHING: "Consulting/Coaching",
  CRAFT: "Craft",
  FINANCIAL_SERVICES: "Financial services",
  FORWARDING_AGENT_COURIER: "Forwarding agent/Courier",
  GRAPHIC_DESIGN_ART: "Graphic design (art.)",
  GRAPHIC_DESIGN_IT: "Graphic design (IT)",
  HEALTH_CARE: "Health/Care",
  IT_CONSULTING: "IT consulting",
  IT_PROGRAMMING: "IT/Programming",
  LAWYER: "Lawyer",
  MARKETING_PR_EVENT: "Marketing/PR/Event",
  MOVING_COMPANY: "Moving company",
  MUSIC_PRODUCTION: "Music production",
  PHOTOGRAPHY: "Photography",
  SALES: "Sales",
  SOCIAL_MEDIA_MANAGER: "Social media manager",
  TRANSLATOR: "Translator",
  WRITER: "Writer",
  JOURNALIST: "Journalismus",
  MOVIE_INDUSTRY: "Filmindustrie",
  REAL_ESTATE_AGENT: "Immobilienmakler",
  COMPLICATED: "Es ist kompliziert",
  DIVORCED: "Geschieden",
  MARRIED: "Verheiratet / Eingetragene Lebenspartnerschaft",
  SINGLE: "Ledig",
  WIDOWED: "Verwitwet",
  CATHOLIC: "Katholisch",
  JEWISH: "Mitglied der jüdischen Kultusgemeinde",
  MISCELLANEOUS: "Sonstiges",
  OTHER_CHURCH_FELLOWSHIP: "Andere Kirchengemeinschaft",
  PROTESTANT: "Evangelisch",
  "DE-BW": "Baden-Württemberg",
  "DE-BY": "Bayern",
  "DE-BE": "Berlin",
  "DE-BB": "Brandenburg",
  "DE-HB": "Bremen",
  "DE-HH": "Hamburg",
  "DE-HE": "Hessen",
  "DE-MV": "Mecklenburg-Vorpommern",
  "DE-NI": "Niedersachsen",
  "DE-NW": "Nordrhein-Westfalen",
  "DE-RP": "Rheinland-Pfalz",
  "DE-SL": "Saarland",
  "DE-SN": "Sachsen",
  "DE-ST": "Sachsen-Anhalt",
  "DE-SH": "Schleswig-Holstein",
  "DE-TH": "Thüringen",
  DISABILITY: "Wegen Behinderung außer Stande sich selbst zu unterhalten",
  MISSING_TRAINING_PLACE: "Fehlender Ausbildungsplatz",
  MULTIPLE_REASONS:
    "Eine Kombination aus mehreren dieser Berücksichtigungsgründe",
  SCHOOL_OR_VOCATIONAL_TRAINING: "Schul-/Berufsausbildung",
  SEEKING_WORK: "Arbeit suchend",
  TRANSITIONAL_PERIOD: "Übergangszeit von höchstens vier Monaten",
  VOLUNTARY_SERVICE: "Freiwilligendienst (kein Zivildienst)",
  NONE: {
    EOY_INCOME_TAX_BASIC_DATA_QUESTION_12:
      "Keine, es handelte sich um einen Kircheneintritt",
    EOY_INCOME_TAX_BASIC_DATA_QUESTION_13:
      "Keine, es handelte sich um einen Kirchenaustritt",
    EOY_INCOME_TAX_BASIC_DATA_QUESTION_14: "Keine",
    EOY_INCOME_TAX_BASIC_DATA_PARTNER_QUESTION_10:
      "Keine, es handelte sich um einen Kircheneintritt",
    EOY_INCOME_TAX_BASIC_DATA_PARTNER_QUESTION_11:
      "Keine, es handelte sich um einen Kirchenaustritt",
    EOY_INCOME_TAX_BASIC_DATA_PARTNER_QUESTION_12: "Keine",
    EOY_INCOME_TAX_CHILD_QUESTION_2:
      "Keiner dieser Berücksichtigungsgründe ist erfüllt",
    EOY_INCOME_TAX_PRIVATE_EXPENSES_QUESTION_2: "Keine davon",
  },
  NO: "Nein",
  YES: "Ja",
  PART_OF_APPRENTICESHIP:
    "Ja, aber nur im Rahmen eines Ausbildungsverhältnisses",
  NO_MORE_THAN_ONE: "Nein, in mehr als einer",
  BADEN_WUERTTEMBERG_OST: "Baden-Württemberg Ost",
  BADEN_WUERTTEMBERG_WEST: "Baden-Württemberg West",
  BAYERN_NORD: "Bayern Nord",
  BAYERN_SUED: "Bayern Süd",
  BERLIN_BRANDENBURG: "Berlin-Brandenburg",
  HESSEN: "Hessen",
  NIEDERSACHSEN_BREMEN: "Niedersachsen-Bremen",
  NORD: "Nord",
  NORDRHEIN_WESTFALEN_NORD: "Nordrhein-Westfalen Nord",
  NORDRHEIN_WESTFALEN_OST: "Nordrhein-Westfalen Ost",
  NORDRHEIN_WESTFALEN_WEST: "Nordrhein-Westfalen West",
  RHEINLAND_PFALZ_SAARLAND: "Rheinland-Pfalz - Saarland",
  SACHSEN: "Sachsen",
  SACHSEN_ANHALT_THUERINGEN: "Sachsen-Anhalt - Thüringen",
  ADOPTED: "Adoptivkind",
  BIOLOGICAL: "Leibliches Kind",
  NO_RELATION: "Kein Kindschaftsverhältnis",
  STEPCHILD: "Im Haushalt aufgenommenes Stiefkind",
  ENDED: "Es endete",
  STARTED: "Es startete",
  I_DO: "Ich",
  NO_ENTITLEMENT: "Es bestand kein Anspruch auf Kindergeld",
  OTHER_PARENT: "Das andere Elternteil",
  UNKNOWN: "Das andere leibliche Elternteil ist unbekannt.",
  I_AM: "Ich",
  MY_PARTNER: "Mein/e Partner*in",
  OTHER_PARTNER: "Das andere Elternteil",
  ADDITIONAL_HEALTH_INSURANCE: "Zusätzliche private Krankenversicherung",
  CHILDCARE: "Kinderbetreuungskosten (ohne Essensgeld)",
  DISABILITY_COSTS: "Invaliditätskosten",
  EXTENSIVE_MEDICAL_EXPENSES: "Umfangreiche Arztkosten",
  SCHOOL_FEES: "Schulgeld (ohne Essensgeld)",
  UNIVERSITY_FEES: "Studiengebühren",
  OTHER_INSURANCE: "Sonstiges",
  PRIVATE_HEALTH_INSURANCE: "Privat",
  PUBLIC_HEALTH_INSURANCE: "Gesetzlich",
  VOLUNTARY_PUBLIC_HEALTH_INSURANCE: "Freiwillig gesetzlich",
  BOTH_RIESTER_RURUP: {
    EOY_INCOME_TAX_PRIVATE_EXPENSES_QUESTION_2:
      "Ich hatte beides, Riester und Rürup",
    EOY_INCOME_TAX_PRIVATE_EXPENSES_PARTNER_QUESTION_2:
      "Mein/e Partner*in hatte beides, Riester und Rürup",
  },
  RIESTER: "Riester",
  RURUP: "Rürup",
  CHILD_ALLOWANCE_NOT_RELEVANT: "Die Kinderzulage ist nicht relevant",
  I_DO_CHILD_ALLOWANCE: "Ich",
  MY_PARTNER_DO_CHILD_ALLOWANCE: "Mein/e Partner*in",
  OTHER_DO_CHILD_ALLOWANCE: "Andere",
  ACCIDENT_INSURANCE: "Unfallversicherung",
  DISABILITY_INSURANCE: "Berufsunfähigkeitsversicherung",
  LIFE_INSURANCE: "Lebensversicherung",
  PENSION_INSURANCE: "Private Rentenversicherung",
  UNEMPLOYMENT_INSURANCE: "Zusätzliche Arbeitslosenversicherung",
  VEHICLE_LIABILITY: "Private (Kfz-)Haftpflicht",
  ALIMENTS: "Unterhalt",
  EXTRAORDINARY_BURDENS: "Außergewöhnliche Belastungen",
  HOUSEHOLD_SERVICES: "Haushaltsnahe Dienst- oder Handwerkerleistungen",
  PRIVATE_DONATIONS: "Private Spenden",
  NON_SELF_EMPLOYED_WORK: "Nichtselbstständige Arbeit (z.B. Festanstellung)",
  CAPITAL_ASSETS_INTERNATIONAL: "Kapitalvermögen (international)",
  PENSIONS_AND_BENEFITS:
    "Renten- und Versorgungsbezüge (Staatlich- und privat)",
  CRYPTO: "Krypto",
  INTERNATIONAL_INCOME: "Ausländische Einkünfte",
  ADDITIONAL_SELF_EMPLOYMENT: "Zusätzliche Selbstständigkeit",
  SALE_OF_PROPERTY: "Verkauf Immobilie",
  OTHER_INCOME: "Sonstiges Einkommen",
  SICK_PAY: "Krankengeld",
  CHILDREN_SICKNESS_BENEFIT: "Kinderkrankengeld",
  MATERNITY_ALLOWANCE: "Mutterschaftsgeld",
  PARENTAL_ALLOWANCE: "Elterngeld",
  PAYMENT_AFTER_INFECTION_PROTECTION: "Zahlung nach Infektionsschutz",
  OTHER_SOCIAL_BENEFITS: "Andere Sozialleistungen",
};

export const QUESTIONS: {
  [question: string]: string;
} = {
  EOY_OFFICE_USAGE_QUESTION_1: "Hast du 2021 von Zuhause aus gearbeitet?",
  EOY_OFFICE_USAGE_QUESTION_2:
    "Ist der Arbeitsplatz in einem räumlich abgetrennten Bereich?",
  EOY_OFFICE_USAGE_QUESTION_3: "Wie wird dieser Arbeitsplatz genutzt?",
  EOY_OFFICE_USAGE_QUESTION_4:
    "Wie viele Tage hast du 2021 dein Arbeitszimmer genutzt?",
  EOY_OFFICE_USAGE_QUESTION_5:
    "Wird die betriebliche Tätigkeit hauptsächlich von Zuhause ausgeübt?",
  EOY_OFFICE_USAGE_QUESTION_5_1:
    "Ist es das erste Mal, das du dieses Arbeitszimmer steuerlich absetzt?",
  EOY_OFFICE_USAGE_QUESTION_6:
    "Wie viele Monate hast du 2021 dein Arbeitszimmer genutzt?",
  EOY_OFFICE_USAGE_QUESTION_6_1:
    "Wie groß ist die Fläche deines Arbeitszimmers?",
  EOY_OFFICE_USAGE_QUESTION_6_2:
    "Wie groß ist die Gesamtfläche deiner Wohnung/deines Hauses?",
  EOY_OFFICE_USAGE_QUESTION_6_3:
    "Wird das Arbeitszimmer auch noch von anderen Personen genutzt?",
  EOY_OFFICE_USAGE_QUESTION_6_4: "Von wem wird die Wohnungsmiete bezahlt?",
  EOY_OFFICE_USAGE_QUESTION_7:
    "Wohnst du zur Miete oder ist die Immobilie, in der sich dein Homeoffice befindet, dein Eigentum?",
  EOY_OFFICE_USAGE_QUESTION_8:
    "Folgende Ausgaben kannst du zusätzlich zur Miete steuerlich absetzbar machen. Bitte wähle aus was davon auf dich zutrifft – Mehrfachauswahl möglich.",
  EOY_OFFICE_USAGE_RESULT_1: "Das war’s auch schon!",
  EOY_OFFICE_USAGE_RESULT_2: "Das war’s auch schon!",
  EOY_OFFICE_USAGE_RESULT_3:
    "Fast geschafft: Lade nur noch die Dokumentation \u2028deiner Geschäftsreisen hoch",
  EOY_TRAVEL_EXPENSES_QUESTION_1: "Hast du 2021 Geschäftsreisen unternommen?",
  EOY_TRAVEL_EXPENSES_QUESTION_2:
    "Hast du 2021 deinen privaten Pkw für Geschäftsreisen genutzt?",
  EOY_TRAVEL_EXPENSES_RESULT_1: "Das war’s auch schon!",
  EOY_TRAVEL_EXPENSES_RESULT_2:
    "Fast geschafft: Lade nur noch die Dokumentation \u2028deiner Geschäftsreisen hoch",
  EOY_CAR_USAGE_QUESTION_1:
    "Hast du 2021 einen Pkw im Rahmen deiner Selbständigkeit genutzt?",
  EOY_CAR_USAGE_QUESTION_1_1:
    "Hattest du im Jahr 2021 mehr als ein Auto (gleichzeitig oder nacheinander)?",
  EOY_CAR_USAGE_QUESTION_2: "Führst du einen doppelten Haushalt?",
  EOY_CAR_USAGE_QUESTION_3:
    "Bist du mit dem Auto von Wohnung zu deinem Betrieb (zB. Büro, Werkstatt etc.) gefahren?",
  EOY_CAR_USAGE_QUESTION_4:
    "Wie viele Kilometer ist die einfache Entfernung zwischen deiner Wohnung und deinem Betrieb?",
  EOY_CAR_USAGE_QUESTION_5:
    "Wie viele Tage im Jahr bist du mit dem Auto zwischen deiner Wohnung und deinem Betrieb gefahren? ",
  EOY_CAR_USAGE_QUESTION_6:
    "Benutzt du diesen Pkw zu mehr als 50 % betrieblich?",
  EOY_CAR_USAGE_QUESTION_6_1:
    "Zu welchem Anteil benutzt du deinen Pkw betrieblich?",
  EOY_CAR_USAGE_QUESTION_7: "Hast du 2021 ein Fahrtenbuch geführt?",
  EOY_CAR_USAGE_QUESTION_8:
    "Sind diese Pkw-Fahrten ausschließlich Geschäftsreisen (länger als 8 Stunden), ausschließlich betriebliche Fahrten unter 8 Stunden oder beides?",
  EOY_CAR_USAGE_QUESTION_9:
    "Möchtest du die 1 %-Regelung anwenden oder hast du ein Fahrtenbuch geführt?",
  EOY_CAR_USAGE_QUESTION_10: "Was für einen Pkw benutzt du?",
  EOY_CAR_USAGE_QUESTION_11:
    "Sind alle deine Pkw-Ausgaben in deiner Kontist Buchhaltung erfasst worden?",
  EOY_CAR_USAGE_RESULT_1: "Das war’s auch schon!",
  EOY_CAR_USAGE_RESULT_2: "Das war’s auch schon!",
  EOY_CAR_USAGE_RESULT_3:
    "Fast geschafft: Lade nur noch Belege für deine Steuererklärung hoch",
  EOY_INCOME_TAX_QUESTION_1:
    "Die Antworten aus den Fragebögen zur Einkommensteuererklärung können für 2021 nicht im Backoffice angezeigt werden",
  EOY_BASIC_DATA_QUESTION_1:
    "Welche Berufsbezeichnung passt am besten zu deiner Tätigkeit?",
  EOY_BASIC_DATA_QUESTION_2: "Bist du erst seit 2021 selbstständig?",
  EOY_BASIC_DATA_QUESTION_3:
    "Nenne uns das exakte Datum, an dem du deine Selbstständigkeit gestartet hast.",
  EOY_BASIC_DATA_QUESTION_4: "Hast du deine Selbstständigkeit 2021 aufgegeben?",
  EOY_BASIC_DATA_QUESTION_5:
    "Wir benötigen das Enddatum deiner Selbstständigkeit.",
  EOY_BASIC_DATA_QUESTION_6: "Hattest du 2021 ein angemeldetes Gewerbe?",
  EOY_BASIC_DATA_QUESTION_7: "Wie lautete deine Geschäftsadresse 2021?",
  EOY_BASIC_DATA_QUESTION_8:
    "Wann war das Einzugsdatum in deine Geschäftsadresse?",
  EOY_BASIC_DATA_QUESTION_9:
    "Laut unseren Informationen hast du in 2021 die sog. Kleinunternehmerregelung (§ 19 Umsatzsteuergesetz) genutzt. Ist das korrekt?",
  EOY_BASIC_DATA_QUESTION_10:
    "Da du 2021 die sog. Kleinunternehmerregelung (§ 19 Umsatzsteuergesetz) genutzt hast, benötigen wir deinen Umsatz aus dem Jahr 2020.",
  EOY_BASIC_DATA_QUESTION_11:
    "Ist dein Anlagevermögen im Abschreibungsjahr 2023 vollständig und aktuell?",
  EOY_BASIC_DATA_RESULT_1: "Das war's such schon!",
  EOY_BASIC_DATA_RESULT_2: "Deine Stammdaten sind komplett!",
  EOY_INCOME_TAX_BASIC_DATA_QUESTION_1: "Ist das deine private Adresse?",
  EOY_INCOME_TAX_BASIC_DATA_QUESTION_2:
    "Bitte ändere deine private Adresse jetzt in deiner Kontist Mobile App.",
  EOY_INCOME_TAX_BASIC_DATA_QUESTION_3:
    "Wie lautete dein Familienstatus im Jahr 2022?",
  EOY_INCOME_TAX_BASIC_DATA_QUESTION_4:
    "Seit wann hat sich Ihr Familienstand geändert?",
  EOY_INCOME_TAX_BASIC_DATA_QUESTION_5:
    "Soll auch für deinen Partner*in eine Steuererklärung von uns erstellt werden?",
  EOY_INCOME_TAX_BASIC_DATA_QUESTION_6:
    "Hast du im Jahr 2022 mit deinem/er Partner*in zusammen gelebt?",
  EOY_INCOME_TAX_BASIC_DATA_QUESTION_7:
    "Hast du 2022 mit deiner/deinem Partner*in Deutschland gelebt?",
  EOY_INCOME_TAX_BASIC_DATA_QUESTION_8: "Hast du Kinder?",
  EOY_INCOME_TAX_BASIC_DATA_QUESTION_8_1:
    "Bestätige oder aktualisiere die Daten aller Kinder, die von dir oder deinem Partner hier gelistet werden.",
  EOY_INCOME_TAX_BASIC_DATA_QUESTION_9:
    "Warst du im Jahr 2022 zu irgendeinem Zeitpunkt Mitglied einer Kirche?",
  EOY_INCOME_TAX_BASIC_DATA_QUESTION_10:
    "Hat sich etwas an deiner Kirchenmitgliedschaft geändert?",
  EOY_INCOME_TAX_BASIC_DATA_QUESTION_11:
    "An welchem Tag im Jahr wurde die Änderung deiner Religionszugehörigkeit durchgeführt?",
  EOY_INCOME_TAX_BASIC_DATA_QUESTION_12:
    "In welcher Kirche warst du vor der Änderung Mitglied?",
  EOY_INCOME_TAX_BASIC_DATA_QUESTION_13:
    "In welcher Kirche warst du nach der Änderung Mitglied?",
  EOY_INCOME_TAX_BASIC_DATA_QUESTION_14: "In welcher Kirche warst du Mitglied?",
  EOY_INCOME_TAX_BASIC_DATA_QUESTION_15:
    "Lag bei dir im Jahr 2022 eine Behinderung vor?",
  EOY_INCOME_TAX_BASIC_DATA_QUESTION_16:
    "Hast du 2022 Einnahmen aus Vermietung und/oder Verpachtung gehabt?",
  EOY_INCOME_TAX_BASIC_DATA_RESULT_1:
    "Geschafft! Es werden keine Belege für deine Stammdaten benötigt.",
  EOY_INCOME_TAX_BASIC_DATA_RESULT_2:
    "Fast geschafft. Lade im nächsten Schritt Dokumente hoch.",
  EOY_INCOME_TAX_BASIC_DATA_PARTNER_QUESTION_1:
    "Wie lautet der Name und das Geburtsdatum deiner/s Partner*in?",
  EOY_INCOME_TAX_BASIC_DATA_PARTNER_QUESTION_2:
    "Wohnen deiner/s Partner*in und du aktuell in der selben Adresse zusammen?",
  EOY_INCOME_TAX_BASIC_DATA_PARTNER_QUESTION_3:
    "Gib die Adresse an, in der deiner/s Partner*in {{taxYear}} gewohnt hat.",
  EOY_INCOME_TAX_BASIC_DATA_PARTNER_QUESTION_4:
    "Wie lautet deiner/s Partner*in  Steuer-Identifikationsnummer?",
  EOY_INCOME_TAX_BASIC_DATA_PARTNER_QUESTION_5:
    "Hat deiner/s Partner*in außerdem eine eigene Steuernummer?",
  EOY_INCOME_TAX_BASIC_DATA_PARTNER_QUESTION_6:
    "Gib hier alle Steuernummern an, die deiner/s Partner*in besitzt.",
  EOY_INCOME_TAX_BASIC_DATA_PARTNER_QUESTION_7:
    "War deiner/s Partner*in im Jahr {{taxYear}} zu irgendeinem Zeitpunkt Mitglied einer Kirche?",
  EOY_INCOME_TAX_BASIC_DATA_PARTNER_QUESTION_8:
    "Hat sich {{taxYear}} etwas an der Kirchenmitgliedschaft geändert?",
  EOY_INCOME_TAX_BASIC_DATA_PARTNER_QUESTION_9:
    "An welchem Tag im Jahr {{taxYear}} wurde die Religionszugehörigkeit geändert?",
  EOY_INCOME_TAX_BASIC_DATA_PARTNER_QUESTION_10:
    "In welcher Kirche war deiner/s Partner*in vor der Änderung Mitglied?",
  EOY_INCOME_TAX_BASIC_DATA_PARTNER_QUESTION_11:
    "In welcher Kirche war deiner/s Partner*in du nach der Änderung Mitglied?",
  EOY_INCOME_TAX_BASIC_DATA_PARTNER_QUESTION_12:
    "In welcher Kirche war deiner/s Partner*in Mitglied?",
  EOY_INCOME_TAX_BASIC_DATA_PARTNER_QUESTION_13:
    "Lag bei deiner/s Partner*in im Jahr {{taxYear}} eine Behinderung vor?",
  EOY_INCOME_TAX_BASIC_DATA_PARTNER_RESULT_1:
    "Geschafft! Es werden keine Belege für die Stammdaten deiner/s Partner*in  benötigt.",
  EOY_INCOME_TAX_BASIC_DATA_PARTNER_RESULT_2:
    "Fast geschafft. Lade im nächsten Schritt Dokumente hoch.",
  EOY_INCOME_TAX_CHILD_QUESTION_1: "Die folgenden Fragen betreffen Kind",
  EOY_INCOME_TAX_CHILD_QUESTION_2:
    "Erfüllt Kind einen dieser Berücksichtigungsgründe?",
  EOY_INCOME_TAX_CHILD_QUESTION_3:
    "Hat Kind im Jahr 2022 bereits eine erstmalige Berufsausbildung oder ein Studium abgeschlossen?",
  EOY_INCOME_TAX_CHILD_QUESTION_4:
    "War Kind im Jahr {{lastTaxYear}} berufstätig?",
  EOY_INCOME_TAX_CHILD_QUESTION_5:
    "Bestätige oder gib die Steueridentifikationsnummer von Kind an",
  EOY_INCOME_TAX_CHILD_QUESTION_6:
    "Hat Kind {{taxYear}} ausschließlich an einer Adresse gelebt?",
  EOY_INCOME_TAX_CHILD_QUESTION_7:
    "Bestätige oder füge die Adresse hinzu, in der Kind {{taxYear}} gelebt hat.",
  EOY_INCOME_TAX_CHILD_QUESTION_8:
    "Welche Familienkasse war im Jahr {{taxYear}} für Kind zuständig?",
  EOY_INCOME_TAX_CHILD_QUESTION_9:
    "In welchem Kindschaftsverhältnis hast du im Jahr {{taxYear}} zu Kind gestanden?",
  EOY_INCOME_TAX_CHILD_QUESTION_10:
    "Hat sich das Kindschaftsverhältnis zu Kind im Laufe des Jahres {{taxYear}} verändert?",
  EOY_INCOME_TAX_CHILD_QUESTION_11:
    "Wie hat sich das Kindschaftsverhältnis verändert?",
  EOY_INCOME_TAX_CHILD_QUESTION_12:
    "Wann startete das Kindschaftsverhältnis zu Kind?",
  EOY_INCOME_TAX_CHILD_QUESTION_13:
    "Wann endete das Kindschaftsverhältnis zu Kind?",
  EOY_INCOME_TAX_CHILD_QUESTION_14:
    "Wer hatte im Jahr {{taxYear}} Anspruch auf das Kindergeld für Kind?",
  EOY_INCOME_TAX_CHILD_QUESTION_15:
    "Warst du {{taxYear}} das alleinerziehende Elternteil für Kind?",
  EOY_INCOME_TAX_CHILD_QUESTION_16:
    "Wie lautet die Steuer-Identifikationsnummer von Kind?",
  EOY_INCOME_TAX_CHILD_QUESTION_17:
    "In welchem Kindschaftsverhältnis hat das andere Elternteil {{taxYear}} zu Kind gestanden?",
  EOY_INCOME_TAX_CHILD_QUESTION_18:
    "Hat sich das Kindschaftsverhältnis des anderen Elternteils zu Kind im Laufe des Jahres {{taxYear}} verändert?",
  EOY_INCOME_TAX_CHILD_QUESTION_19:
    "Wie hat sich das Kindschaftsverhältnis vom anderen Elternteil zu Kind verändert?",
  EOY_INCOME_TAX_CHILD_QUESTION_20:
    "Wann startete das Kindschaftsverhältnis zu Kind?",
  EOY_INCOME_TAX_CHILD_QUESTION_21:
    "Wann endete das Kindschaftsverhältnis zu Kind?",
  EOY_INCOME_TAX_CHILD_QUESTION_22:
    "Wie lautet der Name des anderen Elternteils?",
  EOY_INCOME_TAX_CHILD_QUESTION_23:
    "Wie lautet die Adresse des anderen Elternteils?",
  EOY_INCOME_TAX_CHILD_QUESTION_24:
    "Wie lautet das Geburtsdatum des anderen Elternteils?",
  EOY_INCOME_TAX_CHILD_QUESTION_25:
    "Wie war das Kindschaftsverhältnis deiner/s Partner*in zu Kind in {{taxYear}}?",
  EOY_INCOME_TAX_CHILD_QUESTION_26:
    "Hat sich das Kindschaftsverhältnis deiner/s Partner*in zu Kind während {{taxYear}} verändert?",
  EOY_INCOME_TAX_CHILD_QUESTION_27:
    "Wie hat sich das Kindschaftsverhältnis deiner/s Partner*in zu Kind verändert?",
  EOY_INCOME_TAX_CHILD_QUESTION_28:
    "Wann startete das Kindschaftsverhältnis zu Kind?",
  EOY_INCOME_TAX_CHILD_QUESTION_29:
    "Wann endete das Kindschaftsverhältnis zu Kind?",
  EOY_INCOME_TAX_CHILD_QUESTION_30:
    "Wer hatte im Jahr {{taxYear}} Anspruch auf das Kindergeld für Kind?",
  EOY_INCOME_TAX_CHILD_QUESTION_31:
    "Wie war das Kindschaftsverhältnis deiner/s Partner*in zu Kind in {{taxYear}}?",
  EOY_INCOME_TAX_CHILD_QUESTION_32:
    "Hat sich das Kindschaftsverhältnis deiner/s Partner*in zu Kind während {{taxYear}} verändert?",
  EOY_INCOME_TAX_CHILD_QUESTION_33:
    "Wie hat sich das Kindschaftsverhältnis deiner/s Partner*in zu Kind verändert?",
  EOY_INCOME_TAX_CHILD_QUESTION_34:
    "Wann startete das Kindschaftsverhältnis zu Kind?",
  EOY_INCOME_TAX_CHILD_QUESTION_35:
    "Wann endete das Kindschaftsverhältnis zu Kind?",
  EOY_INCOME_TAX_CHILD_QUESTION_36:
    "Wer hatte im Jahr {{taxYear}} Anspruch auf das Kindergeld für Kind?",
  EOY_INCOME_TAX_CHILD_QUESTION_37:
    "War dein/e Partner*in {{taxYear}} der alleinerziehende Elternteil von Kind?",
  EOY_INCOME_TAX_CHILD_QUESTION_38:
    "Wie lautet die Steuer-Identifikationsnummer von Kind?",
  EOY_INCOME_TAX_CHILD_QUESTION_39:
    "In welchem Kindschaftsverhältnis hat das andere Elternteil {{taxYear}} zu Kind gestanden?",
  EOY_INCOME_TAX_CHILD_QUESTION_40:
    "Hat sich das Kindschaftsverhältnis des anderen Elternteils zu Kind im Laufe des Jahres {{taxYear}} verändert?",
  EOY_INCOME_TAX_CHILD_QUESTION_41:
    "Wie hat sich das Kindschaftsverhältnis vom anderen Elternteil zu Kind verändert?",
  EOY_INCOME_TAX_CHILD_QUESTION_42:
    "Wann startete das Kindschaftsverhältnis zu Kind?",
  EOY_INCOME_TAX_CHILD_QUESTION_43:
    "Wann endete das Kindschaftsverhältnis zu Kind?",
  EOY_INCOME_TAX_CHILD_QUESTION_44:
    "Wie lautet die Adresse des anderen Elternteils?",
  EOY_INCOME_TAX_CHILD_QUESTION_45:
    "Wie lautet die Adresse des anderen Elternteils?",
  EOY_INCOME_TAX_CHILD_QUESTION_46:
    "Wie lautet das Geburtsdatum des anderen Elternteils?",
  EOY_INCOME_TAX_CHILD_QUESTION_47:
    "Lag bei Kind {{taxYear}} eine Behinderung vor?",
  EOY_INCOME_TAX_CHILD_QUESTION_48:
    "Bitte wähle aus, falls dir im Jahr {{taxYear}} eine dieser steuerrelevanten Ausgaben für Kind entstanden ist.",
  EOY_INCOME_TAX_CHILD_RESULT_1:
    "Geschafft! Es werden keine Belege für die Anlage Kind benötigt.",
  EOY_INCOME_TAX_CHILD_RESULT_2:
    "Fast geschafft. Lade im nächsten Schritt Dokumente hoch.",
  EOY_INCOME_TAX_CHILD_RESULT_3:
    "Kind kann nicht steuerlich berücksichtigt werden, es ist nichts weiter zu tun.",
  EOY_INCOME_TAX_PRIVATE_EXPENSES_QUESTION_1:
    "Welche Art von Krankenversicherung hattest du {{taxYear}}?",
  EOY_INCOME_TAX_PRIVATE_EXPENSES_QUESTION_2:
    "Hast du im Jahr {{taxYear}} Beiträge in eine Riester- oder Rürup-Rente eingezahlt?",
  EOY_INCOME_TAX_PRIVATE_EXPENSES_QUESTION_3: "Wer erhält die Kinderzulage?",
  EOY_INCOME_TAX_PRIVATE_EXPENSES_QUESTION_4:
    "Falls {{taxYear}} vorhanden, wähle alle zutreffenden Versicherungen aus.",
  EOY_INCOME_TAX_PRIVATE_EXPENSES_QUESTION_5:
    "Falls {{taxYear}} vorhanden, wähle alle zutreffenden Ausgaben aus.",
  EOY_INCOME_TAX_PRIVATE_EXPENSES_RESULT_1:
    "Geschafft! Es werden keine Belege für deine Privaten Ausgaben benötigt.",
  EOY_INCOME_TAX_PRIVATE_EXPENSES_RESULT_2:
    "Fast geschafft. Lade im nächsten Schritt Dokumente hoch.",
  EOY_INCOME_TAX_PRIVATE_EXPENSES_PARTNER_QUESTION_1:
    "Welche Art von Krankenversicherung hatte dein/e Partner*in {{taxYear}}?",
  EOY_INCOME_TAX_PRIVATE_EXPENSES_PARTNER_QUESTION_2:
    "Hat dein/e Partner*in im Jahr {{taxYear}} Beiträge in eine Riester- oder Rürup-Rente eingezahlt?",
  EOY_INCOME_TAX_PRIVATE_EXPENSES_PARTNER_QUESTION_3:
    "Wer erhält die Kinderzulage?",
  EOY_INCOME_TAX_PRIVATE_EXPENSES_PARTNER_QUESTION_4:
    "Falls {{taxYear}} vorhanden, wähle alle zutreffenden Versicherungen aus.",
  EOY_INCOME_TAX_PRIVATE_EXPENSES_PARTNER_QUESTION_5:
    "Falls {{taxYear}} vorhanden, wähle alle zutreffenden Ausgaben aus.",
  EOY_INCOME_TAX_PRIVATE_EXPENSES_PARTNER_RESULT_1:
    "Geschafft! Es werden keine Belege für die Privaten Ausgaben deiner/s Partner*in benötigt.",
  EOY_INCOME_TAX_PRIVATE_EXPENSES_PARTNER_RESULT_2:
    "Fast geschafft. Lade im nächsten Schritt Dokumente hoch.",
  EOY_INCOME_TAX_ADDITIONAL_INCOME_QUESTION_1:
    "Falls vorhanden wähle sämtliche Arten zusätzlicher Einkommen aus, die du 2022 erhalten hast.",
  EOY_INCOME_TAX_ADDITIONAL_INCOME_QUESTION_2:
    "Hattest du für die nichtselbstständige Arbeit auch Ausgaben?",
  EOY_INCOME_TAX_ADDITIONAL_INCOME_QUESTION_3:
    "Falls vorhanden wähle sämtliche Arten von Sozialleistungen aus, die du {{taxYear}} erhalten hast.",
  EOY_INCOME_TAX_ADDITIONAL_INCOME_RESULT_1:
    "Geschafft! Es werden keine Belege für deine Zusätzlichen Einkünfte benötigt.",
  EOY_INCOME_TAX_ADDITIONAL_INCOME_RESULT_2:
    "Fast geschafft. Lade im nächsten Schritt Dokumente hoch.",
  EOY_INCOME_TAX_ADDITIONAL_INCOME_PARTNER_QUESTION_1:
    "Falls vorhanden wähle sämtliche Arten zusätzlicher Einkommen aus, die dein*e Partner*in {{taxYear}} erhalten hat.",
  EOY_INCOME_TAX_ADDITIONAL_INCOME_PARTNER_QUESTION_2:
    "Hatte dein*e Partner*in für die nicht-selbstständige Arbeit auch Ausgaben?",
  EOY_INCOME_TAX_ADDITIONAL_INCOME_PARTNER_QUESTION_3:
    "Falls vorhanden wähle sämtliche Arten von Sozialleistungen aus, die dein*e Partner*in {{taxYear}} erhalten hat.",
  EOY_INCOME_TAX_ADDITIONAL_INCOME_PARTNER_RESULT_1:
    "Geschafft! Es werden keine Belege für die Zusätzlichen Einkünfte deiner/s Partner*in benötigt.",
  EOY_INCOME_TAX_ADDITIONAL_INCOME_PARTNER_RESULT_2:
    "Fast geschafft. Lade im nächsten Schritt Dokumente hoch.",
  BIZ_TAX_BASIC_DATA_QUESTION_1:
    "Welche Berufsbezeichnung passt am besten zu deiner Tätigkeit?",
  BIZ_TAX_BASIC_DATA_QUESTION_2: "Bist du erst seit {{taxYear}} selbständig?",
  BIZ_TAX_BASIC_DATA_QUESTION_3:
    "Nenne uns das exakte Datum, an dem du deine Selbständigkeit gestartet hast.",
  BIZ_TAX_BASIC_DATA_QUESTION_4:
    "Hast du deine Selbständigkeit {{taxYear}} aufgegeben?",
  BIZ_TAX_BASIC_DATA_QUESTION_5:
    "Wir benötigen das Enddatum deiner Selbständigkeit.",
  BIZ_TAX_BASIC_DATA_QUESTION_6:
    "Für die Umsatzsteuererklärung {{taxYear}} benötigen wir die Summe der Umsatzsteuervorauszahlungen, die du für das Jahr {{taxYear}} geleistet hast.",
  BIZ_TAX_BASIC_DATA_QUESTION_7:
    "Warst du {{taxYear}} Kleinunternehmer nach § 19 UStG?",
  BIZ_TAX_BASIC_DATA_QUESTION_8:
    "Da du {{taxYear}} die sog. Kleinunternehmerregelung (§ 19 Umsatzsteuergesetz) genutzt hast, benötigen wir deinen Umsatz aus dem Jahr {{lastTaxYear}}.",
  BIZ_TAX_BASIC_DATA_QUESTION_9:
    "Hattest du {{taxYear}} ein angemeldetes Gewerbe?",
  BIZ_TAX_BASIC_DATA_QUESTION_10:
    "Wie lautete deine Geschäftsadresse {{taxYear}}?",
  BIZ_TAX_BASIC_DATA_QUESTION_10_1:
    "Leider konnten wir für deine Adresse nicht die zuständige Gemeinde finden.",
  BIZ_TAX_BASIC_DATA_QUESTION_11:
    "Hat sich deine Geschäftsadresse im Laufe des Jahres {{taxYear}} verändert?",
  BIZ_TAX_BASIC_DATA_QUESTION_12:
    "Wann war das Einzugsdatum in deine Geschäftsadresse?",
  BIZ_TAX_BASIC_DATA_QUESTION_12_1: "Wie lautete deine alte Geschäftsadresse?",
  BIZ_TAX_BASIC_DATA_QUESTION_13:
    "Lag dein Betrieb in einem anderen Bezirk als dein privater Wohnsitz im Jahres {{taxYear}}?",
  BIZ_TAX_BASIC_DATA_QUESTION_14:
    "Es gibt ein Problem mit deinem Firmennamen. Ändere ihn entsprechend der Richtlinien in deiner Mobile App.",
  BIZ_TAX_BASIC_DATA_QUESTION_15: "Ist das der Name deines Unternehmens?",
  BIZ_TAX_BASIC_DATA_QUESTION_16:
    "Bitte ändere deinen Firmennamen jetzt in deiner Kontist Mobile App.",
  BIZ_TAX_BASIC_DATA_QUESTION_17:
    "Für die Gewerbesteuererklärung {{taxYear}} benötigen wir die Summe der Gewerbesteuervorauszahlungen, die du für das Jahr {{taxYear}} geleistet hast.",
  BIZ_TAX_BASIC_DATA_RESULT: "Das war’s auch schon!",

  BIZ_TAX_CAR_USAGE_QUESTION_10: "Was für einen Pkw benutzt du?",
  BIZ_TAX_CAR_USAGE_QUESTION_11:
    "Sind alle deine Pkw-Ausgaben in deiner Kontist Buchhaltung erfasst worden?",
  BIZ_TAX_CAR_USAGE_QUESTION_1:
    "Hast du {{taxYear}} einen Pkw im Rahmen deiner Selbständigkeit genutzt?",
  BIZ_TAX_CAR_USAGE_QUESTION_2:
    "Bist du mit dem Auto von deiner Wohnung zu deinem Betrieb (z.B. Büro, Werkstatt etc.) gefahren?",
  BIZ_TAX_CAR_USAGE_QUESTION_3:
    "Wie viele Kilometer ist die einfache Entfernung zwischen deiner Wohnung und deinem Betrieb?",
  BIZ_TAX_CAR_USAGE_QUESTION_4:
    "Wie viele Tage im Jahr bist du mit dem Auto zwischen deiner Wohnung und deinem Betrieb gefahren?",
  BIZ_TAX_CAR_USAGE_QUESTION_5:
    "Hattest du im Jahr {{taxYear}} mehr als ein Auto (gleichzeitig oder nacheinander)?",
  BIZ_TAX_CAR_USAGE_QUESTION_6:
    "Zu welchem Anteil benutzt du deinen Pkw betrieblich?",
  BIZ_TAX_CAR_USAGE_QUESTION_7: "Hast du {{taxYear}} ein Fahrtenbuch geführt?",
  BIZ_TAX_CAR_USAGE_QUESTION_8:
    "Sind diese Pkw-Fahrten ausschließlich Geschäftsreisen (länger als 8 Stunden), ausschließlich betriebliche Fahrten unter 8 Stunden oder beides?",
  BIZ_TAX_CAR_USAGE_QUESTION_9:
    "Möchtest du die 1 %-Regelung anwenden oder hast du ein Fahrtenbuch geführt?",
  BIZ_TAX_CAR_USAGE_RESULT_1: "Das war’s auch schon!",
  BIZ_TAX_CAR_USAGE_RESULT_2: "Geschafft! Es werden keine Belege benötigt.",
  BIZ_TAX_CAR_USAGE_RESULT_3:
    "Fast geschafft: Jetzt musst du nur noch einige Angaben zu deinem Auto machen.",

  BIZ_TAX_OFFICE_USAGE_QUESTION_1:
    "Hast du {{taxYear}} von Zuhause aus gearbeitet?",
  BIZ_TAX_OFFICE_USAGE_QUESTION_1_1:
    "Hattest du im Jahr {{taxYear}} mehr als ein Homeoffice?",
  BIZ_TAX_OFFICE_USAGE_QUESTION_1_2:
    "Leider können wir nur für ein Home Office die echten Kosten absetzen. Alternativ kannst du aber auch die Home Office Pauschale nutzen, dabei ist es egal, ob du umgezogen bist.",
  BIZ_TAX_OFFICE_USAGE_QUESTION_2:
    "Ist der Arbeitsplatz in einem räumlich abgetrennten Bereich?",
  BIZ_TAX_OFFICE_USAGE_QUESTION_3: "Wie wird dieser Arbeitsplatz genutzt?",
  BIZ_TAX_OFFICE_USAGE_QUESTION_4:
    "Wie viele Tage hast du {{taxYear}} dein Arbeitszimmer genutzt?",
  BIZ_TAX_OFFICE_USAGE_QUESTION_5:
    "Wird die betriebliche Tätigkeit hauptsächlich von Zuhause ausgeübt?",
  BIZ_TAX_OFFICE_USAGE_QUESTION_5_1:
    "Ist es das erste Mal, dass du <0>dieses</0> Arbeitszimmer steuerlich absetzt?",
  BIZ_TAX_OFFICE_USAGE_QUESTION_6:
    "Wie viele Monate hast du {{taxYear}} dein Arbeitszimmer genutzt?",
  BIZ_TAX_OFFICE_USAGE_QUESTION_6_1: "Wie groß ist dein Arbeitszimmer?",
  BIZ_TAX_OFFICE_USAGE_QUESTION_6_2:
    "Wie groß ist die Gesamt-Wohnfläche deiner Wohnung/deines Hauses?",
  BIZ_TAX_OFFICE_USAGE_QUESTION_6_3:
    "Wird das Arbeitszimmer auch noch von anderen Personen genutzt?",
  BIZ_TAX_OFFICE_USAGE_QUESTION_6_4: "Von wem wird die Wohnungsmiete bezahlt?",
  BIZ_TAX_OFFICE_USAGE_QUESTION_7:
    "Wohnst du zur Miete oder ist die Immobilie, in der sich dein Homeoffice befindet, dein Eigentum?",
  BIZ_TAX_OFFICE_USAGE_QUESTION_8:
    "Folgende Ausgaben kannst du zusätzlich zur Miete steuerlich absetzbar machen. Bitte wähle aus was davon auf dich zutrifft – Mehrfachauswahl möglich.",
  BIZ_TAX_TRAVEL_EXPENSES_QUESTION_1:
    "Hast du {{taxYear}} Geschäftsreisen unternommen?",
  BIZ_TAX_TRAVEL_EXPENSES_QUESTION_2:
    "Hast du {{taxYear}} deinen privaten Pkw für Geschäftsreisen genutzt?",
  BIZ_TAX_TRAVEL_EXPENSES_RESULT_1: "Das war’s auch schon!",
  BIZ_TAX_TRAVEL_EXPENSES_RESULT_2:
    "Fast geschafft: Jetzt musst du nur noch einige Angaben zu deine Geschäftsreisen machen.",
};

export const QUESTIONNAIRE_DOCUMENT_TYPE: { [type: string]: string } = {
  EOY_CAR_USAGE_LOGBOOK: "Fahrtenbuch",
  EOY_CAR_USAGE_OTHER: "Sonstiges",
  EOY_CAR_USAGE_PRIVATELY_PAID_CAR_EXPENSES: "Privat gezahlte KFZ Ausgaben",
  EOY_CAR_USAGE_PURCHASE_CONTRACT: "Kaufvertrag KFZ",
  EOY_CAR_USAGE_TRAVELED_KM_WITH_PRIVATE_CAR:
    "Gefahrene Kilometer mit priv. KZF",
  EOY_OFFICE_USAGE_ELECTRICITY: "Strom",
  EOY_OFFICE_USAGE_HEATING: "Heizkosten",
  EOY_OFFICE_USAGE_OTHER: "Sonstiges",
  EOY_OFFICE_USAGE_PHONE_OR_INTERNET: "Internet/Telefon",
  EOY_OFFICE_USAGE_RENT_OR_INTEREST: "Miete/Zinsen",
  EOY_OFFICE_USAGE_UTILITY: "Nebenkosten aktuell",
  EOY_OFFICE_USAGE_UTILITY_AFTER_PAYMENT:
    "Nebenkosten Nachzahlung vergangenes Jahr",
  EOY_OFFICE_USAGE_FLOOR_PLAN: "Grundriss Wohnung und häusliches Arbeitszimmer",
  EOY_TRAVEL_EXPENSES_BUSINESS_TRIPS: "Verpflegungsmehraufwand Dokumentation",
  EOY_TRAVEL_EXPENSES_OTHER: "Sonstiges",
  EOY_TRAVEL_EXPENSES_TRAVELED_KM_WITH_PRIVATE_CAR:
    "Gefahrene Kilometer mit priv. KZF",
  EOY_INCOME_TAX_BASIC_DATA_PROOF_OF_DISABILITY:
    "Angaben zu deiner Behinderung",
  EOY_INCOME_TAX_BASIC_DATA_RENTAL_AND_LEASE: "Vermietung & Verpachtung",
  EOY_INCOME_TAX_BASIC_DATA_OTHER: "Sonstiges",
  EOY_INCOME_TAX_BASIC_DATA_PARTNER_PROOF_OF_DISABILITY:
    "Angaben zu der Behinderung Ihres Partners",
  EOY_INCOME_TAX_BASIC_DATA_PARTNER_OTHER: "Sonstiges",
  EOY_INCOME_TAX_CHILD_PROOF_OF_DISABILITY: "Angaben zu deiner Behinderung",
  EOY_INCOME_TAX_CHILD_CHILDCARE: "Kinderbetreuungskosten (ohne Essensgeld)",
  EOY_INCOME_TAX_CHILD_SCHOOL_FEES: "Schulgeld (ohne Essensgeld)",
  EOY_INCOME_TAX_CHILD_ADDITIONAL_HEALTH_INSURANCE:
    "Zusätzliche Krankenversicherung",
  EOY_INCOME_TAX_CHILD_EXTENSIVE_MEDICAL_EXPENSES: "Umfangreiche Arztkosten",
  EOY_INCOME_TAX_CHILD_DISABILITY_COSTS: "Invaliditätskosten",
  EOY_INCOME_TAX_CHILD_UNIVERSITY_FEES: "Studiengebühren",
  EOY_INCOME_TAX_CHILD_OTHER: "Sonstiges",
  EOY_INCOME_TAX_PRIVATE_EXPENSES_HEALTH_INSURANCE: "Krankenversicherung",
  EOY_INCOME_TAX_PRIVATE_EXPENSES_RURUP: "Rürup",
  EOY_INCOME_TAX_PRIVATE_EXPENSES_REISTER: "Riester",
  EOY_INCOME_TAX_PRIVATE_EXPENSES_UNEMPLOYMENT_INSURANCE:
    "Zusätzliche Arbeitslosenversicherung",
  EOY_INCOME_TAX_PRIVATE_EXPENSES_PENSION_INSURANCE:
    "Private Rentenversicherung",
  EOY_INCOME_TAX_PRIVATE_EXPENSES_VEHICLE_LIABILITY:
    "Private (Kfz-)Haftpflicht",
  EOY_INCOME_TAX_PRIVATE_EXPENSES_ACCIDENT_INSURANCE: "Unfallversicherung",
  EOY_INCOME_TAX_PRIVATE_EXPENSES_LIFE_INSURANCE: "Lebensversicherung",
  EOY_INCOME_TAX_PRIVATE_EXPENSES_DISABILITY_INSURANCE:
    "Berufsunfähigkeitsversicherung",
  EOY_INCOME_TAX_PRIVATE_EXPENSES_EXTRAORDINARY_BURDENS:
    "Außergewöhnliche Belastungen",
  EOY_INCOME_TAX_PRIVATE_EXPENSES_PRIVATE_DONATIONS: "Private Spenden",
  EOY_INCOME_TAX_PRIVATE_EXPENSES_HOUSEHOLD_SERVICES:
    "Haushaltsnahe Dienst- oder Handwerkerleistungen",
  EOY_INCOME_TAX_PRIVATE_EXPENSES_ALIMENTS: "Unterhalt",
  EOY_INCOME_TAX_PRIVATE_EXPENSES_UNIVERSITY_FEES: "Studiengebühren",
  EOY_INCOME_TAX_PRIVATE_EXPENSES_OTHER: "Sonstiges",
  EOY_INCOME_TAX_PRIVATE_EXPENSES_PARTNER_HEALTH_INSURANCE:
    "Krankenversicherung",
  EOY_INCOME_TAX_PRIVATE_EXPENSES_PARTNER_RURUP: "Rürup",
  EOY_INCOME_TAX_PRIVATE_EXPENSES_PARTNER_REISTER: "Riester",
  EOY_INCOME_TAX_PRIVATE_EXPENSES_PARTNER_UNEMPLOYMENT_INSURANCE:
    "Zusätzliche Arbeitslosenversicherung",
  EOY_INCOME_TAX_PRIVATE_EXPENSES_PARTNER_PENSION_INSURANCE:
    "Private Rentenversicherung",
  EOY_INCOME_TAX_PRIVATE_EXPENSES_PARTNER_VEHICLE_LIABILITY:
    "Private (Kfz-)Haftpflicht",
  EOY_INCOME_TAX_PRIVATE_EXPENSES_PARTNER_ACCIDENT_INSURANCE:
    "Unfallversicherung",
  EOY_INCOME_TAX_PRIVATE_EXPENSES_PARTNER_LIFE_INSURANCE: "Lebensversicherung",
  EOY_INCOME_TAX_PRIVATE_EXPENSES_PARTNER_DISABILITY_INSURANCE:
    "Berufsunfähigkeitsversicherung",
  EOY_INCOME_TAX_PRIVATE_EXPENSES_PARTNER_EXTRAORDINARY_BURDENS:
    "Außergewöhnliche Belastungen",
  EOY_INCOME_TAX_PRIVATE_EXPENSES_PARTNER_PRIVATE_DONATIONS: "Private Spenden",
  EOY_INCOME_TAX_PRIVATE_EXPENSES_PARTNER_HOUSEHOLD_SERVICES:
    "Haushaltsnahe Dienst- oder Handwerkerleistungen",
  EOY_INCOME_TAX_PRIVATE_EXPENSES_PARTNER_ALIMENTS: "Unterhalt",
  EOY_INCOME_TAX_PRIVATE_EXPENSES_PARTNER_UNIVERSITY_FEES: "Studiengebühren",
  EOY_INCOME_TAX_PRIVATE_EXPENSES_PARTNER_OTHER: "Sonstiges",
  EOY_INCOME_TAX_ADDITIONAL_INCOME_OTHER: "Sonstiges Einkommen",
  EOY_INCOME_TAX_ADDITIONAL_INCOME_SALE_OF_PROPERTY: "Verkauf Immobilie",
  EOY_INCOME_TAX_ADDITIONAL_INCOME_ADDL_SELF_EMPLOYMENT:
    "Zusätzliche Selbstständigkeit",
  EOY_INCOME_TAX_ADDITIONAL_INCOME_INTERNATIONAL_INCOME:
    "Ausländische Einkünfte",
  EOY_INCOME_TAX_ADDITIONAL_INCOME_CRYPTO: "Krypto",
  EOY_INCOME_TAX_ADDITIONAL_INCOME_PENSIONS:
    "Renten- und Versorgungsbezüge (Staatlich- und privat)",
  EOY_INCOME_TAX_ADDITIONAL_INCOME_CAPITAL_ASSETS_INTL:
    "Kapitalvermögen (international)",
  EOY_INCOME_TAX_ADDITIONAL_INCOME_EMPLOYED_WORK:
    "Nichtselbstständige Arbeit (z.B. Festanstellung)",
  EOY_INCOME_TAX_ADDITIONAL_INCOME_EMPLOYMENT_EXPENSES:
    "Ausgaben für nichtselbstständige Arbeit",
  EOY_INCOME_TAX_ADDITIONAL_INCOME_PARTNER_OTHER: "Sonstiges Einkommen",
  EOY_INCOME_TAX_ADDITIONAL_INCOME_PARTNER_SALE_OF_PROPERTY:
    "Verkauf Immobilie",
  EOY_INCOME_TAX_ADDITIONAL_INCOME_PARTNER_ADDL_SELF_EMPLOYMENT:
    "Zusätzliche Selbstständigkeit",
  EOY_INCOME_TAX_ADDITIONAL_INCOME_PARTNER_INTERNATIONAL_INCOME:
    "Ausländische Einkünfte",
  EOY_INCOME_TAX_ADDITIONAL_INCOME_PARTNER_CRYPTO: "Krypto",
  EOY_INCOME_TAX_ADDITIONAL_INCOME_PARTNER_PENSIONS:
    "Renten- und Versorgungsbezüge (Staatlich- und privat)",
  EOY_INCOME_TAX_ADDITIONAL_INCOME_PARTNER_CAPITAL_ASSETS_INTL:
    "Kapitalvermögen (international)",
  EOY_INCOME_TAX_ADDITIONAL_INCOME_PARTNER_EMPLOYED_WORK:
    "Nichtselbstständige Arbeit (z.B. Festanstellung)",
  EOY_INCOME_TAX_ADDITIONAL_INCOME_PARTNER_EMPLOYMENT_EXPENSES:
    "Ausgaben für nichtselbstständige Arbeit",
};

export enum QuestionnaireDocumentInputType {
  BOOLEAN = "BOOLEAN",
  CURRENCY = "CURRENCY",
  DATE = "DATE",
  NUMBER = "NUMBER",
  STRING = "STRING",
  HOME_OFFICE_EXPENSE_PERIOD = "HOME_OFFICE_EXPENSE_PERIOD",
  HOME_OFFICE_VAT_RATE = "HOME_OFFICE_VAT_RATE",
}

export const QUESTIONNAIRE_DOCUMENT_INPUTS_CONFIG: {
  [inputKey: string]: { label: string; type: QuestionnaireDocumentInputType };
} = {
  amount: { label: "Betrag", type: QuestionnaireDocumentInputType.CURRENCY },
  catalogPrice: {
    label: "Bruttolistenpreis",
    type: QuestionnaireDocumentInputType.CURRENCY,
  },
  comment: { label: "Kommentar", type: QuestionnaireDocumentInputType.STRING },
  date: { label: "Datum", type: QuestionnaireDocumentInputType.DATE },
  homeCompanyKm: {
    label: "Wie viele KM davon zwischen Büro und Zuhause",
    type: QuestionnaireDocumentInputType.NUMBER,
  },
  vatRate: {
    label: "Umsatzsteuersatz",
    type: QuestionnaireDocumentInputType.HOME_OFFICE_VAT_RATE,
  },
  privateCarTravelDistance: {
    label: "Summe gefahrene km mit privaten KFZ",
    type: QuestionnaireDocumentInputType.NUMBER,
  },
  privateKm: {
    label: "Anzahl der privat gefahrenen KM",
    type: QuestionnaireDocumentInputType.NUMBER,
  },
  purchaseDate: {
    label: "Kaufdatum",
    type: QuestionnaireDocumentInputType.DATE,
  },
  totalKm: {
    label: "Anzahl der gesamt gefahren KM",
    type: QuestionnaireDocumentInputType.NUMBER,
  },
  adjustByOfficeAreaShare: {
    label:
      "Betrifft der Beleg die ganze Wohnung oder ist er anteilig für das Arbeitszimmer?",
    type: QuestionnaireDocumentInputType.BOOLEAN,
  },
  monthsUsed: {
    label: "Nutzungszeit des Arbeitszimmer in 2021",
    type: QuestionnaireDocumentInputType.NUMBER,
  },
  period: {
    label: "Abrechnungszeitraum des Belegs",
    type: QuestionnaireDocumentInputType.HOME_OFFICE_EXPENSE_PERIOD,
  },
  totalAmount: {
    label: "Summe Pauschbeträge in €",
    type: QuestionnaireDocumentInputType.CURRENCY,
  },
  isTotalKilometersIncluded: {
    label:
      "Inkludiert Summe Tagespauschalen Kilometer-Pauschalen für privates Kfz?",
    type: QuestionnaireDocumentInputType.BOOLEAN,
  },
  mark: { label: "Merkzeichen", type: QuestionnaireDocumentInputType.STRING },
  degreeOfDisability: {
    label: "Grad der Behinderung",
    type: QuestionnaireDocumentInputType.NUMBER,
  },
};
