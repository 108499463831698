import React, { useEffect, useState, useMemo } from "react";
import finanzamt from "finanzamt";

import {
  YesNoEnum,
  IUser,
  IUserDetails,
  PaymentFrequency,
  PermanentExtensionStatus,
  TradeTax,
  VatExemptionWithItd,
  VatExemptionWithoutItd,
  TypeOfProfit,
} from "../../types";
import {
  formatAccountingSource,
  formatBookkeepingServices,
  formatPermanentExtensionStatus,
  formatVatExemption,
  isVatExemptionWithId,
  VAT_EXEMPTION_OPTIONS,
} from "../pages/Mandanten/Common/UserDetails/utils";
import EditableCustomerAttribute, {
  EditType,
} from "./EditableCustomerAttribute";

import {
  CustomerAttributes,
  CustomerInformationCard,
  CustomerInformationSectionTitle,
  SectionTitle,
} from "./styledComponents";
import CustomerAttribute from "./CustomerAttribute";
import { Finanzamt } from "../../types/Finanzamt";
import { generateFinanzamtLink } from "./helpers";
import { formatPaymentFrequency } from "../../utils/paymentFrequency";
import { formatBooleanToString } from "../../utils";
import {
  AccountingSourceType,
  UpsertAccountingSourcePayload,
} from "../../types/AccountingSource.type";
import ActionLogDrawerAsync from "./ActionLogDrawerAsync";
import { formatTypeOfProfit } from "../../utils/profitDetermination";
import FibuFinalCheck from "./FibuFinalCheck";
import { TAX_ADVISORY_PERMISSION_SCOPES } from "../../constants";
import { getPermissionScope } from "../../gapi";

const normalizePhone = (href?: string): string | undefined => {
  if (!href) return undefined;
  const regex = /\s+/g;
  return href.replace(regex, "");
};

const normalizeHref = (href?: string): string | undefined => {
  if (!href) return undefined;
  const regex = /^https?:\/\//i;
  return href.replace(regex, "");
};

const renderLink = ({
  label,
  text,
  hrefSchema,
  href,
}: {
  label?: string;
  text?: string;
  hrefSchema?: string;
  href?: string;
}) => {
  if (!href) return "";

  return (
    <div>
      {label ? <span>{label}:&nbsp;</span> : ""}
      <a
        href={`${hrefSchema}${href}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {text}
      </a>
    </div>
  );
};

const UserTaxInformationBlock = ({
  user,
  editUser,
  removeAccountingSource,
  upsertAccountingSource,
  isOnboarding,
}: {
  user: IUser;
  editUser: (email: string, userDetails: IUserDetails) => Promise<void>;
  removeAccountingSource: (email: string, year: number) => Promise<void>;
  upsertAccountingSource: (
    email: string,
    payload: UpsertAccountingSourcePayload
  ) => Promise<void>;
  isOnboarding?: boolean;
}) => {
  const [taxOffice, setTaxOffice] = useState<Finanzamt>();
  const [finanzamtLink, setFinanzamtLink] = useState<string>();
  const ACCOUNTING_SOURCE_YEARS = [2020, 2021, 2022];

  const hasTaxAdvisoryPermissionScope = TAX_ADVISORY_PERMISSION_SCOPES.includes(
    getPermissionScope()
  );

  useEffect(() => {
    if (user.taxNumber) {
      const firstFourDigits = user.taxNumber.substr(0, 4);
      const taxOfficeDetails = finanzamt(firstFourDigits);
      setTaxOffice(taxOfficeDetails);
      setFinanzamtLink(generateFinanzamtLink(user.taxNumber));
    } else {
      setTaxOffice(undefined);
    }
  }, [user.taxNumber]);

  const vatYearSettings = (user?.vatYearSettings || []).filter(({ id }) =>
    Boolean(id)
  );

  const vatYearSettingSubTexts = useMemo(
    () =>
      vatYearSettings.reduce((all, vatYearSetting) => {
        const { year, id } = vatYearSetting;
        return {
          ...all,
          [String(id)]: `Year: ${year}`,
        };
      }, {}),
    [vatYearSettings]
  );

  const vatYearSettingIds = useMemo(
    () => vatYearSettings.map(({ id }) => String(id)),
    [vatYearSettings]
  );

  return (
    <>
      <CustomerInformationSectionTitle>
        Tax Information
      </CustomerInformationSectionTitle>
      <CustomerInformationCard>
        <CustomerAttributes>
          <CustomerAttribute label="Finanzamt">
            {taxOffice ? (
              <>
                {renderLink({
                  label: "",
                  text: taxOffice.name,
                  hrefSchema: "",
                  href: finanzamtLink,
                })}
                {taxOffice.hausanschrift && (
                  <div>
                    {taxOffice.hausanschrift.strasse}{" "}
                    {taxOffice.hausanschrift.hausNr}
                    {", "}
                    {taxOffice.hausanschrift.plz} {taxOffice.hausanschrift.ort}
                  </div>
                )}
                {renderLink({
                  label: "",
                  text: normalizeHref(taxOffice.url),
                  hrefSchema: "https://",
                  href: normalizeHref(taxOffice.url),
                })}
              </>
            ) : (
              <span>-</span>
            )}
          </CustomerAttribute>
          <CustomerAttribute label="Finanzamt contact">
            {taxOffice ? (
              <>
                {renderLink({
                  label: "Phone",
                  text: taxOffice.tel,
                  hrefSchema: "tel:",
                  href: normalizePhone(taxOffice.tel),
                })}
                {renderLink({
                  label: "Fax",
                  text: taxOffice.fax,
                  hrefSchema: "tel:",
                  href: normalizePhone(taxOffice.fax),
                })}
                {renderLink({
                  label: "",
                  text: taxOffice.mail,
                  hrefSchema: "mailto:",
                  href: taxOffice.mail?.toLocaleLowerCase(),
                })}
              </>
            ) : (
              <span>-</span>
            )}
          </CustomerAttribute>
          <EditableCustomerAttribute
            label="Finanzamt notes (contact person)"
            initialValue={user.kontaxUser?.finanzamtNotes}
            editType={EditType.TextArea}
            inputMaxLength={500}
            onSaveHandler={async (finanzamtNotes: string) => {
              await editUser(user.email, { kontaxUser: { finanzamtNotes } });
            }}
            isReadOnly={!hasTaxAdvisoryPermissionScope}
          />
          <EditableCustomerAttribute
            label="VAT number"
            initialValue={user.vatNumber}
            editType={EditType.Text}
            pattern={/[0-9A-Z]/g}
            onSaveHandler={async (vatNumber: string) => {
              await editUser(user.email, { vatNumber });
            }}
            isReadOnly={!hasTaxAdvisoryPermissionScope}
          />
          {!isOnboarding && (
            <CustomerAttribute label="Connected bookkeeping services">
              {formatBookkeepingServices(user)}
            </CustomerAttribute>
          )}
          <div>
            <SectionTitle>
              <span>USt-Voranmeldung</span>
              <ActionLogDrawerAsync
                title="Action log"
                modelName="vat_year_setting"
                recordIds={vatYearSettingIds}
                subTexts={vatYearSettingSubTexts}
                small
              />
            </SectionTitle>

            {user.vatYearSettings &&
              user.vatYearSettings.map((vatYearSetting) => (
                <EditableCustomerAttribute
                  key={vatYearSetting.year}
                  label={`USt-Voranmeldungszeitraum ${vatYearSetting.year}`}
                  initialValue={vatYearSetting.vatPaymentFrequency}
                  editType={EditType.Select}
                  formatFunction={formatPaymentFrequency}
                  options={Object.values(PaymentFrequency)}
                  onSaveHandler={async (
                    vatPaymentFrequency: PaymentFrequency
                  ) => {
                    await editUser(user.email, {
                      vatYearSettings: [
                        {
                          vatPaymentFrequency,
                          year: vatYearSetting.year,
                        },
                      ],
                    });
                  }}
                  isReadOnly={!hasTaxAdvisoryPermissionScope}
                />
              ))}
          </div>
          <div>
            <SectionTitle>
              <span>Art der Gewinnermittlung</span>
            </SectionTitle>

            {user.profitDeterminations?.map((profitDetermination) => (
              <EditableCustomerAttribute
                key={profitDetermination.year}
                label={`Art der Gewinnermittlung ${profitDetermination.year}`}
                initialValue={
                  profitDetermination.typeOfProfit || TypeOfProfit.EUR
                }
                editType={EditType.Select}
                formatFunction={formatTypeOfProfit}
                options={Object.values(TypeOfProfit)}
                onSaveHandler={async (typeOfProfit: TypeOfProfit) => {
                  await editUser(user.email, {
                    profitDeterminations: [
                      {
                        typeOfProfit: typeOfProfit,
                        year: profitDetermination.year,
                      },
                    ],
                  });
                }}
                isReadOnly={!hasTaxAdvisoryPermissionScope}
              />
            ))}
          </div>
          {!isOnboarding && (
            <EditableCustomerAttribute
              label="Permanent extension status"
              initialValue={user.permanentExtensionStatus}
              editType={EditType.Select}
              formatFunction={formatPermanentExtensionStatus}
              options={[...Object.values(PermanentExtensionStatus)]}
              onSaveHandler={async (
                permanentExtensionStatus: PermanentExtensionStatus
              ) => {
                await editUser(user.email, { permanentExtensionStatus });
              }}
              isReadOnly={!hasTaxAdvisoryPermissionScope}
            />
          )}
          <EditableCustomerAttribute
            label="Trade tax"
            initialValue={user.kontaxUser?.tradeTax}
            editType={EditType.Select}
            options={Object.values(TradeTax)}
            onSaveHandler={async (tradeTax: TradeTax) => {
              await editUser(user.email, {
                kontaxUser: { tradeTax },
              });
            }}
            isReadOnly={!hasTaxAdvisoryPermissionScope}
          />
          {!isOnboarding &&
            ACCOUNTING_SOURCE_YEARS.map((year) => (
              <EditableCustomerAttribute
                label={`Buchhaltung ${year}`}
                key={year}
                initialValue={
                  user.kontaxUser?.accountingSources?.find(
                    (source) => source.year === year
                  )?.type
                }
                editType={EditType.Select}
                formatFunction={formatAccountingSource}
                options={Object.values(AccountingSourceType)}
                allowClear
                onSaveHandler={(accountingSource?: AccountingSourceType) => {
                  if (
                    accountingSource &&
                    accountingSource !== AccountingSourceType.NO_STATUS
                  ) {
                    return upsertAccountingSource(user.email, {
                      type: accountingSource,
                      year,
                    });
                  }
                  return removeAccountingSource(user.email, year);
                }}
                isReadOnly={!hasTaxAdvisoryPermissionScope}
              />
            ))}
          <EditableCustomerAttribute
            label="In DATEV angelegt"
            initialValue={formatBooleanToString(
              user.kontaxUser?.createdInDatev
            )}
            editType={EditType.Select}
            options={Object.values(YesNoEnum)}
            allowClear
            onSaveHandler={async (value: YesNoEnum) => {
              const createdInDatev =
                value === undefined ? null : value === YesNoEnum.YES;

              await editUser(user.email, {
                kontaxUser: { createdInDatev },
              });
            }}
            isReadOnly={!hasTaxAdvisoryPermissionScope}
          />
          {!isOnboarding && (
            <EditableCustomerAttribute
              label="Steuerfreie Umsätze"
              initialValue={
                user.vatExemptionWithItd || user.vatExemptionWithoutItd
              }
              editType={EditType.Select}
              formatFunction={formatVatExemption}
              options={VAT_EXEMPTION_OPTIONS}
              allowClear
              onSaveHandler={async (
                value: VatExemptionWithItd | VatExemptionWithoutItd
              ) => {
                const vatExemptionSettings: {
                  vatExemptionWithItd: VatExemptionWithItd | null;
                  vatExemptionWithoutItd: VatExemptionWithoutItd | null;
                } = {
                  vatExemptionWithItd: null,
                  vatExemptionWithoutItd: null,
                };
                if (value) {
                  if (isVatExemptionWithId(value)) {
                    vatExemptionSettings.vatExemptionWithItd = value;
                  } else {
                    vatExemptionSettings.vatExemptionWithoutItd = value;
                  }
                }

                await editUser(user.email, {
                  ...vatExemptionSettings,
                });
              }}
              isReadOnly={!hasTaxAdvisoryPermissionScope}
            />
          )}
          <EditableCustomerAttribute
            label="Mehrfach selbständig"
            initialValue={formatBooleanToString(user.hasMoreThanOneBusiness)}
            editType={EditType.Select}
            options={Object.values(YesNoEnum)}
            allowClear
            onSaveHandler={async (value: YesNoEnum) => {
              const hasMoreThanOneBusiness =
                value === undefined ? null : value === YesNoEnum.YES;

              await editUser(user.email, {
                hasMoreThanOneBusiness,
              });
            }}
            isReadOnly={!hasTaxAdvisoryPermissionScope}
          />
          {isOnboarding && (
            <>
              <EditableCustomerAttribute
                label="Steuererklärung Vorjahr"
                initialValue={user.kontaxUser?.taxDeclarationPreviousYear}
                editType={EditType.Select}
                options={[2020, 2021, 2022, 2023, 2024, 2025]}
                allowClear
                onSaveHandler={async (value: number | undefined) => {
                  await editUser(user.email, {
                    kontaxUser: {
                      taxDeclarationPreviousYear:
                        value === undefined ? null : value,
                    },
                  });
                }}
                isReadOnly={!hasTaxAdvisoryPermissionScope}
              />
            </>
          )}
          {isOnboarding && (
            <>
              <EditableCustomerAttribute
                label="Neue Betriebliche Steuernummer erhalten"
                initialValue={formatBooleanToString(
                  user.kontaxUser?.newBusinessTaxNumberReceived
                )}
                editType={EditType.Select}
                options={Object.values(YesNoEnum)}
                allowClear
                onSaveHandler={async (value: YesNoEnum) => {
                  await editUser(user.email, {
                    kontaxUser: {
                      newBusinessTaxNumberReceived:
                        value === undefined ? null : value === YesNoEnum.YES,
                    },
                  });
                }}
                isReadOnly={!hasTaxAdvisoryPermissionScope}
              />
            </>
          )}
          {isOnboarding && (
            <>
              <EditableCustomerAttribute
                label="Steuerliche Erfassung bei FA erledigt"
                initialValue={formatBooleanToString(
                  user.kontaxUser?.taxRegistrationWithFaDone
                )}
                editType={EditType.Select}
                options={Object.values(YesNoEnum)}
                allowClear
                onSaveHandler={async (value: YesNoEnum) => {
                  await editUser(user.email, {
                    kontaxUser: {
                      taxRegistrationWithFaDone:
                        value === undefined ? null : value === YesNoEnum.YES,
                    },
                  });
                }}
                isReadOnly={!hasTaxAdvisoryPermissionScope}
              />
            </>
          )}
          <div>
            {user.firstTaxDeclarationYear && (
              <FibuFinalCheck
                fibuFinalChecks={user.fibuFinalChecks}
                user={user}
                editUser={editUser}
              />
            )}
          </div>
        </CustomerAttributes>
      </CustomerInformationCard>
    </>
  );
};

export default UserTaxInformationBlock;
