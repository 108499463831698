import { gql, MutationHookOptions, useMutation } from "@apollo/client";

import { VatAnnualDeclaration } from "../../types";
import { VatAnnualDeclarationStatus } from "../../schema.generated";

export interface UpdateVatAnnualDeclarationInput {
  status: VatAnnualDeclarationStatus;
  lastYearIncomeAdjustment: number | null;
  currentYearIncomeAdjustment: number | null;
  taxOfficeBalance: number | null;
}

export interface UpdateVatAnnualDeclarationMutationVariables {
  email: string;
  year: number;
  payload: UpdateVatAnnualDeclarationInput;
}

export interface UpdateVatAnnualDeclarationMutationResult {
  updateVatAnnualDeclaration: VatAnnualDeclaration;
}

export const UPDATE_VAT_ANNUAL_DECLARATION_MUTATION = gql`
  mutation updateVatAnnualDeclaration(
    $email: String!
    $year: Int!
    $payload: UpdateVatAnnualDeclarationInput!
  ) {
    updateVatAnnualDeclaration(email: $email, year: $year, payload: $payload) {
      status
      statusUpdatedAt
      lastYearIncomeAdjustment
      currentYearIncomeAdjustment
      taxOfficeBalance
    }
  }
`;

export const useUpdateVatAnnualDeclarationMutation = (
  options?: MutationHookOptions<
    UpdateVatAnnualDeclarationMutationResult,
    UpdateVatAnnualDeclarationMutationVariables
  >
) =>
  useMutation<
    UpdateVatAnnualDeclarationMutationResult,
    UpdateVatAnnualDeclarationMutationVariables
  >(UPDATE_VAT_ANNUAL_DECLARATION_MUTATION, options);
