import { CheckboxValueType } from "antd/lib/checkbox/Group";
import React, { useEffect, useState } from "react";

import { KontaxPlan } from "../../../types/IUser.type";
import CheckboxGroup, { CheckboxGroupOption } from "../CheckboxGroup";
import FilterBoxCard from "../Card/FilterBoxCard";

type Props = {
  onChangeHandler?: any;
  value?: Array<KontaxPlan>;
};

const defaultPlansOptions: Array<CheckboxGroupOption> = [
  {
    label: KontaxPlan.KONTAX,
    value: KontaxPlan.KONTAX,
    isSelected: false,
  },
  {
    label: KontaxPlan.KONTAX_SB,
    value: KontaxPlan.KONTAX_SB,
    isSelected: false,
  },
  {
    label: KontaxPlan.ACCOUNTING,
    value: KontaxPlan.ACCOUNTING,
    isSelected: false,
  },
];

const updatePlanOptions = (
  plans: Array<CheckboxValueType> | undefined,
  setPlansOptions: (plansOptions: Array<CheckboxGroupOption>) => void
) => {
  let plansOptions = defaultPlansOptions;

  plansOptions = defaultPlansOptions.map((p) => ({
    ...p,
    isSelected: !plans || plans.length === 0 || plans.includes(p.value),
  }));
  setPlansOptions(plansOptions);
};

const PlanFilter = ({ onChangeHandler, value }: Props) => {
  const [plansOptions, setPlansOptions] = useState(defaultPlansOptions);

  useEffect(() => {
    updatePlanOptions(value, setPlansOptions);
  }, [value]);
  return (
    <FilterBoxCard data-test="planFilters" title="Plan">
      <CheckboxGroup
        options={plansOptions}
        onChange={(selectedPlansTypes: Array<CheckboxValueType>) => {
          if (!selectedPlansTypes.length) {
            updatePlanOptions(selectedPlansTypes, setPlansOptions);
          }
          const plans =
            selectedPlansTypes.length === 0 ? undefined : selectedPlansTypes;
          onChangeHandler({ plans });
        }}
      />
    </FilterBoxCard>
  );
};

export default PlanFilter;
