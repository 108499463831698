import { useState, useMemo, useEffect } from "react";
import { Select, Spin } from "antd";
import debounce from "lodash/debounce";

import api from "../../../api";

interface ValueType {
  label: string;
  value: number;
}

const EmailSearch = ({
  value,
  onChange,
}: {
  value?: number[];
  onChange?: (value: number[]) => void;
}) => {
  const [emailOptions, setEmailOptions] = useState<ValueType[]>([]);
  const [fetching, setFetching] = useState(false);
  const [values, setValues, isSyncing] = useSyncAccounts(value ?? []);

  const debounceFetcher = useMemo(() => {
    const loadOptions = async (email: string) => {
      setFetching(true);

      try {
        const { users } = await api.kontax.getUsers({
          emailLike: email,
        });
        const emailOptions = users.map((user) => ({
          label: user.email,
          value: user.accountId,
        }));

        if (!users.length) {
          setFetching(false);
          return;
        }

        setEmailOptions(emailOptions);
        setFetching(false);
      } catch (e) {
        setFetching(false);
      }
    };

    return debounce(loadOptions, 1000);
  }, []);

  return (
    <Select
      mode="multiple"
      autoClearSearchValue
      style={{ flex: 1 }}
      showArrow
      placeholder="Search or select email/s"
      options={emailOptions}
      labelInValue
      value={values}
      onChange={(users) => {
        setValues(users);
        onChange?.(users.map((user) => user.value));
      }}
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      disabled={isSyncing}
      loading={isSyncing}
    />
  );
};

function useSyncAccounts(accountIds: number[]) {
  const [values, setValues] = useState<ValueType[]>([]);
  const [isSyncing, setIsSyncing] = useState(false);

  const missingAccountIds = accountIds.filter(
    (accountId) => !values.some((emailValue) => emailValue.value === accountId)
  );

  useEffect(() => {
    if (missingAccountIds?.length && !isSyncing) {
      setIsSyncing(true);
      getEmailValues(missingAccountIds).then((missingEmailValues) => {
        setValues((currentEmailValues) => [
          ...currentEmailValues,
          ...missingEmailValues,
        ]);
        setIsSyncing(false);
      });
    }
  }, [missingAccountIds, isSyncing]);

  return [values, setValues, isSyncing] as const;
}

async function getEmailValues(accountIds: number[]): Promise<ValueType[]> {
  return Promise.all(
    accountIds.map(async (accountId) => {
      return {
        label: await getEmailByAccountId(accountId).catch(() => "<unknown>"),
        value: accountId,
      };
    })
  );
}

async function getEmailByAccountId(accountId: number): Promise<string> {
  const { users } = await api.kontax.getUsers({
    search: accountId.toString(),
  });
  const accountUser = users.find((user) => user.accountId === accountId);
  return accountUser?.email ?? "<unknown>";
}

export default EmailSearch;
