import type * as Types from "../../schema.generated";

import { gql } from "@apollo/client";
import { DeclarationDocumentFragmentDoc } from "../../fragments/declarationDocument.generated";
import { DeclarationApprovalFragmentDoc } from "../../fragments/declarationApproval.generated";
import { DeclarationDeclineFragmentDoc } from "../../fragments/declarationDecline.generated";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type GetTaxBoardUsersQueryVariables = Types.Exact<{
  year: Types.Scalars["Int"];
  filter?: Types.InputMaybe<Types.TaxBoardUsersFilterArgs>;
  offset: Types.Scalars["Int"];
  limit: Types.Scalars["Int"];
  orderBy?: Types.InputMaybe<Types.Scalars["String"]>;
  orderDirection?: Types.InputMaybe<Types.Scalars["String"]>;
}>;

export type GetTaxBoardUsersQuery = {
  __typename?: "Query";
  taxBoardUsers: {
    __typename?: "TaxBoardUserDataAndCount";
    count: number;
    data: Array<{
      __typename?: "TaxBoardUser";
      id: number;
      email?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      accounts: Array<{ __typename?: "TaxBoardUserAccount"; id: string }>;
      taxNumbers: Array<{
        __typename?: "TaxBoardTaxNumbers";
        taxNumber: string;
      }>;
      euerDeclarations: Array<{
        __typename?: "EuerDeclaration";
        id: string;
        status: Types.EuerDeclarationStatus;
        declarationDocuments: Array<{
          __typename?: "DeclarationDocument";
          id: string;
          documentType: Types.DeclarationDocumentType;
          createdAt: string;
          assets: Array<{
            __typename?: "Asset";
            id: string;
            name: string;
            filetype: string;
            thumbnail: string;
            fullsize: string;
          }>;
        }>;
        declarationApproval?: {
          __typename?: "DeclarationApproval";
          id: string;
          jointDeclaration?: boolean | null;
          delaySubmission?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        declarationDeclines: Array<{
          __typename?: "DeclarationDecline";
          reason: string;
          createdAt: string;
        }>;
      }>;
      vatAnnualDeclarations: Array<{
        __typename?: "VatAnnualDeclaration";
        id: string;
        status: Types.VatAnnualDeclarationStatus;
        declarationDocuments: Array<{
          __typename?: "DeclarationDocument";
          id: string;
          documentType: Types.DeclarationDocumentType;
          createdAt: string;
          assets: Array<{
            __typename?: "Asset";
            id: string;
            name: string;
            filetype: string;
            thumbnail: string;
            fullsize: string;
          }>;
        }>;
        declarationApproval?: {
          __typename?: "DeclarationApproval";
          id: string;
          jointDeclaration?: boolean | null;
          delaySubmission?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        declarationDeclines: Array<{
          __typename?: "DeclarationDecline";
          reason: string;
          createdAt: string;
        }>;
      }>;
      tradeTaxDeclarations: Array<{
        __typename?: "TradeTaxDeclaration";
        id: string;
        status: Types.TradeTaxDeclarationStatus;
        declarationDocuments: Array<{
          __typename?: "DeclarationDocument";
          id: string;
          documentType: Types.DeclarationDocumentType;
          createdAt: string;
          assets: Array<{
            __typename?: "Asset";
            id: string;
            name: string;
            filetype: string;
            thumbnail: string;
            fullsize: string;
          }>;
        }>;
        declarationApproval?: {
          __typename?: "DeclarationApproval";
          id: string;
          jointDeclaration?: boolean | null;
          delaySubmission?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        declarationDeclines: Array<{
          __typename?: "DeclarationDecline";
          reason: string;
          createdAt: string;
        }>;
      }>;
      incomeTaxDeclarations: Array<{
        __typename?: "IncomeTaxDeclaration";
        id: string;
        status: Types.IncomeTaxDeclarationStatus;
        declarationDocuments: Array<{
          __typename?: "DeclarationDocument";
          id: string;
          documentType: Types.DeclarationDocumentType;
          createdAt: string;
          assets: Array<{
            __typename?: "Asset";
            id: string;
            name: string;
            filetype: string;
            thumbnail: string;
            fullsize: string;
          }>;
        }>;
        declarationApproval?: {
          __typename?: "DeclarationApproval";
          id: string;
          jointDeclaration?: boolean | null;
          delaySubmission?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        declarationDeclines: Array<{
          __typename?: "DeclarationDecline";
          reason: string;
          createdAt: string;
        }>;
      }>;
      kontaxUser?: {
        __typename?: "TaxBoardKontaxUser";
        fibuFinalChecks: Array<{
          __typename?: "FibuFinalCheck";
          status: Types.FibuFinalCheckStatus;
        }>;
        taxCases: Array<{
          __typename?: "TaxBoardTaxCase";
          id: string;
          status: Types.TaxCaseStatus;
          deadline: string;
          taxOfficeDeadline?: string | null;
          assignee?: string | null;
          userFinalizedAt?: string | null;
        }>;
      } | null;
    }>;
  };
};

export const GetTaxBoardUsersDocument = gql`
  query getTaxBoardUsers(
    $year: Int!
    $filter: TaxBoardUsersFilterArgs
    $offset: Int!
    $limit: Int!
    $orderBy: String
    $orderDirection: String
  ) {
    taxBoardUsers(
      year: $year
      filter: $filter
      offset: $offset
      limit: $limit
      orderBy: $orderBy
      orderDirection: $orderDirection
    ) {
      count
      data {
        id
        email
        firstName
        lastName
        accounts {
          id
        }
        taxNumbers {
          taxNumber
        }
        euerDeclarations {
          id
          status
          declarationDocuments {
            ...DeclarationDocument
          }
          declarationApproval {
            ...DeclarationApproval
          }
          declarationDeclines {
            ...DeclarationDecline
          }
        }
        vatAnnualDeclarations {
          id
          status
          declarationDocuments {
            ...DeclarationDocument
          }
          declarationApproval {
            ...DeclarationApproval
          }
          declarationDeclines {
            ...DeclarationDecline
          }
        }
        tradeTaxDeclarations {
          id
          status
          declarationDocuments {
            ...DeclarationDocument
          }
          declarationApproval {
            ...DeclarationApproval
          }
          declarationDeclines {
            ...DeclarationDecline
          }
        }
        incomeTaxDeclarations {
          id
          status
          declarationDocuments {
            ...DeclarationDocument
          }
          declarationApproval {
            ...DeclarationApproval
          }
          declarationDeclines {
            ...DeclarationDecline
          }
        }
        kontaxUser {
          fibuFinalChecks {
            status
          }
          taxCases {
            id
            status
            deadline
            taxOfficeDeadline
            assignee
            userFinalizedAt
          }
        }
      }
    }
  }
  ${DeclarationDocumentFragmentDoc}
  ${DeclarationApprovalFragmentDoc}
  ${DeclarationDeclineFragmentDoc}
`;

/**
 * __useGetTaxBoardUsersQuery__
 *
 * To run a query within a React component, call `useGetTaxBoardUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaxBoardUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaxBoardUsersQuery({
 *   variables: {
 *      year: // value for 'year'
 *      filter: // value for 'filter'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      orderBy: // value for 'orderBy'
 *      orderDirection: // value for 'orderDirection'
 *   },
 * });
 */
export function useGetTaxBoardUsersQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTaxBoardUsersQuery,
    GetTaxBoardUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTaxBoardUsersQuery, GetTaxBoardUsersQueryVariables>(
    GetTaxBoardUsersDocument,
    options
  );
}
export function useGetTaxBoardUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTaxBoardUsersQuery,
    GetTaxBoardUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetTaxBoardUsersQuery,
    GetTaxBoardUsersQueryVariables
  >(GetTaxBoardUsersDocument, options);
}
export type GetTaxBoardUsersQueryHookResult = ReturnType<
  typeof useGetTaxBoardUsersQuery
>;
export type GetTaxBoardUsersLazyQueryHookResult = ReturnType<
  typeof useGetTaxBoardUsersLazyQuery
>;
export type GetTaxBoardUsersQueryResult = Apollo.QueryResult<
  GetTaxBoardUsersQuery,
  GetTaxBoardUsersQueryVariables
>;
