import { gql, MutationHookOptions, useMutation } from "@apollo/client";

import { Direction, SKRMode, BookingMode, BookkeepingTool } from "../../types";

export interface DatevRawData {
  amount: string;
  direction: Direction;
  account: string;
  offsetAccount: string;
  buKey: string;
  date: string;
  meta1: string;
  description: string;
  receiptName?: string;
}

export interface ImportDatevDataInput {
  skrMode: SKRMode;
  bookingMode: BookingMode;
  bookkeepingTool: BookkeepingTool;
  year: string;
  email: string;
  rawDataList: DatevRawData[];
}

export interface ImportDatevDataMutationVariables {
  payload: ImportDatevDataInput;
}

export interface ImportDatevDataMutationResult {
  importDatevData: {
    success: boolean;
  };
}

export const IMPORT_DATEV_DATA_MUTATION = gql`
  mutation importDatevData($payload: ImportDatevDataInput!) {
    importDatevData(payload: $payload) {
      success
    }
  }
`;

export const useImportDatevDataMutation = (
  options?: MutationHookOptions<
    ImportDatevDataMutationResult,
    ImportDatevDataMutationVariables
  >
) =>
  useMutation<ImportDatevDataMutationResult, ImportDatevDataMutationVariables>(
    IMPORT_DATEV_DATA_MUTATION,
    options
  );
