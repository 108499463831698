import React, { useCallback, useMemo } from "react";

import { SECTION_ROW_CONFIGS, SECTION_TITLES } from "../constants";
import { VatAnnualDeclarationSection } from "../../../../../../api/graphql";
import { VatAnnualDeclarationPeriodType } from "../../../types";
import VatAnnualDeclarationBaseTable, {
  VatAnnualDeclarationSectionRow,
} from "../VatAnnualDeclarationBaseTable";
import { VatAnnualDeclarationSmallBusinessValues } from "../../../../../../api/graphql/schema.generated";

export interface SmallBusinessFieldsTableProps {
  email: string;
  taxYear: number;
  period: VatAnnualDeclarationPeriodType;
  taxOfficeBalance: number | null;
  onTaxOfficeBalanceChange: (value: number | null) => void;
  smallBusinessValues: VatAnnualDeclarationSmallBusinessValues;
  grandTotal: number;
}

const SmallBusinessFieldsTable = ({
  email,
  taxYear,
  period,
  taxOfficeBalance,
  onTaxOfficeBalanceChange,
  smallBusinessValues,
  grandTotal,
}: SmallBusinessFieldsTableProps) => {
  const sections: VatAnnualDeclarationSectionRow[] = useMemo(() => {
    return [
      {
        ...SECTION_ROW_CONFIGS.SALES_TAX,
        ...(smallBusinessValues.salesTax as VatAnnualDeclarationSection),
      },
      {
        ...SECTION_ROW_CONFIGS.TAX_OFFICE_BALANCE,
        fields: [],
        total: taxOfficeBalance,
      },
      {
        ...SECTION_ROW_CONFIGS.ADDITIONAL_INFORMATION,
        ...(smallBusinessValues.additionalInformation as VatAnnualDeclarationSection),
      },
    ];
  }, [
    smallBusinessValues.additionalInformation,
    smallBusinessValues.salesTax,
    taxOfficeBalance,
  ]);

  const handleSectionChange = useCallback(
    (section: VatAnnualDeclarationSectionRow) => {
      if (section.title === SECTION_TITLES.TAX_OFFICE_BALANCE) {
        onTaxOfficeBalanceChange(section.total);
      }
    },
    [onTaxOfficeBalanceChange]
  );

  return (
    <VatAnnualDeclarationBaseTable
      email={email}
      taxYear={taxYear}
      period={period}
      sections={sections}
      onChangeSection={handleSectionChange}
      grandTotal={grandTotal}
      yearSuffix={taxYear}
    />
  );
};

export default SmallBusinessFieldsTable;
