import { useCallback, useMemo } from "react";
import {
  useQueryParams,
  StringParam,
  NumberParam,
  DelimitedArrayParam,
} from "use-query-params";
import mapValues from "lodash/mapValues";
import isArray from "lodash/isArray";
import omitBy from "lodash/omitBy";
import isUndefined from "lodash/isUndefined";

import { KontaxTransactionFilterOptions } from "../../types";

const useTransactionFilterParams = () => {
  const [queryParams, setQueryParams] = useQueryParams({
    email: StringParam,
    direction: NumberParam,
    source: StringParam,
    dateFrom: StringParam,
    dateTo: StringParam,
    vatRates: DelimitedArrayParam,
    categories: DelimitedArrayParam,
    vatCategoryCodes: DelimitedArrayParam,
  });

  const params = useMemo(() => omitBy(queryParams, isUndefined), [queryParams]);

  const setParams = useCallback(
    (changes: Record<string, any>) => {
      const newParams = mapValues(changes, (value) => {
        if (!value || (isArray(value) && value.length === 0)) {
          return undefined; // replace empty values with `undefined` to remove param from url
        }
        return value;
      });
      setQueryParams(newParams, "replaceIn"); // replace only passed parameters, leaving the rest as is
    },
    [setQueryParams]
  );

  return [params, setParams] as [
    Partial<KontaxTransactionFilterOptions>,
    (changes: Partial<KontaxTransactionFilterOptions>) => void
  ];
};

export default useTransactionFilterParams;
