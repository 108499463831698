export const downloadPDF = async (
  pdf: Blob,
  fileName: string
): Promise<void> => {
  const element = document.createElement("a");

  const fileURL = URL.createObjectURL(
    new Blob([pdf], { type: "application/pdf" })
  );
  element.href = fileURL;

  element.setAttribute("download", fileName);
  element.style.display = "none";
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};
