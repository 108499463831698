import React, { useState, useCallback } from "react";
import { Select } from "antd";

import api from "../../../../api";
import {
  NextVatDeclarationStatus,
  NextVatDeclarationStatusLabel,
} from "../../../../types";
import { SelectWrapper } from "../styledComponents";
import { getVariantByDeclarationStatus } from "../../../../utils";

const { Option } = Select;

interface Props {
  email: string;
  status: NextVatDeclarationStatus;
  setUpdatedDetails: Function;
}

export const NextDeclarationStatus = ({
  email,
  status,
  setUpdatedDetails,
}: Props) => {
  const [updatedNextDeclarationStatus, setUpdatedNextDeclarationStatus] =
    useState(status);
  const updateNextDeclarationStatus = useCallback(
    async (nextDeclarationStatus: NextVatDeclarationStatus) => {
      setUpdatedDetails(nextDeclarationStatus);

      try {
        await api.kontax.updateNextDeclarationData(
          {
            email,
            nextDeclarationStatus,
          },
          { hideErrorCode: true }
        );
        setUpdatedNextDeclarationStatus(nextDeclarationStatus);
      } catch (error) {
        setUpdatedNextDeclarationStatus(updatedNextDeclarationStatus);
        setUpdatedDetails(updatedNextDeclarationStatus);
      }
    },
    [
      email,
      setUpdatedDetails,
      setUpdatedNextDeclarationStatus,
      updatedNextDeclarationStatus,
    ]
  );

  return (
    <SelectWrapper>
      <Select
        style={{
          backgroundColor: getVariantByDeclarationStatus(status),
        }}
        value={status}
        id="next-declaration-status"
        onChange={(value) => {
          updateNextDeclarationStatus(value as NextVatDeclarationStatus);
        }}
      >
        {Object.entries(NextVatDeclarationStatusLabel).map(([key, value]) => {
          return (
            <Option key={key} value={key}>
              {value}
            </Option>
          );
        })}
      </Select>
    </SelectWrapper>
  );
};
