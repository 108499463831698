import React, { ReactNode } from "react";
import { Space } from "antd";

import { AttributeData, AttributeTitle } from "./styledComponents";

const DrawerUserAttribute = ({
  label,
  children,
}: {
  label?: string;
  children: ReactNode;
}) => {
  return (
    <Space direction="vertical" size={4} style={{ width: "100%" }}>
      {label && <AttributeTitle>{label}</AttributeTitle>}
      <AttributeData>{children || ""}</AttributeData>
    </Space>
  );
};

export default DrawerUserAttribute;
