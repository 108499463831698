import { toPairs } from "lodash";

import {
  Asset,
  ILocalAsset,
  MonthlyVatDeclarationPeriod,
  QuarterlyVatDeclarationPeriod,
} from "../../../types";

export enum EuerDeclarationSubformStatus {
  OPEN = "OPEN",
  NOT_RELEVANT = "NOT_RELEVANT",
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETED = "COMPLETED",
  VERIFIED = "VERIFIED",
}

export enum CarType {
  ICE = "ICE",
  HYBRID = "HYBRID",
  EV = "EV",
}

export enum EuerDeclarationSubformCalculationMethod {
  BUSINESS_ASSETS_DEFAULT = "BUSINESS_ASSETS_DEFAULT",

  CAR_USAGE_1_PCT_METHOD = "CAR_USAGE_1_PCT_METHOD",
  CAR_USAGE_DRIVERS_LOG = "CAR_USAGE_DRIVERS_LOG",

  OFFICE_USAGE_FULL_COST = "OFFICE_USAGE_FULL_COST",
  OFFICE_USAGE_UP_TO_1250_EUR = "OFFICE_USAGE_UP_TO_1250_EUR",
  OFFICE_USAGE_FLAT_RATE_5_EUR = "OFFICE_USAGE_FLAT_RATE_5_EUR",

  TRAVEL_EXPENSES_DEFAULT = "TRAVEL_EXPENSES_DEFAULT",
}

export enum YearlyVatDeclarationPeriod {
  YEAR = "YEAR",
}

export const YEARLY_VAT_DECLARATION_PERIOD_OPTION = {
  value: YearlyVatDeclarationPeriod.YEAR,
  label: "Ganzes Jahr",
};

export const QUARTERLY_VAT_DECLARATION_PERIOD_LABELS: {
  [period: string]: string;
} = {
  [QuarterlyVatDeclarationPeriod.Q1]: "Quartal 1",
  [QuarterlyVatDeclarationPeriod.Q2]: "Quartal 2",
  [QuarterlyVatDeclarationPeriod.Q3]: "Quartal 3",
  [QuarterlyVatDeclarationPeriod.Q4]: "Quartal 4",
};

export const QUARTERLY_VAT_DECLARATION_PERIOD_OPTIONS = toPairs(
  QUARTERLY_VAT_DECLARATION_PERIOD_LABELS
).map(([period, label]) => ({
  value: period as QuarterlyVatDeclarationPeriod,
  label,
}));

export const MONTHLY_VAT_DECLARATION_PERIOD_LABELS: {
  [period: string]: string;
} = {
  [MonthlyVatDeclarationPeriod.JANUARY]: "Januar",
  [MonthlyVatDeclarationPeriod.FEBRUARY]: "Februar",
  [MonthlyVatDeclarationPeriod.MARCH]: "März",
  [MonthlyVatDeclarationPeriod.APRIL]: "April",
  [MonthlyVatDeclarationPeriod.MAY]: "Mai",
  [MonthlyVatDeclarationPeriod.JUNE]: "Juni",
  [MonthlyVatDeclarationPeriod.JULY]: "Juli",
  [MonthlyVatDeclarationPeriod.AUGUST]: "August",
  [MonthlyVatDeclarationPeriod.SEPTEMBER]: "September",
  [MonthlyVatDeclarationPeriod.OCTOBER]: "Oktober",
  [MonthlyVatDeclarationPeriod.NOVEMBER]: "November",
  [MonthlyVatDeclarationPeriod.DECEMBER]: "Dezember",
};

export const MONTHLY_VAT_DECLARATION_PERIOD_OPTIONS = toPairs(
  MONTHLY_VAT_DECLARATION_PERIOD_LABELS
).map(([period, label]) => ({
  value: period as MonthlyVatDeclarationPeriod,
  label,
}));

export const VatAnnualDeclarationPeriod = {
  ...YearlyVatDeclarationPeriod,
  ...QuarterlyVatDeclarationPeriod,
  ...MonthlyVatDeclarationPeriod,
};

export type VatAnnualDeclarationPeriodType =
  | YearlyVatDeclarationPeriod
  | QuarterlyVatDeclarationPeriod
  | MonthlyVatDeclarationPeriod;

export interface IVatAnnualDeclarationPeriodSelect {
  value: VatAnnualDeclarationPeriodType;
  onChange: (value: VatAnnualDeclarationPeriodType) => void;
  availablePeriods?: string[];
  taxYear: number;
}

export interface EuerDeclarationFieldLinkParams {
  type: EuerDeclarationFieldType;
  transactionFilters: {
    categoryCodes?: string[];
    vatCategoryCodes?: string[];
  };
}

export interface EuerDeclarationFieldLink {
  [key: string]: EuerDeclarationFieldLinkParams;
}

export enum EuerDeclarationFieldType {
  Verification = "VERIFICATION_VIEW",
  BusinessAssets = "BUSINESS_ASSETS",
  CarUsageSubform = "CAR_USAGE_SUBFORM",
  TravelExpensesSubform = "TRAVEL_EXPENSES_SUBFORM",
  OfficeUsageSubform = "OFFICE_USAGE_SUBFORM",
  None = "NONE",
}

export type SaveAssetResult = PromiseSettledResult<Asset> & {
  localAsset: ILocalAsset;
};
