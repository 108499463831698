import type * as Types from "../../schema.generated";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type CreateRetroactiveKontaxFeeMutationVariables = Types.Exact<{
  email: Types.Scalars["String"];
  payload: Types.CreateRetroactiveKontaxFeeInput;
}>;

export type CreateRetroactiveKontaxFeeMutation = {
  __typename?: "Mutation";
  createRetroactiveKontaxFee: {
    __typename?: "RetroactiveKontaxFee";
    id: string;
    description: string;
    quantity: number;
    unitAmount: number;
    startDate: string;
    endDate: string;
    dueDate: string;
    invoiceDate?: string | null;
    status?: Types.RetroactiveKontaxFeeStatus | null;
  };
};

export const CreateRetroactiveKontaxFeeDocument = gql`
  mutation createRetroactiveKontaxFee(
    $email: String!
    $payload: CreateRetroactiveKontaxFeeInput!
  ) {
    createRetroactiveKontaxFee(email: $email, payload: $payload) {
      id
      description
      quantity
      unitAmount
      startDate
      endDate
      dueDate
      invoiceDate
      status
    }
  }
`;
export type CreateRetroactiveKontaxFeeMutationFn = Apollo.MutationFunction<
  CreateRetroactiveKontaxFeeMutation,
  CreateRetroactiveKontaxFeeMutationVariables
>;

/**
 * __useCreateRetroactiveKontaxFeeMutation__
 *
 * To run a mutation, you first call `useCreateRetroactiveKontaxFeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRetroactiveKontaxFeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRetroactiveKontaxFeeMutation, { data, loading, error }] = useCreateRetroactiveKontaxFeeMutation({
 *   variables: {
 *      email: // value for 'email'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useCreateRetroactiveKontaxFeeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateRetroactiveKontaxFeeMutation,
    CreateRetroactiveKontaxFeeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateRetroactiveKontaxFeeMutation,
    CreateRetroactiveKontaxFeeMutationVariables
  >(CreateRetroactiveKontaxFeeDocument, options);
}
export type CreateRetroactiveKontaxFeeMutationHookResult = ReturnType<
  typeof useCreateRetroactiveKontaxFeeMutation
>;
export type CreateRetroactiveKontaxFeeMutationResult =
  Apollo.MutationResult<CreateRetroactiveKontaxFeeMutation>;
export type CreateRetroactiveKontaxFeeMutationOptions =
  Apollo.BaseMutationOptions<
    CreateRetroactiveKontaxFeeMutation,
    CreateRetroactiveKontaxFeeMutationVariables
  >;
