import React, { useCallback, useState } from "react";
import { Button } from "antd";

import {
  KontaxUserStatus,
  KontaxUserStatusAdditionalParams,
} from "../../../../../types";

export function ChangeKontaxStatusButton({
  kontaxStatus,
  email,
  updateUserStatus,
}: {
  kontaxStatus: KontaxUserStatus | undefined;
  email: string;
  updateUserStatus: (
    email: string,
    status: KontaxUserStatus | null,
    additionalParams?: KontaxUserStatusAdditionalParams
  ) => Promise<void>;
}) {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const updateStatus = useCallback(
    async (status: KontaxUserStatus | null) => {
      try {
        setIsLoading(true);
        await updateUserStatus(email, status);
      } catch (error) {
        throw error;
      } finally {
        setIsLoading(false);
      }
    },
    [email, updateUserStatus]
  );

  switch (kontaxStatus) {
    case KontaxUserStatus.ACTIVATED: {
      return (
        <Button
          type="dashed"
          size="large"
          onClick={() => updateStatus(KontaxUserStatus.DEACTIVATED)}
          disabled={isLoading}
          data-test="deactivateState"
        >
          Deactivate
        </Button>
      );
    }
    case KontaxUserStatus.DECLINED:
    case KontaxUserStatus.DECLINED_REDIRECTED: {
      return (
        <Button
          type="dashed"
          size="large"
          onClick={() => updateStatus(null)}
          disabled={isLoading}
          data-test="resetState"
        >
          Reset status
        </Button>
      );
    }
    case KontaxUserStatus.PENDING: {
      return (
        <>
          <Button
            type="primary"
            size="large"
            danger
            onClick={() => updateStatus(KontaxUserStatus.DECLINED)}
            disabled={isLoading}
            data-test="declineState"
          >
            Decline
          </Button>
          <Button
            type="primary"
            size="large"
            onClick={() => updateStatus(KontaxUserStatus.ACTIVATED)}
            disabled={isLoading}
            data-test="activateState"
          >
            Activate
          </Button>
        </>
      );
    }
    case KontaxUserStatus.LEAD: {
      return (
        <>
          <Button type="primary" size="large" danger data-test="declineState">
            Decline
          </Button>
          <Button
            type="primary"
            size="large"
            onClick={() => updateStatus(KontaxUserStatus.ACTIVATED)}
            disabled={isLoading}
            data-test="activateState"
          >
            Activate
          </Button>
        </>
      );
    }
    case KontaxUserStatus.DEACTIVATED: {
      return (
        <Button
          type="primary"
          size="large"
          onClick={() => updateStatus(KontaxUserStatus.ACTIVATED)}
          disabled={isLoading}
          data-test="activateState"
        >
          Activate
        </Button>
      );
    }
    default: {
      return (
        <Button
          type="primary"
          size="large"
          onClick={() => updateStatus(KontaxUserStatus.ACTIVATED)}
          disabled={isLoading}
          data-test="activateState"
        >
          Activate
        </Button>
      );
    }
  }
}
