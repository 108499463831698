import styled from "styled-components";
import { List, Row } from "antd";

import colors from "../../../../../../../themes/colors";

const fontSize = "14px";

export const ActionText = styled.div`
  font-size: 13px;
  color: ${colors.purple};
`;

export const ListBox = styled.div`
  margin-top: 20px;
  padding: 0 25px;
  border: 1px solid ${colors.veryLightGrey};
  box-sizing: border-box;
  border-radius: 4px;
`;

export const FormTitle = styled.div`
  font-weight: normal;
  font-size: ${fontSize};
`;

export const StatusUpdateText = styled.div`
  font-size: ${fontSize};
  color: ${colors.darkGrey};
`;

export const AntRow = styled(Row)`
  margin-top: 40px;
`;

export const AntListItem = styled(List.Item)<{
  isSubForm?: boolean;
}>`
  ${({ isSubForm }) => (isSubForm ? `margin-left: 40px;` : "")}
  cursor: pointer;
`;

export const AntListItemMeta = styled(List.Item.Meta)<{
  isSubForm?: boolean;
}>`
  max-width: ${({ isSubForm }) => (isSubForm ? "300" : "340")}px;
`;

export const TagContainer = styled.div`
  margin-left: 40px;
  flex: 1 0;
`;
