import React from "react";
import { Col } from "antd";

import { ListRow } from "./styledComponents";
import { formatAmountCurrency } from "../../../../../../utils";
import DeclarationStatusChangeModal from "../../../../TaxDeclaration/components/DeclarationStatusChangeModal";
import { EuerDeclarationSubformStatus } from "../../../../../../api/graphql/schema.generated";
import { EuerDeclarationFields } from "../../../../../../api/graphql";
import { IUser } from "../../../../../../types";
import { SUBFORM_STATUS_MAPPINGS } from "../../../../TaxDeclaration/pages/EuerDeclaration/constants";

const BUSINESS_ASSETS_EUER_FIELDS = [
  { field: "136", label: "AfA unbewegliche Wirtschaftsgüter" },
  { field: "131", label: "AfA immaterielle Wirtschaftsgüter" },
  { field: "130", label: "AfA bewegliche Wirtschaftsgüter" },
  { field: "135", label: "Restbuchwert der ausgeschiedenen Anlagegueter" },
  { field: "102", label: "Veräußerung oder Entnahme des Anlagevermögen" },
  { field: "140", label: "Vereinnahmte USt 19 %" },
];

const BUSINESS_ASSETS_STATUSES = [
  EuerDeclarationSubformStatus.NOT_RELEVANT,
  EuerDeclarationSubformStatus.OPEN,
  EuerDeclarationSubformStatus.COMPLETED,
  EuerDeclarationSubformStatus.VERIFIED,
];

export interface BusinessAssetsStatusChangeModalProps {
  year: number;
  user: IUser;
  fields: EuerDeclarationFields;
  status: EuerDeclarationSubformStatus;
  onSave: (status: EuerDeclarationSubformStatus) => Promise<void>;
  onClose: () => void;
}

const BusinessAssetsStatusChangeModal = ({
  year,
  user,
  fields,
  status,
  onSave,
  onClose,
}: BusinessAssetsStatusChangeModalProps) => {
  return (
    <DeclarationStatusChangeModal
      title="Anlagevermögen"
      year={year}
      user={user}
      status={status}
      statusOptions={BUSINESS_ASSETS_STATUSES}
      statusMapping={SUBFORM_STATUS_MAPPINGS}
      onSave={onSave}
      onClose={onClose}
    >
      {BUSINESS_ASSETS_EUER_FIELDS.map(({ field, label }) => (
        <ListRow key={field}>
          <Col flex="1">{`[${field}] ${label}`}</Col>
          <Col>{formatAmountCurrency(fields[field] || 0)}</Col>
        </ListRow>
      ))}
    </DeclarationStatusChangeModal>
  );
};

export default BusinessAssetsStatusChangeModal;
