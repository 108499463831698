import { gql, QueryHookOptions, useQuery } from "@apollo/client";

import { BusinessAddress } from "../..";

export interface BusinessAddressQueryVariables {
  email: string;
}

export interface BusinessAddressQueryResult {
  businessAddresses: BusinessAddress[];
}

export const BUSINESS_ADDRESS_QUERY = gql`
  query businessAddresses($email: String!) {
    businessAddresses(email: $email) {
      id
      street
      postCode
      city
      movingDate
      deletedAt
      changeLogs {
        changes
        changedAt
        changedBy
      }
    }
  }
`;

export const useBusinessAddressQuery = (
  options?: QueryHookOptions<
    BusinessAddressQueryResult,
    BusinessAddressQueryVariables
  >
) =>
  useQuery<BusinessAddressQueryResult, BusinessAddressQueryVariables>(
    BUSINESS_ADDRESS_QUERY,
    options
  );
