import denormalizeTaxNumber from "denormalize-steuernummer";
import React, { useCallback } from "react";
import moment from "moment";
import { Input, Form, Select, DatePicker } from "antd";

import { TaxNumber } from "../../../../../../api/graphql";
import { StateAbbreviation } from "./types";
import {
  STATE_OPTION,
  TAX_NUMBER_OPTIONS,
  TABLE_COLUMN_TITLES,
  IS_MAIN_TAX_NUMBER_OPTIONS,
} from "./utils";

export interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: string;
  record: TaxNumber;
  index: number;
  required: boolean;
  children: React.ReactNode;
}

const EditableRow: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  record,
  index,
  required,
  children,
  ...restProps
}) => {
  let inputNode;

  const getInitialValue = useCallback((title: string, record: TaxNumber) => {
    const { taxNumber, taxState } =
      denormalizeTaxNumber(record.taxNumber)! || {};
    switch (title) {
      case TABLE_COLUMN_TITLES.TYPE:
        return record.type;
      case TABLE_COLUMN_TITLES.STATE:
        return taxState || StateAbbreviation.DE_BE;
      case TABLE_COLUMN_TITLES.TAX_NUMBER:
        return taxNumber;
      case TABLE_COLUMN_TITLES.VALID_FROM:
        return record.validFrom && moment(record.validFrom);
      case TABLE_COLUMN_TITLES.DESCRIPTION:
        return record.description;
      case TABLE_COLUMN_TITLES.MAIN_TAX_NUMBER:
        return record.isMainBusinessTaxNumber;

      default:
        return null;
    }
  }, []);

  switch (title) {
    case TABLE_COLUMN_TITLES.TYPE:
      inputNode = (
        <Select style={{ width: 100 }} options={TAX_NUMBER_OPTIONS} />
      );
      break;
    case TABLE_COLUMN_TITLES.STATE:
      inputNode = <Select style={{ width: 100 }} options={STATE_OPTION} />;
      break;
    case TABLE_COLUMN_TITLES.VALID_FROM:
      inputNode = <DatePicker style={{ width: "115px" }} />;
      break;
    case TABLE_COLUMN_TITLES.TAX_NUMBER:
      inputNode = <Input style={{ width: "135px" }} />;
      break;
    case TABLE_COLUMN_TITLES.MAIN_TAX_NUMBER:
      inputNode = (
        <Select style={{ width: 120 }} options={IS_MAIN_TAX_NUMBER_OPTIONS} />
      );
      break;
    default:
      inputNode = <Input style={{ width: "90px" }} />;
  }

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          initialValue={getInitialValue(title, record)}
          rules={[{ required, message: `Please Input ${title}!` }]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

export default EditableRow;
