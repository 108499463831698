import type * as Types from "../../schema.generated";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type CreateHomeOfficeExpenseAssetMutationVariables = Types.Exact<{
  homeOfficeExpenseId: Types.Scalars["ID"];
  name: Types.Scalars["String"];
  filetype: Types.Scalars["String"];
}>;

export type CreateHomeOfficeExpenseAssetMutation = {
  __typename?: "Mutation";
  createHomeOfficeExpenseAsset: {
    __typename?: "CreateAssetResponse";
    assetId: string;
    url: string;
    formData: Array<{
      __typename?: "FormDataPair";
      key: string;
      value: string;
    }>;
  };
};

export const CreateHomeOfficeExpenseAssetDocument = gql`
  mutation createHomeOfficeExpenseAsset(
    $homeOfficeExpenseId: ID!
    $name: String!
    $filetype: String!
  ) {
    createHomeOfficeExpenseAsset(
      homeOfficeExpenseId: $homeOfficeExpenseId
      name: $name
      filetype: $filetype
    ) {
      assetId
      url
      formData {
        key
        value
      }
    }
  }
`;
export type CreateHomeOfficeExpenseAssetMutationFn = Apollo.MutationFunction<
  CreateHomeOfficeExpenseAssetMutation,
  CreateHomeOfficeExpenseAssetMutationVariables
>;

/**
 * __useCreateHomeOfficeExpenseAssetMutation__
 *
 * To run a mutation, you first call `useCreateHomeOfficeExpenseAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateHomeOfficeExpenseAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createHomeOfficeExpenseAssetMutation, { data, loading, error }] = useCreateHomeOfficeExpenseAssetMutation({
 *   variables: {
 *      homeOfficeExpenseId: // value for 'homeOfficeExpenseId'
 *      name: // value for 'name'
 *      filetype: // value for 'filetype'
 *   },
 * });
 */
export function useCreateHomeOfficeExpenseAssetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateHomeOfficeExpenseAssetMutation,
    CreateHomeOfficeExpenseAssetMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateHomeOfficeExpenseAssetMutation,
    CreateHomeOfficeExpenseAssetMutationVariables
  >(CreateHomeOfficeExpenseAssetDocument, options);
}
export type CreateHomeOfficeExpenseAssetMutationHookResult = ReturnType<
  typeof useCreateHomeOfficeExpenseAssetMutation
>;
export type CreateHomeOfficeExpenseAssetMutationResult =
  Apollo.MutationResult<CreateHomeOfficeExpenseAssetMutation>;
export type CreateHomeOfficeExpenseAssetMutationOptions =
  Apollo.BaseMutationOptions<
    CreateHomeOfficeExpenseAssetMutation,
    CreateHomeOfficeExpenseAssetMutationVariables
  >;
