import type * as Types from "../../schema.generated";

import { gql } from "@apollo/client";
import { TaxDeclarationSavedDraftDataFragmentDoc } from "../../fragments/taxDeclarationSavedDraftData.generated";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type TradeTaxDeclarationSavedDraftInfoQueryVariables = Types.Exact<{
  email: Types.Scalars["String"];
  year: Types.Scalars["Int"];
}>;

export type TradeTaxDeclarationSavedDraftInfoQuery = {
  __typename?: "Query";
  tradeTaxDeclaration: {
    __typename?: "TradeTaxDeclaration";
    savedDraftInfo?: {
      __typename?: "TaxDeclarationSavedDraftInfo";
      createdAt?: string | null;
      createdBy?: string | null;
      updatedAt?: string | null;
      pdf?: string | null;
      calculationSheet?: string | null;
      externalAssets?: Array<{
        __typename?: "TaxDeclarationExternalAsset";
        url: string;
        filetype: string;
        createdAt: string;
      }> | null;
    } | null;
  };
};

export const TradeTaxDeclarationSavedDraftInfoDocument = gql`
  query tradeTaxDeclarationSavedDraftInfo($email: String!, $year: Int!) {
    tradeTaxDeclaration(email: $email, year: $year) {
      savedDraftInfo {
        ...TaxDeclarationSavedDraftData
      }
    }
  }
  ${TaxDeclarationSavedDraftDataFragmentDoc}
`;

/**
 * __useTradeTaxDeclarationSavedDraftInfoQuery__
 *
 * To run a query within a React component, call `useTradeTaxDeclarationSavedDraftInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useTradeTaxDeclarationSavedDraftInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTradeTaxDeclarationSavedDraftInfoQuery({
 *   variables: {
 *      email: // value for 'email'
 *      year: // value for 'year'
 *   },
 * });
 */
export function useTradeTaxDeclarationSavedDraftInfoQuery(
  baseOptions: Apollo.QueryHookOptions<
    TradeTaxDeclarationSavedDraftInfoQuery,
    TradeTaxDeclarationSavedDraftInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    TradeTaxDeclarationSavedDraftInfoQuery,
    TradeTaxDeclarationSavedDraftInfoQueryVariables
  >(TradeTaxDeclarationSavedDraftInfoDocument, options);
}
export function useTradeTaxDeclarationSavedDraftInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TradeTaxDeclarationSavedDraftInfoQuery,
    TradeTaxDeclarationSavedDraftInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TradeTaxDeclarationSavedDraftInfoQuery,
    TradeTaxDeclarationSavedDraftInfoQueryVariables
  >(TradeTaxDeclarationSavedDraftInfoDocument, options);
}
export type TradeTaxDeclarationSavedDraftInfoQueryHookResult = ReturnType<
  typeof useTradeTaxDeclarationSavedDraftInfoQuery
>;
export type TradeTaxDeclarationSavedDraftInfoLazyQueryHookResult = ReturnType<
  typeof useTradeTaxDeclarationSavedDraftInfoLazyQuery
>;
export type TradeTaxDeclarationSavedDraftInfoQueryResult = Apollo.QueryResult<
  TradeTaxDeclarationSavedDraftInfoQuery,
  TradeTaxDeclarationSavedDraftInfoQueryVariables
>;
