import React, { useState } from "react";
import { InputNumber } from "antd";

import { TransactionSplit } from "../../types";

interface Props {
  split: { amount?: number };
  onChangeHandler: (split: Partial<TransactionSplit>, index: number) => void;
  index: number;
  disabled: boolean | undefined;
}

export const toCents = (euros: number) => Math.round(euros * 100);

const SplitAmountInput = ({
  split,
  onChangeHandler,
  index,
  disabled,
}: Props) => {
  const [parsedAmount, setParsedAmount] = useState<number>(0);

  // Restrict input to allow only numbers and a decimal point
  const onKeyPress = (e: React.KeyboardEvent) => {
    if (!new RegExp("^[0-9,.-]*$").test(e.key)) {
      e.preventDefault();
      return false;
    }
  };

  return (
    <InputNumber
      style={{ marginTop: "5px", minWidth: "120px" }}
      id={`amount-${index}`}
      data-testid={`amount-${index}`}
      defaultValue={split.amount}
      value={split.amount && split.amount / 100}
      onChange={(value) => setParsedAmount(toCents(value))}
      onBlur={() => {
        parsedAmount &&
          onChangeHandler(
            {
              ...split,
              amount: parsedAmount,
            },
            index
          );
        setParsedAmount(0);
      }}
      addonAfter="€"
      decimalSeparator=","
      controls={false}
      onKeyPress={onKeyPress}
      precision={2}
      disabled={disabled}
    />
  );
};

export default SplitAmountInput;
