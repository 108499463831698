import Search from "antd/lib/input/Search";

import { InputWrapper } from "./style";

interface AssigneeInputProps {
  assignee: string;
  setAssignee: (assignee: string) => void;
}

const AssigneeInput = ({ assignee, setAssignee }: AssigneeInputProps) => {
  return (
    <InputWrapper>
      <Search
        value={assignee}
        onChange={(e) => setAssignee(e.target.value)}
        placeholder="Suche nach Bearbeiter"
        onSearch={setAssignee}
        allowClear
      />
    </InputWrapper>
  );
};

export default AssigneeInput;
