import React from "react";

import SubNavigation, { VAT_TABS } from "./SubNavigation";
import VatTable from "./VatTable/VatTableContainer";
import { ContentWrapper } from "../../common/styledComponents";

const VatView = () => {
  return (
    <ContentWrapper>
      <SubNavigation activeTab={VAT_TABS.VAT.key} />
      <VatTable />
    </ContentWrapper>
  );
};

export default VatView;
