import styled from "styled-components";
import { Space, Table, Typography, Spin } from "antd";
import { ReloadOutlined, FileDoneOutlined } from "@ant-design/icons";

import colors from "../../../../../themes/colors";

const { Paragraph, Text } = Typography;

export const VatTableSpace = styled(Space)`
  width: 100%;
`;

export const ColParagraph = styled(Paragraph)`
  margin-bottom: 5px;
  display: flex;
`;

export const SourceCell = styled(Text)`
  color: ${colors.lightGreyOpacity};
`;

export const BorderedTable: typeof Table = styled(Table)`
  border: 1px solid ${colors.veryLightGrey};
  border-bottom: none;
`;

export const TableWrapper = styled.div`
  border: 1px solid ${colors.veryLightGrey};

  tr.expandable {
    cursor: pointer;
  }
`;

export const SelectWrapper = styled.div`
  display: flex;
`;

export const AntSpin = styled(Spin)`
  padding-top: 6px;
  margin-left: 10px;
`;

export const AvailablePeriodErrorContainer = styled.div`
  display: flex;
  margin-left: 10px;
  max-width: 120px;
`;

export const AvailablePeriodErrorMessage = styled.div`
  color: ${colors.darkRed};
  font-size: 10px;
  max-width: 100px;
`;

export const ReloadOutlinedIcon = styled(ReloadOutlined)`
  align-self: center;
  margin-left: 5px;
  vertical-align: baseline;
  font-size: 14px;
  cursor: pointer;
  color: ${colors.black};
`;

export const SelectHintContainer = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.midGrey};
  margin-left: 10px;
  gap: 4px;
  font-size: 12px;
`;

export const FileDoneOutlinedIcon = styled(FileDoneOutlined)`
  color: ${colors.darkGrey};
  font-size: 14px;
`;
