export enum KontaxUserStatus {
  PENDING = "PENDING",
  ACTIVATED = "ACTIVATED",
  LEAD = "LEAD",
  DECLINED = "DECLINED",
  DEACTIVATED = "DEACTIVATED",
  DECLINED_REDIRECTED = "DECLINED_REDIRECTED",
}

export interface KontaxUserStatusAdditionalParams {
  kontaxBillingStartDate?: string;
}
