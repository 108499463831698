import React, { useState, useCallback, useEffect } from "react";

import api from "../../../../api";
import { UserFilterOptions } from "../UserFilters";
import { IVatDeclarationUser, KontaxPlan } from "../../../../types";
import VatTable from "./VatTableView";
import {
  destroyMessage,
  showSuccessMessage,
  showLoadingMessage,
} from "../../../../utils";
import useEmailParam from "../../../hooks/useEmailParam";

const defaultFilterOptions = {
  email: "",
  status: undefined,
  plans: [KontaxPlan.KONTAX, KontaxPlan.KONTAX_SB],
  vatPaymentFrequency: undefined,
};

const VatTableContainer = () => {
  const [emailParam, setEmailParam] = useEmailParam();
  const [users, setUsers] = useState<Array<IVatDeclarationUser> | null>(null);
  const [pageNumberState, setCurrentPage] = useState(1);
  const [filterOptionsState, setFilterOptions] = useState<UserFilterOptions>({
    ...defaultFilterOptions,
    email: emailParam || "",
  });

  const fetchUsers = useCallback(
    async (pageNumber?: number, filterOptions?: UserFilterOptions) => {
      const loadingKey = "user-declarations";
      try {
        showLoadingMessage(loadingKey);
        const { declarations, page } =
          await api.kontax.getUsersWithDeclarations({
            page: pageNumber,
            status: filterOptions?.status,
            email: filterOptions?.email,
            plans: filterOptions?.plans,
            vatPaymentFrequency: filterOptions?.vatPaymentFrequency,
          });

        setUsers(declarations);
        setCurrentPage(page);
        showSuccessMessage();
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log("Error fetching user", error);
        setUsers([]);
        setCurrentPage(1);
      } finally {
        destroyMessage(loadingKey);
      }
    },
    []
  );

  useEffect(() => {
    if (filterOptionsState?.email) {
      fetchUsers(pageNumberState, filterOptionsState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <VatTable
      users={users}
      filterOptions={filterOptionsState}
      handleFilterChange={(newFilterOptions: UserFilterOptions) => {
        if (newFilterOptions.email) {
          setEmailParam(newFilterOptions.email);
        }

        setFilterOptions({ ...defaultFilterOptions, ...newFilterOptions });
        fetchUsers(pageNumberState, newFilterOptions);
      }}
      pageNumber={pageNumberState}
      handlePageChange={(newPageNumber: number) => {
        setCurrentPage(newPageNumber);
        fetchUsers(newPageNumber, filterOptionsState);
      }}
      loadAll={() => {
        setFilterOptions({ ...filterOptionsState, email: "" });
        fetchUsers(1, { ...filterOptionsState, email: "" });
      }}
    />
  );
};

export default VatTableContainer;
