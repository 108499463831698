import React, { useEffect, useState } from "react";
import { Button } from "antd";

import { getSingularOrPlural } from "../helpers";
import { ShowCountWrapper } from "./styledComponents";

interface Props {
  count?: number;
  fetchTransactionCount: () => void;
}

const ShowCount = ({ count, fetchTransactionCount }: Props) => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(false);
  }, [count]);

  return (
    <ShowCountWrapper>
      {count !== undefined ? (
        <span data-test="transactionsCount">{`${count} ${getSingularOrPlural(
          count,
          "transaction"
        )} found`}</span>
      ) : (
        <Button
          data-test="countTransactionsButton"
          type="link"
          onClick={() => {
            setIsLoading(true);
            fetchTransactionCount();
          }}
          loading={isLoading}
        >
          Show count
        </Button>
      )}
    </ShowCountWrapper>
  );
};

export default ShowCount;
