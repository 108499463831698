import styled from "styled-components";

import FormField from "../common/FormField";

export const AssetClassCustomField = styled(FormField)`
  flex: 0;
  min-width: 280px;
`;

export const DepreciationYearsField = styled(FormField)`
  flex: 0;
  min-width: 43px;
`;

export const DepreciationYearsEditableField = styled(FormField)`
  flex: 0;
  min-width: 225px;
`;
