import React, { useState, useMemo, useCallback } from "react";
import { Button, Drawer, Tooltip } from "antd";

import { ChangeLog } from "../../../api/graphql";
import {
  ActionBlock,
  DateBlock,
  TitleWithLogs,
  OneLine,
} from "./styledComponents";
import {
  constructChangeLogTexts,
  filterSelectedFields,
  formatDateTime,
} from "./helpers";
import { HoverableHistoryIcon } from "../styledComponents";

const TEST_ID = "view-logs-button";
const ACTION_TEXT = "View logs";

interface ActionLogDrawerProps {
  changeLogs?: ChangeLog[];
  title: string;
  withIcon?: boolean;
  selectedFields?: string[];
}

const ActionLogDrawer = ({
  changeLogs,
  title,
  withIcon,
  selectedFields,
}: ActionLogDrawerProps) => {
  const [shouldShowLog, setShouldShowLog] = useState(false);
  const onClick = useCallback(() => setShouldShowLog(true), []);

  const changeLogList = useMemo(() => {
    const filteredChangeLogs = filterSelectedFields({
      changeLogs,
      selectedFields,
    });
    return filteredChangeLogs.map(
      ({ changes, changedAt, changedBy, subText, modelName }) => (
        <ActionBlock key={`${changedAt}`}>
          <DateBlock>{formatDateTime(changedAt)}</DateBlock>
          {changedBy && <div>{changedBy}</div>}
          {subText && <div>{subText}</div>}
          {constructChangeLogTexts(changes, modelName).map((text, idx) => (
            <OneLine key={`${changedAt}-${idx}`}>
              <Tooltip placement="topLeft" title={text}>
                {text}
              </Tooltip>
            </OneLine>
          ))}
        </ActionBlock>
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeLogs?.length]);

  if (!changeLogs?.length) {
    return null;
  }

  return (
    <>
      {withIcon ? (
        <HoverableHistoryIcon
          onClick={onClick}
          data-testid={TEST_ID}
          data-test="viewLogsButton"
          title={ACTION_TEXT}
        />
      ) : (
        <Button type="link" onClick={onClick} data-testid={TEST_ID}>
          {ACTION_TEXT}
        </Button>
      )}
      <Drawer
        title={title}
        placement="right"
        onClose={() => setShouldShowLog(false)}
        visible={shouldShowLog}
      >
        {changeLogList}
      </Drawer>
    </>
  );
};

export default ActionLogDrawer;
export { TitleWithLogs };
