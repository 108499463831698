import React, {
  useEffect,
  useState,
  useCallback,
  Fragment,
  useMemo,
  MouseEvent,
} from "react";
import { Button, notification, Space, Table } from "antd";
import isEqual from "lodash/isEqual";

import { UserDependent } from "../../../../../../types";
import {
  formatDayMonthYear,
  getFormattedType,
  validateDependents,
  trimFakeIdAndEmptyTaxId,
  generateEmptyDependent,
  LOCAL_ID_PREFIX,
} from "../utils";
import { ButtonsWrapper } from "../../../../../common/styledComponents";
import DependentDetails from "./DependentDetails";
import { getErrorMessage } from "../../../../../../utils/error";
import { TAX_ADVISORY_PERMISSION_SCOPES } from "../../../../../../constants";
import { getPermissionScope } from "../../../../../../gapi";

type DependentsTableProps = {
  email: string;
  dependents: UserDependent[];
  updateDependents: Function;
  deleteDependent: (email: string, id: string) => Promise<void>;
};

enum ColumnNames {
  ID = "id",
  FIRST_NAME = "firstName",
  LAST_NAME = "lastName",
  NAME = "name",
  TYPE = "type",
  BIRTH_DATE = "birthDate",
  DE_TAX_ID = "deTaxId",
  MARRIAGE_START_DATE = "marriageStartDate",
  MARRIAGE_END_DATE = "marriageEndDate",
}

interface Dependent {
  id?: string;
  name: string;
  firstName: string;
  lastName: string;
  deTaxId?: string;
  birthDate: string;
  marriageStartDate?: string;
  marriageEndDate?: string;
  type: string;
}

const DependentsTable = ({
  email,
  dependents,
  updateDependents,
  deleteDependent,
}: DependentsTableProps) => {
  const hasTaxAdvisoryPermissionScope = TAX_ADVISORY_PERMISSION_SCOPES.includes(
    getPermissionScope()
  );

  const [isSaving, setIsSaving] = useState(false);
  const [localDependents, setLocalDependents] = useState(dependents);
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);

  // Format data to display it accordingly
  const data: Dependent[] = localDependents.map((dependent: UserDependent) => ({
    [ColumnNames.ID]: dependent.id,
    [ColumnNames.FIRST_NAME]: dependent.firstName,
    [ColumnNames.LAST_NAME]: dependent.lastName,
    [ColumnNames.NAME]: `${dependent.firstName} ${dependent.lastName}`,
    [ColumnNames.BIRTH_DATE]: formatDayMonthYear(dependent.birthDate),
    [ColumnNames.MARRIAGE_START_DATE]: dependent.marriageStartDate
      ? formatDayMonthYear(dependent.marriageStartDate)
      : "-",
    [ColumnNames.MARRIAGE_END_DATE]: dependent.marriageEndDate
      ? formatDayMonthYear(dependent.marriageEndDate)
      : "-",
    [ColumnNames.DE_TAX_ID]: dependent.deTaxId || "-",
    [ColumnNames.TYPE]: getFormattedType(dependent.type),
  }));

  useEffect(() => {
    setLocalDependents(dependents);
  }, [dependents]);

  const filterExpandedRowKeys = useCallback(
    (rowId?: string) => {
      if (rowId && expandedRowKeys.includes(rowId)) {
        setExpandedRowKeys(expandedRowKeys.filter((row) => row !== rowId));
      }
    },
    [expandedRowKeys, setExpandedRowKeys]
  );

  const updateLocalDependents = useCallback(
    (row: UserDependent) => (formData: UserDependent) => {
      const updated = localDependents.map((localDependent: UserDependent) => {
        if (localDependent.id === formData.id) {
          return formData;
        }

        return localDependent;
      });

      setLocalDependents(updated);
      filterExpandedRowKeys(formData.id);
    },
    [localDependents, filterExpandedRowKeys]
  );

  const addLocalUserDependent = () =>
    setLocalDependents([...localDependents, generateEmptyDependent()]);

  const onSave = useCallback(
    async (event: MouseEvent) => {
      event.preventDefault();

      try {
        validateDependents(localDependents);
      } catch (e) {
        notification.error({ message: `Form error: ${getErrorMessage(e)}.` });
        return;
      }

      try {
        setIsSaving(true);
        await updateDependents(email, trimFakeIdAndEmptyTaxId(localDependents));
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(
          "User dependents could not be updated",
          getErrorMessage(error)
        );
      } finally {
        setIsSaving(false);
      }
    },
    [email, localDependents, updateDependents]
  );

  const onDeleteDependent = useCallback(
    async (id: string) => {
      await deleteDependent(email, id);
    },
    [deleteDependent, email]
  );

  const onCancel = useCallback(
    (row: Dependent) => (event: MouseEvent) => {
      event.preventDefault();

      let { id: rowId } = row;

      // If the dependent does not exist in DB, remove it from the list.
      const updatedDependents = rowId?.startsWith(LOCAL_ID_PREFIX)
        ? localDependents.filter(({ id }) => id !== rowId)
        : localDependents;

      setLocalDependents(updatedDependents);
      filterExpandedRowKeys(rowId);
    },
    [localDependents, filterExpandedRowKeys]
  );

  const renderRowSubComponent = useCallback(
    (row: Dependent) => {
      return (
        <DependentDetails
          localDependent={localDependents.find(
            (dependent) => dependent.id === row.id
          )}
          updateLocalDependents={updateLocalDependents(row as UserDependent)}
          onDeleteDependent={onDeleteDependent}
          onCancel={onCancel(row)}
          isSaving={isSaving}
        />
      );
    },
    [
      localDependents,
      updateLocalDependents,
      onDeleteDependent,
      onCancel,
      isSaving,
    ]
  );

  const onTableRowExpand = (expanded: boolean, record: Dependent) => {
    setExpandedRowKeys(record.id && expanded ? [record.id] : []);
  };

  const isFormPristine = useMemo(
    (): boolean => isEqual(dependents, localDependents),
    [dependents, localDependents]
  );

  const columns = React.useMemo(
    () => [
      {
        title: "Type",
        dataIndex: ColumnNames.TYPE,
        key: ColumnNames.TYPE,
        sorter: (a: Dependent, b: Dependent) => a.type.localeCompare(b.type),
      },
      {
        title: "Name",
        dataIndex: ColumnNames.NAME,
        key: ColumnNames.NAME,
        sorter: (a: Dependent, b: Dependent) => a.name.localeCompare(b.name),
      },
      {
        title: "DOB",
        dataIndex: ColumnNames.BIRTH_DATE,
        key: ColumnNames.BIRTH_DATE,
        sorter: (a: Dependent, b: Dependent) =>
          a.birthDate.localeCompare(b.birthDate),
      },
      {
        title: "Marriage start date",
        dataIndex: ColumnNames.MARRIAGE_START_DATE,
        key: ColumnNames.MARRIAGE_START_DATE,
      },
      {
        title: "Marriage end date",
        dataIndex: ColumnNames.MARRIAGE_END_DATE,
        key: ColumnNames.MARRIAGE_END_DATE,
      },
      {
        title: "Steuerliche Identifikationsnr.",
        dataIndex: ColumnNames.DE_TAX_ID,
        key: ColumnNames.DE_TAX_ID,
        sorter: (a: Dependent, b: Dependent) =>
          a.deTaxId && b.deTaxId ? a.deTaxId.localeCompare(b.deTaxId) : 0,
      },
    ],
    []
  );

  return (
    <Fragment>
      {hasTaxAdvisoryPermissionScope && (
        <ButtonsWrapper>
          <Space style={{ marginBottom: 16 }}>
            <Button
              onClick={addLocalUserDependent}
              data-test="addDependentButton"
              disabled={isSaving}
            >
              Add
            </Button>
            <Button
              data-test="saveDependentButton"
              type="primary"
              onClick={onSave}
              disabled={isFormPristine}
              loading={isSaving}
            >
              Save
            </Button>
          </Space>
        </ButtonsWrapper>
      )}
      <Table
        data-test="userDependentTable"
        columns={columns}
        dataSource={data}
        pagination={false}
        bordered
        expandedRowKeys={expandedRowKeys}
        expandable={{
          expandedRowRender: renderRowSubComponent,
          expandRowByClick: true,
        }}
        onExpand={onTableRowExpand}
        rowKey="id"
      />
    </Fragment>
  );
};

export default DependentsTable;
