import api from "../../../api";
import { uploadFile } from "../../../api/modules/Common";
import { getErrorMessage } from "../../../utils/error";

export const createTransactionAsset = async (
  transactionId: string,
  file: File
) => {
  try {
    const [filetype] = file.type.split("/").reverse();
    if (!["jpg", "jpeg", "png", "pdf"].includes(filetype)) {
      throw new Error("Not supported filetype");
    }

    const { url, assetId, formData } = await api.kontax.createTransactionAsset(
      transactionId,
      file.name,
      filetype
    );

    // Upload the file to s3 with the required data (formData, file)
    const form = new FormData();
    formData.map(({ key, value }: { key: string; value: string }) =>
      form.append(key, value)
    );
    form.append("file", file);

    await uploadFile({ url, form });

    // patch to finalize the assets once it has been uploaded to s3
    await api.kontax.acknowledgeTransactionAsset(transactionId, assetId);
    return assetId;
  } catch (error) {
    throw new Error(
      `Error while uploading an asset for a transaction:
      ${getErrorMessage(error)}`
    );
  }
};
