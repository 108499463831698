import React from "react";

import { getBusinessAssetsDataForPopup } from "../../../BusinessAsset/useDeleteBusinessAssetConfirmation";

import WarningPopup from "../../../common/WarningPopup";

const SPLIT_AMOUNT_CHANGE_WARNING_POPUP_TEXT =
  "Der Anschaffungswert der Anlage wurde geändert:\n\n";

type SplitUpdateProps = {
  handleUpdateSplits: any;
  updatingSplits: any;
};

export const TransactionsTablePopups = ({
  handleUpdateSplits,
  updatingSplits,
}: SplitUpdateProps) => {
  return (
    <>
      {/**
       * Confirmation modal for a change in transaction splits amounts
       */}
      {updatingSplits?.assetsToUpdateAmount?.length && (
        <WarningPopup
          text={
            SPLIT_AMOUNT_CHANGE_WARNING_POPUP_TEXT +
            getBusinessAssetsDataForPopup(updatingSplits.assetsToUpdateAmount)
          }
          onClose={() =>
            handleUpdateSplits({
              ...updatingSplits,
              skipConfirmationPopup: true,
            })
          }
        />
      )}
    </>
  );
};
