import React, { useState } from "react";
import moment from "moment";
import { Form, Input, Button, Select, notification } from "antd";
import { useLocation } from "react-router-dom";

import { FibuFinalCheckStatus } from "../../api/graphql/schema.generated";
import {
  YesNoEnum,
  BookkeepingSource,
  BookkeepingSourceType,
  FibuFinalCheck,
} from "../../types";
import {
  BookkeepingSourceWrapper,
  CustomerAttributes,
  CustomerInformationCard,
  CustomerInformationSectionTitle,
} from "./styledComponents";
import CustomerAttribute from "./CustomerAttribute";
import { BottomDatePicker as DatePickerWrapper } from "../common/VerticalDateRangePicker/styledComponents";
import { formatBooleanToString } from "../../utils";
import { getDefaultTaxYear } from "../pages/TaxDeclaration/hooks/useTaxYearParam";
import { HorizontalLine } from "../pages/Mandanten/Common/UserDetails/styledComponents";

const DATE_FORMAT = "YYYY-MM-DD";

const UserExternalBookkeepingBlock = ({
  bookkeepingSources,
  fibuFinalChecks,
  onUpdate,
}: {
  bookkeepingSources: BookkeepingSource[];
  fibuFinalChecks: FibuFinalCheck[];
  onUpdate: (bookkeepingSource: BookkeepingSource) => Promise<void>;
}) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const year = +(searchParams.get("year") || getDefaultTaxYear());

  const sources =
    bookkeepingSources?.filter((source) => source.year === year) || [];

  const advisor = sources.find(
    (source) => source.type === BookkeepingSourceType.ADVISOR
  );
  const tool = sources.find(
    (source) => source.type === BookkeepingSourceType.TOOL
  );
  const manual = sources.find(
    (source) => source.type === BookkeepingSourceType.MANUAL
  );

  const fibuFinalCheck = fibuFinalChecks.find((entry) => entry.year === year);

  const hasAllInKontist =
    !sources.length &&
    fibuFinalCheck?.status !== FibuFinalCheckStatus.NOT_NEEDED;

  const [advisorStartDate, setAdvisorStartDate] = useState(
    advisor?.startDate || null
  );
  const [advisorEndDate, setAdvisorEndDate] = useState(
    advisor?.endDate || null
  );
  const [taxAdvisorAccess, setTaxAdvisorAccess] = useState(
    tool?.taxAdvisorAccess
  );

  const onAdvisorFinish = async (values: BookkeepingSource) => {
    if (!values.email && !values.phone) {
      return notification.error({
        message: "Email or Phone of tax advisor should be filled",
      });
    }

    const data = {
      ...(advisor?.id ? { id: advisor.id } : {}),
      name: values.name,
      email: values.email,
      phone: values.phone,
      startDate: advisorStartDate,
      endDate: advisorEndDate,
      year,
      type: BookkeepingSourceType.ADVISOR,
    };
    await onUpdate(data);
  };

  const onToolFinish = async (values: BookkeepingSource) => {
    const data = {
      ...(tool?.id ? { id: tool.id } : {}),
      name: values.name,
      link: !!values.link ? values.link : null,
      taxAdvisorAccess,
      year,
      type: BookkeepingSourceType.TOOL,
    };
    await onUpdate(data);
  };

  return fibuFinalCheck?.id ? (
    <>
      <HorizontalLine />
      <CustomerInformationSectionTitle>
        External bookkeeping
      </CustomerInformationSectionTitle>
      <CustomerInformationCard>
        {hasAllInKontist ? (
          <BookkeepingSourceWrapper>
            Everything in Kontist account
          </BookkeepingSourceWrapper>
        ) : (
          sources.length && (
            <CustomerAttributes>
              <BookkeepingSourceWrapper>
                <Form
                  autoComplete="off"
                  onFinish={onAdvisorFinish}
                  layout="vertical"
                >
                  <Form.Item
                    initialValue={advisor?.name || ""}
                    label="Name of tax advisor"
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: "Please input Name of tax advisor",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Phone Number"
                    name="phone"
                    initialValue={advisor?.phone || ""}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Email address"
                    name="email"
                    initialValue={advisor?.email || ""}
                    rules={[
                      {
                        type: "email",
                        message: "Not a valid email address",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <CustomerAttribute label="">
                    <Form.Item
                      name="startDate"
                      initialValue={
                        advisorStartDate ? moment(advisorStartDate) : null
                      }
                    >
                      <DatePickerWrapper
                        onChange={(value: moment.Moment | null) => {
                          setAdvisorStartDate(
                            value ? value.format(DATE_FORMAT) : ""
                          );
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      name="endDate"
                      initialValue={
                        advisorEndDate ? moment(advisorEndDate) : null
                      }
                    >
                      <DatePickerWrapper
                        onChange={(value: moment.Moment | null) => {
                          setAdvisorEndDate(
                            value ? value.format(DATE_FORMAT) : ""
                          );
                        }}
                      />
                    </Form.Item>
                  </CustomerAttribute>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ marginBottom: 0 }}
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </Form>
              </BookkeepingSourceWrapper>
              <BookkeepingSourceWrapper>
                <Form
                  autoComplete="off"
                  onFinish={onToolFinish}
                  layout="vertical"
                >
                  <Form.Item
                    name="name"
                    label="Name of bookkeeping tool"
                    initialValue={tool?.name}
                    rules={[
                      {
                        required: true,
                        message: "Please provide name of bookkeeping tool",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="taxAdvisorAccess"
                    label="Tax Advisor Access for tool"
                    initialValue={formatBooleanToString(
                      !!tool?.taxAdvisorAccess
                    )}
                    rules={[
                      {
                        required: true,
                        message: "Please select",
                      },
                    ]}
                  >
                    <Select
                      onChange={(value: YesNoEnum) => {
                        setTaxAdvisorAccess(value === YesNoEnum.YES || false);
                      }}
                    >
                      {Object.values(YesNoEnum).map((option, index) => (
                        <Select.Option value={option} key={index}>
                          {option}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="link"
                    label="Link to the Bookkeeping Tool"
                    initialValue={tool?.link}
                    rules={[{ type: "url", warningOnly: true }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ marginBottom: 0 }}
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </Form>
              </BookkeepingSourceWrapper>
              <BookkeepingSourceWrapper>
                <Form.Item label="Has manual Bookkeeping">
                  <Input value={formatBooleanToString(!!manual)} />
                </Form.Item>
              </BookkeepingSourceWrapper>
            </CustomerAttributes>
          )
        )}
      </CustomerInformationCard>
    </>
  ) : null;
};

export default UserExternalBookkeepingBlock;
