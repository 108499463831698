import { useCallback, useEffect, useMemo } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Row,
  Select,
  Typography,
} from "antd";
import { useWatch } from "antd/lib/form/Form";
import { Trans, useTranslation } from "react-i18next";
import { TFunction } from "i18next";

import {
  UpsertTaxServiceInput,
  TaxServiceStatus,
  TaxServiceDepartment,
} from "../../../api/graphql/schema.generated";
import { TaxServiceModalContent } from "./styledComponent";
import {
  EXTERNAL_NOTE_TAX_SERVICES,
  FLAT_RATE_TAX_SERVICES,
  FREE_TAX_SERVICES,
  INTERNAL_NOTE_REQUIRED_SERVICES,
  TAX_SERVICE_NAMES_BY_DEPARTMENT,
  TAX_SERVICE_TAX_YEAR_OPTIONS,
  TAX_YEAR_REQUIRED_TAX_SERVICES,
  TIME_TRACKING_REQUIRED_TAX_SERVICES,
} from "./constants";
import {
  destroyMessage,
  eurosToCents,
  showGraphQlErrorNotification,
  showLoadingMessage,
} from "../../../utils";
import { useUpsertTaxServiceMutation } from "../../../api/graphql/mutations/taxService/upsertTaxService.generated";
import { IUser } from "../../../types";

const { Paragraph, Text } = Typography;

const LOADING_MESSAGE_KEY = "saving-tax-service";

const getTexts = (t: TFunction<"ServiceTracker", undefined>) => ({
  title: (userName: string) => t("serviceTracker.modal.title", { userName }),
  ctaSave: t("serviceTracker.modal.ctaSave"),
  headline: t("serviceTracker.modal.headline"),
  optional: t(`serviceTracker.optional`),
  inputLabel: (inputName: string) =>
    t(`serviceTracker.modal.${inputName}Label`),
  inputPlaceholder: (inputName: string) =>
    t(`serviceTracker.modal.${inputName}Placeholder`),
  inputError: (inputName: string) =>
    t(`serviceTracker.modal.${inputName}Error`),
  radioButtonOption: (option: string) =>
    t(`serviceTracker.modal.radioButtonOptions.${option}`),
  departmentName: (name: string) => t(`serviceTracker.departmentsName.${name}`),
  serviceName: (name: string) => t(`serviceTracker.servicesName.${name}`),
  status: (status: string) => t(`serviceTracker.statuses.${status}`),
});

const CreateTaxServiceModal = ({
  user,
  visible,
  onClose,
  onSuccess,
}: {
  user: IUser | null;
  visible: boolean;
  onClose: Function;
  onSuccess: Function;
}) => {
  const { t } = useTranslation("ServiceTracker");
  const texts = getTexts(t);

  const [form] = Form.useForm<UpsertTaxServiceInput>();
  const selectedDepartment = useWatch("department", form);
  const selectedService = useWatch("name", form);

  const isYearRequired = TAX_YEAR_REQUIRED_TAX_SERVICES.includes(
    selectedService!
  );

  const isHoursRequired = TIME_TRACKING_REQUIRED_TAX_SERVICES.includes(
    selectedService!
  );

  const isFreeTaxService = FREE_TAX_SERVICES.includes(selectedService!);

  const isFlatRateService = FLAT_RATE_TAX_SERVICES.includes(selectedService);

  const isExternalNoteRequired =
    EXTERNAL_NOTE_TAX_SERVICES.includes(selectedService);

  const isInternalNoteRequired =
    INTERNAL_NOTE_REQUIRED_SERVICES.includes(selectedService);

  const [upsertTaxService, { loading: isSaving }] =
    useUpsertTaxServiceMutation();

  const handleReset = useCallback(() => {
    form.resetFields();
  }, [form]);

  const handleCancel = () => {
    onClose();
    handleReset();
  };

  const handleSubmit = useCallback(
    async (values: UpsertTaxServiceInput) => {
      if (!user) {
        return;
      }
      showLoadingMessage(LOADING_MESSAGE_KEY);
      try {
        await upsertTaxService({
          variables: {
            email: user.email,
            payload: {
              ...values,
              flatRate: values.flatRate ? eurosToCents(values.flatRate) : null,
              internalNote: values.internalNote ?? "",
              externalNote: values.externalNote ?? "",
              status: isFreeTaxService
                ? TaxServiceStatus.WILL_BE_BILLED
                : values.status,
            },
          },
        });

        await onSuccess();

        onClose();
        handleReset();
      } catch (error) {
        showGraphQlErrorNotification(`Saving tax service`, error);
      } finally {
        destroyMessage(LOADING_MESSAGE_KEY);
      }
    },
    [handleReset, isFreeTaxService, onClose, onSuccess, upsertTaxService, user]
  );

  useEffect(() => {
    form.setFieldsValue({ name: undefined });
  }, [form, selectedDepartment]);

  useEffect(() => {
    form.setFieldsValue({ year: null, hours: null, flatRate: null });
  }, [form, selectedService]);

  const departmentOptions = useMemo(() => {
    return Object.keys(TaxServiceDepartment).map((key) => ({
      value: key as TaxServiceDepartment,
      label: texts.departmentName(key),
    }));
  }, [texts]);

  const serviceNameOptions = useMemo(() => {
    return selectedDepartment
      ? TAX_SERVICE_NAMES_BY_DEPARTMENT[selectedDepartment].map((service) => ({
          value: service,
          label: texts.serviceName(service),
        }))
      : undefined;
  }, [selectedDepartment, texts]);

  const footer = [
    <Button
      form="taxServiceFormId"
      key="submit"
      htmlType="submit"
      type="primary"
      loading={isSaving}
    >
      {texts.ctaSave}
    </Button>,
  ];

  if (!user) {
    return null;
  }

  // we want to round the input hours value to a multiple of 0.25
  // valid values: 0,25 0,5, 0,75, 1, 1,25 ...
  const handleBlurHoursInput = () => {
    const roundingStep = 0.25;
    const hours: number = form.getFieldValue("hours");
    let roundValue = hours - (hours % roundingStep);
    if (roundValue === 0) {
      roundValue = roundingStep;
    }
    form.setFieldsValue({ hours: roundValue });
  };

  return (
    <Modal
      title={texts.title(`${user.firstName} ${user.lastName}`)}
      visible={visible}
      onCancel={handleCancel}
      footer={footer}
      centered
      width={600}
    >
      <Form
        form={form}
        name="taxServiceForm"
        id="taxServiceFormId"
        onFinish={handleSubmit}
        autoComplete="off"
        layout="vertical"
        requiredMark={false}
      >
        <TaxServiceModalContent>
          <Form.Item
            name="department"
            label={texts.inputLabel("selectField")}
            rules={[{ required: true }]}
          >
            <Select
              options={departmentOptions}
              placeholder={texts.inputPlaceholder("selectField")}
            ></Select>
          </Form.Item>
          <Form.Item
            name="name"
            label={texts.inputLabel("selectService")}
            rules={[{ required: true }]}
          >
            <Select
              options={serviceNameOptions}
              placeholder={texts.inputPlaceholder("selectService")}
            ></Select>
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.name !== currentValues.name
            }
          >
            <Row gutter={10} justify="space-between">
              <Col span={12}>
                <Form.Item
                  name="year"
                  label={texts.inputLabel("selectTaxYear")}
                  rules={[
                    {
                      required: isYearRequired,
                    },
                  ]}
                >
                  <Select
                    options={TAX_SERVICE_TAX_YEAR_OPTIONS}
                    disabled={!isYearRequired}
                  ></Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                {isFlatRateService ? (
                  <Form.Item
                    name="flatRate"
                    label={texts.inputLabel("inputFlatRate")}
                    rules={[
                      {
                        required: isFlatRateService,
                        validator: (_, flatRate: number) => {
                          if (
                            isFlatRateService &&
                            (flatRate === 0 || flatRate === null)
                          ) {
                            return Promise.reject(
                              new Error(texts.inputError("inputFlatRate"))
                            );
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <InputNumber
                      type="number"
                      addonAfter="€"
                      controls={false}
                      style={{ width: "100%" }}
                      disabled={!isFlatRateService}
                      placeholder={texts.inputPlaceholder("inputFlatRate")}
                    />
                  </Form.Item>
                ) : (
                  <Form.Item
                    name="hours"
                    label={texts.inputLabel("inputHours")}
                    rules={[
                      {
                        required: isHoursRequired,
                        validator: (_, amount: number) => {
                          if (
                            isHoursRequired &&
                            (amount === 0 || amount === null)
                          ) {
                            return Promise.reject(
                              new Error(texts.inputError("inputHours"))
                            );
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <InputNumber
                      type="number"
                      controls={false}
                      style={{ width: "100%" }}
                      disabled={!isHoursRequired}
                      onBlur={handleBlurHoursInput}
                      placeholder={texts.inputPlaceholder("inputHours")}
                    />
                  </Form.Item>
                )}
              </Col>
            </Row>
          </Form.Item>
          <Form.Item
            name="internalNote"
            label={
              <Paragraph>
                <Text>{texts.inputLabel("inputInternalComment")}</Text>
                {!isInternalNoteRequired && (
                  <Text type="secondary" style={{ marginLeft: "8px" }}>
                    {texts.optional}
                  </Text>
                )}
              </Paragraph>
            }
            rules={[{ required: isInternalNoteRequired }]}
          >
            <Input.TextArea
              showCount
              maxLength={250}
              defaultValue=""
              placeholder={texts.inputPlaceholder("inputInternalComment")}
            />
          </Form.Item>
          <Form.Item
            name="externalNote"
            label={
              <Paragraph>
                <Text>
                  <Trans
                    defaults={texts.inputLabel("inputCommentForClient")}
                    components={[<b key="bold" />]}
                  />
                </Text>
                {!isExternalNoteRequired && (
                  <Text type="secondary" style={{ marginLeft: "8px" }}>
                    {texts.optional}
                  </Text>
                )}
              </Paragraph>
            }
            rules={[{ required: isExternalNoteRequired }]}
          >
            <Input.TextArea
              showCount
              maxLength={150}
              defaultValue=""
              placeholder={texts.inputPlaceholder("inputCommentForClient")}
            />
          </Form.Item>
          <Form.Item
            label={texts.inputLabel("radioButton")}
            name="status"
            rules={[{ required: !isFreeTaxService }]}
          >
            <Radio.Group
              options={[
                {
                  label: texts.radioButtonOption("yes"),
                  value: TaxServiceStatus.WILL_BE_BILLED,
                },
                {
                  label: texts.radioButtonOption("no"),
                  value: TaxServiceStatus.BLOCKED,
                },
              ]}
              optionType="button"
              disabled={isFreeTaxService}
            />
          </Form.Item>
        </TaxServiceModalContent>
      </Form>
    </Modal>
  );
};

export default CreateTaxServiceModal;
