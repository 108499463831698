import styled, { css } from "styled-components";

import { HoverableUserIcon } from "../styledComponents";
import colors from "../../../themes/colors";

export const StyledUserIcon = styled(HoverableUserIcon)<{
  showNotification?: boolean;
}>`
  svg {
    margin-left: ${({ showNotification }) =>
      showNotification ? "-5px" : "-11px"};
  }
  height: 24px;
  width: 24px;
  ${({ showNotification }) =>
    showNotification
      ? css`
          ::after {
            background-color: ${colors.danger};
            height: 6px;
            width: 6px;
            border-radius: 50%;
            position: relative;
            top: -8px;
            left: -4px;
            display: inline-flex;
            content: "";
          }
        `
      : ""}
`;

export const Title = styled.div`
  margin-top: 24px;
  font-weight: 500;
  display: inline-block;
`;

export const NameContainer = styled.div`
  padding-bottom: 24px;
  display: flex;
  border-bottom: 1px solid ${colors.antiFlashWhite};
`;

export const NameLabel = styled.div`
  font-weight: 500;
  flex-grow: 0.5;
`;
