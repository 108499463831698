import flatten from "lodash/flatten";
import isObject from "lodash/isObject";
import moment from "moment-timezone";

import { BERLIN_TIMEZONE } from "../../common/helpers";
import { ChangeLog } from "../../../api/graphql";

const OBJECT_FIELDS = ["fields", "inputs", "calculationResults"];

const getValue = (value: any) => {
  if (isObject(value)) {
    return JSON.stringify(value);
  }

  if (value === "") {
    return '" "';
  }

  return value;
};

export const SUBTEXT_MODELS = [
  "transactions_meta",
  "kontax_transaction_meta",
  "transaction_split",
  "asset",
];

export const constructChangeLogTexts = (
  changes: Record<string, any>,
  modelName: string
) => {
  const subText = SUBTEXT_MODELS.includes(modelName) ? ` (${modelName})` : "";
  if (changes.__created) {
    return [`Create new item${subText}: ${getValue(changes.__created)}`];
  }
  if (changes.__deletedAt) {
    return [`Delete item${subText} at: ${formatDateTime(changes.__deletedAt)}`];
  }

  return flatten(
    Object.entries(changes).map(([key, value]) => {
      // special case
      if (OBJECT_FIELDS.includes(key)) {
        return Object.keys(value).map((innerKey) => {
          // update a field
          if (!innerKey.includes("__")) {
            const innerValue = value[innerKey];
            return `edit "${innerKey}": from ${getValue(
              innerValue.__old
            )} to ${getValue(innerValue.__new)}`;
          }
          const objKey = innerKey.split("__")[0];
          // add new field
          if (innerKey.includes("__added")) {
            return `add "${objKey}": ${getValue(value[innerKey])}`;
          }
          // delete a field
          if (innerKey.includes("__deleted")) {
            return `delete "${objKey}": ${getValue(value[innerKey])}`;
          }

          return null;
        });
      }

      if (value.__new !== undefined) {
        return `Edit "${key}": from ${getValue(value.__old)} to ${getValue(
          value.__new
        )}`;
      }
      if (value.__added !== undefined) {
        return `Add "${key}": ${getValue(value.__added)}`;
      }
      if (key.includes("__added")) {
        return `Add "${key.split("__")[0]}": ${getValue(value)}`;
      }
      if (value.__deleted !== undefined) {
        return `Delete "${key}": ${getValue(value.__deleted)}`;
      }
      if (key.includes("__deleted")) {
        return `Delete "${key.split("__")[0]}": ${getValue(value)}`;
      }

      return null;
    })
  );
};

export const formatDateTime = (date: Date | string) =>
  moment(date).tz(BERLIN_TIMEZONE).format("DD.MM.YYYY HH:mm:ss");

export const mergeChangelogs = (objs: Record<string, any>[]) => {
  return flatten(
    objs.map(({ changeLogs, subText }) =>
      changeLogs?.map((log: Record<string, any>) => ({
        ...log,
        ...(subText ? { subText } : {}),
      }))
    )
  )
    .filter((log) => log != null)
    .sort((logA, logB) =>
      logA!.changedAt > logB!.changedAt ? -1 : 1
    ) as ChangeLog[];
};

// if selectedFields exists, only display changeLogs for these fields.
export const filterSelectedFields = ({
  changeLogs,
  selectedFields,
}: {
  changeLogs?: ChangeLog[];
  selectedFields?: string[];
}) => {
  if (!changeLogs) {
    return [];
  }

  if (!selectedFields) {
    return changeLogs;
  }

  return changeLogs.filter(({ changes }) => {
    const fields = [
      ...Object.keys(changes),
      ...Object.keys(changes.__created || {}),
    ];
    return selectedFields.some((selectedField) =>
      fields.includes(selectedField)
    );
  });
};
