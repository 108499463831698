import React from "react";

import { SpinningEmoji, SpinnerWrapper } from "./styledComponents";
import { ReactComponent as SpinnerPurple } from "../../svgs/spinner.svg";

export const SpinnerBasic = ({ className = "" }) => (
  <SpinnerWrapper className={className}>
    <SpinningEmoji>
      <SpinnerPurple />
    </SpinningEmoji>
  </SpinnerWrapper>
);
