import isNumber from "lodash/isNumber";
import mapValues from "lodash/mapValues";
import mergeWith from "lodash/mergeWith";
import pickBy from "lodash/pickBy";

import { EuerDeclarationFields } from "../api/graphql";
import { roundCents } from "./amount";

const REVENUE_FIELDS = ["102", "103", "106", "108", "111", "112", "140", "141"];

const EXPENSES_FIELDS = [
  "100",
  "110",
  "120",
  "130",
  "131",
  "132",
  "135",
  "136",
  "144",
  "145",
  "146",
  "147",
  "150",
  "171",
  "172",
  "174",
  "175",
  "176",
  "177",
  "183",
  "185",
  "186",
  "194",
  "221",
  "222",
  "223",
  "224",
  "225",
  "226",
  "227",
  "228",
  "229",
  "232",
  "234",
  "280",
  "281",
];

const EXPENSES_DEDUCTION_FIELDS = ["142"];

export const TOTAL_FIELDS = ["159", "199", "219"];

const sumFields = (
  fields: EuerDeclarationFields,
  summands: Array<string>
): number => {
  const total = summands.reduce((sum: number, field: string) => {
    sum += fields[field] || 0;
    return sum;
  }, 0);
  return roundCents(total);
};

export const recalculateTotalFields = (
  fields: EuerDeclarationFields
): EuerDeclarationFields => {
  const totalRevenue = sumFields(fields, REVENUE_FIELDS);
  const totalExpenses =
    sumFields(fields, EXPENSES_FIELDS) -
    sumFields(fields, EXPENSES_DEDUCTION_FIELDS);
  const grandTotal = totalRevenue - totalExpenses;
  return {
    ...fields,
    "159": totalRevenue,
    "199": totalExpenses,
    "219": grandTotal,
  };
};

const mergeAndSumValues = (
  fieldsList: EuerDeclarationFields[]
): EuerDeclarationFields => {
  return mergeWith({}, ...fieldsList, (a: number, b: number) => {
    if (isNumber(a) && isNumber(b)) {
      return a + b; // sum values with same ELSTER code
    }

    return undefined; // use default merge strategy
  });
};

export const extractFieldAdjustments = (
  combinedFields: EuerDeclarationFields,
  autoCalculatedFields: EuerDeclarationFields
): EuerDeclarationFields => {
  const negatedAutoCalculatedFields = mapValues(
    autoCalculatedFields,
    (value) => -value
  );
  const adjustments = mergeAndSumValues([
    combinedFields,
    negatedAutoCalculatedFields,
  ]);
  const roundedAdjustments = mapValues(adjustments, roundCents);
  // Return non empty adjustments
  return pickBy(
    roundedAdjustments,
    (value, field) => value !== 0 && !TOTAL_FIELDS.includes(field)
  );
};

export const combineEuerFields = (
  fieldsList: EuerDeclarationFields[]
): EuerDeclarationFields => {
  const mergedFields = mergeAndSumValues(fieldsList);
  const fieldsWithRecalculatedTotals = recalculateTotalFields(mergedFields);
  return mapValues(fieldsWithRecalculatedTotals, roundCents);
};
