import React, { useCallback, useEffect, useState } from "react";
import { DataNode } from "rc-tree/lib/interface";
import { Button, notification, Typography, Upload } from "antd";
import Dragger from "antd/lib/upload/Dragger";
import { UploadOutlined } from "@ant-design/icons";

import { DocumentViewerContent } from "./styledComponent";
import { DocumentCategory } from "../../../../../../api/graphql/types";
import {
  Categories,
  Document,
  Folders,
  IUser,
  IUserDetails,
  OnbStatus,
} from "../../../../../../types";
import DocumentViewer, {
  SUPPORTED_DOCUMENT_PREVIEW_TYPES,
} from "../DocumentViewer";
import DocumentViewerFields from "../DocumentViewerFields";
import { HorizontalLine } from "../styledComponents";
import UserTaxNumbersList from "../TaxNumberList/TaxNumbersList";
import DocumentTree from "./DocumentTree";
import { SpinnerBasic } from "../../../../../common/Spinner";
import api from "../../../../../../api";

const { Text } = Typography;

export const DOCUMENT_TYPES_FOR_UPLOAD = [
  "doc",
  "docx",
  "odt",
  "ods",
  "csv",
  "xls",
  "xlsx",
]
  .concat(SUPPORTED_DOCUMENT_PREVIEW_TYPES)
  .map((type) => `.${type}`)
  .join(", ");

enum TreeNodeClassName {
  CATEGORY_NODE = "category-node",
  FOLDER_NODE = "folder-node",
  DOCUMENT_NODE = "document-node",
  UPLOAD_NODE = "upload-node",
}

interface DocumentCategoryFilesProps {
  categories: DocumentCategory[];
  documents: Document[];
  user: IUser;
  editUser: (email: string, userDetails: IUserDetails) => Promise<void>;
  addUploadedDocuments: (newDocuments: Document[]) => void;
  allowUpload?: boolean;
  year?: number;
  setSelectedDocumentIds?: (ids: string[]) => void;
  onDeleteDocument: (documentId: string) => void;
}

const DocumentCategoryFiles = ({
  categories,
  documents,
  user,
  editUser,
  addUploadedDocuments,
  allowUpload,
  year,
  setSelectedDocumentIds,
  onDeleteDocument,
}: DocumentCategoryFilesProps) => {
  const [isUploading, setIsUploading] = useState(false);
  const [droppedFiles, setDroppedFiles] = useState<File[]>([]);
  const [droppedCategoryId, setDroppedCategoryId] = useState<
    string | null | undefined
  >(null);

  const onDropFiles = useCallback(
    async (files: File[]) => {
      setIsUploading(true);
      const uploadedDocuments = [];

      for (const file of files) {
        try {
          const uploadedDocument = await api.kontax.uploadDocument(
            user.email,
            file,
            droppedCategoryId,
            year
          );

          const documentExist = documents.some(
            (doc) => doc.id === uploadedDocument.id
          );

          if (documentExist) {
            notification.error({
              message: `Document already exists! (${file.name})`,
            });
          } else {
            uploadedDocuments.push(uploadedDocument);
            notification.success({
              message: `File ${file.name} uploaded`,
            });
          }
        } catch (error) {
          const errMessage = (error as Error)?.message;
          notification.error({
            message: `An error occurred while uploading ${file.name}: (${errMessage})`,
          });
        }
      }

      setIsUploading(false);
      if (uploadedDocuments.length) {
        addUploadedDocuments(uploadedDocuments);
      }
    },
    [user.email, droppedCategoryId, year, documents, addUploadedDocuments]
  );

  useEffect(() => {
    if (droppedFiles.length) {
      onDropFiles(droppedFiles);
      setDroppedFiles([]);
    }
  }, [droppedFiles, onDropFiles]);

  // This function together with useEffect hook is a workaround to make it possible
  // to call onDropFiles only once with all files together.
  const beforeUpload = (files: File[], categoryId?: string | null) => {
    setDroppedFiles(files);
    setDroppedCategoryId(categoryId);
    return Upload.LIST_IGNORE;
  };

  const getDocumentsByCategoryId = (documentCategoryId?: string | null) => {
    return documents.filter(
      (document) =>
        (!documentCategoryId && !document.metadata) ||
        document.metadata?.category.id === documentCategoryId
    );
  };

  const getDocumentNodes = (documentCategoryId?: string | null) => {
    return getDocumentsByCategoryId(documentCategoryId).map((doc) => {
      return {
        title: getDocumentNodeTitle(
          doc,
          user,
          editUser,
          !documentCategoryId,
          onDeleteDocument
        ),
        key: doc.id,
        className: TreeNodeClassName.DOCUMENT_NODE,
        isLeaf: true,
        url: doc.url,
      };
    });
  };

  const getFolderNodes = (categoryName: string) => {
    return categories
      .filter((cat) => cat.categoryName === categoryName)
      .map((category) => {
        return {
          title: Folders[category.folderName],
          key: category.folderName,
          className: TreeNodeClassName.FOLDER_NODE,
          children: [
            ...getDocumentNodes(category?.id),
            ...(allowUpload
              ? [
                  {
                    title: (
                      <Dragger
                        // The normal document nodes have a width of 876px, plus a
                        // 1px border on both sides.
                        style={{ width: "calc(876px + 2px)" }}
                        multiple
                        withCredentials={false}
                        accept={DOCUMENT_TYPES_FOR_UPLOAD}
                        showUploadList={false}
                        beforeUpload={(_: File, files: File[]) =>
                          beforeUpload(files, category?.id)
                        }
                        data-test={`documentUploadArea_${category?.folderName}`}
                      >
                        {isUploading ? (
                          <SpinnerBasic />
                        ) : (
                          <p>Drag and drop documents here to upload</p>
                        )}
                        <Button
                          style={{
                            display: "inline-flex",
                            marginTop: "10px",
                            alignItems: "center",
                          }}
                          icon={<UploadOutlined />}
                        >
                          Upload
                        </Button>
                      </Dragger>
                    ),
                    key: category.folderName + "-UPLOAD",
                    className: TreeNodeClassName.UPLOAD_NODE,
                    isLeaf: true,
                    url: "about:blank",
                  },
                ]
              : []),
          ],
          isLeaf: false,
        };
      });
  };

  const getTreeData = (categoryName: string): DataNode[] => {
    return [
      {
        title: <Text strong>{Categories[categoryName]}</Text>,
        key: categoryName,
        className: TreeNodeClassName.CATEGORY_NODE,
        children: getFolderNodes(categoryName),
        isLeaf: false,
        selectable: false,
      },
    ];
  };

  return (
    <>
      {Array.from(
        new Set(categories.map((cat: DocumentCategory) => cat.categoryName))
      ).map((categoryName) => {
        return (
          <DocumentTree
            setSelectedDocumentIds={setSelectedDocumentIds}
            key={categoryName}
            categoryName={categoryName}
            getTreeData={() => getTreeData(categoryName)}
          />
        );
      })}
    </>
  );
};

function getDocumentNodeTitle(
  document: Document,
  user: IUser,
  editUser: (email: string, userDetails: IUserDetails) => Promise<void>,
  isOtherCategory: boolean,
  onDeleteDocument: (documentId: string) => void
) {
  const isOnboardingCompleted =
    user.kontaxUser?.onbStatus === OnbStatus.COMPLETED;

  return (
    <DocumentViewer
      document={document}
      email={isOnboardingCompleted ? undefined : user.email}
      onDeleteDocument={isOnboardingCompleted ? undefined : onDeleteDocument}
    >
      {!isOtherCategory && (
        <DocumentViewerContent>
          <DocumentViewerFields user={user} editUser={editUser} />
          <HorizontalLine />
          <UserTaxNumbersList user={user} />
        </DocumentViewerContent>
      )}
    </DocumentViewer>
  );
}

export default DocumentCategoryFiles;
