import styled from "styled-components";
import { Modal } from "antd";

export const StyledModal = styled(Modal)`
  .ant-modal-header {
    border: none;
    padding-bottom: 0;
  }
  .ant-modal-body {
    padding-top: 0;
    padding-bottom: 0;
    border: none;
    > p {
      padding-left: 40px;
    }
  }
  .ant-modal-footer {
    border: none;
  }
  .ant-modal-title {
    padding-top: 30px;
    font-weight: bold;
    p {
      padding-left: 10px;
      display: inline-block;
    }
  }
  .ant-modal-close {
    display: none;
  }
`;
