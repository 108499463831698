import { ReactNode, useState } from "react";
import CopyOutlined from "@ant-design/icons-svg/inline-namespaced-svg/outlined/copy.svg";
import CheckCircleOutlined from "@ant-design/icons-svg/inline-namespaced-svg/outlined/check-circle.svg";
import styled, { css, keyframes } from "styled-components";

import colors from "../../themes/colors";

const ICON_SIZE = "14px";
const FADE_OUT_ANIMATION = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

type ContainerProps = {
  ellipsis?: boolean;
  justCopied: boolean;
  withFixedIcon?: boolean;
};

const Container = styled.span<ContainerProps>`
  ::before,
  ::after {
    ${({ withFixedIcon, justCopied }) =>
      withFixedIcon
        ? css`
            background-color: ${justCopied
              ? colors.darkLimeGreen
              : colors.midGrey};
          `
        : css`
            background-color: ${justCopied
              ? colors.darkLimeGreen
              : colors.purple};
            opacity: 0;
          `}
    content: "";
    height: ${ICON_SIZE};
    // mask-image (instead of content or background-image) allows us to change
    // the SVG’s color. See https://stackoverflow.com/a/46904983.
    mask-image: url(${({ justCopied }) =>
      justCopied ? CheckCircleOutlined : CopyOutlined});
    mask-repeat: no-repeat;
    transition: opacity 0.1s;
    width: ${ICON_SIZE};

    ${({ justCopied }) =>
      justCopied &&
      css`
        animation-delay: 1.8s;
        animation-duration: 0.2s;
        animation-fill-mode: both;
        animation-name: ${FADE_OUT_ANIMATION};
      `}
  }

  ${({ ellipsis }) =>
    ellipsis
      ? css`
          ::before {
            display: inline;
            float: right;
            margin-top: 0.25em;
          }
        `
      : css`
          ::after {
            display: inline;
            margin-left: 0.5ch;
            padding-right: ${ICON_SIZE};
            vertical-align: -0.125em;
          }
        `}

  &:hover {
    cursor: pointer;

    ::before,
    ::after {
      ${({ withFixedIcon, justCopied }) =>
        withFixedIcon
          ? css`
              background-color: ${() =>
                justCopied ? colors.darkLimeGreen : colors.purple};
            `
          : css`
              opacity: 1;
              transition-duration: 0.2s;
            `}
    }
  }
`;

const Copyable = ({
  text,
  ellipsis,
  children,
  withFixedIcon,
}: {
  text?: string;
  ellipsis?: boolean;
  children: ReactNode;
  withFixedIcon?: boolean;
}) => {
  const [justCopied, setJustCopied] = useState<boolean>(false);

  if (!children) {
    return null;
  }

  const handleClick = (event: React.MouseEvent) => {
    event.stopPropagation();

    const newClipText = text ?? children.toString();
    navigator.clipboard.writeText(newClipText);

    setJustCopied(true);
  };

  const handleAnimationEnd = () => {
    setJustCopied(false);
  };

  return (
    <Container
      ellipsis={ellipsis}
      justCopied={justCopied}
      onClick={handleClick}
      onAnimationEnd={handleAnimationEnd}
      withFixedIcon={withFixedIcon}
      data-test="copyableCell"
    >
      {children}
    </Container>
  );
};

export const WithCopyable = ({
  copyable,
  text,
  ellipsis,
  children,
  withFixedIcon,
}: {
  copyable?: boolean;
  text?: string;
  ellipsis?: boolean;
  children: ReactNode;
  withFixedIcon?: boolean;
}) => {
  return copyable ? (
    <Copyable text={text} ellipsis={ellipsis} withFixedIcon={withFixedIcon}>
      {children}
    </Copyable>
  ) : (
    <>{children}</>
  );
};

export default Copyable;
