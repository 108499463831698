import { gql, QueryHookOptions, useQuery } from "@apollo/client";

export interface EuerDraftPdfQueryVariables {
  payload: {
    email: string;
    year: number;
  };
}

export interface EuerDraftPdfQueryResult {
  getEuerDraftPdf: {
    processResult: string;
    pdf: string;
    message: string;
  };
}

export const EUER_DRAFT_PDF_QUERY = gql`
  query getEuerDraftPdf($payload: EuerProcessingRequest!) {
    getEuerDraftPdf(payload: $payload) {
      processResult
      pdf
      message
    }
  }
`;

export const useEuerDraftPdfQuery = (
  options?: QueryHookOptions<
    EuerDraftPdfQueryResult,
    EuerDraftPdfQueryVariables
  >
) =>
  useQuery<EuerDraftPdfQueryResult, EuerDraftPdfQueryVariables>(
    EUER_DRAFT_PDF_QUERY,
    options
  );
