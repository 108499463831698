import { PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React, { useState, useCallback, useEffect } from "react";

import api from "../../../api";
import { useRequest } from "../../hooks/useRequest.hook";
import RulesTable from "./RulesTable";
import { IRule } from "../../../types";
import EmptyWrapper from "../../common/EmptyWrapper";
import useEmailParam from "../../hooks/useEmailParam";
import Header from "../BelegeView/Header";
import { getFilteredRules } from "./helpers";
import { RulesViewHeader } from "./styledComponents";
import { ContentWrapper } from "../../common/styledComponents";

const RulesView = () => {
  const [rules, setRules] = useState<Array<IRule> | null>(null);
  const [filteredRules, setFilteredRules] = useState<Array<IRule>>([]);
  const [error, setError] = useState<string | null>(null);
  const [email, setEmailParam] = useEmailParam() as [string, Function];
  const [accountId, setAccountId] = useState("");

  const fetchAutocategorizationRules = useCallback(async () => {
    try {
      const { rules } = await api.kontax.getAutocategorizationRules();

      setRules(rules);
      setError(null);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("Error fetching rules", error);
      setError("Error finding rule");
      setRules([]);
    }
  }, []);

  const updateAutocategorizationRule = useCallback(
    async (id: number, enabled: boolean) => {
      try {
        await api.kontax.updateAutocategorizationRule({
          id: id,
          enabled: enabled,
        });
        const { rules } = await api.kontax.getAutocategorizationRules();
        setRules(rules);
      } catch (error) {
        throw error;
      }
    },
    []
  );

  const getAccountId = useCallback(async (email) => {
    try {
      if (email) {
        const accountId = await api.kontax.getAccountIdByEmail(email);
        setAccountId(accountId);
      } else {
        setAccountId("");
      }
    } catch (e) {
      setAccountId("");
    }
  }, []);

  useEffect(() => {
    getAccountId(email);
  }, [email, getAccountId]);

  useEffect(() => {
    if (accountId && rules) {
      setFilteredRules(getFilteredRules(rules, accountId));
    }
  }, [accountId, rules]);

  useRequest(fetchAutocategorizationRules);

  const results = accountId ? filteredRules : rules;

  return (
    <ContentWrapper>
      <RulesViewHeader>
        <Header setEmail={setEmailParam} email={email} />
        <Button
          icon={<PlusOutlined />}
          onClick={() => window.open("/rules-view/add")?.focus()}
        >
          Add Rule
        </Button>
      </RulesViewHeader>
      {error && <p>{error}</p>}
      {results?.length ? (
        <RulesTable
          rules={results}
          updateAutocategorizationRule={updateAutocategorizationRule}
        />
      ) : (
        <EmptyWrapper description="No rules found" />
      )}
    </ContentWrapper>
  );
};

export default RulesView;
