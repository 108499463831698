import React from "react";
import moment from "moment";

import { VatDeclaration } from "../../../../../types";
import api from "../../../../../api";
import { formatAmountInCents } from "../../../../../utils";
import { ReactComponent as Icon } from "../../../../../svgs/download.svg";
import { ClickableTd, StyledTable, H3 } from "../../styledComponents";

const PastDeclarations = ({
  pastDeclarations,
  email,
}: {
  pastDeclarations: VatDeclaration[];
  email: string;
}) => {
  const openPdf = async (email: string, declarationId: string) => {
    const openedWindow = window.open();
    try {
      const PDF = await api.kontax.getDeclarationPdf(email, declarationId);
      if (openedWindow) {
        openedWindow.location.href = PDF;
      }
      setTimeout(function () {
        URL.revokeObjectURL(PDF);
      }, 3000);
    } catch (err) {
      if (openedWindow) openedWindow.close();
    }
  };

  return (
    <>
      <H3>Past declarations</H3>
      <StyledTable>
        <thead>
          <tr>
            <td>Amount</td>
            <td>Time period</td>
            <td>Uploaded on</td>
            <td>Declaration</td>
          </tr>
        </thead>
        <tbody>
          {pastDeclarations.map((declaration) => (
            <tr key={declaration.id}>
              <td>{formatAmountInCents(declaration.amount, true)}</td>
              <td>
                {declaration.period}, {declaration.year}
              </td>
              <td>{moment(declaration.uploadedAt).format("DD/MM/YYYY")}</td>
              <ClickableTd onClick={() => openPdf(email, declaration.id)}>
                <Icon />
                {` Open`}
              </ClickableTd>
            </tr>
          ))}
        </tbody>
      </StyledTable>
    </>
  );
};

export default PastDeclarations;
