import { Form, Switch } from "antd";
import { NamePath } from "antd/lib/form/interface";

import { DEFAULT_LOCALE } from "../../../constants";

type RuleStatusSwitchProps = {
  name?: NamePath;
  appliedTimesCount?: number;
  initialEnabled?: boolean;
};

export const RuleStatusSwitch = ({
  name,
  appliedTimesCount = 0,
  initialEnabled = false,
}: RuleStatusSwitchProps) => {
  return (
    <Form.Item
      name={name}
      label="Rule status"
      extra={displayExtraMessage(appliedTimesCount, initialEnabled)}
      valuePropName="checked"
      required
    >
      <Switch checkedChildren="ON" unCheckedChildren="OFF" />
    </Form.Item>
  );
};

function displayExtraMessage(
  appliedTimesCount: number,
  initialEnabled: boolean
): string {
  const formattedCount = new Intl.NumberFormat(DEFAULT_LOCALE).format(
    appliedTimesCount
  );
  const formattedChecked = initialEnabled ? "off" : "on";
  return `This rule has been applied to ${formattedCount} transactions. Turn it ${formattedChecked} for future transactions.`;
}
