import React, { MouseEventHandler } from "react";
import { Button } from "antd";
import { EditOutlined } from "@ant-design/icons";

const EditExternalTransactionButton = ({
  onClick,
  isDisabled,
}: {
  onClick: MouseEventHandler<HTMLButtonElement>;
  isDisabled: boolean;
}) => {
  return (
    <>
      <Button
        icon={<EditOutlined />}
        size="large"
        style={{ display: "flex", alignItems: "center" }}
        onClick={onClick}
        disabled={isDisabled}
      >
        Edit transaction
      </Button>
    </>
  );
};

export default EditExternalTransactionButton;
