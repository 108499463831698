import { DeleteFilled, DeleteOutlined, WarningFilled } from "@ant-design/icons";
import {
  Button,
  Form,
  message,
  notification,
  Popconfirm,
  Space,
  Typography,
} from "antd";
import { useHistory } from "react-router-dom";

import {
  useDeleteAutocategorizationRuleMutation,
  useUpdateAutocategorizationRuleMutation,
} from "../../../api/graphql/mutations/autocategorizationRule";
import { useAutocategorizationRuleQuery } from "../../../api/graphql/queries/autocategorizationRule";
import { IMessageType } from "../../../types";
import { showMessage } from "../../../utils";
import ActionLogDrawerAsync from "../../common/ActionLogDrawerAsync";
import { ContentWrapper } from "../../common/styledComponents";
import RuleForm from "./RuleForm";
import { RuleStatusSwitch } from "./RuleStatusSwitch";
import { FormHeading } from "./styledComponents";

export type EditRuleProps = {
  id: number;
};

export const EditRule = ({ id }: EditRuleProps) => {
  const history = useHistory();

  const autocategorizationRuleResult = useAutocategorizationRuleQuery({
    variables: { id },
    onCompleted: (data) => {
      if (data.autocategorizationRule === null) {
        showMessage({
          type: IMessageType.ERROR,
          duration: 3,
          content: `Rule #${id} not found`,
        });
        history?.push("/rules-view");
      }
    },
    onError: (error) => {
      notification.error({
        message: `Failed fetching Rule #${id}`,
        description: error.message,
      });
      history?.push("/rules-view");
    },
  });

  const rule = autocategorizationRuleResult.data?.autocategorizationRule;

  const [updateRule, updateRuleResult] =
    useUpdateAutocategorizationRuleMutation({
      onCompleted: () => {
        showMessage({
          type: IMessageType.SUCCESS,
          duration: 3,
          content: "Rule saved",
        });
        window.opener?.location.reload();
        window.close();
      },
      onError: (error) => {
        notification.error({
          message: `Failed updating Rule #${rule?.id}`,
          description: error.message,
        });
      },
    });

  const [deleteRule, deleteRuleResult] =
    useDeleteAutocategorizationRuleMutation({
      onCompleted: () => {
        message.info({
          duration: 3,
          content: `Rule #${rule?.id} has been deleted`,
          icon: <DeleteFilled />,
        });
        window.opener?.location.reload();
        window.close();
      },
      onError: (error) => {
        notification.error({
          message: `Failed deleting Rule #${rule?.id}`,
          description: error.message,
        });
      },
    });

  return (
    <ContentWrapper padding="0">
      <RuleForm
        rule={rule ?? undefined}
        title={
          <Space align="center">
            <FormHeading>Rule #{rule?.id}</FormHeading>
            <Typography.Text type="secondary">Edit</Typography.Text>
          </Space>
        }
        loading={
          autocategorizationRuleResult.loading ||
          updateRuleResult.loading ||
          deleteRuleResult.loading
        }
        onSubmit={(updatedRule) => {
          if (rule?.id) {
            updateRule({
              variables: {
                input: {
                  id: rule.id,
                  ...updatedRule,
                },
              },
            });
          }
        }}
      >
        <RuleStatusSwitch
          name="enabled"
          appliedTimesCount={rule?.appliedTimesCount || undefined}
          initialEnabled={rule?.enabled}
        />

        <Form.Item>
          <ActionLogDrawerAsync
            title="Action log"
            modelName="auto_categorization_rule"
            recordIds={[`${id}`]}
            renderButton={({ onClick }) => (
              <Button onClick={onClick}>View logs</Button>
            )}
          />
        </Form.Item>

        <Form.Item label="Delete rule" required>
          <Popconfirm
            title="Are you sure you want to delete this rule?"
            cancelText="No"
            okText="Yes, delete"
            icon={<WarningFilled style={{ color: "red" }} />}
            onConfirm={() => {
              if (rule?.id) {
                deleteRule({
                  variables: {
                    id: rule.id,
                  },
                });
              }
            }}
          >
            <Button icon={<DeleteOutlined />} danger>
              Delete
            </Button>
          </Popconfirm>
        </Form.Item>
      </RuleForm>
    </ContentWrapper>
  );
};
