import type * as Types from "../../schema.generated";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type DeleteKontaxNoteMutationVariables = Types.Exact<{
  id: Types.Scalars["Int"];
}>;

export type DeleteKontaxNoteMutation = {
  __typename?: "Mutation";
  deleteKontaxNote: { __typename?: "MutationResult"; success: boolean };
};

export const DeleteKontaxNoteDocument = gql`
  mutation deleteKontaxNote($id: Int!) {
    deleteKontaxNote(id: $id) {
      success
    }
  }
`;
export type DeleteKontaxNoteMutationFn = Apollo.MutationFunction<
  DeleteKontaxNoteMutation,
  DeleteKontaxNoteMutationVariables
>;

/**
 * __useDeleteKontaxNoteMutation__
 *
 * To run a mutation, you first call `useDeleteKontaxNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteKontaxNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteKontaxNoteMutation, { data, loading, error }] = useDeleteKontaxNoteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteKontaxNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteKontaxNoteMutation,
    DeleteKontaxNoteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteKontaxNoteMutation,
    DeleteKontaxNoteMutationVariables
  >(DeleteKontaxNoteDocument, options);
}
export type DeleteKontaxNoteMutationHookResult = ReturnType<
  typeof useDeleteKontaxNoteMutation
>;
export type DeleteKontaxNoteMutationResult =
  Apollo.MutationResult<DeleteKontaxNoteMutation>;
export type DeleteKontaxNoteMutationOptions = Apollo.BaseMutationOptions<
  DeleteKontaxNoteMutation,
  DeleteKontaxNoteMutationVariables
>;
