import { Tag, Tooltip } from "antd";

import colors from "../../../../themes/colors";
import { allCategoriesRaw, vatCategories } from "../../../../utils";

export enum TransactionSubmissionState {
  SUBMITTED = "SUBMITTED",
  NOT_SUBMITTED = "NOT_SUBMITTED",
  FIBU_CHANGED = "FIBU_CHANGED",
  VCC_CHANGED = "VCC_CHANGED",
  MULTIPLE_CHANGES = "MULTIPLE_CHANGES",
  UNKNOWN = "UNKNOWN",
  SPLIT = "SPLIT",
}

export const getSubmissionStatus = (record: Record) => {
  const {
    vatCategoryCode,
    categoryCode,
    submittedCategoryCode,
    submittedVatCategoryCode,
  } = record;

  if (record.splits?.length) {
    return TransactionSubmissionState.SPLIT;
  }

  if (!submittedCategoryCode && !submittedVatCategoryCode) {
    return TransactionSubmissionState.NOT_SUBMITTED;
  }

  if (
    submittedCategoryCode === categoryCode &&
    submittedVatCategoryCode === vatCategoryCode
  ) {
    return TransactionSubmissionState.SUBMITTED;
  }

  if (
    submittedCategoryCode !== categoryCode &&
    submittedVatCategoryCode !== vatCategoryCode
  ) {
    return TransactionSubmissionState.MULTIPLE_CHANGES;
  }

  if (submittedCategoryCode !== categoryCode) {
    return TransactionSubmissionState.FIBU_CHANGED;
  }

  if (submittedVatCategoryCode !== vatCategoryCode) {
    return TransactionSubmissionState.VCC_CHANGED;
  }

  return TransactionSubmissionState.UNKNOWN;
};

export const getTagColor = (status: TransactionSubmissionState) => {
  switch (status) {
    case TransactionSubmissionState.UNKNOWN:
      return colors.darkRed;
    case TransactionSubmissionState.NOT_SUBMITTED:
    case TransactionSubmissionState.SPLIT:
      return "default";
    case TransactionSubmissionState.SUBMITTED:
      return "success";
    default:
      return "orange";
  }
};

export const statusToTitle = (status: TransactionSubmissionState) => {
  switch (status) {
    case TransactionSubmissionState.UNKNOWN:
      return "Unknown";
    case TransactionSubmissionState.NOT_SUBMITTED:
      return "Not submitted";
    case TransactionSubmissionState.SUBMITTED:
      return "Submitted";
    case TransactionSubmissionState.FIBU_CHANGED:
      return "Fibu changed";
    case TransactionSubmissionState.VCC_CHANGED:
      return "VCC changed";
    case TransactionSubmissionState.MULTIPLE_CHANGES:
      return "Fibu & VCC changed";
    case TransactionSubmissionState.SPLIT:
      return "Split";
    default:
      return "";
  }
};

const translateCategoryCode = (code?: string | undefined | null) => {
  return allCategoriesRaw.find(([value]) => value === code)?.[1] || code;
};

const translateVatCategoryCode = (code?: string | undefined | null) => {
  return vatCategories.find(([value]) => value === code)?.[1] || code;
};

export const getTooltipTitle = (
  status: TransactionSubmissionState,
  record: Record
) => {
  let tooltipTitle = "";

  if (status === TransactionSubmissionState.MULTIPLE_CHANGES) {
    tooltipTitle = `Fibu: ${translateCategoryCode(
      record.submittedCategoryCode
    )} -> ${translateCategoryCode(
      record.categoryCode
    )} | VCC: ${translateVatCategoryCode(
      record.submittedVatCategoryCode
    )} -> ${translateVatCategoryCode(record.vatCategoryCode)}`;
  } else if (status === TransactionSubmissionState.FIBU_CHANGED) {
    tooltipTitle = `Fibu: ${translateCategoryCode(
      record.submittedCategoryCode
    )} -> ${translateCategoryCode(record.categoryCode)}`;
  } else if (status === TransactionSubmissionState.VCC_CHANGED) {
    tooltipTitle = `VCC: ${translateVatCategoryCode(
      record.submittedVatCategoryCode
    )} -> ${translateVatCategoryCode(record.vatCategoryCode)}`;
  } else if (status === TransactionSubmissionState.SPLIT) {
    tooltipTitle = "Split transaction. Please expand + for more details";
  }

  return tooltipTitle;
};

interface Record {
  categoryCode?: string | null | undefined;
  vatCategoryCode?: string | null | undefined;
  submittedCategoryCode?: string | null | undefined;
  submittedVatCategoryCode?: string | null | undefined;
  splits?: any[];
}

const TransactionSubmissionStatus = ({ record }: { record: Record }) => {
  const status = getSubmissionStatus(record);
  const color = getTagColor(status);
  const title = statusToTitle(status);
  const tooltipTitle = getTooltipTitle(status, record);
  const tag = <Tag color={color}>{title}</Tag>;

  if (tooltipTitle) {
    return (
      <Tooltip title={tooltipTitle} placement="topLeft">
        {tag}
      </Tooltip>
    );
  }

  return tag;
};

export default TransactionSubmissionStatus;
