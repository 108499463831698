import React, { ReactNode } from "react";
import { Collapse } from "antd";
import DownloadOutlined from "@ant-design/icons/lib/icons/DownloadOutlined";

import { Document } from "../../../../../../types";
import {
  StyledCollapse,
  TitleWrapper,
  TitleContent,
  TitleIcon,
  Title,
  Description,
  DownloadButton,
} from "./styledComponent";
import LocalAssetsViewer from "../../../../../common/LocalAssetsViewer";
import { downloadUsingAnchorElement } from "../../../../../../utils/download";

export const SUPPORTED_DOCUMENT_PREVIEW_TYPES = [
  "png",
  "jpg",
  "jpeg",
  "tiff",
  "pdf",
];

const DocumentViewer = ({
  document,
  children,
  defaultActiveKey,
  email,
  onDeleteDocument,
}: {
  document: Document;
  children?: ReactNode;
  defaultActiveKey?: string[];
  email?: string;
  onDeleteDocument?: (documentId: string) => void;
}) => {
  return (
    <StyledCollapse bordered={false} defaultActiveKey={defaultActiveKey}>
      <Collapse.Panel
        showArrow={false}
        header={
          <TitleWrapper>
            <TitleIcon />
            <TitleContent>
              <Title>{document.filename}</Title>
              <Description type="secondary">{document.createdAt}</Description>
            </TitleContent>
            <DownloadButton
              type="dashed"
              icon={<DownloadOutlined />}
              onClick={(event) =>
                downloadUsingAnchorElement({
                  url: document.downloadUrl,
                  fileName: document.filename,
                  event,
                })
              }
            >
              Download
            </DownloadButton>
          </TitleWrapper>
        }
        key={document.id}
      >
        {SUPPORTED_DOCUMENT_PREVIEW_TYPES.includes(document.type) && (
          <LocalAssetsViewer
            assets={[
              {
                id: document.id,
                fullsize: document.url,
                filetype: document.type,
              },
            ]}
            minHeight={400}
            maxHeight={400}
            email={email}
            onDeleteDocument={onDeleteDocument}
          />
        )}
        {children}
      </Collapse.Panel>
    </StyledCollapse>
  );
};

export default DocumentViewer;
