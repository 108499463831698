import React from "react";
import { Card, CardProps } from "antd";

const padding = "0 14px 0 0";

const FilterBoxCard = (props: CardProps) => (
  <Card
    size="small"
    bodyStyle={{ padding }}
    bordered={false}
    headStyle={{ border: "none", padding }}
    {...props}
  >
    {props.children}
  </Card>
);

export default FilterBoxCard;
