import React from "react";
import IBAN from "iban";
import { Form, Input, InputNumber, DatePicker } from "antd";
import { EuroOutlined } from "@ant-design/icons";

import CategorySelect from "../../TransactionView/CategorySelect";
import { ExternalTransactionModalContent } from "./styledComponents";
import LocalAssetsViewer from "../../../common/LocalAssetsViewer";
import { Asset, BusinessAssetForm, ILocalAsset } from "../../../../types";
import VatCategorySelect from "../../TransactionView/VatCategorySelect";
import Upload from "../../../common/Upload";
import { DirectionRadioButton } from "../../../common/DirectionRadioButton";
import ExternalBusinessAsset from "./ExternalBusinessAsset";
import { businessAssetValidator, disabledDateAfterToday } from "./utils";

type ExternalTransactionFormProps = {
  onDropFiles?: (files: File[]) => void;
  assets: Asset[];
  onChangeCategory: (categoryCode: string | null) => void;
  onVatCategorySelected: (category: string | null) => void;
  onAssetDelete?: (asset: Asset | ILocalAsset) => void;
  onChangeBusinessAsset: (businessAssetForm: BusinessAssetForm) => void;
};

const dateFormat = "DD.MM.YYYY";

const ExternalTransactionForm = ({
  onDropFiles,
  assets,
  onChangeCategory,
  onVatCategorySelected,
  onAssetDelete,
  onChangeBusinessAsset,
}: ExternalTransactionFormProps) => {
  const checkAmount = async (amount: number) => {
    if (amount === 0) {
      return Promise.reject(new Error("Amount can't be 0"));
    }
  };

  const assetsViewer = (
    <LocalAssetsViewer
      assets={assets}
      minHeight={600}
      onDelete={onAssetDelete}
    />
  );

  return (
    <ExternalTransactionModalContent>
      <Form.Item
        name="assets"
        rules={[{ required: true, message: "Please upload an invoice" }]}
        className="assets"
      >
        {onDropFiles ? (
          <Upload onDropFiles={onDropFiles} isInPreviewMode={!!assets.length}>
            {assetsViewer}
          </Upload>
        ) : (
          assetsViewer
        )}
      </Form.Item>

      <div>
        <Form.Item
          label="Type of transaction"
          name="direction"
          rules={[
            {
              required: true,
              message: "Please select type of transaction",
            },
          ]}
        >
          <DirectionRadioButton />
        </Form.Item>

        <Form.Item
          label="Amount"
          name="amount"
          rules={[
            {
              required: true,
              message: "Please input the amount",
            },
            {
              validator: (_, value) => checkAmount(value),
            },
          ]}
        >
          <InputNumber
            autoFocus
            addonAfter={<EuroOutlined />}
            placeholder="Enter amount"
            type="number"
            precision={2}
            controls={false}
            min={0}
            data-test="ExternalTransaction.amount"
          />
        </Form.Item>
        <Form.Item
          label="Payment date"
          name="paymentDate"
          rules={[{ required: true, message: "Please input the payment date" }]}
        >
          <DatePicker
            format={dateFormat}
            allowClear={false}
            disabledDate={disabledDateAfterToday}
          />
        </Form.Item>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.amount !== currentValues.amount
          }
        >
          {({ getFieldValue }) => (
            <div>
              <Form.Item
                label="Category"
                name="categoryCode"
                rules={[
                  { required: true, message: "Please select a category" },
                ]}
              >
                <CategorySelect
                  transactionAmount={getFieldValue("amount") || 1}
                  onChangeHandler={onChangeCategory}
                  style={{ width: "100%" }}
                />
              </Form.Item>
              {getFieldValue("businessAssetForm") != null && (
                <Form.Item
                  name="businessAssetForm"
                  rules={[{ validator: businessAssetValidator }]}
                >
                  <ExternalBusinessAsset
                    businessAssetForm={getFieldValue("businessAssetForm")}
                    categoryCode={getFieldValue("categoryCode")}
                    onChange={onChangeBusinessAsset}
                    isSubmitClicked={false}
                  />
                </Form.Item>
              )}
            </div>
          )}
        </Form.Item>

        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.amount !== currentValues.amount ||
            prevValues.category !== currentValues.category
          }
        >
          {({ getFieldValue }) => (
            <Form.Item
              label="Vat category"
              name="vatCategoryCode"
              rules={[
                { required: true, message: "Please select vat category" },
              ]}
            >
              <VatCategorySelect
                value={getFieldValue("vatCategoryCode")}
                onChangeHandler={onVatCategorySelected}
                kontaxCategory={getFieldValue("categoryCode")}
                style={{ width: "100%" }}
              />
            </Form.Item>
          )}
        </Form.Item>

        <Form.Item label="Paid by" name="name" rules={[{ required: false }]}>
          <Input placeholder="Enter paid by" type="string" />
        </Form.Item>
        <Form.Item
          label="IBAN"
          name="iban"
          rules={[
            {
              validator: async (_, iban) => {
                if (iban && !IBAN.isValid(iban)) {
                  return Promise.reject(new Error("Wrong IBAN"));
                }
              },
            },
          ]}
        >
          <Input placeholder="Enter IBAN" type="string" />
        </Form.Item>
        <Form.Item
          label="Description"
          name="description"
          rules={[{ required: false }]}
        >
          <Input placeholder="Enter description" type="string" />
        </Form.Item>
      </div>
    </ExternalTransactionModalContent>
  );
};

export default ExternalTransactionForm;
