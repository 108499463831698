import styled from "styled-components";

import {
  CopyEmoji,
  CustomerAttributeLabel,
} from "../../../../../common/CustomerAttribute/styledComponents";

export const FieldList = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1.5rem;
  width: 100%;
  padding: 10px 0 4px 0;

  ${CopyEmoji} {
    background: none;
  }

  ${CustomerAttributeLabel} {
    font-size: 14px;
  }
`;
