import type * as Types from "../../schema.generated";

import { gql } from "@apollo/client";
import { AutocategorizationRuleFragmentDoc } from "../../fragments/autocategorizationRule.generated";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type UpdateAutocategorizationRuleMutationVariables = Types.Exact<{
  input: Types.UpdateAutocategorizationRuleInput;
}>;

export type UpdateAutocategorizationRuleMutation = {
  __typename?: "Mutation";
  updateAutocategorizationRule: {
    __typename?: "UpdateAutocategorizationRulePayload";
    autocategorizationRule: {
      __typename?: "AutocategorizationRule";
      id: number;
      description: string;
      enabled: boolean;
      priority: number;
      categoryCode?: Types.CategoryCode | null;
      vatCategoryCode?: Types.VatCategoryCode | null;
      verify: boolean;
      accuracy?: number | null;
      appliedTimesCount?: number | null;
      conditions: {
        __typename?: "AutocategorizationRuleConditions";
        accountIds?: Array<number> | null;
        hasEmployees?: boolean | null;
        hasInvoices?: boolean | null;
        hasVatNumber?: boolean | null;
        ibans?: Array<string> | null;
        vatPaymentFrequencies?: Array<Types.PaymentFrequency> | null;
        amount?: {
          __typename?: "RuleConditionNumber";
          op: Types.RuleConditionNumberOp;
          value: number;
        } | null;
        description?: {
          __typename?: "RuleConditionString";
          op: Types.RuleConditionStringOp;
          value: string;
        } | null;
        name?: {
          __typename?: "RuleConditionString";
          op: Types.RuleConditionStringOp;
          value: string;
        } | null;
      };
    };
  };
};

export const UpdateAutocategorizationRuleDocument = gql`
  mutation updateAutocategorizationRule(
    $input: UpdateAutocategorizationRuleInput!
  ) {
    updateAutocategorizationRule(input: $input) {
      autocategorizationRule {
        ...AutocategorizationRule
      }
    }
  }
  ${AutocategorizationRuleFragmentDoc}
`;
export type UpdateAutocategorizationRuleMutationFn = Apollo.MutationFunction<
  UpdateAutocategorizationRuleMutation,
  UpdateAutocategorizationRuleMutationVariables
>;

/**
 * __useUpdateAutocategorizationRuleMutation__
 *
 * To run a mutation, you first call `useUpdateAutocategorizationRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAutocategorizationRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAutocategorizationRuleMutation, { data, loading, error }] = useUpdateAutocategorizationRuleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAutocategorizationRuleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAutocategorizationRuleMutation,
    UpdateAutocategorizationRuleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAutocategorizationRuleMutation,
    UpdateAutocategorizationRuleMutationVariables
  >(UpdateAutocategorizationRuleDocument, options);
}
export type UpdateAutocategorizationRuleMutationHookResult = ReturnType<
  typeof useUpdateAutocategorizationRuleMutation
>;
export type UpdateAutocategorizationRuleMutationResult =
  Apollo.MutationResult<UpdateAutocategorizationRuleMutation>;
export type UpdateAutocategorizationRuleMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateAutocategorizationRuleMutation,
    UpdateAutocategorizationRuleMutationVariables
  >;
