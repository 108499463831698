import React, { useCallback, useEffect, useState } from "react";
import { FileOutlined, UploadOutlined } from "@ant-design/icons";
import { Upload, Button, notification } from "antd";
import styled from "styled-components";

import { DocumentsContainer } from "../../Common/UserDetails/styledComponents";
import { IUser } from "../../../../../types";

import { SpinnerBasic } from "../../../../common/Spinner";
import api from "../../../../../api";
import { UserPages } from "../../Common/UserDetails/enums";
import { Document } from "../../../../../types";
import config from "../../../../../config";
import { ReactComponent as GoToIcon } from "../../../../../svgs/goTo.svg";

const { Dragger } = Upload;

type DocumentsListProps = {
  user: IUser;
  pageType: UserPages;
};

const ButtonWrapper = styled.div`
  width: 900px;
  margin: auto;
  margin-bottom: 14px;
`;

const GoToButton = ({
  email,
  disabled,
}: {
  email: string;
  disabled: boolean;
}) => {
  return (
    <Button
      data-test="goToBelegeButton"
      disabled={disabled}
      icon={
        <GoToIcon style={{ marginRight: 8, opacity: disabled ? 0.3 : 1 }} />
      }
      onClick={() => {
        window.open(`/belege-view?email=${encodeURIComponent(email)}`);
      }}
      style={{ display: "flex" }}
    >
      Go to Belege
    </Button>
  );
};

export const DocumentsList = ({
  onDropFiles,
  isLoading,
  documents,
  pageType,
  email,
}: {
  isLoading: boolean;
  documents: Document[];
  pageType: UserPages;
  email: string;
  onDropFiles: (files: File[]) => Promise<void>;
}) => {
  const [droppedFiles, setDroppedFiles] = useState<File[]>([]);

  useEffect(() => {
    if (droppedFiles.length) {
      onDropFiles(droppedFiles);
      setDroppedFiles([]);
    }
  }, [droppedFiles, onDropFiles]);

  // ant calls beforeUpload for every single file that is uploaded.
  // This function together with useEffect hook is a workaround to make it possible
  // to call onDropFiles only once with all files together.
  const beforeUpload = (_: File, files: File[]) => {
    setDroppedFiles(files);
    return Upload.LIST_IGNORE;
  };

  return (
    <DocumentsContainer>
      <Dragger
        data-test="documentUploadArea"
        multiple
        withCredentials={false}
        beforeUpload={beforeUpload}
        showUploadList={{
          showRemoveIcon: false,
          downloadIcon: FileOutlined,
        }}
        fileList={documents.map((document, index) => ({
          uid: index.toString(),
          name: document.filename,
          url: document.url,
        }))}
        itemRender={(originNode, file) => {
          const document = documents[+file.uid];

          return (
            <div className="list-item" data-test="documentFileTree">
              {typeof documents[+file.uid].matched === "boolean" && (
                <span>
                  {document.matched ? (
                    <a
                      href={`${config.REACT_APP_URL}/transaction-view?id=${document.transactionId}`}
                      target="_blank"
                      rel="noreferrer"
                      data-test="documentLinkSuccuss"
                    >
                      ✅ {document.filename}
                    </a>
                  ) : (
                    <a
                      href={`${
                        config.REACT_APP_URL
                      }/belege-view?email=${encodeURIComponent(email)}&id=${
                        document.id
                      }`}
                      target="_blank"
                      rel="noreferrer"
                      data-test="documentLinkFailure"
                    >
                      {/* noMatchReason got replaced by matchStatus and is there
                      for backward compatibility reasons and can be removed soon */}
                      ❌ ({document.noMatchReason || document.matchStatus}){" "}
                      {document.filename}
                    </a>
                  )}
                </span>
              )}
              {originNode}
              <span className="list-item-meta">
                {documents[+file.uid].createdAt}
              </span>
            </div>
          );
        }}
      >
        {isLoading ? (
          <SpinnerBasic />
        ) : (
          <p>Drag and drop {pageType} here to upload</p>
        )}
        <Button
          style={{
            display: "inline-flex",
            marginTop: "10px",
            alignItems: "center",
          }}
          icon={<UploadOutlined />}
        >
          Upload
        </Button>
      </Dragger>
    </DocumentsContainer>
  );
};

const DocumentsListContainer = ({ user, pageType }: DocumentsListProps) => {
  const [docs, setDocs] = useState<Document[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchDocuments = useCallback(
    async (email: string) => {
      try {
        setIsLoading(true);
        const fetchedDocs = await api.kontax[
          pageType === UserPages.DOCUMENTS ? "getDocuments" : "getReceipts"
        ](email);
        setDocs(fetchedDocs);
      } catch (error) {
        const errMessage = (error as Error)?.message;
        notification.error({
          message: `An error occurred while fetching ${pageType}: (${errMessage})`,
        });
      } finally {
        setIsLoading(false);
      }
    },
    [pageType]
  );

  useEffect(() => {
    fetchDocuments(user.email);
  }, [fetchDocuments, user]);

  const onDropFiles = async (files: File[]) => {
    setIsLoading(true);

    for (const file of files) {
      try {
        await api.kontax[
          pageType === UserPages.DOCUMENTS ? "uploadDocument" : "uploadReceipt"
        ](user.email, file);
        notification.success({
          message: `File ${file.name} uploaded, processing...`,
        });
      } catch (error) {
        const errMessage = (error as Error)?.message;
        notification.error({
          message: `An error occurred while uploading ${file.name}: (${errMessage})`,
        });
      }
    }

    setIsLoading(false);
    fetchDocuments(user.email);
  };

  return (
    <>
      {user.email && pageType === UserPages.RECEIPTS ? (
        <ButtonWrapper>
          <GoToButton
            email={user.email}
            disabled={docs.filter((doc) => !doc.matched).length === 0}
          />
        </ButtonWrapper>
      ) : null}
      <DocumentsList
        onDropFiles={onDropFiles}
        pageType={pageType}
        documents={docs}
        isLoading={isLoading}
        email={user.email}
      />
    </>
  );
};

export default DocumentsListContainer;
