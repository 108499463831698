import { Select } from "antd";
import React from "react";

import {
  TaxServiceDetails,
  TaxServiceStatus,
} from "../../../api/graphql/schema.generated";
import { TAX_SERVICE_STATUS_LABELS } from "./constants";

export interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: string;
  record: TaxServiceDetails;
  index: number;
  required: boolean;
  children: React.ReactNode;
  handleStatusChange: (status: TaxServiceStatus) => void;
}

export const TAX_SERVICE_STATUS_OPTIONS = [
  {
    label: TAX_SERVICE_STATUS_LABELS[TaxServiceStatus.BLOCKED],
    value: TaxServiceStatus.BLOCKED,
  },
  {
    label: TAX_SERVICE_STATUS_LABELS[TaxServiceStatus.WILL_BE_BILLED],
    value: TaxServiceStatus.WILL_BE_BILLED,
  },
];

function EditableCell({
  record,
  editing,
  dataIndex,
  title,
  index,
  children,
  handleStatusChange,
  ...restProps
}: React.PropsWithChildren<EditableCellProps>) {
  return (
    <td {...restProps}>
      {editing ? (
        <Select
          style={{ width: 150 }}
          options={TAX_SERVICE_STATUS_OPTIONS}
          defaultValue={record.status}
          onSelect={handleStatusChange}
        />
      ) : (
        children
      )}
    </td>
  );
}

export default EditableCell;
