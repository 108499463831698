import type * as Types from "../../schema.generated";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type QuestionnairesQueryVariables = Types.Exact<{
  email: Types.Scalars["String"];
  year: Types.Scalars["Int"];
}>;

export type QuestionnairesQuery = {
  __typename?: "Query";
  questionnaires: Array<{
    __typename?: "Questionnaire";
    id: string;
    type: Types.QuestionnaireType;
    year: number;
    context?: any | null;
    status: Types.QuestionnaireStatus;
    startedAt?: string | null;
    completedAt?: string | null;
  }>;
};

export const QuestionnairesDocument = gql`
  query questionnaires($email: String!, $year: Int!) {
    questionnaires(email: $email, year: $year) {
      id
      type
      year
      context
      status
      startedAt
      completedAt
    }
  }
`;

/**
 * __useQuestionnairesQuery__
 *
 * To run a query within a React component, call `useQuestionnairesQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuestionnairesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuestionnairesQuery({
 *   variables: {
 *      email: // value for 'email'
 *      year: // value for 'year'
 *   },
 * });
 */
export function useQuestionnairesQuery(
  baseOptions: Apollo.QueryHookOptions<
    QuestionnairesQuery,
    QuestionnairesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QuestionnairesQuery, QuestionnairesQueryVariables>(
    QuestionnairesDocument,
    options
  );
}
export function useQuestionnairesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QuestionnairesQuery,
    QuestionnairesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QuestionnairesQuery, QuestionnairesQueryVariables>(
    QuestionnairesDocument,
    options
  );
}
export type QuestionnairesQueryHookResult = ReturnType<
  typeof useQuestionnairesQuery
>;
export type QuestionnairesLazyQueryHookResult = ReturnType<
  typeof useQuestionnairesLazyQuery
>;
export type QuestionnairesQueryResult = Apollo.QueryResult<
  QuestionnairesQuery,
  QuestionnairesQueryVariables
>;
