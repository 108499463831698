import type * as Types from "../../schema.generated";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type SystemUsageStatsQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type SystemUsageStatsQuery = {
  __typename?: "Query";
  systemUsageStats: Array<{
    __typename?: "SystemUsageStat";
    startedAt: string;
    finishedAt: string;
  }>;
};

export const SystemUsageStatsDocument = gql`
  query systemUsageStats {
    systemUsageStats {
      startedAt
      finishedAt
    }
  }
`;

/**
 * __useSystemUsageStatsQuery__
 *
 * To run a query within a React component, call `useSystemUsageStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSystemUsageStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSystemUsageStatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSystemUsageStatsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SystemUsageStatsQuery,
    SystemUsageStatsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SystemUsageStatsQuery, SystemUsageStatsQueryVariables>(
    SystemUsageStatsDocument,
    options
  );
}
export function useSystemUsageStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SystemUsageStatsQuery,
    SystemUsageStatsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SystemUsageStatsQuery,
    SystemUsageStatsQueryVariables
  >(SystemUsageStatsDocument, options);
}
export type SystemUsageStatsQueryHookResult = ReturnType<
  typeof useSystemUsageStatsQuery
>;
export type SystemUsageStatsLazyQueryHookResult = ReturnType<
  typeof useSystemUsageStatsLazyQuery
>;
export type SystemUsageStatsQueryResult = Apollo.QueryResult<
  SystemUsageStatsQuery,
  SystemUsageStatsQueryVariables
>;
