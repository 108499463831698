export enum VatCategoryCode {
  /** Erlöse 19 % */
  INCOME_19 = "81",
  /** Erlöse 7 % */
  INCOME_7 = "82",
  /** Erlöse 16 % */
  INCOME_16 = "83",
  /** Erlöse 5 % */
  INCOME_5 = "84",
  /** Erlöse 0 % mit VSt-Abzug (ITD = input tax deduction) */
  INCOME_0_ITD = "85",
  /** Erlöse 0 % ohne VSt-Abzug */
  INCOME_0 = "86",
  /** Erlöse EU Sonstige Leistung (B2B) */
  INCOME_EU_B2B = "70",
  /** Erlöse EU Innergemeinschaftliche Lieferung (B2B) */
  INCOME_EU_INTRA_B2B = "60",
  /** Erlöse EU Innergemeinschaftliche Lieferung (B2C) 19 % */
  INCOME_EU_INTRA_B2C_19 = "61",
  /** Erlöse EU Innergemeinschaftliche Lieferung (B2C) 7 % */
  INCOME_EU_INTRA_B2C_7 = "62",
  /** Erlöse EU Innergemeinschaftliche Lieferung (B2C) 16 % */
  INCOME_EU_INTRA_B2C_16 = "63",
  /** Erlöse EU Innergemeinschaftliche Lieferung (B2C) 5 % */
  INCOME_EU_INTRA_B2C_5 = "64",
  /** Erlöse EU Sonstige Leistung (B2C) 19 % */
  INCOME_EU_B2C_19 = "71",
  /** Erlöse EU Sonstige Leistung (B2C) 7 % */
  INCOME_EU_B2C_7 = "72",
  /** Erlöse EU Sonstige Leistung (B2C) 16 % */
  INCOME_EU_B2C_16 = "73",
  /** Erlöse EU Sonstige Leistung (B2C) 5 % */
  INCOME_EU_B2C_5 = "74",
  /**  Ausfuhrlieferungen */
  EXPORT_DELIVERY = "51",
  /** Übrige nicht steuerbare Umsätze */
  NON_TAXABLE = "52",
  /** Erlöse § 13b Absatz 5 UStG */
  INCOME_13B5_USTG = "53",
  /** Abziehbare VSt 19 % (DIT = deductible input tax) */
  DIT_19 = "11",
  /** Abziehbare VSt 7 % (DIT = deductible input tax) */
  DIT_7 = "12",
  /** Abziehbare VSt 16 % (DIT = deductible input tax) */
  DIT_16 = "13",
  /** Abziehbare VSt 5 % (DIT = deductible input tax) */
  DIT_5 = "14",
  /** Innergemeinschaftlicher Erwerb (Vorsteuer) (IT = Input Tax) */
  INTRA_ACQUISITION_IT = "15",
  /** Reverse Charge (Vorsteuer) {Leistung} (IT = Input Tax) */
  REVERSE_CHARGE_IT = "16",
  /** Reverse Charge (ohne Vorsteuer) */
  REVERSE_CHARGE = "17",
  /** Kein Vorsteuerabzug (ITD = input tax deduction) */
  NO_ITD = "18",
  /** Keine Umsatzsteuer */
  NO_VAT = "10",
}

export enum IncomingCategoryCode {
  PRIVATE_IN = "1220",
  INCOME_GERMANY = "1120",
  INCOME_EU = "1030",
  INCOME_INTL = "1031",
  VAT_REFUND = "1410",
  TAX_REFUND = "5000",
  TRADE_TAX_REFUND = "9000",
  CORONA_HELP = "1032",
  CONSTRUCTION_REVENUE = "1033",
  REVENUE_SB = "1110",
  VAT_ON_UNPAID_ITEMS = "1400",
  OTHER_USAGE_AND_SERVICE_WITHDRAWALS = "1081",
  FREE_VALUE_DELIVERY = "1082",
  FREE_VALUE_DELIVERY_PV_19 = "1083",
  FREE_VALUE_SERVICE = "1084",
  ELECTRONIC_SERVICE_EU_B2C_KU = "1111",
  INCOME_ONLY_VAT = "1121",
  ELECTRONIC_SERVICE_EU_B2C = "1122",
}

export enum OutgoingCategoryCode {
  OTHER_EXPENSES = "1830",
  TRAVEL_COSTS = "1460",
  ADVERTISING = "2240",
  PRIVATE_OUT = "1230",
  FEES = "2230",
  TELECOMMUNICATION = "2800",
  IT_COSTS = "2280",
  LEASING_MOVABLES = "2220",
  OFFICE_COSTS = "2290",
  LEGAL_TAX_CONSULTING = "1940",
  RENT = "1500",
  EDUCATION = "2810",
  VAT_PAYMENT = "1860",
  EXTERNAL_FREELANCER = "1100",
  ENTERTAINMENT = "1650",
  ACCOMMODATION = "2210",
  GOODS = "1000",
  PAYROLL = "1200",
  ASSETS_LESS_THAN_EUR_250 = "1300",
  ASSETS_GREATER_THAN_EUR_250 = "1320",
  ASSETS_GREATER_THAN_EUR_800 = "1370",
  MAINTENANCE_COSTS = "2250",
  SHIPPING_COSTS = "2270",
  INTERESTS_ASSETS = "2320",
  INTERESTS_CAR_ASSETS = "2321",
  INTERESTS_OTHER = "2340",
  GIFTS = "1740",
  DAILY_ALLOWANCE = "1710",
  LEASING_CAR = "1440",
  CAR_FEES = "1450",
  WASTE_DISPOSALS = "2260",
  TAX_PAYMENT = "2000",
  TRADE_TAX_PAYMENT = "8000",
  VAT = "1850",
  PRIVATE_WITHDRAWAL = "1221",
  CAR_COSTS = "1461",
  PUBLIC_TRANSPORT = "1462",
  LIMITED_DEDUCTIBLE_EXPENSES = "1680",
  LIMITED_NOT_DEDUCTIBLE_EXPENSES = "1770",
  BANK_FEES = "1831",
  INSURANCES = "2231",
  SOFTWARE_AND_LICENSES = "2291",
  BOOKS = "2292",
  DOWN_PAYMENT = "9991",
  IMPORT_VAT = "1851",
  DEPOSIT = "1399",
}

export const AllCategoryCodes = {
  ...IncomingCategoryCode,
  ...OutgoingCategoryCode,
};

export type CategoryCodeType = IncomingCategoryCode | OutgoingCategoryCode;
