import omit from "lodash/omit";
import { AxiosRequestConfig } from "axios";

import BaseApi from "./BaseApi";
import {
  Euer,
  ExternalResource,
  FetchTransactionsResponse,
  IBusinessAsset,
  ICreateBusinessAssetFromTransactionPayload,
  ICreateBusinessAssetPayload,
  IExitBusinessAssetPayload,
  IRule,
  ITaxNumber,
  ITransaction,
  IUpdateBusinessAssetPayload,
  IUser,
  IVatDeclarationUser,
  KontaxPlan,
  KontaxTransactionFilterOptions,
  KontaxTransactionMeta,
  KontaxUserStatus,
  KontaxUserStatusAdditionalParams,
  PaymentFrequency,
  PermanentExtensionStatus,
  TaxOpsPermissionsScope,
  TransactionSplit,
  UserDependent,
  UstvaPeriod,
  NextVatDeclarationStatus,
  VatMeta,
  Document,
  UsageStatPingPayload,
} from "../../types";

class KontaxApi extends BaseApi {
  protected get moduleUrl(): string {
    return "/kontax";
  }

  public getUsers = async (filterOptions: {
    search?: string;
    emailLike?: string;
    page?: number;
    pageSize?: number;
    filters?: Record<string, any>;
    sortField?: string;
    sortOrder?: string;
  }): Promise<{ users: Array<IUser>; count: number }> => {
    const { data } = await this.get("/users", { params: filterOptions });

    return {
      users: data.users,
      count: data.count,
    };
  };

  public getOnboardingUsers = async (filterOptions: {
    search?: string;
    emailLike?: string;
    page?: number;
    pageSize?: number;
    filters?: Record<string, any>;
    sortField?: string;
    sortOrder?: string;
  }): Promise<{ users: Array<IUser>; count: number }> => {
    filterOptions.filters = {
      "user.kontaxStatus": ["ACTIVATED"],
      ...filterOptions.filters,
    };

    return this.getUsers(filterOptions);
  };

  public getUserByEmail = async (email: string): Promise<IUser> => {
    const { users } = await this.getUsers({ search: email });
    return users[0];
  };

  public getAccountIdByEmail = async (email: string): Promise<string> => {
    const { users } = await this.getUsers({ search: email });
    return `${users[0].accountId}`;
  };

  public getUsersWithDeclarations = async (filterOptions: {
    email?: string;
    page?: number;
    status?: NextVatDeclarationStatus;
    plans?: Array<KontaxPlan>;
    vatPaymentFrequency?: PaymentFrequency;
  }): Promise<{
    declarations: Array<IVatDeclarationUser>;
    page: number;
  }> => {
    const { email, page, status, vatPaymentFrequency, plans } = filterOptions;

    const params = email
      ? { email }
      : {
          ...(status && { status }),
          ...(page && { page }),
          ...(plans && { plan: plans.join(",") }),
          ...(vatPaymentFrequency && { vatPaymentFrequency }),
        };

    const { data } = await this.get("/users/declarations", { params });

    return data;
  };

  public updateUser = async (user: {
    email: string;
    taxNumber?: ITaxNumber;
    businessTypeComment?: string;
    businessStartDate?: string;
    businessEndDate?: string;
    permanentExtensionStatus?: PermanentExtensionStatus;
  }): Promise<IUser> => {
    const { data } = await this.patch("/users", user);
    return data;
  };

  public updateUserStatus = async (
    email: string,
    status: KontaxUserStatus | null,
    additionalParams: KontaxUserStatusAdditionalParams = {}
  ) => {
    const { data } = await this.put(`/users/${email}/status`, {
      status,
      ...additionalParams,
    });

    return data;
  };

  public updateNextDeclarationData = async (
    {
      email,
      nextDeclarationDuePeriod,
      nextDeclarationDueYear,
      nextDeclarationStatus,
      vatLastCheckedAt,
    }: {
      email: string;
      nextDeclarationDuePeriod?: number;
      nextDeclarationDueYear?: number;
      nextDeclarationStatus?: NextVatDeclarationStatus;
      vatLastCheckedAt?: string;
    },
    config?: AxiosRequestConfig
  ) => {
    return this.put(
      `/users/${email}/declarations`,
      {
        nextDeclarationDuePeriod,
        nextDeclarationDueYear,
        nextDeclarationStatus,
        vatLastCheckedAt,
      },
      config
    );
  };

  public getVatMeta = async (email: string): Promise<VatMeta> => {
    const { data } = await this.get(`/users/${email}/vat-declaration-meta`);
    return data;
  };

  public getDeclarationPdf = async (
    email: string,
    id: string
  ): Promise<string> => {
    const { data } = await this.get(`/users/${email}/declarations/${id}`);
    return data;
  };

  public getUserPOATemporaryUrl = async (email: string): Promise<string> => {
    const { data } = await this.get(`/users/${email}/poa`);
    return data;
  };

  public getExternalUrl = async (
    email: string,
    resource: ExternalResource
  ): Promise<string> => {
    const { data } = await this.get(`/users/${email}/external-url/${resource}`);
    return data;
  };

  public fetchTransactions = async (
    filters?: KontaxTransactionFilterOptions,
    limit?: number
  ): Promise<FetchTransactionsResponse> => {
    const params: any = {
      ...omit(filters, "plans"),
      plan: filters?.plans,
      ...(limit && { limit }),
    };

    const { data } = await this.get("/transactions", { params });

    // This code is here to make this endpoint backwards compatible
    // Please remove once this endpoint only sends an array of transactions
    let transactions: ITransaction[] = [];
    if (data?.transactions) {
      transactions.push(...data.transactions);
    }

    if (data?.transaction) {
      transactions.push(data.transaction);
    }

    return {
      count: data?.count,
      transactions,
    };
  };

  public fetchTransactionCount = async (
    filters?: KontaxTransactionFilterOptions
  ): Promise<number> => {
    const params: any = {
      ...omit(filters, "plans"),
      plan: filters?.plans,
    };

    const { data } = await this.get("/transactions/count", { params });
    return data?.count;
  };

  public fetchTransactionById = async (
    id: string
  ): Promise<ITransaction | null> => {
    const { data } = await this.get(`/transactions/${id}`);
    return data;
  };

  public getUstvaElster = async (
    accountId: string,
    year: number,
    period: UstvaPeriod,
    contentType: string
  ): Promise<string> => {
    const response = await this.get(
      `/accounts/${accountId}/ustva/${year}/${period}`,
      {
        headers: {
          Accept: contentType,
        },
      }
    );
    return response.data;
  };

  public getEuerElster = async (
    accountId: string,
    startDate: string,
    endDate: string
  ): Promise<Euer> => {
    const response = await this.get(
      `/accounts/${accountId}/euer/${startDate}/${endDate}`,
      {
        headers: {
          Accept: "application/json",
        },
      }
    );
    return response.data;
  };

  public getAutocategorizationRules = async (): Promise<{
    rules: Array<IRule>;
  }> => {
    const { data } = await this.get("/autocategorization-rules");

    return {
      rules: data,
    };
  };

  public updateAutocategorizationRule = async (rule: {
    id: number;
    enabled?: boolean;
  }) => {
    return this.patch("/autocategorization-rules", rule);
  };

  public getUstvaStatus = async (
    accountId: string,
    period: string,
    year: number
  ) => {
    const response = await this.get(
      `/accounts/${accountId}/ustva/${year}/${period}/status`
    );
    return response.data;
  };

  public sendViaEric = async (
    accountId: string,
    period: string,
    year: number
  ) => {
    const response = await this.post(
      `/accounts/${accountId}/ustva/${year}/${period}`,
      {},
      { responseType: "arraybuffer" }
    );
    return response.data;
  };

  public createTransactionAsset = async (
    transactionId: string,
    name: string,
    filetype: string
  ) => {
    const response = await this.post(`/transactions/${transactionId}/assets`, {
      name,
      filetype,
    });
    return response.data;
  };

  public acknowledgeTransactionAsset = async (
    transactionId: string,
    assetId: string
  ) => {
    return this.patch(`/transactions/${transactionId}/assets/${assetId}`);
  };

  public downloadIntegrationDocument = (transactionId: string) => {
    return this.get(`/transactions/${transactionId}/document/download`, {
      responseType: "blob",
    });
  };

  public createTransactionSplit = async (
    transactionId: string,
    splits: Array<TransactionSplit>
  ) => this.post(`/transactions/${transactionId}/splits`, { splits });

  public updateTransactionSplit = async (
    transactionId: string,
    splits: Array<TransactionSplit>
  ) =>
    this.patch(`/transactions/${transactionId}/splits`, {
      splits: splits.map((s) => omit(s, ["category"])),
    });

  public deleteTransactionSplit = async (transactionId: string) =>
    this.delete(`/transactions/${transactionId}/splits`);

  public updateDependents = async (
    email: string,
    userDependents: UserDependent[]
  ) => {
    const { data } = await this.put(`/users/${email}/dependents`, {
      dependents: userDependents,
    });
    return data;
  };

  public createBusinessAsset = async ({
    email,
    businessAsset,
  }: {
    email: string;
    businessAsset: ICreateBusinessAssetPayload;
  }) =>
    this.post(`/users/${email}/business-assets`, {
      businessAsset,
    });

  public createBusinessAssetFromTransaction = async ({
    transactionId,
    businessAsset,
    transactionMeta,
  }: {
    transactionId: string;
    businessAsset: ICreateBusinessAssetFromTransactionPayload;
    transactionMeta: KontaxTransactionMeta;
  }) =>
    this.post(`/transactions/${transactionId}/business-assets`, {
      businessAsset,
      transactionMeta,
    });

  public updateBusinessAsset = async ({
    id,
    ...info
  }:
    | IUpdateBusinessAssetPayload
    | IExitBusinessAssetPayload): Promise<IBusinessAsset> => {
    const { data } = await this.patch(`/business-assets/${id}`, {
      ...info,
    });
    return data;
  };

  public acknowledgeAssetsUploaded = async (assetIds: string[]) => {
    return this.post(`/assets/acknowledge-assets-uploaded`, { assetIds });
  };

  public createTaxOpsPermissionsScope = async (
    taxOpsPermissionsScope: TaxOpsPermissionsScope
  ) =>
    this.post("/taxops/permission", {
      taxOpsEmail: taxOpsPermissionsScope.email,
      ...taxOpsPermissionsScope,
      email: undefined,
    });

  public deleteTaxOpsPermissionsScope = async (
    taxOpsPermissionsScope: TaxOpsPermissionsScope
  ) => this.delete(`/taxops/permission/${taxOpsPermissionsScope.email}`);

  public getTaxOpsPermissionsScopes = async () =>
    this.get("/taxops/permission/");

  public getSelfPermissionScope = async (config?: AxiosRequestConfig) =>
    this.get("/taxops/self-permission", config);

  public updateTaxOpsPermissionsScope = async (
    taxOpsPermissionsScope: TaxOpsPermissionsScope
  ) =>
    this.put(`/taxops/permission/${taxOpsPermissionsScope.email}`, {
      permissionsScope: taxOpsPermissionsScope.permissionsScope,
    });

  public getDocuments = async (email: string): Promise<Document[]> =>
    (await this.get(`/users/${email}/documents`)).data;

  public uploadDocument = async (
    email: string,
    file: File,
    documentCategoryId?: string | null,
    year?: number | null
  ): Promise<Document> => {
    const form = new FormData();
    form.append("file", file);

    if (documentCategoryId) {
      form.append("documentCategoryId", documentCategoryId);
    }

    if (year) {
      form.append("year", year.toString());
    }

    return (
      await this.post(`/users/${email}/documents`, form, {
        headers: { "Content-Type": "multipart/form-data" },
      })
    ).data;
  };

  public downloadDocumentsArchive = async (email: string, ids: string[]) =>
    await this.download(`/users/${email}/documents/archive`, `${email}.zip`, {
      params: { ids },
      responseType: "blob",
    });

  public getReceipts = async (email: string): Promise<Document[]> =>
    (await this.get(`/users/${email}/receipts`)).data;

  public uploadReceipt = async (
    email: string,
    file: File
  ): Promise<Document> => {
    const form = new FormData();
    form.append("file", file);
    return (
      await this.post(`/users/${email}/receipts`, form, {
        headers: { "Content-Type": "multipart/form-data" },
      })
    ).data;
  };

  public getBWAPdf = async (
    email: string,
    fromDate: string,
    toDate: string
  ) => {
    return await this.get(
      `/users/${email}/bwa-pdf/?fromDate=${fromDate}&toDate=${toDate}`,
      { responseType: "blob" }
    );
  };

  public sendUsageStatsPing = async (payload: UsageStatPingPayload) => {
    return this.post("/usage-stats/ping", payload);
  };

  public getDocuwareDocument = async (id: string) => {
    return this.get(`/docuware/document/${id}`, {
      responseType: "arraybuffer",
    });
  };
}

export default new KontaxApi();
