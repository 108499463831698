import React from "react";
import { Link, LinkProps } from "react-router-dom";
import queryString from "query-string";

import { getVerificationViewPath } from "../../../../VatAnnualDeclaration/utils";
import {
  EuerDeclarationFieldLinkParams,
  EuerDeclarationFieldType,
} from "../../../../../types";
import { EUER_DECLARATION_FIELD_LINKS, PATH_BY_EUER_TYPE } from "../helpers";

const getFieldTypePath = ({
  email,
  taxYear,
  type,
  transactionFilters,
}: EuerDeclarationFieldLinkParams & {
  taxYear: number;
  email: string;
}): LinkProps["to"] => {
  if (type === EuerDeclarationFieldType.Verification) {
    return getVerificationViewPath({ email, taxYear, transactionFilters });
  } else if (type === EuerDeclarationFieldType.BusinessAssets) {
    return {
      pathname: PATH_BY_EUER_TYPE[type],
      search: queryString.stringify({
        email,
        year: taxYear,
        userPage: "assets",
      }),
    };
  } else {
    return {
      pathname: PATH_BY_EUER_TYPE[type],
      search: queryString.stringify({ email, year: taxYear }),
    };
  }
};

type EuerDeclarationFieldLinkProps = {
  code: string;
  email: string;
  taxYear: number;
  description: string;
};

const EuerDeclarationFieldLink = ({
  code,
  email,
  taxYear,
  description,
}: EuerDeclarationFieldLinkProps) => {
  const euerDeclarationField = EUER_DECLARATION_FIELD_LINKS[code];
  const fieldTypePath = getFieldTypePath({
    ...euerDeclarationField,
    email,
    taxYear,
  });
  if (euerDeclarationField.type === EuerDeclarationFieldType.None) {
    return <>{description}</>;
  }

  return <Link to={fieldTypePath}>{description}</Link>;
};

export default EuerDeclarationFieldLink;
