import React, { ReactNode } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import {
  CopyEmoji,
  CustomerAttributeIconWrapper,
  CustomerAttributeLabel,
  CustomerAttributeLabelContainer,
  CustomerAttributeValue,
  EditOutlinedIcon,
  InspectLink,
} from "./styledComponents";
import { ReactComponent as OpenInNewTabIcon } from "../../../svgs/open-in-new-tab.svg";
import { WithCopyable } from "../Copyable";

const renderEditTooltip = (props: any) => (
  <Tooltip id="button-tooltip-edit" {...props}>
    Click to edit
  </Tooltip>
);

const CustomerAttribute = ({
  label,
  children,
  isCopyable,
  onEdit,
  onInspect,
  icon,
  withFixedIcon,
  dataTest,
  changeLog,
}: {
  label: string;
  children: ReactNode;
  isCopyable?: boolean;
  onEdit?: any;
  onInspect?: any;
  icon?: React.ReactNode;
  withFixedIcon?: boolean;
  dataTest?: string;
  changeLog?: ReactNode;
}) => {
  return (
    <div data-test={dataTest}>
      <CustomerAttributeLabelContainer>
        <CustomerAttributeLabel>{label}</CustomerAttributeLabel>
        {typeof onEdit === "function" && (
          <OverlayTrigger placement="top" overlay={renderEditTooltip}>
            <CopyEmoji
              onClick={onEdit}
              data-test={dataTest ? dataTest + "Icon" : "fieldIcon"}
            >
              <EditOutlinedIcon />
            </CopyEmoji>
          </OverlayTrigger>
        )}
        {icon && (
          <CustomerAttributeIconWrapper>{icon}</CustomerAttributeIconWrapper>
        )}
        {changeLog}
      </CustomerAttributeLabelContainer>
      <CustomerAttributeValue>
        {children ? (
          <WithCopyable copyable={isCopyable} withFixedIcon={withFixedIcon}>
            {children}
          </WithCopyable>
        ) : (
          "-"
        )}
      </CustomerAttributeValue>
      {typeof onInspect === "function" && (
        <InspectLink onClick={onInspect}>
          <OpenInNewTabIcon />
          <span>Open in new tab</span>
        </InspectLink>
      )}
    </div>
  );
};

export default CustomerAttribute;
