export interface ElsterFieldDefinition {
  label: string;
  description: string;
  hasValue?: boolean;
}

export const ELSTER_FIELDS_ENTRIES: Array<[string, ElsterFieldDefinition]> = [
  [
    "111",
    {
      label: "111",
      description: "Erlöse Kleinunternehmer",
    },
  ],
  [
    "112",
    {
      label: "112",
      description: "Umsatzsteuerpflichtige Betriebseinnahmen",
    },
  ],
  [
    "103",
    {
      label: "103",
      description:
        "Umsatzsteuerfreie, nicht umsatzsteuerbare Betriebseinnahmen sowie Betriebseinnahmen, für die der Leistungsempfänger die Umsatzsteuer nach §13b UStG schuldet",
    },
  ],
  [
    "140",
    {
      label: "140",
      description:
        "Vereinnahmte Umsatzsteuer sowie Umsatzsteuer auf unentgeltliche Wertabgaben",
    },
  ],
  [
    "141",
    {
      label: "141",
      description:
        "Vom Finanzamt erstattete und ggf. verrechnete Umsatzsteuer (Die Regelung zum 10-Tageszeitraum nach §11 Abs. 1Satz 2EStG ist zu beachten.)",
    },
  ],
  [
    "106",
    {
      label: "106",
      hasValue: false,
      description: "Private Kfz-Nutzung",
    },
  ],
  [
    "108",
    {
      label: "108",
      hasValue: true,
      description: "Sonstige Sach-, Nutzungs- und Leistungsentnahmen",
    },
  ],
  [
    "108b",
    {
      label: "-",
      hasValue: false,
      description:
        "Auflösung von Rücklagen und Ausgleichsposten (Übertrag aus Zeile 124)",
    },
  ],
  [
    "159",
    {
      label: "159",
      description: "Summe Betriebseinnahmen (Übertrag in Zeile 89)",
    },
  ],
  [
    "100",
    {
      label: "100",
      description: "Waren, Rohstoffe und Hilfsstoffe einschl. der Nebenkosten",
    },
  ],
  [
    "110",
    {
      label: "110",
      description: "Bezogene Fremdleistungen",
    },
  ],
  [
    "120",
    {
      label: "120",
      description:
        "Ausgaben für eigenes Personal (z. B. Gehälter, Löhne und Versicherungsbeiträge)",
    },
  ],
  [
    "136",
    {
      label: "136",
      hasValue: false,
      description:
        "AfA auf unbewegliche Wirtschaftsgüter (Übertrag aus Zeile 6 der Anlage AVEÜR)",
    },
  ],
  [
    "131",
    {
      label: "131",
      hasValue: false,
      description:
        "AfA auf immaterielle Wirtschaftsgüter (Übertrag aus Zeile 9 der Anlage AVEÜR)",
    },
  ],
  [
    "130",
    {
      label: "130",
      hasValue: false,
      description:
        "AfA auf bewegliche Wirtschaftsgüter (Übertrag aus Zeile 13 der Anlage AVEÜR)",
    },
  ],
  [
    "132",
    {
      label: "132",
      description:
        "Aufwendungen für geringwertige Wirtschaftsgüter nach §6Abs. 2EStG",
    },
  ],
  [
    "137",
    {
      label: "137",
      hasValue: false,
      description:
        "Auflösung Sammelposten nach §6Abs. 2a EStG (Übertrag aus Zeile 19 der Anlage AVEÜR)",
    },
  ],
  [
    "135",
    {
      label: "135",
      hasValue: false,
      description:
        "Restbuchwert der ausgeschiedenen Anlagegüter (Übertrag der Summe der Einzelbeträge aus Spalte „Abgänge“ der Anlage AVEÜR ohne Zeile 22)",
    },
  ],
  [
    "150",
    {
      label: "150",
      description:
        "Miete/Pacht für Geschäftsräume und betrieblich genutzte Grundstücke",
    },
  ],
  [
    "168",
    {
      label: "168",
      description:
        "Sonstige beschränkt abziehbare Betriebsausgaben (nicht abziehbar)",
    },
  ],
  [
    "177",
    {
      label: "177",
      description:
        "Sonstige beschränkt abziehbare Betriebsausgaben (abziehbar)",
    },
  ],
  [
    "280",
    {
      label: "280",
      description:
        "Aufwendungen für Telekommunikation (z. B. Telefon, Internet)",
    },
  ],
  [
    "221",
    {
      label: "221",
      description:
        "Übernachtungs- und Reisenebenkosten bei Geschäftsreisen des Steuerpflichtigen",
    },
  ],
  [
    "281",
    {
      label: "281",
      description: "Fortbildungskosten (ohne Reisekosten)",
    },
  ],
  [
    "194",
    {
      label: "194",
      description: "Kosten für Rechts- und Steuerberatung, Buchführung",
    },
  ],
  [
    "222",
    {
      label: "222",
      description:
        "Miete/Leasing für bewegliche Wirtschaftsgüter (ohne Kraftfahrzeuge)",
    },
  ],
  [
    "225",
    {
      label: "225",
      description:
        "Erhaltungsaufwendungen (z. B. Instandhaltung, Wartung, Reparatur; ohne solche für Gebäude und Kraftfahrzeuge)",
    },
  ],
  [
    "223",
    {
      label: "223",
      description:
        "Beiträge, Gebühren, Abgaben und Versicherungen (ohne solche für Gebäude und Kraftfahrzeuge)",
    },
  ],
  [
    "228",
    {
      label: "228",
      description: "Laufende EDV-Kosten (z. B. Beratung, Wartung, Reparatur)",
    },
  ],
  [
    "229",
    {
      label: "229",
      description: "Arbeitsmittel (z. B. Bürobedarf, Porto, Fachliteratur)",
    },
  ],
  [
    "226",
    {
      label: "226",
      description: "Kosten für Abfallbeseitigung und Entsorgung",
    },
  ],
  [
    "227",
    {
      label: "227",
      description: "Kosten für Verpackung und Transport",
    },
  ],
  [
    "224",
    {
      label: "224",
      description: "Werbekosten (z. B. Inserate, Werbespots, Plakate)",
    },
  ],
  [
    "232",
    {
      label: "232",
      description:
        "Schuldzinsen zur Finanzierung von Anschaffungs- und Herstellungskosten von Wirtschaftsgütern des Anlagevermögens (ohne häusliches Arbeitszimmer)",
    },
  ],
  [
    "234",
    {
      label: "234",
      description: "Übrige Schuldzinsen",
    },
  ],
  [
    "185",
    {
      label: "185",
      description: "Gezahlte Vorsteuerbeträge",
    },
  ],
  [
    "186",
    {
      label: "186",
      description:
        "An das Finanzamt gezahlte und ggf. verrechnete Umsatzsteuer (Die Regelung zum 10-Tageszeitraum nach §11 Abs. 2Satz 2EStG ist zu beachten.)",
    },
  ],
  [
    "183",
    {
      label: "183",
      description: "Übrige unbeschränkt abziehbare Betriebsausgaben",
    },
  ],
  [
    "164",
    {
      label: "164",
      hasValue: false,
      description: "Geschenke nicht abziehbar",
    },
  ],
  [
    "174",
    {
      label: "174",
      description: "Geschenke abziehbar",
    },
  ],
  [
    "165",
    {
      label: "165",
      description: "Bewirtungsaufwendungen nicht abziehbar",
    },
  ],
  [
    "175",
    {
      label: "175",
      description: "Bewirtungsaufwendungen abziehbar",
    },
  ],
  [
    "171",
    {
      label: "171",
      hasValue: false,
      description: "Verpflegungsmehraufwendungen",
    },
  ],
  [
    "172",
    {
      label: "172",
      hasValue: false,
      description:
        "Aufwendungen für ein häusliches Arbeitszimmer (einschl. AfA und Schuldzinsen)",
    },
  ],
  [
    "144",
    {
      label: "144",
      description: "Leasingkosten",
    },
  ],
  [
    "145",
    {
      label: "145",
      description: "Steuern, Versicherungen und Maut",
    },
  ],
  [
    "146",
    {
      label: "146",
      description:
        "Sonstige tatsächliche Fahrtkosten ohne AfA und Zinsen (z.B.Reparaturen, Wartungen, Treibstoff, Kosten für Flugstrecken, Kosten für öffentliche Verkehrsmittel)",
    },
  ],
  [
    "147",
    {
      label: "147",
      description:
        "Fahrtkosten für nicht zum Betriebsvermögen gehörende Fahrzeuge (Nutzungseinlage)",
    },
  ],
  [
    "199",
    {
      label: "199",
      description: "Summe Betriebsausgaben (Übertrag in Zeile 90)",
    },
  ],
  [
    "219",
    {
      label: "219",
      description: "Steuerpflichtiger Gewinn/Verlust",
    },
  ],
  [
    "122",
    {
      label: "122",
      description: "Entnahmen einschl. Sach-, Leistungs- und Nutzungsentnahmen",
    },
  ],
  [
    "123",
    {
      label: "123",
      description: "Einlagen einschl. Sach-, Leistungs- und Nutzungseinlagen",
    },
  ],
];

const elsterFields: Map<string, ElsterFieldDefinition> = new Map(
  ELSTER_FIELDS_ENTRIES
);

export default elsterFields;
