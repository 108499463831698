import moment from "moment";
import React, { useEffect, useState, useCallback } from "react";
import { DatePicker, notification } from "antd";

import { calculateDate } from "./helpers";
import api from "../../../../../api";
import {
  PaymentFrequency,
  QUARTERLY_PAYMENT_FREQUENCIES,
} from "../../../../../types";

interface Props {
  email: string;
  nextDeclarationDuePeriod?: string;
  nextDeclarationDueYear?: number;
  vatPaymentFrequency: PaymentFrequency;
  setUpdatedDetails: Function;
}
export const NextDeclarationDue = ({
  email,
  nextDeclarationDuePeriod,
  nextDeclarationDueYear,
  vatPaymentFrequency,
  setUpdatedDetails,
}: Props) => {
  const isChangeable = [
    PaymentFrequency.MONTHLY,
    PaymentFrequency.QUARTERLY,
    PaymentFrequency.NONE_QUARTERLY,
  ].includes(vatPaymentFrequency);

  const defaultDate = calculateDate(
    nextDeclarationDuePeriod,
    nextDeclarationDueYear
  );

  const [nextDeclarationDue, setNextDeclarationDue] = useState(defaultDate);
  const [updatedNextDeclarationDue, setUpdatedNextDeclarationDue] =
    useState(defaultDate);

  useEffect(() => {
    setNextDeclarationDue(
      calculateDate(nextDeclarationDuePeriod, nextDeclarationDueYear)
    );
  }, [nextDeclarationDuePeriod, nextDeclarationDueYear]);

  const updateNextDeclarationDue = useCallback(
    async (date: Date) => {
      setNextDeclarationDue(date);
      const period = QUARTERLY_PAYMENT_FREQUENCIES.includes(vatPaymentFrequency)
        ? moment(date).quarter()
        : moment(date).month() + 1; // months in moment are zero-indexed
      const response = await api.kontax.updateNextDeclarationData({
        email,
        nextDeclarationDuePeriod: period,
        nextDeclarationDueYear: moment(date).year(),
      });

      if (!(response instanceof Error)) {
        setUpdatedNextDeclarationDue(date);
        setUpdatedDetails(response.data.nextDeclarationStatus);
      } else {
        // Set to previous server-side updated nextDeclarationDue
        // in case the request failed
        setNextDeclarationDue(updatedNextDeclarationDue);
        notification.error({ message: "Error updating nextDeclarationDue" });
      }
    },
    [email, updatedNextDeclarationDue, vatPaymentFrequency, setUpdatedDetails]
  );

  return isChangeable ? (
    <DatePicker
      value={nextDeclarationDue ? moment(nextDeclarationDue) : null}
      onChange={(value: moment.Moment | null) => {
        value && updateNextDeclarationDue(value.toDate());
      }}
      picker={
        QUARTERLY_PAYMENT_FREQUENCIES.includes(vatPaymentFrequency)
          ? "quarter"
          : "month"
      }
      format={
        QUARTERLY_PAYMENT_FREQUENCIES.includes(vatPaymentFrequency)
          ? "\\QQ, YYYY"
          : "MMM, YYYY"
      }
    />
  ) : null;
};
