import type * as Types from "../schema.generated";

import { gql } from "@apollo/client";
export type AnschreibenTemplateFragment = {
  __typename?: "AnschreibenTemplate";
  id: string;
  content: string;
  type: string;
  language: string;
  year: number;
  note: string;
};

export const AnschreibenTemplateFragmentDoc = gql`
  fragment AnschreibenTemplate on AnschreibenTemplate {
    id
    content
    type
    language
    year
    note
  }
`;
