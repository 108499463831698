import { IUser, IUserDetails, TradeTax } from "../../../../../../types";
import { FieldList } from "./styledComponent";
import CustomerAttribute from "../../../../../common/CustomerAttribute";
import EditableCustomerAttribute, {
  EditType,
} from "../../../../../common/EditableCustomerAttribute";

const DocumentViewerFields = ({
  user,
  editUser,
}: {
  user: IUser;
  editUser: (email: string, userDetails: IUserDetails) => Promise<void>;
}) => {
  return (
    <FieldList>
      <CustomerAttribute
        label="Steuerliche Identifikationsnummer"
        isCopyable
        withFixedIcon
      >
        {user.deTaxId}
      </CustomerAttribute>
      <EditableCustomerAttribute
        label="Trade tax"
        initialValue={user.kontaxUser?.tradeTax}
        editType={EditType.Select}
        options={Object.values(TradeTax)}
        onSaveHandler={async (tradeTax: TradeTax) => {
          await editUser(user.email, {
            kontaxUser: { tradeTax },
          });
        }}
      />
    </FieldList>
  );
};

export default DocumentViewerFields;
