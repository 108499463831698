import styled from "styled-components";

import colors from "../../../../../themes/colors";
import AntNotes from "../../../../common/AntNotes";

export const InputWrapper = styled.div`
  padding: 8px;
  display: flex;
  flex-direction: column;
`;

export const TaxCaseExternalContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 1rem 10rem;
`;

export const TaxCaseCommentsWrapper = styled.div`
  max-width: 860px;
  margin: 0 auto;
`;

export const StyledNotes = styled(AntNotes)`
  padding: 24px 30px;
  margin-bottom: 24px;
  background-color: ${colors.white};
`;

export const ModalContent = styled.div`
  display: grid;
  grid-template-columns: 395px 1fr;
  gap: 0 24px;
  justify-items: stretch;
  align-items: center;

  .assets {
    grid-column: 1 / span 1;
    grid-row: 1 / span 4;
    align-self: stretch;

    .assetContainer {
      min-height: 430px;
    }
  }

  .ant-input-number-group-wrapper,
  .ant-picker {
    width: 100%;
  }

  .anticon {
    display: flex;
  }
`;

export const DeclarationDocumentModalContent = styled(ModalContent)`
  .ant-upload-drag {
    min-height: 430px !important;
  }
`;

export const DeclarationCardHint = styled.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: ${colors.darkBlack};
`;

export const TableContainer = styled.div`
  tr.expandable {
    cursor: pointer;
  }
`;
