import { gql, MutationHookOptions, useMutation } from "@apollo/client";

export interface RemoveAccountingSourceVariables {
  email: string;
  year: number;
}

export interface RemoveAccountingSourceResult {
  removeAccountingSource: {
    success: boolean;
  };
}

export const REMOVE_ACCOUNTING_SOURCE_MUTATION = gql`
  mutation RemoveAccountingSource($email: String!, $year: Int!) {
    removeAccountingSource(email: $email, year: $year) {
      success
    }
  }
`;

export const useRemoveAccountingSourceMutation = (
  options?: MutationHookOptions<
    RemoveAccountingSourceResult,
    RemoveAccountingSourceVariables
  >
) =>
  useMutation<RemoveAccountingSourceResult, RemoveAccountingSourceVariables>(
    REMOVE_ACCOUNTING_SOURCE_MUTATION,
    options
  );
