import type * as Types from "../../schema.generated";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type RemoveAnschreibenMutationVariables = Types.Exact<{
  email: Types.Scalars["String"];
  id: Types.Scalars["String"];
}>;

export type RemoveAnschreibenMutation = {
  __typename?: "Mutation";
  removeAnschreiben: { __typename?: "MutationResult"; success: boolean };
};

export const RemoveAnschreibenDocument = gql`
  mutation removeAnschreiben($email: String!, $id: String!) {
    removeAnschreiben(email: $email, id: $id) {
      success
    }
  }
`;
export type RemoveAnschreibenMutationFn = Apollo.MutationFunction<
  RemoveAnschreibenMutation,
  RemoveAnschreibenMutationVariables
>;

/**
 * __useRemoveAnschreibenMutation__
 *
 * To run a mutation, you first call `useRemoveAnschreibenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAnschreibenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAnschreibenMutation, { data, loading, error }] = useRemoveAnschreibenMutation({
 *   variables: {
 *      email: // value for 'email'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveAnschreibenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveAnschreibenMutation,
    RemoveAnschreibenMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveAnschreibenMutation,
    RemoveAnschreibenMutationVariables
  >(RemoveAnschreibenDocument, options);
}
export type RemoveAnschreibenMutationHookResult = ReturnType<
  typeof useRemoveAnschreibenMutation
>;
export type RemoveAnschreibenMutationResult =
  Apollo.MutationResult<RemoveAnschreibenMutation>;
export type RemoveAnschreibenMutationOptions = Apollo.BaseMutationOptions<
  RemoveAnschreibenMutation,
  RemoveAnschreibenMutationVariables
>;
