import { Col, Row, Typography } from "antd";
import moment from "moment";
import React from "react";

import { ChangeLog } from "../../../../../../../api/graphql";
import {
  extractNameFromEmail,
  getDraftInfoLatestCreationDate,
} from "../../../../utils";
import colors from "../../../../../../../themes/colors";
import ActionLogDrawer from "../../../../../../common/ActionLogDrawer";
import { ActionsContainer } from "../../../../styles";
import NotesDrawer from "../../../../../../common/NotesDrawer";
import DeclarationDeclines from "../../../../../../common/DeclarationDeclinesDrawer";
import {
  DeclarationDecline,
  EuerDeclarationStatus,
  KontaxNote,
  KontaxNoteType,
  TaxDeclarationSavedDraftInfo,
  TaxDeclarationSubmission,
} from "../../../../../../../api/graphql/schema.generated";

const { Title } = Typography;

export interface EuerDeclarationHeaderProps {
  taxYear: number;
  isLoading: boolean;
  lastSubmission: TaxDeclarationSubmission | null;
  changeLogs?: ChangeLog[];
  notes: KontaxNote[];
  declarationDeclines: DeclarationDecline[];
  declarationId: string;
  status?: EuerDeclarationStatus;
  draftInfo: TaxDeclarationSavedDraftInfo | null;
  hasExternalAssets: boolean;
}

const EuerDeclarationHeader = ({
  taxYear,
  isLoading,
  lastSubmission,
  changeLogs,
  notes,
  declarationDeclines,
  declarationId,
  status,
  draftInfo,
  hasExternalAssets,
}: EuerDeclarationHeaderProps) => {
  const title = `Anlage EÜR ${taxYear}`;
  return (
    <Row align="middle" className="mb-3">
      <Col flex="1">
        <ActionsContainer noTopMargin>
          <ActionLogDrawer
            withIcon
            title="Action log"
            changeLogs={changeLogs}
          />
          <NotesDrawer
            notes={notes}
            title={title}
            type={KontaxNoteType.EUER_DECLARATION}
            recordId={declarationId}
          />
          <DeclarationDeclines
            declarationDeclines={declarationDeclines}
            showNotification={status === EuerDeclarationStatus.OBJECTED_BY_USER}
            title={title}
          />
        </ActionsContainer>
        <Title level={3}>{title}</Title>
      </Col>
      {!isLoading && (
        <Col
          style={{
            color:
              lastSubmission || hasExternalAssets
                ? colors.darkLimeGreen
                : colors.darkGrey,
          }}
        >
          {hasExternalAssets ? (
            <>Eingereicht</>
          ) : lastSubmission ? (
            <>
              Eingereicht
              <div style={{ color: colors.darkGrey }}>
                am {moment(lastSubmission.submissionDate).format("L")} von{" "}
                {extractNameFromEmail(lastSubmission.submissionAgent!)}
              </div>
            </>
          ) : !!draftInfo ? (
            <div>
              <div>Freigabe PDF erstellt</div>
              <div>
                am{" "}
                {moment(getDraftInfoLatestCreationDate(draftInfo!)).format("L")}
              </div>
            </div>
          ) : (
            "In Bearbeitung"
          )}
        </Col>
      )}
    </Row>
  );
};
export default EuerDeclarationHeader;
