import React, {
  useEffect,
  useCallback,
  useMemo,
  MouseEventHandler,
} from "react";

import {
  Form,
  Select,
  Input,
  DatePicker,
  Button,
  Space,
  Popconfirm,
} from "antd";
import moment, { Moment } from "moment";

import { isSteuerIdValid } from "validate-steuerid";

import { UserDependent, UserDependentType } from "../../../../../../types";
import { getFormattedType, LOCAL_ID_PREFIX } from "../utils";
import { getBerlinMomentTimezone } from "../../../../../common/helpers";
import {
  DependentFormButtonWrapper,
  StyledDependentForm,
} from "./styledComponents";

const { Option } = Select;

type DependentDetailsProps = {
  localDependent?: UserDependent;
  updateLocalDependents: Function;
  onDeleteDependent: (id: string) => void;
  onCancel: MouseEventHandler;
  isSaving: boolean;
};

const DependentDetails = ({
  localDependent,
  updateLocalDependents,
  onDeleteDependent,
  onCancel,
  isSaving,
}: DependentDetailsProps) => {
  const [form] = Form.useForm();
  const isLocalRecord = useMemo(
    () => localDependent?.id?.startsWith(LOCAL_ID_PREFIX),
    [localDependent]
  );

  const setInitialFormValues = useCallback(() => {
    form.setFields([
      {
        name: "type",
        value: localDependent?.type,
      },
      {
        name: "firstName",
        value: localDependent?.firstName,
      },
      {
        name: "lastName",
        value: localDependent?.lastName,
      },
      {
        name: "birthDate",
        value: localDependent?.birthDate
          ? moment(localDependent.birthDate)
          : null,
      },
      {
        name: "marriageStartDate",
        value: localDependent?.marriageStartDate
          ? moment(localDependent.marriageStartDate)
          : null,
      },
      {
        name: "marriageEndDate",
        value: localDependent?.marriageEndDate
          ? moment(localDependent.marriageEndDate)
          : null,
      },
      {
        name: "deTaxId",
        value: localDependent?.deTaxId,
      },
    ]);
  }, [form, localDependent]);

  useEffect(() => {
    setInitialFormValues();
  }, [localDependent, setInitialFormValues]);

  const cancelButtonText = useMemo(
    () => (isLocalRecord ? "Remove" : "Cancel"),
    [isLocalRecord]
  );

  const onOkPress = useCallback(() => {
    updateLocalDependents({
      id: localDependent?.id,
      ...form.getFieldsValue(),
    });
  }, [form, localDependent, updateLocalDependents]);

  const cancel = (event: any) => {
    setInitialFormValues();
    onCancel(event);
  };

  if (!localDependent?.id) {
    return null;
  }

  const onDateChange = (fieldName: string) => (date: Moment | null) => {
    if (date) {
      form.setFields([
        {
          name: fieldName,
          value: getBerlinMomentTimezone(date.format("YYYY-MM-DD")),
        },
      ]);
    }
  };

  return (
    <StyledDependentForm
      form={form}
      id={localDependent.id}
      autoComplete="off"
      onFinish={onOkPress}
      labelCol={{ span: 6 }}
      labelAlign="left"
    >
      <Form.Item
        label="Type"
        name="type"
        rules={[{ required: true, message: "Please enter a type" }]}
      >
        <Select data-test="userDependentTableRelationSelect">
          {Object.values(UserDependentType).map((type) => (
            <Option
              key={type}
              value={type}
              data-test={type}
              disabled={type === ""}
            >
              {getFormattedType(type)}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label="First Name"
        name="firstName"
        data-test="firstName"
        rules={[{ required: true, message: "Please enter first name" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Last Name"
        name="lastName"
        data-test="lastName"
        rules={[{ required: true, message: "Please enter last name" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Birthdate"
        name="birthDate"
        rules={[{ required: true, message: "Please enter birth date" }]}
      >
        <DatePicker
          data-test="userDependentTableBirthdate"
          picker="date"
          format="DD.MM.YYYY"
          onChange={onDateChange("birthDate")}
          style={{ width: "100%" }}
        />
      </Form.Item>
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.type !== currentValues.type
        }
      >
        {({ getFieldValue }) => {
          if (getFieldValue("type") === UserDependentType.PARTNER) {
            return (
              <>
                <Form.Item label="Marriage start date" name="marriageStartDate">
                  <DatePicker
                    data-test="userDependentTableMarriageStartDate"
                    picker="date"
                    format="DD.MM.YYYY"
                    onChange={onDateChange("marriageStartDate")}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
                <Form.Item label="Marriage end date" name="marriageEndDate">
                  <DatePicker
                    data-test="userDependentTableMarriageEndDate"
                    picker="date"
                    format="DD.MM.YYYY"
                    onChange={onDateChange("marriageEndDate")}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </>
            );
          }
          return null;
        }}
      </Form.Item>
      <Form.Item
        label="Steuerliche Identifikationsnr."
        name="deTaxId"
        data-test="userDependentTableDETaxId"
        rules={[
          {
            validator: (_, value) => {
              if (!!value && !isSteuerIdValid(value)) {
                return Promise.reject(
                  new Error("Dependents' DE Tax ID is invalid")
                );
              }
              return Promise.resolve();
            },
          },
        ]}
      >
        <Input />
      </Form.Item>
      <DependentFormButtonWrapper>
        <Space>
          <Button
            onClick={cancel}
            data-test={`userDependentTable${cancelButtonText}Button`}
            disabled={isSaving}
          >
            {cancelButtonText}
          </Button>
          {!isLocalRecord && (
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => onDeleteDependent(localDependent!.id!)}
            >
              <Button
                danger
                data-test="userDependentTableDeleteButton"
                disabled={isSaving}
              >
                Delete
              </Button>
            </Popconfirm>
          )}
          <Button
            form={localDependent.id}
            key="submit"
            htmlType="submit"
            type="primary"
            data-test="userDependentTableOKButton"
          >
            OK
          </Button>
        </Space>
      </DependentFormButtonWrapper>
    </StyledDependentForm>
  );
};

export default DependentDetails;
