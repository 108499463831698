import React from "react";
import { Typography } from "antd";
import { useLocation } from "react-router-dom";

import useEmailParam from "../../../../../../hooks/useEmailParam";
import useTaxYearParam from "../../../../hooks/useTaxYearParam";
import EmptyWrapper from "../../../../../../common/EmptyWrapper";
import {
  ANSWERS,
  QUESTIONS,
  QUESTION_TOPIC_LABELS,
  TaxPreparationTopic,
} from "./constants";
import { GREYED_OUT_CLASS, StyledTable } from "../styledComponents";
import {
  QuestionnaireAnswer,
  QuestionnaireQuestion,
} from "../../../../../../../api/graphql";
import {
  getQuestionnaireAnswerValue,
  getQuestionnaireUpdatedAtValue,
} from "../utils";
import BackButton from "../../../EuerDeclaration/components/BackButton";
import { useQuestionnaireQuery } from "../../../../../../../api/graphql/queries/questionnaire/questionnaire.generated";
import { QuestionnaireType } from "../../../../../../../api/graphql/schema.generated";

const { Title } = Typography;

const SUPPORTED_TAX_YEAR = [2022, 2023, 2024];

const columns = [
  {
    title: "Frage",
    key: "name",
    dataIndex: "name",
    width: "60%",
    render: (name: string) => {
      return QUESTIONS[name];
    },
  },
  {
    title: "Thema",
    key: "topic",
    dataIndex: "topic",
    width: "15%",
    render: (topic: TaxPreparationTopic) => {
      return QUESTION_TOPIC_LABELS[topic];
    },
  },
  {
    title: "Antwort",
    key: "answer",
    width: "15%",
    render: (question: QuestionnaireQuestion) =>
      getQuestionnaireAnswerValue(question, ANSWERS),
  },
  {
    title: "Bearbeitet",
    key: "updatedAt",
    dataIndex: "answer",
    width: "10%",
    render: (answer: QuestionnaireAnswer) =>
      getQuestionnaireUpdatedAtValue(answer),
  },
];

const StartOfTheYearQuestionnaire = () => {
  const location = useLocation();
  const [taxYear] = useTaxYearParam();
  const [email] = useEmailParam();

  const { data: questionnaireData, loading: isLoadingQuestionnaire } =
    useQuestionnaireQuery({
      skip: !email,
      variables: {
        email: email!,
        type: QuestionnaireType.START_OF_THE_YEAR,
        year: taxYear,
      },
    });

  const questions = questionnaireData?.questionnaire?.questions;

  if (!email) {
    return <EmptyWrapper description="Suche nach einem Mandanten" />;
  }

  if (!SUPPORTED_TAX_YEAR.includes(taxYear)) {
    const startYear = SUPPORTED_TAX_YEAR[0];
    const endYear = SUPPORTED_TAX_YEAR[SUPPORTED_TAX_YEAR.length - 1];
    return (
      <EmptyWrapper
        description={`Fragebogen ist ab Jahr ${startYear} verfügbar bis ${endYear}`}
        redText
      />
    );
  }

  return (
    <>
      <BackButton
        text="Vorbereitung Steuererklärung"
        to={{
          pathname: "/tax-declaration/tax-preparation",
          search: location.search,
        }}
      />

      <Title
        level={3}
        className="m-0"
      >{`Anfang des Jahres Fragebogen ${taxYear}`}</Title>

      <StyledTable
        size="large"
        columns={columns}
        dataSource={questions}
        rowClassName={(record: any) => (!record.answer ? GREYED_OUT_CLASS : "")}
        sortDirections={[]}
        pagination={false}
        loading={isLoadingQuestionnaire}
      />
    </>
  );
};

export default StartOfTheYearQuestionnaire;
