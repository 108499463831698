import styled from "styled-components";
import { Tree } from "antd";

import colors from "../../../../../../themes/colors";

export const StyledTree = styled(Tree)`
  background: none;

  .category-node {
    margin-top: 24px;
  }

  .folder-node {
    padding: 8px 0;
  }

  .document-node,
  .upload-node {
    padding: 0;
  }

  .category-node > span.ant-tree-node-content-wrapper {
    background: unset;
    cursor: default;
    padding-left: 0;
  }

  .category-node > span.ant-tree-switcher {
    display: none;
  }

  .document-node > span.ant-tree-node-content-wrapper,
  .upload-node > span.ant-tree-node-content-wrapper {
    padding: 0px;
  }

  .document-node
    > span.ant-tree-node-content-wrapper:not(.ant-tree-node-selected):not(:hover) {
    background-color: ${colors.white};
  }

  .document-node.ant-tree-treenode-leaf-last
    > span.ant-tree-node-content-wrapper
    > span
    > div {
    border-bottom-width: 1px;
  }

  .document-node > span.ant-tree-switcher,
  .upload-node > span.ant-tree-switcher {
    display: none;
  }

  .ant-tree-indent-unit {
    display: none;
  }

  .ant-tree-switcher {
    background: unset;
  }

  .ant-tree-switcher-line-icon {
    vertical-align: 0;
  }

  .category-node .ant-tree-checkbox {
    display: none;
  }

  .upload-node .ant-tree-checkbox {
    visibility: hidden;
    margin: 0px 5px;
  }

  .folder-node .ant-tree-checkbox {
    margin: 4px;
  }

  .document-node > span.ant-tree-checkbox {
    margin: 20px 5px 0px 5px;
  }
`;

export const DocumentViewerContent = styled.div`
  margin-top: 14px;
`;

export const EMLFilePreview = styled.div`
  label {
    min-width: 100px;
  }

  p {
    margin: 0;
  }
`;

export const DocuwareDocumentsContainer = styled.div`
  width: 900px;
  margin: 30px auto;
`;
