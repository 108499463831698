import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Button, Col, Divider, Modal, Row, Typography } from "antd";

import { useUserContext } from "../../contexts/UserContext";
import {
  ListRow,
  SectionRow,
  StyledModal,
  Total,
  Error,
} from "./styledComponents";
import DeclarationStatusSelect from "../DeclarationStatusSelect";
import { IUser } from "../../../../../types";
import WarnLeaveWithoutSaving from "../../../../common/WarnLeaveWithoutSaving";
import { WARNING_MESSAGE } from "../../../../common/WarnLeaveWithoutSaving/constants";
import { getGraphQLErrorMessage } from "../../../../../utils/error";

const { Text } = Typography;

export interface DeclarationStatusChangeModalProps<T extends string> {
  title: string;
  year: number;
  user?: IUser;
  subtitle?: string;
  status: T;
  statusOptions: T[];
  statusMapping: Record<T, { label: string }>;
  onSave: (status: T) => Promise<void>;
  onClose: () => void;
  children?: ReactNode;
}

const DeclarationStatusChangeModal = <T extends string>({
  title,
  year,
  user: userFromProps,
  subtitle,
  status: initialStatus,
  statusOptions,
  statusMapping,
  children,
  onSave,
  onClose,
}: DeclarationStatusChangeModalProps<T>) => {
  const userFromContext = useUserContext();
  const user = userFromProps || userFromContext;
  const [status, setStatus] = useState<T>(initialStatus);
  const [isSaving, setIsSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  useEffect(() => {
    setStatus(initialStatus);
  }, [initialStatus]);

  const isDirty = useMemo(
    () => initialStatus !== status,
    [initialStatus, status]
  );

  const handleSaveAndClose = useCallback(async () => {
    try {
      setIsSaving(true);
      await onSave(status);
      onClose();
    } catch (error) {
      const errorMessage = getGraphQLErrorMessage(error);
      if (
        errorMessage &&
        errorMessage.message === "DOCUMENTS_REQUIRED_FOR_STATUS_UPDATE"
      ) {
        setErrorMessage(
          "Ein Dokument muss hochgeladen sein, um diesen Status setzen zu können"
        );
      }
      // eslint-disable-next-line no-console
      console.error(error);
      setIsSaving(false);
    }
  }, [onClose, onSave, status]);

  const handleClose = useCallback(() => {
    if (isDirty) {
      Modal.confirm({
        title: WARNING_MESSAGE,
        onOk: onClose,
        cancelText: "Abbrechen",
      });
    } else {
      onClose();
    }
  }, [isDirty, onClose]);

  if (!user) {
    return null;
  }

  return (
    <>
      <WarnLeaveWithoutSaving warn={isDirty} />
      <StyledModal
        visible
        title={
          <>
            <Text strong>{`${title} ${year}`}</Text>
            <Text> Statusübersicht</Text>
          </>
        }
        maskClosable={false}
        onCancel={handleClose}
        width={800}
        footer={
          <>
            <Button
              data-test="saveAnlagevermogenStatusButton"
              type="primary"
              onClick={handleSaveAndClose}
              loading={isSaving}
            >
              Speichern und schließen
            </Button>
          </>
        }
      >
        <SectionRow>
          <Col>
            <Text strong>
              {user.firstName} {user.lastName}
            </Text>
            <div>{user.email}</div>
            <div>{subtitle}</div>
          </Col>
        </SectionRow>

        <Divider className="m-0" />

        {children}

        <Divider className="m-0" />

        <SectionRow>
          <Row>
            <DeclarationStatusSelect
              value={status}
              options={statusOptions}
              mapping={statusMapping}
              onChange={setStatus}
              disabled={isSaving}
              error={!!errorMessage}
            />
          </Row>
          <Row>{!!errorMessage && <Error>{errorMessage}</Error>}</Row>
        </SectionRow>
      </StyledModal>
    </>
  );
};

DeclarationStatusChangeModal.ListRow = ListRow;
DeclarationStatusChangeModal.Total = Total;

export default DeclarationStatusChangeModal;
