import { useState, useEffect } from "react";
// @ts-ignore
import emlformat from "eml-format";
import JSZip from "jszip";
import moment from "moment";

import { EMLFilePreview } from "./styledComponent";

interface EMLParsingOutput {
  text?: string;
  html?: string;
  date?: Date;
  from?: {
    email: string;
    name: string;
  };
  to?: {
    email: string;
  };
  subject?: string;
}

export function EMLFileViewer({ file }: { file: File }) {
  const [fileContents, setFileContents] = useState<EMLParsingOutput[]>();

  useEffect(() => {
    loadEML(file)
      .then(setFileContents)
      .catch((err) =>
        setFileContents([{ text: `Couldn't load EML file: ${err.message}` }])
      );
  }, [file]);

  return (
    <EMLFilePreview>
      {fileContents?.map((eml, index) => {
        return (
          <div key={`${file.name}-${index}`}>
            {eml.date && (
              <p>
                <label>Date:</label>
                <b>{moment(eml.date).format("YYYY-MM-DD HH:mm")}</b>
              </p>
            )}
            {eml.from?.email && (
              <p>
                <label>From:</label>
                <b>
                  {eml.from.name} ({eml.from.email})
                </b>
              </p>
            )}
            {eml.to?.email && (
              <p>
                <label>To:</label>
                <b>{eml.to.email}</b>
              </p>
            )}
            <p>
              <label>Subject:</label>
              <b>{eml.subject}</b>
            </p>
            {eml.html ? (
              <div dangerouslySetInnerHTML={{ __html: eml.html }} />
            ) : (
              <div key={`${file.name}-${index}`}>{eml.text}</div>
            )}
          </div>
        );
      })}
    </EMLFilePreview>
  );
}

async function loadEML(file: File): Promise<Record<string, any>[]> {
  const results = await loadEMLZip(file).catch((err) => {
    // eslint-disable-next-line no-console
    console.log("EML zip read", err);
  });

  if (results?.length) {
    return results;
  }

  const result = await readEml(await file.text()).catch((err) => {
    // eslint-disable-next-line no-console
    console.log("EML read", file.name, err);
  });
  if (result) return [result];

  return [];
}

async function loadEMLZip(file: File): Promise<Record<string, any>[]> {
  const data = await JSZip.loadAsync(file.arrayBuffer());

  return (await Promise.all(
    Object.keys(data.files)
      .filter((key) => key.endsWith(".eml"))
      .map((key) => data.files[key].async("text").then(readEml))
  )) as Record<string, any>[];
}

function readEml(data: any) {
  return new Promise((resolve, reject) => {
    emlformat.read(data, undefined, (err: Error, parsed: any) => {
      if (err) reject(err);
      resolve(parsed);
    });
  });
}
