import React, { useCallback } from "react";
import { Card, Tag } from "antd";
import { useHistory } from "react-router-dom";
import * as queryString from "query-string";

import { StatusColor } from "../../../../../constants";
import { AntGrayTag } from "../../components/styledComponents";
import useEmailParam from "../../../../hooks/useEmailParam";
import { DeclarationCardHint } from "./style";

export interface DeclarationCardProps {
  taxYear: number;
  title: string;
  path: string;
  queryParams?: Record<string, any>;
  gridArea: string;
  statusInfo: { color: StatusColor; label: string };
  hint?: string;
}

const DeclarationCard = ({
  taxYear,
  title,
  path,
  queryParams = {},
  gridArea,
  statusInfo,
  hint,
}: DeclarationCardProps) => {
  const history = useHistory();
  const [email] = useEmailParam();

  const goToDeclaration = useCallback(() => {
    history.push({
      pathname: path,
      search: queryString.stringify({
        email,
        year: taxYear,
        ...queryParams,
      }),
    });
  }, [history, queryParams, path, email, taxYear]);

  return (
    <Card
      title={title}
      size="small"
      hoverable
      onClick={goToDeclaration}
      style={{ gridArea }}
    >
      {statusInfo.color === StatusColor.GRAY ? (
        <AntGrayTag>{statusInfo.label}</AntGrayTag>
      ) : (
        <Tag color={statusInfo.color}>{statusInfo.label}</Tag>
      )}
      {hint && <DeclarationCardHint>{hint}</DeclarationCardHint>}
    </Card>
  );
};

export default DeclarationCard;
