import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import LanguageDetector from "i18next-browser-languagedetector";

import enTranslations from "./en";
import deTranslations from "./de";

export enum LanguageCode {
  ENGLISH = "en",
  GERMAN = "de",
}

export const MISSING_TRANSLATION = "Missing Translation";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: {
      // order and from where user language should be detected
      order: ["localStorage"],
      // cache user language on
      caches: ["localStorage"],
    },
    fallbackLng: LanguageCode.GERMAN,
    resources: {
      en: enTranslations,
      de: deTranslations,
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
