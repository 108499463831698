import type * as Types from "../../schema.generated";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type AddAnnotationMutationVariables = Types.Exact<{
  payload: Types.AnnotationValueInput;
}>;

export type AddAnnotationMutation = {
  __typename?: "Mutation";
  addAnnotation: {
    __typename?: "Annotation";
    id: string;
    start: number;
    end: number;
    tag: string;
    color: string;
    text: string;
  };
};

export const AddAnnotationDocument = gql`
  mutation addAnnotation($payload: AnnotationValueInput!) {
    addAnnotation(payload: $payload) {
      id
      start
      end
      tag
      color
      text
    }
  }
`;
export type AddAnnotationMutationFn = Apollo.MutationFunction<
  AddAnnotationMutation,
  AddAnnotationMutationVariables
>;

/**
 * __useAddAnnotationMutation__
 *
 * To run a mutation, you first call `useAddAnnotationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAnnotationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAnnotationMutation, { data, loading, error }] = useAddAnnotationMutation({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useAddAnnotationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddAnnotationMutation,
    AddAnnotationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddAnnotationMutation,
    AddAnnotationMutationVariables
  >(AddAnnotationDocument, options);
}
export type AddAnnotationMutationHookResult = ReturnType<
  typeof useAddAnnotationMutation
>;
export type AddAnnotationMutationResult =
  Apollo.MutationResult<AddAnnotationMutation>;
export type AddAnnotationMutationOptions = Apollo.BaseMutationOptions<
  AddAnnotationMutation,
  AddAnnotationMutationVariables
>;
