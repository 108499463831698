import type * as Types from "../schema.generated";

import { gql } from "@apollo/client";
export type AnschreibenFragment = {
  __typename?: "Anschreiben";
  id: string;
  content: string;
  year: number;
  note?: string | null;
  approvedAt?: string | null;
  rejectedAt?: string | null;
  exportedAt?: string | null;
};

export const AnschreibenFragmentDoc = gql`
  fragment Anschreiben on Anschreiben {
    id
    content
    year
    note
    approvedAt
    rejectedAt
    exportedAt
  }
`;
