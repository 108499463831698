export const MANUAL_ACTIVITIES = [
  "Gesonderte und einheitliche Feststellung (wenn anderer Beriebssitz)",
  "Einsprüche",
  "Fragen zur Rechnungsstellung (In- und Ausland) einschließlich Korrektur von Rechnungen",
  "Prüfung Kleinunternehmerregelung vs. Regelbesteuerung",
  "Jahresendgespräch",
  "Beratungen zu ESOP, VSOPs, o.ä.",
  "Beratung zum Wegzug aus Deutschland",
  "Beratung im Zusammenhang mit Abfindungen",
  "Beurteilung von Beteiligungen an anderen Gesellschaften (In- und Ausland)",
  "Umzug beim Finanzamt melden",
  "USt ID beantragen",
  "Dauerfristverlängerung beantragen",
  "Erstberatung",
  "Sonstige Leistungen",
];

export const MANUAL_ACTIVITY_OPTIONS = MANUAL_ACTIVITIES.map((value) => ({
  value,
  label: value,
}));
