import * as GQL from "../api/graphql/schema.generated";
import {
  IncomingCategoryCode,
  OutgoingCategoryCode,
  VatCategoryCode,
} from "../types";
import { createCodec } from "./codec";

export const vatCategories = [
  [VatCategoryCode.INCOME_19, "81 Erlöse 19 %"],
  [VatCategoryCode.INCOME_7, "82 Erlöse 7 %"],
  [VatCategoryCode.INCOME_16, "83 Erlöse 16 %"],
  [VatCategoryCode.INCOME_5, "84 Erlöse 5 %"],
  [VatCategoryCode.INCOME_0_ITD, "85 Erlöse 0 % mit VSt-Abzug"],
  [VatCategoryCode.INCOME_0, "86 Erlöse 0 % ohne VSt-Abzug"],
  [VatCategoryCode.INCOME_EU_B2B, "70 Erlöse EU Sonst. Leistung (B2B)"],
  [VatCategoryCode.INCOME_EU_INTRA_B2B, "60 Erlöse EU Innerg. Lieferung (B2B)"],
  [
    VatCategoryCode.INCOME_EU_INTRA_B2C_19,
    "61 Erlöse EU Innerg. Lieferung (B2C) 19 %",
  ],
  [
    VatCategoryCode.INCOME_EU_INTRA_B2C_7,
    "62 Erlöse EU Innerg. Lieferung (B2C) 7 %",
  ],
  [
    VatCategoryCode.INCOME_EU_INTRA_B2C_16,
    "63 Erlöse EU Innerg. Lieferung (B2C) 16 %",
  ],
  [
    VatCategoryCode.INCOME_EU_INTRA_B2C_5,
    "64 Erlöse EU Innerg. Lieferung (B2C) 5 %",
  ],
  [VatCategoryCode.INCOME_EU_B2C_19, "71 Erlöse EU Sonst. Leistung (B2C) 19 %"],
  [VatCategoryCode.INCOME_EU_B2C_7, "72 Erlöse EU Sonst. Leistung (B2C) 7 %"],
  [VatCategoryCode.INCOME_EU_B2C_16, "73 Erlöse EU Sonst. Leistung (B2C) 16 %"],
  [VatCategoryCode.INCOME_EU_B2C_5, "74 Erlöse EU Sonst. Leistung (B2C) 5 %"],
  [VatCategoryCode.EXPORT_DELIVERY, "51 Ausfuhrlieferungen"],
  [VatCategoryCode.NON_TAXABLE, "52 Übrige nicht steuerbare Umsätze"],
  [VatCategoryCode.INCOME_13B5_USTG, "53 Erlöse § 13b Abs. 5 UStG"],
  [VatCategoryCode.DIT_19, "11 Abziehbare VSt 19 %"],
  [VatCategoryCode.DIT_7, "12 Abziehbare VSt 7 %"],
  [VatCategoryCode.DIT_16, "13 Abziehbare VSt 16 %"],
  [VatCategoryCode.DIT_5, "14 Abziehbare VSt 5 %"],
  [VatCategoryCode.INTRA_ACQUISITION_IT, "15 Innerg. Erwerb (Vorsteuer)"],
  [
    VatCategoryCode.REVERSE_CHARGE_IT,
    "16 Reverse Charge (Vorsteuer) {Leistung}",
  ],
  [VatCategoryCode.REVERSE_CHARGE, "17 Reverse Charge (ohne Vorsteuer)"],
  [VatCategoryCode.NO_ITD, "18 Kein Vorsteuerabzug"],
  [VatCategoryCode.NO_VAT, "10 Keine Umsatzsteuer"],
];

export const EXCLUDED_VCC_FOR_NO_VAT_NUMBER = [
  VatCategoryCode.INCOME_EU_B2B,
  VatCategoryCode.INCOME_EU_INTRA_B2B,
  VatCategoryCode.INCOME_EU_INTRA_B2C_19,
  VatCategoryCode.INCOME_EU_INTRA_B2C_7,
  VatCategoryCode.INCOME_EU_INTRA_B2C_16,
  VatCategoryCode.INCOME_EU_INTRA_B2C_5,
  VatCategoryCode.INCOME_EU_B2C_19,
  VatCategoryCode.INCOME_EU_B2C_7,
  VatCategoryCode.INCOME_EU_B2C_16,
  VatCategoryCode.INCOME_EU_B2C_5,
  VatCategoryCode.INTRA_ACQUISITION_IT,
  VatCategoryCode.REVERSE_CHARGE_IT,
  VatCategoryCode.REVERSE_CHARGE,
];

const INCOME_GERMANY_CATEGORIES = [
  VatCategoryCode.INCOME_19,
  VatCategoryCode.INCOME_7,
  VatCategoryCode.INCOME_16,
  VatCategoryCode.INCOME_5,
  VatCategoryCode.INCOME_0_ITD,
  VatCategoryCode.INCOME_0,
];
const INCOME_EU_CATEGORIES = [
  VatCategoryCode.INCOME_EU_B2B,
  VatCategoryCode.INCOME_EU_INTRA_B2B,
  VatCategoryCode.INCOME_EU_INTRA_B2C_19,
  VatCategoryCode.INCOME_EU_INTRA_B2C_7,
  VatCategoryCode.INCOME_EU_INTRA_B2C_16,
  VatCategoryCode.INCOME_EU_INTRA_B2C_5,
  VatCategoryCode.INCOME_EU_B2C_19,
  VatCategoryCode.INCOME_EU_B2C_7,
  VatCategoryCode.INCOME_EU_B2C_16,
  VatCategoryCode.INCOME_EU_B2C_5,
];

const INCOME_INTL_CATEGORIES = [
  VatCategoryCode.EXPORT_DELIVERY,
  VatCategoryCode.NON_TAXABLE,
];
const CONSTRUCTION_REVENUE_CATEGORIES = [VatCategoryCode.INCOME_13B5_USTG];
const REVENUE_SB_CATEGORIES = [VatCategoryCode.NO_VAT];
const OTHER_USAGE_AND_SERVICE_WITHDRAWALS_CATEGORIES = [VatCategoryCode.NO_VAT];

const INCOME_FREE_VALUE_DELIVERY_CATEGORIES = [
  VatCategoryCode.INCOME_19,
  VatCategoryCode.INCOME_7,
  VatCategoryCode.NO_VAT,
];
const INCOME_FREE_VALUE_DELIVERY_PV_19_CATEGORIES = [VatCategoryCode.INCOME_19];
const INCOME_FREE_VALUE_SERVICE_CATEGORIES = [
  VatCategoryCode.INCOME_19,
  VatCategoryCode.INCOME_7,
  VatCategoryCode.NO_VAT,
];
const ELECTRONIC_SERVICE_EU_B2C_KU_CATEGORIES = [VatCategoryCode.NO_VAT];
const INCOME_ONLY_VAT_CATEGORIES = [
  VatCategoryCode.INCOME_19,
  VatCategoryCode.INCOME_7,
];
const ELECTRONIC_SERVICE_EU_B2C_CATEGORIES = [
  VatCategoryCode.INCOME_EU_B2C_19,
  VatCategoryCode.INCOME_EU_B2C_7,
];

const filterCategoriesByCodes = (codes: string[]) => {
  return vatCategories.filter((category) => {
    return codes.includes(category[0]);
  });
};

export const getCompatibleVatCategories = (
  kontaxCategory: string | null,
  isSmallBusinessOwner?: boolean
) => {
  if (kontaxCategory === IncomingCategoryCode.INCOME_GERMANY) {
    return filterCategoriesByCodes(INCOME_GERMANY_CATEGORIES);
  }

  if (kontaxCategory === IncomingCategoryCode.INCOME_EU) {
    return filterCategoriesByCodes(INCOME_EU_CATEGORIES);
  }

  if (kontaxCategory === IncomingCategoryCode.INCOME_INTL) {
    return filterCategoriesByCodes(INCOME_INTL_CATEGORIES);
  }

  if (kontaxCategory === IncomingCategoryCode.CONSTRUCTION_REVENUE) {
    return filterCategoriesByCodes(CONSTRUCTION_REVENUE_CATEGORIES);
  }

  if (
    kontaxCategory === IncomingCategoryCode.OTHER_USAGE_AND_SERVICE_WITHDRAWALS
  ) {
    return filterCategoriesByCodes(
      OTHER_USAGE_AND_SERVICE_WITHDRAWALS_CATEGORIES
    );
  }

  if (kontaxCategory === IncomingCategoryCode.FREE_VALUE_DELIVERY) {
    return filterCategoriesByCodes(INCOME_FREE_VALUE_DELIVERY_CATEGORIES);
  }

  if (kontaxCategory === IncomingCategoryCode.FREE_VALUE_DELIVERY_PV_19) {
    return filterCategoriesByCodes(INCOME_FREE_VALUE_DELIVERY_PV_19_CATEGORIES);
  }

  if (kontaxCategory === IncomingCategoryCode.FREE_VALUE_SERVICE) {
    return filterCategoriesByCodes(INCOME_FREE_VALUE_SERVICE_CATEGORIES);
  }

  if (kontaxCategory === IncomingCategoryCode.ELECTRONIC_SERVICE_EU_B2C_KU) {
    return filterCategoriesByCodes(ELECTRONIC_SERVICE_EU_B2C_KU_CATEGORIES);
  }
  if (kontaxCategory === IncomingCategoryCode.INCOME_ONLY_VAT) {
    return filterCategoriesByCodes(INCOME_ONLY_VAT_CATEGORIES);
  }
  if (kontaxCategory === IncomingCategoryCode.ELECTRONIC_SERVICE_EU_B2C) {
    return filterCategoriesByCodes(ELECTRONIC_SERVICE_EU_B2C_CATEGORIES);
  }

  if (
    [
      IncomingCategoryCode.CORONA_HELP,
      IncomingCategoryCode.REVENUE_SB,
      IncomingCategoryCode.PRIVATE_IN,
      IncomingCategoryCode.VAT_ON_UNPAID_ITEMS,
      IncomingCategoryCode.VAT_REFUND,
      IncomingCategoryCode.TAX_REFUND,
      IncomingCategoryCode.TRADE_TAX_REFUND,
    ].includes(kontaxCategory as IncomingCategoryCode)
  ) {
    return filterCategoriesByCodes(REVENUE_SB_CATEGORIES);
  }

  if (
    [
      OutgoingCategoryCode.INTERESTS_CAR_ASSETS,
      OutgoingCategoryCode.INTERESTS_ASSETS,
      OutgoingCategoryCode.INSURANCES,
      OutgoingCategoryCode.VAT,
      OutgoingCategoryCode.DAILY_ALLOWANCE,
      OutgoingCategoryCode.PRIVATE_WITHDRAWAL,
      OutgoingCategoryCode.PAYROLL,
      OutgoingCategoryCode.IMPORT_VAT,
      OutgoingCategoryCode.VAT_PAYMENT,
      OutgoingCategoryCode.PRIVATE_OUT,
      OutgoingCategoryCode.TAX_PAYMENT,
      OutgoingCategoryCode.TRADE_TAX_PAYMENT,
      OutgoingCategoryCode.DOWN_PAYMENT,
    ].includes(kontaxCategory as OutgoingCategoryCode)
  ) {
    return filterCategoriesByCodes([VatCategoryCode.NO_ITD]);
  }

  if (
    [
      OutgoingCategoryCode.GOODS,
      OutgoingCategoryCode.EXTERNAL_FREELANCER,
      OutgoingCategoryCode.ASSETS_LESS_THAN_EUR_250,
      OutgoingCategoryCode.ASSETS_GREATER_THAN_EUR_250,
      OutgoingCategoryCode.ASSETS_GREATER_THAN_EUR_800,
      OutgoingCategoryCode.LEASING_CAR,
      OutgoingCategoryCode.CAR_FEES,
      OutgoingCategoryCode.TRAVEL_COSTS,
      OutgoingCategoryCode.CAR_COSTS,
      OutgoingCategoryCode.PUBLIC_TRANSPORT,
      OutgoingCategoryCode.RENT,
      OutgoingCategoryCode.ENTERTAINMENT,
      OutgoingCategoryCode.LIMITED_DEDUCTIBLE_EXPENSES,
      OutgoingCategoryCode.GIFTS,
      OutgoingCategoryCode.LIMITED_NOT_DEDUCTIBLE_EXPENSES,
      OutgoingCategoryCode.OTHER_EXPENSES,
      OutgoingCategoryCode.BANK_FEES,
      OutgoingCategoryCode.LEGAL_TAX_CONSULTING,
      OutgoingCategoryCode.ACCOMMODATION,
      OutgoingCategoryCode.LEASING_MOVABLES,
      OutgoingCategoryCode.FEES,
      OutgoingCategoryCode.ADVERTISING,
      OutgoingCategoryCode.MAINTENANCE_COSTS,
      OutgoingCategoryCode.WASTE_DISPOSALS,
      OutgoingCategoryCode.SHIPPING_COSTS,
      OutgoingCategoryCode.IT_COSTS,
      OutgoingCategoryCode.OFFICE_COSTS,
      OutgoingCategoryCode.SOFTWARE_AND_LICENSES,
      OutgoingCategoryCode.BOOKS,
      OutgoingCategoryCode.INTERESTS_OTHER,
      OutgoingCategoryCode.TELECOMMUNICATION,
      OutgoingCategoryCode.EDUCATION,
    ].includes(kontaxCategory as OutgoingCategoryCode)
  ) {
    if (isSmallBusinessOwner) {
      return filterCategoriesByCodes([
        VatCategoryCode.NO_ITD,
        VatCategoryCode.REVERSE_CHARGE,
      ]);
    }

    return filterCategoriesByCodes([
      VatCategoryCode.DIT_19,
      VatCategoryCode.DIT_7,
      VatCategoryCode.DIT_16,
      VatCategoryCode.DIT_5,
      VatCategoryCode.INTRA_ACQUISITION_IT,
      VatCategoryCode.REVERSE_CHARGE_IT,
      VatCategoryCode.REVERSE_CHARGE,
      VatCategoryCode.NO_ITD,
    ]);
  }

  // For any other Kontax category, all VAT categories are allowed.
  return vatCategories;
};

export const vatCategoryCodeCodec = createCodec([
  [GQL.VatCategoryCode.DIT_16, VatCategoryCode.DIT_16],
  [GQL.VatCategoryCode.DIT_19, VatCategoryCode.DIT_19],
  [GQL.VatCategoryCode.DIT_5, VatCategoryCode.DIT_5],
  [GQL.VatCategoryCode.DIT_7, VatCategoryCode.DIT_7],
  [GQL.VatCategoryCode.EXPORT_DELIVERY, VatCategoryCode.EXPORT_DELIVERY],
  [GQL.VatCategoryCode.INCOME_0, VatCategoryCode.INCOME_0],
  [GQL.VatCategoryCode.INCOME_0_ITD, VatCategoryCode.INCOME_0_ITD],
  [GQL.VatCategoryCode.INCOME_13B5_USTG, VatCategoryCode.INCOME_13B5_USTG],
  [GQL.VatCategoryCode.INCOME_16, VatCategoryCode.INCOME_16],
  [GQL.VatCategoryCode.INCOME_19, VatCategoryCode.INCOME_19],
  [GQL.VatCategoryCode.INCOME_5, VatCategoryCode.INCOME_5],
  [GQL.VatCategoryCode.INCOME_7, VatCategoryCode.INCOME_7],
  [GQL.VatCategoryCode.INCOME_EU_B2B, VatCategoryCode.INCOME_EU_B2B],
  [GQL.VatCategoryCode.INCOME_EU_B2C_16, VatCategoryCode.INCOME_EU_B2C_16],
  [GQL.VatCategoryCode.INCOME_EU_B2C_19, VatCategoryCode.INCOME_EU_B2C_19],
  [GQL.VatCategoryCode.INCOME_EU_B2C_5, VatCategoryCode.INCOME_EU_B2C_5],
  [GQL.VatCategoryCode.INCOME_EU_B2C_7, VatCategoryCode.INCOME_EU_B2C_7],
  [
    GQL.VatCategoryCode.INCOME_EU_INTRA_B2B,
    VatCategoryCode.INCOME_EU_INTRA_B2B,
  ],
  [
    GQL.VatCategoryCode.INCOME_EU_INTRA_B2C_16,
    VatCategoryCode.INCOME_EU_INTRA_B2C_16,
  ],
  [
    GQL.VatCategoryCode.INCOME_EU_INTRA_B2C_19,
    VatCategoryCode.INCOME_EU_INTRA_B2C_19,
  ],
  [
    GQL.VatCategoryCode.INCOME_EU_INTRA_B2C_5,
    VatCategoryCode.INCOME_EU_INTRA_B2C_5,
  ],
  [
    GQL.VatCategoryCode.INCOME_EU_INTRA_B2C_7,
    VatCategoryCode.INCOME_EU_INTRA_B2C_7,
  ],
  [
    GQL.VatCategoryCode.INTRA_ACQUISITION_IT,
    VatCategoryCode.INTRA_ACQUISITION_IT,
  ],
  [GQL.VatCategoryCode.NON_TAXABLE, VatCategoryCode.NON_TAXABLE],
  [GQL.VatCategoryCode.NO_ITD, VatCategoryCode.NO_ITD],
  [GQL.VatCategoryCode.NO_VAT, VatCategoryCode.NO_VAT],
  [GQL.VatCategoryCode.REVERSE_CHARGE, VatCategoryCode.REVERSE_CHARGE],
  [GQL.VatCategoryCode.REVERSE_CHARGE_IT, VatCategoryCode.REVERSE_CHARGE_IT],
] as const);
