import type * as Types from "../../schema.generated";

import { gql } from "@apollo/client";
import { AnschreibenFragmentDoc } from "../../fragments/anschreiben.generated";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type CreateAnschreibenMutationVariables = Types.Exact<{
  email: Types.Scalars["String"];
  payload: Types.CreateAnschreibenInput;
}>;

export type CreateAnschreibenMutation = {
  __typename?: "Mutation";
  createAnschreiben: {
    __typename?: "Anschreiben";
    id: string;
    content: string;
    year: number;
    note?: string | null;
    approvedAt?: string | null;
    rejectedAt?: string | null;
    exportedAt?: string | null;
  };
};

export const CreateAnschreibenDocument = gql`
  mutation createAnschreiben(
    $email: String!
    $payload: CreateAnschreibenInput!
  ) {
    createAnschreiben(email: $email, payload: $payload) {
      ...Anschreiben
    }
  }
  ${AnschreibenFragmentDoc}
`;
export type CreateAnschreibenMutationFn = Apollo.MutationFunction<
  CreateAnschreibenMutation,
  CreateAnschreibenMutationVariables
>;

/**
 * __useCreateAnschreibenMutation__
 *
 * To run a mutation, you first call `useCreateAnschreibenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAnschreibenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAnschreibenMutation, { data, loading, error }] = useCreateAnschreibenMutation({
 *   variables: {
 *      email: // value for 'email'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useCreateAnschreibenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAnschreibenMutation,
    CreateAnschreibenMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateAnschreibenMutation,
    CreateAnschreibenMutationVariables
  >(CreateAnschreibenDocument, options);
}
export type CreateAnschreibenMutationHookResult = ReturnType<
  typeof useCreateAnschreibenMutation
>;
export type CreateAnschreibenMutationResult =
  Apollo.MutationResult<CreateAnschreibenMutation>;
export type CreateAnschreibenMutationOptions = Apollo.BaseMutationOptions<
  CreateAnschreibenMutation,
  CreateAnschreibenMutationVariables
>;
