import { Transaction } from "../../../types/transactions";

export enum MatchStatus {
  TOO_MANY_MATCHES = "TOO_MANY_MATCHES",
  NO_MATCHES = "NO_MATCHES",
  LATER_MATCH = "LATER_MATCH",
  ALREADY_HAS_ASSET = "ALREADY_HAS_ASSET",
  OTHER_PROVIDER_MATCH = "OTHER_PROVIDER_MATCH",
  WRONG_MATCH = "WRONG_MATCH",
}

export interface EmailDocument {
  id: string;
  filename: string;
  url: string;
  createdAt: Date;
  transactionMatches: Transaction[];
  name?: string;
  documentNumber?: string;
  iban?: string;
  transactionId?: string;
  currency?: string;
  amount?: number;
  matchStatus?: MatchStatus;
  date?: Date;
}
