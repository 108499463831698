import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Divider, Row, Skeleton, Typography, Radio, Select } from "antd";

import { ExclamationCircleOutlined } from "@ant-design/icons";

import { useBusinessAddressOverviewQuery } from "../../../../../../../api/graphql";
import { TradeTaxDeclarationPreparationField } from "./TradeTaxDeclarationPreparationField";
import { useUserContext } from "../../../../contexts/UserContext";

import {
  formatAddress,
  formatBoolean,
  formatDate,
  navigateToMandatenView,
} from "../../../../utils";
import { AutoRow, ContentWrapper } from "../../styledComponents";
import { filterCities } from "../../utils";
import {
  Municipality,
  useMunicipalitiesQuery,
} from "../../../../../../../api/graphql/queries/tradeTaxDeclaration/municipalities";
import { useFormatMunicipalitiesMemo } from "../../../../hooks/useFormatMunicipalitiesMemo";
import colors from "../../../../../../../themes/colors";

const { Paragraph, Text } = Typography;

const options = [
  { label: formatBoolean(true), value: true },
  { label: formatBoolean(false), value: false },
];

export interface TradeTaxDeclarationPreparationProps {
  email: string;
  taxYear: number;
  hasMultipleMunicipalities: boolean;
  onHasMultipleMunicipalitiesChange: (
    hasMultipleMunicipalities: boolean
  ) => void;
  municipality: string | null;
  municipalityTaxRate: number;
  onMunicipalityChange: (municipality: string | null) => void;
  onMunicipalityTaxRateChange: (municipalityTaxRate: number) => void;
}

export const TradeTaxDeclarationPreparation = ({
  email,
  taxYear,
  hasMultipleMunicipalities,
  onHasMultipleMunicipalitiesChange,
  municipality,
  municipalityTaxRate,
  onMunicipalityChange,
  onMunicipalityTaxRateChange,
}: TradeTaxDeclarationPreparationProps) => {
  const [localMunicipality, setLocalMunicipality] = useState<string | null>(
    municipality
  );
  const [localTaxRate, setLocalTaxRate] = useState<number>(municipalityTaxRate);
  const user = useUserContext();
  const {
    data: businessAddressOverviewData,
    loading: isBusinessAddressOverviewLoading,
  } = useBusinessAddressOverviewQuery({
    variables: { year: taxYear, email: email },
  });

  useEffect(() => {
    // update municipality on consecutive renders by taxYear selection
    setLocalMunicipality(municipality);
  }, [municipality]);

  const { data: municipalityData } = useMunicipalitiesQuery({
    fetchPolicy: "cache-first",
  });

  const municipalityNameAndTaxRateData = municipalityData?.municipalities;

  const municipalityOptions = useFormatMunicipalitiesMemo(
    municipalityNameAndTaxRateData!
  );

  const autoMunicipality = useMemo(
    () =>
      municipalityNameAndTaxRateData?.find(
        (item) =>
          item.name === businessAddressOverviewData?.businessAddress.city
      ),
    [municipalityNameAndTaxRateData, businessAddressOverviewData]
  );

  const onChange = useCallback(
    (newMunicipality: Municipality) => {
      onMunicipalityChange(newMunicipality.name);
      onMunicipalityTaxRateChange(newMunicipality.taxRate);
      setLocalMunicipality(newMunicipality.name);
      setLocalTaxRate(newMunicipality.taxRate);
    },
    [onMunicipalityChange, onMunicipalityTaxRateChange]
  );

  const navigateToMandatenViewHandler = useCallback(() => {
    navigateToMandatenView(email);
  }, [email]);

  if (isBusinessAddressOverviewLoading || !municipalityNameAndTaxRateData) {
    return <Skeleton active />;
  }

  return (
    <ContentWrapper>
      <Paragraph>
        <Text strong>Betriebsstätte</Text>
      </Paragraph>

      <TradeTaxDeclarationPreparationField
        label="Unternehmereigenschaft Start"
        value={formatDate(user?.kontaxUser?.businessStartDate)}
        onEdit={navigateToMandatenViewHandler}
      />
      <TradeTaxDeclarationPreparationField
        label="Unternehmereigenschaft Ende"
        value={formatDate(user?.kontaxUser?.businessEndDate)}
        onEdit={navigateToMandatenViewHandler}
      />
      <TradeTaxDeclarationPreparationField
        label="Betriebliche Adresse"
        value={formatAddress(businessAddressOverviewData?.businessAddress)}
        onEdit={navigateToMandatenViewHandler}
      />
      <TradeTaxDeclarationPreparationField
        label="Private Adresse wird als betriebliche Adresse verwendet"
        value={formatBoolean(
          businessAddressOverviewData?.businessAddress.isSameAsPrivateAddress
        )}
      />

      <Paragraph className="pt-4">
        <Text strong>Gemeinde</Text>
      </Paragraph>
      <Row align="middle">
        <Col flex="1">
          <Text style={{ maxWidth: "55%" }}>Gemeinde</Text>
        </Col>
        <Col style={{ paddingRight: "15px" }}>
          <div className="ant-input-number-group-wrapper">
            <div
              className="ant-input-number-wrapper ant-input-number-group"
              style={{ width: 290 }}
            >
              {autoMunicipality && localMunicipality === autoMunicipality.name && (
                <div className="ant-input-number-group-addon">
                  <span>Auto</span>
                </div>
              )}
              <div className="ant-input-number">
                <Select
                  bordered={false}
                  defaultValue={localMunicipality}
                  filterOption={filterCities}
                  onSelect={(value: string) => {
                    let [changedMunicipality, changedTaxRate] =
                      value.split("#");
                    onChange({
                      name: changedMunicipality,
                      taxRate: parseInt(changedTaxRate),
                    });
                  }}
                  optionFilterProp="children"
                  options={municipalityOptions}
                  placeholder="Suchen und auswählen"
                  style={{ width: "100%" }}
                  showSearch
                  value={localMunicipality}
                ></Select>
              </div>
            </div>
          </div>
          {autoMunicipality && localMunicipality !== autoMunicipality.name && (
            <AutoRow
              className="ant-typography ant-typography-secondary"
              onClick={() => onChange(autoMunicipality)}
            >
              <Col>
                <span>Auto</span>
              </Col>
              <Col>
                <span>{autoMunicipality.name}</span>
              </Col>
            </AutoRow>
          )}
        </Col>
      </Row>
      <Divider className="my-2" />

      <Row align="middle">
        <Col flex="1">
          <Text>Hebesatz</Text>
        </Col>
        <Col style={{ paddingRight: "15px" }}>
          {localMunicipality ? <span>{localTaxRate} %</span> : <span>-</span>}
        </Col>
      </Row>
      <Divider className="my-2" />
      <TradeTaxDeclarationPreparationField
        label={`Im Jahr ${taxYear} Betriebsstätte umgezogen?`}
        value={formatBoolean(
          !!businessAddressOverviewData?.businessAddressMovement
        )}
        onEdit={navigateToMandatenViewHandler}
      />
      {businessAddressOverviewData?.businessAddressMovement && (
        <>
          <TradeTaxDeclarationPreparationField
            label={`Einzugsdatum in ${taxYear}`}
            value={formatDate(
              businessAddressOverviewData?.businessAddressMovement.movingDate
            )}
            onEdit={navigateToMandatenViewHandler}
          />
          <TradeTaxDeclarationPreparationField
            label="Alte betriebliche Adresse"
            value={formatAddress(
              businessAddressOverviewData?.businessAddressMovement
                .previousBusinessAddress
            )}
            onEdit={navigateToMandatenViewHandler}
          />
        </>
      )}
      <Row align="middle">
        <Col flex="1">
          <Text style={{ maxWidth: "55%" }}>
            {`Betriebsstätten bestanden ${taxYear} in mehreren Gemeinden`}
          </Text>
        </Col>
        <Col style={{ paddingRight: "15px" }}>
          <Radio.Group
            options={options}
            optionType="button"
            onChange={(e) => {
              onHasMultipleMunicipalitiesChange(e.target.value);
            }}
            value={hasMultipleMunicipalities}
          />
        </Col>
      </Row>
      {hasMultipleMunicipalities && (
        <Row
          align="top"
          style={{ color: colors.lightGreyOpacity, marginTop: "6px" }}
        >
          <ExclamationCircleOutlined
            type="secondary"
            style={{ marginTop: "5px" }}
          />
          <span
            style={{
              width: "432px",
              marginLeft: "6px",
              fontSize: "12px",
            }}
          >
            Eine Gewerbesteuerzerlegungserklärung muss zusätzlich in Mein ELSTER
            erstellt und abgeschickt werden.
          </span>
        </Row>
      )}
    </ContentWrapper>
  );
};
