import React, { useMemo, useState } from "react";
import { Button, Tabs, Tag, Typography } from "antd";
import { useLocation, useParams } from "react-router-dom";
import groupBy from "lodash/groupBy";

import { ExportOutlined } from "@ant-design/icons";

import moment from "moment";

import useEmailParam from "../../../../../../hooks/useEmailParam";
import useTaxYearParam from "../../../../hooks/useTaxYearParam";
import EmptyWrapper from "../../../../../../common/EmptyWrapper";
import { ANSWERS, QUESTIONS } from "./constants";
import {
  GREYED_OUT_CLASS,
  InformationWrapper,
  StyledTable,
} from "../styledComponents";
import {
  QuestionnaireAnswerDocumentsStatus,
  QuestionnaireQuestion,
  QuestionnaireAnswer as QuestionnaireAnswerType,
} from "../../../../../../../api/graphql";
import {
  getQuestionnaireAnswerValue,
  getQuestionnaireInfo,
  getQuestionnaireUpdatedAtValue,
} from "../utils";
import BackButton from "../../../EuerDeclaration/components/BackButton";
import QuestionnaireDetailedStatus from "../../QuestionnaireDetailedStatus";
import {
  Questionnaire,
  QuestionnaireAnswer,
  QuestionnaireType,
} from "../../../../../../../api/graphql/schema.generated";
import { useQuestionnaireQuery } from "../../../../../../../api/graphql/queries/questionnaire/questionnaire.generated";
import { QuestionnaireDocumentsTable } from "./QuestionnaireDocumentsTable";
import { useExportDocumentInputsMutation } from "../../../../../../../api/graphql/mutations/euerDeclaration/exportDocumentInputs.generated";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../../../../../utils";
import { useTaxCaseQuery } from "../../../../../../../api/graphql/queries/taxCase/taxCase.generated";
import { QUESTIONNAIRE_SYNC_SUPPORTED_AFTER } from "../../../EuerDeclaration/pages/TravelExpenses";
import { TAX_ADVISORY_PERMISSION_SCOPES } from "../../../../../../../constants";
import { getPermissionScope } from "../../../../../../../gapi";

const { Title } = Typography;

const { TabPane } = Tabs;

const SUPPORTED_TAX_YEAR = [2021, 2022, 2023, 2024];
const QUESTIONNAIRE_SYNC_SUPPORTED_TYPES = [
  QuestionnaireType.EOY_TRAVEL_EXPENSES,
  QuestionnaireType.EOY_CAR_USAGE,
  QuestionnaireType.EOY_OFFICE_USAGE,
  QuestionnaireType.EOY_BASIC_DATA,
];

const DOCUMENTS_STATUS_TAG_CONFIG: Record<
  QuestionnaireAnswerDocumentsStatus,
  { text: string; color?: string; style?: React.CSSProperties } | null
> = {
  [QuestionnaireAnswerDocumentsStatus.NOT_REQUIRED]: null,
  [QuestionnaireAnswerDocumentsStatus.PENDING]: {
    text: "Benötigt",
  },
  [QuestionnaireAnswerDocumentsStatus.UPLOADED]: {
    text: "Upload",
    color: "green",
  },
  [QuestionnaireAnswerDocumentsStatus.DELETED]: {
    text: "Benötigt",
    color: "green",
    style: { textDecoration: "line-through" },
  },
};

enum Tab {
  QUESTIONNAIRE = "1",
  SUPPORTING_DOCUMENTS = "2",
}
export interface UrlParams {
  type: QuestionnaireType;
  questionnaireId?: string;
}

const EndOfTheYearQuestionnaire = () => {
  const location = useLocation();
  const [taxYear] = useTaxYearParam();
  const [email] = useEmailParam();
  const [activeTab, setActiveTab] = useState<string>(Tab.QUESTIONNAIRE);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { type, questionnaireId } = useParams<UrlParams>();
  const questionnaireInfo = getQuestionnaireInfo(type);
  const hasTaxAdvisoryPermissionScope = TAX_ADVISORY_PERMISSION_SCOPES.includes(
    getPermissionScope()
  );

  const {
    data: questionnaireData,
    loading: isLoadingQuestionnaire,
    refetch: refetchUseQuestionnaireQuery,
  } = useQuestionnaireQuery({
    skip: !email || !type,
    variables: {
      ...(!!questionnaireId && { questionnaireId }),
      email: email!,
      type: type!,
      year: taxYear,
    },
  });
  const { data: taxCaseData, refetch: refetchTaxCaseQuery } = useTaxCaseQuery({
    skip: !email || !hasTaxAdvisoryPermissionScope,
    variables: {
      email: email!,
      year: taxYear,
    },
  });

  const [exportDocumentInputs] = useExportDocumentInputsMutation({
    variables: {
      email: email!,
      year: taxYear,
      type: type,
    },
  });

  const handleExportDocumentInputs = async () => {
    try {
      setIsLoading(true);
      await exportDocumentInputs();
      showSuccessMessage("Daten erfolgreich exportiert.");
    } catch (e) {
      showErrorMessage("Daten konnten nicht exportiert werden.");
    }
    await refetchTaxCaseQuery();
    await refetchUseQuestionnaireQuery();
    setIsLoading(false);
  };
  const questions = questionnaireData?.questionnaire?.questions;
  const taxCaseFinalized = taxCaseData?.taxCase.finalizedAt;

  const shouldDisableExportButton = useMemo(
    () => !taxCaseFinalized || !!questionnaireData?.questionnaire?.syncedAt,
    [taxCaseFinalized, questionnaireData]
  );

  const columns = useMemo(
    () => [
      {
        title: "Frage",
        key: "name",
        dataIndex: "name",
        width: "60%",
        render: (name: string) => {
          return QUESTIONS[name]
            ?.replaceAll("{{taxYear}}", taxYear.toString())
            .replaceAll("{{lastTaxYear}}", `${taxYear - 1}`);
        },
      },
      {
        title: "Antwort",
        key: "answer",
        width: "15%",
        render: (question: QuestionnaireQuestion) =>
          getQuestionnaireAnswerValue(question, ANSWERS),
      },
      {
        title: "Dokument",
        key: "documents",
        dataIndex: "answer",
        width: "15%",
        render: (answer: QuestionnaireAnswer) => {
          if (!answer) {
            return null;
          }

          const tagConfig = DOCUMENTS_STATUS_TAG_CONFIG[answer.documentsStatus];
          if (!tagConfig) {
            return null;
          }

          const { text, color, style } = tagConfig;
          return (
            <Tag color={color} style={style}>
              {text}
            </Tag>
          );
        },
      },
      {
        title: "Bearbeitet",
        key: "updatedAt",
        dataIndex: "answer",
        width: "10%",
        render: (answer: QuestionnaireAnswer) =>
          getQuestionnaireUpdatedAtValue(answer as QuestionnaireAnswerType),
      },
    ],
    [taxYear]
  );

  const columnsByYear = useMemo(
    () =>
      taxYear > QUESTIONNAIRE_SYNC_SUPPORTED_AFTER
        ? [
            ...columns,
            {
              title: "Daten übernommen",
              key: "syncedAt",
              dataIndex: "answer",
              render: (answer: QuestionnaireAnswer) =>
                answer?.syncedAt
                  ? moment(answer.syncedAt).format("DD.MM.YYYY")
                  : "-",
            },
          ]
        : columns,
    [columns, taxYear]
  );

  const documents = useMemo(() => {
    const groupedDocuments = groupBy(
      questionnaireData?.questionnaire?.documents,
      "type"
    );
    return Object.keys(groupedDocuments).flatMap((type) =>
      groupedDocuments[type].map((document, index) => ({
        count: groupedDocuments[type].length,
        index: index + 1,
        ...document,
      }))
    );
  }, [questionnaireData?.questionnaire?.documents]);

  const onTabChange = (key: string) => {
    setActiveTab(key);
  };

  if (!email) {
    return <EmptyWrapper description="Suche nach einem Mandanten" />;
  }

  if (!SUPPORTED_TAX_YEAR.includes(taxYear)) {
    const startYear = SUPPORTED_TAX_YEAR[0];
    const endYear = SUPPORTED_TAX_YEAR[SUPPORTED_TAX_YEAR.length - 1];
    return (
      <EmptyWrapper
        description={`Fragebogen ist ab Jahr ${startYear} verfügbar bis ${endYear}`}
        redText
      />
    );
  }

  return (
    <>
      <BackButton
        text="Vorbereitung Steuererklärung"
        to={{
          pathname: "/tax-declaration/tax-preparation",
          search: location.search,
        }}
      />

      <Title
        level={3}
        className="m-0 mb-3"
      >{`${questionnaireInfo?.title} ${taxYear}`}</Title>

      <InformationWrapper>
        {type === QuestionnaireType.EOY_INCOME_TAX_CHILD && (
          <Title level={4} className="m-0 mb-3">
            Kinder: {questionnaireData?.questionnaire?.context.firstName}
          </Title>
        )}
        {[
          QuestionnaireType.EOY_INCOME_TAX_ADDITIONAL_INCOME,
          QuestionnaireType.EOY_INCOME_TAX_ADDITIONAL_INCOME_PARTNER,
        ].includes(type) && (
          <Title level={4} className="m-0 mb-3">
            {questionnaireInfo?.description}
          </Title>
        )}
        <QuestionnaireDetailedStatus
          questionnaire={questionnaireData?.questionnaire as Questionnaire}
        />
        {taxYear > QUESTIONNAIRE_SYNC_SUPPORTED_AFTER &&
          QUESTIONNAIRE_SYNC_SUPPORTED_TYPES.includes(type) && (
            <Button
              disabled={shouldDisableExportButton}
              icon={<ExportOutlined />}
              onClick={handleExportDocumentInputs}
              loading={isLoading}
            >
              Daten übernehmen
            </Button>
          )}
      </InformationWrapper>

      <Tabs activeKey={activeTab} onChange={onTabChange}>
        <TabPane tab="Fragebogen" key={Tab.QUESTIONNAIRE}>
          <StyledTable
            size="large"
            columns={columnsByYear}
            dataSource={questions}
            rowClassName={(record: any) =>
              !record.answer ? GREYED_OUT_CLASS : ""
            }
            sortDirections={[]}
            pagination={false}
            loading={isLoadingQuestionnaire}
          />
        </TabPane>
        <TabPane tab="Belege" key={Tab.SUPPORTING_DOCUMENTS}>
          <QuestionnaireDocumentsTable
            questionnaireTitle={questionnaireInfo?.title!}
            isLoadingQuestionnaire={isLoadingQuestionnaire}
            documents={documents!}
            syncedAt={questionnaireData?.questionnaire?.syncedAt || undefined}
          />
        </TabPane>
      </Tabs>
    </>
  );
};

export default EndOfTheYearQuestionnaire;
