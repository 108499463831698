import React, { ReactNode, useMemo } from "react";
import { Select } from "antd";
import { sortBy } from "lodash";

import {
  IVatAnnualDeclarationPeriodSelect,
  YEARLY_VAT_DECLARATION_PERIOD_OPTION,
  QUARTERLY_VAT_DECLARATION_PERIOD_OPTIONS,
  MONTHLY_VAT_DECLARATION_PERIOD_OPTIONS,
  VatAnnualDeclarationPeriod,
} from "../../../types";
import {
  FileDoneOutlinedIcon,
  NoDataIcon,
  OptionContainer,
} from "./styledComponents";

const VatAnnualDeclarationPeriodSelect: React.FunctionComponent<IVatAnnualDeclarationPeriodSelect> =
  (props) => {
    const { availablePeriods, value, onChange, taxYear } = props;

    const options: ReactNode[] = useMemo(
      () =>
        [
          YEARLY_VAT_DECLARATION_PERIOD_OPTION,
          ...sortBy(MONTHLY_VAT_DECLARATION_PERIOD_OPTIONS, "value"),
          ...QUARTERLY_VAT_DECLARATION_PERIOD_OPTIONS,
        ].map((option) => {
          // The yearly option is not prefixed with an icon because there is never a yearly advance declaration.
          const isYearlyOption =
            option.value === YEARLY_VAT_DECLARATION_PERIOD_OPTION.value;
          return (
            <Select.Option value={option.value} key={option.value}>
              <OptionContainer>
                {!isYearlyOption ? (
                  !availablePeriods?.includes(option.value) ? (
                    <NoDataIcon />
                  ) : (
                    <FileDoneOutlinedIcon />
                  )
                ) : null}
                {`${option.label}${isYearlyOption ? ` ${taxYear}` : ""}`}
              </OptionContainer>
            </Select.Option>
          );
        }),
      [availablePeriods, taxYear]
    );

    return (
      <Select
        defaultValue={VatAnnualDeclarationPeriod.YEAR}
        onChange={onChange}
        value={value}
        style={{ width: 160 }}
      >
        {options}
      </Select>
    );
  };

export default VatAnnualDeclarationPeriodSelect;
