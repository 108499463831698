import React from "react";
import { Form, InputNumber } from "antd";

import { EuerFormFieldProps } from "./types";

export const EuerNumericField = ({
  name,
  label,
  maxValue,
  value,
  addonBefore,
  addonAfter,
  disabled,
  integerOnly,
  prefix,
  onChange = () => {},
}: EuerFormFieldProps): JSX.Element => (
  <Form.Item name={name} label={label} initialValue={value}>
    <InputNumber
      type="number"
      min={0}
      max={maxValue}
      value={value}
      disabled={disabled}
      addonBefore={addonBefore}
      addonAfter={addonAfter}
      prefix={prefix}
      decimalSeparator=","
      onChange={(value) => onChange(name, value!)}
      parser={(value) => {
        return value && integerOnly
          ? parseInt(value)
          : parseFloat(value?.replace(",", ".") || "");
      }}
    />
  </Form.Item>
);
