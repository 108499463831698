import { notification } from "antd";

import {
  IMessageType,
  ITransaction,
  KontaxTransactionMeta,
} from "../../../../types";
import {
  destroyMessage,
  showSuccessMessage,
  showLoadingMessage,
  showMessage,
} from "../../../../utils";
import { hasVerifiedOnly } from "./utils";
import { isTransactionComplete } from "./VerificationField";

export const verifyTransactions = async ({
  selectedTransactions,
  updateTransactionsMeta,
  resetSelectedTransactions,
}: {
  selectedTransactions: ITransaction[];
  updateTransactionsMeta: (
    payload: Array<KontaxTransactionMeta>
  ) => Promise<boolean>;
  resetSelectedTransactions: () => void;
}) => {
  const hasUncategorizedTransactions = selectedTransactions.some(
    (transaction) => !isTransactionComplete(transaction)
  );

  if (hasUncategorizedTransactions) {
    showMessage({
      duration: 5,
      content: "It is just allowed to verify categorized transactions",
      type: IMessageType.ERROR,
    });
    return;
  }
  const LOADING_MESSAGE_KEY = "verify-selected-transactions";
  showLoadingMessage(LOADING_MESSAGE_KEY);

  const hasSelectedVerifiedTransactionsOnly =
    hasVerifiedOnly(selectedTransactions);

  const verified = hasSelectedVerifiedTransactionsOnly ? false : true;

  try {
    await updateTransactionsMeta(
      selectedTransactions.map((transaction) => ({
        kontistTransactionId: transaction.id,
        verified,
      }))
    );
    const successMessage = hasSelectedVerifiedTransactionsOnly
      ? "Successfully unverified transactions"
      : "Successfully verified transactions";
    showSuccessMessage(successMessage);
    resetSelectedTransactions();
  } catch (error) {
    const errorMessage = hasSelectedVerifiedTransactionsOnly
      ? "Error during unverification of selected transactions"
      : "Error during verification of selected transactions";
    notification.error({
      message: errorMessage,
      description: (error as Error).message,
    });
  } finally {
    destroyMessage(LOADING_MESSAGE_KEY);
  }
};
