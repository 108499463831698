import type * as Types from "../../schema.generated";

import { gql } from "@apollo/client";
import { AnschreibenTemplateFragmentDoc } from "../../fragments/anschreibenTemplate.generated";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type AnschreibenTemplateQueryVariables = Types.Exact<{
  type: Types.Scalars["String"];
  language: Types.Scalars["String"];
  year: Types.Scalars["Int"];
}>;

export type AnschreibenTemplateQuery = {
  __typename?: "Query";
  anschreibenTemplate?: {
    __typename?: "AnschreibenTemplate";
    id: string;
    content: string;
    type: string;
    language: string;
    year: number;
    note: string;
  } | null;
};

export const AnschreibenTemplateDocument = gql`
  query anschreibenTemplate($type: String!, $language: String!, $year: Int!) {
    anschreibenTemplate(type: $type, language: $language, year: $year) {
      ...AnschreibenTemplate
    }
  }
  ${AnschreibenTemplateFragmentDoc}
`;

/**
 * __useAnschreibenTemplateQuery__
 *
 * To run a query within a React component, call `useAnschreibenTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnschreibenTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnschreibenTemplateQuery({
 *   variables: {
 *      type: // value for 'type'
 *      language: // value for 'language'
 *      year: // value for 'year'
 *   },
 * });
 */
export function useAnschreibenTemplateQuery(
  baseOptions: Apollo.QueryHookOptions<
    AnschreibenTemplateQuery,
    AnschreibenTemplateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AnschreibenTemplateQuery,
    AnschreibenTemplateQueryVariables
  >(AnschreibenTemplateDocument, options);
}
export function useAnschreibenTemplateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AnschreibenTemplateQuery,
    AnschreibenTemplateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AnschreibenTemplateQuery,
    AnschreibenTemplateQueryVariables
  >(AnschreibenTemplateDocument, options);
}
export type AnschreibenTemplateQueryHookResult = ReturnType<
  typeof useAnschreibenTemplateQuery
>;
export type AnschreibenTemplateLazyQueryHookResult = ReturnType<
  typeof useAnschreibenTemplateLazyQuery
>;
export type AnschreibenTemplateQueryResult = Apollo.QueryResult<
  AnschreibenTemplateQuery,
  AnschreibenTemplateQueryVariables
>;
