import React from "react";
import { Col, Divider, Typography } from "antd";
import {
  EuerDeclarationTravelExpensesSubformCalculationResults,
  EuerDeclarationTravelExpensesSubformInputs,
} from "@kontist/euer-declaration";

import { EuerDeclarationSubformStatus } from "../../../../types";
import { formatAmountInCents } from "../../../../../../../utils";
import colors from "../../../../../../../themes/colors";
import DeclarationStatusChangeModal from "../../../../components/DeclarationStatusChangeModal";
import useTaxYearParam from "../../../../hooks/useTaxYearParam";
import formatDistanceInKm from "../../../../../../../utils/formatDistanceInKm";
import { SUBFORM_STATUSES, SUBFORM_STATUS_MAPPINGS } from "../../constants";

const { Text } = Typography;
const { ListRow, Total } = DeclarationStatusChangeModal;

export interface TravelExpensesStatusChangeModalProps {
  inputs: EuerDeclarationTravelExpensesSubformInputs;
  calculationResults: EuerDeclarationTravelExpensesSubformCalculationResults;
  status: EuerDeclarationSubformStatus;
  onSave: (status: EuerDeclarationSubformStatus) => Promise<void>;
  onClose: () => void;
}

const TravelExpensesStatusChangeModal = ({
  inputs,
  calculationResults,
  status,
  onSave,
  onClose,
}: TravelExpensesStatusChangeModalProps) => {
  const [taxYear] = useTaxYearParam();

  return (
    <DeclarationStatusChangeModal
      title="Verpflegungsmehraufwand"
      year={taxYear}
      status={status}
      statusOptions={SUBFORM_STATUSES}
      statusMapping={SUBFORM_STATUS_MAPPINGS}
      onSave={onSave}
      onClose={onClose}
    >
      <ListRow>
        <Col>
          <Text strong>
            Betriebliche Nutzung des privaten KFZ (Nutzungseinlage)
          </Text>
        </Col>
      </ListRow>

      <ListRow>
        <Col flex="1">Gefahrene Kilometer</Col>
        <Col>{formatDistanceInKm(inputs.privateCarTravelDistance)}</Col>
      </ListRow>

      <Divider className="m-0" />

      <ListRow>
        <Col>
          <Text strong>Verpflegungsmehraufwand</Text>
        </Col>
      </ListRow>

      <ListRow>
        <Col flex="1">Summe Pauschbeträge</Col>
        <Col>{formatAmountInCents(inputs.additionalMealExpenses, true)}</Col>
      </ListRow>

      <Divider className="m-0" />

      <ListRow>
        <Col flex="1">
          [147] Fahrten für nicht zum Betriebsvermögen gehörende Fahrzeuge
          (Nutzungseinlage)"
        </Col>
        <Col>
          {formatAmountInCents(
            calculationResults.privateCarUsageContribution,
            true
          )}
        </Col>
      </ListRow>

      <ListRow>
        <Col flex="1">[171] Verpflegungsmehraufwand</Col>
        <Col>
          {formatAmountInCents(calculationResults.additionalMealExpenses, true)}
        </Col>
      </ListRow>

      <ListRow>
        <Col flex="1">[176] Min. abziehbare Fahrtkosten zw Whg/Betrieb</Col>
        <Col>
          {formatAmountInCents(
            calculationResults.minDeductibleTravelExpenses,
            true
          )}
        </Col>
      </ListRow>

      <Divider className="m-0" />

      <ListRow>
        <Col flex="1">
          <Total>Gewinnminderung</Total>
        </Col>
        <Col>
          <Text
            strong
            style={{
              color:
                calculationResults.profitReduction > 0
                  ? colors.darkRed
                  : colors.darkGrey,
            }}
          >
            {formatAmountInCents(calculationResults.profitReduction, true)}
          </Text>
        </Col>
      </ListRow>
    </DeclarationStatusChangeModal>
  );
};

export default TravelExpensesStatusChangeModal;
