import styled from "styled-components";
import { Form } from "antd";

export const DependentFormButtonWrapper = styled.div`
  display: flex;
  justify-content: end;
`;

export const StyledDependentForm = styled(Form)`
  .ant-form-item {
    margin-bottom: 0;
    height: 60px;
  }
`;
