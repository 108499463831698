import type * as Types from "../../schema.generated";

import { gql } from "@apollo/client";
import { KontaxTransactionForAdminFragmentDoc } from "../../fragments/kontaxTransactionForAdmin.generated";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type UpdateTransactionFeedbackMutationVariables = Types.Exact<{
  transactionId: Types.Scalars["String"];
  feedback: Types.TransactionFeedback;
}>;

export type UpdateTransactionFeedbackMutation = {
  __typename?: "Mutation";
  updateTransactionFeedback: {
    __typename?: "KontaxTransactionForAdmin";
    id: string;
    amount: number;
    valutaDate: string;
    verified: boolean;
    verifiedBy?: string | null;
    name?: string | null;
    iban?: string | null;
    description?: string | null;
    merchantCategoryCode?: string | null;
    foreignCurrency?: string | null;
    escalated?: boolean | null;
    escalationNote?: string | null;
    internalNote?: string | null;
    businessTypeComment?: string | null;
    personalNote?: string | null;
    invoiceRequestedAt?: string | null;
    email: string;
    firstName: string;
    lastName: string;
    categoryCode?: string | null;
    vatCategoryCode?: string | null;
    hasIntegrationDocument?: boolean | null;
    isSplitCategorized?: boolean | null;
    vatYearPaymentFrequency?: Types.PaymentFrequency | null;
    source: string;
    taxAdvisorProof?: Types.TaxAdvisorProof | null;
    fibuCategoryComment?: string | null;
    vatCategoryComment?: string | null;
    categoryCodeMeta?: {
      __typename?: "ValueMeta";
      label?: string | null;
      description?: string | null;
      categorizationType?: string | null;
      suggestionSource?: string | null;
    } | null;
    vatCategoryCodeMeta?: {
      __typename?: "ValueMeta";
      label?: string | null;
      description?: string | null;
      categorizationType?: string | null;
      suggestionSource?: string | null;
    } | null;
    assets: Array<{
      __typename?: "AssetData";
      id: string;
      filetype: string;
      fullsize: string;
    }>;
    businessAssets: Array<{
      __typename?: "BusinessAsset";
      businessAssetableId: string;
      businessAssetableType: string;
      assetType: Types.AssetType;
      assetClass: string;
      purchaseDate: string;
      amount: number;
      depreciationPeriodYears: number;
      note?: string | null;
    }>;
  };
};

export const UpdateTransactionFeedbackDocument = gql`
  mutation updateTransactionFeedback(
    $transactionId: String!
    $feedback: TransactionFeedback!
  ) {
    updateTransactionFeedback(
      transactionId: $transactionId
      feedback: $feedback
    ) {
      ...KontaxTransactionForAdmin
    }
  }
  ${KontaxTransactionForAdminFragmentDoc}
`;
export type UpdateTransactionFeedbackMutationFn = Apollo.MutationFunction<
  UpdateTransactionFeedbackMutation,
  UpdateTransactionFeedbackMutationVariables
>;

/**
 * __useUpdateTransactionFeedbackMutation__
 *
 * To run a mutation, you first call `useUpdateTransactionFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTransactionFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTransactionFeedbackMutation, { data, loading, error }] = useUpdateTransactionFeedbackMutation({
 *   variables: {
 *      transactionId: // value for 'transactionId'
 *      feedback: // value for 'feedback'
 *   },
 * });
 */
export function useUpdateTransactionFeedbackMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTransactionFeedbackMutation,
    UpdateTransactionFeedbackMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateTransactionFeedbackMutation,
    UpdateTransactionFeedbackMutationVariables
  >(UpdateTransactionFeedbackDocument, options);
}
export type UpdateTransactionFeedbackMutationHookResult = ReturnType<
  typeof useUpdateTransactionFeedbackMutation
>;
export type UpdateTransactionFeedbackMutationResult =
  Apollo.MutationResult<UpdateTransactionFeedbackMutation>;
export type UpdateTransactionFeedbackMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateTransactionFeedbackMutation,
    UpdateTransactionFeedbackMutationVariables
  >;
