import React, { FC, useEffect, useState } from "react";
import { Space, Button, notification } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import { RightSquareOutlined } from "@ant-design/icons";

import { AntDrawerStyled, FullSpace } from "./styledComponents";
import IntercomIcon from "../../../icons/intercom.png";
import AsanaIcon from "../../../icons/asana.png";
import { ExternalResource, IUser, VatMetaNote } from "../../../types";
import api from "../../../api";
import DrawerUserAttribute from "./DrawerUserAttribute";
import AntNotes from "../AntNotes";
import TaxTemplate from "./templates/taxTemplate";
import AccountingTemplate from "./templates/accountingTemplate";
import { fetchVatPaymentFrequency } from "../../../utils/paymentFrequency";
import { fetchSubscriptionType } from "../../../utils/subscription";
import { KontaxNoteType } from "../../../api/graphql/schema.generated";

export enum UserInfoDrawerSources {
  TAX = "tax",
  ACCOUNTING = "accounting",
  CLIENTS = "clients",
}

interface Props {
  visible: boolean;
  onClose: () => void;
  user: IUser | null;
  notes: VatMetaNote[];
  source: UserInfoDrawerSources;
}

const UserInfoDrawer: FC<Props> = ({
  visible,
  onClose,
  user,
  source,
  notes: initialNotes,
}) => {
  const [notes, setNotes] = useState<VatMetaNote[]>(initialNotes || []);

  const { pathname } = useLocation();
  const history = useHistory();

  useEffect(() => {
    setNotes(initialNotes);
  }, [initialNotes]);

  if (!user) return null;

  const openIntercomHandler = async () => {
    try {
      if (!user) throw new Error();

      const response = await api.kontax.getExternalUrl(
        user.email,
        ExternalResource.Intercom
      );
      if (!response) throw new Error();

      window.open(response, "_blank");
    } catch (err) {
      notification.error({ message: "Error getting intercom link" });
    }
  };

  const getFooter = () => {
    const links = [
      {
        onClick: () => {
          window.open(
            user?.email ? `/?email=${encodeURIComponent(user.email)}` : "/",
            "_blank"
          );
        },
        pathname: "/",
        label: "Open user in Mandanten view",
      },
      {
        pathname: "/transaction-view",
        source: UserInfoDrawerSources.ACCOUNTING,
        label: "Go to Transaction view",
      },
      {
        pathname: "/verification-view",
        source: UserInfoDrawerSources.ACCOUNTING,
        label: "Go to Verification view",
      },
      {
        pathname: "/vat-view",
        source: UserInfoDrawerSources.ACCOUNTING,
        label: "Go to VAT view",
      },
    ];

    return (
      <Space direction="vertical" size="middle">
        {links.map((link) => {
          if (pathname === link.pathname) return null;
          if (link.source && link.source !== source) return null;
          return (
            <Button
              type="link"
              key={link.pathname}
              size="small"
              onClick={() => {
                if (link.onClick) return link.onClick();

                history.push(
                  `${link.pathname}?email=${encodeURIComponent(user.email)}`
                );
              }}
              icon={<RightSquareOutlined />}
            >
              {link.label}
            </Button>
          );
        })}
      </Space>
    );
  };

  const renderDrawerTemplate = () => {
    if (!user) return null;

    if (source === UserInfoDrawerSources.TAX)
      return <TaxTemplate user={user} />;
    return (
      <AccountingTemplate
        user={user}
        subscriptionType={fetchSubscriptionType(user)}
        vatDetails={fetchVatPaymentFrequency(user, new Date().getFullYear())}
      />
    );
  };

  return (
    <AntDrawerStyled
      title="User information"
      extra={
        <Space size={12}>
          <Button
            shape="circle"
            size="large"
            onClick={(openIntercomHandler) => {
              window.open(`https://app.asana.com/0/search?q=${user?.email}`);
            }}
            icon={<img src={AsanaIcon} alt="Asana link" width={24} />}
          />
          <Button
            shape="circle"
            size="large"
            onClick={openIntercomHandler}
            icon={<img src={IntercomIcon} alt="Intercom link" width={24} />}
          />
        </Space>
      }
      size="default"
      closable
      onClose={onClose}
      visible={visible}
      footer={getFooter()}
    >
      <FullSpace direction="vertical" size="large">
        {renderDrawerTemplate()}
        <DrawerUserAttribute>
          <AntNotes
            setNotes={setNotes}
            notes={notes}
            email={user.email}
            type={KontaxNoteType.VAT}
          />
        </DrawerUserAttribute>
      </FullSpace>
    </AntDrawerStyled>
  );
};

export default UserInfoDrawer;
