import React, { useMemo } from "react";
import { Popover, Table } from "antd";
import moment from "moment-timezone";
import { ColumnType } from "antd/lib/table";
import { CommentOutlined } from "@ant-design/icons";

import { SorterResult } from "antd/lib/table/interface";

import { UsageStatDetails } from "../../../api/graphql/schema.generated";

import { formatDate } from "../TaxDeclaration/utils";

interface UsageStatsAndCount {
  count: number;
  data: UsageStatDetails[] | undefined;
}

interface ActivityTrackingTableProps {
  usageStatsAndCount: UsageStatsAndCount | undefined;
  page: number;
  pageSize: number;
  handleOrderChange: (sorter: SorterResult<UsageStatDetails>) => void;
  handlePaginationChange: (newPage: number, pageSize: number) => void;
}

const getFullName = (data: UsageStatDetails) => {
  if (!data.kontistUser) {
    return "-";
  }

  const { firstName, lastName } = data.kontistUser;
  return `${firstName} ${lastName}`;
};

const getEmail = (data: UsageStatDetails) => {
  if (!data.kontistUser) {
    return "-";
  }

  return data.kontistUser.email!;
};

const ActivityTrackingTable = ({
  usageStatsAndCount,
  page,
  pageSize,
  handleOrderChange,
  handlePaginationChange,
}: ActivityTrackingTableProps) => {
  const columns: ColumnType<UsageStatDetails>[] = useMemo(
    () => [
      {
        title: "Date",
        key: "startedAt",
        dataIndex: "startedAt",
        defaultSortOrder: "descend",
        render: (startedAt: Date) => {
          return formatDate(startedAt);
        },
        sorter: (a, b) => (moment(a.startedAt).isAfter(b.startedAt) ? 1 : -1),
      },
      {
        title: "Time range",
        key: "time",
        render: (row: UsageStatDetails) =>
          [row.startedAt, row.finishedAt]
            .map((date) => moment(date).format("HH:mm"))
            .join(" - "),
      },

      {
        title: "Name",
        render: getFullName,
        sorter: (a, b) => {
          const nameA = getFullName(a);
          const nameB = getFullName(b);
          return nameA.localeCompare(nameB);
        },
      },
      {
        title: "Email",
        render: getEmail,
        sorter: (a, b) => getEmail(a).localeCompare(getEmail(b)),
      },
      {
        title: <CommentOutlined />,
        key: "comment",
        dataIndex: "comment",
        render: (comment: string) => {
          return comment?.length ? (
            <Popover content={comment} trigger="click">
              <CommentOutlined />
            </Popover>
          ) : (
            ""
          );
        },
      },
      {
        title: "Activity",
        key: "activity",
        dataIndex: "activity",
        sorter: (a, b) => a.activity.localeCompare(b.activity),
      },
    ],
    []
  );

  return (
    <Table
      columns={columns}
      dataSource={usageStatsAndCount?.data}
      onChange={(pagination, filters, sorter) =>
        handleOrderChange(sorter as SorterResult<UsageStatDetails>)
      }
      pagination={{
        total: usageStatsAndCount?.count,
        defaultPageSize: pageSize,
        pageSizeOptions: ["10", "20", "50"],
        showSizeChanger: true,
        current: page,
        onChange: (newPage, pageSize) => {
          handlePaginationChange(newPage, pageSize);
        },
      }}
      rowKey="id"
      style={{ width: "100%" }}
    />
  );
};

export default ActivityTrackingTable;
