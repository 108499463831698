import type * as Types from "../../schema.generated";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type TaxBoardOverviewEuerDeclarationSubformsQueryVariables =
  Types.Exact<{
    email: Types.Scalars["String"];
    year: Types.Scalars["Int"];
  }>;

export type TaxBoardOverviewEuerDeclarationSubformsQuery = {
  __typename?: "Query";
  euerDeclarationSubforms: Array<{
    __typename?: "EuerDeclarationSubform";
    id: string;
    type: Types.EuerDeclarationSubformType;
    status: Types.EuerDeclarationSubformStatus;
  }>;
};

export const TaxBoardOverviewEuerDeclarationSubformsDocument = gql`
  query taxBoardOverviewEuerDeclarationSubforms($email: String!, $year: Int!) {
    euerDeclarationSubforms(email: $email, year: $year) {
      id
      type
      status
    }
  }
`;

/**
 * __useTaxBoardOverviewEuerDeclarationSubformsQuery__
 *
 * To run a query within a React component, call `useTaxBoardOverviewEuerDeclarationSubformsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaxBoardOverviewEuerDeclarationSubformsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaxBoardOverviewEuerDeclarationSubformsQuery({
 *   variables: {
 *      email: // value for 'email'
 *      year: // value for 'year'
 *   },
 * });
 */
export function useTaxBoardOverviewEuerDeclarationSubformsQuery(
  baseOptions: Apollo.QueryHookOptions<
    TaxBoardOverviewEuerDeclarationSubformsQuery,
    TaxBoardOverviewEuerDeclarationSubformsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    TaxBoardOverviewEuerDeclarationSubformsQuery,
    TaxBoardOverviewEuerDeclarationSubformsQueryVariables
  >(TaxBoardOverviewEuerDeclarationSubformsDocument, options);
}
export function useTaxBoardOverviewEuerDeclarationSubformsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TaxBoardOverviewEuerDeclarationSubformsQuery,
    TaxBoardOverviewEuerDeclarationSubformsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TaxBoardOverviewEuerDeclarationSubformsQuery,
    TaxBoardOverviewEuerDeclarationSubformsQueryVariables
  >(TaxBoardOverviewEuerDeclarationSubformsDocument, options);
}
export type TaxBoardOverviewEuerDeclarationSubformsQueryHookResult = ReturnType<
  typeof useTaxBoardOverviewEuerDeclarationSubformsQuery
>;
export type TaxBoardOverviewEuerDeclarationSubformsLazyQueryHookResult =
  ReturnType<typeof useTaxBoardOverviewEuerDeclarationSubformsLazyQuery>;
export type TaxBoardOverviewEuerDeclarationSubformsQueryResult =
  Apollo.QueryResult<
    TaxBoardOverviewEuerDeclarationSubformsQuery,
    TaxBoardOverviewEuerDeclarationSubformsQueryVariables
  >;
