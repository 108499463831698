import { YesNoEnum } from "../types";

export const formatBooleanToString = (
  prop?: boolean | null
): YesNoEnum | undefined => {
  switch (prop) {
    case true: {
      return YesNoEnum.YES;
    }
    case false: {
      return YesNoEnum.NO;
    }
    default: {
      return;
    }
  }
};
