import styled from "styled-components";

import { Form } from "antd";

import colors from "../../../themes/colors";
import { SpinnerBasic } from "../../common/Spinner";

export const CategorisationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap 20px;
`;

export const TransactionDetailsWrapper = styled.div`
  flex: 1;

  label {
    color: ${colors.lightGreyOpacity};
  }
`;

export const InvoiceWrapper = styled.div`
  display: flex;
  background: aliceblue;
  flex: 2;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 80px;
`;

export const StatusBarWrapper = styled.div`
  button {
    padding: 0;
  }
`;

export const SelectBadgeContainer = styled.div`
  user-select: none;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .pink {
    background-color: ${colors.pink};
  }
`;

export const Banner = styled.div`
  background: ${colors.white};
  border-radius: 8px;
  height: 60px;
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  margin: 12px;
  padding: 12px;
`;

export const IconBackground = styled.div`
  background: ${colors.pureOrange};
  height: 70px;
  width: 70px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 62px;
  margin-top: 62px;
`;

export const IconBackgroundSmall = styled.div`
  background: ${colors.lightGrey};
  height: 40px;
  width: 40px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  path {
    stroke: ${colors.veryDarkViolet};
  }
  svg {
    width: 24px;
    height: 24px;
  }
`;

export const BusinessAssetWrapper = styled.div`
  border-bottom: 1px solid ${colors.veryLightGrey};
  margin-bottom: 23px;
`;

export const StyledForm = styled(Form)`
  .ant-form-item {
    margin-bottom: 0px;
  }
`;

export const SpinnerBasicCentered = styled(SpinnerBasic)`
  position: absolute;
  width: 100%;
  top: 50%;
  z-index: 1500;
`;

export const EscalateAndContactWrapper = styled.div`
  & > button > span > svg {
    color: ${colors.midPurple};
  }
  & > button > span {
    color: ${colors.black};
  }
`;

export const BadgesBlock = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
`;
