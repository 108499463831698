import React, { ChangeEvent, ReactElement, useEffect, useState } from "react";
import { Button, Divider, Space, Typography, Input, Form, Row } from "antd";
import { RiseOutlined, SendOutlined } from "@ant-design/icons";

import CategorySelect from "./CategorySelect";
import VatCategorySelect from "./VatCategorySelect";
import {
  TransactionDetailsWrapper,
  BusinessAssetWrapper,
  StyledForm,
  EscalateAndContactWrapper,
} from "./styledComponents";
import {
  EXTERNAL_TRANSACTION_CATEGORIES,
  formatAmountInCents,
} from "../../../utils";
import TransactionSplitContainer from "../../common/TransactionSplitContainer";
import BusinessAssetConfirmationModal from "../../BusinessAsset/BusinessAssetConfirmationModal";
import { TransactionSplitState } from "../../common/types";
import BusinessAsset from "./BusinessAsset";
import { ITransaction, IBusinessAssetForm } from "../../../types";
import TransactionSplitButton from "../../common/TransactionSplitButton";
import { Label, SplitButton } from "../../common/styledComponents";
import { isDepreciableCategoryCode } from "../../BusinessAsset/utils";
import { UserBusinessAssetsLink } from "./UserBusinessAssetsLink";
import { UserInfoDrawerSources } from "../../common/UserInfoExcerpt/UserInfoDrawer";
import UserInfo from "../../common/UserInfo";
import colors from "../../../themes/colors";

const { Link } = Typography;
const { Item } = Form;

const TransactionDetails = ({
  buttonsSection,
  category,
  vatCategory,
  error,
  internalNoteInput,
  isEscalateClicked,
  isNextClicked,
  jobInput,
  onCategorySelected,
  onVatCategorySelected,
  onInternalNoteChange,
  onSaveInternalNoteClick,
  transaction,
  businessAssetFormData,
  onFieldChange,
  showBusinessAssetConfirmationModal,
  onHideBusinessAssetConfirmationModal,
  hideBusinessAssetMode,
  onSplitChange,
  onSplitDelete,
  onEscalateHandler,
  triggerContactUserMode,
}: {
  buttonsSection: ReactElement;
  category: string | null | undefined;
  vatCategory: string | null | undefined;
  error: any;
  internalNoteInput: string | undefined;
  isEscalateClicked: boolean;
  isNextClicked: boolean;
  jobInput: string | undefined;
  onCategorySelected: (category: string | null) => void;
  onVatCategorySelected: (category: string | null) => void;
  onInternalNoteChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  onSaveInternalNoteClick: (event: any) => void;
  onEscalateHandler: (event: any) => void;
  transaction: ITransaction;
  showBusinessAssetConfirmationModal: boolean;
  triggerContactUserMode: (event: any) => void;
  onHideBusinessAssetConfirmationModal: () => void;
  hideBusinessAssetMode: () => void;
  onSplitChange: (state: TransactionSplitState) => void;
  onSplitDelete: (state: TransactionSplitState) => Promise<boolean>;
} & IBusinessAssetForm) => {
  const [isSplitMode, triggerSplitMode] = useState<boolean>(
    !!transaction.splits?.length
  );

  useEffect(() => {
    triggerSplitMode(!!transaction.splits?.length);
  }, [transaction.splits]);

  const onContactUserHandler = () => {
    triggerContactUserMode(true);
  };

  const getSearchableValue = (value: string | null | undefined, id: string) => {
    return (
      !!value && (
        <Link
          id={id}
          href={`https://www.google.com/search?q=${value}`}
          target="_blank"
        >
          {value}
        </Link>
      )
    );
  };

  const getBusinessAssetMode = () => {
    if (transaction.businessAssets?.length) {
      return (
        <Row justify="end">
          <UserBusinessAssetsLink email={transaction.email} />
          <Divider style={{ margin: "8px 0" }} />
        </Row>
      );
    }

    if (isDepreciableCategoryCode(category)) {
      return (
        <BusinessAssetWrapper>
          <BusinessAsset
            businessAssetFormData={businessAssetFormData}
            onFieldChange={onFieldChange}
            isSubmitClicked={isEscalateClicked || isNextClicked}
          />
          <BusinessAssetConfirmationModal
            show={showBusinessAssetConfirmationModal}
            onHide={onHideBusinessAssetConfirmationModal}
          />
        </BusinessAssetWrapper>
      );
    }
  };

  return (
    <TransactionDetailsWrapper>
      <Space direction="vertical" size="large" style={{ width: "100%" }}>
        <Row justify="space-between" align="middle">
          <UserInfo source={UserInfoDrawerSources.ACCOUNTING} />
        </Row>
        <StyledForm
          labelCol={{ span: 5 }}
          colon={false}
          labelAlign="left"
          layout="horizontal"
        >
          {buttonsSection}
          {error && <p>{error}</p>}
          <Item label="Amount">
            <span id="amount">
              {formatAmountInCents(transaction.amount, true)}
            </span>
            {!isSplitMode && (
              <SplitButton>
                <TransactionSplitButton
                  onClick={() => triggerSplitMode(true)}
                  small={true}
                />
              </SplitButton>
            )}
          </Item>
          {isSplitMode && (
            <TransactionSplitContainer
              transaction={transaction}
              isEscalateClicked={isEscalateClicked}
              isNextClicked={isNextClicked}
              showBusinessAssetConfirmationModal={
                showBusinessAssetConfirmationModal
              }
              onHideBusinessAssetConfirmationModal={
                onHideBusinessAssetConfirmationModal
              }
              onCreate={() => {
                hideBusinessAssetMode();
                triggerSplitMode(true);
              }}
              onChange={onSplitChange}
              onDelete={async (state: TransactionSplitState) => {
                if (await onSplitDelete(state)) {
                  triggerSplitMode(false);
                  return true;
                }
                return false;
              }}
            />
          )}
          <Divider style={{ margin: "8px 0" }} />
          {!isSplitMode && (
            <React.Fragment>
              <CategorySelect
                style={{ width: "100%" }}
                label="Category"
                value={category}
                source={transaction.source}
                type={transaction.type}
                meta={transaction.categoryCodeMeta}
                transactionAmount={transaction.amount}
                onChangeHandler={onCategorySelected}
                focused
                invalid={!category && isNextClicked}
                disabledOptions={EXTERNAL_TRANSACTION_CATEGORIES}
              />
              <Divider style={{ margin: "8px 0" }} />
              <VatCategorySelect
                style={{ width: "100%" }}
                label="VAT"
                value={vatCategory}
                onChangeHandler={onVatCategorySelected}
                updateValueOnCategoryChange
                kontaxCategory={category}
                vatYearPaymentFrequency={transaction.vatYearPaymentFrequency}
                invalid={!vatCategory && isNextClicked}
                meta={transaction.vatCategoryCodeMeta}
                showInfo
              />
              <Divider style={{ margin: "8px 0" }} />
              {getBusinessAssetMode()}
            </React.Fragment>
          )}
          <Item label="Job">
            <span id="job-description">{jobInput}</span>
          </Item>
          <Divider style={{ margin: "8px 0" }} />
          <Item label="Paid by">
            <span>{getSearchableValue(transaction.name, "paid-by")}</span>
          </Item>
          <Divider style={{ margin: "8px 0" }} />
          <Item label="Description">
            <span>
              {getSearchableValue(transaction.description, "description")}
            </span>
          </Item>
          <Divider style={{ margin: "8px 0" }} />
          {!!transaction.personalNote && (
            <React.Fragment>
              <Item label="User’s Note">
                <span>
                  {getSearchableValue(transaction.personalNote, "personalNote")}
                </span>
              </Item>
              <Divider style={{ margin: "8px 0" }} />
            </React.Fragment>
          )}
          <Item label="Date">
            <span id="payment-date">{transaction.valutaDate}</span>
          </Item>
          <Divider style={{ margin: "8px 0" }} />
          <Item label="IBAN">
            <span id="iban">{transaction.iban}</span>
          </Item>
          <Divider style={{ margin: "8px 0" }} />
          <Item>
            <Label style={{ color: colors.black }}>Internal Note</Label>
            <Input.TextArea
              showCount
              maxLength={255}
              disabled={false}
              id="internal-note"
              value={internalNoteInput || ""}
              onChange={onInternalNoteChange}
              style={{ width: "50%" }}
              rows={3}
            />
            <Button
              size="large"
              data-test="saveInternalNote"
              disabled={!internalNoteInput}
              onClick={onSaveInternalNoteClick}
              style={{ marginTop: 30 }}
            >
              Save note
            </Button>
            <Divider style={{ margin: "8px 0" }} />
            <EscalateAndContactWrapper>
              <Button
                type="link"
                data-test="escalateBtn"
                onClick={onEscalateHandler}
              >
                <RiseOutlined /> Escalate
              </Button>
              <Button
                type="link"
                data-test="contactUserBtn"
                onClick={onContactUserHandler}
              >
                <SendOutlined /> Contact user
              </Button>
            </EscalateAndContactWrapper>
          </Item>
        </StyledForm>
      </Space>
    </TransactionDetailsWrapper>
  );
};

export default TransactionDetails;
