import { DoubleLeftOutlined } from "@ant-design/icons";
import React from "react";

import config from "../../../config";

import { ReactComponent as KontistLogo } from "../../../svgs/kontist-logo.svg";

import { HeadSiderTitle, IconMenuButton, MenuHeader } from "./styledComponents";

export const HeadSider = ({
  collapsed,
  onCollapse,
}: {
  collapsed: boolean;
  onCollapse: () => void;
}) => {
  const isStaging = config.REACT_APP_ENV === "staging";

  return (
    <MenuHeader isStaging={isStaging}>
      <KontistLogo className="kontist-logo" />
      {isStaging && !collapsed && <HeadSiderTitle>Staging</HeadSiderTitle>}
      <IconMenuButton
        shape="circle"
        icon={<DoubleLeftOutlined />}
        onClick={onCollapse}
        className="arrow"
        size="small"
      />
    </MenuHeader>
  );
};
