import type * as Types from "../../schema.generated";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type SaveVatAnnualDeclarationDraftPdfMutationVariables = Types.Exact<{
  email: Types.Scalars["String"];
  year: Types.Scalars["Int"];
}>;

export type SaveVatAnnualDeclarationDraftPdfMutation = {
  __typename?: "Mutation";
  saveVatAnnualDeclarationDraftPdf: {
    __typename?: "VatAnnualDeclarationProcessResult";
    pdf?: string | null;
    processResult: string;
    message: string;
  };
};

export const SaveVatAnnualDeclarationDraftPdfDocument = gql`
  mutation saveVatAnnualDeclarationDraftPdf($email: String!, $year: Int!) {
    saveVatAnnualDeclarationDraftPdf(email: $email, year: $year) {
      pdf
      processResult
      message
    }
  }
`;
export type SaveVatAnnualDeclarationDraftPdfMutationFn =
  Apollo.MutationFunction<
    SaveVatAnnualDeclarationDraftPdfMutation,
    SaveVatAnnualDeclarationDraftPdfMutationVariables
  >;

/**
 * __useSaveVatAnnualDeclarationDraftPdfMutation__
 *
 * To run a mutation, you first call `useSaveVatAnnualDeclarationDraftPdfMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveVatAnnualDeclarationDraftPdfMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveVatAnnualDeclarationDraftPdfMutation, { data, loading, error }] = useSaveVatAnnualDeclarationDraftPdfMutation({
 *   variables: {
 *      email: // value for 'email'
 *      year: // value for 'year'
 *   },
 * });
 */
export function useSaveVatAnnualDeclarationDraftPdfMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveVatAnnualDeclarationDraftPdfMutation,
    SaveVatAnnualDeclarationDraftPdfMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SaveVatAnnualDeclarationDraftPdfMutation,
    SaveVatAnnualDeclarationDraftPdfMutationVariables
  >(SaveVatAnnualDeclarationDraftPdfDocument, options);
}
export type SaveVatAnnualDeclarationDraftPdfMutationHookResult = ReturnType<
  typeof useSaveVatAnnualDeclarationDraftPdfMutation
>;
export type SaveVatAnnualDeclarationDraftPdfMutationResult =
  Apollo.MutationResult<SaveVatAnnualDeclarationDraftPdfMutation>;
export type SaveVatAnnualDeclarationDraftPdfMutationOptions =
  Apollo.BaseMutationOptions<
    SaveVatAnnualDeclarationDraftPdfMutation,
    SaveVatAnnualDeclarationDraftPdfMutationVariables
  >;
