import { FileDoneOutlined } from "@ant-design/icons";
import styled from "styled-components";

import { ReactComponent as OriginalNoDataIcon } from "../../../../../../svgs/no-data-icon.svg";
import colors from "../../../../../../themes/colors";

export const OptionContainer = styled.div`
  display: flex;
  align-items: center;
`;

const iconStyle = `
  font-size: 18px;
  margin-right: 10px;
`;

export const NoDataIcon = styled(OriginalNoDataIcon)`
  ${iconStyle}
`;

export const FileDoneOutlinedIcon = styled(FileDoneOutlined)`
  color: ${colors.darkGrey};
  ${iconStyle}
`;
