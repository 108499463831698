import React from "react";
import moment, { Moment } from "moment";

import CustomerAttribute from "../CustomerAttribute";
import { BottomDatePicker, TopDatePicker } from "./styledComponents";

export interface DateRange {
  startDate?: string | null;
  endDate?: string | null;
}

function getFormattedDate(date: moment.Moment | null) {
  return date ? moment(date).format("YYYY-MM-DD") : null;
}

const VerticalDateRangePicker = ({
  label,
  initialValue,
  onSaveHandler,
  topPlaceholder,
  bottomPlaceholder,
  isReadOnly,
}: {
  label: string;
  initialValue: DateRange;
  onSaveHandler: (range: DateRange) => void;
  topPlaceholder?: string;
  bottomPlaceholder?: string;
  isReadOnly?: boolean;
}) => {
  const { startDate, endDate } = initialValue;

  return (
    <CustomerAttribute label={label}>
      <TopDatePicker
        placeholder={topPlaceholder || "Select start date"}
        value={startDate ? moment(startDate) : undefined}
        onChange={async (date: Moment | null) => {
          await onSaveHandler({
            startDate: getFormattedDate(date),
          });
        }}
        disabled={isReadOnly}
      />
      <BottomDatePicker
        placeholder={bottomPlaceholder || "Select end date"}
        value={endDate ? moment(endDate) : undefined}
        onChange={async (date: Moment | null) => {
          await onSaveHandler({
            endDate: getFormattedDate(date),
          });
        }}
        disabled={isReadOnly}
      />
    </CustomerAttribute>
  );
};
export default VerticalDateRangePicker;
