import React from "react";
import Badge from "react-bootstrap/Badge";

import { SelectBadgeContainer } from "./styledComponents";
import { TransactionSource, ExternalTransactionType } from "../../../types";

const SOURCES_TO_DISPLAY = [
  TransactionSource.USER,
  TransactionSource.BACKOFFICE_MANUAL,
];

const TRANSACTION_TYPE_TO_LABEL = {
  [ExternalTransactionType.EXTERNAL_TRANSACTION]: "User external",
  [ExternalTransactionType.EXTERNAL_TRANSACTION_CASH]: "User cash external",
};

const getLabel = (source: TransactionSource, type: ExternalTransactionType) => {
  if (source === TransactionSource.USER) {
    return TRANSACTION_TYPE_TO_LABEL[type];
  } else {
    return "Ops external";
  }
};

const SourceBadge = ({
  source,
  type,
}: {
  source: TransactionSource;
  type: string;
}) => {
  if (!SOURCES_TO_DISPLAY.includes(source)) {
    return null;
  }
  return (
    <SelectBadgeContainer>
      <Badge variant="light" className="mb-1 pink" data-test="sourceBadge">
        {getLabel(source, type as ExternalTransactionType)}
      </Badge>
    </SelectBadgeContainer>
  );
};

export default SourceBadge;
