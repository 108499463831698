import React, {
  ChangeEvent,
  KeyboardEvent,
  FocusEvent,
  useRef,
  useState,
  useEffect,
} from "react";
import classNames from "classnames";

import { Input, InputWrapper, Label, TextAfterInput } from "./styledComponents";

const FormField = ({
  id,
  label,
  value,
  disabled,
  placeholder,
  className = "",
  onChange,
  onKeyPress,
  onBlur,
  type = "text",
  required,
  min,
  withSeparator,
  textAfter,
  withoutMargin,
  invalid = false,
}: {
  id?: string;
  label?: string;
  value?: string | null;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onKeyPress?: (event: KeyboardEvent<HTMLInputElement>) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  type?: string;
  required?: boolean;
  min?: number;
  withSeparator?: boolean;
  textAfter?: string;
  withoutMargin?: boolean;
  invalid?: boolean;
}) => {
  const [selection, setSelection] = useState([0, 0]);
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (type === "text") {
      ref.current?.setSelectionRange(selection[0], selection[1]);
    }
  });

  const inputWrapperClasses = classNames(className, {
    separator: withSeparator,
    "no-margin": withoutMargin,
  });

  return (
    <InputWrapper className={inputWrapperClasses}>
      {label && <Label htmlFor={id}>{label}</Label>}
      <Input
        type={type}
        id={id}
        value={value || ""}
        disabled={disabled}
        placeholder={placeholder}
        className={classNames(className, { invalid })}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          setSelection([
            event.target.selectionStart || 0,
            event.target.selectionEnd || 0,
          ]);
          onChange && onChange(event);
        }}
        onBlur={onBlur}
        onKeyPress={onKeyPress}
        required={required}
        min={min}
        ref={ref}
      />
      {textAfter && <TextAfterInput>{textAfter}</TextAfterInput>}
    </InputWrapper>
  );
};

export default FormField;
