import { useMemo } from "react";

import { Municipality } from "../../../../api/graphql/queries/tradeTaxDeclaration/municipalities";

export const useFormatMunicipalitiesMemo = (municipalities: Municipality[]) => {
  return useMemo(() => {
    return municipalities?.map((municipality) => ({
      value: `${municipality.name}#${municipality.taxRate}`,
      label: municipality.name,
    }));
  }, [municipalities]);
};
