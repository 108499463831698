import { gql, QueryHookOptions, useQuery } from "@apollo/client";

import { DeclarationDeclineFragmentDoc } from "../fragments/declarationDecline.generated";
import { NoteDataFragmentDoc } from "../fragments/note.generated";
import { IncomeTaxDeclaration } from "../schema.generated";

export interface IncomeTaxDeclarationQueryVariables {
  email: string;
  year: number;
}

export interface IncomeTaxDeclarationQueryResult {
  incomeTaxDeclaration: IncomeTaxDeclaration;
}

export const INCOME_TAX_DECLARATION_QUERY = gql`
  query incomeTaxDeclaration($email: String!, $year: Int!) {
    incomeTaxDeclaration(email: $email, year: $year) {
      id
      status
      statusUpdatedAt
      lastStatusChange {
        status
        changedAt
        changedBy
      }
      changeLogs {
        changes
        changedAt
        changedBy
      }
      submissionInfo {
        submissionAgent
        submissionDate
        externalAssets {
          url
        }
      }
      savedDraftInfo {
        createdBy
        createdAt
        updatedAt
        externalAssets {
          url
          createdAt
        }
      }
      notes {
        ...noteData
      }
      declarationDeclines {
        ...DeclarationDecline
      }
    }
  }
  ${NoteDataFragmentDoc}
  ${DeclarationDeclineFragmentDoc}
`;

export const useIncomeTaxDeclarationQuery = (
  options?: QueryHookOptions<
    IncomeTaxDeclarationQueryResult,
    IncomeTaxDeclarationQueryVariables
  >
) =>
  useQuery<IncomeTaxDeclarationQueryResult, IncomeTaxDeclarationQueryVariables>(
    INCOME_TAX_DECLARATION_QUERY,
    options
  );
