import React, { ReactNode, useEffect, useState } from "react";
import { Input, Select, Space, Button, DatePicker } from "antd";

import moment, { Moment } from "moment";

import CustomerAttribute from "../CustomerAttribute";
import { DateInputType } from "../TransactionFilters/DateInput";
import { getBerlinMomentTimezone } from "../helpers";
import {
  PaymentFrequency,
  QUARTERLY_PAYMENT_FREQUENCIES,
} from "../../../types";
import TextWithHyperlinks from "../TextWithHyperlinks";
import { EditableCustomerAttributeContent } from "./styledComponents";
import { getErrorMessage } from "../../../utils/error";

const { TextArea } = Input;
const { Option, OptGroup } = Select;

export enum EditType {
  Text = "text",
  TextArea = "textarea",
  Select = "select",
  PeriodPicker = "picker",
}

const formatValue = (value: any, formatFunction?: Function) => {
  if (typeof formatFunction === "function") {
    return formatFunction(value);
  }

  return value;
};

const renderSelectOptions = (options: Array<any>, formatFunction?: Function) =>
  options.map((value, index) => {
    return (
      <Option value={value} key={`${index}-${value}`}>
        {formatValue(value, formatFunction)}
      </Option>
    );
  });

const EditableCustomerAttribute = ({
  label,
  editType = EditType.Text,
  options,
  initialValue,
  formatFunction,
  renderAttributeContent,
  onSaveHandler,
  pattern,
  inputRequired,
  inputClassName,
  inputMaxLength = 255,
  allowClear,
  vatPaymentFrequency,
  dataTest,
  changeLog,
  isReadOnly,
}: {
  label: string;
  editType?: EditType | DateInputType;
  options?: Array<any> | { [groupLabel: string]: Array<any> };
  initialValue: any;
  formatFunction?: Function;
  renderAttributeContent?: (value?: any) => JSX.Element;
  onSaveHandler: Function;
  pattern?: RegExp;
  inputRequired?: boolean;
  inputClassName?: string;
  inputMaxLength?: number;
  allowClear?: boolean;
  vatPaymentFrequency?: PaymentFrequency;
  dataTest?: string;
  changeLog?: ReactNode;
  isReadOnly?: boolean;
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [value, setValue] = useState(initialValue || "");
  const [error, setError] = useState("");

  const attributeValue = formatValue(initialValue, formatFunction);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue, setValue]);

  const onDateInputChangeHandler = (date: Moment | null) => {
    let newDate: string | null = null;
    if (date) {
      newDate =
        editType === DateInputType.Date
          ? date.format("YYYY-MM-DD")
          : getBerlinMomentTimezone(date.format("YYYY-MM-DD")).toISOString();
    }
    setValue(newDate);
  };

  return (
    <CustomerAttribute
      label={label}
      dataTest={dataTest}
      onEdit={
        !isReadOnly
          ? () => {
              setIsEditMode(!isEditMode);
              setError("");
            }
          : undefined
      }
      changeLog={changeLog}
    >
      {isEditMode ? (
        <div data-test={dataTest + "Edit"}>
          {editType === EditType.Select && options && (
            <Select
              id={label}
              style={{ width: "100%" }}
              onChange={(event: any) => {
                setValue(event);
                setError("");
              }}
              value={value ? value : ""}
              placeholder="Please select an option"
              allowClear={allowClear}
            >
              <Option disabled value="">
                Please select an option
              </Option>
              {Array.isArray(options)
                ? renderSelectOptions(options, formatFunction)
                : Object.keys(options).map((groupLabel) => (
                    <OptGroup label={groupLabel}>
                      {renderSelectOptions(options[groupLabel], formatFunction)}
                    </OptGroup>
                  ))}
            </Select>
          )}

          {editType === EditType.Text && (
            <Input
              placeholder={`Please enter a ${label}`}
              value={value}
              disabled={false}
              maxLength={inputMaxLength}
              className={inputClassName}
              allowClear={allowClear}
              onChange={(event: any) => {
                const value = pattern
                  ? (event.target.value.match(pattern) || []).join("")
                  : event.target.value;

                setValue(value);
                setError("");
              }}
            />
          )}

          {editType === EditType.TextArea && (
            <TextArea
              showCount
              placeholder={`Please enter a ${label}`}
              value={value}
              disabled={false}
              maxLength={inputMaxLength}
              className={inputClassName}
              allowClear={allowClear}
              onChange={(event: any) => {
                const value = pattern
                  ? (event.target.value.match(pattern) || []).join("")
                  : event.target.value;

                setValue(value);
                setError("");
              }}
            />
          )}

          {(editType === DateInputType.Date ||
            editType === DateInputType.Month) && (
            <DatePicker
              name={label}
              picker={editType}
              value={value ? getBerlinMomentTimezone(value) : null}
              onChange={onDateInputChangeHandler}
              allowClear={allowClear}
              format={editType === DateInputType.Date ? "DD.MM.YYYY" : ""}
            />
          )}

          {editType === EditType.PeriodPicker && (
            <DatePicker
              name={label}
              value={value ? moment(value) : null}
              defaultPickerValue={value ? moment(value) : undefined}
              allowClear={allowClear}
              picker={
                vatPaymentFrequency &&
                QUARTERLY_PAYMENT_FREQUENCIES.includes(vatPaymentFrequency)
                  ? "quarter"
                  : "month"
              }
              format={
                vatPaymentFrequency &&
                QUARTERLY_PAYMENT_FREQUENCIES.includes(vatPaymentFrequency)
                  ? "\\QQ, YYYY"
                  : "MMM, YYYY"
              }
              onChange={setValue}
            />
          )}

          {editType === DateInputType.Year && (
            <DatePicker
              name={label}
              value={value ? moment(value) : null}
              defaultPickerValue={value ? moment(value) : moment()}
              picker={DateInputType.Year}
              allowClear={allowClear}
              onChange={setValue}
            />
          )}

          <div className="mt-1">
            <Space>
              <Button
                type="primary"
                data-test="saveEdit"
                onClick={async () => {
                  try {
                    setIsLoading(true);
                    await onSaveHandler(value);
                    setIsEditMode(false);
                  } catch (error) {
                    setError(getErrorMessage(error));
                  } finally {
                    setIsLoading(false);
                  }
                }}
                disabled={isLoading || (inputRequired && !value)}
              >
                Save
              </Button>
              <Button
                data-test="cancelEdit"
                onClick={() => {
                  setValue(initialValue);
                  setIsEditMode(false);
                  setError("");
                }}
                disabled={isLoading}
              >
                Cancel
              </Button>
            </Space>
          </div>
        </div>
      ) : (
        <EditableCustomerAttributeContent>
          {renderAttributeContent ? (
            renderAttributeContent(value)
          ) : (
            <TextWithHyperlinks text={attributeValue} withFallbackDash />
          )}
        </EditableCustomerAttributeContent>
      )}
      {error && <p>An error occurred. {error}</p>}
    </CustomerAttribute>
  );
};

export default EditableCustomerAttribute;
