import React, { useMemo } from "react";
import { notification } from "antd";

import { SectionTitle } from "./styledComponents";
import ActionLogDrawerAsync from "./ActionLogDrawerAsync";
import { FibuFinalCheckStatus } from "../../api/graphql/schema.generated";
import { IUser, IUserDetails, FibuFinalCheck } from "../../types";
import EditableCustomerAttribute, {
  EditType,
} from "./EditableCustomerAttribute";
import { useKontaxUserContext } from "../contexts/KontaxUserContext";

const FibuFinalCheckComponent = ({
  fibuFinalChecks,
  user,
  editUser,
}: {
  fibuFinalChecks: FibuFinalCheck[];
  user: IUser;
  editUser: (email: string, userDetails: IUserDetails) => Promise<void>;
}) => {
  const RESET = "-- RESET --";

  const renderFibuFinalCheck = (
    user: IUser,
    editUser: (email: string, userDetails: IUserDetails) => Promise<void>
  ) =>
    fibuFinalChecks?.map((fibuFinalCheck) => (
      <EditableCustomerAttribute
        key={fibuFinalCheck.year}
        label={`Fibu final check status ${fibuFinalCheck.year}`}
        initialValue={fibuFinalCheck.status}
        editType={EditType.Select}
        options={{
          Statuses: Object.values(FibuFinalCheckStatus),
          "Reset status (WARNING: it's irreversible)": [RESET],
        }}
        onSaveHandler={async (selection: string) => {
          const status: FibuFinalCheckStatus | null =
            selection !== RESET ? (selection as FibuFinalCheckStatus) : null;
          try {
            await editUser(user.email, {
              fibuFinalChecks: [
                {
                  status,
                  year: fibuFinalCheck.year,
                },
              ],
            });
          } catch ({ message }) {
            if (message === "Forbidden") {
              notification.error({
                message:
                  "Fibu status 'done' kann nicht gesetzt werden, weil die Buchhaltung noch nicht vollständig gebucht ist.",
              });
            }
          }
        }}
      />
    ));

  const texts = useMemo(
    () =>
      fibuFinalChecks.reduce((all, vatYearSetting) => {
        const { year, id } = vatYearSetting;
        return {
          ...all,
          [String(id)]: `Year: ${year}`,
        };
      }, {}),
    [fibuFinalChecks]
  );

  const ids = useMemo(
    () => fibuFinalChecks.map(({ id }) => String(id)),
    [fibuFinalChecks]
  );

  return (
    <>
      <SectionTitle>
        <span>Fibu Status</span>
        <ActionLogDrawerAsync
          title="Action log"
          modelName="fibu_final_check"
          recordIds={ids}
          subTexts={texts}
          small
        />
      </SectionTitle>
      {renderFibuFinalCheck(user, editUser)}
    </>
  );
};

export const UserFibuFinalCheck = ({ year }: { year: number }) => {
  const { user, editUser } = useKontaxUserContext();

  if (!user) return null;

  const fibuFinalCheck = user?.fibuFinalChecks.find(
    (entry) => entry.year === year
  );

  return fibuFinalCheck ? (
    <FibuFinalCheckComponent
      fibuFinalChecks={[fibuFinalCheck]}
      user={user}
      editUser={async (_, userData) => editUser(userData)}
    />
  ) : null;
};

export default FibuFinalCheckComponent;
