import React from "react";
import { Col, Divider, Typography } from "antd";
import moment from "moment";

import {
  EuerDeclarationOfficeUsageSubformCalculationResults,
  EuerDeclarationOfficeUsageSubformFlatRate5EurInputs,
  EuerDeclarationOfficeUsageSubformInputs,
  EuerDeclarationSubformCalculationMethod,
  HomeOwnershipType,
} from "@kontist/euer-declaration";

import { EuerDeclarationSubformStatus } from "../../../../types";
import DeclarationStatusChangeModal from "../../../../components/DeclarationStatusChangeModal";
import useTaxYearParam from "../../../../hooks/useTaxYearParam";
import { formatAmountInCents } from "../../../../../../../utils";
import colors from "../../../../../../../themes/colors";
import {
  HOME_OWNERSHIP_TYPE_LABELS,
  OFFICE_USAGE_CALCULATION_METHOD_LABELS,
} from "./helpers";
import { SUBFORM_STATUSES, SUBFORM_STATUS_MAPPINGS } from "../../constants";

const { Text } = Typography;
const { ListRow, Total } = DeclarationStatusChangeModal;

export interface OfficeUsageStatusChangeModalProps {
  calculationMethod: EuerDeclarationSubformCalculationMethod;
  inputs: EuerDeclarationOfficeUsageSubformInputs;
  calculationResults: EuerDeclarationOfficeUsageSubformCalculationResults;
  status: EuerDeclarationSubformStatus;
  isSmallBusiness: boolean;
  onSave: (status: EuerDeclarationSubformStatus) => Promise<void>;
  onClose: () => void;
  officeAreaShare?: number;
}

const isFlatRateMethod = (
  calculationMethod: EuerDeclarationSubformCalculationMethod,
  inputs: EuerDeclarationOfficeUsageSubformInputs
): inputs is EuerDeclarationOfficeUsageSubformFlatRate5EurInputs =>
  calculationMethod ===
  EuerDeclarationSubformCalculationMethod.OFFICE_USAGE_FLAT_RATE_5_EUR;

const OldOfficeUsageStatusChangeModal = ({
  calculationMethod,
  inputs,
  calculationResults,
  status,
  isSmallBusiness,
  onSave,
  onClose,
  officeAreaShare,
}: OfficeUsageStatusChangeModalProps) => {
  const [taxYear] = useTaxYearParam();

  return (
    <DeclarationStatusChangeModal
      title="Häusliches Arbeitszimmer"
      year={taxYear}
      subtitle={OFFICE_USAGE_CALCULATION_METHOD_LABELS[calculationMethod]}
      status={status}
      statusOptions={SUBFORM_STATUSES}
      statusMapping={SUBFORM_STATUS_MAPPINGS}
      onSave={onSave}
      onClose={onClose}
    >
      {isFlatRateMethod(calculationMethod, inputs) ? (
        <>
          <ListRow>
            <Col>
              <Text strong>Berechnung</Text>
            </Col>
          </ListRow>

          <ListRow>
            <Col flex="1">An wie vielen Tagen genutzt</Col>
            <Col>{inputs.daysUsed}</Col>
          </ListRow>
        </>
      ) : (
        <>
          <ListRow>
            <Col flex="1">Art der Wohnimmobilie</Col>
            <Col>{HOME_OWNERSHIP_TYPE_LABELS[inputs.homeOwnershipType]}</Col>
          </ListRow>

          <Divider className="m-0" />

          <ListRow>
            <Col>
              <Text strong>Angaben Wohnung/Haus</Text>
            </Col>
          </ListRow>

          <ListRow>
            <Col flex="1">Gesamtfläche</Col>
            <Col>{inputs.totalHomeArea}</Col>
          </ListRow>
          <ListRow>
            <Col flex="1">Arbeitszimmer Fläche</Col>
            <Col>{inputs.officeArea}</Col>
          </ListRow>
          {officeAreaShare !== undefined && (
            <ListRow>
              <Col flex="1">Anteil Arbeitszimmer Gesamtfläche</Col>
              <Col>{Math.round(officeAreaShare * 100)} %</Col>
            </ListRow>
          )}

          <Divider className="m-0" />

          <ListRow>
            <Col>
              <Text strong>Nutzungszeitraum</Text>
            </Col>
          </ListRow>

          <ListRow>
            <Col flex="1">Nutzungsbeginn</Col>
            <Col>
              {inputs.usageStartedAt
                ? moment(inputs.usageStartedAt).format("L")
                : ""}
            </Col>
          </ListRow>
          <ListRow>
            <Col flex="1">Nutzungsmonate</Col>
            <Col>{inputs.monthsUsed}</Col>
          </ListRow>

          <Divider className="m-0" />

          <ListRow>
            <Col>
              <Text strong>Kosten</Text>
            </Col>
          </ListRow>
          {inputs.homeOwnershipType === HomeOwnershipType.RENTAL ? (
            <ListRow>
              <Col flex="1">Kaltmiete monatlich (lt. Mietvertrag)</Col>
              <Col>{formatAmountInCents(inputs.monthlyRent, true)}</Col>
            </ListRow>
          ) : (
            <ListRow>
              <Col flex="1">AfA ganze Wohnung volles Jahr (netto)</Col>
              <Col>
                {formatAmountInCents(inputs.annualHomeDepreciation, true)}
              </Col>
            </ListRow>
          )}
          <ListRow>
            <Col flex="1">
              Betriebskosten für tatsächlichen Nutzungszeitraum gesamte Wohnung
              (netto)
            </Col>
            <Col>
              {formatAmountInCents(
                inputs.usagePeriodOperatingHomeExpenses,
                true
              )}
            </Col>
          </ListRow>
          <ListRow>
            <Col flex="1">Weitere Ausgaben gesamte Wohnung (netto)</Col>
            <Col>
              {formatAmountInCents(inputs.annualOtherHomeExpenses, true)}
            </Col>
          </ListRow>
          <ListRow>
            <Col flex="1">Weitere Ausgaben Arbeitszimmer (netto)</Col>
            <Col>
              {formatAmountInCents(inputs.annualOtherOfficeExpenses, true)}
            </Col>
          </ListRow>
          <ListRow>
            <Col
              flex="1"
              style={{
                color:
                  calculationMethod ===
                  EuerDeclarationSubformCalculationMethod.OFFICE_USAGE_UP_TO_1250_EUR
                    ? colors.darkLimeGreen
                    : colors.darkGrey,
              }}
            >
              {calculationMethod ===
              EuerDeclarationSubformCalculationMethod.OFFICE_USAGE_UP_TO_1250_EUR
                ? "Kostendeckelung aktiv"
                : "Summe Kosten Arbeitszimmer"}
            </Col>
            <Col>
              {formatAmountInCents(calculationResults.homeOfficeExpenses, true)}
            </Col>
          </ListRow>

          {!isSmallBusiness && (
            <>
              <Divider className="m-0" />

              <ListRow>
                <Col>
                  <Text strong>Vorsteuer</Text>
                </Col>
              </ListRow>

              <ListRow>
                <Col flex="1">16&nbsp;% gezahlte Vorsteuer gesamte Wohnung</Col>
                <Col>
                  {formatAmountInCents(inputs.paidHomeExpensesInputTax16, true)}
                </Col>
              </ListRow>
              <ListRow>
                <Col flex="1">19&nbsp;% gezahlte Vorsteuer gesamte Wohnung</Col>
                <Col>
                  {formatAmountInCents(inputs.paidHomeExpensesInputTax19, true)}
                </Col>
              </ListRow>
              <ListRow>
                <Col flex="1">16&nbsp;% gezahlte Vorsteuer Arbeitszimmer</Col>
                <Col>
                  {formatAmountInCents(
                    inputs.paidOfficeExpensesInputTax16,
                    true
                  )}
                </Col>
              </ListRow>
              <ListRow>
                <Col flex="1">19&nbsp;% gezahlte Vorsteuer Arbeitszimmer</Col>
                <Col>
                  {formatAmountInCents(
                    inputs.paidOfficeExpensesInputTax19,
                    true
                  )}
                </Col>
              </ListRow>
              <ListRow>
                <Col
                  flex="1"
                  style={{
                    color: colors.darkGrey,
                  }}
                >
                  Summe gezahlte Vorsteuer Arbeitszimmer
                </Col>
                <Col>
                  {formatAmountInCents(
                    calculationResults.paidInputTax || 0,
                    true
                  )}
                </Col>
              </ListRow>
            </>
          )}
        </>
      )}
      <Divider className="m-0" />

      <ListRow>
        <Col>
          <Text strong>Ergebnisse für EÜR</Text>
        </Col>
      </ListRow>

      <ListRow>
        <Col flex="1">
          [172] Abziehbare Aufwendung für häusliches Arbeitszimmer (inkl. AfA
          und Schuldzinsen)
        </Col>
        <Col>
          {formatAmountInCents(calculationResults.homeOfficeExpenses, true)}
        </Col>
      </ListRow>

      {!isFlatRateMethod(calculationMethod, inputs) && !isSmallBusiness && (
        <ListRow>
          <Col flex="1">[185] Gezahlte Vorsteuerbeträge</Col>
          <Col>
            {formatAmountInCents(calculationResults.paidInputTax || 0, true)}
          </Col>
        </ListRow>
      )}

      <Divider className="m-0" />

      <ListRow>
        <Col flex="1">
          <Total>Gewinnminderung</Total>
        </Col>
        <Col>
          <Text
            strong
            style={{
              color:
                calculationResults.profitReduction > 0
                  ? colors.darkRed
                  : colors.darkGrey,
            }}
          >
            {formatAmountInCents(calculationResults.profitReduction, true)}
          </Text>
        </Col>
      </ListRow>
    </DeclarationStatusChangeModal>
  );
};

export default OldOfficeUsageStatusChangeModal;
