import type * as Types from "../../schema.generated";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type FetchDocuWareDocumentsQueryVariables = Types.Exact<{
  accountId: Types.Scalars["Int"];
}>;

export type FetchDocuWareDocumentsQuery = {
  __typename?: "Query";
  docuWareDocuments: {
    __typename?: "DocuWareDocumentResponse";
    totalCount: number;
    documents: Array<{
      __typename?: "DocuWareDocument";
      id: string;
      createdAt: string;
      category: string;
      name?: string | null;
    }>;
  };
};

export const FetchDocuWareDocumentsDocument = gql`
  query FetchDocuWareDocuments($accountId: Int!) {
    docuWareDocuments(accountId: $accountId) {
      totalCount
      documents {
        id
        createdAt
        category
        name
      }
    }
  }
`;

/**
 * __useFetchDocuWareDocumentsQuery__
 *
 * To run a query within a React component, call `useFetchDocuWareDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchDocuWareDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchDocuWareDocumentsQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useFetchDocuWareDocumentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchDocuWareDocumentsQuery,
    FetchDocuWareDocumentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FetchDocuWareDocumentsQuery,
    FetchDocuWareDocumentsQueryVariables
  >(FetchDocuWareDocumentsDocument, options);
}
export function useFetchDocuWareDocumentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchDocuWareDocumentsQuery,
    FetchDocuWareDocumentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FetchDocuWareDocumentsQuery,
    FetchDocuWareDocumentsQueryVariables
  >(FetchDocuWareDocumentsDocument, options);
}
export type FetchDocuWareDocumentsQueryHookResult = ReturnType<
  typeof useFetchDocuWareDocumentsQuery
>;
export type FetchDocuWareDocumentsLazyQueryHookResult = ReturnType<
  typeof useFetchDocuWareDocumentsLazyQuery
>;
export type FetchDocuWareDocumentsQueryResult = Apollo.QueryResult<
  FetchDocuWareDocumentsQuery,
  FetchDocuWareDocumentsQueryVariables
>;
