import styled from "styled-components";

import colors from "../../../themes/colors";

export const CarouselWrapper = styled.div`
  display: flex;
  flex: 1;

  .ant-carousel {
    width: 300px;
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  .slick-dots {
    position: static;
    margin: 0 12px 0;
    flex-wrap: wrap;
  }

  .slick-dots li {
    background-color: ${colors.white};
    border-radius: 50%;
    width: 40px;
    height: 40px;
    cursor: pointer;
    text-indent: 0;
    :hover {
      background-color: ${colors.lightestPurple};
    }
  }

  .slick-dots li div {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .slick-dots li.slick-active {
    color: ${colors.purple};
    background-color: ${colors.lightPurple};
    width: 40px;
  }

  .slick-dots-bottom {
    bottom: 0;
  }

  .imageWrapper {
    width: 100%;
    background: rgba(238, 238, 238, 0.8);
  }

  .assetContainer {
    display: flex;
    position: relative;
    margin: auto;
    min-height: 600px;
    height: calc(100vh - 600px);
    width: calc(100% - 10px);
  }

  .invoice {
    max-width: 100%;
    margin: auto;
    object-fit: contain;
    max-height: 100%;
  }

  .img-magnifier-glass {
    position: absolute;
    z-index: 1000;
    border: 3px solid rgba(255, 255, 255, 0.7);
    cursor: none;
    /*Set the size of the magnifier glass:*/
    width: 200px;
    height: 200px;

    img {
      display: block;
    }
`;

export const RotateReceiptButtonWrapper = styled.div`
  text-align: right;
  margin-right: 8px;
  position: absolute;
  bottom: 12px;
  right: 0;

  /* Ant Design button corrections */
  button svg {
    color: ${colors.purple};
    vertical-align: unset;
  }

  button:hover,
  button:active,
  button:focus {
    border-color: ${colors.purple};
  }
`;

export const AssetImage = styled.img`
  transition: transform;
  transition-duration: 100ms;
`;
