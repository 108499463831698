import React from "react";
import { Radio } from "antd";

import { Direction } from "../../types";

export const DirectionRadioButton = (props: {}) => {
  return (
    <Radio.Group
      defaultValue={Direction.OUTGOING}
      options={[
        { label: "Outgoing", value: Direction.OUTGOING },
        { label: "Incoming", value: Direction.INCOMING },
      ]}
      optionType="button"
      buttonStyle="solid"
      {...props}
    />
  );
};
