import { Collapse } from "antd";

import { DeleteOutlined } from "@ant-design/icons";

import { useState } from "react";

import { DeclarationDocumentFragment } from "../../../../../../api/graphql/fragments/declarationDocument.generated";
import LocalAssetsViewer from "../../../../../common/LocalAssetsViewer";
import {
  DocumentDescription,
  DocumentTitle,
  DocumentCollapse,
  DocumentTitleContent,
  DocumentTitleIcon,
  DocumentTitleWrapper,
  DeleteButtonWrapper,
  DeleteButton,
} from "./styles";
import { useDeleteAssetMutation } from "../../../../../../api/graphql";

const { Panel } = Collapse;

const DocumentsList = ({
  documents,
  onRefresh,
}: {
  documents: DeclarationDocumentFragment[];
  onRefresh: () => void;
}) => {
  const [deleteAssetHandler] = useDeleteAssetMutation();
  const [isDisabled, setIsDisabled] = useState(false);

  return (
    <DocumentCollapse>
      {documents.map((document) => {
        return document.assets.map((asset) => {
          return (
            <Panel
              collapsible={isDisabled ? "disabled" : "header"}
              header={
                <DocumentTitleWrapper>
                  <DocumentTitleIcon />
                  <DocumentTitleContent>
                    <DocumentTitle>{asset.name}</DocumentTitle>
                    <DocumentDescription type="secondary">
                      {document.createdAt}
                    </DocumentDescription>
                  </DocumentTitleContent>
                </DocumentTitleWrapper>
              }
              key={asset.id}
              showArrow={false}
            >
              <LocalAssetsViewer
                assets={[asset]}
                minHeight={490}
                maxHeight={490}
              />
              <DeleteButtonWrapper>
                <DeleteButton
                  disabled={isDisabled}
                  onClick={async () => {
                    setIsDisabled(true);
                    await deleteAssetHandler({
                      variables: {
                        id: asset.id,
                      },
                    });
                    onRefresh();
                    setIsDisabled(false);
                  }}
                  icon={<DeleteOutlined />}
                >
                  Löschen
                </DeleteButton>
              </DeleteButtonWrapper>
            </Panel>
          );
        });
      })}
    </DocumentCollapse>
  );
};

export default DocumentsList;
