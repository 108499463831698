import type * as Types from "../schema.generated";

import { gql } from "@apollo/client";
export type TaxDeclarationSavedDraftDataFragment = {
  __typename?: "TaxDeclarationSavedDraftInfo";
  createdAt?: string | null;
  createdBy?: string | null;
  updatedAt?: string | null;
  pdf?: string | null;
  calculationSheet?: string | null;
  externalAssets?: Array<{
    __typename?: "TaxDeclarationExternalAsset";
    url: string;
    filetype: string;
    createdAt: string;
  }> | null;
};

export const TaxDeclarationSavedDraftDataFragmentDoc = gql`
  fragment TaxDeclarationSavedDraftData on TaxDeclarationSavedDraftInfo {
    createdAt
    createdBy
    updatedAt
    externalAssets {
      url
      filetype
      createdAt
    }
    pdf
    calculationSheet
  }
`;
