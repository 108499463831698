import React, { ReactNode, useState } from "react";
import styled from "styled-components";

import { Transaction } from "../../../../types/transactions";
import {
  BUSINESS_ASSET_CATEGORIES,
  EXTERNAL_TRANSACTION_CATEGORIES,
} from "../../../../utils";
import CategorySelect from "../../TransactionView/CategorySelect";
import VatCategorySelect from "../../TransactionView/VatCategorySelect";
import { MatchButton } from "./styles";

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

type BookAndMatchTransaction = (
  transaction: Transaction,
  vatCategoryCode: string,
  categoryCode: string
) => void;

const ExpandedRow = ({
  transaction,
  bookAndMatchTransaction,
}: {
  transaction: Transaction;
  bookAndMatchTransaction: BookAndMatchTransaction;
}) => {
  const [vatCategoryCode, setVatCategoryCode] = useState<
    string | null | undefined
  >(transaction.vatCategoryCode);
  const [categoryCode, setCategoryCode] = useState<string | null | undefined>(
    transaction.categoryCode
  );

  return (
    <Wrapper>
      <VatCategorySelect
        value={vatCategoryCode}
        meta={transaction.vatCategoryCodeMeta}
        disabled={false}
        style={{
          marginRight: "15px",
          width: "285px",
        }}
        onChangeHandler={setVatCategoryCode}
      />

      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <CategorySelect
          value={categoryCode}
          meta={transaction.categoryCodeMeta}
          transactionAmount={transaction.amount}
          disabledOptions={[
            ...EXTERNAL_TRANSACTION_CATEGORIES,
            ...BUSINESS_ASSET_CATEGORIES,
          ]}
          disabled={false}
          onChangeHandler={setCategoryCode}
          style={{
            marginRight: "15px",
            width: "200px",
          }}
        />
        <MatchButton
          data-test="matchedTransactionsList.matchButton"
          disabled={!(categoryCode && vatCategoryCode)}
          onClick={async () => {
            if (categoryCode && vatCategoryCode) {
              await bookAndMatchTransaction(
                transaction,
                vatCategoryCode,
                categoryCode
              );
            }
          }}
        >
          Book & Match
        </MatchButton>
      </div>
    </Wrapper>
  );
};

export const renderExpandedRow = (
  transaction: Transaction,
  bookAndMatchTransaction: BookAndMatchTransaction
): ReactNode => {
  return (
    <ExpandedRow
      transaction={transaction}
      bookAndMatchTransaction={bookAndMatchTransaction}
    />
  );
};
