import {
  DeclarationDecline,
  KontaxNote,
  TaxDeclarationSavedDraftInfo,
  TaxDeclarationSubmission,
  TaxDeclarationSubmissionInfo,
  VatAnnualDeclarationStatus,
} from "../schema.generated";
import ChangeLog from "./ChangeLog";

export enum VatAnnualDeclarationFieldType {
  // Steuerpflichtige Umsätze
  TOTAL_INCOME_19 = "TOTAL_INCOME_19", // Summe Umsätze 19 %
  TOTAL_INCOME_7 = "TOTAL_INCOME_7", // Summe Umsätze 7 %
  INCOME_19 = "INCOME_19", // Steuerpflichtige Umsätze 19 %
  INCOME_7 = "INCOME_7", // Steuerpflichtige Umsätze 7 %
  INCOME_EU_B2C = "INCOME_EU_B2C", // Erlöse EU Sonstige Leistung (B2C)
  INCOME_EU_INTRA_B2C = "INCOME_EU_INTRA_B2C", // Erlöse EU Innerg. Lieferung (B2C)
  TOTAL_INCOME_REDUCED_RATES = "TOTAL_INCOME_REDUCED_RATES", // Summe andere Steuersätze
  INCOME_16 = "INCOME_16", // Steuerpflichtige Umsätze 16 %
  INCOME_5 = "INCOME_5", // Steuerpflichtige Umsätze 5 %
  INTRA_ACQUISITION_ST = "INTRA_ACQUISITION_ST", // Innerg. Erwerb (Umsatzsteuer)
  REVERSE_CHARGE_ST = "REVERSE_CHARGE_ST", // Reverse Charge (Umsatzsteuer)
  REVERSE_CHARGE = "REVERSE_CHARGE", // Reverse Charge (nur USt)
  FREE_VALUE_DELIVERY_19 = "FREE_VALUE_DELIVERY_19", // Unentgeltliche Wertabgaben (Lieferungen nach § 3 Absatz 1b UStG zu 19 %)
  FREE_BENEFITS = "FREE_BENEFITS", // Unentgeltliche Wertabgaben (Sonstige Leistungen nach § 3 Abs. 9a UStG) zu 19 %
  CAR_USAGE = "CAR_USAGE", // Umsatzsteuer aus privater KFZ Nutzung
  OTHER_USAGE_AND_SERVICE_WITHDRAWALS = "OTHER_USAGE_AND_SERVICE_WITHDRAWALS", // Umsatzsteuer aus anderen sonstigen Leistungen
  FREE_VALUE_DELIVERY_7 = "FREE_VALUE_DELIVERY_7", // Unentgeltliche Wertabgaben (Lieferungen nach § 3 Absatz 1b UStG zu 7 %)
  FREE_VALUE_SERVICE_7 = "FREE_VALUE_SERVICE_7", // Unentgeltliche Wertabgaben (Sonstige Leistungen nach § 3 Absatz 9a UStG zu 7 %)

  // Abziehbare Vorsteuerbeträge
  TOTAL_DIT = "TOTAL_DIT", // Summe abziehbare Vorsteuerbeträge
  DIT_5 = "DIT_5", // Abziehbare Vorsteuerbeträge 5 %
  DIT_7 = "DIT_7", // Abziehbare Vorsteuerbeträge 7 %
  DIT_16 = "DIT_16", // Abziehbare Vorsteuerbeträge 16 %
  DIT_19 = "DIT_19", // Abziehbare Vorsteuerbeträge 19 %
  HOME_OFFICE_DIT = "HOME_OFFICE_DIT", // Abziehbare Vorsteuerbeträge für häusliches Arbeitszimmer
  KKR_1850 = "KKR_1850", //KKR 1850
  INTRA_ACQUISITION_IT = "INTRA_ACQUISITION_IT", // Innerg. Erwerb (Vorsteuer)
  IMPORT_VAT = "IMPORT_VAT", // Einfuhrumsatzsteuer
  REVERSE_CHARGE_IT = "REVERSE_CHARGE_IT", // Reverse Charge (Vorsteuer)

  // Ergänzende Angaben zu Umsätzen
  INCOME_EU_INTRA_B2B = "INCOME_EU_INTRA_B2B", // Erlöse EU Innerg. Lieferung (B2B)
  VAT_EXEMPTION_WITH_ITD = "VAT_EXEMPTION_WITH_ITD", // Weitere steuerfreie Umsätze mit Vorsteuerabzug
  EXPORT_DELIVERY = "EXPORT_DELIVERY", // Ausfuhrlieferungen
  INCOME_0_ITD = "INCOME_0_ITD", // Umsätze nach § 4 Nr. ... UStG
  VAT_EXEMPTION_WITHOUT_ITD = "VAT_EXEMPTION_WITHOUT_ITD", // Steuerfreie Umsätze ohne Vorsteuerabzug nach § ... UStG
  INCOME_EU_B2B = "INCOME_EU_B2B", // Erlöse EU Sonstige Leistung (B2B)
  NON_TAXABLE = "NON_TAXABLE", // Übrige nicht steuerbare Umsätze (Leistungsort nicht im Inland)
  INCOME_13B5_USTG = "INCOME_13B5_USTG", // Steuerpflichtige Umsätze des leistenden Unternehmers, für die der Leistungsempfänger die Steuer nach § 13b Abs. 5 UStG schuldet
  ELECTRONIC_SERVICE_EU_B2C = "ELECTRONIC_SERVICE_EU_B2C", // Elektronische Leistungen EU (B2C)
}

export interface VatAnnualDeclarationField {
  type: VatAnnualDeclarationFieldType;
  netAmount: number | null;
  vatAmount: number | null;
  advanceNetAmount: number | null;
  advanceVatAmount: number | null;
  transactionFilters: {
    categoryCodes: string[];
    vatCategoryCodes: string[];
  };
}

export interface VatAnnualDeclarationSection {
  fields: VatAnnualDeclarationField[];
  total: number | null;
}

export interface VatAnnualDeclarationAutoCalculatedValues {
  salesTax: VatAnnualDeclarationSection;
  deductibleInputTax: VatAnnualDeclarationSection;
  additionalInformation: VatAnnualDeclarationSection;
  total: number;
}

interface VatAnnualDeclarationStatusChange {
  status: VatAnnualDeclarationStatus;
  changedAt: string;
  changedBy?: string;
}

export interface VatAnnualDeclaration {
  id: string;
  status: VatAnnualDeclarationStatus;
  statusUpdatedAt: string | null;
  lastYearIncomeAdjustment: number | null;
  currentYearIncomeAdjustment: number | null;
  taxOfficeBalance: number | null;
  submissionInfo: TaxDeclarationSubmissionInfo | null;
  submissions: TaxDeclarationSubmission[] | [];
  savedDraftInfo: TaxDeclarationSavedDraftInfo | null;
  lastStatusChange: VatAnnualDeclarationStatusChange | null;
  changeLogs: ChangeLog[];
  notes: KontaxNote[];
  declarationDeclines: DeclarationDecline[];
}
