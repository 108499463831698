import { ITransaction, TransactionSource } from "../../../types";

export const EMPTY_TRANSACTION: ITransaction = {
  email: "",
  firstName: "",
  lastName: "",
  id: "",
  type: "",
  amount: 0,
  valutaDate: "",
  name: "",
  iban: "",
  description: "",
  foreignCurrency: "",
  businessTypeComment: "",
  personalNote: null,
  assets: [],
  verified: false,
  source: TransactionSource.SOLARIS,
};
