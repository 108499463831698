import { TaxPreparationAnswer } from "../constants";

export enum TaxPreparationTopic {
  CAR_USAGE = "CAR_USAGE",
  OFFICE_USAGE = "OFFICE_USAGE",
  TRAVEL_EXPENSES = "TRAVEL_EXPENSES",
}

export const ANSWERS: {
  [answer: string]: string;
} = {
  //  Car Usage Question 2_1
  LESS_THAN_50_PERCENT: "Weniger als 50 %",
  MORE_THAN_50_PERCENT: "Mehr als 50 % aber weniger als 99 %",
  TO_100_PERCENT: "Zu 100 %",
  // Car Usage Question 4
  CAR_USAGE_DRIVERS_LOG: "Fartenbuch",
  CAR_USAGE_1_PCT_METHOD: "1 %-Regelung",
};

export const QUESTIONS: {
  [question: string]: string;
} = {
  CAR_USAGE_QUESTION_1:
    "Nutzt du dieses Jahr einen Pkw im Rahmen deiner Selbständigkeit?",
  CAR_USAGE_QUESTION_2: "Benutzt du diesen Pkw zu mehr als 50 % betrieblich?",
  CAR_USAGE_QUESTION_2_1:
    "Zu welchem Anteil benutzt du deinen Pkw betrieblich?",
  CAR_USAGE_QUESTION_3: "Führst du dieses Jahr ein Fahrtenbuch?",
  CAR_USAGE_QUESTION_4:
    "Möchtest du die 1 %-Regelung anwenden oder führst du ein Fahrtenbuch?",
  CAR_USAGE_QUESTION_5: "Du wirst voraussichtlich keinen PKW haben.",
  CAR_USAGE_QUESTION_6:
    "Du nutzt deinen privaten PKW gelegentlich betrieblich.",
  CAR_USAGE_QUESTION_7:
    "Du nutzt deinen privaten PKW gelegentlich betrieblich.",
  CAR_USAGE_QUESTION_8: "Du nutzt deinen PKW überwiegend betrieblich.",
  OFFICE_USAGE_QUESTION_1: "Arbeitest du 2022 von Zuhause aus?",
  OFFICE_USAGE_QUESTION_2:
    "Ist der Arbeitsplatz in einem räumlich abgetrennten Bereich und wird außerdem überwiegend betrieblich genutzt?",
  OFFICE_USAGE_QUESTION_3:
    "Du wirst voraussichtlich nicht von Zuhause arbeiten.",
  OFFICE_USAGE_QUESTION_4:
    "Du kannst voraussichtlich für jeden Tag, den du von Zuhause aus arbeitest, eine Pauschale geltend machen.",
  OFFICE_USAGE_QUESTION_5: "Denke daran für alle Ausgaben Belege zu sammeln.",
  TRAVEL_EXPENSES_QUESTION_1:
    "Hast du 2022 Geschäftsreisen unternommen oder planst dieses Jahr noch welche zu machen?",
  TRAVEL_EXPENSES_QUESTION_2:
    "Du wirst voraussichtlich keine Geschäftsreisen unternehmen.",
  TRAVEL_EXPENSES_QUESTION_3:
    "Denke daran die Geschäftsreisen sauber zu dokumentieren.",
};

export const QUESTION_TOPIC_LABELS: {
  [topic: string]: string;
} = {
  [TaxPreparationTopic.CAR_USAGE]: "Pkw",
  [TaxPreparationTopic.OFFICE_USAGE]: "Arbeitszimmer",
  [TaxPreparationTopic.TRAVEL_EXPENSES]: "Geschäftsreisen",
};

export interface TaxPreparationQuestion {
  number: number;
  epic: TaxPreparationTopic;
  answer?: TaxPreparationAnswer;
  updatedAt?: Date;
  description: string;
}

export interface TaxPreparationForm {
  updatedAt?: Date;
  startedAt?: Date;
  completedAt?: Date;
  changedBy?: string;
  questions: Array<TaxPreparationQuestion>;
}
