import React, { useMemo } from "react";
import { Button, Col, Space, Typography } from "antd";
import moment from "moment-timezone";

import {
  EuerDeclaration,
  TradeTaxDeclaration,
  VatAnnualDeclaration,
} from "../../../../../api/graphql";
import { DeclineBannerContainer, StyledRightOutline } from "./styledComponents";
import { useUserContext } from "../../contexts/UserContext";
import {
  TaxDeclarationType,
  EuerDeclarationStatus,
  VatAnnualDeclarationStatus,
  TradeTaxDeclarationStatus,
  IncomeTaxDeclarationStatus,
  IncomeTaxDeclaration,
} from "../../../../../api/graphql/schema.generated";

const { Text } = Typography;

type TaxDeclaration =
  | EuerDeclaration
  | VatAnnualDeclaration
  | TradeTaxDeclaration
  | IncomeTaxDeclaration;

const GET_DECLARATION_STATUS_BY_DECLARATION_TYPE = {
  [TaxDeclarationType.EUER]: EuerDeclarationStatus,
  [TaxDeclarationType.VAT_ANNUAL]: VatAnnualDeclarationStatus,
  [TaxDeclarationType.TRADE_TAX]: TradeTaxDeclarationStatus,
  [TaxDeclarationType.INCOME_TAX]: IncomeTaxDeclarationStatus,
};

const DeclarationDeclinedBanner = ({
  declaration,
  declarationType,
  onLinkClick,
}: {
  declaration: TaxDeclaration;
  declarationType: TaxDeclarationType;
  onLinkClick: () => void;
}) => {
  const user = useUserContext();

  const declarationStatus =
    GET_DECLARATION_STATUS_BY_DECLARATION_TYPE[declarationType];

  const latestDeclinedDate = useMemo(() => {
    const latestDeclined = declaration?.declarationDeclines?.sort((a, b) =>
      moment(a.createdAt).isBefore(b.createdAt) ? 1 : -1
    )[0];
    return moment(latestDeclined?.createdAt).format("DD.MM.YYYY");
  }, [declaration?.declarationDeclines]);

  if (
    !user ||
    declaration?.status !== declarationStatus.OBJECTED_BY_USER ||
    !declaration.declarationDeclines.length
  ) {
    return null;
  }

  return (
    <DeclineBannerContainer>
      <Col flex="auto">
        <Space size={16}>
          <Text>Vorbehalt</Text>
          <Text type="secondary">{`${user.firstName} ${user.lastName} am ${latestDeclinedDate}`}</Text>
        </Space>
      </Col>
      <Col>
        <Space size={0}>
          <Button type="link" onClick={onLinkClick}>
            Zum Ablehnungsgrund
          </Button>
          <StyledRightOutline />
        </Space>
      </Col>
    </DeclineBannerContainer>
  );
};

export default DeclarationDeclinedBanner;
