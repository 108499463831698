import type * as Types from "../../schema.generated";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type DeleteHomeOfficeExpenseMutationVariables = Types.Exact<{
  homeOfficeExpenseId: Types.Scalars["ID"];
}>;

export type DeleteHomeOfficeExpenseMutation = {
  __typename?: "Mutation";
  deleteHomeOfficeExpense: { __typename?: "MutationResult"; success: boolean };
};

export const DeleteHomeOfficeExpenseDocument = gql`
  mutation deleteHomeOfficeExpense($homeOfficeExpenseId: ID!) {
    deleteHomeOfficeExpense(homeOfficeExpenseId: $homeOfficeExpenseId) {
      success
    }
  }
`;
export type DeleteHomeOfficeExpenseMutationFn = Apollo.MutationFunction<
  DeleteHomeOfficeExpenseMutation,
  DeleteHomeOfficeExpenseMutationVariables
>;

/**
 * __useDeleteHomeOfficeExpenseMutation__
 *
 * To run a mutation, you first call `useDeleteHomeOfficeExpenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteHomeOfficeExpenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteHomeOfficeExpenseMutation, { data, loading, error }] = useDeleteHomeOfficeExpenseMutation({
 *   variables: {
 *      homeOfficeExpenseId: // value for 'homeOfficeExpenseId'
 *   },
 * });
 */
export function useDeleteHomeOfficeExpenseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteHomeOfficeExpenseMutation,
    DeleteHomeOfficeExpenseMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteHomeOfficeExpenseMutation,
    DeleteHomeOfficeExpenseMutationVariables
  >(DeleteHomeOfficeExpenseDocument, options);
}
export type DeleteHomeOfficeExpenseMutationHookResult = ReturnType<
  typeof useDeleteHomeOfficeExpenseMutation
>;
export type DeleteHomeOfficeExpenseMutationResult =
  Apollo.MutationResult<DeleteHomeOfficeExpenseMutation>;
export type DeleteHomeOfficeExpenseMutationOptions = Apollo.BaseMutationOptions<
  DeleteHomeOfficeExpenseMutation,
  DeleteHomeOfficeExpenseMutationVariables
>;
