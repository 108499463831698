import config from "./config";
import api from "./api";
import { PermissionsScope } from "./types";

declare global {
  interface Window {
    gapi: any;
  }
}

let authInstance: any = null;
let permissionScope: PermissionsScope | any = null;

const useDebugMock = localStorage.getItem("debug:mockAuth") === "true";
const isCypress = !!("Cypress" in window);
const isPreview = window.location.hostname.endsWith(".herokuapp.com");
const INVALID_LOGIN = "Invalid login";

/**
 * Used to bypass GoogleAuth when cypress is running or in pull request preview
 * environments.
 */
const mockGetAuthInstance = (mockEmail: string) => {
  return {
    isSignedIn: {
      get: () => true,
      listen: () => {},
    },
    currentUser: {
      get: () => ({
        getAuthResponse: () => ({
          id_token: mockEmail,
        }),
        getBasicProfile: () => ({
          getEmail: () => mockEmail,
        }),
      }),
    },
  };
};

export const getAuthInstance = () => {
  if (useDebugMock) {
    authInstance = mockGetAuthInstance("debug@kontist.com");
  } else if (isCypress) {
    authInstance = mockGetAuthInstance("e2e@kontist.com");
  } else if (isPreview) {
    authInstance = mockGetAuthInstance("preview@kontist.com");
  } else {
    authInstance = authInstance || window.gapi?.auth2?.getAuthInstance();
  }
  return authInstance;
};

export const getToken = (): string => {
  return getAuthInstance()?.currentUser.get().getAuthResponse().id_token;
};

export const getCurrentUserEmail = () => {
  return (
    getAuthInstance()?.isSignedIn &&
    getAuthInstance()?.currentUser.get().getBasicProfile()?.getEmail()
  );
};

const SetPermissionScope =
  (callback: Function) => (permission: PermissionsScope | null) => {
    permissionScope = permission;
    callback(permissionScope);
  };

export const getPermissionScope = () => permissionScope;

export const setupAuth = (setPermissionScopeCallback: Function) => {
  const setPermissionScope = SetPermissionScope(setPermissionScopeCallback);

  if (useDebugMock || isCypress || isPreview) {
    setPermissionScope(PermissionsScope.ADMIN);
    return;
  }

  window.gapi.load("auth2", function () {
    window.gapi.auth2
      .init({
        client_id: config.GOOGLE_CLIENT_ID,
        hosted_domain: "ageras.com",
        redirect_uri: config.REACT_APP_URL,
        ux_mode: "redirect",
      })
      .then(
        () => {
          getAuthInstance()?.isSignedIn.listen((isSignedIn: boolean) => {
            if (!isSignedIn) {
              // set invalid to redirect to login screen
              setPermissionScope(PermissionsScope.INVALID);
            }
          });
        },
        // eslint-disable-next-line no-console
        (error: any) => console.log(error)
      )
      .then(() =>
        !getCurrentUserEmail()
          ? Promise.reject(INVALID_LOGIN)
          : api.kontax.getSelfPermissionScope({ skipNotification: true })
      )
      .then(({ data: permissionsScope }: { data: PermissionsScope }) => {
        // set permission scope here for route authentication
        setPermissionScope(permissionsScope);
      })
      .catch((err: any) => {
        // only log user out if account is invalid
        if (err === INVALID_LOGIN) {
          setPermissionScope(PermissionsScope.INVALID);
          return;
        }

        // eslint-disable-next-line no-console
        console.error(err);
        // @TODO: should only allow authenticated agents with permission.
        // This is added because we don't know for sure if all permissions have been set correctly or not.
        setPermissionScope(PermissionsScope.READONLY);
      });
  });
};

export default setupAuth;
