import React from "react";
import groupBy from "lodash/groupBy";
import compose from "lodash/fp/compose";
import map from "lodash/fp/map";
import sortBy from "lodash/fp/sortBy";
import { Select, Form } from "antd";
import { NamePath } from "antd/lib/form/interface";

import { TransactionSource, ValueMeta } from "../../../types";
import SelectBadges from "./SelectBadges";
import { incomingCategoriesRaw, outgoingCategoriesRaw } from "../../../utils";
import { AntSelect } from "../../common/styledComponents";
import {
  RESET_OPTION,
  NOT_VERIFIED_CODES,
  EXCLUDED_CATEGORIES_FOR_NO_VAT_NUMBER,
  DEPRECATED_CATEGORIES,
} from "../../../utils/categories";
import { CategoryCodeType } from "../../../types";
import { useKontaxUserContext } from "../../contexts/KontaxUserContext";
import SourceBadge from "./SourceBadge";
import { BadgesBlock } from "./styledComponents";

const { Option, OptGroup } = Select;

const normalizeCategories = (
  transactionDirection: TransactionDirection,
  categories: string[][]
) =>
  compose(
    sortBy(0), // sort by value
    map((category: string[]): string[] => [...category, transactionDirection]) // append transaction direction
  )(categories);

enum TransactionDirection {
  Incoming = "Incoming categories",
  Outgoing = "Outgoing categories",
}

// Do not enable the user to select a deprecated category
// however, if the deprecated category was already used as a value, display it
const removeDeprecatedCategory = (
  categories: string[][],
  value: string | null | undefined
) => {
  return categories.filter((category) => {
    if (!DEPRECATED_CATEGORIES.includes(category[0])) {
      return true;
    } else {
      return !!value && value === category[0];
    }
  });
};

const getCategories = ({
  transactionAmount,
  value,
  options,
  disabledOptions,
  withResetOption,
  withoutExcludedNoVatNumberCodes,
}: {
  transactionAmount: number;
  value?: string | null;
  options?: string[];
  disabledOptions?: string[];
  withResetOption: boolean;
  withoutExcludedNoVatNumberCodes?: boolean;
}) => {
  const incomingCategories: string[][] | any = normalizeCategories(
    TransactionDirection.Incoming,
    removeDeprecatedCategory(incomingCategoriesRaw, value)
  );
  const outgoingCategories: string[][] | any = normalizeCategories(
    TransactionDirection.Outgoing,
    removeDeprecatedCategory(outgoingCategoriesRaw, value)
  );
  const getCategoriesByTransactionDirection = (amount: number): string[][] => {
    if (amount > 0) {
      return incomingCategories;
    } else {
      return outgoingCategories;
    }
  };

  let categories: string[][] = [];

  if (!transactionAmount) return [];

  if (!value || NOT_VERIFIED_CODES.includes(value)) {
    categories.push(["", "Category not set", "", "disabled"]);
    if (withResetOption) {
      categories.push([RESET_OPTION.RESET, "-- RESET --", ""]);
    }
  } else {
    categories.push(["", "-- RESET --", ""]);
  }

  let generatedCategories;
  // Restrict displayed categories to the provided `options`.
  if (options) {
    generatedCategories = categories.concat(
      [...incomingCategories, ...outgoingCategories].filter((category) => {
        return options.includes(category[0]);
      })
    );
  } else {
    categories = categories.concat(
      getCategoriesByTransactionDirection(transactionAmount)
    );

    generatedCategories = categories.concat(
      getCategoriesByTransactionDirection(-transactionAmount)
    );
  }

  if (withoutExcludedNoVatNumberCodes) {
    generatedCategories = generatedCategories.map((category) => {
      return EXCLUDED_CATEGORIES_FOR_NO_VAT_NUMBER.includes(
        category[0] as CategoryCodeType
      )
        ? [...category, "disabled"]
        : category;
    });
  }

  // If disabled options have been provided, find the matching categories and disable them.
  if (disabledOptions) {
    return generatedCategories.map((category) => {
      return disabledOptions.includes(category[0])
        ? [...category, "disabled"]
        : category;
    });
  }

  return generatedCategories;
};

const renderSelectOptions = (
  categoryOptions: string[][]
): React.ReactNodeArray =>
  categoryOptions.map(([val, text, group, disabled]) => {
    return (
      <Option key={val || text} value={val} disabled={!!disabled}>
        {text}
      </Option>
    );
  });

const CategorySelect = ({
  name,
  value,
  onChangeHandler,
  transactionAmount,
  className,
  focused,
  meta = {},
  id,
  options,
  disabledOptions = [],
  disabled,
  invalid = false,
  style,
  label,
  help,
  withResetOption = false,
  source,
  type,
}: {
  name?: NamePath;
  value?: string | null;
  onChangeHandler?: (value: CategoryCodeType) => void;
  transactionAmount: number;
  className?: string;
  focused?: boolean;
  meta?: ValueMeta;
  id?: string;
  options?: string[];
  disabledOptions?: string[];
  disabled?: boolean;
  invalid?: boolean;
  style?: React.CSSProperties;
  label?: string;
  help?: string;
  withResetOption?: boolean;
  source?: TransactionSource;
  type?: string;
}) => {
  const user = useKontaxUserContext().user;
  const withoutExcludedNoVatNumberCodes = !!user && !user.vatNumber;

  const categoryOptions = getCategories({
    transactionAmount,
    value,
    options,
    disabledOptions,
    withResetOption,
    withoutExcludedNoVatNumberCodes,
  });

  const isValueAllowed = categoryOptions
    .map(([categoryCode]) => categoryCode)
    .includes(value as string);

  // Group categories by transaction direction, either: outgoing or incoming
  const categoryOptionsGroups = groupBy(
    categoryOptions,
    (category) => category[2]
  );

  return (
    <AntSelect data-test="categorySelect" style={style}>
      <Form.Item label={label}>
        <BadgesBlock>
          {source && type && <SourceBadge source={source} type={type} />}
          {meta && <SelectBadges meta={meta} hasValue={!!value} />}
        </BadgesBlock>
        <Form.Item
          name={name}
          validateStatus={invalid ? "error" : undefined}
          help={help}
        >
          <Select
            id={id || "category"}
            style={{ width: style?.width }}
            className={className}
            showSearch
            value={value && isValueAllowed ? value : ""}
            onChange={(value) =>
              onChangeHandler?.((value as CategoryCodeType) || null)
            }
            dropdownMatchSelectWidth={320}
            filterOption={(input, option: any) => {
              if (option.children) {
                return (
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                );
                // allows it to type e.g. "outgoing or incoming categories" and you will see all outgoing or incoming transactions
              } else if (option.label) {
                return (
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                );
              } else {
                return false;
              }
            }}
            disabled={disabled}
            autoFocus={focused}
          >
            {Object.keys(categoryOptionsGroups).map((group) => {
              if (group) {
                return (
                  <OptGroup key={group} label={group}>
                    {renderSelectOptions(categoryOptionsGroups[group])}
                  </OptGroup>
                );
              } else {
                return renderSelectOptions(categoryOptionsGroups[group]);
              }
            })}
          </Select>
        </Form.Item>
      </Form.Item>
    </AntSelect>
  );
};

export default CategorySelect;
