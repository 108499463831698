import React, { ReactNode, useEffect } from "react";

import { AntLayout } from "./styledComponents";
import LeftNavigation from "./LeftNavigation";
import api from "../../../api";
import useEmailParam from "../../hooks/useEmailParam";
import useUsageStatsTracker from "../../hooks/useUsageStatsTracker";

const PageWrapperWithNavigation = (props: { children: ReactNode }) => {
  useUsageStatsTracker();

  const [emailParam] = useEmailParam();
  useEffect(() => {
    document.title = "Backoffice";
    const fetchData = async () => {
      if (emailParam) {
        const user = await api.kontax.getUserByEmail(emailParam);
        if (user) {
          document.title = `${user.firstName} ${user.lastName}`;
        }
      }
    };

    fetchData().catch(() => {}); // ignore "User not found"
  }, [emailParam]);

  return (
    <AntLayout hasSider>
      <LeftNavigation />
      {props.children}
    </AntLayout>
  );
};

export default PageWrapperWithNavigation;
