import React, { useState, useEffect, ReactNode } from "react";
import { Radio, Space } from "antd";

export interface RadioButtonGroupOption {
  label: string;
  value: any;
  isReset?: boolean;
}

type SpaceDirection = "vertical" | "horizontal";

const RadioButtonGroup = ({
  value,
  options,
  onChange,
  isReset,
  direction = "vertical",
  renderLabel,
}: {
  value?: any;
  options: Array<RadioButtonGroupOption>;
  onChange: (value: any) => void;
  isReset?: boolean;
  direction?: SpaceDirection;
  renderLabel?: (label: string, value: any) => ReactNode;
}) => {
  const [currentValue, setCurrentValue] = useState(value || options[0].value);

  useEffect(() => {
    if (isReset) {
      setCurrentValue(options[0].value);
    }
  }, [options, isReset]);

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  return (
    <Radio.Group
      onChange={(e) => {
        setCurrentValue(e.target.value);
        onChange(e.target.value);
      }}
      value={currentValue}
    >
      <Space direction={direction}>
        {options.map(({ label }, index) => (
          <Radio value={options[index].value} key={index}>
            {renderLabel ? renderLabel(label, options[index].value) : label}
          </Radio>
        ))}
      </Space>
    </Radio.Group>
  );
};

export default RadioButtonGroup;
