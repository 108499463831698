import styled from "styled-components";
import { Select } from "antd";

import colors from "../../../themes/colors";

export const Bar = styled.div`
  background-color: ${colors.veryDarkGreyishBlue};
  color: ${colors.white};
  padding: 10px 0;
`;

export const Left = styled.div`
  display: inline-block;
  padding: 0 20px;
  width: 200px;
`;

export const Title = styled.span`
  font-size: 12px;
  font-weight: bold;
  margin-left: 10px;
  text-transform: uppercase;
`;

export const Right = styled.div`
  display: inline-block;
  padding: 0 24px;
`;

export const SettingsList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  > li {
    display: inline-block;
    padding: 0 10px;

    &:first-child {
      padding-left: 0;
    }
  }
`;

export const ApiUrlSelect = styled(Select)`
  color: ${colors.white};

  .ant-select-selector {
    padding-left: 0 !important;
  }

  .ant-select-arrow {
    color: ${colors.white};
  }
`;

export const UserSelect = styled(Select)`
  color: ${colors.white};

  .ant-select-selector {
    padding-left: 0 !important;
  }

  .ant-select-arrow {
    color: ${colors.white};
  }
`;
