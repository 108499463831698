import AnnotationTagTable from "./AnnotationTagTable";
import { ContentWrapper } from "../../common/styledComponents";
import { useAnnotationTagsQuery } from "../../../api/graphql/queries/annotation/annotationTags.generated";
import { AnnotationTagInput } from "../../../api/graphql/schema.generated";
import { useUpsertAnnotationTagMutation } from "../../../api/graphql/mutations/annotation/upsertAnnotationTag.generated";

const AnnotationTagView = () => {
  const { data, loading: isFetching } = useAnnotationTagsQuery();
  const [upsertAnnotationTag, { loading: isUpserting }] =
    useUpsertAnnotationTagMutation();

  const upsert = (annotationTag: AnnotationTagInput) =>
    upsertAnnotationTag({
      variables: { payload: annotationTag },
    });

  return (
    <ContentWrapper>
      <AnnotationTagTable
        annotationTags={data?.annotationTags}
        upsertAnnotationTag={upsert}
        loading={isFetching || isUpserting}
      />
    </ContentWrapper>
  );
};

export default AnnotationTagView;
