import React, { ChangeEvent, useEffect, useState } from "react";
import styled from "styled-components";
import { Input } from "antd";
import { notification } from "antd";
import { StringParam, useQueryParam } from "use-query-params";

import DateFiltersPicker from "../../common/DateFiltersPicker";
import FilterBoxCard from "../../common/Card/FilterBoxCard";
import { getBerlinMomentTimezone } from "../../common/helpers";
import useEmailParam from "../../hooks/useEmailParam";
import {
  useTransactionsSearchQuery,
  parseSearchQuery,
} from "../../../api/graphql/queries/transactionsSearch";

const { Search } = Input;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const SearchWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
const HeaderText = styled.div`
  font-size: 20px;
`;

const DateFiltersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 200px;
`;

const defaultFilterOptions = {
  dateFrom: undefined,
  dateTo: undefined,
};

const TransactionSearch = ({ onSearch }: { onSearch: Function }) => {
  const [email] = useEmailParam() as [string, Function];
  const [id] = useQueryParam("id", StringParam) as [string, Function];
  const [searchInput, setSearchInput] = useState("");
  const [searchDates, setSearchDates] =
    useState<{ dateFrom: string | undefined; dateTo: string | undefined }>(
      defaultFilterOptions
    );
  const [isLoading, setIsLoading] = useState(false);

  const [getTransactions] = useTransactionsSearchQuery({
    onCompleted: ({ transactions: { edges: response } }) => {
      const transactions = response.map(({ node: transaction }) => transaction);
      onSearch(transactions);
      setIsLoading(false);
    },
    onError: (err: Error) => {
      notification.error({ message: `An error occurred: ${err.message}` });
      setIsLoading(false);
    },
  });

  useEffect(() => {
    setSearchInput("");
    setSearchDates(defaultFilterOptions);
  }, [id]);

  const transactionsSearch = (
    inputValue: string,
    dateOptions = searchDates
  ) => {
    setIsLoading(true);
    const filter = parseSearchQuery(inputValue, {
      valutaDate_gte: dateOptions.dateFrom,
      valutaDate_lte: dateOptions.dateTo,
    });
    getTransactions({
      variables: {
        email,
        filter,
      },
    });
  };
  const onDateChangeHandler = (dateOptions: {
    dateFrom: string;
    dateTo: string;
  }) => {
    setSearchDates(dateOptions);
    transactionsSearch(searchInput, dateOptions);
  };

  return (
    <Wrapper>
      <SearchWrapper>
        <HeaderText>Choose a transaction</HeaderText>
        <Search
          loading={isLoading}
          value={searchInput}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            setSearchInput(event.target.value);
          }}
          onSearch={(value) => transactionsSearch(value)}
          id="transaction-search"
          placeholder="Search amount, name or description"
        />
      </SearchWrapper>
      <FilterBoxCard>
        <DateFiltersWrapper>
          <DateFiltersPicker
            dateFrom={searchDates.dateFrom}
            dateTo={searchDates.dateTo}
            maxDate={getBerlinMomentTimezone().format()}
            onChange={onDateChangeHandler}
          />
        </DateFiltersWrapper>
      </FilterBoxCard>
    </Wrapper>
  );
};

export default TransactionSearch;
