import React, { useCallback, useMemo, useState } from "react";
import {
  Button,
  Form,
  notification,
  Popconfirm,
  Spin,
  Table,
  Space,
} from "antd";
import moment, { Moment } from "moment";

import { SortOrder } from "antd/es/table/interface";

import {
  BusinessAddress,
  BUSINESS_ADDRESS_QUERY,
  useBusinessAddressQuery,
  useCreateBusinessAddressMutation,
  useDeleteBusinessAddressMutation,
  useUpdateBusinessAddressMutation,
} from "../../../../../../api/graphql";
import { InputType } from "./BusinessAddressEditableRow";
import { VerticalAligned } from "../styledComponents";
import EditableRow from "./BusinessAddressEditableRow";
import {
  ActionLink,
  PopconfirmSentence,
  PopconfirmText,
  RetryButton,
} from "./styledComponents";
import { notifySavedChange } from "../../utils";
import { destroyMessage, showLoadingMessage } from "../../../../../../utils";
import ActionLogDrawer from "../../../../../common/ActionLogDrawer";
import { mergeChangelogs } from "../../../../../common/ActionLogDrawer/helpers";
import { TAX_ADVISORY_PERMISSION_SCOPES } from "../../../../../../constants";
import { getPermissionScope } from "../../../../../../gapi";

const BUSINESS_ADDRESS_LOADING_KEY = "business-address-loading";
const ACTION_COLUMN_INDEX = "action";
const NEW_BUSINESS_ADDRESS_ID = "new_business_address";

const businessAddressesColumns: Array<{
  title: string;
  dataIndex: string;
  key: string;
  required?: boolean;
  editable?: boolean;
  render?: any;
  onCell?: any;
  sortDirections?: any;
  defaultSortOrder?: any;
  sorter?: any;
  width: any;
}> = [
  {
    title: "Straße und Hausnummer",
    dataIndex: "street",
    key: "street",
    required: true,
    editable: true,
    sorter: true,
    width: 250,
  },
  {
    title: "PLZ",
    dataIndex: "postCode",
    key: "postCode",
    required: true,
    editable: true,
    sorter: true,
    width: 140,
  },
  {
    title: "Ort",
    dataIndex: "city",
    key: "city",
    required: true,
    editable: true,
    sorter: true,
    width: 200,
  },
  {
    title: "Einzugsdatum",
    dataIndex: "movingDate",
    key: "movingDate",
    required: true,
    editable: true,
    defaultSortOrder: "descend",
    sortDirections: ["ascend", "descend", "ascend"],
    render: (movingDate: Date) => moment(movingDate).format("L"),
    sorter: (a: BusinessAddress, b: BusinessAddress, sortOrder: SortOrder) => {
      if (!a.deletedAt && b.deletedAt) {
        return sortOrder === "ascend" ? -1 : 1;
      } else if (a.deletedAt && !b.deletedAt) {
        return sortOrder === "ascend" ? 1 : -1;
      } else {
        const movingDateA = a.movingDate;
        const movingDateB = b.movingDate;
        return moment(movingDateA).isAfter(moment(movingDateB)) ? -1 : 1;
      }
    },
    width: 200,
  },
  {
    title: "Aktion",
    key: "action",
    dataIndex: ACTION_COLUMN_INDEX,
    width: "auto",
  },
];

const BusinessAddressTable = ({ email }: { email: string }) => {
  const hasTaxAdvisoryPermissionScope = TAX_ADVISORY_PERMISSION_SCOPES.includes(
    getPermissionScope()
  );

  const [form] = Form.useForm<BusinessAddress>();
  const [editingKey, setEditingKey] = useState<string | null>(null);
  const [newBusinessAddress, setNewBusinessAddress] =
    useState<BusinessAddress | null>(null);

  const {
    loading: isLoadingBusinessAddresses,
    error: businessAddressesError,
    data: businessAddressesData,
    refetch: refetchBusinessAddresses,
  } = useBusinessAddressQuery({
    variables: { email },
    // Allow loading status to get updated on refetch
    notifyOnNetworkStatusChange: true,
  });

  const [updateBusinessAddress, { loading: updateLoading }] =
    useUpdateBusinessAddressMutation({
      refetchQueries: [BUSINESS_ADDRESS_QUERY],
    });

  const [createBusinessAddress, { loading: createLoading }] =
    useCreateBusinessAddressMutation({
      refetchQueries: [BUSINESS_ADDRESS_QUERY],
    });

  const [deleteBusinessAddressMutation, { loading: deleteLoading }] =
    useDeleteBusinessAddressMutation({
      refetchQueries: [BUSINESS_ADDRESS_QUERY],
    });

  const onRetryBusinessAddressFetch = () => {
    refetchBusinessAddresses();
  };

  const isLoading = useMemo(
    () => updateLoading || createLoading || deleteLoading,
    [createLoading, deleteLoading, updateLoading]
  );

  const onAdd = useCallback(() => {
    form.resetFields();
    setNewBusinessAddress({
      id: NEW_BUSINESS_ADDRESS_ID,
      street: "",
      city: "",
      postCode: "",
      movingDate: new Date(),
    });
    setEditingKey(NEW_BUSINESS_ADDRESS_ID);
  }, [form]);

  const onEdit = useCallback(
    (row: BusinessAddress) => {
      form.setFieldsValue({
        ...row,
        movingDate: moment(row.movingDate),
      });
      setEditingKey(row.id);
    },
    [form]
  );

  const onSave = useCallback(
    async (id: string) => {
      const row = await form.validateFields();
      const payload = {
        ...row,
        movingDate: (row.movingDate as unknown as Moment).toDate(),
      };
      try {
        showLoadingMessage(BUSINESS_ADDRESS_LOADING_KEY);
        if (id !== NEW_BUSINESS_ADDRESS_ID) {
          await updateBusinessAddress({
            variables: { payload: { ...payload, id } },
          });
        } else {
          await createBusinessAddress({
            variables: { email, payload },
          });
          setNewBusinessAddress(null);
        }

        notifySavedChange();
        setEditingKey(null);
      } catch (error) {
        notification.error({
          // Error saving business address
          message: `Fehler beim Speichern der Geschäftsadresse: ${error}`,
          duration: 0,
        });
      } finally {
        destroyMessage(BUSINESS_ADDRESS_LOADING_KEY);
      }
    },
    [form, email, updateBusinessAddress, createBusinessAddress]
  );

  const onCancel = useCallback(() => {
    setEditingKey(null);
    if (newBusinessAddress) {
      setNewBusinessAddress(null);
    }
  }, [newBusinessAddress]);

  const onDeleteBusinessAddress = useCallback(
    async (id: string) => {
      try {
        showLoadingMessage(BUSINESS_ADDRESS_LOADING_KEY);
        await deleteBusinessAddressMutation({ variables: { id } });

        notifySavedChange();
        setEditingKey(null);
      } catch (error) {
        notification.error({
          // Error while deleting the businessAddress
          message: `Fehler beim Löschen der Geschäftsadresse: ${error}`,
          duration: 0,
        });
      } finally {
        destroyMessage(BUSINESS_ADDRESS_LOADING_KEY);
      }
    },
    [deleteBusinessAddressMutation]
  );

  const isEditing = useCallback(
    (id: string) => editingKey === id,
    [editingKey]
  );

  /**
   * Base columns definition is extended with a render function for the Action's column
   * and the editing features of the rows.
   */
  const columns = useMemo(
    () =>
      businessAddressesColumns.map((column) => {
        let mergedColumn =
          column.dataIndex === ACTION_COLUMN_INDEX
            ? {
                ...column,
                render: (_: any, row: BusinessAddress) => {
                  const { id } = row;
                  return isEditing(id) ? (
                    <span>
                      <ActionLink
                        onClick={() => onSave(id)}
                        disabled={isLoading}
                      >
                        Save
                      </ActionLink>
                      <ActionLink onClick={onCancel} disabled={isLoading}>
                        Cancel
                      </ActionLink>
                      {editingKey !== NEW_BUSINESS_ADDRESS_ID && (
                        <Popconfirm
                          title={
                            <PopconfirmText>
                              <PopconfirmSentence>
                                Bitte nur falsche Eingaben löschen.
                              </PopconfirmSentence>
                              <PopconfirmSentence>
                                Alte Geschäftsadressen müssen im System
                                verbleiben.
                              </PopconfirmSentence>
                              <PopconfirmSentence>
                                Neue Adressen werden über „Hinzufügen“ angelegt.
                              </PopconfirmSentence>
                            </PopconfirmText>
                          }
                          onConfirm={() => onDeleteBusinessAddress(id)}
                          okText="Löschen"
                          cancelText="Abbrechen"
                        >
                          <ActionLink disabled={isLoading}>Delete</ActionLink>
                        </Popconfirm>
                      )}
                    </span>
                  ) : (
                    <span>
                      <ActionLink
                        disabled={!!editingKey || !!row.deletedAt}
                        onClick={() => onEdit(row)}
                      >
                        {row.deletedAt ? "(Deleted)" : "Edit"}
                      </ActionLink>
                    </span>
                  );
                },
              }
            : column;

        return mergedColumn.editable
          ? {
              ...mergedColumn,
              onCell: (row: BusinessAddress) => ({
                record: row,
                inputType:
                  mergedColumn.dataIndex === "movingDate"
                    ? InputType.DATE
                    : InputType.TEXT,
                dataIndex: mergedColumn.dataIndex,
                title: mergedColumn.title,
                editing: isEditing(row.id),
                required: mergedColumn.required,
              }),
            }
          : mergedColumn;
      }),
    [
      editingKey,
      isEditing,
      isLoading,
      onCancel,
      onEdit,
      onSave,
      onDeleteBusinessAddress,
    ]
  );

  /**
   * If user is adding a new address we extend the table rows with the new one.
   */
  const dataSource = useMemo(() => {
    const userBusinessAddresses =
      businessAddressesData?.businessAddresses || [];
    return newBusinessAddress
      ? [newBusinessAddress, ...userBusinessAddresses]
      : userBusinessAddresses;
  }, [businessAddressesData, newBusinessAddress]);

  const changeLogs = useMemo(
    () =>
      mergeChangelogs(
        businessAddressesData?.businessAddresses?.map((businessAddress) => {
          const { street, postCode, city } = businessAddress;

          return {
            ...businessAddress,
            ...(street
              ? { subText: `Address: ${[street, postCode, city].join(", ")}` }
              : {}),
          };
        }) || []
      ),
    [businessAddressesData?.businessAddresses]
  );

  return (
    <VerticalAligned>
      <p style={{ marginBottom: 0 }}>
        <Space>
          Geschäftsadresse
          <ActionLogDrawer title="Action log" changeLogs={changeLogs} />
        </Space>
      </p>
      {isLoadingBusinessAddresses ? (
        <Spin />
      ) : businessAddressesError ? (
        <p>
          Fehler beim Laden der Geschäftsadressen.
          <RetryButton onClick={onRetryBusinessAddressFetch}>
            Versuch es nochmal
          </RetryButton>
        </p>
      ) : (
        <Form form={form} component={false}>
          <Table
            columns={columns}
            dataSource={dataSource}
            rowClassName={(record) =>
              !!record.deletedAt ? "ant-typography ant-typography-disabled" : ""
            }
            pagination={false}
            components={{
              body: {
                cell: EditableRow,
              },
            }}
          />
          {hasTaxAdvisoryPermissionScope && (
            <div style={{ display: "flex", justifyContent: "end" }}>
              <Button disabled={editingKey !== null} onClick={onAdd}>
                + Hinzufügen
              </Button>
            </div>
          )}
        </Form>
      )}
    </VerticalAligned>
  );
};

export default BusinessAddressTable;
