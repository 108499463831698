import React, { useState } from "react";
import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Row, Table } from "antd";

import moment from "moment";

import { useHomeOfficeDocumentsQuery } from "../../../../../../../../api/graphql/queries/homeOfficeDocument/homeOfficeDocument.generated";
import {
  EuerDeclarationSubformType,
  HomeOfficeDocumentType,
} from "../../../../../../../../api/graphql/schema.generated";
import { HomeOfficeDocumentFragment } from "../../../../../../../../api/graphql/fragments/homeOfficeDocument.generated";
import { AssetViewerContainer } from "./styledComponents";
import LocalAssetsViewer from "../../../../../../../common/LocalAssetsViewer";
import { HOME_OFFICE_DOCUMENT_TYPE_LABELS } from "./constants";
import {
  HomeOfficeDocumentFormInputs,
  UpsertHomeOfficeDocumentModal,
} from "./UpsertHomeOfficeDocumentModal";

interface Props {
  taxYear: number;
  email?: string | null;
}

const columns = [
  {
    title: "Art",
    key: "type",
    dataIndex: "type",
    width: "15%",
    sorter: (a: HomeOfficeDocumentFragment, b: HomeOfficeDocumentFragment) =>
      a.type.localeCompare(b.type),
    render: (type: HomeOfficeDocumentType) =>
      HOME_OFFICE_DOCUMENT_TYPE_LABELS[type],
  },
  {
    title: "Beschreibung",
    key: "note",
    dataIndex: "note",
    width: "60%",
    sorter: (a: HomeOfficeDocumentFragment, b: HomeOfficeDocumentFragment) =>
      a.note.localeCompare(b.note),
  },
  {
    title: "Daten übernommen",
    key: "syncedFromQuestionnaireAt",
    dataIndex: "syncedFromQuestionnaireAt",
    width: "25%",
    render: (syncedFromQuestionnaireAt: Date) => {
      return syncedFromQuestionnaireAt
        ? moment(syncedFromQuestionnaireAt).format("DD.MM.YYYY")
        : "-";
    },
  },
];

const DocumentsTable = ({ taxYear, email }: Props) => {
  const [
    showUpsertHomeOfficeDocumentModal,
    setShowUpsertHomeOfficeDocumentModal,
  ] = useState<boolean>(false);
  const [
    homeOfficeDocumentFormInitialValues,
    setHomeOfficeDocumentFormInitialValues,
  ] = useState<HomeOfficeDocumentFormInputs | undefined>(undefined);

  const { data: queryResult, refetch: refetchHomeOfficeDocument } =
    useHomeOfficeDocumentsQuery({
      skip: !email,
      variables: {
        email: email!,
        year: taxYear,
        type: EuerDeclarationSubformType.OFFICE_USAGE,
      },
    });
  const homeOfficeDocuments =
    queryResult?.euerDeclarationSubform?.homeOfficeDocuments;

  const handleCreate = () => {
    setHomeOfficeDocumentFormInitialValues(undefined);
    setShowUpsertHomeOfficeDocumentModal(true);
  };

  const handleEdit = (homeOfficeDocument: HomeOfficeDocumentFragment) => {
    setHomeOfficeDocumentFormInitialValues({ ...homeOfficeDocument });
    setShowUpsertHomeOfficeDocumentModal(true);
  };

  return (
    <>
      <Row
        align="middle"
        justify="space-between"
        className="mb-3"
        style={{ flexDirection: "row-reverse" }}
      >
        <Col>
          <Button
            icon={<PlusOutlined />}
            size="large"
            style={{ display: "flex", alignItems: "center" }}
            onClick={handleCreate}
          >
            Beleg hinzufügen
          </Button>
        </Col>
      </Row>
      <Row>
        <Table
          size="large"
          columns={columns}
          dataSource={homeOfficeDocuments}
          pagination={false}
          rowKey={(row: HomeOfficeDocumentFragment) => row.id}
          style={{ width: "100%" }}
          scroll={{ x: "100%" }}
          expandable={{
            expandedRowRender: (record: HomeOfficeDocumentFragment) => {
              return (
                <Col>
                  <Row style={{ paddingBottom: "16px" }}>
                    <Button
                      icon={<EditOutlined />}
                      size="large"
                      style={{ display: "flex", alignItems: "center" }}
                      onClick={() => handleEdit(record)}
                    >
                      Beleg ändern
                    </Button>
                  </Row>
                  <Row>
                    <AssetViewerContainer>
                      <LocalAssetsViewer
                        assets={record.assets}
                        minHeight={600}
                      />
                    </AssetViewerContainer>
                  </Row>
                </Col>
              );
            },
          }}
        />
      </Row>
      <UpsertHomeOfficeDocumentModal
        email={email!}
        taxYear={taxYear}
        visible={showUpsertHomeOfficeDocumentModal}
        onSuccess={refetchHomeOfficeDocument}
        onClose={() => {
          setShowUpsertHomeOfficeDocumentModal(false);
        }}
        initialValues={homeOfficeDocumentFormInitialValues}
      />
    </>
  );
};

export default DocumentsTable;
