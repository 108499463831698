import React, { useState, useEffect, useRef, useCallback } from "react";
import sortBy from "lodash/sortBy";
import isEqual from "lodash/isEqual";
import { Button, Dropdown, Checkbox, Divider } from "antd";

import { DownOutlined } from "@ant-design/icons";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { CheckboxChangeEvent } from "antd/lib/checkbox";

import CustomMenu from "./CustomMenu";

type DropdownOption = {
  label: string;
  value: CheckboxValueType;
};

type Props = {
  values: CheckboxValueType[];
  options: DropdownOption[];
  disabled?: boolean;
  withFilter?: boolean;
  moveSelectedToTheTop?: boolean;
  name: string;
  placeholder?: string;
  onChange: (newValues: CheckboxValueType[]) => void;
  withCheckAll?: boolean;
};

const dropdownWidth = 160;

const buttonStyle = {
  width: `${dropdownWidth + 10}px`,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};

export default function DropdownMultiSelect({
  name,
  placeholder,
  values,
  options,
  disabled = false,
  withFilter = true,
  moveSelectedToTheTop = false,
  onChange,
  withCheckAll = false,
}: Props) {
  const [selectedValues, setSelectedValues] =
    useState<CheckboxValueType[]>(values);
  const [checkAll, setCheckAll] = useState(false);
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [haveChangedValues, setHaveChangedValues] = useState<boolean>(false);
  const [filter, setFilter] = useState<string>("");

  const menuRef = useRef<HTMLDivElement>(null);
  const toggleRef = useRef<HTMLDivElement>(null);

  const selectedCount = values.length;

  const getOptions = useCallback(
    () =>
      moveSelectedToTheTop
        ? sortBy(options, (opt) => !values.includes(opt.value))
        : options,
    [options, values, moveSelectedToTheTop]
  );

  const valuesForCheckAll = getOptions().map((option) => option.value);

  const onValueChange = useCallback(
    (checkedValue: CheckboxValueType[]) => {
      setSelectedValues(checkedValue);
      setHaveChangedValues(true);
      if (withCheckAll) {
        setCheckAll(checkedValue.length === valuesForCheckAll.length);
      }
    },
    [setSelectedValues, withCheckAll, valuesForCheckAll]
  );

  const applyChanges = useCallback(
    () => onChange(selectedValues),
    [selectedValues, onChange]
  );

  const onCheckAllChange = (event: CheckboxChangeEvent) => {
    setSelectedValues(event.target.checked ? valuesForCheckAll : []);
    setCheckAll(event.target.checked);
    setHaveChangedValues(true);
  };

  const onDropdownToggle = useCallback(
    (show) => {
      if (!show && haveChangedValues) {
        applyChanges();
      }
      setShowDropdown(show);
      withFilter && setFilter("");
      setHaveChangedValues(false);
    },
    [applyChanges, haveChangedValues, withFilter]
  );

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        toggleRef.current &&
        !toggleRef.current.contains(event.target)
      ) {
        onDropdownToggle(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef, toggleRef, onDropdownToggle]);

  const dropdownText = selectedCount
    ? `${selectedCount} selected`
    : placeholder || `Select a ${name}`;

  const menu = (
    <div ref={menuRef}>
      <CustomMenu
        filter={filter}
        setFilter={withFilter ? setFilter : undefined}
        dropdownDisabled={disabled}
        disableApply={isEqual(values, selectedValues)}
        onReset={() => {
          setSelectedValues([]);
          setHaveChangedValues(true);
          withFilter && setFilter("");
          setCheckAll(false);
        }}
        onApply={() => onDropdownToggle(false)}
        id={`${name}-dropdown-custom-menu`}
      >
        {withCheckAll && (
          <>
            <Checkbox onChange={onCheckAllChange} checked={checkAll}>
              Select all
            </Checkbox>
            <Divider style={{ margin: "12px 0" }} />
          </>
        )}
        <Checkbox.Group
          options={getOptions()}
          onChange={(checkedValue: CheckboxValueType[]) => {
            onValueChange(checkedValue);
          }}
          disabled={disabled}
          value={selectedValues}
          style={{ display: "flex", flexDirection: "column" }}
        />
      </CustomMenu>
    </div>
  );

  return (
    <Dropdown overlay={menu} trigger={["click"]} visible={showDropdown}>
      <Button
        ref={toggleRef}
        onClick={() => setShowDropdown(!showDropdown)}
        style={buttonStyle}
      >
        {dropdownText} <DownOutlined />
      </Button>
    </Dropdown>
  );
}
