export enum RiskClassificationStatus {
  NOT_SCORED = "NOT_SCORED",
  POTENTIAL_RISK = "POTENTIAL_RISK",
  NORMAL_RISK = "NORMAL_RISK",
  INFORMATION_REQUESTED = "INFORMATION_REQUESTED",
  INFORMATION_RECEIVED = "INFORMATION_RECEIVED",
  RISK_ACCEPTED = "RISK_ACCEPTED",
  RISK_REJECTED = "RISK_REJECTED",
  CUSTOMER_UNRESPONSIVE = "CUSTOMER_UNRESPONSIVE",
  SCORING_NOT_REQUIRED = "SCORING_NOT_REQUIRED",
}
