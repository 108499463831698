import React from "react";

import { ReactComponent as WarningIcon } from "../../../svgs/warning.svg";
import Popup from "../Popup";
import { Button } from "../styledComponents";

type WarningPopupProps = {
  text: string;
  onClose: () => void;
};

const WarningPopup = ({ text, onClose }: WarningPopupProps) => (
  <Popup onClosePopup={onClose}>
    <WarningIcon className="mb-3" />
    <p className="mb-2">{text}</p>
    <Button className="primary mb-2" onClick={onClose}>
      OK
    </Button>
  </Popup>
);

export default WarningPopup;
