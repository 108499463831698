import { toPairs } from "lodash";

import {
  DeclarationDocumentType,
  TaxDeclarationType,
  FibuFinalCheckStatus,
  TaxCaseStatus,
} from "../../../../../api/graphql/schema.generated";

export const TAX_CASE_STATUS_MAPPINGS = {
  [TaxCaseStatus.NOT_STARTED]: "Nicht angefangen",
  [TaxCaseStatus.IN_PROGRESS]: "In Bearbeitung",
  [TaxCaseStatus.DONE]: "Abgeschlossen",
};

export const FIBU_FINAL_CHECK_STATUS_MAPPINGS = {
  [FibuFinalCheckStatus.QUALIFIED_FOR_OPS]: "Qualifiziert für Ops",
  [FibuFinalCheckStatus.IN_PROGRESS_OPS]: "In Bearbeitung Ops",
  [FibuFinalCheckStatus.IN_PROGRESS_USER]: "In Bearbeitung Kunde",
  [FibuFinalCheckStatus.NOT_NEEDED]: "Nicht notwendig",
  [FibuFinalCheckStatus.COMPLETED_BY_USER]: "Abgeschlossen Kunde",
  [FibuFinalCheckStatus.WAITING_FOR_PRECONSULTANT]: "Warten auf SB",
  [FibuFinalCheckStatus.WAITING_FOR_USER]: "Warten auf Kunde",
  [FibuFinalCheckStatus.DONE]: "Abgeschlossen",
};

export const DECLARATION_TYPE_LABELS: Record<TaxDeclarationType, string> = {
  [TaxDeclarationType.EUER]: "Anlage EÜR inkl. AV",
  [TaxDeclarationType.VAT_ANNUAL]: "Umsatzsteuererkl.",
  [TaxDeclarationType.TRADE_TAX]: "Gewerbesteuererkl.",
  [TaxDeclarationType.INCOME_TAX]: "Einkommensteuererkl.",
};

export const DECLARATION_TYPE_OPTIONS = toPairs(DECLARATION_TYPE_LABELS).map(
  ([declarationType, label]) => ({
    value: declarationType as TaxDeclarationType,
    label,
  })
);

export const DECLARATION_DOCUMENT_TYPE_LABELS: Record<
  DeclarationDocumentType,
  string
> = {
  [DeclarationDocumentType.PREVIEW_FORM]: "Freigabe Formular",
  [DeclarationDocumentType.SUBMITTED_FORM]: "Eingereichtes Formular",
  [DeclarationDocumentType.TAX_BILL]: "Bescheide",
};

export const DECLARATION_DOCUMENT_TYPE_OPTIONS = toPairs(
  DECLARATION_DOCUMENT_TYPE_LABELS
).map(([declarationDocumentType, label]) => ({
  value: declarationDocumentType as DeclarationDocumentType,
  label,
}));
