import styled from "styled-components";
import { Collapse, Typography, Button } from "antd";
import { FileOutlined } from "@ant-design/icons";

import colors from "../../../../../../themes/colors";

export const StyledCollapse = styled(Collapse)`
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-width: 1px 1px 0 1px;

  .ant-collapse-item > .ant-collapse-header {
    padding: 0px;
    background-color: ${colors.white};
  }
  .ant-collapse-item > .ant-collapse-content {
    background-color: ${colors.lightestGray};
  }
  .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
    padding: 16px;
  }
  .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header {
    padding: 0px;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 8px 8px 4px;
  width: 876px;
`;

export const TitleIcon = styled(FileOutlined)`
  margin: 0px 9px;
  color: #bfbfbf;
  font-size: 20px;
`;

export const TitleContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Title = styled(Typography.Text)`
  line-height: 22px;
  overflow-wrap: anywhere;
`;

export const Description = styled(Typography.Text)`
  line-height: 20px;
  font-size: 12px;
`;

export const DownloadButton = styled(Button)`
  margin: 0 9px;
`;
