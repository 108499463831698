import type * as Types from "../../schema.generated";

import { gql } from "@apollo/client";
import { DatevImportFragmentDoc } from "./DatevImport.generated";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type UpdateMultipleDatevDataMutationVariables = Types.Exact<{
  payload: Array<Types.UpdateDatevDataInput> | Types.UpdateDatevDataInput;
}>;

export type UpdateMultipleDatevDataMutation = {
  __typename?: "Mutation";
  updateMultipleDatevData: Array<{
    __typename?: "DatevImport";
    id: string;
    skrMode: Types.SkrMode;
    bookingMode: Types.BookingMode;
    bookkeepingTool: Types.BookkeepingTool;
    amount: number;
    direction: Types.Direction;
    account: string;
    offsetAccount: string;
    paymentDate: string;
    buKey: string;
    meta1: string;
    description: string;
    kkr?: string | null;
    vatCategoryCode?: string | null;
    verifiedAt?: string | null;
    assets?: Array<{
      __typename?: "Asset";
      filetype: string;
      fullsize: string;
    }> | null;
  }>;
};

export const UpdateMultipleDatevDataDocument = gql`
  mutation updateMultipleDatevData($payload: [UpdateDatevDataInput!]!) {
    updateMultipleDatevData(payload: $payload) {
      ...DatevImport
    }
  }
  ${DatevImportFragmentDoc}
`;
export type UpdateMultipleDatevDataMutationFn = Apollo.MutationFunction<
  UpdateMultipleDatevDataMutation,
  UpdateMultipleDatevDataMutationVariables
>;

/**
 * __useUpdateMultipleDatevDataMutation__
 *
 * To run a mutation, you first call `useUpdateMultipleDatevDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMultipleDatevDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMultipleDatevDataMutation, { data, loading, error }] = useUpdateMultipleDatevDataMutation({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useUpdateMultipleDatevDataMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMultipleDatevDataMutation,
    UpdateMultipleDatevDataMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateMultipleDatevDataMutation,
    UpdateMultipleDatevDataMutationVariables
  >(UpdateMultipleDatevDataDocument, options);
}
export type UpdateMultipleDatevDataMutationHookResult = ReturnType<
  typeof useUpdateMultipleDatevDataMutation
>;
export type UpdateMultipleDatevDataMutationResult =
  Apollo.MutationResult<UpdateMultipleDatevDataMutation>;
export type UpdateMultipleDatevDataMutationOptions = Apollo.BaseMutationOptions<
  UpdateMultipleDatevDataMutation,
  UpdateMultipleDatevDataMutationVariables
>;
