import styled from "styled-components";
import { Typography } from "antd";

const { Title } = Typography;

export const ActionBlock = styled.div`
  margin-bottom: 24px;
`;

export const DateBlock = styled.div`
  font-weight: 500;
  margin-bottom: 4px;
`;

export const TitleWithLogs = styled(Title)`
  display: flex;
`;

export const OneLine = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: list-item;
  list-style-type: disc;
  list-style-position: inside;
  cursor: default;
`;
