import React, { useState, useEffect } from "react";
import { Modal, Form, Button } from "antd";
import moment from "moment";

import ExternalTransactionForm from "./ExternalTransactionForm";
import {
  Asset,
  ILocalAsset,
  ITransaction,
  Direction,
  BusinessAssetForm,
} from "../../../../types";
import { createTransactionAsset } from "../../../common/DragAndDrop/actionHandler";
import { useUpsertExternalTransactionMutation } from "../../../../api/graphql";
import {
  showErrorNotification,
  showGraphQlErrorNotification,
} from "../../../../utils/notifications";
import { isDepreciableCategoryCode } from "../../../BusinessAsset/utils";
import { getBusinessAssetFormForUpdate, getBusinessAssetForm } from "./utils";
import { BusinessAsset } from "../../../../api/graphql/schema.generated";

type Values = {
  amount: number;
  paymentDate: string;
  categoryCode: string;
  vatCategoryCode: string;
  name?: string;
  iban?: string;
  description?: string;
  assets?: Array<Asset>;
  direction?: Direction;
  businessAssetForm?: BusinessAssetForm;
};

export interface ExternalTransactionInitialValues {
  id?: string;
  amount?: number;
  assets?: Array<Asset>;
  valutaDate?: Date | string;
  categoryCode?: string | null;
  name?: string | null;
  iban?: string | null;
  description?: string | null;
  vatCategoryCode?: string | null;
  businessAssets?: BusinessAsset[];
}

async function createFile(asset: Asset) {
  const response = await fetch(asset.fullsize);
  const data = await response.blob();
  const metadata = {
    type: asset.filetype,
  };
  return new File(
    [data],
    asset.id || new Date().getTime().toString(),
    metadata
  );
}

export const ExternalTransactionPopup = ({
  onSuccess,
  isPopupShown,
  email,
  closePopup,
  initialValues,
  emailDocumentId,
  hideAssetActions,
}: {
  isPopupShown: boolean;
  onSuccess: Function;
  email: string;
  closePopup: Function;
  initialValues?: ExternalTransactionInitialValues;
  emailDocumentId?: string;
  hideAssetActions?: boolean;
}) => {
  const [form] = Form.useForm();
  const [assets, setAssets] = useState<Asset[]>(initialValues?.assets || []);
  const [files, setFiles] = useState<File[]>([]);
  const [isReplacingAsset, setIsReplacingAsset] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDuplicatedTransaction, setIsDuplicatedTransaction] = useState(false);

  const [upsertExternalTransaction] = useUpsertExternalTransactionMutation();

  // if modal was opened on Belege View, where assets are AWS S3 paths,
  // we want to map these urls to files
  useEffect(() => {
    if (isModalVisible && !files.length && assets.length) {
      (async () => {
        setFiles(await Promise.all(assets.map(createFile)));
      })();
    }
  }, [isModalVisible, assets, files]);

  const upsertTransaction = async (values: Values, transactionId?: string) => {
    const isDocumentMatch = !!emailDocumentId;

    const amountDirection = parseInt(
      `${values.direction || Direction.OUTGOING}`,
      10
    );

    const transactionPayload = {
      id: transactionId,
      ...values,
      amount: amountDirection * Math.round(values.amount * 100),
      paymentDate: new Date(values.paymentDate),
      businessAssetForm: getBusinessAssetFormForUpdate(
        values.businessAssetForm
      ),
    };
    delete transactionPayload.assets;
    delete transactionPayload.direction;
    const result = await upsertExternalTransaction({
      variables: {
        email,
        transaction: transactionPayload,
        options:
          // if in BelegeView a virtual transaction got duplicated, it should use previous
          // asset and should not trigger any document match logic
          !isDuplicatedTransaction && isDocumentMatch
            ? { emailDocumentId }
            : {},
      },
    });

    // in case of email document matching, we link asset on backend side so no extra upload is required
    if (!isDocumentMatch || isDuplicatedTransaction) {
      await Promise.all(
        files.map((file) =>
          createTransactionAsset(
            result?.data?.upsertExternalTransaction.id as string,
            file
          )
        )
      );
    }

    return result.data?.upsertExternalTransaction as ITransaction;
  };

  useEffect(() => {
    setAssets(initialValues?.assets || []);
  }, [initialValues?.assets]);

  useEffect(() => {
    setIsModalVisible(isPopupShown);
  }, [isPopupShown]);

  useEffect(() => {
    if (!initialValues) {
      form.resetFields();
      form.setFields([
        {
          name: "direction",
          value: Direction.OUTGOING,
        },
      ]);
    } else {
      form.setFields([
        {
          name: "amount",
          value: initialValues.amount
            ? Math.abs(initialValues.amount / 100)
            : "",
        },
        {
          name: "direction",
          value: getInitialDirection(initialValues),
        },
        {
          name: "paymentDate",
          value: moment(initialValues.valutaDate, "YYYY-MM-DD"),
        },
        { name: "assets", value: initialValues.assets },
        { name: "categoryCode", value: initialValues.categoryCode },
        { name: "name", value: initialValues.name },
        { name: "iban", value: initialValues.iban },
        { name: "description", value: initialValues.description },
        { name: "vatCategoryCode", value: initialValues.vatCategoryCode },
        {
          name: "businessAssetForm",
          value: getBusinessAssetForm(initialValues.businessAssets),
        },
      ]);
    }
  }, [initialValues, form]);
  const handleCancel = () => {
    closePopup();
    handleReset();
  };

  const handleSubmit = async (values: Values, isDuplicate = false) => {
    setIsButtonLoading(true);
    try {
      const transaction = await upsertTransaction(values, initialValues?.id);
      setIsButtonLoading(false);
      if (!isDuplicate) {
        closePopup();
        handleReset();
      } else {
        setIsDuplicatedTransaction(true);
      }

      const assets =
        initialValues?.id && isReplacingAsset
          ? initialValues?.assets
          : values.assets;

      onSuccess(
        {
          ...transaction,
          assets: isReplacingAsset ? values.assets : assets,
        },
        isDuplicate
      );
    } catch (error) {
      setIsButtonLoading(false);
      showGraphQlErrorNotification(
        `${initialValues?.id ? "Update" : "Create"} external transaction:`,
        error
      );
    }
  };

  const onDropFiles = (files: File[]) => {
    const newAssets = files.map((file) => ({
      fullsize: URL.createObjectURL(file),
      filetype: file.type.split("/").pop()!,
    }));
    const updatedAssets = assets ? assets.concat(newAssets) : newAssets;

    form.setFieldsValue({
      assets: updatedAssets,
    });
    // Needed to re-render the AssetsViewer when one or multiple assets are added
    setAssets(updatedAssets);
    setFiles(files);
  };

  const onChangeCategory = (categoryCode: string | null) => {
    form.setFieldsValue({ categoryCode });
    form.setFieldsValue({
      businessAssetForm: isDepreciableCategoryCode(categoryCode)
        ? {}
        : undefined,
    }); // set businessAssetForm to {} to reveal the form
    if (form.getFieldValue("vatCategoryCode")) {
      form.resetFields(["vatCategoryCode"]);
    }
  };

  const onChangeBusinessAsset = (businessAssetForm: BusinessAssetForm) => {
    form.setFieldsValue({ businessAssetForm });
  };

  const onVatCategorySelected = (category: string | null) => {
    form.setFieldsValue({
      vatCategoryCode: category,
    });
  };

  const handleReset = () => {
    setAssets([]);
    setIsReplacingAsset(false);
    setIsDuplicatedTransaction(false);
    form.resetFields();
    form.setFields([
      {
        name: "direction",
        value: Direction.OUTGOING,
      },
    ]);
  };

  const onAssetDelete = (deletedAsset: Asset | ILocalAsset) => {
    const newAssets = assets.filter(
      (asset) =>
        asset !== deletedAsset || asset?.fullsize !== deletedAsset?.fullsize
    );
    setAssets(newAssets);
    form.setFieldsValue({ assets: newAssets });
    setIsReplacingAsset(true);
  };

  const isEditMode = !!initialValues && !emailDocumentId;

  const handleDuplicate = async () => {
    try {
      await form.validateFields();
      const formValues = form.getFieldsValue();
      await handleSubmit(formValues, true);
      form.setFieldsValue({
        amount: null,
      });
    } catch (err: any) {
      if (!err.errorFields) {
        showErrorNotification({ message: err.message || "An error occurred" });
      }
    }
  };

  const footer = [
    <Button
      form="externalTransactionFormId"
      type="default"
      loading={isButtonLoading}
      onClick={handleDuplicate}
      data-test="duplicateButton"
    >
      {isEditMode ? "Duplicate" : "Add & Duplicate"}
    </Button>,
    <Button
      form="externalTransactionFormId"
      key="submit"
      htmlType="submit"
      type="primary"
      loading={isButtonLoading}
    >
      {isEditMode ? "Update" : "Create"}
    </Button>,
  ];

  return (
    <Modal
      data-test="externalTransactionPopup"
      title={`${initialValues ? "Update" : "Add"} an external transaction`}
      visible={isModalVisible}
      onCancel={handleCancel}
      footer={footer}
      centered
      width={1058}
      bodyStyle={{
        paddingBottom: (form.getFieldValue("assets") || []).length > 1 ? 40 : 0,
      }}
    >
      <Form
        form={form}
        name="externalTransaction"
        id="externalTransactionFormId"
        onFinish={handleSubmit}
        autoComplete="off"
        layout="vertical"
        requiredMark="optional"
      >
        <ExternalTransactionForm
          onDropFiles={hideAssetActions ? undefined : onDropFiles}
          assets={assets}
          onChangeCategory={onChangeCategory}
          onVatCategorySelected={onVatCategorySelected}
          onAssetDelete={hideAssetActions ? undefined : onAssetDelete}
          onChangeBusinessAsset={onChangeBusinessAsset}
        />
      </Form>
    </Modal>
  );
};

export function getInitialDirection(
  initialValues: ExternalTransactionInitialValues
) {
  if (initialValues?.id && initialValues.amount) {
    return initialValues.amount > 0 ? Direction.INCOMING : Direction.OUTGOING;
  }
  return Direction.OUTGOING;
}
