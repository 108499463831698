import styled from "styled-components";
import { Tag } from "antd";

import colors from "../../themes/colors";

const { CheckableTag } = Tag;

interface StyledCheckableTagProps {
  width?: number;
  padding?: string | number;
  disabled?: boolean;
}

const StyledCheckableTag = styled(CheckableTag)<StyledCheckableTagProps>`
  font-size: 14px;
  line-height: 30px;
  margin-right: 0;
  padding: ${(props) => props.padding ?? "0 15px"};
  text-align: center;
  border: 1px solid #d9d9d9;
  user-select: none;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  width: ${(props) => `${props.width}px`};
  cursor: ${(props) => props.disabled && "not-allowed"};
  pointer-events: ${(props) => props.disabled && "none"};
  color: ${(props) => props.disabled && "rgba(0, 0, 0, 0.25)"};
  background: ${(props) => props.disabled && "rgba(0, 0, 0, 0.05)"};

  &.ant-tag-checkable-checked {
    border-color: ${colors.purple};
  }

  &:nth-child(n + 2) {
    margin-left: -1px;
  }

  &:first-of-type {
    border-bottom-left-radius: 2px;
    border-top-left-radius: 2px;
  }

  &:last-of-type {
    border-left: none;
    border-bottom-right-radius: 2px;
    border-top-right-radius: 2px;
  }
`;

interface Option<T> {
  label: string;
  value: T;
  disabled?: boolean;
}

export interface ResettableOptionButtonProps<T> {
  value?: T;
  onChange?: (value?: T) => void;
  width?: number;
  padding?: string | number;
  options: Option<T>[];
}

export function ResettableOptionButton<T>(
  props: ResettableOptionButtonProps<T>
) {
  const handleChange = (option: Option<T>, checked: boolean) => {
    const nextSelectedTag = checked ? option : undefined;
    props.onChange?.(
      nextSelectedTag === undefined ? undefined : nextSelectedTag.value
    );
  };

  return (
    <>
      {props.options.map((option) => (
        <StyledCheckableTag
          key={option.label}
          checked={props?.value === option.value}
          onChange={(checked) => handleChange(option, checked)}
          disabled={option.disabled}
          width={props.width}
          padding={props.padding}
        >
          {option.label}
        </StyledCheckableTag>
      ))}
    </>
  );
}
