import { notification } from "antd";
import axios, { AxiosInstance, AxiosResponse, AxiosRequestConfig } from "axios";

import config from "../../config";
import { getToken } from "../../gapi";
import { toError } from "../../utils/error";

declare module "axios" {
  interface AxiosRequestConfig {
    hideErrorCode?: boolean;
    skipNotification?: boolean;
  }
}

const DEFAULT_HEADERS = {
  "Content-Type": "application/json",
  "Accept-Language": "en-GB",
};

const http: AxiosInstance = axios.create({
  baseURL: `${config.REACT_APP_API_URL}/api`,
  headers: { ...DEFAULT_HEADERS },
});

http.interceptors.request.use((config) => {
  if (config.headers) {
    config.headers["X-Auth-Token"] = getToken();
  }

  return config;
});

http.interceptors.response.use(
  (response) => response,
  async (error) => {
    const notify = error.config?.skipNotification
      ? () => null
      : notification.error;

    if (axios.isAxiosError(error) && error.config?.hideErrorCode) {
      notify({ message: error.response?.data?.message });
      throw error;
    }

    if (error.response?.status === 401) {
      notify({ message: "Unauthorized. Please log in again." });
      throw error;
    }

    notify({
      message: `An error occurred: "${error.message} (${error.response?.data?.message})"`,
    });
    throw error;
  }
);

const convertToApiError = (error: unknown): Error => {
  if (axios.isAxiosError(error)) {
    return new Error(error.response?.data?.message || error.message);
  }
  return toError(error);
};

export default abstract class BaseApi {
  protected abstract get moduleUrl(): string;

  protected async get<T = any>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    try {
      return await http.get(`${this.moduleUrl}${url}`, config);
    } catch (error) {
      throw convertToApiError(error);
    }
  }

  protected async download(
    url: string,
    fileName: string,
    config?: AxiosRequestConfig
  ): Promise<void> {
    try {
      const response = await http.get(`${this.moduleUrl}${url}`, config);

      const href = URL.createObjectURL(response.data);

      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(href);
      return;
    } catch (error) {
      throw convertToApiError(error);
    }
  }

  protected async post<T = any>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    try {
      return await http.post(`${this.moduleUrl}${url}`, data, config);
    } catch (error) {
      throw convertToApiError(error);
    }
  }

  protected async put<T = any>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    try {
      return await http.put(`${this.moduleUrl}${url}`, data, config);
    } catch (error) {
      throw convertToApiError(error);
    }
  }

  protected async patch<T = any>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    try {
      return await http.patch(`${this.moduleUrl}${url}`, data, config);
    } catch (error) {
      throw convertToApiError(error);
    }
  }

  protected async delete<T = any>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    try {
      return await http.delete(`${this.moduleUrl}${url}`, config);
    } catch (error) {
      throw convertToApiError(error);
    }
  }
}
