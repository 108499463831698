import styled from "styled-components";
import { Form } from "antd";

export const StyledForm = styled(Form)`
  .ant-form-item {
    margin-bottom: 20px;
  }

  .ant-input-number-handler-wrap {
    display: none;
  }

  .ant-input-number,
  .ant-picker,
  .ant-input-number-group-wrapper {
    width: 100%;
  }

  .ant-input-number-input,
  .ant-picker-input input {
    text-align: right;
  }

  .ant-form-item-label > label {
    white-space: normal;
    min-height: 32px;
    height: auto;
  }
`;

export const StyledFieldset = styled.fieldset`
  margin-bottom: 10px;
`;
