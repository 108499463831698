import type * as Types from "../../schema.generated";

import { gql } from "@apollo/client";
import { DatevImportFragmentDoc } from "./DatevImport.generated";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type ToggleMultipleDatevDataVerifiedFlagMutationVariables = Types.Exact<{
  payload: Array<Types.Scalars["ID"]> | Types.Scalars["ID"];
}>;

export type ToggleMultipleDatevDataVerifiedFlagMutation = {
  __typename?: "Mutation";
  toggleMultipleDatevDataVerifiedFlag: Array<{
    __typename?: "DatevImport";
    id: string;
    skrMode: Types.SkrMode;
    bookingMode: Types.BookingMode;
    bookkeepingTool: Types.BookkeepingTool;
    amount: number;
    direction: Types.Direction;
    account: string;
    offsetAccount: string;
    paymentDate: string;
    buKey: string;
    meta1: string;
    description: string;
    kkr?: string | null;
    vatCategoryCode?: string | null;
    verifiedAt?: string | null;
    assets?: Array<{
      __typename?: "Asset";
      filetype: string;
      fullsize: string;
    }> | null;
  }>;
};

export const ToggleMultipleDatevDataVerifiedFlagDocument = gql`
  mutation toggleMultipleDatevDataVerifiedFlag($payload: [ID!]!) {
    toggleMultipleDatevDataVerifiedFlag(payload: $payload) {
      ...DatevImport
    }
  }
  ${DatevImportFragmentDoc}
`;
export type ToggleMultipleDatevDataVerifiedFlagMutationFn =
  Apollo.MutationFunction<
    ToggleMultipleDatevDataVerifiedFlagMutation,
    ToggleMultipleDatevDataVerifiedFlagMutationVariables
  >;

/**
 * __useToggleMultipleDatevDataVerifiedFlagMutation__
 *
 * To run a mutation, you first call `useToggleMultipleDatevDataVerifiedFlagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleMultipleDatevDataVerifiedFlagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleMultipleDatevDataVerifiedFlagMutation, { data, loading, error }] = useToggleMultipleDatevDataVerifiedFlagMutation({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useToggleMultipleDatevDataVerifiedFlagMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ToggleMultipleDatevDataVerifiedFlagMutation,
    ToggleMultipleDatevDataVerifiedFlagMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ToggleMultipleDatevDataVerifiedFlagMutation,
    ToggleMultipleDatevDataVerifiedFlagMutationVariables
  >(ToggleMultipleDatevDataVerifiedFlagDocument, options);
}
export type ToggleMultipleDatevDataVerifiedFlagMutationHookResult = ReturnType<
  typeof useToggleMultipleDatevDataVerifiedFlagMutation
>;
export type ToggleMultipleDatevDataVerifiedFlagMutationResult =
  Apollo.MutationResult<ToggleMultipleDatevDataVerifiedFlagMutation>;
export type ToggleMultipleDatevDataVerifiedFlagMutationOptions =
  Apollo.BaseMutationOptions<
    ToggleMultipleDatevDataVerifiedFlagMutation,
    ToggleMultipleDatevDataVerifiedFlagMutationVariables
  >;
