import React from "react";

import { Button, Empty } from "antd";

import { ContentWrapper } from "./styledComponents";
import colors from "../../../themes/colors";

interface EmptyWrapperProps {
  description: string;
  buttonProperties?: {
    text: string;
    onClick: () => void;
  };
  redText?: boolean;
}

const EmptyWrapper = ({
  description,
  buttonProperties,
  redText = false,
}: EmptyWrapperProps) => {
  return (
    <ContentWrapper>
      <Empty
        style={redText ? { color: colors.danger } : undefined}
        description={description}
      >
        {buttonProperties && (
          <Button
            id="emptyButton"
            type="primary"
            onClick={buttonProperties.onClick}
          >
            {buttonProperties.text}
          </Button>
        )}
      </Empty>
    </ContentWrapper>
  );
};

export default EmptyWrapper;
