export const getCategoryName = (
  categoryCode: string | null,
  categories: string[][]
): string => {
  const [, text] = categories.find(([code]) => code === categoryCode) || [
    "",
    "INVALID CATEGORY CODE",
  ];
  return text;
};
