import { gql, QueryHookOptions, useQuery } from "@apollo/client";

import { DatevData } from "../types";

export interface DatevDataQueryVariables {
  email: string;
}

export interface DatevDataQueryResult {
  datevData?: DatevData[];
}

export const DATEV_DATA_QUERY = gql`
  query datevData($email: String!) {
    datevData(email: $email) {
      id
      skrMode
      bookingMode
      bookkeepingTool
      amount
      direction
      account
      offsetAccount
      paymentDate
      buKey
      meta1
      description
      kkr
      vatCategoryCode
      verifiedAt
      assets {
        filetype
        fullsize
      }
    }
  }
`;

export const useDatevDataQuery = (
  options?: QueryHookOptions<DatevDataQueryResult, DatevDataQueryVariables>
) =>
  useQuery<DatevDataQueryResult, DatevDataQueryVariables>(
    DATEV_DATA_QUERY,
    options
  );
