import type * as Types from "../../schema.generated";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type FetchDocumentCountQueryVariables = Types.Exact<{
  userId: Types.Scalars["Int"];
  year: Types.Scalars["Int"];
}>;

export type FetchDocumentCountQuery = {
  __typename?: "Query";
  documentCount: { __typename?: "DocumentCountResponse"; count: number };
};

export const FetchDocumentCountDocument = gql`
  query fetchDocumentCount($userId: Int!, $year: Int!) {
    documentCount(userId: $userId, year: $year) {
      count
    }
  }
`;

/**
 * __useFetchDocumentCountQuery__
 *
 * To run a query within a React component, call `useFetchDocumentCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchDocumentCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchDocumentCountQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      year: // value for 'year'
 *   },
 * });
 */
export function useFetchDocumentCountQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchDocumentCountQuery,
    FetchDocumentCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FetchDocumentCountQuery,
    FetchDocumentCountQueryVariables
  >(FetchDocumentCountDocument, options);
}
export function useFetchDocumentCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchDocumentCountQuery,
    FetchDocumentCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FetchDocumentCountQuery,
    FetchDocumentCountQueryVariables
  >(FetchDocumentCountDocument, options);
}
export type FetchDocumentCountQueryHookResult = ReturnType<
  typeof useFetchDocumentCountQuery
>;
export type FetchDocumentCountLazyQueryHookResult = ReturnType<
  typeof useFetchDocumentCountLazyQuery
>;
export type FetchDocumentCountQueryResult = Apollo.QueryResult<
  FetchDocumentCountQuery,
  FetchDocumentCountQueryVariables
>;
