import React, { useCallback, useMemo } from "react";
import { Typography, Card, Spin } from "antd";
import { useHistory } from "react-router-dom";

import { QuestionnaireInfo } from "./constants";
import QuestionnaireStatus from "./QuestionnaireStatus";
import QuestionnaireDetailedStatus from "../../QuestionnaireDetailedStatus";
import {
  Questionnaire,
  QuestionnaireType,
} from "../../../../../../../api/graphql/schema.generated";
import colors from "../../../../../../../themes/colors";

const { Paragraph, Text } = Typography;

export interface QuestionnaireCardProps {
  taxYear: number;
  info: QuestionnaireInfo;
  loading?: boolean;
  detailedStatus?: boolean;
  questionnaire?: Questionnaire;
  isNotRelevant?: boolean;
}

const QuestionnaireCard = ({
  taxYear,
  info,
  questionnaire,
  isNotRelevant,
  detailedStatus = false,
  loading,
}: QuestionnaireCardProps) => {
  const history = useHistory();

  const description = useMemo(() => {
    if (questionnaire?.type === QuestionnaireType.EOY_INCOME_TAX_CHILD) {
      const prefix = info.description;
      const childName = questionnaire.context.firstName;

      return `${prefix}: ${childName}`;
    }

    return info.description;
  }, [info.description, questionnaire]);

  const goToQuestionnaire = useCallback(() => {
    if (!info.notAvailable) {
      let pathname = `/tax-declaration/tax-preparation/${info.type}`;

      if (questionnaire?.type === QuestionnaireType.EOY_INCOME_TAX_CHILD) {
        pathname += `/${questionnaire.id}`;
      }

      history.push({
        pathname,
        search: history.location.search,
        key: info.type,
      });
    }
  }, [
    history,
    info.notAvailable,
    info.type,
    questionnaire?.id,
    questionnaire?.type,
  ]);

  return (
    <Card
      key={info.type}
      title={`${info.title} ${taxYear}${info.notAvailable ? " (soon)" : ""}`}
      size="small"
      hoverable
      onClick={goToQuestionnaire}
      style={info.notAvailable ? { cursor: "not-allowed" } : {}}
    >
      <Paragraph>{description}</Paragraph>
      {loading ? (
        <Spin size="small" style={{ height: 16 }} />
      ) : isNotRelevant ? (
        <Text style={{ color: colors.darkLimeGreen }}>Nicht relevant</Text>
      ) : detailedStatus ? (
        <QuestionnaireDetailedStatus questionnaire={questionnaire} />
      ) : (
        <QuestionnaireStatus status={questionnaire?.status} />
      )}
    </Card>
  );
};

export default QuestionnaireCard;
