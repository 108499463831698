import React, { useCallback, useMemo, useState } from "react";
import { Button, Col, Row, Space, Typography } from "antd";

import { DownloadOutlined, EyeOutlined } from "@ant-design/icons";

import moment from "moment";

import {
  ChangeLog,
  INCOME_TAX_DECLARATION_QUERY,
  useIncomeTaxDeclarationQuery,
  useUpdateIncomeTaxDeclarationMutation,
} from "../../../../../api/graphql";
import {
  IncomeTaxDeclarationStatus,
  KontaxNoteType,
  TaxDeclarationSavedDraftInfo,
  TaxDeclarationSubmissionInfo,
  TaxDeclarationType,
} from "../../../../../api/graphql/schema.generated";
import useEmailParam from "../../../../hooks/useEmailParam";
import useTaxYearParam from "../../hooks/useTaxYearParam";
import DeclarationStatus from "../../components/DeclarationStatus";
import { INCOME_TAX_DECLARATION_STATUS_MAPPINGS } from "./constants";
import IncomeTaxDeclarationStatusChangeModal from "./IncomeTaxDeclarationStatusChangeModal";
import EmptyWrapper from "../../../../common/EmptyWrapper";
import ActionLogDrawer from "../../../../common/ActionLogDrawer";
import { ActionsContainer } from "../../styles";
import NotesDrawer from "../../../../common/NotesDrawer";
import { useIncomeTaxDeclarationSubmissionInfoQuery } from "../../../../../api/graphql/queries/incomeTaxDeclaration/incomeTaxDeclarationSubmissionInfo.generated";
import { useIncomeTaxDeclarationSavedDraftInfoQuery } from "../../../../../api/graphql/queries/incomeTaxDeclaration/incomeTaxDeclarationSavedDraftInfo.generated";
import {
  destroyMessage,
  showGraphQlErrorNotification,
  showLoadingMessage,
} from "../../../../../utils";
import PdfPreviewModal from "../../components/PdfPreviewModal";
import DeclarationDeclinesDrawer from "../../../../common/DeclarationDeclinesDrawer";
import { declarationDeclinesSorter } from "../../../../../utils/declarationDeclines";
import DeclarationDeclinedBanner from "../../components/DeclarationDeclinedBanner";
import { getDraftInfoLatestCreationDate } from "../../utils";
import { SavedDraftInfoHint } from "../../components/styledComponents";

const { Title } = Typography;

const IncomeTaxDeclaration = () => {
  const [taxYear] = useTaxYearParam();
  const [email] = useEmailParam();
  const [submissionInfo, setSubmissionInfo] =
    useState<TaxDeclarationSubmissionInfo | null>();
  const [draftInfo, setDraftInfo] =
    useState<TaxDeclarationSavedDraftInfo | null>();

  const [isStatusChangeModalVisible, setIsStatusChangeModalVisible] =
    useState(false);

  const showStatusChangeModal = useCallback(() => {
    setIsStatusChangeModalVisible(true);
  }, []);

  const handleStatusChangeModalClose = useCallback(() => {
    setIsStatusChangeModalVisible(false);
  }, []);

  const { data: incomeTaxDeclarationData, loading: isLoadingDeclaration } =
    useIncomeTaxDeclarationQuery({
      skip: !email,
      variables: {
        email: email!,
        year: taxYear,
      },
    });
  const incomeTaxDeclaration = incomeTaxDeclarationData?.incomeTaxDeclaration;
  const savedSubmissionInfo = incomeTaxDeclaration?.submissionInfo || null;
  const savedDraftInfo = incomeTaxDeclaration?.savedDraftInfo || null;

  const { refetch: getIncomeTaxDeclarationSubmissionInfo } =
    useIncomeTaxDeclarationSubmissionInfoQuery({
      variables: {
        email: email!,
        year: taxYear,
      },
      fetchPolicy: "standby",
    });

  const { refetch: getIncomeTaxDeclarationSavedDraftInfo } =
    useIncomeTaxDeclarationSavedDraftInfoQuery({
      variables: {
        email: email!,
        year: taxYear,
      },
      fetchPolicy: "standby",
    });

  const [updateIncomeTaxDeclaration] = useUpdateIncomeTaxDeclarationMutation({
    refetchQueries: [INCOME_TAX_DECLARATION_QUERY],
  });

  const saveDeclaration = useCallback(
    async (status: IncomeTaxDeclarationStatus) => {
      if (!email) {
        return;
      }

      await updateIncomeTaxDeclaration({
        variables: {
          email,
          year: taxYear,
          payload: {
            status,
          },
        },
      });
    },
    [email, taxYear, updateIncomeTaxDeclaration]
  );

  const showSubmissionDocuments = useCallback(async () => {
    const loadingKey = "loading-submission-info";
    showLoadingMessage(loadingKey);
    try {
      const result = await getIncomeTaxDeclarationSubmissionInfo();
      setSubmissionInfo(result.data.incomeTaxDeclaration.submissionInfo);
    } catch (error) {
      showGraphQlErrorNotification(
        "Beim Abrufen des Einkommensteuererklärung documents ist ein Fehler aufgetreten:",
        error
      );
    } finally {
      destroyMessage(loadingKey);
    }
  }, [getIncomeTaxDeclarationSubmissionInfo]);

  const showSavedDraftDocuments = useCallback(async () => {
    const loadingKey = "loading-saved-draft-pdf";
    showLoadingMessage(loadingKey);
    try {
      const result = await getIncomeTaxDeclarationSavedDraftInfo();
      setDraftInfo(result.data.incomeTaxDeclaration.savedDraftInfo);
    } catch (error) {
      showGraphQlErrorNotification(
        "Beim Abrufen des Einkommensteuererklärung documents ist ein Fehler aufgetreten:",
        error
      );
    } finally {
      destroyMessage(loadingKey);
    }
  }, [getIncomeTaxDeclarationSavedDraftInfo]);

  const lastDeclarationDecline = useMemo(() => {
    if (
      incomeTaxDeclarationData?.incomeTaxDeclaration.declarationDeclines?.length
    ) {
      const declarationDeclines =
        incomeTaxDeclarationData.incomeTaxDeclaration.declarationDeclines;
      declarationDeclines.sort(declarationDeclinesSorter);
      return declarationDeclines[0];
    }
  }, [incomeTaxDeclarationData]);

  const isStatusObjectedByUser = useMemo(
    () =>
      incomeTaxDeclarationData?.incomeTaxDeclaration.status ===
      IncomeTaxDeclarationStatus.OBJECTED_BY_USER,
    [incomeTaxDeclarationData]
  );

  if (!email) {
    return <EmptyWrapper description="Suche nach einem Mandanten" />;
  }

  if (!incomeTaxDeclaration) {
    return null;
  }
  const title = `Einkommensteuererklärung ${taxYear}`;
  return (
    <>
      <ActionsContainer noTopMargin>
        <ActionLogDrawer
          withIcon
          title="Action log"
          changeLogs={incomeTaxDeclaration.changeLogs as ChangeLog[]}
        />
        <NotesDrawer
          notes={incomeTaxDeclaration.notes}
          title={title}
          type={KontaxNoteType.INCOME_TAX_DECLARATION}
          recordId={incomeTaxDeclaration.id}
        />
        <DeclarationDeclinesDrawer
          declarationDeclines={incomeTaxDeclaration.declarationDeclines}
          showNotification={
            incomeTaxDeclaration.status ===
            IncomeTaxDeclarationStatus.OBJECTED_BY_USER
          }
          title={title}
        />
      </ActionsContainer>
      <Title level={3}>{title}</Title>
      <Row justify="space-between" align="middle" className="mb-3">
        <Col>
          <DeclarationStatus
            mapping={INCOME_TAX_DECLARATION_STATUS_MAPPINGS}
            status={incomeTaxDeclaration.status}
            statusUpdatedAt={incomeTaxDeclaration.statusUpdatedAt}
            changedBy={incomeTaxDeclaration.lastStatusChange?.changedBy}
            onClick={showStatusChangeModal}
          />
        </Col>
        <Col>
          {!isLoadingDeclaration && savedDraftInfo && (
            <SavedDraftInfoHint>
              <div>Freigabe PDF erstellt</div>
              <div>
                am{" "}
                {moment(getDraftInfoLatestCreationDate(savedDraftInfo)).format(
                  "L"
                )}
              </div>
            </SavedDraftInfoHint>
          )}
        </Col>
      </Row>
      <Space direction="horizontal" style={{ marginTop: 25 }}>
        {!isLoadingDeclaration && savedDraftInfo && (
          <Button
            type="primary"
            size="small"
            icon={<EyeOutlined />}
            onClick={showSavedDraftDocuments}
          >
            Aktuelles Freigabe-PDF
          </Button>
        )}
        {!isLoadingDeclaration && savedSubmissionInfo && (
          <Button
            type="primary"
            size="small"
            icon={<DownloadOutlined />}
            onClick={showSubmissionDocuments}
          >
            Übermittelte Anl. EÜR PDF
          </Button>
        )}
      </Space>
      <DeclarationDeclinedBanner
        declaration={incomeTaxDeclaration}
        declarationType={TaxDeclarationType.INCOME_TAX}
        onLinkClick={showSavedDraftDocuments}
      />
      {isStatusChangeModalVisible && (
        <IncomeTaxDeclarationStatusChangeModal
          status={incomeTaxDeclaration.status}
          onSave={saveDeclaration}
          onClose={handleStatusChangeModalClose}
        />
      )}

      {(!!draftInfo || !!submissionInfo) && (
        <PdfPreviewModal
          declarationName="EÜR"
          title={
            !!draftInfo
              ? "Testformular"
              : "Übermittelte Einkommensteuererklärung PDF"
          }
          info={draftInfo! || submissionInfo!}
          declarationDecline={
            draftInfo && isStatusObjectedByUser
              ? lastDeclarationDecline
              : undefined
          }
          statusUpdatedAt={
            draftInfo &&
            isStatusObjectedByUser &&
            incomeTaxDeclarationData?.incomeTaxDeclaration.lastStatusChange
              ? incomeTaxDeclarationData.incomeTaxDeclaration.lastStatusChange
                  .changedAt
              : undefined
          }
          onClose={() => {
            setDraftInfo(null);
            setSubmissionInfo(null);
          }}
        />
      )}
    </>
  );
};

export default IncomeTaxDeclaration;
