import styled from "styled-components";
import { DatePicker } from "antd";

export const TopDatePicker = styled(DatePicker)`
  width: 100%;
  margin-bottom: 7px;
`;

export const BottomDatePicker = styled(DatePicker)`
  width: 100%;
`;
