import type * as Types from "../schema.generated";

import { gql } from "@apollo/client";
export type AnnotationTagFragment = {
  __typename?: "AnnotationTag";
  id: string;
  tag: string;
  color: string;
  description: string;
};

export const AnnotationTagFragmentDoc = gql`
  fragment AnnotationTag on AnnotationTag {
    id
    tag
    color
    description
  }
`;
