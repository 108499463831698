import type * as Types from "../../schema.generated";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type MatchDocumentToTransactionMutationVariables = Types.Exact<{
  email: Types.Scalars["String"];
  emailDocumentId: Types.Scalars["ID"];
  transactionId: Types.Scalars["ID"];
}>;

export type MatchDocumentToTransactionMutation = {
  __typename?: "Mutation";
  matchEmailDocumentToTransaction: {
    __typename?: "MutationResult";
    success: boolean;
  };
};

export const MatchDocumentToTransactionDocument = gql`
  mutation matchDocumentToTransaction(
    $email: String!
    $emailDocumentId: ID!
    $transactionId: ID!
  ) {
    matchEmailDocumentToTransaction(
      email: $email
      emailDocumentId: $emailDocumentId
      transactionId: $transactionId
    ) {
      success
    }
  }
`;
export type MatchDocumentToTransactionMutationFn = Apollo.MutationFunction<
  MatchDocumentToTransactionMutation,
  MatchDocumentToTransactionMutationVariables
>;

/**
 * __useMatchDocumentToTransactionMutation__
 *
 * To run a mutation, you first call `useMatchDocumentToTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMatchDocumentToTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [matchDocumentToTransactionMutation, { data, loading, error }] = useMatchDocumentToTransactionMutation({
 *   variables: {
 *      email: // value for 'email'
 *      emailDocumentId: // value for 'emailDocumentId'
 *      transactionId: // value for 'transactionId'
 *   },
 * });
 */
export function useMatchDocumentToTransactionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MatchDocumentToTransactionMutation,
    MatchDocumentToTransactionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MatchDocumentToTransactionMutation,
    MatchDocumentToTransactionMutationVariables
  >(MatchDocumentToTransactionDocument, options);
}
export type MatchDocumentToTransactionMutationHookResult = ReturnType<
  typeof useMatchDocumentToTransactionMutation
>;
export type MatchDocumentToTransactionMutationResult =
  Apollo.MutationResult<MatchDocumentToTransactionMutation>;
export type MatchDocumentToTransactionMutationOptions =
  Apollo.BaseMutationOptions<
    MatchDocumentToTransactionMutation,
    MatchDocumentToTransactionMutationVariables
  >;
