import { Col, Divider, Row, Tag, Typography } from "antd";
import React from "react";

import { formatIntegerAmountCurrency } from "../../../../../../../utils";

const { Text } = Typography;

export interface TradeTaxDeclarationCalculationFieldProps {
  tag: string;
  label: string;
  amount: number;
}

const TradeTaxDeclarationCalculationField = ({
  tag,
  label,
  amount,
}: TradeTaxDeclarationCalculationFieldProps) => {
  return (
    <>
      <Row>
        <Col>
          <Tag className="px-3">{tag}</Tag>
        </Col>
        <Col flex="1" className="pr-5">
          <Text>{label}</Text>
        </Col>
        <Col>
          <Text>{formatIntegerAmountCurrency(amount)}</Text>
        </Col>
      </Row>
      <Divider />
    </>
  );
};

export default TradeTaxDeclarationCalculationField;
