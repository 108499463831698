import React, { useState, useCallback } from "react";

import _ from "lodash";
import { notification } from "antd";

import { useRequest, useLazyRequest } from "../../../hooks/useRequest.hook";
import {
  KontaxTransactionFilterOptions,
  ITransaction,
  KontaxTransactionMeta,
  TransactionSplit,
  Asset,
} from "../../../../types";
import api from "../../../../api";
import { TransactionsListViewWrapper } from "./styledComponents";
import { TransactionSplitState } from "../../../common/types";
import {
  destroyMessage,
  showSuccessMessage,
  showLoadingMessage,
} from "../../../../utils";
import TransactionsTable from "./TransactionsTable";
import {
  KontaxTransactionUpsertInput,
  UPSERT_KONTAX_TRANSACTIONS_MUTATION,
  useUpsertTransactionsMutation,
} from "../../../../api/graphql/mutations/transaction/upsertKontaxTransactions";
import { hasVerifiedOnly } from "./utils";
import { verifyTransactions } from "./verifyTransactions";
import { shouldRefetchTransactionOnAssetUpload } from "../../TransactionView/helpers";

interface Props {
  filterOptions: KontaxTransactionFilterOptions;
  isNested?: boolean;
}

const TransactionList = ({ filterOptions, isNested }: Props) => {
  const [response, setResponse] = useState<{
    transactions: ITransaction[] | null;
  }>({
    transactions: null,
  });

  const [selectedTransactions, setSelectedTransactions] = useState<
    ITransaction[]
  >([]);

  const [
    hasSelectedVerifiedTransactionsOnly,
    setHasSelectedVerifiedTransactionsOnly,
  ] = useState<boolean>(false);

  const resetSelectedTransactions = () => {
    setSelectedTransactions([]);
    setHasSelectedVerifiedTransactionsOnly(false);
  };

  const setSelection = (selectedTransactionIds: string[]) => {
    const transactions = response.transactions;

    if (transactions) {
      const selectedTransactions = transactions.filter((t) =>
        selectedTransactionIds.includes(t.id)
      );
      setSelectedTransactions(selectedTransactions);

      setHasSelectedVerifiedTransactionsOnly(
        hasVerifiedOnly(selectedTransactions)
      );
    }
  };

  const verifySelectedTransactions = async () =>
    await verifyTransactions({
      selectedTransactions,
      updateTransactionsMeta,
      resetSelectedTransactions,
    });

  const fetchTransactions = useCallback(async () => {
    if (filterOptions.email) {
      const loadingKey = "loading-verified-transactions";
      try {
        showLoadingMessage(loadingKey);
        resetSelectedTransactions();

        const data = await api.kontax.fetchTransactions(
          filterOptions,
          undefined
        );

        setResponse({
          transactions: data.transactions,
        });
        showSuccessMessage();
      } catch (error) {
        notification.error({
          message: "Error while fetching transactions",
          description: (error as Error).message,
        });
      } finally {
        destroyMessage(loadingKey);
      }
    }
  }, [filterOptions]);

  const refreshTransactionSplitView = useCallback(
    (transactionId: string, state: TransactionSplitState) => {
      const updatedTransactions = response.transactions?.map((transaction) => {
        if (transaction.id === transactionId) {
          return { ...transaction, splits: state.splits };
        }

        return transaction;
      });

      updatedTransactions &&
        setResponse({ transactions: updatedTransactions as ITransaction[] });
    },
    [response.transactions]
  );

  const [upsertTransactionSplit] = useLazyRequest(
    async (transactionId: string, state: TransactionSplitState) => {
      try {
        if (state.isValid) {
          const methodName = state.isNew
            ? "createTransactionSplit"
            : "updateTransactionSplit";
          await api.kontax[methodName](
            transactionId,
            state.splits as Array<TransactionSplit>
          );
          await fetchTransactions();
        }
      } catch (error) {
        notification.error({
          message: "Error while split transaction",
          description: (error as Error).message,
        });
      }
    }
  );

  const [deleteTransactionSplit] = useLazyRequest(
    async (transactionId: string, state: TransactionSplitState) => {
      try {
        if (!state.isNew) {
          await api.kontax.deleteTransactionSplit(transactionId);
          await fetchTransactions();
        }
      } catch (error) {
        notification.error({
          message: "Error while delete transaction",
          description: (error as Error).message,
        });
      }
    }
  );

  const [upsertKontaxTransactions] = useUpsertTransactionsMutation({
    refetchQueries: [UPSERT_KONTAX_TRANSACTIONS_MUTATION],
  });

  const updateTransactionsMeta = async (
    payload: Array<KontaxTransactionMeta>
  ) => {
    resetSelectedTransactions();

    const mappedTransactions = payload.map((payload) => {
      let { categoryCodeMeta, vatCategoryCodeMeta, ...rest } = payload;
      return {
        categoryCodeSuggestionSource: categoryCodeMeta?.suggestionSource,
        ...rest,
      };
    });

    try {
      const upsertResponse = await upsertKontaxTransactions({
        variables: {
          transactions: mappedTransactions as KontaxTransactionUpsertInput[],
        },
      });

      const updatedTransactions =
        upsertResponse?.data?.upsertKontaxTransactions?.transactions || [];

      // transaction can be removed from DB eventually. For example: in case agent un-verify DATEV transaction.
      // in that case, we simply exclude it from the transactions.
      if (updatedTransactions.length !== mappedTransactions.length) {
        for (const mappedTransaction of mappedTransactions) {
          if (
            !updatedTransactions.some(
              (updatedTransaction) =>
                mappedTransaction.id === updatedTransaction.id
            )
          ) {
            setResponse(({ transactions }) => ({
              transactions:
                transactions?.filter(
                  (transaction) => transaction.id !== mappedTransaction.id
                ) || null,
            }));
          }
        }
      }

      // Exchange old transactions with updated ones.
      setResponse((currentResponse) => {
        const changedTransactions = [];
        for (const updatedTransaction of updatedTransactions) {
          const index = _.findIndex(currentResponse.transactions, [
            "id",
            updatedTransaction.id,
          ]);
          if (index > -1) {
            const currentTransaction = currentResponse.transactions?.[index];
            changedTransactions[index] = {
              ...updatedTransaction,
              assets: currentTransaction?.assets || updatedTransaction.assets,
              splits: updatedTransaction.splits?.map((updatedSplit) => {
                const currentSplit = currentTransaction?.splits?.find(
                  (currentSplit) => currentSplit.id === updatedSplit.id
                );
                return {
                  ...currentSplit,
                  ...updatedSplit,
                };
              }),
            };
          }
        }

        return {
          ...currentResponse,
          transactions: Object.assign(
            [],
            currentResponse.transactions ?? [],
            changedTransactions
          ),
        };
      });

      return true;
    } catch (error) {
      await fetchTransactions();
      notification.error({
        message: "Error while updating transaction",
        description: (error as Error).message,
      });
      return false;
    }
  };

  const refreshTransactionAssets = useCallback(
    async (transactionId: string, assets: Array<Asset>) => {
      const transaction = response.transactions?.find(
        (t) => t.id === transactionId
      );
      let refetchedTransaction: ITransaction | null;

      if (shouldRefetchTransactionOnAssetUpload(transaction)) {
        await new Promise((resolve) => setTimeout(resolve, 200));
        refetchedTransaction = await api.kontax.fetchTransactionById(
          transactionId
        );
      }

      const updatedTransactions = response.transactions?.map((transaction) => {
        if (transaction.id === transactionId) {
          return { ...transaction, assets, ...refetchedTransaction };
        }

        return transaction;
      });

      updatedTransactions &&
        setResponse({ transactions: updatedTransactions as ITransaction[] });
    },
    [response.transactions]
  );

  useRequest(fetchTransactions);
  return (
    <TransactionsListViewWrapper>
      <TransactionsTable
        email={filterOptions.email}
        updateTransactionsMeta={updateTransactionsMeta}
        transactions={response.transactions}
        updateTransactionsState={setResponse}
        onSplitSubmit={(...args) => {
          refreshTransactionSplitView(...args);
          upsertTransactionSplit(...args);
        }}
        onSplitDelete={(...args) => {
          refreshTransactionSplitView(...args);
          deleteTransactionSplit(...args);
        }}
        onAssetsUpdate={refreshTransactionAssets}
        setSelection={setSelection}
        selectedTransactions={selectedTransactions}
        hasSelectedVerifiedTransactionsOnly={
          hasSelectedVerifiedTransactionsOnly
        }
        verifySelectedTransactions={verifySelectedTransactions}
        isNested={isNested}
      />
    </TransactionsListViewWrapper>
  );
};

export default TransactionList;
