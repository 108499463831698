import React, { FC, useState, ChangeEvent } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { notification, Modal } from "antd";

import { Container, TableContainer, LoaderContainer } from "./styled";
import {
  useUpdateDatevDataMutation,
  useToggleDatevDataVerifiedFlagMutation,
  useRemoveDatevDataMutation,
  useSwitchDatevDataAccountFieldMutation,
  useDatevDataQuery,
  DatevData,
} from "../../../../../../api/graphql";
import { Direction, IUser } from "../../../../../../types";
import DatevTable from "./DatevTable";
import ButtonGroups from "./ButtonGroups";
import TableOptions from "./TableOptions";
import UploadForm from "./UploadForm";
import { getCompatibleVatCategories } from "../../../../../../utils/vatCategories";

type DatevImportProps = {
  user: IUser;
};

const updateVatCategoryIfKkrChange = ({
  fieldName,
  ...rest
}: {
  id: string;
  direction: Direction;
  kkr: string;
  vatCategoryCode: string;
  fieldName: string;
}) => {
  if (fieldName === "kkr") {
    // At this moment, we don't know if client is business owner or not, because "vatYearPaymentFrequency" is not defined anywhere.
    // So we intentionally set "isSmallBusinessOwner" to false.
    const vatCategories = getCompatibleVatCategories(rest.kkr, false);
    const vatCategoryIds = vatCategories.map(([id]) => id);

    // If there is only one matching vatCategory, select it
    if (vatCategoryIds.length === 1) {
      return { ...rest, vatCategoryCode: vatCategoryIds[0] };
    }

    // If the vatCategory is not in the list, unset it.
    if (!vatCategoryIds.includes(rest.vatCategoryCode)) {
      return { ...rest, vatCategoryCode: "" };
    }
  }

  return rest;
};

const DatevImport: FC<DatevImportProps> = ({ user }) => {
  // Here we get and assign the first key of each enum above
  const [skrSearchInput, setSkrSearchInput] = useState<string>("");
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [deletingIds, setDeletingIds] = useState<string[]>([]);

  const confirmDelete = () => {
    if (deletingIds.length) {
      handleDeleteImportedData(deletingIds);
    }
  };

  const {
    data: queryData,
    loading: isQuerying,
    refetch: refetchDatevData,
  } = useDatevDataQuery({
    variables: {
      email: user.email,
    },
    fetchPolicy: "network-only", // Used for first execution
    nextFetchPolicy: "cache-first", // Used for subsequent executions
    notifyOnNetworkStatusChange: true,
  });

  const [deleteImportedData, { loading: isDeleting }] =
    useRemoveDatevDataMutation({
      update: (cache, { data }) => {
        if (!data?.removeDatevData) {
          return;
        }

        const {
          removeDatevData: { deletedIds },
        } = data;

        cache.modify({
          fields: {
            datevData: (existingRefs = [], { readField }) => {
              if (deletedIds?.length) {
                return existingRefs.filter(
                  (ref: any) => !deletedIds.includes(readField("id", ref) || "")
                );
              }
              return [];
            },
          },
        });
      },
    });

  const [updateImportedData, { loading: isUpdating }] =
    useUpdateDatevDataMutation();

  const [verifyImportedData, { loading: isVerifying }] =
    useToggleDatevDataVerifiedFlagMutation();

  const [switchDatevDataAccountField, { loading: isSwitching }] =
    useSwitchDatevDataAccountFieldMutation();

  const resetData = () => {
    setSkrSearchInput("");
    setSelectedIds([]);
    setDeletingIds([]);
  };

  const handleSkrSearch = () => {
    const selected = queryData?.datevData
      ?.filter(
        ({ account, offsetAccount, verifiedAt }) =>
          [account, offsetAccount].includes(skrSearchInput) && !verifiedAt // do not selected verified record
      )
      .map(({ id }) => id);

    if (selected != null) {
      setSelectedIds(selected);
    }
  };

  const handleDeleteImportedData = async (ids?: string[]) => {
    resetData();
    await deleteImportedData({
      variables: {
        email: user.email,
        ...(ids ? { ids } : {}),
      },
    });
  };

  const handleVerifyImportedData = async (id: string) => {
    try {
      await verifyImportedData({
        variables: {
          id,
        },
      });

      // exclude from row selection
      setSelectedIds(selectedIds.filter((selectedId) => selectedId !== id));
    } catch (e) {
      const statusCode = (e as any).networkError?.result?.errors?.[0]
        ?.extensions?.status;
      // If status code is 403, it's because the associated transaction is submitted for VAT prepayment.
      // Otherwise, it's because of missing kkr or vatCode.
      const errorMessage =
        statusCode === 403
          ? "Buchung durch USTVA festgeschrieben"
          : "Fail to verify data. Please try again.";
      notification.error({ message: errorMessage });
    }
  };

  const handleSwitchDatevDataAccountField = async (ids: string[]) => {
    try {
      await switchDatevDataAccountField({
        variables: {
          ids,
        },
      });
    } catch (e) {
      notification.error({
        message: "Fail to switch account and offsetAccount. Please try again.",
      });
    }
  };

  const handleFieldSelect =
    (fieldName: string, row: DatevData) => async (value: string | null) => {
      const current = {
        id: row.id,
        direction: row.direction,
        kkr: row.kkr,
        vatCategoryCode: row.vatCategoryCode,
      };

      try {
        await updateImportedData({
          variables: {
            payload: updateVatCategoryIfKkrChange({
              ...current,
              // "kkr" and "vatCategoryCode" value can be null
              [fieldName]: value || "",
              fieldName,
            }),
          },
        });
      } catch (e) {
        notification.error({
          message: "Fail to update data. Please try again.",
        });
      }
    };

  const handleSkrSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSkrSearchInput(event.target.value);
  };

  if (isQuerying) {
    return (
      <LoaderContainer>
        <LoadingOutlined style={{ fontSize: 24 }} spin />
      </LoaderContainer>
    );
  }

  // Using nullish coalescing to avoid typescript error
  if ((queryData?.datevData?.length ?? 0) > 0) {
    const hasVerifiedData = queryData!.datevData!.some(({ verifiedAt }) =>
      Boolean(verifiedAt)
    );
    const isLoading = isUpdating || isDeleting || isVerifying || isSwitching;

    return (
      <TableContainer>
        <TableOptions
          skrSearchInput={skrSearchInput}
          selectedIds={selectedIds}
          onDeleteImportedData={setDeletingIds}
          onSwitchAccountField={handleSwitchDatevDataAccountField}
          onSkrSearchChange={handleSkrSearchChange}
          onSearch={handleSkrSearch}
          isLoading={isLoading}
          datevData={queryData!.datevData!}
          resetData={resetData}
        />
        <DatevTable
          datevData={queryData!.datevData!}
          selectedIds={selectedIds}
          onRowSelection={setSelectedIds}
          onFieldSelect={handleFieldSelect}
          onFieldDelete={setDeletingIds}
          onVerifyData={handleVerifyImportedData}
          onSwitchAccount={handleSwitchDatevDataAccountField}
          isLoading={isLoading}
        />
        <ButtonGroups
          loading={isDeleting}
          onDeleteImportedData={
            hasVerifiedData ? undefined : handleDeleteImportedData
          }
          userEmail={user.email}
          refetchDatevData={refetchDatevData}
        />
        <Modal
          title="Löschen bestätigen"
          visible={deletingIds.length > 0}
          onOk={confirmDelete}
          onCancel={() => setDeletingIds([])}
          okText="Löschen"
          okType="danger"
          cancelText="Abbrechen"
        >
          <p>Soll die Transaktion endgültig gelöscht werden?</p>
        </Modal>
      </TableContainer>
    );
  }

  return (
    <Container>
      <UploadForm userEmail={user.email} refetchDatevData={refetchDatevData} />
    </Container>
  );
};

export default DatevImport;
