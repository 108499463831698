import { gql, MutationHookOptions, useMutation } from "@apollo/client";

export interface FinalizeAssetUploadMutationVariables {
  assetId: string;
}

export interface FinalizeAssetUploadMutationResult {
  finalizeAssetUpload: {
    filetype: string;
    fullsize: string;
  };
}

export const FINALIZE_ASSET_UPLOAD_MUTATION = gql`
  mutation finalizeAssetUpload($assetId: ID!) {
    finalizeAssetUpload(assetId: $assetId) {
      filetype
      fullsize
    }
  }
`;

export const useFinalizeAssetUploadMutation = (
  options?: MutationHookOptions<
    FinalizeAssetUploadMutationResult,
    FinalizeAssetUploadMutationVariables
  >
) =>
  useMutation<
    FinalizeAssetUploadMutationResult,
    FinalizeAssetUploadMutationVariables
  >(FINALIZE_ASSET_UPLOAD_MUTATION, options);
