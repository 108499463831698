import styled from "styled-components";
import { Row } from "antd";
import { RightOutlined } from "@ant-design/icons";

import colors from "../../../../../themes/colors";

export const DeclineBannerContainer = styled(Row)`
  margin-top: 20px;
  padding-left: 16px;
  padding-right: 16px;
  height: 46px;
  align-items: center;
  background: ${colors.lightestGray};
  border-radius: 4px;
`;

export const StyledRightOutline = styled(RightOutlined)`
  display: flex;
  color: ${colors.primaryPurple};
`;
