import { Typography } from "antd";
import styled from "styled-components";

import colors from "../../../../../../themes/colors";

export const ActionLink = styled(Typography.Link)`
  margin-left: 8px;
`;

export const RetryButton = styled.span`
  cursor: pointer;
  color: ${colors.purple};
  font-weight: bold;
`;

const TEXT_WIDTH = "345px";
export const PopconfirmText = styled.div`
  max-width: ${TEXT_WIDTH};
  display: flex;
  flex-wrap: wrap;
`;

export const PopconfirmSentence = styled.div`
  width: ${TEXT_WIDTH};
`;
