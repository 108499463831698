import {
  DisconnectOutlined,
  EllipsisOutlined,
  HistoryOutlined,
  StarOutlined,
} from "@ant-design/icons";
import { Button, Col, notification, Row } from "antd";
import styled from "styled-components";

import { useAssignTaxCaseMutation } from "../../../../../api/graphql/mutations/taxCase/assignTaxCase.generated";
import { useUnassignTaxCaseMutation } from "../../../../../api/graphql/mutations/taxCase/unassignTaxCase.generated";
import { TaxBoardUser } from "../../../../../api/graphql/schema.generated";

import { getCurrentUserEmail } from "../../../../../gapi";
import { destroyMessage, showLoadingMessage } from "../../../../../utils";
import ActionLogDrawerAsync from "../../../../common/ActionLogDrawerAsync";
import UserInfoExcerpt from "../../../../common/UserInfoExcerpt";
import { UserInfoDrawerSources } from "../../../../common/UserInfoExcerpt/UserInfoDrawer";
import { useKontaxUserContext } from "../../../../contexts/KontaxUserContext";

const Container = styled.div`
  margin-bottom: 30px;
`;

const LOADING_MESSAGE_KEY = "assigning-or-unassigning-tax-case";

const getTaxCaseAssignee = (taxBoardUser: TaxBoardUser) =>
  taxBoardUser.kontaxUser?.taxCases[0]?.assignee;

const TaxCaseActions = ({ taxBoardUser }: { taxBoardUser: TaxBoardUser }) => {
  const { user, notes, isLoading } = useKontaxUserContext();
  const [assignTaxCase, { loading: isAssigning }] = useAssignTaxCaseMutation();
  const [unassignTaxCase, { loading: isUnassigning }] =
    useUnassignTaxCaseMutation();

  const assignee = getTaxCaseAssignee(taxBoardUser);
  const taxCaseId = taxBoardUser.kontaxUser?.taxCases[0]?.id!;

  const handleAssignUnassignButton = async () => {
    showLoadingMessage(LOADING_MESSAGE_KEY);
    try {
      if (getTaxCaseAssignee(taxBoardUser)) {
        await unassignTaxCase({ variables: { taxCaseId } });
      } else {
        await assignTaxCase({ variables: { taxCaseId } });
      }
    } catch (error) {
      notification.error({
        message: `Error occurred while assign or unassign client`,
      });
    } finally {
      destroyMessage(LOADING_MESSAGE_KEY);
    }
  };

  return (
    <Container>
      <Row gutter={8}>
        <Col>
          <Button
            type={!assignee ? "default" : "primary"}
            icon={
              assignee === null || assignee === getCurrentUserEmail() ? (
                <StarOutlined />
              ) : (
                <DisconnectOutlined />
              )
            }
            onClick={handleAssignUnassignButton}
            loading={isAssigning || isUnassigning}
          >
            {assignee === null
              ? "Mandant übernehmen"
              : assignee === getCurrentUserEmail()
              ? "Mir zugewiesen"
              : "Mandant abgeben"}
          </Button>
        </Col>
        <Col>
          {taxCaseId && (
            <ActionLogDrawerAsync
              title="Action log"
              modelName="tax_case"
              recordIds={[taxCaseId!]}
              renderButton={({ onClick }) => (
                <Button icon={<HistoryOutlined />} onClick={onClick}>
                  View logs
                </Button>
              )}
            />
          )}
        </Col>
        <Col>
          {user && (
            <UserInfoExcerpt
              user={user}
              notes={notes}
              source={UserInfoDrawerSources.TAX}
              isLoading={isLoading}
              renderExcerptData={() => {
                return (
                  <Button type="default" icon={<EllipsisOutlined />}>
                    More info
                  </Button>
                );
              }}
            />
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default TaxCaseActions;
