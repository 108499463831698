import { gql, MutationHookOptions, useMutation } from "@apollo/client";

import { BusinessAddress } from "../..";

export interface CreateBusinessAddressInput {
  street: string;
  postCode: string;
  city: string;
  movingDate: Date;
}

export interface CreateBusinessAddressMutationVariables {
  email: string;
  payload: CreateBusinessAddressInput;
}

export interface CreateBusinessAddressMutationResult {
  createBusinessAddress: BusinessAddress;
}

export const CREATE_BUSINESS_ADDRESS_MUTATION = gql`
  mutation createBusinessAddress(
    $email: String!
    $payload: CreateBusinessAddressInput!
  ) {
    createBusinessAddress(email: $email, payload: $payload) {
      id
      street
      postCode
      city
      movingDate
    }
  }
`;

export const useCreateBusinessAddressMutation = (
  options?: MutationHookOptions<
    CreateBusinessAddressMutationResult,
    CreateBusinessAddressMutationVariables
  >
) =>
  useMutation<
    CreateBusinessAddressMutationResult,
    CreateBusinessAddressMutationVariables
  >(CREATE_BUSINESS_ADDRESS_MUTATION, options);
