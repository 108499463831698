import type * as Types from "../../schema.generated";

import { gql } from "@apollo/client";
import { AutocategorizationRuleFragmentDoc } from "../../fragments/autocategorizationRule.generated";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type AutocategorizationRuleQueryVariables = Types.Exact<{
  id: Types.Scalars["Int"];
}>;

export type AutocategorizationRuleQuery = {
  __typename?: "Query";
  autocategorizationRule?: {
    __typename?: "AutocategorizationRule";
    id: number;
    description: string;
    enabled: boolean;
    priority: number;
    categoryCode?: Types.CategoryCode | null;
    vatCategoryCode?: Types.VatCategoryCode | null;
    verify: boolean;
    accuracy?: number | null;
    appliedTimesCount?: number | null;
    conditions: {
      __typename?: "AutocategorizationRuleConditions";
      accountIds?: Array<number> | null;
      hasEmployees?: boolean | null;
      hasInvoices?: boolean | null;
      hasVatNumber?: boolean | null;
      ibans?: Array<string> | null;
      vatPaymentFrequencies?: Array<Types.PaymentFrequency> | null;
      amount?: {
        __typename?: "RuleConditionNumber";
        op: Types.RuleConditionNumberOp;
        value: number;
      } | null;
      description?: {
        __typename?: "RuleConditionString";
        op: Types.RuleConditionStringOp;
        value: string;
      } | null;
      name?: {
        __typename?: "RuleConditionString";
        op: Types.RuleConditionStringOp;
        value: string;
      } | null;
    };
  } | null;
};

export const AutocategorizationRuleDocument = gql`
  query autocategorizationRule($id: Int!) {
    autocategorizationRule(id: $id) {
      ...AutocategorizationRule
    }
  }
  ${AutocategorizationRuleFragmentDoc}
`;

/**
 * __useAutocategorizationRuleQuery__
 *
 * To run a query within a React component, call `useAutocategorizationRuleQuery` and pass it any options that fit your needs.
 * When your component renders, `useAutocategorizationRuleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAutocategorizationRuleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAutocategorizationRuleQuery(
  baseOptions: Apollo.QueryHookOptions<
    AutocategorizationRuleQuery,
    AutocategorizationRuleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AutocategorizationRuleQuery,
    AutocategorizationRuleQueryVariables
  >(AutocategorizationRuleDocument, options);
}
export function useAutocategorizationRuleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AutocategorizationRuleQuery,
    AutocategorizationRuleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AutocategorizationRuleQuery,
    AutocategorizationRuleQueryVariables
  >(AutocategorizationRuleDocument, options);
}
export type AutocategorizationRuleQueryHookResult = ReturnType<
  typeof useAutocategorizationRuleQuery
>;
export type AutocategorizationRuleLazyQueryHookResult = ReturnType<
  typeof useAutocategorizationRuleLazyQuery
>;
export type AutocategorizationRuleQueryResult = Apollo.QueryResult<
  AutocategorizationRuleQuery,
  AutocategorizationRuleQueryVariables
>;
