import React, { useMemo } from "react";
import { Link, LinkProps } from "react-router-dom";
import * as queryString from "query-string";
import zip from "lodash/zip";

import { VatAnnualDeclarationFieldType } from "../../../../../api/graphql";
import { VAT_ANNUAL_DECLARATION_FIELD_TYPE_LABELS } from "./constants";
import { VatAnnualDeclarationFieldRow } from "./VatAnnualDeclarationFieldsTable";
import { EUER_DECLARATION_OVERVIEW_META_INFO } from "../EuerDeclaration/constants";
import colors from "../../../../../themes/colors";
import { getVerificationViewPath } from "./utils";
import { useUserContext } from "../../contexts/UserContext";
import { SMALL_BUSINESS_PAYMENT_FREQUENCIES } from "../../../../../types";
import useTaxYearParam from "../../hooks/useTaxYearParam";
import { PATH_BY_EUER_TYPE } from "../EuerDeclaration/pages/EuerDeclarationOverview/helpers";

const FIELD_TYPE_REPLACEMENT_PLACEHOLDER = "...";

const getFieldTypeLabelReplacement = (
  row: VatAnnualDeclarationFieldRow
): string | null => {
  switch (row.type) {
    case VatAnnualDeclarationFieldType.INCOME_0_ITD:
      return row.vatExemptionWithItd;
    case VatAnnualDeclarationFieldType.VAT_EXEMPTION_WITHOUT_ITD:
      return row.vatExemptionWithoutItd;
    default:
      return null;
  }
};

const getFieldTypeLabels = (row: VatAnnualDeclarationFieldRow): string[] => {
  const label = VAT_ANNUAL_DECLARATION_FIELD_TYPE_LABELS[row.type];
  const labels: string[] = Array.isArray(label) ? label : [label];
  return labels.map((text) => {
    const replacement = getFieldTypeLabelReplacement(row);
    if (replacement) {
      text = text.replace(FIELD_TYPE_REPLACEMENT_PLACEHOLDER, replacement);
    }
    const suffix = row.yearSuffix ? ` ${row.yearSuffix}` : "";
    return `${text}${suffix}`;
  });
};

const getFieldTypePaths = (
  row: VatAnnualDeclarationFieldRow,
  isSmallBusiness?: boolean
): Array<LinkProps["to"] | null> => {
  const { type, email, taxYear: year } = row;
  switch (type) {
    case VatAnnualDeclarationFieldType.CAR_USAGE: {
      return [
        {
          pathname: EUER_DECLARATION_OVERVIEW_META_INFO.carUsageSubform.path,
          search: queryString.stringify({ email, year }),
        },
      ];
    }
    case VatAnnualDeclarationFieldType.HOME_OFFICE_DIT: {
      return [
        {
          pathname: EUER_DECLARATION_OVERVIEW_META_INFO.officeUsageSubform.path,
          search: queryString.stringify({ email, year }),
        },
      ];
    }
    case VatAnnualDeclarationFieldType.TOTAL_DIT: {
      return [];
    }
    case VatAnnualDeclarationFieldType.ELECTRONIC_SERVICE_EU_B2C: {
      const categories = isSmallBusiness ? 1111 : 1122;
      return [
        {
          pathname: PATH_BY_EUER_TYPE.VERIFICATION_VIEW,
          search: queryString.stringify({ email, year, categories }),
        },
      ];
    }
    default:
      return [getVerificationViewPath(row)];
  }
};

export interface VatAnnualDeclarationFieldTypeLabelProps {
  row: VatAnnualDeclarationFieldRow;
}

const VatAnnualDeclarationFieldTypeLabel = ({
  row,
}: VatAnnualDeclarationFieldTypeLabelProps) => {
  const [taxYear] = useTaxYearParam();

  const user = useUserContext();
  const isSmallBusiness = useMemo(() => {
    const userVatPaymentFrequency = user?.vatYearSettings?.find(
      ({ year }) => year === taxYear
    )?.vatPaymentFrequency;
    return SMALL_BUSINESS_PAYMENT_FREQUENCIES.includes(
      userVatPaymentFrequency!
    );
  }, [user?.vatYearSettings, taxYear]);

  const labels = getFieldTypeLabels(row);
  const paths = getFieldTypePaths(row, isSmallBusiness);
  return (
    <>
      {zip(labels, paths).map(([label, path], index) => (
        <>
          {index > 0 ? (
            <div style={{ color: colors.lightGreyOpacity }}>und</div>
          ) : null}
          {path ? <Link to={path}>{label}</Link> : label}
        </>
      ))}
    </>
  );
};

export default VatAnnualDeclarationFieldTypeLabel;
