import { useEffect, useMemo, useState } from "react";
import { Collapse } from "antd";
import groupBy from "lodash/groupBy";

import { MinusSquareOutlined, PlusSquareOutlined } from "@ant-design/icons";

import { DeclarationDocumentType } from "../../../../../../api/graphql/schema.generated";
import {
  DocumentsContainer,
  DeclarationTypeTitle,
  DeclarationTypeCollapse,
} from "./styles";
import { DeclarationDocumentFragment } from "../../../../../../api/graphql/fragments/declarationDocument.generated";
import DocumentsList from "./DocumentsList";

const { Panel } = Collapse;

const DeclarationDocuments = ({
  title,
  declarationDocuments,
  onRefresh,
}: {
  title: string;
  declarationDocuments: DeclarationDocumentFragment[];
  onRefresh: () => void;
}) => {
  const [activeKeys, setActiveKeys] = useState<string[]>([]);

  const documents = useMemo(() => {
    const groupedDocuments = groupBy(
      declarationDocuments,
      (document) => document.documentType
    );
    return {
      PREVIEW_FORM:
        groupedDocuments[DeclarationDocumentType.PREVIEW_FORM] || [],
      SUBMITTED_FORM:
        groupedDocuments[DeclarationDocumentType.SUBMITTED_FORM] || [],
      TAX_BILL: groupedDocuments[DeclarationDocumentType.TAX_BILL] || [],
    };
  }, [declarationDocuments]);

  // we want to close the panel if there are no documents
  useEffect(() => {
    activeKeys.forEach((activeKey: string) => {
      if (documents[activeKey as DeclarationDocumentType].length === 0) {
        setActiveKeys(activeKeys.filter((key) => key !== activeKey));
      }
    });
  }, [activeKeys, documents]);

  return (
    <DocumentsContainer>
      <DeclarationTypeTitle>{title}</DeclarationTypeTitle>
      <DeclarationTypeCollapse
        bordered={false}
        expandIcon={({ isActive }) =>
          isActive ? <MinusSquareOutlined /> : <PlusSquareOutlined />
        }
        ghost
        onChange={(key) => setActiveKeys(key as string[])}
        activeKey={activeKeys}
      >
        <Panel
          header="Vorschau-Formular"
          key={DeclarationDocumentType.PREVIEW_FORM}
          collapsible={documents.PREVIEW_FORM?.length ? "header" : "disabled"}
        >
          <DocumentsList
            onRefresh={onRefresh}
            documents={documents.PREVIEW_FORM}
          />
        </Panel>
        <Panel
          header="Eingereichtes Formular"
          key={DeclarationDocumentType.SUBMITTED_FORM}
          collapsible={documents.SUBMITTED_FORM?.length ? "header" : "disabled"}
        >
          <DocumentsList
            onRefresh={onRefresh}
            documents={documents.SUBMITTED_FORM}
          />
        </Panel>
        <Panel
          header="Bescheide"
          key={DeclarationDocumentType.TAX_BILL}
          collapsible={documents.TAX_BILL?.length ? "header" : "disabled"}
        >
          <DocumentsList onRefresh={onRefresh} documents={documents.TAX_BILL} />
        </Panel>
      </DeclarationTypeCollapse>
    </DocumentsContainer>
  );
};

export default DeclarationDocuments;
