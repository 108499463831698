import styled from "styled-components";
import { Input } from "antd";

import { ReactComponent as OpenInNewTabIcon } from "../../../../svgs/open-in-new-tab.svg";

export const Wrapper = styled.div`
  padding-bottom: 20px;
`;

export const Container = styled.div`
  padding: 20px 40px;
  overflow: scroll;
  height: 100%;
  width: 100%;
`;

export const TelenumberInput = styled(Input)`
  width: 200px;
`;

export const OpenPdfIcon = styled(OpenInNewTabIcon)`
  margin-left: 5px;
`;
