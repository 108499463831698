import React from "react";
import { Form, Input, InputNumber, Radio, Select, Typography } from "antd";
import { EuroOutlined } from "@ant-design/icons";

import { HomeOfficeExpensePeriod } from "../../../../../../../../api/graphql/schema.generated";
import LocalAssetsViewer from "../../../../../../../common/LocalAssetsViewer";
import Upload from "../../../../../../../common/Upload";
import { Asset, ILocalAsset } from "../../../../../../../../types";
import { formatAmountInCents } from "../../../../../../../../utils";
import { ExpenseModalContent } from "./styledComponents";
import {
  HOME_OFFICE_EXPENSE_MONTHS_USED_OPTIONS,
  HOME_OFFICE_EXPENSE_PERIOD_OPTIONS,
  HOME_OFFICE_EXPENSE_TYPE_OPTIONS,
  HOME_OFFICE_EXPENSE_VAT_RATE_OPTIONS,
} from "./constants";

const { Text } = Typography;

type HomeOfficeExpenseFormProps = {
  onDropFiles: (files: File[]) => void;
  assets: any[];
  onAssetDelete: (asset: Asset | ILocalAsset) => void;
  totalGrossAmount: number;
  totalNetAmount: number;
  totalVatAmount: number;
};

const HomeOfficeExpenseForm = ({
  onDropFiles,
  assets,
  onAssetDelete,
  totalGrossAmount,
  totalNetAmount,
  totalVatAmount,
}: HomeOfficeExpenseFormProps) => {
  return (
    <ExpenseModalContent>
      <Form.Item
        name="assets"
        rules={[{ required: true, message: "Please upload an invoice" }]}
        className="assets"
      >
        <Upload onDropFiles={onDropFiles} isInPreviewMode={!!assets.length}>
          <LocalAssetsViewer
            assets={assets}
            minHeight={430}
            onDelete={onAssetDelete}
          />
        </Upload>
      </Form.Item>

      <div>
        <Form.Item
          label="Art"
          name="type"
          rules={[{ required: true, message: "Please select type" }]}
        >
          <Select options={HOME_OFFICE_EXPENSE_TYPE_OPTIONS}></Select>
        </Form.Item>

        <Form.Item
          label="Abrechnungszeitraum des Beleg"
          name="period"
          rules={[{ required: true, message: "Please select period" }]}
        >
          <Select options={HOME_OFFICE_EXPENSE_PERIOD_OPTIONS}></Select>
        </Form.Item>

        <Form.Item
          label="Betrag brutto laut Beleg"
          name="amount"
          rules={[
            {
              required: true,
              message: "Please input the amount",
            },
            {
              validator: (_, value) =>
                value && value !== 0
                  ? Promise.resolve()
                  : Promise.reject(new Error("Amount can't be 0")),
            },
          ]}
        >
          <InputNumber
            addonAfter={<EuroOutlined />}
            controls={false}
            placeholder="Enter amount"
            precision={2}
            type="number"
          />
        </Form.Item>

        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.period !== currentValues.period
          }
        >
          {({ getFieldValue }) => (
            <Form.Item
              label="Nutzungszeit Arbeitszimmer"
              name="monthsUsed"
              rules={[
                {
                  required:
                    getFieldValue("period") !==
                    HomeOfficeExpensePeriod.ONE_TIME_PAYMENT,
                  message: "Please select month used",
                },
              ]}
            >
              <Select
                options={HOME_OFFICE_EXPENSE_MONTHS_USED_OPTIONS}
                placeholder="Anzahl Monate auswählen"
                defaultValue={getFieldValue("monthsUsed")}
                disabled
              ></Select>
            </Form.Item>
          )}
        </Form.Item>

        <Form.Item
          label="Umsatzsteuerrate"
          name="vatRate"
          rules={[{ required: true, message: "Please select vat rate" }]}
        >
          <Select
            options={HOME_OFFICE_EXPENSE_VAT_RATE_OPTIONS}
            placeholder="Umsatzsteuerrate auswählen"
          ></Select>
        </Form.Item>
      </div>

      <div>
        <Form.Item
          label="Anteilig auf m² des Arbeitszimmers?"
          name="adjustByOfficeAreaShare"
          rules={[{ required: true }]}
        >
          <Radio.Group
            options={[
              { label: "Ja", value: true },
              { label: "Nein", value: false },
            ]}
            optionType="button"
          />
        </Form.Item>

        <Form.Item label="Beschreibung" name="note">
          <Input placeholder="Verpflichtend" />
        </Form.Item>

        <Form.Item label="Betrag brutto (automatisch)">
          <Text>{formatAmountInCents(totalGrossAmount || 0, true)}</Text>
        </Form.Item>
        <Form.Item label="Betrag netto (automatisch)">
          <Text>{formatAmountInCents(totalNetAmount || 0, true)}</Text>
        </Form.Item>
        <Form.Item label="Betrag Umsatzsteuer (automatisch)">
          <Text>{formatAmountInCents(totalVatAmount || 0, true)}</Text>
        </Form.Item>
      </div>
    </ExpenseModalContent>
  );
};

export default HomeOfficeExpenseForm;
