import React from "react";
import { Col, Divider, Typography } from "antd";

import { TradeTaxDeclarationCalculations } from "../../../../../../api/graphql";
import { TradeTaxDeclarationStatus } from "../../../../../../api/graphql/schema.generated";
import DeclarationStatusChangeModal from "../../../components/DeclarationStatusChangeModal";
import useTaxYearParam from "../../../hooks/useTaxYearParam";
import {
  DATA_SOURCE_LABELS,
  SECTION_TITLES,
  TRADE_TAX_DECLARATION_STATUSES,
  TRADE_TAX_DECLARATION_STATUS_MAPPINGS,
} from "../constants";
import {
  getFinalResultLabel,
  getFormattedAmount,
  getFormattedFinalResult,
  isPositive,
} from "../utils";
import colors from "../../../../../../themes/colors";

const { Title } = Typography;
const { ListRow } = DeclarationStatusChangeModal;
export interface TradeTaxDeclarationStatusChangeModalProps {
  calculations: TradeTaxDeclarationCalculations;
  status: TradeTaxDeclarationStatus;
  onSave: (status: TradeTaxDeclarationStatus) => Promise<void>;
  onClose: () => void;
}

const TradeTaxDeclarationStatusChangeModal = ({
  calculations,
  status,
  onSave,
  onClose,
}: TradeTaxDeclarationStatusChangeModalProps) => {
  const [taxYear] = useTaxYearParam();

  return (
    <DeclarationStatusChangeModal
      title="Gewerbesteuererklärung"
      year={taxYear}
      status={status}
      statusOptions={TRADE_TAX_DECLARATION_STATUSES}
      statusMapping={TRADE_TAX_DECLARATION_STATUS_MAPPINGS}
      onSave={onSave}
      onClose={onClose}
    >
      <ListRow>
        <Col>
          <Title level={4}>{DATA_SOURCE_LABELS.CALCULATION}</Title>
        </Col>
      </ListRow>

      <ListRow>
        <Col flex="1">Gewerbesteuer</Col>
        <Col>{getFormattedAmount(calculations.tradeTaxAmount)}</Col>
      </ListRow>

      <ListRow>
        <Col flex="1">{SECTION_TITLES.TAX_OFFICE_BALANCE}</Col>
        <Col>{getFormattedAmount(calculations.taxOfficeBalance)}</Col>
      </ListRow>

      <Divider className="m-0" />

      <ListRow
        style={{
          color: isPositive(calculations.finalResult)
            ? colors.danger
            : colors.lightGreen,
        }}
      >
        <Col flex="1">{getFinalResultLabel(calculations.finalResult)}</Col>
        <Col>{getFormattedFinalResult(calculations.finalResult)}</Col>
      </ListRow>
    </DeclarationStatusChangeModal>
  );
};

export default TradeTaxDeclarationStatusChangeModal;
