import type * as Types from "../../schema.generated";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type VatAnnualDeclarationSmallBusinessValuesQueryVariables =
  Types.Exact<{
    email: Types.Scalars["String"];
    year: Types.Scalars["Int"];
  }>;

export type VatAnnualDeclarationSmallBusinessValuesQuery = {
  __typename?: "Query";
  vatAnnualDeclaration: {
    __typename?: "VatAnnualDeclaration";
    smallBusinessValues: {
      __typename?: "VatAnnualDeclarationSmallBusinessValues";
      lastYearIncome: number;
      currentYearIncome: number;
      salesTax: {
        __typename?: "VatAnnualDeclarationSection";
        total?: number | null;
        fields: Array<{
          __typename?: "VatAnnualDeclarationField";
          type: Types.VatAnnualDeclarationFieldType;
          netAmount?: number | null;
          vatAmount?: number | null;
          advanceNetAmount?: number | null;
          advanceVatAmount?: number | null;
          transactionFilters: {
            __typename?: "VatAnnualDeclarationFieldTransactionFilters";
            categoryCodes?: Array<string> | null;
            vatCategoryCodes?: Array<string> | null;
          };
        }>;
      };
      additionalInformation: {
        __typename?: "VatAnnualDeclarationSection";
        total?: number | null;
        fields: Array<{
          __typename?: "VatAnnualDeclarationField";
          type: Types.VatAnnualDeclarationFieldType;
          netAmount?: number | null;
          vatAmount?: number | null;
          advanceNetAmount?: number | null;
          advanceVatAmount?: number | null;
          transactionFilters: {
            __typename?: "VatAnnualDeclarationFieldTransactionFilters";
            categoryCodes?: Array<string> | null;
            vatCategoryCodes?: Array<string> | null;
          };
        }>;
      };
    };
  };
};

export const VatAnnualDeclarationSmallBusinessValuesDocument = gql`
  query vatAnnualDeclarationSmallBusinessValues($email: String!, $year: Int!) {
    vatAnnualDeclaration(email: $email, year: $year) {
      smallBusinessValues {
        lastYearIncome
        currentYearIncome
        salesTax {
          fields {
            type
            netAmount
            vatAmount
            advanceNetAmount
            advanceVatAmount
            transactionFilters {
              categoryCodes
              vatCategoryCodes
            }
          }
          total
        }
        additionalInformation {
          fields {
            type
            netAmount
            vatAmount
            advanceNetAmount
            advanceVatAmount
            transactionFilters {
              categoryCodes
              vatCategoryCodes
            }
          }
          total
        }
      }
    }
  }
`;

/**
 * __useVatAnnualDeclarationSmallBusinessValuesQuery__
 *
 * To run a query within a React component, call `useVatAnnualDeclarationSmallBusinessValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useVatAnnualDeclarationSmallBusinessValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVatAnnualDeclarationSmallBusinessValuesQuery({
 *   variables: {
 *      email: // value for 'email'
 *      year: // value for 'year'
 *   },
 * });
 */
export function useVatAnnualDeclarationSmallBusinessValuesQuery(
  baseOptions: Apollo.QueryHookOptions<
    VatAnnualDeclarationSmallBusinessValuesQuery,
    VatAnnualDeclarationSmallBusinessValuesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    VatAnnualDeclarationSmallBusinessValuesQuery,
    VatAnnualDeclarationSmallBusinessValuesQueryVariables
  >(VatAnnualDeclarationSmallBusinessValuesDocument, options);
}
export function useVatAnnualDeclarationSmallBusinessValuesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    VatAnnualDeclarationSmallBusinessValuesQuery,
    VatAnnualDeclarationSmallBusinessValuesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    VatAnnualDeclarationSmallBusinessValuesQuery,
    VatAnnualDeclarationSmallBusinessValuesQueryVariables
  >(VatAnnualDeclarationSmallBusinessValuesDocument, options);
}
export type VatAnnualDeclarationSmallBusinessValuesQueryHookResult = ReturnType<
  typeof useVatAnnualDeclarationSmallBusinessValuesQuery
>;
export type VatAnnualDeclarationSmallBusinessValuesLazyQueryHookResult =
  ReturnType<typeof useVatAnnualDeclarationSmallBusinessValuesLazyQuery>;
export type VatAnnualDeclarationSmallBusinessValuesQueryResult =
  Apollo.QueryResult<
    VatAnnualDeclarationSmallBusinessValuesQuery,
    VatAnnualDeclarationSmallBusinessValuesQueryVariables
  >;
