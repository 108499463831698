import React, { useState } from "react";
import { notification } from "antd";

import TransactionList from "./TransactionList";
import { Transaction } from "../../../../types/transactions";
import { useMatchDocumentToTransactionMutation } from "../../../../api/graphql/mutations/matchEmailDocumentToTransaction";
import { EmailDocument } from "../../../../api/graphql";
import {
  destroyMessage,
  showGraphQlErrorNotification,
  showLoadingMessage,
  showMessage,
} from "../../../../utils";
import { IMessageType } from "../../../../types";
import {
  UPSERT_KONTAX_TRANSACTIONS_MUTATION,
  useUpsertTransactionsMutation,
} from "../../../../api/graphql/mutations/transaction/upsertKontaxTransactions";

const matchingErrorMessage =
  "Error during matching transaction with email document";
const loadingKey = "matching-document-to-transaction";

const TransactionListContainer = ({
  transactions,
  email,
  currentEmailDocument,
  onMatchSuccess,
}: {
  transactions: Transaction[];
  email: string;
  currentEmailDocument: EmailDocument;
  onMatchSuccess: () => void;
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const [matchDocumentToTransactionMutation] =
    useMatchDocumentToTransactionMutation();

  const [upsertKontaxTransactions] = useUpsertTransactionsMutation({
    refetchQueries: [UPSERT_KONTAX_TRANSACTIONS_MUTATION],
  });

  const showMatchingError = () => {
    notification.error({
      message: matchingErrorMessage,
    });
  };

  const handleMatchSuccess = async () => {
    showMessage({
      key: loadingKey,
      type: IMessageType.SUCCESS,
      content: "Beleg successfully matched with Transaction",
    });
    await new Promise((resolve) => setTimeout(resolve, 2000));
    await onMatchSuccess();
  };

  const matchDocumentToTransaction = async (
    transactionId: string,
    skipStartLoading?: boolean
  ) => {
    if (!skipStartLoading) {
      if (isLoading) {
        return;
      }

      setIsLoading(true);
      showLoadingMessage(loadingKey);
    }

    try {
      const result = await matchDocumentToTransactionMutation({
        variables: {
          email,
          emailDocumentId: currentEmailDocument.id,
          transactionId,
        },
      });
      if (!result.data?.matchEmailDocumentToTransaction?.success) {
        showMatchingError();
      } else {
        await handleMatchSuccess();
      }
    } catch (error) {
      showGraphQlErrorNotification(matchingErrorMessage, error);
    } finally {
      setIsLoading(false);
      destroyMessage(loadingKey);
    }
  };

  const bookAndMatchTransaction = async (
    transaction: Transaction,
    vatCategoryCode: string,
    categoryCode: string
  ) => {
    if (isLoading) {
      return;
    }

    if (transaction.verified) {
      showMessage({
        key: loadingKey,
        type: IMessageType.ERROR,
        content: "It is not allowed to recategorize verified transactions",
      });
      return;
    }
    setIsLoading(true);
    showLoadingMessage(loadingKey);
    try {
      await upsertKontaxTransactions({
        variables: {
          transactions: [
            {
              kontistTransactionId: transaction.id,
              vatCategoryCode,
              categoryCode,
            },
          ],
        },
      });
    } catch (error) {
      showGraphQlErrorNotification(
        "Error during updating transaction categories",
        error
      );
      setIsLoading(false);
      destroyMessage(loadingKey);
      return;
    }

    await matchDocumentToTransaction(transaction.id, true);
  };

  return (
    <TransactionList
      transactions={transactions}
      matchDocumentToTransaction={matchDocumentToTransaction}
      bookAndMatchTransaction={bookAndMatchTransaction}
    />
  );
};

export default TransactionListContainer;
