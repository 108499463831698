const colors = {
  lightGrey: "#ccc",
  lightGreyishBlue: "#e0e6ec",
  veryLightGrey: "#eee",
  lightestGray: "#f5f5f5",
  lightestEverGray: "#fafafa",
  midLightGrey: "#e4e4e4",
  midGrey: "#b3b3b3",
  darkGrey: "#9b9b9b",
  veryDarkGreyishViolet: "#4d4b51",
  veryDarkGreyishBlue: "#343a40",
  veryDarkViolet: "#24163a",
  lightGreyOpacity: "rgba(0, 0, 0, 0.45)",
  lightPurpleOpacity: "#e9e1f04d",
  lightGreen: "#9dd5cf",
  darkLimeGreen: "#4caf50",
  lightRed: "#ff9494",
  darkRed: "#dc3545",
  danger: "#FF4D4F",
  reddishOrange: "#e03d00",
  darkOrange: "#c1712b",
  pureOrange: "#f59d00",
  orangeYellow: "#fabb1b",
  lightestPurple: "#f9f8fd",
  lighterPurple: "#e9e1f0",
  lightPurple: "#ebe7f7",
  purple: "#5a2fb1",
  darkPurple: "#180b32",
  midPurple: "#3f1e8a",
  lightBlue: "hsl(200, 75%, 75%)",
  white: "#fff",
  antiFlashWhite: "#f0f0f0",
  black: "#000",
  almostBlack: "#24163A",
  darkBlack: "rgba(0, 0, 0, 0.5)",
  veryLightGreyOpacity: "rgba(0, 0, 0, 0.25)",
  primaryPurple: "#5B2FB1",
  veryLightPurple: "#d8cfe3",
  cyan: "#348288",
  lightCyan: "#bcdede",
  veryLightCyan: "#e7f3f3",
  pink: "#ffc0cb",
};

export default colors;
