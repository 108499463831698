import { Checkbox, Space } from "antd";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import React from "react";

export interface CheckboxGroupOption {
  label: string;
  value: any;
  isSelected?: boolean;
}

const CheckboxGroup = ({
  options,
  onChange,
}: {
  options: Array<CheckboxGroupOption>;
  onChange: (checkedValue: Array<CheckboxValueType>) => void;
}) => {
  return (
    <Checkbox.Group
      onChange={onChange}
      value={options.filter((o) => o.isSelected).map((o) => o.value)}
    >
      <Space direction="vertical">
        {options.map(({ label, value }, index) => (
          <Checkbox
            data-test={`${value}-checkbox`}
            value={value}
            key={`${value}-${index}`}
          >
            {label}
          </Checkbox>
        ))}
      </Space>
    </Checkbox.Group>
  );
};

export default CheckboxGroup;
