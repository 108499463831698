import React, { useRef, useState } from "react";
import { Input, Button, Row, Col, InputRef } from "antd";
import { useTranslation } from "react-i18next";

import useSearchHotkey from "../../../../hooks/useSearchHotkey";
import { MISSING_TRANSLATION } from "../../../../../i18n";

const { Search } = Input;

export interface UserFilterOptions {
  search: string;
  all: boolean;
  myCases?: boolean;
}

const UserFilters = ({
  filterOptions,
  setFilterOptions,
  withoutLoadAll = false,
  withMyCases = false,
}: {
  filterOptions: UserFilterOptions;
  setFilterOptions: (filterOptions: Partial<UserFilterOptions>) => void;
  withoutLoadAll?: boolean;
  withMyCases?: boolean;
}) => {
  const { search } = filterOptions;
  const [searchValue, setSearchValue] = useState(search);
  const { t } = useTranslation();

  const searchRef = useRef<InputRef>(null);

  useSearchHotkey(searchRef);

  return (
    <Row gutter={8} style={{ marginTop: 10 }}>
      <Col flex="auto" xl={8}>
        <Search
          ref={searchRef}
          value={searchValue}
          onChange={(e) => {
            setSearchValue(e.target.value);
          }}
          onSearch={(value: string) => {
            setFilterOptions({
              search: value,
              all: false,
              myCases: false,
            });
          }}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
            }
          }}
          id="search"
          data-test="searchBar"
          placeholder={t("userFilterPlaceholder") || MISSING_TRANSLATION}
          allowClear
          enterButton="Filter"
        />
      </Col>
      {!withoutLoadAll && (
        <Col>
          <Button
            type="default"
            data-test="loadAll"
            onClick={(event) => {
              event.preventDefault();
              setFilterOptions({ all: true, search: "", myCases: false });
            }}
          >
            Load all
          </Button>
        </Col>
      )}
      {withMyCases && (
        <Col>
          <Button
            type="default"
            data-test="myCases"
            onClick={(event) => {
              event.preventDefault();
              setFilterOptions({
                all: false,
                search: searchValue,
                myCases: true,
              });
            }}
          >
            {t("ctaMyTasks")}
          </Button>
        </Col>
      )}
    </Row>
  );
};

export default UserFilters;
