import { ChangeEvent, useState } from "react";
import { Button, notification, Select } from "antd";

import { useUpdateTransactionFeedbackMutation } from "../../../api/graphql";
import { TaxAdvisorProof } from "../../../api/graphql/schema.generated";
import {
  FormLabel,
  CommentInput,
  SaveButtonContainer,
} from "./styledComponents";

interface FeedbackFormProps {
  transactionId: string;
  refetch: () => void;
}

const FeedbackForm = ({ transactionId, refetch }: FeedbackFormProps) => {
  const [fibuCategoryComment, setFibuCategoryComment] = useState<string>("");
  const [vatCategoryComment, setVatCategoryComment] = useState<string>("");
  const [taxAdvisorProof, setTaxAdvisorProof] =
    useState<TaxAdvisorProof | null>(null);
  const [isCommentRequired, setIsCommentRequired] = useState<boolean>(false);

  const [updateTransactionFeedback, { loading: isUpdating }] =
    useUpdateTransactionFeedbackMutation();

  const updateFeedback = async () => {
    if (!taxAdvisorProof) {
      return;
    }

    if (
      taxAdvisorProof !== TaxAdvisorProof.OKAY &&
      !(fibuCategoryComment && vatCategoryComment)
    ) {
      notification.warn({
        message:
          "If tax advisor proof is not OKAY, both comment fields are required.",
      });
      setIsCommentRequired(true);
      return;
    }

    try {
      await updateTransactionFeedback({
        variables: {
          transactionId,
          feedback: {
            fibuCategoryComment,
            vatCategoryComment,
            taxAdvisorProof,
          },
        },
      });

      setIsCommentRequired(false);
      setFibuCategoryComment("");
      setVatCategoryComment("");
      setTaxAdvisorProof(null);

      await refetch();
    } catch (error) {
      notification.error({ message: "Failed to save feedback data." });
    }
  };

  return (
    <div>
      <FormLabel>Fibu category comment</FormLabel>
      <CommentInput
        name="fibuCategoryComment"
        value={fibuCategoryComment}
        onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
          setFibuCategoryComment(event.target.value)
        }
        data-testid={`fibu-category-comment-${transactionId}`}
        disabled={isUpdating}
        status={isCommentRequired && !fibuCategoryComment ? "error" : ""}
      />
      <FormLabel>Vat category comment</FormLabel>
      <CommentInput
        name="vatCategoryComment"
        value={vatCategoryComment}
        onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
          setVatCategoryComment(event.target.value)
        }
        data-testid={`vat-category-comment-${transactionId}`}
        disabled={isUpdating}
        status={isCommentRequired && !vatCategoryComment ? "error" : ""}
      />
      <FormLabel>Tax advisor proof</FormLabel>
      <Select
        style={{ width: 520 }}
        value={taxAdvisorProof}
        id="taxAdvisorProof"
        onChange={(value: TaxAdvisorProof) => setTaxAdvisorProof(value)}
        options={[
          {
            value: TaxAdvisorProof.OKAY,
            label: "Okay",
          },
          {
            value: TaxAdvisorProof.NOT_OKAY,
            label: "Not Okay",
          },
          {
            value: TaxAdvisorProof.PARTIAL,
            label: "Partial",
          },
        ]}
        data-testid={`tax-advisor-proof-${transactionId}`}
        disabled={isUpdating}
      />
      <SaveButtonContainer>
        <Button
          data-testid="feedback-form-submit-button"
          type="primary"
          onClick={updateFeedback}
          disabled={isUpdating || !taxAdvisorProof}
        >
          Save and go to next transaction
        </Button>
      </SaveButtonContainer>
    </div>
  );
};

export default FeedbackForm;
