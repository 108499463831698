import type * as Types from "../schema.generated";

import { gql } from "@apollo/client";
export type DeclarationDocumentFragment = {
  __typename?: "DeclarationDocument";
  id: string;
  documentType: Types.DeclarationDocumentType;
  createdAt: string;
  assets: Array<{
    __typename?: "Asset";
    id: string;
    name: string;
    filetype: string;
    thumbnail: string;
    fullsize: string;
  }>;
};

export const DeclarationDocumentFragmentDoc = gql`
  fragment DeclarationDocument on DeclarationDocument {
    id
    documentType
    createdAt
    assets {
      id
      name
      filetype
      thumbnail
      fullsize
    }
  }
`;
