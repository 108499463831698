import React, { useState } from "react";
import { Button } from "antd";
import styled from "styled-components";

import LocalAssetsViewer from "../../common/LocalAssetsViewer";
import { Asset } from "../../../types";
import { EmailDocument } from "../../../api/graphql";
import { useDeleteEmailDocumentMutation } from "../../../api/graphql/mutations/deleteEmailDocument";
import DeleteButton from "../../common/DeleteButton";
import { texts } from "../../common/DeleteAssetButton";

import {
  destroyMessage,
  showErrorMessage,
  showSuccessMessage,
  showLoadingMessage,
} from "../../../utils";

const AssetPreview = styled.div`
  display: flex;
  flex: 1;
  position: relative;
`;

const NextButton = styled(Button)`
  position: absolute;
  right: 5px;
  bottom: 5px;
`;

const ButtonWrapper = styled.div`
  position: absolute;
  margin: 90px 0 0 50px;
`;

export const getAsset = (emailDocument: EmailDocument) =>
  ({
    id: emailDocument.id,
    fullsize: emailDocument.url,
    filetype: emailDocument?.filename.split(".").pop(),
  } as Asset);

const Preview = ({
  emailDocument,
  fetchNext,
}: {
  emailDocument: EmailDocument;
  fetchNext: () => void;
}) => {
  const [deleteEmailDocumentMutation] = useDeleteEmailDocumentMutation();
  const [isLoading, setIsLoading] = useState(false);

  const deleteEmailDocument = async () => {
    const DELETION_KEY = "delete-email-document";

    setIsLoading(true);
    try {
      showLoadingMessage(DELETION_KEY);
      const response = await deleteEmailDocumentMutation({
        variables: {
          id: emailDocument.id,
        },
      });

      if (!response.data?.deleteEmailDocument.success) {
        throw new Error("Error during deletion of email document");
      }
      showSuccessMessage("Deletion successful");
      fetchNext();
    } catch (error) {
      showErrorMessage("Error during deletion");
    } finally {
      destroyMessage(DELETION_KEY);
      setIsLoading(false);
    }
  };

  return (
    <AssetPreview>
      <LocalAssetsViewer assets={[getAsset(emailDocument)]} minHeight={850} />
      <NextButton data-test="nextBtn" type="primary" onClick={fetchNext}>
        Next
      </NextButton>
      <ButtonWrapper>
        <DeleteButton
          isLoading={isLoading}
          handleConfirm={deleteEmailDocument}
          title={texts.title}
          okText={texts.okText}
          cancelText={texts.cancelText}
        />
      </ButtonWrapper>
    </AssetPreview>
  );
};

export default Preview;
