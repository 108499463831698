import type * as Types from "../schema.generated";

import { gql } from "@apollo/client";
export type DeclarationDeclineFragment = {
  __typename?: "DeclarationDecline";
  reason: string;
  createdAt: string;
};

export const DeclarationDeclineFragmentDoc = gql`
  fragment DeclarationDecline on DeclarationDecline {
    reason
    createdAt
  }
`;
