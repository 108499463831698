import isNumber from "lodash/isNumber";

import { DEFAULT_CURRENCY, DEFAULT_LOCALE } from "../constants";

export const currencyFormatter = new Intl.NumberFormat(DEFAULT_LOCALE, {
  style: "currency",
  currency: DEFAULT_CURRENCY,
});

export const roundCents = (value: number): number =>
  parseFloat(value.toFixed(2));

export const formatAmount = (
  amount: number,
  locale = DEFAULT_LOCALE
): string => {
  return `${Intl.NumberFormat(locale, {
    minimumFractionDigits: 2,
  }).format(amount)}`;
};

export const formatAmountCurrency = (
  number: number | undefined,
  options: Intl.NumberFormatOptions = {}
) => {
  if (typeof number === "undefined") return "";
  return new Intl.NumberFormat(DEFAULT_LOCALE, {
    style: "currency",
    currency: "EUR",
    ...options,
  }).format(number);
};

export const formatIntegerAmountCurrency = (
  amountInCents: number | undefined
) => {
  if (amountInCents == null) {
    return "";
  }
  const amountInEuro = amountInCents / 100;
  return formatAmountCurrency(amountInEuro, { maximumFractionDigits: 0 });
};

export const formatAmountInCents = (
  amountInCents: number,
  showCurrency: boolean = false,
  locale?: string
): string => {
  const amountInEuro = amountInCents / 100;
  return showCurrency
    ? formatAmountCurrency(amountInEuro)
    : formatAmount(amountInEuro, locale);
};

/**
 * Format amount into DE format, for example: 5.000,00 €
 */
export const formatDEAmount = (amount?: number): string | undefined =>
  isNumber(amount) ? formatAmountInCents(amount!, true) : undefined;

/**
 * Parse amount in string into number.
 * Note:
 * - Users can enter any amount like 5.000,00, 500000, or 5000,00.
 * - If the amount is invalid, it will return 0 instead of NaN.
 */
export const parseAmount = (amount: string): number => {
  // Remove all non-integer characters.
  const num = amount.replace(/[^0-9-]/g, "");
  const parsedNum = parseInt(num, 10);
  return isNaN(parsedNum) ? 0 : parsedNum;
};

/**
 * Format the input value in to currency.
 * Examples:
 * - "100,95 €" => "100,95 €"
 * - "100,95" => "100,95 €"
 * - "100,9" => "100,90 €"
 * - "100" => "100,00 €"
 * - "100.00" => "10.000,00 €"
 * - "abc" => "0,00 €"
 * - "" => ""
 * It also supports negative amount
 * - "-100,9" => "-100,90 €"
 */
export const formatAmountToCurrency = (
  amount: string,
  negativeEnabled?: boolean
): string => {
  if (amount === "") {
    return "";
  }

  // If the first character is "-", consider it's negative amount
  const isNegative = negativeEnabled ? amount.charAt(0) === "-" : false;
  const amountParts = amount.split(",");

  let parsedAmount;

  if (amountParts.length < 2) {
    parsedAmount = parseAmount(`${amount}00`);
  } else {
    let [euros, cents] = amountParts.map((amount: string): string =>
      amount.replace(/[^0-9]/g, "")
    );

    // Make sure number of cents to be 2.
    cents = `${cents}0`.substr(0, 2);

    parsedAmount = parseAmount(`${euros}${cents}`);
  }

  // Only apply negative prefix if parsedAmount !== 0
  // Otherwise - 0,00 € doesn't make any sense.
  const prefix = isNegative && parsedAmount > 0 ? "-" : "";

  return isNumber(parsedAmount) ? prefix + formatDEAmount(parsedAmount)! : "";
};

const isNumeric = (param: string | number) => {
  const paramType = typeof param;
  const restrictedTypes = [
    "undefined",
    "object",
    "boolean",
    "symbol",
    "function",
    "bigint",
  ];
  const allowedTypes = ["number"];
  if (restrictedTypes.includes(paramType)) return false;
  if (allowedTypes.includes(paramType)) return true;
  if (paramType === "string") {
    if (String(param).trim() === "") return false;
    return !isNaN(Number(param));
  }
  return false;
};

export const eurosToCents = (euros: number | string): number => {
  if (!euros || !isNumeric(euros)) return 0;
  return Number(`${euros}e2`);
};

/**
 * Converts the given amount from cents to Euros by dividing it by 100.
 * If number is undefined it returns 0 as default value.
 */
export const centsToEuros = (amount?: number): number =>
  amount ? roundCents(amount / 100) : 0;

/**
 * Takes a number in cents and rounds it down to the nearest euro.
 * @param  {number} value Amount in cents
 * @returns {number} Rounded down amount in cents
 */

export const roundDownCents = (value: number): number =>
  Math.floor(value / 100) * 100;
