import React, { useState, useEffect, useCallback } from "react";
import { Button } from "antd";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";

import { ITransaction, TransactionSplit } from "../../types";
import TransactionSplitView from "./TransactionSplitView";
import { isSplitValid } from "./helpers";
import { TransactionSplitState } from "./types";
import BusinessAssetConfirmationModal from "../BusinessAsset/BusinessAssetConfirmationModal";
import {
  SplitsWrapper,
  SplitButtonsWrapper,
  RemainingAmount,
  RemainingAmountBox,
  RemainingTitle,
} from "./styledComponents";
import AntDeletePopupConfirm from "../common/AntDeletePopupConfirm";
import { formatAmountInCents, parseAmount } from "../../utils/amount";
import { UserBusinessAssetsLink } from "../pages/TransactionView/UserBusinessAssetsLink";
import useMountedState from "../hooks/useMountedState";

const EMPTY_STATE: TransactionSplitState = {
  isNew: false,
  splits: [],
  isValid: isSplitValid([]),
  remainingAmount: "0,00 €",
};

const TransactionSplitContainer = ({
  transaction,
  isEscalateClicked,
  isNextClicked,
  showBusinessAssetConfirmationModal,
  disableBusinessAssetFeatures,
  onHideBusinessAssetConfirmationModal,
  onDelete,
  onChange,
  onSubmit,
  width,
}: {
  transaction: ITransaction;
  isEscalateClicked?: boolean;
  isNextClicked?: boolean;
  showBusinessAssetConfirmationModal?: boolean;
  disableBusinessAssetFeatures?: boolean;
  onHideBusinessAssetConfirmationModal?: () => void;
  onCreate?: (state: TransactionSplitState) => void;
  onChange?: (state: TransactionSplitState) => void;
  onSubmit?: (state: TransactionSplitState) => void;
  onDelete?: (state: TransactionSplitState) => Promise<boolean>;
  width?: string;
}) => {
  const isMounted = useMountedState();
  const [state, setState] = useState<TransactionSplitState>(EMPTY_STATE);

  useEffect(() => {
    const newState = transaction.splits?.length
      ? {
          isNew: false,
          splits: transaction.splits.map((split) => ({ ...split })),
          isValid: isSplitValid(transaction.splits),
        }
      : {
          isNew: true,
          splits: [{ amount: transaction.amount }, { amount: 0 }],
          isValid: false,
        };

    setState(newState);
    onChange?.(newState);
    // we don't want this effect to run if `onChange` callback is changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transaction.splits, disableBusinessAssetFeatures, transaction.amount]);

  const onDeleteHandler = useCallback(async () => {
    if (!onDelete || (await onDelete(EMPTY_STATE))) {
      if (isMounted()) {
        setState(EMPTY_STATE);
      }
    }
  }, [isMounted, onDelete]);

  const calculateRemainingAmount = (
    updatedSplits: Partial<TransactionSplit>[]
  ) => {
    let remainingAmount = 0;
    if (updatedSplits.length >= 3) {
      const splitsSum = updatedSplits.reduce(
        (acc, item) => acc + (item.amount || 0),
        0
      );
      remainingAmount = transaction.amount - splitsSum;
    }
    return remainingAmount;
  };

  const handleTransactionSplitChange = (
    split: Partial<TransactionSplit>,
    index: number
  ) => {
    const updatedSplits = [...state.splits];

    if (updatedSplits.length < 3) {
      const secondSplit = updatedSplits.filter((split, i) => i !== index)[0];

      if (secondSplit && split?.amount) {
        secondSplit.amount = transaction.amount - split.amount;
      }
      updatedSplits.forEach((item, i) => {
        if (i === index) {
          return split;
        } else {
          return secondSplit;
        }
      });
    }

    updatedSplits[index] = split;

    const newState = {
      ...state,
      splits: updatedSplits,
      isValid: isSplitValid(updatedSplits, transaction.amount),
      remainingAmount: formatAmountInCents(
        calculateRemainingAmount(updatedSplits),
        true
      ),
    };
    setState(newState);
    onChange && onChange(newState);
  };

  const handleSplitDelete = (index: number) => {
    const updatedSplits = [...state.splits];
    updatedSplits.splice(index, 1);
    if (updatedSplits.length === 2) {
      updatedSplits[0].amount = transaction.amount;
      updatedSplits[1].amount = 0;
    }
    const newState = {
      ...state,
      splits: updatedSplits,
      isValid: isSplitValid(updatedSplits, transaction.amount),
      remainingAmount: formatAmountInCents(
        calculateRemainingAmount(updatedSplits),
        true
      ),
    };
    setState(newState);
    onChange && onChange(newState);
  };

  const handleSplitAdd = () => {
    const updatedSplits = [...state.splits, { amount: 0 }];
    let remainingAmount = state.remainingAmount;
    if (updatedSplits.length === 3) {
      remainingAmount = formatAmountInCents(updatedSplits[1].amount || 0, true);
      updatedSplits[1].amount = 0;
    }

    const newState = {
      ...state,
      splits: updatedSplits,
      isValid: isSplitValid(updatedSplits, transaction.amount),
      remainingAmount,
    };
    setState(newState);
    onChange && onChange(newState);
  };

  const handleUseRemaining = () => {
    const splitIndex = state.splits.findIndex((split) => split.amount === 0);
    const updatedSplits = [...state.splits];
    updatedSplits[splitIndex] = {
      amount: parseAmount(state.remainingAmount || "0"),
    };

    const newState = {
      ...state,
      splits: updatedSplits,
      isValid: isSplitValid(updatedSplits, transaction.amount),
      remainingAmount: formatAmountInCents(
        calculateRemainingAmount(updatedSplits),
        true
      ),
    };
    setState(newState);
    onChange && onChange(newState);
  };

  const isTransactionVerified = transaction.verified;
  const isFullySplit =
    state.remainingAmount && parseAmount(state.remainingAmount) === 0;
  const isUseRemainingButtonVisible =
    !isFullySplit &&
    state.splits.filter((split) => split.amount === 0).length === 1;
  const isRemainingAmountVisible =
    state.remainingAmount && state.splits.length > 2;

  return (
    <SplitsWrapper style={{ width: width }}>
      <SplitButtonsWrapper>
        <AntDeletePopupConfirm
          title={`Are you sure you want to discard ${state.splits?.length} splits?`}
          onDelete={onDeleteHandler}
          placement="bottomRight"
        >
          <Button
            icon={<CloseOutlined style={{ color: "red" }} />}
            size="large"
            style={{ display: "flex", alignItems: "center" }}
            data-test="transactionSplit.deleteButton"
            disabled={isTransactionVerified}
          >
            Discard split
          </Button>
        </AntDeletePopupConfirm>
        {onSubmit && (
          <Button
            size="large"
            htmlType="submit"
            type="primary"
            disabled={!state.isValid || isTransactionVerified}
            onClick={() => onSubmit(state)}
            data-test="transactionSplit.submitButton"
          >
            Save
          </Button>
        )}
      </SplitButtonsWrapper>
      <TransactionSplitView
        splits={state.splits}
        transaction={transaction}
        isEscalateClicked={isEscalateClicked}
        isNextClicked={isNextClicked}
        onChangeHandler={handleTransactionSplitChange}
        disableBusinessAssetFeatures={disableBusinessAssetFeatures}
        onDeleteHandler={handleSplitDelete}
        isTransactionVerified={isTransactionVerified}
      />
      {showBusinessAssetConfirmationModal &&
        onHideBusinessAssetConfirmationModal && (
          <BusinessAssetConfirmationModal
            show={showBusinessAssetConfirmationModal}
            onHide={onHideBusinessAssetConfirmationModal}
          />
        )}
      <SplitButtonsWrapper>
        {transaction.businessAssets?.length ? (
          <UserBusinessAssetsLink email={transaction.email} />
        ) : null}
        <Button
          icon={<PlusOutlined />}
          size="large"
          style={{
            display: "flex",
            alignItems: "center",
            border: "1px dashed #D9D9D9",
          }}
          onClick={handleSplitAdd}
          disabled={isTransactionVerified}
          data-test="transactionSplit.addButton"
        >
          Add a split
        </Button>
      </SplitButtonsWrapper>

      {isRemainingAmountVisible && (
        <RemainingAmountBox
          className={isFullySplit ? "green" : ""}
          data-test="transactionSplit.remainingAmountBox"
        >
          <RemainingTitle>
            {isFullySplit ? "Fully split" : "Remaining"}
          </RemainingTitle>
          <RemainingAmount
            className={isFullySplit ? "green" : ""}
            data-test="transactionSplit.remainingAmount"
          >
            {state.remainingAmount}
          </RemainingAmount>
          {isUseRemainingButtonVisible && (
            <Button
              data-test="transactionSplit.useRemainingButton"
              type="primary"
              style={{ marginTop: "17px" }}
              onClick={handleUseRemaining}
            >
              Use remaining
            </Button>
          )}
        </RemainingAmountBox>
      )}
    </SplitsWrapper>
  );
};

export default TransactionSplitContainer;
