import { DatevRawData } from "../../../../../../../api/graphql";
import { DEPRECIATION } from "../constants";
import { buKey as buKeyCopies } from "../data";
import isDateAfter from "./isDateAfter";

type CombinedAccountInfo = {
  account: string;
  offsetAccount: string;
};

const combineAccountInfo = (
  first: DatevRawData,
  second: DatevRawData
): CombinedAccountInfo | null => {
  if (!second) {
    return null;
  }

  // choose account and offsetAccount that are not repeated in both transaction.
  if (first.offsetAccount === second.account) {
    return {
      account: first.account,
      offsetAccount: second.offsetAccount,
    };
  }

  if (first.account === second.offsetAccount) {
    return {
      account: second.account,
      offsetAccount: first.offsetAccount,
    };
  }

  return null;
};

const mergeTransactions = (
  first: DatevRawData,
  second: DatevRawData,
  combinedAccountInfo: CombinedAccountInfo
): DatevRawData => {
  return {
    ...combinedAccountInfo,
    amount: first.amount,
    direction: first.direction,
    meta1: first.meta1,
    description: `${first.description}, ${second.description}`,
    date: isDateAfter(first.date, second.date) ? first.date : second.date,
    buKey: (buKeyCopies as Record<string, any>)[first.buKey]
      ? first.buKey
      : second.buKey,
    ...(Boolean(first.receiptName) ? { receiptName: first.receiptName } : {}),
    ...(Boolean(second.receiptName) ? { receiptName: second.receiptName } : {}),
  };
};

const notDepreciation = (description: string) =>
  !description.toLowerCase().includes(DEPRECIATION);

const combineTransactions = (parsed: DatevRawData[]): DatevRawData[] => {
  const sorted = parsed.sort((a, b) => a.meta1.localeCompare(b.meta1));
  const combined: DatevRawData[] = [];

  let rmIdx = -1;

  for (let idx = 0; idx < sorted.length; idx += 1) {
    if (rmIdx !== idx) {
      const nextIdx = idx + 1;
      const current = sorted[idx];
      const next = sorted[nextIdx];
      const combinedAccountInfo = combineAccountInfo(current, next);

      const shouldMerge =
        next &&
        current.meta1 !== "" && // meta1 must not be empty
        current.meta1 === next.meta1 &&
        current.amount === next.amount &&
        current.direction === next.direction &&
        combinedAccountInfo &&
        // Both description shouldn't have the text Abschreibung
        notDepreciation(current.description) &&
        notDepreciation(next.description);

      // merge the current transaction with next transaction
      if (shouldMerge) {
        combined.push(mergeTransactions(current, next, combinedAccountInfo!));
        rmIdx = nextIdx; // skip the next item because it was merged
      } else {
        combined.push(current);
      }
    }
  }

  return combined;
};

export default combineTransactions;
