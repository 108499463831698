/**
 * Represents where bookkeping is done.
 * @enum {string}
 * @see {@link https://kontist.atlassian.net/browse/WANN-268}
 * @see {@link https://github.com/kontist/kontax-web/pull/1490}
 */
export enum AccountingSourceType {
  /** no status */
  NO_STATUS = "NO_STATUS",
  /** komplett Kontist */
  COMPLETELY_IN_KONTIST = "COMPLETELY_IN_KONTIST",
  /** extern lexoffice/debitor/sevdesk */
  EXTERNAL_VIA_LEXOFFICE_DEBITOR_SEVDESK = "EXTERNAL_VIA_LEXOFFICE_DEBITOR_SEVDESK",
  /** extern komplett Vorberater */
  EXTERNAL_VIA_VORBERATER = "EXTERNAL_VIA_VORBERATER",
  /** extern manuell */
  EXTERNAL_MANUAL = "EXTERNAL_MANUAL",
  /** Kontist/Vorberater */
  PARTIALLY_IN_KONTIST_AND_VIA_VORBERATER = "PARTIALLY_IN_KONTIST_AND_VIA_VORBERATER",
  /** Kontist/Lexoffice/debitor/sevdesk */
  PARTIALLY_IN_KONTIST_AND_VIA_LEXOFFICE_DEBITOR_SEVDESK = "PARTIALLY_IN_KONTIST_AND_VIA_LEXOFFICE_DEBITOR_SEVDESK",
  /** Kontist/manuell */
  PARTIALLY_IN_KONTIST_AND_MANUAL = "PARTIALLY_IN_KONTIST_AND_MANUAL",
}

/**
 * Represents where bookkeping is done for a specific year.
 * @interface
 */
export interface AccountingSource {
  /**
   * The year bookkeping is done
   */
  year: number;

  /**
   * Represents where bookkeeping is done
   */
  type: AccountingSourceType;
}

/**
 * The payload used to upsert (create or update) an accounting source
 * @interface
 */
export interface UpsertAccountingSourcePayload {
  /**
   * Represents where bookkeeping is done
   */
  type: AccountingSourceType;

  /**
   * The year bookkeping is done
   */
  year: number;
}
