import type * as Types from "../../schema.generated";

import { gql } from "@apollo/client";
import { QuestionnaireDocumentFragmentDoc } from "../../fragments/questionnaireDocument.generated";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type QuestionnaireQueryVariables = Types.Exact<{
  email: Types.Scalars["String"];
  type: Types.QuestionnaireType;
  year: Types.Scalars["Int"];
  questionnaireId?: Types.InputMaybe<Types.Scalars["ID"]>;
}>;

export type QuestionnaireQuery = {
  __typename?: "Query";
  questionnaire?: {
    __typename?: "Questionnaire";
    context?: any | null;
    status: Types.QuestionnaireStatus;
    syncedAt?: string | null;
    startedAt?: string | null;
    completedAt?: string | null;
    questions: Array<{
      __typename?: "QuestionnaireQuestionWithAnswer";
      name: string;
      topic?: string | null;
      inputConfig?: Record<string, unknown> | null;
      answer?: {
        __typename?: "QuestionnaireAnswer";
        value?: any | null;
        submittedAt?: string | null;
        postponedAt?: string | null;
        documentsStatus: Types.QuestionnaireAnswerDocumentsStatus;
        syncedAt?: string | null;
      } | null;
    }>;
    documents: Array<{
      __typename?: "QuestionnaireDocument";
      id: string;
      type: Types.QuestionnaireDocumentType;
      inputs?: any | null;
      updatedAt: string;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        name: string;
        filetype: string;
        thumbnail: string;
        fullsize: string;
      }>;
    }>;
  } | null;
};

export const QuestionnaireDocument = gql`
  query questionnaire(
    $email: String!
    $type: QuestionnaireType!
    $year: Int!
    $questionnaireId: ID
  ) {
    questionnaire(
      email: $email
      type: $type
      year: $year
      questionnaireId: $questionnaireId
    ) {
      questions {
        name
        topic
        inputConfig
        answer {
          value
          submittedAt
          postponedAt
          documentsStatus
          syncedAt
        }
      }
      context
      documents {
        ...questionnaireDocument
      }
      status
      syncedAt
      startedAt
      completedAt
    }
  }
  ${QuestionnaireDocumentFragmentDoc}
`;

/**
 * __useQuestionnaireQuery__
 *
 * To run a query within a React component, call `useQuestionnaireQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuestionnaireQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuestionnaireQuery({
 *   variables: {
 *      email: // value for 'email'
 *      type: // value for 'type'
 *      year: // value for 'year'
 *      questionnaireId: // value for 'questionnaireId'
 *   },
 * });
 */
export function useQuestionnaireQuery(
  baseOptions: Apollo.QueryHookOptions<
    QuestionnaireQuery,
    QuestionnaireQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QuestionnaireQuery, QuestionnaireQueryVariables>(
    QuestionnaireDocument,
    options
  );
}
export function useQuestionnaireLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QuestionnaireQuery,
    QuestionnaireQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QuestionnaireQuery, QuestionnaireQueryVariables>(
    QuestionnaireDocument,
    options
  );
}
export type QuestionnaireQueryHookResult = ReturnType<
  typeof useQuestionnaireQuery
>;
export type QuestionnaireLazyQueryHookResult = ReturnType<
  typeof useQuestionnaireLazyQuery
>;
export type QuestionnaireQueryResult = Apollo.QueryResult<
  QuestionnaireQuery,
  QuestionnaireQueryVariables
>;
