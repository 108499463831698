import { Modal, Row } from "antd";
import styled from "styled-components";

export const ActivityTrackingContainer = styled.div`
  margin-top: 50px;
`;

export const StyledModal = styled(Modal)`
  .ant-modal-body {
    padding: 0;
  }
`;

export const SectionRow = styled(Row)`
  padding: 24px;
`;
