import React, { FC, useState, useEffect, useMemo } from "react";
import { Button, Space, notification, Alert, Typography, Form } from "antd";

import { AnschreibenType } from "../../../../api/graphql/schema.generated";
import { useAnschreibenQuery } from "../../../../api/graphql/queries/anschreiben/anschreiben.generated";
import { useAnschreibenTemplateLazyQuery } from "../../../../api/graphql/queries/anschreiben/anschreibenTemplate.generated";
import { useCreateAnschreibenMutation } from "../../../../api/graphql/mutations/anschreiben/createAnschreiben.generated";
import { IUser } from "../../../../types";
import { reportError } from "../../../../sentry";
import TopBar from "../TopBar/AnschreibenTopBar";
import * as texts from "../texts";
import { Wrapper, TelenumberInput } from "./styledComponents";
import { profitAndLossData, otherThingsData } from "./data";
import Checkboxes from "./Checkboxes";
import AnschreibenForm from "./AnschreibenForm";
import { getInitialType } from "./utils";

const { Title } = Typography;

type AnschreibenProps = {
  user: IUser;
};

const Anschreiben: FC<AnschreibenProps> = ({ user }) => {
  const [year, setYear] = useState<number>(2020);
  const [type, setType] = useState<AnschreibenType | null>(
    getInitialType(user)
  );
  const [language, setLanguage] = useState<string>(user.language || "de");
  const [eurTelenumber, setEurTelenumber] = useState<string>("");
  const [usteTelenumber, setUsteTelenumber] = useState<string>("");
  const [esteTelenumber, setEsteTelenumber] = useState<string>("");
  const [gewsteTelenumber, setGewsteTelenumber] = useState<string>("");
  const [selectedProfitAndLoss, setSelectedProfitAndLoss] = useState<string[]>(
    []
  );
  const [selectedOtherThings, setSelectedOtherThings] = useState<string[]>([]);
  const [esteTelenumberInvalid, setEsteTelenumberInvalid] =
    useState<boolean>(false);

  const {
    data: anschreibenData,
    loading: loadingAnschreiben,
    refetch: refetchAnschreiben,
  } = useAnschreibenQuery({
    variables: {
      year,
      email: user.email,
    },
  });

  const [saveAnschreiben] = useCreateAnschreibenMutation();
  const [getTemplate, { data: templateData, loading: loadingTemplate }] =
    useAnschreibenTemplateLazyQuery();

  useEffect(() => {
    if (
      anschreibenData && // make sure we fetch the anschreiben first
      !anschreibenData.anschreiben &&
      year &&
      type &&
      language
    ) {
      getTemplate({
        variables: {
          type,
          year,
          language,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, year, language, anschreibenData]);

  // reset the form when changing language
  useEffect(() => {
    setSelectedProfitAndLoss([]);
    setSelectedOtherThings([]);
  }, [language]);

  useEffect(() => {
    if (user.kontaxUser?.tradeTax) {
      setType(getInitialType(user));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.kontaxUser]);

  const profitAndLossOptions: string[] = useMemo(
    () => (language === "en" ? profitAndLossData.en : profitAndLossData.de),
    [language]
  );

  const otherThingsOptions: string[] = useMemo(
    () => (language === "en" ? otherThingsData.en : otherThingsData.de),
    [language]
  );

  const resetForm = () => {
    setEurTelenumber("");
    setUsteTelenumber("");
    setEsteTelenumber("");
    setGewsteTelenumber("");
    setSelectedProfitAndLoss([]);
    setSelectedOtherThings([]);
    setEsteTelenumberInvalid(false);
  };

  const generate = async () => {
    setEsteTelenumberInvalid(!esteTelenumber);

    if (!type || !esteTelenumber) {
      return;
    }

    try {
      await saveAnschreiben({
        variables: {
          email: user.email,
          payload: {
            year,
            type,
            language,
            otherThings: selectedOtherThings,
            profitAndLossActivities: selectedProfitAndLoss,
            eurTelenumber,
            usteTelenumber,
            esteTelenumber,
            gewsteTelenumber,
          },
        },
      });

      notification.success({ message: texts.SUCCESS });
    } catch (e) {
      reportError(e);
      notification.error({ message: texts.FAILED_TO_CREATE_ANSCHREIBEN });
    }

    resetForm();
    await refetchAnschreiben();
  };

  if (loadingAnschreiben || loadingTemplate) {
    return null;
  }

  if (!anschreibenData?.anschreiben && !templateData?.anschreibenTemplate) {
    return (
      <>
        <TopBar
          year={year}
          setYear={setYear}
          type={type}
          setType={setType}
          language={language}
          setLanguage={setLanguage}
        />
        <Alert message={texts.NO_TEMPLATE} banner />
      </>
    );
  }

  if (!anschreibenData?.anschreiben) {
    return (
      <>
        <TopBar
          year={year}
          setYear={setYear}
          type={type}
          setType={setType}
          language={language}
          setLanguage={setLanguage}
          RightComponents={
            <Button
              type="primary"
              onClick={generate}
              disabled={loadingAnschreiben}
            >
              {texts.GENERATE}
            </Button>
          }
        />

        <Wrapper>
          <Title level={5}>{texts.TELENUMBERS}</Title>
          <Space>
            <Form.Item
              {...(esteTelenumberInvalid
                ? { validateStatus: "error", help: "Please enter a value" }
                : {})}
            >
              <TelenumberInput
                placeholder={texts.TELENUMBER_3}
                value={esteTelenumber}
                onChange={(e) => setEsteTelenumber(e.target.value)}
                maxLength={3}
              />
            </Form.Item>
            <Form.Item>
              <TelenumberInput
                placeholder={texts.TELENUMBER_1}
                value={eurTelenumber}
                onChange={(e) => setEurTelenumber(e.target.value)}
                maxLength={3}
              />
            </Form.Item>
            <Form.Item>
              <TelenumberInput
                placeholder={texts.TELENUMBER_2}
                value={usteTelenumber}
                onChange={(e) => setUsteTelenumber(e.target.value)}
                maxLength={3}
              />
            </Form.Item>

            <Form.Item>
              <TelenumberInput
                placeholder={texts.TELENUMBER_4}
                value={gewsteTelenumber}
                onChange={(e) => setGewsteTelenumber(e.target.value)}
                maxLength={3}
              />
            </Form.Item>
          </Space>
        </Wrapper>

        <Form.Item>
          <Checkboxes
            title={texts.PROFIT_AND_LOSS}
            allOptions={profitAndLossOptions}
            selectedOptions={selectedProfitAndLoss}
            selectOption={setSelectedProfitAndLoss}
          />
        </Form.Item>

        <Form.Item>
          <Checkboxes
            title={texts.OTHER_STUFF}
            allOptions={otherThingsOptions}
            selectedOptions={selectedOtherThings}
            selectOption={setSelectedOtherThings}
          />
        </Form.Item>
      </>
    );
  }

  return (
    <AnschreibenForm
      user={user}
      anschreiben={anschreibenData.anschreiben}
      refetchAnschreiben={refetchAnschreiben}
      year={year}
      setYear={setYear}
    />
  );
};

export default Anschreiben;
