import React from "react";

import OfficeUsageView from "./OfficeUsageView";
import OldOfficeUsageForm from "./OldOfficeUsageForm";
import useTaxYearParam from "../../../../hooks/useTaxYearParam";

const NEW_OFFICE_USAGE_SUBFORM_TAX_YEAR = 2021;

const OfficeUsage = () => {
  const [taxYear] = useTaxYearParam();
  return taxYear >= NEW_OFFICE_USAGE_SUBFORM_TAX_YEAR ? (
    <OfficeUsageView taxYear={taxYear} />
  ) : (
    <OldOfficeUsageForm taxYear={taxYear} />
  );
};

export default OfficeUsage;
