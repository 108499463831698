import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Table, Empty } from "antd";
import moment from "moment";

import { renderExpandedRow } from "./ExpandedRow";
import { MatchButton } from "./styles";
import { IMessageType, Transaction } from "../../../../types";
import { formatAmountInCents, showMessage } from "../../../../utils";
import TransactionSearch from "../TransactionSearch";
import SelectBadge from "../../TransactionView/SelectBadges";

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
  .ant-table-wrapper {
    width: 100%;
  }
  gap: 15px;
`;

const RightSideContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BadgeContainer = styled.div`
  padding-bottom: 10px;
`;

const TransactionList = ({
  transactions,
  matchDocumentToTransaction,
  bookAndMatchTransaction,
}: {
  transactions: Transaction[];
  matchDocumentToTransaction: (transactionId: string) => void;
  bookAndMatchTransaction: (
    transaction: Transaction,
    vatCategoryCode: string,
    categoryCode: string
  ) => void;
}) => {
  const [transactionsList, setTransactionsList] = useState(transactions);

  const columns = [
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      sorter: (a: Transaction, b: Transaction) => a.amount - b.amount,
      render: (amount: number) => formatAmountInCents(amount, true),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a: Transaction, b: Transaction) =>
        (a.name || "").localeCompare(b.name || ""),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      sorter: (a: Transaction, b: Transaction) =>
        (a.description || "").localeCompare(b.description || ""),
      render: (description: string) => description || "-",
    },
    {
      title: "Date",
      key: "date",
      dataIndex: "valutaDate",
      width: 120,
      sorter: (a: Transaction, b: Transaction) =>
        moment(a.valutaDate).isAfter(b.valutaDate) ? 1 : -1,
      render: (date: Date) => moment(date).format("YYYY-MM-DD"),
    },
    {
      title: "",
      key: "match",
      render: (entry: Transaction) => (
        <RightSideContainer>
          {entry.assets?.length ? (
            <BadgeContainer>
              <SelectBadge info="Has asset" />
            </BadgeContainer>
          ) : null}
          <MatchButton
            data-test="matchedTransactionsList.matchButton"
            onClick={async (event) => {
              event.stopPropagation();
              await matchDocumentToTransaction(entry.id);
            }}
          >
            Match
          </MatchButton>
        </RightSideContainer>
      ),
    },
  ];

  const data = React.useMemo(() => transactionsList, [transactionsList]);

  const onTransactionsListUpdateHandler = (transactions: Transaction[]) => {
    if (transactions.length >= 50) {
      showMessage({
        duration: 5,
        content:
          "We show a maximum of 50 results. If the transaction is not included please make your search more granular.",
        type: IMessageType.INFO,
      });
    }
    setTransactionsList(transactions);
  };

  useEffect(() => {
    setTransactionsList(transactions);
  }, [transactions]);

  return (
    <Wrapper>
      <TransactionSearch onSearch={onTransactionsListUpdateHandler} />
      <Table
        columns={columns}
        dataSource={data}
        bordered
        expandable={{
          expandedRowRender: (transaction) => {
            return renderExpandedRow(transaction, bookAndMatchTransaction);
          },
          expandRowByClick: true,
        }}
        rowKey={(row) => row.id}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No transactions found"
            />
          ),
        }}
      />
    </Wrapper>
  );
};

export default TransactionList;
