import { Button, ButtonProps, Space } from "antd";

import {
  ITransaction,
  TransactionSource,
  TransactionSplit,
} from "../../../types";
import { isTransactionCategorized } from "./helpers";
import { ButtonsWrapper } from "./styledComponents";

type ActionButtonsProps = {
  originalTransaction: ActionButtonsTransaction;
  updatedTransaction: ActionButtonsTransaction;
  onClick: (verified: boolean, isRecurringVerify?: boolean) => void;
};

type ActionButtonsTransaction = Pick<
  ITransaction,
  "categoryCode" | "vatCategoryCode" | "source"
> & {
  splits?: ActionButtonsSplit[];
};

type ActionButtonsSplit = Pick<
  Partial<TransactionSplit>,
  "amount" | "categoryCode" | "vatCategoryCode"
>;

export const ActionButtons = ({
  originalTransaction,
  updatedTransaction,
  onClick,
}: ActionButtonsProps) => {
  const VerifyButton = (props: ButtonProps) => (
    <Button {...props} data-test="verifyBtn" onClick={() => onClick(true)} />
  );

  return (
    <ButtonsWrapper>
      <Space>
        {!isTransactionCategorized(originalTransaction) && (
          <Button data-test="bookBtn" onClick={() => onClick(false)}>
            Book
          </Button>
        )}
        {originalTransaction.source === TransactionSource.SOLARIS &&
          !originalTransaction.splits?.length &&
          !updatedTransaction.splits?.length && (
            <Button
              data-test="recurringVerifyBtn"
              onClick={() => onClick(true, true)}
            >
              Verify & Recurring Payment
            </Button>
          )}
        <VerifyButton type="primary" tabIndex={-1}>
          Verify
        </VerifyButton>
      </Space>
    </ButtonsWrapper>
  );
};
