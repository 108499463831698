import { gql } from "@apollo/client";

export const DOCUMENT_MATCH_RESULT_FIELDS = gql`
  fragment DocumentMatchResultFields on KontaxTransaction {
    id
    assets {
      id
    }
    name
    amount
    description
    valutaDate
    categoryCode
    categoryCodeMeta {
      label
      description
      categorizationType
      suggestionSource
    }
    vatCategoryCode
    vatCategoryCodeMeta {
      label
      description
      categorizationType
      suggestionSource
    }
    verified
  }
`;
