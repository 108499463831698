import { notification } from "antd";
import React, { ReactNode, useContext, useEffect, useState } from "react";

import api from "../../api";
import { IUser, IUserDetails, VatMeta } from "../../types";

export type UserState = VatMeta & { isLoading: boolean } & {
  editUser: (userDetails: IUserDetails) => Promise<void>;
};

const initialState = {
  isLoading: false,
  user: null,
  pastDeclarations: [],
  notes: [],
  editUser: async () => {},
};

const Context = React.createContext<UserState>(initialState);

export const useKontaxUserContext = () => useContext(Context);

type KontaxUserContextProps = {
  email?: string;
  user?: IUser;
  children: ReactNode | ReactNode[];
};

export const KontaxUserContext = ({
  email,
  user,
  children,
}: KontaxUserContextProps) => {
  const [context, setContext] = useState<UserState>(initialState);

  useEffect(() => {
    const getEditUser =
      (_user: IUser | null) => async (userDetails: IUserDetails) => {
        if (!_user) return;

        try {
          const updatedUser = await api.kontax.updateUser({
            email: _user.email,
            ...userDetails,
          });
          setContext((data) => ({
            ...data,
            user: updatedUser,
          }));
        } catch (error) {
          notification.error({
            message: "Failed updating user.",
          });
        }
      };

    if (user) {
      setContext({
        ...initialState,
        editUser: getEditUser(user),
        user,
      });
      return;
    }

    if (!email) {
      setContext(initialState);
      return;
    }

    (async (email: string) => {
      setContext((data) => ({
        ...data,
        isLoading: true,
      }));

      try {
        const vatMeta = await api.kontax.getVatMeta(email);
        setContext({
          isLoading: false,
          editUser: getEditUser(vatMeta.user),
          ...vatMeta,
        });
      } catch (error) {
        setContext(initialState);
      }
    })(email);
  }, [email, user]);

  return <Context.Provider value={context}>{children}</Context.Provider>;
};
