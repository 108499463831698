import moment from "moment-timezone";

interface DeclarationDownloadNameParams {
  firstName?: string;
  lastName?: string;
  declarationName: string;
  taxYear: number;
  creationDate: Date;
}

export const getDeclarationDownloadName = ({
  firstName,
  lastName,
  declarationName,
  taxYear,
  creationDate,
}: DeclarationDownloadNameParams) => {
  const creationDateFormat = moment(creationDate).format("DD.MM.YYYY");
  return `${declarationName}, ${lastName}, ${firstName}, ${taxYear} - ${creationDateFormat}.pdf`;
};
