import React from "react";
import { Modal } from "antd";
import moment from "moment-timezone";

import { formatAmountCurrency } from "../../../utils";
import { Euer } from "../../../types";

import elsterFields from "./elsterFields";

const PreviewModal = ({
  show,
  onCloseHandler,
  accountId,
  startDate,
  endDate,
  euer,
}: {
  show: boolean | undefined;
  onCloseHandler: () => void;
  accountId: string;
  startDate: string | null;
  endDate: string | null;
  euer: Euer | undefined;
}) => {
  return (
    <Modal
      visible={show}
      onCancel={onCloseHandler}
      title={`Einnahmenüberschussrechnung [${accountId}] • Vorschau`}
      footer={null}
      width={800}
    >
      <div style={{ float: "left" }}>
        <strong>Name:</strong> {euer?.firstName} {euer?.lastName}
        <br />
        <strong>Straße:</strong> {euer?.street}
        <br />
        <strong>Ort:</strong> {euer?.postCode} {euer?.city}
      </div>
      <div style={{ float: "right" }}>
        <strong>Startdatum:</strong> {moment(startDate).format("DD.MM.YYYY")}
        <br />
        <strong>Enddatum:</strong> {moment(endDate).format("DD.MM.YYYY")}
        <br />
        <br />
        <strong>Anzahl Transaktionen:</strong>
        <br />
        Kategorisiert: {euer?.transactionsCount}
        <br />
        Unkategorisiert: {euer?.uncategorizedCount}
        <br />
        <br />
      </div>
      <div style={{ clear: "both" }}></div>
      <table className="ustva">
        <thead>
          <tr>
            <th>ELSTER-Kennzahl</th>
            <th>Betrag</th>
            <th>Bezeichnung</th>
          </tr>
        </thead>
        <tbody>
          {Array.from(elsterFields).map(([id, definition]) => {
            const { label, description } = definition;
            const hasValue = definition.hasValue !== false;

            return (
              <tr key={id} className={hasValue ? "" : "light"}>
                <td>{label}</td>
                <td>
                  {hasValue ? formatAmountCurrency(euer?.elsterFields[id]) : ""}
                </td>
                <td>{description}</td>
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </tfoot>
      </table>
    </Modal>
  );
};

export default PreviewModal;
