import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import EuerDeclarationOverview from "./pages/EuerDeclarationOverview";
import CarUsage from "./pages/CarUsage";
import OfficeUsage from "./pages/OfficeUsage";
import TravelExpenses from "./pages/TravelExpenses";

const EuerDeclaration = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}`} component={EuerDeclarationOverview} exact />
      <Route path={`${path}/car-usage`} component={CarUsage} />
      <Route path={`${path}/office-usage`} component={OfficeUsage} />
      <Route path={`${path}/travel-expenses`} component={TravelExpenses} />
    </Switch>
  );
};

export default EuerDeclaration;
