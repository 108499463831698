import React, { useEffect, useState } from "react";

import { message, Table, Tag, Tooltip } from "antd";

import { capitalize } from "lodash";

import { ColumnType, TablePaginationConfig } from "antd/lib/table";
import denormalizeTaxNumber from "denormalize-steuernummer";

import {
  FilterValue,
  SorterResult,
  TableCurrentDataSource,
} from "antd/es/table/interface";

import {
  formatBookkeepingServices,
  formatTimestamp,
  getActiveSubscription,
} from "../../Common/UserDetails/utils";
import {
  IUser,
  IUserDetails,
  KontaxUserStatus,
  KontaxUserStatusAdditionalParams,
  TableParams,
  UserRow,
} from "../../../../../types";
import UserDetails from "../UserDetails";
import { getColorByUserStatus } from "../../../../../utils";
import { UpsertAccountingSourcePayload } from "../../../../../types/AccountingSource.type";
import Copyable from "../../../../common/Copyable";
import { getUserFullName } from "../../../../../utils/getUserFullName";

const copyToClipboard = (email: string, successMessage: string) => {
  navigator.clipboard.writeText(email);
  message.success(successMessage);
};

const clientStateFilters: { text: string; value: string }[] = [];
for (const key in KontaxUserStatus) {
  clientStateFilters.push({
    text: capitalize(key),
    value: key,
  });
}

const setDefaultExpandedRowKeys = (dataSource: UserRow[]) =>
  dataSource.length === 1 ? [0] : [];

const UsersTable = ({
  users,
  updateUserStatus,
  updateDependents,
  deleteDependent,
  upsertAccountingSource,
  removeAccountingSource,
  editUser,
  setEmailParam,
  tableParams,
  onChange,
}: {
  users: Array<IUser>;
  updateUserStatus: (
    email: string,
    status: KontaxUserStatus | null,
    additionalParams?: KontaxUserStatusAdditionalParams
  ) => Promise<void>;
  updateDependents: Function;
  upsertAccountingSource: (
    email: string,
    payload: UpsertAccountingSourcePayload
  ) => Promise<void>;
  removeAccountingSource: (email: string, year: number) => Promise<void>;
  deleteDependent: (email: string, id: string) => Promise<void>;
  editUser: (email: string, userDetails: IUserDetails) => Promise<void>;
  setEmailParam: (email: string) => void;
  tableParams: TableParams;
  onChange: (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<UserRow> | SorterResult<UserRow>[],
    extra: TableCurrentDataSource<UserRow>
  ) => void;
}) => {
  const bookkeepingServiceFilters = new Set<string>();
  const activePlanFilters = new Set<string>();

  let dataSource: UserRow[] = users.map((user: IUser, index) => {
    const bookkeepingService = formatBookkeepingServices(user);
    const activePlanType = getActiveSubscription(user.subscriptions);

    bookkeepingServiceFilters.add(bookkeepingService ?? "");
    activePlanFilters.add(activePlanType);

    return {
      key: index,
      name: getUserFullName(user),
      mobileNumber: user.mobileNumber || user.untrustedPhoneNumber,
      bookkeepingService,
      activePlanType,
      fibuOnboardingCompletedAt: formatTimestamp(
        user.taxServiceOnboardingCompletedAt
      ),
      user: user,
    };
  });

  const columns: ColumnType<UserRow>[] = [
    {
      title: "Account ID",
      dataIndex: ["user", "accountId"],
      width: 100,
      className: "accountId",
      showSorterTooltip: false,
    },
    {
      title: "Name",
      dataIndex: "name",
      width: 120,
      showSorterTooltip: false,
      sorter: false,
    },
    {
      title: "Phone number",
      dataIndex: ["user", "mobileNumber"],
      render: (value) => <Copyable>{value}</Copyable>,
      width: 110,
      showSorterTooltip: false,
      sorter: true,
    },
    {
      title: "Email",
      dataIndex: ["user", "email"],
      render: (value) => (
        <Tooltip title={value} placement="topLeft">
          <Copyable ellipsis>{value}</Copyable>
        </Tooltip>
      ),
      onCell: (record: UserRow) => {
        return {
          onClick: (event: any) => {
            event.stopPropagation();
            if (record.user.email)
              copyToClipboard(record.user.email, "Email copied");
          },
        };
      },
      width: 90,
      ellipsis: {
        showTitle: false,
      },
      showSorterTooltip: false,
      sorter: true,
    },
    {
      title: "Bookk. service",
      dataIndex: "bookkeepingService",
      width: 100,
      showSorterTooltip: false,
    },
    {
      title: "Hauptsteuernr.",
      dataIndex: ["user", "taxNumber"],
      width: 110,
      showSorterTooltip: false,
      render: (value) => value && denormalizeTaxNumber(value)?.taxNumber,
    },
    {
      title: "Steuerliche Identifikationsnr.",
      dataIndex: ["user", "deTaxId"],
      width: 120,
      showSorterTooltip: false,
      sorter: true,
    },
    {
      title: "Client state",
      className: "clientState",
      dataIndex: ["user", "kontaxStatus"],
      render: (value: KontaxUserStatus) => (
        <Tag color={getColorByUserStatus(value)} style={{ marginRight: 0 }}>
          {value}
        </Tag>
      ),
      width: 140,
      showSorterTooltip: false,
      align: "center",
      filters: clientStateFilters.sort((a, b) => a.text.localeCompare(b.text)),
      sorter: true,
    },
    {
      title: "Recurly plan",
      className: "recurlyPlan",
      dataIndex: "activePlanType",
      width: 100,
      showSorterTooltip: false,
    },
    {
      title: "FiBu onboarding completed",
      dataIndex: "fibuOnboardingCompletedAt",
      width: 140,
      showSorterTooltip: false,
    },
  ];

  const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>(
    setDefaultExpandedRowKeys(dataSource)
  );

  useEffect(() => {
    if (expandedRowKeys.length) {
      document
        .querySelector(`tr[data-row-key="${expandedRowKeys[0]}"]`)
        ?.scrollIntoView({
          behavior: "smooth",
        });
    }
  }, [expandedRowKeys]);

  useEffect(() => {
    if (users.length === 1) setEmailParam(users[0].email);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users]);

  const onTableRowExpand = (expanded: boolean, record: UserRow) => {
    setExpandedRowKeys(expanded ? [record.key] : []);
    setEmailParam(record.user.email);
  };

  if (!users.length) {
    return null;
  }

  return (
    <Table
      dataSource={dataSource}
      pagination={tableParams.pagination}
      onChange={onChange}
      data-test="mandantenTable"
      columns={columns}
      bordered
      expandRowByClick
      scroll={{ x: "100%" }}
      rowClassName="expendableRow"
      expandable={{
        expandedRowRender: (record: UserRow) => (
          <UserDetails
            user={record.user}
            updateUserStatus={updateUserStatus}
            upsertAccountingSource={upsertAccountingSource}
            removeAccountingSource={removeAccountingSource}
            editUser={editUser}
            updateDependents={updateDependents}
            deleteDependent={deleteDependent}
          />
        ),
        defaultExpandedRowKeys: setDefaultExpandedRowKeys(dataSource),
        expandedRowKeys: expandedRowKeys,
        onExpand: onTableRowExpand,
      }}
    />
  );
};

export default UsersTable;
