import type * as Types from "../../schema.generated";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type AssignTaxCaseMutationVariables = Types.Exact<{
  taxCaseId: Types.Scalars["ID"];
}>;

export type AssignTaxCaseMutation = {
  __typename?: "Mutation";
  assignTaxCase: {
    __typename?: "TaxBoardTaxCase";
    id: string;
    year: number;
    assignee?: string | null;
    deadline: string;
  };
};

export const AssignTaxCaseDocument = gql`
  mutation assignTaxCase($taxCaseId: ID!) {
    assignTaxCase(taxCaseId: $taxCaseId) {
      id
      year
      assignee
      deadline
    }
  }
`;
export type AssignTaxCaseMutationFn = Apollo.MutationFunction<
  AssignTaxCaseMutation,
  AssignTaxCaseMutationVariables
>;

/**
 * __useAssignTaxCaseMutation__
 *
 * To run a mutation, you first call `useAssignTaxCaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignTaxCaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignTaxCaseMutation, { data, loading, error }] = useAssignTaxCaseMutation({
 *   variables: {
 *      taxCaseId: // value for 'taxCaseId'
 *   },
 * });
 */
export function useAssignTaxCaseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AssignTaxCaseMutation,
    AssignTaxCaseMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AssignTaxCaseMutation,
    AssignTaxCaseMutationVariables
  >(AssignTaxCaseDocument, options);
}
export type AssignTaxCaseMutationHookResult = ReturnType<
  typeof useAssignTaxCaseMutation
>;
export type AssignTaxCaseMutationResult =
  Apollo.MutationResult<AssignTaxCaseMutation>;
export type AssignTaxCaseMutationOptions = Apollo.BaseMutationOptions<
  AssignTaxCaseMutation,
  AssignTaxCaseMutationVariables
>;
