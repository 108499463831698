import { useEffect, useState } from "react";
import { Typography } from "antd";

import { VatMetaNote } from "../../../../../types";
import {
  EuerDeclarationSubformType,
  KontaxNoteType,
} from "../../../../../api/graphql/schema.generated";
import { useKontaxUserContext } from "../../../../contexts/KontaxUserContext";
import { StyledNotes, TaxCaseCommentsWrapper } from "./style";
import {
  TaxBoardCommentsQuery,
  useTaxBoardCommentsQuery,
} from "../../../../../api/graphql/queries/taxBoard/taxBoardComments.generated";

const { Title } = Typography;

const getSubformByType = (
  data: TaxBoardCommentsQuery,
  type: EuerDeclarationSubformType
): TaxBoardCommentsQuery["euerDeclarationSubforms"][0] =>
  data.euerDeclarationSubforms.find((subform) => subform.type === type)!;

const TaxCaseComments = ({ taxYear }: { taxYear: number }) => {
  const { user, notes } = useKontaxUserContext();

  const [globalNotes, setGlobalNotes] = useState<VatMetaNote[]>([]);
  const [taxCaseNotes, setTaxCaseNotes] = useState<VatMetaNote[]>([]);
  const [euerDeclarationNotes, setEuerDeclarationNotes] = useState<
    VatMetaNote[]
  >([]);
  const [vatAnnualDeclarationNotes, setVatAnnualDeclarationNotes] = useState<
    VatMetaNote[]
  >([]);
  const [tradeTaxDeclarationNotes, setTradeTaxDeclarationNotes] = useState<
    VatMetaNote[]
  >([]);
  const [incomeTaxDeclarationNotes, setIncomeTaxDeclarationNotes] = useState<
    VatMetaNote[]
  >([]);
  const [businessAssetsNotes, setBusinessAssetsNotes] = useState<VatMetaNote[]>(
    []
  );
  const [carUsageNotes, setCarUsageNotes] = useState<VatMetaNote[]>([]);
  const [officeUsageNotes, setOfficeUsageNotes] = useState<VatMetaNote[]>([]);
  const [travelExpensesNotes, setTravelExpensesNotes] = useState<VatMetaNote[]>(
    []
  );

  const { data: taxBoardComments } = useTaxBoardCommentsQuery({
    variables: {
      email: user?.email!,
      year: taxYear,
    },
    skip: !user?.email,
    onCompleted: (data) => {
      setTaxCaseNotes(data.taxCase.notes);
      setEuerDeclarationNotes(data.euerDeclaration.notes);
      setVatAnnualDeclarationNotes(data.vatAnnualDeclaration.notes);
      setTradeTaxDeclarationNotes(data.tradeTaxDeclaration.notes);
      setIncomeTaxDeclarationNotes(data.incomeTaxDeclaration.notes);
      setBusinessAssetsNotes(
        getSubformByType(data, EuerDeclarationSubformType.BUSINESS_ASSETS).notes
      );
      setCarUsageNotes(
        getSubformByType(data, EuerDeclarationSubformType.CAR_USAGE).notes
      );
      setOfficeUsageNotes(
        getSubformByType(data, EuerDeclarationSubformType.OFFICE_USAGE).notes
      );
      setTravelExpensesNotes(
        getSubformByType(data, EuerDeclarationSubformType.TRAVEL_EXPENSES).notes
      );
    },
  });

  useEffect(() => {
    if (notes) {
      setGlobalNotes(notes);
    }
  }, [notes]);

  if (!user || !taxBoardComments) {
    return null;
  }

  return (
    <TaxCaseCommentsWrapper>
      <Title level={2}>Global (alle Jahre)</Title>
      <StyledNotes
        title="User info"
        setNotes={setGlobalNotes}
        notes={globalNotes}
        email={user.email}
        type={KontaxNoteType.VAT}
        withLoadMore
      />

      <Title level={2}>{taxYear}</Title>
      <StyledNotes
        title="Allgemein Steuerjahr"
        setNotes={setTaxCaseNotes}
        notes={taxCaseNotes}
        email={user.email}
        type={KontaxNoteType.TAX_CASE}
        recordId={taxBoardComments.taxCase.id}
        withLoadMore
      />

      <Title level={2}>Anlagen</Title>
      <StyledNotes
        title="Anlagen EÜR + AV"
        setNotes={setEuerDeclarationNotes}
        notes={euerDeclarationNotes}
        email={user.email}
        type={KontaxNoteType.EUER_DECLARATION}
        recordId={taxBoardComments.euerDeclaration.id}
        withLoadMore
      />
      <StyledNotes
        title="Umsatzsteuererklärung"
        setNotes={setVatAnnualDeclarationNotes}
        notes={vatAnnualDeclarationNotes}
        email={user.email}
        type={KontaxNoteType.VAT_ANNUAL_DECLARATION}
        recordId={taxBoardComments.vatAnnualDeclaration.id}
        withLoadMore
      />
      <StyledNotes
        title="Gewerbesteuererklärung"
        setNotes={setTradeTaxDeclarationNotes}
        notes={tradeTaxDeclarationNotes}
        email={user.email}
        type={KontaxNoteType.TRADE_TAX_DECLARATION}
        recordId={taxBoardComments.tradeTaxDeclaration.id}
        withLoadMore
      />
      <StyledNotes
        title="Einkommensteuererklärung"
        setNotes={setIncomeTaxDeclarationNotes}
        notes={incomeTaxDeclarationNotes}
        email={user.email}
        type={KontaxNoteType.INCOME_TAX_DECLARATION}
        recordId={taxBoardComments.incomeTaxDeclaration.id}
        withLoadMore
      />

      <Title level={2}>Unteranlagen EÜR</Title>
      <StyledNotes
        title="Anlagevermögen"
        setNotes={setBusinessAssetsNotes}
        notes={businessAssetsNotes}
        email={user.email}
        type={KontaxNoteType.EUER_DECLARATION_SUBFORM}
        recordId={
          getSubformByType(
            taxBoardComments,
            EuerDeclarationSubformType.BUSINESS_ASSETS
          ).id
        }
        withLoadMore
      />
      <StyledNotes
        title="Pkw-Nutzung"
        setNotes={setCarUsageNotes}
        notes={carUsageNotes}
        email={user.email}
        type={KontaxNoteType.EUER_DECLARATION_SUBFORM}
        recordId={
          getSubformByType(
            taxBoardComments,
            EuerDeclarationSubformType.CAR_USAGE
          ).id
        }
        withLoadMore
      />
      <StyledNotes
        title="Häusliches Arbeitszimmer"
        setNotes={setOfficeUsageNotes}
        notes={officeUsageNotes}
        email={user.email}
        type={KontaxNoteType.EUER_DECLARATION_SUBFORM}
        recordId={
          getSubformByType(
            taxBoardComments,
            EuerDeclarationSubformType.OFFICE_USAGE
          ).id
        }
        withLoadMore
      />
      <StyledNotes
        title="Verpflegungsmehraufwand & KFZ-Pauschalen"
        setNotes={setTravelExpensesNotes}
        notes={travelExpensesNotes}
        email={user.email}
        type={KontaxNoteType.EUER_DECLARATION_SUBFORM}
        recordId={
          getSubformByType(
            taxBoardComments,
            EuerDeclarationSubformType.TRAVEL_EXPENSES
          ).id
        }
        withLoadMore
      />
    </TaxCaseCommentsWrapper>
  );
};

export default TaxCaseComments;
