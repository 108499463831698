import React from "react";
import { Tag } from "antd";

import { TagContainer, SubParagraph } from "./styledComponents";
import { StatusColor } from "../../../../../constants";
import { getStatusText } from "../../utils";
import { AntGrayTag } from "../styledComponents";
export interface DeclarationStatusProps<T extends string> {
  mapping: Record<T, { color: StatusColor; label: string }>;
  status: T;
  statusUpdatedAt: Date | string | null | undefined;
  changedBy?: string | null;
  onClick?: () => void;
}

const DeclarationStatus = <T extends string>({
  mapping,
  status,
  statusUpdatedAt,
  changedBy,
  onClick,
}: DeclarationStatusProps<T>) => {
  const statusMappings = mapping[status];
  const statusText =
    getStatusText({ statusUpdatedAt, changedBy }) || "Kein Status gesetzt";

  return (
    <>
      <TagContainer data-test="anlagevermogenStatus" onClick={onClick}>
        {statusMappings.color === StatusColor.GRAY ? (
          <AntGrayTag>{statusMappings.label}</AntGrayTag>
        ) : (
          <Tag color={statusMappings.color}>{statusMappings.label}</Tag>
        )}
      </TagContainer>
      <SubParagraph>{statusText}</SubParagraph>
    </>
  );
};

export default DeclarationStatus;
