import styled from "styled-components";
import { InfoCircleOutlined } from "@ant-design/icons";

import colors from "../../../../../themes/colors";

export const YellowInfoIcon = styled(InfoCircleOutlined)`
  margin-top: 3px;
  color: ${colors.orangeYellow};
`;

export const GreyInfoIcon = styled(InfoCircleOutlined)`
  margin-top: 3px;
`;

export const InfoMessageContainer = styled.div`
  margin-top: 20px;
  display: flex;
  gap: 5px;
  max-width: 600px;
  color: ${colors.darkGrey};
`;
