import { useCallback, useMemo } from "react";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Select,
  TimePicker,
  Typography,
} from "antd";

import moment from "moment-timezone";

import { MANUAL_ACTIVITY_OPTIONS } from "./constants";
import {
  destroyMessage,
  showGraphQlErrorNotification,
  showLoadingMessage,
} from "../../../utils";
import { IUser } from "../../../types";
import { useCreateUsageStatMutation } from "../../../api/graphql";
import { CreateUsageStatInput } from "../../../api/graphql/schema.generated";
import { SectionRow, StyledModal } from "./styledComponent";

const { Text } = Typography;

interface AddNewActivityForm {
  activity: string;
  date: moment.Moment;
  timeRange: [moment.Moment, moment.Moment];
  comment: string;
}

const LOADING_MESSAGE_KEY = "creating-usage-stat";

const formatTimeInputValue = (date: moment.Moment, time: moment.Moment) =>
  date
    .clone()
    .startOf("day")
    .hours(time.hours())
    .minutes(time.minutes())
    .toISOString();

const AddNewActivityModal = ({
  user,
  visible,
  onClose,
  onSuccess,
  activityDateRange,
}: {
  user: IUser | null;
  visible: boolean;
  onClose: Function;
  onSuccess: Function;
  activityDateRange: { startDate: Date; endDate: Date } | null;
}) => {
  const today = useMemo(() => moment(), []);
  const [form] = Form.useForm<AddNewActivityForm>();
  const [createUsageStat, { loading: isSaving }] = useCreateUsageStatMutation();

  const handleReset = useCallback(() => {
    form.resetFields();
  }, [form]);

  const handleCancel = () => {
    onClose();
    handleReset();
  };

  // initialize the form with default date range values
  form.setFieldsValue({
    date: activityDateRange ? moment(activityDateRange.startDate) : undefined,
    timeRange: activityDateRange
      ? [
          moment(activityDateRange?.startDate),
          moment(activityDateRange?.endDate),
        ]
      : undefined,
  });

  const handleSubmit = useCallback(
    async (values: AddNewActivityForm) => {
      showLoadingMessage(LOADING_MESSAGE_KEY);
      try {
        const {
          activity,
          comment,
          date,
          timeRange: [startTime, finishTime],
        } = values;

        const payload: CreateUsageStatInput = {
          email: user?.email,
          activity,
          comment,
          startedAt: formatTimeInputValue(date, startTime),
          finishedAt: formatTimeInputValue(date, finishTime),
        };
        await createUsageStat({
          variables: {
            payload,
          },
        });

        await onSuccess();

        onClose();
        handleReset();
      } catch (error) {
        showGraphQlErrorNotification("Adding new activity", error);
      } finally {
        destroyMessage(LOADING_MESSAGE_KEY);
      }
    },
    [handleReset, onClose, onSuccess, createUsageStat, user]
  );

  const footer = [
    <Button
      form="addNewActivityFormId"
      key="submit"
      htmlType="submit"
      type="primary"
      loading={isSaving}
    >
      Submit
    </Button>,
  ];

  return (
    <StyledModal
      title="Add new activity"
      visible={visible}
      onCancel={handleCancel}
      footer={footer}
      width={600}
    >
      {user && (
        <>
          <SectionRow>
            <Col>
              <Text strong>
                {user.firstName} {user.lastName}
              </Text>
              <div>{user.email}</div>
            </Col>
          </SectionRow>
          <Divider className="m-0" />
        </>
      )}
      <SectionRow>
        <Col flex="1">
          <Form
            form={form}
            name="addNewActivityForm"
            id="addNewActivityFormId"
            onFinish={handleSubmit}
            autoComplete="off"
            requiredMark={false}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
          >
            <Form.Item
              name="activity"
              label="Activity"
              rules={[{ required: true }]}
            >
              <Select options={MANUAL_ACTIVITY_OPTIONS}></Select>
            </Form.Item>
            <Form.Item
              name="date"
              label="Date"
              rules={[{ required: true }]}
              initialValue={today}
            >
              <DatePicker />
            </Form.Item>
            <Form.Item
              name="timeRange"
              label="Time range"
              rules={[{ required: true }]}
            >
              <TimePicker.RangePicker format="HH:mm" minuteStep={5} />
            </Form.Item>
            <Form.Item name="comment" label="Comment">
              <Input.TextArea showCount maxLength={255} defaultValue="" />
            </Form.Item>
          </Form>
        </Col>
      </SectionRow>
    </StyledModal>
  );
};

export default AddNewActivityModal;
