import React from "react";
import ReactDOM from "react-dom";

// Import fonts
import "@fontsource/roboto";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto-mono";

import "./sentry";

// Antd styles imported on top so it does not effect bootstrap and custom styles
import "./custom-antd.less";

// Bootstrap styles must be imported before our custom styles so that we can override them
import "bootstrap/dist/css/bootstrap.min.css";

import "./index.css";

import "./config/setupMoment";

import App from "./App";
import "./i18n";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
