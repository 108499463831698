import toPairs from "lodash/toPairs";
import denormalizeTaxNumber from "denormalize-steuernummer";
import queryString from "query-string";

import { TaxNumberType } from "./types";
import { StateAbbreviation } from "./types";
import { IsMainBusinessTaxNumber } from "./types";

export const TAX_NUMBER_TYPE_LABELS: {
  [taxNumberType: string]: string;
} = {
  [TaxNumberType.PERSONAL]: "Private Steuernummer",
  [TaxNumberType.BUSINESS]: "Business Steuernummer",
};

export const TAX_NUMBER_OPTIONS = toPairs(TAX_NUMBER_TYPE_LABELS).map(
  ([taxNumberType, label]) => ({
    value: taxNumberType as TaxNumberType,
    label,
  })
);

export const STATE_LABEL: {
  [state: string]: string;
} = {
  [StateAbbreviation.DE_BW]: "Baden-Württemberg",
  [StateAbbreviation.DE_BY]: "Bayern",
  [StateAbbreviation.DE_BE]: "Berlin",
  [StateAbbreviation.DE_BB]: "Brandenburg",
  [StateAbbreviation.DE_HB]: "Bremen",
  [StateAbbreviation.DE_HH]: "Hamburg",
  [StateAbbreviation.DE_HE]: "Hessen",
  [StateAbbreviation.DE_MV]: "Mecklenburg-Vorpommern",
  [StateAbbreviation.DE_NI]: "Niedersachsen",
  [StateAbbreviation.DE_NW]: "Nordrhein-Westfalen",
  [StateAbbreviation.DE_RP]: "Rheinland-Pfalz",
  [StateAbbreviation.DE_SL]: "Saarland",
  [StateAbbreviation.DE_SN]: "Sachsen",
  [StateAbbreviation.DE_ST]: "Sachsen-Anhalt",
  [StateAbbreviation.DE_SH]: "Schleswig-Holstein",
  [StateAbbreviation.DE_TH]: "Thüringen",
};

export const STATE_OPTION = toPairs(STATE_LABEL).map(([state, label]) => ({
  value: state as StateAbbreviation,
  label,
}));

export const IS_MAIN_TAX_NUMBER_OPTIONS = [
  {
    label: IsMainBusinessTaxNumber.YES,
    value: true,
  },
  {
    label: IsMainBusinessTaxNumber.NO,
    value: false,
  },
];

export const TABLE_COLUMN_TITLES = {
  TYPE: "Art",
  STATE: "Bundesland",
  TAX_NUMBER: "Steuernummer",
  VALID_FROM: "Gültig ab",
  DESCRIPTION: "Info",
  ACTION: "Aktion",
  MAIN_TAX_NUMBER: "Hauptsteuernr.",
};

export const getTaxAccountViewPath = ({
  email,
  taxNumber: normalizedTaxNumber,
}: {
  email: string;
  taxNumber: string;
}) => {
  const { taxState, taxNumber } =
    denormalizeTaxNumber(normalizedTaxNumber)! || {};

  return {
    pathname: "/tax-account-view",
    search: queryString.stringify({
      email,
      taxNumber,
      state: taxState,
    }),
  };
};
