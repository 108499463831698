import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  FormInstance,
  Row,
  Select,
  Typography,
} from "antd";
import { useCallback, useMemo } from "react";
import styled from "styled-components";
import range from "lodash/range";

import {
  calculateNetAmount,
  calculateSubformResults,
  calculateVatAmount,
  EuerDeclarationOfficeUsageSubformCalculationResults,
  EuerDeclarationOfficeUsageSubformFlatRate5EurInputs,
  EuerDeclarationOfficeUsageSubformFullCostInputs,
  EuerDeclarationOfficeUsageSubformInputs,
  EuerDeclarationSubformCalculationMethod,
  EuerDeclarationSubformType,
  HomeOwnershipType,
} from "@kontist/euer-declaration";

import Link from "antd/lib/typography/Link";

import { UserOutlined } from "@ant-design/icons";

import { defaults } from "lodash";

import { EuerNumericField } from "../../../components/EuerFormFields";
import colors from "../../../../../../../../themes/colors";
import { EuerDeclarationSubformStatus } from "../../../../../types";
import { formatAmountInCents } from "../../../../../../../../utils";
import LabelWithTag from "../../../components/LabelWithTag";
import { StyledFieldset, StyledForm } from "../styledComponents";
import {
  INITIAL_FLAT_RATE_INPUTS,
  INITIAL_FULL_COST_INPUTS,
  INITIAL_CALCULATION_RESULTS,
  getInputsFromForm,
  HOME_OWNERSHIP_TYPE_OPTIONS,
  OFFICE_USAGE_CALCULATION_METHOD_OPTIONS,
} from "../helpers";
import { Tab } from ".";
import { HomeOfficeExpense } from "../../../../../../../../api/graphql/schema.generated";
import { OfficeUsageQuestionnaireValues } from "../types";
import {
  AutoWrapper,
  InputWithAutoWrapper,
  LabelWithIcon,
} from "../../CarUsage/styledComponents";
import RadioButtonGroup from "../../../../../../../common/RadioButtonGroup";

const { Text } = Typography;
const dateFormat = "DD.MM.YYYY";

export const QUESTIONNAIRE_SYNC_SUPPORTED_AFTER = 2020;

const MONTHS_USED_OPTIONS = range(1, 13).map((value) => ({
  label: String(value),
  value,
}));

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  width: 100%;
`;

interface Props {
  taxYear: number;
  calculationMethod: EuerDeclarationSubformCalculationMethod;
  questionnaireCalculationMethod: EuerDeclarationSubformCalculationMethod | null;
  questionnaireValues: OfficeUsageQuestionnaireValues | null;
  onFormValueChange: (values: { [key: string]: any }) => void;
  saveSubform: (status?: EuerDeclarationSubformStatus) => void;
  calculationResults: EuerDeclarationOfficeUsageSubformCalculationResults;
  setCalculationResults: (
    calculationResults: EuerDeclarationOfficeUsageSubformCalculationResults
  ) => void;
  formInstance: FormInstance<EuerDeclarationOfficeUsageSubformInputs>;
  isSmallBusiness: boolean;
  isSaving: boolean;
  officeAreaShare: number;
  setActiveTab: (activeTab: string) => void;
  homeOfficeExpenses: HomeOfficeExpense[];
}

const CalculationTab = ({
  taxYear,
  calculationMethod,
  questionnaireCalculationMethod,
  questionnaireValues,
  onFormValueChange,
  formInstance,
  saveSubform,
  calculationResults,
  setCalculationResults,
  isSmallBusiness,
  isSaving,
  officeAreaShare,
  setActiveTab,
  homeOfficeExpenses,
}: Props) => {
  const handleSelectCalculationMethod = (
    newCalculationMethod: EuerDeclarationSubformCalculationMethod
  ) => {
    const oldCalculationMethod = calculationMethod;

    if (newCalculationMethod === oldCalculationMethod) {
      return;
    }

    let newInitialValues:
      | EuerDeclarationOfficeUsageSubformFlatRate5EurInputs
      | EuerDeclarationOfficeUsageSubformFullCostInputs
      | null = null;

    if (
      newCalculationMethod ===
      EuerDeclarationSubformCalculationMethod.OFFICE_USAGE_FLAT_RATE_5_EUR
    ) {
      newInitialValues = defaults(
        {
          daysUsed: questionnaireValues?.daysUsed,
        },
        {
          ...INITIAL_FLAT_RATE_INPUTS,
        }
      );
    } else if (
      oldCalculationMethod ===
      EuerDeclarationSubformCalculationMethod.OFFICE_USAGE_FLAT_RATE_5_EUR
    ) {
      newInitialValues = defaults(
        {
          homeOwnershipType: questionnaireValues?.homeOwnershipType,
          totalHomeArea: questionnaireValues?.totalHomeArea,
          officeArea: questionnaireValues?.officeArea,
          monthsUsed: questionnaireValues?.monthsUsed,
        },
        {
          ...INITIAL_FULL_COST_INPUTS,
        }
      );
    }

    let changedValues = {
      calculationMethod: newCalculationMethod,
    };

    if (newInitialValues) {
      formInstance.setFieldsValue(newInitialValues);
      changedValues = {
        ...changedValues,
        ...newInitialValues,
      };
    }

    onFormValueChange(changedValues);
    calculateResults(newCalculationMethod);
  };

  const calculateResults = useCallback(
    (
      // Allow to explicitly pass calculation method to be able to call that function with new calculation method just after it is set
      calculationMethodOverwrite: EuerDeclarationSubformCalculationMethod = calculationMethod
    ) => {
      const currentValues = formInstance.getFieldsValue(true);
      const inputs = getInputsFromForm(
        calculationMethodOverwrite,
        currentValues
      );

      let results: EuerDeclarationOfficeUsageSubformCalculationResults;
      try {
        results = calculateSubformResults(
          EuerDeclarationSubformType.OFFICE_USAGE,
          calculationMethodOverwrite,
          inputs,
          taxYear
        ) as EuerDeclarationOfficeUsageSubformCalculationResults;
      } catch (error) {
        // Use empty results when validation failed
        results = INITIAL_CALCULATION_RESULTS;
      }

      setCalculationResults(results);
    },
    [calculationMethod, formInstance, setCalculationResults, taxYear]
  );

  const totalNetAmount = useMemo(() => {
    // Use calculation method is OFFICE_USAGE_UP_TO_1250_EUR cap totalNetAmount to 1250 EUR
    const totalExpenses = homeOfficeExpenses.reduce(
      (acc, homeOfficeExpense) =>
        acc +
        calculateNetAmount({
          adjustByOfficeAreaShare: homeOfficeExpense.adjustByOfficeAreaShare,
          officeAreaShare,
          amount: homeOfficeExpense.amount,
          vatRate: parseInt(homeOfficeExpense.vatRate),
          period: homeOfficeExpense.period,
          monthsUsed: homeOfficeExpense.monthsUsed || 0,
        }),
      0
    );

    if (
      calculationMethod ===
      EuerDeclarationSubformCalculationMethod.OFFICE_USAGE_UP_TO_1250_EUR
    ) {
      return Math.min(totalExpenses, 1250_00);
    }

    return totalExpenses;
  }, [homeOfficeExpenses, officeAreaShare, calculationMethod]);

  const totalVatAmount = useMemo(() => {
    return homeOfficeExpenses.reduce(
      (acc, homeOfficeExpense) =>
        acc +
        calculateVatAmount({
          adjustByOfficeAreaShare: homeOfficeExpense.adjustByOfficeAreaShare,
          officeAreaShare,
          amount: homeOfficeExpense.amount,
          vatRate: parseInt(homeOfficeExpense.vatRate),
          period: homeOfficeExpense.period,
          monthsUsed: homeOfficeExpense.monthsUsed || 0,
        }),
      0
    );
  }, [homeOfficeExpenses, officeAreaShare]);

  const handleFormValuesChange = useCallback(
    (changedValue: { [key: string]: any }) => {
      onFormValueChange(changedValue);
      calculateResults();
    },
    [calculateResults, onFormValueChange]
  );

  const handleAutoButton = useCallback(
    (field: keyof OfficeUsageQuestionnaireValues) => {
      formInstance.setFieldsValue({
        [field]: questionnaireValues![field],
      });
      calculateResults();
    },
    [formInstance, questionnaireValues, calculateResults]
  );

  const shouldShowAutoField = useCallback(
    (field: keyof OfficeUsageQuestionnaireValues) => {
      return (
        taxYear > QUESTIONNAIRE_SYNC_SUPPORTED_AFTER &&
        questionnaireValues &&
        questionnaireValues[field] != null &&
        questionnaireValues[field] !== formInstance.getFieldValue(field)
      );
    },
    [taxYear, questionnaireValues, formInstance]
  );

  const handleAutoField = useCallback(
    (
      field: keyof OfficeUsageQuestionnaireValues,
      {
        formatValue,
        style,
      }: {
        formatValue?: (value: any) => string;
        style?: React.CSSProperties;
      } = {}
    ) => {
      const value = questionnaireValues?.[field];
      return shouldShowAutoField(field) ? (
        <AutoWrapper
          onClick={() => {
            handleAutoButton(field);
          }}
          style={style}
        >
          <UserOutlined />
          <span>{formatValue ? formatValue(value) : value}</span>
        </AutoWrapper>
      ) : null;
    },
    [shouldShowAutoField, questionnaireValues, handleAutoButton]
  );

  const onSaveClick = useCallback(() => {
    saveSubform();
  }, [saveSubform]);

  return (
    <>
      <Row gutter={16}>
        <Col span={8}>
          <StyledForm layout="vertical" colon={false}>
            <Form.Item label="Kostenansatz">
              <Select
                style={{ width: 300 }}
                value={calculationMethod}
                onChange={handleSelectCalculationMethod}
              >
                {OFFICE_USAGE_CALCULATION_METHOD_OPTIONS.map(
                  ({ value, label }) => (
                    <Select.Option key={value} value={value}>
                      <LabelWithIcon>
                        {questionnaireCalculationMethod === value && (
                          <UserOutlined />
                        )}
                        {label}
                      </LabelWithIcon>
                    </Select.Option>
                  )
                )}
              </Select>
            </Form.Item>
          </StyledForm>
          {calculationMethod !==
            EuerDeclarationSubformCalculationMethod.OFFICE_USAGE_FLAT_RATE_5_EUR && (
            <StyledForm layout="vertical" colon={false} form={formInstance}>
              <Form.Item label="Art der Wohnimmobilie" name="homeOwnershipType">
                <RadioButtonGroup
                  options={HOME_OWNERSHIP_TYPE_OPTIONS}
                  onChange={(value: HomeOwnershipType) => {
                    formInstance.setFieldsValue({
                      homeOwnershipType: value,
                    });
                    calculateResults();
                  }}
                  value={formInstance.getFieldValue("homeOwnershipType")}
                  renderLabel={(label, value) => (
                    <LabelWithIcon>
                      {value === questionnaireValues?.homeOwnershipType && (
                        <UserOutlined />
                      )}
                      {label}
                    </LabelWithIcon>
                  )}
                />
              </Form.Item>
            </StyledForm>
          )}
        </Col>
        <Col span={8} offset={8}>
          <ButtonContainer>
            <Button onClick={onSaveClick} type="primary" disabled={isSaving}>
              Speichern
            </Button>
          </ButtonContainer>
        </Col>
      </Row>

      <Row gutter={16} align="top">
        <Col span={12}>
          <StyledForm
            labelAlign="left"
            colon={false}
            labelCol={{ span: 14 }}
            wrapperCol={{ span: 10 }}
            form={formInstance}
            initialValues={
              calculationMethod ===
              EuerDeclarationSubformCalculationMethod.OFFICE_USAGE_FLAT_RATE_5_EUR
                ? INITIAL_FLAT_RATE_INPUTS
                : INITIAL_FULL_COST_INPUTS
            }
            onValuesChange={handleFormValuesChange}
          >
            {calculationMethod !==
              EuerDeclarationSubformCalculationMethod.OFFICE_USAGE_FLAT_RATE_5_EUR && (
              <>
                <StyledFieldset>
                  <legend />
                  <Form.Item>
                    <Text>
                      <b>Angaben Wohnung/Haus</b>
                    </Text>
                  </Form.Item>

                  <InputWithAutoWrapper>
                    <EuerNumericField
                      label="Gesamtfläche"
                      name="totalHomeArea"
                      prefix={
                        formInstance.getFieldValue("totalHomeArea") ===
                        questionnaireValues?.totalHomeArea ? (
                          <UserOutlined />
                        ) : (
                          <span />
                        )
                      }
                      addonAfter="m2"
                    />
                    {handleAutoField("totalHomeArea", {
                      style: { paddingRight: 38 },
                    })}
                  </InputWithAutoWrapper>
                  <InputWithAutoWrapper>
                    <EuerNumericField
                      label="Arbeitszimmer Fläche"
                      prefix={
                        formInstance.getFieldValue("officeArea") ===
                        questionnaireValues?.officeArea ? (
                          <UserOutlined />
                        ) : (
                          <span />
                        )
                      }
                      name="officeArea"
                      addonAfter="m2"
                    />
                    {handleAutoField("officeArea", {
                      style: { paddingRight: 38 },
                    })}
                  </InputWithAutoWrapper>
                  <Form.Item
                    style={{ textAlign: "right" }}
                    label="Anteil Arbeitszimmer Gesamtfläche"
                  >
                    <Text>{Math.round(officeAreaShare * 100)} %</Text>
                  </Form.Item>
                </StyledFieldset>
                <StyledFieldset>
                  <legend />
                  <Form.Item>
                    <Text>
                      <b>Nutzungszeitraum</b>
                    </Text>
                  </Form.Item>
                  <Form.Item label="Nutzungsbeginn" name="usageStartedAt">
                    <DatePicker format={dateFormat} />
                  </Form.Item>
                  <Form.Item label="Nutzungsmonate" name="monthsUsed">
                    <Select>
                      {MONTHS_USED_OPTIONS.map(({ value, label }) => (
                        <Select.Option key={value} value={value}>
                          <LabelWithIcon>
                            {questionnaireValues?.monthsUsed === value && (
                              <UserOutlined />
                            )}
                            {label}
                          </LabelWithIcon>
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </StyledFieldset>
                <StyledFieldset>
                  <legend />
                  <Form.Item>
                    <Text>
                      <b>Ausgaben </b>
                    </Text>
                    <Link
                      style={{ marginLeft: 10 }}
                      onClick={() => setActiveTab(Tab.EXPENSES)}
                    >
                      Belege
                    </Link>
                  </Form.Item>

                  <Form.Item
                    style={{ textAlign: "right" }}
                    label={
                      <label
                        style={{
                          color:
                            calculationMethod ===
                            EuerDeclarationSubformCalculationMethod.OFFICE_USAGE_UP_TO_1250_EUR
                              ? colors.darkLimeGreen
                              : colors.black,
                        }}
                      >
                        {calculationMethod ===
                        EuerDeclarationSubformCalculationMethod.OFFICE_USAGE_UP_TO_1250_EUR
                          ? "Kostendeckelung aktiv"
                          : "Summe Kosten Arbeitszimmer"}
                      </label>
                    }
                  >
                    <Text>{formatAmountInCents(totalNetAmount, true)}</Text>
                  </Form.Item>
                  <Form.Item
                    style={{ textAlign: "right" }}
                    label="Summe gezahlte Vorsteuer Arbeitszimmer"
                  >
                    <Text>{formatAmountInCents(totalVatAmount, true)}</Text>
                  </Form.Item>
                </StyledFieldset>
              </>
            )}
            {calculationMethod ===
              EuerDeclarationSubformCalculationMethod.OFFICE_USAGE_FLAT_RATE_5_EUR && (
              <StyledFieldset>
                <legend />
                <Text>
                  <b>Berechnung</b>
                </Text>
                <InputWithAutoWrapper>
                  <EuerNumericField
                    prefix={
                      formInstance.getFieldValue("daysUsed") ===
                      questionnaireValues?.daysUsed ? (
                        <UserOutlined />
                      ) : (
                        <span />
                      )
                    }
                    label="An wie vielen Tagen genutzt"
                    name="daysUsed"
                    addonAfter="Tage"
                  />
                  {handleAutoField("daysUsed", {
                    style: { paddingRight: 38 },
                  })}
                </InputWithAutoWrapper>
              </StyledFieldset>
            )}

            <fieldset>
              <legend />
              <Form.Item>
                <Text>
                  <b>Ergebnisse für EÜR</b>
                </Text>
              </Form.Item>
              <Form.Item
                style={{ textAlign: "right" }}
                label={
                  <LabelWithTag
                    tag="172"
                    label="Abziehbare Aufwendung für häusliches Arbeitszimmer (inkl. AfA und Schuldzinsen)"
                  />
                }
              >
                <Text className="ant-form-text">
                  {calculationMethod ===
                  EuerDeclarationSubformCalculationMethod.OFFICE_USAGE_FLAT_RATE_5_EUR
                    ? formatAmountInCents(
                        calculationResults.homeOfficeExpenses,
                        true
                      )
                    : formatAmountInCents(totalNetAmount, true)}
                </Text>
              </Form.Item>
              {calculationMethod !==
                EuerDeclarationSubformCalculationMethod.OFFICE_USAGE_FLAT_RATE_5_EUR &&
                !isSmallBusiness && (
                  <Form.Item
                    style={{ textAlign: "right" }}
                    label={
                      <LabelWithTag
                        tag="185"
                        label="Gezahlte Vorsteuerbeträge"
                      />
                    }
                  >
                    <Text className="ant-form-text">
                      {formatAmountInCents(totalVatAmount, true)}
                    </Text>
                  </Form.Item>
                )}
              <Divider />
              <Form.Item label="Gewinnminderung" style={{ textAlign: "right" }}>
                <Text
                  className="ant-form-text"
                  style={{
                    color:
                      calculationResults.profitReduction > 0
                        ? colors.darkRed
                        : colors.darkGrey,
                  }}
                >
                  {calculationMethod ===
                  EuerDeclarationSubformCalculationMethod.OFFICE_USAGE_FLAT_RATE_5_EUR
                    ? formatAmountInCents(
                        calculationResults.profitReduction,
                        true
                      )
                    : formatAmountInCents(
                        totalNetAmount + totalVatAmount,
                        true
                      )}
                </Text>
              </Form.Item>
            </fieldset>
          </StyledForm>
        </Col>
      </Row>

      <Row>
        <ButtonContainer>
          <Button onClick={onSaveClick} type="primary" disabled={isSaving}>
            Speichern
          </Button>
        </ButtonContainer>
      </Row>
    </>
  );
};

export default CalculationTab;
