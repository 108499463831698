export enum BookkeepingSourceType {
  MANUAL = "MANUAL",
  KONTIST = "KONTIST",
  ADVISOR = "ADVISOR",
  TOOL = "TOOL",
}
export type BookkeepingSource = {
  id?: number;
  year: number;
  type: BookkeepingSourceType;
  taxAdvisorAccess?: boolean;
  link?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  phone?: string | null;
  name?: string | null;
  email?: string | null;
};
