import React, { useState, useCallback, MouseEventHandler } from "react";
import styled from "styled-components";
import { Button } from "antd";

import {
  Asset,
  KontaxTransactionMeta,
  TransactionSource,
  ILocalAsset,
  ITransaction,
} from "../../../../types";
import { createTransactionAsset } from "../../../common/DragAndDrop/actionHandler";
import { SpinnerBasic } from "../../../common/Spinner";
import TransactionSplitButton from "../../../common/TransactionSplitButton";
import TransactionSplitContainer from "../../../common/TransactionSplitContainer";
import { TransactionSplitState } from "../../../common/types";
import { NoteSection } from "./NoteSection";
import { InvoiceViewWrapper, SplitAndInvoiceWrapper } from "./styledComponents";
import { TransactionRow, UpdateSplitsState } from "./types";
import EditExternalTransactionButton from "./EditExternalTransactionButton";
import Upload from "../../../common/Upload";
import LocalAssetsViewer from "../../../common/LocalAssetsViewer";
import ActionLogDrawerAsync from "../../../common/ActionLogDrawerAsync";
import { isDepreciableCategoryCode } from "../../../BusinessAsset/utils";
import { AddBusinessAssetPopup } from "./AddBusinessAssetPopup";
import RecurringPaymentToggle, {
  shouldDisplayToggle,
} from "../../../common/RecurringPaymentToggle";

interface IntegrationDocState {
  downloading: boolean;
  id: string | undefined;
}

const ActionLogWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;

export const TransactionsTableExpandedRow = ({
  transaction,
  handleDeleteSplits,
  onAssetsUpdate,
  handleUpdateSplits,
  updateTransactionsMeta,
  downloadingIntegrationDocument,
  editExternalTransactionButtonOnClick,
  onTransactionUpdated,
  isReadOnly = false,
}: {
  transaction: TransactionRow;
  handleDeleteSplits: (
    transaction: TransactionRow,
    state: TransactionSplitState
  ) => Promise<boolean>;
  onAssetsUpdate: (transactionId: string, assets: Array<Asset>) => void;
  handleUpdateSplits: ({
    transaction,
    splitState,
    skipConfirmationPopup,
  }: UpdateSplitsState) => Promise<void>;
  updateTransactionsMeta: (
    payload: Array<KontaxTransactionMeta>
  ) => Promise<boolean>;
  downloadingIntegrationDocument: IntegrationDocState;
  editExternalTransactionButtonOnClick: MouseEventHandler<HTMLButtonElement>;
  onTransactionUpdated: (upsertedTransaction: ITransaction) => void;
  isReadOnly?: boolean;
}) => {
  const [isUploading, setIsUploading] = useState(false);
  const [isSplitMode, setIsSplitMode] = useState(!!transaction.splits?.length);
  const [showAddBusinessAssetModal, setShowAddBusinessAssetModal] =
    useState<boolean>(false);

  const showSplitButton =
    !transaction.splits?.length &&
    !isSplitMode &&
    transaction.source !== TransactionSource.BACKOFFICE_MANUAL;

  const renderInvoiceContent = useCallback(
    (transaction: TransactionRow) => {
      if (
        transaction.hasIntegrationDocument &&
        downloadingIntegrationDocument.downloading &&
        transaction.id === downloadingIntegrationDocument.id
      ) {
        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <SpinnerBasic />
          </div>
        );
      } else {
        if (transaction.assets?.length) {
          return (
            <LocalAssetsViewer
              topPagination
              assets={transaction.assets || []}
              id={`asset-${transaction.id}`}
              minHeight={1000}
              onDelete={(asset: Asset | ILocalAsset) => {
                const assets =
                  transaction.assets?.filter(
                    (transactionAsset) => transactionAsset !== asset
                  ) || [];
                onAssetsUpdate(transaction.id, assets);
              }}
              isReadOnly={isReadOnly}
            />
          );
        } else {
          return <p style={{ textAlign: "center" }}> No Invoice found</p>;
        }
      }
    },
    [
      downloadingIntegrationDocument.downloading,
      downloadingIntegrationDocument.id,
      onAssetsUpdate,
      isReadOnly,
    ]
  );

  const renderInvoiceComponent = useCallback(
    (transaction: TransactionRow) => {
      if (isReadOnly) {
        return <div>{renderInvoiceContent(transaction)}</div>;
      }

      return (
        <InvoiceViewWrapper>
          <Upload
            onDropFiles={async (files: File[]) => {
              try {
                setIsUploading(true);

                const assets = (
                  await Promise.all(
                    files.map(async (file: File) => ({
                      id: await createTransactionAsset(transaction.id, file),
                      fullsize: URL.createObjectURL(file),
                      filetype: file.type.split("/").pop() as string,
                    }))
                  )
                ).concat(transaction.assets || ([] as any));

                onAssetsUpdate(transaction.id, assets);
              } catch (error) {
              } finally {
                setIsUploading(false);
              }
            }}
          >
            {renderInvoiceContent(transaction)}
            {isUploading ? (
              <SpinnerBasic />
            ) : (
              <p>Drag and drop invoices here to upload</p>
            )}
          </Upload>
        </InvoiceViewWrapper>
      );
    },
    [renderInvoiceContent, onAssetsUpdate, isUploading, isReadOnly]
  );

  const showEditButton =
    [TransactionSource.BACKOFFICE_MANUAL, TransactionSource.USER].includes(
      transaction.source as TransactionSource
    ) && !transaction.splits?.length;

  // this button is only visible on non-manual, outgoing, unverified, non 1320 / 1370 categories transactions
  const shouldShowAddBusinessAssetButton =
    !transaction.splits?.length &&
    (transaction.amount || 0) < 0 &&
    !transaction.verified &&
    !isDepreciableCategoryCode(transaction.categoryCode) &&
    transaction.source !== TransactionSource.BACKOFFICE_MANUAL;

  return (
    <div>
      <div
        style={{
          display: "flex",
          gap: "24px",
          padding: "24px",
          alignItems: "center",
        }}
      >
        {showSplitButton && (
          <TransactionSplitButton
            onClick={() => setIsSplitMode(true)}
            isDisabled={isReadOnly}
          />
        )}
        <NoteSection
          initialValue={transaction.internalNote || ""}
          onSave={async (note?: string) => {
            return updateTransactionsMeta([
              {
                kontistTransactionId: transaction.id,
                internalNote: note,
              },
            ]);
          }}
          personalNote={transaction.personalNote}
          uneditable={isReadOnly}
        />
        {shouldDisplayToggle(transaction) && (
          <RecurringPaymentToggle
            transactionId={transaction.id}
            recurringPaymentRuleId={transaction.recurringPaymentRuleId}
            isDisabled={isReadOnly}
          />
        )}
        {showEditButton && (
          <EditExternalTransactionButton
            onClick={editExternalTransactionButtonOnClick}
            isDisabled={
              transaction.verified ||
              isDepreciableCategoryCode(transaction.categoryCode) ||
              isReadOnly
            }
          />
        )}
        {shouldShowAddBusinessAssetButton && (
          <Button
            size="large"
            disabled={isReadOnly || !transaction.assets?.length}
            onClick={() => setShowAddBusinessAssetModal(true)}
          >
            Add business asset
          </Button>
        )}
        {/* should remove the component completely to reset the state */}
        {showAddBusinessAssetModal && (
          <AddBusinessAssetPopup
            initialValues={{
              id: transaction.id,
              categoryCode: transaction.categoryCode,
              vatCategoryCode: transaction.vatCategoryCode,
              amount: transaction.amount,
              paymentDate: transaction.paymentDate,
            }}
            closePopup={() => setShowAddBusinessAssetModal(false)}
            onTransactionUpdated={onTransactionUpdated}
            vatYearPaymentFrequency={transaction.vatYearPaymentFrequency}
          />
        )}
        <ActionLogWrapper>
          <ActionLogDrawerAsync
            title="Action log"
            modelName="transaction"
            recordIds={[transaction.id]}
            small
          />
        </ActionLogWrapper>
      </div>
      <SplitAndInvoiceWrapper>
        {renderInvoiceComponent(transaction)}
        {isSplitMode && (
          <TransactionSplitContainer
            transaction={transaction}
            onSubmit={(splitState: TransactionSplitState) =>
              handleUpdateSplits({
                transaction,
                splitState,
              })
            }
            onDelete={async (state: TransactionSplitState) => {
              if (await handleDeleteSplits(transaction, state)) {
                setIsSplitMode(false);
                return true;
              }
              return false;
            }}
            disableBusinessAssetFeatures
          />
        )}
      </SplitAndInvoiceWrapper>
    </div>
  );
};
