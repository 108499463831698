import type * as Types from "../../schema.generated";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type DeleteEmailDocumentMutationVariables = Types.Exact<{
  id: Types.Scalars["ID"];
}>;

export type DeleteEmailDocumentMutation = {
  __typename?: "Mutation";
  deleteEmailDocument: { __typename?: "MutationResult"; success: boolean };
};

export const DeleteEmailDocumentDocument = gql`
  mutation deleteEmailDocument($id: ID!) {
    deleteEmailDocument(id: $id) {
      success
    }
  }
`;
export type DeleteEmailDocumentMutationFn = Apollo.MutationFunction<
  DeleteEmailDocumentMutation,
  DeleteEmailDocumentMutationVariables
>;

/**
 * __useDeleteEmailDocumentMutation__
 *
 * To run a mutation, you first call `useDeleteEmailDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEmailDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEmailDocumentMutation, { data, loading, error }] = useDeleteEmailDocumentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteEmailDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteEmailDocumentMutation,
    DeleteEmailDocumentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteEmailDocumentMutation,
    DeleteEmailDocumentMutationVariables
  >(DeleteEmailDocumentDocument, options);
}
export type DeleteEmailDocumentMutationHookResult = ReturnType<
  typeof useDeleteEmailDocumentMutation
>;
export type DeleteEmailDocumentMutationResult =
  Apollo.MutationResult<DeleteEmailDocumentMutation>;
export type DeleteEmailDocumentMutationOptions = Apollo.BaseMutationOptions<
  DeleteEmailDocumentMutation,
  DeleteEmailDocumentMutationVariables
>;
