import React from "react";

import { Col } from "./styledComponents";

const InfoBlock = ({
  show,
  title,
  value,
}: {
  show: boolean;
  title: string;
  value: number | string | undefined;
}) => {
  if (!show || !value) {
    return null;
  }

  return (
    <Col>
      <span>{title}</span>
      <div>{value}</div>
    </Col>
  );
};

export default InfoBlock;
