import React from "react";
import { Button } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import { StyledModal } from "./styledComponents";

export interface DeclarationSubmitConfirmationProps {
  declarationName: string;
  taxYear: number;
  email: string;
  loading: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

const DeclarationSubmitConfirmation = ({
  declarationName,
  taxYear,
  email,
  loading,
  onConfirm,
  onCancel,
}: DeclarationSubmitConfirmationProps) => {
  return (
    <StyledModal
      visible
      title={
        <div>
          <ExclamationCircleOutlined
            style={{ fontSize: "28px", color: "var(--purple)" }}
          />
          <p>{`${declarationName} ${taxYear} beim Finanzamt einreichen`}</p>
        </div>
      }
      onOk={onConfirm}
      onCancel={onCancel}
      footer={[
        <Button key="Abbrechen" onClick={onCancel}>
          Abbrechen
        </Button>,
        <Button
          key="VerbindlichSenden"
          type="primary"
          loading={loading}
          onClick={onConfirm}
        >
          Verbindlich senden
        </Button>,
      ]}
      width={405}
      style={{ top: 130 }}
    >
      <p>{`Die ${declarationName} ${taxYear} für ${email} beim Finanzamt einreichen?`}</p>
    </StyledModal>
  );
};
export default DeclarationSubmitConfirmation;
