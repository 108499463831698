import { range, toPairs } from "lodash";

import {
  HomeOfficeExpensePeriod,
  HomeOfficeExpenseType,
  HomeOfficeDocumentType,
  TransactionCategory,
} from "../../../../../../../../api/graphql/schema.generated";

export const HOME_OFFICE_EXPENSE_TYPE_LABELS: {
  [homeOfficeExpenseType: string]: string;
} = {
  [HomeOfficeExpenseType.RENT_OR_INTEREST]: "Miete/Zinsen",
  [HomeOfficeExpenseType.PHONE_OR_INTERNET]: "Internet/Telefon",
  [HomeOfficeExpenseType.ELECTRICITY]: "Strom",
  [HomeOfficeExpenseType.HEATING]: "Heizkosten",
  [HomeOfficeExpenseType.UTILITY]: "Nebenkosten aktuell",
  [HomeOfficeExpenseType.UTILITY_AFTER_PAYMENT]:
    "Nebenkosten Nachzahlung Vergangenes Jahr",
  [HomeOfficeExpenseType.OTHER]: "Sonstiges",
};

export const HOME_OFFICE_EXPENSE_TYPE_OPTIONS = toPairs(
  HOME_OFFICE_EXPENSE_TYPE_LABELS
).map(([homeOfficeExpenseType, label]) => ({
  value: homeOfficeExpenseType as HomeOfficeExpenseType,
  label,
}));

export const HOME_OFFICE_EXPENSE_PERIOD_LABELS: {
  [homeOfficeExpensePeriod: string]: string;
} = {
  [HomeOfficeExpensePeriod.ANNUAL]: "Jährlich",
  [HomeOfficeExpensePeriod.MONTHLY]: "Monatlich",
  [HomeOfficeExpensePeriod.ONE_TIME_PAYMENT]: "Einmalige Zahlung",
};

export const HOME_OFFICE_VAT_RATE_LABELS: {
  [HomeOfficeVatRate: string]: string;
} = {
  [TransactionCategory.VAT_0]: "0 %",
  [TransactionCategory.VAT_7]: "7 %",
  [TransactionCategory.VAT_19]: "19 %",
};

export const HOME_OFFICE_EXPENSE_PERIOD_OPTIONS = toPairs(
  HOME_OFFICE_EXPENSE_PERIOD_LABELS
).map(([homeOfficeExpensePeriod, label]) => ({
  value: homeOfficeExpensePeriod as HomeOfficeExpenseType,
  label,
}));

export const HOME_OFFICE_EXPENSE_MONTHS_USED_OPTIONS = range(1, 13).map(
  (value) => ({
    label: `${value} Monate`,
    value,
  })
);

export const HOME_OFFICE_EXPENSE_VAT_RATE_OPTIONS = [
  "0",
  "5",
  "7",
  "16",
  "19",
].map((value) => ({
  label: `${value} %`,
  value,
}));

export const HOME_OFFICE_DOCUMENT_TYPE_LABELS: {
  [homeOfficeDocumentType: string]: string;
} = {
  [HomeOfficeDocumentType.FLOOR_PLAN]: "Grundriss",
  [HomeOfficeDocumentType.OTHER]: "Sonstiges",
};

export const HOME_OFFICE_DOCUMENT_TYPE_OPTIONS = toPairs(
  HOME_OFFICE_DOCUMENT_TYPE_LABELS
).map(([homeOfficeDocumentType, label]) => ({
  value: homeOfficeDocumentType as HomeOfficeDocumentType,
  label,
}));
