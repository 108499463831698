import React from "react";
import { useLocation } from "react-router-dom";
import { NavLink } from "react-router-dom";
import * as queryString from "query-string";
import pick from "lodash/pick";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";

import { EMAIL_PARAM_NAME } from "../../hooks/useEmailParam";
import { TAX_YEAR_PARAM_NAME } from "../../pages/TaxDeclaration/hooks/useTaxYearParam";
import { PermissionsScope } from "../../../types";

export interface NavigationLink {
  icon?: React.ComponentType;
  path?: string;
  label: string;
  preserveQueryParams?: boolean;
  onClick?: () => void;
  requiredScope?: PermissionsScope[];
  featureFlag?: string;
}

export interface LeftNavItemProps {
  item: NavigationLink;
}

const getTexts = (t: TFunction<"Navigation", undefined>) => ({
  label: (labelName: string) => t(`navigation.${labelName}`, ""),
});

const QUERY_PARAMS_TO_PRESERVE = [EMAIL_PARAM_NAME, TAX_YEAR_PARAM_NAME];
const getQueryParamsToPreserve = (search: string) => {
  const allParams = queryString.parse(search);
  const paramsToPreserve = pick(allParams, QUERY_PARAMS_TO_PRESERVE);
  return queryString.stringify(paramsToPreserve);
};

const LeftNavItem = ({ item }: LeftNavItemProps) => {
  const location = useLocation();
  const { t } = useTranslation("Navigation");
  const texts = getTexts(t);

  return (
    <NavLink
      exact={item.path === "/" /* use exact match only for home page */}
      onClick={item.onClick}
      to={{
        pathname: item.path,
        search: item.preserveQueryParams
          ? getQueryParamsToPreserve(location.search)
          : undefined,
      }}
    >
      {texts.label(item.label) || item.label}
    </NavLink>
  );
};

export default LeftNavItem;
