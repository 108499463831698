import React, { useEffect, useRef, useState } from "react";

import { Table, Tooltip } from "antd";
import { ColumnsType, ColumnType } from "antd/es/table";

import moment from "moment-timezone";

import {
  BWAFilterOptions,
  KontaxTransactionFilterOptions,
} from "../../../types";
import { BWAStatsData } from "../../../api/graphql";
import { formatAmountInCents } from "../../../utils";
import { BWATableWrapper, BWATableCellWrapper } from "./styledComponents";
import EmptyWrapper from "../../common/EmptyWrapper";
import { useBWAStatsQuery } from "../../../api/graphql";
import { allCategoriesRaw, NOT_VERIFIED_CODES } from "../../../utils";
import TransactionList from "../../pages/VerificationView/TransactionList";
import {
  startOfBerlinDay,
  endOfBerlinDay,
  BERLIN_TIMEZONE,
} from "../../common/helpers";

interface Record {
  [key: string]: string | number;
}

const allCategories = ((arr) => {
  const [uncategorized] = arr.splice(
    arr.findIndex((category) =>
      ["uncategorized", ...NOT_VERIFIED_CODES].includes(category[0])
    ),
    1
  );

  return [uncategorized, ...arr];
})([...allCategoriesRaw]);

const getTableRow = (
  columns: ColumnsType<Record>,
  row: BWAStatsData[]
): Record => {
  const tableData: Record = {};

  columns.forEach((column: ColumnType<Record>) => {
    const dataIndex = column.dataIndex as string;
    const [month, year] = dataIndex.split("-");
    const bucket = row.find((bucket) => {
      return (
        bucket.year === year && bucket.month === parseInt(month).toString()
      );
    });
    tableData[dataIndex] = bucket?.sum || 0;
  });

  return tableData;
};

const getCellMark = (dataIndex: string, rowIndex: number) =>
  `${rowIndex}-${dataIndex}`;

export const BWAStatsTable = ({
  filterOptions: { email, dateFrom, dateTo },
}: {
  filterOptions: BWAFilterOptions;
}) => {
  const [transactionFilterOptions, setTransactionFilterOptions] =
    useState<KontaxTransactionFilterOptions>({
      email,
      dateFrom,
      dateTo,
      categories: [],
      categorized: false,
    });
  const expandedCellIndices = useRef<string[]>([]);

  const renderCell = (content: number | string) => {
    const text =
      typeof content === "string"
        ? content
        : content
        ? formatAmountInCents(content as number)
        : 0;
    return <BWATableCellWrapper content={content}>{text}</BWATableCellWrapper>;
  };

  const getOnCellPropsHandler = (cellMark: string) => {
    const mark = cellMark;
    return (
      options: Partial<KontaxTransactionFilterOptions>
    ): React.HTMLAttributes<any> | React.TdHTMLAttributes<any> => ({
      className: expandedCellIndices.current.includes(mark)
        ? "active"
        : "data-cell",
      onClick: () => {
        if (!expandedCellIndices.current.includes(mark))
          expandedCellIndices.current.push(mark);
        setTransactionFilterOptions((prev) => ({ ...prev, ...options }));
      },
    });
  };

  const getDateColumns = (
    dateFrom: moment.MomentInput,
    dateTo: moment.MomentInput
  ): ColumnsType<Record> => {
    const columns: ColumnsType<Record> = [];

    let month = moment(dateFrom);
    const dateMonthTo = moment(dateTo).tz(BERLIN_TIMEZONE).format("YYYY-MM");
    while (month.format("YYYY-MM") <= dateMonthTo) {
      const dataIndex = month.format("MM-YYYY");
      const dateFrom = startOfBerlinDay(month.startOf("month").format());
      const dateTo = endOfBerlinDay(month.endOf("month").format());
      columns.push({
        title: dataIndex,
        dataIndex,
        align: "right",
        width: 100,
        render: (amount: number, _record: Record, rowIndex: number) =>
          renderCell(amount),
        onCell: (_record: Record, rowIndex: number | undefined) =>
          getOnCellPropsHandler(getCellMark(dataIndex, rowIndex as number))({
            dateFrom,
            dateTo,
          }),
      });
      month = month.add(1, "month");
    }

    return columns;
  };

  useEffect(() => {
    setTransactionFilterOptions((prev) => ({ ...prev, email }));
    expandedCellIndices.current = [];
  }, [email]);

  const { data, loading } = useBWAStatsQuery({
    variables: {
      email,
      dateFrom,
      dateTo,
    },
  });

  const BWAStats = data?.BWAStats || [];

  const dateColumns = getDateColumns(dateFrom, dateTo);

  const dataSource: Record[] = [];
  allCategories.forEach(([category, description], idx) => {
    const categoryBucket = BWAStats.find(
      (bucket) => bucket.category === category
    );
    if (categoryBucket) {
      dataSource.push({
        key: "row" + idx,
        category: description,
        ...getTableRow(dateColumns, categoryBucket.data),
      });
    }
  });

  const columns = [
    {
      title: "Category",
      dataIndex: "category",
      fixed: "left" as const,
      width: 200,
      render: (categoryDescription: string, _record: Record) => (
        <Tooltip placement="topLeft" title={categoryDescription}>
          {renderCell(categoryDescription)}
        </Tooltip>
      ),
      ellipsis: true,
      onCell: (_record: Record, rowIndex: number | undefined) =>
        getOnCellPropsHandler(getCellMark("category", rowIndex as number))({
          dateFrom,
          dateTo,
        }),
    },
    ...dateColumns,
  ];

  const handleCategoryRowClick = (record: Record): void => {
    const category = allCategories.find(
      (categoryRaw) => categoryRaw[1] === record.category
    );
    const categorized = category && category[0] !== "uncategorized";
    setTransactionFilterOptions((prev) => ({
      ...prev,
      categorized,
      categories: categorized ? [category ? category[0] : ""] : [],
    }));
  };

  return (
    <BWATableWrapper>
      {dataSource.length ? (
        <Table<Record>
          loading={loading}
          pagination={false}
          expandRowByClick={true}
          onRow={(record) => ({
            onClick: () => {
              handleCategoryRowClick(record);
            },
          })}
          expandable={{
            expandedRowRender: (_record, index, _indent, expanded) => {
              if (!expanded)
                expandedCellIndices.current =
                  expandedCellIndices.current.filter(
                    (mark) => mark.split("-")[0] !== index.toString()
                  );
              return expanded ? (
                <TransactionList
                  filterOptions={transactionFilterOptions}
                  isNested
                />
              ) : null;
            },
            showExpandColumn: false,
          }}
          columns={columns}
          scroll={{ x: 600, y: "calc(100vh - 305px)" }}
          dataSource={dataSource}
          summary={() => (
            <Table.Summary fixed>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0} key="total">
                  TOTAL
                </Table.Summary.Cell>
                {dateColumns.map((column: ColumnType<Record>, idx) => {
                  const [dataIndexMonth, dataIndexYear] = (
                    column.dataIndex as string
                  ).split("-");
                  return (
                    <Table.Summary.Cell
                      index={1}
                      align="right"
                      key={"total" + idx}
                    >
                      {formatAmountInCents(
                        BWAStats.reduce((total, { data }) => {
                          const sum = data.find(({ year, month }) => {
                            return (
                              year === dataIndexYear &&
                              month === parseInt(dataIndexMonth).toString()
                            );
                          })?.sum;
                          return sum ? total + sum : total;
                        }, 0)
                      )}
                    </Table.Summary.Cell>
                  );
                })}
              </Table.Summary.Row>
            </Table.Summary>
          )}
        />
      ) : (
        <EmptyWrapper description="No transactions" />
      )}
    </BWATableWrapper>
  );
};

export default BWAStatsTable;
