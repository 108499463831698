import { useState, useEffect, useCallback } from "react";

import { toError } from "../../utils/error";

import useMountedState from "./useMountedState";

type TAsyncFn<T> = (...args: any[]) => Promise<T>;

type UseRequestResult<T> = {
  isLoading: boolean;
  data?: T;
  error?: Error;
};

type UseRequestTuple<T> = [(...args: any[]) => Promise<T>, UseRequestResult<T>];

function useBaseRequest<T>(fn: TAsyncFn<T>, lazy: boolean) {
  const [state, setState] = useState<UseRequestResult<T>>({
    isLoading: false,
  });

  const isMounted = useMountedState();

  const runRequest = useCallback(
    async (...args: any[]) => {
      if (isMounted()) {
        setState({ isLoading: true });
      }
      try {
        const data = await fn(...args);
        if (isMounted()) {
          setState({ isLoading: false, data });
        }
        return data;
      } catch (error) {
        if (isMounted()) {
          setState({ isLoading: false, error: toError(error) });
        }
      }
    },
    [fn, isMounted]
  );
  // Runs non-lazy requests immediately as an Effect
  //
  // The runRequest reference _will_ change anytime we re-render
  // with a new fn reference.
  // Make sure to wrap fn() in a useCallback() if you want it to be
  // a stable reference accross re-renders
  useEffect(() => {
    if (!lazy) {
      runRequest();
    }
  }, [lazy, runRequest]);

  return lazy ? [runRequest, state] : state;
}

/**
 * Runs an asynchronous request as an Effect, and yields an { isLoading, data, error }
 * state that gets updated when the underlying Promise resolves.
 *
 *
 * Example:
 *
 *   const fn = useCallback(() => api.user.getStatementsPdf (year, month), [ year, month ]);
 *
 *   const { isLoading, data: url, error } = useRequest(fn);
 *
 *   return <>
 *     // do something with isLoading, url, error
 *   </>;
 */
export function useRequest<T>(fn: TAsyncFn<T>): UseRequestResult<T> {
  return useBaseRequest(fn, false) as UseRequestResult<T>;
}

/**
 * A lazy version of useRequest(): provides a runRequest callback that could be set
 * as a component's onClick() handler for example
 *
 * Example:
 *
 *   const [ runRequest, { isLoading, data: url, error } ] = useLazyRequest(
 *     () => api.user.getStatementsPdf(year, month)
 *   );
 *
 *   return <>
 *     <Component onClick={runRequest}>
 *      // do something with isLoading, url, error
 *     </Component>
 *   </>;
 */
export function useLazyRequest<T>(fn: TAsyncFn<T>): UseRequestTuple<T> {
  return useBaseRequest(fn, true) as UseRequestTuple<T>;
}
