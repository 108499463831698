import { gql, MutationHookOptions, useMutation } from "@apollo/client";

import { IncomeTaxDeclarationStatus } from "../schema.generated";
import { IncomeTaxDeclaration } from "../types";

export interface UpdateIncomeTaxDeclarationInput {
  status: IncomeTaxDeclarationStatus;
}

export interface UpdateIncomeTaxDeclarationMutationVariables {
  email: string;
  year: number;
  payload: UpdateIncomeTaxDeclarationInput;
}

export interface UpdateIncomeTaxDeclarationMutationResult {
  updateIncomeTaxDeclaration: IncomeTaxDeclaration;
}

export const UPDATE_INCOME_TAX_DECLARATION_MUTATION = gql`
  mutation updateIncomeTaxDeclaration(
    $email: String!
    $year: Int!
    $payload: UpdateIncomeTaxDeclarationInput!
  ) {
    updateIncomeTaxDeclaration(email: $email, year: $year, payload: $payload) {
      status
      statusUpdatedAt
    }
  }
`;

export const useUpdateIncomeTaxDeclarationMutation = (
  options?: MutationHookOptions<
    UpdateIncomeTaxDeclarationMutationResult,
    UpdateIncomeTaxDeclarationMutationVariables
  >
) =>
  useMutation<
    UpdateIncomeTaxDeclarationMutationResult,
    UpdateIncomeTaxDeclarationMutationVariables
  >(UPDATE_INCOME_TAX_DECLARATION_MUTATION, options);
