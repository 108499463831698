import styled from "styled-components";
import { Modal } from "antd";

import colors from "../../../../../themes/colors";

export const StyledModal = styled(Modal)`
  .ant-modal-body {
    padding: 0;
    border: none;
    height: 800px;
    overflow-y: scroll;
  }
  .ant-modal-footer {
    display: none;
  }
`;

export const InternalPdfContainer = styled.div`
  height: 700px;
`;

export const DeclineInfoHeader = styled.div`
  display: flex;
  gap: 20px;
  padding: 16px 24px;
  border-bottom: 1px solid ${colors.veryLightGrey};
`;

export const UserNameContainer = styled.div`
  font-weight: 500;
`;

export const DeclineDateContainer = styled.div`
  color: ${colors.danger};
`;

export const DeclineReasonContainer = styled.div`
  padding: 16px 24px;
  border-bottom: 1px solid ${colors.veryLightGrey};
`;

export const DeclineReasonMetaContainer = styled.div`
  color: ${colors.lightGrey};
  margin-bottom: 4px;
`;
