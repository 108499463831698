export const predefinedKeywords = [
  "{{ tax_year }}",
  "{{ client_name }}",
  "{{ taxable_profit_or_loss }}",
  "{{ business_type }}",
  "{{ EUR_telenumber }}",
  "{{ UStE_telenumber }}",
  "{{ EStE_telenumber }}",
  "{{ GewStE_telenumber }}",
  "{{ profit_and_loss_activities }}",
  "{{ other_things }}",
  "{{ agent_name }}",
  "{{ vat_return_payment }}",
  "{{ business_tax_return_payment }}",
  "{{ current_year }}",
  "{{ approval_button }}",
];
