import { StatusColor } from "../../../../../constants";
import { IncomeTaxDeclarationStatus } from "../../../../../api/graphql/schema.generated";

export const INCOME_TAX_DECLARATION_STATUS_MAPPINGS = {
  [IncomeTaxDeclarationStatus.OPEN]: {
    color: StatusColor.GRAY,
    label: "Offen",
  },
  [IncomeTaxDeclarationStatus.NOT_RELEVANT]: {
    color: StatusColor.GREEN,
    label: "Nicht relevant",
  },
  [IncomeTaxDeclarationStatus.IN_PROGRESS_DATA]: {
    color: StatusColor.GOLD,
    label: "In Bearbeitung DATA",
  },
  [IncomeTaxDeclarationStatus.CONSULTATION_DATA]: {
    color: StatusColor.GOLD,
    label: "Rückfrage DATA",
  },
  [IncomeTaxDeclarationStatus.COMPLETED_BY_DATA]: {
    color: StatusColor.PURPLE,
    label: "Abgeschlossen DATA",
  },
  [IncomeTaxDeclarationStatus.IN_PROGRESS_OPS]: {
    color: StatusColor.GOLD,
    label: "In Bearbeitung Ops",
  },
  [IncomeTaxDeclarationStatus.COMPLETED_BY_OPS]: {
    color: StatusColor.PURPLE,
    label: "Abgeschlossen Ops",
  },
  [IncomeTaxDeclarationStatus.IN_PROGRESS_TAX_CONSULTANT]: {
    color: StatusColor.GOLD,
    label: "In Bearbeitung SB",
  },
  [IncomeTaxDeclarationStatus.APPROVED_BY_TAX_CONSULTANT]: {
    color: StatusColor.GREEN,
    label: "Abgeschlossen SB",
  },
  [IncomeTaxDeclarationStatus.OBJECTED_BY_TAX_CONSULTANT]: {
    color: StatusColor.RED,
    label: "Vorbehalt SB",
  },
  [IncomeTaxDeclarationStatus.IN_SUBMISSION_FOR_USER_APPROVAL]: {
    color: StatusColor.PURPLE,
    label: "Vorbereitung Kundenfreigabe",
    hidden: true,
  },
  [IncomeTaxDeclarationStatus.WAITING_FOR_USER_APPROVAL]: {
    color: StatusColor.GOLD,
    label: "Warten auf Kundenfreigabe",
  },
  [IncomeTaxDeclarationStatus.APPROVED_BY_USER]: {
    color: StatusColor.GREEN,
    label: "Von Kunde freigegeben",
  },
  [IncomeTaxDeclarationStatus.OBJECTED_BY_USER]: {
    color: StatusColor.RED,
    label: "Vorbehalt Kunde",
  },
  [IncomeTaxDeclarationStatus.SUBMITTED]: {
    color: StatusColor.PURPLE,
    label: "Eingereicht FA",
  },
  [IncomeTaxDeclarationStatus.OBJECTED_BY_FINANZAMT]: {
    color: StatusColor.RED,
    label: "Vorbehalt FA",
  },
  [IncomeTaxDeclarationStatus.RECEIVED_TAX_BILL]: {
    color: StatusColor.PURPLE,
    label: "Bescheid erhalten",
  },
  [IncomeTaxDeclarationStatus.CLOSED]: {
    color: StatusColor.GREEN,
    label: "Abgeschlossen",
  },
  [IncomeTaxDeclarationStatus.APPEAL_PROCESS_STARTED]: {
    color: StatusColor.RED,
    label: "Rechtsbehelfsverfahren begonnen",
  },
  [IncomeTaxDeclarationStatus.APPEAL_PROCESS_COMPLETED]: {
    color: StatusColor.GREEN,
    label: "Rechtsbehelfsverfahren abgeschlossem",
  },
};

export const INCOME_TAX_DECLARATION_STATUSES = Object.keys(
  INCOME_TAX_DECLARATION_STATUS_MAPPINGS
) as IncomeTaxDeclarationStatus[];
