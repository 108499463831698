import { Form, Input, Select } from "antd";
import { ValidateStatus } from "antd/lib/form/FormItem";

import { RuleConditionStringOp } from "../../../api/graphql/schema.generated";

type RuleConditionStringInputProps = {
  name: string[];
  help?: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  validateStatus?: ValidateStatus;
};

export const RuleConditionStringInput = ({
  help,
  name,
  label,
  placeholder,
  required,
  validateStatus,
}: RuleConditionStringInputProps) => {
  return (
    <Form.Item
      name={[...name, "value"]}
      label={label}
      rules={[{ required }]}
      validateStatus={validateStatus}
      help={help}
    >
      <Input
        type="string"
        placeholder={placeholder}
        addonBefore={
          <Form.Item name={[...name, "op"]} noStyle>
            <Select>
              <Select.Option value={RuleConditionStringOp.EQ}>
                is exactly
              </Select.Option>
              <Select.Option value={RuleConditionStringOp.LIKE}>
                similar to
              </Select.Option>
            </Select>
          </Form.Item>
        }
      />
    </Form.Item>
  );
};
