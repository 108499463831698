import type * as Types from "../../schema.generated";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type CreateDeclarationDocumentMutationVariables = Types.Exact<{
  documentType: Types.DeclarationDocumentType;
  declarationType: Types.TaxDeclarationType;
  declarationId: Types.Scalars["ID"];
}>;

export type CreateDeclarationDocumentMutation = {
  __typename?: "Mutation";
  createDeclarationDocument: { __typename?: "DeclarationDocument"; id: string };
};

export const CreateDeclarationDocumentDocument = gql`
  mutation createDeclarationDocument(
    $documentType: DeclarationDocumentType!
    $declarationType: TaxDeclarationType!
    $declarationId: ID!
  ) {
    createDeclarationDocument(
      documentType: $documentType
      declarationType: $declarationType
      declarationId: $declarationId
    ) {
      id
    }
  }
`;
export type CreateDeclarationDocumentMutationFn = Apollo.MutationFunction<
  CreateDeclarationDocumentMutation,
  CreateDeclarationDocumentMutationVariables
>;

/**
 * __useCreateDeclarationDocumentMutation__
 *
 * To run a mutation, you first call `useCreateDeclarationDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDeclarationDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDeclarationDocumentMutation, { data, loading, error }] = useCreateDeclarationDocumentMutation({
 *   variables: {
 *      documentType: // value for 'documentType'
 *      declarationType: // value for 'declarationType'
 *      declarationId: // value for 'declarationId'
 *   },
 * });
 */
export function useCreateDeclarationDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateDeclarationDocumentMutation,
    CreateDeclarationDocumentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateDeclarationDocumentMutation,
    CreateDeclarationDocumentMutationVariables
  >(CreateDeclarationDocumentDocument, options);
}
export type CreateDeclarationDocumentMutationHookResult = ReturnType<
  typeof useCreateDeclarationDocumentMutation
>;
export type CreateDeclarationDocumentMutationResult =
  Apollo.MutationResult<CreateDeclarationDocumentMutation>;
export type CreateDeclarationDocumentMutationOptions =
  Apollo.BaseMutationOptions<
    CreateDeclarationDocumentMutation,
    CreateDeclarationDocumentMutationVariables
  >;
