import { DocumentCategory } from "../api/graphql/types";

export type Document = {
  id: string;
  url: string;
  downloadUrl: string;
  filename: string;
  createdAt: string;
  type: string;
  matched?: boolean;
  transactionId?: string;
  noMatchReason?: string;
  matchStatus?: string;
  metadata?: DocumentMetadata;
};

export type DocumentMetadata = {
  id: string;
  documentId: string;
  documentCategoryId: string;
  category: DocumentCategory;
};

export const Categories: Record<string, string> = {
  OTHER: "Other documents",
  VERIFICATION: "Verification",
  EXTERNAL_BOOKKEEPING_TOOL: "Daten von deinem Buchhaltungstool",
  EXTERNAL_BOOKEEPING_MANUAL: "Deine Buchhaltungsdaten",
  EXTERNAL_BOOKKEEPING_ADVISOR: "Daten von deiner Steuerkanzlei",
};

export const Folders: Record<string, string> = {
  QUESTIONNAIRE: "Fragebogen der steuerlichen Erfassung",
  INCOME_TAX_RETURN: "Letzte Einkommensteuererklärung",
  INCOME_TAX_NOTICE: "Letzter Einkommensteuerbescheid",
  RECEIPTS: "Receipts",
  RECEIPTS_OUTGOING_TRANSACTIONS: "Belege deiner Ausgaben",
  MANUAL_TABLE: "Manuelle Buchhaltungstabelle",
  INVOICES_INCOMING_TRANSACTIONS: "Rechnungen deiner Einnahmen",
  CSV_EXPORT: "Datev Export als CSV-Datei",
  CASH_RECEIPTS: "Barbelege",
  BANK_STATEMENTS: "Kontoauszüge mit deinen geschäftlichen Transaktionen",
  ASSET_LIST: "Anlagenverzeichnis",
};
