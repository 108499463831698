import { gql, MutationHookOptions, useMutation } from "@apollo/client";
import {
  EuerDeclarationSubformCalculationResults,
  EuerDeclarationSubformInputs,
} from "@kontist/euer-declaration";

import {
  EuerDeclarationSubformStatus,
  EuerDeclarationSubformType,
  EuerDeclarationSubformCalculationMethod,
} from "../schema.generated";

export interface UpdateEuerDeclarationSubformInput {
  calculationMethod: EuerDeclarationSubformCalculationMethod;
  status: EuerDeclarationSubformStatus;
  inputs: EuerDeclarationSubformInputs;
  calculationResults: EuerDeclarationSubformCalculationResults;
}

export interface UpdateEuerDeclarationSubformMutationVariables {
  email: string;
  type: EuerDeclarationSubformType;
  year: number;
  payload: UpdateEuerDeclarationSubformInput;
}

export interface UpdateEuerDeclarationSubformMutationResult {
  updateEuerDeclarationSubform: {
    id: string;
  };
}

export const UPDATE_EUER_DECLARATION_SUBFORM_MUTATION = gql`
  mutation updateEuerDeclarationSubform(
    $email: String!
    $type: EuerDeclarationSubformType!
    $year: Int!
    $payload: UpdateEuerDeclarationSubformInput!
  ) {
    updateEuerDeclarationSubform(
      email: $email
      type: $type
      year: $year
      payload: $payload
    ) {
      id
    }
  }
`;

export const useUpdateEuerDeclarationSubformMutation = (
  options?: MutationHookOptions<
    UpdateEuerDeclarationSubformMutationResult,
    UpdateEuerDeclarationSubformMutationVariables
  >
) =>
  useMutation<
    UpdateEuerDeclarationSubformMutationResult,
    UpdateEuerDeclarationSubformMutationVariables
  >(UPDATE_EUER_DECLARATION_SUBFORM_MUTATION, options);
