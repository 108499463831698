import { gql, QueryHookOptions, useQuery } from "@apollo/client";

import { IBusinessAsset } from "../../../types";

export interface BusinessAssetsQueryVariables {
  email: string;
}

export interface BusinessAssetsQueryResult {
  businessAssets: IBusinessAsset[];
}

export const BUSINESS_ASSETS_QUERY = gql`
  query businessAssets($email: String!) {
    businessAssets(email: $email) {
      id
      assetClass
      assetType
      note
      amount
      purchaseDate
      depreciationPeriodYears
      receipts {
        id
        filetype
        fullsize
      }
      depreciations {
        year
        startAmount
        depreciationAmount
        depreciationMonths
      }
      naturallyDepreciated
      categoryCode
      endAmount
      exitReason
      exitAmount
      exitDate
      isExitedWithVat
      bookValueOnExit
      metaData {
        amount
        transactionName
        transactionValutaDate
        transactionDescription
      }
    }
  }
`;

export const useBusinessAssetsQuery = (
  options?: QueryHookOptions<
    BusinessAssetsQueryResult,
    BusinessAssetsQueryVariables
  >
) =>
  useQuery<BusinessAssetsQueryResult, BusinessAssetsQueryVariables>(
    BUSINESS_ASSETS_QUERY,
    options
  );
