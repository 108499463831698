import moment from "moment-timezone";
import uniqBy from "lodash/uniqBy";
import { isSteuerIdValid } from "validate-steuerid";

import {
  IUser,
  CashTransactionsPercentage,
  PermanentExtensionStatus,
  InternationalCustomers,
  ThreeStateAnswer,
  Subscription,
  UserDependent,
  UserDependentType,
  DependentError,
  VatExemptionWithItd,
  VatExemptionWithoutItd,
  OnbStatus,
} from "../../../../../types";
import { AccountingSourceType } from "../../../../../types/AccountingSource.type";
import { SupportedLanguage } from "../../../../../api/graphql/schema.generated";

export const LOCAL_ID_PREFIX = "local";

const RECURLY_PLAN_TRANSLATION: {
  [recurlyPlanId: string]: string;
} = {
  basic01: "Free",
  basic02: "Free",
  card01: "Karte",
  premium01: "Premium",
  lexoffice01: "Duo",
  accounting: "Buchhaltungsplan",
  kontax01: "Steuerservice",
  kontax02: "Steuerservice Kleinunternehmer",
  kontax_pending: "Steuerservice Onboarding",
};

export const formatThreeStateAnswerToString = (
  prop?: ThreeStateAnswer | null
) => {
  switch (prop) {
    case ThreeStateAnswer.YES: {
      return "Yes";
    }
    case ThreeStateAnswer.NO: {
      return "No";
    }
    case ThreeStateAnswer.NOT_SURE: {
      return "Not sure";
    }
    default: {
      return;
    }
  }
};

export const formatCashTransactionsPercentage = (
  percentage?: CashTransactionsPercentage
) => {
  switch (percentage) {
    case CashTransactionsPercentage.NONE: {
      return "None";
    }
    case CashTransactionsPercentage.LESS_THAN_10: {
      return "Less than 10%";
    }
    case CashTransactionsPercentage.MORE_THAN_10: {
      return "More than 10%";
    }
    default: {
      return;
    }
  }
};

export const formatPermanentExtensionStatus = (
  status?: PermanentExtensionStatus
) => {
  switch (status) {
    case PermanentExtensionStatus.DOES_NOT_KNOW: {
      return "Doesn't know";
    }
    case PermanentExtensionStatus.DOES_NOT_HAVE: {
      return "No";
    }
    case PermanentExtensionStatus.DOES_HAVE: {
      return "Yes";
    }
    default: {
      return;
    }
  }
};

export const formatAccountingSource = (source?: AccountingSourceType) => {
  switch (source) {
    case AccountingSourceType.COMPLETELY_IN_KONTIST: {
      return "Komplett Kontist";
    }
    case AccountingSourceType.EXTERNAL_VIA_LEXOFFICE_DEBITOR_SEVDESK: {
      return "Extern Lexoffice/Debitor/Sevdesk";
    }
    case AccountingSourceType.EXTERNAL_VIA_VORBERATER: {
      return "Extern komplett Vorberater";
    }
    case AccountingSourceType.EXTERNAL_MANUAL: {
      return "Extern manuell";
    }
    case AccountingSourceType.PARTIALLY_IN_KONTIST_AND_VIA_VORBERATER: {
      return "Kontist/Vorberater";
    }
    case AccountingSourceType.PARTIALLY_IN_KONTIST_AND_VIA_LEXOFFICE_DEBITOR_SEVDESK: {
      return "Kontist/Lexoffice/Debitor/Sevdesk";
    }
    case AccountingSourceType.PARTIALLY_IN_KONTIST_AND_MANUAL: {
      return "Kontist/manuell";
    }
    default: {
      return "No status";
    }
  }
};

export const formatInternationalCustomers = (
  customers?: InternationalCustomers
) => {
  switch (customers) {
    case InternationalCustomers.NONE: {
      return "No";
    }
    case InternationalCustomers.EU: {
      return "Yes, in other EU countries";
    }
    case InternationalCustomers.WORLDWIDE: {
      return "Yes, worldwide";
    }
    default: {
      return;
    }
  }
};

export const formatBookkeepingServices = (user: IUser) => {
  const services = [];

  if (user.isDebitoorConnected) services.push("Debitoor");
  if (user.isFastbillConnected) services.push("FastBill");
  if (user.isLexofficeConnected) services.push("lexoffice");

  return services.length ? services.join(" + ") : null;
};

export const formatTimestamp = (timestamp?: string) => {
  return !!timestamp ? moment(timestamp).format("YYYY-MM-DD HH:mm") : "-";
};

/**
 * Returns a timestamp formatted to its full month name and year. E.g: March 2021.
 */
export const formatMonthYear = (timestamp?: string) => {
  return !!timestamp ? moment(timestamp).format("MMMM YYYY") : "-";
};

export const formatDayMonthYear = (
  timestamp?: string,
  lang: SupportedLanguage = SupportedLanguage.EN
) => {
  const format = lang === SupportedLanguage.EN ? "YYYY-MM-DD" : "DD.MM.YYYY";
  return !!timestamp ? moment(timestamp).format(format) : "-";
};

export const formatRecurlyPlans = (subscriptions: Subscription[]) => {
  if (!subscriptions.length) {
    return;
  }
  return subscriptions
    .map(
      ({ recurlyPlanId }) =>
        RECURLY_PLAN_TRANSLATION[recurlyPlanId] || recurlyPlanId
    )
    .join(", ");
};

export const getActiveSubscription = (subscriptions: Subscription[]) => {
  return (
    subscriptions.find((subscription) => subscription.status === "ACTIVE")
      ?.type || ""
  );
};

export const getFormattedType = (type: UserDependentType): string => {
  switch (type) {
    case UserDependentType.CHILD:
      return "Child";
    case UserDependentType.PARTNER:
      return "Partner";
    case UserDependentType.EMPTY:
      return "-";
  }
};

// Remove fake ID ("local.xxx") of newly created dependent
// Remove deTaxId if it is empty
export const trimFakeIdAndEmptyTaxId = (
  dependents: UserDependent[]
): UserDependent[] =>
  dependents.map(({ id, deTaxId, ...rest }) => ({
    ...(id?.startsWith(LOCAL_ID_PREFIX) ? {} : { id }),
    ...(!!deTaxId ? { deTaxId } : {}),
    ...rest,
  }));

export const validateDependent = (dependent: UserDependent): DependentError => {
  const errors: DependentError = {
    type:
      dependent.type !== UserDependentType.PARTNER &&
      dependent.type !== UserDependentType.CHILD,
    firstName: dependent.firstName === "",
    lastName: dependent.lastName === "",
    birthDate: dependent.birthDate === "",
    deTaxId: !!dependent.deTaxId && !isSteuerIdValid(dependent.deTaxId),
  };

  return errors;
};

export const hasDependentError = (errors: DependentError) =>
  Object.values(errors).some((invalid) => invalid);

export const validateDependents = (dependents: UserDependent[]): void => {
  // Check empty field
  dependents.forEach((dependent) => {
    const errors: DependentError = validateDependent(dependent);
    const hasError = hasDependentError(errors);
    if (hasError) {
      throw new Error("Some of the input fields are empty or invalid.");
    }
  });

  // Check number of partners
  const partners = dependents.filter(
    ({ type }) => type === UserDependentType.PARTNER
  );

  if (partners.length > 1) {
    throw new Error("Can't provide more than one Partner as user dependents");
  }

  // Check dependents' deTaxId for duplication
  const depWithDeTaxIds = dependents.filter(({ deTaxId }) => !!deTaxId);
  const uniqDepDeTaxIds = uniqBy(depWithDeTaxIds, "deTaxId");
  if (depWithDeTaxIds.length !== uniqDepDeTaxIds.length) {
    // Error! Received repeated ids
    throw new Error("Dependents' DE Tax ID must be unique");
  }
};

export const generateEmptyDependent = (): UserDependent => ({
  // Generate fake ID, which will be removed upon making request to backend.
  id: `${LOCAL_ID_PREFIX}.${new Date().getTime()}`,
  type: UserDependentType.EMPTY,
  firstName: "",
  lastName: "",
  birthDate: "",
  deTaxId: "",
});

export const isVatExemptionWithId = (
  value: VatExemptionWithItd | VatExemptionWithoutItd
): value is VatExemptionWithItd =>
  Object.values(VatExemptionWithItd).includes(value as VatExemptionWithItd);

export const formatVatExemption = (
  value: VatExemptionWithItd | VatExemptionWithoutItd
) => {
  if (!value) {
    return null;
  }

  if (isVatExemptionWithId(value)) {
    switch (value) {
      case VatExemptionWithItd.SECTION_4_NR_7: {
        return "§ 4 Nr. 7 UStG";
      }
    }
  } else {
    switch (value) {
      case VatExemptionWithoutItd.SECTION_4_NR_8: {
        return "§ 4 Nr. 8 UStG - Kreditgewährung [Finanzberater, Vertrieb von Krediten etc.]";
      }
      case VatExemptionWithoutItd.SECTION_4_NR_11: {
        return "§ 4 Nr. 11 UStG - Bausparkassenvertreter, Versicherungsvertreter";
      }
      case VatExemptionWithoutItd.SECTION_4_NR_14: {
        return "§ 4 Nr. 14 UStG - Heilberufe [Ärzte, Physiotherapeuten etc.]";
      }
      case VatExemptionWithoutItd.SECTION_4_NR_16: {
        return "§ 4 Nr. 16 UStG - Pflegeeinrichtungen [Pfleger etc.]";
      }
      case VatExemptionWithoutItd.SECTION_4_NR_20: {
        return "§ 4 Nr. 20 UStG - Theater, Orchester usw. [Kreativ-Berufe: Musiker, Schauspieler etc.]";
      }
      case VatExemptionWithoutItd.SECTION_4_NR_21: {
        return "§ 4 Nr. 21 UStG - Private Schulen, sonstige Einrichtungen [Dozenten etc.]";
      }
      case VatExemptionWithoutItd.SECTION_4_NR_22: {
        return "§ 4 Nr. 22 UStG - Vorträge, Kurse usw. [Dozenten etc.]";
      }
    }
  }
};

export const VAT_EXEMPTION_OPTIONS = {
  "Steuerfreie Umsätze mit Vorsteuerabzug": Object.values(VatExemptionWithItd),
  "Steuerfreie Umsätze ohne Vorsteuerabzug": Object.values(
    VatExemptionWithoutItd
  ),
};

export const formatOnbStatus = (type?: OnbStatus | null): string => {
  switch (type) {
    case OnbStatus.COMPLETED:
      return "Completed";
    case OnbStatus.NOT_COMPLETED:
      return "Not completed";
    case OnbStatus.NOT_SET:
      return "Not set";
    case OnbStatus.DECLINED_IN_CALL:
      return "Declined in call";
    case OnbStatus.DUNNING_BLOCK:
      return "Dunning block";
    case OnbStatus.DATA_CHECK:
      return "Data check";
    case OnbStatus.EXPIRED:
      return "Expired";
    case OnbStatus.READY_FOR_CALL:
      return "Ready for call";
    case OnbStatus.WAITING_FOR_TAX_NUMBER:
      return "Waiting for tax number";
    case OnbStatus.WAITING_FOR_USER:
      return "Waiting for user";
    case OnbStatus.USER_CANCELLATION:
      return "User Cancellation";
    case OnbStatus.IN_REVIEW:
      return "In Review";
    default:
      return "Not set";
  }
};
