import React, { MouseEventHandler } from "react";
import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";

const AddExternalTransactionButton = ({
  onClick,
  disabled,
}: {
  onClick: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
}) => {
  return (
    <Button
      data-test="addVirtualTransactionButton"
      icon={<PlusOutlined />}
      size="middle"
      style={{ display: "flex", alignItems: "center" }}
      onClick={onClick}
      disabled={disabled}
    >
      Add transaction
    </Button>
  );
};

export default AddExternalTransactionButton;
