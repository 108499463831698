import React, { useState, useEffect } from "react";
import { notification } from "antd";

import EditableCustomerAttribute, {
  EditType,
} from "../../../../../common/EditableCustomerAttribute";
import {
  CustomerAttributes,
  CustomerInformationCard,
  CustomerInformationSectionTitle,
} from "../../../../../common/styledComponents";
import {
  BusinessType,
  IUser,
  IUserDetails,
  YesNoEnum,
} from "../../../../../../types";
import VerticalDateRangePicker, {
  DateRange,
} from "../../../../../common/VerticalDateRangePicker";
import { VerticalAligned } from "../styledComponents";
import BusinessAddressTable from "./BusinessAddressTable";
import { formatBooleanToString } from "../../../../../../utils";
import ActionLogDrawerAsync from "../../../../../common/ActionLogDrawerAsync";
import { TAX_ADVISORY_PERMISSION_SCOPES } from "../../../../../../constants";
import { getPermissionScope } from "../../../../../../gapi";

const UserBusinessInformationBlock = ({
  user,
  editUser,
}: {
  user: IUser;
  editUser: (email: string, userDetails: IUserDetails) => Promise<void>;
}) => {
  const { email } = user;
  const [businessDuration, setBusinessDuration] = useState<DateRange>({
    startDate: user.kontaxUser?.businessStartDate,
    endDate: user.kontaxUser?.businessEndDate,
  });

  const hasTaxAdvisoryPermissionScope = TAX_ADVISORY_PERMISSION_SCOPES.includes(
    getPermissionScope()
  );

  useEffect(() => {
    setBusinessDuration({
      startDate: user.kontaxUser?.businessStartDate,
      endDate: user.kontaxUser?.businessEndDate,
    });
  }, [user]);

  // Removed value returns null, undefined means untouched.
  async function onSaveBusinessDurationHandler({
    startDate,
    endDate,
  }: DateRange) {
    const businessDurationValue = {
      startDate: businessDuration.startDate,
      endDate: businessDuration.endDate,
    };

    if (startDate !== undefined) {
      businessDurationValue.startDate = startDate;
    }

    if (endDate !== undefined) {
      businessDurationValue.endDate = endDate;
    }

    setBusinessDuration(businessDurationValue);
    try {
      await editUser(email, {
        kontaxUser: {
          businessStartDate: businessDurationValue.startDate,
          businessEndDate: businessDurationValue.endDate,
        },
      });
    } catch (error) {
      notification.error({
        message: (error as Error).name,
        description: (error as Error).message,
      });
    }
  }

  async function onSaveBusinessTypeHandler(businessType: BusinessType) {
    try {
      await editUser(email, {
        kontaxUser: {
          businessType,
        },
      });
    } catch (error) {
      notification.error({
        message: (error as Error).name,
        description: (error as Error).message,
      });
    }
  }

  return (
    <>
      <CustomerInformationSectionTitle>
        <span>Unternehmensdaten</span>
        <span>
          <ActionLogDrawerAsync
            title="Action log"
            modelName="kontax_user"
            recordIds={[user?.kontaxUser?.id?.toString() || ""]}
            small
          />
        </span>
      </CustomerInformationSectionTitle>
      <CustomerInformationCard>
        <VerticalAligned>
          <CustomerAttributes>
            <EditableCustomerAttribute
              label="Art des Betriebs"
              initialValue={user.kontaxUser?.businessType}
              editType={EditType.Select}
              options={Object.values(BusinessType)}
              onSaveHandler={onSaveBusinessTypeHandler}
              isReadOnly={!hasTaxAdvisoryPermissionScope}
            />
            <EditableCustomerAttribute
              label="Kommentar zur Art des Betriebs"
              initialValue={user.businessTypeComment}
              editType={EditType.TextArea}
              inputMaxLength={255}
              onSaveHandler={async (businessTypeComment: string) => {
                await editUser(user.email, { businessTypeComment });
              }}
              isReadOnly={!hasTaxAdvisoryPermissionScope}
            />

            <EditableCustomerAttribute
              label="Has Employees"
              initialValue={
                user.hasEmployees === null || undefined
                  ? user.hasEmployees
                  : formatBooleanToString(!!user.hasEmployees)
              }
              editType={EditType.Select}
              options={[...Object.values(YesNoEnum)]}
              onSaveHandler={async (value: YesNoEnum) => {
                await editUser(user.email, {
                  hasEmployees: !!value ? value === YesNoEnum.YES : null,
                });
              }}
              allowClear
              isReadOnly={!hasTaxAdvisoryPermissionScope}
            />
            <VerticalDateRangePicker
              label="Dauer der Unternehmereigenschaft"
              initialValue={businessDuration}
              topPlaceholder="Beginn der Tätigkeit"
              bottomPlaceholder="Ende der Tätigkeit"
              onSaveHandler={onSaveBusinessDurationHandler}
              isReadOnly={!hasTaxAdvisoryPermissionScope}
            />
          </CustomerAttributes>
          <BusinessAddressTable email={email} />
        </VerticalAligned>
      </CustomerInformationCard>
    </>
  );
};

export default UserBusinessInformationBlock;
