import type * as Types from "../schema.generated";

import { gql } from "@apollo/client";
export type HomeOfficeExpenseFragment = {
  __typename?: "HomeOfficeExpense";
  id: string;
  type: Types.HomeOfficeExpenseType;
  period: Types.HomeOfficeExpensePeriod;
  monthsUsed?: number | null;
  adjustByOfficeAreaShare: boolean;
  syncedFromQuestionnaireAt?: string | null;
  amount: number;
  grossAmount: number;
  netAmount: number;
  vatAmount: number;
  vatRate: string;
  note: string;
  assets: Array<{
    __typename?: "Asset";
    id: string;
    name: string;
    filetype: string;
    thumbnail: string;
    fullsize: string;
  }>;
};

export const HomeOfficeExpenseFragmentDoc = gql`
  fragment homeOfficeExpense on HomeOfficeExpense {
    id
    type
    period
    monthsUsed
    adjustByOfficeAreaShare
    syncedFromQuestionnaireAt
    amount
    grossAmount
    netAmount
    vatAmount
    vatRate
    note
    assets {
      id
      name
      filetype
      thumbnail
      fullsize
    }
  }
`;
