import { gql, MutationHookOptions, useMutation } from "@apollo/client";

import { DatevData, Direction } from "../../types";

export interface UpdateDatevDataInput {
  id: string;
  direction: Direction;
  kkr: string;
  vatCategoryCode: string;
}

export interface UpdateDatevDataMutationVariables {
  payload: UpdateDatevDataInput;
}

export interface UpdateDatevDataMutationResult {
  updateDatevData: DatevData;
}

export const UPDATE_DATEV_DATA_MUTATION = gql`
  mutation updateDatevData($payload: UpdateDatevDataInput!) {
    updateDatevData(payload: $payload) {
      id
      skrMode
      bookingMode
      bookkeepingTool
      amount
      direction
      account
      offsetAccount
      paymentDate
      buKey
      meta1
      description
      kkr
      vatCategoryCode
      verifiedAt
      assets {
        filetype
        fullsize
      }
    }
  }
`;

export const useUpdateDatevDataMutation = (
  options?: MutationHookOptions<
    UpdateDatevDataMutationResult,
    UpdateDatevDataMutationVariables
  >
) =>
  useMutation<UpdateDatevDataMutationResult, UpdateDatevDataMutationVariables>(
    UPDATE_DATEV_DATA_MUTATION,
    options
  );
