import { gql, QueryHookOptions, useQuery } from "@apollo/client";

import { TaxNumber } from "../types";

export interface TaxNumbersQueryVariables {
  email: string;
}

export interface TaxNumbersQueryResult {
  taxNumbers: TaxNumber[];
}

export const TAX_NUMBERS_QUERY = gql`
  query taxNumbers($email: String!) {
    taxNumbers(email: $email) {
      id
      type
      taxNumber
      validFrom
      description
      deletedAt
      isMainBusinessTaxNumber
      changeLogs {
        changes
        changedAt
        changedBy
      }
    }
  }
`;

export const useTaxNumbersQuery = (
  options?: QueryHookOptions<TaxNumbersQueryResult, TaxNumbersQueryVariables>
) =>
  useQuery<TaxNumbersQueryResult, TaxNumbersQueryVariables>(
    TAX_NUMBERS_QUERY,
    options
  );
