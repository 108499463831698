import React from "react";

import Popup from "../Popup";
import { Button } from "../styledComponents";
import { PreLineParagraph } from "./styledComponents";
import { ReactComponent as WarningIcon } from "../../../svgs/warning.svg";

type Props = {
  isOpen?: boolean;
  onDelete: () => void;
  onClose: () => void;
  disabled?: boolean;
  title: string;
  text?: string;
};

const DeleteConfirmationPopup = ({
  isOpen,
  onDelete,
  onClose,
  disabled = false,
  title,
  text,
}: Props) => {
  if (!isOpen) {
    return null;
  }

  return (
    <Popup onClosePopup={onClose}>
      <WarningIcon className="mb-3" />
      <h3 className="mb-3">{title}</h3>
      {text && <PreLineParagraph className="mb-2">{text}</PreLineParagraph>}
      <Button
        data-test="confirmAssetDeletion"
        className="primary mb-2"
        onClick={onDelete}
        disabled={disabled}
      >
        Endgültig löschen
      </Button>
      <Button className="link" onClick={onClose} disabled={disabled}>
        Abbrechen
      </Button>
    </Popup>
  );
};

export default DeleteConfirmationPopup;
