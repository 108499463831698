export const LAND_ASSET_CLASS = "Grund und Boden";

const immovableAssets = [
  {
    assetClass: LAND_ASSET_CLASS,
    depreciationYears: null,
  },
  {
    assetClass: "Gebäude",
    depreciationYears: null,
  },
  {
    assetClass: "Andere (z. B. grundstücksgleiche Rechte)",
    depreciationYears: null,
  },
];

export default immovableAssets;
