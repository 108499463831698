import type * as Types from "../../schema.generated";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type SetActionReasonMutationVariables = Types.Exact<{
  transactionId: Types.Scalars["String"];
  reason: Types.ActionReason;
}>;

export type SetActionReasonMutation = {
  __typename?: "Mutation";
  setActionReason: { __typename?: "MutationResult"; success: boolean };
};

export const SetActionReasonDocument = gql`
  mutation setActionReason($transactionId: String!, $reason: ActionReason!) {
    setActionReason(transactionId: $transactionId, reason: $reason) {
      success
    }
  }
`;
export type SetActionReasonMutationFn = Apollo.MutationFunction<
  SetActionReasonMutation,
  SetActionReasonMutationVariables
>;

/**
 * __useSetActionReasonMutation__
 *
 * To run a mutation, you first call `useSetActionReasonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetActionReasonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setActionReasonMutation, { data, loading, error }] = useSetActionReasonMutation({
 *   variables: {
 *      transactionId: // value for 'transactionId'
 *      reason: // value for 'reason'
 *   },
 * });
 */
export function useSetActionReasonMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetActionReasonMutation,
    SetActionReasonMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetActionReasonMutation,
    SetActionReasonMutationVariables
  >(SetActionReasonDocument, options);
}
export type SetActionReasonMutationHookResult = ReturnType<
  typeof useSetActionReasonMutation
>;
export type SetActionReasonMutationResult =
  Apollo.MutationResult<SetActionReasonMutation>;
export type SetActionReasonMutationOptions = Apollo.BaseMutationOptions<
  SetActionReasonMutation,
  SetActionReasonMutationVariables
>;
