import React from "react";

import { VatMeta } from "../../styledComponents";
import PastDeclarations from "./PastDeclarations";
import UserDetails from "./UserDetails";
import UserInfoExcerpt from "../../../../common/UserInfoExcerpt";
import {
  UserInfoExcerptWrapper,
  UserInfoWrapper,
  UserNameWrapper,
  ViewMoreWrapper,
} from "../../../../common/UserInfoExcerpt/styledComponents";
import { ReactComponent as ArrowRight } from "../../../../../svgs/arrow-right.svg";
import {
  formatDayMonthYear,
  formatMonthYear,
} from "../../../Mandanten/Common/UserDetails/utils";
import { useKontaxUserContext } from "../../../../contexts/KontaxUserContext";
import { UserInfoDrawerSources } from "../../../../common/UserInfoExcerpt/UserInfoDrawer";

const VatDeclarationDetails = ({
  setUpdatedDetails,
}: {
  setUpdatedDetails: Function;
}) => {
  const { user, notes, pastDeclarations, isLoading } = useKontaxUserContext();

  return (
    <VatMeta>
      <UserInfoExcerpt
        user={user}
        notes={notes}
        source={UserInfoDrawerSources.ACCOUNTING}
        isLoading={isLoading}
        renderExcerptData={(excerptData) => {
          return (
            <UserInfoExcerptWrapper>
              <UserNameWrapper>
                {excerptData.user.firstName} {excerptData.user.lastName}
              </UserNameWrapper>

              <span>
                {excerptData.vatDetails?.isKleinunternehmer && (
                  <UserInfoWrapper>Kleinunternehmer</UserInfoWrapper>
                )}
                {excerptData.user.businessTypeComment && (
                  <UserInfoWrapper>
                    {excerptData.user.businessTypeComment}
                  </UserInfoWrapper>
                )}
                <UserInfoWrapper>
                  FiBu: {formatMonthYear(excerptData.user.fibuStartDate)}
                </UserInfoWrapper>
                <UserInfoWrapper>
                  Billing:{" "}
                  {formatDayMonthYear(excerptData.user.kontaxBillingStartDate)}
                </UserInfoWrapper>
                <UserInfoWrapper>{excerptData.notesCount}</UserInfoWrapper>
              </span>

              <ViewMoreWrapper>
                <span>View more info</span>
                <ArrowRight />
              </ViewMoreWrapper>
            </UserInfoExcerptWrapper>
          );
        }}
      />
      <UserDetails user={user} />
      {user && pastDeclarations?.length > 0 && (
        <PastDeclarations
          pastDeclarations={pastDeclarations}
          email={user.email}
        />
      )}
    </VatMeta>
  );
};

export default VatDeclarationDetails;
